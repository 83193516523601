import { PagedTable, usePagedTable } from '@jetkit/react'
import { Button, Table, TableBody, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { deleteNotification, fetchCompanyNotifications, fetchCompanyTypeNotifications } from 'api/notifications'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import NotificationDialog, { INotification } from 'components/notifications/notificationsDialog'
import CompanyHead from 'components/notifications/notificationsTable/companyHead'
import CompanyTypeHead from 'components/notifications/notificationsTable/companyTypeHead'
import NotificationRow from 'components/notifications/notificationsTable/notificationRow'
import * as React from 'react'
import { updatePageVisitsCount } from 'utils/user'
import useSortTable from '../../hooks/useSortTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    notifications: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      marginBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    searchBar: {
      marginBottom: '1rem',
    },
  })
)

export type NotificationType = 'company' | 'companyType'

const Notifications = () => {
  const classes = useStyles()
  const [notificationsType, setNotificationsType] = React.useState<NotificationType>('company')
  const [notificationDialogShown, setNotificationDialogShown] = React.useState(false)
  const [deleteDialogShown, setDeleteDialogShown] = React.useState(false)
  const [editedNotification, setEditedNotification] = React.useState<INotification>()
  const [notificationToDelete, setNotificationToDelete] = React.useState<INotification>()
  const [query, setQuery] = React.useState('')

  const handleEditNotificationClick = React.useCallback(
    (notification: INotification) => {
      setEditedNotification(notification)
      setNotificationDialogShown(true)
    },
    [setEditedNotification, setNotificationDialogShown]
  )

  const handleDeleteNotificationClick = React.useCallback(
    (notification: INotification) => {
      setNotificationToDelete(notification)
      setDeleteDialogShown(true)
    },
    [setNotificationToDelete, setDeleteDialogShown]
  )

  const handleNotificationDialogCloseClick = React.useCallback(() => {
    setEditedNotification(undefined)
    setNotificationDialogShown(false)
  }, [setEditedNotification, setNotificationDialogShown])

  const handleDeleteNotificationDialogCloseClick = React.useCallback(() => {
    setNotificationToDelete(undefined)
    setDeleteDialogShown(false)
  }, [setNotificationToDelete, setDeleteDialogShown])

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const memoApiCall = React.useMemo(() => fetchCompanyTypeNotifications()({ ...sortSettings, query }), [
    query,
    sortSettings,
  ])

  const pagedTable = usePagedTable<INotification>({
    apiCall: memoApiCall,
  })

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const tableHead = React.useMemo(
    () => <CompanyTypeHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  return (
    <div className={classes.container}>
      <NotificationDialog
        isOpen={notificationDialogShown}
        close={handleNotificationDialogCloseClick}
        notificationType={notificationsType}
        editedNotification={editedNotification}
        onRequestSuccessful={handleSuccessfulRequest}
      />
      <DeleteDialog
        open={deleteDialogShown}
        deletionObject={notificationToDelete}
        close={handleDeleteNotificationDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deleteNotification}
      />
      <Button onClick={() => setNotificationsType(prev => (prev === 'company' ? 'companyType' : 'company'))}>
        Switch Table
      </Button>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <div className={classes.notifications}>notifications</div>
          <div className={classes.tableTitle}>Company Notifications List</div>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => setNotificationDialogShown(true)}>
            Schedule Notification
          </ButtonWithLoading>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={text => setQuery(text)} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({ numberOfColumns: 7, sizes: [120, 81, 100, 130, 80, 80, 70], numberOfRows: 6 })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Notifications"
          {...pagedTable.renderProps}
          renderRow={(notification: INotification) => (
            <NotificationRow
              notification={notification}
              notificationType={notificationsType}
              onEditClick={() => handleEditNotificationClick(notification)}
              onDeleteClick={() => handleDeleteNotificationClick(notification)}
            />
          )}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default Notifications
