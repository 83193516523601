import React, { useMemo } from 'react'
import { createStyles, makeStyles, Theme, Button, Table, TableBody, TableContainer, Paper } from '@material-ui/core'
import { useHistory } from 'react-router'
import WebSubmissionHead from '../webSubmissionArchiveTable/webSubmissionArchiveHead'
import { fetchWebSubmissions, WebSubmissionsData } from 'api/webSubmission'
import { PagedTable, usePagedTable } from '@jetkit/react'
import WebSubmissionRow from '../webSubmissionArchiveTable/webSubmissionArchiveRow'
import { skeletons } from 'components/common/table'
import useSortTable from 'hooks/useSortTable'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { WEB_SUBMISSION_TYPE } from 'utils/constant'
import { updatePageVisitsCount } from 'utils/user'

const ArchiveWebSubmissions = () => {
  const classes = useStyles()
  const history = useHistory()
  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_date' })

  const { order, sort_by } = sortSettings
  const type = WEB_SUBMISSION_TYPE?.Archive

  const memoApiCall = useMemo(() => fetchWebSubmissions(type, query)({ sort_order: order, sort_by }), [
    order,
    query,
    sort_by,
    type,
  ])

  const pagedTable = usePagedTable<WebSubmissionsData>({
    apiCall: memoApiCall,
  })

  const tableHead = useMemo(
    () => <WebSubmissionHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.titlesContainer}>
            <p className={classes.missingItems}>Archive Web Submission</p>
            <p className={classes.tableTitle}>Archive Web Submission List</p>
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="outlined" color="primary" onClick={() => history.push('/web-submissions')}>
              Web Submission List
            </Button>
          </div>
        </div>
        {/**/}
      </div>
      <div className={classes.tableSection}>
        <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />

        <TableLoadingIndicator isLoading={pagedTable.isLoading} />

        {pagedTable.isLoading ? (
          <TableContainer component={Paper}>
            <Table>
              {tableHead}
              <TableBody>
                {skeletons({
                  numberOfColumns: 9,
                  sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100],
                  numberOfRows: 6,
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <PagedTable
              colSpan={9}
              emptyRowText="No WebSubmissions"
              {...pagedTable.renderProps}
              renderRow={(webSubmission: WebSubmissionsData) => <WebSubmissionRow webSubmissions={webSubmission} />}
              header={tableHead}
            />
          </TableContainer>
        )}
      </div>
    </>
  )
}

export default ArchiveWebSubmissions

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '50px 50px 5px 50px',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    textField: {
      marginBottom: '1rem',
    },
    tableSection: {
      padding: '0 50px',
      // display: 'flex',
    },
  })
)
