import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'id', label: 'ID', style: {}, sortable: true },
  { id: 'first_name', label: 'Name', style: {}, sortable: true },
  { id: 'company_name', label: 'Company Name', style: {}, sortable: true },
  { id: 'phone', label: 'Phone', style: {}, sortable: true },
  { id: 'email', label: 'Email', style: {}, sortable: true },
  { id: 'industry_type', label: 'Industry Type', style: {}, sortable: true },
  { id: 'created_at', label: 'Created At', style: {}, sortable: true },
  { id: 'last_update', label: 'Last Update', style: {}, sortable: true },
  { id: 'prospect_status', label: 'Status', style: {}, sortable: true },
  { id: 'count', label: 'Notification', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const ProspectTableHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default ProspectTableHead
