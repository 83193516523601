import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '2rem',
      },
    },

    commonRow: {
      margin: '1.35rem 0',
      padding: '1rem 1.25rem',
      display: 'flex',
      backgroundColor: '#f7f8f9',

      '& .offer-heading': {
        fontSize: '0.95rem',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 'normal',
        paddingBottom: '0.5rem',
      },
    },
  })
)
