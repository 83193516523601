import * as React from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonLabel: {
      color: 'darkgray',
    },
  })
)

interface ICancelButtonProps extends ButtonProps {}

function CancelButton(props: ICancelButtonProps) {
  const classes = useStyles()
  return (
    <Button {...props} variant="outlined" classes={{ label: classes.buttonLabel }}>
      {props.children}
    </Button>
  )
}
export default CancelButton
