import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ISuggestionAttribute } from 'components/interfaces'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      //   minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

interface SelectFieldProps {
  placeholder: string
  menuItems: ISuggestionAttribute[] | any
  handleChange?: any
  value?: ISuggestionAttribute | null
  className?: string
}

const SelectField = ({ placeholder, menuItems, handleChange, value, className }: SelectFieldProps) => {
  const classes = useStyles()
  return (
    <FormControl variant="outlined" className={`${classes.formControl} ${className}`}>
      <InputLabel>{placeholder}</InputLabel>
      <Select value={value} onChange={handleChange} label={placeholder} fullWidth>
        {menuItems?.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectField
