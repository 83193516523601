import React from 'react'
import { useStyles } from '../style'
import { Link } from '@material-ui/core'
import { ISpecialOffersCategory } from 'api/specialOffers'
import useRouter from 'use-react-router'

const PowerUpCard = ({ category_img, title, link_text, heading, description, id }: ISpecialOffersCategory) => {
  const classes = useStyles()
  const { history } = useRouter()

  return (
    <div className={classes.PowerUpCardContainer}>
      <p className="powerup-card-title">{title}</p>
      <div style={{ padding: '1.25rem', background: '#f9fcff', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)' }}>
        <div className="powerup-card">
          <div style={{ width: '65%' }}>
            <p className="powerup-card-heading">{heading}</p>
            <div dangerouslySetInnerHTML={{ __html: `${description}` }} className="powerup-card-description" />
          </div>
          <div className="powerup-img">
            <div className="powerup-circle">
              <img width="100%" src={category_img} />
            </div>
          </div>
        </div>
        <Link
          style={{ maxHeight: '22px', textOverflow: 'ellipsis', fontSize: '14px' }}
          onClick={() => history.push(`/power-up/${id}`)}
        >
          {link_text}
        </Link>
      </div>
    </div>
  )
}

export default PowerUpCard
