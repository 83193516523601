import { getDefaultTasksForOrderType, getOrderTasks, IOrderDetailsResponse } from 'api/orders'
import { IOrderTask } from 'models/task'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'

interface IUseTasksProps {
  onCompanyOrderFieldChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => Promise<void>
  companyOrderDetails: IOrderDetailsResponse
  setOpenAddTaskDialog: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * This hook serves the purpose of having tasks logic from company order config
 * dialog in one place
 *
 * @param companyOrderDetails
 * State of the company order that we are gonna update/create
 * @param onCompanyOrderFieldChange
 * Callback used whenever we intend to update a field of `companyOrderDetails`
 */
export const useTasks = ({ onCompanyOrderFieldChange, companyOrderDetails, setOpenAddTaskDialog }: IUseTasksProps) => {
  /**
   * Each order type may have a pre-defined set of tasks associated with it.
   */
  const handleGettingDefaultTasksForOrderType = React.useCallback(async () => {
    if (companyOrderDetails.order_type_id) {
      const defaultTasks = await getDefaultTasksForOrderType(companyOrderDetails.order_type_id)
      onCompanyOrderFieldChange('tasks', defaultTasks)
    } else {
      onCompanyOrderFieldChange('tasks', [])
    }
  }, [companyOrderDetails.order_type_id, onCompanyOrderFieldChange])

  /**
   * When dealing with a company order we may often want to add custom tasks.
   */
  const handleCloseAddTaskDialog = React.useCallback(() => setOpenAddTaskDialog(false), [setOpenAddTaskDialog])

  /**
   * Get an array of tasks the belong to the company order from the backend and set them as state.
   *
   * May be used when we want to make sure that the tasks state is up to date with the backend.
   */
  const getTasksAndSetState = React.useCallback(async () => {
    if (!companyOrderDetails.id) return
    const tasks: IOrderTask[] = await getOrderTasks(companyOrderDetails.id)
    onCompanyOrderFieldChange('tasks', tasks)
  }, [companyOrderDetails.id, onCompanyOrderFieldChange])

  /**
   * When a new task is added to a company order, we want to refresh the tasks state by fetching it from the backend.
   *
   * Used in conjunction with the `AddTaskDialog` which will be closed after a new task is added.
   */
  const onTaskCreated = React.useCallback(() => {
    getTasksAndSetState()
    setOpenAddTaskDialog(false)
  }, [getTasksAndSetState, setOpenAddTaskDialog])

  return {
    onTaskCreated,
    handleCloseAddTaskDialog,
    handleGettingDefaultTasksForOrderType,
  }
}
