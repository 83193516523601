import { usePagedTable } from '@jetkit/react'
import Typography from '@material-ui/core/Typography'
import { fetchTasksAndOrders, getTasksProgress } from 'api/task'
import GlobalNotesDrawer, { GlobalNotesContext, useGlobalNotesDrawer } from 'components/common/notes/globalNotesDrawer'
import CreateTask from 'components/tasks/create'
import { IDashboardRow } from 'components/tasks/dashboardRow'
import { ToggleShowDoneTasksContext } from 'components/tasks/toggleShowDoneTasks'
import { ISortSettings, SortSettingsContext } from 'hooks/useSortTable'
import TasksProgress, {
  TasksProgressContext,
  TasksProgressContextProps,
  useProgress,
} from 'pages/dashboard/tasksProgress'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { DashboardPagedTable } from 'components/dashboard/table'
import TasksSearch from 'pages/dashboard/tasksSearch'
import { useStyles } from 'components/dashboard/styles'
import { TasksContext } from 'contexts/tasks'
import OrderDetailCard from 'components/common/OrderDetailCard'
import { Divider, Grid } from '@material-ui/core'
import HeadingProgress from './headingProgress'
import ClientMsgBox from './clientMsgBox'
import { getWidgetsForDashboard } from 'api/widgets'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import useRouter from 'hooks/router'
import DatePicker from 'components/common/datePicker'
import { datePickerDatePlaceholder } from 'utils/formatDate'
import moment from 'moment'
import Skeleton from '@material-ui/lab/Skeleton'
import useUser from 'hooks/useUser'
import { USERTYPES, USER_TYPES_MAPPING } from 'utils/constant'
import { updatePageVisitsCount } from 'utils/user'
import { ITaxWidgetDashboard, getCustomTaxWidges, getDashboardTaxWidget } from 'api/taxWidgets'
import { fetchTaxOrders } from 'api/taxOrders'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import TaxOrderTable from './taxOrderTable'
import TaxOrderDetailCard from 'components/common/OrderDetailCard/taxOrderDetailCard'
import { ICallWidgets, getCallLogsWidgets } from 'api/callLogs'
import CallLogs from './callLogsTable'
// import CallLogs from 'components/callLogs'

export interface ITasksQueryParams extends ISortSettings {
  query?: string
  company_id: number | null
  client_id: number | null
  assignee_id: number | null
  show_done_tasks: boolean
  priority: number
  date_filter?: any
}

const defaultSortSettings: ITasksQueryParams = {
  sort_by: '',
  order: 'desc',
  query: '',
  show_done_tasks: false,
  company_id: null,
  client_id: null,
  assignee_id: null,
  priority: 1,
  date_filter: moment(),
}

const defaultSortSettingsM: ITasksQueryParams = {
  sort_by: '',
  order: 'desc',
  query: '',
  show_done_tasks: false,
  company_id: null,
  client_id: null,
  assignee_id: null,
  priority: 2,
  date_filter: moment(),
}
const defaultSortSettingsL: ITasksQueryParams = {
  sort_by: '',
  order: 'desc',
  query: '',
  show_done_tasks: false,
  company_id: null,
  client_id: null,
  assignee_id: null,
  priority: 3,
  date_filter: moment(),
}
export const UserDashboard = ({ userId }: { userId: number }) => {
  const classes = useStyles()
  const { user } = useUser()

  const [sortSettings, setSortSettings] = React.useState<ITasksQueryParams>({
    ...defaultSortSettings,
    assignee_id: userId,
    date_filter: moment(new Date())
      .format('MM/DD/YYYY')
      .toString(),
  })
  const [widgets, setwidgets] = React.useState<any>([])
  const [loading, setLoading] = React.useState<any>(false)

  const [taxWidgets, setTaxWidgets] = React.useState<any>([])
  const [loadingTaxWidgets, setLoadingTaxWidgets] = React.useState<any>(false)

  const [taxWidgetDetail, setTaxWidgetDetail] = React.useState<any>([])
  const [isTaxTable, setIsTaxTable] = React.useState(false)

  const [callWidgets, setCallWidgets] = React.useState<any>([])
  const [loadingCallWidget, setLoadingCallWidget] = React.useState<boolean>(false)

  const [callWidgetDetails, setCallWidgetDetails] = React.useState<any>([])
  const [isCallTable, setIsCallTable] = React.useState(false)

  const memoApiCall = useMemo(() => fetchTasksAndOrders(sortSettings), [sortSettings])

  const pagedTable = usePagedTable<IDashboardRow>({ apiCall: memoApiCall })

  const [sortSettingsM, setSortSettingsM] = React.useState<ITasksQueryParams>({
    ...defaultSortSettingsM,
    assignee_id: userId,
    date_filter: moment(new Date())
      .format('MM/DD/YYYY')
      .toString(),
  })
  const [sortSettingsL, setSortSettingsL] = React.useState<ITasksQueryParams>({
    ...defaultSortSettingsL,
    assignee_id: userId,
    date_filter: moment(new Date())
      .format('MM/DD/YYYY')
      .toString(),
  })
  const memoApiCallMedium = useMemo(() => fetchTasksAndOrders(sortSettingsM), [sortSettingsM])
  const memoApiCallCallBack = useMemo(() => fetchTasksAndOrders(sortSettingsL), [sortSettingsL])

  const pagedTableMedium = usePagedTable<IDashboardRow>({ apiCall: memoApiCallMedium })
  const pagedTableCallBacks = usePagedTable<IDashboardRow>({ apiCall: memoApiCallCallBack })

  const onSortSettingsChange = React.useCallback(
    (sortSettings: React.SetStateAction<ISortSettings>) =>
      setSortSettings(prevSortSettings => ({ ...prevSortSettings, ...sortSettings })),
    []
  )

  const onCompanyChange = React.useCallback(
    (newCompanyId: number | null) => setSortSettings(prev => ({ ...prev, company_id: newCompanyId })),
    []
  )
  // React.useEffect(()=>{
  //   onDateFilterChange(moment(new Date()).format("DD/MM/YYYY").toString());
  // },[])
  const onDateFilterChange = React.useCallback((date: any | null) => {
    setSortSettings(prev => ({
      ...prev,
      date_filter: moment(date)
        .format('MM/DD/YYYY')
        .toString(),
    }))
    setSortSettingsM(prev => ({
      ...prev,
      date_filter: moment(date)
        .format('MM/DD/YYYY')
        .toString(),
    }))
    setSortSettingsL(prev => ({
      ...prev,
      date_filter: moment(date)
        .format('MM/DD/YYYY')
        .toString(),
    }))
  }, [])

  const onClientChange = React.useCallback((newClientId: number | null) => {
    setSortSettings(prev => ({ ...prev, client_id: newClientId }))
  }, [])

  const onAssigneeChange = useCallback((newAssigneeId: number | null) => {
    setSortSettings(prev => ({
      ...prev,
      assignee_id: newAssigneeId,
    }))
    setSortSettingsM(prev => ({
      ...prev,
      assignee_id: newAssigneeId,
    }))
    setSortSettingsL(prev => ({
      ...prev,
      assignee_id: newAssigneeId,
    }))
  }, [])

  const onQueryChange = React.useCallback((query: string) => setSortSettings(prev => ({ ...prev, query })), [])

  const handleShowDoneTasksChange = React.useCallback((show_done_tasks: boolean) => {
    setSortSettings(prev => ({
      ...prev,
      show_done_tasks,
    }))
    setSortSettingsM(prev => ({
      ...prev,
      show_done_tasks,
    }))
    setSortSettingsL(prev => ({
      ...prev,
      show_done_tasks,
    }))
  }, [])
  const getCallWidgets = async () => {
    setLoadingCallWidget(true)
    try {
      const data = await getCallLogsWidgets()
      setCallWidgets(data)
      console.log('CALLLOGSWIDGET', data)
    } catch (error) {
      console.error(error)
    }
    setLoadingCallWidget(false)
  }

  React.useEffect(() => {
    const getwidgets = async () => {
      setLoading(true)
      const data = await getWidgetsForDashboard()
      setwidgets(data)
      setLoading(false)
    }
    getwidgets()
  }, [])
  React.useEffect(() => {
    const getTaxWidgets = async () => {
      setLoadingTaxWidgets(true)
      try {
        const data = await getDashboardTaxWidget()
        setTaxWidgets(data)
      } catch (error) {
        console.error(error)
      }
      setLoadingTaxWidgets(false)
    }
    getTaxWidgets()
  }, [])

  React.useEffect(() => {
    getCallWidgets()
  }, [])
  const { notesVisible, setNotesVisible, setNoteEntities, noteEntities } = useGlobalNotesDrawer()

  const tasksProgress: TasksProgressContextProps = useProgress(getTasksProgress)
  const { history } = useRouter()

  const viewOrderDetails = (order_types: any, order_status: any, order: any) => {
    history.push(`/company-order?orderTypes=${[order_types]}&orderStatuses=${order_status}`, { order })
  }

  const viewTaxOrderDetails = (taxOrder: ITaxWidgetDashboard) => {
    setTaxWidgetDetail(taxOrder)
    setIsTaxTable(true)
    //   if (taxOrder?.custom) {
    //     try {
    //       const res = getCustomTaxWidges(taxOrder?.custom_widget)
    //       const pagedTable = usePagedTable<IDashboardRow>({ apiCall: res })
    //       // const memoApiCall = useMemo(() => getCustomTaxWidges(taxOrder?.custom_widget)(sortSettings), [
    //       //   taxOrder?.custom_widget,
    //       // ])
    //       setCustomeWidget(res)
    //     } catch (error) {}
    //   } else {
    //     try {
    //       const res = fetchTaxOrders()
    //       setTaxWidgetDetail(res)
    //     } catch (error) {}
    //   }
  }

  const viewCallLogDetails = (callLog: ICallWidgets) => {
    setCallWidgetDetails(callLog)
    setIsCallTable(true)
    // setTaxWidgetDetail(taxOrder)
    // setIsTaxTable(true)
    //   if (taxOrder?.custom) {
    //     try {
    //       const res = getCustomTaxWidges(taxOrder?.custom_widget)
    //       const pagedTable = usePagedTable<IDashboardRow>({ apiCall: res })
    //       // const memoApiCall = useMemo(() => getCustomTaxWidges(taxOrder?.custom_widget)(sortSettings), [
    //       //   taxOrder?.custom_widget,
    //       // ])
    //       setCustomeWidget(res)
    //     } catch (error) {}
    //   } else {
    //     try {
    //       const res = fetchTaxOrders()
    //       setTaxWidgetDetail(res)
    //     } catch (error) {}
    //   }
  }
  console.log('customeWidget taxWidgetDetail', taxWidgetDetail)

  const reloadTables = async () => {
    pagedTable.reloadData()
    pagedTableMedium.reloadData()
    pagedTableCallBacks.reloadData()
  }
  return (
    <TasksProgressContext.Provider value={tasksProgress}>
      <GlobalNotesContext.Provider value={{ setNoteEntities }}>
        <ToggleShowDoneTasksContext.Provider
          value={{ value: sortSettings.show_done_tasks, onChange: handleShowDoneTasksChange }}
        >
          <TasksContext.Provider value={{ reloadTasks: reloadTables }}>
            <SortSettingsContext.Provider
              value={{
                onSortSettingsChange,
              }}
            >
              <div className={classes.root}>
                <div className={classes.container}>
                  <div className={classes.dashboardSection}>
                    <Typography variant="h4" className={classes.dashboardTitle}>
                      Dashboard
                    </Typography>
                    {user?.user_type !== USERTYPES.accountant ? (
                      <>
                        <Grid
                          container
                          spacing={3}
                          // style={{ justifyContent: 'space-between', alignItems: 'center' }}
                          // className="widget-grid-container"
                        >
                          {/* <div id="2" style={{ display: 'flex' }}> */}
                          {loading ? (
                            <Grid item xs={12} className="left-grid">
                              <Skeleton animation="wave" variant="text" height={200} />
                            </Grid>
                          ) : (
                            widgets.map((order: any, key: number) => (
                              <Grid
                                item
                                md={3}
                                xs={6}
                                key={key}
                                // className="left-grid"
                                onClick={() => viewOrderDetails(order.order_types, order.order_statuses, order.orders)}
                              >
                                <OrderDetailCard
                                  orderStatus={order.name}
                                  orderCount={order.count}
                                  orderDate={formatDateTimeToHumanFriendly(
                                    order.oldest_order_date ? order.oldest_order_date : ''
                                  )}
                                />
                              </Grid>
                            ))
                          )}
                        </Grid>

                        <br />

                        <Grid
                          container
                          spacing={3}
                          // style={{ justifyContent: 'space-between', alignItems: 'center' }}
                          className="widget-grid-container"
                        >
                          <Grid item xs={6} style={{ marginTop: '-12px' }}>
                            <ClientMsgBox type="client" />
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: '-12px' }}>
                            <ClientMsgBox type="prospect" />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    <Grid container spacing={3}>
                      {loadingTaxWidgets ? (
                        <Grid item xs={12} className="left-grid">
                          <Skeleton animation="wave" variant="text" height={200} />
                        </Grid>
                      ) : (
                        <>
                          {user?.user_type === USER_TYPES_MAPPING?.Admin || user?.tax_team
                            ? taxWidgets?.map((item: ITaxWidgetDashboard, key: number) => {
                                if (item?.custom) {
                                  return (
                                    <Grid
                                      item
                                      md={3}
                                      xs={6}
                                      key={key}
                                      // className="left-grid"
                                      onClick={() => viewTaxOrderDetails(item)}
                                    >
                                      <OrderDetailCard
                                        orderStatus={item?.title || ''}
                                        orderCount={item?.count}
                                        orderDate={
                                          item.oldest_order ? formatDateTimeToHumanFriendly(item.oldest_order) : ''
                                        }
                                        isCustomWidget={item?.custom}
                                      />
                                    </Grid>
                                  )
                                }
                              })
                            : ''}

                          <Grid item xs={12} className="card-divider" />
                          <Grid container spacing={3} style={{ margin: 0 }}>
                            {taxWidgets?.map((item: ITaxWidgetDashboard, key: number) => {
                              if (!item?.custom) {
                                return (
                                  <Grid
                                    item
                                    md={3}
                                    xs={6}
                                    key={key}
                                    // className="left-grid"
                                    // onClick={() => viewTaxOrderDetails(item)}
                                  >
                                    <TaxOrderDetailCard
                                      orderStatus={item?.title || ''}
                                      orderCount={item?.count}
                                      orderDate={
                                        item.oldest_order ? formatDateTimeToHumanFriendly(item.oldest_order) : ''
                                      }
                                      alertOrderCount={item?.twentyOneDayAlertOrders}
                                      orderCountHandler={() => viewTaxOrderDetails(item)}
                                      alertOrderCountHandler={() =>
                                        viewTaxOrderDetails({ ...item, twenty_one_day_alert: true })
                                      }
                                    />
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid container spacing={3}>
                      {loadingCallWidget ? (
                        <Grid item xs={12} className="left-grid">
                          <Skeleton animation="wave" variant="text" height={200} />
                        </Grid>
                      ) : (
                        <>
                          {callWidgets?.map((item: ICallWidgets, key: number) => {
                            // if (item?.custom) {
                            return (
                              <Grid
                                item
                                md={3}
                                xs={6}
                                key={key}
                                // className="left-grid"
                                onClick={() => viewCallLogDetails(item)}
                              >
                                <OrderDetailCard
                                  isOldestOrder={false}
                                  orderStatus={item?.call_queue || ''}
                                  orderCount={item?.count || 0}
                                />
                              </Grid>
                            )
                            // }
                          })}
                        </>
                      )}
                    </Grid>
                  </div>
                  <div className={classes.titleAndProgressbarWrapper}>
                    <Typography variant="h4" className={classes.title}>
                      Tasks
                    </Typography>
                    <div className={classes.progressWrapper}>
                      {/* <TasksProgress className={classes.progressBar} /> */}
                      <DatePicker
                        label="Date"
                        style={{ width: '100%' }}
                        onDateSelected={onDateFilterChange}
                        value={sortSettings.date_filter ? moment(sortSettings.date_filter) : moment()}
                        placeholder={datePickerDatePlaceholder}
                        dataTestId="due-date"
                      />
                      <div style={{ marginTop: '2%', marginLeft: '2%' }}>
                        <CreateTask onFormSubmitted={reloadTables} />
                      </div>
                    </div>
                  </div>

                  <div className={classes.dashboardSection}>
                    <TasksSearch
                      onSearchQueryChange={onQueryChange}
                      onCompanyChange={onCompanyChange}
                      onClientChange={onClientChange}
                      onAssigneeChange={onAssigneeChange}
                    />
                    <HeadingProgress tableHeading="Daily Tasks" />

                    <DashboardPagedTable pagedTable={pagedTable} background="daily" />
                  </div>
                  <div className={classes.dashboardSection}>
                    <HeadingProgress tableHeading="Soft Tasks" />

                    <DashboardPagedTable pagedTable={pagedTableMedium} background="week" />
                  </div>
                  <div className={classes.dashboardSection}>
                    <HeadingProgress tableHeading="System Tasks" />

                    <DashboardPagedTable pagedTable={pagedTableCallBacks} background="callBack" />
                  </div>
                </div>
                <ModalWrapper
                  maxWidth={'lg'}
                  open={isTaxTable}
                  setOpen={() => setIsTaxTable(false)}
                  heading={taxWidgetDetail?.type === 'company' ? 'Companies' : 'Tax Service Orders'}
                >
                  <TaxOrderTable widgetData={taxWidgetDetail} />
                </ModalWrapper>
                <ModalWrapper
                  onClose={() => getCallWidgets()}
                  maxWidth={'lg'}
                  open={isCallTable}
                  setOpen={() => setIsCallTable(false)}
                  heading={'Call Logs Queue List'}
                >
                  <CallLogs widgetData={callWidgetDetails} />
                </ModalWrapper>
                <GlobalNotesDrawer
                  notesVisible={notesVisible}
                  setNotesVisible={setNotesVisible}
                  noteEntitiesContext={noteEntities}
                />
              </div>
            </SortSettingsContext.Provider>
          </TasksContext.Provider>
        </ToggleShowDoneTasksContext.Provider>
      </GlobalNotesContext.Provider>
    </TasksProgressContext.Provider>
  )
}

export interface OrderDetails {
  orderStatus: string
  orderCount: number
  orderDate: string
}

const orderDetails: OrderDetails[] = [
  {
    orderStatus: 'Order initiated',
    orderCount: 7,
    orderDate: '04/13/19 - 10:20 AM',
  },
  {
    orderStatus: 'filed by state',
    orderCount: 3,
    orderDate: '04/13/19 - 10:20 AM',
  },
  {
    orderStatus: 'filed by state, processing internally',
    orderCount: 12,
    orderDate: '04/13/19 - 10:20 AM',
  },
]
