import { requestPaginated } from '@jetkit/react'

export interface IUTMs {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  gclid: string
  fbclid: string
  msclkid: string
  sscid: string
}
export interface IVisitorLogs extends IUTMs {
  ip_address?: string
  visit_time?: string
  ref_code: string
  other_params: string
  brid?: string
}

export const fetchVisitorLogs = (brid: string, ip_address: string) =>
  requestPaginated<IVisitorLogs[]>({ url: `visitorlog?brid=${brid}&ip_address=${ip_address}` })
