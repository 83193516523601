import React from 'react'
import { Chip, IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import { LabelItemProps } from 'api/label'

interface LabelRowProps {
  labelItem: LabelItemProps
  onEditClick: () => void
  onDeleteClick: () => void
}

const LabelRow: React.FC<LabelRowProps> = ({ labelItem, onEditClick, onDeleteClick }) => {
  const handleEditIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onEditClick()
    },
    [onEditClick]
  )

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )
  return (
    <TableRow onClick={onEditClick} style={{ cursor: 'pointer' }} data-testid={`row-for-label-with-id-${1}`}>
      <TableCell style={{ minWidth: 120 }}>{labelItem?.name}</TableCell>
      {/* <TableCell style={{ maxWidth: 120 }}>{labelItem?.color}</TableCell> */}
      <TableCell style={{ maxWidth: 120 }}>
        <Chip label="" style={{ background: labelItem?.color, width: '32px' }} />
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>{labelItem?.type}</TableCell>
      <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleEditIconClick}>
            <EditIcon data-testid="editButton" />
          </IconButton>
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default LabelRow
