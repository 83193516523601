import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Collapse, IconButton, TablePagination, Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import InnerTableItem from './innerTableItem'
import EditIcon from '@material-ui/icons/EditOutlined'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import TodayIcon from '@material-ui/icons/Today'
import { ImportExport } from '@material-ui/icons'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import { Alert, Skeleton } from '@material-ui/lab'
import { ComplianceTasks, ComplianceTasksData } from 'api/complianceCalendar'
import moment from 'moment'
import { ComplianceColorResponse } from 'utils/constant'
import RocketIcon from '../../icons/rocket_icon.png'
import DoneIcon from '@material-ui/icons/Done'
import ScheduleIcon from '@material-ui/icons/Schedule'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      background: '#fff',

      '& .MuiTable-root': {
        background: '#fff',
      },

      '& .MuiTableHead-root': {
        background: '#eaeaed',
        borderTop: '10px solid #f47950',

        '& .MuiTableCell-root': {
          padding: '0.5rem',
          paddingLeft: '1rem',
        },

        '& .MuiTableCell-head': {
          color: '#22222299',
          fontWeight: 500,
          fontSize: '14px',
        },
      },

      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          padding: '0.5rem',
          paddingLeft: '1rem',
          fontWeight: 500,
          color: '#222222',
        },
      },
      '& .inline-cell': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '38px',
      },
    },

    formControl: {
      minWidth: '190px',
      width: '100%',

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },

      '& .select-filed': {
        background: '#eaecf0',
        padding: '0.2rem 1rem',
        paddingLeft: '0',
        color: '#22222299',
        fontWeight: 500,
        fontFamily: 'Roboto',

        '& .MuiSelect-select': {
          fontSize: '14px',
        },

        '& .MuiInputBase-root': {
          '& ::before': {
            border: 'none !important',
          },

          '& .MuiInputAdornment-root': {
            marginLeft: '-12px',
          },
        },

        '&:hover': {
          border: 'none',
        },
        '&::before': {
          border: 'none',

          '&:hover': {
            border: 'none !important',
          },
        },
      },
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
)

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '& .icon-cell': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .circle-icon': {
      color: '#ff0404',
    },
    '& .calendar-icon': {
      color: '#979797',
      marginRight: '2.25rem',
    },
    '& .edit-icon': {
      color: '#3c9fdf',
      cursor: 'pointer',
      borderBottom: '1px solid #3c9fdf',
    },
  },
})

function Row(props) {
  const { row, completeOrderHandler, companyCloseHandler, inProgressOrderHandler } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const responseColorHandler = (value: string) => {
    switch (value) {
      case '1':
        return ComplianceColorResponse[value]
      case '2':
        return ComplianceColorResponse[value]
      case '3':
        return ComplianceColorResponse[value]

      default:
        return 'gray'
    }
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row?.id}
        </TableCell>{' '}
        <TableCell>{row?.title}</TableCell>
        {/* <TableCell>{row?.details?.order_type?.name}</TableCell> */}
        <TableCell>
          <div className="icon-cell">
            {row?.details?.company_details?.name} {row?.rocket_compliance ? <img src={RocketIcon} /> : ''}
          </div>
        </TableCell>
        <TableCell width="200px" className="icon-cell" sortDirection="desc">
          <FiberManualRecordIcon className="circle-icon" style={{ color: responseColorHandler(row?.response) }} />
          {/* {formatDateToHumanFriendly(row?.due_date)} */}
          {moment.utc(row?.due_date).format('DD MMMM YYYY')}

          <TodayIcon className="calendar-icon" />
        </TableCell>
        {/* <TableCell>{row?.active_schedule}</TableCell>
         */}
        <TableCell>
          {row?.custom_schedules?.map((item, i) => (
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-around' }} key={i}>
              {item.template_id}
              {/* {item.template_id} <span style={{ fontStyle: 'italic', color: 'gray' }}>{item.schedule_status}</span> */}
              {item.schedule_status === 'Active' ? (
                <>
                  <Tooltip title="Active">
                    <ScheduleIcon color="primary" />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Completed">
                    <DoneIcon color="primary" />
                  </Tooltip>
                </>
              )}
              <br />
            </div>
          ))}
        </TableCell>
        <TableCell>{row?.details?.company_details?.client?.name}</TableCell>
        <TableCell width="100px" className="icon-cell">
          {/* <EditIcon className="edit-icon" onClick={() => setOpen(true)} /> */}
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <InnerTableItem
                taskDetails={row}
                inProgressBtn
                completeOrderHandler={completeOrderHandler}
                companyCloseHandler={companyCloseHandler}
                inProgressOrderHandler={inProgressOrderHandler}
              />
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

interface UpCommingTableProps {
  isLoadingTasks: boolean
  complianceTasks: ComplianceTasks | null
  completeOrderHandler: any
  companyCloseHandler: any
  inProgressOrderHandler: any
  page: number
  setPage: any
  rowsPerPage: number
  setRowsPerPage: any
  // allComplianceTasks: ComplianceTasksData[]
}

const UpCommingTable = ({
  complianceTasks,
  isLoadingTasks,
  completeOrderHandler,
  companyCloseHandler,
  inProgressOrderHandler,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: // allComplianceTasks,
UpCommingTableProps) => {
  const classes = useStyles()

  const [orderType, setOrderType] = useState('')
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrderType(event.target.value as string)
  }

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const dataCount = complianceTasks?.pagination?.total

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Task</TableCell>

            <TableCell>Company Name | SOF</TableCell>
            <TableCell width="auto" className="inline-cell">
              Due Date
              {/* &nbsp; <ImportExport /> */}
            </TableCell>
            <TableCell>Custom Schedule</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell width="100px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingTasks ? (
            <TableRow className={classes.root}>
              {[1, 2, 3, 4, 5]?.map(_ => (
                <TableCell component="th" scope="row">
                  <Skeleton variant="rect" width={'100%'} height={'50px'} />
                </TableCell>
              ))}
            </TableRow>
          ) : complianceTasks?.data?.length ? (
            complianceTasks?.data?.map((row: ComplianceTasksData) => (
              <Row
                key={row?.id}
                row={row}
                completeOrderHandler={completeOrderHandler}
                companyCloseHandler={companyCloseHandler}
                inProgressOrderHandler={inProgressOrderHandler}
              />
            ))
          ) : (
            <TableRow className={classes.root}>
              <TableCell>
                <Alert severity="info">No Task Found</Alert>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        colSpan={3}
        count={dataCount ? dataCount : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default UpCommingTable
