import React from 'react'
import { PopupButton } from 'react-calendly'

interface CalendlyModalProps {
  calendlyUrl: string
  btnText?: string
}

const CalendlyModal = ({ calendlyUrl, btnText }: CalendlyModalProps) => {
  return (
    <PopupButton
      className="schedule-call-modal-container"
      pageSettings={{
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideGdprBanner: true,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055',
      }}
      prefill={{}}
      styles={{}}
      text={btnText ? btnText : `SCHEDULE`}
      url={calendlyUrl}
    />
  )
}
CalendlyModal.default = {
  btnText: 'SCHEDULE',
}

export default CalendlyModal
