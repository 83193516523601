import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const formatPhoneNumberToInternational = (phoneNumber: string) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)
  return parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : phoneNumber
}

export const formatSSN = (ssn: string) => `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5, 4)}`

export const formatNaturalNumber = (val: string, length?: number) => {
  const re = /^[0-9]*$/
  if (val === '00') return '0' // prevent '00'
  if (val.length > 1 && val[0] === '0' && val[1] !== '0') return val.slice(1, val.length) // delete leading zero
  if (length && val.length > length) return val.slice(0, val.length - 1) // limit the input length
  if (!re.test(val[val.length - 1])) return val.slice(0, val.length - 1)
  return val
}

export const parseDigits = (value: string): number => parseInt(value.replace(/\D/g, ''))

export const parseNumberFromInput = (oldValue?: string, newValue?: string, float = false): string => {
  if (!newValue) return '0'

  const re = /(^[0-9]*){1}(\.|\.([0-9]{1,2})?)?$/
  const parsedOld: string = oldValue ? oldValue : '0'

  if (!re.test(newValue) || (!float && newValue.includes('.'))) return parsedOld
  else return newValue.replace(/^0+/, '') || '0'
}
