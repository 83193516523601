import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface IEmptyCircleCheckbox {
  style?: React.CSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 28,
      backgroundColor: theme.customPalette.whiteBackgroundColor,
      borderRadius: 1000,
      border: 'solid 1px #979797',
    },
  })
)

export default function EmptyCircleCheckbox({ style }: IEmptyCircleCheckbox) {
  const classes = useStyles()
  const customStyle = style || {}
  return (
    <div
      className={classes.root}
      style={{
        width: 28,
        height: 28,
        backgroundColor: '#f9f9f9',
        borderRadius: 1000,
        border: 'solid 1px #979797',
        ...customStyle,
      }}
    />
  )
}
