import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem',
      },
    },
    root: {
      overflow: 'hidden',
      paddingTop: '2rem',
      '& .MuiTextField-root': {
        marginBottom: '1rem',
      },
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'center',
    },

    uploadBtn: {
      color: '#fff',
      width: '200px',
      height: '50px',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
    },
  })
)
