import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'
import copyToClipboard from 'utils/copyToClipboard'
import CopyTextButton from './copyTextButton'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
)

interface ICopyTextFieldWrapper {
  valueToCopy?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  btnStyle?: React.CSSProperties
}

// A wrapper for components, that don't accept a TextField component as a prop, that allows to
// add a copy button to them. Button will be vertically centered and right-aligned.
const CopyTextFieldWrapper: React.FC<ICopyTextFieldWrapper> = ({ valueToCopy, style, children, btnStyle }) => {
  const classes = useStyles()
  const styles: React.CSSProperties = { ...btnStyle, position: 'absolute', marginLeft: '-0.9rem' }
  return (
    <div className={classes.wrapper} style={style}>
      {children}
      <CopyTextButton onClick={() => copyToClipboard(valueToCopy)} customStyles={styles} />
    </div>
  )
}

export default CopyTextFieldWrapper
