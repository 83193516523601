import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  mainWrapper: {
    minWidth: 935,
    flexDirection: 'column',
    padding: '37px 36px 26px 40px',
  },
  dataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftSideWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 6px)',
  },
  dueDatePicker: {
    width: '100%',
  },
  mainTitle: {
    fontFamily: 'Lato',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: '0.14px',
    color: '#020202',
    marginBottom: 7,
  },
  requiredOrdersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addButtonWrapper: {
    border: '1px solid #979797',
    borderRadius: 4,
    opacity: 0.9,
  },
  inputRoot: {
    height: 50,
    marginBottom: 13,
  },
  input: {
    height: 13,
  },
  requiredOrderRoot: {
    padding: 0,
    paddingRight: 8,
    '& > div': {
      '& > div': {
        height: 50,
      },
    },
  },
  priceRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 13,
  },
  priceInput: {
    marginRight: 9,
    width: 'calc(50% - 4.5px)',
  },
  commissionInput: {
    width: 'calc(50% - 4.5px)',
  },
  dateField: {
    margin: 0,
    marginTop: 13,
    '& > div': {
      '& > div': {
        height: 50,
      },
    },
  },

  saveButton: {
    width: 220,
    height: 45,
    marginTop: 8,
    alignSelf: 'center',
    justifySelf: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: -8,
    top: -8,
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
  },
})
