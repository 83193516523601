import React, { useState } from 'react'
import { useStyles } from './styles'
import ComplianceCalendar from './complianceCalendar'
import InProgressTask from './inProgressTask'
import UpCommingTask from './upCommingTask'

const UserComplianceCalendar = () => {
  const classes = useStyles()

  const [isUpdated, setIsUpdated] = useState(false)
  const [isUpCommingUpdated, setIsUpCommingUpdated] = useState(false)

  return (
    <div className={classes.container}>
      <ComplianceCalendar />
      <InProgressTask isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
      <UpCommingTask isUpdated={isUpCommingUpdated} setIsUpdated={setIsUpCommingUpdated} />
    </div>
  )
}

export default UserComplianceCalendar
