import { INotificationTemplate } from 'api/notifications'
import { ISuggestionAttribute } from 'components/interfaces'
import { legalTypesList } from 'models/company'

export const fakeTemplates: INotificationTemplate[] = [
  {
    id: 1,
    name: 'First Template',
  },
  {
    id: 2,
    name: 'Second Template',
  },
  {
    id: 1,
    name: 'Third Template',
  },
]

export interface ILegalTypes {
  value: number
  label: string
}

export const legalTypesLists: ILegalTypes[] = [
  {
    value: 1,
    label: 'LLC',
  },
  {
    value: 2,
    label: 'Corporation',
  },
  {
    value: 4,
    label: 'Nonprofit Organization',
  },
  {
    value: 5,
    label: 'DBA',
  },
  {
    value: 6,
    label: 'Sole Proprietorship',
  },
  {
    value: 7,
    label: 'Partnership',
  },
]

export const fakeFetchCompanyTypes = async (): Promise<ISuggestionAttribute[]> => {
  return legalTypesLists.map((type, index) => {
    return {
      value: type?.value,
      label: type?.label,
    }
  })
}

export const fakeFetchNotificationTemplates = async (): Promise<ISuggestionAttribute[]> => {
  return fakeTemplates.map(template => {
    return {
      value: template.id,
      label: template.name,
    }
  })
}
