import { IAddressErrors } from 'components/clients/create/clientDetails'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import { IAddress } from 'models/address'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
}

// hooks for handling address editing in AddAddressDialog component
const useAddressEdit = () => {
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)

  const [isAddAddressDialogOpen, setIsAddAddressDialogOpen] = React.useState<boolean>(false)

  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [addressFieldsErrors, setAddressFieldsErrors] = React.useState<IAddressErrors>({})

  const onAddressInputFieldsChange = (key: keyof IAddress) => (value: ValueOf<IAddress>) => {
    setCurrentEditedAddress(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const toggleShowAddAddressDialog = React.useCallback(() => {
    setCurrentEditedAddress(emptyAddress)
    setIsEditing(false)
    setIsAddAddressDialogOpen(prev => !prev)
    setAddressFieldsErrors({})
  }, [setIsAddAddressDialogOpen])

  const validateAddressFields = () => {
    // const { country, city, street, postal_code } = currentEditedAddress
    // const fieldsToValidate = { country, city, street, postal_code: postal_code }
    const { country, street, postal_code } = currentEditedAddress
    const fieldsToValidate = { country, street, postal_code: postal_code }
    setAddressFieldsErrors({
      country: country.length === 0,
      // city: city.length === 0,
      street: street.length === 0,
      postal_code: postal_code.length === 0,
    })
    return !Object.values(fieldsToValidate).some(string => string.length === 0)
  }

  return {
    currentEditedAddress,
    setCurrentEditedAddress,
    isAddAddressDialogOpen,
    setIsAddAddressDialogOpen,
    isEditing,
    setIsEditing,
    onAddressInputFieldsChange,
    toggleShowAddAddressDialog,
    validateAddressFields,
    addressFieldsErrors,
  }
}

export default useAddressEdit
