import AutocompleteFieldComponent from 'utils/FormGenerator/Fields/autocompleteField'
import DateFieldComponent from 'utils/FormGenerator/Fields/dateField'
import DateTimeFieldComponent from 'utils/FormGenerator/Fields/dateTimeField'
import NumberFieldComponent from 'utils/FormGenerator/Fields/numberField'
import TextFieldComponent from 'utils/FormGenerator/Fields/textField'
import { FieldType, IFormFieldProps } from 'utils/FormGenerator/formTypings'
import AddressFieldComponent from 'utils/FormGenerator/Fields/addressField'

const fieldTypeToRenderFieldFunctionMapping: Record<
  Exclude<FieldType, FieldType.custom>,
  (props: IFormFieldProps<any>) => JSX.Element
> = {
  [FieldType.text]: TextFieldComponent,
  [FieldType.number]: NumberFieldComponent,
  [FieldType.autocomplete]: AutocompleteFieldComponent,
  [FieldType.date]: DateFieldComponent,
  [FieldType.dateTime]: DateTimeFieldComponent,
  [FieldType.address]: AddressFieldComponent,
}

export default fieldTypeToRenderFieldFunctionMapping
