import React from 'react'
import UserComplianceCalendar from 'components/userComplianceCalendar'
import { updatePageVisitsCount } from 'utils/user'

const ComplianceCalendar: React.FC = () => {
  return (
    <>
      <UserComplianceCalendar />
    </>
  )
}

export default ComplianceCalendar
