import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import {
  createAccountantSuggestions,
  fetchAssigneeSuggestions,
  fetchSuggestions,
  getTaxYearFilterOptions,
} from 'api/suggestions'
import { getAccountants, getTaxLabels } from 'api/taxOrders'
import SearchBar from 'components/common/searchBar'
import MultiSelect from 'components/common/select/multiSelect'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import { ISuggestionAttribute } from 'components/interfaces'
import { ITaxStructureArr, TaxOrderStatuses, TaxServicesArr, TaxServicesOptions } from 'models/taxOrder'
import * as React from 'react'
import { TAX_ORDER_STAGES, TAX_ORDER_STAGES_NUM } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import { TaxOrderContext } from '../taxOrderContext'
import AsyncSelectField from 'components/common/select/index'
import AsyncSelect from 'react-select/async'
import SelectField from 'components/common/select'
import { useResources } from 'hooks/useResources'
import { fetchStaff } from 'api/staff'
import { IUser } from 'models/user'
import DefaultSelect from 'components/common/select/defaultSelect'
import { getStaffName } from 'api/complianceCalendar'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterRoot: {
      fontFamily: theme.typography.body1.fontFamily,
      // width: '100%',
      display: 'flex',
      flexDirection: 'column',
      // padding: '0.125rem 0 0.5rem 0',
      flexGrow: 1,
      // width: '200px',
      // maxWidth: '200px',

      '& .select-class': {
        padding: 0,

        '& .MuiInputBase-input': {
          padding: '0.1rem 0.5rem',
          minHeight: '2.1rem',
        },
      },
    },
    mainFiltersContainer: {
      width: '100%',
      display: 'flex',

      flexDirection: 'column',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      // marginRight: '1rem',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
    },
    filterTitle: {
      fontSize: 14,
    },
    searchBarContainer: {
      margin: '0.5rem 0 0.5rem 0',
    },
    checkBoxRoot: {
      padding: 0,
    },
    toggleButton: {
      height: '100%',
      width: '50%',
    },
    isFileButtonText: {
      fontSize: 12,
      color: 'grey',
    },
    innerMultiSelectContainer: {
      display: 'flex',
      width: '100%',
    },
    innerSwitchButtonsContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'end',
      flexWrap: 'wrap',

      width: '100%',
      marginTop: '1rem',
    },
    filterWithTitle: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    toggleButtonGroup: {
      height: 38,
      paddingTop: '0.125rem',
    },
  })
)

interface ITaxOrderTableFiltersProps {}

// make a list of ISuggestionAttribute elements from a list of order statuses
const getOrderStatusSuggestions = (): ISuggestionAttribute[] =>
  TaxOrderStatuses.options.map((status, i) => {
    return { value: i, label: status }
  })

const TaxOrderTableFilters: React.FC<ITaxOrderTableFiltersProps> = () => {
  const classes = useStyles() // styling
  const {
    searchOptions,
    handleAddFilter,
    handleSearch,
    handleFiledOnlyFilter,
    handleOrderTypeFilter,
    handleTaxOrderStatusOnlyFilter,
  } = React.useContext(TaxOrderContext)

  const [labels, setLabels] = React.useState<ISuggestionAttribute[]>([])

  const [accountants, setAccountants] = React.useState<ISuggestionAttribute[]>([])
  const [taxControllers, setTaxControllers] = React.useState<ISuggestionAttribute[]>([])
  const [seniorAccountants, setseniorAccountants] = React.useState<ISuggestionAttribute[]>([])

  const defaultStatus = React.useMemo(
    () => getOrderStatusSuggestions().filter(item => item.label === TaxOrderStatuses.default),
    []
  )

  React.useEffect(() => {
    const getTaxLabel = async () => {
      try {
        const response = await getTaxLabels()
        setLabels(response)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
    }
    getTaxLabel()
  }, [])

  React.useEffect(() => {
    const getTaxAccountant = async () => {
      try {
        const response = await getAccountants()
        const result: any[] = response?.map(acc => {
          return { value: acc?.id, label: acc?.name }
        })
        const newArray: any[] = [...result, { value: 'NONE', label: 'None' }]
        const newAccountArray: any[] = [...result, { value: -1, label: 'None' }]
        console.log('newArray', newArray)

        setAccountants(newArray)
        setseniorAccountants(newAccountArray)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
    }
    getTaxAccountant()
  }, [])

  React.useEffect(() => {
    const getTaxController = async () => {
      try {
        const response = await getStaffName()
        const result: any[] = response?.map(acc => {
          return { value: acc?.id, label: acc?.name }
        })
        const newArray: any[] = [...result, { value: -1, label: 'None' }]
        console.log('newArray', newArray)

        setTaxControllers(newArray)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
    }
    getTaxController()
  }, [])

  console.log('taxControllers', taxControllers)

  const yearSuggestions = React.useMemo(() => getTaxYearFilterOptions(), [])

  // const onSelectAccountant = option => {
  //   addForeignKeyAttribute('senior_cpa', option ? option.value : null)
  // }

  return (
    <div>
      <div className={classes.mainFiltersContainer}>
        {/* <div className={classes.innerMultiSelectContainer}> */}
        {/* <div className={classes.filterContainer}> */}
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>CPA</Typography>
              <MultiSelect
                addAttribute={handleAddFilter}
                title="CPA"
                field="accountant_user_id"
                // width="240px"
                width="100%"
                async={false}
                placeholder="Select CPA"
                defaultValue={[]}
                options={accountants}
              />
              {/* <Typography className={classes.filterTitle}>Tax Order Status:</Typography>
               <MultiSelect
                addAttribute={handleAddFilter}
                title="Status"
                field="order_statuses"
                width="240px"
                async={false}
                placeholder="Select Statuses"
                defaultValue={defaultStatus}
                options={getOrderStatusSuggestions()}
              /> */}
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Tax Year:</Typography>
              <MultiSelect
                addAttribute={handleAddFilter}
                title="Tax Year"
                field="tax_year"
                // width="200px"
                width="100%"
                async={false}
                placeholder="Select Tax Years"
                defaultValue={[]}
                options={yearSuggestions}
              />
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Label:</Typography>
              <MultiSelect
                addAttribute={handleAddFilter}
                title="Label"
                field="label"
                // width="220px"
                width="100%"
                async={false}
                placeholder="Select Label"
                defaultValue={[]}
                options={labels}
              />
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Stage:</Typography>
              {/* <SelectAutocomplete
                // addAttribute={handleAddFilter}
                handleChange={val => handleAddFilter('stage', { label: val, value: val })}
                label="Stage"
                // field="label"
                // style={{ width = '300px' }}
                containerStyles={{ width: '300px' }}
                options={Object.values(TAX_ORDER_STAGES).map(item => ({ label: item, value: item }))}
              /> */}
              {/* <AsyncSelectField
                onOptionSelected={val => handleAddFilter('stage', { label: val, value: val })}
                title="Label"
                // field="label"
                // width="300px"
                // async={false}
                placeholder="Select Stage"
                // defaultValue={[]}
                defaultOptions={Object.values(TAX_ORDER_STAGES).map(item => ({ label: item, value: item }))}
              /> */}
              <div className={classes.filterRoot}>
                {/* <AsyncSelect
                  key={1}
                  isClearable
                  // inputValue={inputValue}
                  // styles={selectStyles}
                  defaultOptions={Object.values(TAX_ORDER_STAGES).map(item => ({ label: item, value: item }))}
                  // loadOptions={promiseOptions}
                  onChange={val => handleAddFilter('stage', val)}
                  // components={components}
                  placeholder={'Select Stage'}
                  // maxMenuHeight={160}
                  cacheOptions={false}
                  // defaultValue={defaultValue}
                  // we are doing that to have 'managed' input field (pass in null)
                  // value={value}
                /> */}

                <MultiSelect
                  addAttribute={handleAddFilter}
                  title="Select Stage"
                  field="stage"
                  // width="220px"
                  width="100%"
                  async={false}
                  placeholder="Select Select Stage"
                  defaultValue={[]}
                  options={Object.values(TAX_ORDER_STAGES).map((item: any) => ({ label: item, value: item }))}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Question Status</Typography>
              <div className={classes.filterRoot}>
                <AsyncSelect
                  key={1}
                  isClearable
                  // inputValue={inputValue}
                  // styles={selectStyles}
                  defaultOptions={['Answered', 'Unanswered'].map(item => ({ label: item, value: item }))}
                  // loadOptions={promiseOptions}
                  onChange={val => handleAddFilter('question', val)}
                  // components={components}
                  placeholder={'Select Status'}
                  // maxMenuHeight={160}
                  cacheOptions={false}
                  // defaultValue={defaultValue}
                  // we are doing that to have 'managed' input field (pass in null)
                  // value={value}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Tax Structure</Typography>
              <div className={classes.filterRoot}>
                <AsyncSelect
                  key={1}
                  isClearable
                  // inputValue={inputValue}
                  // styles={selectStyles}
                  // defaultOptions={Object.values(TaxServicesOptions).map(val => ({ value: val, label: val }))}
                  defaultOptions={TaxServicesArr.map((val: ITaxStructureArr) => ({
                    value: val?.value,
                    label: val?.label,
                  }))}
                  // loadOptions={promiseOptions}
                  onChange={val => handleAddFilter('tax_structure', val)}
                  // components={components}
                  placeholder={'Tax Structure'}
                  // maxMenuHeight={160}
                  cacheOptions={false}
                  // defaultValue={defaultValue}
                  // we are doing that to have 'managed' input field (pass in null)
                  // value={value}
                />
              </div>
            </div>
          </Grid>

          {/* </div> */}
          {/* </div> */}
          {/* <div className={classes.innerSwitchButtonsContainer}> */}
          {/* <Grid container spacing={2}> */}
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Service Type</Typography>
              <div className={classes.filterRoot}>
                <AsyncSelect
                  key={1}
                  isClearable
                  // inputValue={inputValue}
                  // styles={selectStyles}
                  defaultOptions={serviceType}
                  // loadOptions={promiseOptions}
                  onChange={val => handleAddFilter('service_type', val)}
                  // components={components}
                  placeholder={'Select Service'}
                  // maxMenuHeight={160}
                  cacheOptions={false}
                  // defaultValue={defaultValue}
                  // we are doing that to have 'managed' input field (pass in null)
                  // value={value}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Senior Accountant</Typography>
              <div className={classes.filterRoot}>
                {/* <AsyncSelect
                key={1}
                isClearable
                defaultOptions={accountants}
                // loadOptions={promiseOptions}
                onChange={option => handleAddFilter('senior_cpa', (option && option.value) || undefined)}
                // components={components}
                placeholder={'Senior Accountant'}
                // maxMenuHeight={160}
                cacheOptions={false}
                // defaultValue={defaultValue}
                // we are doing that to have 'managed' input field (pass in null)
                // value={value}
              /> */}
                {/* <AsyncSelectField
                title="Senior Accountant"
                fetchSuggestions={(query, _) => fetchSuggestions(query, 'senior_cpa')}
                field="senior_cpa"
                onOptionSelected={val => handleAddFilter('senior_cpa', val)}
                placeholder="Senior Accountant"
                className="select-class"
              /> */}

                <DefaultSelect
                  // addAttribute={(query, _) => fetchSuggestions(query, 'senior_cpa')}
                  addAttribute={handleAddFilter}
                  title="Senior Accountant"
                  field="senior_cpa"
                  // width="240px"
                  width="100%"
                  placeholder="Senior Accountant"
                  options={seniorAccountants}
                />

                {/* <SelectField
                onOptionSelected={option => handleAddFilter('senior_cpa', (option && option.value) || undefined)}
                title="Senior Accountant"
                label="Senior Accountant"
                fetchSuggestions={fetchSuggestions}
                field="senior_cpa"
                placeholder="Senior Accountant"
                // defaultValue={
                //   taxOrder.senior_accountant && taxOrder.senior_accountant.id
                //     ? {
                //         value: taxOrder.senior_accountant.id,
                //         label: `${taxOrder.senior_accountant.first_name} ${taxOrder.senior_accountant.last_name}`,
                //       }
                //     : undefined
                // }
              /> */}
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Tax Associate</Typography>
              <div className={classes.filterRoot}>
                {/* <AsyncSelectField
                title="Tax Associate"
                fetchSuggestions={(query, _) => fetchAssigneeSuggestions(query)}
                field="tax_associate"
                onOptionSelected={val => handleAddFilter('tax_associate', val)}
                placeholder="Tax Associate"
                className="select-class"
              /> */}

                <DefaultSelect
                  addAttribute={handleAddFilter}
                  title="Tax Associate"
                  field="tax_associate"
                  // width="240px"
                  width="100%"
                  placeholder="Tax Associate"
                  options={taxControllers}
                />
                {/* <AsyncSelect
                key={1}
                isClearable
                // inputValue={inputValue}
                // styles={selectStyles}
                // defaultOptions={Object.values(TaxServicesOptions).map(val => ({ value: val, label: val }))}
                defaultOptions={TaxServicesArr.map((val: ITaxStructureArr) => ({
                  value: val?.value,
                  label: val?.label,
                }))}
                // loadOptions={promiseOptions}
                onChange={val => handleAddFilter('tax_associate', val)}
                // components={components}
                placeholder={'Tax Associate'}
                // maxMenuHeight={160}
                cacheOptions={false}
                // defaultValue={defaultValue}
                // we are doing that to have 'managed' input field (pass in null)
                // value={value}
              /> */}
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Tax Controller</Typography>
              <div className={classes.filterRoot}>
                {/* <AsyncSelectField
                title="Tax Controller"
                fetchSuggestions={(query, _) => fetchAssigneeSuggestions(query)}
                field="tax_associate"
                onOptionSelected={val => handleAddFilter('tax_controller', val)}
                placeholder="Tax Controller"
                className="select-class"
              /> */}
                <DefaultSelect
                  addAttribute={handleAddFilter}
                  title="Tax Controller"
                  field="tax_controller"
                  // width="240px"
                  width="100%"
                  placeholder="Tax Controller"
                  options={taxControllers}
                />
                {/* <AsyncSelect
                key={1}
                isClearable
                // inputValue={inputValue}
                // styles={selectStyles}
                // defaultOptions={Object.values(TaxServicesOptions).map(val => ({ value: val, label: val }))}
                defaultOptions={staff.map((val: IUser) => ({
                  value: val?.id,
                  label: val?.name,
                }))}
                // loadOptions={promiseOptions}
                onChange={val => handleAddFilter('tax_controller', val)}
                // components={components}
                placeholder={'Tax Controller'}
                // maxMenuHeight={160}
                cacheOptions={false}
                // defaultValue={defaultValue}
                // we are doing that to have 'managed' input field (pass in null)
                // value={value}
              /> */}
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Tax Order Type:</Typography>
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                onChange={handleOrderTypeFilter}
                exclusive
                value={searchOptions.tax_order_type}
              >
                <ToggleButton value="all" className={classes.toggleButton}>
                  <Typography className={classes.isFileButtonText}>All</Typography>
                </ToggleButton>
                <ToggleButton value="business" className={classes.toggleButton}>
                  <Typography className={classes.isFileButtonText}>Business</Typography>
                </ToggleButton>
                <ToggleButton value="personal" className={classes.toggleButton}>
                  <Typography className={classes.isFileButtonText}>Personal</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
          {/* </Grid> */}

          {/* <div className={classes.filterWithTitle} >
            <Typography className={classes.filterTitle}>Extension Status:</Typography>
            <ToggleButtonGroup
              className={classes.toggleButtonGroup}
              onChange={handleFiledOnlyFilter}
              exclusive
              value={searchOptions.is_filed}
            >
              <ToggleButton value="all" className={classes.toggleButton}>
                <Typography className={classes.isFileButtonText}>All</Typography>
              </ToggleButton>
              <ToggleButton value="true" className={classes.toggleButton}>
                <Typography className={classes.isFileButtonText}>Filed</Typography>
              </ToggleButton>
              <ToggleButton value="false" className={classes.toggleButton}>
                <Typography className={classes.isFileButtonText}>Not Filed</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </div> */}
          <Grid item lg={3} md={4} xs={12}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Tax Order Status:</Typography>
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                onChange={handleTaxOrderStatusOnlyFilter}
                exclusive
                value={searchOptions.tax_order_status}
              >
                <ToggleButton value="open" className={classes.toggleButton}>
                  <Typography className={classes.isFileButtonText}>Open</Typography>
                </ToggleButton>
                <ToggleButton value="archived" className={classes.toggleButton}>
                  <Typography className={classes.isFileButtonText}>Archived</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>
        {/* </div> */}
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar onChange={handleSearch} placeholder="Search by company or client name" />
      </div>
    </div>
  )
}

export default TaxOrderTableFilters

const serviceType = [
  { value: 'bookkeeping', label: 'Book Keeping' },
  { value: 'tax', label: 'Tax' },
]
