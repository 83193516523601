import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'order_id', label: 'Submision ID', style: {}, sortable: true },
  { id: 'company_subscription_id', label: 'Subscription ID', style: {}, sortable: true },
  { id: 'company_id', label: 'Company ID', style: {}, sortable: true },
  { id: 'company_name', label: 'Company Name', style: {}, sortable: false },
  { id: '', label: 'Order Type', style: {}, sortable: false },
  { id: 'decline_code', label: 'Decline Code', style: {}, sortable: false },
  { id: 'error_text', label: 'Error Message', style: {}, sortable: false },
  { id: 'created_at', label: 'Created Date', style: {}, sortable: true },
  { id: '', label: 'Action', style: {}, sortable: false },
]

const LogsHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default LogsHead
