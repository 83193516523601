import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'field_name', label: 'Field Name', style: {}, sortable: true },
  { id: 'old_value', label: 'Old Value', style: {}, sortable: true },
  { id: 'new_value', label: 'New Value', style: {}, sortable: true },
  { id: 'changed_by', label: 'Change By', style: {}, sortable: true },
  { id: 'changed_at', label: 'Change at', style: {}, sortable: true },
]

const LogsHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default LogsHead
