// eslint-disable-next-line max-lines
import {
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  ListItem,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { deleteAddress, editAddress } from 'api/addresses'
import { createClient, updateClient } from 'api/clients'
import { fetchSuggestions } from 'api/suggestions'
import CommissionAccordion from 'components/clients/commissions/accordion'
import EinSsnInput from 'components/clients/create/einSsnInput'
import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import showSuccessNotification from 'utils/showSuccessNotification'
import { checkRefcode, checkUsername, resetPassword } from 'api/users'

import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { validateEmail } from 'utils/validators'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { ISuggestionAttribute } from 'components/interfaces'
import { PrimaryAddress } from 'components/companies/companyCreation/firstStep/PrimaryAddress'
// import { useState } from 'react-transition-group/node_modules/@types/react'
import { IInputFieldsErrors } from 'components/companies/create'
import { MailingAddress } from 'components/companies/companyCreation/firstStep/MailingAddress'
import LinearProgress from '@material-ui/core/LinearProgress'
import { toTitleCase } from '@jetkit/react'
import { Close } from '@material-ui/icons'
import showErrorNotification from 'utils/showErrorNotification'
import { FIELDS_MIN_LENGTH, PERSONAL_TAX_STRUCTURE } from 'utils/constant'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import { clientFactory } from 'utils/factories/user'
import AddProspectModal from 'components/leadManagerElements/addProspectModal'
import ModalTitle from 'components/common/modalTitle'
import Client from 'pages/client'
import LogsTable from '../logsTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    HelperTextUsername: {
      color: '#3c9fdf', //'rgba(0, 0, 0, 0.54)',//theme.customPalette.errorText,
      margin: '8px 14px 0',
      fontSize: '13px',
    },
    RegsiteredAgentText: {
      paddingLeft: 0,
      fontSize: 20,
      cursor: 'pointer',
      margin: '6px 0',
    },

    backClientBtn: {
      position: 'absolute',
      top: 15,
      right: 75,
    },
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '100%',
      },
      //   width: '25%',
    },
    root: {
      overflow: 'hidden',
      paddingTop: '2rem',
      '& .MuiTextField-root': {
        marginBottom: '1rem',
        width: '100%',
      },
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '93%',
      },
      padding: theme.spacing(2),
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'center',
    },

    uploadBtn: {
      color: '#fff',
      width: '200px',
      height: '50px',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
    },
    durationText: {
      color: 'grey',
      fontSize: '12px',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
  locality?: boolean
}

const newClient: IClient = {
  extid: '',
  name: '',
  email: '',
  ssn_ein: undefined,
  dob: undefined,
  sales_rep: undefined,
  sales_rep_id: undefined,
  affiliate_id: undefined,
  affiliate: undefined,
  phone_number: undefined,
  addresses: [],
  user_type: 'client',
  middle_name: '',
  last_name: '',
  username: '',
  is_registered_agent: false,
  ref_code: '',
  commission_percentage: undefined,
  partner_id: undefined,
  tax_structure: '',
}

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

export interface IClientDetailsProps {
  editedClient?: IClient
  initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedClient) => void
  title?: string
  fromProspect?: boolean
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

// Because date returned from backend, is in different format, that existing validator accepts,
// we format it properly first
const extractDateFromClient = (client: IClient): string | undefined => {
  if (!client.dob) return undefined
  return client?.dob
  // return backendDateStringToFrontendFormat(client.dob)
}

const validateName = (client: IClient) => {
  return !(!client.name || client.name.trim().length < 2)
}

const validateAddress = (client: IClient): boolean => {
  const { addresses } = client
  if (addresses && addresses.length) {
    if (!addresses.find(address => address.is_primary === true)) return false
  }
  return true
}

const validateDob = (client: IClient) => {
  if (!client.dob) return true
  const selectedDate = dateFromString(client.dob)
  if (!selectedDate.isValid() || selectedDate.isAfter(moment())) {
    return false
  }
  return true
}

// Backend accepts addresses with `id` field and unprocessable entities
const addressesWithoutIds = (client: IClient): IAddress[] | undefined => {
  if (!client.addresses) return undefined
  return client.addresses.map(address => {
    delete address.id
    return {
      ...address,
      city: address?.cityObj?.label || '',
      city_id: address?.cityObj?.value || 0,
    }
  })
}

const updateClientRequest = async (client: IClient) => {
  const editedClient = client

  const newAddress: IAddress[] | undefined = editedClient?.addresses?.map((item: IAddress) => {
    if (item?.cityObj?.value) {
      return {
        ...item,
        city: item?.cityObj?.label || '',
        city_id: item?.cityObj?.value || 0,
      }
    } else {
      return item
    }
  })
  //
  const resClient = await updateClient({
    // We pick certain fields from editedClient object, because client,
    // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
    // if we send them to PATCH `user/:id`
    extid: editedClient.extid,
    id: editedClient.id,
    name: editedClient.name,
    email: editedClient.email,
    ssn_ein: editedClient.ssn_ein,
    dob: editedClient.dob,
    phone_number: editedClient.phone_number || '',
    user_type: editedClient.user_type,
    // addresses: editedClient.addresses,
    addresses: newAddress,
    sales_rep_id: editedClient.sales_rep_id,
    affiliate_id: editedClient.affiliate_id,
    middle_name: editedClient.middle_name,
    last_name: editedClient.last_name,
    // username: editedClient.username,
    password: editedClient.password,
    is_registered_agent: editedClient.is_registered_agent,
    ref_code: editedClient.ref_code || '',
    commission_percentage: editedClient.commission_percentage,
    tax_structure: editedClient.tax_structure,
    partner_id: editedClient.partner_id,
  })
  return resClient
}

const createClientRequest = async (client: IClient) => {
  const resClient = await createClient({ ...client, addresses: addressesWithoutIds(client) })
  return resClient
}

const addressesWithNewPrimary = (addresses: IAddress[], newPrimaryAddress: IAddress | null): IAddress[] =>
  addresses.map((address: IAddress) => {
    if (newPrimaryAddress && address.id === newPrimaryAddress.id) {
      address.is_primary = true
    }
    if (!newPrimaryAddress || (address.id !== newPrimaryAddress.id && address.is_primary)) {
      address.is_primary = false
    }
    return address
  })

const ClientDetails: React.FC<IClientDetailsProps> = ({
  editedClient,
  onRequestSuccessful,
  initialClient,
  title,
  fromProspect,
}) => {
  const formMode: FormMode = editedClient && editedClient.id ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient, addresses: initialClient }
  const [client, setClient] = React.useState<IClient>(
    editedClient
      ? {
          ...editedClient,
          // addresses: initialClient,
          dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [addressesToDelete, setAddressesToDelete] = React.useState<number[]>([])
  const [idtoSend, setidtoSend] = React.useState<number>(0)

  const { history } = useRouter()

  const addAttribute = React.useCallback(
    <K extends keyof Pick<IClient, 'sales_rep_id' | 'affiliate_id'>>(field: K, id: IClient[K]) => {
      setClient({
        ...client,
        [field]: id,
      })
    },
    [setClient, client]
  )

  const toggleAddAddressDialog = React.useCallback(() => {
    setShowAddAddressDialog(!showAddAddressDialog)
    setCurrentEditedAddress(emptyAddress)
  }, [setShowAddAddressDialog, showAddAddressDialog])
  const [usernameChanged, setUsernameChanged] = React.useState<boolean>(false)
  const [refcodeChanged, setRefcodeChanged] = React.useState<boolean>(false)

  const changeClientField = React.useCallback(
    async (field: keyof IClient, value: ValueOf<IClient>) => {
      field === 'username' ? setUsernameChanged(true) : setUsernameChanged(false)
      field === 'ref_code' ? setRefcodeChanged(true) : setRefcodeChanged(false)
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )
  const changeDropDownField = React.useCallback(
    async (field: keyof IClient, valueAtt: ISuggestionAttribute | null) => {
      if (field && (field === 'partner' || field === 'affiliate' || field === 'sales_rep')) {
        setClient({
          ...client,
          [`${field}_id`]: valueAtt ? valueAtt.value : null,
          [field]: { name: valueAtt?.label, id: valueAtt?.value },
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )
  const validateFields = React.useCallback((): boolean => {
    ////
    let isValid = true
    const tmpHelperTexts = helperTexts
    if (!validateName(client)) {
      clientEditErrors.setError('name', true)
      isValid = false
    }
    // if (!client.email || !validateEmail(client.email)) {
    //   clientEditErrors.setError('email', true)
    //   tmpHelperTexts.email = 'Please Specify Email'
    //   isValid = false
    // }
    if (client.dob && client.dob !== '' && !validateDob(client)) {
      clientEditErrors.setError('dob', true)
      isValid = false
    }
    if (!validateAddress(client)) {
      clientEditErrors.setError('no_primary_address', true)
      isValid = false
    }
    if (client.password === null || client.password === '' || client.password === undefined) {
      // clientEditErrors.setError('password', true)
      // isValid = false
    }
    if (client.username === null || client.username === '' || client.username === undefined) {
      // clientEditErrors.setError('username', true)
      // isValid = false
    }
    if (errorUsername) {
      isValid = false
    }
    setHelperTexts(tmpHelperTexts)
    return isValid
  }, [helperTexts, client, clientEditErrors])

  const sendAddressDeletionRequests = React.useCallback(() => {
    addressesToDelete.forEach(async addressId => {
      try {
        deleteAddress(addressId)
      } catch (err) {
        showApiResponseError(err, `Failed to delete address ${addressId}`)
      }
    })
  }, [addressesToDelete])

  const sendAddressUpdateRequests = React.useCallback(() => {
    addressesToEdit.forEach(async address => {
      try {
        const cleanedUpAddress: IAddress = removeNullAndUndefined(address) as IAddress
        await editAddress(cleanedUpAddress)
      } catch (err) {
        showApiResponseError(err, `Failed to update address ${address.id}`)
      }
    })
  }, [addressesToEdit])

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    if (!validateFields()) {
      return false
    }

    try {
      // let res: any

      // if (client.username && client.username?.length > FIELDS_MIN_LENGTH.username) {
      //   setLoading(true)
      //   setUsernameFieldDisabled(true)

      //   res = await checkUsername({ username: client?.username || '' })
      //   debugger
      //   setLoading(false)
      //   setUsernameFieldDisabled(false)

      //   if (res.error && existingUsername !== client.username) {
      //     setErrorusername(true)
      //     setErrorusernameText('Sorry! username already exists!')

      //     return false
      //   }
      // }
      setErrorusername(false)

      try {
        let clientResponse: ICreatedClient
        if (formMode === FormMode.UPDATE) {
          clientResponse = await updateClientRequest(client)
        } else {
          clientResponse = await createClientRequest(client)
          setidtoSend(clientResponse.id)
          // When creating a new client, the attached addresses are without client ids.
          // This prevents us from sending a request to create a new address and link it with the client.
          const addressesToCreateWithClientIds = addressesToAdd
          addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
          setAddressesToAdd(addressesToCreateWithClientIds)
        }

        if (addressesToDelete.length) {
          sendAddressDeletionRequests()
        }
        // if (addressesToEdit.length) {
        //   sendAddressUpdateRequests()
        // }

        showSuccessNotification('Client successfully update')

        const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
        // const updatedClient =  clientResponse
        // if (formMode === FormMode.UPDATE) setClient(clientResponse)
        // else
        setClient({
          ...client,
          id: clientResponse.id,
        })
      } catch (err) {
        const errorMessage: string | undefined = err?.response?.data?.message
        const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
        const tmpHelperTexts = {
          email: '',
          ssn_ein: '',
        }
        if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
          if (errorMessage.includes('email')) {
            clientEditErrors.setError('email', true)
            tmpHelperTexts.email = 'This email is already in use'
          }
          if (errorMessage.includes('ssn_ein')) {
            clientEditErrors.setError('ssn_ein', true)
            tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
          }
          setHelperTexts(tmpHelperTexts)
          return false
        }
        showApiResponseError(err, 'Could not save changes')
        return false
      }
    } catch (e) {
      showApiResponseError(e, 'Error Checking username')
    }

    // try {
    //   let clientResponse: ICreatedClient
    //   if (formMode === FormMode.UPDATE) {
    //     clientResponse = await updateClientRequest(client)
    //   } else {
    //     clientResponse = await createClientRequest(client)
    //     setidtoSend(clientResponse.id)
    //     // When creating a new client, the attached addresses are without client ids.
    //     // This prevents us from sending a request to create a new address and link it with the client.
    //     const addressesToCreateWithClientIds = addressesToAdd
    //     addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
    //     setAddressesToAdd(addressesToCreateWithClientIds)
    //   }

    //   if (addressesToDelete.length) {
    //     sendAddressDeletionRequests()
    //   }
    //   // if (addressesToEdit.length) {
    //   //   sendAddressUpdateRequests()
    //   // }

    //   showSuccessNotification('Client successfully update')

    //   const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
    //   // const updatedClient =  clientResponse
    //   // if (formMode === FormMode.UPDATE) setClient(clientResponse)
    //   // else
    //   setClient({
    //     ...client,
    //     id: clientResponse.id,
    //   })
    // } catch (err) {
    //   const errorMessage: string | undefined = err?.response?.data?.message
    //   const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
    //   const tmpHelperTexts = {
    //     email: '',
    //     ssn_ein: '',
    //   }
    //   if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
    //     if (errorMessage.includes('email')) {
    //       clientEditErrors.setError('email', true)
    //       tmpHelperTexts.email = 'This email is already in use'
    //     }
    //     if (errorMessage.includes('ssn_ein')) {
    //       clientEditErrors.setError('ssn_ein', true)
    //       tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
    //     }
    //     setHelperTexts(tmpHelperTexts)
    //     return false
    //   }
    //   showApiResponseError(err, 'Could not save changes')
    //   return false
    // }
    return true
  }, [
    validateFields,
    formMode,
    addressesToDelete.length,
    addressesToEdit.length,
    client,
    addressesToAdd,
    sendAddressDeletionRequests,
    sendAddressUpdateRequests,
    clientEditErrors,
  ])
  React.useEffect(() => {
    // console.log('clientchange', idtoSend)
  }, [idtoSend])
  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedClient = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const removeAddressFieldsErrors = React.useCallback((key: AddressError) => addressEditErrors.setError(key, false), [
    addressEditErrors,
  ])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      removeAddressFieldsErrors(key as AddressError)
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
        city: currentEditedAddress?.cityObj?.label || '',
        city_id: currentEditedAddress?.cityObj?.value || 0,
      })
    },
    [removeAddressFieldsErrors, setCurrentEditedAddress, currentEditedAddress]
  )

  // We cannot really use `useMemo()` here, because it will cause error messages to appear once
  // we open addressEditionDialog. The reason for that is, that once we open it, fields are empty.
  // Empty fields are not valid for a request, but are OK for initial state of dialog or any other moment,
  // while editing these fields. Address errors must be set if necessary only when submit button is clicked,
  // `useCallback()` fits this purpose. Using `useMemo()` causes errors to be set immediately,
  // and to be set always, when a field is empty.
  const validateNewAddressFields = React.useCallback(() => {
    // const { country, city, street, postal_code } = currentEditedAddress
    // const fieldsToValidate = { country, city, street, postal_code }
    const { country, street, postal_code, locality, city } = currentEditedAddress
    const fieldsToValidate = { country, street, postal_code, locality, city }

    addressEditErrors.setError('country', country.length === 0)
    addressEditErrors.setError('city', city.length === 0)
    addressEditErrors.setError('locality', locality.length === 0)
    addressEditErrors.setError('street', street.length === 0)
    addressEditErrors.setError('postal_code', postal_code.length === 0)
    return !Object.values(fieldsToValidate).some(string => string.length === 0)
  }, [currentEditedAddress, addressEditErrors])

  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    const { addresses } = client
    const indexToUpdate = addresses && addresses.findIndex(address => address.id === editedAddressId)
    // checks for zero inequality
    // checks for zero inequality
    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(client.addresses || [])]
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setClient({
      ...client,
      addresses: addressesWithUpdatedAddress,
    })
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    setCurrentEditedAddress(emptyAddress)
    toggleAddAddressDialog()
  }, [editedAddressId, client, toggleAddAddressDialog, addressesToEdit, setAddressesToEdit, currentEditedAddress])

  const handleAddAddress = React.useCallback(() => {
    if (!validateNewAddressFields()) return false

    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      // in case new address is a primary address, reset the current primary address
      const newAddresses: IAddress[] = client.addresses ? deepcopy(client.addresses) : []
      if (currentEditedAddress.is_primary && client.addresses) {
        const primaryAddress = newAddresses.find(address => address.is_primary)
        if (primaryAddress) {
          primaryAddress.is_primary = false
        }
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
      }
      currentEditedAddress.id = client.addresses && client.addresses.length ? client.addresses.length * -1 : 0
      newAddresses.push(currentEditedAddress)
      setClient({ ...client, addresses: newAddresses })

      currentEditedAddress.client_id = client.id

      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd, currentEditedAddress])
      toggleAddAddressDialog()
    }
    return true
  }, [
    validateNewAddressFields,
    editedAddressId,
    updateAddress,
    client,
    currentEditedAddress,
    addressesToAdd,
    toggleAddAddressDialog,
    clientEditErrors,
  ])

  const handlePrimaryAddressChange = React.useCallback(
    (address: IAddress) => (checked: boolean) => {
      if (checked) {
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
        const addresses = addressesWithNewPrimary(client.addresses || [], address)
        setClient({ ...client, addresses: addresses })

        // setClient({ ...client, addresses: [...addresses, ...(initialClient || [])] })
      }
    },
    [clientEditErrors, client]
  )

  const handleCompanyAddressChange = React.useCallback(
    (newAddressId: number | null): void => {
      const addressArray = initialClient || []
      const selectedAddress = addressArray.find(address => address.id === newAddressId)
      if (selectedAddress) setClient({ ...client, addresses: [selectedAddress] })
    },
    [client, initialClient]
  )

  const handlePrimaryAddressChecked = React.useCallback(
    (checked: boolean) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        is_primary: checked,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setClient({ ...client, addresses: client.addresses && client.addresses.filter(address => address.id !== id) })
      if (id > 0) setAddressesToDelete([...addressesToDelete, id])
    },
    [setClient, setAddressesToDelete, addressesToDelete, client]
  )

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((client.addresses && client.addresses.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [setEditedAddressId, setCurrentEditedAddress, setShowAddAddressDialog, client.addresses]
  )

  // Here we navigate to companies page and pass this client object there.
  // If that component will find this object on `router.history.location.state`,
  // company creation flow dialog will be opened automatically with prefilled client field.
  const createCompany = React.useCallback(() => {
    history.push('/companies', { client })
  }, [client, history])

  // const createCompany1 = React.useCallback((id) => {
  //   //client.id=id;
  //   let temp:any = client;
  //   temp.id = idtoSend
  //   setClient(temp)
  //   // if(client.id){
  //   console.log('clientosend',idtoSend ,client);

  //   history.push('/companies', { client })
  //   // }
  // }, [client, history])

  const createCompany1 = id => {
    //client.id=id;
    // if(client.id){

    history.push('/companies', { client })
    // }
  }

  React.useEffect(() => {
    if (idtoSend > 0) {
      const temp: any = client
      temp.id = idtoSend
      setClient(temp)
    }
  }, [idtoSend])
  const addclientandAssignComp = async () => {
    const data: any = await onSubmitClick()
    if (data === true) {
      createCompany1(data.id)
    }
  }

  const navigateToAssociateCompanies = React.useCallback(() => {
    // history.push(`/companies?query=${encodeURI(client.name)}`)
    history.push(
      `/companies?client_id=${client.id}&client_name=${encodeURI(client.name)} ${encodeURI(client.last_name || '')}`
    )
  }, [client.name, history])

  const classes = useStyles()

  const { country, city, street, postal_code, locality } = addressEditErrors

  const salesRepresentative: ISuggestionAttribute | undefined = useMemo(
    () => (client?.sales_rep?.id ? { label: client.sales_rep.name, value: client.sales_rep.id } : undefined),
    [client]
  )

  const affiliate: ISuggestionAttribute | undefined = useMemo(
    () =>
      client?.affiliate?.id
        ? {
            label: client.affiliate.name,
            value: client.affiliate.id,
          }
        : undefined,
    [client]
  )
  const partner: ISuggestionAttribute | undefined = useMemo(
    () =>
      client?.partner?.id
        ? {
            label: client.partner.name,
            value: client.partner.id,
          }
        : undefined,
    [client]
  )

  // const arrayAddress = initialClient?.map(item => {
  //   return item
  // })

  // const newArray = client.addresses
  React.useEffect(() => {
    //const tempMail: string = client.email.toString()
    if (client) {
      // if (client.email?.length >= 5) {
      //   changeClientField('username', client.name + Math.floor(100000 + Math.random() * 900000))
      // } else {
      //   changeClientField('username', '')
      // }
      console.log('editedClientPArtner', client.partner?.name, client.partner?.id)
    }
  }, [client])
  const [errorUsername, setErrorusername] = React.useState<boolean>(false)
  const [errorUsernameText, setErrorusernameText] = React.useState<string>('')

  const [loading, setLoading] = React.useState<boolean>(false)
  const [existingUsername, setExisingUsername] = React.useState<string>(editedClient?.username || '')
  const [errorRefcode, setErrorRefcode] = React.useState<boolean>(false)
  const [errorRefcodeText, setErrorRefcodeText] = React.useState<string>('')
  const [refcodeLoading, setRefcodeLoading] = React.useState<boolean>(false)
  const [existingRefcode, setExisingRefcode] = React.useState<string>(editedClient?.ref_code || '')
  const [usernameFieldDisabled, setUsernameFieldDisabled] = React.useState<boolean>(false)

  // const userNameCheck = async (username: string) => {
  //   try {
  //     setLoading(true)
  //     setUsernameFieldDisabled(true)
  //     debugger
  //     const res: any = await checkUsername({ username: username })
  //     setLoading(false)
  //     setUsernameFieldDisabled(false)
  //     if (res.error && existingUsername !== client.username) {
  //       setErrorusername(true)
  //       setErrorusernameText('Sorry! username already exists!')
  //     } else {
  //       setErrorusername(false)
  //     }
  //   } catch (e) {
  //     showApiResponseError(e, 'Error Checking username')
  //   }
  // }

  // React.useEffect(() => {
  //   // userNameCheck(client.username || '')
  //   // const userNameCheck = async () => {
  //   //   try {
  //   //     setLoading(true)
  //   //     setUsernameFieldDisabled(true)
  //   //     const res: any = await checkUsername({ username: client.username })
  //   //     setLoading(false)
  //   //     setUsernameFieldDisabled(false)
  //   //     if (res.error && existingUsername !== client.username) {
  //   //       setErrorusername(true)
  //   //       setErrorusernameText('Sorry! username already exists!')
  //   //     } else {
  //   //       setErrorusername(false)
  //   //     }
  //   //   } catch (e) {
  //   //     showApiResponseError(e, 'Error Checking username')
  //   //   }
  //   // }
  //   const reg = /^[A-Za-z0-9]*$/

  //   // client.username &&
  //   //   client.username?.length >= FIELDS_MIN_LENGTH.username &&
  //   //   // reg.test(client.username) &&
  //   //   usernameChanged &&
  //   //   userNameCheck()
  //   // debugger
  //   if (client.username) {
  //     if (reg.test(client.username)) {
  //       if (client.username && client.username?.length < FIELDS_MIN_LENGTH.username) {
  //         setErrorusername(true)
  //         setErrorusernameText(`Username cannot be less than ${FIELDS_MIN_LENGTH.username} characters`)
  //       } else {
  //         setErrorusername(false)
  //         setErrorusernameText('')
  //       }
  //       // else if (
  //       //   client.username &&
  //       //   client.username?.length >= FIELDS_MIN_LENGTH.username &&
  //       //   // reg.test(client.username) &&
  //       //   usernameChanged
  //       // )
  //       //   setTimeout(() => {
  //       //     userNameCheck()
  //       //   }, 500)
  //     } else {
  //       setErrorusername(true)
  //       setErrorusernameText(`Username should contain Alpha numeric Only`)
  //     }
  //   }
  // }, [client.username])

  // const onBlurCapture = () => {
  //   if (client.username && client.username?.length > FIELDS_MIN_LENGTH.username) {
  //     userNameCheck(client.username || '')
  //   }
  // }

  //Check refcode

  React.useEffect(() => {
    const refcodeCheck = async () => {
      try {
        setRefcodeLoading(true)
        const res: any = await checkRefcode({ ref_code: client.ref_code })
        setRefcodeLoading(false)
        if (res.error && existingRefcode !== client.ref_code) {
          setErrorRefcode(true)
          setErrorRefcodeText(res.error)
        } else {
          setErrorRefcode(false)
        }
      } catch (e) {
        showApiResponseError(e, 'Error Checking Refcode')
      }
    }

    client.ref_code && client.ref_code?.length >= FIELDS_MIN_LENGTH.ref_code && refcodeChanged && refcodeCheck()
    if (client.ref_code && client.ref_code?.length < FIELDS_MIN_LENGTH.ref_code) {
      setErrorRefcode(true)
      setErrorRefcodeText(`Refcode cannot be less than ${FIELDS_MIN_LENGTH.ref_code} characters`)
    }
  }, [client.ref_code])
  const resetPass = async () => {
    try {
      if (client.email) {
        const result = await resetPassword({ username: client.email })
        if (result.success) showSuccessNotification(`Password reset Email sent to ${client.email}`)
        else if (result.error) showApiResponseError(result.error, `Invalid Email, ${client.email} Doesn't exists`)
      } else showErrorNotification('No Username For this user')
    } catch (err) {
      showApiResponseError(err.message, `Couldn't Send Reset Email!`)
    }
  }
  const sendPortalSetupEmail = async () => {
    try {
      if (client.email) {
        const result = await resetPassword({ username: client.email, portal_setup: true })
        if (result.success) showSuccessNotification(`Portal Setup Email sent to ${client.email}`)
        else if (result.error) showApiResponseError(result.error, `Invalid Email, ${client.email} Doesn't exists`)
      } else showErrorNotification('No Username For this user')
    } catch (err) {
      showApiResponseError(err.message, `Couldn't Send Email!`)
    }
  }
  const [taxSelect, setTaxSelect] = React.useState(false)
  const handleTaxSelect = (value: string) => {
    changeClientField('tax_structure', value || undefined)
  }
  React.useEffect(() => {
    if (client.tax_structure === PERSONAL_TAX_STRUCTURE.married_filing_jointly) setTaxSelect(true)
    else setTaxSelect(false)
  }, [client.tax_structure])
  const [isUpdated, setIsUpdated] = React.useState(false)

  const [isModal, setIsModal] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      {!!editedClient && !fromProspect && (
        <IconButton className={classes.backClientBtn} onClick={() => history.push('/client')}>
          <Close />
        </IconButton>
      )}

      <AddAddressDialog
        open={showAddAddressDialog}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressFieldsChange}
        handleDialogClose={toggleAddAddressDialog}
        onAddressAdd={handleAddAddress}
        addressErrors={{
          country,
          city,
          street,
          postal_code,
          locality,
        }}
        onPrimaryAddressChecked={handlePrimaryAddressChecked}
        isEditing={editedAddressId !== undefined}
        showPrimaryAddressCheckbox
      />
      <div className={classes.formContainer}>
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.name}
          helperText={clientEditErrors.name && 'Please Specify Name Of The Client'}
          label="First Name"
          style={{ marginBottom: clientEditErrors.name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.name}
          onChange={event => changeClientField('name', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-middle-name"
          error={clientEditErrors.middle_name}
          helperText={clientEditErrors.middle_name && 'Please Specify Middle Name Of The Client'}
          label="Middle Name"
          style={{ marginBottom: clientEditErrors.middle_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="middle_name"
          margin="normal"
          variant="outlined"
          value={client.middle_name}
          onChange={event => changeClientField('middle_name', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-middle-name"
          error={clientEditErrors.last_name}
          helperText={clientEditErrors.last_name && 'Please Specify Last Name Of The Client'}
          label="Last Name"
          style={{ marginBottom: clientEditErrors.last_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.last_name}
          onChange={event => changeClientField('last_name', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          error={clientEditErrors.email}
          helperText={helperTexts.email}
          label="Email"
          className={classes.textField}
          style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
          type="text"
          name="email"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.email}
          onChange={event => changeClientField('email', event.target.value)}
        />
        <FormControl className={classes.fullWidth}>
          <PhoneInput
            showCopyButton={!!editedClient}
            value={client.phone_number || ''}
            onChange={value => changeClientField('phone_number', value)}
            error={clientEditErrors['phone_number']}
          />
          {clientEditErrors['phone_number'] && (
            <FormHelperText className={classes.errorHelperText}>Please enter phone number</FormHelperText>
          )}
        </FormControl>
        <EinSsnInput
          showCopyButton={!!editedClient}
          ssnEinHelperText={helperTexts.ssn_ein}
          ssnEinError={clientEditErrors.ssn_ein}
          value={client.ssn_ein}
          onChange={(value: string) => changeClientField('ssn_ein', positiveNumericInput(value))}
        />
        {clientEditErrors.ssn_ein ? <div style={{ height: 10 }} /> : null}
        <DatePicker
          onDateSelected={value => changeClientField('dob', value)}
          label="Date of Birth"
          classes={{ root: classes.fullWidth }}
          maxDateMessage="Client should be older than 18 years"
          minDateMessage="Age cannot exceed 100 years"
          value={client.dob}
          style={{ width: '100%' }}
          placeholder={datePickerDatePlaceholder}
          maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
          minDate={new Date().setFullYear(new Date().getFullYear() - 120)}
          disableFuture
          format={datePickerDateFormat}
          error={clientEditErrors.dob}
        />
        <SelectField
          showCopyButton={!!editedClient}
          onOptionSelected={option => changeDropDownField('sales_rep', option ? option : null)}
          title="Sales Representative"
          fetchSuggestions={fetchSuggestions}
          field="sales_rep_id"
          placeholder="Choose Sales Representative"
          value={salesRepresentative}
        />
        <SelectField
          showCopyButton={!!editedClient}
          onOptionSelected={option => changeDropDownField('affiliate', option ? option : null)}
          title="Affiliate"
          fetchSuggestions={fetchSuggestions}
          field="affiliate_id"
          placeholder="Choose Affiliate"
          value={affiliate}
        />
        <br />
        <SelectAutocomplete
          isClearable={true}
          // showCopyButton={!!editedClient}
          handleChange={value => handleTaxSelect(value as string)}
          label="Tax Structure"
          value={client.tax_structure}
          options={Object.values(PERSONAL_TAX_STRUCTURE).map(val => ({ value: val, label: val }))}
        />
        {taxSelect && (
          <SelectField
            showCopyButton={!!editedClient}
            onOptionSelected={option => changeDropDownField('partner', option ? option : null)}
            title="Partner"
            fetchSuggestions={fetchSuggestions}
            field="affiliate_id"
            placeholder="Choose Partner Client"
            value={partner}
          />
        )}
        <ListItem key={100} className={classes.RegsiteredAgentText}>
          <Checkbox
            color="secondary"
            checked={client.is_registered_agent}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            // value={values.is_primary_contact}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => changeClientField('is_registered_agent', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Registered Agent')}</span>
        </ListItem>
        <Addresses
          label="Address Details"
          onPrimaryAddressChange={handlePrimaryAddressChange}
          onAddAddressClick={handleAddAddressClick}
          // addresses={initialClient || []}
          addresses={client.addresses || []}
          onAddressEditClick={handleAddressEditClick}
          onAddressDeleteClick={handleAddressDeleteClick}
          noPrimaryAddressError={clientEditErrors.no_primary_address}
        />
        {/* <PrimaryAddress
          address={checkPrimary(initialClient)}
          addressOptions={initialClient || []}
          onAddAddressClick={handleAddAddressClick}
          onAddressEditClick={handleAddressEditClick}
          onAddressDeleteClick={handleAddressDeleteClick}
          handleCompanyAddressChange={handleCompanyAddressChange}
          errors={}
        /> */}
        {/* <MailingAddress
          mailingAddress={client.addresses || []}
          addressOptions={initialClient || []}
          onAddAddressClick={handleAddAddressClick}
          onAddressEditClick={handleAddressEditClick}
          onAddressDeleteClick={handleAddressDeleteClick}
          handleMailingAddressChange={handleCompanyAddressChange}
        /> */}
        <span className={classes.HelperTextUsername}>
          Kindly choose Ref code of minimum length {FIELDS_MIN_LENGTH.ref_code}
        </span>
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          disabled={false}
          showCopyButton={!!editedClient}
          data-testid="ref_code"
          error={errorRefcode}
          helperText={errorRefcode && errorRefcodeText}
          label="Ref Code"
          style={{ marginBottom: errorRefcode ? '2.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="ref_code"
          margin="normal"
          variant="outlined"
          value={client.ref_code}
          onChange={event => changeClientField('ref_code', event.target.value)}
        />
        {refcodeLoading && (
          <div className={classes.rootProgress}>
            <LinearProgress />
          </div>
        )}
        {/* <span className={classes.HelperTextUsername}>
          To Create an Account Kindly choose Username of minimum length {FIELDS_MIN_LENGTH.username}
        </span> */}
        {/* <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          disabled={usernameFieldDisabled}
          showCopyButton={!!editedClient}
          data-testid="client-username"
          error={errorUsername}
          helperText={errorUsername && errorUsernameText}
          label="User Name"
          style={{ marginBottom: errorUsername ? '2.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.username}
          onChange={event => changeClientField('username', event.target.value)}
          onBlur={onBlurCapture}
        />
        {loading && (
          <div className={classes.rootProgress}>
            <LinearProgress />
          </div>
        )} */}
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          disabled={false}
          showCopyButton={!!editedClient}
          data-testid="client-commission"
          // error={clientEditErrors.username}
          // helperText={clientEditErrors.username && 'Please Specify Username'}
          label="Commission Percentage"
          style={{ marginBottom: clientEditErrors.username ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="number"
          name="commission_percentage"
          margin="normal"
          variant="outlined"
          value={client.commission_percentage}
          onChange={event => changeClientField('commission_percentage', Number(event.target.value))}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          disabled={true}
          showCopyButton={!!editedClient}
          data-testid="client-username"
          // error={clientEditErrors.username}
          // helperText={clientEditErrors.username && 'Please Specify Username'}
          label="Credit"
          style={{ marginBottom: clientEditErrors.username ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.credit}
          // onChange={event => changeClientField('username', event.target.value)}
        />
        {/* <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-password"
          error={clientEditErrors.password}
          helperText={clientEditErrors.password && 'Please Specify Password'}
          label="Password"
          style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="password"
          name="password"
          margin="normal"
          variant="outlined"
          value={client.password}
          onChange={event => changeClientField('password', event.target.value)}
        /> */}
        {editedClient && editedClient.id && (
          <CommissionAccordion changeClientField={changeClientField} editedClient={editedClient} />
        )}
        <SubmitFormButton
          onFormSubmitted={!editedClient ? onFormSubmitted : () => null}
          title={editedClient ? 'Save Changes' : 'Add Client'}
          onClick={onSubmitClick}
        />
        {((!editedClient && title === undefined) || (editedClient && !editedClient.id)) && (
          <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={addclientandAssignComp}>
            Add Client & Assign Company
          </ButtonWithLoading>
        )}
        {editedClient && editedClient.id && (
          <>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '0.5rem' }} onClick={navigateToAssociateCompanies}>
              Show Associated Companies
            </ButtonWithLoading>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={createCompany}>
              Create Associated Company
            </ButtonWithLoading>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={resetPass}>
              Reset Password{' '}
            </ButtonWithLoading>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={sendPortalSetupEmail}>
              Send Login Info{' '}
            </ButtonWithLoading>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={() => setIsModal(true)}>
              Create Prospect{' '}
            </ButtonWithLoading>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={() => setOpen(true)}>
              Show Referrals{' '}
            </ButtonWithLoading>
          </>
        )}
      </div>

      {editedClient && editedClient.id && <LogsTable clientId={editedClient.id} />}

      <AddProspectModal open={isModal} setOpen={setIsModal} setIsUpdated={setIsUpdated} client={client} />
      <Dialog
        open={open}
        maxWidth="lg"
        //className={classes.dialogContainer}
        fullWidth={true}
      >
        <br />
        <ModalTitle title={'Affiliates'} toggleModal={setOpen} />
        <DialogContent
        // className={'classes.root'}
        >
          <Client affiliate={client.id} setOpen={setOpen} />
        </DialogContent>

        <DialogActions className={classes.dialogAction}></DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ClientDetails
