import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { fetchNotificationTemplates } from 'api/notifications'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { TempNotification } from 'components/notifications/notificationsDialog'
import AddTempleteDialog from 'components/notifications/Templetes/TempletesDialogs/AddTempleteDialog'
import DeleteTempleteDialog from 'components/notifications/Templetes/TempletesDialogs/DeleteTempleteDialog'
import TempleteNotiHead from 'components/notifications/Templetes/TempletesTables/TempleteNotiHead'
import TempleteNotiRow from 'components/notifications/Templetes/TempletesTables/TempleteNotiRow'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo } from 'react'
import { updatePageVisitsCount } from 'utils/user'
import { useStyles } from '../styles'

const TemplatesList = () => {
  const classes = useStyles()

  const [addTempleteDialoge, setAddTempleteDialoge] = React.useState(false)
  const [editedTempleteNotification, setEditedTempleteNotification] = React.useState<TempNotification>()
  const [query, setQuery] = React.useState('')

  const [deleteTempleteDialoge, setDeleteTempleteDialoge] = React.useState(false)
  const [deleteTempleteNotification, setDeleteTempleteNotification] = React.useState<TempNotification>()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'asc', sort_by: 'subject' })
  const { order, sort_by } = sortSettings

  const memoApiCall = useMemo(() => fetchNotificationTemplates()({ sort_order: order, sort_by, query }), [
    order,
    query,
    sort_by,
  ])

  const pagedTable = usePagedTable<TempNotification>({
    apiCall: memoApiCall,
  })

  const tableHead = useMemo(
    () => <TempleteNotiHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleTempleteDialogCloseClick = React.useCallback(() => {
    setAddTempleteDialoge(false)
  }, [setAddTempleteDialoge])

  const handleDeleteTempleteDialogCloseClick = React.useCallback(() => {
    setDeleteTempleteDialoge(false)
  }, [setDeleteTempleteDialoge])

  const handleEditNotificationClick = React.useCallback(
    (notification: TempNotification) => {
      setEditedTempleteNotification(notification)
      setAddTempleteDialoge(true)
    },
    [setEditedTempleteNotification, setAddTempleteDialoge]
  )

  const handleDeleteNotificationClick = React.useCallback((notification: TempNotification) => {
    setDeleteTempleteNotification(notification)
    setDeleteTempleteDialoge(true)
  }, [])

  return (
    <div className={classes.notificationSection}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className="notification-text">notifications</p>
          <p className="table-text">
            {/* Company{notificationsType === 'companyType' && ' Type'} */}
            Templates List
          </p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => setAddTempleteDialoge(true)}>
            Add Template
          </ButtonWithLoading>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 2, sizes: [500, 100], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Notifications"
          {...pagedTable.renderProps}
          renderRow={(notification: TempNotification) => (
            <TempleteNotiRow
              notification={notification}
              onEditClick={() => handleEditNotificationClick(notification)}
              onDeleteClick={() => handleDeleteNotificationClick(notification)}
            />
          )}
          header={tableHead}
        />
      )}
      <AddTempleteDialog
        isOpen={addTempleteDialoge}
        close={handleTempleteDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        editedNotification={editedTempleteNotification}
      />
      <DeleteTempleteDialog
        open={deleteTempleteDialoge}
        close={handleDeleteTempleteDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionObject={deleteTempleteNotification}
      />
    </div>
  )
}

export default TemplatesList
