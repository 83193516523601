import Address from 'components/common/addresses/address'
import AddressesSelect from 'components/common/addresses/addressesSelect'
import useStyles from 'components/common/styles/createDialogStyles'
import { CreateUpdateAddressDialog } from 'components/companies/companyOwners/CreateUpdateAddressDialog'
import { IAddress } from 'models/address'
import * as React from 'react'
import showErrorNotification from 'utils/showErrorNotification'

export enum ChangeAction {
  update = 'update',
  create = 'create',
}

export interface AddressSelectProps {
  /**
   * if owner already has an address, you can pass it as this parameter
   */
  initialValue: IAddress | null

  options: IAddress[]

  /**
   * is called when a new address is selected from a dropdown or address is
   * deselected
   */
  onSelect(address: IAddress | null): void

  /**
   * is called when an address is created or edited from an address dialog
   */
  onCreateUpdate(address: IAddress, action: ChangeAction): void
}

/**
 * address dropdown. user can also create a new address from it
 */
export const AddressSelect: React.FC<AddressSelectProps> = ({ initialValue, options, onSelect, onCreateUpdate }) => {
  const classes = useStyles()

  const isUpdateMode = initialValue?.id ? ChangeAction.update : ChangeAction.create

  const isAddressSelected = !!initialValue

  const [isAddEditAddressDialogOpen, setIsAddEditAddressDialogOpen] = React.useState(false)

  const handleAddressSelect = React.useCallback(
    (newAddressId: number | null) => {
      const newAddress: IAddress | null = options.find(address => address.id === newAddressId) ?? null
      //

      if (!newAddress) {
        showErrorNotification('You should select an address for company owner')
        return
      }

      onSelect(newAddress)
    },
    [options, onSelect]
  )

  const handleAddNewAddressClick = React.useCallback(() => {
    setIsAddEditAddressDialogOpen(true)
  }, [])

  const handleEditAddressClick = React.useCallback(() => {
    setIsAddEditAddressDialogOpen(true)
  }, [])

  const handleDeleteAddressClick = React.useCallback(() => {
    onSelect(null)
  }, [onSelect])

  const handleAddEditAddressDialogSubmit = React.useCallback(
    async (address: IAddress) => {
      onCreateUpdate(address, isUpdateMode)
      //

      setIsAddEditAddressDialogOpen(false)
    },
    [onCreateUpdate, isUpdateMode]
  )

  const handleCreateUpdateAddressDialogClose = React.useCallback(() => {
    setIsAddEditAddressDialogOpen(false)
  }, [])

  return (
    <>
      {isAddressSelected && initialValue ? (
        <Address
          onlyOneAddress={true}
          address={initialValue}
          onEditClick={handleEditAddressClick}
          onDeleteClick={handleDeleteAddressClick}
        />
      ) : (
        <AddressesSelect
          label="Select Address"
          addresses={options}
          onChange={handleAddressSelect}
          onAddAddressClick={handleAddNewAddressClick}
          className={classes.textField}
        />
      )}

      {isAddEditAddressDialogOpen && (
        <CreateUpdateAddressDialog
          initialAddress={initialValue}
          onSubmit={handleAddEditAddressDialogSubmit}
          onClose={handleCreateUpdateAddressDialogClose}
        />
      )}
    </>
  )
}
