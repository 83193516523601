import { apiClient, getAccessToken, requestPaginated } from '@jetkit/react'
import { CancelToken, CancelTokenStatic } from 'axios'
import { IAsset } from 'models/asset'
import { IDetailedCompanyResponse } from 'models/company'
import { IDocument } from 'models/document'
import { INewOrderState, IOrder, IOrderPaymentStatus, IOrderStage, IOrderType } from 'models/order'
import { ISalesRep } from 'models/salesRep'
import { IOrderTask } from 'models/task'
import { ICompanyOwner, IUser } from 'models/user'
import axios from 'axios'
import { TaxStructureBusinessOptions } from 'models/taxOrder'

export const FORMATION_ORDER_ID = 4

interface OrderSearchParams {
  orderTypeQuery: string
  companyId?: number
  orderTypeIds?: number[]
}

export interface IOrderDetailsResponse {
  affiliate: IUser | null
  commission: number

  /**
   * for open orders, we take data from `company`.
   * this is an actual `company` at current moment of time.
   * it can be edited on Business Formation order type, and
   * it cannot be edited on any other order type.
   */
  company?: IDetailedCompanyResponse

  /**
   * for completed orders, we take data from `company_details`.
   * this is company data at a moment of time when the order
   * was worked on. namely, at the time when the order was
   * completed
   * it is readonly, it cannot EVER be edited.
   * P.S. current state of system is this object is updated in
   * many different places. that will be refactored away with
   * time.
   */
  readonly company_details?: IDetailedCompanyResponse

  company_users: ICompanyOwner[]
  company_id: number
  creator: IUser | null
  future_orders: IOrder[]
  documents: IDocument[]
  due_date: string | null
  due_date_order_status: string | null

  id: number
  last_update: string | null
  order_stage: IOrderStage | null
  order_stage_id: number | null
  order_stages: IOrderStage[] | null
  assignee_id: number | null
  assignee: IUser | null
  order_type: IOrderType | null
  order_type_id: number | null
  order_status?: number | null
  payment_status: string
  price: number
  required_orders: IOrder[]
  required_order_ids?: number[]
  sales_rep: ISalesRep | null
  sales_rep_id: number | null
  orderTypeList?: []
  is_rush_order?: boolean

  prospect_id?: number
  prospect?: any
  prospect_name?: string
  invoice_no?: string
  submitted_on?: string
  submission_id?: number
  /**
   * @deprecated
   * TODO: #414 `tasks` should be deleted and be fetched from a different
   * endpoint for tasks only
   */
  tasks: IOrderTask[]

  complianceId?: number

  client?: any
  post_form_completion?: boolean
  internal_rush_processing?: boolean

  expected_ein_date?: string | null
}

export interface IUpdateOrder extends Partial<IOrderDetailsResponse> {
  id: number
}

export const emptyOrderDetails: IOrderDetailsResponse = {
  affiliate: null,
  commission: 0,
  company_users: [],
  company_id: 0,
  creator: null,
  future_orders: [],
  documents: [],
  due_date: null,
  due_date_order_status: null,

  id: 0,
  last_update: null,
  order_stage: null,
  order_stage_id: null,
  order_type: null,
  order_type_id: null,
  order_status: null,
  assignee_id: null,
  assignee: null,
  payment_status: 'Unpaid',
  price: 0,
  required_orders: [],
  sales_rep: null,
  sales_rep_id: null,
  tasks: [],
  order_stages: null,
  is_rush_order: false,

  prospect_id: 0,
  prospect_name: '',
}

export const fetchOrders = requestPaginated<IOrder[]>({ url: '/order' })

export const fetchOrderTypes = async (query = ''): Promise<IOrderType[]> => {
  return (await apiClient.get('/order/type', { params: { query } })).data
}
export const fetchOrderStatuses = async (query = ''): Promise<IOrderType[]> => {
  return (await apiClient.get('/order-statuses', { params: { query } })).data
}
export const fetchOrderTypeByName = async (orderTypeName: string): Promise<any | undefined> => {
  //(await apiClient.get('/order_type', { params: { order_type_name: orderTypeName } })).data
  return { id: 3, order_type_name: 'BUSINESS FORMATION' }
}
export const findOrders = async (params: OrderSearchParams): Promise<IOrder[]> => {
  return (
    await apiClient.get('/order', {
      params: {
        order_type_query: params.orderTypeQuery,
        company_id: params.companyId,
        order_type_ids: params.orderTypeIds || [],
      },
    })
  ).data
}
export const findOrdersByIds = async (orderIds: number[]): Promise<IOrder[]> => {
  return (
    await apiClient.get('order', {
      params: {
        order_ids: orderIds,
      },
    })
  ).data
}
export const deleteOrder = async (orderId: number): Promise<void> => {
  await apiClient.delete(`/order/${orderId}`)
}

export const createCompanyOrder = async (order: any): Promise<any> => {
  order.commission = parseFloat(order.commission)
  return (await apiClient.post(`/company-orders`, order)).data
  //return
}
//send Review
export const sendReviewApi = async (data: any, order_id: number): Promise<any> => {
  return (await apiClient.post(`/send-review-request/${order_id}`, data)).data
}
//
//details confirmation Email
export const reSendDetailsConfirmationEmail = async (data: any): Promise<any> => {
  return (await apiClient.post(`/details-confirmation-email`, data)).data
}
//

//generate pdf
export const generateAndUploadPdf = async (data: any): Promise<any> => {
  // apiClient.defaults.responseType='blob';
  return (await apiClient.post(`/company/${data.company_details.id}/pdf-generate`, data)).data
  // let resdata:any;
  // await axios({
  //   url:  `https://portal.businessrocket.net/api/generate_document`,//`${apiClient.defaults.baseURL}/company/${data.company_details.id}/pdf-generate`, //your url
  //   data: data,
  //   method: 'POST',
  //   headers:{'Accept': '*/*', 'Content-Type': 'application/json'},
  //   responseType: 'blob', // important
  // }).then((response) => {
  //   resdata = response.data;

  // });

  //  return resdata;
}
//upload generated pdf after review
export const uploadPdf = async (file: File, id: string): Promise<any> => {
  const formData = new FormData()
  formData.append('file', file)
  const responseData = (
    await axios({
      url: `https://portal.businessrocket.net/api/split_document/${id}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
  ).data
  // const responseData = (
  //   await axios.post(`https://portal.businessrocket.net/api/split_document/${id}`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   })
  // ).data
  console.log('File response', responseData)
  return responseData
}
export const externalDocsToS3 = async (data: any): Promise<any> => {
  // apiClient.defaults.responseType='blob';
  return (await apiClient.post(`/company/${data.company_id}/upload-pdf`, data)).data
}
//pdf docs
export const fetchDocsForPdf = async (
  company_id: number
  // company_type: number,
  // tax_structure?: string
): Promise<any> => {
  return (await apiClient.get(`/documentsCompany/${company_id}`)).data
}
export const getOrderDetails = async (orderId: number): Promise<IOrderDetailsResponse> => {
  return (await apiClient.get(`order/${orderId}`)).data
}
export const updateOrder = async (orderDetails: any): Promise<IOrder> => {
  // console.log(orderDetails.commission, typeof orderDetails.commission)
  orderDetails.commission = parseFloat(orderDetails.commission)
  // console.log(orderDetails.commission, typeof orderDetails.commission)
  orderDetails.verification_code = ''
  orderDetails.payment_method = ''
  orderDetails.payment_id = ''
  orderDetails.invoice_link = ''
  orderDetails.price = parseFloat(orderDetails.price)
  orderDetails.deleted_at = ''
  return (await apiClient.post(`order/${orderDetails.id}`, orderDetails)).data
}
export const getOrderStages = async (orderTypeId: number): Promise<IOrderStage[]> =>
  (await apiClient.get(`order_type/${orderTypeId}/stage`)).data

export const createOrderTask = async (orderId: number, orderTask: IOrderTask): Promise<IOrderTask> =>
  //(await apiClient.post(`order/${orderId}/task`, orderTask)).data
  (await apiClient.post(`/task`, orderTask)).data

export const updateOrderTask = async (orderId, orderTask: IOrderTask): Promise<any> => {
  // orderTask.order_id = orderId
  return (await apiClient.post(`task/${orderTask.id}`, orderTask)).data
}

export const getOrderTasks = async (orderId: number): Promise<IOrderTask[]> =>
  (await apiClient.get(`order/${orderId}/task`)).data

export const getCompanyOrderDocuments = (orderId: number) =>
  requestPaginated<IAsset[]>({ url: `order/${orderId}/asset` })

export const getCompanyOrderDocumentsWithoutPagination = async (orderId: number) => {
  return (await apiClient.get(`order/${orderId}/asset`)).data
}
export const sendDocsToClient = async (orderId: number, payload) => {
  return (await apiClient.post(`sendDocsToClient/${orderId}`, payload)).data
}

//post payment reminder//
export const postPaymentReminder = async payload => {
  return (await apiClient.post(`send-postpayment-link`, payload)).data
}

interface IOrderAssetParams {
  orderId: number
  documentId: number
}

export const downloadCompanyOrderDocumentURL = async ({ orderId, documentId }: IOrderAssetParams): Promise<string> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  const res = (await apiClient.get(`${BASE_URL}/order/${orderId}/asset/${documentId}/download?jwt=${token}`)).data
  return res
}

export const deleteCompanyOrderDocument = async ({ orderId, documentId }: IOrderAssetParams): Promise<unknown> =>
  apiClient.delete(`company/${orderId}/asset/${documentId}`)

/**
 * An order type may have a list of default tasks associated with it.
 *
 * We want to be able to view these tasks so that we can choose which of them should be completed later.
 */
export const getDefaultTasksForOrderType = async (orderTypeId: number): Promise<IOrderTask[]> =>
  (await apiClient.get(`order_type/${orderTypeId}/get-default-tasks`)).data

/**
 * We want to be able to add both default and custom user-created tasks to a company order.
 *
 * This will allow the users to track assignments required to fulfill an order within the app.
 */
export const addTasksToCompanyOrder = async (orderId: number, tasks_data: IOrderTask[]): Promise<IOrderTask[]> => {
  return await apiClient.post(`order/${orderId}/add-tasks`, { tasks_data: tasks_data })
}
export const notifyClient = async (order_id: number | null, company_id: number | null): Promise<IOrder[]> => {
  return (
    await apiClient.get('client/send-document-email', {
      params: {
        order_id: order_id,
        company_id: company_id,
      },
    })
  ).data
}

export const fetchAffCommission = async (companyID: number): Promise<number> => {
  return (await apiClient.get(`company-affiliate-percentage/${companyID}`)).data
}
