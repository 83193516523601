function copyObjectAndDeleteKey<T>(object: T, key: string): T {
  const newObject = { ...object }
  if (newObject[key] !== undefined) {
    delete newObject[key]
    return newObject
  }
  return newObject
}

export default copyObjectAndDeleteKey
