import App from 'App'
import { RouterState } from 'connected-react-router'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Reducer } from 'redux'
import { PersistPartial } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import createRootReducer, { history, storeAndPersistorForRootReducer } from 'store'
import ErrorBoundaryWrapper from 'components/errorBoundaryWrapper'

const AppEntryPoint: React.FC = () => {
  const { store, persistor } = storeAndPersistorForRootReducer(
    (createRootReducer(history) as unknown) as Reducer<{ router: RouterState } & PersistPartial>
  )

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ErrorBoundaryWrapper>
            <App />
          </ErrorBoundaryWrapper>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default AppEntryPoint
