import { HeadColumn, SortableTableHead } from 'components/common/table'
import * as React from 'react'
import useSortTable from '../../hooks/useSortTable'
import ToggleShowDoneTasks from './toggleShowDoneTasks'

const headColumns: HeadColumn[] = [
  { id: 'task', label: 'Tasks/Orders', style: { minWidth: 250, maxWidth: '25%' }, sortable: false },
  // { id: 'priority_value', label: 'Priority', style: { width: '20%', maxWidth: '20%' }, sortable: true },
  // { id: 'category', label: 'Category', style: { width: '10%', maxWidth: '10%' }, sortable: false },
  { id: 'company_name', label: 'Company Name', style: { width: '20%', maxWidth: '15%' }, sortable: true },
  { id: 'client_name', label: 'Client Name', style: { width: '20%', maxWidth: '20%' }, sortable: true },
  { id: 'due_date', label: 'Due Date', style: { width: '15%', maxWidth: '10%' }, sortable: true },
  { id: 'task_type', label: 'Task Type', style: { width: '15%', maxWidth: '10%' }, sortable: true },

  { id: 'actions', label: <ToggleShowDoneTasks />, style: { width: '10%', maxWidth: '10%' }, sortable: false },
]

const TasksTableHead = () => {
  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'due_date' })
  return (
    <SortableTableHead
      cellStyle={{
        padding: 10,
      }}
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default TasksTableHead
