import { createStyles, FormControl, FormHelperText, makeStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { deleteAddress, editAddress } from 'api/addresses'
import { createClient, updateClient } from 'api/orderstatus'
import { fetchSuggestions } from 'api/suggestions'
import CommissionAccordion from 'components/clients/commissions/accordion'
import EinSsnInput from 'components/clients/create/einSsnInput'
import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { Checkbox, ListItem } from '@material-ui/core'
import { toTitleCase } from '@jetkit/react'

import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { validateEmail } from 'utils/validators'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'

import { ORDERSTATUS } from 'utils/constant'
//import { getstates, getcities } from 'api/addresses'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    bgColorDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
      fontSize: '17px',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: any = {
  id: 0,
  //state_name: '',
  //state_display_name: '',
  //country_id: 0,
  //country_name: '',
  //city_name: '',
  name: '',
  is_open: false,
  background_color: '',
}

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

export interface IClientDetailsProps {
  editedClient?: any
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedState) => void
}

const ClientDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful /*, initialClient*/ }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<any>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  const updateClientRequest = async (client: any) => {
    const id = 0
    client.state_id = id
    const editedClient = client
    const resClient = await updateClient({
      // We pick certain fields from editedClient object, because client,
      // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
      // if we send them to PATCH `user/:id`
      // extid: editedClient.extid,
      id: editedClient.id,
      name: editedClient.name,
      is_open: editedClient.is_open,
      background_color: editedClient.background_color,
    })
    return resClient
  }

  const createClientRequest = async (client: any) => {
    const id = 1
    client.state_id = id
    const resClient = await createClient({ ...client })
    return resClient
  }

  const changeClientField = React.useCallback(
    (field: keyof any, value: ValueOf<any>) => {
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const validateFields = React.useCallback((): boolean => {
    ////
    const isValid = true
    const tmpHelperTexts = helperTexts
    // if (!validateName(client)) {
    //   clientEditErrors.setError('name', true)
    //   isValid = false
    // }
    // if (!client.email || !validateEmail(client.email)) {
    //   clientEditErrors.setError('email', true)
    //   tmpHelperTexts.email = 'Please Specify Email'
    //   isValid = false
    // }
    // if (client.dob && client.dob !== '' && !validateDob(client)) {
    //   clientEditErrors.setError('dob', true)
    //   isValid = false
    // }
    // if (!validateAddress(client)) {
    //   clientEditErrors.setError('no_primary_address', true)
    //   isValid = false
    // }
    // setHelperTexts(tmpHelperTexts)
    return isValid
  }, [helperTexts, client, clientEditErrors])

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    if (!validateFields()) return false
    try {
      let clientResponse: ICreatedState
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)
      }

      const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
      setClient(updatedClient)
    } catch (err) {
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }, [validateFields, formMode, client])

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedState = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        {editedClient === undefined ? (
          <BRTextField
            required
            data-testid="client-email"
            error={clientEditErrors.email}
            helperText={helperTexts.email}
            label="Order Status Name"
            className={classes.textField}
            style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
            type="text"
            name="email"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            variant="outlined"
            value={client.name}
            onChange={event => changeClientField('name', event.target.value)}
          />
        ) : (
          <BRTextField
            showCopyButton={!!editedClient}
            required
            data-testid="client-email"
            error={clientEditErrors.email}
            helperText={helperTexts.email}
            label="Order Status Name"
            className={classes.textField}
            style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
            type="text"
            name="email"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            variant="outlined"
            value={client.name}
            disabled={!client?.is_editable ? true : false}
            onChange={event => changeClientField('name', event.target.value)}
          />
        )}
        <br />
        {/* <div className={classes.bgColorDiv}>
          <span>{toTitleCase('Select Background Color')}</span>
          <input
            type="color"
            value={client.background_color}
            onChange={e => changeClientField('background_color', e.target.value)}
          />
        </div> */}

        <BRTextField
          label="Background Color"
          className={classes.textField}
          style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
          type="color"
          name="Background"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.background_color}
          onChange={e => changeClientField('background_color', e.target.value)}
        />
        <ListItem
          key={100}
          // className={classes2.ownerModalListElement}
        >
          <Checkbox
            color="secondary"
            checked={client.is_open}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            value={client.is_open}
            style={{ padding: 0, marginRight: 10 }}
            //  onChange={() => !client.is_open}
            onChange={event => changeClientField('is_open', event.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Open Order')}</span>
        </ListItem>

        <SubmitFormButton
          //onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add New Order Status'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default ClientDetails
