import { notify } from '@jetkit/react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from 'components/common/icons/delete'
import * as React from 'react'
import {
  formatDateTimeToHumanFriendly,
  formatDateToHumanFriendly,
  getDueDateWithYear,
  getTimeFromNow,
} from 'utils/formatDate'
import { toTitleCase } from 'utils/toTitleCase'
import TableActionButton from 'components/common/buttons/tableActionButton'
import { ITaxOrder } from 'models/taxOrder'
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { CUSTOM_TAX_WIDGET_TYPES } from 'utils/constant'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import { Collapse, IconButton, Table } from '@material-ui/core'
import CompanyInnerTable from './companyInnerTable'

interface ITaxOrderTableRowProps {
  taxOrder: ITaxOrder
  handleTaxOrderDelete: any
  widgetType?: number
}

interface ICompanyTableRowProps {
  taxOrder: any
  handleTaxOrderDelete?: any
  widgetType?: number
  isArchive?: boolean
}

const getFilersName = (taxOrder: ITaxOrder) =>
  taxOrder.company
    ? taxOrder.company.name || ''
    : taxOrder.client
    ? (taxOrder.client?.first_name || '') +
      ' ' +
      (taxOrder.client?.middle_name || '') +
      ' ' +
      (taxOrder.client?.last_name || '')
    : ''

const TaxOrderTableRow: React.FC<ITaxOrderTableRowProps> = ({ widgetType, taxOrder, handleTaxOrderDelete }) => {
  // const { reloadData } = React.useContext(PagedDataContext)
  // // const { history } = useRouter()

  // const handleTaxOrderDelete = React.useCallback(
  //   async (taxOrder: ITaxOrder) => {
  //     const payload = {
  //       customer_widget: 1,
  //       company_id: taxOrder?.company_id || 0,
  //     }
  //     try {
  //       await archiveTaxCompaines(payload)
  //       reloadData()
  //     } catch (err) {
  //       showApiResponseError(err, 'Failed to delete tax order')
  //     }
  //   },
  //   [reloadData]
  // )

  // const handleEdit = id => {
  //   history.push(`/tax-orders/${id}`)
  // }

  return (
    <TableRow hover style={{ cursor: 'pointer' }}>
      <TableCell>{taxOrder.priority}</TableCell>
      <TableCell>{getFilersName(taxOrder)}</TableCell>
      <TableCell>{taxOrder.tax_year}</TableCell>
      <TableCell>
        {toTitleCase(taxOrder.tax_order_type)} {toTitleCase(taxOrder.service_type || '')}
      </TableCell>
      {/* <TableCell>
        {taxOrder.accountant?.first_name} {taxOrder.accountant?.last_name}
      </TableCell> */}
      <TableCell>
        {taxOrder.tax_associate?.first_name} {taxOrder.tax_associate?.last_name}
      </TableCell>
      <TableCell>
        {taxOrder.tax_controller?.first_name} {taxOrder.tax_controller?.last_name}
      </TableCell>
      <TableCell>{taxOrder.tax_order_stage && taxOrder.tax_order_stage.title}</TableCell>
      <TableCell title={formatDateTimeToHumanFriendly(taxOrder.creation_time || '')} style={{ whiteSpace: 'nowrap' }}>
        {getTimeFromNow(taxOrder.creation_time || '')}
      </TableCell>
      <TableCell
        title={taxOrder?.last_updated ? formatDateTimeToHumanFriendly(taxOrder?.last_updated) : ''}
        style={{ whiteSpace: 'nowrap' }}
      >
        {getTimeFromNow(taxOrder?.last_updated || '')}
      </TableCell>
      <TableCell title={formatDateTimeToHumanFriendly(taxOrder.due_date)} style={{ whiteSpace: 'nowrap' }}>
        {getDueDateWithYear(taxOrder.due_date)}
      </TableCell>

      <TableCell style={{ display: 'flex', height: '100%', minWidth: 40 }}>
        <TableActionButton style={{ width: 40, height: 40, marginRight: '0.5rem' }}>
          <Link to={`/tax-orders/${taxOrder.id}`} target="_blank" rel="noreferrer">
            <OpenInNew data-testid="newTab" color="primary" />
          </Link>
        </TableActionButton>
        {widgetType === CUSTOM_TAX_WIDGET_TYPES?.previous_clients ? (
          <TableActionButton
            onClick={event => {
              handleTaxOrderDelete()
              event.stopPropagation()
            }}
            style={{ width: 40, height: 40 }}
          >
            <DeleteIcon />
          </TableActionButton>
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  )
}

const CompanyTableRow: React.FC<ICompanyTableRowProps> = ({
  taxOrder,
  handleTaxOrderDelete,
  widgetType,
  isArchive,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleOrderDelete = async () => {
    try {
      await handleTaxOrderDelete()
      setShowDeleteDialog(false)
    } catch (error) {
      notify.error(error)
      console.error(error)
    }
  }

  return (
    <>
      <DeleteConfirmationDialog
        itemName={
          <React.Fragment>
            {taxOrder?.id}# {taxOrder?.name}
          </React.Fragment>
        }
        onClose={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        onDelete={() => handleOrderDelete()}
        actionText="Archive"
      />
      <TableRow hover style={{ cursor: 'pointer' }}>
        <TableCell>{taxOrder?.id}</TableCell>
        <TableCell>{taxOrder?.name}</TableCell>
        <TableCell>{taxOrder?.file_date ? formatDateToHumanFriendly(taxOrder?.file_date) : ''}</TableCell>
        <TableCell style={{ display: 'flex', height: '100%', minWidth: 40 }}>
          <TableActionButton style={{ width: 40, height: 40, marginRight: '0.5rem' }}>
            <Link to={`/company/${taxOrder.id}`} target="_blank" rel="noreferrer">
              <OpenInNew data-testid="newTab" color="primary" />
            </Link>
          </TableActionButton>
          {(widgetType === CUSTOM_TAX_WIDGET_TYPES?.previous_clients ||
            widgetType === CUSTOM_TAX_WIDGET_TYPES?.untracked_subscription) &&
          !isArchive ? (
            <TableActionButton
              onClick={event => {
                // handleTaxOrderDelete()
                setShowDeleteDialog(true)
                event.stopPropagation()
              }}
              style={{ width: 40, height: 40 }}
            >
              <DeleteIcon />
            </TableActionButton>
          ) : (
            ''
          )}
          {widgetType === CUSTOM_TAX_WIDGET_TYPES?.untracked_subscription ? (
            <TableActionButton style={{ width: 40, height: 40, marginLeft: '0.5rem' }}>
              <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableActionButton>
          ) : (
            ''
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="un track subscription">
              <CompanyInnerTable customerWidget={widgetType} companyId={taxOrder?.id} />
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { TaxOrderTableRow, CompanyTableRow }
