import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '1.3rem',
      },

      '& .search-filed-container': {
        background: '#f7f8f9',
        borderRadius: '4px',
        minHeight: '56px',
        display: 'flex',
        justifyContent: 'center',

        '& .MuiInputBase-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '10.5px',
          },
        },
      },

      '& .add-btn': {
        background: '#307abc',
        color: '#fff',
        margin: '0 1rem 1.3rem 0',
      },
      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },
    },

    autocompleteField: {
      padding: 0,
    },
    formControl1: {
      margin: '0',
      minWidth: '250px',
    },

    textBox: {
      background: '#f6f8fb',
      minHeight: '140px',
      padding: '1rem',

      '& .address-box': {
        margin: '0',
        padding: '0',

        '& .MuiTypography-root': {
          color: '##979797',
          fontSize: '14px',
          fontFamily: 'Roboto',
        },

        '& .MuiPaper-root': {
          minHeight: 'auto',
          padding: 0,
          margin: 0,
          background: 'transparent',
          boxShadow: 'none',

          '& div': {
            padding: 0,
            margin: 0,
          },
        },
      },

      '& .bottom-space': {
        '&:not(:last-child)': {
          marginBottom: '1rem',
        },
      },
    },

    commonRow: {
      margin: '1.35rem 0',
      padding: '1rem 1.25rem',
      display: 'flex',
      backgroundColor: '#f7f8f9',

      '& .offer-heading': {
        fontSize: '0.95rem',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 'normal',
        paddingBottom: '0.5rem',
      },
    },
    alertNoteText: {
      color: 'gray',
    },
    footerBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      marginRight: '0.5rem',
      marginTop: '1rem',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },

      '& div': {
        width: '150px',
      },

      '& .text': {
        color: '#788190a6',
        fontSize: '0.8rem',
        margin: 0,

        [theme.breakpoints.down('sm')]: {
          marginBottom: '1rem',
        },
      },
    },
    textArea: {
      '& .MuiFormLabel-root': {
        fontSize: '0.9rem',

        [theme.breakpoints.down('sm')]: {
          fontSize: '0.75rem',
        },
      },
    },
  })
)
