import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'sendTime', label: 'Send Time', style: {}, sortable: true },
  { id: 'configType', label: 'Config Type', style: {}, sortable: true },
  { id: 'message', label: 'Message', style: { width: '40%' }, sortable: true },
  { id: 'status', label: 'Status', style: {}, sortable: true },
  { id: 'updateDetails', label: 'Update Details', style: { width: '25%' }, sortable: true },
  { id: 'action', label: 'Action', style: { width: '15%' }, sortable: true },
]

const ReminderScheduleTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default ReminderScheduleTableHead
