import { IPagedTableHook, PagedTable } from '@jetkit/react'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DashboardRow, { IDashboardRow } from 'components/tasks/dashboardRow'
import TasksTableHead from 'components/tasks/tasksTableHead'
import * as React from 'react'
import { useStyles } from 'components/dashboard/styles'
import { skeletons } from 'components/common/table'
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core'

interface DashboardPagedTableProps {
  pagedTable: IPagedTableHook<IDashboardRow>
  background?: string
}

export const DashboardPagedTable = ({ pagedTable, background }: DashboardPagedTableProps) => {
  const classes = useStyles()

  return (
    <div className={classes.tableContainer}>
      <TableLoadingIndicator isLoading={pagedTable.isLoading} background={background} />
      {pagedTable.isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            <TasksTableHead />
            <TableBody>
              {skeletons({ numberOfColumns: 6, sizes: [120, 200, 120, 120, 200, 120], numberOfRows: 6 })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <PagedTable
            {...pagedTable.renderProps}
            renderRow={value => <DashboardRow data={value} />}
            header={<TasksTableHead />}
            colSpan={7}
            emptyRowText={"Couldn't Find Any Tasks & Orders Matching Your Criteria"}
          />
        </TableContainer>
      )}
    </div>
  )
}
