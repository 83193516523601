import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { useStyles } from './style'

const CompanyCardSkeleton = () => {
  const classes = useStyles()
  return (
    <div className={classes.companyCard}>
      <div className="company-card-section">
        <div className="img-text-inline">
          <Skeleton className="avatar" variant="circle" width={40} height={40} />
          <span>
            <Skeleton variant="text" width={125} />
            <Skeleton variant="text" width={125} />
            <Skeleton variant="text" width={125} />
          </span>
        </div>
        <span className="detail-text">
          <Skeleton variant="text" width={60} />
        </span>
      </div>
    </div>
  )
}

export default CompanyCardSkeleton
