import { IUser } from 'models/user'
import * as React from 'react'
import { fetchStaff } from 'api/staff'

export const useStaff = () => {
  const [staff, setStaff] = React.useState<IUser[]>([])

  const loadStaff = React.useCallback(async () => {
    const staff = await fetchStaff()
    setStaff(staff)
  }, [])

  React.useEffect(() => {
    loadStaff()
  }, [loadStaff])

  return {
    staff,
  }
}
