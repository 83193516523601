import * as React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import copyToClipboard from 'utils/copyToClipboard'

interface ICopyButtonProps {
  valueToCopy?: string
}

const useStyles = makeStyles({
  iconButton: {
    position: 'absolute',
    top: 18,
    right: 20,
    zIndex: 10,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
  },
})

const CopyButton = ({ valueToCopy }: ICopyButtonProps) => {
  const classes = useStyles()

  const handleCopy = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      copyToClipboard(valueToCopy)
    },
    [valueToCopy]
  )

  return (
    <IconButton disableRipple className={classes.iconButton} onClick={handleCopy}>
      <FileCopyIcon width={24} height={24} />
    </IconButton>
  )
}

export default CopyButton
