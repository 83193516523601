import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  makeStyles,
  createStyles,
  Checkbox,
  Tab,
  withStyles,
  Theme,
  Tabs,
} from '@material-ui/core'
import React, { useState } from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: '120px',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
)

const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    // marginBottom: '1rem',
  },
  indicator: {
    backgroundColor: '#407ace',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      color: '#292929',
      fontFamily: 'Roboto',

      '&:hover': {
        color: '#407ace',
        opacity: 1,
      },
      '&$selected': {
        color: '#407ace',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#407ace',
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

interface StyledTabProps {
  label: string
  value?: number
}

const FilterDays = ({ tabValue, setTabValue }) => {
  const [day, setDay] = useState('')
  const classes = useStyles()

  const handleSelectChange = event => {
    setDay(event.target.value)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <div className={classes.mainContainer}>
      <AntTabs value={tabValue} onChange={handleChange} aria-label="ant example">
        <AntTab label="Today" value={0} />
        <AntTab label="Last 7 Days" value={7} />
        <AntTab label="Last 30 days" value={30} />
        <AntTab label="365 days" value={365} />
      </AntTabs>

      <FormControl variant="outlined" className={classes.formControl}>
        <FormControlLabel control={<Checkbox color="primary" />} label="Complete" />
      </FormControl>

      <FormControl className={classes.formControl}>
        <Select
          value={day}
          onChange={handleSelectChange}
          displayEmpty
          className=""
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={1}>Yesterday</MenuItem>
          <MenuItem value={2}>Tomorrow</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default FilterDays
