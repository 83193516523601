/* eslint-disable complexity */
import CircularProgress from '@material-ui/core/CircularProgress'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { fetchClient } from 'api/clients'
import { createOwner, deleteOwner } from 'api/companies'
import DocumentUploads from 'components/common/assets/documentUploads'
import PrimaryButton from 'components/common/buttons/primaryButton'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'
import { IOwnerSummary } from 'components/companies/companyOwners'
import AddOwnerDialog from 'components/companies/companyOwners/AddOwnerDialog'
import CompanyOwnersTable from 'components/companies/companyOwners/CompanyOwnersTable'
import NewOwnerDialog from 'components/companies/companyOwners/newOwnerDialog'
import useCompanyOwners from 'components/companies/hooks/companyOwners'
import CompanyInfo from 'components/taxOrders/details/companyInfo'
import PendingQuestions from 'components/taxOrders/details/pendingQuestions'
import useTaxOrders from 'components/taxOrders/hooks'
import deepcopy from 'deepcopy'
import { IDetailedCompanyResponse } from 'models/company'
import { ICompanyOwner } from 'models/user'
import * as React from 'react'
import useRouter from 'use-react-router'
import { CHECKLIST_QUESTION_TYPE, USERTYPES } from 'utils/constant'
import { notEmpty, removeNullAndUndefined } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showErrorNotification from 'utils/showErrorNotification'
import Invoices from './invoices'
import TaxOrderLabels from './labels'
import ClientInfo from 'components/taxOrders/details/clientInfo'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import { NotesTableContext } from 'pages/orders/orderDetails'
import Notes from 'components/common/notes/notes'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { INote } from 'models/note'
import { fetchNotes } from 'api/note'
import { usePagedTable } from '@jetkit/react'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import { ISuggestionAttribute } from 'components/interfaces'
import AffiliatedTable from './affiliatedTable'
import TaxMissingItems from './taxMissingItems'
import { useStyles } from './style'
import TaxOrderTasks from './taxOrderTasks'
import useUser from 'hooks/useUser'
import {
  getCheckListQues,
  getTaxOrderPendingNotification,
  getTaxQuesResolvedAccountants,
  IPendingNotifications,
} from 'api/taxOrders'
import { Badge, Chip, Grid } from '@material-ui/core'
import { SettingsBackupRestoreOutlined } from '@material-ui/icons'

import { capitalizeString } from 'components/common/functions'

import EditableTaxOrderComponent from './editableTaxOrderField'
import CheckList from './checkList'
import BOQuestions from './boQues'
import BONotes from './boNotes'
import TaxDocumentUploads from './taxQuestionTable/documentUpload'
import ClientMessages from './clientMessages'

// type TabType = 'cpa_questions' | 'labels' | 'bo_question' | 'order_info' | 'bo_notes' | 'client_messages'
type TabType = 'cpa_questions' | 'labels' | 'bo_question' | 'order_info' | 'bo_notes'

interface ITaxOrderDetailsProps {
  classes?: Record<string, string>
}

const TaxOrderDetails: React.FC<ITaxOrderDetailsProps> = props => {
  const classes = useStyles(props)
  const { user } = useUser()

  const [pendingNotifictionObj, setPendingNotifictionObj] = React.useState<IPendingNotifications>()
  const [tab, setTab] = React.useState<TabType | undefined>()
  const [checkList, setCheckList] = React.useState<any>()

  const router = useRouter<{ id: string | undefined }>()
  const id: number | undefined = parseInt(router.match.params.id || '')

  const [notesVisible, setNotesVisible] = React.useState<boolean>(true)
  const onOpenNotesClick = React.useCallback(() => setNotesVisible(true), [])
  const defaultSearchOptions: NoteEntities = React.useMemo(
    (): NoteEntities => ({
      types: ['taxorder'],
      tax_order_id: id,
    }),
    [id]
  )
  const [searchOptions, setSearchOptions] = React.useState<NoteEntities>(defaultSearchOptions)

  const setSearchQuery = React.useCallback(
    (newQuery: string) => setSearchOptions(prevState => ({ ...prevState, query: newQuery })),
    [setSearchOptions]
  )

  const memoApiCall = React.useMemo(() => fetchNotes(removeNullAndUndefined(searchOptions)), [searchOptions])

  const pagedTable = usePagedTable<INote>({
    apiCall: memoApiCall,
  })

  const {
    handleTaxOrderUpdate,
    isSendingRequest,
    editableTaxOrder,
    editableCompany,
    fetchTaxOrder,
    handleCompanyChanges,
    handleTaxOrderChanges,
    open,
    setOpen,
    setQuesResolAccountants,
    quesResolAccountants,
  } = useTaxOrders()

  const {
    ownerFields,
    companyOwners,
    companyRoles,
    fetchAndSetCompanyOwners,
    clearOwnerState,
    toggleRole,
    onOwnerFieldsChange,
    handleEditButtonClick,
    handleOwnerDeleteClick,
    toggleIsNewOwnerDialogOpen,
    isNewOwnerDialogOpen,
    setIsNewOwnerDialogOpen,
    onOwnerEdit,
    fetchAndSetCompanyRoles,
    setIsLoading,
    isLoading,
    getRoleById,
    shouldUpdateOwnersOnCompanyLevel,
    setUpdateOwnersOnCompanyLevel,
    toggleUpdateLevelForCompanyOwners,
    isAddOwnerDialogOpen,
    isEditModeInAddAddressDialog,
    activeOwner,
    handleModalClose,
    setOwnerToBeDeleted,
    ownerIdToBeDeleted,
    shouldShowDeleteConfirmationDialog,
    setShouldShowDeleteConfirmationDialog,
  } = useCompanyOwners({ company: editableCompany })

  const [taxOrderId, setTaxOrderId] = React.useState<number | undefined>()
  // useEffect to fetch the tax order details
  React.useEffect(() => {
    if (!router.match.params.id) {
      router.history.push('/')
      return
    }
    const id: number | undefined = parseInt(router.match.params.id)
    if (isNaN(id)) {
      router.history.push('/')
      return
    }
    fetchTaxOrder(id)
    setTaxOrderId(id)
  }, [router.history, router.match.params.id, fetchTaxOrder, isSendingRequest])

  // useEffect to set the current tab
  React.useEffect(() => {
    if (editableTaxOrder) {
      setTab('order_info')
      // setTab('bo_question')
      // setTab(editableTaxOrder.tax_order_type === 'business' ? 'company_order_info' : 'client_order_info')
      // setTab(editableTaxOrder.tax_order_type === 'business' ? 'companyInfo' : 'clientInfo')
    }
  }, [editableTaxOrder])

  const getCheckList = React.useCallback(async (taxOrderType, id) => {
    try {
      const result = await getCheckListQues(taxOrderType, id)
      setCheckList(result)
    } catch (error) {}
  }, [])

  React.useEffect(() => {
    if (editableTaxOrder?.tax_order_type && router.match.params.id) {
      getCheckList(CHECKLIST_QUESTION_TYPE[editableTaxOrder?.tax_order_type], router.match.params.id)
    }
  }, [editableTaxOrder?.tax_order_type, getCheckList, router.match.params.id])

  React.useEffect(() => {
    if (id || taxOrderId) {
      pendingNotifications(id || taxOrderId)
    }
  }, [id, taxOrderId])

  const pendingNotifications = async (id: any) => {
    try {
      const result = await getTaxOrderPendingNotification(id)
      setPendingNotifictionObj(result)
      console.log('result', result)
    } catch (error) {
      showErrorNotification('Tax order does not have pending notifications')
    }
  }

  const handleUpdate = React.useCallback(async () => {
    await handleTaxOrderUpdate()
    pagedTable.reloadData()
  }, [handleTaxOrderUpdate])

  const updateOwnersOnOrderLevel = React.useCallback(async () => {
    if (!editableTaxOrder) {
      showErrorNotification('Tax order does not exist')
      return
    }
    if (!editableTaxOrder.company_details) return
    const companyDetails: IDetailedCompanyResponse = deepcopy(editableTaxOrder.company_details)
    // find the user to update by id and update its No of shares and roles
    const userToUpdate = companyDetails.company_users.find(user => user.user.id === ownerFields.user_id)
    if (!userToUpdate) return
    userToUpdate.number_of_shares = ownerFields.number_of_shares
    userToUpdate.roles = ownerFields.role_ids.map(getRoleById).filter(notEmpty)

    handleTaxOrderChanges('company_details', companyDetails)
  }, [
    editableTaxOrder,
    ownerFields.number_of_shares,
    ownerFields.role_ids,
    ownerFields.user_id,
    getRoleById,
    handleTaxOrderChanges,
  ])

  const saveEditedOwner = React.useCallback(async () => {
    if (!editableCompany) return
    setIsLoading(true)
    try {
      await createOwner(ownerFields, editableCompany.id)
      fetchAndSetCompanyOwners(editableCompany.id)
      setIsLoading(false)
    } catch (err) {
      showApiResponseError(err, 'Could not create an owner')
      setIsLoading(false)
    }
    // setUpdateOwnersOnCompanyLevel(false)
  }, [fetchAndSetCompanyOwners, ownerFields, setUpdateOwnersOnCompanyLevel, editableCompany, setIsLoading])

  // save the chosen (created owner) or update the existing one (if it already
  // exists among the company owners list
  const saveCreatedOwner = React.useCallback(
    async (newOwner?: IOwnerSummary) => {
      if (!newOwner || !editableCompany) return
      setIsLoading(true)
      const companyUsers: ICompanyOwner[] = deepcopy(editableCompany.company_users)
      // if the owner already exists - just update the existing owner
      if (companyUsers.find(user => user.user.id === ownerFields.user_id)) {
        updateOwnersOnOrderLevel()
        if (shouldUpdateOwnersOnCompanyLevel) {
          saveEditedOwner()
        }
      } else {
        const owner = await fetchClient(newOwner.id)
        const newUser: ICompanyOwner = {
          user: owner,
          number_of_shares: ownerFields.number_of_shares,
          roles: ownerFields.role_ids.map(getRoleById).filter(notEmpty),
          address: ownerFields.address,
        }
        companyUsers.push(newUser)
        handleCompanyChanges('company_users', companyUsers)
        if (shouldUpdateOwnersOnCompanyLevel) {
          saveEditedOwner()
        }
      }
      setIsNewOwnerDialogOpen(false)
      setIsLoading(false)
    },
    [
      editableCompany,
      setIsLoading,
      setIsNewOwnerDialogOpen,
      ownerFields.user_id,
      ownerFields.number_of_shares,
      ownerFields.role_ids,
      ownerFields.address,
      updateOwnersOnOrderLevel,
      shouldUpdateOwnersOnCompanyLevel,
      saveEditedOwner,
      getRoleById,
      handleCompanyChanges,
    ]
  )

  const onEditOwnerButtonClick = React.useCallback(async () => {
    updateOwnersOnOrderLevel()
    if (shouldUpdateOwnersOnCompanyLevel) {
      saveEditedOwner()
    }
    clearOwnerState()
  }, [clearOwnerState, saveEditedOwner, shouldUpdateOwnersOnCompanyLevel, updateOwnersOnOrderLevel])

  const handleSubmitOwnerButtonClick = async () => {
    await onEditOwnerButtonClick()
    handleModalClose()
  }

  // if we close the Delete Confirmation Dialog,
  // reset the owner that needs to be deleted
  // and close the optionsSelectDialog
  const handleOwnerDeleteConfirmationDialogClose = React.useCallback(() => {
    setShouldShowDeleteConfirmationDialog(false)
    setOwnerToBeDeleted(undefined)
  }, [setOwnerToBeDeleted, setShouldShowDeleteConfirmationDialog])

  // do a request to the API endpoint to delete the owner
  const deleteOwnerOnCompanyLevel = React.useCallback(async () => {
    if (!editableCompany || !ownerIdToBeDeleted || !editableCompany) {
      showErrorNotification('Could not delete the owner')
      console.warn('No editable company/ownerIdToBeDeleted/editableCompany')
      return
    }
    setIsLoading(true)
    await deleteOwner(editableCompany.id, ownerIdToBeDeleted)
    setIsLoading(false)
    fetchAndSetCompanyOwners(editableCompany.id)
  }, [editableCompany, ownerIdToBeDeleted, fetchAndSetCompanyOwners, setIsLoading])

  // handle owner deletion either on the company or on the order level
  // if it's company level - we are doing an additional API call right away
  // if only order level we are going to update the backend upon saving the order
  const handleOwnerDeleteConfirmationClick = React.useCallback(
    (companyLevel?: boolean): void => {
      if (!editableCompany) {
        showErrorNotification('Could not delete company owner')
        console.warn('Could not delete company owner. Missing editableTaxOrder')
        return
      }
      let companyUsers: ICompanyOwner[] = deepcopy(editableCompany.company_users)
      companyUsers = companyUsers.filter(user => user.user.id !== ownerIdToBeDeleted)
      if (companyLevel) {
        deleteOwnerOnCompanyLevel()
      }
      handleCompanyChanges('company_users', companyUsers)
      setOwnerToBeDeleted(undefined)
      setShouldShowDeleteConfirmationDialog(false)
    },
    [
      handleCompanyChanges,
      deleteOwnerOnCompanyLevel,
      ownerIdToBeDeleted,
      setOwnerToBeDeleted,
      setShouldShowDeleteConfirmationDialog,
      editableCompany,
    ]
  )

  const selectedTaxOrder: ISuggestionAttribute | undefined = taxOrderId
    ? {
        value: taxOrderId,
        label: `#${taxOrderId} ${editableTaxOrder?.client?.name}${
          editableTaxOrder?.client?.middle_name ? ' ' + editableTaxOrder?.client?.middle_name : ''
        } ${editableTaxOrder?.client?.last_name || ''}`,
      }
    : undefined

  const selectedCompanyTaxOrder: ISuggestionAttribute | undefined = taxOrderId
    ? {
        value: taxOrderId,
        label: `#${taxOrderId} ${editableTaxOrder?.company?.name}`,
      }
    : undefined

  const getUpdatedNotes = () => {
    pagedTable.reloadData()
  }

  return (
    <>
      <div className={classes.container}>
        {taxOrderId && tab ? (
          <React.Fragment>
            <DialogWithConfirmButtons
              onClose={handleOwnerDeleteConfirmationDialogClose}
              open={shouldShowDeleteConfirmationDialog}
              isLoading={isLoading}
              title="Do you want to delete the owner from this order or from this company?"
              firstOptionTitle="Company"
              secondOptionTitle="Order"
              onFirstOptionClick={() => handleOwnerDeleteConfirmationClick(true)}
              onSecondOptionClick={() => handleOwnerDeleteConfirmationClick()}
            />
            <NewOwnerDialog
              open={isNewOwnerDialogOpen}
              onClose={toggleIsNewOwnerDialogOpen}
              companyOwners={companyOwners}
              onOwnerFieldsChange={onOwnerFieldsChange}
              onOwnerEdit={onOwnerEdit}
              fetchCompanyRoles={fetchAndSetCompanyRoles}
              toggleRole={toggleRole}
              onAddOwnerButtonClick={saveCreatedOwner}
              clearOwnerState={clearOwnerState}
              values={ownerFields}
              roles={companyRoles}
              updateOnCompanyLevel={shouldUpdateOwnersOnCompanyLevel}
              shouldShowUpdateLevelOption
              toggleUpdateLevelOption={toggleUpdateLevelForCompanyOwners}
            />
            {activeOwner && (
              <AddOwnerDialog
                shouldShowUpdateLevelOption
                isAddOwnerDialogOpen={isAddOwnerDialogOpen}
                isOnEdit={isEditModeInAddAddressDialog}
                activeOwner={activeOwner}
                handleModalClose={handleModalClose}
                values={ownerFields}
                company={editableCompany ?? undefined}
                roles={companyRoles}
                toggleRole={toggleRole}
                handleSubmitOwnerClick={handleSubmitOwnerButtonClick}
                onOwnerFieldsChange={onOwnerFieldsChange}
                toggleUpdateLevelOption={toggleUpdateLevelForCompanyOwners}
                updateOnCompanyLevel={shouldUpdateOwnersOnCompanyLevel}
              />
            )}

            <div className={classes.titleButtonContainer}>
              <Typography className={classes.title}>
                {`Edit ${editableTaxOrder?.tax_order_type === 'business' ? 'Company' : 'Personal'} Tax Order ${
                  editableTaxOrder ? `#${editableTaxOrder.id}` : ''
                } `}
                <Chip size="medium" label={editableTaxOrder?.service_type} className="service-type" />
              </Typography>

              {/* {editableTaxOrder?.unpaid_subscription ? (
                <Alert severity="error">Tax Subscription is un-paid/passed due</Alert>
              ) : (
                ''
              )} */}

              <PrimaryButton
                startIcon={<SettingsBackupRestoreOutlined />}
                style={{ width: '240px' }}
                onClick={() =>
                  router.history.push(
                    editableTaxOrder && editableTaxOrder.tax_order_type === 'business'
                      ? `/company/${editableTaxOrder?.company_id}`
                      : `/client/${editableTaxOrder?.client?.id}`
                  )
                }
              >
                Redirect to {editableTaxOrder && editableTaxOrder.tax_order_type === 'business' ? 'Company' : 'Client'}
              </PrimaryButton>
            </div>
            {editableTaxOrder?.tax_order_type === 'business' ? (
              <>
                <Typography variant="h5">
                  {editableCompany?.name}
                  <Chip
                    label={'Company Tax Order'}
                    color="secondary"
                    variant="default"
                    size="small"
                    style={{ color: '#fff', marginLeft: '0.5rem' }}
                  />
                </Typography>
                <Typography variant="h6">{editableCompany?.state_of_formation}</Typography>
              </>
            ) : (
              <Typography variant="h5">
                {editableTaxOrder?.client?.name} {editableTaxOrder?.client?.middle_name}{' '}
                {editableTaxOrder?.client?.last_name}
                <Chip
                  label={'Personal Tax Order'}
                  color="primary"
                  variant="default"
                  size="small"
                  style={{ color: '#fff', marginLeft: '0.5rem' }}
                />
              </Typography>
            )}

            {editableTaxOrder ? (
              <Tabs
                value={tab}
                indicatorColor="primary"
                classes={{ scroller: classes.tabs }}
                textColor="primary"
                onChange={(event: React.ChangeEvent<{}>, value: TabType) => setTab(value)}
                variant="scrollable"
                scrollButtons="auto"
                style={{ minHeight: '58px' }}
              >
                <Tab disableRipple classes={{ root: classes.tabRoot }} value="order_info" label="Order Information" />

                <Tab disableRipple classes={{ root: classes.tabRoot }} value="bo_question" label="BO Questions" />
                <Tab disableRipple classes={{ root: classes.tabRoot }} value="bo_notes" label="BO Notes" />
                <Tab
                  disableRipple
                  classes={{ root: classes.tabRoot }}
                  value="cpa_questions"
                  label={
                    <Badge badgeContent={pendingNotifictionObj?.pending_questions_count} color="error">
                      <span className="badge-text">CPA Questions</span>
                    </Badge>
                  }
                />
                <Tab disableRipple classes={{ root: classes.tabRoot }} value="labels" label="Labels" />
                {/* <Tab disableRipple classes={{ root: classes.tabRoot }} value="client_messages" label="Client Messages" /> */}
              </Tabs>
            ) : (
              ''
            )}
            {console.log(editableTaxOrder?.reminder_sequence, 'TAXORDER DETAILS')}
            <div className={classes.contentPanel} style={{ paddingRight: notesVisible ? 0 : 'revert-layer' }}>
              {tab === 'order_info' && editableTaxOrder && (
                <Grid container spacing={0}>
                  <Grid item xs={notesVisible ? 8 : 12}>
                    <EditableTaxOrderComponent
                      open={open}
                      setOpen={setOpen}
                      editableTaxOrder={editableTaxOrder}
                      handleTaxOrderChanges={handleTaxOrderChanges}
                      isSendingRequest={isSendingRequest}
                      handleUpdate={handleUpdate}
                      // accountants={accountants}
                      // state={state}
                      // handleChange={handleChange}
                      setQuesResolAccountants={setQuesResolAccountants}
                      quesResolAccountants={quesResolAccountants}
                    />
                    {editableTaxOrder.tax_order_type === 'business' && editableCompany ? (
                      <>
                        <CompanyInfo handleCompanyChanges={handleCompanyChanges} companyDetails={editableCompany} />
                        <br />
                        <CompanyOwnersTable
                          classes={{ ownersTable: classes.ownersTable }}
                          companyOwners={editableCompany?.company_users}
                          company={editableCompany}
                          handleEditButtonClick={handleEditButtonClick}
                          onOwnerDelete={handleOwnerDeleteClick}
                          onAddOwnerClick={toggleIsNewOwnerDialogOpen}
                        />
                      </>
                    ) : (
                      <ClientInfo taxOrder={editableTaxOrder} handleTaxOrderChanges={handleTaxOrderChanges} />
                    )}

                    <br />
                    <CheckList getUpdatedNotes={getUpdatedNotes} quesList={checkList} />
                    {/* <h1>Checklist * New Feature</h1>
                  <br /> */}
                    <TaxOrderTasks isSendingRequest={isSendingRequest} taxOrder={editableTaxOrder} />
                    <br />
                    <div className={classes.documentUploads}>
                      <DocumentUploads
                        type={editableTaxOrder.tax_order_type === 'business' ? 'tax_order' : 'personal'}
                        objectId={
                          editableTaxOrder.tax_order_type === 'business'
                            ? editableTaxOrder?.id
                            : editableTaxOrder?.client?.id || 0
                        }
                        taxId={editableTaxOrder?.id}
                      />
                    </div>
                    <br />
                    {/* <br />
                    <div className={classes.documentUploads}>
                      <TaxDocumentUploads
                        type={editableTaxOrder.tax_order_type === 'business' ? 'tax_order' : 'personal'}
                        objectId={
                          editableTaxOrder.tax_order_type === 'business'
                            ? editableTaxOrder?.id
                            : editableTaxOrder?.client?.id || 0
                        }
                        taxId={editableTaxOrder?.id}
                      />
                    </div>
                    <br /> */}
                    <TaxMissingItems />
                    <br />
                    <AffiliatedTable
                      client_id={editableTaxOrder.tax_order_type === 'business' ? 0 : editableTaxOrder?.client?.id || 0}
                      company_id={
                        editableTaxOrder.tax_order_type === 'business' ? editableTaxOrder?.company?.id || 0 : 0
                      }
                    />
                    <br />
                    {user?.user_type !== USERTYPES?.accountant ? <Invoices taxOrderId={taxOrderId} /> : ''}
                  </Grid>
                  <Grid item xs={notesVisible ? 4 : 'auto'} style={{ display: 'flex' }} justify="flex-end">
                    {notesVisible ? (
                      <NoteEntitiesContext.Provider
                        value={{
                          types: ['taxorder'],
                          taxorder:
                            editableTaxOrder.tax_order_type === 'business' ? selectedCompanyTaxOrder : selectedTaxOrder,
                        }}
                      >
                        <NotesTableContext.Provider
                          value={{
                            pagedTable,
                            searchOptions,
                            setSearchQuery,
                            setSearchOptions,
                          }}
                        >
                          <Notes
                            setModalIsOpen={setNotesVisible}
                            classes={{ mainContainer: classes.notesContainer }}
                            tableBodyClassName={classes.notesTable}
                          />
                        </NotesTableContext.Provider>
                      </NoteEntitiesContext.Provider>
                    ) : (
                      <div className={classes.taxNotes}>
                        <OpenNotesButton onClick={onOpenNotesClick} />
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}

              {tab === 'cpa_questions' && <PendingQuestions taxOrderId={taxOrderId} />}
              {tab === 'bo_question' && (
                <BOQuestions reminderSequence={editableTaxOrder?.reminder_sequence} taxOrderId={taxOrderId} />
              )}
              {tab === 'bo_notes' && <BONotes taxOrderId={taxOrderId} />}
              {/* {tab === 'client_messages' && <ClientMessages taxOrderId={taxOrderId} />} */}
              {tab === 'labels' && (
                <div className={classes.documentUploads}>
                  <TaxOrderLabels taxOrderId={taxOrderId} />
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <CircularProgress style={{ alignSelf: 'center' }} />
        )}
      </div>
    </>
  )
}

export default TaxOrderDetails

// TODO: #376 should be broken down into separate personal tax order component and business tax order component
