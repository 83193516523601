import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { paginateStatus } from 'api/widgets'
import { deletePredefinedTask } from 'api/ordertype'

import CreateClient from 'components/widgets/create'
import ClientTableHead from 'components/widgets/table/head'
import ClientTableRow from 'components/widgets/table/row'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import { IClient, IStates } from 'models/user'
import * as React from 'react'
import { theme } from 'theme'
import useRouter from 'use-react-router'
import { updatePageVisitsCount } from 'utils/user'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.title.fontFamily,
    fontWeight: 600,
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
})

interface IClientProps extends WithStyles<typeof styles> {}

const Widgets: React.FC<IClientProps> = ({ classes }) => {
  const [query, setQuery] = React.useState('')
  const [clientToDelete, setClientToDelete] = React.useState<IStates>()
  const [deleteClientDialogShown, setDeleteClientDialogShown] = React.useState(false)
  const router = useRouter()

  const apiCall = React.useMemo(() => paginateStatus()({ query }), [query])
  const pagedTable = usePagedTable<any>({ apiCall })

  const onEditClick = React.useCallback(
    (client: any) => {
      if (!client.id) return
      router.history.push(`/widget/${client.id}`)
    },
    [router.history]
  )

  const onDeleteClick = React.useCallback(
    (client: any) => {
      if (!client.id) return
      setClientToDelete(client)
      setDeleteClientDialogShown(true)
    },
    [setClientToDelete, setDeleteClientDialogShown]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteClientDialogShown}
        close={() => setDeleteClientDialogShown(false)}
        deletionObject={clientToDelete}
        deletionRequest={deletePredefinedTask}
        onRequestSuccessful={onDeleteSuccessful}
      />
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>Widgets</Typography>
        <CreateClient fetchClients={pagedTable.reloadData} />
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar onChange={setQuery} placeholder="Search by name" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            <ClientTableHead />
            <TableBody>{skeletons({ numberOfColumns: 3, numberOfRows: 6 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No data"
            {...pagedTable.renderProps}
            renderRow={(client: IStates) => (
              <ClientTableRow
                states={client}
                onEditClick={() => onEditClick(client)}
                onDeleteClick={() => onDeleteClick(client)}
              ></ClientTableRow>
            )}
            header={<ClientTableHead />}
            colSpan={6}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(Widgets)
function setDeleteClientDialogShown(arg0: boolean) {
  throw new Error('Function not implemented.')
}
