import { toTitleCase } from '@jetkit/react'
import { Checkbox, GridList, ListItem, Tooltip } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import classNames from 'classnames'
import useStyles from 'components/common/styles/createDialogStyles'
import { ICompanyRole } from 'models/company'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

export interface RolesSelectorProps {
  /**
   * all available roles to select from
   */
  roleOptions: ICompanyRole[]

  /**
   * is `true` if all nonshareholder roles are selected and exactly one
   * shareholder role is selected
   */
  areAllRolesSelected?: boolean

  /**
   * FIXME: #335 this must not be optional BR-489
   * maybe areAllRolesSelected also should not be optional
   */
  selectAllRoles?: () => void

  selectedRoleIds: number[]
  toggleRole: (id: number) => void

  hasZeroShareholderRolesSelected: boolean
}

export const RolesSelector: React.FC<RolesSelectorProps> = ({
  roleOptions,
  selectAllRoles,
  areAllRolesSelected,
  hasZeroShareholderRolesSelected,
  toggleRole,
  selectedRoleIds,
}) => {
  const classes = useStyles()

  return (
    <>
      <GridList cols={2} cellHeight={24} classes={{ root: classes.ownerModalCheckboxContainer }}>
        {roleOptions.map(role => {
          const hasShareholderRoleSelected = !hasZeroShareholderRolesSelected
          const isShareholderRole = role.can_hold_shares
          const isRoleSelected = selectedRoleIds.includes(role.id)
          const isItShareholderRoleThatIsNotSelected = isShareholderRole && !isRoleSelected

          /**
           * only one shareholder role can be selected.
           * because of that we want to disable shareholder role checkbox,
           * if another one is already selected and current role
           * is not the one that is selected
           */
          const isRoleCheckboxDisabled = false //hasShareholderRoleSelected && isItShareholderRoleThatIsNotSelected

          return (
            <ListItem
              key={role.id}
              disabled={isRoleCheckboxDisabled}
              data-testid={role.title}
              className={classes.ownerModalListElement}
              onClick={() => toggleRole(role.id)}
            >
              <Checkbox
                color="primary"
                checked={isRoleSelected}
                // `value` prop is added here for easier testing.
                // because MUI only changes svg image when checkbox is clicked
                value={isRoleSelected}
                style={{ padding: 0, marginRight: 10 }}
              />
              {toTitleCase(role.title)}
              {role.can_hold_shares && (
                <Tooltip classes={{ tooltip: classes.tooltip }} title="This role allows shareholding">
                  <AttachMoneyIcon color="primary" />
                </Tooltip>
              )}
            </ListItem>
          )
        })}
      </GridList>
      <div
        className={classNames(classes.ownerModalListElement, classes.dialogOneLineCheckboxContainer)}
        onClick={selectAllRoles}
      >
        <Checkbox
          color="primary"
          checked={areAllRolesSelected}
          // `value` prop is added here for easier testing.
          // because MUI only changes svg image when checkbox is clicked
          value={areAllRolesSelected}
          style={{ padding: 0, marginRight: 10 }}
          data-testid={TestID.ALL_ROLES_SELECTED_CHECKBOX}
        />
        <div>Select All Roles</div>
      </div>
    </>
  )
}
