import { deleteTask } from 'api/task'
import { TasksProgressContext } from 'pages/dashboard/tasksProgress'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { TasksContext } from 'contexts/tasks'

function useDeleteTask() {
  const { getProgress } = React.useContext(TasksProgressContext)
  const tasksContext = React.useContext(TasksContext)
  const [showDelete, setShowDelete] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  const deleteTaskCallback = React.useCallback(
    async (taskId: number) => {
      try {
        setIsDeleting(true)
        await deleteTask(taskId)
        getProgress()
        showSuccessNotification('Task deleted')
        tasksContext.reloadTasks()
        setShowDelete(false)
      } catch (error) {
        showApiResponseError(error, 'Something went wrong')
      }
      setIsDeleting(false)
    },
    [tasksContext, getProgress]
  )

  return {
    isDeleting,
    showDelete,
    setShowDelete,
    deleteTaskCallback,
  }
}

export default useDeleteTask
