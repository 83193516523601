import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import { AssetsUploadCallBackContext, IDocumentUploadProps } from 'components/common/assets/documentUploads'
import AssetUpload from 'components/common/assetUpload'
import * as React from 'react'
import { UploadFileToS3Args } from 'models/asset'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      width: 150,
    },
    buttonLabel: {
      textTransform: 'none',
    },
    tableCell: {
      textTransform: 'uppercase',
    },
    assetUploadCell: {
      width: 200,
      padding: 6,
    },
    uploadDateCell: {
      width: 200,
    },
  })
)

interface IAssetTableHeader extends IDocumentUploadProps {
  onUploadComplete?: (args: UploadFileToS3Args) => void
  isDownload?: boolean
}

function AssetTableHeader({ type, objectId, onUploadComplete, isDownload = false }: IAssetTableHeader) {
  const UploadRequest = React.useContext(AssetsUploadCallBackContext)
  const classes = useStyles()
  // debugger
  return (
    <TableHead>
      <TableRow>
        {isDownload ? <TableCell className={classes.tableCell}>Id</TableCell> : ''}
        <TableCell className={classes.tableCell}>Document Type</TableCell>

        <TableCell className={classes.tableCell}>Label</TableCell>
        <TableCell className={classes.tableCell}>Notes</TableCell>

        <TableCell className={classNames(classes.tableCell)}>Upload Date</TableCell>
        <TableCell className={classes.tableCell}>{type === 'personal' ? 'Tax Year' : 'Order'}</TableCell>
        {type !== 'personal' && !isDownload && (
          <>
            <TableCell className={classes.tableCell}>Client Doc</TableCell>
            <TableCell className={classes.tableCell}>RA Doc</TableCell>{' '}
            <TableCell className={classes.tableCell}>Address Doc</TableCell>{' '}
          </>
        )}

        <TableCell className={classes.tableCell}>Uploaded By</TableCell>

        {!isDownload && (
          <TableCell>
            <AssetUpload
              type={type}
              objectId={objectId}
              onUploadComplete={onUploadComplete}
              UploadRequest={UploadRequest}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
export default AssetTableHeader
