import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

interface ICloseCompanyOrderDialogButtonProps {
  onClose: () => void
  className: string
}

export const CloseDialogButton = ({ onClose, className }: ICloseCompanyOrderDialogButtonProps) => {
  return (
    <IconButton
      data-testid={TestID.DIALOG_CLOSE_ICON}
      disableFocusRipple
      disableRipple
      onClick={onClose}
      className={className}
    >
      <CloseIcon fontSize="large" />
    </IconButton>
  )
}
