/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useStyles } from './style'
import CongransImg from 'img/congratulation.webp'
import { IAnswerResponse, IQuizAnswer, getProfileQuestions, quizsAnswer } from 'api/clientDashboard'
import { ProfileQuestionProps } from 'models/user'
import { getProfileScoreById } from 'api/clientDashboard'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'

// interface QuestionProps {
//   id: number
//   question: string
//   score: number
// }

interface ProfileScore {
  profile_score: number
}

const ProfileModal = ({ open, setOpen, id, companyName }) => {
  const classes = useStyles()
  // const [profileValue, setProfileValue] = useState(30)
  const [stepNumber, setStepNumber] = useState(0)
  // const [stepQuestion, setStepQuestion] = useState('')
  const [profileQuestions, setProfileQuestions] = useState<ProfileQuestionProps[]>([])
  // const [isAnswer, setIsAnswer] = useState<any>(null)

  const [answerResponse, setAnswerResponse] = useState<IAnswerResponse>()

  const [isAnswerYes, setIsAnswerYes] = useState(false)
  const [isAnswerNo, setIsAnswerNo] = useState(false)

  const [profScore, setProfScore] = useState<number>()

  const [value, setValue] = React.useState(null)

  const getProfileScore = useCallback(async () => {
    try {
      const score = await getProfileScoreById(id)
      setProfScore(score?.profile_score)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  const getQuestions = useCallback(async () => {
    try {
      const questions = await getProfileQuestions(id)
      setProfileQuestions(questions)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    getProfileScore()
  }, [getProfileScore])

  useEffect(() => {
    getQuestions()
  }, [getQuestions, open])

  console.log('profscore', profScore)

  const handleChange = async (event: any, answer: any) => {
    setValue(event?.target?.value)

    debugger
    console.log('event?.target?.value', event?.target?.value)
    console.log('answer', answer)

    const payload: IQuizAnswer = {
      company_id: id,
      question_id: answer?.id,
      answer: true,
      answer_id: Number(event?.target?.value),
    }
    try {
      const res = await quizsAnswer(payload)
      setAnswerResponse(res)

      console.log('res', res)

      await getProfileScore()
      setStepNumber(preVal => preVal + 1)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setStepNumber(0)

    window?.location?.reload()
  }

  console.log('sdlsdldsd', id)

  console.log('profileQuestions', profileQuestions)
  console.log('profileQuestions[stepNumber]', profileQuestions[stepNumber])
  console.log('value', value)

  // const handleYesAnswer = async (data: any, val: boolean) => {
  //   setIsAnswerYes(true)

  //   if (val !== null) {
  //     const payload: any = {
  //       company_id: id,
  //       question_id: data?.id,
  //       answer: val,
  //     }
  //     try {
  //       const res = await quizsAnswer(payload)
  //       console.log('res', res)

  //       await getProfileScore()
  //       setStepNumber(preVal => preVal + 1)
  //       setIsAnswerYes(false)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //     setIsAnswerYes(false)
  //   }
  // }

  // const handleNoAnswer = async (data: any, val: boolean) => {
  //   setIsAnswerNo(true)

  //   if (val !== null) {
  //     const payload: any = {
  //       company_id: id,
  //       question_id: data?.id,
  //       answer: val,
  //     }

  //     try {
  //       const res = await quizsAnswer(payload)
  //       console.log('res', res)

  //       await getProfileScore()
  //       setIsAnswerNo(false)

  //       setStepNumber(preVal => preVal + 1)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //     setIsAnswerNo(false)
  //   }
  // }

  // const isAnswerHandler = async () => {
  //   const data = profileQuestions[stepNumber]
  //   const payload: any = {
  //     company_id: id,
  //     question_id: data?.id,
  //     answer: isAnswer,
  //   }
  //   const res = await quizsAnswer(payload)
  //   console.log('res', res)
  //   getProfileScore()
  //   setStepNumber(preVal => preVal + 1)
  // }

  // useEffect(() => {
  //   if (isAnswer) {
  //     isAnswerHandler()
  //   } else {
  //     isAnswerHandler()
  //   }
  // }, [isAnswer])

  // useEffect(() => {
  //   switch (stepNumber) {
  //     case 0:
  //       setStepQuestion('Will this company hire employees in the next 12 months?')
  //       setProfileValue(30)
  //       break
  //     case 1:
  //       setStepQuestion('Will this company accept credit card payments from its clients?')
  //       setProfileValue(40)
  //       break
  //     case 2:
  //       setStepQuestion('Will this company seek outside investors in the next 12 months?')
  //       setProfileValue(50)
  //       break
  //     case 3:
  //       setStepQuestion('Will this company sell tangible products (non-service) in the next 12 months?')
  //       setProfileValue(60)
  //       break
  //     case 4:
  //       setStepQuestion('Will this company have a website?')
  //       setProfileValue(70)
  //       break
  //     case 5:
  //       setStepQuestion('Does this company have an Accountant?')
  //       setProfileValue(80)
  //       break
  //     case 6:
  //       setStepQuestion('Congratulations You are completed your profile!')
  //       setProfileValue(100)
  //       break
  //     default:
  //       break
  //   }
  // }, [stepNumber, stepQuestion])

  // const sum = profScore || 0
  // const setScore = (stepNum: number) => {
  //   //
  //   if (stepNum === 0) {
  //     return sum
  //   } else {
  //     const total = profileQuestions[stepNum - 1]?.score
  //     return sum + total
  //   }
  // }]

  const checkQuestionsRender = (): string => {
    if (profScore === 100) {
      return ''
    } else if (answerResponse?.question) {
      return answerResponse?.question
    } else {
      return profileQuestions[0]?.question?.replace('$COMPANY_NAME$', companyName)
    }
  }

  console.log('profileScoreprofileScore', profScore)

  return (
    <Dialog maxWidth={'xs'} fullWidth={true} className={classes.profileDialog} open={open}>
      <div className="profile-modal">
        <Box top={0} left={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
          <Avatar className="avater">{companyName?.charAt(0).toUpperCase()}</Avatar>
        </Box>
        <h1 className={classes.companyName}>{companyName}</h1>

        {/* {profileQuestions.length === stepNumber ? ( */}
        {profScore === 100 ? (
          <img src={CongransImg} alt="img" className="congrats-img" />
        ) : (
          <div className={classes.account}>
            <CircularProgressWithLabel value={profScore || 0} size={50} className="progress-color" />
            <div className={classes.accountText}>
              <p className={classes.accountPara}>Complete your account</p>
              <p className={classes.accountParaTwo}>Your account {profScore}% complete</p>
            </div>
          </div>
        )}
        <h2 className={classes.employeeHead}>
          {checkQuestionsRender()}

          {/* {answerResponse?.question
            ? answerResponse?.question
            : profileQuestions[0]?.question?.replace('$COMPANY_NAME$', companyName)} */}
          {/* {profileQuestions[stepNumber]?.question?.replace('$COMPANY_NAME$', companyName)} */}
        </h2>
        <div className={classes.buttonSec}>
          {/* {profileQuestions.length === stepNumber ? ( */}
          {profScore === 100 ? (
            <Button variant="contained" color="primary" className="close-btn" onClick={handleClose}>
              Close
            </Button>
          ) : (
            <>
              {/* <ButtonWithLoading
                kind="BRSecondary"
                disabled={isAnswerYes}
                isLoading={isAnswerYes}
                wrapperClassName="confirm-btn"
                onClick={() => {
                  handleYesAnswer(profileQuestions[stepNumber], true)
                }}
              >
                Yes
              </ButtonWithLoading>

              <ButtonWithLoading
                kind="BRSecondary"
                disabled={isAnswerNo}
                isLoading={isAnswerNo}
                wrapperClassName="decline-btn"
                onClick={() => {
                  handleNoAnswer(profileQuestions[stepNumber], false)
                }}
              >
                No
              </ButtonWithLoading> */}
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="answer"
                  name="answer"
                  value={value}
                  onChange={(event: any) =>
                    handleChange(
                      event,
                      answerResponse?.answerOptions?.length ? answerResponse : profileQuestions[stepNumber]
                    )
                  }
                >
                  {answerResponse?.answerOptions?.length
                    ? answerResponse?.answerOptions?.map(item => (
                        <FormControlLabel
                          key={item?.id}
                          value={item?.id?.toString()}
                          control={<Radio />}
                          label={item?.answer_text}
                        />
                      ))
                    : profileQuestions[stepNumber]?.answerOptions?.map(item => (
                        <FormControlLabel
                          key={item?.id}
                          value={item?.id?.toLocaleString()}
                          control={<Radio />}
                          label={item?.answer_text}
                        />
                      ))}
                  {/* {profileQuestions[stepNumber]?.answerOptions?.map(item => (
                  <FormControlLabel value={item?.id} control={<Radio />} label={item?.answer_text} />
                ))} */}
                </RadioGroup>
              </FormControl>
            </>
          )}
        </div>
        {/* {profileQuestions.length !== stepNumber ? (
          <p className={classes.linkText} onClick={() => setStepNumber(preVal => preVal + 1)}>
            Skip for Now
          </p>
        ) : (
          ''
        )} */}
      </div>
    </Dialog>
  )
}

export default ProfileModal

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <p className="inner-text">{`${Math.round(props.value)}%`}</p>
      </Box>
    </Box>
  )
}
