export const EditorApiKey = 'hpy4eu1zytcizpm9rm8uqsi5fqscg72079jyzrw7w3wzrvv5'

export const USERTYPES = {
  admin: '9',
  client: '2',
  employee: '1',
  salesRep: '3',
  accountant: '4',
  salesAgent: '5',
}

export const ORDERSTATUS = {
  orderInitiated: 7,
  completed: 23,
  cancelled: 24,
  Submitted_to_Secretary_of_State: 4, //This status text has been changed to Submitted to Government Agency but Functionality is same
  unverified_call_request: 5,
  unverified: 6,
  order_pending: 8,
  filed_by_state_process_internally: 13,
}

export const ORDERTYPES = {
  businessFormation: 3,
}
export const COLORS = {
  rushOrders: '#EA987A',
  overDue: '#ff00009c',
  registeredAgent: '#CBC3E3',
  orderPaid: '#a3e362',
  internalRushProcessing: '#db635b',
}
export const COMPLIANCE_TASKS_STATUS = {
  upcoming: '0',
  inprogress: '1',
  completed: '2',
  closed: '3',
  removed: '4',
}
// export const COMPLIANCE_TASKS_STATUS = {
//   upcoming: 'upcoming',
//   inprogress: 'inprogress',
//   completed: 'completed',
//   closed: 'closed',
//   removed: 'removed',
// }
export const INVOICE_PAYMENT_STATUS = {
  Paid: 'paid',
  Unpaid: 'unpaid',
  All: 'all',
}
export const VERIFICATION_METHODS = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
}
export const REMINDER_TYPES = {
  sms: 'sms',
  email: 'email',
}

export const LEGALTYPES = {
  llc: 1,
  c_corp: 2,
  s_corp: 3,
  non_profit: 4,
  DBA: 5,
}
export const REMINDER_SCHEDULES_STATUS = {
  Scheduled: 'Scheduled',
  Sent: 'Sent',
  NotSent: 'Not Sent',
}
export const PAYMENT_STATUS = {
  paid: 'Paid',
  unpaid: 'Unpaid',
}
export const PROSPECT_STATUSES = {
  leadReceived: 3,
  pending: 2,
  completed: 4,
}
export const PROMO_CODE_TYPES = {
  percentage: 'percentage',
  fixed: 'fixed',
}

export const LABEL_TYPES = {
  company: 'company',
  taxorder: 'taxorder',
}

export const EMPLOYEE_ROLES = {
  senior: 'senior',
  junior: 'junior',
}

export const WEB_SUBMISSION_TYPE = {
  Archive: 1,
  NotArchive: 0,
}
export const TAX_ORDER_STAGES = {
  Pending: 'Pending',
  Waiting_For_Client_Info: 'Waiting For Client Info',

  Processing: 'Processing',
  // Preparation_In_Progress: 'Preparation In Progress',
  // Waiting_For_Answers_To_Questions: 'Waiting For Answers To Questions',
  // Finalization: 'Finalization',
  // Waiting_For_Signatures: 'Waiting For Signatures',
  // Completed: 'Completed',
  // Cancelled: 'Cancelled',
  New_Questions_Issued: 'New Questions Issued',

  Waiting_For_Answers_To_Questions: 'Waiting for Answers to Questions',
  Questions_Resolved: 'Questions Resolved',
  Review_In_Progress: 'Review in Progress',
  Review_Completed: 'Review Completed',

  Waiting_For_Signatures: 'Waiting For Signatures',
  Client_Review_in_progress: 'Client Review In Progress',
  Finalization: 'Finalization',
  Filed_and_Rejected: 'Filed and Rejected',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Hold: 'Hold',
}

export const TAX_ORDER_STAGES_NUM = {
  1: 'Pending',
  2: 'Processing',
  3: 'Waiting For Client Info',
  4: 'Preparation In Progress',

  5: 'Waiting for Answers to Questions',
  6: 'Review in Progress',
  7: 'Waiting For Signatures',
  8: 'Finalization',
  9: 'Completed',
  10: 'Cancelled',
}
export const taxOrderStatusColors = {
  Pending: '#90D7A0',
  Processing: '#FBA0D9',
  'Waiting For Client Info': '#A18AFF',
  'Preparation In Progress': '#8AB7FF',
  'Waiting for Answers to Questions': '#FF8181',
  'Review in Progress': '#D7E294',
  'Review Completed': '#D7E8',

  'Waiting For Signatures': '#FFD792',
  Finalization: '#FFB793',
  'Filed and Rejected': '#eb5e5e',
  Completed: '#7FB77E',
  Cancelled: '#FC4137',
  'Questions Resolved': '#FC4',
  'Client Review In Progress': '#c3baba',
  'New Questions Issued': '#c7c0ed',
  Hold: '#c7c',
}

export const paymentStatusMappings = {
  '0': 'Not Paid',
  '1': 'Paid',
  '2': 'Declined',
  '3': 'OfflinePaid',
  Paid: 'Paid',
}

export const paymentMethodMappings = {
  '0': 'Credit Card',
  '1': 'Paypal',
  '2': 'Apple Pay',
  '3': 'Google Pay',
}

export const OrderTypes = {
  '0': 'Corporation',
  '1': 'LLC',
  '2': 'DBA',
  '3': 'Non Profit',
  '4': 'Sole Proprietorship',
  '5': 'Partnership',
}

export const WEB_ORDER_TYPES = {
  llc: 1,
  corporation: 0,
}

export const FILE_JOINTLY_TYPES = {
  0: 'No',
  1: 'Yes',
}

export const refreshApiErrorMsg = 'Unable to refresh access token for request'

export const ComplianceColorResponse = {
  '1': 'green',
  '2': 'orange',
  '3': 'red',
}

export const USER_TYPES = {
  '9': 'Admin',
  '2': 'Client',
  '1': 'Employee',
  '3': 'SalesRep',
  '4': 'Accountant',
  '5': 'Sales Agent',
}

export const USER_TYPES_MAPPING = {
  Admin: '9',
  Client: '2',
  Employee: '1',
  SalesRep: '3',
  Accountant: '4',
  'Sales Agent': '5',
}

export const CLIENT_STATUS_TYPE_TEXT = {
  Upcoming: '0',
  Inprogress: '1',
  Completed: '2',
  Closed: '3',
  Removed: '4',
}

export const CLIENT_STATUS_TYPE = {
  '0': 'Upcoming',
  '1': 'Inprogress',
  '2': 'Completed',
  '3': 'Closed',
  '4': 'Removed',
}

export const CLIENT_STATUS_TYPE_COLORS = {
  '0': '#bdd5ff',
  '1': '#FF8181',
  '2': '#D7E294',
  '3': '#FFD792',
  '4': '#90D7A0',
}

export const ORDER_TYPES_ROUTES = {
  '0': 'corp',
  '1': 'llc',
  '2': 'dba',
  '3': 'non-profit',
  '4': 'sole-proprietorship',
  '5': 'partnership',
}

export const FORMS = {
  main_form: 1,
  post_form: 2,
}

export const FIELDS_MIN_LENGTH = {
  ref_code: 3,
  username: 8,
}

export const KPI_ENTITY_TYPES = {
  client: 'client',
  order: 'order',
  company: 'company',
}
export enum PostStepNewIds {
  StepAddressConfirmation = 21,
  StepOwnership = 22,
  StepRegisterAgent = 23,
  StepEINUpgrades = 24,
  StepMailingAddress = 25,
  StepAgreementUpgrade = 26,
  StepRush = 27,
  StepSCorpUpgrade = 28,
  StepShareNumber = 29,
  StepPositionConfirmation = 30,
  StepBank = 31,
  StepTaxBandle = 32,
  StepReviewAndCheckout = 41,
  StepPayment = 42,
  StepThankyou = 43,
}
//These are the last submitted steps of the user on post form
export const POST_STEPS = {
  ein: 'step4',
  registered_agent: 'step2',
  review: 'step5',
  thank_you: 'step21',
}
export const PERSONAL_TAX_STRUCTURE = {
  single: 'Single',
  married_filing_single: 'Married, Filing Single',
  married_filing_jointly: 'Married, Filing Jointly',
}
export enum OrderStepNewIds {
  StepBusinessNameAndState = 1,
  StepContactInformation = 2,
  StepBusinessActivity = 3,
  StepBusinessAddress = 4,
  StepIPostal = 5,
  StepOwnershipCount = 6,
  StepPackages = 7,
  StepOfferUnlocked = 8,
  StepGoldStandard = 9,
  StepReviewAndCheckout = 10,
  StepPayment = 11,
  StepThankyou = 12,
  StepAgreement = 51,
  StepBankAccount = 52,
  StepRush = 53,
  StepEIN = 54,
  StepRocket = 55,
  StepTaxBandle = 56,
}

export const SUBSCRIPTION_STATUS_MAPPING = {
  trialing: 'Trialing',
  active: 'Active',
  past_due: 'Past Due',
  canceled: 'Canceled',
  incomplete_expired: 'Incomplete Expired',
  paused: 'Paused',
  incomplete: 'incomplete',
  unpaid: 'Unpaid',
}
export const STRIPE_SUBSCRIPTION_STATUSES = {
  trialing: 'trialing', //the subscription is in a trial period
  active: 'active', //subscription is currently active and the customer is being charged according to the subscription's pricing plan
  past_due: 'past_due', //customer has missed a payment and the subscription is past due. Stripe will continue to attempt to charge the customer until the payment is successful.
  canceled: 'canceled', //This status indicates that the subscription has been canceled by the customer or the merchant. The subscription will remain active until the end of the current billing cycle, after which it will no longer be charged.
  incomplete: 'incomplete', // subscription is in an incomplete state and requires additional action to be taken. For example, if a payment fails during subscription creation, the subscription will be in an incomplete state until the payment issue is resolved.
  incomplete_expired: 'incomplete_expired', //subscription is in an incomplete state and has expired. This can happen if the customer does not complete the required action within a certain period of time.
  paused: 'paused', // Paused by the user
  unpaid: 'unpaid', // unpaid
}
export const COOKIEE_NAMES = {
  device: 'device',
}
export const OFFER_TAKEN_FROM = {
  remarketing_email: 1,
  file_date_email: 3,
  client_portal: 2,
}
export const PROSPECT_NOTES_CREATORS = {
  Client: 'Client',
  System: 'System',
  Employee: 'Employee',
  Affiliate_Client: 'Affiliate',
}
export const TAX_ORDER_TYPES = {
  Business: 2,
  Personal: 1,
}
export const TAX_ORDER_STATUS = {
  Bookkeeping: 'bookkeeping',
  Tax: 'tax',
}
export const CUSTOM_TAX_WIDGET_TYPES = {
  twenty_one_day_alert: 1,
  untracked_subscription: 2,
  previous_clients: 3,
}

export const complianceType = ['Annual Report', '1099', 'Business license', 'Business tax', 'BOI', 'TCP Puctra']

export const CHECKLIST_QUESTION_TYPE = {
  personal: '1',
  business: '2',
}
export const RESIDENT_MAPPING: Record<string, string> = {
  non_us_resident: 'Non US Resident',
  us_resident: 'US Resident',
}

export const SubscriptionType = {
  stripe: 'stripe',
  quickbook: 'quickbook',
}

export const FAILED_PAYMENT_TYPES = {
  renewal: 'Renewal',
  order: 'Order',
  subscription: 'Subscription',
}

export const CLIENT_COMPANY_STATUS = {
  'Under Review': '#888888',
  Active: '#04a464',
  'In-Active': '#C40C0C',
  Processing: '#FFB22C',
  Hold: '#FF6500',
}

export const MISSING_ITEM_STATUS = {
  NotReceived: '0',
  Received: '1',
  under_review: '2',
  rejected: '3',
}

export const MISSING_ITEM_ALL_STATUS = {
  '0': 'Not Received',
  '1': 'Received',
  '2': 'Under Review',
  '3': 'Rejected',
}

export const User_Type_TaxOrder_Ans = {
  '1': 'Admin',
  '2': 'Client',
}
export const REMINDER_ITEM_STATUS = {
  Active: 'active',
  Complete: 'complete',
}
export const BO_Reminder_Scheduler = {
  'Rush Sequence': '1',
  'Standard Sequence': '2',
}

export const BO_Reminder_Scheduler_New = {
  '1': 'Rush Sequence',
  '2': 'Standard Sequence',
}
