import { OrderTemplateList } from 'pages/orderTemplates/list'
import * as React from 'react'
import TaxOrderDetails from './components/taxOrders/details'
import Client from './pages/client'
import Employee from './pages/employee'
import EditClient from './pages/client/editClient'
import EditEmployee from './pages/employee/editEmployee'

import Companies from './pages/companies'
import Dashboard from './pages/dashboard'
import DocumentEditor from './pages/documents'
import NotificationsPage from './pages/notifications'
import Orders from './pages/orders'
import OrderDetails from './pages/orders/orderDetails'
import TaxOrders from './pages/taxOrders'
import CompanyPage from 'pages/company'
import sales_rep from 'pages/sales_rep'
import EditSalesRep from 'pages/sales_rep/editSalesRep'
import Clients from 'pages/clientDashboard'
import ClientDashboard from 'pages/clientDashboard/client'
import states from 'pages/states'
import EditState from 'pages/states/editState'
import cities from 'pages/cities'
import ComplianceCalendar from 'pages/complianceCalendar'
import EditCity from 'pages/cities/editCity'
import DocumentTypes from 'pages/documentTypes'
import editDocumentType from 'pages/documentTypes/editDocumentType'
import CreatePassword from 'pages/auth/createPassword'
import ForgotPassword from 'pages/auth/forgotPassword'
import twoFA from 'pages/auth/twoFA'

import PredefinedTasks from 'pages/predefinedtasks'
import EditPredefinedTask from 'pages/predefinedtasks/editpredefinedtask'
import orderstatus from 'pages/orderstatus'
import EditOrderStatus from 'pages/orderstatus/editorderstatus'
import ordertypes from 'pages/ordertypes'
import EditOrderType from 'pages/ordertypes/editordertype'
import ClientNotifications from 'pages/clientNotifications'
import ClientCompanies from 'pages/clientCompanies'
import ClientCorrespondence from 'pages/clientCorrespondence'
// import PageNotFound from 'pages/404'

import widgets from 'pages/widgets'
import Editwidget from 'pages/widgets/editwidget'
import EditTaxWidget from 'pages/taxWidgets/editwidget'
import { CompanyList, TemplatesList } from 'pages/notificationLists'
import AllInvoices from 'pages/invoices'
import MissingItems from 'pages/missingItems'
import { ReminderAddTemplate, ReminderTemplateList } from 'pages/autoReminderTemplate'
import ReminderTemplateDetails from 'pages/autoReminderTemplate/reminderTemplateDetails'
import statesProcessing from 'pages/statesProcessing'
import EditStateProcessing from 'pages/statesProcessing/editStateProcessing'
import KPIDashboard from 'pages/kpiDashboard'
import industrytype from 'pages/industrytype'
import EditIndustryType from 'pages/industrytype/editIndustryType'
import prospectStatus from 'pages/prospectStatus'
import EditProspectStatus from 'pages/prospectStatus/editProspectStatusType'
import Prospects from 'pages/prospects'
import EditProspect from 'pages/prospects/editProspect'
import { AddSchedule, SchedulesList } from 'pages/prospects/schedules'
import { PromoCode } from 'pages/promoCode'
import { WebSubmissions, ArchiveWebSubmissions, WebSubmissionDetail, WebSubmissionReport } from 'pages/webSubmission'
import { CompanyLabel } from 'pages/labels'
import specialOffers from 'pages/specialOffers'
import EditSpecialOffer from 'pages/specialOffers/editSpecialOffer'
import nameCheckList from 'pages/nameCheckList'
import EditNameCheckList from 'pages/nameCheckList/editNameCheckList'
import MeetingLinks from 'pages/meetingLinks'
import { EmployeeReport } from 'pages/employeeReport'
import FormationState from 'pages/formationState'
import NotificationLogs from 'pages/notificationLogs'
import NoUTMWebSubmission from 'components/webSubmissions/noUTMWebSubmission'
import subscription from 'pages/subscription'
import EditSubscription from 'pages/subscription/editSubscription'
import CompanySubscription from 'pages/companySubscription'
import PowerUps from 'pages/specialOffers/powerUps'
import ClientProspects from 'pages/clientProspects'
import PowerUp from 'pages/clientDashboard/client/powerUp'
import specialOffersCategory from 'pages/specialOffersCategory'
import EditSpecialOfferCategory from 'pages/specialOffersCategory/editSpecialOffer'
import VisitorLogs from 'pages/visitorLogs'
import Register from 'pages/register'
import ForgotUsername from 'pages/auth/forgotUsername'
import AllPowerUp from 'pages/clientDashboard/client/allPowerUp'
import CreditScoreDetails from 'pages/clientDashboard/creditScoreDetails'
import TaxWidgets from 'pages/taxWidgets'
import CallLogs from 'pages/callLogs/index'
import RockyAI from 'pages/RockyAI'
import PaymentFailedLogs from 'pages/paymentFailedLogs'
import ClientMissingItems from 'pages/clientMissingItems'
import ClientMissingItemDetails from 'pages/clientMissingItems/clientMissingItemDetails'

export const routes: { path: string; component: React.ComponentType }[] = [
  // { path: '/', component: Dashboard },
  { path: '/doc/:id', component: DocumentEditor },
  { path: '/client', component: Client },
  { path: '/client/:id', component: EditClient },
  { path: '/companies', component: Companies },
  { path: '/dashboard', component: Dashboard },
  { path: '/company-order', component: Orders },
  { path: '/company-order/:id', component: OrderDetails },
  { path: '/tax-orders', component: TaxOrders },
  { path: '/tax-orders/:id', component: TaxOrderDetails },
  { path: '/notifications', component: NotificationsPage },
  // { path: '/order-types', component: OrderTemplateList },
  { path: '/company/:companyId', component: CompanyPage },
  { path: '/employee', component: Employee },
  { path: '/employee/:id', component: EditEmployee },
  { path: '/sales_representative', component: sales_rep },
  { path: '/sales_representative/:id', component: EditSalesRep },
  { path: '/client-dashboard', component: Clients },
  { path: '/client-dashboard/:id', component: ClientDashboard },
  { path: '/client-notifications', component: ClientNotifications },
  { path: '/client-companies', component: ClientCompanies },
  { path: '/client-correspondence', component: ClientCorrespondence },
  { path: '/states', component: states },
  { path: '/state/:id', component: EditState },
  { path: '/cities', component: cities },
  { path: '/city/:id', component: EditCity },
  { path: '/compliance-calendar', component: ComplianceCalendar },
  { path: '/document-types', component: DocumentTypes },
  { path: '/document-type/:id', component: editDocumentType },
  { path: '/create-password/:id', component: CreatePassword },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/forgot-username', component: ForgotUsername },
  { path: '/predefined-tasks', component: PredefinedTasks },
  { path: '/predefined-task/:id', component: EditPredefinedTask },
  { path: '/order-status', component: orderstatus },
  { path: '/order-status/:id', component: EditOrderStatus },
  { path: '/order-types', component: ordertypes },
  { path: '/order-type/:id', component: EditOrderType },
  { path: '/widgets', component: widgets },
  { path: '/widget/:id', component: Editwidget },
  { path: '/tax-widgets', component: TaxWidgets },
  { path: '/tax-widget/:id', component: EditTaxWidget },
  { path: '/templates-list', component: TemplatesList },
  { path: '/company-notifications', component: CompanyList },
  { path: '/invoices', component: AllInvoices },
  { path: '/auth', component: twoFA },

  { path: '/missing-items', component: MissingItems },
  { path: '/reminder-template', component: ReminderAddTemplate },
  { path: '/reminder-template-list', component: ReminderTemplateList },
  { path: '/reminder-template-list/:id', component: ReminderAddTemplate },
  { path: '/state-processing', component: statesProcessing },
  { path: '/state-processing/:id', component: EditStateProcessing },
  { path: '/kpi-dashboard', component: KPIDashboard },
  { path: '/rocky-ai', component: RockyAI },
  { path: '/industry-type', component: industrytype },
  { path: '/industry-type/:id', component: EditIndustryType },
  { path: '/prospect-status', component: prospectStatus },
  { path: '/prospect-status/:id', component: EditProspectStatus },

  { path: '/prospects', component: Prospects },
  { path: '/prospect/:id', component: EditProspect },

  { path: '/prospect-schedule', component: AddSchedule },
  { path: '/prospect-schedule/:id', component: AddSchedule },
  { path: '/prospect-schedules', component: SchedulesList },

  { path: '/promo-code', component: PromoCode },
  { path: '/web-submissions', component: WebSubmissions },
  { path: '/web-submission/:id', component: WebSubmissionDetail },
  { path: '/archive-web-submissions', component: ArchiveWebSubmissions },
  { path: '/web-submissions-reports', component: WebSubmissionReport },

  { path: '/web-submissions-no-utm', component: NoUTMWebSubmission },

  { path: '/labels', component: CompanyLabel },
  { path: '/special-offers', component: specialOffers },
  { path: '/special-offer/:id', component: EditSpecialOffer },
  { path: '/name-checklists', component: nameCheckList },
  { path: '/name-checklist/:id', component: EditNameCheckList },

  { path: '/meeting-links', component: MeetingLinks },
  { path: '/employee-report/:id', component: EmployeeReport },

  { path: '/formation-state', component: FormationState },

  { path: '/notifications-logs', component: NotificationLogs },
  { path: '/visitor-logs', component: VisitorLogs },
  { path: '/subscription', component: subscription },
  { path: '/subscription/:id', component: EditSubscription },

  { path: '/company-subscription', component: CompanySubscription },

  { path: '/power-ups', component: PowerUps },
  { path: '/power-up/all', component: AllPowerUp },

  { path: '/client-referrals', component: ClientProspects },

  { path: '/power-up/:id', component: PowerUp },
  { path: '/special-offers-category', component: specialOffersCategory },
  { path: '/special-offers-category/:id', component: EditSpecialOfferCategory },

  { path: '/register', component: Register },
  { path: '/credit-score/:id', component: CreditScoreDetails },

  { path: '/payment-fail-logs', component: PaymentFailedLogs },

  { path: '/client-missing-item', component: ClientMissingItems },
  // { path: '/client-missing-item/:id', component: ClientMissingItemDetails },
  { path: '/client-missing-item/:id/company', component: ClientMissingItemDetails },
  { path: '/client-missing-item/:id/taxOrder', component: ClientMissingItemDetails },

  // { path: '/call-logs', component: CallLogs },
]
