import * as React from 'react'
import { IconProps } from './props'

function EditIcon({ width, height }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '23'} height={height || '26'} viewBox="0 0 23 26">
      <g
        fill="#3C9FDF"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        transform="translate(-1225 -325) translate(1215 317) translate(10.033 8.6)"
      >
        <path d="M0 23.782H21.364V25.103H0z" />
        <path d="M21.819 2.27L20.403.87a3.03 3.03 0 00-4.249 0L3.408 13.481a.659.659 0 00-.17.286l-1.888 6.54a.658.658 0 00.17.65.67.67 0 00.655.168l6.61-1.868a.662.662 0 00.289-.168L21.819 6.475c.567-.562.88-1.308.88-2.102 0-.794-.313-1.542-.88-2.102zM8.25 18.033l-5.287 1.494 1.51-5.232L14.737 4.137l3.777 3.738L8.251 18.032zM20.875 5.541L19.46 6.942l-3.777-3.737 1.416-1.402a1.685 1.685 0 012.36 0l1.417 1.402c.315.312.489.726.489 1.167 0 .442-.174.857-.489 1.169z" />
      </g>
    </svg>
  )
}

export default EditIcon
