// import { MissingItemProps } from 'api/missingItems'
import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

interface KpiDetailTableRowProps {
  rowData: any
  onEditClick: () => void
  type: string
  special_offer?: boolean
}

const KpiDetailTableRow: React.FC<KpiDetailTableRowProps> = ({ rowData, onEditClick, type, special_offer }) => {
  const getRowData = (type: string) => {
    switch (type) {
      case KPI_ENTITY_TYPES.client:
        return (
          <>
            <TableCell style={{ minWidth: 120 }}>{rowData?.id}</TableCell>
            <TableCell style={{ maxWidth: 120 }}>
              {rowData?.first_name} {rowData?.middle_name} {rowData?.last_name}
            </TableCell>
            <TableCell style={{ minWidth: 120 }}>{rowData?.email_1}</TableCell>
          </>
        )
        break
      case KPI_ENTITY_TYPES.company:
        return (
          <>
            <TableCell style={{ minWidth: 120 }}>{rowData?.id}</TableCell>
            <TableCell style={{ maxWidth: 120 }}>{rowData?.company_name}</TableCell>
            <TableCell style={{ minWidth: 120 }}>{rowData?.company_type}</TableCell>
            {special_offer && (
              <TableCell style={{ minWidth: 120 }}>{formatDateTimeToHumanFriendly(rowData?.created_at)}</TableCell>
            )}
          </>
        )
        break
      case KPI_ENTITY_TYPES.order:
        return (
          <>
            <TableCell style={{ minWidth: 120 }}>{rowData?.id}</TableCell>
            <TableCell style={{ maxWidth: 120 }}>{rowData?.order_type}</TableCell>
            <TableCell style={{ minWidth: 120 }}>{rowData?.company_name}</TableCell>
          </>
        )
        break
      default:
        break
    }
  }
  return (
    <TableRow onClick={onEditClick} style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      {getRowData(type)}
      {/* <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleEditIconClick}>
            <EditIcon data-testid="editButton" />
          </IconButton>
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell> */}
    </TableRow>
  )
}

export default KpiDetailTableRow
