import * as React from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ececee',
      border: 'none',
    },
    label: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.07px',
      fontSize: 14,
    },
  })
)

interface IDownloadButtonProps extends ButtonProps {
  onClick?: () => void
  children?: React.ReactNode | React.ReactNode[]
  style?: React.CSSProperties
}

function DownloadButton(props: IDownloadButtonProps) {
  const { children, onClick, style } = props
  const classes = useStyles()
  return (
    <Button
      {...props}
      classes={{ root: classes.root, label: classes.label }}
      style={style}
      variant="outlined"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
export default DownloadButton
