import { IPagedTableHook, PagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import TaxOrderTableFilters from 'components/taxOrders/table/filters'
import TaxOrderTableHead from 'components/taxOrders/table/head'
import TaxOrderTableRow from 'components/taxOrders/table/row'
import { TaxOrderContext } from 'components/taxOrders/taxOrderContext'
import { ITaxOrder } from 'models/taxOrder'
import * as React from 'react'
import useRouter from 'use-react-router'

interface ITaxOrderTableProps {
  pagedTable: IPagedTableHook<ITaxOrder>
}

const TaxOrderTable: React.FC<ITaxOrderTableProps> = ({ pagedTable }) => {
  // const { searchOptions, handleSortingClick } = React.useContext(TaxOrderContext)
  // const router = useRouter()
  // const handleEditClick = (taxOrderId: number) => {
  //   router.history.push(`/tax-orders/${taxOrderId}`)
  // }

  // const table = () => {
  //   // Return a dummy table instead of PagedTable if loading
  //   if (pagedTable.isLoading) {
  //     return (
  //       <Table>
  //         <TaxOrderTableHead
  //           sortBy={searchOptions['sort_by']}
  //           sortOrder={searchOptions['sort_order']}
  //           handleSortingClick={handleSortingClick}
  //         />
  //         <TableBody>
  //           {skeletons({
  //             numberOfColumns: 9,
  //             sizes: [60, 45, 65, 80, 78, 80, 170],
  //             numberOfRows: 10,
  //           })}
  //         </TableBody>
  //       </Table>
  //     )
  //   }
  //   return (
  //     <PagedTable
  //       {...pagedTable.renderProps}
  //       renderRow={(rowData: ITaxOrder) => {
  //         return (
  //           <TaxOrderTableRow
  //             onEdit={handleEditClick}
  //             key={rowData.id}
  //             taxOrder={rowData}
  //             searchQuery={searchOptions['query']}
  //           />
  //         )
  //       }}
  //       header={
  //         <TaxOrderTableHead
  //           sortBy={searchOptions['sort_by']}
  //           sortOrder={searchOptions['sort_order']}
  //           handleSortingClick={handleSortingClick}
  //         />
  //       }
  //       colSpan={8}
  //       emptyRowText="No Tax Orders"
  //     />
  //   )
  // }

  return (
    <div>
      <TaxOrderTableFilters />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {/* {table()} */}
    </div>
  )
}

export default TaxOrderTable
