import * as React from 'react'

export type ClientError =
  | 'name'
  | 'email'
  | 'phone_number'
  | 'dob'
  | 'ssn_ein'
  | 'sales_rep_id'
  | 'no_primary_address'
  | 'duplicate_email'
  | 'first_name'
  | 'last_name'
  | 'meetinglink'
  | 'email_1'
  | 'email_2'
  | 'middle_name'
  | 'username'
  | 'password'

const useClientEditErrors = () => {
  const [name, setName] = React.useState(false)
  const [email, setEmail] = React.useState(false)
  const [duplicate_email, setDuplicateEmail] = React.useState(false)
  const [phone_number, setPhoneNumber] = React.useState(false)
  const [dob, setDob] = React.useState(false)
  const [ssn_ein, setSsnEin] = React.useState(false)
  const [sales_rep_id, setSalesRepId] = React.useState(false)
  const [no_primary_address, setNoPrimaryAddress] = React.useState(false)
  const [first_name, setfirst_name] = React.useState(false)
  const [last_name, setlast_name] = React.useState(false)
  const [email_1, setemail1] = React.useState(false)
  const [email_2, setemail2] = React.useState(false)
  const [meetinglink, setmeetinglink] = React.useState(false)
  const [middle_name, setmiddle_name] = React.useState(false)
  const [username, setusername] = React.useState(false)
  const [password, setpassword] = React.useState(false)

  const setError = (error: ClientError, state: boolean) => {
    switch (error) {
      case 'first_name':
        setfirst_name(state)
        break
      case 'email_1':
        setemail1(state)
        break
      case 'email_2':
        setemail2(state)
        break

      case 'last_name':
        setlast_name(state)
        break
      case 'meetinglink':
        setmeetinglink(state)
        break
      case 'name':
        setName(state)
        break
      case 'email':
        setEmail(state)
        break
      case 'phone_number':
        setPhoneNumber(state)
        break
      case 'dob':
        setDob(state)
        break
      case 'ssn_ein':
        setSsnEin(state)
        break
      case 'sales_rep_id':
        setSalesRepId(state)
        break
      case 'no_primary_address':
        setNoPrimaryAddress(state)
        break
      case 'duplicate_email':
        setDuplicateEmail(state)
        break
      case 'middle_name':
        setmiddle_name(state)
        break
      case 'username':
        setusername(state)
        break
      case 'password':
        setpassword(state)
        break
    }
  }

  return {
    name,
    first_name,
    last_name,
    meetinglink,
    email_1,
    email_2,
    duplicate_email,
    phone_number,
    dob,
    ssn_ein,
    sales_rep_id,
    no_primary_address,
    email,
    middle_name,
    username,
    password,
    setError,
  }
}

export default useClientEditErrors
