import { apiClient, requestPaginated } from '@jetkit/react'
import { INewTaxOrderState, ITaxOrder } from 'models/taxOrder'
import { IAsset } from 'models/asset'
import { getAccessToken } from 'axios-jwt'
import { ISuggestionAttribute } from 'components/interfaces'
import { AxiosResponse } from 'axios'
import { ISortSettings } from 'hooks/useSortTable'
import { SearchField } from './suggestions'

export type QuestionStatus = 'pending' | 'answered' | 'unanswered' | 'sent_to_client'

export type BOQuestionStatus = string

export enum BOQuestionStatusText {
  UNANSWERED = '1',
  ANSWERED = '2',
}

export enum QuestionStatusText {
  PENDING = 'pending',
  ANSWERED = 'answered',
  UNANSWERED = 'unanswered',
  SENT_TO_CLIENT = 'sent_to_client',
}

export interface IQuestion {
  id: number
  question_text: string
  answer_text: string
  status: QuestionStatus
  name?: string
  question_date?: string
}

export interface IBOQues {
  is_stared: boolean
  id: number
  question_id: null | number
  question_text: string
  controller_id: number
  schedule_active: boolean
  schedule_date: null | any
  taxorder_id: number
  answered: string
  status: string
  parent_question_id: null | number
  child_question: any
  employeedetails: any
  answered_by: string
}
export interface IBONotes {
  id: number
  bo_content: string
  controller_id: string
  created_at: string
  last_modified: string
  taxorder_id: number
  employee_details: any
  new_content?: string
  isCurrent?: boolean
}

export interface IInvoice {
  id?: number
  invoice_no: string
  issue_date: string
  amount: number
  created_at?: string
  created_by?: string
  paid?: boolean
  taxorderid?: number
  paid_by?: string
  paid_at?: string
  filer_name?: string
  payment_link?: string
}
interface ITaxAssetParams {
  taxId: number
  documentId: number
}

interface IQuestionParams {
  questionId: number
}

export interface IPendingNotifications {
  pending_missing_items_count: number
  pending_questions_count: number
  pending_task_count: number
}

export interface CheckListQuestion {
  user_answered?: any
  // child_question: any
  id?: number
  question_text?: string
  question_type?: '1' | '[1,2]' | '2'
  parent_question_id?: number | null
  parent_heading?: string | null
  answers?: Answer[] | null
  nested_question?: any
}

interface Answer {
  answer: {
    child_question?: JSX.Element
    id: number
    answer_text: string
  }
  child_question?: CheckListQuestion | null
}
export interface CheckListAns {
  question_id?: number | null
  answer_id?: number | null
  // answer_text: 'hello'
}
export interface SearchItem {
  id: number
  text: string
}

export const fetchTaxOrders = requestPaginated<ITaxOrder[]>({ url: '/tax-order' })

export const fetchInvoices = (taxOrderId: number) => {
  return requestPaginated<IInvoice[]>({ url: `/tax-order/${taxOrderId}/invoices` })
}
export const paginateInvoices = (
  paymentStatus: string,
  sortSettings?: ISortSettings,
  dateFiltr?: string,
  taxorderid?: number
) => {
  return requestPaginated<IInvoice[]>({
    url: `/invoices/?payment_status=${paymentStatus}&issue_date=${dateFiltr}&sort_by=${
      sortSettings?.sort_by
    }&sort_order=${sortSettings?.order}&taxorderid=${taxorderid || ''}`,
  })
}
export const addInvoice = async (taxOrderId: number, invoice: IInvoice): Promise<void> =>
  (await apiClient.post(`tax-order/${taxOrderId}/invoice`, invoice)).data

export const updateInvoice = async (invoiceId: number, invoice: IInvoice): Promise<void> =>
  (await apiClient.post(`/invoices/${invoiceId}`, invoice)).data

export const updateInvoiceStatus = async (taxOrderId: number, paid: boolean, invoiceId: number): Promise<void> =>
  (await apiClient.post(`invoice/${invoiceId}/status`, { taxOrderId: taxOrderId, paid: paid })).data

export const deleteTaxOrder = async (taxOrderId: number): Promise<AxiosResponse<void>> => {
  return await apiClient.delete(`/tax-orders/${taxOrderId}`)
}

export const updateTableRow = async (source: number, destination: number): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.109:3000/'
  return (await apiClient.post(`/tax-order-priority`, { source, destination })).data
}

const getParentURL = (taxOrder: INewTaxOrderState): string => {
  let parent: string
  switch (taxOrder.tax_order_type) {
    case 'personal':
      parent = 'user'
      break
    case 'business':
      parent = 'company'
      break
    default:
      parent = ''
  }
  return `/${parent}/${taxOrder.parent_id}/tax-order`
}

export const createTaxOrder = async (taxOrder: INewTaxOrderState): Promise<ITaxOrder> =>
  (await apiClient.post(getParentURL(taxOrder), taxOrder)).data

export const fetchTaxQuestions = (taxOrderId: number) => {
  return requestPaginated<IQuestion[]>({ url: `/tax-order/${taxOrderId}/question` })
}

export const addQuestion = async (taxOrderId: number, questionText: string): Promise<void> =>
  (await apiClient.post(`tax-order/${taxOrderId}/question`, { question_text: questionText })).data

export const editQuestion = async (
  questionId: number,
  questionText: string,
  answerText: string,
  status?: QuestionStatus
): Promise<void> =>
  (
    await apiClient.post(`tax-order/question/${questionId}`, {
      question_text: questionText,
      answer_text: answerText || '',
      status,
    })
  ).data

export const deleteQuestion = async (questionId: number): Promise<void> =>
  (await apiClient.delete(`tax-order/question/${questionId}`)).data

export const getTaxOrderDetails = async (taxOrderId: number): Promise<ITaxOrder> => {
  // const BASE_URL = 'http://192.168.0.213:3000'
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.get(`tax-order/${taxOrderId}`)).data
}
export const updateTaxOrder = async (taxOrderDetails: ITaxOrder): Promise<ITaxOrder> =>
  (await apiClient.post(`tax-order/${taxOrderDetails.id}`, taxOrderDetails)).data

export const getTaxDocuments = (taxId: number) => {
  return requestPaginated<IAsset[]>({ url: `taxorder/${taxId}/asset` })
}
export const downloadTaxDocumentURL = async ({ taxId, documentId }: ITaxAssetParams): Promise<string> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  const res = (await apiClient.get(`${BASE_URL}/taxorder/${taxId}/asset/${documentId}/download?jwt=${token}`)).data
  return res //res.data;
}
export const deleteTaxDocument = async ({ taxId, documentId }: ITaxAssetParams): Promise<void> => {
  await apiClient.delete(`taxorder/${taxId}/asset/${documentId}`)
}

export const getTaxLabels = async (type = 'taxorder'): Promise<ISuggestionAttribute[]> => {
  return (await apiClient.get('/companylabelslist', { params: { type } })).data
}

export const getAssociatedTaxOrder = (client_id: number, company_id: number) => {
  return requestPaginated<ITaxOrder[]>({
    url: `/associated-tax-order?client_id=${client_id ? client_id : 0}&company_id=${company_id ? company_id : 0}`,
  })
}

export const getAccountants = async (user_type = 4, query = ''): Promise<any[]> => {
  return (await apiClient.get('/user', { params: { user_type, query } })).data
}

export const getTaxAnswerEmail = async (taxOrderId: number): Promise<any> =>
  await apiClient.get(`tax-order/answer-notifications/${taxOrderId}`)

export const getTaxQuestionEmail = async (taxOrderId: number): Promise<any> =>
  await apiClient.get(`tax-order/question-notifications/${taxOrderId}`)

export const downloadQuestionsURL = async (questionId: IQuestionParams): Promise<string> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  // const BASE_URL = 'http://192.168.0.112:3000'

  // const token = getAccessToken()
  const res = (await apiClient.get(`${BASE_URL}/question-attachments/${questionId}`)).data
  return res //res.data;
}

export const deleteTaxInvoice = async (invoiceId: number): Promise<AxiosResponse<void>> => {
  return await apiClient.delete(`/invoices/${invoiceId}`)
}

export const getTaxOrderAccountants = async (id = 0, type = ''): Promise<any[]> => {
  return (await apiClient.get(`/taxorder-accountant/${id}`, { params: { type } })).data
}

export const getTaxOrderPendingNotification = async (id: any): Promise<IPendingNotifications> => {
  return (await apiClient.get(`/taxorder-pending-notifications/${id}`)).data
}

export const getCheckListQues = async (type: number | string, id: number | string): Promise<CheckListQuestion> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.214:3000/'
  return (await apiClient.get(`checklistquestions?question_type=${type}&taxorder_id=${id}`)).data
}
export const sendCheckListAnswer = async (payload: CheckListAns): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.214:3000/'

  return await apiClient.post('/userchecklistanswers', payload)
}

export const getTaxQuesResolvedAccountants = async (id: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'

  return await apiClient.get(`/tax-order/accountants/${id}`)
}
// export const getCheckListAns = async (): Promise<any> => {
//   apiClient.defaults.baseURL = 'http://192.168.0.214:3000/'

//   return (await apiClient.get(`/userchecklistanswers/400`)).data
// }
export const fetchBOQuestions = (taxorder_id: number) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return requestPaginated<IBOQues[]>({ url: `/boquestions/taxorder/${taxorder_id}` })
}

export const addBOQuestion = async (payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`boquestions`, payload)).data
}

export const fetchBONotes = (taxorderid: number) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return requestPaginated<IBONotes[]>({ url: `bonotes/${taxorderid}/taxorder_id` })
}

export const addBONotes = async (payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`bonotes`, payload)).data
}

export const getVersions = async (notesID: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.get(`bonotes/${notesID}/versions `)).data
}
export const sendAnswer = async (quesId: number, payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`taxorder/boquestion-ans/${quesId}`, payload)).data
}
export const notify = async (payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`boquestions/notify-client`, payload)).data
}
export const pauseNotification = async (payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`boquestions/paused-date`, payload)).data
}
export const reviewQues = async (payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`boquestions/answer-review`, payload)).data
}

export const getPreDefinedQues = async (): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.get(`pre-defined/questions `)).data
}

export const getRejectedReason = async (): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.get(`/boquestions/pre-defined/rejected-reason`)).data
}

export const deleteBOQuestion = async (payload: any): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`boquestions/delete`, payload)).data
}

export const editBOQuestion = async (id: number, payload): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`boquestions/${id}`, payload)).data
}

export const customFetchSuggestions = (extra_params?: { feedback_id?: any }) => async (
  query: string,
  field: SearchField
): Promise<ISuggestionAttribute[]> => {
  let endpoint: string
  switch (field) {
    case 'feedback_id':
      endpoint = '/boquestions/pre-defined/rejected-reason'
      break
    case 'question':
      endpoint = 'pre-defined/questions'
      break
    default:
      console.warn(`invalid field ${field}!!`)
      return Promise.reject('invalid field')
  }
  // apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL //'http://localhost:3000/'
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  const results: SearchItem[] = (await apiClient.get(`${endpoint}`, { params: { query, ...(extra_params || {}) } }))
    .data
  const options: ISuggestionAttribute[] = results.map(elem => ({
    value: elem.id,
    label: elem.text,
  }))
  console.log(options, 'OPTIONSSSSSSSSSSSSSSS')
  return options
}
export const fetchSuggestions = customFetchSuggestions()

export const downloadBOQuestionURL = async (
  taxorder_id: any,
  question_id: any,
  is_question: boolean
): Promise<string> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  // const BASE_URL = 'http://192.168.0.213:3000'
  const res = (
    await apiClient.get(
      `${BASE_URL}/bo/taxorder/${taxorder_id}/question/${question_id}/download/all?is_question=${is_question}`
    )
  ).data
  return res //res.data;
}

export const starredQuestion = async (id: number, payload): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`taxorder/boquestion-stared/${id}`, payload)).data
}

export const getCurrentNote = async (id: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  // return (await apiClient.get(`/bonotes/624`)).data
  return (await apiClient.get(`/bonotes/${id}`)).data
}
export const getDirectedLink = async (id: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  // return (await apiClient.get(`/bonotes/624`)).data
  return (await apiClient.get(`boquestions/link/${id}`)).data
}
export const getScheulderLink = async (id: number) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.get(`tax-order/Scheduler/link/${id}`)).data
}
export const reminderScheduler = async (payload): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.213:3000/'
  return (await apiClient.post(`/boquestions/reminder-schedule`, payload)).data
}
