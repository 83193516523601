import { PagedTable, usePagedTable } from '@jetkit/react'
import { Grid, Select, Table, TableBody, Typography } from '@material-ui/core'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { deleteCompany, paginateCompanies } from 'api/companies'
import AddButton from 'components/common/buttons/AlertButton'
import SearchBar from 'components/common/searchBar'
import MultiSelect from 'components/common/select/multiSelect'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import CompanyCreationFlow from 'components/companies/create'
import CompanyTableHead from 'components/companies/table/head'
import CompanyTableRow from 'components/companies/table/row'
import { ISuggestionAttribute } from 'components/interfaces'
import DeleteDialog from 'components/notifications/deleteDialog'
import useRouter from 'hooks/router'
import useSortTable from 'hooks/useSortTable'
import { ICompany } from 'models/company'
import { IClient } from 'models/user'
import * as React from 'react'
import showErrorNotification from 'utils/showErrorNotification'
import { findLabelList } from 'api/label'
import { fetchSuggestions } from 'api/suggestions'
import SelectField from 'components/common/select'
// import Dropdown from 'components/leadManagerElements/editProspectById/components/Dropdown'
import SelectAutocomplete from 'components/common/SelectAutocomplete'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '2rem',
    fontFamily: 'SFCompactDisplay',
    fontWeight: 600,
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
  addCompanyButton: {
    backgroundColor: '#3c9fdf',
  },
  filterWithTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
})

interface ICompanyProps extends WithStyles<typeof styles> {}

const Companies: React.FC<ICompanyProps> = ({ classes }) => {
  const { history, location } = useRouter()

  // const initialQuery = new URLSearchParams(location.search).get('query') || ''
  const initialQuery = new URLSearchParams(location.search).get('client_name') || ''
  const client_id = new URLSearchParams(location.search).get('client_id') || null
  const [query, setQuery] = React.useState('')
  const [companyToDelete, setCompanyToDelete] = React.useState<ICompany>()
  const [deleteCompanyDialogShown, setDeleteCompanyDialogShown] = React.useState(false)
  const [initialClientForCreation, setInitialClientForCreation] = React.useState<IClient>()
  const [salesRep, setSalesRep] = React.useState<ISuggestionAttribute | null>()

  const [isModalOpen, setModalOpen] = React.useState(false)

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'id' })
  const [labels, setLabels] = React.useState<ISuggestionAttribute[]>([])
  const [labelFiltr, setLabelFiltr] = React.useState<string>('')
  const [activeFilter, setActiveFilter] = React.useState<string>('All')

  const tableHead = React.useMemo(
    () => <CompanyTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const { order, sort_by } = sortSettings

  const memoApiCall = React.useMemo(
    () =>
      paginateCompanies(
        client_id ? parseInt(client_id) : null,
        query,
        labelFiltr,
        activeFilter
      )({ sort_order: order, sort_by, sales_rep_id: salesRep?.value || 0, active_filter: activeFilter || '' }),
    [client_id, query, labelFiltr, order, sort_by, salesRep?.value, activeFilter]
  )

  const pagedTable = usePagedTable<ICompany>({
    apiCall: memoApiCall,
  })

  // Here we attempt to extract client object from `history.location.state.client`.
  // If such object exists, first we remove it from `history.location.state` and then
  // set it to `initialClientForCreation`, so it will be passed as prop to CompanyCreationFlow component.
  React.useEffect(() => {
    const state = history.location.state as { client: IClient | undefined }

    const client = state && (state['client'] as IClient)
    if (client) {
      if (!state) return

      delete state['client']
      history.replace({ ...history.location, state })
      setModalOpen(true)
      setInitialClientForCreation(client)
    }
  }, [history])

  const handleDeleteCompany = React.useCallback(
    (company: ICompany) => {
      setCompanyToDelete(company)
      setDeleteCompanyDialogShown(true)
    },
    [setCompanyToDelete]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  React.useEffect(() => {
    const getCompanyLabels = async () => {
      try {
        const response = await findLabelList('company')
        setLabels(response)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
    }
    getCompanyLabels()
  }, [])
  const handleAddFilter = React.useCallback(
    (field: string, select: any) => {
      switch (field) {
        case 'label':
          // setSearchOptions(prevState => ({
          //   ...prevState,
          //   [field]:
          //     select && Array.isArray(select)
          //       ? select.map((item: ISuggestionAttribute) => item.label as ITaxOrderStatus)
          //       : [],
          // })) // collect strings representing enum values
          setLabelFiltr(select.map((item: ISuggestionAttribute) => item.value).toString())
          setSalesRep(null)
          break
        default:
          showErrorNotification('An error occurred while filtering')
          console.warn(`Wrong filter key: ${field}`)
      }
    },
    [setLabelFiltr]
  )
  React.useEffect(() => {
    console.log('labelFiltr', labelFiltr)
  }, [labelFiltr])

  console.log('salesRepresentative salesRep', salesRep)

  const changeDropDownField = React.useCallback(async (field: keyof IClient, valueAtt: ISuggestionAttribute | null) => {
    setSalesRep(valueAtt)
    debugger
    console.log('valueAtt', valueAtt)
  }, [])

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteCompanyDialogShown}
        close={() => setDeleteCompanyDialogShown(false)}
        deletionObject={companyToDelete}
        onRequestSuccessful={onDeleteSuccessful}
        deletionRequest={deleteCompany}
      />
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>{client_id && initialQuery} Companies</Typography>
        {!client_id && (
          <AddButton
            data-testid="create-new-company-button"
            onClick={() => setModalOpen(true)}
            classes={{ button: classes.addCompanyButton }}
          >
            Add Company
          </AddButton>
        )}
        <CompanyCreationFlow
          setIsOpen={setModalOpen}
          isOpen={isModalOpen}
          clearInitialClientInParent={() => {
            setInitialClientForCreation(undefined)
          }}
          onCompanyCreated={pagedTable.reloadData}
          initialClient={initialClientForCreation}
        />
      </div>
      <div className={classes.filterWithTitle} style={{ marginLeft: '1rem' }}>
        {/* <Typography className={classes.filterWithTitle}>Label:</Typography> */}
        <MultiSelect
          addAttribute={handleAddFilter}
          title="Label"
          field="label"
          width="300px"
          async={false}
          placeholder="Select Label"
          defaultValue={[]}
          options={labels}
        />
        {/* <Typography className={classes.filterWithTitle}>Status</Typography> */}
        {/* <div style={{ width: '300px' }}>
          status
          <Dropdown
            placeholder="Select Status"
            handleValueChange={e => setActiveFilter(e.target.value)}
            val={activeFilter}
            label=""
            options={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'InActive' },
              { value: 'all', label: 'All' },
            ]}
          />
        </div> */}
        <div style={{ width: '300px' }}>
          <SelectAutocomplete
            handleChange={val => setActiveFilter(String(val))}
            options={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'InActive' },
              { value: 'all', label: 'All' },
            ]}
            value={activeFilter}
            label="Status"
          />
        </div>
      </div>
      <div className={classes.filterWithTitle} style={{ marginLeft: '1rem' }}></div>

      <Grid container spacing={3}>
        <Grid item xs={8}>
          <div className={classes.searchBarContainer}>
            <SearchBar onChange={setQuery} placeholder="Search by name" initialValue={query} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <SelectField
            showCopyButton={false}
            onOptionSelected={option => changeDropDownField('sales_rep', option ? option : null)}
            title="Sales Representative"
            fetchSuggestions={fetchSuggestions}
            field="sales_rep_id"
            placeholder="Choose Sales Representative"
            value={salesRep}
          />
        </Grid>
        {/* <Grid item xs={4}>
          <SelectAutocomplete
            handleChange={val => setActiveFilter(String(val))}
            options={['active', 'inactive', 'all']}
            value={activeFilter}
            label="Status"
          />
        </Grid> */}
      </Grid>

      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            {tableHead}
            <TableBody>
              {skeletons({ numberOfColumns: 8, sizes: [100, 100, 100, 100, 120, 120, 120, 100], numberOfRows: 8 })}
            </TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Companies"
            {...pagedTable.renderProps}
            renderRow={(company: ICompany) => (
              <CompanyTableRow
                onClick={() => history.push(`/company/${company.id}`)}
                key={company.id}
                company={company}
                onDeleteClick={() => handleDeleteCompany(company)}
              />
            )}
            header={tableHead}
            colSpan={8}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(Companies)
