import { TextField, useTheme } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { KeyboardArrowDown, NotificationsActiveOutlined } from '@material-ui/icons'
// import DatePicker from 'components/common/datePicker'

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { CSSProperties } from '@material-ui/styles'
import { getDirectedLink, notify, pauseNotification, reminderScheduler } from 'api/taxOrders'
import ExportIcon from 'icons/export'
import SendPlaneIcon from 'icons/sendPlane'
import moment from 'moment'
import * as React from 'react'
import { useReactToPrint } from 'react-to-print'
// import NotifyModal from '../notify'
import { useParams } from 'react-router'
import showSuccessNotification from 'utils/showSuccessNotification'
import showErrorNotification from 'utils/showErrorNotification'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import DatePicker from 'components/common/datePicker'
import { backendDateFormat, datePickerDateFormat } from 'utils/formatDate'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import { BO_Reminder_Scheduler, BO_Reminder_Scheduler_New } from 'utils/constant'

const useStyles = makeStyles((theme: Theme) => {
  const buttonStyle: CSSProperties = {
    fontWeight: 'bold',
    letterSpacing: '0.07px',
    textDecoration: 'underline',
  }
  return {
    container: {
      display: 'flex',
      marginTop: 20,
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    datePickerWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    questions: {
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: '0.07px',
      color: theme.palette.text.primary,
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    addQuestionButton: {
      ...buttonStyle,
      color: theme.palette.primary.main,
      marginRight: '1rem',
    },
    sendToClientButton: {
      ...buttonStyle,
      color: theme.customPalette.darkerBlue,
      marginRight: '1rem',
    },
    exportToPdfButton: {
      ...buttonStyle,
      marginLeft: '0.5rem',
      color: theme.customPalette.darkGray,
    },
    notifyButton: {
      ...buttonStyle,
      marginRight: '1rem',
      color: theme.customPalette.darkGray,
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '0.25rem',
        fontSize: '1.25rem',
      },
    },
    confirmBtn: {
      ...buttonStyle,
      color: theme.customPalette.darkGray,
      display: 'flex',
      justifyContent: 'flex-end',
      '& svg': {
        marginRight: '0.25rem',
        fontSize: '1.25rem',
      },
    },
  }
})

interface IToolbarProps {
  pickedYear: string
  onYearChange: (date: MaterialUiPickersDate) => void
  onAddQuestionClicked: () => void
  refComp?: any
  reminderSequence?: string | null | undefined
}

const BOToolbar: React.FC<IToolbarProps> = ({
  reminderSequence,
  pickedYear,
  onYearChange,
  onAddQuestionClicked,
  refComp,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { id } = useParams<any>()

  const [isOpen, setIsOpen] = React.useState(false)
  const [dueDate, setDueDate] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [activeFilter, setActiveFilter] = React.useState<string>('')

  const handlePrint = useReactToPrint({
    content: () => refComp.current,
    documentTitle: 'Document',
  })

  const notifyClient = async () => {
    if (id) {
      const payload = {
        tax_order_id: Number(id),
      }
      try {
        const result = await notify(payload)
        // console.log(result.message, 'RESULTISHEREEEEEEEEEEEE')
        // const message=result.message=="sent successfully"??
        // const message = result.message
        showSuccessNotification('Sent Succesfully')
      } catch (error) {
        showErrorNotification(error)
        console.log(error)
      }
    }
  }

  const onDueDateChange = React.useCallback((date: string) => {
    setDueDate(moment(date).format('YYYY-MM-DD'))
  }, [])

  const handlepauseNotification = async () => {
    if (id && dueDate?.length) {
      try {
        setLoading(true)
        const payload = {
          tax_order_id: Number(id),
          pause_day: dueDate,
        }
        await pauseNotification(payload)
        setIsOpen(false)
        setDueDate('')
        setLoading(false)
        showSuccessNotification(`Successfully paused notifications till ${dueDate}`)
      } catch (error) {
        setIsOpen(false)
        setDueDate('')
        showErrorNotification(error)
        setLoading(false)
      }
    } else {
      showErrorNotification('Select a date')
    }
  }

  const gotoDirectedLink = async () => {
    const result = await getDirectedLink(id)
    window.open(result?.link, '_blank')
  }

  const handleRemiderScheduler = async val => {
    const payload = {
      taxorder_id: Number(id),
      reminder_sequence: Number(BO_Reminder_Scheduler[val]),
    }
    await reminderScheduler(payload)
    showSuccessNotification(`Reminder Sequence is updated`)
  }

  React.useEffect(() => {
    if (reminderSequence) {
      setActiveFilter(BO_Reminder_Scheduler_New[reminderSequence])
    } else {
      setActiveFilter('Standard Sequence')
      handleRemiderScheduler('Standard Sequence')
    }
  }, [])
  return (
    <div className={classes.container}>
      <div className={classes.datePickerWrapper}>
        <div style={{ width: '200px' }}>
          <SelectAutocomplete
            handleChange={val => {
              setActiveFilter(String(val))
              handleRemiderScheduler(val)
            }}
            options={[
              { value: 'Rush Sequence', label: 'Rush Sequence' },
              { value: 'Standard Sequence', label: 'Standard Sequence' },
            ]}
            value={reminderSequence ? BO_Reminder_Scheduler_New[reminderSequence] : 'Standard Sequence'}
            label="Reminder Sequence"
          />
        </div>
        {/* <Typography className={classes.questions}>QUESTIONS</Typography>
        <DatePicker
          views={['year']}
          label="Year"
          value={pickedYear}
          onChange={onYearChange}
          autoOk
          style={{ width: 80, marginLeft: '1.5rem' }}
          inputVariant="outlined"
          okLabel=""
          minDate={moment().add(-10, 'year')}
          maxDate={moment().add(+10, 'year')}
        /> */}
        {/* <Typography style={{ margin: '0 1rem' }} className={classes.questions}>
          Pause
        </Typography>
        <Autocomplete
          popupIcon={<KeyboardArrowDown />}
          options={clientResponses}
          getOptionLabel={(option: any) => option?.label}
          onChange={(event: any, newValue: any) => {
            handlepauseNotification(newValue)
          }}
          renderInput={params => <TextField {...params} label="Days" variant="outlined" />}
        /> */}
      </div>

      <div className={classes.buttonsWrapper}>
        <Link onClick={gotoDirectedLink} component="button" variant="body2" className={classes.addQuestionButton}>
          Client Direct Link
        </Link>

        <Link onClick={onAddQuestionClicked} component="button" variant="body2" className={classes.addQuestionButton}>
          + Add Question
        </Link>
        <Link component="button" variant="body2" className={classes.notifyButton} onClick={notifyClient}>
          <NotificationsActiveOutlined /> Notify
        </Link>

        <SendPlaneIcon fillColor={theme.customPalette.primaryDark} height={20} />
        {/* <Link onClick={pauseNotification} component="button" variant="body2" className={classes.sendToClientButton}>
          Pause
        </Link> */}
        <Link onClick={() => setIsOpen(true)} component="button" variant="body2" className={classes.sendToClientButton}>
          Pause
        </Link>
        <ExportIcon />
        <Link component="button" variant="body2" className={classes.exportToPdfButton} onClick={handlePrint}>
          Export To PDF
        </Link>

        <ModalWrapper open={isOpen} setOpen={setIsOpen} heading={`Pause Notifications`}>
          <DatePicker
            value={dueDate || undefined}
            onDateSelected={onDueDateChange}
            disablePast={true}
            placeholder="Select Date"
            style={{ width: '100%' }}
          />

          <div className={classes.confirmBtn}>
            <ButtonWithLoading
              isLoading={loading}
              onClick={handlepauseNotification}
              wrapperClassName="question-btn"
              kind="BRPrimary"
            >
              Confirm
            </ButtonWithLoading>
          </div>
        </ModalWrapper>
      </div>
    </div>
  )
}

export default BOToolbar
