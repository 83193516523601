import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import DocumentVault from '../documentVault'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: '2rem 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tabRoot: {
    flexGrow: 1,

    '& .tab-header': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: '#020202',
    },

    '& .history-tab': {
      marginRight: '1rem',
      borderBottom: '3px solid #f1f3f4',
      color: '#020202',
      opacity: 1,
    },
    // '& .Mui-selected': {
    //   borderBottom: '3px solid #407ace',
    // },
    '& .MuiTabs-indicator': {
      borderBottom: '2px solid #407ace',
      background: '#407ace',
    },
  },
}))

export default function NotificationTabs() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={classes.tabRoot}>
      <AppBar position="static" className="tab-header">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Overview" {...a11yProps(0)} className="history-tab" />
          {/* <Tab label="History" {...a11yProps(1)} className="history-tab" /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DocumentVault />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <DocumentVault />
      </TabPanel> */}
    </div>
  )
}
