import * as React from 'react'
import { IconProps } from './props'
import Tooltip from '@material-ui/core/Tooltip'

function DeleteIcon({ width, height, tooltipText }: IconProps) {
  return (
    <Tooltip title={tooltipText || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width || '23'} height={height || '25'} viewBox="0 0 23 25">
        <g fill="none" fillRule="evenodd" stroke="#FF8B8B" strokeLinecap="round" strokeWidth="2">
          <path
            d="M0 23L20.467 0M20.467 23L0 0"
            transform="translate(-1276 -415) translate(1216 406) translate(61 10)"
          />
        </g>
      </svg>
    </Tooltip>
  )
}

export default DeleteIcon
