import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { Tooltip } from '@material-ui/core'
import { DoneAll, ErrorOutline } from '@material-ui/icons'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '14px',
      fontWeight: 'bold',
      minWidth: '25%',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginLeft: '0.5rem',
        cursor: 'pointer',
      },
    },
    value: {
      fontSize: '14px',
      // textTransform: 'uppercase',
      lineBreak: 'anywhere',
    },
  })
)

interface OrderCardContentProps {
  title: string
  value: string | number
  isLoading?: boolean
  isPaid?: string
}

const OrderCardContent = (props: OrderCardContentProps) => {
  const classes = useStyles()
  const { title, value, isLoading, isPaid } = props

  return (
    <div className={classes.mainContainer}>
      <p className={classes.title}>
        {title}
        {isPaid === 'paid' ? (
          <Tooltip title="Paid" arrow>
            <DoneAll htmlColor="#4caf50" fontSize="small" />
          </Tooltip>
        ) : isPaid === 'unpaid' ? (
          <Tooltip title="Unpaid" arrow>
            <ErrorOutline htmlColor="red" fontSize="small" />
          </Tooltip>
        ) : (
          ''
        )}
      </p>
      {isLoading ? <Skeleton variant="text" width={100} /> : <p className={classes.value}>{value}</p>}
    </div>
  )
}

OrderCardContent.defaultProps = {
  isPaid: '',
  isLoading: false,
  value: '',
  title: '',
}

export default OrderCardContent
