import { Dialog, FormControl, FormHelperText, IconButton, TextField } from '@material-ui/core'
import { createMeetingLinks, MeetingLinkProps } from 'api/meetingLinks'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../styles'
import CloseIcon from '@material-ui/icons/Close'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showApiResponseError from 'utils/showApiResponseError'
import { notify } from '@jetkit/react'

interface AddMeetingLinkDialogProps {
  isOpen: boolean
  close: () => void
  onRequestSuccessful?: () => void
}

const AddMeetingLinkDialog: React.FC<AddMeetingLinkDialogProps> = ({ isOpen, close, onRequestSuccessful }) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [link, setLink] = useState('')
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const setErrorMessages = React.useCallback(() => {
    if (!title) errors.setTitle(true)
    if (!link) errors.setLink(true)
  }, [title, link])

  const useMeetingLinksErrors = () => {
    const [title, setTitle] = React.useState(false)
    const [link, setLink] = React.useState(false)

    const clearAll = React.useCallback(() => {
      setTitle(false)
      setLink(false)
    }, [])

    return {
      title,
      setTitle,
      link,
      setLink,
      clearAll,
    }
  }
  const errors = useMeetingLinksErrors()

  const tryToSendRequest = React.useCallback(async () => {
    if (!title || !link) return
    const meetingData: MeetingLinkProps = {
      title,
      link,
    }
    setWaitingForResponse(true)
    try {
      await createMeetingLinks(meetingData)
      setWaitingForResponse(false)
      close()
      notify.success('Meeting Item Created!')
      onRequestSuccessful && onRequestSuccessful()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, `Sorry, could not create Meeting`)
    }
  }, [close, link, title, onRequestSuccessful])

  const handleAddMeetingLink = React.useCallback(() => {
    tryToSendRequest()
    setErrorMessages()
  }, [setErrorMessages, tryToSendRequest])

  return (
    <Dialog open={isOpen} PaperProps={{ className: classes.paper }}>
      <IconButton onClick={close} className={classes.closeButton}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <div className={classes.innerContainer} data-testid="notification-dialog-inner-container">
        <div className={classes.title}>Add Meeting Link</div>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <TextField
            label="Title"
            variant="outlined"
            value={title}
            onChange={e => setTitle(e.target.value)}
            // helperText={errors?.name ? 'Enter Name' : ''}
          />
          <FormHelperText className={classes.errorMsg}>{errors?.title ? '*Enter Title' : ''}</FormHelperText>
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <TextField
            label="Meeting Link"
            variant="outlined"
            value={link}
            onChange={e => setLink(e.target.value)}
            // helperText={errors?.meetingLink ? 'Enter meetingLink' : ''}
          />
          <FormHelperText className={classes.errorMsg}>{errors?.link ? '*Enter Meeting Link' : ''}</FormHelperText>
        </FormControl>
        <ButtonWithLoading
          kind="BRPrimary"
          wrapperClassName={classes.scheduleButton}
          isLoading={waitingForResponse}
          onClick={handleAddMeetingLink}
        >
          Add Meeting Link
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default AddMeetingLinkDialog
