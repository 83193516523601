import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const StatesTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>id</StyledTableCell>
        <StyledTableCell>Title</StyledTableCell>
        <StyledTableCell>Heading</StyledTableCell>
        <StyledTableCell>Link Text</StyledTableCell>

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default StatesTableHead
