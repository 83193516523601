import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    creditClientDashboard: {
      fontFamily: 'Montserrat, sans-serif',
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      '& p, h2, h3,': {
        margin: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& .inline-icon-heading': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.6rem',

        '& .avatar': {
          marginRight: '1rem ',
        },
      },
      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2.5rem',
        fontFamily: theme.typography.h1.fontFamily,
        margin: '0',
      },

      '& .user-back-card': {
        padding: '1rem',
        background: '#f7faff',
      },

      '& .title': {
        fontSize: '1rem',
        paddingBottom: '1rem',
        fontWeight: 600,
      },
      '& .description': {
        fontSize: '1.5rem',
      },
      '& .highlight-text': {
        fontWeight: 500,
        fontSize: '1rem',
        color: '#5b38da',
        paddingBottom: '1rem',
      },
      '& .credit-score-card': {
        padding: '1rem',
        marginTop: '3rem',

        '& .sub-title': {
          fontSize: '1rem',
          paddingTop: '1rem',
          fontWeight: 500,
        },
      },

      '& .score-meter-card': {
        margin: '3rem 0',
        padding: '2rem',
        background: '#f7faff',
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },

      '& .credit-data-card': {
        padding: '2rem 1.5rem',
        background: '#f9fcff',

        '& .credit-heading': {
          fontSize: '1.4rem',
        },
        '& .credit-title': {
          fontSize: '1.15rem',
          padding: '0.5rem 0 1rem 0',
        },
        '& .credit-description': {
          fontSize: '0.85rem',
        },
      },

      '& .container': {
        position: 'relative',

        '& .df-n': {
          display: 'none',
        },

        '& .box-overlay': {
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 1,
          background: 'rgba(255, 255, 255, 0.84)',
          cursor: 'not-allowed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '& .moniter-card': {
            border: '3px solid #5b38da',
            backgroundColor: '#fff',
            padding: '2.6rem 4rem',
            position: 'relative',
            textAlign: 'center',
            zIndex: 2,

            '& .title': {
              fontSize: '1.15rem',
              fontWeight: 600,
            },
            '& .subscribe-btn': {
              background: '#5b38da',
              color: '#fff',
              borderRadius: '2rem',
              padding: '0.5rem 3rem',
              marginTop: '1rem',
            },
          },
        },
      },
    },
    creditScoreMeterContainer: {
      textAlign: 'center',
      fontFamily: 'Montserrat, sans-serif',

      '& .profile-title': {
        color: '#788190',
        fontSize: '0.75rem',
        margin: 0,
        textAlign: 'center',
        marginBottom: '0.75rem',
      },

      '& .profile-subtitle1': {
        color: '#343b4c',
        fontSize: '1rem',
        margin: 0,
        textAlign: 'center',
        marginTop: '-0.5rem',
        marginBottom: '1.25rem',
        fontWeight: 700,
      },
      '& .profile-subtitle2': {
        color: '#788190',
        fontSize: '0.75rem',
        margin: 0,
        textAlign: 'center',
        marginTop: '-1rem',
      },
      '& .profile-subtitle3': {
        color: '#343b4c',
        fontSize: '0.75rem',
        margin: 0,
        textAlign: 'center',
        marginTop: '-0.5rem',
      },
    },
    creditProgressContainer: {
      margin: '1rem 4rem 0px',
      height: '175px',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',

      '& .label': {
        color: '#343b4c',
        fontSize: '1rem',
        margin: 0,
        marginBottom: '0.5rem',
        fontWeight: 700,
      },

      '& .CircularProgressbar > .CircularProgressbar-text': {
        fontWeight: 700,
        fontSize: '2.5rem',
      },
    },
  })
)
