import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      color: '#292929',
    },
    heading: {
      fontSize: '20px',
      textTransform: 'uppercase',
      fontFamily: theme.typography.h1.fontFamily,
    },
    paraText: {
      fontSize: '12px',
      marginTop: '0.4rem !important',
    },
  })
)

interface PrimaryHeadingProps {
  heading?: string
  text?: string
}

const PrimaryHeading = ({ heading, text }: PrimaryHeadingProps) => {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <h1 className={classes.heading}>{heading}</h1>
      <p className={classes.paraText}>{text}</p>
    </div>
  )
}

export default PrimaryHeading
