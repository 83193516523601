import { TextField } from '@material-ui/core'
import * as React from 'react'
import { SetFieldValueContext } from 'utils/FormGenerator'
import { IFormFieldProps } from 'utils/FormGenerator/formTypings'
import { useStyles } from 'utils/FormGenerator/styles'
import { isTextField } from 'utils/FormGenerator/typeAssertionFunction'
import CopyButton from 'components/common/CopyButton'

const TextFieldComponent = ({ field, value }: IFormFieldProps<string>) => {
  const classes = useStyles()

  if (!isTextField(field)) {
    throw new Error('type error')
  }

  const setFieldValue = React.useContext(SetFieldValueContext)

  return (
    <div className={classes.copyButtonContainer}>
      <CopyButton valueToCopy={value} />
      <TextField
        label={field.label}
        name={field.name}
        value={value}
        classes={{ root: classes.fieldRoot }}
        InputLabelProps={{
          classes: { root: classes.fieldLabel, shrink: classes.fieldLabelShrink },
          shrink: true,
        }}
        id={field.name}
        onChange={event => setFieldValue(field.name, event.target.value)}
        InputProps={{ classes: { root: classes.textFieldInputRoot, underline: classes.textInputUnderline } }}
        multiline={field.multiline}
        rows={field.row}
      />
    </div>
  )
}

export default TextFieldComponent
