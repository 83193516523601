import { ISuggestionAttribute } from 'components/interfaces'
import * as React from 'react'
import MultiSelect from '../select/multiSelect'

interface ICompanyOrderRequiredOrdersFieldProps {
  addAttribute: (field: string, select: any) => void
  fetchSuggestions: ((query: string, field: string) => Promise<ISuggestionAttribute[]>) | undefined
  reloadKey: string | number | undefined
}

/**
 * An order may depend on completion of other orders before the work on it begins.
 * When interacting with an order we should be able to select multiple "required" orders.
 *
 * @param addAttribute: Add another required order attribute
 * @param fetchSuggestions: Get options of required orders to pick from
 * @param reloadKey: Value that triggers component re-render
 */
const CompanyOrderRequiredOrdersField = ({
  addAttribute,
  fetchSuggestions,
  reloadKey,
}: ICompanyOrderRequiredOrdersFieldProps) => {
  return (
    <MultiSelect
      addAttribute={addAttribute}
      title="Required Orders"
      fetchSuggestions={fetchSuggestions}
      field="required_order_ids"
      async={true}
      placeholder="Required Orders"
      styled={true}
      reloadKey={reloadKey}
    />
  )
}

export default CompanyOrderRequiredOrdersField
