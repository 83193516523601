import { getStaff } from 'api/staff'
import { ISuggestionAttribute } from 'components/interfaces'
import { IUser } from 'models/user'
import * as React from 'react'

type Props = {
  assignee: IUser | null
  handleAssigneeSelected(assignee: IUser | null): void
}

export const useAssigneeField = ({ assignee, handleAssigneeSelected }: Props) => {
  const assigneeAsSuggestionAttribute: ISuggestionAttribute | undefined = React.useMemo(
    () =>
      assignee?.id
        ? {
            label: assignee.name,
            value: assignee.id,
          }
        : undefined,
    [assignee]
  )

  const onAssigneeSelected = React.useCallback(
    async (option: ISuggestionAttribute | null) => {
      const assigneeId = option?.value

      if (!assigneeId) {
        handleAssigneeSelected(null)
        return
      }

      const selectedUser = await getStaff(assigneeId)
      handleAssigneeSelected(selectedUser)
    },
    [handleAssigneeSelected]
  )

  return {
    assigneeAsSuggestionAttribute,
    onAssigneeSelected,
  }
}
