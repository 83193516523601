import React, { useMemo } from 'react'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { useStyles } from '../styles'
import AutoReminderRow from './AutoReminderRow'
import AutoReminderTableHead from './AutoReminderTableHead'
import useSortTable from 'hooks/useSortTable'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { skeletons } from 'components/common/table'
import DeleteDialog from 'components/notifications/deleteDialog'
import { useHistory } from 'react-router'
import { getPropspectSchedule, deletePropspectScheduleItems, PropspectScheduleProps } from 'api/leadManager'

const Schedules = () => {
  const classes = useStyles()
  const history = useHistory()

  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [reminderItemToDelete, setReminderItemToDelete] = React.useState<PropspectScheduleProps>()
  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const tableHead = useMemo(
    () => <AutoReminderTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getPropspectSchedule()({ query }), [query])

  const pagedTable = usePagedTable<PropspectScheduleProps>({
    apiCall: memoApiCall,
  })

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleEditNotificationClick = React.useCallback(
    (autoReminder: PropspectScheduleProps) => {
      console.log(autoReminder)
      const { id } = autoReminder
      history.push(`/prospect-schedule/${id}`)
    },
    [history]
  )
  const handleDeleteNotificationClick = React.useCallback((autoReminder: PropspectScheduleProps) => {
    setReminderItemToDelete(autoReminder)
    setDeleteItemDialogShown(true)
  }, [])
  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  return (
    <div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 3, sizes: [180, 200, 120, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Auto Reminders"
          {...pagedTable.renderProps}
          renderRow={(autoReminder: PropspectScheduleProps) => (
            <AutoReminderRow
              autoReminder={autoReminder}
              onEditClick={() => handleEditNotificationClick(autoReminder)}
              onDeleteClick={() => handleDeleteNotificationClick(autoReminder)}
            />
          )}
          header={tableHead}
        />
      )}

      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={reminderItemToDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deletePropspectScheduleItems}
      />
    </div>
  )
}

export default Schedules
