import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { BusinessCenter, MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import CompanyCreationFlow from 'components/companies/create'
import { IClient } from 'models/user'
import * as React from 'react'
import { formatPhoneNumberToInternational, formatSSN } from 'utils/formatNumbers'
import MoreOptionsMenu from '../moreOptionsMenu'
import { Chip } from '@material-ui/core'
import { COLORS } from 'utils/constant'
import { IAddress } from 'models/address'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },

    primaryAddress: {
      marginTop: '0.25rem',
      '& p': {
        color: '#0000009c',
        fontSize: '12px',
        margin: '0',
        fontStyle: 'italic',
      },
    },
  })
)

interface IClientTableRowProps {
  client: IClient
  onEditClick: () => void
  onDeleteClick: () => void
  fromCompany: boolean
}

const ClientTableRow: React.FC<IClientTableRowProps> = ({ client, onEditClick, onDeleteClick, fromCompany }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialClient={client} />
      <StyledTableRow onClick={onEditClick} data-testid={`${client.id}`} className={classes.row}>
        <StyledTableCell>{client.id || '- - -'}</StyledTableCell>
        <StyledTableCell>
          {client.name}
          <br />
          {client?.addresses?.map((address: IAddress) =>
            address?.is_primary ? (
              <div className={classes.primaryAddress}>
                <p>{address?.street}</p>
                <p>
                  {address?.city}&nbsp;{address?.locality}&nbsp;{address?.zip}
                </p>
              </div>
            ) : (
              ''
            )
          )}
          {client.is_registered_agent && (
            <Chip
              label={'Registered Agent'}
              size="small"
              style={{ marginTop: '5px', background: COLORS.registeredAgent }}
            />
          )}
        </StyledTableCell>
        <StyledTableCell>
          {formatPhoneNumberToInternational(client.phone_number ? client.phone_number : '')}
        </StyledTableCell>
        <StyledTableCell>{client.email}</StyledTableCell>
        <StyledTableCell>{client.sales_rep ? client?.sales_rep?.name : null}</StyledTableCell>
        <StyledTableCell>${Number(client.credit)?.toFixed(2) || 0}</StyledTableCell>
        <StyledTableCell>
          {client?.ssn_ein?.toString().includes('-')
            ? client?.ssn_ein
            : formatSSN(client.ssn_ein ? client.ssn_ein.toString() : '')}
        </StyledTableCell>
        {fromCompany && <StyledTableCell></StyledTableCell>}

        {!fromCompany && (
          <StyledTableCell>
            <span ref={moreOptionsRef}>
              <TableActionButton
                className={classes.button}
                onClick={event => {
                  event.stopPropagation()
                  setIsMoreOptionsOpen(!isMoreOptionsOpen)
                }}
              >
                <Tooltip title="Show More" ref={moreOptionsRef}>
                  <MoreVert />
                </Tooltip>
                <MoreOptionsMenu
                  isOpen={isMoreOptionsOpen}
                  refElement={moreOptionsRef}
                  setIsOpen={setIsMoreOptionsOpen}
                  client={client}
                />
              </TableActionButton>
            </span>
            <TableActionButton
              className={classes.button}
              onClick={event => {
                event.stopPropagation()
                setIsModalOpen(true)
              }}
            >
              <Tooltip title="Create company for the client">
                <AddIcon />
              </Tooltip>
            </TableActionButton>
            <TableActionButton
              className={classes.button}
              onClick={event => {
                event.stopPropagation()
                onDeleteClick()
              }}
            >
              <DeleteIcon tooltipText="Delete client" />
            </TableActionButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
    </>
  )
}

export default ClientTableRow
