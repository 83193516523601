import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'

interface AllDetails {
  id: number
  call_status: string
  status: string
  call_id: string
  caller_name: string
  date_time: string
  callee_name: string
  duration: number
  caller_number: string
}
export interface CallLogsDetails {
  details: AllDetails[]
}
export interface IUpdateStatus {
  status: string
}

export interface NotesData {
  notes?: string
  created_by?: string
  call_log_id?: string
}

export interface ICallWidgets {
  status?: string
  count?: number
  call_queue?: string
}

export interface ICallDashboardWidget {}
export const fetchCallLogs = (status?: number | string, call_queue?: number) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.216:3000/'
  return requestPaginated<CallLogsDetails[]>({
    url: `callqueuelogs/list?status=${status}&call_queue=${call_queue}`,
  })
}

export const updateCallStatus = async (id: string, data: IUpdateStatus): Promise<AxiosResponse<void>> =>
  apiClient.patch(`/callqueuelogs/${id}`, data)

export const addCallLogsNote = async (payload: NotesData): Promise<any> => {
  return await apiClient.post('/callqueuelognotes', payload)
}

export const getCallLogsNoteById = async (callLogId: string): Promise<NotesData[]> => {
  return (await apiClient.get(`/callqueuelognotes/?call_logs_id=${callLogId}`)).data
}

export const getCallLogsWidgets = async (): Promise<ICallWidgets> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.216:3000/'
  return (await apiClient.get(`callqueuelogs/count`)).data
}
