import { Button, Divider, Grid, Link, TextareaAutosize, Typography, TextField } from '@material-ui/core'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import {
  addComplianceNote,
  complianceCompanyClosed,
  complianceCompleteOrder,
  complianceNotificationSequence,
  ComplianceSequenceData,
  ComplianceTasksData,
  getcomplianceCurrentSequence,
  getComplianceNoteById,
  getComplianceNotificationTemplates,
  NotesData,
} from 'api/complianceCalendar'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'
import CreateOrder from 'components/orders/create'
import { CURRENT_USER_LOCAL_STORAGE_KEY } from 'hooks/useUser'
import React, { useState, useEffect } from 'react'
import { COMPLIANCE_TASKS_STATUS } from 'utils/constant'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import { reversedArray } from 'utils/functions'
import showErrorNotification from 'utils/showErrorNotification'
import { useStyles } from './styles'
import ViewMoreDialog from './viewMoreDialog'
import copyToClipboard from 'utils/copyToClipboard'
import { nameCheckByStateId } from 'api/namechecklist'
import showApiResponseError from 'utils/showApiResponseError'
import { ICompanyTask } from 'models/task'
import AddTaskDialog from './complianceTasks/addTaskDialog'

interface InnerTableItemProps {
  inProgressBtn?: boolean
  taskDetails?: ComplianceTasksData
  completeOrderHandler(id: any): void
  companyCloseHandler(id: any): void
  inProgressOrderHandler(id: any): void
  setIsUpdated?: any
  setTempNoteUpdate?: any
}

const InnerTableItem = ({
  inProgressBtn,
  taskDetails,
  completeOrderHandler,
  companyCloseHandler,
  inProgressOrderHandler,
  setIsUpdated,
  setTempNoteUpdate,
}: InnerTableItemProps) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [noteText, setNoteText] = useState('')
  const [addNoteLoading, setAddNoteLoading] = useState(false)
  const [getNoteLoading, setGetNoteLoading] = useState(false)
  const [notes, setNotes] = useState<NotesData[]>([])
  const [scheduleList, setScheduleList] = useState<any[]>([])
  const [scheduleID, setScheduleID] = useState()
  const [isScheduleLoading, setIsScheduleLoading] = useState(false)

  const [isCompanyClosed, setIsCompanyClosed] = useState(false)
  const [isCompleteOrder, setIsCompleteOrder] = useState(false)

  const [runningSequences, setRunningSequences] = useState<any>()

  const userDetails = JSON.parse(localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY) || '')
  const userId = userDetails?.extid

  const getNotes = async (id: number | undefined) => {
    setGetNoteLoading(true)
    try {
      if (id) {
        const notes = await getComplianceNoteById(id)
        setNotes(notes)
        setGetNoteLoading(false)
      }
    } catch (error) {
      showErrorNotification(error)
    }
    setGetNoteLoading(false)
  }

  const handleAddNote = async () => {
    // setIsUpdated(false)
    setAddNoteLoading(true)
    const data = {
      note_text: noteText,
      company_id: taskDetails?.company_id,
      compliance_task_id: taskDetails?.id,
      created_by: userId,
    }
    if (data) {
      try {
        await addComplianceNote(data)
        setNoteText('')
        getNotes(taskDetails?.id)
        setTempNoteUpdate(true)

        // if (taskDetails?.last_note !== 1) {
        //   setTempNoteUpdate(true)
        // } else {
        //   setTempNoteUpdate(false)
        // }
        setAddNoteLoading(false)
        // setIsUpdated(true)
      } catch (error) {
        showErrorNotification(error)
        setAddNoteLoading(false)
      }
    }
    // setIsUpdated(false)
  }

  useEffect(() => {
    if (taskDetails?.id) {
      getNotes(taskDetails?.id)
    }
  }, [taskDetails?.id])

  // const completeOrderHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.completed,
  //   }
  //   try {
  //     const complateOrder = await complianceCompleteOrder(payload)
  //     return complateOrder
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }
  // const companyCloseHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.closed,
  //   }
  //   try {
  //     const companyClosed = await complianceCompanyClosed(payload)
  //     return companyClosed
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }
  // const inProgressOrderHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.inprogress,
  //   }
  //   try {
  //     const companyClosed = await complianceInProgressOrder(payload)
  //     return companyClosed
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }

  const notesReversed = reversedArray(notes)

  // const notesReversed = Array.from(notes).reverse()

  console.log('notesReversed', notesReversed)
  console.log('notes', notes)

  useEffect(() => {
    const getComplianceSchedule = async () => {
      try {
        const respose = await getComplianceNotificationTemplates()
        setScheduleList(respose)
      } catch (error) {
        console.error('error', error)
      }
    }
    getComplianceSchedule()
  }, [])

  const getCurrentSequence = async (id: number) => {
    try {
      const result = await getcomplianceCurrentSequence(id)
      setRunningSequences(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSchedule = async (id: any, type: 'start' | 'stop') => {
    // setIsUpdated(false)
    if (type === 'start' && !scheduleID) {
      showErrorNotification('Please Select Schedule template')
      return
    }
    const payload: ComplianceSequenceData = {
      compliance_task_id: id,
      // compliance_template_id: scheduleID,
      compliance_template_id: type === 'start' ? scheduleID : runningSequences?.id,
      type: type,
    }

    setIsScheduleLoading(true)
    try {
      await complianceNotificationSequence(payload)
      setIsScheduleLoading(false)
      setScheduleID(undefined)
      // setIsUpdated(true)
      if (taskDetails?.id) {
        getCurrentSequence(taskDetails?.id)
      }
    } catch (error) {
      console.error('error', error)
    }
    setIsScheduleLoading(false)
    // setIsUpdated(false)
  }

  useEffect(() => {
    if (taskDetails?.id) {
      getCurrentSequence(taskDetails?.id)
    }
  }, [taskDetails?.id])

  const [namecheckloading, setnamecheckloading] = React.useState<boolean>(false)

  const [addTaskModal, setAddTaskModal] = React.useState(false)
  const [taskToEdit, setTaskToEdit] = React.useState<ICompanyTask>()

  const addTaskHanlder = React.useCallback(() => {
    setAddTaskModal(true)
  }, [])

  const checkName = async () => {
    try {
      setnamecheckloading(true)
      debugger
      const res = await nameCheckByStateId(taskDetails?.details.company?.state_of_formation_id || 0)
      if (res && res.name_check_url && res.name_check_url.length) {
        window.open(res.name_check_url, '_blank')
        setnamecheckloading(false)
      } else {
        showErrorNotification('No Url provided for this state')
        setnamecheckloading(false)
      }
      //showSuccessNotification('Sent Successfully')
    } catch (e) {
      showApiResponseError(e, 'Error: Something went wrong! ')
      setnamecheckloading(false)
    }
  }
  return (
    <div className={classes.innerItems}>
      <DialogWithConfirmButtons
        onClose={() => setIsCompanyClosed(false)}
        open={isCompanyClosed}
        title={`Are you sure you want to close your ${taskDetails?.title} company ?`}
        firstOptionTitle="Yes"
        secondOptionTitle="Cancel"
        onFirstOptionClick={() => companyCloseHandler(taskDetails?.id)}
        onSecondOptionClick={() => setIsCompanyClosed(false)}
      />
      <DialogWithConfirmButtons
        onClose={() => setIsCompleteOrder(false)}
        open={isCompleteOrder}
        title={`Are you sure you want to complete your ${taskDetails?.title} order ?`}
        firstOptionTitle="Yes"
        secondOptionTitle="Cancel"
        onFirstOptionClick={() => completeOrderHandler(taskDetails?.id)}
        onSecondOptionClick={() => setIsCompleteOrder(false)}
      />
      <AddTaskDialog
        open={addTaskModal}
        onClose={() => setAddTaskModal(false)}
        // onTaskCreated={onTaskCreated}
        complianceId={taskDetails?.id || 0}
        complianceName={`${taskDetails?.id} ${taskDetails?.title}`}
        task={taskToEdit}
        mode={taskToEdit ? 'update' : 'create'}
      />

      <div className="header-controls">
        <ButtonWithLoading kind="BRPrimary" wrapperClassName="text-template-btn" onClick={addTaskHanlder}>
          Add Task
        </ButtonWithLoading>
        <div className="btn-sec">
          {inProgressBtn ? (
            <Button
              variant="contained"
              color="primary"
              className="inProgress-btn"
              onClick={() => inProgressOrderHandler(taskDetails?.id)}
            >
              In Progress Order
            </Button>
          ) : (
            ''
          )}
          <Button
            variant="contained"
            color="primary"
            className="complete-btn"
            // onClick={() => completeOrderHandler(taskDetails?.id)}
            onClick={() => setIsCompleteOrder(true)}
          >
            Complete
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            className="create-btn"
            // onClick={() => completeOrderHandler(taskDetails?.id)}
            // onClick={() => setIsCompleteOrder(true)}
          >
            Create Order
          </Button> */}

          <CreateOrder
            btnClassName="create-btn"
            buttonName="Create Order"
            companyId={taskDetails?.company_id}
            complianceId={taskDetails?.id}
            setIsUpdated={setIsUpdated}
          />

          {!inProgressBtn && (
            <Button
              variant="contained"
              color="primary"
              className="copy-btn"
              // onClick={() => completeOrderHandler(taskDetails?.id)}
              onClick={() => copyToClipboard(taskDetails?.form_link)}
            >
              Copy Renewal Link
            </Button>
          )}
          {!inProgressBtn && (
            <ButtonWithLoading
              variant="contained"
              color="primary"
              className="namecheck-btn"
              // onClick={() => completeOrderHandler(taskDetails?.id)}
              onClick={checkName}
              isLoading={namecheckloading}
            >
              Name Check
            </ButtonWithLoading>
          )}

          {/* <Button
            variant="contained"
            color="primary"
            className="schedule-btn"
            // onClick={() => completeOrderHandler(taskDetails?.id)}
            // onClick={() => setIsCompleteOrder(true)}
          >
            Schedule
          </Button> */}
        </div>
        <div className="btn-sec">
          <Button
            variant="contained"
            color="primary"
            className="company-btn"
            // onClick={() => companyCloseHandler(taskDetails?.id)}
            onClick={() => setIsCompanyClosed(true)}
          >
            Cancel
          </Button>
          <Link component="button" variant="body2" className="link-btn" onClick={() => setOpen(true)}>
            View More
          </Link>
        </div>
      </div>
      {!inProgressBtn && (
        <Grid container spacing={1} style={{ marginTop: '1rem' }}>
          <Grid item xs={7}>
            <Autocomplete
              id="schedulesValue"
              options={scheduleList}
              getOptionLabel={option => option?.name}
              onChange={(_, newValue: any) => {
                setScheduleID(newValue?.id)
              }}
              renderInput={params => <TextField {...params} label="Schedules" variant="outlined" />}
              className="schedule-filed"
            />
            <p className="schedule-text">
              <strong>Currently Running Schedule: </strong>
              {/* {runningSequences[0]?.name} */}
              {runningSequences?.name} <strong>By: </strong>
              {runningSequences?.created_by} <strong>Creation Time: </strong>
              {runningSequences?.creation_time ? formatDateTimeToHumanFriendly(runningSequences?.creation_time) : ''}
            </p>
          </Grid>

          <Grid item xs={1}>
            <ButtonWithLoading
              isLoading={isScheduleLoading}
              wrapperClassName="start-btn"
              onClick={() => handleSchedule(taskDetails?.id, 'start')}
            >
              START
            </ButtonWithLoading>
          </Grid>
          <Grid item xs={1}>
            <ButtonWithLoading
              isLoading={isScheduleLoading}
              wrapperClassName="end-btn"
              onClick={() => handleSchedule(taskDetails?.id, 'stop')}
            >
              STOP
            </ButtonWithLoading>
          </Grid>
        </Grid>
      )}
      <Divider className="item-divider" />
      <Grid container spacing={3} className="text-box-grid">
        <Grid item xs={4}>
          <div className="text-box">
            <TextareaAutosize
              className="text-area"
              placeholder="Notes/ Descriptions"
              rowsMax="10"
              rowsMin="10"
              value={noteText}
              name="noteText"
              onChange={e => setNoteText(e.target.value)}
            />
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="save-btn"
              onClick={handleAddNote}
              isLoading={addNoteLoading}
              disabled={!noteText.length}
            >
              Save
            </ButtonWithLoading>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="text-box">
            {getNoteLoading
              ? [1, 2, 3, 4, 5, 6].map(key => <Skeleton animation="wave" variant="text" key={key} />)
              : notes.length
              ? notesReversed.slice(0, 3)?.map(note => (
                  <div className="bottom-space" key={note?.id}>
                    <p className="dark-text">
                      {note?.created_by} {formatDateTimeToHumanFriendly(note?.created_at || '')}
                    </p>
                    <p className="dark-text">{note?.note_text}</p>
                  </div>
                ))
              : 'No notes found'}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="input-box">
            <div className="inputs">
              <p className="input-dark-text">Primary Contact Name </p>
              <p className="input-light-text">
                {taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[0]?.name}{' '}
                {taskDetails?.details?.company_details?.client?.isdeleted ? (
                  <Typography
                    variant="caption"
                    display="block"
                    color="error"
                    align="right"
                    style={{ paddingLeft: '1rem' }}
                  >
                    (Client Deleted)
                  </Typography>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Phone </p>
              <p className="input-light-text">
                {taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[0]?.phone_1}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Email </p>
              <p className="input-light-text">
                {taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[0]?.email}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Email </p>
              <p className="input-light-text">
                {(taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[1]?.email_1) ||
                  ''}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <ViewMoreDialog
        open={open}
        setOpen={setOpen}
        taskDetails={taskDetails}
        notes={notes}
        inProgressBtn={inProgressBtn}
        completeOrderHandler={completeOrderHandler}
        companyCloseHandler={companyCloseHandler}
        inProgressOrderHandler={inProgressOrderHandler}
      />
    </div>
  )
}

InnerTableItem.defaultProps = {
  inProgressBtn: false,
}

export default InnerTableItem

// const scheduleList = [
//   { value: 0, label: 'Day' },
//   { value: 1, label: 'Week' },
// ]
