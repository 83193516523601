import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CheckboxWithDropDownArrow from 'components/common/elements/CheckboxWIthDropDownArrow'
import { IHumanReadableDocsStatus } from 'models/task'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginLeft: 9,
      marginBottom: 2,
    },
  })
)

export type SubtaskSelectionMenuItems = 'All' | 'None' | IHumanReadableDocsStatus

interface IOrderSubtaskHeader {
  checked: boolean
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
  indeterminate?: boolean
  menuItems: string[]
  onMenuItemClick(menuItem: SubtaskSelectionMenuItems): void
}

const OrderSubtaskHeader: React.FC<IOrderSubtaskHeader> = props => {
  const { checked, onChange, indeterminate, menuItems, onMenuItemClick } = props

  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <CheckboxWithDropDownArrow
        color="default"
        checked={checked}
        onChange={onChange}
        indeterminate={indeterminate}
        menuItems={menuItems}
        handleMenuItemClick={onMenuItemClick}
      />
    </div>
  )
}

export default OrderSubtaskHeader
