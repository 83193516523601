import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import ModalTitle from '../modalTitle'
import { Typography, CircularProgress } from '@material-ui/core'
import AlertButton from '../buttons/AlertButton'
import { useConfirmationDialogStyles } from './confirmationDialogStyles'
interface IDeleteConfirmationDialog {
  itemName: string | React.ReactNode
  itemType?: string
  onClose(): void
  open: boolean
  isDeleting?: boolean
  onDelete(): void
  actionText?: string
  buttonText?: string
}

const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialog> = ({
  itemName,
  itemType = 'item',
  onClose,
  open,
  isDeleting,
  onDelete,
  actionText,
  buttonText,
}) => {
  const classes = useConfirmationDialogStyles()

  return (
    <Dialog onBackdropClick={onClose} maxWidth="xl" open={open}>
      <div className={classes.container}>
        <ModalTitle
          title={`Are you sure you want to ${actionText ? actionText : 'delete'} this ${itemType}?`}
          toggleModal={onClose}
        />
        <div className={classes.itemNameWrapper}>
          <Typography className={classes.itemName}>{itemName}</Typography>
        </div>
        <AlertButton
          disabled={isDeleting}
          classes={{ disabled: classes.disabled, button: classes.button }}
          onClick={onDelete}
          data-testid="delete-conf-button"
        >
          {/* {isDeleting ? '' : 'Delete'} */}
          {buttonText ? buttonText : 'Delete'}
        </AlertButton>
        {isDeleting && <CircularProgress size={30} className={classes.fabProgress} />}
      </div>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
