import { AsyncSelectField } from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

interface ICompanyOrderAssigneeFieldProps {
  fetchSuggestions: (query: string, field: string) => Promise<ISuggestionAttribute[]>
  assigneeAsSuggestionAttribute: ISuggestionAttribute | null | undefined
  onOptionSelected: (option: ISuggestionAttribute | null) => void
}

/**
 * We may wanna use a dropdown select field to assign a user who will tackle the completion of a particular order or task.
 *
 * @param fetchSuggestions: A list of assignee-options to select from.
 * @param assigneeAsSuggestionAttribute: Current value in the selection field.
 * @param onOptionSelected: Handler triggering when an option is selected from a dropdown.
 * @param key: Value to trigger component re-rendering.
 */
export const CompanyOrderAssigneeField = ({
  fetchSuggestions,
  assigneeAsSuggestionAttribute,
  onOptionSelected,
}: ICompanyOrderAssigneeFieldProps) => {
  return (
    <AsyncSelectField
      dataTestId={TestID.ASSIGNEE_ASYNC_SELECT_DROPDOWN}
      title="Assignee"
      fetchSuggestions={fetchSuggestions}
      field="assignee_id"
      onOptionSelected={onOptionSelected}
      value={assigneeAsSuggestionAttribute}
      key={assigneeAsSuggestionAttribute?.value}
    />
  )
}
