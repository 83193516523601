import React, { useState } from 'react'
import { useStyles } from './styles'
import { Button, Grid } from '@material-ui/core'
import { ISpecialOffers } from 'api/specialOffers'
import clock from '../../../../img/clock.svg'
interface PowerCardProps {
  title: string
  heading: string
  subheading: string
  description: string
  link: string
  logo: string
  button_text: string
  onClick?: any
  terms_text?: string
}

const PowerCard = ({
  title,
  heading,
  subheading,
  description,
  link,
  logo,
  button_text,
  onClick,
  terms_text,
}: PowerCardProps) => {
  const classes = useStyles()
  const [showFullContent, setShowFullContent] = useState(false)
  return (
    <div className={classes.powerCard}>
      <Grid container style={{ minHeight: '281px' }}>
        <Grid xs={12} sm={12} item>
          <div className="title">
            <p
              style={{
                whiteSpace: showFullContent ? 'normal' : 'nowrap',
              }}
              className="title-text"
            >
              {title}
            </p>
            <img className="clock-img" src={clock} alt={title} />
          </div>
          <h1
            style={{
              whiteSpace: showFullContent ? 'normal' : 'nowrap',
            }}
            title={heading}
            className="heading-text"
          >
            {heading}
          </h1>

          <p
            style={{
              whiteSpace: showFullContent ? 'normal' : 'nowrap',
            }}
            title={subheading}
            className="sub-heading-text"
          >
            {subheading}
          </p>
          <div
            style={{
              maxHeight: showFullContent ? 'none' : '100px',
            }}
            dangerouslySetInnerHTML={{ __html: `${description}` }}
            className="content-text"
          />
          <div className="see-more">
            {description.length > 25 && !showFullContent ? (
              <Button
                style={{ fontSize: '14px' }}
                variant="text"
                color="primary"
                onClick={() => {
                  setShowFullContent(true)
                }}
              >
                See More
              </Button>
            ) : description.length > 25 ? (
              <Button
                style={{ fontSize: '14px' }}
                variant="text"
                color="primary"
                onClick={() => {
                  setShowFullContent(false)
                }}
              >
                View Less
              </Button>
            ) : (
              ''
            )}
          </div>
        </Grid>
        {/* <Grid style={{ height: '100%' }} xs={12} sm={12} item> */}
        <Grid xs={12} sm={12} item>
          <Grid container justify="space-between" spacing={2}>
            <Grid item lg={6} sm={6} xs={6}>
              <Button variant="contained" color="primary" className="power-btn" onClick={onClick}>
                {button_text}
              </Button>
            </Grid>
            <Grid item lg={6} sm={6} xs={6}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div className="powerimg">
                  <img height="100%" width="100%" src={logo} alt="Logo" />
                </div>
              </div>
            </Grid>
          </Grid>
          <p className="sub-title-text">{terms_text}</p>
        </Grid>
        {/* <Grid container justify="space-between" alignItems="flex-start">
          <Grid item xs={8} sm={6}>
            <Button variant="contained" color="primary" className="power-btn" onClick={onClick}>
              {button_text}
            </Button>
            <p className="sub-title-text">{terms_text}</p>
          </Grid>
          <Grid item xs={4} sm={6}>
            <div className="powerimg">
              <img src={logo} alt="Logo" />
            </div>
          </Grid>
        </Grid> */}
      </Grid>
      {/* </Grid> */}
    </div>
  )
}

export default PowerCard
