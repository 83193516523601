import React from 'react'
import { InlineItem } from './inlineItem'

const SingleOwner = ({ ownerName, ownership, positions, address, phone, email, ownershipText }) => {
  return (
    <div className="single-owners-container">
      <InlineItem title={'OWNER Name:'} content={ownerName} />
      <InlineItem title={ownershipText} content={ownership} />
      <InlineItem title={'Positions:'} chipText={positions} />
      <InlineItem title={'Address:'} content={address} />
      <InlineItem title={'PHONE:'} content={phone} />
      <InlineItem title={'Email:'} content={email} />
    </div>
  )
}

export default SingleOwner
