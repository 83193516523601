import InputBase from '@material-ui/core/InputBase'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import classnames from 'classnames'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'
import { normalizeText } from './functions'

const styles = (theme: Theme) =>
  createStyles({
    search: {
      borderRadius: '0.25rem',
      height: 54,
      border: 'solid 1px #979797',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchIcon: {
      color: '#979797',
      width: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: '#666666',
      width: '100%',
    },
    inputInput: {
      width: '100%',
      padding: 5,
      fontSize: 20,
    },
  })

interface ISearchBarProps extends WithStyles<typeof styles> {
  onChange: (value: string) => void
  placeholder?: string
  className?: string
  initialValue?: string
}

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

const SearchBar: React.FC<ISearchBarProps> = ({ className, classes, onChange, placeholder, initialValue }) => {
  const [searchTerm, setSearchTerm] = React.useState(initialValue || '')

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    onChange(debouncedSearchTerm)
  }, [debouncedSearchTerm, onChange])

  return (
    <div className={classnames(className, classes.search)}>
      <div className={classes.searchIcon}>
        <SearchIcon fontSize="large" />
      </div>
      <InputBase
        placeholder={placeholder || 'Search…'}
        type="search"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        data-testid={TestID.SEARCH_QUERY}
      />
    </div>
  )
}

export default withStyles(styles)(SearchBar)
