import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  FormControl,
  makeStyles,
  Theme,
} from '@material-ui/core'
import * as React from 'react'
import useRouter from 'use-react-router'
import showApiResponseError from 'utils/showApiResponseError'
import BRTextField from 'components/common/textFields/BRTextField'
import { forgotUser } from 'api/users'
import Logo from 'icons/logo'
import { LinearProgress } from '@material-ui/core'
import PhoneInput from 'components/clients/create/phoneInput'
import { Link } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { isLoggedIn } from '@jetkit/react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 450,

      '& .MuiTypography-root': {
        color: '#141923',
        fontSize: '3.5rem',
        fontWeight: 500,
        fontFamily: 'SFCompactDisplay',
        textAlign: 'center',
      },
    },

    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },

    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      color: '#f47950',
    },
    submitButton: {
      height: 70,
      color: '#fff',
      fontSize: '1.5rem',
      padding: '0 3.75rem',
      textTransform: 'capitalize',
      backgroundColor: theme.palette.primary.main,
      // backgroundColor: '#26316c',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#2479af',
        color: '#fff',
      },
    },
    fullWidth: {
      width: '100%',
    },
  })
)

const ForgotUsername = () => {
  const classes = useStyles()
  const router = useRouter()

  const [fullName, setFullName] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')

  const [isDisabled, setIsDisabled] = React.useState<boolean>(false)
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (isLoggedIn()) {
      router.history.push('/')
    }
  }, [router])

  React.useEffect(() => {
    if (!fullName?.length || !email?.length || !phone?.length || !companyName?.length) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [companyName?.length, email?.length, fullName?.length, phone?.length])

  const fullNameSplite = fullName?.split(' ')

  const onSubmitClick = async () => {
    if (isDisabled) {
      return
    }

    const payload = {
      first_name: fullNameSplite[0] || '',
      middle_name: fullNameSplite[2] ? fullNameSplite[1] : '',
      last_name: fullNameSplite[2] ? fullNameSplite[2] : fullNameSplite[1] || '',
      email: email,
      phone: phone,
      companyName: companyName,
    }

    setLoading(true)

    try {
      const res: any = await forgotUser(payload)
      setIsSuccess(true)
      if (res.success) {
        setIsSuccess(true)
        setFullName('')
        setEmail('')
        setCompanyName('')
        setPhone('1')
      } else if (res.error) {
        showApiResponseError(res.error, `Invalid Email, User doesn't Exists.`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showApiResponseError(error.message, `Couldn't get, User doesn't Exists.`)
    }
    setLoading(false)
  }

  return (
    // <div className={classes.flex}>
    <div className={classes.container}>
      {isSuccess ? (
        <Alert severity="success" style={{ marginTop: '1rem' }}>
          We have received your request, please allow 24-72 Hours for us to retrieve this information.
          <br />
          If we are unable to locate your account, a representative may call you on the provided telephone number
        </Alert>
      ) : (
        ''
      )}

      <Logo />

      <Card className={classes.root}>
        <CardHeader title=" Forgot Username" />
        <CardContent>
          <BRTextField
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            //showCopyButton={!!editedClient}
            required
            data-testid="client-password"
            // error={clientEditErrors.password}
            label="Name"
            //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
            //className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="text"
            name="fullName"
            margin="normal"
            variant="outlined"
            value={fullName}
            onChange={event => setFullName(event.target.value)}
          />

          <BRTextField
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            //showCopyButton={!!editedClient}
            required
            data-testid="client-password"
            // error={clientEditErrors.password}
            // helperText={passlengthError && 'Password cannot be less than 8 characters!'}
            label="Company Name"
            //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
            //className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="text"
            name="email"
            margin="normal"
            variant="outlined"
            value={companyName}
            onChange={event => setCompanyName(event.target.value)}
          />

          <FormControl className={classes.fullWidth}>
            <PhoneInput
              value={phone}
              onChange={value => setPhone(value)}

              // error={clientEditErrors['phone_number']}
            />
            {/* {clientEditErrors['phone_number'] && (
                <FormHelperText className={classes.errorHelperText}>Please enter phone number</FormHelperText>
              )} */}
          </FormControl>

          <BRTextField
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            //showCopyButton={!!editedClient}
            required
            data-testid="client-password"
            // error={clientEditErrors.password}
            // helperText={passlengthError && 'Password cannot be less than 8 characters!'}
            label="Email"
            //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
            //className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="text"
            name="email"
            margin="normal"
            variant="outlined"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </CardContent>
        <CardActions>
          {/* <ButtonWithLoading isLoading={loading} style={{ width: '100%' }} onClick={submitPass} disabled={isDisabled}>
            Submit
          </ButtonWithLoading> */}
          <Button
            size="large"
            fullWidth
            color="primary"
            disabled={isDisabled}
            className={classes.submitButton}
            onClick={onSubmitClick}
          >
            Submit
          </Button>
        </CardActions>
        {loading && (
          <div className={classes.rootProgress}>
            <LinearProgress />
          </div>
        )}
      </Card>

      <div style={{ margin: '1rem 0' }}>
        Back to the
        <Link style={{ margin: '0.5rem' }} to="/">
          Login
        </Link>
        page.
      </div>
    </div>
  )
}

export default ForgotUsername
