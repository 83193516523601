import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (client: IStates): Promise<ICreatedState> => {
  delete client.id // delete the extid, the backend should take care of it
  delete client.country_name

  // //   client.user_type = '2'
  //   client.email = client.email ? client.email : ' '
  return (await apiClient.post('states', snakeCaseKeys(client))).data
}
//testing
export const apiTest = async (): Promise<any> => {
  const data = {
    id: 1,
    company_type: 0,
  }
  // //   client.user_type = '2'
  //   client.email = client.email ? client.email : ' '
  return (await apiClient.post('/web/get-processingTimeAndFee', snakeCaseKeys(data))).data
}

//

export const updateClient = async (client: IStates): Promise<ICreatedState> => {
  //   const obj: any = client
  //   obj.email_1 = client.email
  //   obj.sales_representative_id = client.sales_rep_id
  //   obj.affiliate_id = client.affiliate_id
  //   obj.date_of_birth = client.dob
  delete client.country_name

  return (await apiClient.post(`states/${client.id}`, snakeCaseKeys(client))).data
}
export const fetchClient = async (id: number): Promise<ICreatedState> => {
  return (await apiClient.get(`states/${id}`)).data
}
export const fetchClients = async (query = ''): Promise<ICreatedClient[]> => {
  return (await apiClient.get(`user/client`, { params: { user_type: 'client', query, sort_order: 'desc' } })).data
}
export const paginateStates = () => {
  return requestPaginated<ICreatedState[]>({ url: '/statelist' })
}

// export const searchClients = async (query = ''): Promise<IClient[]> =>
//   (apiClient.defaults.baseURL =
//     process.env.REACT_APP_BASE_URL ||
//     (
//       await apiClient.get(
//         //'http://localhost:3000/'
//         `search/client`,
//         { params: { query } }
//       )
//     ).data)

export const searchClients = async (query = ''): Promise<IClient[]> => {
  // apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
  // return (await apiClient.get(`search/client`, { params: { query, sort_order: 'desc' } })).data
  return []
}
export const getClientAddresses = async (id: number): Promise<IAddress[]> =>
  (await apiClient.get(`user/${id}/address`)).data

interface IClientAssetParams {
  clientId: number
  documentId: number
}

export const uploadClientAsset = async (clientId: number, file: File): Promise<UploadRequest> =>
  (await apiClient.post(`user/${clientId}/upload-asset`, { mime_type: file.type, file_name: file.name })).data

export const getClientDocuments = (clientId: number) => requestPaginated<IAsset[]>({ url: `user/${clientId}/asset` })

export const deleteClientDocument = async ({ clientId, documentId }: IClientAssetParams): Promise<void> => {
  await apiClient.delete(`user/${clientId}/asset/${documentId}`)
}

export const downloadClientDocumentURL = ({ clientId, documentId }: IClientAssetParams): string => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  return `${BASE_URL}/user/${clientId}/asset/${documentId}/download?jwt=${token}`
}

export const fetchStates = async (): Promise<any> => {
  return (await apiClient.get(`/states`)).data
}
