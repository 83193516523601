import { CircularProgress, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import { StyledTable, StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import OwnerRow from 'components/companies/companyOwners/OwnerRow'
import { ICreatedCompany, IDetailedCompanyResponse } from 'models/company'
import { ICompanyOwner } from 'models/user'
import * as React from 'react'
import { useLocation } from 'react-router'

interface ICompanyOwnersTable {
  companyOwners: ICompanyOwner[]
  company?: ICreatedCompany | IDetailedCompanyResponse

  /**
   * handle edit owner button click on an owner row
   */
  handleEditButtonClick(userId: number, userName: string): void

  onOwnerDelete?: (userId: number) => void
  classes?: Record<string, string>
  onAddOwnerClick?: () => void
  hideEmptyOwnersTable?: boolean
  isCompanyCreation?: boolean
  isEditable?: boolean
}

const CompanyOwnersTable: React.FC<ICompanyOwnersTable> = ({ isCompanyCreation = false, ...props }) => {
  const classes = useStyles(props)
  const { companyOwners, company, handleEditButtonClick, onOwnerDelete, onAddOwnerClick, isEditable = true } = props
  const [loading, setloading] = React.useState(true)

  const { pathname } = useLocation()

  return (
    <>
      <div className={classes.headerContainer}>
        <p className={classes.tableTitle}>Owners</p>
      </div>
      <StyledTable className={classes.ownersTable}>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell style={{ width: '25%' }}>Name</StyledTableCell>
            <StyledTableCell style={{ width: '15%' }}>Share</StyledTableCell>
            <StyledTableCell style={{ width: '25%' }}>Roles</StyledTableCell>
            {!pathname.includes('tax-orders') ? (
              onAddOwnerClick ? (
                isEditable && (
                  <StyledTableCell className={classes.addOwnerHeaderCell}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={e => {
                        e.preventDefault()
                        onAddOwnerClick()
                      }}
                    >
                      + Add New Owner
                    </Link>
                  </StyledTableCell>
                )
              ) : (
                <StyledTableCell className={classes.addOwnerEmptyHeaderCell} />
              )
            ) : (
              <StyledTableCell className={classes.addOwnerHeaderCell} />
            )}
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {companyOwners &&
            companyOwners.length > 0 &&
            companyOwners.map(companyOwner => (
              <OwnerRow
                key={companyOwner.user.id}
                companyOwner={companyOwner}
                handleEditButtonClick={handleEditButtonClick}
                company={company}
                onOwnerDelete={onOwnerDelete}
                isCompanyCreation={isCompanyCreation}
                isEditable={isEditable}
              />
            ))}
        </TableBody>
        {/* {loading && <CircularProgress size={30}// className={classes.buttonProgress}
       />} */}
      </StyledTable>
    </>
  )
}

export default CompanyOwnersTable
export const useStyles = makeStyles({
  ownersTable: {
    marginBottom: 8,
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  addOwnerHeaderCell: {
    width: '20%',
    textAlign: 'right',
    paddingRight: 27,
  },
  addOwnerEmptyHeaderCell: {
    width: '20%',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem',
  },
  tableTitle: {
    color: '#020202',
    fontSize: '2rem',
    fontFamily: 'Lato',
    fontWeight: 600,
    margin: 0,
  },
})
