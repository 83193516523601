import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerCardContainer: {
      borderRadius: '4px',
      border: '2px solid',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1.25rem 0.8rem',
      minHeight: '100px',
      justifyContent: 'center',
      color: '#363636',
      background: theme.customPalette.paleGreyColor,
      cursor: 'pointer',

      ' & .product-img': {
        width: '100%',
        objectFit: 'contain',
        height: '2rem',
      },

      '& .inline-text': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: '0.5rem',
        // flexWrap: 'wrap',

        '& .extra-text': {
          margin: '0',
          fontSize: '0.9rem',
          fontWeight: 700,
          padding: '0.25rem 0',
          textAlign: 'center',
        },
        '& .title-text': {
          margin: '0',
          fontSize: '0.9rem',
          fontWeight: 600,
          textAlign: 'center',
          textTransform: 'uppercase',
        },
      },
    },
    offerModalContainer: {
      '& .offer-modal-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        //   borderBottom: '1px solid #e0e0e0',
        padding: '1rem',
        paddingBottom: '0',

        '& .modal-heading-sec': {
          display: 'flex',
          alignItems: 'center',

          '& .modal-heading-icon': {
            fontSize: '1.5rem',
            marginRight: '1rem',
          },

          '& .modal-heading': {
            color: '#202124',
            fontSize: '18px',
            fontFamily: 'Lato',
          },
        },

        '& .close': {
          color: '#707070',
          cursor: 'pointer',
        },
      },
      '& .modal-content': {
        padding: '0 1.25rem',

        '& .MuiDivider-root': {
          background: '#e0e0e0',
          marginBottom: '14px',
        },
        '& .bottom-space': {
          marginBottom: '1rem',
        },
      },

      '& .modal-footer': {
        display: 'flex',
        alignItems: 'center',
        padding: '1.25rem',
        paddingTop: '1rem',
        width: 'fit-content',
        alignSelf: 'flex-end',

        '& .modal-btn': {
          color: '#fff',
          padding: '0.5rem 1rem',
          fontSize: '14px',
          background: '#307abc',
        },

        '& .schedule-call-modal-container': {
          color: '#fff',
          padding: '0.5rem 1rem',
          fontSize: '14px',
          background: '#307abc',
          borderRadius: '4px',
          fontWeight: 600,
          minHeight: '45px',
          border: 'none',
          cursor: 'pointer',
        },
      },
      '& .modal-light-text': {
        color: '#979797',
        fontSize: '12px',
        padding: '0',
        margin: '0',
      },
      '& .modal-dark-text': {
        color: '#202124',
        fontSize: '14px',
        padding: '0',
        margin: '0',
      },
    },
    selectFormControl: {
      margin: '0.5rem',
      //   minWidth: 120,
      width: '100%',
    },
  })
)
