import { apiClient, requestPaginated } from '@jetkit/react'

export interface IFailedPaymentReponse {
  company_name: string | undefined
  id?: number | undefined
  error_text?: string | undefined
  order_id?: number | undefined
  company_id?: number | undefined
  company_subscription_id?: number | undefined
  created_at?: string | undefined
  decline_code?: string | undefined
  decline_code_url?: string | undefined
  read?: boolean
  subscription_name?: string
  compliance_name?: string
  payment_type?: string
}

export const fetchFailedPayments = () => requestPaginated<IFailedPaymentReponse[]>({ url: `failed-payments` })

export const readFailedPayments = async (failedId: number, isRead: boolean): Promise<any> =>
  (await apiClient.post(`failedpayments/${failedId}`, { read: isRead })).data
