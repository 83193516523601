import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import classnames from 'classnames'
import CancelButton from 'components/common/buttons/cancelButton'
import PrimaryButton from 'components/common/buttons/primaryButton'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      marginRight: '1rem',
    },
  })
)

interface INoteEditButtonsProps {
  onSave: () => void
  onCancel: () => void
  className?: string
}
function NoteEditButtons(props: INoteEditButtonsProps) {
  const classes = useStyles()
  return (
    <div className={classnames(classes.container, props.className)}>
      <CancelButton onClick={props.onCancel} className={classes.cancelButton} style={{ width: 150, height: 40 }}>
        Cancel
      </CancelButton>
      <PrimaryButton onClick={props.onSave} style={{ width: 150, height: 40 }}>
        Save
      </PrimaryButton>
    </div>
  )
}

export default NoteEditButtons
