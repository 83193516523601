import Button, { ButtonProps } from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      border: 'none',
      minWidth: 0,
      padding: 0,
    },
    label: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.07px',
      fontSize: 14,
    },
  })
)

interface ITableActionButtonProps extends ButtonProps {}

function TableActionButton(props: ITableActionButtonProps) {
  const { children, onClick, style } = props
  const classes = useStyles()
  return (
    <Button
      {...props}
      classes={{ root: classes.root, label: classes.label }}
      style={style}
      variant="outlined"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
export default TableActionButton
