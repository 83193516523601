import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import React, { useMemo } from 'react'
import { useStyles } from './style'
import { skeletons } from 'components/common/table'
import LogsTableHead from './head'
import useSortTable from 'hooks/useSortTable'
import { usePagedTable, PagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import LogsTableRow from './row'
import { IClientLogs, getClientLogs } from 'api/clients'

interface LogsTableProps {
  clientId: number
}

const LogsTable = ({ clientId }: LogsTableProps) => {
  const classes = useStyles()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'logged_at' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(
    () => <LogsTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getClientLogs(clientId)({ sort_order: order, sort_by }), [clientId, order, sort_by])

  const pagedTable = usePagedTable<IClientLogs>({
    apiCall: memoApiCall,
  })

  return (
    <div className={classes.tableContainer}>
      <p className={classes.tableTitle}>Report</p>
      <br />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 2, sizes: [200, 200], numberOfRows: 4 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={2}
          emptyRowText="No Employee Report Found"
          {...pagedTable.renderProps}
          renderRow={(logsTable: IClientLogs) => <LogsTableRow tableReport={logsTable} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default LogsTable
