import { makeStyles } from '@material-ui/styles'
import { IDocumentUploadProps } from 'components/common/assets/documentUploads'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { UploadRequestClass, UploadFileToS3Args } from 'models/asset'
import UploadModal from './uploadModal'
import { getDocumentTypes } from 'api/documentTypes'
import { AttachmentOutlined } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import useRouter from 'use-react-router'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
  },
})

interface IAssetUploadProps extends IDocumentUploadProps {
  UploadRequest?: UploadRequestClass
  onBeforeUpload?: (file?: File) => Promise<undefined> // should return model to proceed or false to abort upload
  onUploadComplete?: (args: UploadFileToS3Args) => void
  onError?: (error: Error) => void
  disabled?: boolean
  setLoading?: any
}

/**
 * A component for handling file uploads of assets to S3.
 *
 * An API call must be made to retrieve a pre-signed S3 upload URL.
 * After getting the pre-signed URL, a PUT of the file must be made to S3 directly.
 * The API call is made right before uploading because the upload URL can expire.
 *
 * The endpoint URL should accept a POST and return an `UploadResponse`.
 */
function AssetUpload({
  UploadRequest,
  onBeforeUpload,
  onUploadComplete,
  onError,
  disabled,
  type,
  objectId,
  setLoading,
}: IAssetUploadProps) {
  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [docType, setdocType] = React.useState<any>(null)
  const [notes, setnotes] = React.useState<any>(null)
  const [docLabel, setDocLabel] = React.useState<string>('')

  const [docs, setdocs] = React.useState<any>([])
  const [visibleOnClient, setvisibleOnClient] = React.useState<any>(false)
  const [isRADoc, setIsRADoc] = React.useState<any>(false)
  const [isAddressDoc, setIsAddressDoc] = React.useState<any>(false)
  const { history } = useRouter()

  console.log('type', type)
  console.log('objectId', objectId)

  const handleUploadComplete = React.useCallback(
    (args: UploadFileToS3Args) => {
      debugger
      setUploadProgress(0)
      debugger
      if (onUploadComplete) onUploadComplete(args)
    },
    [onUploadComplete]
  )
  // const setDocParams=(doc:any, note:any)=>{
  //   setdocType
  // }
  const handleProgress = (evt: ProgressEvent) => {
    setUploadProgress(Math.ceil((evt.loaded / evt.total) * 100))

    console.log('evt', evt)
  }
  React.useEffect(() => {
    const getdocs = async () => {
      const data: any = await getDocumentTypes()
      setdocs(data)
    }
    getdocs()
  }, [])
  const onDrop = React.useCallback(
    async (files: File[]) => {
      console.log('files', files)
      console.log('UploadRequest', UploadRequest)

      debugger
      if (!files || !UploadRequest) return
      // const file = files[0]
      debugger

      if (!files.length) throw new Error(`No file provided`)
      debugger

      // do an API request to get our presigned upload url

      // const uploadReq = files.map(file => new UploadRequest(type, objectId, file, docType, notes))
      debugger
      let taxId = 0
      if (history.location.pathname.includes('tax-orders')) {
        taxId = Number(history.location.pathname?.split('/')[2])
      }
      files.map(async file => {
        debugger
        const uploadReq = new UploadRequest(
          type,
          objectId,
          file,
          docType,
          notes,
          visibleOnClient,
          docLabel,
          isRADoc
          // isAddressDoc
        )
        let id = 0
        docs.map((item: any) => {
          if (docType === item.typename) {
            id = item.id
          }
        })
        debugger
        uploadReq.docType = id
        uploadReq.notes = notes

        uploadReq.visibleOnClient = visibleOnClient
        uploadReq.docLabel = docLabel

        uploadReq.isRADoc = isRADoc
        uploadReq.isAddressDoc = isAddressDoc
        if (taxId) uploadReq.tax_order_id = taxId
        setIsUploading(true)
        setLoading && setLoading(true)

        // upload
        try {
          debugger
          await uploadReq.uploadFileToS3({
            type,
            objectId,
            file,
            docType,
            notes,
            visibleOnClient,
            docLabel,
            onProgress: handleProgress,
            isRADoc,
            tax_order_id: taxId,
            isAddressDoc,
          })
          debugger
          handleUploadComplete({ type, objectId, file, onProgress: handleProgress })
        } catch (ex) {
          console.error(ex)
          if (onError) onError(ex)
        } finally {
          setIsUploading(false)
          setLoading && setLoading(false)
        }
      })
    },
    [
      UploadRequest,
      type,
      objectId,
      docType,
      notes,
      docs,
      visibleOnClient,
      docLabel,
      isRADoc,
      setLoading,
      handleUploadComplete,
      onError,
      isAddressDoc,
    ]
  )

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true })
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {type === 'questions' || type === 'bo_answer' || type === 'bo_question' ? (
          <IconButton aria-label="upload" onClick={open}>
            <AttachmentOutlined />
          </IconButton>
        ) : (
          <div>
            <ButtonWithLoading
              linearProgressVariant="determinate"
              linearProgressValue={uploadProgress}
              disabled={disabled}
              isLoading={isUploading}
              classes={{ button: classes.button }}
              onClick={() => setOpenModal(true)}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </ButtonWithLoading>
          </div>
        )}
      </div>

      {type !== 'questions' && (
        <UploadModal
          open={openModal}
          setOpen={() => setOpenModal(false)}
          uploadHandler={open}
          docSetter={setdocType}
          setnote={setnotes}
          notess={notes}
          selectedType={docType}
          docs={docs}
          setvisibleonclient={setvisibleOnClient}
          visibleOnClient={visibleOnClient}
          setDocLabel={setDocLabel}
          setIsRADoc={setIsRADoc}
          isRADoc={isRADoc}
          setIsAddressDoc={setIsAddressDoc}
          isAddressDoc={isAddressDoc}
        />
      )}
    </div>
  )
}

export default AssetUpload
