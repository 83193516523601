import { Chip } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import moment from 'moment'
import * as React from 'react'
import { yearSubstring } from 'utils/formatDate'
import { IEditableNotificationDate } from '.'

const useStyles = makeStyles(() =>
  createStyles({
    placeholder: {
      margin: '1rem',
      textAlign: 'center',
      color: 'rgb(117, 117, 117)',
    },
    chip: {
      borderRadius: 4,
      fontSize: 16,
      margin: 5,
      flex: 1,
      maxWidth: 130,
    },
  })
)

interface INextYearsViewProps {
  nextYears?: IEditableNotificationDate[]
}

const chipContent = (date: IEditableNotificationDate, id: number): string => {
  const yearId = id + 1
  // if (date.file_date) return `${yearSubstring(date.schedule_date)}: File Date`
  if (date.file_date) return `${yearId}: File Date`

  // return `${yearSubstring(date.date)}: ${moment(date.date).format('MMM D')}`
  return `${yearId}: ${moment(date.schedule_date).format('MMM D')}`
}

const NextYearsView: React.FC<INextYearsViewProps> = ({ nextYears }) => {
  const classes = useStyles()
  if (!nextYears) {
    return (
      <div className={classes.placeholder}>
        Please schedule first notification to customize notifications for next 10 years
      </div>
    )
  }
  if (!nextYears.length) {
    return (
      <div className={classes.placeholder}>
        You have skipped notifications on all years that follow first notification year
      </div>
    )
  }
  return (
    <React.Fragment>
      {nextYears.map((date, index) => (
        // <Chip key={index} label={chipContent(date)} className={classes.chip} />
        <Chip key={index} label={chipContent(date, index)} className={classes.chip} />
      ))}
    </React.Fragment>
  )
}

export default NextYearsView
