import { SalectList } from 'api/taxWidgets'
import { ISuggestionAttribute } from 'components/interfaces'

export const handleChange = (
  selectArray: SalectList,
  key: string,
  selected: SalectList[],
  setSelected: React.Dispatch<React.SetStateAction<SalectList[]>>
) => {
  switch (key) {
    case 'CPA':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Year':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Label':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Stage':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Status':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Structure':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Accountant':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Associate':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Controller':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Type':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Order':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    case 'Service':
      if (!selected?.some(item => item?.value === selectArray?.value)) setSelected(prev => [...prev, selectArray])
      break
    default:
      break
  }
}
export const onDeleteClick = (
  val: number | string,
  key: string,
  selected: SalectList[],
  setSelected: React.Dispatch<React.SetStateAction<SalectList[]>>
) => {
  switch (key) {
    case 'CPA':
      const filteredDataCPA = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataCPA ? filteredDataCPA : [])
      break
    case 'Year':
      const filteredDataYear = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataYear ? filteredDataYear : [])
      break
    case 'Label':
      const filteredDataLabel = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataLabel ? filteredDataLabel : [])
      break
    case 'Stage':
      const filteredDataStage = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataStage ? filteredDataStage : [])
      break
    case 'Status':
      const filteredDataStatus = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataStatus ? filteredDataStatus : [])
      break
    case 'Structure':
      const filteredDataStructure = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataStructure ? filteredDataStructure : [])
      break
    case 'Accountant':
      const filteredDataAccountant = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataAccountant ? filteredDataAccountant : [])
      break
    case 'Associate':
      const filteredDataAssociate = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataAssociate ? filteredDataAssociate : [])
      break
    case 'Controller':
      const filteredDataController = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataController ? filteredDataController : [])
      break
    case 'Type':
      const filteredDataType = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataType ? filteredDataType : [])
      break
    case 'Order':
      const filteredDataOrder = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataOrder ? filteredDataOrder : [])
      break
    case 'Service':
      const filteredDataService = selected.filter(object => {
        return object.value !== val
      })
      setSelected(filteredDataService ? filteredDataService : [])
      break
    default:
      break
  }
}

export const handleSeprateKeyValue = (array: SalectList[]) =>
  array?.map((item: SalectList) => {
    return item?.value
  })
