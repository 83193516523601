import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'
import { AnyObjectSchema } from 'yup'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (client: any): Promise<ICreatedState> => {
  delete client.id // delete the extid, the backend should take care of it
  delete client.state_display_name
  delete client.state_name
  return (await apiClient.post('cities', snakeCaseKeys(client))).data
}

export const updateClient = async (client: any): Promise<ICreatedState> => {
  //   const obj: any = client
  //   obj.email_1 = client.email
  //   obj.sales_representative_id = client.sales_rep_id
  //   obj.affiliate_id = client.affiliate_id
  //   obj.date_of_birth = client.dob
  //delete client.country_name
  delete client.state_display_name
  //delete client.state_name

  return (await apiClient.post(`cities/${client.id}`, snakeCaseKeys(client))).data
}
export const fetchClient = async (id: number): Promise<ICreatedState> => {
  return (await apiClient.get(`cities/${id}`)).data
}
export const fetchClients = async (query = ''): Promise<ICreatedClient[]> => {
  return (await apiClient.get(`user/client`, { params: { user_type: 'client', query, sort_order: 'desc' } })).data
}
export const paginateStates = () => {
  return requestPaginated<ICreatedState[]>({ url: '/cities' })
}

export const deleteCity = async (id: number): Promise<any> => {
  return apiClient.delete(`cities/${id}`)
}
