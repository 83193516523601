import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      margin: '20px',
      borderRadius: '6px',
      border: '2px solid #00000012',
    },
    header: {
      borderBottom: '2px solid #00000012',
    },
    content: {
      padding: '1rem',
    },
  })
)
const OrderCard = props => {
  const classes = useStyles()

  return (
    <div>
      <Card
        className={classes.mainContainer}
        variant="outlined"
        style={{
          background: props.isComplete
            ? '#00fb9b17'
            : props.isPending
            ? '#ff000038'
            : props.isSalesRep
            ? '#9185e133'
            : '',
        }}
      >
        <CardHeader className={classes.header} title={props.title} />
        <CardContent className={classes.content}>{props.children}</CardContent>
      </Card>
    </div>
  )
}

export default OrderCard
