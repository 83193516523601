import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 20,
    backgroundColor: '#e4e4e8',
    '& > div': {
      height: '100%',
    },
  },
  tabWrapper: {
    textTransform: 'uppercase',
    fontSize: 16,
    letterSpacing: 0.08,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  tabsContainer: {
    backgroundColor: '#e4e4e8',
  },
  appBarContainer: {
    boxShadow: 'none',
    background: 'transparent',
  },
  currentStepIndicator: {
    backgroundColor: '#f47950',
    height: 4,
  },
  indicator: {
    width: '100%',
    bottom: 0,
    height: 1,
    position: 'absolute',
    backgroundColor: '#979797',
  },
  tabRoot: {
    '&.Mui-selected': {
      '& > span': {
        color: '#213265',
      },
    },
  },
  companyNameFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
    maxHeight: 40,

    '& .inline-field': {
      display: 'flex',
    },

    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  companyNameLabel: {
    fontSize: 28,
    letterSpacing: 0.14,
  },
  editButton: {
    color: '#3c9fdf',
    '&:hover': {
      backgroundColor: '#e4e4e8',
    },
  },
  documentsContainer: {
    width: '100%',
    padding: '0 16px',
  },
  loadingWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logsBtn: {
    // position: 'absolute',
    // right: 0,

    '& button': {
      height: '40px',
    },
  },
  //
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    margin: '0.5rem 0',
  },
})
