import React, { useState, useEffect } from 'react'
import { createStyles, Grid, makeStyles, TextareaAutosize } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import showErrorNotification from 'utils/showErrorNotification'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { Theme } from 'tinymce'
import { addCallLogsNote, getCallLogsNoteById } from 'api/callLogs'
import { reversedArray } from 'utils/functions'
import { createTaxWigetNote, getTaxWidgetNotes } from 'api/taxWidgets'

interface ICallLogsRowProps {
  companyId?: number
  customerWidget?: number
}
export interface NotesData {
  created_by?: string
  id?: number
  created_at?: string
  company_id?: number
  custom_widget?: number
  note_text?: string
}
const CompanyInnerTable: React.FC<ICallLogsRowProps> = ({ customerWidget, companyId }) => {
  const classes = useStyles()
  const [noteText, setNoteText] = useState<string>('')
  const [notes, setNotes] = useState<NotesData[]>([])
  const [getNoteLoading, setGetNoteLoading] = useState<boolean>(false)
  const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false)

  const getNotes = async (customerWidget: number, companyId: number) => {
    setGetNoteLoading(true)
    try {
      const notes = await getTaxWidgetNotes(customerWidget, companyId)
      const notesReversed = reversedArray(notes)
      setNotes(notesReversed)
      setGetNoteLoading(false)
    } catch (error) {
      showErrorNotification(error)
    }
    setGetNoteLoading(false)
  }

  const handleAddNote = async () => {
    setAddNoteLoading(true)
    const data = {
      custom_widget: customerWidget,
      company_id: companyId,
      note_text: noteText,
    }
    if (data) {
      try {
        await createTaxWigetNote(data)
        setNoteText('')
        if (customerWidget && companyId) {
          getNotes(customerWidget, companyId)
        }
        setAddNoteLoading(false)
      } catch (error) {
        showErrorNotification(error)
      }
    }
  }

  console.log('customerWidget, companyIdcustomerWidget, companyId', customerWidget, companyId)

  useEffect(() => {
    if (customerWidget && companyId) {
      getNotes(customerWidget, companyId)
    }
  }, [companyId, customerWidget])
  return (
    <div className={classes.innerItems}>
      <Grid container spacing={3} className="text-box-grid">
        <Grid item xs={6}>
          <div className="text-box">
            <TextareaAutosize
              className="text-area"
              placeholder="Notes/ Descriptions"
              rowsMax="10"
              rowsMin="10"
              value={noteText}
              name="noteText"
              onChange={e => setNoteText(e.target.value)}
            />
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="save-btn"
              onClick={handleAddNote}
              isLoading={addNoteLoading}
              disabled={!noteText.length}
            >
              Save
            </ButtonWithLoading>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-box-notes">
            {getNoteLoading
              ? [1, 2, 3, 4, 5, 6].map(key => <Skeleton animation="wave" variant="text" key={key} />)
              : notes?.length
              ? notes?.map(note => (
                  <div className="bottom-space" key={note?.id}>
                    <p className="dark-text">{note?.note_text}</p>
                    <p className="dark-text">
                      {note?.created_by} {formatDateTimeToHumanFriendly(note?.created_at || '')}
                    </p>
                  </div>
                ))
              : 'No notes found'}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CompanyInnerTable

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerItems: {
      minHeight: '50px',
      width: '100%',
      marginTop: '1.25rem',

      '& .dark-text': {
        color: '#020202',
        paddingBottom: '0.5rem',
        margin: '0',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        display: 'flex',
        // flexWrap: 'wrap',
      },

      '& .text-box-grid': {
        margin: '2rem 0',
        marginTop: '1rem',
        width: '100%',

        '& .text-box': {
          background: 'white',
          minHeight: '200px',
          padding: '1rem',
          fontWeight: '500',
        },
        '& .text-box-notes': {
          background: 'white',
          height: '200px',
          overflowY: 'auto',
          padding: '1rem',
          fontWeight: '500',
        },

        '& .text-area': {
          resize: 'none',
          background: 'inherit',
          border: 'none',
          outline: 'none',
          width: '100%',

          '&::placeholder': {
            color: '#979797',
            fontSize: '2rem,',
          },
        },
        '& .save-btn': {
          '& .MuiButtonBase-root': {
            background: '#788190',
            color: '#fff',
            minWidth: '100px',
            height: 'auto',
          },
          background: '#788190',
          color: '#fff',
          width: '100px',
          height: 'auto',
        },
      },
    },
  })
)
