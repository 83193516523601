import { logoutUser, updateUserLog } from 'api/users'
import { clearAuthTokens } from 'axios-jwt'
// import showApiResponseError from './showApiResponseError'
import showErrorNotification from './showErrorNotification'

export const logout = async () => {
  const pageVisits = localStorage.getItem('page_visits')
  const userClicks = localStorage.getItem('user_clicks')
  await logoutUser(parseInt(pageVisits || ''), parseInt(userClicks || ''))
  localStorage.setItem('page_visits', '0')
  localStorage.setItem('user_clicks', '0')
  localStorage.setItem('parent_user', '{}')
  clearAuthTokens()
}

export const updateUserlog = async () => {
  const pageVisits = localStorage.getItem('page_visits')
  const userClicks = localStorage.getItem('user_clicks')
  try {
    await updateUserLog(parseInt(pageVisits || ''), parseInt(userClicks || ''))
    localStorage.setItem('page_visits', '0')
    localStorage.setItem('user_clicks', '0')
  } catch (error) {
    showErrorNotification('Error updating logs')
  }
}

export const updatePageVisitsCount = () => {
  let pageVisits: string | number | null = localStorage.getItem('page_visits')
  let userClicks: string | number | null = localStorage.getItem('user_clicks')
  // debugger
  if (pageVisits) {
    pageVisits = parseInt(pageVisits) + 1
    userClicks = Number(userClicks) + 1
    localStorage.setItem('page_visits', pageVisits.toString())
    localStorage.setItem('user_clicks', userClicks.toString())
  }
}
