import { CircularProgressProps, Box, CircularProgress, Button } from '@material-ui/core'
import { getProfileScoreById } from 'api/clientDashboard'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ProfileModal from '../profileModal'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Error } from '@material-ui/icons'

interface CompanyInformationProps {
  companyName: string
  profileScore: number
}

const CompanyProfile = ({ profileScore, companyName }: CompanyInformationProps) => {
  const { id } = useParams<{ id: string }>()
  const [isProfile, setIsProfile] = useState(false)
  const [profScore, setProfScore] = useState<number>()

  const getProfileScore = useCallback(async () => {
    try {
      const score = await getProfileScoreById(id)
      setProfScore(score?.profile_score)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    if (!isProfile) {
      getProfileScore()
    }
    getProfileScore()
  }, [getProfileScore, isProfile])

  const checkProfileScore = (score: number) => {
    if (score >= 0 && score <= 30) {
      return 'red'
    } else if (score > 30 && score <= 70) {
      return 'orange'
    } else if (score > 70 && score <= 100) {
      return 'green'
    } else {
      return 'red'
    }
  }

  return (
    <div className="company-profile-container">
      <h1 className="profile-heading">BUSINESS CREDIT BUILDER</h1>
      <p className="complete-profile-text">Company Profile</p>
      <div className="complete-profile-section" style={{ height: 1 }}>
        {/* <CircularProgressWithLabel
          value={profScore || profileScore}
          size={200}
          style={{ color: checkProfileScore(profScore || profileScore) }}
          // className="circular-color"
        /> */}
        <CircularProgressbar
          backgroundPadding={0}
          value={profScore || profileScore}
          text={`${profScore || profileScore}%`}
          circleRatio={0.5}
          strokeWidth={12}
          styles={buildStyles({
            rotation: 1 / 4 + 1 / 2,
            strokeLinecap: 'butt',
            trailColor: '#eee',
            pathColor: checkProfileScore(profScore || profileScore),
            textSize: '1.5rem',
            textColor: '#202124',
          })}
        />

        <div className="error-icon">
          {(profScore || profileScore) !== 100 ? (
            <>
              <Error fontSize="large" htmlColor="#ffc400" /> <p>Attention Required</p>
            </>
          ) : (
            ''
          )}
        </div>

        <div className="complete-profile-sec">
          <Button variant="contained" className="complete-btn" onClick={() => setIsProfile(true)}>
            Complete
          </Button>
        </div>
      </div>

      <ProfileModal open={isProfile} setOpen={setIsProfile} id={id} companyName={companyName} />
    </div>
  )
}

export default CompanyProfile

// function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
//   return (
//     <Box position="relative" display="inline-flex">
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         top={0}
//         left={0}
//         bottom={0}
//         right={0}
//         position="absolute"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//       >
//         <p className="inner-text">{`${Math.round(props.value)}%`}</p>
//       </Box>
//     </Box>
//   )
// }
