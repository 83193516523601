import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  profileSettingModal: {
    '& .view-modal-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      //   borderBottom: '1px solid #e0e0e0',
      padding: '1rem',
      paddingBottom: '0',

      '& .modal-heading-sec': {
        display: 'flex',
        alignItems: 'center',

        '& .modal-heading-icon': {
          fontSize: '1.5rem',
          marginRight: '1rem',
        },

        '& .modal-heading': {
          color: '#202124',
          fontSize: '18px',
          fontFamily: 'Lato',
        },
      },

      '& .close': {
        color: '#707070',
        cursor: 'pointer',
      },
    },
    '& .modal-content': {
      padding: '0 1.25rem',

      '& .MuiDivider-root': {
        background: '#e0e0e0',
        marginBottom: '14px',
      },
      '& .bottom-space': {
        marginBottom: '1rem',
      },
    },

    '& .modal-footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '1.25rem',
      paddingTop: '1rem',

      '& .update-btn': {
        backgroundColor: '#307abc',
        padding: '6px 24px',
        color: '#fff',
      },
    },
  },

  mainRoot: {
    // width: '396px',
    // height: '378px',
    // borderRadius: '4px',
    // padding: '20px',
    // boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  profileImage: {
    backgroundColor: '#f4f5f7',
    padding: 0,
    margin: '40px auto 40px',
  },
  profileIcon: {
    color: '#fff',
    fontSize: '5rem',
    width: '5rem',
    height: '5rem',
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    display: 'flex',
  },
  tabs: {
    '& .MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        '& .Mui-selected': {
          borderBottom: '1px solid #307abc ',
        },
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  },
  tabRoot: {
    display: 'flex',
    flexDirection: 'column',

    '& .img-section': {
      textAlign: 'center',
    },
  },
  tabsLabel: {
    fontSize: '12px',
    color: ' #202124',
    textAlign: 'left',
    padding: 0,
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  tabPanel: {
    width: '100%',
    '& .MuiBox-root': {
      paddingBottom: '0px',
    },
  },
  inputLabel: {
    fontSize: '12px',
    color: '#979797',
  },
  toggleInput: {
    fontSize: '12px',
    color: '#979797',
    display: 'flex',
    justifyContent: 'space-between',
    width: '109%',
  },
  innerInput: {
    fontSize: '14px',
    color: '#202124',
    backgroundColor: '#f7f8f9',
    border: 0,
    outline: 'none',
    padding: '10px 10px',
    borderRadius: '4px',
    width: '100%',
    margin: '0 0 15px',
    boxSizing: 'inherit',
    // border:"none !important",

    '&::before': {
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },

    '& .MuiInput-input': {
      padding: '0',
    },

    '& .open': {
      background: 'transparent !important',
    },

    '& .form-control': {
      width: 'inherit',
      border: 'none',
      background: 'transparent',
      paddingLeft: '32px',
      height: '22px',
    },
    '& .flag-dropdown': {
      border: 'none',
      background: 'transparent',

      '& .country-list-dropdown': {
        width: '199px',
        left: '-8px',
        backgroundColor: '#f7f8f9',
        marginTop: '0',
      },

      '& .selected-flag': {
        // width: '28px',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  errorText: {
    marginTop: '-12px',
    color: 'red',
    fontSize: '11px',
  },

  input: {
    display: 'none',
  },
})
