import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inlineTitleButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    title: {
      width: '100%',
      fontFamily: theme.typography.h1.fontFamily,
      fontWeight: 600,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: 4,
    },
  })
)

interface IModalTitleProps {
  toggleModal?: (value: boolean) => void // in case if you use toggle function (pass true / false to control the modal)
  onClose?: () => void // in case if you use simple closing function
  title?: string
  customTitleComponent?: React.ReactNode
  classes?: Record<string, string>
}

const ModalTitle: React.FC<IModalTitleProps> = props => {
  const { toggleModal, onClose, title, customTitleComponent } = props
  const classes = useStyles(props)

  return (
    <div className={classes.inlineTitleButtonContainer}>
      {customTitleComponent || (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}
      <IconButton
        onClick={() => (toggleModal ? toggleModal(false) : onClose ? onClose() : undefined)}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </div>
  )
}

export default ModalTitle
