import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import useStyles from 'components/common/styles/createDialogStyles'
import * as React from 'react'
import { ICompanyRole, ICreatedCompany, IDetailedCompanyResponse, legalTypes } from 'models/company'
import { getOwnershipTaken } from 'api/companies'
import { LinearProgress } from '@material-ui/core'
import { LEGALTYPES } from 'utils/constant'

interface Props {
  isDisabled: boolean

  sharesError: boolean
  setSharesError: (error: boolean) => void

  /**
   * how many shares the current owner is taking
   */
  ownerSharesCount: number

  /**
   * shares left to be distributed for a company
   */
  companySharesLeftCount: number
  company?: any //ICreatedCompany | IDetailedCompanyResponse
  comp_id?: any
  handleSharesChange: (numberOfSharesToTake: number) => void
}

export const SharesField = ({
  isDisabled,
  sharesError,
  setSharesError,
  ownerSharesCount,
  companySharesLeftCount,
  handleSharesChange,
  company,
  comp_id,
}: Props) => {
  const classes = useStyles()

  const [remainingShares, setRemainingShares] = React.useState<number>(companySharesLeftCount)
  const [remainingSharesText, setRemainingSharesText] = React.useState<string>('')
  const [availabeOwnershipPer, setavailabeOwnershipPer] = React.useState<number>(100)
  const [remainingTextper, setRemainingperText] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    const sharesText =
      remainingShares < 0
        ? 'You have exceeded the number of available shares'
        : `The number of the company shares cannot exceed ${remainingShares} available for this owner`
    setRemainingSharesText(sharesText)
    const perText =
      availabeOwnershipPer < 0
        ? 'You have exceeded the number of available percentage'
        : `The company percentage cannot exceed ${availabeOwnershipPer} available for this owner`
    setRemainingperText(perText)
  }, [remainingShares, availabeOwnershipPer])
  const validateTakenSharesCount = React.useCallback(
    (numberOfSharesToTake: number) => {
      if (numberOfSharesToTake > remainingShares) {
        setSharesError(true)
      } else {
        setSharesError(false)
      }
    },
    [remainingShares, setSharesError]
  )
  const validateTakenPerCount = React.useCallback(
    (numberOfSharesToTake: number) => {
      if (numberOfSharesToTake > availabeOwnershipPer) {
        setSharesError(true)
      } else {
        setSharesError(false)
      }
    },
    [availabeOwnershipPer, setSharesError]
  )
  React.useEffect(() => {
    const func = async () => {
      const type = company?.company_type
      if (company?.legal_type === 'LLC' || type === 1) {
        setLoading(true)
        const sum = await getOwnershipTaken(company?.id)
        setLoading(false)
        const totalavailper = 100 - sum
        setavailabeOwnershipPer(totalavailper + ownerSharesCount)
      } else {
        setLoading(true)
        const sum = await getOwnershipTaken(company?.id)
        setLoading(false)
        const totalavail = parseInt(company?.number_of_shares || '0') - sum
        setRemainingShares(totalavail + ownerSharesCount)
      }
    }
    func()
  }, [])
  const handleChangePercentage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = parseFloat(parseFloat(event.target.value).toFixed(2))
      // console.log('hehe', event.target.value);
      // if (isNaN(value)) {
      //   // don't allow non-digits
      //   value = 0;
      // }

      // if (value > 100) {
      //   // don't allow non-digits

      //   return
      // }

      const numberOfSharesToTake = value

      const remaining = availabeOwnershipPer - numberOfSharesToTake
      //setavailabeOwnershipPer(remaining)
      validateTakenPerCount(numberOfSharesToTake)

      handleSharesChange(numberOfSharesToTake)
    },
    [handleSharesChange, availabeOwnershipPer, validateTakenPerCount]
  )
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = parseInt(event.target.value)
      if (isNaN(value)) {
        // don't allow non-digits
        return
      }

      const numberOfSharesToTake = value

      const remaining = companySharesLeftCount - numberOfSharesToTake
      // setRemainingShares(remaining)
      validateTakenSharesCount(numberOfSharesToTake)

      handleSharesChange(numberOfSharesToTake)
    },
    [handleSharesChange, companySharesLeftCount, validateTakenSharesCount]
  )
  const setSharesField = () => {
    if (company?.legal_type === 'LLC' || company?.company_type === 1) {
      return (
        <TextField
          onFocus={event => event.target.select()}
          disabled={isDisabled}
          className={classes.fullWidthContainer}
          label="OwnerShip Percentage"
          data-testid="shares"
          type="number"
          name="Shares"
          margin="normal"
          variant="outlined"
          value={ownerSharesCount}
          error={sharesError}
          onChange={handleChangePercentage}
          //onBlur={handleChangePercentage}
          helperText={remainingTextper}
        />
      )
    } else if (company.company_type !== LEGALTYPES.non_profit) {
      return (
        <TextField
          onFocus={event => event.target.select()}
          disabled={isDisabled}
          className={classes.fullWidthContainer}
          label="Number of Shares"
          data-testid="shares"
          type="text"
          name="Shares"
          margin="normal"
          variant="outlined"
          value={ownerSharesCount}
          error={sharesError}
          onChange={handleChange}
          helperText={remainingSharesText}
        />
      )
    }
  }
  return (
    <div className={classes.ownerModalSharesContainer} style={{ marginBottom: '1rem' }}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={isDisabled ? 'Please select a role that can hold shares' : ''}
      >
        <>
          <span className={classes.fullWidth}>
            {/* {company?.legal_type === 'LLC' || company?.company_type === 1 ? (
              <TextField
                onFocus={event => event.target.select()}
                disabled={isDisabled}
                className={classes.fullWidthContainer}
                label="OwnerShip Percentage"
                data-testid="shares"
                type="number"
                name="Shares"
                margin="normal"
                variant="outlined"
                value={ownerSharesCount}
                error={sharesError}
                onChange={handleChangePercentage}
                //onBlur={handleChangePercentage}
                helperText={remainingTextper}
              />
            ) : (
              <TextField
                onFocus={event => event.target.select()}
                disabled={isDisabled}
                className={classes.fullWidthContainer}
                label="Number of Shares"
                data-testid="shares"
                type="text"
                name="Shares"
                margin="normal"
                variant="outlined"
                value={ownerSharesCount}
                error={sharesError}
                onChange={handleChange}
                helperText={remainingSharesText}
              />
            )} */}
            {setSharesField()}
            {loading && <LinearProgress />}
          </span>
        </>
      </Tooltip>
    </div>
  )
}

// TODO: #338 write tests for this component
