import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import * as React from 'react'

interface IMenuOption {
  name: string
  onClick: () => void
}

interface ISimpleMenuProps {
  refElement?: React.RefObject<HTMLElement>
  isOpen: boolean
  handleClose: () => void
  options: Array<IMenuOption>
}

const SimpleMenu: React.FC<ISimpleMenuProps> = (props: ISimpleMenuProps) => {
  const { refElement, isOpen, handleClose, options } = props

  return (
    <Menu anchorEl={refElement?.current} keepMounted open={isOpen} onClose={handleClose}>
      {options.map(({ name, onClick }, index) => {
        return (
          <MenuItem key={index} onClick={onClick}>
            {name}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default SimpleMenu
