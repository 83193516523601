import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
    },
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,
    },
    titleAndProgressbarWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: '1rem',
    },
    progressWrapper: {
      display: 'flex',
    },

    tableContainer: {
      borderRadius: 4,
      overflow: 'hidden',
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    dashboardSection: {
      marginBottom: '2rem',

      '& .left-grid': {
        flexGrow: 0,
        // maxWidth: '20%',
        // flexBasis: '20%',
        margin: '0 0 0 10px',
      },
      '& .right-grid': {
        flexGrow: 0,
        maxWidth: '40%',
        flexBasis: '40%',
      },
      '& .card-divider': {
        border: '0.2px solid #0000001f',
        padding: 0,
        margin: '1rem 0',
      },
    },
    dashboardTitle: {
      paddingBottom: '1rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .table-heading': {
        fontSize: '1.5rem',
        color: theme.customPalette.primaryFontColor,
        paddingLeft: '0.5rem',
      },
    },
    progressBar: {
      marginRight: '1rem',
    },
    msgBoxGrid: { display: 'flex', justifyContent: 'space-between' },

    clientMsgBoxSection: {
      border: '1px solid #3c9fdf',
      width: '100%',
      borderRadius: '6px',
      minHeight: '200px',
      display: 'flex',
      // justifyContent: 'space-between',
      flexDirection: 'column',

      '& .inline-text': {
        display: 'flex',
        alignItems: 'center',

        '& .badge-count': {
          marginRight: '1.5rem',
          cursor: 'pointer',

          '& .MuiBadge-badge': {
            backgroundColor: '#f47950',
            color: '#fff',
          },
        },
      },

      '& .msg-box-header': {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .herder-text': {
          fontSize: '14px',
          margin: '0',
          color: '#000000',
          fontWeight: 500,
        },
      },

      '& .msg-divider': {
        background: '#3c9fdf',
        height: '2px',
        margin: '0 1rem',
      },

      '& .msg-box-content': {
        padding: '1rem 2rem',
        marginBottom: '0.5rem',
        minHeight: '64px',

        '& p': {
          margin: '0',
          fontSize: '14px',
        },

        '& .primary-text': {
          color: '#5f6368',
          fontWeight: 500,
          marginBottom: '0.5rem',
        },

        '& .secondary-text': {
          color: '#5f6368',
        },
      },

      '& .msg-box-footer': {
        display: 'flex',
        alignItems: 'center',
        background: '#f7f7f7',
        padding: '0.25rem 1rem',
        borderRadius: '0 0 6px 6px',

        '& .box-btn': {
          color: '#5f6368',
          fontSize: '14px',
          marginRight: '0.25rem',
        },
        '& .btn-icon': {
          color: '#3c9fdf',
        },
      },
    },

    msgBox: {
      maxWidth: '440px',
      padding: '0.5rem 2rem',
      backgroundColor: '#fff',

      '&:nth-of-type(even)': {
        backgroundColor: '#F5F5F5',
      },

      '& .msg-box-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .inline-text': {
          display: 'flex',
          alignItems: 'center',

          '& .name-text': {
            color: '#000',
            padding: '0 0.75rem',
            fontWeight: 500,
            textTransform: 'capitalize',
          },
          '& .hour-text': {
            color: '#788190',
          },
        },
        '& .btn-icon': {
          color: '#3c9fdf',
          fontSize: '1.25rem',
          cursor: 'pointer',
        },
      },

      '& .msg-text': {
        color: '#000',
        fontSize: '0.9rem',
        width: '75%',
      },
    },
  })
)
