import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { INote } from 'models/note'
import * as React from 'react'
import Note from './note'
import { colorsMapping } from './noteTypeCheckbox'
import useStyles from './styles'

interface INoteRowProps {
  note: INote
}

function NoteRow({ note }: INoteRowProps) {
  const classes = useStyles()
  const color = colorsMapping(note.note_type)

  return (
    <TableRow>
      <TableCell className={classes.noteTableCell} style={{ padding: '14px 0 0 0' }}>
        <Note noteData={note} color={color} />
      </TableCell>
    </TableRow>
  )
}

export default NoteRow
