import { fetchClients } from 'api/clients'
import SelectField from 'components/common/select'
import useStyles from 'components/common/styles/createDialogStyles'
import { ISuggestionAttribute } from 'components/interfaces'
import { ICompanyRole, ICreatedCompany, IDetailedCompanyResponse } from 'models/company'
import { IClient, ICompanyOwner, ICreatedClient, ICreateUpdateOwner } from 'models/user'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import showErrorNotification from 'utils/showErrorNotification'
import CreateClient from '../../clients/create'
import AddOwnerDialog from './AddOwnerDialog'
import CompanyOwnersTable from './CompanyOwnersTable'
import Client from '../../../pages/client'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { paginateClientsAsOwners } from 'api/clients'
import ClientTableHead from 'components/clients/table/head'
import ClientTableRow from 'components/clients/table/row'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { StyledTable, StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import { Table, TableBody, Link } from '@material-ui/core'
import AddButton from 'components/common/buttons/primaryButton'

export interface IOwnerSummary {
  id: number
  name: string
}

export interface ICompanyOwnersCreation {
  onOwnerFieldsChange: (key: keyof ICreateUpdateOwner, value: ValueOf<ICreateUpdateOwner>) => void
  fetchCompanyRoles: () => void
  toggleRole: (roleId: number) => void
  selectAllRoles?: () => void
  allRolesSelected?: boolean
  takenShares?: number
  initialClient?: IClient
  /**
   * handles submit event of add owner dialog
   */
  onAddOwnerButtonClick: (activeOwner?: IOwnerSummary) => void

  onOwnerEdit: (userId: number) => void
  onOwnerDelete?: (userId: number) => void
  clearOwnerState?: () => void
  values: ICreateUpdateOwner
  roles: ICompanyRole[]
  company?: ICreatedCompany | IDetailedCompanyResponse | (ICreatedCompany & IDetailedCompanyResponse)
  companyOwners: ICompanyOwner[]
  hideCompanyOwnersTable?: boolean
  shouldShowUpdateLevelOption?: boolean
  updateOnCompanyLevel?: boolean
  toggleUpdateLevelOption?: (
    event: React.MouseEvent<HTMLDivElement | MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => void
  hideEmptyOwnersTable?: boolean
  isCompanyCreation?: boolean
}

/**
 * Component with company owners table and a button that can add new owner to the company
 */
const CompanyOwnersCreation: React.FC<ICompanyOwnersCreation> = ({
  fetchCompanyRoles,
  roles,
  toggleRole,
  selectAllRoles,
  allRolesSelected,
  values,
  companyOwners,
  onAddOwnerButtonClick,
  onOwnerFieldsChange,
  company,
  onOwnerEdit,
  onOwnerDelete,
  clearOwnerState,
  hideCompanyOwnersTable,
  shouldShowUpdateLevelOption,
  updateOnCompanyLevel,
  toggleUpdateLevelOption,
  hideEmptyOwnersTable,
  takenShares = 0,
  isCompanyCreation = false,
  initialClient,
}) => {
  const [isAddOwnerDialogOpen, setIsAddOwnerDialogOpen] = React.useState<boolean>(false)

  /**
   * is used for creating or updating company owner through AddOwnerDialog
   */
  const [activeOwner, setActiveOwner] = React.useState<IOwnerSummary | undefined>()
  const [query, setQueryy] = React.useState('')

  const [clientCreation, setClientCreation] = React.useState<boolean>(false)
  const [isOnEdit, setIsOnEdit] = React.useState<boolean>(false)
  const apiCall = React.useMemo(() => paginateClientsAsOwners()({ query, page_size: 10 }), [query])
  const [showClient, setshowClient] = React.useState<boolean>(false)

  const pagedTable = usePagedTable<IClient>({ apiCall })

  const classes = useStyles()
  React.useEffect(() => {
    fetchCompanyRoles()
  }, [fetchCompanyRoles, companyOwners])

  /**
   * get all clients, filtering out company owners of current company
   */
  const getClients = React.useCallback(
    async (query: string) => {
      const clients = await fetchClients(query)
      const ownersIds = companyOwners.map(owner => owner.user.id)
      return clients
        .filter(client => !ownersIds.includes(client.id))
        .map(client => ({
          value: client.id,
          label: client.name,
        }))
    },
    [companyOwners]
  )

  const handleEditButtonClick = React.useCallback(
    async (userId: number) => {
      const owner: ICompanyOwner | undefined = companyOwners && companyOwners.find(client => client.user.id === userId)
      if (!owner || !owner.user.id) return console.warn('No user ID found')
      setActiveOwner({
        id: owner.user.id,
        name: owner.user.name,
      })
      setIsOnEdit(true)
      await fetchCompanyRoles()
      await onOwnerEdit(userId)
      setIsAddOwnerDialogOpen(true)
    },
    [companyOwners, fetchCompanyRoles, onOwnerEdit]
  )

  const handleAddOwnerClick = React.useCallback(() => {
    setClientCreation(true)
  }, [setClientCreation])

  /**
   * handle client select action
   *
   * @param item - selected client
   * its value
   *   1. will never be null because user is required to
   *      choose an item from select field.
   *   2. will never contain user that is already a company
   *      owner of the company, because options for user
   *      select field do not include existing company owners.
   */
  const handleClientDropdownClick = React.useCallback(
    async (item: any | null) => {
      if (!item) throw new Error('Client select does not support delete option.')

      const userId = item.value
      const userName = item.label

      // the value to be edited in the AddOwnerDialog
      const newActiveOwner: IOwnerSummary = {
        id: userId,
        name: userName,
      }
      setActiveOwner(newActiveOwner)

      onOwnerFieldsChange('user_id', newActiveOwner.id)

      setIsAddOwnerDialogOpen(true)
    },
    [onOwnerFieldsChange]
  )

  const handleModalClose = React.useCallback(() => {
    setIsAddOwnerDialogOpen(false)
    setActiveOwner(undefined)
    setIsOnEdit(false)
    if (clearOwnerState) clearOwnerState()
  }, [setIsAddOwnerDialogOpen, setActiveOwner, setIsOnEdit, clearOwnerState])

  const handleSubmitOwnerButtonClick = async () => {
    if (!activeOwner) {
      showErrorNotification('Could not submit active owner')
      handleModalClose()
      return
    }
    console.log('activeOwner', activeOwner)

    await onAddOwnerButtonClick(activeOwner)
    handleModalClose()
  }

  const onClientCreated = React.useCallback(
    (client: ICreatedClient) => {
      // if new client is created successfully, open a pop-up to make him the owner
      onOwnerFieldsChange('user_id', client.id)
      const clientPrimaryAddress = client.addresses?.find(address => address.is_primary) ?? null
      const newActiveOwner = {
        ...client,
        address: clientPrimaryAddress,
      }
      setActiveOwner(newActiveOwner)
      setClientCreation(false)
      setIsAddOwnerDialogOpen(true)
    },
    [setClientCreation, onOwnerFieldsChange]
  )

  console.log('initialClient => ', initialClient)

  return (
    <div className={classes.innerContainer} style={{ flexDirection: 'column' }}>
      {clientCreation && (
        <CreateClient
          isPopup={true}
          title={company ? `Add new owner for ${company.name}` : undefined}
          fetchClients={fetchClients}
          openByDefault={true}
          onClientCreated={onClientCreated}
          onClose={() => setClientCreation(false)}
          initialClient={company && company.addresses ? company.addresses : []}
        />
      )}
      {/* <AddButton style={{float:'right'}} onClick={() => setshowClient(true)}>Choose Existing Clients as Owners</AddButton> */}
      <div>
        <Link
          component="button"
          variant="body2"
          onClick={e => {
            e.preventDefault()
            setshowClient(true)
          }}
        >
          + Choose Existing Clients as Owners
        </Link>
      </div>

      {showClient && (
        <div className={classes.searchBarContainer}>
          {/* <SelectField
          onOptionSelected={handleClientDropdownClick}
          title="Clients"
          fetchSuggestions={(query, _field) => getClients(query)}
          field="client_id"
          placeholder="Search by name, email, phone or SSN/EIN"
          value={
            activeOwner
              ? {
                  value: activeOwner.id,
                  label: activeOwner.name,
                }
              : undefined
          }
          key={activeOwner && activeOwner.id}
        /> */}
          {/* <Client/> */}
          <div className={classes.searchBarContainer}>
            <SearchBar onChange={setQueryy} placeholder="Search by name, email, phone or SSN/EIN" />
          </div>
          <div className={classes.container} style={{ marginBottom: '1%' }}>
            <TableLoadingIndicator isLoading={pagedTable.isLoading} />
            {pagedTable.isLoading ? (
              <Table>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Phone</StyledTableCell>
                    <StyledTableCell>E-mail</StyledTableCell>
                    <StyledTableCell>Sales rep.</StyledTableCell>
                    <StyledTableCell>Credit</StyledTableCell>
                    <StyledTableCell>SSS/EIN</StyledTableCell>
                    <StyledTableCell /*className={classes.addOwnerHeaderCell}*/>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={e => {
                          e.preventDefault()
                          handleAddOwnerClick()
                        }}
                      >
                        + Add Owner
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <TableBody>{skeletons({ numberOfColumns: 8, numberOfRows: 3 })}</TableBody>
              </Table>
            ) : (
              <PagedTable
                // totalRows={10}
                emptyRowText="No Clients"
                {...pagedTable.renderProps}
                renderRow={(client: IClient) => (
                  <ClientTableRow
                    fromCompany={true}
                    client={client}
                    onEditClick={() => handleClientDropdownClick({ value: client.id, label: client.name })}
                    onDeleteClick={() => console.log()}
                  ></ClientTableRow>
                )}
                header={
                  <StyledTableHead>
                    <StyledTableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell>E-mail</StyledTableCell>
                      <StyledTableCell>Sales rep.</StyledTableCell>
                      <StyledTableCell>Credit</StyledTableCell>
                      <StyledTableCell>SSS/EIN</StyledTableCell>
                      <StyledTableCell /*className={classes.addOwnerHeaderCell}*/>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={e => {
                            e.preventDefault()
                            handleAddOwnerClick()
                          }}
                        >
                          + Add Owner
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  </StyledTableHead>
                }
                colSpan={8}
              />
            )}
          </div>
        </div>
      )}

      {activeOwner && (
        <AddOwnerDialog
          isAddOwnerDialogOpen={isAddOwnerDialogOpen}
          isOnEdit={isOnEdit}
          activeOwner={activeOwner}
          handleModalClose={handleModalClose}
          values={values}
          company={company}
          roles={roles}
          toggleRole={toggleRole}
          selectAllRoles={selectAllRoles}
          allRolesSelected={allRolesSelected}
          takenShares={takenShares}
          handleSubmitOwnerClick={handleSubmitOwnerButtonClick}
          onOwnerFieldsChange={onOwnerFieldsChange}
          shouldShowUpdateLevelOption={shouldShowUpdateLevelOption}
          updateOnCompanyLevel={updateOnCompanyLevel}
          toggleUpdateLevelOption={toggleUpdateLevelOption}
        />
      )}
      {!hideCompanyOwnersTable && (
        <div style={{ marginTop: showClient ? '5%' : '1%' }}>
          <CompanyOwnersTable
            hideEmptyOwnersTable={hideEmptyOwnersTable}
            companyOwners={companyOwners}
            company={company}
            handleEditButtonClick={handleEditButtonClick}
            onAddOwnerClick={handleAddOwnerClick}
            onOwnerDelete={onOwnerDelete}
            isCompanyCreation={isCompanyCreation}
          />
        </div>
      )}
    </div>
  )
}

export default CompanyOwnersCreation
