import { usePagedTable } from '@jetkit/react'
import { CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { fetchSubscription, ICreateProductReq } from 'api/subscription'
import SubscriptionDetails from 'components/subscription/create/subscriptionDetails'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import Notes from 'components/common/notes/notes'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { ISuggestionAttribute } from 'components/interfaces'
import { INote } from 'models/note'
import { IClient } from 'models/user'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import useRouter from 'use-react-router'
import { removeNullAndUndefined } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      ...theme.title,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    notesContainer: {
      width: '35%',
      zIndex: 1,
    },
    flex: {
      display: 'flex',
    },
  })
)

const EditSubscription = () => {
  const router = useRouter<{ id: string | undefined }>()
  const [subscription, setSubscription] = React.useState<ICreateProductReq>()
  const classes = useStyles()

  const fetchClientAndSetState = React.useCallback(
    async (id: number) => {
      try {
        const subscription = await fetchSubscription(id)
        setSubscription(subscription)
      } catch (error) {
        showApiResponseError(error, 'Could not load subscription details')
      }
    },
    [setSubscription]
  )

  React.useEffect(() => {
    // Scroll to top of the page because if we begin editing subscription
    // in the middle or bottom of clients table, first thing we see is the bottom
    // of the `subscription/:id` page
    window.scrollTo(0, 0)
    if (!router.match.params.id) {
      router.history.push('/')
      return
    }
    const id: number | undefined = parseInt(router.match.params.id)
    if (isNaN(id)) {
      router.history.push('/')
      return
    }
    fetchClientAndSetState(id)
  }, [fetchClientAndSetState, router.history, router.match.params.id])

  const onRequestSuccessful = React.useCallback(() => router.history.push('/subscription'), [router.history])

  return (
    <div className={classes.flex}>
      <div className={classes.container}>
        {subscription ? (
          <React.Fragment>
            <div className={classes.titleButtonContainer}>
              <Typography className={classes.title}>Edit Subscription (ID: {subscription.id || '- - -'})</Typography>
            </div>
            <SubscriptionDetails editedProduct={subscription} onRequestSuccessful={onRequestSuccessful} />
          </React.Fragment>
        ) : (
          <CircularProgress style={{ alignSelf: 'center' }} />
        )}
      </div>
    </div>
  )
}

export default EditSubscription
