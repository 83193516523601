import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientDashboard: {
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& .ques': {
        fontSize: '0.875rem',
        '& .link-schedule': {
          color: '#5b38da',
          fontWeight: theme.typography.fontWeightBold,
          cursor: 'pointer',
          textDecoration: 'underline',
          fontFamily: theme.typography.fontFamily,
        },
        '& .schedule-call-modal-container': {
          color: '#5b38da',
          fontWeight: theme.typography.fontWeightBold,
          textDecoration: 'underline',
          fontFamily: theme.typography.fontFamily,
          border: 'none',
          background: 'none',
          padding: 0,
          cursor: 'pointer',
        },
        // backgroundColor:"yellow",
      },
      '& .header': {
        background: '#e8f0ff',
        fontSize: '0.875rem',
        padding: '1.2rem 1rem',
        borderRadius: '4px',
        lineHeight: '1.2rem',
        '& .companies-name': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '& .upcoming-deadlines': {
        margin: '0rem 0 2rem 0',
      },

      '& .user-company-name': {
        color: '#407ace',
        fontSize: '1.25rem',
        margin: '0',
        marginBottom: '0.5rem',
        fontWeight: 500,
        textTransform: 'uppercase',
      },
      '& .inline-icon-heading': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.6rem',

        '& .avatar': {
          marginRight: '1rem ',
          marginBottom: '1rem',
        },
      },
      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2rem',
        fontFamily: theme.typography.h1.fontFamily,
        margin: '0',
        marginBottom: '1.2rem',
      },

      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },

      '& .secondary-section': {
        borderBottom: '3px solid #3c9fdf',
        margin: '2.5rem 0',
        maxWidth: '15%',

        '& .secondary-heading': {
          fontSize: '14px',
          color: '#020202',
          textAlign: 'center',
          padding: '0',
          margin: '0',
          fontWeight: 600,
          paddingBottom: '0.5rem',
        },
      },

      '& .accordian-table': {
        '& .accordian-table-text': {
          color: '#222222',
          fontSize: '14px',
          fontWeight: 500,
          paddingBottom: '1rem',
          textTransform: 'uppercase',
        },
        '& .extra-space': {
          marginLeft: '-20px',
        },
      },

      '& .companies-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '5rem 0 2rem 0',

        '& > div:first-child': {
          marginRight: '1rem',
        },
      },
      '& .power-ups-section': {
        margin: '5rem 0 2rem 0',
      },
    },
    powersUpHeading: {
      margin: '5rem 0 2rem 0',
    },

    addressContainer: {
      // maxHeight: '160px',
      // '& Grid': {
      //   height: 'max-content',
      // },
    },
    addressText: {
      color: theme.customPalette.primaryFontColor,
      margin: 0,
      opacity: '0.6',
      textTransform: 'uppercase',
    },
    addressSec: {
      padding: '1rem 1.25rem',
      color: theme.customPalette.primaryFontColor,
      fontSize: '1.15rem',
      background: theme.customPalette.paleGreyColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: '128px',
      height: '100%',
    },
    mapImg: {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
      maxHeight: '160px',
    },
    scheduleCallCard: {
      marginTop: '1rem',
      borderRadius: '4px',
      position: 'relative',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#f1f3f4',
      width: '100%',

      '& .schedule-bg': {
        width: '20%',

        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      '& .content-sec': {
        display: 'flex',
        width: '70%',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('md')]: {
          width: '100%',
          padding: '1rem',
        },

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'start',
        },
      },
      '& p, h1': {
        margin: '0',
        padding: '0',
      },

      '& .primary-text': {
        fontWeight: 700,
        fontSize: '26px',
        color: '#020202',

        [theme.breakpoints.down('md')]: {
          marginBottom: '0.5rem',
          fontSize: '22px',
        },
      },
      '& .secondary-text': {
        fontSize: '16px',
        fontWeight: 600,
        color: '#020202',

        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      },
      '& .schedule-btn': {
        background: '#00aba9',
        padding: '0.25rem 1.5rem',
        color: '#fff',
        borderRadius: '4px',
        fontWeight: 500,
        minHeight: '45px',

        [theme.breakpoints.down('sm')]: {
          marginTop: '1rem',
        },
      },
      '& .schedule-call-modal-container': {
        background: '#307abc',
        padding: '0.5rem 1.5rem',
        color: '#fff',
        borderRadius: '50px',
        fontWeight: 500,
        fontSize: '0.9rem',
        // minHeight: '45px',
        border: 'none',
        cursor: 'pointer',
        textTransform: 'uppercase',

        [theme.breakpoints.down('sm')]: {
          marginTop: '1rem',
        },
      },
      '& .close-icon': {
        color: '#707070',
        background: '#fff',
        padding: '2px',
        borderRadius: '100px',
        marginRight: '1rem',
        position: 'relative',
        top: '-30px',

        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },
    headingHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // margin: '1.2rem 0',
      marginTop: '0',
      '& h1': {
        margin: '0',
      },
    },
    primaryHeading: {
      color: theme.customPalette.primaryFontColor,
      fontSize: '1.4rem',
      // fontFamily: theme.typography.h1.fontFamily,
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      // marginBottom:'1rem'
    },
    linkText: {
      color: '#2c90d2',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      // fontFamily: theme.typography.fontFamily,
      cursor: 'pointer',
      textDecoration: 'underline',
    },

    iconCard: {
      // padding: '0.75rem',
      background: theme.customPalette.paleGreyColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // flexDirection: 'column',
      minHeight: '90px',
      position: 'relative',
      cursor: 'pointer',

      '& .icon': {
        fontSize: '1rem',
        // position: 'absolute',
        width: '2rem',
        height: '2rem',
        // top: '12px',
        // left: '12px',
        objectFit: 'contain',
      },
      '& .icon-text': {
        color: '#020202',
        fontSize: '14px',
        margin: '0',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        textAlign: 'center',
        // paddingTop: '0.5rem',
        width: '30%',
      },
      '& .upload-text': {
        color: '#788190',
        fontSize: '12px',
      },
    },
    addSec: {
      background: theme.palette.secondary.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '85px',
      padding: '1.5rem',
      color: '#fff',
      cursor: 'pointer',
    },
    companyCard: {
      background: theme.customPalette.paleGreyColor,
      padding: '0.9rem 1.2rem',
      borderRadius: '4px',
      // minHeight: '75px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      minHeight: '55px',

      '& .img-text-inline': {
        display: 'flex',
        alignItems: 'center',
        fontFamily: theme.typography.fontFamily,

        '& .avatar': {
          marginRight: '1rem',
          '& img': {
            objectFit: 'contain',
          },
        },
        '& .name-text': {
          fontWeight: 600,
          fontSize: '1rem',
          margin: '0',
        },
        '& .city-text': {
          fontWeight: 500,
          fontSize: '0.75rem',
          margin: '0',
          paddingTop: '0.25rem',
        },
        '& .date': {
          fontFamily: theme.typography.fontFamily,
          margin: '0',
          fontSize: '0.75rem',
          paddingTop: '0.25rem',
          color: theme.palette.primary.main,
        },
      },
      '& .samll-img-text-inline': {
        display: 'flex',
        alignItems: 'center',
        fontFamily: theme.typography.fontFamily,

        '& .avatar': {
          marginRight: '0.5rem',
          width: '25px',
          height: '25px',

          '& img': {
            objectFit: 'contain',
          },
        },
        '& .name-text': {
          fontWeight: 600,
          fontSize: '0.9rem',
          margin: '0',
        },
      },
      '& .company-card-section': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

        '& .detail-text': {
          color: '#407ace',
          fontSize: '0.75rem',
          fontFamily: 'Lato',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        },
      },
      '& .small-city-text': {
        fontWeight: 500,
        fontSize: '0.75rem',
        margin: '0',
        paddingTop: '0.25rem',
      },
      '& .small-date': {
        fontFamily: theme.typography.fontFamily,
        margin: '0',
        fontSize: '0.75rem',
        paddingTop: '0.25rem',
        color: theme.palette.primary.main,
      },
    },
    offerCard: {
      borderRadius: '4px',
      border: '2px solid',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1.25rem 0.8rem',
      minHeight: '90px',
      justifyContent: 'center',
      color: '#363636',
      background: theme.customPalette.paleGreyColor,
      cursor: 'pointer',

      ' & .product-img': {
        width: '100%',
        objectFit: 'contain',
        height: '2rem',
      },

      '& .inline-text': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: '0.5rem',
        flexWrap: 'wrap',

        '& .extra-text': {
          margin: '0',
          fontSize: '0.9rem',
          fontWeight: 700,
          padding: '0.25rem 0',
          textAlign: 'center',
        },
        '& .title-text': {
          margin: '0',
          fontSize: '0.9rem',
          fontWeight: 600,
          textAlign: 'center',
          textTransform: 'uppercase',
        },
      },
    },
    processCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5rem 1rem',
      background: '#E3E5E7',

      '& .penddingText': {
        color: theme.customPalette.primaryFontColor,
        margin: '0',
      },
      '& .renewText': {
        color: '#32be66',
        background: '#fff',
        padding: '0.5rem',
        boxShadow: '0 5px 4px -3px rgba(0, 0, 0, 0.5)',
      },
      '& .clearText': { color: '#b08a19', margin: '0' },
    },
    input: {
      display: 'none',
    },
    uploadSec: {
      display: 'flex',
      justifyContent: 'flex-end',

      '& label': {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

        '& span': {
          color: '#307abc',
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    inlineHeading: {
      display: 'flex',
      alignItems: 'center',
      margin: '5rem 0 1rem 0',
      '& span': {
        marginLeft: '4rem',
        display: 'flex',
        alignItems: 'center',
        color: '#307abc',
        fontSize: '14.5px',
        fontWeight: 500,
        cursor: 'pointer',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    tabRoot: {
      flexGrow: 1,
      backgroundColor: 'transparent',
      width: '100%',

      '& .tab-app-container': {
        boxShadow: 'none',
        background: '#EAF1FB',

        '& .Mui-selected': {
          color: '#407ace',
        },
        '& .MuiTabs-indicator': {
          background: '#407ace',
        },

        '& button': {
          minWidth: '20%',
          color: '#292929',
          fontFamily: 'Roboto, sans-serif',
          fontweight: 500,
          opacity: 1,
        },
      },

      '& .company-box': {
        background: '#f8fafc',
        padding: '3rem 1rem !important',
      },

      '& .inline-items': {
        display: 'flex',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        alignItems: 'center',

        '& .title': {
          color: '#788190',
          paddingRight: '2rem',
          textAlign: 'end',
          textTransform: 'uppercase',
          width: '40%',
        },
        '& .chip-inline': {
          display: 'flex',
          flexWrap: 'wrap',
          // justifyContent: 'space-between',
          width: '60%',
        },
        '& .content': {
          color: '#000000',
          width: '60%',
          textAlign: 'start',
          overflow: 'hidden',
          wordWrap: 'break-word',
        },
        '& .chip-container': {
          background: '#407ace38',
          borderRadius: '0.8rem',
          color: '#202124',
          padding: '0.25rem 0.75rem',
          marginLeft: '0.5rem',
          textAlign: 'center',
          minWidth: '108px',
        },
      },

      '& .company-info-container': {
        // display: 'flex',
        // justifyContent: 'center',

        '& .MuiChip-root': {
          background: '#04a464',
          color: '#fff',
          padding: '0 0.8rem',
          fontSize: '0.9rem',
        },
        '& .custom-render': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginBottom: '0.5rem',
        },
      },
      '& .company-owners-container': {},

      '& .single-owners-container': {
        padding: '0 0.5rem',
      },

      '& .owners-list-container': {
        // borderBottom: '1px solid',

        '& .owners-list-section': {
          // maxWidth: '49.9%',
          // flexBasis: '49.9%',
          margin: '1rem 0',

          [theme.breakpoints.down('sm')]: {
            maxWidth: '99.9%',
            flexBasis: '99.9%',
            margin: '0',

            '&:nth-of-type(even)': {
              // borderTop: '0.5px solid #29292999',
              // borderBottom: '0.5px solid #29292999',
              border: 'none',
              margin: '3rem 0',
            },
          },

          [theme.breakpoints.up('md')]: {
            '&:nth-of-type(even)': {
              borderLeft: '0.5px solid #29292999',
            },
          },

          // '&:nth-of-type(odd)': {
          //   borderRight: '0.5px solid #29292999',
          // },
        },
      },

      '& .licenses-permit-accordion-container': {
        '& .accordion-summary': {
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
          },
        },
        '& .accordion-details': {
          flexDirection: 'column',
        },
        '& .accordion-icon': {
          height: '28px',
          objectFit: 'contain',
          marginRight: '1rem',
        },
        '& .accordion-heading': {
          color: '#020202',
          fontSize: '14px',
          padding: '0',
          margin: '0',
          fontWeight: 500,
          lineHeight: 'normal',
          fontFamily: 'Roboto, sans-serif',
          textTransform: 'capitalize',
        },
        '& .bottom-space': {
          marginBottom: '1rem',
        },
        '& .light-text': {
          color: '#979797',
          fontSize: '12px',
          padding: '0',
          margin: '0',
        },
        '& .dark-text': {
          color: '#202124',
          fontSize: '14px',
          padding: '0',
          margin: '0',
        },
      },
    },

    companyProfileCard: {
      '& .company-profile-container': {
        background: '#f7faff',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: '4px',
        padding: '1rem',

        '& .profile-heading': {
          color: '#343b4c',
          fontSize: '1rem',
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: 700,
          // marginTop: '2.25rem',
          marginBottom: '1rem',
        },

        '& .complete-profile-text': {
          color: '#020202',
          textTransform: 'uppercase',
          fontWeight: 600,
          margin: 0,
          fontFamily: 'Lato, sans-serif',
          fontSize: '1rem',
          textAlign: 'center',
          padding: '1rem 0 0.5rem',
        },

        '& .complete-profile-section': {
          background: '#f8fafc',
          borderTop: '3px solid #00823b',
          minHeight: '301px',
          paddingTop: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center',

          '& .CircularProgressbar > .CircularProgressbar-text': {
            fontWeight: 700,
            fontFamily: 'Lato, sans-serif',
          },
        },

        '& .complete-profile-sec': {
          textAlign: 'center',

          '& .complete-profile-text': {
            color: '#202124',
            textTransform: 'uppercase',
            fontWeight: 500,
          },
          '& .complete-btn': {
            background: '#307abc',
            color: '#fff',
          },
        },

        '& .circular-color': {
          color: '#307abc',
        },
        '& .inner-text': {
          color: '#202124',
          fontSize: '20px',
          fontFamily: 'Lato',
          fontWeight: 800,
        },

        '& .error-icon': {
          display: 'flex',
          alignItems: 'center',
          minHeight: '56px',
          marginTop: '-4rem',
          marginBottom: '1rem',

          '& p': {
            paddingLeft: '0.5rem',
            color: '#292929',
            fontSize: '0.85rem',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
          },
        },
      },
    },
    handPickedPowersUp: {
      // marginTop: '2rem',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      '& .hands-powerup-header': {
        margin: '5rem 0 2rem 0',
      },
    },

    creditScoreCard: {
      background: '#f7faff',
      padding: '2rem',
      minHeight: '300px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',

      '& .profile-heading': {
        color: '#292929',
        fontSize: '1.5rem',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        // marginTop: '2.25rem',
        margin: 0,
        textAlign: 'center',
        textWrap: 'balance',
      },

      '& .heading-divider': {
        margin: '0.5rem 0',
        background: '#407ace',
      },

      '& .profile-title': {
        color: '#292929',
        fontSize: '1rem',
        margin: 0,
        textAlign: 'center',
        textWrap: 'balance',
      },

      '& .profile-subtitle1': {
        color: '#343b4c',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1rem',
        margin: 0,
        textAlign: 'center',
        marginTop: '-0.5rem',
        position: 'relative',
        zIndex: 2,
        marginBottom: '1.25rem',
        fontWeight: 700,
      },

      '& .profile-subtitle2': {
        fontFamily: 'Roboto, sans-serif',
        color: '#788190',
        fontSize: '0.75rem',
        margin: 0,
        textAlign: 'center',
        position: 'relative',
        zIndex: 2,
        marginTop: '-1rem',
        marginBottom: '1.25rem',
      },

      '& .inline-img': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',

        '& img': {
          height: '48px',
        },
        '& span': {
          color: '#26478d',
          fontSize: '2.5rem',
          padding: '0 1rem',
        },
      },

      '& .complete-profile-sec': {
        textAlign: 'center',

        '& .complete-profile-text': {
          color: '#202124',
          textTransform: 'uppercase',
          fontWeight: 500,
        },
        '& .crdit-detail-btn': {
          background: '#5b38da',
          color: '#fff',
          borderRadius: '2rem',
          padding: '0.5rem 3rem',
          marginTop: '1rem',
        },
      },

      '& .circular-color': {
        color: '#307abc',
      },
      '& .inner-text': {
        color: '#202124',
        fontSize: '20px',
        fontFamily: 'Lato',
        fontWeight: 800,
      },
    },

    creditProgressContainer: {
      margin: '1rem 4rem 0px',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',

      '& .label': {
        color: '#343b4c',
        fontSize: '1rem',
        margin: 0,
        marginBottom: '0.5rem',
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        },
      },

      '& .CircularProgressbar > .CircularProgressbar-text': {
        fontWeight: 700,
        fontSize: '2.5rem',
      },
    },
    PowerUpCardContainer: {
      '& p': {
        margin: 0,
      },
      '& a': {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#5b38da',
        cursor: 'pointer',
        lineBreak: 'anywhere',
      },

      '& .powerup-card-title': {
        padding: '1rem 0',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#343b4c',
      },
      '& .powerup-card': {
        // padding: '1.25rem',
        // background: '#f9fcff',
        // boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        '& .powerup-card-heading': {
          // padding: '1rem',
          fontSize: '1rem',
          fontWeight: 500,
          maxHeight: '42px',
          minHeight: '42px',
          marginBottom: '0.5rem',
          overflow: 'hidden',
          color: '#343b4c',
          textOverflow: 'ellipsis',
        },

        '& .powerup-card-description': {
          // padding: '0.9rem',
          fontSize: '0.9rem',
          maxHeight: '58px',
          minHeight: '58px',
          marginBottom: '1.8rem',
          overflow: 'hidden',
          color: '#343b4c',
          textOverflow: 'ellipsis',

          [theme.breakpoints.down('sm')]: {
            maxHeight: '110px',
            minHeight: '110px',
          },

          '& ul, ol': {
            paddingLeft: '1rem',
          },

          '& li': {
            listStyleType: "'✔'",
            paddingInlineStart: '1ch',

            '&::marker': {
              color: '#5b38da',
            },
          },
        },
      },
      '& .powerup-img': {
        width: '30%',
        display: 'flex',
        justifyContent: 'end',
        '& .powerup-circle': {
          background: 'linear-gradient(to right, #D4F0FC 50%, #bfe8f9 50%)',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& img': {
            display: 'block',
            // margin-left: auto;
            // margin-right: 'auto';
            width: '65%',
            // margin: '0 auto',
            objectFit: 'contain',
            // width: '50px',
            // height: '50px',
            // height: '96px',
            // // marginLeft: '1rem',
            // borderRadius: '100%',
            // [theme.breakpoints.down('lg')]: {
            //   width: '56px',
            //   height: '56px',
            // },
            // [theme.breakpoints.down('md')]: {
            //   width: '86px',
            //   height: '86px',
            // },
            // [theme.breakpoints.down('sm')]: {
            //   width: '72px',
            //   height: '72px',
            // },
          },
        },
      },
    },
    newCompanyCard: {
      background: '#f7faff',
      fontFamily: 'Lato',
      padding: '1.25rem',

      '& .newCompanyCard-section': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '115px',
      },

      '& p, h1': {
        margin: '0',
      },

      '& .icon-sec': {
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: 'linear-gradient(to right,  #d4f0fc 0%,#d4f0fc 50%,#5bb3d9 50%,#5bb3d9 100%)',
        width: '52px',
        height: '52px',
        position: 'relative',
        marginBottom: '1rem',

        '& .progress-sec': {
          position: 'absolute',
          top: 0,
        },
      },
      '& .active-sec': {
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column',
        '& .MuiChip-root': {
          background: '#04a464',
          color: '#fff',
          padding: '0 0.8rem',
          fontSize: '0.9rem',
        },

        '& .date-text': {
          color: '#292929',
          fontSize: '0.9rem',
          paddingTop: '0.25rem',
        },
      },

      '& .company-text': {
        color: '#020202',
        fontSize: '1.2rem',
        padding: '1rem 0 0.5rem 0',
      },
      '& .state-text': {
        color: '#292929',
        fontSize: '1rem',
      },
      '& .error-icon': {
        display: 'flex',
        alignItems: 'center',
        minHeight: '56px',

        '& p': {
          paddingLeft: '0.5rem',
          color: '#292929',
          fontSize: '0.85rem',
        },
      },
      '& .detail-text': {
        color: '#5b38da',
        fontSize: '1rem',
        fontFamily: 'Lato',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        lineHeight: 0,
        fontWeight: 600,
        marginTop: '1rem',
      },
    },
    reviewBtn: {
      fontFamily: 'Roboto,sans-serif',
      fontSize: '0.9rem',
      color: '#fff',
      background: '#f44336',
      borderRadius: '1rem',
      padding: '0.5rem 1rem',
      lineHeight: 'normal',
    },
  })
)
