import { FormControlLabel, Switch, Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/styles'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import * as React from 'react'
import { IYearsSchedule } from '.'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import { useParams } from 'react-router'
import { complianceMuteUnmute } from 'api/complianceCalendar'
import showErrorNotification from 'utils/showErrorNotification'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },

    primaryAddress: {
      marginTop: '0.25rem',
      '& p': {
        color: '#0000009c',
        fontSize: '12px',
        margin: '0',
        fontStyle: 'italic',
      },
    },
  })
)

const YearsTableRow: React.FC<{ data: IYearsSchedule; setIsupdate: any }> = ({ data, setIsupdate }) => {
  const classes = useStyles()
  const { companyId } = useParams<{ companyId: string }>()

  const [isMute, setIsMute] = React.useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
  const [showDeleteLoading, setShowDeleteLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (data?.muted) setIsMute(data?.muted)
  }, [data?.muted])

  const handleMuteUnmuteSchedule = async () => {
    const payload = {
      muted: isMute || false,
      company_id: Number(companyId),
      notification_schedule_id: data?.notification_schedule_id || 0,
    }
    setShowDeleteLoading(true)

    try {
      await complianceMuteUnmute(payload)
      setIsupdate(true)
    } catch (error) {
      showErrorNotification(error)
    }
    setIsupdate(false)
    setShowDeleteDialog(false)
    setShowDeleteLoading(false)
    setIsMute(data?.muted || false)
  }

  console.log('data?.muteddata?.muteddata?.muted', data?.muted, isMute)

  return (
    <>
      <StyledTableRow className={classes.row}>
        <StyledTableCell>{data.schedule_name || '- - -'}</StyledTableCell>
        {data?.years_schedule?.map((item: string) => (
          <StyledTableCell>{item}</StyledTableCell>
        ))}
        {/* <StyledTableCell>{data.years_schedule[0]}</StyledTableCell>
        <StyledTableCell>{data.years_schedule[1]}</StyledTableCell>
        <StyledTableCell>{data.years_schedule[2]}</StyledTableCell>
        <StyledTableCell>{data.years_schedule[3]}</StyledTableCell>
        <StyledTableCell>{data.years_schedule[4]}</StyledTableCell> */}
        <StyledTableCell>
          <FormControlLabel
            control={
              <Switch
                checked={data?.muted}
                // checked={data?.muted || isMute}
                onChange={e => {
                  setIsMute(e.target.checked)
                  setShowDeleteDialog(true)
                }}
                name="isMute"
                color="primary"
              />
            }
            label={data?.muted ? 'Muted' : 'Unmuted'}
            // label={data?.muted ? 'Muted' : 'Unmuted'}
          />
        </StyledTableCell>
      </StyledTableRow>

      <DeleteConfirmationDialog
        itemName={data.schedule_name || '- - -'}
        onClose={() => {
          setShowDeleteDialog(false)
          setIsMute(data?.muted || false)
        }}
        open={showDeleteDialog}
        actionText={isMute ? 'Mute' : 'Unmute'}
        itemType="Schedule"
        onDelete={handleMuteUnmuteSchedule}
        buttonText="Confirm"
        isDeleting={showDeleteLoading}
      />
    </>
  )
}

export default YearsTableRow
