import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField'
import { IAddress } from 'models/address'
import * as React from 'react'

interface IAddressesSelect extends BaseTextFieldProps {
  className?: string
  addresses: IAddress[]

  /**
   * when address select dropdown is open, below address options,
   * there is a button `Add New Address`. this prop is a handler
   * of what happens when this button is called.
   */
  onAddAddressClick(): void

  label?: string

  /**
   * is called when a different address is selected
   */
  onChange: (newAddressId: number | null) => void

  dataTestId?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    innerContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  })
)

/**
 * address select field
 */
export const AddressesSelect = ({
  className,
  addresses,
  onAddAddressClick,
  variant,
  label,
  dataTestId = `${label}-addresses-select`,
  onChange,
  ...props
}: IAddressesSelect) => {
  const classes = useStyles()

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      event.stopPropagation()
      const numberValue = parseInt(event.target.value)
      const newAddressId = isNaN(numberValue) ? null : numberValue
      onChange(newAddressId)
    },
    [onChange]
  )

  const handleAddNewAddressButtonClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      onAddAddressClick()
    },
    [onAddAddressClick]
  )

  return (
    <TextField
      data-testid={dataTestId}
      select
      variant="outlined"
      value=""
      className={className}
      label={label}
      inputProps={{
        name: label ?? 'Select Address',
      }}
      onChange={handleChange}
      {...props}
    >
      {addresses?.map(address => (
        <MenuItem data-testid={`address-option-${address.id}`} key={address.id} value={address.id}>
          {getAddressAsString(address)}
        </MenuItem>
      ))}
      <div className={classes.innerContainer}>
        <Button
          variant="contained"
          onClick={handleAddNewAddressButtonClick}
          style={{ color: 'white', fontWeight: 'bold' }}
          color="primary"
        >
          Add New Address
        </Button>
      </div>
    </TextField>
  )
}

export default AddressesSelect

export function getAddressAsString(address: IAddress): string {
  return `${address.street} ${address.city} ${address.postal_code} ${address.country}`
}
