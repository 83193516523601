import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { getTaxAnswerEmail, getTaxQuestionEmail } from 'api/taxOrders'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import useUser from 'hooks/useUser'
import React from 'react'
import { USERTYPES } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useParams } from 'react-router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notifyButton: {
      display: 'flex',
      justifyContent: 'flex-end',

      '& button': {
        minWidth: '140px',
      },

      '& .question-btn': {
        marginRight: '1rem',
      },
    },
  })
)

const NotifyModal = ({ open, setOpen }) => {
  const classes = useStyles()
  const { user } = useUser()
  const { id } = useParams<any>()

  const [isQuestionLoading, setIsQuestionLoading] = React.useState(false)
  const [isAnswerLoading, setIsAnswerLoading] = React.useState(false)

  const handleQuestionEmail = async () => {
    setIsQuestionLoading(true)
    if (id) {
      try {
        await getTaxQuestionEmail(id)
        showSuccessNotification('Successfully Sent Notify Email')
        setIsQuestionLoading(false)
        setOpen()
      } catch (error) {
        showErrorNotification(error)
        console.log(error)
        setIsQuestionLoading(false)
      }
    }
  }
  const handleAnswerEmail = async () => {
    setIsAnswerLoading(true)
    if (id) {
      try {
        await getTaxAnswerEmail(id)
        showSuccessNotification('Successfully Sent Notify Email')
        setIsAnswerLoading(false)
        setOpen()
      } catch (error) {
        showErrorNotification(error)
        console.log(error)
        setIsAnswerLoading(false)
      }
    }
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      heading={`Send Notification to ${
        user?.user_type === USERTYPES.accountant ? 'Controller ' : 'Accountant '
      } about ?`}
    >
      <div className={classes.notifyButton}>
        <ButtonWithLoading
          wrapperClassName="question-btn"
          kind="BRSecondary"
          isLoading={isQuestionLoading}
          onClick={handleQuestionEmail}
        >
          Question(s)
        </ButtonWithLoading>
        <ButtonWithLoading
          wrapperClassName="answer-btn"
          kind="BRSecondary"
          isLoading={isAnswerLoading}
          onClick={handleAnswerEmail}
        >
          Answer(s)
        </ButtonWithLoading>
      </div>
    </ModalWrapper>
  )
}

export default NotifyModal
