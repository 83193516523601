import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import useStyles from './styles'

interface INoteModalTitleProps {
  toggleModal?: (value: boolean) => void // in case if you use toggle function (pass true / false to control the modal)
  onClose?: () => void // in case if you use simple closing function
}

const NoteModalTitle: React.FC<INoteModalTitleProps> = ({ toggleModal, onClose }) => {
  const classes = useStyles()
  return (
    <div className={classes.inlineTitleButtonContainer}>
      <Typography>Notes</Typography>
      <IconButton
        onClick={() => (toggleModal ? toggleModal(false) : onClose ? onClose() : undefined)}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </div>
  )
}

export default NoteModalTitle
