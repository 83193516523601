import { MeetingLinkProps } from 'api/meetingLinks'
import React from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'

interface MeetingLinkRowProps {
  meetingLink: MeetingLinkProps
  onDeleteClick: () => void
}

const MeetingLinkRow: React.FC<MeetingLinkRowProps> = ({ meetingLink, onDeleteClick }) => {
  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )
  return (
    <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ minWidth: 120 }}>{meetingLink?.title}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{meetingLink?.link}</TableCell>

      <TableCell style={{ minWidth: 90 }}>
        <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default MeetingLinkRow
