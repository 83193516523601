import { useStyles as useStyles2 } from 'components/common/assetUpload/uploadModalStyle'
import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Button, Checkbox, ListItem } from '@material-ui/core'
import ModalTitle from 'components/common/modalTitle'
import { getCompanyOrderDocumentsWithoutPagination, sendDocsToClient } from 'api/orders'
import showApiResponseError from 'utils/showApiResponseError'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'

interface Documents {
  company_id: number
  deleted_at: string
  doc_type: string
  doc_type_id: number
  filename: string
  id: number
  is_deleted: boolean
  is_show_on_client: boolean
  notes: string
  order_id: number
  uploaded_by: number
  uploaded_on: string
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '25%',
      },
      //   width: '25%',
    },
    root: {
      overflow: 'hidden',
      overflowY: 'auto',

      paddingTop: '2rem',
      '& .MuiTextField-root': {
        marginBottom: '1rem',
        width: '100%',
      },
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '93%',
      },
      padding: theme.spacing(2),
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'center',
    },

    uploadBtn: {
      color: '#fff',
      width: '200px',
      height: '50px',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
    },
  })
)
const DocumentModal: React.FC<any> = ({ open, setOpen, order_id, reloadNoteTable }) => {
  const classes2 = useStyles()
  const [docs, setDocs] = React.useState<Documents[]>([])
  const [selectedDocs, setSelectedDocs] = React.useState<number[]>([])
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false)

  const getDocs = async () => {
    try {
      const res = await getCompanyOrderDocumentsWithoutPagination(order_id)
      setDocs(res)
    } catch (error) {
      showApiResponseError(error, 'Error')
    }
  }
  React.useEffect(() => {
    if (open) getDocs()
  }, [open])
  React.useEffect(() => {
    console.log('docss', selectedDocs)
  }, [selectedDocs])
  const onDocChange = (id: number, val: boolean) => {
    let temparray = selectedDocs

    if (val === true) {
      temparray.push(id)

      setSelectedDocs(temparray)
    } else {
      temparray = temparray.filter(item => {
        return item !== id
      })
      setSelectedDocs(temparray)
    }
  }
  const sendDocs = async () => {
    console.log('selectedDocs', selectedDocs)
    if (!selectedDocs.length) {
      showErrorNotification('No Documents Selected')
      return
    }
    try {
      setSubmitLoading(true)
      const res = await sendDocsToClient(order_id, { documents: selectedDocs })
      setSubmitLoading(false)
      if (res.success) {
        showSuccessNotification('Sent Successfully')
        setOpen(false)
        setSelectedDocs([])
        reloadNoteTable()
      }
    } catch (error) {
      showErrorNotification('Something Went Wrong')
      setSubmitLoading(false)
    }
  }
  return (
    <Dialog open={open} className={classes2.dialogContainer} fullWidth={false}>
      <ModalTitle title={'Choose Documents'} toggleModal={setOpen} />
      <DialogContent className={classes2.root}>
        {!docs.length && <Alert severity="info">No Documents found!</Alert>}
        {docs.length > 0 &&
          docs.map((doc, index) => (
            <ListItem key={index}>
              <Checkbox
                color="secondary"
                //   checked={is_rush_order}
                // `value` prop is added here for easier testing.
                // because MUI only changes svg image when checkbox is clicked
                //   value={is_rush_order}
                style={{ padding: 0, marginRight: 10 }}
                onChange={e => onDocChange(doc.id, e.target.checked)}
              />
              <span style={{ fontSize: '19px' }}>{doc.filename}</span>
            </ListItem>
          ))}
      </DialogContent>
      <DialogActions className={classes2.dialogAction}>
        <ButtonWithLoading
          // disabled={selectedDocs.length ? false : true}
          isLoading={submitLoading}
          onClick={sendDocs}
        >
          Send Email{' '}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  )
}

export default DocumentModal
