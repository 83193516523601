import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { searchStaff } from 'api/staff'
import { useAPICallback } from 'hooks/useAPICallback'
import { IClient } from 'models/user'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

export interface IncorporatorFieldProps {
  /**
   * Triggered once an option has been selected from the `<Autocomplete>` dropdown or it's been emptied.
   */
  handleAutocompleteChange: (value: string | null) => void

  /**
   * Default option that will appear as the selected incorporator
   */
  defaultIncorporator: string | null

  className?: string

  /**
   * Boolean indicating whether we should display the view intended for
   */
  error?: boolean
}

/**
 * An incorporator is the person in charge of setting up a corporation and registering it with the state. They're responsible for filing the paperwork and signing the articles of incorporation. A business is not fully incorporated and legally registered without an incorporator.
 *
 * By default on user's input this component will send search requests, getting a new list of incorporators to select from.
 */
const IncorporatorField = ({
  className,
  error,
  handleAutocompleteChange,
  defaultIncorporator,
}: IncorporatorFieldProps) => {
  const [staffMembers, setStaffMembers] = React.useState<IClient[]>([])
  const [inc, setinc] = React.useState('')

  const autocompleteOptions: string[] = staffMembers ? staffMembers.map(staffMember => staffMember.name) : []
  /**
   * If `query` is provided return a list of people with matching names.
   * Otherwise return a list of all the staff within the limit set on the backend.
   */
  const handleGettingStaffMembers = useAPICallback(async (query: string) => {
    const staff: IClient[] = await searchStaff(query)
    if (staff.length == 0) {
      staff.push({ name: query, user_type: 'staff', email: '' })
    }
    setStaffMembers(staff)
  }, [])

  // Wrapper around `handleAutocompleteChange`, which allows to omit `event` in the interface of that prop
  const handleChangingAutocomplete = React.useCallback(
    (event: React.ChangeEvent<{}>, value: string | null) => {
      handleAutocompleteChange(value)
    },
    [handleAutocompleteChange]
  )

  /**
   * Triggered upon typing into the `<Autocomplete>` input field
   */
  const handleInputChange = useAPICallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      //console.log('val', value);
      setinc(value)
      const incorporatorName = value ? value : ''
      handleGettingStaffMembers(incorporatorName)
    },
    [handleGettingStaffMembers],
    { debounceDelay: 300 }
  )

  // Get a list of incorporators on the first render
  React.useEffect(() => {
    handleGettingStaffMembers('')
  }, [handleGettingStaffMembers])

  return (
    <Autocomplete
      data-testid={TestID.INCORPORATOR_AUTOCOMPLETE}
      options={autocompleteOptions}
      className={className}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          name="Incorporator"
          error={error}
          variant="outlined"
          helperText={error && 'Please Select An Incorporator Client'}
          label="Incorporator"
          InputProps={{ ...params.InputProps }}
        />
      )}
      onInputChange={handleInputChange}
      onChange={handleChangingAutocomplete}
      value={defaultIncorporator}
      //value = {inc}
    />
  )
}

export default IncorporatorField
