import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'name', label: 'Name', style: { width: '50%' }, sortable: true },
  { id: 'color', label: 'Color', style: {}, sortable: true },
  { id: 'type', label: 'Type', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const LabelTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default LabelTableHead
