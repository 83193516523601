import React, { useMemo } from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Table, Theme, TableBody } from '@material-ui/core'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import MissingItemTableHead from 'components/missingItem/MissingItemTableHead'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { deleteMissingItems, getMissingItems, MissingItemProps } from 'api/missingItems'
import { skeletons } from 'components/common/table'
import MissingItemRow from 'components/missingItem/MissingItemRow'
import AddMissingItemDialog from 'components/missingItem/AddMissingItemDialog'
import DeleteDialog from 'components/notifications/deleteDialog'

const MissingItems = () => {
  const classes = useStyles()

  const [missingItemDialogShown, setMissingItemDialogShown] = React.useState(false)
  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [missingItemToDelete, setMissingItemToDelete] = React.useState<MissingItemProps>()
  const [editedMissingItem, setEditedMissingItem] = React.useState<MissingItemProps>()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const tableHead = useMemo(
    () => <MissingItemTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getMissingItems()(), [])

  const pagedTable = usePagedTable<MissingItemProps>({
    apiCall: memoApiCall,
  })

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleEditNotificationClick = React.useCallback((missingItem: MissingItemProps) => {
    setEditedMissingItem(missingItem)
    setMissingItemDialogShown(true)
  }, [])

  const handleDeleteNotificationClick = React.useCallback((missingItem: MissingItemProps) => {
    setMissingItemToDelete(missingItem)
    setDeleteItemDialogShown(true)
  }, [])

  const handleMissingItemsDialogCloseClick = React.useCallback(() => {
    setMissingItemDialogShown(false)
  }, [])

  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.missingItems}>Missing Items</p>
          <p className={classes.tableTitle}>Missing Items List</p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => setMissingItemDialogShown(true)}>
            Add Missing Item
          </ButtonWithLoading>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={e => console.log(e)} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 3, sizes: [120, 200, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Missing Items"
          {...pagedTable.renderProps}
          renderRow={(missingItem: MissingItemProps) => (
            <MissingItemRow
              missingItem={missingItem}
              onEditClick={() => handleEditNotificationClick(missingItem)}
              onDeleteClick={() => handleDeleteNotificationClick(missingItem)}
            />
          )}
          header={tableHead}
        />
      )}
      <AddMissingItemDialog
        isOpen={missingItemDialogShown}
        close={handleMissingItemsDialogCloseClick}
        editedMissingItem={editedMissingItem}
        onRequestSuccessful={handleSuccessfulRequest}
      />
      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={missingItemToDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deleteMissingItems}
      />
    </div>
  )
}

export default MissingItems

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
