export const toTitleCase = (word: string): string => {
  if (!word) {
    return ''
  }
  let str = word.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  const lowers = []
  for (let i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), function(txt) {
      return txt.toLowerCase()
    })

  // Certain words such as initialisms or acronyms should be left uppercase
  const uppers = ['Id', 'Tv', 'Ein']
  for (let i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), uppers[i].toUpperCase())

  return str
}

export const fromSnakeCaseToTitle = words => {
  /*
  From "aaa_bbb" to "Aaa Bbb"
  */
  return words
    .split('_')
    .map(w => {
      return w[0].toUpperCase() + w.slice(1)
    })
    .join(' ')
}
