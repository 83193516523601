import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Typography from '@material-ui/core/Typography'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { NoteType } from 'models/note'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    name: {
      padding: 5,
      borderRadius: 3,
    },
  })
)

interface INoteTypeCheckBoxProps {
  noteType: NoteType
  checked?: boolean
  onChange?: (value: boolean) => void
}

function noteTypeToTitle(noteType: NoteType) {
  return {
    company: 'Company Notes',
    order: 'Order Notes',
    client: 'Client Notes',
    general: 'General Notes',
    // task: 'Task Notes',
    taxorder: 'Tax Order',
  }[noteType]
}
export const colorsMapping = (type: NoteType): string => {
  return {
    company: '#feddae',
    order: '#ffcb80',
    client: '#fdffdd',
    general: '#ddeeff',
    task: '#DDFFED',
    taxorder: '#b8b9e5ba',
  }[type]
}

function NoteTypeCheckBox({ checked, noteType, onChange }: INoteTypeCheckBoxProps) {
  const classes = useStyles()
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(event: unknown, checked: boolean) => onChange && onChange(checked)}
          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
          checkedIcon={<CheckBoxIcon fontSize="large" />}
          checked={checked}
          data-testid={`${noteType}-checkbox`}
        />
      }
      label={
        <Typography className={classes.name} style={{ backgroundColor: colorsMapping(noteType) }}>
          {noteTypeToTitle(noteType)}
        </Typography>
      }
    />
  )
}

export default NoteTypeCheckBox
