import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // height: '100%',
      width: '100%',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',

      '& .campany-detail': {
        fontSize: '1.6rem',
        fontFamily: theme.typography.h1.fontFamily,
        margin: 0,
      },
    },
    paper: {
      padding: '10px 0',
      margin: 0,
      height: 'calc(100% - 20px)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'calc(100% - 320px)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: '2rem',
      //   marginBottom: 15,
      '& .service-type': {
        textTransform: 'capitalize',
        fontSize: '1.5rem',
        background: '#DDEEFF',
      },
    },
    tabRoot: {
      fontSize: 20,
      minWidth: 0,
      textTransform: 'none',
      letterSpacing: 0.1,
      display: 'flex',
      margin: '0px 10px',

      '& .badge-text': {
        color: '#0000008a',
        fontSize: '1.25rem',
      },
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        right: 2,
      },
    },
    contentPanel: {
      // padding: '2rem 0 2rem 2rem',
      padding: '2rem',
      // padding: '2rem 0',
      // width: '100%',
      // height: '100%',
      backgroundColor: '#fafafb',
      borderTop: '1px solid #979797',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tabs: {
      display: 'flex',
      justifyContent: 'center',
    },
    ownersTable: {
      width: '100%',
      // width: '80%',
    },
    documentUploads: {
      // width: '80%',
      //   maxWidth: '1080px',
      width: '100%',
      margin: '2rem 0',
    },
    textField: {
      margin: '0.5rem 0',
      width: '100%',
    },
    schedulerLink: {
      width: '100%',
      margin: '0.5rem 0',
      height: '75%',
    },
    root: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '40px',
    },
    notesContainer: {
      minWidth: 470,
      position: 'sticky',
      height: '100vh',
      top: 0,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '-5px 3px 4px 0 rgba(0, 0, 0, 0.07)',
      marginLeft: '1rem',
      // this zIndex should be higher than zIndex of `stickyFooter` in components/orders/orderDetails/index.tsx
      // so that the current panel is over the footer of OrderEdit component
      zIndex: 1000,
    },
    notesTable: {
      flexGrow: 1,
    },
    orderRoot: {
      marginLeft: '1rem',
      paddingTop: '2rem',
      flexGrow: 1,
    },
    orderRootMain: {
      paddingRight: '2rem',
    },
    taxNotes: {
      float: 'right',
      position: 'absolute',
      right: 0,
      top: '4rem',
    },
    gridContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    einInput: {
      marginBottom: '0.5rem',
      width: '100%',
    },
    phoneField: {
      marginTop: '0.5rem',
      position: 'relative',
    },
    RegsiteredAgentText: {
      paddingLeft: 0,
      fontSize: 20,
      cursor: 'no-drop',
      margin: '6px 0',
    },
  })
)
