import { getCompanyDetails, updateCompanyById } from 'api/companies'
import { createAccountantSuggestions, createClientSuggestions, createCompanySuggestions } from 'api/suggestions'
import { createTaxOrder, getTaxOrderDetails, updateTaxOrder } from 'api/taxOrders'
import { ISuggestionAttribute } from 'components/interfaces'
import { IDetailedCompanyResponse } from 'models/company'
import { INewTaxOrderState, ITaxOrder, ITaxOrderStatus, ITaxOrderType, ITaxStructureBusiness } from 'models/taxOrder'
import moment from 'moment'
import * as React from 'react'
import { ValueType } from 'react-select/src/types'
import showApiResponseError from 'utils/showApiResponseError'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { InitialSearchParams, TaxOrderSearchParams } from './search'
import { TaxOrderContext } from './taxOrderContext'

const emptyTaxOrderState: INewTaxOrderState = {
  tax_order_type: 'business',
  parent_id: undefined,
  tax_structure_business: 'c_corp',
  tax_structure_personal: 'single',
  tax_year: moment().format('YYYY'),
  accountant_id: undefined,
  senior_cpa: undefined,
  tax_associate: undefined,
  tax_controller: undefined,
  service_type: 'tax',
}

const useTaxOrders = () => {
  const { reloadTable } = React.useContext(TaxOrderContext)

  const [searchOptions, setSearchOptions] = React.useState<TaxOrderSearchParams>(InitialSearchParams)
  const [isCreateModalOpen, setCreateModalOpen] = React.useState<boolean>(false)
  const [newTaxOrderState, setNewTaxOrderState] = React.useState<INewTaxOrderState>(emptyTaxOrderState)
  const [editableTaxOrder, setEditableTaxOrder] = React.useState<ITaxOrder | null>(null)
  const [isCreating, setIsCreating] = React.useState<boolean>(false)
  const [createdTaxOrder, setCreatedTaxOrder] = React.useState<ITaxOrder | null>(null)
  const [isTaxOrderDetailsModal, toggleTaxOrderDetailsModal] = React.useState<boolean>(false)
  const [isSendingRequest, setIsSendingRequest] = React.useState<boolean>(false)

  const [editableCompany, setEditableCompany] = React.useState<IDetailedCompanyResponse | null>(null)
  const [companyShouldBeUpdated, setCompanyShouldBeUpdated] = React.useState<boolean>(false)

  const [selectedValve, setSelectedValve] = React.useState<ISuggestionAttribute>()
  const [quesResolAccountants, setQuesResolAccountants] = React.useState<any>([])
  const [open, setOpen] = React.useState<boolean>(false)

  const handleAddFilter = React.useCallback(
    (field: string, select: ValueType<ISuggestionAttribute>) => {
      switch (field) {
        case 'order_statuses':
          setSearchOptions(prevState => ({
            ...prevState,
            [field]:
              select && Array.isArray(select)
                ? select.map((item: ISuggestionAttribute) => item.label as ITaxOrderStatus)
                : [],
          })) // collect strings representing enum values
          break
        case 'tax_year':
          // debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.label) : [],
          }))

          break
        case 'label':
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          }))
          break
        case 'accountant_user_id':
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          }))
          break
        case 'stage':
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          }))
          break
        case 'tax_structure':
          // debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select ? select.value : '',
          }))
          break
        case 'service_type':
          // debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select ? select.value : '',
          }))
          break
        case 'question':
          // debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select ? select.value : '',
          }))
          break
        case 'senior_cpa':
          debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select ? select.value : 0,
          }))
          break
        case 'tax_associate':
          debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select ? select.value : 0,
          }))
          break
        case 'tax_controller':
          debugger
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select ? select.value : 0,
          }))
          break
        default:
          showErrorNotification('An error occurred while filtering')
          console.warn(`Wrong filter key: ${field}    ${select}`)
      }
    },
    [setSearchOptions]
  )

  const handleSortingClick = React.useCallback(
    (sortBy: string) => {
      if (searchOptions['sort_by'] === sortBy) {
        setSearchOptions(prevState => ({
          ...prevState,
          sort_order: prevState['sort_order'] === 'desc' ? 'asc' : 'desc',
        }))
      } else {
        setSearchOptions(prevState => ({ ...prevState, sort_order: 'desc', sort_by: sortBy }))
      }
    },
    [searchOptions, setSearchOptions]
  )

  const handleSearch = React.useCallback(
    (query: string) => {
      setSearchOptions(prevState => ({ ...prevState, query }))
    },
    [setSearchOptions]
  )

  const handleFiledOnlyFilter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
      if (value) setSearchOptions(prevState => ({ ...prevState, is_filed: value }))
    },
    [setSearchOptions]
  )

  const handleTaxOrderStatusOnlyFilter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
      if (value) setSearchOptions(prevState => ({ ...prevState, tax_order_status: value }))
    },
    [setSearchOptions]
  )

  const handleCompanyChanges = React.useCallback(
    <K extends IDetailedCompanyResponse>(field: keyof K, value: K[keyof K]) => {
      if (editableCompany) {
        setEditableCompany(prevState =>
          prevState
            ? {
                ...prevState,
                [field]: value,
              }
            : prevState
        )
      }
    },
    [editableCompany, setEditableCompany]
  )

  const handleTaxOrderChanges = React.useCallback(
    <K extends ITaxOrder>(field: keyof K, value: K[keyof K]) => {
      if (editableTaxOrder) {
        if (typeof value === 'number' && value < 1) {
          // check for correct foreign keys
          console.error(`Invalid value for ${field}: ${value}`)
          return
        }
        setEditableTaxOrder(prevState =>
          prevState
            ? {
                ...prevState,
                [field]: value,
              }
            : prevState
        )
      }
    },
    [editableTaxOrder, setEditableTaxOrder]
  )

  const fetchTaxOrder = React.useCallback(
    async (taxOrderId: number) => {
      try {
        const taxOrder = await getTaxOrderDetails(taxOrderId)
        setEditableTaxOrder(taxOrder)
        setEditableCompany(taxOrder.company_details ? taxOrder.company_details : null)
        toggleTaxOrderDetailsModal(true)
      } catch (err) {
        showApiResponseError(err, 'Failed to get tax order details')
        setEditableTaxOrder(null)
      }
    },
    [toggleTaxOrderDetailsModal, setEditableTaxOrder, setEditableCompany]
  )

  const closeDetailsScreen = React.useCallback(() => {
    toggleTaxOrderDetailsModal(false)
    setEditableTaxOrder(null)
    setEditableCompany(null)
  }, [toggleTaxOrderDetailsModal, setEditableTaxOrder, setEditableCompany])

  const handleOrderTypeFilter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: ITaxOrderType | string) => {
      if (value) setSearchOptions(prevState => ({ ...prevState, tax_order_type: value }))
    },
    [setSearchOptions]
  )

  const handleTaxOrderUpdate = React.useCallback(async () => {
    try {
      if (editableTaxOrder) {
        setOpen(false)
        setIsSendingRequest(true)
        const updatedOrder = await updateTaxOrder({
          ...editableTaxOrder,
          company_details: editableCompany ? editableCompany : editableTaxOrder.company_details,
          tax_associate: editableTaxOrder?.tax_associate?.id
            ? editableTaxOrder?.tax_associate?.id
            : editableTaxOrder?.tax_associate,
          tax_controller: editableTaxOrder?.tax_controller?.id
            ? editableTaxOrder?.tax_controller?.id
            : editableTaxOrder?.tax_controller,
        })
        if (updatedOrder.error) {
          showErrorNotification(updatedOrder.error)
          return
        }
        if (companyShouldBeUpdated && editableCompany) {
          const params = Object.keys(editableCompany).reduce(
            (result, key) =>
              editableTaxOrder &&
              editableTaxOrder.company_details &&
              editableCompany[key] !== editableTaxOrder.company_details[key]
                ? { ...result, [key]: editableCompany[key] }
                : { ...result },
            {}
          )
          updateCompanyById(editableCompany.id, params)
          setCompanyShouldBeUpdated(false)
          showSuccessNotification('Company successfully updated')
        }
        setIsSendingRequest(false)

        showSuccessNotification('Tax order successfully updated')
        setQuesResolAccountants([])
      }
    } catch (err) {
      showApiResponseError(err, 'Failed to update tax order')
      setIsSendingRequest(false)
      setOpen(false)
    }
  }, [editableTaxOrder, setIsSendingRequest, editableCompany, companyShouldBeUpdated])

  const toggleCreateTaxOrderModal = React.useCallback(
    (isOpen: boolean) => {
      setCreateModalOpen(isOpen)
      setNewTaxOrderState(emptyTaxOrderState)

      setSelectedValve(undefined)
    },
    [setCreateModalOpen, setNewTaxOrderState]
  )

  const saveTaxOrder = React.useCallback(async () => {
    try {
      setIsCreating(true)
      const createdTaxOrder = await createTaxOrder(newTaxOrderState)
      if (createdTaxOrder.error) {
        showErrorNotification(createdTaxOrder.error)
        setIsCreating(false)
        return
      }
      setCreatedTaxOrder(createdTaxOrder)
      showSuccessNotification('Tax Order was successfully created')
      setCreateModalOpen(false)
      setIsCreating(false)
      setSelectedValve(undefined)

      if (reloadTable) reloadTable()
    } catch (err) {
      showApiResponseError(err, 'Failed to create a tax order')
      setIsCreating(false)
    }
  }, [newTaxOrderState, reloadTable])

  const addForeignKeyAttribute = React.useCallback(
    (field: 'parent_id' | 'accountant_id' | 'senior_cpa' | 'tax_associate' | 'tax_controller', id: number | null) => {
      setNewTaxOrderState(prevState => ({
        ...prevState,
        [field]: id || undefined,
      }))
    },
    [setNewTaxOrderState]
  )

  const fetchBusinessTaxStructure = React.useCallback(
    async (companyId?: number) => {
      // check if company id is changed to define correct tax structure
      if (newTaxOrderState.tax_order_type === 'business' && companyId) {
        const company = await getCompanyDetails(companyId)
        let structure: ITaxStructureBusiness = 'c_corp'
        switch (company.legal_type.toString()) {
          case 'c_corp':
            structure = 'c_corp'
            break
          case 'dba':
            structure = 'sole'
            break
          case 'llc':
            structure = 'mmllc'
            break
          case 'non_profit':
            structure = 'non_profit'
            break
          default:
            console.error('Unable to get company legal type')
        }
        if (structure) setNewTaxOrderState(prevState => ({ ...prevState, tax_structure_business: structure }))
      }
    },
    [newTaxOrderState.tax_order_type]
  )

  const fetchSuggestions = React.useCallback(
    async (query: string, field: string): Promise<ISuggestionAttribute[]> => {
      switch (field) {
        case 'parent_id':
          if (newTaxOrderState.tax_order_type === 'personal') return createClientSuggestions(query)
          else if (newTaxOrderState.tax_order_type === 'business') return createCompanySuggestions(query)
          else return []
        case 'accountant_id':
          return createAccountantSuggestions(query)
        case 'senior_cpa':
          return createAccountantSuggestions(query)
        case 'tax_associate':
          return createAccountantSuggestions(query)
        case 'tax_controller':
          return createAccountantSuggestions(query)
        default:
          showErrorNotification(`Failed to get options`)
          console.error(`Incorrect object type`)
          return []
      }
    },
    [newTaxOrderState]
  )

  return {
    searchOptions,
    setSearchOptions,
    handleAddFilter,
    handleSortingClick,
    handleSearch,
    handleFiledOnlyFilter,
    handleOrderTypeFilter,
    isCreateModalOpen,
    toggleCreateTaxOrderModal,
    newTaxOrderState,
    isCreating,
    saveTaxOrder,
    createdTaxOrder,
    addForeignKeyAttribute,
    fetchSuggestions,
    setNewTaxOrderState,
    fetchBusinessTaxStructure,
    isTaxOrderDetailsModal,
    toggleTaxOrderDetailsModal,
    fetchTaxOrder,
    closeDetailsScreen,
    editableTaxOrder,
    handleTaxOrderUpdate,
    isSendingRequest,
    editableCompany,
    companyShouldBeUpdated,
    setCompanyShouldBeUpdated,
    handleCompanyChanges,
    handleTaxOrderChanges,
    handleTaxOrderStatusOnlyFilter,

    selectedValve,
    setSelectedValve,
    open,
    setOpen,
    setQuesResolAccountants,
    quesResolAccountants,
  }
}

export default useTaxOrders
