import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
// import BRLogoTransparent from 'img/br_logo_transparent.png'
import LogoSrc from 'img/logo.svg'
import * as React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const styles = (theme: Theme) =>
  createStyles({
    logoContainer: {
      // width: 450,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '2rem 0',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    image: {
      // width: '100%',
      width: '250px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
  })

interface ILogoProps extends WithStyles<typeof styles> {}

const Logo: React.FC<ILogoProps> = ({ classes }) => {
  return (
    <>
      <div className={classes.logoContainer}>
        <img alt="logo" src={LogoSrc} className={classes.image} />
      </div>
    </>
  )
}

export default withStyles(styles)(Logo)
