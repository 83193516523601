import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

interface ICompanyOrderTypeFieldProps {
  handleSelectingOrderType: (option: ISuggestionAttribute | null) => void
  fetchSuggestions: (query: string, field: string) => Promise<ISuggestionAttribute[]>
  orderTypeSuggestion: ISuggestionAttribute | null
  disabled: boolean
}

/**
 * We may wanna switch the company order type when creating a new order.
 *
 * @param fetchSuggestions: Get order type options for the select dropdown.
 * @param orderTypeSuggestion: Current value in the select field.
 * @param handleSelectingOrderType: Callback triggered upon selecting an option from the dropdown.
 */
const CompanyOrderTypeField = ({
  handleSelectingOrderType,
  orderTypeSuggestion,
  fetchSuggestions,
  disabled,
}: ICompanyOrderTypeFieldProps) => {
  return (
    <SelectField
      onOptionSelected={handleSelectingOrderType}
      title="Order Type"
      fetchSuggestions={fetchSuggestions}
      field="order_type_id"
      placeholder="Select Order Type"
      required={true}
      value={orderTypeSuggestion}
      dataTestId={TestID.ORDER_TYPE_SELECT}
      disabled={disabled}
    />
  )
}

export default CompanyOrderTypeField
