import { apiClient } from '@jetkit/react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { IAddress } from 'models/address'
import { IClient } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'

interface IResetPasswordReq {
  username?: string
  email?: string
  portal_setup?: boolean
}

interface IClientSignup {
  first_name: string
  middle_name: string
  last_name: string
  date_of_birth: string
  email: string
  phone: string
  username: string
  password: string
  smsConsend?: boolean
}

interface IClientForgot {
  first_name: string
  middle_name: string
  last_name: string
  email: string
  phone: string
  companyName: string
}

export const fetchUsers = async (query = ''): Promise<IClient[]> => {
  // TODO: double-check the user interface

  const res = await apiClient.get(`user`, { params: { user_type: 'staff', query: query } })
  return res.data
}

export const getUserAddresses = async (userId: number): Promise<IAddress[]> =>
  (await apiClient.get(`user/${userId}/address`)).data

export const deleteUser = async (id: number): Promise<AxiosResponse<void>> => apiClient.delete(`clients/${id}`)

export const deleteEmployee = async (id: number): Promise<AxiosResponse<void>> => apiClient.delete(`employees/${id}`)

export const deleteClient = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`client/${id}`)
}
export const deleteSalesRep = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`salesrepresentatives/${id}`)
}

export const deleteState = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`states/${id}`)
}
export const deleteStateProcessing = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`state-processing-configs/${id}`)
}
export const createPassword = async (client: any): Promise<any> => {
  return (await apiClient.post('user/password/create', snakeCaseKeys(client))).data
}
export const checkUsername = async (data: any): Promise<any> => {
  return (await apiClient.post('checkUsername', snakeCaseKeys(data))).data
}
export const checkRefcode = async (data: any): Promise<any> => {
  return (await apiClient.post('check-refcode', snakeCaseKeys(data))).data
}
export const resetPassword = async (client: IResetPasswordReq): Promise<any> => {
  return (await apiClient.post('user/password/reset', snakeCaseKeys(client))).data
}

export const sendVerificationCode = async (req: any): Promise<any> => {
  return (await apiClient.post('send-verification-code', snakeCaseKeys(req))).data
}
export const submitVerificationCode = async (req: any): Promise<any> => {
  return (await apiClient.post('submit-verification-code', snakeCaseKeys(req))).data
}
export const getuser = async (client: any): Promise<any> => {
  return (await apiClient.post('getuser', snakeCaseKeys(client))).data
}
export const logoutUser = async (page_visits: number, user_clicks: number): Promise<any> => {
  return (await apiClient.get(`/logout?page_visits=${page_visits}&user_clicks=${user_clicks}`)).data
}

export const updateProfile = async (payload: any): Promise<any> => {
  return (await apiClient.post('/client/profileUpdate', payload)).data
}

export const updateEmpProfile = async (payload: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000'
  return (await apiClient.post(`employee/${payload?.id}`, payload)).data
}

export const getUserImage = async (): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000/'
  return (await apiClient.get(`/client/getImage`)).data //.progress
}

export const uploadClientImg = async (file: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000/'
  const imgUrl = (await apiClient.post('/client/uploadImage', {})).data
  // return await axios({
  //   url: imgUrl.url,
  //   method: 'PUT',
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  //   data: file,
  // })
  return await fetch(imgUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data', //'multipart/form-data',
    },
    body: file,
  })
}
export const updateUserLog = async (page_visits: number, user_clicks: number): Promise<any> => {
  return (await apiClient.get(`/userlog?page_visits=${page_visits}&user_clicks=${user_clicks}`)).data
}

export const registerUser = async (data: IClientSignup): Promise<any> => {
  return (await apiClient.post('/user/register', snakeCaseKeys(data))).data
}

export const forgotUser = async (data: IClientForgot): Promise<any> => {
  return (await apiClient.post('/user/forgotUsername', data)).data
}

export const getLoginUser = async (userId: string, token: string): Promise<any> => {
  console.log(' apiClient.defaults.baseURL', apiClient.defaults.baseURL)
  debugger
  const responseData = (
    await axios({
      url: `${apiClient.defaults.baseURL}authenticate/user/${userId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data
  debugger
  return responseData
}

export const verifyUserEmail = async (data: { email: string }): Promise<any> => {
  return (await apiClient.post('/user/verify', snakeCaseKeys(data))).data
}
