import { IAddress } from 'models/address'
import { ITask } from 'models/task'
import { IClient, ICompanyOwner, IUser } from 'models/user'
import moment from 'moment'
import { ISalesRep } from './salesRep'

export interface ICompanyMeetingApiResponse {
  address: IAddress
  date: string
  id: number
  meeting_type: MeetingType
}

export interface IPatchMeeting {
  address_id?: number
  date?: string
}

export interface ICompanyMeeting {
  date: moment.Moment | string | null
  meeting_type: MeetingType
  address_id?: number | null
}

export type MeetingType = 'directors' | 'shareholders' | 'formation'

export interface ICreateCompanyParams {
  client_id: number
  ein?: string
  file_date?: string
  country_of_formation?: string
  state_of_formation: string
  name: string
  legal_type: legalTypesToApiFormat
  owner_id?: number
  number_of_shares?: number
  incorporator: string
  business_activity: string
  tax_structure?: string
}

export interface IUpdateCompanyParams {
  client_id?: number
  ein?: string
  state_id?: string
  file_date?: string
  country_of_formation?: string
  state_of_formation?: string
  name?: string
  legal_type?: legalTypesToApiFormat
  owner_id?: number
  number_of_shares?: number
  business_activity?: string
  incorporator?: string
  meeting_date?: string
  primary_address?: IAddress | null
  mailing_address?: IAddress | null
  directors_meeting?: any
  shareholders_meeting?: any
  directors_meeting_address?: any
  shareholder_meeting_address?: any
  tax_structure?: string

  client_meeting_date_time?: string
  shareholders_meeting_date_time?: string
}
export interface ICreatedCompany {
  business_activity: null | string
  client: IClient
  country_of_formation: string
  description: null | string
  director_meetings: [] //TODO: specify a director_meeting type
  ein: string
  id: number
  incorporator: string | null
  legal_type: ILegalType
  name: string
  number_of_shares: null | number
  owner: IClient
  registration_date: Date
  sales_reps: IUser[] // TODO: change this to sales_rep type once we have it
  shareholder_meetings: [] // TODO: change this to shareholder meeting type once we have it
  state_of_formation: string | null
  tasks: ITask[]
  team: IClient[]
  white_label: boolean
  tax_structure?: string
  addresses?: IAddress[]
}

interface IPrimaryAddress {}

export interface IDetailedCompanyResponse {
  business_activity: string | null
  client: IClient
  company_users: ICompanyOwner[]
  country_of_formation: string | null
  description: string | null
  ein: string | null
  file_date: string | null
  id: number
  incorporator: string | null
  legal_type: ILegalType
  name: string
  number_of_shares: number
  owner: IClient
  registration_date: string | null
  state_of_formation?: string
  white_label: boolean
  primary_address: IAddress | null
  primary_contact?: IClient[]
  mailing_address: IAddress | null
  addresses: IAddress[]
  state_tax_id: string
  meeting_date?: string
  state_id: string
  shareholder_meeting: ICompanyMeetingApiResponse
  director_meeting: ICompanyMeetingApiResponse
  profile_score?: number
  company_type?: number
  state_of_formation_id?: number
  tax_structure: string
  business_active?: boolean
  salesRep: ISalesRep
  experian_business_id?: number
  experian_credit_score?: number
  business_active_status?: string
  expected_ein_date?: string
  expected_file_date?: string
}
export interface ICompany {
  file_date?: any
  state_of_formation?: string
  address: IAddress
  description: string
  owner?: any //IUser
  id: number
  legal_type: ILegalType
  name: string
  registration_date: Date
  sales_rep_id: null | number
  creation_time?: any
  parent_company?: number
  business_active?: boolean
  profile_score?: number
  business_active_status?: string
  expected_ein_date?: string
  expected_file_date?: string
}

export interface ICompanyRole {
  readonly id: number
  title: string
  can_hold_shares: boolean
}

// TODO: #334 leave only 1 enum for Legal Types
export enum legalTypes {
  LLC = 'LLC',
  C_CORP = 'Corporation',
  NON_PROFIT = 'Non-Profit Organization',
  DBA = 'DBA',
}

export enum legalTypesToHumanReadableFormat {
  llc = 'LLC',
  c_corp = 'Corporation',
  non_profit = 'Non-Profit Organization',
  dba = 'DBA',
  sole_proprietorship = 'Sole Proprietorship',
  partnership = 'Partnership',
}

/*
    llc = "LLC"
    c_corp = "C Corp"
    s_corp = "S Corp"
    non_profit = "Non-Profit"
    dba = "DBA"
*/

export enum legalTypesToApiFormat {
  'LLC' = 'llc',
  'Corporation' = 'c_corp',
  'Non-Profit Organization' = 'non_profit',
  'DBA' = 'dba',
  'Sole Proprietorship' = 'sole_proprietorship',
  'Partnership' = 'partnership',
}

export const legalTypesList: ILegalType[] = [
  'LLC',
  'Corporation',
  'Non-Profit Organization',
  'DBA',
  'Sole Proprietorship',
  'Partnership',
]

export const stateOfFormation = ''

export type ILegalType =
  | 'LLC'
  | 'Corporation'
  | 'Non-Profit Organization'
  | 'DBA'
  | 'Sole Proprietorship'
  | 'Partnership'

export interface ChangeLogs {
  id: number
  company_id: number
  changed_by: string
  changed_at: string
  field_name: string
  old_value: string
  new_value: string
}
