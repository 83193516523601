import * as React from 'react'

interface IUseHandleDialogAccessibility {
  saveSideEffect?: () => void
  openSideEffect?: () => void
}

/**
 * Encapsulates logic for closing a dialog window with either mutations or lack thereof as a consequence.
 *
 *
 * @param saveSideEffect
 * Function invoked as a side-effect of closing the dialog with a mutation i.e. `handleSave`.
 * E.g. re-render another component after closing the dialog
 * @param openSideEffect
 * Function invoked as a side-effect of opening the dialog i.e. in `handleOpen`
 *
 * @example
 * You have a table and a modal window to add new items to that table.
 * When closing the window by adding a new item you'd invoke `handleSave`. And re-render the table.
 * When closing the window by canceling new item creation you'd invoke `handleClose`. Without consequences for the table.
 */
export const useHandleDialogAccessibility = ({ saveSideEffect, openSideEffect }: IUseHandleDialogAccessibility) => {
  const [isOpen, setIsOpen] = React.useState(false)

  /**
   * Used to open a dialog window.
   */
  const handleOpen = React.useCallback(() => {
    setIsOpen(true)
    if (openSideEffect) openSideEffect()
  }, [openSideEffect])

  /**
   * Used when closing the dialog with object mutations as a consequence.
   */
  const handleSave = React.useCallback(() => {
    setIsOpen(false)
    if (saveSideEffect) saveSideEffect()
  }, [saveSideEffect])

  /**
   * Used when closing a dialog without mutations as a consequence.
   */
  const handleClose = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  return {
    isOpen,
    handleOpen,
    handleSave,
    handleClose,
  }
}
