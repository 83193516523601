import { handleError, PagedTable, usePagedTable } from '@jetkit/react'
import { Grid, Paper, Table, TableBody, TableContainer } from '@material-ui/core'
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { paginateStates } from 'api/documentTypes'
//import { deleteUser } from 'api/users'

import CreateClient from 'components/documentTypes/create'
import ClientTableHead from 'components/documentTypes/table/head'
import ClientTableRow from 'components/documentTypes/table/row'
import SearchBar from 'components/common/searchBar'
import { skeletons, StyledTable } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import { IClient, IStates } from 'models/user'
import * as React from 'react'
import { theme } from 'theme'
import useRouter from 'use-react-router'
import { deleteTaxInvoice, IInvoice, paginateInvoices, updateInvoice, updateInvoiceStatus } from 'api/taxOrders'
import InvoiceTableRow from 'components/taxOrders/details/invoices/row'
import InvoiceTableHead from 'components/taxOrders/details/invoices/invoiceTableHead'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { INVOICE_PAYMENT_STATUS } from 'utils/constant'
import { updatePageVisitsCount } from 'utils/user'
import DatePicker from 'components/common/datePicker'
import { datePickerDatePlaceholder } from 'utils/formatDate'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import { Clear } from '@material-ui/icons'
import useSortTable from 'hooks/useSortTable'
import InvoiceDialog from 'components/taxOrders/details/invoices/addInvoiceDialog'
import { QuestionDialogType } from 'components/taxOrders/details/pendingQuestions/questionDialog'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.title.fontFamily,
    fontWeight: 600,
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
})

interface IInvoiceProps extends WithStyles<typeof styles> {}

const AllInvoices: React.FC<IInvoiceProps> = ({ classes }) => {
  const [query, setQuery] = React.useState('')
  const [clientToDelete, setClientToDelete] = React.useState<IInvoice>()
  const [deleteClientDialogShown, setDeleteClientDialogShown] = React.useState(false)
  const router = useRouter()
  const [loading, setLoading] = React.useState(false)

  const [invoiceNo, setInvoiceNo] = React.useState<string>('')
  const [issueDate, setIssueDate] = React.useState<string>('')
  const [amount, setAmount] = React.useState<number>(0)
  const [paymentLink, setPaymentLink] = React.useState<string>('')
  const [invoiceId, setInvoiceId] = React.useState(0)
  const [questionDialogType, setQuestionDialogType] = React.useState<QuestionDialogType>('add')
  const [addQuestionDialogShown, setAddQuestionDialogShown] = React.useState(false)
  const [waitingForResponse, setWaitingForResponse] = React.useState(false)

  const [searchPaymentStatus, setSearchPaymentStatus] = React.useState(INVOICE_PAYMENT_STATUS.Unpaid)
  const [dateFiltr, setDateFiltr] = React.useState<string | undefined>('')
  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'id' })

  const tableHead = React.useMemo(
    () => <InvoiceTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )
  const classname = useStyles()

  const apiCall = React.useMemo(() => paginateInvoices(searchPaymentStatus, sortSettings, dateFiltr)({ query }), [
    query,
    searchPaymentStatus,
    dateFiltr,
    sortSettings,
  ])
  const pagedTable = usePagedTable<IInvoice>({ apiCall })

  // const onEditClick = React.useCallback(
  //   (invoice: IInvoice) => {
  //     if (!invoice.id) return
  //     router.history.push(`/tax-orders/${invoice.taxorderid}`)
  //   },
  //   [router.history]
  // )

  const onEditClick = React.useCallback((invoice: IInvoice) => {
    console.log('invoiceinvoiceinvoiceinvoice', invoice)

    setQuestionDialogType('edit')
    setAddQuestionDialogShown(true)

    setInvoiceId(invoice?.id || 0)

    setInvoiceNo(invoice?.invoice_no)
    setIssueDate(invoice?.issue_date)
    setAmount(invoice?.amount)
    setPaymentLink(invoice?.payment_link || '')

    return
  }, [])

  const onDeleteClick = React.useCallback(
    (invoice: IInvoice) => {
      if (!invoice.id) return
      setClientToDelete(invoice)
      setDeleteClientDialogShown(true)
    },
    [setClientToDelete, setDeleteClientDialogShown]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const updateStatus = async (taxid, paid, invoiceId) => {
    setLoading(true)
    await updateInvoiceStatus(taxid, paid, invoiceId)
    setLoading(false)
    pagedTable.reloadData()
  }
  const onDateFilterChange = React.useCallback((date: any | null) => {
    setDateFiltr(moment(date).format('MM/DD/YYYY'))
  }, [])

  const handleQuestionDialogClosed = React.useCallback(() => {
    setAddQuestionDialogShown(false)
    setQuestionDialogType('add')
    setInvoiceId(0)
  }, [])

  const handleSaveClicked = React.useCallback(
    async (dialogType: QuestionDialogType) => {
      const data: IInvoice = {
        invoice_no: invoiceNo,
        amount: amount,
        issue_date: issueDate,
        payment_link: paymentLink,
      }
      setWaitingForResponse(true)
      try {
        if (dialogType === 'edit') await updateInvoice(invoiceId, data)
        setAddQuestionDialogShown(false)
        setWaitingForResponse(false)
        setPaymentLink('')
        setAmount(0)
        setIssueDate('')
        setInvoiceNo('')
        setQuestionDialogType('add')
        setInvoiceId(0)
        pagedTable.reloadData()
      } catch (error) {
        alert(error)
        handleError(error)
      }
    },
    [amount, invoiceId, invoiceNo, issueDate, pagedTable, paymentLink]
  )

  console.log('invoiceIdinvoiceIdinvoiceId', invoiceId)

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteClientDialogShown}
        close={() => setDeleteClientDialogShown(false)}
        deletionObject={clientToDelete}
        deletionRequest={deleteTaxInvoice}
        onRequestSuccessful={onDeleteSuccessful}
      />
      {/* <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}></Typography>
        <CreateClient fetchClients={pagedTable.reloadData} />
      </div> */}

      {/* <div className={classname.mainFiltersContainer}>
        <div className={classname.innerSwitchButtonsContainer}>
          <Typography className={classname.heading}>Tax Invoice</Typography>
          <div className={classname.filterWithTitle}>
            <Typography className={classname.filterTitle}>Payment Status</Typography>
            <ToggleButtonGroup
              className={classname.toggleButtonGroup}
              onChange={(e, val) => setSearchPaymentStatus(val)}
              exclusive
              value={searchPaymentStatus}
            >
              <ToggleButton value={INVOICE_PAYMENT_STATUS.Paid} className={classname.toggleButton}>
                <Typography className={classname.isFileButtonText}>Paid</Typography>
              </ToggleButton>
              <ToggleButton value={INVOICE_PAYMENT_STATUS.Unpaid} className={classname.toggleButton}>
                <Typography className={classname.isFileButtonText}>Unpaid</Typography>
              </ToggleButton>
              <ToggleButton value={INVOICE_PAYMENT_STATUS.All} className={classname.toggleButton}>
                <Typography className={classname.isFileButtonText}>All</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <DatePicker
          label="Issue Date"
          style={{ width: '93%', marginLeft: '1rem' }}
          onDateSelected={onDateFilterChange}
          value={dateFiltr && moment(dateFiltr)}
          placeholder={datePickerDatePlaceholder}
          dataTestId="due-date"
          InputProps={{
            startAdornment: dateFiltr ? (
              <IconButton onClick={() => setDateFiltr('')}>
                <Clear />
              </IconButton>
            ) : (
              ''
            ),
          }}
          inputVariant={'outlined'}
        />
      </div> */}
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography className={classname.heading}>Tax Invoice</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item md={6} xs={12}>
              <div className={classname.filterWithTitle}>
                <Typography className={classname.filterTitle}>Payment Status</Typography>
                <ToggleButtonGroup
                  className={classname.toggleButtonGroup}
                  onChange={(e, val) => setSearchPaymentStatus(val)}
                  exclusive
                  value={searchPaymentStatus}
                >
                  <ToggleButton value={INVOICE_PAYMENT_STATUS.Paid} className={classname.toggleButton}>
                    <Typography className={classname.isFileButtonText}>Paid</Typography>
                  </ToggleButton>
                  <ToggleButton value={INVOICE_PAYMENT_STATUS.Unpaid} className={classname.toggleButton}>
                    <Typography className={classname.isFileButtonText}>Unpaid</Typography>
                  </ToggleButton>
                  <ToggleButton value={INVOICE_PAYMENT_STATUS.All} className={classname.toggleButton}>
                    <Typography className={classname.isFileButtonText}>All</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <DatePicker
                label="Issue Date"
                style={{ width: '100%' }}
                onDateSelected={onDateFilterChange}
                value={dateFiltr && moment(dateFiltr)}
                placeholder={datePickerDatePlaceholder}
                dataTestId="due-date"
                InputProps={{
                  startAdornment: dateFiltr ? (
                    <IconButton onClick={() => setDateFiltr('')}>
                      <Clear />
                    </IconButton>
                  ) : (
                    ''
                  ),
                }}
                inputVariant={'outlined'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.searchBarContainer}>
        <SearchBar onChange={setQuery} placeholder="Search by Invoice No and Filer Name" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />

        {pagedTable.isLoading || loading ? (
          <TableContainer component={Paper}>
            <Table>
              {tableHead}
              <TableBody>
                {skeletons({
                  numberOfColumns: 9,
                  sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100],
                  numberOfRows: 9,
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          // <Table>
          //   {tableHead}
          //   {skeletons({ numberOfColumns: 9, numberOfRows: 9, sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100] })}
          // </Table>
          <TableContainer component={Paper}>
            <PagedTable
              emptyRowText="No Invoices"
              {...pagedTable.renderProps}
              renderRow={(invoice: IInvoice) => (
                <InvoiceTableRow
                  invoice={invoice}
                  updateStatus={updateStatus}
                  loading={loading}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                />
              )}
              header={tableHead}
              colSpan={9}
            />
          </TableContainer>
        )}
      </div>

      <InvoiceDialog
        dialogType={questionDialogType}
        shown={addQuestionDialogShown}
        onClose={handleQuestionDialogClosed}
        loading={waitingForResponse}
        onSaveClick={handleSaveClicked}
        setAmount={setAmount}
        setInvoiceNo={setInvoiceNo}
        setIssueDate={setIssueDate}
        amount={amount}
        invoiceNo={invoiceNo}
        issueDate={issueDate}
        paymentLink={paymentLink}
        setPaymentLink={setPaymentLink}
      />
    </div>
  )
}

export default withStyles(styles)(AllInvoices)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: theme.customPalette.tableBackgroundColor,
      paddingBottom: 300,
    },
    innerContainer: {
      display: 'flex',
      maxWidth: '79.5rem',
      width: '100%',
      flexDirection: 'column',
    },
    searchBarContainer: {
      //   marginTop: '2rem',
      display: 'flex',
      width: '100%',
    },
    mainFiltersContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1rem',
    },

    heading: {
      fontSize: '2rem',
      fontFamily: 'Times New Roman',
      fontWeight: 600,
    },
    filterTitle: {
      fontSize: 14,
    },

    checkBoxRoot: {
      padding: 0,
    },
    toggleButton: {
      height: '100%',
      width: '100%',
    },
    isFileButtonText: {
      fontSize: 12,
      color: 'grey',
    },
    innerMultiSelectContainer: {
      display: 'flex',
      width: '100%',
    },
    innerSwitchButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    filterWithTitle: {
      display: 'flex',
      justifyContent: 'end',
      flexDirection: 'column',
    },
    toggleButtonGroup: {
      // height: 38,
      paddingTop: '0.125rem',
      marginBottom: '8px',
      // justifyContent: 'end',
    },
  })
)
