import { Grid, InputLabel, MenuItem, TextField, Select, FormControl } from '@material-ui/core'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { IAddress } from 'models/address'
import React, { useEffect, useState } from 'react'
import InputField from '../inputField'
import ModalWrapper from '../modalWrapper'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import { Alert, Autocomplete } from '@material-ui/lab'
import { createProspectItem, getIndustryType, getProspectStatuses, IndustryProspectData } from 'api/leadManager'
import showErrorNotification from 'utils/showErrorNotification'
import { useStyles } from '../styles'
import { ISuggestionAttribute } from 'components/interfaces'
import { fetchAssigneeSuggestions, fetchSuggestions } from 'api/suggestions'
import showSuccessNotification from 'utils/showSuccessNotification'
import PhoneInput from 'components/clients/create/phoneInput'
import Dropdown from '../editProspectById/components/Dropdown'
import { IClient } from 'models/user'
import SelectField from 'components/common/select'

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

interface AddProspectModalProps {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  setIsUpdated: any
  client?: IClient
}
const initialProspect = {
  firstName: '',
  middleName: '',
  lastName: '',
  title: '',
  phone: '',
  email: '',
  companyName: '',
  POC: '',
  submissionId: '',
  invoicePaymentLink: '',
  note: '',
}
const AddProspectModal = ({ open, setOpen, setIsUpdated, client }: AddProspectModalProps) => {
  const classes = useStyles()
  initialProspect.firstName = client?.name || ''
  initialProspect.middleName = client?.middle_name || ''
  initialProspect.lastName = client?.last_name || ''
  initialProspect.phone = client?.phone_number || ''
  initialProspect.email = client?.email || ''
  emptyAddress.city = (client && client.addresses && client?.addresses[0]?.city) || ''
  emptyAddress.street = (client && client.addresses && client?.addresses[0]?.street) || ''
  emptyAddress.locality = (client && client.addresses && client?.addresses[0]?.locality) || ''
  emptyAddress.postal_code = (client && client.addresses && client?.addresses[0]?.postal_code) || ''

  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [prospectAddress, setProspectAddress] = React.useState<IAddress[]>([emptyAddress])

  const [salesRep, setSalesRep] = React.useState<ISuggestionAttribute | null>()
  const [clientObj, setClientObj] = React.useState<ISuggestionAttribute | null>()

  const [isLoading, setIsLoading] = useState(false)

  const [isError, setIsError] = useState(false)

  const [prospectData, setProspectData] = useState(initialProspect)

  const [industryType, setIndustryType] = useState<any>()
  const [pocValue, setPocValue] = useState<any>()
  const [senderOptions, setSenderOptions] = useState<ISuggestionAttribute[]>([])
  const [prospectStatusList, setProspectStatusList] = React.useState<IndustryProspectData[]>([])
  const [statusValue, setStatusValue] = React.useState<number>(0)

  const {
    firstName,
    middleName,
    lastName,
    title,
    phone,
    email,
    companyName,
    submissionId,
    invoicePaymentLink,
    note,
  } = prospectData

  const findSelectedAssigne = async () => {
    const data = await fetchAssigneeSuggestions('')
    // const selected = data?.find(item => item?.value === reminderData?.senderName)
    setSenderOptions(data)
    // setSelectedValve(selected)
  }

  useEffect(() => {
    if (
      firstName ||
      middleName ||
      lastName ||
      title ||
      phone ||
      email ||
      companyName ||
      submissionId ||
      pocValue ||
      industryType ||
      prospectAddress.length ||
      invoicePaymentLink
    ) {
      return setIsError(false)
    }
  }, [
    companyName,
    email,
    firstName,
    industryType,
    lastName,
    phone,
    pocValue,
    prospectAddress.length,
    submissionId,
    title,
    middleName,
    invoicePaymentLink,
  ])

  const getProspectsList = async () => {
    try {
      const res = await getProspectStatuses()
      setProspectStatusList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    findSelectedAssigne()
    getProspectsList()
  }, [])

  const toggleAddAddressDialog = React.useCallback(() => {
    setCurrentEditedAddress(emptyAddress)
    setShowAddAddressDialog(!showAddAddressDialog)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
        city: currentEditedAddress?.cityObj?.label || '',
        city_id: currentEditedAddress?.cityObj?.value || 0,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )
  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    const indexToUpdate = prospectAddress && prospectAddress.findIndex(address => address.id === editedAddressId)
    //
    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(prospectAddress || [])]
    //

    // const newCurrentEditedAddress = {
    //   ...currentEditedAddress,
    //   city: currentEditedAddress?.cityObj?.label,
    //   city_id: currentEditedAddress?.cityObj?.value,
    // }
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setProspectAddress(addressesWithUpdatedAddress)
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    setCurrentEditedAddress(emptyAddress)
  }, [editedAddressId, prospectAddress, currentEditedAddress, addressesToEdit])

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((prospectAddress && prospectAddress.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [prospectAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setProspectAddress(prospectAddress.filter(address => address.id !== id))
    },
    [prospectAddress]
  )

  const handleAddAddress = React.useCallback(() => {
    //

    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      const newAddresses: IAddress[] = []
      // console.log('currentEditedAddress', currentEditedAddress)
      // const newCurrentEditedAddress = {
      //   ...currentEditedAddress,
      //   city: currentEditedAddress?.cityObj?.label,
      //   city_id: currentEditedAddress?.cityObj?.value,
      // }
      //
      newAddresses.push(currentEditedAddress)
      console.log('newAddresses', newAddresses)

      setProspectAddress(newAddresses)
      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd])
      toggleAddAddressDialog()
    }
    return true
  }, [addressesToAdd, currentEditedAddress, editedAddressId, toggleAddAddressDialog, updateAddress])

  const [industryTypesList, setIndustryTypesList] = useState<IndustryProspectData[]>([])

  const getIndustriesList = async () => {
    try {
      const res = await getIndustryType()
      setIndustryTypesList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }
  useEffect(() => {
    getIndustriesList()
  }, [])

  const handleSave = async () => {
    if (
      !firstName ||
      !middleName ||
      !lastName ||
      !title ||
      !phone ||
      !email ||
      !companyName ||
      !submissionId ||
      !pocValue ||
      !industryType ||
      !prospectAddress.length ||
      !invoicePaymentLink
    ) {
      // return setIsError(true)
    }

    setIsError(false)

    setIsLoading(true)
    const payload = {
      addressData: prospectAddress,
      industry_type: industryType?.id,
      poc: pocValue,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      company_name: companyName,
      phone: phone,
      email: email,
      title: title,
      submission_id: submissionId,
      prospect_status: statusValue,
      invoice_payment_link: invoicePaymentLink,
      client_id: client?.id || clientObj?.value,
      sales_representative_id: salesRep?.value,
      note,
    }
    try {
      await createProspectItem(payload)
      setIsLoading(false)
      setOpen(false)
      showSuccessNotification('Prospect Successfully Created.')
      setIsUpdated(true)
      setProspectAddress([])
      setIndustryType(null)
      setPocValue(undefined)
      setProspectData(initialProspect)
      setStatusValue(0)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
    setIsUpdated(false)
  }

  const changeDropDownField = React.useCallback(async (field: keyof IClient, valueAtt: ISuggestionAttribute | null) => {
    if (field && field === 'sales_rep') {
      console.log('valueAttvalueAttvalueAttvalueAtt setSalesRep', valueAtt)
      setSalesRep(valueAtt)
    } else if (field && field === 'affiliate') {
      console.log('valueAttvalueAttvalueAttvalueAtt setClientObj', valueAtt)
      setClientObj(valueAtt)
    }
  }, [])
  return (
    <React.Fragment>
      <AddAddressDialog
        open={showAddAddressDialog}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressFieldsChange}
        handleDialogClose={toggleAddAddressDialog}
        onAddressAdd={handleAddAddress}
        addressErrors={{
          country: false,
          city: false,
          street: false,
          postal_code: false,
        }}
        isEditing={editedAddressId !== undefined}
      />
      <ModalWrapper open={open} setOpen={setOpen} heading="Add Prospect">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputField
              label="First Name"
              value={firstName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, firstName: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Middle Name"
              value={middleName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, middleName: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Last Name"
              value={lastName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, lastName: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Title"
              value={title}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, title: val }
                })
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={6}>
            <PhoneInput
              value={phone || ''}
              onChange={val => {
                setProspectData(prevState => {
                  return { ...prevState, phone: val }
                })
              }}
            />
            {/* <InputField
              label="Phone"
              value={phone}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, phone: val }
                })
              }}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Email"
              type="email"
              value={email}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, email: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.textBox}>
              <Addresses
                label="Address"
                customClass
                customClassName="address-box"
                onlyOneAddress={true}
                addresses={prospectAddress || []}
                onAddAddressClick={handleAddAddressClick}
                onAddressEditClick={handleAddressEditClick}
                onAddressDeleteClick={handleAddressDeleteClick}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputField
              label="Company Name"
              value={companyName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, companyName: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <InputField label="Industry Type" /> */}
            <Autocomplete
              // options={industryTypesList?.map(type => type.name)}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={option => option.name}
              options={industryTypesList}
              className={classes.autocompleteField}
              freeSolo
              renderInput={params => (
                <TextField
                  {...params}
                  name="industryType"
                  label="Industry Type"
                  InputProps={{ ...params.InputProps }}
                  // onBlur={event => handleCreatorSelected(event.target.value)}
                />
              )}
              onChange={(_, value) => setIndustryType(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>POC</InputLabel>
              <Select value={pocValue} onChange={e => setPocValue(e.target.value)} label="POC">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {senderOptions?.map(option => (
                  <MenuItem value={option?.value}>{option?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Submission ID"
              value={submissionId}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, submissionId: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              label="Status"
              options={prospectStatusList}
              placeholder="Select your status"
              val={statusValue}
              handleValueChange={(event: any) => setStatusValue(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Invoice Payment Link"
              value={invoicePaymentLink}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, invoicePaymentLink: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              // showCopyButton={!!editedClient}
              onOptionSelected={option => changeDropDownField('sales_rep', option ? option : null)}
              title="Sales Representative"
              fetchSuggestions={fetchSuggestions}
              field="sales_rep_id"
              placeholder="Choose Sales Rep"
              value={salesRep}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              // showCopyButton={!!editedClient}
              onOptionSelected={option => changeDropDownField('affiliate', option ? option : null)}
              title="Affiliate"
              fetchSuggestions={fetchSuggestions}
              field="affiliate_id"
              placeholder="Choose Affiliate"
              value={clientObj}
              variant="standard"
            />
          </Grid>
          <br />
          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <TextField
                // style={{ width: '100%' }}
                // className={classes.textBox}
                multiline
                label="Note"
                value={note}
                onChange={e => {
                  const val = e.target.value
                  setProspectData(prevState => {
                    return { ...prevState, note: val }
                  })
                }}
                rows={5}
                variant="outlined"
                // minRows={5}
              />{' '}
            </FormControl>
          </Grid>
        </Grid>
        <br />
        {isError ? <Alert severity="error">Please fill all fields</Alert> : ''}
        <br />
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={4}>
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="text-template-btn"
              onClick={handleSave}
              isLoading={isLoading}
            >
              Save
            </ButtonWithLoading>
          </Grid>
        </Grid>
      </ModalWrapper>
    </React.Fragment>
  )
}

export default AddProspectModal
