import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ITaxOrder } from 'models/taxOrder'
import BRTextField from 'components/common/textFields/BRTextField'
import PhoneInput from 'components/clients/create/phoneInput'
import EinSsnInput from 'components/clients/create/einSsnInput'
import { positiveNumericInput } from 'components/common/functions'
import DatePicker from 'components/common/datePicker'
import { datePickerDateFormat, datePickerDatePlaceholder } from 'utils/formatDate'
import Addresses from 'components/common/addresses'
import { Checkbox, Grid, ListItem } from '@material-ui/core'
import { toTitleCase } from 'utils/toTitleCase'
import useUser from 'hooks/useUser'
import { USERTYPES } from 'utils/constant'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 430,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      margin: '0.5rem 0',
      width: '100%',
    },
    phoneField: {
      width: '100%',
      position: 'relative',
    },
    RegsiteredAgentText: {
      paddingLeft: 0,
      fontSize: 20,
      cursor: 'no-drop',
      margin: '6px 0',
    },
  })
)

interface IClientInfoProps {
  taxOrder: ITaxOrder
  handleTaxOrderChanges: <K extends ITaxOrder>(field: keyof K, value: K[keyof K]) => void
}

const ClientInfo: React.FC<IClientInfoProps> = props => {
  const classes = useStyles()
  const { taxOrder, handleTaxOrderChanges } = props

  const { client } = taxOrder

  const { user } = useUser()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1 className="campany-detail">Personal Details</h1>
      </Grid>
      <Grid item xs={4}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="First Name"
          className={classes.textField}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client?.name}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={4}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="Middle Name"
          className={classes.textField}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client?.middle_name}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={4}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="Last Name"
          className={classes.textField}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client?.last_name}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={5}>
        {user?.user_type !== USERTYPES.accountant ? (
          <>
            <BRTextField
              showCopyButton={!!taxOrder}
              required
              label="Email"
              className={classes.textField}
              type="email"
              name="email"
              margin="normal"
              variant="outlined"
              value={client?.email}
              onChange={event => handleTaxOrderChanges('client', event.target.value)}
              disabled
            />
          </>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={4}>
        <EinSsnInput
          showCopyButton={!!taxOrder}
          value={client?.ssn_ein}
          onChange={(value: string) => handleTaxOrderChanges('client', positiveNumericInput(value))}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <div className={classes.phoneField}>
          <PhoneInput
            showCopyButton={!!client}
            value={client?.phone_number || ''}
            onChange={value => handleTaxOrderChanges('client', value)}
            disabled
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          onDateSelected={value => handleTaxOrderChanges('client', value)}
          label="Date of Birth"
          classes={{ root: classes.textField }}
          maxDateMessage="Client should be older than 18 years"
          minDateMessage="Age cannot exceed 100 years"
          value={client?.dob}
          style={{ width: '100%' }}
          placeholder={datePickerDatePlaceholder}
          maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
          minDate={new Date().setFullYear(new Date().getFullYear() - 120)}
          disableFuture
          format={datePickerDateFormat}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="Affiliate"
          className={classes.textField}
          type="text"
          name="affiliate_id"
          margin="normal"
          variant="outlined"
          value={`${client?.affiliate?.first_name || ''} ${client?.affiliate?.middle_name || ''} ${client?.affiliate
            ?.last_name || ''}`}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="Sales Representative"
          className={classes.textField}
          type="text"
          name="sales_rep_id"
          margin="normal"
          variant="outlined"
          value={client?.sales_rep?.name}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="Tax Structure"
          className={classes.textField}
          type="text"
          name="tax_structure"
          margin="normal"
          variant="outlined"
          value={client?.tax_structure || ''}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <BRTextField
          showCopyButton={!!taxOrder}
          required
          label="Partner"
          className={classes.textField}
          type="text"
          name="partner"
          margin="normal"
          variant="outlined"
          value={client?.partner?.name || ''}
          onChange={event => handleTaxOrderChanges('client', event.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <ListItem className={classes.RegsiteredAgentText} disabled>
          <Checkbox
            color="secondary"
            checked={client?.is_registered_agent}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => handleTaxOrderChanges('client', e.target.checked)}
            disabled
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Registered Agent')}</span>
        </ListItem>
      </Grid>
      <Grid item xs={12}>
        <h1 className="campany-detail">Addresses</h1>
      </Grid>
      <Grid item xs={6}>
        <Addresses
          label="Address Details"
          addresses={client?.addresses || []}
          isEditable={false}
          isDeletable={false}
          onlyOneAddress
          onAddressEditClick={function(id: number): void {
            throw new Error('Function not implemented.')
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ClientInfo
