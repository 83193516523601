import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import * as React from 'react'

interface ICompanySelectFieldProps {
  fetchCompanySuggestions: (query: string, field: string) => Promise<ISuggestionAttribute[]>
  onCompanySelected: (option: ISuggestionAttribute | null) => void
  defaultCompanyValue?: ISuggestionAttribute
}

/**
 * A lot of things in the app depend on a company e.g. orders, tasks etc.
 * When interacting with those things we may wanna select a company for them from a list of available options.
 *
 * @param fetchCompanySuggestions: A list of company options to select from.
 * @param defaultCompanyValue: Default option that will appear as the selected company
 * @param onCompanySelected: Handler triggering when an option is selected from a dropdown.
 */
export const CompanySelectField = ({
  fetchCompanySuggestions,
  defaultCompanyValue,
  onCompanySelected,
}: ICompanySelectFieldProps) => {
  return (
    <SelectField
      onOptionSelected={onCompanySelected}
      title="Company"
      fetchSuggestions={fetchCompanySuggestions}
      field="company_id"
      placeholder="Select Company"
      required={true}
      value={defaultCompanyValue}
    />
  )
}
