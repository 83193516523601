import { emptyOrderDetails, getOrderDetails, IOrderDetailsResponse, updateOrder } from 'api/orders'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'

interface IUseCompanyOrderConfigProps {
  useUpdate?: boolean
}

/**
 * This hook revolves around creating/updating an order for a specific company.
 *
 * @param useUpdate: If truthy, send a company order update API request on every field change.
 *
 * This hook would be used in any component that deals with a single company order and that order may need editing.
 * */
export const useCompanyOrderConfig = ({ useUpdate }: IUseCompanyOrderConfigProps) => {
  const [companyOrderDetails, setCompanyOrderDetails] = React.useState<IOrderDetailsResponse>(emptyOrderDetails)

  /**
   * When a component is rendered we wanna fetch and save the state of a company order.
   */
  const getCompanyOrderDetails = React.useCallback(async (companyOrderId: number): Promise<void> => {
    // const orderDetails: IOrderDetailsResponse = await getOrderDetails(companyOrderId)
    // setCompanyOrderDetails(orderDetails)
  }, [])

  /**
   * Used for company order UPDATE.
   *
   * We wanna dynamically update the company order with every change the user makes.
   *
   * This is a generic callback that should be passed as a handler to all the onChange events of the component fields.
   *
   * Since state is updated asynchronously, we can't just pass `companyOrderDetails` to `updateOrder` calls.
   *
   * @example
   * <PriceTextField onChange={e => onCompanyOrderFieldChange("price", e.target.value)}/>
   */
  const onCompanyOrderFieldChange = React.useCallback(
    async (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => {
      setCompanyOrderDetails((prev: IOrderDetailsResponse) => ({
        ...prev,
        [key]: value,
      }))

      if (useUpdate) await updateOrder({ ...companyOrderDetails, [key]: value })
    },
    [companyOrderDetails, setCompanyOrderDetails, useUpdate]
  )

  return { companyOrderDetails, getCompanyOrderDetails, onCompanyOrderFieldChange, setCompanyOrderDetails }
}
