import Divider from '@material-ui/core/Divider'
import { NoteType, NoteTypeList } from 'models/note'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import { NoteEntitiesContext } from './addNote'
import NoteEntitySelector, { NoteEntities, useEntityFilter } from './NoteEntitySelector'
import NoteTypeCheckBox from './noteTypeCheckbox'
import SearchableNotes from './searchableNotes'
import useStyles from './styles'
import Title from './title'
import { getUnpaidSubscriptions } from 'api/note'
import { Alert, AlertTitle } from '@material-ui/lab'
import { STRIPE_SUBSCRIPTION_STATUSES, SUBSCRIPTION_STATUS_MAPPING } from 'utils/constant'
import useRouter from 'use-react-router'

export interface APINoteType {
  id?: number
  color?: string
  create?: boolean
  search?: boolean
}

interface INotesProps {
  setModalIsOpen?: (isOpen: boolean) => void
  classes?: Record<string, string>
  tableBodyClassName?: string
}

/**
 * is a component for selecting note entities, selecting which objects to filter notes by and list of notes.
 *
 * @context requires NotesTableContext with search options for filtering notes
 */
const Notes: React.FC<INotesProps> = props => {
  const classes = useStyles(props)
  const { history } = useRouter()

  const { setModalIsOpen, tableBodyClassName } = props

  const { searchOptions, setSearchOptions } = React.useContext(NotesTableContext)
  const noteEntitiesContext = React.useContext(NoteEntitiesContext)
  const [unPaidList, setUnPaidList] = React.useState<{ name: string; status: string; company_id: number }[]>([])

  const selectedTypes: NoteType[] = searchOptions.types || []

  // key-value store which shows if note type is selected in NoteEntitySelector
  const { entityFilter, setEntityFilter } = useEntityFilter({ defaultTypes: selectedTypes })

  React.useEffect(() => {
    const getUnPaid = async (comId?: number, ordId?: number, taxId?: number) => {
      try {
        const result: any[] = await getUnpaidSubscriptions(comId, ordId, taxId)
        setUnPaidList(result)
        console.log('resultresultresult', result)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (searchOptions?.company_id || searchOptions?.order_id || searchOptions?.tax_order_id) {
      getUnPaid(searchOptions?.company_id, searchOptions?.order_id, searchOptions?.tax_order_id)
    }
  }, [searchOptions?.order_id, searchOptions?.company_id, searchOptions?.tax_order_id])

  /**
   * change checked value in note entity selector
   *
   * there is a list of checkboxes for different note entities.
   * and this method handles clicking on the checkbox
   *
   * @param noteType - which note type to check/uncheck
   * @param isEnabled - checked or not checked
   */
  const handleEntityFilterValueToggle = React.useCallback(
    (noteType: NoteType) => (isEnabled: boolean) => {
      //
      console.log('noteType', noteType)

      if (isEnabled) {
        //select entity checkbox

        setSearchOptions((prev: any) => {
          if (prev.types?.includes(noteType)) {
            // note type is already enabled -> do nothing
            return prev
          }
          console.log('prevvvvv', prev.types)

          let newTypes: any = []
          if (prev.types && typeof prev.types === 'string' && prev.types.length > 0) {
            newTypes = prev.types.split(',')
          } else if (prev.types && prev.types.length > 0) {
            newTypes = prev.types
          }
          // typeof prev.types === 'string' && prev.types.length > 0
          //   ? prev.types.split(',')
          //   : prev.types.length > 0
          //   ? prev.types
          //   : []
          // const newTypes = ['client', 'order', 'company'] || []

          // if (noteType === 'order') {
          //   newTypes.push(noteType)
          //   return {
          //     ...prev,
          //     order_id: noteEntitiesContext?.order?.value,
          //     // company_id: noteEntitiesContext?.company?.value,
          //     // client_id: noteEntitiesContext?.client?.value,
          //     types: newTypes,
          //   }
          // } else {
          //   newTypes.push(noteType)
          //   return {
          //     ...prev,
          //     types: newTypes,
          //   }
          // }

          switch (noteType) {
            case 'order': {
              newTypes.push(noteType)
              // console.log('prev', prev)

              return {
                ...prev,
                order_id: noteEntitiesContext?.order?.value,
                // company_id: noteEntitiesContext?.company?.value,
                // client_id: noteEntitiesContext?.client?.value,
                types: newTypes.toString(),

                //types: prev.types + ',order',
              }
            }
            case 'company': {
              newTypes.push(noteType)
              return {
                ...prev,
                // order_id: noteEntitiesContext?.order?.value,
                company_id: noteEntitiesContext?.company?.value,
                types: newTypes.toString(),
                // types: prev.types + ',company',
              }
            }
            case 'client': {
              newTypes.push(noteType)
              debugger
              return {
                ...prev,
                // order_id: noteEntitiesContext?.order?.value,
                // company_id: noteEntitiesContext?.company?.value,
                client_id: noteEntitiesContext?.client?.value,
                types: newTypes.toString(),
                // types: prev.types + ',client',
              }
            }
            case 'general': {
              newTypes.push(noteType)
              return {
                ...prev,
                // order_id: noteEntitiesContext?.order?.value,
                // company_id: noteEntitiesContext?.company?.value,
                // client_id: noteEntitiesContext?.client?.value,
                types: newTypes.toString(),
                // types: prev.types + ',general',
              }
            }
            case 'taxorder': {
              newTypes.push(noteType)
              return {
                ...prev,
                // order_id: noteEntitiesContext?.order?.value,
                // company_id: noteEntitiesContext?.company?.value,
                tax_order_id: noteEntitiesContext?.taxorder?.value,
                types: newTypes.toString(),
                // types: prev.types + ',general',
              }
            }
            default:
              break
          }

          // newTypes.push(noteType)
          // return {
          //   ...prev,
          //   order_id: noteEntitiesContext?.order?.value,
          //   // company_id: noteEntitiesContext?.company?.value,
          //   types: newTypes,
          // }
        })
      } else {
        // unselect entity checkbox

        setSearchOptions((prev: any) => {
          console.log('prevvvvvelse', prev.types)

          // remove `type` from `prev.types`
          // const noteTypeIndex: number | undefined = prev.types?.findIndex(x => x === noteType)
          const prevType = prev.types.toString()
          const noteTypeIndex: number | undefined = prevType?.split(',').findIndex(x => x === noteType)

          if (noteTypeIndex === undefined || noteTypeIndex === -1 || !prevType) {
            // if `type` is not found, we do no changes
            return prev
          }

          const newTypes: NoteType[] = prevType?.split(',')
          newTypes.splice(noteTypeIndex, 1)
          console.log('newTypes', newTypes)

          // const newType =
          //   prev && prev?.types.includes(',')
          //     ? prev?.types?.replace(`,${noteType}`, '')
          //     : prev?.types?.replace(`${noteType}`, '')
          const result: any = {
            ...prev,
            types: newTypes.toString(),
            [`${noteType}_id`]: null,
          }

          return result
        })
      }

      setEntityFilter(prev => ({
        ...prev,
        [noteType]: isEnabled,
      }))
    },
    [setEntityFilter, setSearchOptions]
  )

  const handleNoteEntitiesChange = React.useCallback(
    (value: React.SetStateAction<NoteEntities>) => {
      setSearchOptions(value)
    },
    [setSearchOptions]
  )

  const UnPaidPassDue = unPaidList?.filter(
    item =>
      item?.status === STRIPE_SUBSCRIPTION_STATUSES?.past_due || item?.status === STRIPE_SUBSCRIPTION_STATUSES?.unpaid
  )
  const ActiveTrailing = unPaidList?.filter(
    item =>
      item?.status === STRIPE_SUBSCRIPTION_STATUSES?.active || item?.status === STRIPE_SUBSCRIPTION_STATUSES?.trialing
  )

  return (
    <div className={classes.mainContainer}>
      <Title onClose={() => setModalIsOpen && setModalIsOpen(false)} />

      {UnPaidPassDue?.length ? (
        <Alert severity="warning">
          <AlertTitle>UnPaid Subscriptions</AlertTitle>
          <ul style={{ listStyleType: 'disclosure-closed' }}>
            {UnPaidPassDue?.map((item: { name: string; status: string; company_id: number }, key: number) => (
              <li
                style={{ listStyleType: 'disclosure-closed', cursor: 'pointer' }}
                onClick={() => history?.push({ pathname: `/company/${item?.company_id}`, state: { tab: 3 } })}
              >
                {item?.name}
              </li>
            ))}
          </ul>
        </Alert>
      ) : (
        ''
      )}
      <hr />
      {ActiveTrailing?.length ? (
        <Alert severity="success">
          <AlertTitle>Active Subscriptions</AlertTitle>
          <ul style={{ listStyleType: 'disclosure-closed' }}>
            {ActiveTrailing?.map((item: { name: string; status: string; company_id: number }, key: number) => (
              <li
                style={{ listStyleType: 'disclosure-closed', cursor: 'pointer' }}
                onClick={() => history?.push({ pathname: `/company/${item?.company_id}`, state: { tab: 3 } })}
              >
                {item?.name}
              </li>
            ))}
          </ul>
        </Alert>
      ) : (
        ''
      )}

      <div>
        <div>
          {NoteTypeList.map((type: NoteType) => (
            <NoteTypeCheckBox
              checked={entityFilter[type]}
              key={type}
              noteType={type}
              onChange={handleEntityFilterValueToggle(type)}
            />
          ))}
        </div>

        <NoteEntitySelector strict={true} noteEntities={searchOptions} setNoteEntities={handleNoteEntitiesChange} />
      </div>
      <Divider style={{ marginBottom: 25, marginTop: 13 }} />
      <SearchableNotes tableBodyClassName={tableBodyClassName} />
    </div>
  )
}

export default Notes

// switch (noteType) {
//   case 'order':
//     setSearchOptions(prev => {
//       if (prev.types?.includes(noteType)) {
//         // note type is already enabled -> do nothing
//         return prev
//       }
//

//       const newTypes = prev.types || []

//       newTypes.push(noteType)
//       return {
//         ...prev,
//         order_id: noteEntitiesContext?.order?.value,
//         // company_id: noteEntitiesContext?.company?.value,
//         // client_id: noteEntitiesContext?.client?.value,
//         types: newTypes,
//       }
//     })
//     break
//   case 'company':
//     setSearchOptions(prev => {
//       if (prev.types?.includes(noteType)) {
//         // note type is already enabled -> do nothing
//         return prev
//       }
//

//       const newTypes = prev.types || []

//       newTypes.push(noteType)
//       return {
//         ...prev,
//         // order_id: noteEntitiesContext?.order?.value,
//         company_id: noteEntitiesContext?.company?.value,
//         types: newTypes,
//       }
//     })
//     break

//   case 'client':
//     setSearchOptions(prev => {
//       if (prev.types?.includes(noteType)) {
//         // note type is already enabled -> do nothing
//         return prev
//       }
//

//       const newTypes = prev.types || []

//       newTypes.push(noteType)
//       return {
//         ...prev,
//         // order_id: noteEntitiesContext?.order?.value,
//         // company_id: noteEntitiesContext?.company?.value,
//         client_id: noteEntitiesContext?.client?.value,
//         types: newTypes,
//       }
//     })

//     break

//   default:
//     break
// }
