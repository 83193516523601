import * as React from 'react'
import { IconProps } from './props'

function ExpandIcon({ width, height }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '12'} height={height || '7'} viewBox="0 0 12 7">
      <path
        fill="#919192"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M16.334 19.698l5.005 5.018a.968.968 0 001.37 0l5.007-5.018a.968.968 0 10-1.37-1.37l-4.32 4.332-4.322-4.32a.968.968 0 10-1.37 1.358z"
        transform="translate(-1331 -488) translate(1315 470)"
      />
    </svg>
  )
}

export default ExpandIcon
