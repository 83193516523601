import React from 'react'
import { InlineItem } from './inlineItem'
import { Chip } from '@material-ui/core'
import { CLIENT_COMPANY_STATUS } from 'utils/constant'
import { formatDateToHumanFriendly } from 'utils/formatDate'

interface CompanyInfoProps {
  clientName: string
  company: string
  fileDate: string
  ein: string
  stateId: string
  businessAddress: string
  formationState: string
  business_active_status?: string
  expected_ein_date?: string
  expected_file_date?: string
}

const CompanyInfo = ({
  clientName,
  company,
  fileDate,
  ein,
  stateId,
  businessAddress,
  formationState,
  business_active_status,
  expected_ein_date,
  expected_file_date,
}: CompanyInfoProps) => {
  return (
    <div className="company-info-container">
      <InlineItem title={'Status:'}>
        {business_active_status ? (
          <Chip
            style={{ backgroundColor: CLIENT_COMPANY_STATUS[business_active_status || ''] }}
            label={business_active_status}
          />
        ) : (
          ''
        )}
      </InlineItem>

      <InlineItem title={'Client Name:'} content={clientName} />
      <InlineItem title={'Company:'} content={company} />
      <InlineItem title={'STATE OF FORMATION:'} content={formationState} />
      <InlineItem title={'Business Address:'} content={businessAddress} />
      {/* <InlineItem title={'File Date:'} content={fileDate} />
      <InlineItem title={'State ID:'} content={stateId} /> */}
      {expected_file_date ? (
        <InlineItem title={'File Date:'}>
          <div className="custom-render">
            <Chip style={{ backgroundColor: '#2bd390', color: '#fff' }} label={'PROCESSING'} size="small" />
            <small style={{ marginTop: '0.25rem' }}>
              * EXPECTED TO BE FILED BY {expected_file_date ? formatDateToHumanFriendly(expected_file_date) : '-'}
            </small>
          </div>
        </InlineItem>
      ) : (
        <InlineItem title={'File Date:'} content={fileDate ? formatDateToHumanFriendly(fileDate) : '-'} />
      )}

      {expected_file_date ? (
        <InlineItem title={'State ID:'}>
          <div className="custom-render">
            <Chip style={{ backgroundColor: '#2bd390', color: '#fff' }} label={'PROCESSING'} size="small" />
            <small style={{ marginTop: '0.25rem' }}>
              * EXPECTED TO BE FILED BY {expected_file_date ? formatDateToHumanFriendly(expected_file_date) : '-'}
            </small>
          </div>
        </InlineItem>
      ) : (
        <InlineItem title={'State ID:'} content={stateId} />
      )}
      {/* <InlineItem title={'EIN:'} content={expected_ein_date ? expected_ein_date : ein} /> */}

      {expected_ein_date ? (
        <InlineItem title={'EIN:'}>
          <div className="custom-render">
            {/* <Chip style={{ backgroundColor: '#2bd390', color: '#fff' }} label={'PROCESSING'} size="small" /> */}
            <small style={{ marginTop: '0.25rem' }}>
              {/* * EXPECTED TO BE FILED BY {formatDateToHumanFriendly(expected_ein_date || '')} */}
              <span
                dangerouslySetInnerHTML={{
                  __html: `${expected_ein_date}`,
                }}
              />
            </small>
          </div>
        </InlineItem>
      ) : (
        <InlineItem title={'EIN:'} content={ein} />
      )}
    </div>
  )
}

export default CompanyInfo
