import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const SendPlaneIcon = (props: SvgIconProps & { fillColor?: string }) => {
  return (
    <SvgIcon {...props}>
      <path
        fill={props.fillColor || '#FFF'}
        d="M6.596 10.213l3.485 6.587L16.809 0 0 6.728l6.596 3.485zm3.379 4.566l-2.55-4.808 7.437-7.438-4.887 12.246zM6.832 9.375L2.029 6.836 14.28 1.93 6.832 9.375z"
      />
    </SvgIcon>
  )
}

export default SendPlaneIcon
