import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'subject', label: 'template title', style: { width: '100%' }, sortable: true },
  { id: 'actions', label: 'actions', style: { minWidth: 110 }, sortable: false },
]

const TempleteNotiHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default TempleteNotiHead
