import { normalizePhone } from 'components/common/functions'
import CopyTextButton from 'components/common/textFields/copyTextButton'
import * as React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import copyToClipboard from 'utils/copyToClipboard'

interface IPhoneInputProps {
  error?: boolean
  value: string
  onChange: (value: string) => void
  showCopyButton?: boolean
  disabled?: boolean
}

const PhoneInput: React.FC<IPhoneInputProps> = ({ error, onChange, value, showCopyButton, disabled }) => {
  // Because ReactPhoneInput does not accept a textfield component prop, where we could pass
  // BRTextField, that supports copy button, we just add this button here manually.
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReactPhoneInput
        disabled={disabled}
        data-testid="phone-input"
        country="us"
        inputClass="TextField"
        inputStyle={{ width: '100%', height: 50, backgroundColor: '#fafafb', borderColor: error ? 'red' : undefined }}
        inputProps={{ required: true }}
        containerStyle={{ margin: error ? '19px 0 2px' : '8px 0 2px' }}
        dropdownStyle={{ width: 420, marginTop: 8 }}
        value={value}
        onChange={value => onChange(normalizePhone(value))}
        countryCodeEditable={false}
      />
      {showCopyButton && value && value !== '' ? (
        <CopyTextButton
          onClick={() => copyToClipboard(value)}
          customStyles={{ position: 'absolute', right: '0.85rem' }}
        />
      ) : null}
    </div>
  )
}

export default PhoneInput
