import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '2rem',
      },
    },
    commonRow: {
      padding: '1rem 1.25rem',
      display: 'flex',
      backgroundColor: '#f7f8f9',
      minHeight: '162px',
      alignItems: 'center',

      '& .offer-heading': {
        fontSize: '0.95rem',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 'normal',
        paddingBottom: '0.5rem',
      },
    },
    tableContainer: {
      marginTop: '4rem',
    },
    searchBar: {
      margin: '1rem 0',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '1.5rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
  })
)
