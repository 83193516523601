import { HeadColumn, SortableTableHead, SortableTableSortProps } from 'components/common/table'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'priority', label: `Priority`, style: { width: '8%' }, sortable: true },
  { id: 'filer_name', label: `Filer's Name`, style: {}, sortable: true },

  { id: 'tax_year', label: 'Tax Year', style: {}, sortable: true },
  { id: 'service_type', label: 'Service', style: {}, sortable: true },
  { id: 'order_type', label: 'Type', style: {}, sortable: true },
  { id: 'accountant_user_id', label: 'CPA Assigned', style: {}, sortable: true },

  { id: 'order_status_description', label: 'Stage', style: {}, sortable: true },
  { id: 'state_of_formation', label: 'State Of Formation', style: {}, sortable: true },
  { id: 'extension_filed', label: 'Extension', style: {}, sortable: true },
  { id: 'creation_time', label: 'Creation Date', style: {}, sortable: true },
  { id: 'last_updated ', label: 'Last Updated', style: { width: '8%' }, sortable: true },
  { id: 'due_date', label: 'Due Date', style: { width: '8%' }, sortable: true },

  { id: 'actions', label: '', style: { width: '12%' }, sortable: false },
]

const TaxOrderTableHead: React.FC<SortableTableSortProps> = props => (
  <SortableTableHead {...props} headColumns={headColumns} />
)

export default TaxOrderTableHead

// import { HeadColumn, SortableTableHead, SortableTableSortProps } from 'components/common/table'
// import * as React from 'react'

// const headColumns: HeadColumn[] = [
//   { id: 'id', label: `Priority`, style: { width: '5%', maxWidth: '5%' }, sortable: false },
//   { id: 'filer_name', label: `Filer's Name`, style: { width: '20%', maxWidth: '20%' }, sortable: false },
//   { id: 'labels', label: 'Labels', style: { width: '10%', maxWidth: '10%' }, sortable: true },

//   { id: 'tax_year', label: 'Tax Year', style: { width: '5%', maxWidth: '5%' }, sortable: true },
//   { id: 'type', label: 'Type', style: { width: '10%', maxWidth: '10%' }, sortable: false },
//   { id: 'cpa_assigned', label: 'CPA Assigned', style: { width: '20%', maxWidth: '20%' }, sortable: false },

//   { id: 'stage', label: 'Stage', style: { width: '10%', maxWidth: '15%' }, sortable: false },
//   { id: 'extension', label: 'Extension', style: { width: '10%', maxWidth: '10%' }, sortable: false },
//   { id: 'updated', label: 'Last Update', style: { width: '10%', maxWidth: '10%' }, sortable: true },
//   { id: 'due_date', label: 'Due Date', style: { width: '10%', maxWidth: '10%' }, sortable: true },
//   { id: 'actions', label: '', style: { width: '10%', maxWidth: '5%' }, sortable: false },
// ]

// const TaxOrderTableHead: React.FC<SortableTableSortProps> = props => (
//   <SortableTableHead {...props} headColumns={headColumns} />
// )

// export default TaxOrderTableHead
