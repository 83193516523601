import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import { IOrderDetailsResponse, updateOrder } from 'api/orders'
import { fetchAssigneeSuggestions } from 'api/suggestions'
import PrimaryButton from 'components/common/buttons/primaryButton'
import DatePicker from 'components/common/datePicker'
import AsyncSelectField from 'components/common/select'
import { useAssigneeField } from 'components/orders/hooks/useAssigneeField'
import { useStyles } from 'components/orders/orderDetails/ChangeOrderStatusDialog.styles'
import { IUser } from 'models/user'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { ValueOf } from 'utils/objectUtils'

interface Props {
  open: boolean
  handleClose(): void
  newOrderStatus: string
  defaultAssignee: IUser | null
  orderId: number
  onOrderDetailsFieldsChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => void
}

export const ChangeOrderStatusDialog: React.FC<Props> = ({
  open,
  handleClose,
  newOrderStatus,
  defaultAssignee,
  orderId,
  onOrderDetailsFieldsChange,
}) => {
  const classes = useStyles()

  const [dueDate, setDueDate] = useState<Date | null>(
    moment()
      .add(7, 'days')
      .toDate()
  )

  const onDueDateChange = useCallback((date: string) => {
    setDueDate(moment(date).toDate())
  }, [])

  const [assignee, setAssignee] = useState<IUser | null>(defaultAssignee)

  const { assigneeAsSuggestionAttribute, onAssigneeSelected: handleAssigneeSelected } = useAssigneeField({
    assignee,
    handleAssigneeSelected: setAssignee,
  })

  const handleSaveClick = useCallback(async () => {
    onOrderDetailsFieldsChange('assignee', assignee)
    onOrderDetailsFieldsChange('assignee_id', assignee?.id || null)

    const formattedDueDate: string = moment(dueDate).toISOString()
    onOrderDetailsFieldsChange('due_date', formattedDueDate)

    await updateOrder({
      id: orderId,
      assignee_id: assignee?.id,
      due_date: moment(dueDate).toISOString(),
    })

    handleClose()
  }, [assignee, dueDate, handleClose, onOrderDetailsFieldsChange, orderId])

  return (
    <Dialog open={open} classes={{ paper: classes.dialogContainer }}>
      <div className={classes.root}>
        <div className={classes.closeIconContainer} onClick={handleClose}>
          <CloseIcon fontSize="large" />
        </div>

        <div className={classes.contentContainer}>
          <div className={classes.titleLabel}>
            Change Status To: <span className={classes.newStatusLabel}>{newOrderStatus}</span>
          </div>

          <AsyncSelectField
            title="Assignee"
            fetchSuggestions={(query, _) => fetchAssigneeSuggestions(query)}
            field="assignee_id"
            onOptionSelected={handleAssigneeSelected}
            defaultValue={assigneeAsSuggestionAttribute}
            placeholder="Assignee"
          />

          <DatePicker
            value={dueDate || undefined}
            onDateSelected={onDueDateChange}
            disablePast={true}
            placeholder="Deadline"
            style={{ width: '100%' }}
          />

          <div className={classes.saveButtonContainer}>
            <PrimaryButton className={classes.saveButton} onClick={handleSaveClick}>
              <span className={classes.saveButtonLabel}>Save</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
