import { TableRow, TableCell } from '@material-ui/core'
import { EmployeeReportData } from 'api/employeeReport'
import React from 'react'
import { formatDateToHumanFriendly } from 'utils/formatDate'

interface TableReportRowProps {
  tableReport: EmployeeReportData
}

const TableReportRow: React.FC<TableReportRowProps> = ({ tableReport }) => {
  return (
    <TableRow data-testid={`row-for-report-with-id-${1}`}>
      <TableCell style={{}}>{formatDateToHumanFriendly(tableReport?.logged_at?.split('T')[0])}</TableCell>
      {/* <TableCell style={{}}>{`${tableReport?.time_spent} Hour`}</TableCell> */}
      <TableCell style={{}}>{tableReport?.user_clicks}</TableCell>
      <TableCell style={{}}>{tableReport?.page_visits}</TableCell>
      <TableCell style={{}}>
        {(tableReport?.user_clicks > 0 ? tableReport?.page_visits / tableReport?.user_clicks : 0).toFixed(2)}
      </TableCell>
      <TableCell style={{}}>{tableReport?.order_status_updated}</TableCell>
      <TableCell style={{}}>{tableReport?.order_updated}</TableCell>
      <TableCell style={{}}>{tableReport?.notes_created}</TableCell>
      <TableCell style={{}}>{tableReport?.task_created}</TableCell>
      <TableCell style={{}}>{tableReport?.task_completed}</TableCell>
      <TableCell style={{}}>{tableReport?.task_updated}</TableCell>
    </TableRow>
  )
}

export default TableReportRow
