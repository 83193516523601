import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { changeClientCamplianceTaskStatus } from 'api/clientDashboard'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import React, { useState } from 'react'
import { COMPLIANCE_TASKS_STATUS } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useStyles } from './styles'

interface DeleteModalProps {
  open: boolean
  setOpen: any
  selectedState?: any
  setIsStatusChange?: any
}

const DeleteModal = ({ open, setOpen, selectedState, setIsStatusChange }: DeleteModalProps) => {
  const classes = useStyles()
  const [isFileReport, setIsFileReport] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const deleteTaskHandler = async id => {
    setIsFileReport(true)
    if (id) {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.removed,
      }
      try {
        const data = await changeClientCamplianceTaskStatus(payload)
        showSuccessNotification('Task Delete Success')
        setIsFileReport(false)
        setOpen(false)
        setIsStatusChange(true)
        return data
      } catch (error) {
        showErrorNotification(error)
        setIsFileReport(false)
      }
      setIsStatusChange(false)
    } else return
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.deleteModal}
    >
      <div className="close-icon">
        <Close onClick={handleClose} className="close" />
      </div>
      <DialogTitle id="alert-dialog-title">{'WARNING'}</DialogTitle>
      <DialogContent>
        Removing this task will pause alerts for this report until next year, it is best to only pause alerts if this
        task has been completed or is no longer required!
      </DialogContent>
      <DialogActions className="modal-action">
        <ButtonWithLoading
          wrapperClassName="remove-btn"
          isLoading={isFileReport}
          onClick={() => deleteTaskHandler(selectedState?.id)}
        >
          Remove
        </ButtonWithLoading>
        {/* <Button className="remove-btn" variant="contained" onClick={() => deleteTaskHandler(selectedState?.id)}>
          Remove
        </Button> */}
        <Button className="keep-btn" variant="contained" onClick={handleClose} autoFocus>
          Keep Task
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteModal.defaultProps = {
  setIsStatusChange: false,
}

export default DeleteModal
