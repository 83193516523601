import { apiClient } from '@jetkit/react'
import { fetchAccountants, fetchClients } from 'api/clients'
import { fetchCompanies } from 'api/companies'
import { fetchOrderTypes, findOrders } from 'api/orders'
import { ISuggestionAttribute } from 'components/interfaces'
import { ICompany } from 'models/company'
import { IOrder, IOrderType } from 'models/order'
import { IClient } from 'models/user'
import moment from 'moment'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import { notEmpty } from 'utils/objectUtils'
import { fetchComplianceOrderTypes } from './complianceCalendar'

export interface SearchItem {
  id: number
  name: string
}

export type SearchField =
  | 'assigned_to'
  | 'client_id'
  | 'company_id'
  | 'sales_rep_id'
  | 'affiliate_id'
  | 'order_id'
  | 'task_id'
  | 'order_type_id'
  | 'accountant_id'
  | 'assignee_id'
  | 'parent_id'
  | 'type'
  | 'prospect_id'
  | 'tax_order_id'
  | 'registered_agent'
  | 'senior_cpa'
  | ''
  | 'partner_id'
  | 'tax_associate'
  | 'tax_controller'
  // | 'compliance_id'
  | 'compliance_task_id'
  | 'feedback_id'
  | 'question'

export const customFetchSuggestions = (extra_params?: {
  company_id?: number
  client_id?: number
  prospect_id?: number
  tax_order_id?: number
  compliance_task_id?: number
}) => async (query: string, field: SearchField): Promise<ISuggestionAttribute[]> => {
  let endpoint: string
  switch (field) {
    case 'assigned_to':
      endpoint = 'staff'
      break
    case 'client_id':
      endpoint = 'client'
      break
    case 'company_id':
      endpoint = 'company'
      break
    case 'sales_rep_id':
      endpoint = 'sales_rep'
      break
    case 'affiliate_id':
      endpoint = 'client'
      break
    case 'order_id':
      endpoint = 'order'
      break
    case 'task_id':
      endpoint = 'task'
      break
    case 'prospect_id':
      endpoint = 'prospectslist'
      break
    case 'tax_order_id':
      endpoint = 'taxorder'
      break
    // case 'compliance_id':
    //   endpoint = 'compliance'
    //   break
    case 'compliance_task_id':
      endpoint = 'compliance'
      break
    case 'registered_agent':
      endpoint = 'registeredAgents'
      break
    case 'senior_cpa':
      endpoint = '?user_type=4'
      break
    default:
      console.warn(`invalid field ${field}!!`)
      return Promise.reject('invalid field')
  }
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'

  // apiClient.defaults.baseURL = 'http://192.168.0.123:3000'

  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL //'http://localhost:3000/'

  const results: SearchItem[] = (
    await apiClient.get(`/user/${endpoint}`, { params: { query, ...(extra_params || {}) } })
  ).data
  const options: ISuggestionAttribute[] = results.map(elem => ({
    value: elem.id,
    label: elem.name,
  }))

  return options
}

export const fetchSuggestions = customFetchSuggestions()

export const fetchAssigneeSuggestions = (query: string): Promise<ISuggestionAttribute[]> => {
  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL // 'http://localhost:3000/'
  return customFetchSuggestions()(query, 'assigned_to')
}

export const fetchRegisterAgentSuggestions = (query: string): Promise<ISuggestionAttribute[]> => {
  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL // 'http://localhost:3000/'
  return customFetchSuggestions()(query, 'registered_agent')
}

export const makeOrderSuggestion = (order: IOrder) => ({
  value: order.id,
  label: `${order.order_type ? order.order_type.name : 'Unnamed'} (${
    order.due_date ? formatDateToHumanFriendly(order.due_date) : 'No due date'
  })`,
})

export const createCompanySuggestions = async (query: string): Promise<ISuggestionAttribute[]> =>
  (await fetchCompanies(query)).map((elem: ICompany) => ({
    value: elem.id,
    label: elem.name,
  }))

export const createAccountantSuggestions = async (query: string): Promise<ISuggestionAttribute[]> =>
  (await fetchAccountants(query))
    .map((elem: IClient) =>
      elem.id
        ? {
            value: elem.id,
            label: elem.name,
          }
        : undefined
    )
    .filter(notEmpty)

export const createClientSuggestions = async (query: string): Promise<ISuggestionAttribute[]> =>
  (await fetchClients(query))
    .map((elem: IClient) =>
      elem.id
        ? {
            value: elem.id,
            label: elem.name,
          }
        : undefined
    )
    .filter(notEmpty)

export const createOrderTypeSuggestions = async (query: string): Promise<ISuggestionAttribute[]> =>
  (await fetchOrderTypes(query)).map((elem: IOrderType) => ({
    value: elem.id,
    label: elem.name,
  }))

export const complianceOrderTypeSuggestions = async (query: string): Promise<ISuggestionAttribute[]> =>
  (await fetchComplianceOrderTypes(query)).map((elem: IOrderType) => ({
    value: elem.id,
    label: elem.name,
  }))

export const createOrderSuggestions = async (
  query: string,
  companyId: number,
  except: number[] = []
): Promise<ISuggestionAttribute[]> => {
  if (companyId < 1) return []
  return (await findOrders({ orderTypeQuery: query, companyId: companyId }))
    .filter((order: IOrder) => !except.includes(order.id))
    .map((order: IOrder) => makeOrderSuggestion(order))
}
export const getTaxYearFilterOptions = (past = 5, future = 5) => {
  if (past < 0 || future < 0) {
    console.error('Invalid values provided for getTaxYearFilterOptions()')
    return []
  }
  // creates an array filled by select options for tax year filter
  const currentYear = moment().year()
  return Array<ISuggestionAttribute>(past + future + 1)
    .fill({ value: -1, label: '' })
    .map((e, i) => {
      return { value: currentYear - past + i, label: (currentYear - past + i).toString() }
    })
}
