import { Dialog } from '@material-ui/core'
import { fetchSuggestions } from 'api/suggestions'
import { createTask, editTask } from 'api/task'
import CreateTaskForm from 'components/tasks/create/form'
import { ITask, ITaskType, TaskSelectKeys } from 'models/task'
import moment from 'moment'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'

const newTask: ITask = {
  title: '',
  completed: null,
  created_by: null,
  details: '',
  priority: 'low',
  task_type: 'general',
  assigned_to: undefined,
  client_id: null,
  company_id: null,
  prospect_id: null,
}

interface IError {
  title: boolean
}

interface ICreateTaskDialogProps {
  onFormSubmitted?: () => void
  open: boolean
  toggleOpen: () => void
  taskToEdit?: ITask
}

function CreateTaskDialog({ taskToEdit, onFormSubmitted, open, toggleOpen }: ICreateTaskDialogProps) {
  const [task, setTask] = React.useState<ITask>(taskToEdit || newTask)
  const [errors, setErrors] = React.useState<IError>({ title: false })
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState(false)

  React.useEffect(() => {
    setTask(taskToEdit || newTask)
  }, [taskToEdit])

  const handleSubmitForm = React.useCallback(async () => {
    if (task.title === '') {
      setErrors({ ...errors, title: true })
      return
    }
    task.due_date = task.due_date
      ? moment(task.due_date)
          ?.format('ddd MMM DD YYYY 00:00:00 Z')
          .toString()
      : moment()
          ?.format('ddd MMM DD YYYY 00:00:00 Z')
          .toString()
    debugger
    try {
      setIsSubmitButtonDisabled(true)
      if (taskToEdit) {
        debugger
        setTask(
          await editTask({
            ...task,
            // details: task?.task_note ? (task?.description || '')?.concat(` ${task?.task_note || ''}`) : '',
            // description: task?.task_note,
            // details: task?.task_note,
          })
        )
        showSuccessNotification('Task updated')
      } else {
        debugger
        await createTask({
          ...task,
          // details: task?.task_note,
          // description: task?.task_note,
        })
        showSuccessNotification('Task created')
        setTask(newTask)
      }
      toggleOpen()
      if (onFormSubmitted) onFormSubmitted()
    } catch (err) {
      if (!taskToEdit) {
        showApiResponseError(err, 'Failed to edit new task')
      } else {
        showApiResponseError(err, 'Failed to create new task')
      }
    } finally {
      setIsSubmitButtonDisabled(false)
    }
  }, [onFormSubmitted, toggleOpen, task, taskToEdit, errors])

  const addAttribute = React.useCallback((field: TaskSelectKeys, id: ValueOf<Pick<ITask, TaskSelectKeys>>) => {
    setTask(prevTask => ({
      ...prevTask,
      [field]: id,
    }))
  }, [])

  const changeStringValue = React.useCallback(
    (field: string, value: string) => {
      setTask(prevTask => ({
        ...prevTask,
        [field]: value,
      }))
      if (field === 'title') {
        setErrors({
          title: value.trim() === '',
        })
      }
    },
    [setErrors, setTask]
  )

  const handleTaskTypeChange = React.useCallback(
    (taskType: ITaskType) => {
      setTask(prevState => ({
        ...prevState,
        task_type: taskType,
        company_id: null,
        client_id: null,
        order_id: null,
        company: undefined,
        client: undefined,
      }))
    },
    [setTask]
  )

  const checkFields =
    (task.task_type === 'client' && !task.client_id) ||
    (task.task_type === 'company' && !task.company_id) ||
    !!(task.title && !task.title.trim())

  return (
    <Dialog open={open} onClose={toggleOpen}>
      <CreateTaskForm
        closeDrawer={toggleOpen}
        task={task}
        errors={errors}
        submitForm={handleSubmitForm}
        changeStringValue={changeStringValue}
        addAttribute={addAttribute}
        fetchSuggestions={fetchSuggestions}
        disabled={checkFields || isSubmitButtonDisabled}
        handleTaskTypeChange={handleTaskTypeChange}
        confButtonTitle={taskToEdit ? 'Update Task' : 'Add Task'}
      />
    </Dialog>
  )
}

export default CreateTaskDialog
