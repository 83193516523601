import { MissingItemProps } from 'api/missingItems'
import React from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'

interface MissingItemRowProps {
  missingItem: MissingItemProps
  onEditClick: () => void
  onDeleteClick: () => void
}

const MissingItemRow: React.FC<MissingItemRowProps> = ({ missingItem, onEditClick, onDeleteClick }) => {
  const handleEditIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onEditClick()
    },
    [onEditClick]
  )

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )
  return (
    <TableRow onClick={onEditClick} style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ minWidth: 120 }}>{missingItem?.name}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>
        <div dangerouslySetInnerHTML={{ __html: missingItem?.description }} />
      </TableCell>

      <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleEditIconClick}>
            <EditIcon data-testid="editButton" />
          </IconButton>
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default MissingItemRow
