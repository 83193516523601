import { useEffect, useRef } from 'react'

export function useInterval(callback: () => void, delay: number | null, isStart: boolean): void {
  const savedCallback = useRef<() => void>()

  console.log('isPromptStartisPromptStartisPromptStart hook', isStart)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (isStart) {
        if (savedCallback.current) {
          savedCallback.current()
        }
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay, isStart])
}
