import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'

export interface MeetingLinkProps {
  id?: number
  title: string
  link: string
}

// apiClient.defaults.baseURL = 'http://192.168.0.108:3000'

export const getMeetingLinks = () =>
  requestPaginated<any>({
    url: '/meetinglinkslist',
  })

// export const getMeetingLinksById = async (id: number): Promise<AxiosResponse<void>> =>
//   apiClient.get(`/meetinglinks/${id}`)

export const deleteMeetingLinks = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/meetinglinks/${id}`)

export const createMeetingLinks = async (meetingData: MeetingLinkProps): Promise<AxiosResponse<void>> =>
  await apiClient.post('/meetinglinks', meetingData)

export const getMeetingLinksList = async (): Promise<MeetingLinkProps[]> => {
  return (await apiClient.get(`/meetinglinks`)).data
}
