import React, { useMemo } from 'react'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { useStyles } from '../styles'

import useSortTable from 'hooks/useSortTable'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { skeletons } from 'components/common/table'
import DeleteDialog from 'components/notifications/deleteDialog'
import PromoCodeTableHead from './promoCodeTableHead'
import { PromoCodesProps, getPromoCode, deletePromoCode } from 'api/promoCode'
import PromoCodeRow from './promoCodeRow'

const PromoCodeList = ({ addPromoSuccess }) => {
  const classes = useStyles()
  const [query, setQuery] = React.useState('')

  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [promoCodeItemToDelete, setPromoCodeItemToDelete] = React.useState<PromoCodesProps>()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'id' })

  const { order, sort_by } = sortSettings

  const tableHead = useMemo(
    () => <PromoCodeTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getPromoCode()({ query, sort_order: order, sort_by }), [order, query, sort_by])

  const pagedTable = usePagedTable<PromoCodesProps>({
    apiCall: memoApiCall,
  })

  const onAddReload = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  React.useEffect(() => {
    if (addPromoSuccess) {
      onAddReload()
    }
  }, [addPromoSuccess, onAddReload])

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleDeleteNotificationClick = React.useCallback((promoCode: PromoCodesProps) => {
    setPromoCodeItemToDelete(promoCode)
    setDeleteItemDialogShown(true)
  }, [])
  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  return (
    <div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 3, sizes: [200, 200, 120, 120, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Promo Code"
          {...pagedTable.renderProps}
          renderRow={(promoCode: PromoCodesProps) => (
            <PromoCodeRow promoCode={promoCode} onDeleteClick={() => handleDeleteNotificationClick(promoCode)} />
          )}
          header={tableHead}
        />
      )}

      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={promoCodeItemToDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deletePromoCode}
      />
    </div>
  )
}

export default PromoCodeList
