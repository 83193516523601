import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import classNames from 'classnames'
import * as React from 'react'
import { SetFieldValueContext } from 'utils/FormGenerator'
import { IFormFieldProps } from 'utils/FormGenerator/formTypings'
import { useStyles } from 'utils/FormGenerator/styles'
import { isAutocompleteField } from 'utils/FormGenerator/typeAssertionFunction'
import CopyButton from 'components/common/CopyButton'

const AutocompleteFieldComponent = ({ field, value }: IFormFieldProps<unknown>) => {
  if (!isAutocompleteField(field)) {
    throw new Error('type error')
  }
  const setFieldValue = React.useContext(SetFieldValueContext)
  const classes = useStyles()
  return (
    <div className={classes.copyButtonContainer}>
      <CopyButton valueToCopy={field.getOptionLabel ? field.getOptionLabel(value) : (value as string)} />
      <Autocomplete
        value={value}
        options={field.options}
        onChange={(_, value) => setFieldValue(field.name, value)}
        getOptionLabel={field.getOptionLabel}
        openOnFocus
        disableClearable
        classes={{ root: classes.fieldRoot }}
        freeSolo={field.freeSolo}
        renderInput={props => (
          <TextField
            {...props}
            label={field.label}
            InputLabelProps={{ classes: { root: classes.fieldLabel, shrink: classes.fieldLabelShrink }, shrink: true }}
            name={field.name}
            onBlur={e => setFieldValue(field.name, e.target.value)}
            InputProps={{
              classes: {
                root: classNames(classes.textFieldInputRoot, classes.autocompleteInputRoot),
                underline: classes.textInputUnderline,
              },
              ...props.InputProps,
            }}
          />
        )}
      />
    </div>
  )
}

export default AutocompleteFieldComponent
