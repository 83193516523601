import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '1.3rem',
      },
    },

    powerCard: {
      background: '#f7faff',
      padding: '1rem 2rem 2rem',
      boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.16)',
      marginTop: '2rem',

      // [theme.breakpoints.up('xs')]: {
      //   padding: '1rem',
      // },

      '& img': {
        width: '200px',
        marginTop: '2.5rem',
        objectFit: 'contain',
        height: 'auto',

        [theme.breakpoints.down('xs')]: {
          marginTop: '0',
          width: '100%',
        },
        [theme.breakpoints.only('lg')]: {
          width: '100%',
        },
      },

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .title-text': {
        color: '#307abc',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        fontWeight: 600,
      },
      '& .heading-text': {
        textTransform: 'uppercase',
        paddingTop: '1.3rem',
        fontSize: '1.25rem',
        color: '#292929',
        fontWeight: 900,
      },
      '& .sub-heading-text': {
        paddingTop: '0.7rem',
        color: '#292929',
        fontSize: '1rem',
        fontWeight: 600,
      },
      '& .sub-title-text': {
        paddingTop: '1rem',
        color: '#292929',
        fontSize: '0.9rem',
        fontWeight: 600,

        [theme.breakpoints.down('xs')]: {
          padding: '1rem 0',
        },
      },
      '& .content-text': {
        paddingTop: '1.5rem',

        '& ul': {
          paddingLeft: '1rem',
        },
        '& li': {
          // listStyleImage: `url(./check-solid.svg)`,
          listStyleType: "'✔'",
          paddingInlineStart: '1ch',
          wordWrap: 'break-word',
          // padding: '0 0.5rem',

          '&::marker': {
            color: '#5b38da',
          },
        },
      },
      '& .power-btn': {
        background: '#5b38da',
        color: '#fff',
        borderRadius: '2rem',
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        padding: '0.75rem 2rem',
      },

      '& .left-sec': {
        marginTop: '1rem',
        display: 'flex',
        // justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'flex-end',

        [theme.breakpoints.down('xs')]: {
          marginTop: '0',
          flexDirection: 'column-reverse',
        },
      },
    },
  })
)
