import { TextFieldWithDebounce } from '@jetkit/react'
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Link,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { IOrderDetailsResponse } from 'api/orders'
import shouldShowSharesInput from 'components/common/companies/shouldShowSharesInput'
import OutlinedSelect from 'components/common/outlinedSelect'
import BRTextField from 'components/common/textFields/BRTextField'
import CopyTextFieldWrapper from 'components/common/textFields/copyTextFieldWrapper'
import DatePicker from 'components/common/datePicker'
// import SelectAutocomplete from 'components/common/SelectAutocomplete'
import EinInput from 'components/common/textFields/einInput'
// import MoneyInputField from 'components/common/textFields/moneyInputField'
// import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import AddressComponent from 'components/orders/AddressComponent'
// import CountryList from 'country-list'
import { useStaff } from 'components/companies/hooks/useStaff'
import deepcopy from 'deepcopy'
import { IAddress } from 'models/address'
import { IDetailedCompanyResponse, ILegalType, legalTypesList, legalTypesToHumanReadableFormat } from 'models/company'
import { ValueOf } from 'utils/objectUtils'
import * as React from 'react'
import { formatDateForInputField, formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import Addresses from 'components/common/addresses'
import moment from 'moment'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { getstates } from 'api/addresses'
import showApiResponseError from 'utils/showApiResponseError'
import { updateCompany } from 'api/companies'
import showSuccessNotification from 'utils/showSuccessNotification'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import MeetingAddressComponent from './MeetingAddressComponent'
import { TaxStructureBusinessOptions } from 'models/taxOrder'
import { nameCheckByStateId } from 'api/namechecklist'
import showErrorNotification from 'utils/showErrorNotification'
import { OpenInNew } from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
// import { formatNaturalNumber } from 'utils/formatNumbers'
// import usaStates from 'utils/usaStates'

const fieldsWidth = '100%'

export const BUSINESS_FORMATION = 'Business Formation'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',

      '& .campany-detail': {
        fontSize: '1.6rem',
        fontFamily: theme.typography.h1.fontFamily,
      },
    },
    horizontalWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 0',
    },
    inlineHeading: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0',

      '& h1': {
        width: 'auto !important',
        paddingRight: '1rem',
      },
      '& button': {
        padding: '0',
        borderRadius: 0,
        borderBottom: '1px solid',
      },
    },
    formationOrderPriceWrapper: {
      width: fieldsWidth,
      margin: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    textField: {
      width: fieldsWidth,
      marginTop: 0,
      marginBottom: 0,
    },
    moneyTextField: {
      height: 'unset',
    },
    datePicker: {
      width: '75%',
    },
    einInput: {
      width: '100%',
      marginTop: '0.5rem',
    },
    addressSection: {
      padding: '0 12px',
      width: '100%',
      marginTop: '1rem',
    },
    saveButton: {
      marginTop: '0.65rem',
    },
    alignLeft: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      paddingLeft: '5rem',
      paddingRight: '0.5rem',
    },
  })
)

export interface IOrderCompanyDetailsInputFields {
  name: string
  country?: string
  state_of_formation?: string
  business_activity?: string
  file_date?: string
  address: IAddress
  ein?: string
  legal_type: ILegalType
  number_of_shares?: number
}

interface ICompanyDetails {
  errors: Record<string, boolean>
  orderDetails: IOrderDetailsResponse
  classes?: Record<string, string>
  onOrderDetailsFieldsChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => void
  isEditable?: boolean
  setIsUpdate?: any
  saveOrder(): Promise<void>
  setCompanyFieldsChanged(state: boolean): void
}

// const countriesData = CountryList.getData()

const getLegalType = (orderDetails: IOrderDetailsResponse) => {
  /**
   * This is needed because the legalType initial value comes to us in a format not like legalTypesList.
   * For the initial value we need to do mapping via legalTypesToHumanReadableFormat and for others we just use values from legalTypesList.
   */
  return orderDetails.company_details
    ? !legalTypesList.includes(orderDetails.company_details.legal_type)
      ? legalTypesToHumanReadableFormat[orderDetails.company_details.legal_type]
      : orderDetails.company_details.legal_type
    : ''
}

export const CompanyDetails: React.FC<ICompanyDetails> = props => {
  const classes = useStyles(props)
  const {
    errors,
    onOrderDetailsFieldsChange,
    orderDetails,
    isEditable = true,
    setIsUpdate,
    saveOrder,
    setCompanyFieldsChanged,
  } = props
  const { staff } = useStaff()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [namecheckloading, setnamecheckloading] = React.useState<boolean>(false)

  // Whether editing fields in this component is disabled
  const disabled = React.useMemo(() => !isEditable, [isEditable])
  const [directorMeetingDate, setdirectorMeetingDate] = React.useState<any>(
    orderDetails.company_details?.director_meeting.date || null
  )
  const [shareholderMeetingDate, setshareholderMeetingDate] = React.useState<any>(
    orderDetails.company_details?.shareholder_meeting.date || null
  )
  const taxStructureSelectOptions = React.useMemo(() => Object.values(TaxStructureBusinessOptions), [])

  React.useEffect(() => {
    // setdirectorMeetingDate(orderDetails.company_details?.director_meeting.date)
    // setshareholderMeetingDate(orderDetails.company_details?.shareholder_meeting.date)
    console.log('shareholderMeetingDate', shareholderMeetingDate)
    console.log('directorMeetingDate', directorMeetingDate)
    const func = async () => {
      const data = {
        client_meeting_date_time: directorMeetingDate, //orderDetails.company_details?.director_meeting?.date,
        shareholders_meeting_date_time: shareholderMeetingDate, //orderDetails.company_details?.shareholder_meeting?.date,
      }
      try {
        setLoading(true)
        await updateCompany(orderDetails.company_details?.id || 0, data)
        showSuccessNotification('Updated Successfully!')
        setLoading(false)
      } catch (error) {
        showApiResponseError(error, 'Error updating Details')
        setLoading(false)
      }
    }
    if (
      directorMeetingDate !== orderDetails.company_details?.director_meeting.date ||
      shareholderMeetingDate !== orderDetails.company_details?.shareholder_meeting.date
    )
      func()
  }, [
    shareholderMeetingDate,
    directorMeetingDate,
    orderDetails.company_details?.director_meeting.date,
    orderDetails.company_details?.shareholder_meeting.date,
    orderDetails.company_details?.id,
  ])
  const showSharesInput = React.useMemo(() => {
    if (!orderDetails.company_details) return false
    return shouldShowSharesInput(orderDetails.company_details.legal_type)
  }, [orderDetails])

  const legalType = React.useMemo(() => getLegalType(orderDetails), [orderDetails])
  // const [companyfieldsChanged, setCompanyFieldsChanged] = React.useState<boolean>(false)

  const onCompanyDetailsInputFieldsChange = React.useCallback(
    (key: keyof IDetailedCompanyResponse, value: ValueOf<IDetailedCompanyResponse>) => {
      if (!orderDetails.company_details) return
      // setIsUpdate(false)

      let newCompany: IDetailedCompanyResponse = deepcopy(orderDetails.company_details)
      newCompany = {
        ...newCompany,
        [key]: value,
      }

      onOrderDetailsFieldsChange('company_details', newCompany)
      // setIsUpdate(true)
      setCompanyFieldsChanged(true)
    },
    [onOrderDetailsFieldsChange, orderDetails.company_details]
  )

  const handleCreatorSelected = React.useCallback(
    async (incorporator: string | null) => {
      onCompanyDetailsInputFieldsChange('incorporator', incorporator)
    },
    [onCompanyDetailsInputFieldsChange]
  )

  // const saveCompanyDetails = React.useCallback(async () => {
  //   if (companyfieldsChanged) {
  //     const data: any = {
  //       name: orderDetails.company_details?.name,
  //       legalType: orderDetails.company_details?.legal_type,
  //       business_activity: orderDetails.company_details?.business_activity,
  //       state_of_formation_id: orderDetails.company_details?.state_of_formation_id,
  //       ein: orderDetails.company_details?.ein,
  //       incorporator: orderDetails.company_details?.incorporator,
  //       state_id: orderDetails.company_details?.state_id,
  //       file_date: orderDetails.company_details?.file_date,
  //       meeting_date: orderDetails.company_details?.meeting_date,
  //       number_of_shares: orderDetails.company_details?.number_of_shares,
  //       tax_structure: orderDetails.company_details?.tax_structure,
  //     }
  //     try {
  //       setLoading(true)
  //       await updateCompany(orderDetails.company_details?.id || 0, data)
  //       showSuccessNotification('Updated Successfully!')
  //       setLoading(false)
  //     } catch (error) {
  //       showApiResponseError(error, 'Error updating Details')
  //       setLoading(false)
  //     }
  //     console.log('orderDetails.company_details', orderDetails.company_details)
  //   }
  //   setLoading(true)
  //   await saveOrder()
  //   setLoading(false)
  // }, [orderDetails, companyfieldsChanged])

  const [states, setStates] = React.useState([])
  React.useEffect(() => {
    const states = async () => {
      const state = await getstates()
      setStates(state)
    }
    states()
  }, [])

  const stateOptions = () => {
    return states?.map((state: any) => ({
      value: state?.id,
      label: state?.state_display_name,
    }))
  }

  const checkName = async () => {
    try {
      setnamecheckloading(true)
      const res = await nameCheckByStateId(orderDetails.company_details?.state_of_formation_id || 0)
      if (res && res.name_check_url && res.name_check_url.length) {
        window.open(res.name_check_url, '_blank')
        setnamecheckloading(false)
      } else {
        showErrorNotification('No Url provided for this state')
        setnamecheckloading(false)
      }
      //showSuccessNotification('Sent Successfully')
    } catch (e) {
      showApiResponseError(e, 'Error: Something went wrong! ')
      setnamecheckloading(false)
    }
  }

  console.log('directorMeetingDate', directorMeetingDate)

  return (
    <div className={classes.root}>
      <div className={classes.inlineHeading}>
        <h1 className="campany-detail">Company Details</h1>
        <NavLink target="_blank" to={`/company/${orderDetails.company_details?.id}`}>
          <Button variant="text" color="primary" endIcon={<OpenInNew />}>
            View Company
          </Button>
        </NavLink>
      </div>

      {/* In order to add copy button to TextFieldWithDebounce, we put it in a wrapper, that
          has that button and accepts `string` value, that may be copied. */}
      <Grid container spacing={3}>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
          <CopyTextFieldWrapper
            style={{ width: fieldsWidth }}
            valueToCopy={orderDetails.company && orderDetails.company.name}
          >
            <TextFieldWithDebounce
              variant="outlined"
              error={errors.name}
              // titleCase
              helperText={errors.name && 'Please Specify Name For The Company'}
              label="Company Name"
              type="text"
              name="Company Name"
              margin="normal"
              fullWidth
              style={{ width: '100%' }}
              className={classes.textField}
              disabled={disabled}
              value={orderDetails.company_details ? orderDetails.company_details.name : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onCompanyDetailsInputFieldsChange('name', event.target.value)
              }}
            />
          </CopyTextFieldWrapper>
          <ButtonWithLoading
            style={{ width: '250px', marginLeft: '0.5rem', minHeight: '56px' }}
            // wrapperClassName={classes.nameCheckBtn}
            isLoading={namecheckloading}
            disabled={disabled}
            onClick={checkName}
          >
            Name Check
          </ButtonWithLoading>
        </Grid>

        <Grid item xs={3}>
          <OutlinedSelect
            showCopyButton
            name="Company Type"
            error={errors.companyType}
            errorHelperText="Please Specify Company Type"
            style={{ width: showSharesInput ? fieldsWidth : '100%', margin: 0 }}
            value={legalType}
            disabled={disabled}
            options={legalTypesList}
            onOptionSelect={value => {
              onCompanyDetailsInputFieldsChange('legal_type', value as ILegalType)
            }}
          />
        </Grid>

        <Grid item xs={9}>
          <BRTextField
            showCopyButton
            value={
              (orderDetails && orderDetails.company_details && orderDetails.company_details.business_activity) || ''
            }
            multiline
            type="text"
            // title="Order Type"
            name="Business Activity"
            placeholder="Business Activity"
            label="Business Activity"
            className={classes.textField}
            variant="outlined"
            rows={5}
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onCompanyDetailsInputFieldsChange('business_activity', event.target.value)
            }
          />
        </Grid>

        <Grid item xs={3}>
          {/* <CopyTextFieldWrapper
            style={{ width: fieldsWidth }}
            valueToCopy={
              orderDetails && orderDetails.company_details && orderDetails.company_details.state_of_formation
            }
          > */}
          {/* <TextFieldWithDebounce
              variant="outlined"
              label="State of Formation"
              titleCase
              type="text"
              className={classes.textField}
              style={{ width: '100%' }}
              name="State of Formation"
              disabled={disabled}
              value={
                (orderDetails && orderDetails.company_details && orderDetails.company_details.state_of_formation) || ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onCompanyDetailsInputFieldsChange('state_of_formation', event.target.value)
              }
            /> */}
          <div data-testid="state-of-formation" className="select-notification-template">
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel>State of Formation</InputLabel>
              <Select
                variant="outlined"
                onChange={(event: any) =>
                  onCompanyDetailsInputFieldsChange('state_of_formation_id', event.target.value)
                }
                label="State of Formation"
                name="stateOfFormation"
                value={
                  (orderDetails &&
                    orderDetails.company_details &&
                    orderDetails.company_details.state_of_formation_id) ||
                  ''
                }
                fullWidth
                disabled={disabled}
              >
                {stateOptions()?.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* </CopyTextFieldWrapper> */}
          <EinInput
            showCopyButton
            value={
              orderDetails.company_details && orderDetails.company_details.ein ? orderDetails.company_details.ein : ''
            }
            onChange={(value: string) => onCompanyDetailsInputFieldsChange('ein', value)}
            disabled={disabled}
            label="EIN Number"
            classes={{ container: classes.einInput }}
          />
        </Grid>

        <Grid item xs={orderDetails.company_details?.company_type === 2 ? 6 : 9}>
          <Autocomplete
            options={staff.map(user => user.name)}
            className={classes.textField}
            freeSolo
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                name="Incorporator"
                disabled={disabled}
                error={errors.incorporator}
                variant="outlined"
                helperText={errors.incorporator && 'Please Select An Incorporator Client'}
                label="Incorporator"
                InputProps={{ ...params.InputProps }}
                onBlur={event => handleCreatorSelected(event.target.value)}
              />
            )}
            onChange={(_, value) => handleCreatorSelected(value)}
            value={
              orderDetails.company_details && orderDetails.company_details.incorporator
                ? orderDetails.company_details.incorporator
                : ''
            }
          />
        </Grid>

        {orderDetails.company_details?.company_type === 2 ? (
          <Grid item xs={3}>
            <BRTextField
              label="Number Of Share"
              placeholder="Number Of Share"
              value={orderDetails.company_details && orderDetails.company_details?.number_of_shares}
              onChange={event => onCompanyDetailsInputFieldsChange('number_of_shares', event.target.value)}
              className={classes.textField}
              variant="outlined"
              type="text"
              showCopyButton
              disabled={disabled}
            />
          </Grid>
        ) : (
          ''
        )}

        <Grid item xs={3}>
          <BRTextField
            label="State ID Number"
            placeholder="State ID Number"
            value={orderDetails.company_details && orderDetails.company_details.state_id}
            onChange={event => onCompanyDetailsInputFieldsChange('state_id', event.target.value)}
            className={classes.textField}
            variant="outlined"
            type="text"
            showCopyButton
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <TextFieldWithDebounce
              variant="outlined"
              error={errors.name}
              titleCase
              onChange={event => onCompanyDetailsInputFieldsChange('file_date', event.target.value)}
              label="File Date"
              type="text"
              name="File"
              margin="normal"
              fullWidth
              style={{ width: '100%' }}
              className={classes.textField}
              disabled={disabled}
              value={moment(orderDetails.company_details?.file_date || '').format('MM/DD/YYYY')}
            /> */}
          <DatePicker
            style={{ width: '100%' }}
            label="File Date"
            value={orderDetails.company_details?.file_date}
            onDateSelected={value => onCompanyDetailsInputFieldsChange('file_date', value)}
            disabled={disabled}
          >
            <CopyTextFieldWrapper
              style={{ width: fieldsWidth }}
              valueToCopy={orderDetails.company_details?.file_date || ''}
            ></CopyTextFieldWrapper>
          </DatePicker>
          {orderDetails.submitted_on && (
            <i style={{ color: 'gray' }}>Submission Date: {formatDateTimeToHumanFriendly(orderDetails.submitted_on)}</i>
          )}
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            style={{ width: '100%' }}
            label="Meeting Date "
            value={orderDetails.company_details?.meeting_date}
            onDateSelected={value => onCompanyDetailsInputFieldsChange('meeting_date', value)}
            disabled={disabled}
          >
            <CopyTextFieldWrapper
              style={{ width: fieldsWidth }}
              valueToCopy={orderDetails.company_details?.meeting_date || ''}
            ></CopyTextFieldWrapper>
          </DatePicker>
        </Grid>
        <Grid item xs={3}>
          {/* <BRTextField
            label="Tax Structure"
            placeholder="Tax Structure"
            value={orderDetails.company_details && orderDetails.company_details?.tax_structure}
            onChange={event => onCompanyDetailsInputFieldsChange('tax_structure', event.target.value)}
            className={classes.textField}
            variant="outlined"
            type="text"
            showCopyButton
            disabled={disabled}
          /> */}

          <OutlinedSelect
            // classes={{ formControl: classes.selectContainer }}
            name={'Tax Structure'}
            value={orderDetails.company_details && orderDetails.company_details?.tax_structure}
            options={taxStructureSelectOptions}
            onOptionSelect={value => onCompanyDetailsInputFieldsChange('tax_structure', value as string)}
            disabled={disabled}
          />
        </Grid>

        {/* <div className={classes.alignLeft}>
          <Grid item xs={3}>
            <ButtonWithLoading
              disabled={disabled}
              style={{ marginTop: '0.65rem' }}
              isLoading={loading}
              onClick={saveCompanyDetails}
            >
              Save Details
            </ButtonWithLoading>
          </Grid>
        </div> */}

        <div className={classes.addressSection}>
          <h1 className="campany-detail" style={{ marginBottom: '0' }}>
            Business Adresses
          </h1>

          <AddressComponent
            orderDetails={orderDetails}
            onCompanyDetailsInputFieldsChange={onCompanyDetailsInputFieldsChange}
            isEditable={isEditable}
          />
        </div>

        <Grid container className={classes.addressSection}>
          <h1 className="campany-detail" style={{ marginBottom: '0' }}>
            Meeting Report
          </h1>

          <Grid item xs={6}>
            {/* <DatePicker
              label="Director Meeting Date"
              value={
                orderDetails.company_details && directorMeetingDate
                  ? formatDateForInputField(
                      moment(directorMeetingDate)
                        .format('YYYY-MM-DD')
                        .toString()
                    )
                  : ''
              }
              onDateSelected={date => {
                //if (orderDetails?.company_details) orderDetails.company_details.director_meeting.date = date
                setdirectorMeetingDate(date)
              }}
              classes={{ root: classes.datePicker }}
              style={{ width: '100%' }}
              disabled={disabled}
            /> */}
            <br />
            <KeyboardDateTimePicker
              value={
                // orderDetails.company_details && directorMeetingDate
                //   ? formatDateForInputField(
                //       moment(directorMeetingDate)
                //         .format('YYYY-MM-DD')
                //         .toString()
                //     )
                //   :
                // formatDateForInputField(
                //   moment(directorMeetingDate)
                //     .format('YYYY-MM-DD')
                //     .toString()
                // )
                directorMeetingDate
              }
              onChange={value => {
                console.log(value, 'directorMeetingDate')
                setdirectorMeetingDate(value?.toString())
                // saveCompanyDetails()
              }}
              style={{ width: '100%' }}
              format="MM/DD hh:mm A"
              label="Directors Meeting Date And Time"
              autoOk
              inputVariant="outlined"
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={6} style={{ paddingLeft: '0.85rem' }}>
            {/* <DatePicker
              label="Shareholder Meeting Date"
              value={
                orderDetails.company_details && shareholderMeetingDate
                  ? formatDateForInputField(
                      moment(shareholderMeetingDate)
                        .format('YYYY-MM-DD')
                        .toString()
                    )
                  : ''
              }
              onDateSelected={date => setshareholderMeetingDate(date)}
              classes={{ root: classes.datePicker }}
              style={{ width: '100%' }}
              disabled={disabled}
            /> */}
            <br />
            <KeyboardDateTimePicker
              value={
                // orderDetails.company_details && shareholderMeetingDate
                //   ? formatDateForInputField(
                //       moment(shareholderMeetingDate)
                //         .format('YYYY-MM-DD')
                //         .toString()
                //     )
                //   : ''
                shareholderMeetingDate
              }
              onChange={value => {
                setshareholderMeetingDate(value?.toString())
              }}
              style={{ width: '100%' }}
              format="MM/DD hh:mm A"
              label="Shareholders Meeting Date And Time"
              autoOk
              inputVariant="outlined"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <Addresses
              label="Directors Meeting Address"
              onlyOneAddress={true}
              // onAddAddressClick={() => {console.log('');
              // }}
              addresses={
                orderDetails.company_details?.director_meeting?.address
                  ? [orderDetails.company_details?.director_meeting?.address]
                  : [
                      {
                        city: '',
                        country: '',
                        is_primary: false,
                        is_mailing: false,
                        locality: '',
                        postal_code: '',
                        street: 'No Address Available.',
                      },
                    ]
              }
              onAddressEditClick={() => {
                console.log('')
              }}
              // onAddressDeleteClick={id => handleAddressDeleteClick('mailingAddress', id)}
              isEditable={true}
              isDeletable={false}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: '0.85rem' }}>
            <Addresses
              label="ShareHolders Meeting Address"
              onlyOneAddress={true}
              // onAddAddressClick={() => {}}
              addresses={
                orderDetails.company_details?.shareholder_meeting?.address
                  ? [orderDetails.company_details?.shareholder_meeting?.address]
                  : [
                      {
                        city: '',
                        country: '',
                        is_primary: false,
                        is_mailing: false,
                        locality: '',
                        postal_code: '',
                        street: 'No Address Available.',
                      },
                    ]
              }
              onAddressEditClick={() => {
                console.log('')
              }}
              // onAddressDeleteClick={id => handleAddressDeleteClick('mailingAddress', id)}
              isEditable={false}
              isDeletable={false}
            /> */}

            {/* <Addresses
              label="Address"
              customClass
              customClassName="address-box"
              onlyOneAddress={true}
              onAddAddressClick={handleAddAddressClick}
              addresses={prospectAddress || []}
              onAddressEditClick={handleAddressEditClick}
              onAddressDeleteClick={handleAddressDeleteClick}
              isDeletable={false}
            /> */}
            <MeetingAddressComponent
              orderDetails={orderDetails}
              onCompanyDetailsInputFieldsChange={onCompanyDetailsInputFieldsChange}
              isEditable={isEditable}
            />
          </Grid>
          {/* <AddressComponent
            orderDetails={orderDetails}
            onCompanyDetailsInputFieldsChange={onCompanyDetailsInputFieldsChange}
            isEditable={isEditable}
          /> */}
        </Grid>
      </Grid>
    </div>
  )
}

export default CompanyDetails

// FIXME: #372 break down this file into smaller components
