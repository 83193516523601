import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'
import { KPI_ENTITY_TYPES } from 'utils/constant'

// const headColumns: HeadColumn[] = [
//   { id: 'name', label: 'Name', style: {}, sortable: true },
//   { id: 'description', label: 'Description', style: { width: '50%' }, sortable: true },
//   { id: 'actions', label: 'actions', style: {}, sortable: false },
// ]

const KpiDetailTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
  type: string
  special_offer?: boolean
}> = ({ sortSettings, handleSortingClick, type, special_offer }) => {
  const setHeader = (type: string) => {
    switch (type) {
      case KPI_ENTITY_TYPES.client:
        return [
          { id: 'id', label: 'Id', style: {}, sortable: false },
          { id: 'Name', label: 'Name', style: {}, sortable: false },
          { id: 'email', label: 'email', style: {}, sortable: false },
        ]
        break
      case KPI_ENTITY_TYPES.company:
        {
          const header = [
            { id: 'id', label: 'Id', style: {}, sortable: false },
            { id: 'Name', label: 'Name', style: {}, sortable: false },
            { id: 'company_type', label: 'Company Type', style: {}, sortable: false },
          ]

          special_offer && header.push({ id: 'created_at', label: 'Time Selected', style: {}, sortable: false })
          return header
        }
        break
      case KPI_ENTITY_TYPES.order:
        return [
          { id: 'id', label: 'Id', style: {}, sortable: false },
          { id: 'Order_type', label: 'Order Type', style: {}, sortable: false },
          { id: 'company_name', label: 'Company Name', style: {}, sortable: false },
        ]
        break
      default:
        break
    }
  }
  return (
    <SortableTableHead
      headColumns={setHeader(type)}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default KpiDetailTableHead
