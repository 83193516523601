import copy from 'copy-to-clipboard'
import { notify } from '@jetkit/react'

const copyToClipboard = (value: string | undefined) => {
  if (!value || value.trim() === '') return
  copy(value)
  notify.success('Copied to clipboard!')
}

export default copyToClipboard
