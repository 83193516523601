import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { Moment } from 'moment'

export interface PromoCodesProps {
  id?: number
  code?: string
  expiry?: Moment
  type?: string
  value?: number
  times_used?: number
  max_usage?: number
}

export const getPromoCode = () =>
  requestPaginated<any>({
    url: '/promocodes',
  })

// export const getPromoCodeById = async (id: number): Promise<[]> => (await apiClient.get(`promocodes/${id}`)).data

export const addPromoCode = async (promocodeData: PromoCodesProps): Promise<any> =>
  await apiClient.post('/promocodes', promocodeData)

export const deletePromoCode = async (id: number): Promise<AxiosResponse<void>> => apiClient.delete(`/promocodes/${id}`)
