import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'

export interface MissingItemProps {
  id?: number
  name: string
  description: string
  tax_order?: boolean
}

export const getMissingItems = () =>
  requestPaginated<any>({
    url: '/missingitems',
  })

export const getMissingItemById = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.get(`/missingitems/${id}`)

export const deleteMissingItems = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/missingitems/${id}`)

export const createMissingItems = async (missingData: MissingItemProps): Promise<AxiosResponse<void>> =>
  await apiClient.post('/missingitems', missingData)

export const editMissingItems = async (id: number, notificationData: MissingItemProps): Promise<AxiosResponse<void>> =>
  await apiClient.patch(`missingitems/${id}`, notificationData)
