import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'ip_address', label: 'IP Address', style: { maxWidth: '9%' }, sortable: true },
  { id: 'brid', label: 'BRID', style: { maxWidth: '100px' }, sortable: true },
  { id: 'visit_time', label: 'Visit Time', style: { maxWidth: '9%' }, sortable: true },
  { id: 'ref_code', label: 'Ref Code', style: {}, sortable: true },
  { id: 'utm_campaign', label: 'utm_campaign', style: { maxWidth: '9%' }, sortable: true },
  { id: 'utm_content', label: 'utm_content', style: { maxWidth: '9%' }, sortable: true },
  { id: 'utm_medium', label: 'utm_medium', style: { maxWidth: '9%' }, sortable: true },
  { id: 'utm_source', label: 'utm_source', style: { maxWidth: '9%' }, sortable: true },
  { id: 'gclid', label: 'gclid', style: { maxWidth: '100px' }, sortable: true },
  { id: 'sscid', label: 'sscid', style: { maxWidth: '9%' }, sortable: true },
  { id: 'fbclid', label: 'fbclid', style: { maxWidth: '9%' }, sortable: true },
  { id: 'msclkid', label: 'msclkid', style: { maxWidth: '9%' }, sortable: true },
  // { id: 'other_params', label: 'Other', style: {}, sortable: true },
]

const LogsHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default LogsHead
