import TextField from '@material-ui/core/TextField'
import { createNote } from 'api/note'
import { ISuggestionAttribute } from 'components/interfaces'
import { emptyNote, NoteType } from 'models/note'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import PrimaryButton from '../buttons/primaryButton'
import OutlinedSelect from '../outlinedSelect'
import useNotes from './hooks'
import NoteEntitySelector, { ISelectedEntities, NoteEntities } from './NoteEntitySelector'
import useStyles from './styles'
import useRouter from 'hooks/router'
import useRouter2 from 'use-react-router'

interface IAddNoteProps {
  colors?: Record<NoteType, string> | {}
  defaultType?: NoteType
  onCreated?: () => void
}

export interface INoteEntitiesContext {
  types: NoteType[]
  task?: ISuggestionAttribute
  client?: ISuggestionAttribute
  company?: ISuggestionAttribute
  order?: ISuggestionAttribute
  prospect?: ISuggestionAttribute
  taxorder?: ISuggestionAttribute
}

export const NoteEntitiesContext = React.createContext<INoteEntitiesContext | undefined>(undefined)

NoteEntitiesContext.displayName = 'NoteEntitiesContext'

function AddNote({ onCreated, defaultType = 'general' }: IAddNoteProps) {
  const classes = useStyles()
  const router = useRouter2()
  const { history } = useRouter()

  const notesContext = React.useContext(NoteEntitiesContext)
  // defaultType = notesContext ? notesContext?.types[0] : 'general'

  console.log('noteEntities defaultType', defaultType)

  const { newNote, setNewNote, updateNewNote } = useNotes({ defaultType })

  const [noteEntities, setNoteEntities] = React.useState<NoteEntities>({})

  console.log(' noteEntities.types noteEntities.types', notesContext, newNote)

  // console.log('his', router.match.params.id, router);

  const setSelectedNoteTypeInNewNote = React.useCallback(() => {
    // this add note dialog can only have 1 element in noteEntities
    const noteType: NoteType = noteEntities.types?.[0] ?? defaultType

    setNewNote(prevState => ({
      ...prevState,
      note_type: noteType,
    }))
  }, [defaultType, noteEntities.types, setNewNote])

  React.useEffect(() => {
    setSelectedNoteTypeInNewNote()
  }, [defaultType, noteEntities.types, setNewNote, setSelectedNoteTypeInNewNote])

  React.useEffect(() => {
    if (router.match.path.includes('company-order')) {
      handleNoteTypeSelect('order')
    }
    if (router.match.path.includes('company/')) {
      handleNoteTypeSelect('company')
    }
    if (router.match.path.includes('tax-orders/')) {
      handleNoteTypeSelect('taxorder')
    }
    if (router.match.path.includes('client/')) {
      handleNoteTypeSelect('client')
    }
  }, [])
  const [isCreating, setIsCreating] = React.useState<boolean>(false)

  const saveNote = React.useCallback(async () => {
    try {
      setIsCreating(true)
      await createNote(newNote)
      setNewNote(emptyNote)
      setIsCreating(false)
      showSuccessNotification('Note successfully created')
      if (onCreated) {
        onCreated()
      }
    } catch (error) {
      showApiResponseError(error, 'Failed to create note')
    }
  }, [newNote, onCreated, setNewNote])

  const handleEntitiesSelected = React.useCallback(
    (selectedEntities: ISelectedEntities) => {
      const entityInfo: ISelectedEntities = {
        company_id: newNote.note_type === 'company' ? selectedEntities['company_id'] : undefined,
        client_id: newNote.note_type === 'client' ? selectedEntities['client_id'] : undefined,
        order_id: newNote.note_type === 'order' ? selectedEntities['order_id'] : undefined,
        tax_order_id: newNote.note_type === 'taxorder' ? selectedEntities['tax_order_id'] : undefined,
        // task_id: newNote.note_type === 'task' ? selectedEntities['task_id'] : undefined,
      }
      setNewNote(prevState => ({
        ...prevState,
        ...entityInfo,
      }))
      // if you pass newNote it will be called in infinite loop
      // eslint-disable-next-line
    },
    [setNewNote, newNote.note_type]
  )

  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (!newNote.note_text.trim().length || isCreating) {
      setSaveButtonDisabled(true)
    } else if (newNote.note_type === 'company') {
      setSaveButtonDisabled(!noteEntities || !noteEntities['company_id'])
    } else if (newNote.note_type === 'client') {
      setSaveButtonDisabled(!noteEntities || !noteEntities['client_id'])
    } else if (newNote.note_type === 'order') {
      setSaveButtonDisabled(!noteEntities || !noteEntities['order_id'])
    } else if (newNote.note_type === 'taxorder') {
      setSaveButtonDisabled(!noteEntities || !noteEntities['tax_order_id'])
    }
    // else if (newNote.note_type === 'task') {
    //   setSaveButtonDisabled(!noteEntities || !noteEntities['task_id'])
    // }
    else {
      setSaveButtonDisabled(false)
    }
  }, [setSaveButtonDisabled, newNote, noteEntities, isCreating])

  const handleNoteTypeSelect = React.useCallback((value: unknown) => {
    const selectedNoteType = value as NoteType
    setNoteEntities({ types: [selectedNoteType] })
  }, [])

  return (
    <div>
      <OutlinedSelect
        name="Note Type"
        value={newNote.note_type ?? ''}
        options={['client', 'company', 'order', 'general', 'taxorder']}
        onOptionSelect={handleNoteTypeSelect}
      />
      <NoteEntitySelector
        strict={true}
        noteEntities={noteEntities}
        setNoteEntities={setNoteEntities}
        onEntitySelected={handleEntitiesSelected}
      />
      <div className={classes.newNoteContainer}>
        <TextField
          classes={{ root: classes.textFieldRoot }}
          style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
          multiline={true}
          type="text"
          rowsMax={4}
          rows={4}
          margin="normal"
          variant="outlined"
          value={newNote.note_text}
          onChange={e => updateNewNote('note_text', e.target.value)}
        />
        <PrimaryButton style={{ width: '100%' }} disabled={saveButtonDisabled} onClick={saveNote}>
          Save
        </PrimaryButton>
      </div>
    </div>
  )
}

export default AddNote
