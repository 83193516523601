import React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { NotesData } from '.'
import { PROSPECT_NOTES_CREATORS } from 'utils/constant'

interface NoteBoxProps {
  note: NotesData
}

const NoteBox: React.FC<NoteBoxProps> = ({ note }: NoteBoxProps) => {
  const { creator, note_text, created_on, created_by, sent_notification_id } = note
  return (
    <>
      {/* {creator?.includes(PROSPECT_NOTES_CREATORS.Employee) ||
      (creator?.includes(PROSPECT_NOTES_CREATORS.Affiliate_Client) && !sent_notification_id) ? ( */}
      {(creator?.includes(PROSPECT_NOTES_CREATORS.Employee) && !sent_notification_id) ||
      creator?.includes(PROSPECT_NOTES_CREATORS.Affiliate_Client) ? (
        <div className={`${'note-chatbox-section'}`}>
          <div className="inline-text">
            <p className="title-text">{created_by}</p>
            <p className="date-text">{formatDateTimeToHumanFriendly(created_on || '')}</p>
          </div>
          <p className="note-text" dangerouslySetInnerHTML={{ __html: note_text || '' }} />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default NoteBox
