import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import classnames from 'classnames'
import AddNoteDialog from './addNoteDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
    },
    notesTitle: {
      marginRight: 19,
      fontSize: 14,
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    notesTitleWrapper: {
      display: 'flex',
    },
    notesHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
)

interface IAddNoteHeaderProps {
  classes?: Record<string, string>
  onCreated?: () => void
  accessoryView?: React.ReactNode
}
function AddNoteHeader({ classes, onCreated, accessoryView }: IAddNoteHeaderProps) {
  const onwClasses = useStyles()
  const [addNoteOpen, setAddNoteOpen] = React.useState<boolean>(false)

  return (
    <React.Fragment>
      <AddNoteDialog
        open={!!addNoteOpen}
        onClose={() => setAddNoteOpen(false)}
        onCreated={() => {
          if (onCreated) onCreated()
          setAddNoteOpen(false)
        }}
      />
      <div className={onwClasses.notesHeader}>
        <div className={classnames(onwClasses.notesTitleWrapper, classes && classes.root)}>
          <Typography className={onwClasses.notesTitle}>Notes</Typography>
          <Link
            className={onwClasses.link}
            onClick={() => {
              setAddNoteOpen(true)
            }}
          >
            + Add Note
          </Link>
        </div>
        {accessoryView}
      </div>
    </React.Fragment>
  )
}

export default AddNoteHeader
