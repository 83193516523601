import Dialog from '@material-ui/core/Dialog'
import { createOrderTask, updateOrderTask } from 'api/orders'
import { fetchSuggestions } from 'api/suggestions'
import CreateTaskForm from 'components/tasks/create/form'
import { IOrderTask, ITaskType, TaskSelectKeys } from 'models/task'
import moment from 'moment'
import { OrderDetailsContext } from 'pages/orders/orderDetails/OrderDetailsContext'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'

export type AddTaskDialogMode = 'create' | 'update' | 'noapi'

interface IAddTaskDialogProps {
  open: boolean
  onClose(): void
  onTaskCreated(task?: IOrderTask): void
  orderId: number
  task?: IOrderTask // `undefined` when creating a task
  mode: AddTaskDialogMode
  companyId?: number
}

interface IAddTaskDialogErrors {
  title?: boolean
}

export const emptyInputFields: any = {
  title: '',
  details: '',
  completed: null,
  created_by: null,
  parent_id: null,
  status: 'processing',
  doc_status: 'open',
  can_have_docs: true,
  task_type: 'order',
  priority: 'low',
  is_activated: false,
}

/**
 * Allows to create/edit tasks
 *
 * @param mode: Decides whether we create a new task or update an existing one.
 * If the `noapi` mode is used, no request gets sent.
 * This allows to use the resulting `IOrderTask` object from this component later instead of immediately hitting the API.
 *
 * @param onTaskCreated: Triggered when saving the task. Allows to use the resulting `IOrderTask` object from this component.
 */
const AddTaskDialog: React.FC<IAddTaskDialogProps> = ({
  open,
  onClose,
  onTaskCreated,
  orderId,
  task,
  mode,
}: IAddTaskDialogProps) => {
  const [errors, setErrors] = React.useState<IAddTaskDialogErrors>({})

  const [inputFields, setInputFields] = React.useState<IOrderTask>({ ...emptyInputFields, ...task })

  const orderDetails = React.useContext(OrderDetailsContext)

  React.useEffect(() => {
    // console.log('taskkkk', task)

    if (open) {
      unsetErrors()

      setInputFields(
        task
          ? {
              ...emptyInputFields,
              ...task,
              assigned_to: task.assigned_to,
            }
          : {
              ...emptyInputFields,
              order_id: orderDetails && orderDetails.id,
            }
      )
    }
  }, [open, task, orderDetails])

  const handleAddTask = async () => {
    if (!validateInput()) return
    inputFields.due_date = inputFields.due_date
      ? moment(inputFields.due_date)
          ?.format('ddd MMM DD YYYY 00:00:00 Z')
          .toString()
      : moment()
          ?.format('ddd MMM DD YYYY 00:00:00 Z')
          .toString()
    try {
      if (mode === 'create') {
        await createOrderTask(orderId, {
          ...inputFields,
          // details: inputFields?.task_note,
          // description: inputFields?.task_note,
        })
      } else if (mode === 'update') {
        debugger
        await updateOrderTask(orderId, {
          ...inputFields,
          // details: inputFields?.task_note
          //   ? (inputFields?.description || '')?.concat(` ${inputFields?.task_note || ''}`)
          //   : '',
          // description: inputFields?.task_note,
          // details: inputFields?.task_note,
        })
      }
    } catch (err) {
      console.warn(err)
      showApiResponseError(err, `Could not ${mode === 'create' ? 'create' : 'update'} a task`)
    } finally {
      onTaskCreated(inputFields)
      setInputFields(emptyInputFields)
      mode !== 'noapi' && showSuccessNotification(`Task has been ${mode === 'create' ? 'created' : 'updated'}`)
      onClose()
    }
  }

  const handleInputChange = (field: string, value: string | number) => {
    if (field === 'title' && typeof value === 'string' && value.length) {
      unsetErrors()
    }
    setInputFields(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const unsetErrors = () => {
    setErrors(prevState => ({
      ...prevState,
      title: false,
    }))
  }

  const validateInput = () => {
    if (!inputFields.title) {
      setErrors(prevState => ({
        ...prevState,
        title: true,
      }))
      return false
    }
    return true
  }

  const addAttribute = React.useCallback(
    (field: TaskSelectKeys, id: ValueOf<Pick<IOrderTask, TaskSelectKeys>>) => {
      setInputFields(prevTask => ({
        ...prevTask,
        [field]: id,
      }))
    },
    [setInputFields]
  )

  const handleTaskTypeChange = React.useCallback(
    (taskType: ITaskType) => {
      setInputFields(prevState => ({
        ...prevState,
        task_type: taskType,
        company_id: null,
        client_id: null,
        order_id: null,
        company: undefined,
        client: undefined,
      }))
    },
    [setInputFields]
  )

  return (
    <Dialog maxWidth="lg" open={open}>
      <CreateTaskForm
        mode={mode}
        closeDrawer={onClose}
        task={inputFields}
        errors={errors}
        submitForm={handleAddTask}
        changeStringValue={handleInputChange}
        addAttribute={addAttribute}
        fetchSuggestions={fetchSuggestions}
        handleTaskTypeChange={handleTaskTypeChange}
        confButtonTitle={task ? 'Update Task' : 'Add Task'}
        disabled={false}
      />
    </Dialog>
  )
}

export default AddTaskDialog
