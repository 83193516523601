import * as React from 'react'

const useToggle = (initialValue: boolean): [boolean, () => void] => {
  const [value, setValue] = React.useState(initialValue)

  const toggleValue = React.useCallback(() => setValue(currentValue => !currentValue), [])

  return [value, toggleValue]
}

export default useToggle
