import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '0 15px 50px 50px',
      flexGrow: 1,

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
      },
      '& .secondary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '24px',
        color: '#202124',
        fontWeight: 600,
        paddingTop: '1.5rem',
        paddingBottom: '1rem',
      },
    },

    subContainer: {
      paddingTop: '85px',

      '& .outer-sec': {
        background: '#f6f8fb',
        padding: '1rem',
        // textAlign: 'center',

        '& .MuiGrid-item': {
          fontWeight: 500,
          fontSize: '16px',
        },
        '& .border-left': {
          borderRight: '2px solid #dfdada',
          marginRight: '1rem',
        },
      },

      '& .bell-icon': {
        color: '#fc1111',
      },

      '& .heading-divider': {
        background: '#788190',
        marginTop: '1rem',
      },

      '& .search-filed-container': {
        background: '#f7f8f9',
        borderRadius: '4px',

        '& .MuiInputBase-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '10.5px',
          },
        },
      },
    },

    innerItems: {
      minHeight: '50px',
      width: '100%',
      marginTop: '1.25rem',

      '& .start-btn': {
        '&  Button': {
          background: '#5a7495',
          color: '#fff',
        },
      },
      '& .end-btn': {
        '&  Button': {
          background: '#a91739a6',
          color: '#fff',
        },
      },
      '& .schedule-text': {
        margin: '0.5rem 0',
        fontWeight: 400,
      },

      '& .header-controls': {
        display: 'flex',
        justifyContent: 'space-between',

        '& .right-sec': {
          width: '80%',
          [theme.breakpoints.down('lg')]: {
            width: '76%',
          },
        },

        '& .btn-sec': {
          display: 'flex',

          '& .container': {
            margin: '5rem',
            '& .button': {
              background: '#4ca38e',
              marginLeft: '1rem',
              color: '#fff',
            },
          },
        },

        '& .inProgress-btn': {
          background: '#41c576',
          color: '#fff',
          marginRight: '1rem',
        },
        '& .complete-btn': {
          background: '#307abc',
          color: '#fff',
        },
        '& .copy-btn': {
          background: '#a030bc7a',
          color: '#fff',
          marginLeft: '1rem',
          marginRight: '1rem',
        },
        '& .namecheck-btn': {
          // background: '#a030bc7a',
          // color: '#fff',
          marginLeft: '2rem',
        },
        '& .create-btn': {
          background: '#4ca38e',
          marginLeft: '1rem',
          color: '#fff',
          width: 'auto',
        },
        '& .company-btn': {
          background: '#ff7171',
          color: '#fff',
          marginLeft: '1rem',
        },
        '& .link-btn': {
          color: '#788190',
          padding: '0 1rem',
        },

        '& .schedule-filed': {
          width: '50%',
          margin: '0 1rem',

          [theme.breakpoints.down('lg')]: {
            width: '35%',
          },
        },
      },

      '& .item-divider': {
        marginTop: '1.25rem',
      },

      '& .text-box-grid': {
        margin: '2rem 0',
        marginTop: '1rem',
        width: '100%',

        '& .text-box': {
          background: '#f6f8fb',
          minHeight: '200px',
          padding: '1rem',

          '& .bottom-space': {
            '&:not(:last-child)': {
              marginBottom: '1rem',
            },
          },
        },

        '& .input-box': {
          '& .inputs': {
            display: 'flex',
            alignItems: 'center',
            background: '#f6f8fb',
            padding: '0.85rem 1rem',
            marginBottom: '0.9rem',
            overflowX: 'hidden',

            '& .input-dark-text': {
              color: '#020202',
              paddingRight: '2.5rem',
            },
            '& .input-light-text': {
              color: '#979797',
              overflowX: 'hidden',
              display: 'flex',
              flexWrap: 'wrap',
            },
          },
        },

        '& .dark-text': {
          color: '#020202',
          paddingBottom: '0.5rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          // fontSize: '1rem',
          display: 'flex',
          flexWrap: 'wrap',
        },

        '& .text-area': {
          resize: 'none',
          background: 'inherit',
          border: 'none',
          outline: 'none',
          width: '100%',

          '&::placeholder': {
            color: '#979797',
            fontSize: '2rem,',
          },
        },
        '& .save-btn': {
          '& .MuiButtonBase-root': {
            background: '#788190',
            color: '#fff',
            minWidth: '100px',
            height: 'auto',
          },
          background: '#788190',
          color: '#fff',
          width: '100px',
          height: 'auto',
        },
      },
    },
    dateField: {
      margin: 0,
      marginTop: 13,
      '& > div': {
        '& > div': {
          height: 50,
        },
      },
    },
    dueDatePicker: {
      width: '100%',
    },

    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },

    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      marginRight: 8,
      marginTop: 2,
      borderRadius: '100px',
    },
    text: {
      flexGrow: 1,
      textTransform: 'capitalize',
    },
  })
)
