import { usePagedTable } from '@jetkit/react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { fetchNotes } from 'api/note'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { INote } from 'models/note'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import { emptyFunction, removeNullAndUndefined } from 'utils/objectUtils'
import { INoteEntitiesContext, NoteEntitiesContext } from './addNote'
import Notes from './notes'

const useStyles = makeStyles({
  notesContainer: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  progressContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notesTable: {
    flexGrow: 1,
    minWidth: 360,
    maxWidth: 360,
  },
  notesButtonContainer: {
    width: 70,
  },
})

interface IGlobalNotesContext {
  setNoteEntities: (context: INoteEntitiesContext) => void
}
export const GlobalNotesContext = React.createContext<IGlobalNotesContext>({
  setNoteEntities: emptyFunction,
})

export const useGlobalNotesDrawer = () => {
  const [notesVisible, setNotesVisible] = React.useState<boolean>(false)
  const [noteEntities, setNoteEntities] = React.useState<INoteEntitiesContext>({ types: [] })

  React.useEffect(() => {
    if (!notesVisible) {
      setNoteEntities({ types: [] })
    }
  }, [notesVisible])

  return {
    notesVisible,
    setNotesVisible,
    setNoteEntities,
    noteEntities,
  }
}
interface IGlobalNotesDrawerProps {
  notesVisible: boolean
  setNotesVisible: React.Dispatch<React.SetStateAction<boolean>>
  noteEntitiesContext: INoteEntitiesContext
}

/**
 * GlobalNotesDrawer is a component to be used when you want to add Notes Panel to your page.
 *
 * @param noteEntitiescontext - context which represents which note entities are checked.
 *                              Note entities are types of notes (client notes, company notes etc)
 *                              and specific entity notes (specific order notes, specific task notes)
 *                              to be shown in notes panel.
 * @param notesVisible - is panel shown or `open notes` button
 * @param setNotesVisible
 */
const GlobalNotesDrawer: React.FC<IGlobalNotesDrawerProps> = ({
  noteEntitiesContext,
  notesVisible,
  setNotesVisible,
}) => {
  const classes = useStyles()

  const [searchOptions, setSearchOptions] = React.useState<NoteEntities>({})

  const memoApiCall = React.useMemo(() => fetchNotes(removeNullAndUndefined(searchOptions)), [searchOptions])

  const pagedTable = usePagedTable<INote>({
    apiCall: memoApiCall,
    queryParams: searchOptions,
  })

  const setSearchQuery = React.useCallback(
    (newQuery: string) => setSearchOptions(prevState => ({ ...prevState, query: newQuery })),
    [setSearchOptions]
  )

  return (
    <React.Fragment>
      <Drawer anchor="right" open={notesVisible}>
        <NoteEntitiesContext.Provider value={noteEntitiesContext || {}}>
          <NotesTableContext.Provider
            value={{
              pagedTable,
              searchOptions,
              setSearchQuery,
              setSearchOptions,
            }}
          >
            <Notes
              setModalIsOpen={setNotesVisible}
              classes={{ mainContainer: classes.notesContainer }}
              tableBodyClassName={classes.notesTable}
            />
          </NotesTableContext.Provider>
        </NoteEntitiesContext.Provider>
      </Drawer>
      {!notesVisible && (
        <div className={classes.notesButtonContainer}>
          <OpenNotesButton onClick={() => setNotesVisible(true)} />
        </div>
      )}
    </React.Fragment>
  )
}

export default GlobalNotesDrawer
