import { getKpiDashboardSpecialOffer } from 'api/kpiDashboard'
import React, { useEffect, useState } from 'react'
import PrimaryHeading from '../PrimaryHeading'
import { useStyles } from '../styles'
import { Divider, Grid } from '@material-ui/core'
import FilterDays from '../FilterDays'
import OfferDetail from '../OfferDetail'
import showApiResponseError from 'utils/showApiResponseError'
import moment from 'moment'
import DetailModal from '../kpiDetail/detailmodal'
import { KPI_ENTITY_TYPES, OFFER_TAKEN_FROM } from 'utils/constant'

interface SpecialOfferCardResponse {
  count: string
  offer_id: number
  title: string
  companies: number[]
  offer_taken_from: number
}

const SpecialOfferCard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<SpecialOfferCardResponse[]>([])
  const [ids, setIds] = useState<any[]>([])
  const [entityType, setEntityType] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)
  const [offer_id, setOfferId] = useState<number>(0)
  const [filteredDataFromEmail, setFilteredDataFromEmail] = useState<SpecialOfferCardResponse[]>([])
  const [fromEmail, setFromEmail] = useState(false)
  const [offerTakenFrom, setOfferTakenFrom] = useState<number>(OFFER_TAKEN_FROM.client_portal)

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  // useEffect(() => {
  //   const payload = {
  //     start_date: '03/17/2022',
  //     end_date: '03/24/2022',
  //   }
  //   getKpiDashboardSpecialOffer(payload)
  // }, [])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
        from_email: false,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          let res = await getKpiDashboardSpecialOffer(payload)
          setFilteredData(res)
          payload.from_email = true
          res = await getKpiDashboardSpecialOffer(payload)
          setFilteredDataFromEmail(res)

          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])
  const handleModalOpen = (
    type: string,
    companies: number[],
    offer_id: number,
    offer_taken_from: number,
    from_email = false
  ) => {
    setEntityType(type)
    setOfferId(offer_id)
    setFromEmail(from_email)
    setOfferTakenFrom(offer_taken_from)
    if (filteredData) {
      setIds(companies)
    }
    setModalOpen(true)
  }
  return (
    <>
      <Grid className={classes.commonRow} container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <PrimaryHeading heading="Power Ups" text={startData} />
            </Grid>
            <Grid item xs={10}>
              <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="offer-heading">From Portal</h3>
          <Grid container spacing={3}>
            {filteredData.length ? (
              filteredData?.map(data => (
                <Grid
                  item
                  xs={6}
                  key={data?.offer_id}
                  onClick={() =>
                    handleModalOpen(
                      KPI_ENTITY_TYPES.company,
                      data.companies,
                      data.offer_id,
                      OFFER_TAKEN_FROM.client_portal
                    )
                  }
                >
                  <OfferDetail count={data?.count} description={data?.title} isLoading={isLoading} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <h3>No Data Found</h3>
              </Grid>
            )}
          </Grid>
          <br />
          <Divider />
          <h3 className="offer-heading">From Email</h3>
          <Grid container spacing={3}>
            {filteredDataFromEmail.length ? (
              filteredDataFromEmail?.map(data => (
                <Grid
                  item
                  xs={6}
                  key={data?.offer_id}
                  onClick={() =>
                    handleModalOpen(
                      KPI_ENTITY_TYPES.company,
                      data.companies,
                      data.offer_id,
                      data.offer_taken_from,
                      true
                    )
                  }
                >
                  <OfferDetail count={data?.count} description={data?.title} isLoading={isLoading} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <h3>No Data Found</h3>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {filteredData && (
        <DetailModal
          open={modalOpen}
          setOpen={setModalOpen}
          ids={ids}
          type={entityType}
          from_email={fromEmail}
          offer_id={offer_id}
          startDate={startData}
          endDate={currentData}
          offer_taken_from={offerTakenFrom}
        />
      )}
    </>
  )
}

export default SpecialOfferCard
