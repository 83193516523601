import { TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker as DatePickerComponent } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import classNames from 'classnames'
import moment from 'moment'
import * as React from 'react'
import { datePickerDateFormat, formatDateForApiPost } from 'utils/formatDate'

const useStyles = makeStyles({
  root: {
    margin: '0.5rem 0',
  },
})

export interface IDatePicker {
  onDateSelected(date: string): void
  label?: string
  disablePast?: boolean
  okLabel?: string
  style?: React.CSSProperties
  maxDateMessage?: string
  minDateMessage?: string
  minDate?: ParsableDate
  maxDate?: ParsableDate
  placeholder?: string
  format?: string
  error?: boolean
  disableFuture?: boolean
  value?: ParsableDate
  classes?: Record<'root', string>
  disabled?: boolean
  dataTestId?: string
  className?: string
  containerClassName?: string
  InputProps?: TextFieldProps['InputProps']
  inputVariant?: TextFieldProps['variant']
  name?: string
  InputLabelProps?: TextFieldProps['InputLabelProps']
}

const DatePicker: React.FC<IDatePicker> = props => {
  const classes = useStyles(props)
  const {
    onDateSelected,
    label,
    disablePast,
    value,
    okLabel,
    style,
    maxDateMessage,
    minDateMessage,
    minDate,
    maxDate,
    placeholder,
    format,
    error,
    disableFuture,
    disabled = false,
    dataTestId = `${label}-date-picker`,
    className,
    containerClassName,
    InputProps,
    inputVariant,
    name,
    InputLabelProps,
  } = props

  const handleDateChange = (muiDate: MaterialUiPickersDate) => {
    // DatePickerComponent returns MaterialUiPickersDate on change, so I'm casting it to Date as it's a more
    // generic Date type
    if (!muiDate) return
    const date: Date = muiDate.toDate()
    if (date.toDateString() === 'Invalid Date') {
      // user is currently editing the date
      return
    }

    if (format) {
      onDateSelected(moment(date).format(format))
    } else {
      onDateSelected(formatDateForApiPost(date))
    }
  }

  return (
    <div className={classNames(classes.root, containerClassName)}>
      <DatePickerComponent
        KeyboardButtonProps={{ disabled, id: 'calendar-button' }}
        InputProps={{
          disabled,
          id: 'date-picker-input',
          ...InputProps,
        }}
        InputLabelProps={InputLabelProps}
        inputVariant={inputVariant || 'outlined'}
        variant="inline"
        label={label}
        disablePast={disablePast}
        autoOk
        clearable
        okLabel={okLabel}
        style={style}
        value={value || null}
        onChange={handleDateChange}
        maxDateMessage={maxDateMessage}
        error={error}
        disableToolbar={disabled}
        className={className}
        name={name}
        // Changed error message to more neutral one,
        // so it fits cases with invalid date and also empty field
        helperText={error && 'Please Provide Valid Date'}
        disableFuture={disableFuture}
        minDateMessage={minDateMessage}
        maxDate={maxDate}
        minDate={minDate}
        placeholder={placeholder}
        format={format ? format : datePickerDateFormat}
        data-testid={dataTestId}
      />
    </div>
  )
}

export default DatePicker
