import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fetchSuggestions } from 'api/suggestions'
import SearchBar from 'components/common/searchBar'
import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import useUser from 'hooks/useUser'
import * as React from 'react'
import { USERTYPES } from 'utils/constant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    divider: {
      width: '1rem',
    },
    searchContainer: {
      flexGrow: 2,
    },
    entitySearchContainer: {
      flexGrow: 1,
    },
  })
)
interface ITasksSearchProps {
  onSearchQueryChange: (value: string) => void
  onCompanyChange: (companyId: number | null) => void
  onClientChange: (companyId: number | null) => void
  onAssigneeChange: (assigneeId: number | null) => void
}

const TasksSearch = ({ onClientChange, onCompanyChange, onAssigneeChange, onSearchQueryChange }: ITasksSearchProps) => {
  const classes = useStyles()

  const addAttribute = React.useCallback(
    (field: 'company_id' | 'client_id', id: number | null) => {
      if (field === 'company_id') {
        onCompanyChange(id)
      }
      if (field === 'client_id') {
        onClientChange(id)
      }
    },
    [onCompanyChange, onClientChange]
  )

  const { user } = useUser()

  const defaultAssignee: ISuggestionAttribute | undefined =
    user && user.name && user.id ? { label: user.name, value: user.id } : undefined

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <SearchBar onChange={onSearchQueryChange} placeholder="Search" />
      </div>

      <div className={classes.divider} />

      <div className={classes.entitySearchContainer}>
        <SelectField
          title="Assignee"
          field="assigned_to"
          onOptionSelected={option => onAssigneeChange(option?.value || null)}
          fetchSuggestions={fetchSuggestions}
          defaultValue={defaultAssignee}
          key={user?.id}
          disabled={user?.user_type === USERTYPES.accountant}
        />
      </div>

      <div className={classes.divider} />

      <div className={classes.entitySearchContainer}>
        <SelectField
          onOptionSelected={option => addAttribute('company_id', option ? option.value : null)}
          title="Company"
          fetchSuggestions={fetchSuggestions}
          field="company_id"
        />
      </div>

      <div className={classes.divider} />

      <div className={classes.entitySearchContainer}>
        <SelectField
          onOptionSelected={option => addAttribute('client_id', option ? option.value : null)}
          title="Client"
          fetchSuggestions={fetchSuggestions}
          field="client_id"
        />
      </div>
    </div>
  )
}

export default TasksSearch
