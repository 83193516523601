import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'
import { USERTYPES } from 'utils/constant'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (client: IClient): Promise<ICreatedClient> => {
  delete client.extid // delete the extid, the backend should take care of it
  client.user_type = '2'
  client.email = client.email ? client.email : ' '
  client.password = client.password === '' || client.password?.length === 0 ? 'password' : client.password
  client.username = client.username === null ? ' ' : client.username
  return (await apiClient.post('user', snakeCaseKeys(client))).data
}

export const updateClient = async (client: IClient): Promise<ICreatedClient> => {
  const obj: any = client
  obj.email_1 = client.email
  obj.sales_representative_id = client.sales_rep_id
  obj.affiliate_id = client.affiliate_id
  obj.date_of_birth = client.dob

  return (await apiClient.post(`client/${client.id}`, snakeCaseKeys(obj))).data
}
export const fetchClient = async (id: number): Promise<ICreatedClient> => {
  const data = (await apiClient.get(`clients/${id}`)).data
  data.affiliate.name = data.affiliate?.id ? `${data.affiliate.first_name} ${data.affiliate.last_name}` : null
  return data
}
export const fetchClients = async (query = ''): Promise<ICreatedClient[]> => {
  return (await apiClient.get(`user/client`, { params: { user_type: 'client', query, sort_order: 'desc' } })).data
}
export const paginateClients = () => {
  return requestPaginated<ICreatedClient[]>({ url: '/user/client' })
}
export const paginateClientsAsOwners = () => {
  return requestPaginated<ICreatedClient[]>({ url: '/getClientsAsOwners' })
}
export const fetchAccountants = async (query = ''): Promise<IClient[]> =>
  (await apiClient.get(`user`, { params: { user_type: USERTYPES.accountant, query } })).data

// export const searchClients = async (query = ''): Promise<IClient[]> =>
//   (apiClient.defaults.baseURL =
//     process.env.REACT_APP_BASE_URL ||
//     (
//       await apiClient.get(
//         //'http://localhost:3000/'
//         `search/client`,
//         { params: { query } }
//       )
//     ).data)

export const searchClients = async (query = ''): Promise<IClient[]> => {
  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
  return (await apiClient.get(`search/client`, { params: { query, sort_order: 'desc' } })).data
}
export const getClientAddresses = async (id: number): Promise<IAddress[]> =>
  (await apiClient.get(`user/${id}/address`)).data

interface IClientAssetParams {
  clientId: number
  documentId: number
}

interface Userlog {
  user_id?: string
  logged_at?: string
  user_type?: string
  loggedOut_at?: string
}
export interface IClientLogs {
  client?: IClient
  user_log: Userlog
}

export const uploadClientAsset = async (clientId: number, file: File): Promise<UploadRequest> =>
  (await apiClient.post(`client/${clientId}/upload-asset`, { mime_type: file.type, file_name: file.name })).data

export const getClientDocuments = (clientId: number) => requestPaginated<IAsset[]>({ url: `client/${clientId}/asset` })

export const getClientTaxDocuments = (clientId: number, archive: boolean, taxId: number) =>
  requestPaginated<IAsset[]>({ url: `client/${clientId}/asset/?archived=${archive}&tax_order_id=${taxId}` })

export const deleteClientDocument = async ({ clientId, documentId }: IClientAssetParams): Promise<void> => {
  await apiClient.delete(`user/${clientId}/asset/${documentId}`)
}

export const downloadClientDocumentURL = async ({ clientId, documentId }: IClientAssetParams): Promise<string> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()

  //return `${BASE_URL}/company/${companyId}/asset/${documentId}/download?jwt=${token}`
  //const res = await apiClient.get(`company/1/meeting`)

  const res = (await apiClient.get(`${BASE_URL}/client/${clientId}/asset/${documentId}/download?jwt=${token}`)).data
  return res //res.data;
}
export const fetchClientByRefcode = async (ref_code: string): Promise<ICreatedClient> => {
  const data = (await apiClient.get(`client-refcode/${ref_code}`)).data
  data.name = data?.id ? `${data.first_name} ${data.last_name}` : null
  return data
}

export const getClientLogs = (clientId: number) =>
  requestPaginated<IClientLogs[]>({ url: `userlogs/client?client_id=${clientId ? clientId : 0}` })

export const fetchClientUnReadDocsCount = async (query = ''): Promise<any> => {
  return (await apiClient.get(`/client/company/unread/uploads/count`)).data
}

export const fetchMissingItemsCount = async (): Promise<any> => {
  return (await apiClient.get(`client/order-reminder-count`)).data
}
