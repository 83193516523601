import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      display: 'flex',
      backgroundColor: theme.customPalette.noteDefaultColor,
      padding: '15px 25px 15px 15px',
      justifyContent: 'space-between',
    },
    textContainer: {
      wordBreak: 'break-word',
      lineHeight: 'normal',
      fontSize: '15px',
      paddingTop: '0.5rem',
    },
    showMore: {},
    dateTime: {
      fontWeight: 300,
      color: 'gray',
      whiteSpace: 'nowrap',
      fontSize: '0.8rem',
    },
    buttonsContainer: {
      width: 40,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    editButtonsContainer: {
      marginTop: 15,
    },
    noteContent: {
      width: 'calc(100% - 50px)',
    },
    iconButton: {
      width: 15,
      padding: 0,
      '&:hover': {
        background: 'none',
      },
    },
    editIcon: {
      color: theme.palette.primary.main,
      fontSize: '1.275rem',
      padding: 1,
    },
    deleteIcon: {
      color: theme.destructiveColor,
      marginLeft: 10,
    },
    textFieldRoot: {
      marginTop: 0,
    },
    searchBar: {
      backgroundColor: 'white',
      height: 38,
      margin: '10px 0',
    },
    mainContainer: {
      padding: 20,
      width: 420,
      overflow: 'scroll',
      backgroundColor: theme.customPalette.tableBackgroundColor,
    },
    noteTableCell: {
      border: 'none',
      paddingBottom: 0,
      minWidth: 360,
      maxWidth: 360,
    },
    newNoteContainer: {
      margin: 0,
      backgroundColor: theme.customPalette.tableBackgroundColor,
    },
    notesTitle: {
      fontSize: 24,
      fontFamily: theme.typography.h1.fontFamily,
    },
    inlineTitleButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    title: {
      width: '100%',
      fontFamily: theme.typography.h1.fontFamily,
      fontWeight: 600,
      textAlign: 'left',
    },
    closeButton: {
      right: '-16px',
    },
    adornment: {
      alignItems: 'end',
    },
    noteAuthor: {
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
)
