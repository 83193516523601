import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    companySubscriptionContainer: {
      '& .inline-item': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      '& .primary-heading': {
        fontFamily: 'Lato',
        fontSize: '1.75rem',
      },
      '& .secondary-heading': {
        fontFamily: 'Lato',
        fontSize: '1.25rem',
      },

      '& .active-section': {
        paddingLeft: '2rem',
      },

      '& .inactive-section': {
        paddingLeft: '2rem',
      },
    },

    subBtn: {
      width: '238px',
      margin: '2.5rem 0 4rem 2rem',
    },

    subscriptionCardContainer: {
      padding: '1.5rem 1.15rem',
      //   background: '#f6f8fb',
      border: '2px solid #407ace',
      minHeight: '206px',

      '& .title': {
        fontFamily: 'Lato',
        fontSize: '1rem',
        color: '#2c3b9b',
        margin: 0,
        marginBottom: '1.5rem',
      },

      '& .edit-btn': {
        color: '#307abc',
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '4rem',
        cursor: 'pointer',
      },

      '& .inline': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',

        '& p': {
          fontFamily: 'Lato',
          fontSize: '0.9rem',
          color: '#292929',
          margin: 0,
        },

        '& svg': {
          fontSize: '1rem',
          color: '#5b38d9',
          marginRight: '1rem',
        },
      },
      '& .inline-icon': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },

    SubscriptionModalContainer: {
      padding: '0 1.15rem',
      background: '#ffffff',

      '& .align-end': {
        display: 'flex',
        alignItems: 'flex-end',
      },

      '& .starting-text': {
        color: '#888888',
        fontSize: '0.95rem',
        // fontSize: '0.85rem',
        margin: 0,
      },

      '& .check-box': {
        '& .MuiButtonBase-root': {
          //   color: '#5b38da',
        },

        '& .MuiTypography-root': {
          color: '#292929',
        },
      },

      '& .date-field': {
        '& .MuiFormLabel-root': {
          transform: 'translate(0px, -16px) scale(0.75)',
          color: '#202124',
          textTransform: '#uppercase',
        },
        '& .MuiInputBase-root': {
          background: '#f6f6f8',

          '&::before': {
            border: 'none',
          },
        },
        '& .MuiFilledInput-input': {
          paddingTop: '8px',
        },
      },

      '& .sub-chip': {
        // background: '#5b38da',

        '& .MuiChip-label': {
          color: '#fff',
          paddingLeft: '2rem',
          paddingRight: '1.5rem',
        },
        '& .MuiSvgIcon-root': {
          color: '#fff',
          width: '12px',
          height: '12px',
          marginRight: '0.5rem',
        },
      },

      '& .payment-header': {
        marginTop: '5rem',

        '& .payment-heading': {
          fontSize: '0.75rem',
          color: '#202124',
          marginBottom: 0,
        },

        '& .payment-text': {
          fontSize: '0.85rem',
          color: '#888888',
        },

        '& .payment-details': {
          padding: '1.25rem 1rem',
          background: '#f7f8f9',

          '& p': {
            margin: 0,
          },

          '& .inline-item': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },

          '& .blance-text': {
            fontSize: '0.875rem',
            color: '#292929',
            fontWeight: 500,
          },
          '& .amount-text': {
            fontSize: '1rem',
            color: '#292929',
            fontWeight: 600,
          },
          '& .light-text': {
            fontSize: '0.75rem',
            color: '#888888',
          },
          '& .dark-text': {
            fontSize: '0.875rem',
            color: '#292929',
            paddingBottom: '0.5rem',
          },
        },
      },

      '& .text-area': {
        resize: 'none',
        background: 'inherit',
        border: 'none',
        outline: 'none',
        width: '100%',

        '&::placeholder': {
          color: '#979797',
          fontSize: '2rem,',
        },
      },
    },

    sendBtn: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '0.75rem',
    },
    cancelBtn: {
      maxWidth: 'fit-content',
      marginTop: '0.75rem',
    },
    cancelSubBtn: {
      // maxWidth: 'fit-content',
      marginTop: '0.75rem',
      background: 'red',
      borderColor: 'red',
      color: '#fff',
      minHeight: '50px',
      fontSize: '0.9rem',
      fontWeight: 700,

      '&:hover': {
        background: '#e50c0c',
      },
    },
    delSubBtn: {
      // maxWidth: 'fit-content',
      marginTop: '0.75rem',
      background: '#857272',
      borderColor: '#857272',
      color: '#fff',
      minHeight: '50px',
      fontSize: '0.9rem',
      fontWeight: 700,

      '&:hover': {
        background: '#6a5454',
      },
    },
    saveBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      '& .cancle-btn': {
        display: 'flex',
        '& > *': {
          margin: theme.spacing(1),
        },
        '& .MuiButton-root.Mui-disabled': {
          background: '#ff000021',
        },
      },

      '& button': {
        width: '180px !important',
      },
    },

    accordion: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'auto',
      boxShadow: 'none',

      '& .MuiAccordionSummary-root': {
        flexDirection: 'row-reverse',

        '& .MuiAccordionSummary-content': {
          alignItems: 'center',
          color: '#307abc',
          margin: 0,
        },

        '& .MuiAccordionSummary-expandIcon': {
          color: '#307abc',
          padding: 0,
          marginRight: '1rem',
        },
      },
    },
    details: {
      height: 'auto',
    },

    inlineBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '1rem 0',
    },

    smallText: {
      fontSize: '0.75rem',
      color: '#202124',
      margin: '0.25rem 0 0.5rem 0',
      // marginBottom: '0.25rem',
    },
  })
)
