import Addresses from 'components/common/addresses'
import AddressesSelect from 'components/common/addresses/addressesSelect'
import { IAddress } from 'models/address'
import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: '100%',
      margin: '0.7rem 0 0 0',
    },
  })
)

interface MailingAddressProps {
  mailingAddress?: IAddress
  addressOptions: IAddress[]
  onAddAddressClick: () => void
  onAddressEditClick: (id: number) => void
  onAddressDeleteClick: (id: number) => void
  handleMailingAddressChange: (newAddressId: number | null) => void
}

/**
 * This component is some part of code extracted from big file.
 * Not to be reused.
 *
 * TODO: #400 make MailingAddress component reusable, have better props
 */
export const MailingAddress = ({
  mailingAddress,
  addressOptions,
  onAddAddressClick,
  onAddressEditClick,
  onAddressDeleteClick,
  handleMailingAddressChange,
}: MailingAddressProps) => {
  const classes = useStyles()

  /**
   * converting to list because that's what the component expects
   */
  const mailingAddressAsList = mailingAddress ? [mailingAddress] : []

  return mailingAddress ? (
    <Addresses
      label="Mailing Address"
      onlyOneAddress={true}
      addresses={mailingAddressAsList}
      onAddressEditClick={onAddressEditClick}
      onAddressDeleteClick={onAddressDeleteClick}
    />
  ) : (
    <AddressesSelect
      dataTestId="company-mailing-address-dropdown"
      className={classes.textField}
      onAddAddressClick={onAddAddressClick}
      addresses={addressOptions}
      label="Mailing Address"
      onChange={handleMailingAddressChange}
    />
  )
}
