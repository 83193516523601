import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '33.5rem',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '3.5rem',
      marginRight: '3.5rem',

      '& .select-notification-template': {
        '& .MuiFormControl-root ': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
    closeButton: {
      width: 'min-content',
      alignSelf: 'flex-end',
      marginRight: '1rem',
    },
    title: {
      fontSize: '1.75rem',
      fontWeight: 600,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary,
      marginBottom: '0.4rem',
    },
    subTitle: {
      fontSize: '1.125rem',
      letterSpacing: '0.09px',
      color: theme.customPalette.lightGray,
      textTransform: 'uppercase',
      marginBottom: '1rem',
    },
    select: {
      width: '100%',
      marginRight: '0.3rem',
      paddingBottom: '0.06rem',
      marginTop: '0.875rem',
    },
    textField: {
      margin: '0.5rem 0',
    },
    nextYearsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      border: '1px solid rgb(196, 196, 196)',
      borderRadius: '0.25rem',
      alignItems: 'center',
    },
    customizeButton: {
      color: '#3c9fdf',
      textDecoration: 'underline',
      fontSize: '1.125rem',
      fontWeight: 500,
      alignSelf: 'flex-end',
      cursor: 'pointer',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      '&:disabled': {
        opacity: 0.5,
      },
    },
    scheduleButton: {
      width: '13.75rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      alignSelf: 'center',
      background: '#ececee',
      color: '#3c9fdf',

      '& .MuiButton-contained': {
        background: '#ececee',
        color: '#3c9fdf',
        fontWeight: 500,
        fontSize: '1.125rem',
        boxShadow: 'none',
      },
    },
    checkBoxSec: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    errorMsg: {
      color: 'red',
    },
  })
)
