import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import BRTextField from 'components/common/textFields/BRTextField'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import React, { useState, useCallback, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './styles'
import UpCommingTable from './upCommingTable'
import { COMPLIANCE_TASKS_STATUS, complianceType } from 'utils/constant'
import { datePickerDateFormat, formatDateToHumanFriendly } from 'utils/formatDate'
import {
  complianceCompanyClosed,
  complianceCompleteOrder,
  complianceInProgressOrder,
  ComplianceTasks,
  getComplianceTasks,
} from 'api/complianceCalendar'
import { ISuggestionAttribute } from 'components/interfaces'
import showErrorNotification from 'utils/showErrorNotification'
import { OrderDueDatePicker } from 'components/common/companyOrder/orderDueDatePicker'
import CompanyOrderTypeField from 'components/common/companyOrder/orderTypeField'
import SelectField from 'components/common/select'
import { complianceOrderTypeSuggestions, fetchSuggestions } from 'api/suggestions'
import showSuccessNotification from 'utils/showSuccessNotification'
import moment from 'moment'
import { Clear } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'

interface UpCommingTaskProps {
  isUpdated?: boolean
  setIsUpdated?: any
}

const UpCommingTask = ({ isUpdated, setIsUpdated }: UpCommingTaskProps) => {
  const classes = useStyles()
  const [complianceTasks, setComplianceTasks] = useState<ComplianceTasks>()
  const [isLoadingTasks, setIsLoadingTasks] = useState(false)

  // const [allComplianceTasks, setAllComplianceTasks] = useState<ComplianceTasksData[]>([])

  const [orderTypeSuggestion, setOrderTypeSuggestion] = useState<ISuggestionAttribute>()
  const [companyOrderDueDate, setCompanyOrderDueDate] = useState<Date>()
  const [companyName, setCompanyName] = useState<number | null>()
  const [clientName, setClientName] = useState<number | null>()
  const [complianceTypeValue, setComplianceTypeValue] = React.useState<string>('')

  const [query, setQuery] = useState('')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const getTasks = useCallback(async () => {
    const complianceTaskParams = {
      company_id: (companyName && companyName) || null,
      client_id: (clientName && clientName) || null,
      due_date: companyOrderDueDate && moment.utc(companyOrderDueDate).format('DD MMMM YYYY'),
      //formatDateToHumanFriendly(companyOrderDueDate),
      order_type_id: orderTypeSuggestion?.value,
      status: COMPLIANCE_TASKS_STATUS?.upcoming,
      page: page + 1,
      page_size: rowsPerPage,
      query: query,
      compliance_type: complianceTypeValue,
    }
    // const allComplianceTaskParams = {
    //   company_id: (companyName && companyName) || null,
    //   client_id: (clientName && clientName) || null,
    //   due_date: companyOrderDueDate && formatDateToHumanFriendly(companyOrderDueDate),
    //   order_type_id: orderTypeSuggestion?.value,
    //   status: COMPLIANCE_TASKS_STATUS?.upcoming,
    // }
    setIsLoadingTasks(true)
    try {
      const tasks = await getComplianceTasks(complianceTaskParams)
      // const allTaks = await getComplianceTasks(allComplianceTaskParams)
      setComplianceTasks(tasks)
      // setAllComplianceTasks(allTaks)
      setIsLoadingTasks(false)
    } catch (e) {
      showErrorNotification(e)
      setIsLoadingTasks(false)
    }
  }, [
    clientName,
    companyName,
    companyOrderDueDate,
    complianceTypeValue,
    orderTypeSuggestion?.value,
    page,
    query,
    rowsPerPage,
  ])

  useEffect(() => {
    getTasks()
  }, [getTasks, isUpdated])

  const handleSelectingOrderType = data => {
    setOrderTypeSuggestion(data)
    // setCompanyOrderDueDate(undefined)
    // setCompanyName(null)
    // setClientName(null)
  }

  const handleDueDateSelected = date => {
    setCompanyOrderDueDate(date)
    // setCompanyName(0)
    // setClientName(0)
    // setOrderTypeSuggestion({
    //   value: 0,
    //   label: '',
    // })
  }
  const addAttribute = React.useCallback((field: 'company_id' | 'client_id', id: number | null) => {
    if (field === 'company_id') {
      if (id) {
        setCompanyName(id)
        // setClientName(null)
        // setOrderTypeSuggestion({
        //   value: 0,
        //   label: '',
        // })
        // setCompanyOrderDueDate(undefined)
      }
    }
    if (field === 'client_id') {
      if (id) {
        setClientName(id)
        // setCompanyName(null)
        // setOrderTypeSuggestion({
        //   value: 0,
        //   label: '',
        // })
        // setCompanyOrderDueDate(undefined)
      }
    }
  }, [])

  const completeOrderHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.completed,
      }
      try {
        const complateOrder = await complianceCompleteOrder(payload)
        showSuccessNotification('Order Complete Success')
        setIsUpdated(true)
        return complateOrder
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [setIsUpdated]
  )

  const companyCloseHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.closed,
      }
      try {
        const companyClosed = await complianceCompanyClosed(payload)
        showSuccessNotification('Order Closed Success')
        setIsUpdated(true)
        return companyClosed
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [setIsUpdated]
  )
  const inProgressOrderHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.inprogress,
      }
      try {
        const companyClosed = await complianceInProgressOrder(payload)
        showSuccessNotification('Order InProgress Success')
        setIsUpdated(true)
        return companyClosed
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [setIsUpdated]
  )

  return (
    <div className={classes.subContainer}>
      <HeadingHeader
        title="Upcoming Tasks"
        isLink={false}
        // secondaryComponent={
        //   <BRTextField
        //     placeholder="Search"
        //     type="text"
        //     variant="outlined"
        //     style={{ width: '23.4%' }}
        //     className="search-filed-container"
        //     InputProps={{
        //       startAdornment: (
        //         <InputAdornment position="start">
        //           <SearchIcon />
        //         </InputAdornment>
        //       ),
        //     }}
        //     value={query}
        //     onChange={e => setQuery(e.target.value)}
        //   />
        // }
      />

      <Grid container spacing={3} style={{ width: 'calc(100% + 16px)' }}>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={option => addAttribute('company_id', option ? option.value : null)}
            title="Company"
            fetchSuggestions={fetchSuggestions}
            field="company_id"
            setFieldValue={setCompanyName}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={option => addAttribute('client_id', option ? option.value : null)}
            title="Client"
            fetchSuggestions={fetchSuggestions}
            field="client_id"
            setFieldValue={setClientName}
          />
        </Grid>
        <Grid item xs={3}>
          <CompanyOrderTypeField
            handleSelectingOrderType={handleSelectingOrderType}
            fetchSuggestions={complianceOrderTypeSuggestions}
            orderTypeSuggestion={orderTypeSuggestion || null}
            disabled={false} //{shouldDisableOrderTypeSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <OrderDueDatePicker
            className={classes.dueDatePicker}
            classes={{ root: classes.dateField }}
            onDueDateSelected={handleDueDateSelected}
            dueDateValue={companyOrderDueDate}
            dateFormat={datePickerDateFormat}
            InputProps={{
              startAdornment: companyOrderDueDate ? (
                <IconButton onClick={() => handleDueDateSelected(null)}>
                  <Clear />
                </IconButton>
              ) : (
                ''
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="complianceTypeValue"
            options={complianceType as string[]}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setComplianceTypeValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="Compliance Type" variant="outlined" />}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <SelectField label="Search by Company Name" />
        </Grid>
        <Grid item xs={3}>
          <SelectField label="Search by Order Type" />
        </Grid>
        <Grid item xs={3}>
          <SelectField label="Search by Due Date" />
        </Grid>
        <Grid item xs={3}>
          <SelectField label="Search by Client Name" />
        </Grid> */}
      </Grid>

      <br />

      <UpCommingTable
        complianceTasks={complianceTasks || null}
        isLoadingTasks={isLoadingTasks}
        completeOrderHandler={completeOrderHandler}
        companyCloseHandler={companyCloseHandler}
        inProgressOrderHandler={inProgressOrderHandler}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        // allComplianceTasks={allComplianceTasks}
      />
    </div>
  )
}

export default UpCommingTask
