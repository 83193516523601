import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { IInvoice, IQuestion, QuestionStatus, updateInvoiceStatus } from 'api/taxOrders'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import * as React from 'react'
import { theme } from 'theme'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import grey from '@material-ui/core/colors/grey'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import PrimaryButton from 'components/common/buttons/primaryButton'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { LaunchOutlined, OpenInNew } from '@material-ui/icons'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: 4,
      padding: 10,
      paddingLeft: 13,
      paddingRight: 25,
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    checkbox: {
      height: 'min-content',
      alignSelf: 'center',
      marginRight: 12,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    statusLabel: {
      fontSize: 12,
      letterSpacing: '0.06px',
      padding: 5,
      paddingLeft: 8,
      paddingRight: 16,
      width: 'fit-content',
    },
    question: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: '0.09px',
      color: 'black',
    },
    answer: {
      fontSize: 18,
      letterSpacing: '0.09px',
      color: theme.palette.text.primary,
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
    actionButton: {
      marginLeft: '0.5rem',
      width: 40,
      height: 40,
      '& span': {
        fontSize: '2rem',
      },
    },
  }
})

const statusToHuman = (status: QuestionStatus): string => {
  switch (status) {
    case 'pending':
      return 'PENDING'
    case 'answered':
      return 'ANSWERED'
    case 'unanswered':
      return 'UNANSWERED'
    case 'sent_to_client':
      return 'SENT TO CLIENT'
    default:
      return 'STATUS UNKNOWN'
  }
}

interface InvoiceProps {
  invoice: IInvoice
  onEditClick: (invoice: IInvoice) => void
  onDeleteClick?: (invoice: IInvoice) => void
  updateStatus: (taxid?: number, paid?: boolean, invoiceId?: number) => void
  loading?: boolean
}

const questionColors = {
  answered: '#e4ffdf',
  unanswered: '#ffdecc',
  pending: 'lightGray',
  sent_to_client: 'lightBlue',
}
// const updateStatus =async (taxid, paid, invoiceId) =>{
//   await updateInvoiceStatus(taxid, paid, invoiceId)
// }
const InvoiceTableRow: React.FC<InvoiceProps> = ({ invoice, onEditClick, onDeleteClick, updateStatus, loading }) => {
  const classes = useStyles()

  return (
    <>
      {/* <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialstates={states} /> */}
      <StyledTableRow data-testid={`${invoice.id}`} className={classes.row}>
        <StyledTableCell>{invoice.invoice_no}</StyledTableCell>
        <StyledTableCell>{invoice.issue_date}</StyledTableCell>
        <StyledTableCell>{invoice.amount}</StyledTableCell>
        <StyledTableCell>
          {invoice.filer_name} # {invoice.taxorderid}
        </StyledTableCell>
        <StyledTableCell>{formatDateTimeToHumanFriendly(invoice.created_at || '')}</StyledTableCell>
        <StyledTableCell>{invoice.created_by}</StyledTableCell>
        <StyledTableCell>{invoice.paid ? formatDateTimeToHumanFriendly(invoice.paid_at || '') : ''}</StyledTableCell>
        <StyledTableCell>{invoice.paid_by}</StyledTableCell>
        <StyledTableCell>
          {/* <ButtonWithLoading> */}
          <PrimaryButton
            style={{ height: '0%', width: '17%', backgroundColor: invoice.paid ? '#449d44' : '#d9534f' }}
            onClick={() => updateStatus(invoice.taxorderid, !invoice.paid, invoice.id)}
            // disabled={isSendingRequest}
            // data-testid={TestID.SAVE_TAX_ORDER_BUTTON}
          >
            {invoice.paid ? 'Paid' : 'Unpaid'}
          </PrimaryButton>
        </StyledTableCell>

        {/* <StyledTableCell>{client.addresses?.length ? client.addresses[0].postal_code : '- - -'}</StyledTableCell> */}

        {/* <StyledTableCell>{formatSSN(client.ssn_ein ? client.ssn_ein.toString() : '')}</StyledTableCell> */}
        <StyledTableCell>
          {/* <TableActionButton
            className={classes.actionButton}
            onClick={() => {
              window.open(`/tax-orders/${invoice.taxorderid}`, '_blank')
            }}
          >
            <LaunchOutlined />
          </TableActionButton> */}
          <TableActionButton className={classes.actionButton}>
            <Link to={`/tax-orders/${invoice.taxorderid}`} target="_blank" rel="noreferrer">
              <OpenInNew data-testid="newTab" color="primary" />
            </Link>
          </TableActionButton>
          <TableActionButton onClick={() => onEditClick(invoice)} className={classes.actionButton}>
            <EditIcon />
          </TableActionButton>
          <TableActionButton
            className={classes.button}
            onClick={event => {
              onDeleteClick && onDeleteClick(invoice)
              event.stopPropagation()
            }}
          >
            <DeleteIcon tooltipText="Delete" />
          </TableActionButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default InvoiceTableRow
