import React from 'react'
import ComplianceKpi from './compliance'
import LeadManagerCard from './leadmanagerCard'
import MainCard from './mainCard'
import OrdersCard from './orderDetail'
import SpecialOfferCard from './SpecialOfferCard'
import { useStyles } from './styles'
import TrafficCard from './TrafficCard'
import UsersCard from './usersCard'
import UserTrafficeCard from './userTrafficeCard'
import UnsubscribeCard from './UnsubscribeNotificationCard'

const KpiDasboardElements = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <h1 className="primary-heading">Dashboard</h1>
      <MainCard />
      <UsersCard />
      <TrafficCard />
      <SpecialOfferCard />
      <UserTrafficeCard />
      <LeadManagerCard />
      <OrdersCard />
      <ComplianceKpi />
      <UnsubscribeCard />
    </div>
  )
}

export default KpiDasboardElements
