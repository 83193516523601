import { makeStyles } from '@material-ui/core'
import * as React from 'react'

interface ITabPanelProps {
  children?: React.ReactNode
  activeTabIndex: number
  tabIndex: number
}

const useStyles = makeStyles(() => ({
  tabContainer: {
    display: 'flex',
    height: '100%',
  },
}))

const TabPanel = ({ activeTabIndex, tabIndex, children }: ITabPanelProps) => {
  const classes = useStyles()
  return (
    <div
      role="tabpanel"
      hidden={activeTabIndex !== tabIndex}
      id={`wrapped-tabpanel-${tabIndex}`}
      aria-labelledby={`wrapped-tab-${tabIndex}`}
    >
      {activeTabIndex === tabIndex && <div className={classes.tabContainer}>{children}</div>}
    </div>
  )
}

export default TabPanel
