import { createStyles, FormControl, FormHelperText, makeStyles, Theme } from '@material-ui/core'
import { deleteAddress, editAddress } from 'api/addresses'
import { createClient, updateClient } from 'api/industrytype'

import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'

import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient, ICreatedIndustryType, ICreatedState, IIndustryType } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: IIndustryType = {
  name: '',
  id: 0,
}

export interface IClientDetailsProps {
  editedClient?: IIndustryType
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedIndustryType) => void
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

const ClientDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful /*, initialClient*/ }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<IIndustryType>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })
  const [error, setError] = React.useState(false)

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()

  const { history } = useRouter()
  const updateClientRequest = async (client: IIndustryType) => {
    const editedClient = client
    const resClient = await updateClient({
      id: editedClient.id,
      name: editedClient.name,

      // country_name: editedClient.country_name,
    })
    return resClient
  }

  const createClientRequest = async (client: IIndustryType) => {
    const resClient = await createClient({ ...client })
    return resClient
  }

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedIndustryType = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const changeClientField = React.useCallback(
    (field: keyof IIndustryType, value: ValueOf<IIndustryType>) => {
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (value && typeof value === 'string' && value.length) setError(false)
      // if (helperTexts[field]) {
      //   setHelperTexts({
      //     ...helperTexts,
      //     [field]: '',
      //   })
      // }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  // React.useEffect(() => {
  //   if (!client.name?.length) {
  //     setError(true)
  //   } else setError(false)
  // }, [client])
  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    if (!client.name?.length) {
      setError(true)
      return false
    }
    try {
      let clientResponse: ICreatedState
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)

        // When creating a new client, the attached addresses are without client ids.
        // This prevents us from sending a request to create a new address and link it with the client.
      }

      const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
      setClient(updatedClient)
    } catch (err) {
      showApiResponseError(err, 'Could not save changes')

      return false
    }

    return true
  }, [formMode, client, clientEditErrors])

  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <BRTextField
          error={error}
          helperText={error && 'Name is required'}
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Industry Type Name"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.name}
          onChange={event => changeClientField('name', event.target.value)}
        />
        <br />
        <SubmitFormButton
          // loading={loading}
          onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add industry Type'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default ClientDetails
