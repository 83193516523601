import React from 'react'
import { useParams } from 'react-router'
import 'react-circular-progressbar/dist/styles.css'
import { useStyles } from './style'
// import RadialSeparators from './RadialSeparotors'
import { CreditProgressMetter } from '../img/creditProgressMetter'
import { checkCreditScore } from 'utils/functions'
import useRouter from 'use-react-router'

interface CompanyInformationProps {
  title: string
  creditScore: number
}

const CreditScoreMeter = ({ title, creditScore }: CompanyInformationProps) => {
  const classes = useStyles()
  const { history } = useRouter()

  const { id } = useParams<{ id: string }>()

  return (
    <div className={classes.creditScoreMeterContainer}>
      <p className="profile-title">{title}</p>

      <div className={classes.creditProgressContainer}>
        <p className="label" style={{ marginRight: '-0.5rem' }}>
          D
        </p>
        <CreditProgressMetter
          svgWidth="100%"
          svgHeight="auto"
          part1={checkCreditScore(creditScore)?.color1}
          part2={checkCreditScore(creditScore)?.color2}
          part3={checkCreditScore(creditScore)?.color3}
          part4={checkCreditScore(creditScore)?.color4}
          part5={checkCreditScore(creditScore)?.color5}
          part6={checkCreditScore(creditScore)?.color6}
          text={
            title?.includes('Business Rocket')
              ? checkCreditScore(creditScore)?.rank
              : checkCreditScore(creditScore)?.score?.toString()
          }
        />
        <p className="label" style={{ marginLeft: '-0.5rem' }}>
          A
        </p>
      </div>

      {!title?.includes('Business Rocket') ? (
        <>
          <p className="profile-subtitle1">Below Average</p>
          <p className="profile-subtitle2">Over Last Week</p>
        </>
      ) : (
        <p className="profile-subtitle3">
          Learn how the score
          <br />
          is calculated
        </p>
      )}
    </div>
  )
}

export default CreditScoreMeter
