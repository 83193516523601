import CheckBox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ModalTitle from 'components/common/modalTitle'
import EmptyCheckbox from 'icons/emptyCheckbox'
import FilledCheckbox from 'icons/filledCheckbox'
import * as React from 'react'
import DatePicker from 'components/common/datePicker'
import { datePickerDateFormat, datePickerDatePlaceholder } from 'utils/formatDate'
import { IInvoice } from 'api/taxOrders'

const useStyles = makeStyles(() => {
  return {
    container: {
      paddingTop: '1.5rem',
      paddingBottom: '1rem',
    },
    questionTextField: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '1.5rem',
    },
    answerTextField: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '0.6rem',
    },
    saveButtonWrapper: {
      marginTop: '1rem',
      width: '13.7rem',
      alignSelf: 'center',
    },
    answerQuestionCheckbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
    },
  }
})

interface IAddQuestionDialog {
  dialogType: QuestionDialogType
  shown: boolean
  onClose: () => void
  setInvoiceNo: (invoiceNo: string) => void
  setIssueDate: (invoicedate: string) => void
  setAmount: (amount: number) => void
  issueDate: string
  amount: number
  invoiceNo: string
  loading: boolean
  onSaveClick: (dialogType: QuestionDialogType) => void
  paymentLink: string
  setPaymentLink: (invoiceNo: string) => void
}

export type QuestionDialogType = 'add' | 'edit'

const InvoiceDialog: React.FC<IAddQuestionDialog> = ({
  dialogType,
  shown,
  onClose,
  loading,
  onSaveClick,
  setInvoiceNo,
  setIssueDate,
  setAmount,
  amount,
  invoiceNo,
  issueDate,
  paymentLink,
  setPaymentLink,
}) => {
  const classes = useStyles()

  const handleModalClose = () => {
    onClose()
    setAmount(0)
    setIssueDate('')
    setInvoiceNo('')
    setPaymentLink('')
  }
  return (
    <Dialog
      PaperProps={{ className: classes.container }}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      open={shown}
    >
      {/* <ModalTitle title={dialogType === 'add' ? 'Add Invoice' : 'Answer A Question'} onClose={handleModalClose} /> */}
      <ModalTitle title={dialogType === 'add' ? 'Add Invoice' : 'Update Invoice'} onClose={handleModalClose} />
      <TextField
        disabled={loading}
        label="Invoice Number"
        className={classes.questionTextField}
        type="text"
        name="inno"
        variant="outlined"
        //rows={dialogType === 'add' ? 3 : undefined}
        value={invoiceNo} //{dialogType === 'add' ? newQuestionText : editedQuestionText}
        onChange={event => setInvoiceNo(event.target.value)}
      />
      <DatePicker
        value={issueDate} //{values.fileDate}
        style={{ width: '89%' }}
        // classes={{ root: classes.fullWidth }}
        className={classes.questionTextField}
        label="Issue Date"
        placeholder={datePickerDatePlaceholder}
        format={datePickerDateFormat}
        onDateSelected={value => setIssueDate(value)}
      />
      <TextField
        disabled={loading}
        label="Amount"
        className={classes.questionTextField}
        type="number"
        name="amount"
        variant="outlined"
        //rows={dialogType === 'add' ? 3 : undefined}
        value={amount} //{dialogType === 'add' ? newQuestionText : editedQuestionText}
        onChange={event => setAmount(parseFloat(event.target.value))}
        inputProps={{
          maxLength: 13,
          step: 0.5,
          min: 0.0,
        }}
      />
      <TextField
        disabled={loading}
        label="Payment Link"
        className={classes.questionTextField}
        type="text"
        name="inno"
        variant="outlined"
        //rows={dialogType === 'add' ? 3 : undefined}
        value={paymentLink} //{dialogType === 'add' ? newQuestionText : editedQuestionText}
        onChange={event => setPaymentLink(event.target.value)}
      />
      <div className={classes.saveButtonWrapper}>
        <ButtonWithLoading disabled={false} onClick={() => onSaveClick(dialogType)} isLoading={loading}>
          Save
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default InvoiceDialog
