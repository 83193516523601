import { PagedTable, usePagedTable } from '@jetkit/react'
import { Grid, Table, TableBody, TextField, Theme, Select, MenuItem, FormControl, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {
  companySubscriptions,
  deleteSubscriptionProduct,
  fetchSubscription,
  fetchSubscriptionProduct,
  ICreateProductReq,
  IProductResponse,
  IStripePrice,
  INameValue,
  companySubscriptionsData,
} from 'api/subscription'
import CreateSubscription from 'components/subscription/create'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import useSortTable from 'hooks/useSortTable'
import * as React from 'react'
import useRouter from 'use-react-router'
import { ICompanySubscriptionResponse } from 'api/subscription'
import { createStyles, makeStyles } from '@material-ui/styles'
import SubscriptionTableRow from '../companySubscription/table/row'
import SubscriptionTableHead from '../companySubscription/table/head'
import SelectField from 'components/common/select'
import { fetchSuggestions } from 'api/suggestions'
import { Autocomplete } from '@material-ui/lab'
import { getstates } from 'api/notifications'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'
import { CSVLink, CSVDownload } from 'react-csv'

const csvData = [
  ['firstname', 'lastname', 'email'],
  ['Ahmed', 'Tomi', 'ah@smthing.co.com'],
  ['Raed', 'Labes', 'rl@smthing.co.com'],
  ['Yezzi', 'Min l3b', 'ymin@cocococo.com'],
]
interface INameValueString {
  value: string
  name: string
}

const CompanySubscription: React.FC = () => {
  const classes = useStyles()

  const [query, setQuery] = React.useState('')
  const [clientToDelete, setClientToDelete] = React.useState<ICompanySubscriptionResponse>()
  const [deleteClientDialogShown, setDeleteClientDialogShown] = React.useState(false)
  const router = useRouter()

  const [companyId, setCompanyId] = React.useState<number>(0)

  const [subscriptions, setSubscriptions] = React.useState([])
  const [productId, setProductId] = React.useState<INameValue>()
  // const [productId, setProductId] = React.useState<number>(0)

  const [states, setStates] = React.useState([])
  const [stateId, setStateId] = React.useState<INameValue>()

  const [subscriptionPrices, setSubscriptionPrices] = React.useState([])
  const [priceId, setPriceId] = React.useState<INameValue>()
  // const [priceId, setPriceId] = React.useState<number>(0)

  const [status, setStatus] = React.useState<INameValueString>()

  const [startLastDateRange, setStartLastDateRange] = React.useState<Moment | null>(null)
  const [endLastDateRange, setEndLastDateRange] = React.useState<Moment | null>(null)
  const [startNextDateRange, setStartNextDateRange] = React.useState<Moment | null>(null)
  const [endNextDateRange, setEndNextDateRange] = React.useState<Moment | null>(null)

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'creation_time' })
  const [subscriptionReportData, setSubscriptionReportData] = React.useState<any[]>([])
  const { order, sort_by } = sortSettings

  React.useEffect(() => {
    const getSubscriptionProduct = async () => {
      try {
        const result: IProductResponse[] = await fetchSubscriptionProduct()
        const finalResult: any = result?.map((item: IProductResponse) => {
          return {
            value: item?.id,
            name: item?.name,
          }
        })
        setSubscriptions(finalResult)
      } catch (error) {
        console.log('error', error)
      }
    }
    getSubscriptionProduct()
  }, [])

  React.useEffect(() => {
    const getStatesArr = async () => {
      try {
        const result: any[] = await getstates()
        const finalResult: any = result?.map((item: any) => {
          return {
            value: item?.id,
            name: item?.state_display_name,
          }
        })
        setStates(finalResult)
      } catch (error) {
        console.log('error', error)
      }
    }
    getStatesArr()
  }, [])
  React.useEffect(() => {
    const getsubs = async () => {
      const reportData: ICompanySubscriptionResponse[] = await companySubscriptionsData({
        page: 1,
        page_size: 250,
        sort_order: order,
        sort_by,
        query,
        company_id: companyId || 0,
        product_id: productId?.value || 0,
        price_id: priceId?.value || 0,
        status: status?.value || '',
        state_of_formation: stateId?.value || 0,
        last_bill_start: startLastDateRange ? encodeURIComponent(startLastDateRange?.format('YYYY-MM-DD 00:00 Z')) : '',
        last_bill_end: endLastDateRange ? encodeURIComponent(endLastDateRange?.format('YYYY-MM-DD 23:59 Z')) : '',
        next_bill_start: startNextDateRange ? encodeURIComponent(startNextDateRange?.format('YYYY-MM-DD 00:00 Z')) : '',
        next_bill_end: endNextDateRange ? encodeURIComponent(endNextDateRange?.format('YYYY-MM-DD 23:59 Z')) : '',
      })
      const transformedData = reportData.map(item => {
        return {
          company_name: item.company_name,
          subscription_type: item.product.name,
          state: item.state_of_formation,
          date_started: item.creation_time,
          last_bill: item.current_period_start,
          next_bill: item.current_period_end,
          amount: item.price.amount,
          status: item.status,
          collected_revenue: item.collected_revenue,
        }
      })
      setSubscriptionReportData(transformedData)

      console.log('transformedData', transformedData)
    }
    getsubs()
  }, [
    companyId,
    endLastDateRange,
    endNextDateRange,
    order,
    priceId?.value,
    productId?.value,
    query,
    sort_by,
    startLastDateRange,
    startNextDateRange,
    stateId?.value,
    status?.value,
  ])
  const handleSubscriptionsChange = async (e: any) => {
    setProductId(e)

    if (e) {
      try {
        const result: ICreateProductReq = await fetchSubscription(e.value)
        const finalResult: any = result?.pricing?.map((item: IStripePrice) => {
          return {
            value: item?.id,
            name: `${item?.name} - $${item?.amount}  ${item.billing_period ? '/' + item?.billing_period : ''}`,
          }
        })
        setSubscriptionPrices(finalResult)
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const tableHead = React.useMemo(
    () => <SubscriptionTableHead handleSortingClick={handleSortingClick} sortSettings={sortSettings} />,
    []
  )

  const apiCall = React.useMemo(
    () =>
      companySubscriptions()({
        sort_order: order,
        sort_by,
        query,
        company_id: companyId || 0,
        product_id: productId?.value || 0,
        price_id: priceId?.value || 0,
        status: status?.value || '',
        state_of_formation: stateId?.value || 0,
        last_bill_start: startLastDateRange ? encodeURIComponent(startLastDateRange?.format('YYYY-MM-DD 00:00 Z')) : '',
        last_bill_end: endLastDateRange ? encodeURIComponent(endLastDateRange?.format('YYYY-MM-DD 23:59 Z')) : '',
        next_bill_start: startNextDateRange ? encodeURIComponent(startNextDateRange?.format('YYYY-MM-DD 00:00 Z')) : '',
        next_bill_end: endNextDateRange ? encodeURIComponent(endNextDateRange?.format('YYYY-MM-DD 23:59 Z')) : '',
      }),
    [
      companyId,
      endLastDateRange,
      endNextDateRange,
      order,
      priceId?.value,
      productId?.value,
      query,
      sort_by,
      startLastDateRange,
      startNextDateRange,
      stateId?.value,
      status?.value,
    ]
  )
  const pagedTable = usePagedTable<ICompanySubscriptionResponse>({ apiCall })

  const onEditClick = React.useCallback(
    (subscription: ICompanySubscriptionResponse) => {
      if (!subscription.id) return
      router.history.push(`/subscription/${subscription.id}`)
    },
    [router.history]
  )

  const onDeleteClick = React.useCallback(
    (subscription: ICompanySubscriptionResponse) => {
      if (!subscription.id) return
      setClientToDelete(subscription)
      setDeleteClientDialogShown(true)
    },
    [setClientToDelete, setDeleteClientDialogShown]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const addAttribute = React.useCallback((field: 'company_id', id: number | null) => {
    if (field === 'company_id') {
      if (id) {
        setCompanyId(id)
      }
    }
  }, [])

  const SUBSCRIPTION_STATUSES_ARR = [
    {
      name: 'Trialing',
      value: 'trialing',
    },
    {
      name: 'Active',
      value: 'active',
    },
    {
      name: 'Past Due',
      value: 'past_due',
    },
    {
      name: 'Canceled',
      value: 'canceled',
    },
    {
      name: 'Incomplete',
      value: 'incomplete',
    },
    {
      name: 'Incomplete Expired',
      value: 'incomplete_expired',
    },
    {
      name: 'Paused',
      value: 'paused',
    },
    {
      name: 'Unpaid',
      value: 'unpaid',
    },
  ]

  const handleStartLastDateChange = date => {
    setStartLastDateRange(date)
  }
  const handleEndLastDateChange = date => {
    setEndLastDateRange(date)
  }
  const handleStartNextDateChange = date => {
    setStartNextDateRange(date)
  }
  const handleEndNextDateChange = date => {
    setEndNextDateRange(date)
  }

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteClientDialogShown}
        close={() => setDeleteClientDialogShown(false)}
        deletionObject={clientToDelete}
        deletionRequest={deleteSubscriptionProduct}
        onRequestSuccessful={onDeleteSuccessful}
      />
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>Company Subscription</Typography>
        {/* <Button color="primary" variant="contained">
          Download Report
        </Button> */}
        <Button color="primary" variant="contained" disabled={subscriptionReportData.length === 0}>
          <CSVLink
            data={subscriptionReportData}
            headers={[
              {
                label: 'company_name',
                key: 'company_name',
              },
              {
                label: 'date_started',
                key: 'date_started',
              },
              {
                label: 'last_bill',
                key: 'last_bill',
              },
              {
                label: 'next_bill',
                key: 'next_bill',
              },
              {
                label: 'state',
                key: 'state',
              },
              {
                label: 'subscription_type',
                key: 'subscription_type',
              },
              {
                label: 'price',
                key: 'amount',
              },
              {
                label: 'status',
                key: 'status',
              },
              {
                label: 'collected_revenue',
                key: 'collected_revenue',
              },
            ]}
            filename="subscription-report.csv"
            className={classes.downloadButton}
          >
            Download Report
          </CSVLink>
        </Button>
      </div>

      <div className={classes.datePickerFiled} style={{ justifyContent: 'space-between' }}>
        <div className={classes.datePickerFiled}>
          <FormControl className="formControl">
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={startLastDateRange}
              onChange={handleStartLastDateChange}
              label="Start Last Bill Date"
              variant="inline"
              inputVariant="outlined"
              autoOk
            />
          </FormControl>
          <span>to</span>
          <FormControl className="formControl">
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={endLastDateRange}
              onChange={handleEndLastDateChange}
              label="End Last Bill Date"
              variant="inline"
              inputVariant="outlined"
              autoOk
            />
          </FormControl>
        </div>
        <div className={classes.datePickerFiled}>
          <FormControl className="formControl">
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={startNextDateRange}
              onChange={handleStartNextDateChange}
              label="Start Next Bill Date"
              variant="inline"
              inputVariant="outlined"
              autoOk
            />
          </FormControl>
          <span>to</span>
          <FormControl className="formControl">
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={endNextDateRange}
              onChange={handleEndNextDateChange}
              label="End Next Bill Date"
              variant="inline"
              inputVariant="outlined"
              autoOk
            />
          </FormControl>
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={option => addAttribute('company_id', option ? option.value : null)}
            title="Company"
            fetchSuggestions={fetchSuggestions}
            field="company_id"
            setFieldValue={setCompanyId}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={subscriptions}
            // className={classes.autocompleteField}
            style={{ marginTop: '0.5rem' }}
            freeSolo
            // onChange={(_event, value: any) => setProductId(value)}
            onChange={(_event, value: any) => handleSubscriptionsChange(value)}
            value={productId}
            renderInput={params => (
              <TextField
                {...params}
                name="productId"
                variant="outlined"
                label="Subscription Type "
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={subscriptionPrices}
            // className={classes.autocompleteField}
            style={{ marginTop: '0.5rem' }}
            freeSolo
            // onChange={(_event, value: any) => setProductId(value)}
            onChange={(_event, value: any) => setPriceId(value)}
            value={priceId}
            renderInput={params => (
              <TextField
                {...params}
                name="priceId"
                variant="outlined"
                label="Amount "
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            getOptionSelected={(option: INameValueString, value) => option.name === value.name}
            getOptionLabel={(option: INameValueString) => option.name}
            options={SUBSCRIPTION_STATUSES_ARR}
            // className={classes.autocompleteField}
            style={{ marginTop: '0.5rem' }}
            freeSolo
            // onChange={(_event, value: any) => setProductId(value)}
            onChange={(_event, value: any) => setStatus(value)}
            value={status}
            renderInput={params => (
              <TextField
                {...params}
                name="priceId"
                variant="outlined"
                label="Status "
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Autocomplete
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={states}
            // className={classes.autocompleteField}
            style={{ marginTop: '0.5rem' }}
            freeSolo
            onChange={(_event, value: any) => setStateId(value)}
            value={stateId}
            renderInput={params => (
              <TextField
                {...params}
                name="stateId"
                variant="outlined"
                label="State of Formation"
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Grid>
        <Grid item xs={9}>
          <div className={classes.searchBarContainer}>
            <SearchBar onChange={setQuery} placeholder="Search by name" />
          </div>
        </Grid>
      </Grid>

      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            {tableHead}
            <TableBody>{skeletons({ numberOfColumns: 8, numberOfRows: 10 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Data"
            {...pagedTable.renderProps}
            renderRow={(subscription: ICompanySubscriptionResponse) => (
              <SubscriptionTableRow
                subscription={subscription}
                onEditClick={() => onEditClick(subscription)}
                onDeleteClick={() => onDeleteClick(subscription)}
              />
            )}
            header={tableHead}
            colSpan={8}
          />
        )}
      </div>
    </div>
  )
}

export default CompanySubscription

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '2rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 600,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    searchBarContainer: {
      margin: '0.5rem 0',
    },
    tableContainer: {
      margin: '0.5rem 0',
    },
    datePickerFiled: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '1.5rem',
      marginBottom: '0.5rem',

      '& span': {
        margin: '0 0.5rem',
        fontWeight: 600,
      },

      '& .formControl': {
        minWidth: '120px',
        // minWidth: '100%',

        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    downloadButton: {
      // margin: '0.5rem 0',
      color: 'white',
      textDecoration: 'none',
    },
  })
)
