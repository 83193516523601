import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import NoteIcon from 'icons/note'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openNotesButton: {
      backgroundColor: theme.customPalette.noteDefaultColor,
      '&:hover': {
        backgroundColor: '#fbffb3',
      },
      display: 'flex',
      justifyContent: 'center',
      width: 70,
      right: 0,
      borderRadius: '4px 0 0 4px',
      marginTop: 15,
    },
  })
)
interface IOpenNotesButtonProps {
  onClick: () => void
}

const OpenNotesButton: React.FC<IOpenNotesButtonProps> = ({ onClick }) => {
  const classes = useStyles()
  return (
    <IconButton disableRipple onClick={onClick} className={classes.openNotesButton} data-testid="toggle-notes-button">
      <NoteIcon fontSize="default" />
    </IconButton>
  )
}

export default OpenNotesButton
