import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'

export interface AutoReminderProps {
  id?: number
  name: string
  created_at: string
  total_configs: number
}
export interface AddReminderTemprProps {
  id?: number
  name: string
  created_at: string
  created_by: string
}

export const getAutoReminders = () =>
  requestPaginated<any>({
    url: '/remindertemplateslist',
  })

export const getAutoRemindersById = async (id: number): Promise<[]> =>
  (await apiClient.get(`remindertemplates/${id}`)).data

export const addReminderTemp = async (reminderTempData: AddReminderTemprProps): Promise<any> =>
  await apiClient.post('/remindertemplates', reminderTempData)

export const addRemindersConfig = async (data: any): Promise<AxiosResponse<void>> =>
  await apiClient.post('/reminder-templates-config', data)

export const updateRemindersConfig = async (id: number, data: any): Promise<AxiosResponse<void>> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000/'
  return await apiClient.post(`/remindertemplates/${id}`, data)
}

export const checkTestTemplate = async (data: any): Promise<AxiosResponse<void>> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000'
  return await apiClient.post('/test-remindertemplates', data)
}

export const deleteReminderItems = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/remindertemplates/${id}`)

// export const getTemplatesList = async (): Promise<AxiosResponse<void>> => apiClient.get(`/remindertemplateslist`)

// export const deleteAutoReminders = async (id: number): Promise<AxiosResponse<void>> =>
//   apiClient.delete(`/AutoReminders/${id}`)

// export const createAutoReminders = async (missingData: MissingItemProps): Promise<AxiosResponse<void>> =>
//   await apiClient.post('/AutoReminders', missingData)

// export const editAutoReminders = async (id: number, notificationData: MissingItemProps): Promise<AxiosResponse<void>> =>
//   await apiClient.patch(`AutoReminders/${id}`, notificationData)
