import { usePagedTable } from '@jetkit/react'
import { CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { fetchClient } from 'api/documentTypes'
import { fetchNotes } from 'api/note'
import ClientDetails from 'components/documentTypes/create/documentTypesDetails'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import Notes from 'components/common/notes/notes'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { ISuggestionAttribute } from 'components/interfaces'
import { INote } from 'models/note'
import { IClient, IStates } from 'models/user'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import useRouter from 'use-react-router'
import { removeNullAndUndefined } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import BRTextField from 'components/common/textFields/BRTextField'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { createPassword, resetPassword } from 'api/users'
import showSuccessNotification from 'utils/showSuccessNotification'
import Logo from 'icons/logo'
import useRouter1 from 'hooks/router'
import { LinearProgress } from '@material-ui/core'
import AuthBG from 'img/auth-bg.webp'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundImage: `url(${AuthBG})`,
      height: 'calc(100vh)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',

      [theme.breakpoints.down('xs')]: {
        height: '100vh',
      },
    },

    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      ...theme.title,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    notesContainer: {
      width: '35%',
    },
    flex: {
      display: 'flex',
    },
    formContainer: {
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      color: '#f47950',
    },
  })
)

const ForgotPassword = () => {
  const classes = useStyles()
  const [email, setemail] = React.useState<string>('')
  const [confpassword, setconfpassword] = React.useState<string>('')
  const [passError, setpassError] = React.useState<boolean>(false)
  const router = useRouter<{ id: string | undefined }>()
  const [passlengthError, setpasslengthError] = React.useState<boolean>(false)
  const { history } = useRouter1()
  const [loading, setLoading] = React.useState(false)

  console.log(router.match.params.id)
  const id = router.match.params.id
  const submitPass = async () => {
    try {
      setLoading(true)
      const res = await resetPassword({ username: email })
      setLoading(false)

      if (res.success) {
        showSuccessNotification(`Password Reset Email Sent Successfully`)
        //history.push('/create-password/' + res.id)
      } else if (res.error) {
        showApiResponseError(res.error, `Invalid Email, User doesn't Exists.`)
      }
    } catch (error) {
      setLoading(false)
      if (error.statusCode === 404) showApiResponseError(error, `Failed to create password, User doesn't Exists.`)
      else {
        showApiResponseError(error.message, `Couldn't get, User doesn't Exists.`)
      }
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.flex}>
        <div className={classes.container}>
          <div className={classes.titleButtonContainer}>
            <Logo />

            <Typography className={classes.title}>Enter Email</Typography>
          </div>
          <div className={classes.formContainer}>
            <BRTextField
              // We show copy button in these fields only if we're looking at existing client, not creating a new one.
              //showCopyButton={!!editedClient}
              required
              data-testid="client-password"
              // error={clientEditErrors.password}
              helperText={passlengthError && 'Password cannot be less than 8 characters!'}
              label="Email"
              //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
              //className={classes.textField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorHelperText,
                },
              }}
              type="text"
              name="email"
              margin="normal"
              variant="outlined"
              value={email}
              onChange={event => setemail(event.target.value)}
            />

            <ButtonWithLoading isLoading={loading} style={{ width: '100%' }} onClick={submitPass}>
              Submit
            </ButtonWithLoading>

            <ButtonWithLoading style={{ width: '100%', marginTop: '1rem' }} onClick={() => history.push('/')}>
              Back to Login
            </ButtonWithLoading>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
