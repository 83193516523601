import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from 'theme'

// export as styles to wrap a class
export const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    paper: {
      padding: 10,
      margin: 0,
      height: 'calc(100% - 20px)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'calc(100% - 350px)',
      backgroundColor: '#fafafb',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    popupPaper: {
      // provides additional 65px margin to make it popup-styled
      padding: 10,
      margin: '0 65px 0 0',
      height: 'calc(100% - 150px)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'calc(100% - 480px)',
      backgroundColor: '#fafafb',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      width: '100%',
      fontWeight: 600,
      textAlign: 'center',
    },
    title: {
      width: '100%',
      fontSize: '2rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 600,
      textAlign: 'center',
    },
    titleButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    inlineTitleButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    formContainer: {
      width: 420,
      alignItems: 'center',
      margin: '10px auto 0',
    },
    textField: {
      width: '100%',
      margin: '0.7rem 0 0 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    innerContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    checkBoxAndTextWrapper: {
      width: '104%',
      display: 'flex',
      alignItems: 'center',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    button: {
      width: '100%',
    },
    createCompanyStepWrapper: {
      marginTop: 20,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    searchBarContainer: {
      margin: '0.5rem 0',
      width: '100%',
    },
    ownerModalContainer: {
      width: 'calc(100% - 350px)',
      padding: 10,
      margin: 0,
    },
    ownerModalRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    ownerModalPaper: {
      width: '100%',
      padding: 24,
    },
    ownerModalHeader: {
      fontSize: 24,
    },
    ownerModalSharesContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 90,
    },
    fullWidthContainer: {
      width: '100%',
    },
    ownerModalSectionText: {
      fontSize: 20,
      fontWeight: 300,
    },
    ownerModalListElement: {
      paddingLeft: 0,
      fontSize: 20,
      cursor: 'pointer',
      margin: '6px 0',
    },
    checkBoxWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    ownerModalCheckboxContainer: {
      margin: '2px 0 10px 0 !important',
    },
    addOwnerButton: {
      width: 250,
      marginTop: 10,
      backgroundColor: theme.button.backgroundColor,
      height: 50,
      fontWeight: 'bold',
      color: 'white',
      '&:hover': {
        color: theme.button.hover.color,
        backgroundColor: theme.button.hover.backgroundColor,
      },
    },
    tooltip: {
      fontSize: 16,
    },
    ownersTablePlaceholder: {
      fontSize: 24,
      color: grey[500],
      padding: 16,
      textAlign: 'center',
    },
    sectionTitle: {
      textTransform: 'uppercase',
      fontWeight: 300,
      fontSize: 11,
      margin: '10px 0 10px 0',
    },
    newClientLink: {
      color: grey[700],
      fontWeight: 500,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: grey[800],
      },
    },
    previousStepButton: {
      color: '#2479af',
      marginTop: 10,
    },
    updateOnCompanyLevelText: {
      fontSize: 20,
      cursor: 'pointer',
    },
    updateLevelAndButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
    },
    dialogOneLineCheckboxContainer: {
      justifyContent: 'center',
      marginLeft: '2px',
      marginBottom: '10px',
      display: 'flex',
    },
    addClientButton: {
      float: 'right',
    },
    saveCompanyWrapper: {
      width: '100%',
      marginTop: 10,
    },
    marginTop10: {
      marginTop: 10,
    },
    documentUploads: {
      width: '60vw',
    },
    clientSelectFieldContainer: {
      marginTop: 25,
    },
  })

// default export as styles hooks
export default makeStyles((theme: Theme) => styles(theme))
