import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import { TempNotification } from 'components/notifications/notificationsDialog'
import * as React from 'react'

interface ICompanyTempleteNotiRowProps {
  notification: TempNotification
  onEditClick?: () => void
  onDeleteClick?: () => void
}

const TempleteNotiRow: React.FC<ICompanyTempleteNotiRowProps> = ({ notification, onEditClick, onDeleteClick }) => {
  // Because we cannot show notification dates for all following years in this table row,
  // we just give user main info about further notifications:
  // if all following years are skipped, we show 'No Further Notifications Scheduled'
  // if on all following years notifications should be fired on company's file date, we show 'File Date'
  // if all other cases we show 'Custom Dates'. User will be able to see all these dates by clicking on
  // notification row or edit icon.
  // const nextDatesCellContent = React.useMemo((): string => {
  //   if (
  //     notification.dates.slice(1).filter(notification => !notification.date).length ===
  //     notification.dates.slice(1).length
  //   ) {
  //     return 'No Further Notifications Scheduled'
  //   }
  //   if (
  //     notification.dates.slice(1).filter(notification => notification.use_file_date).length ===
  //     notification.dates.slice(1).length
  //   ) {
  //     return 'File Date'
  //   }
  //   return 'Custom Dates'
  // }, [notification])

  const handleEditIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onEditClick && onEditClick()
    },
    [onEditClick]
  )

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick && onDeleteClick()
    },
    [onDeleteClick]
  )

  return (
    <TableRow onClick={onEditClick} style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      {/* <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}> */}
      <TableCell style={{ width: '100%' }}>{notification?.subject}</TableCell>
      <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleEditIconClick}>
            <EditIcon data-testid="editButton" />
          </IconButton>
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default TempleteNotiRow
