import Paper from '@material-ui/core/Paper'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { BaseTextFieldProps } from '@material-ui/core/TextField'
import BRTextField from 'components/common/textFields/BRTextField'
import * as React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { ValueContainerProps } from 'react-select/src/components/containers'
import { ControlProps } from 'react-select/src/components/Control'
import { MenuProps } from 'react-select/src/components/Menu'
import { StylesConfig } from 'react-select/src/styles'
import { ValueType } from 'react-select/src/types'

const styles = createStyles({
  input: {
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: '0.25rem',
    borderRadius: '0.25rem',
    left: 0,
    right: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  container: {
    width: '100%',
  },
  placeholder: {
    // disable react-select placeholder, as we are using placeholder from TextField component
    display: 'none',
  },
  disabled: {
    '&.MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
  },
  customValue: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DEEBFF',
    },
  },
})

interface IOption {
  value: string | number
  label: string
}

interface ISelectAutocomplete extends WithStyles<typeof styles> {
  options: ValueType<IOption>
  required?: boolean
  label?: string
  value?: string | number
  handleChange(value: string | number): void
  handleCustomValueChange?: (value: string) => void
  handleClear?: () => void
  error?: boolean
  helperText?: string
  containerStyles?: React.CSSProperties
  placeholderStyles?: React.CSSProperties
  isDisabled?: boolean
  showCopyButton?: boolean
  dataTestId?: string
  isClearable?: boolean
}

const SelectAutocomplete: React.FunctionComponent<ISelectAutocomplete> = ({
  options,
  value,
  required,
  label,
  error,
  helperText,
  isDisabled,
  classes,
  handleChange,
  handleCustomValueChange,
  handleClear,
  containerStyles,
  placeholderStyles,
  showCopyButton,
  dataTestId = `${label}-select-autocomplete`,
  isClearable = false,
}) => {
  type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & React.HTMLAttributes<HTMLDivElement>
  const customStyles: StylesConfig = {
    container: (base: React.CSSProperties) => ({
      ...base,
      ...containerStyles,
      width: containerStyles ? containerStyles.width : '100%',
      // If Select component is disabled, pointerEvents is set to `none`, which blocks
      // clicks on input adornments. Because of that we set it to `unset`
      pointerEvents: 'unset',
    }),
    placeholder: base => ({
      // disable react-select placeholder, as we are using placeholder from TextField component
      ...base,
      ...placeholderStyles,
      display: 'none',
    }),
    input: base => ({
      ...base,
      color: isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
    }),
  }

  // Select component from react-select has a bug - when field is cleared,
  // menu does not hide. To fix that I created a ref to call `blur()` when field is cleared.
  // I installed @types/react-select to get the type of `ref` prop of Select component,
  // but providing that type to generic hook `useRef()` sets type `boolean` to const selectRef
  // and also creates a bunch of typing errors in Select component props. Considering all that,
  // I decided to use type `any` and perform all necessary checks when accessing this object's properties.
  const selectRef = React.useRef<any>()

  const blurSelf = React.useCallback(() => {
    selectRef && selectRef.current.blur && typeof selectRef.current.blur === 'function' && selectRef.current.blur()
  }, [selectRef])

  const inputComponent = ({ inputRef, ...props }: InputComponentProps) => {
    return <div ref={inputRef} {...props} />
  }

  const ValueContainer = React.useCallback(
    (props: ValueContainerProps<IOption>) => {
      return <div className={classes.valueContainer}>{props.children}</div>
    },
    [classes.valueContainer]
  )

  const Control = React.useCallback(
    (props: ControlProps<IOption>) => {
      const value = props.getValue()

      // We need to be able to pass to parent component not only the selected option, but also a free-form text,
      // that user types while searching for desired option, to store it there.
      // Unfortunately, if we try to update string value in parent component dynamically, by passing
      // some 'set' method from there to `onChange` prop of this BRTextField, this whole Select component will
      // re-render and input will lose focus. Because of that, we store this `string` value internally,
      // inside this FC. And when `onBlur` will be fired for this textfield, we call `sendTextInputToParent`
      // method, passed from parent, that accepts a `string` parameter and handles it on upper level.
      // const [stringValue, setStringValue] = React.useState('')

      return (
        <BRTextField
          dataTestId={dataTestId}
          disabled={isDisabled}
          showCopyButton={showCopyButton}
          fullWidth
          required={!!required}
          // use value from react-select component so that label is displayed properly
          // or display the passed value
          value={(value && value[0] && value[0].label) || props.selectProps.inputValue}
          // onChange={event => setStringValue(event.target.value)}
          type="text"
          label={label}
          margin="none"
          // onBlur={() => sendTextInputToParent && sendTextInputToParent(stringValue)}
          onClick={e => e.stopPropagation()} // not to cause any other events in the parent component
          variant="outlined"
          error={error}
          helperText={error ? helperText : ''}
          InputProps={{
            classes: { root: isDisabled ? classes.disabled : undefined },
            inputComponent,
            name: label,
            inputProps: {
              className: classes.input,
              inputRef: props.innerRef,
              children: props.children,
              ...props.innerProps,
            },
          }}
          {...props.selectProps.TextFieldProps}
        />
      )
    },
    [classes.disabled, classes.input, dataTestId, error, helperText, isDisabled, label, required, showCopyButton]
  )

  const handleCustomOptionClick = React.useCallback(
    (value: string) => {
      handleCustomValueChange && handleCustomValueChange(value)
      blurSelf()
    },
    [handleCustomValueChange, blurSelf]
  )

  const NoOptionsMessage = React.useCallback(
    props => {
      const { inputValue } = props.selectProps

      return (
        <div className={classes.customValue} onClick={() => handleCustomOptionClick(inputValue as string)}>
          {inputValue}
        </div>
      )
    },
    [classes.customValue, handleCustomOptionClick]
  )

  const Menu = React.useCallback(
    (props: MenuProps<IOption>) => {
      return (
        <Paper
          data-testid={`${dataTestId}-options`}
          onClick={e => e.stopPropagation()}
          square
          className={classes.paper}
          {...props.innerProps}
        >
          {props.children}
        </Paper>
      )
    },
    [classes.paper, dataTestId]
  )

  // If component is meant to handle selection of some custom value, that is not present
  // in options list, we provide our custom 'NoOptionsMessage' component, that when clicked,
  // calls specific custom value change handler from parent component.
  const components = React.useMemo(
    () =>
      handleCustomValueChange
        ? {
            Control,
            Menu,
            ValueContainer,
            NoOptionsMessage,
            IndicatorsContainer: () => null,
            placeholder: () => null,
          }
        : {
            Control,
            Menu,
            ValueContainer,
            IndicatorsContainer: () => null,
            placeholder: () => null,
          },
    // Eslint wants us to have Control, Menu, NoOptionsMessage and ValueContainer wrapped in useMemo hook,
    // but we can't really do that, because they are functional components, that accept props, and useMemo does
    // not allow to pass props.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Control, Menu, NoOptionsMessage, ValueContainer, handleCustomValueChange]
  )

  const onChange = (option: ValueType<IOption>) => {
    if (option === null) {
      blurSelf()
      handleClear && handleClear()
    }

    // this is single select, arrays are not possible.
    // this line is here, because react-select is implemented to not differentiate between single and multi select fields.
    if (Array.isArray(option)) return

    if (option && (option as IOption).value) {
      handleChange((option as IOption).value)
    }
  }

  const [isFocused, setIsFocused] = React.useState(false)

  const inputValue = React.useMemo(() => {
    if (!options || (Array.isArray(options) && options.some(option => option.label === value)) || isFocused)
      return undefined

    return value
  }, [options, value, isFocused])

  const onBlur = React.useCallback(() => {
    if (selectRef && selectRef.current && selectRef.current.state && !selectRef.current.state.value && handleClear) {
      handleClear()
    }
    setIsFocused(true)
  }, [handleClear])

  return (
    <CreatableSelect
      ref={selectRef}
      // If Select component is focused, we pass actual `string` value typed by user there so it will be displayed.
      // Else we pass here `undefined`, so either a selected value or nothing at all will be displayed.
      inputValue={inputValue}
      onFocus={() => setIsFocused(true)} //edited
      onBlur={onBlur}
      // onBlur={() => input.onBlur({value: input.value})}
      controlProps
      isDisabled={isDisabled}
      styles={customStyles}
      options={options || undefined}
      variant="outlined"
      defaultInputValue={value}
      isClearable={isClearable}
      onChange={onChange}
      placeholder={label}
      components={components}
    />
  )
}

export default withStyles(styles)(SelectAutocomplete)
