import { SortingType } from 'components/common/table'
import * as React from 'react'

export interface ISortSettings {
  sort_by: string
  order: SortingType
}

interface ISortSettingsContext {
  onSortSettingsChange: React.Dispatch<React.SetStateAction<ISortSettings>>
}

export const SortSettingsContext = React.createContext<ISortSettingsContext>({ onSortSettingsChange: () => void 0 })

interface IUseSortTableProps extends ISortSettings {}

function useSortTable({ order, sort_by }: IUseSortTableProps) {
  const [sortSettings, setSortSettings] = React.useState<ISortSettings>({ order, sort_by })
  const { onSortSettingsChange } = React.useContext(SortSettingsContext)

  const handleSortingClick = React.useCallback(
    (sort_by: string) => {
      // Do not rewrite this with useEffect
      // It will cause infinite rendering in parent

      setSortSettings(prevState => ({
        order: prevState.order === 'asc' ? 'desc' : 'asc',
        sort_by,
      }))

      onSortSettingsChange(prevState => ({
        order: prevState.order === 'asc' ? 'desc' : 'asc',
        sort_by,
      }))
    },
    [onSortSettingsChange]
  )

  return {
    sortSettings,
    handleSortingClick,
  }
}

export default useSortTable
