import * as React from 'react'

const ExportIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18">
    <g fill="#5b5555" fillRule="nonzero">
      <path d="M17.531 0h-4.673a.469.469 0 100 .938h3.549l-9.118 9.205a.469.469 0 00.666.66l9.107-9.195v3.534a.469.469 0 10.938 0V.47A.469.469 0 0017.531 0z" />
      <path d="M15.194 10.054a.469.469 0 00-.469.47v5.132c0 .777-.63 1.406-1.406 1.406H2.344c-.777 0-1.406-.63-1.406-1.406V4.681c0-.776.63-1.405 1.406-1.406h5.133a.469.469 0 100-.938H2.344A2.346 2.346 0 000 4.681v10.975A2.346 2.346 0 002.344 18h10.975a2.346 2.346 0 002.344-2.344v-5.133a.469.469 0 00-.47-.469z" />
    </g>
  </svg>
)

export default ExportIcon
