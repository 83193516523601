import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TableActionButton from 'components/common/buttons/tableActionButton'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { ICompany, legalTypesToHumanReadableFormat } from 'models/company'
import * as React from 'react'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import DeleteIcon from 'components/common/icons/delete'
import { OfferCard, OfferDialog } from 'pages/clientDashboard/offers'
import Alert from '@material-ui/lab/Alert'
import { getCompanySpecialOffer } from 'api/companies'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: 40,
      height: 40,
    },
    deleteIcon: {
      color: theme.destructiveColor,
      fontSize: '2rem',
      padding: 1,
    },
  })
)

interface ICompanyOffersProps {
  company_id: number
}

const CompanySpecialOffers: React.FC<ICompanyOffersProps> = ({ company_id }) => {
  const classes = useStyles()
  const [offers, setOffers] = React.useState<any>([])
  const getOffers = async () => {
    try {
      const offers = await getCompanySpecialOffer(company_id)
      setOffers(offers)
    } catch (error) {
      console.log('error', error)
    }
  }
  React.useEffect(() => {
    getOffers()
  }, [])
  return (
    <>
      <h1>Power Ups</h1>
      <Grid container spacing={3}>
        {offers.length ? (
          offers?.map((offer, key) => (
            <Grid item xs={6} md={4} lg={2} key={key}>
              <OfferCard
                img={offer.logo}
                // mainTextColor={offer.color}
                borderColor={offer.color}
                mainText={offer.title}
              />
            </Grid>
          ))
        ) : (
          //   [...Array(6)].map((value: undefined, index: number) => (
          //       <Grid item xs={6} md={4} lg={2} key={index}>
          //         <Skeleton variant="rect" height={145} />
          //       </Grid>
          //     ))
          <Grid item xs={6} md={4} lg={2} key={1}>
            <Alert severity="info">No Offers</Alert>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default CompanySpecialOffers
