import { notify } from '@jetkit/react'
import {
  Checkbox,
  createStyles,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Theme,
  Select,
  OutlinedInput,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  createCustomDateNotification,
  createNotification,
  editNotification,
  getNotificationById,
  getstates,
  INotificationRequestData,
  INotificationTemplate,
  notificationTemplates,
  updateCustomDateNotification,
} from 'api/notifications'
import { fetchOrderTypes } from 'api/orders'
import { createCompanySuggestions } from 'api/suggestions'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import DatePicker from 'components/common/datePicker'
import SelectField from 'components/common/select'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import { ISuggestionAttribute } from 'components/interfaces'
import {
  ICompany,
  ILegalType,
  legalTypesList,
  legalTypesToApiFormat,
  legalTypesToHumanReadableFormat,
} from 'models/company'
import { IOrderType } from 'models/order'
import moment from 'moment'
import { NotificationType } from 'pages/notifications'
import * as React from 'react'
import { fakeFetchCompanyTypes, ILegalTypes, legalTypesLists } from 'utils/autocompleteFakers'
import {
  backendDateFormat,
  datePickerDateFormat,
  datePickerDateFormatMMYYYY,
  datePickerDatePlaceholder,
  datePickerDatePlaceholderMMYYYY,
  daySubstring,
  formatDateToHumanFriendly,
  monthSubstring,
  yearSubstring,
} from 'utils/formatDate'
import showApiResponseError from 'utils/showApiResponseError'
import usaStates from 'utils/usaStates'
import CustomizeDatesDialog from './customizeDatesDialog'
import NextYearsView from './nextYearsView'
import useNotificationErrors from './notificationErrors'
import {
  ITaxOrderType,
  TaxOrderTypeOptions,
  TaxStructureBusinessOptions,
  TaxStructurePersonalOptions,
} from 'models/taxOrder'
import OutlinedSelect from 'components/common/outlinedSelect'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '33.5rem',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '3.5rem',
      marginRight: '3.5rem',

      '& .select-notification-template': {
        '& .MuiFormControl-root ': {
          margin: '0 !important',
          width: '100%',
        },
      },
      '& .select-notification-margin': {
        margin: '0.5rem 0',
      },
    },
    closeButton: {
      width: 'min-content',
      alignSelf: 'flex-end',
      marginRight: '1rem',
    },
    title: {
      fontSize: '1.75rem',
      fontWeight: 600,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary,
      marginBottom: '0.4rem',
    },
    subTitle: {
      fontSize: '1.125rem',
      letterSpacing: '0.09px',
      color: theme.customPalette.lightGray,
      textTransform: 'uppercase',
      marginBottom: '1rem',
    },
    select: {
      width: '100%',
      marginRight: '0.3rem',
      paddingBottom: '0.06rem',
      marginTop: '0.875rem',
    },
    textField: {
      margin: '0.5rem 0',
    },
    nextYearsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      border: '1px solid rgb(196, 196, 196)',
      borderRadius: '0.25rem',
      alignItems: 'center',
    },
    customizeButton: {
      color: '#3c9fdf',
      textDecoration: 'underline',
      fontSize: '1.125rem',
      fontWeight: 500,
      alignSelf: 'flex-end',
      cursor: 'pointer',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      '&:disabled': {
        opacity: 0.5,
      },
    },
    scheduleButton: {
      width: '13.75rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      alignSelf: 'center',
      background: '#ececee',
      color: '#3c9fdf',

      '& .MuiButton-contained': {
        background: '#ececee',
        color: '#3c9fdf',
        fontWeight: 500,
        fontSize: '1.125rem',
        boxShadow: 'none',
      },
    },
    checkBoxSec: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

const defaultYearsCount = 2

export interface INotificationDate {
  year?: number
  date?: string
  use_file_date?: boolean
  file_date?: boolean
  id?: number
  notification_schedule_id?: number
  schedule_date?: string
  skip?: boolean
}
export interface INewNotificationDate {
  file_date: boolean
  id: number
  notification_schedule_id: number
  schedule_date: Date
  skip: boolean
  year: number
}

// Interface IEditableNotificationDate is a frontend version of
// INotificationDate interface, that is more comfortable for modifying
// notification dates table.
export interface IEditableNotificationDate {
  notification_schedule_id?: number
  years?: number
  schedule_date: Date
  file_date?: boolean
  skip?: boolean
  id?: number
  year?: number
  date?: string
  use_file_date?: boolean
}

export interface INotification {
  template: INotificationTemplate
  company_type?: string | number
  state_of_formation?: string
  company?: ICompany
  order_type: IOrderType
  dates: INotificationDate[]
  next_date: string
  id: number
  stateName?: string
  templateName?: string
  orderTypeName?: string
  first_year_schedule?: string
  following_year_file_date?: boolean
  first_year_schedule_date?: string
  following_year_schedule?: string
  title?: string
  tax_structure?: string
  notify_only: boolean
  notification_years_data?: INotificationDate[]
  email_template_id?: number
}

export interface TempNotification {
  id?: number
  subject?: string
  content?: string
  heading?: string
  sub_heading?: string
  email?: string
  button1_text?: string
  button2_text?: string
  button3_text?: string
  button1_link?: string
  button2_link?: string
  button3_link?: string
  protip_heading?: string
  protip_content?: string
}

interface ICompanyTypeNotificationDialogProps {
  isOpen: boolean
  close: () => void
  notificationType: NotificationType
  editedNotification?: INotification
  onRequestSuccessful?: () => void
}

const NotificationDialog: React.FC<ICompanyTypeNotificationDialogProps> = ({
  isOpen,
  close,
  notificationType,
  editedNotification,
  onRequestSuccessful,
}) => {
  const classes = useStyles()

  // `SelectField` component accepts `value` prop of type `ISuggestionAttribute`.
  // In order to be able to pass data there (e.g. while prefilling fields when editing notification),
  // and also because we need `id`s of stuff to send to API, some of values have this specific type.
  const [company, setCompany] = React.useState<ISuggestionAttribute>()
  const [companyType, setCompanyType] = React.useState<number | string>()
  // const [orderType, setOrderType] = React.useState<ISuggestionAttribute>()
  const [orderType, setOrderType] = React.useState<number | string | any>()
  const [orderTypeList, setOrderTypeList] = React.useState<IOrderType[]>()

  const [stateOfFormation, setStateOfFormation] = React.useState<string | number>()
  // const [notificationTemplate, setNotificationTemplate] = React.useState<ISuggestionAttribute>()

  const [notificationTemplate, setNotificationTemplate] = React.useState<number | string | any>()
  const [notificationTemplateList, setNotificationTemplateList] = React.useState<any[]>()

  const [firstDate, setFirstDate] = React.useState<Date>()
  const [nextDates, setNextDates] = React.useState<IEditableNotificationDate[]>()
  const [customizeDialogShown, setCustomizeDialogShown] = React.useState(false)
  const [waitingForResponse, setWaitingForResponse] = React.useState(false)
  const [scheduleYear, setScheduleYear] = React.useState<string>()
  const [states, setStates] = React.useState([])

  const [followingYears, setFollowingYears] = React.useState<Date>()
  const [notificationTitle, setNotificationTitle] = React.useState<string>()
  const [taxStructure, setTaxStructure] = React.useState('')
  const [useCompanyFileDate, setuseCompanyFileDate] = React.useState(false)
  const [notifyOnly, setNotifyOnly] = React.useState<boolean>(false)

  const [schedulesDates, setSchedulesDates] = React.useState<INewNotificationDate[]>()

  const errors = useNotificationErrors()
  React.useEffect(() => {
    const followDate = moment(followingYears, datePickerDateFormat).toDate()
    generateTenYears(followDate)
    // dynamicDate(followDate)
  }, [followingYears])

  React.useEffect(() => {
    const today = moment().format(datePickerDateFormat)
    const followDate = moment(today, datePickerDateFormat).toDate()
    setFollowingYears(followDate)
  }, [])
  // In order to reuse this component not only to create, but also to edit notifications,
  // every time it opens, we check, whether `editedNotification` prop exists. If so, we
  // prefill all fields with data from this notification.

  const getNotification = async (id: number) => {
    try {
      const res: any = await getNotificationById(id)
      const dates = res?.notification_years_data
      setSchedulesDates(dates)
      console.log('Resilt', res)
    } catch (error) {
      console.log(error)
    }
  }

  const getOrderTypeSuggestions = async (): Promise<ISuggestionAttribute[]> => {
    const res = await fetchOrderTypes('')
    setOrderTypeList(res)
    return res.map(orderType => {
      return { value: orderType.id, label: orderType.name }
    })
  }

  React.useEffect(() => {
    getOrderTypeSuggestions()
  }, [])

  React.useEffect(() => {
    if (editedNotification?.id) {
      getNotification(editedNotification?.id)
    }
  }, [editedNotification?.id])

  React.useEffect(() => {
    if (isOpen && editedNotification && schedulesDates) {
      // if (notificationType === 'company' && editedNotification.company) {
      //   setCompany({
      //     value: editedNotification.company.id,
      //     label: editedNotification.company.name,
      //   })
      // } else {
      setNotifyOnly(editedNotification.notify_only)
      if (editedNotification.company_type) {
        setCompanyType(editedNotification.company_type || '')
      }
      setTaxStructure(editedNotification?.tax_structure || '')
      setStateOfFormation(editedNotification.state_of_formation as string)
      // }
      // setOrderType({
      //   value: editedNotification.order_type?.id,
      //   label: editedNotification?.orderTypeName || '',
      // })
      setOrderType(editedNotification.order_type)
      // setNotificationTemplate({
      //   value: editedNotification?.template?.id,
      //   label: editedNotification?.templateName || '',
      // })
      // setNotificationTemplate(editedNotification?.template?.id)
      setNotificationTemplate(editedNotification?.email_template_id)

      setScheduleYear(editedNotification?.first_year_schedule)

      setNotificationTitle(editedNotification?.title)

      if (editedNotification?.following_year_schedule) {
        setFollowingYears(moment(editedNotification?.following_year_schedule).toDate())
        generateTenYears(moment(editedNotification?.following_year_schedule).toDate())
      }
      if (editedNotification?.first_year_schedule_date) {
        setFirstDate(moment(editedNotification?.first_year_schedule_date).toDate())
        generateTenYears(moment(editedNotification?.following_year_schedule).toDate())
      }
      if (schedulesDates) {
        const firstDate = moment(schedulesDates && schedulesDates[0]?.schedule_date).toDate()
        const nextDates =
          schedulesDates &&
          schedulesDates?.map((schedulesDate: INewNotificationDate, index: number): any => {
            const date: Date = schedulesDate.schedule_date
              ? moment(schedulesDate.schedule_date).toDate()
              : moment(`01/01/${schedulesDate.year}`).toDate()
            return {
              // id: schedulesDate?.id,
              id: index + 1,
              schedule_date: date,
              file_date: schedulesDate.file_date,
              skip: schedulesDate.skip,
            }
          })
        setFirstDate(firstDate)
        setNextDates(nextDates)
      }
      // if (editedNotification.dates) {
      //   const firstDate = moment(editedNotification.dates[0].date).toDate()
      //   const nextDates = editedNotification.dates.slice(1).map(
      //     (notificationDate: INotificationDate): IEditableNotificationDate => {
      //       const date: Date = notificationDate.date
      //         ? moment(notificationDate.date).toDate()
      //         : moment(`01/01/${notificationDate.year}`).toDate()
      //       return {
      //         schedule_date: date,
      //         file_date: notificationDate.use_file_date,
      //         skip: !Boolean(notificationDate.date),
      //       }
      //     }
      //   )
      //   setFirstDate(firstDate)
      //   setNextDates(nextDates)
      // }
    } else if (!isOpen) {
      setCompany(undefined)
      setCompanyType(undefined)
      setFirstDate(new Date())
      setNextDates([])
      setNotificationTemplate(undefined)
      setOrderType(undefined)
      setStateOfFormation(undefined)
      errors.clearAll()
    }
    // If `errors` is mentioned in deps here, we get an infinite re-render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, editedNotification, notificationType, schedulesDates])

  const generateTenYears = React.useCallback(
    (firstYearDate: Date) => {
      if (editedNotification) {
        // const firstDate = moment(schedulesDates[0].schedule_date).toDate()
        const nextDates = schedulesDates?.map((schedulesDate: INewNotificationDate, index: number): any => {
          const date: Date = schedulesDate.schedule_date
            ? moment(schedulesDate.schedule_date).toDate()
            : moment(`01/01/${schedulesDate.year}`).toDate()
          return {
            id: index + 1,
            schedule_date: date,
            file_date: schedulesDate.file_date,
            skip: schedulesDate.skip,
          }
        })
        // setFirstDate(firstDate)
        setNextDates(nextDates)
      } else {
        const firstYear = parseInt(yearSubstring(firstYearDate))
        const monthString = monthSubstring(firstYearDate)
        const dayString = daySubstring(firstYearDate)

        const initValue: IEditableNotificationDate = {
          id: 1,
          // schedule_date: moment(`${monthString}/${dayString}/${firstYear + 1}`).toDate(),
          schedule_date: moment(`${monthString}/${dayString}/${firstYear}`).toDate(),
          file_date: useCompanyFileDate,
          skip: false,
        }
        setNextDates([initValue])
      }
      // const years: IEditableNotificationDate[] = Array(defaultYearsCount - 1)
      //   .fill(null)
      //   .map(
      //     (value, index): IEditableNotificationDate => {
      //       return {
      //         id: index + 1,
      //         schedule_date: moment(`${monthString}/${dayString}/${firstYear + index + 1}`).toDate(),
      //         file_date: useCompanyFileDate,
      //         skip: false,
      //       }
      //     }
      //   )
    },
    [editedNotification, schedulesDates, useCompanyFileDate]
  )

  console.log('schedulesDates', schedulesDates)
  console.log('new NextDates', nextDates)

  console.log('editedNotification', editedNotification)

  // const handleCompanyChange = React.useCallback(
  //   (item: ISuggestionAttribute | null) => {
  //     errors.setCompany(false)
  //     setCompany(item || undefined)
  //   },
  //   [errors]
  // )

  // const handleOrderTypeChange = React.useCallback(
  //   (item: ISuggestionAttribute | null) => {
  //     errors.setOrderType(false)
  //     setOrderType(item || null)
  //   },
  //   [errors, setOrderType]
  // )

  // const handleStateChange = React.useCallback(
  //   (state: string | number) => {
  //     errors.setStateOfFormation(false)
  //     setStateOfFormation(state)
  //   },
  //   [errors, setStateOfFormation]
  // )

  // const handleNotificationTemplateChange = React.useCallback(
  //   (item: ISuggestionAttribute | null) => {
  //     errors.setNotificationTemplate(false)
  //     setNotificationTemplate(item || undefined)
  //   },
  //   [errors, setNotificationTemplate]
  // )

  const handleFirstYearScheduleChange = React.useCallback(
    (date: string) => {
      errors.setFirstDate(false)
      const firstDate = moment(date, datePickerDateFormat).toDate()
      if (isNaN(firstDate.getTime())) {
        setFirstDate(undefined)
        setNextDates(undefined)
        return
      }
      setFirstDate(firstDate)
      generateTenYears(firstDate)
      // dynamicDate(firstDate)
    },
    [errors, generateTenYears]
  )

  const handleFollowYearScheduleChange = React.useCallback(
    (date: string) => {
      // errors.setFirstDate(false)
      // const firstDate = moment(date, datePickerDateFormat).toDate()
      // if (isNaN(firstDate.getTime())) {
      //   setFirstDate(undefined)
      //   setNextDates(undefined)
      //   return
      // }
      const followDate = moment(date, datePickerDateFormat).toDate()
      setFollowingYears(followDate)
      // generateTenYears(followDate)
    },
    [generateTenYears]
  )
  const taxStructureSelectOptions = React.useMemo(() => Object.values(TaxStructureBusinessOptions), [])
  // const handleFirstYearScheduleChange = React.useCallback(
  //   (date: string) => {
  //     errors.setFirstDate(false)
  //     const firstDate = moment(date, datePickerDateFormat).toDate()
  //     if (isNaN(firstDate.getTime())) {
  //       setFirstDate(undefined)
  //       setNextDates(undefined)
  //       return
  //     }
  //     setFirstDate(firstDate)
  //     generateTenYears(firstDate)
  //   },
  //   [errors, generateTenYears]
  // )

  const handleNextYearsCustomize = React.useCallback(
    (years: IEditableNotificationDate[]) => {
      setNextDates(years)
    },
    [setNextDates]
  )

  const notSkippedYears = React.useMemo(() => {
    if (!nextDates) return undefined
    return nextDates.filter(year => !year.skip)
  }, [nextDates])

  const setErrorMessages = React.useCallback(() => {
    if (notificationType === 'company' && !company) errors.setCompany(true)
    if (notificationType === 'companyType' && !companyType) errors.setCompanyType(true)
    // if (!orderType) errors.setOrderType(true)
    if (notificationType === 'companyType' && !stateOfFormation) errors.setStateOfFormation(true)
    if (!notificationTemplate) errors.setNotificationTemplate(true)
    if (!firstDate) errors.setFirstDate(true)
  }, [notificationType, company, companyType, orderType, stateOfFormation, notificationTemplate, firstDate, errors])

  // const datesForBackend = React.useCallback(
  //   (firstDate: Date, nextDates: IEditableNotificationDate[]): INotificationDate[] => {
  //     return [
  //       {
  //         year: parseInt(moment(firstDate, datePickerDateFormat).format('YYYY')),
  //         date: moment(firstDate).format(backendDateFormat),
  //         use_file_date: false,
  //       },
  //       ...nextDates.map(
  //         (date): INotificationDate => {
  //           return {
  //             year: parseInt(moment(date.schedule_date).format('YYYY')),
  //             date: date.skip ? undefined : moment(date.schedule_date).format(backendDateFormat),
  //             use_file_date: date.file_date,
  //           }
  //         }
  //       ),
  //     ]
  //   },
  //   []
  // )

  const tryToSendRequest = React.useCallback(async () => {
    // let notificationData: INotificationRequestData
    if (!notificationTemplate || !scheduleYear || !notificationTitle) return
    const notificationData: INotificationRequestData = {
      company_type: companyType,
      state_of_formation: stateOfFormation ? stateOfFormation : undefined,
      // order_type: orderType?.value,
      order_type: orderType ? orderType : undefined,
      tax_structure: taxStructure,
      // email_template_id: notificationTemplate.value,
      email_template_id: notificationTemplate,
      // dates: datesForBackend(firstDate, nextDates),
      first_year_schedule: scheduleYear,
      first_year_schedule_date: moment(firstDate).format('MM/DD/YYYY'),
      following_year_schedule: formatDateToHumanFriendly(followingYears || ''),
      title: notificationTitle,
      notify_only: notifyOnly,
    }
    setWaitingForResponse(true)

    try {
      if (editedNotification) {
        await editNotification(editedNotification.id, notificationData)

        const tempData = nextDates?.map((item: any, index: number) => {
          const year = index + 1
          return {
            notification_schedule_id: editedNotification.id,
            year: year,
            schedule_date: item?.schedule_date,
            file_date: item?.file_date,
            skip: item?.skip,
          }
        })

        const updateData = {
          data: tempData || [],
        }
        await updateCustomDateNotification(editedNotification.id, updateData)
      } else {
        const res: any = await createNotification(notificationData)
        const notificationId = res?.data?.id

        const tempData = nextDates?.map((item: any, index: number) => {
          const year = index + 1
          return {
            notification_schedule_id: notificationId,
            year: year,
            schedule_date: item?.schedule_date,
            file_date: item?.file_date,
            skip: item?.skip,
          }
        })

        const customData = {
          data: tempData || [],
          // data: nextDates?.map((data: any) => {
          //   return {
          //     notification_schedule_id: res?.data?.id,
          //     data,
          //   }
          // }),
          // data: nextDates?.push({ notification_schedule_id: res?.data?.id }) || [],
        }
        await createCustomDateNotification(customData)
      }
      setWaitingForResponse(false)
      close()
      notify.success(`${editedNotification ? 'Notification Updated!' : 'Notification Created!'}`)
      onRequestSuccessful && onRequestSuccessful()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, `Sorry, could not ${editedNotification ? 'edit' : 'create'} notification`)
    }
  }, [
    companyType,
    orderType,
    stateOfFormation,
    notificationTemplate,
    scheduleYear,
    followingYears,
    editedNotification,
    close,
    onRequestSuccessful,
    nextDates,
    notifyOnly,
    taxStructure,
  ])

  const handleScheduleClick = React.useCallback(() => {
    setErrorMessages()
    tryToSendRequest()
  }, [setErrorMessages, tryToSendRequest])

  const buttonTitle = React.useMemo(() => (editedNotification ? 'Save Changes' : 'Schedule Notification'), [
    editedNotification,
  ])

  React.useEffect(() => {
    const states = async () => {
      const state = await getstates()
      setStates(state)
    }
    if (isOpen) {
      states()
    }
  }, [isOpen])

  const stateOptions = () => {
    return states?.map((state: any) => ({
      value: state?.id,
      label: state?.state_display_name,
    }))
  }
  React.useEffect(() => {
    console.log('useCompanyFileDate', useCompanyFileDate)
    const temp = nextDates
    temp?.map(item => {
      item.file_date = useCompanyFileDate
    })
    setNextDates(temp)
  }, [useCompanyFileDate])

  React.useEffect(() => {
    console.log('notify', notifyOnly)
  }, [notifyOnly])

  React.useEffect(() => {
    const getNotificationTemplates = async () => {
      try {
        const res = await notificationTemplates()
        setNotificationTemplateList(res)
      } catch (error) {
        console.log('error')
      }
    }
    getNotificationTemplates()
  }, [])

  return (
    <Dialog open={isOpen} PaperProps={{ className: classes.paper }}>
      <CustomizeDatesDialog
        open={customizeDialogShown}
        close={() => setCustomizeDialogShown(false)}
        dates={nextDates}
        onSaveDates={handleNextYearsCustomize}
      />
      <IconButton onClick={close} className={classes.closeButton}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <div className={classes.innerContainer} data-testid="notification-dialog-inner-container">
        <div className={classes.title}>Schedule Notifications </div>
        <div className={classes.subTitle}>
          {notificationType === 'company' ? 'for specific company' : 'for specific company type'}
        </div>
        {/* {notificationType === 'company' && (
          <div data-testid="company-name">
            <SelectField
              value={company}
              error={errors.company}
              errorText="Please Choose Company"
              onOptionSelected={handleCompanyChange}
              title={company ? 'Company Name' : ''}
              fetchSuggestions={createCompanySuggestions}
              field="company_id"
              placeholder="Company Name"
              disabled={waitingForResponse}
            />
          </div>
        )} */}
        {/* {notificationType === 'companyType' && (
          <div data-testid="company-type">
            <SelectField
              value={companyType}
              error={errors.companyType}
              errorText="Please Choose Company Type"
              onOptionSelected={option =>
                handleCompanyTypeChange(option ? ((option?.value as unknown) as ILegalTypes) : undefined)
              }
              fetchSuggestions={fakeFetchCompanyTypes}
              title={companyType ? 'Company Type' : ''}
              placeholder="Company Type"
              field=""
              disabled={waitingForResponse}
            />
          </div>
        )} */}
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <TextField
            id="notificationTitle"
            label="Title"
            variant="outlined"
            value={notificationTitle ? notificationTitle : editedNotification?.title}
            onChange={e => setNotificationTitle(e.target.value)}
          />
          {/* <OutlinedInput
            id="outlined-adornment-Title"
            aria-describedby="outlined-Title-helper-text"
            inputProps={{
              'aria-label': 'Title',
            }}
            name="notificationTitle"
            value={notificationTitle}
            onChange={e => setNotificationTitle(e.target.value)}
          /> */}
        </FormControl>
        <div data-testid="company-type" className="select-notification-template">
          <FormControl variant="outlined">
            <InputLabel>Company Type</InputLabel>
            <Select
              onChange={(e: any) => setCompanyType(e.target.value)}
              label="Company Type"
              name="companyType"
              fullWidth
              value={companyType ? companyType : editedNotification?.company_type}
            >
              {legalTypesLists?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div data-testid="order-type" className="select-notification-template select-notification-margin">
          <FormControl variant="outlined">
            <InputLabel>Order Type</InputLabel>
            <Select
              onChange={(e: any) => setOrderType(e.target.value)}
              label="Order Type"
              name="orderType"
              fullWidth
              value={orderType ? orderType : editedNotification?.order_type}
            >
              {orderTypeList?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <SelectField
            value={orderType}
            error={errors.orderType}
            errorText="Please Choose Order Type"
            onOptionSelected={handleOrderTypeChange}
            fetchSuggestions={getOrderTypeSuggestions}
            title={orderType ? 'Order Type' : ''}
            placeholder="Order Type"
            field="order_type_id"
            disabled={waitingForResponse}
          /> */}
        </div>

        {/* <div data-testid="state-of-formation">
          <SelectAutocomplete
            isDisabled={waitingForResponse}
            error={errors.stateOfFormation}
            helperText="Please Select State"
            containerStyles={{ margin: '0.5rem 0' }}
            options={states?.map((state: any) => ({
              value: state?.id,
              label: state?.state_display_name,
            }))}
            label="State of Formation"
            value={stateOfFormation || ''}
            handleChange={value => handleStateChange(value)}
            handleClear={() => handleStateChange('')}
          />
        </div> */}

        <div data-testid="state-of-formation" className="select-notification-template">
          <FormControl variant="outlined">
            <InputLabel>State of Formation</InputLabel>
            <Select
              // value={age}
              onChange={(e: any) => setStateOfFormation(e.target.value)}
              label="State of Formation"
              name="stateOfFormation"
              value={stateOfFormation ? stateOfFormation : editedNotification?.state_of_formation}
              fullWidth
            >
              {stateOptions()?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <OutlinedSelect
          // classes={{ formControl: classes.selectContainer }}
          name={'Tax Structure'}
          value={taxStructure ? taxStructure : editedNotification?.tax_structure}
          options={taxStructureSelectOptions}
          onOptionSelect={value => setTaxStructure(value as string)}
        />
        <div className={classes.checkBoxSec}>
          <FormControlLabel
            control={<Checkbox checked={notifyOnly} onChange={e => setNotifyOnly(e.target.checked)} />}
            label="Notify Only"
          />
        </div>
        <div data-testid="notification-template" className="select-notification-template">
          {/* <SelectField
            value={notificationTemplate}
            error={errors.notificationTemplate}
            errorText="Please Choose Notification Template"
            onOptionSelected={handleNotificationTemplateChange}
            fetchSuggestions={notificationTemplates}
            title={notificationTemplate ? 'Notification Template' : ''}
            placeholder="Notification Template"
            field=""
            disabled={waitingForResponse}
          /> */}

          <FormControl variant="outlined">
            <InputLabel>Notification Template</InputLabel>
            <Select
              onChange={(e: any) => setNotificationTemplate(e.target.value)}
              label="Notification Template"
              name="notificationTemplate"
              fullWidth
              value={notificationTemplate ? notificationTemplate : editedNotification?.email_template_id}
            >
              {notificationTemplateList?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div data-testid="notification-template" className="select-notification-template select-notification-margin">
          <FormControl variant="outlined">
            <InputLabel>Select First Year Schedule</InputLabel>
            <Select
              // value={age}
              onChange={(e: any) => setScheduleYear(e.target.value)}
              label="Select Year Schedule"
              name="scheduleYear"
              value={scheduleYear ? scheduleYear : editedNotification?.first_year_schedule}
              fullWidth
            >
              {scheduleList?.map(item => (
                <MenuItem key={item.value} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* <div data-testid="notification-template">
          <AsyncSelectField
            value={notificationTemplate}
            error={errors.notificationTemplate}
            errorText="Please Choose Notification Template"
            onOptionSelected={handleNotificationTemplateChange}
            defaultOptions={scheduleList}
            fetchSuggestions={scheduleList}
            title="Schedule List"
            placeholder="Schedule List"
            field=""
            disabled={waitingForResponse}
          />
        </div> */}
        {scheduleYear === 'Seleted Date' ? (
          <DatePicker
            error={errors.firstDate}
            onDateSelected={handleFirstYearScheduleChange}
            label="First Year Schedule Date"
            style={{ width: '100%' }}
            value={firstDate}
            placeholder={datePickerDatePlaceholderMMYYYY}
            // minDate={moment()}
            format={datePickerDateFormatMMYYYY}
            disabled={waitingForResponse}
          />
        ) : (
          ''
        )}

        <DatePicker
          onDateSelected={handleFollowYearScheduleChange}
          label="Following Years Schedule Date"
          style={{ width: '100%' }}
          value={followingYears}
          placeholder={datePickerDatePlaceholderMMYYYY}
          // minDate={moment()}
          format={datePickerDateFormatMMYYYY}
          disabled={waitingForResponse}
        />
        <div className={classes.checkBoxSec}>
          <FormControlLabel
            control={
              <Checkbox checked={useCompanyFileDate} onChange={() => setuseCompanyFileDate(!useCompanyFileDate)} />
            }
            label="Use company file date instead"
          />

          <button
            data-testid="customize-dates-button"
            className={classes.customizeButton}
            onClick={() => setCustomizeDialogShown(true)}
          >
            Customize
          </button>
        </div>
        <div className={classes.nextYearsContainer} style={{ opacity: waitingForResponse ? 0.5 : 'unset' }}>
          <NextYearsView nextYears={notSkippedYears} />
        </div>
        {/* {nextDates && (
          <button
            data-testid="customize-dates-button"
            disabled={waitingForResponse}
            className={classes.customizeButton}
            onClick={() => setCustomizeDialogShown(true)}
          >
            Customize
          </button>
        )} */}
        <ButtonWithLoading
          kind="BRPrimary"
          wrapperClassName={classes.scheduleButton}
          onClick={handleScheduleClick}
          isLoading={waitingForResponse}
        >
          {buttonTitle}
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default NotificationDialog

const scheduleList: ISuggestionAttribute[] = [
  {
    value: 1,
    label: 'N/A',
  },
  {
    value: 2,
    label: '30 days',
  },
  {
    value: 3,
    label: '60 days',
  },
  {
    value: 4,
    label: '90 days',
  },
  {
    value: 5,
    label: 'Seleted Date',
  },
]
