import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import AddReminder from 'components/AutoReminder/AddReminder'
import { useParams } from 'react-router'

const ReminderAddTemplate = () => {
  const classes = useStyles()
  const { id } = useParams<any>()

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.missingItems}>Auto Reminder</p>
          <p className={classes.tableTitle}>{id ? 'Update Auto Reminder' : 'Add Auto Reminder'}</p>
        </div>
        {/* <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => history.push(`/reminder-template`)}>
            Add Auto Reminder
          </ButtonWithLoading>
        </div> */}
      </div>
      {/* <SearchBar className={classes.searchBar} onChange={e => console.log(e)} placeholder="Search" />
      <TableLoadingIndicator isLoading={true} /> */}
      <AddReminder />
    </div>
  )
}

export default ReminderAddTemplate

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
