import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import * as React from 'react'
import { ITaxWidgets } from 'api/taxWidgets'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface ITaxWidgetTableRowRowProps {
  taxWidget: ITaxWidgets
  onEditClick: () => void
  onDeleteClick: () => void
}

const TaxWidgetTableRowRow: React.FC<ITaxWidgetTableRowRowProps> = ({ taxWidget, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      <StyledTableRow onClick={onEditClick} data-testid={`${taxWidget.id}`} className={classes.row}>
        <StyledTableCell>{taxWidget.id}</StyledTableCell>
        <StyledTableCell>{taxWidget.title}</StyledTableCell>
        <StyledTableCell>
          <span ref={moreOptionsRef} />
          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              onDeleteClick()
            }}
            style={{ width: '35px', height: '35px' }}
          >
            <DeleteIcon tooltipText="Delete" />
          </TableActionButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default TaxWidgetTableRowRow
