import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ISalesRep } from 'models/salesRep'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.salesRepBackground,
      borderRadius: 4,
      padding: 22,
      color: 'white',
      boxSizing: 'border-box',
    },
  })
)

interface ISalesRepresentative {
  salesRep: ISalesRep
  classes?: Record<string, string>
}

const SalesRep: React.FC<ISalesRepresentative> = props => {
  const { name, phone_number, email } = props.salesRep
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <Typography style={{ textTransform: 'uppercase', opacity: 0.6 }} variant="caption">
        Sales Representative
      </Typography>
      <Typography style={{ fontWeight: 'bold', textDecoration: 'underline' }} variant="body1">
        {name}
      </Typography>
      <Typography variant="body1">{email}</Typography>
      <Typography variant="body1">{phone_number}</Typography>
    </div>
  )
}

export default SalesRep
