import { getCompanyAddresses } from 'api/companies'
import { IInputFieldsErrors } from 'components/companies/create'
import { IAddress } from 'models/address'
import { ICreatedCompany, MeetingType } from 'models/company'
import moment from 'moment'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import { validateSsnEin } from 'utils/validators'

export interface IMeetingReportFields {
  ein?: string
  stateId?: string
  fileDate?: moment.Moment
  directorsMeetingDate: moment.Moment | string
  shareholdersMeetingDate: moment.Moment | string | null
  directorsMeetingAddress?: IAddress
  shareholdersMeetingAddress?: IAddress
  directorsMeetingId?: number
  shareholdersMeetingId?: number
  formationMeetingId?: number
  formationMeetingDate?: moment.Moment | string
}

export type MeetingIdKey = 'directorsMeetingId' | 'formationMeetingId' | 'shareholdersMeetingId'

export const MeetingTypeToMeetingIdMapping: Record<MeetingType, MeetingIdKey> = {
  directors: 'directorsMeetingId',
  shareholders: 'shareholdersMeetingId',
  formation: 'formationMeetingId',
}

const getDefaultCompanyMeetingDate = (time: string): moment.Moment => {
  const currentYear = moment().year()
  const meetingDate = `${currentYear}-01-16`
  if (moment().isBefore(meetingDate)) return moment(`${currentYear}-01-15 ${time} Z`)
  else return moment(`${currentYear + 1}-01-15 ${time} Z`)
}

const meetingReportAddressFields = ['shareholdersMeetingAddress', 'directorsMeetingAddress']

export const emptyMeetingReportState: IMeetingReportFields = {
  directorsMeetingDate: getDefaultCompanyMeetingDate('18:00').format('YYYY-MM-DD HH:mm Z'),
  shareholdersMeetingDate: getDefaultCompanyMeetingDate('19:00').format('YYYY-MM-DD HH:mm Z'),
}

const useMeetingReport = (createdCompany?: ICreatedCompany) => {
  const [meetingReportFields, setMeetingReportFields] = React.useState<IMeetingReportFields>(emptyMeetingReportState)
  const [companyAddresses, setCompanyAddresses] = React.useState<IAddress[]>([])
  const [meetingReportErrors, setMeetingReportErrors] = React.useState<IInputFieldsErrors>({})

  const setInputFieldError = (field: string, value: boolean) => {
    setMeetingReportErrors((prevState: IInputFieldsErrors) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const validateEin = () => {
    if (!meetingReportFields.ein) return true
    else if (validateSsnEin(meetingReportFields.ein)) {
      if (meetingReportErrors.ein) {
        setInputFieldError('ein', false)
      }
      return true
    } else {
      setInputFieldError('ein', true)
      return false
    }
  }

  const validateMeetingReportFields = () => {
    // for now we are just validating ein
    return validateEin()
  }

  const removeFieldsErrors = (key: keyof IMeetingReportFields, value: ValueOf<IMeetingReportFields>) => {
    if (key === 'ein' && value && meetingReportErrors) {
      setInputFieldError('ein', false)
    }
  }

  const handleMeetingReportFieldsChange = (key: keyof IMeetingReportFields, value: ValueOf<IMeetingReportFields>) => {
    removeFieldsErrors(key, value)
    setMeetingReportFields(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const getCompanyAddressesAndSetState = async () => {
    if (!createdCompany) return
    const companyAddresses = await getCompanyAddresses(createdCompany.id)
    setCompanyAddresses(companyAddresses)
  }

  return {
    meetingReportFields,
    handleMeetingReportFieldsChange,
    companyAddresses,
    getCompanyAddressesAndSetState,
    validateMeetingReportFields,
    meetingReportErrors,
    setMeetingReportFields,
    meetingReportAddressFields,
  }
}

export default useMeetingReport
