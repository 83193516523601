import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const NoteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 27 27">
      <path
        fill="#545454"
        d="M26.156 4.331c-1.293-1.125-2.7-1.181-4.106 0l-.675.619V2.812A2.785 2.785 0 0018.562 0H2.813A2.785 2.785 0 000 2.813v21.375A2.785 2.785 0 002.813 27h15.75a2.785 2.785 0 002.812-2.813V13.22l4.781-4.781c.563-.563.844-1.294.844-2.082 0-.787-.281-1.518-.844-2.025zm-5.062 2.531l.843.844-10.912 10.969c-.394-.281-.787-.45-1.125-.619L21.094 6.862zm-9.619 14.4l-3.15.9.9-3.093c.338.056.9.281 1.406.787.394.281.675.788.844 1.406zm4.5-10.912H8.213V6.3h8.662l.056 3.094-.956.956zM3.825 25.875H2.812a1.657 1.657 0 01-1.687-1.688V2.813c0-.956.731-1.687 1.688-1.687h1.012v24.75zm16.425-1.688c0 .957-.731 1.688-1.688 1.688H4.95V1.125h13.613c.956 0 1.687.731 1.687 1.688v3.262l-2.194 2.194V5.68c0-.337-.225-.562-.562-.562H7.65c-.338 0-.563.225-.563.562v5.175c0 .338.226.563.563.563h7.2l-6.412 6.525c-.057.056-.113.169-.17.225l-1.35 4.668c-.056.17 0 .394.17.563a.51.51 0 00.393.169h.169l4.669-1.35c.056 0 .112-.056.112-.056.057 0 .057-.057.057-.057l7.762-7.762v9.844zm-7.819-3.656c-.168-.45-.393-.787-.618-1.125L22.78 8.438l.844.843-11.194 11.25zM25.37 7.65l-.956.956-2.532-2.531.956-.956c.675-.675 1.857-.675 2.532 0 .675.787.675 1.631 0 2.531z"
      />
    </SvgIcon>
  )
}

export default NoteIcon
