import * as React from 'react'
import {
  Chip,
  CircularProgress,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core'
import { Done, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { taxOrderStatusColors } from 'utils/constant'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import InnerTableItem from './callLogsInnerTable'

interface ICallLogsRowProps {
  callLogsData: any
  onCompleteClick: () => void
  getAllData: () => void
  loading: boolean
}

const CallLogsRow: React.FC<ICallLogsRowProps> = ({ callLogsData, onCompleteClick, getAllData, loading }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState<boolean>(false)
  const [loaderId, setLoaderId] = React.useState<number | null>(null)

  const handleChangeStatus = React.useCallback(
    (id, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setLoaderId(id)
      event.stopPropagation()
      onCompleteClick()
    },

    [onCompleteClick]
  )
  console.log(loaderId, loaderId === callLogsData?.id, 'ALLCALLLOGSDATAAAA')
  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
        <TableCell width={150}>
          {callLogsData?.date_time?.length ? formatDateTimeToHumanFriendly(callLogsData?.date_time) : 'No Date'}
        </TableCell>
        <TableCell>
          {callLogsData?.status === 'completed' ? (
            <div className={classes.status}>
              <Chip size="medium" label="Completed" className="paid-chip" />
            </div>
          ) : callLogsData?.status === 'processing' ? (
            <div className={classes.status}>
              <Chip size="medium" label="Processing" className="processing-chip" />
            </div>
          ) : callLogsData?.status === 'pending' ? (
            <div className={classes.status}>
              <Chip size="medium" label="Pending" className="pending-chip" />
            </div>
          ) : (
            <TableCell>{callLogsData?.status ?? '-'}</TableCell>
          )}
        </TableCell>
        <TableCell>{callLogsData?.call_status}</TableCell>
        <TableCell>{callLogsData?.callee_name}</TableCell>
        <TableCell>{callLogsData?.caller_name}</TableCell>
        <TableCell>{callLogsData?.caller_number}</TableCell>
        <TableCell>{callLogsData?.duration}</TableCell>
        <TableCell>{callLogsData?.call_queue}</TableCell>

        <TableCell width="100px">
          <div className={classes.icon}>
            {callLogsData?.status !== 'completed' && (
              <>
                {}
                <div style={{ position: 'relative' }}>
                  {loading && loaderId === callLogsData?.id ? (
                    <>
                      <CircularProgress style={{ position: 'relative', left: -8 }} size={28} />
                      <IconButton className={classes.loader} onClick={e => handleChangeStatus(callLogsData?.id, e)}>
                        <Done color="primary" />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton onClick={e => handleChangeStatus(callLogsData?.id, e)}>
                      <Done color="primary" />
                    </IconButton>
                  )}
                </div>
              </>
            )}
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <InnerTableItem callLogsData={callLogsData} getAllData={() => getAllData()} />
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CallLogsRow

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      '& .MuiChip-root': {
        color: '#fff',
        margin: '0.1rem 0',
      },
      '& .paid-chip': {
        background: taxOrderStatusColors['Completed'],
      },
      '& .processing-chip': {
        background: taxOrderStatusColors['Processing'],
      },
      '& .pending-chip': {
        background: taxOrderStatusColors['Pending'],
      },
    },
    loader: {
      position: 'absolute',
      right: -2,
      bottom: -5,
    },
    icon: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    },
  })
)
