import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient, ICreatedState, IStates, IStatesProcessing } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (client): Promise<ICreatedState> => {
  delete client.id // delete the extid, the backend should take care of it
  delete client.state_display_name

  // //   client.user_type = '2'
  //   client.email = client.email ? client.email : ' '
  return (await apiClient.post('state-processing-configs', snakeCaseKeys(client))).data
}

export const updateClient = async (client: IStatesProcessing | any): Promise<ICreatedState> => {
  return (await apiClient.post(`state-processing-configs/${client.id}`, snakeCaseKeys(client))).data
}
export const fetchClient = async (id: number): Promise<ICreatedState> => {
  return (await apiClient.get(`state-processing-configs/${id}`)).data
}

export const checkCompanyTypes = async (state_id: number): Promise<any> => {
  return (await apiClient.get(`/check-available-configs/${state_id}`)).data
}
export const paginateStates = () => {
  return requestPaginated<ICreatedState[]>({ url: '/state-processing-configs' })
}
