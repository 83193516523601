import * as React from 'react'

const useNotificationErrors = () => {
  const [company, setCompany] = React.useState(false)
  const [companyType, setCompanyType] = React.useState(false)
  const [orderType, setOrderType] = React.useState(false)
  const [stateOfFormation, setStateOfFormation] = React.useState(false)
  const [notificationTemplate, setNotificationTemplate] = React.useState(false)
  const [firstDate, setFirstDate] = React.useState(false)

  const clearAll = React.useCallback(() => {
    setCompany(false)
    setCompanyType(false)
    setOrderType(false)
    setStateOfFormation(false)
    setNotificationTemplate(false)
    setFirstDate(false)
  }, [])

  return {
    company,
    setCompany,
    companyType,
    setCompanyType,
    orderType,
    setOrderType,
    stateOfFormation,
    setStateOfFormation,
    notificationTemplate,
    setNotificationTemplate,
    firstDate,
    setFirstDate,
    clearAll,
  }
}

export default useNotificationErrors
