import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'id', label: 'ID', style: {}, sortable: true },
  { id: 'first_name', label: 'Name', style: {}, sortable: true },
  { id: 'phone_1', label: 'Phone', style: {}, sortable: true },
  { id: 'email_1', label: 'E-mail', style: {}, sortable: true },
  { id: 'sales_representative_id', label: 'Sales rep.', style: {}, sortable: true },
  { id: 'credit', label: 'Credit', style: {}, sortable: true },
  { id: 'ssn_ein', label: 'SSS/EIN', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const ClientTableHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default ClientTableHead

// import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
// import * as React from 'react'

// const ClientTableHead: React.FC<any> = ({ fromCompany }) => {
//   return (
//     <StyledTableHead>
//       <StyledTableRow>
//         <StyledTableCell>Name</StyledTableCell>
//         <StyledTableCell>ID</StyledTableCell>
//         <StyledTableCell>Phone</StyledTableCell>
//         <StyledTableCell>E-mail</StyledTableCell>
//         <StyledTableCell>Sales rep.</StyledTableCell>
//         <StyledTableCell>SSS/EIN</StyledTableCell>
//         {fromCompany === false ? <StyledTableCell>Actions</StyledTableCell> : null}
//       </StyledTableRow>
//     </StyledTableHead>
//   )
// }

// export default ClientTableHead
