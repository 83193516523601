import React, { ReactNode } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button, Divider } from '@material-ui/core'

interface HeaderProps {
  title: string
  icon?: any
  iconText?: string
  handler?: React.MouseEventHandler<HTMLButtonElement> | undefined
  secondaryBtnText?: string
  secondaryIcon?: any
  secondaryHandler?: React.MouseEventHandler<HTMLButtonElement> | undefined
  disabled?: boolean
  teritaryBtnText?: string
  teritaryIcon?: any
  teritaryHandler?: React.MouseEventHandler<HTMLButtonElement> | undefined
  extraNode?: ReactNode
}

const Header = ({
  title,
  icon,
  iconText,
  handler,
  secondaryBtnText,
  secondaryIcon,
  secondaryHandler,
  disabled,
  teritaryBtnText,
  teritaryIcon,
  teritaryHandler,
  extraNode,
}: HeaderProps) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.text}>
          <h2 className={classes.heading}>{title}</h2>
        </div>
        <div className={classes.iconPart}>
          {extraNode}
          {secondaryBtnText ? (
            <Button
              variant="contained"
              className={classes.iconBtn}
              endIcon={secondaryIcon}
              onClick={secondaryHandler}
              disabled={disabled}
            >
              {secondaryBtnText}
            </Button>
          ) : (
            ''
          )}
          {teritaryBtnText ? (
            <Button
              variant="contained"
              className={classes.iconBtn}
              endIcon={teritaryIcon}
              onClick={teritaryHandler}
              // disabled={disabled}
            >
              {teritaryBtnText}
            </Button>
          ) : (
            ''
          )}

          {iconText ? (
            <Button variant="contained" className={classes.iconBtn} endIcon={icon} onClick={handler}>
              {iconText}
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
      <Divider className={classes.divider} />
    </>
  )
}

export default Header

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      margin: '51px 0 11px 0',
      alignItems: 'center',
    },
    text: {
      width: '53%',
    },
    heading: {
      margin: '0',
      color: '#202124',
      fontSize: '22px',
      fontFamily: 'Lato',
    },
    divider: {
      background: '#307abc',
      height: '2px',
      marginBottom: '21px',
    },
    button: {},
    iconBtn: {
      color: '#2c90d2',
      backgroundColor: 'transparent',
      fontSize: '14px',
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    spanNot: {
      borderBottom: ' 1px solid #2c90d2',
    },
    iconPart: {
      width: '100%',
      textAlign: 'right',
    },
    iconInner: {
      paddingLeft: '10px',
    },
  })
)
