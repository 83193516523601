import { fetchCompanyOwners, getCompanyRoles } from 'api/companies'
import { tr } from 'date-fns/locale'
import {
  ICompanyRole,
  ICreatedCompany,
  IDetailedCompanyResponse,
  legalTypesToHumanReadableFormat,
} from 'models/company'
import { ICompanyOwner, ICreateUpdateOwner } from 'models/user'
import * as React from 'react'
import { assertNonNullable } from 'utils/asserts'
import { ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { IOwnerSummary } from './../companyOwners/index'

export const emptyOwnerState: ICreateUpdateOwner = {
  number_of_shares: 0,
  role_ids: [],
  address: null,
  is_primary_contact: false,
}

export const PRIORITY_SHAREHOLDER_ROLE_TITLE = 'manager'

// returns all company role ids that can hold shares
// if the legal type is set to LLC we want to make sure that PRIORITY_SHAREHOLDER_ROLE_TITLE is picked first
// by default, if multiple, we always pick first role that can hold shares when pressing "select all"
export const getCompanyShareholderRoleIds = (
  company: IDetailedCompanyResponse | ICreatedCompany | null | undefined,
  companyRoles: ICompanyRole[]
): number[] => {
  if (legalTypesToHumanReadableFormat[company?.legal_type ?? ''] !== 'LLC') {
    return companyRoles.filter(role => role.can_hold_shares).map(role => role.id)
  }
  // If PRIORITY_SHAREHOLDER_ROLE_TITLE exist, we put it first, so when "SELECT ALL" pressed it will be checked
  const shareholderRoles = companyRoles
    .filter(role => role.can_hold_shares && role.title !== PRIORITY_SHAREHOLDER_ROLE_TITLE)
    .map(role => role.id)
  const priorityRole = companyRoles.find(role => role.title === PRIORITY_SHAREHOLDER_ROLE_TITLE)
  if (priorityRole) {
    shareholderRoles.unshift(priorityRole.id)
  }
  return shareholderRoles
}

const useCompanyOwners = ({ company }: { company: IDetailedCompanyResponse | null | undefined | ICreatedCompany }) => {
  const reducerSharesTaken = (accumulator: number, currentValues: ICompanyOwner) =>
    accumulator + currentValues.number_of_shares

  const [ownerFields, setOwnerFields] = React.useState<ICreateUpdateOwner>(emptyOwnerState)
  const [companyOwners, setCompanyOwners] = React.useState<ICompanyOwner[]>([])
  const [takenShares, setTakenShares] = React.useState<number>(companyOwners.reduce<number>(reducerSharesTaken, 0))
  const [companyRoles, setCompanyRoles] = React.useState<ICompanyRole[]>([])
  const [isNewOwnerDialogOpen, setIsNewOwnerDialogOpen] = React.useState<boolean>(false)
  const [isAddOwnerDialogOpen, setIsAddOwnerDialogOpen] = React.useState<boolean>(false)
  const [activeOwner, setActiveOwner] = React.useState<IOwnerSummary | undefined>()
  const [isEditModeInAddAddressDialog, setIsEditModeInAddAddressDialog] = React.useState<boolean>(false)
  const [ownerIdToBeDeleted, setOwnerToBeDeleted] = React.useState<number | undefined>()
  const [shouldShowDeleteConfirmationDialog, setShouldShowDeleteConfirmationDialog] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [shouldUpdateOwnersOnCompanyLevel, setUpdateOwnersOnCompanyLevel] = React.useState<boolean>(true)

  const fetchAndSetCompanyOwners = React.useCallback(
    async (companyId: number) => {
      // get owners associated to the company (should be empty at the beginning)
      try {
        const data = await fetchCompanyOwners(companyId)
        setCompanyOwners(data)
        setTakenShares(data.reduce(reducerSharesTaken, 0))
        return data
      } catch (err) {
        showApiResponseError(err, 'Failed to fetch owners')
      }
    },
    [setCompanyOwners]
  )

  const onOwnerFieldsChange = React.useCallback(
    (key: keyof ICreateUpdateOwner, value: ValueOf<ICreateUpdateOwner>) => {
      setOwnerFields(prevState => ({
        ...prevState,
        [key]: value,
      }))
    },
    [setOwnerFields]
  )

  const toggleRole = React.useCallback(
    (roleId: number) => {
      if (ownerFields.role_ids.indexOf(roleId) < 0) {
        setOwnerFields(prevState => ({
          ...prevState,
          role_ids: [...prevState.role_ids, roleId],
        }))
      } else {
        setOwnerFields(prevState => ({
          ...prevState,
          role_ids: prevState.role_ids.filter(role => role !== roleId),
        }))
      }
    },
    [ownerFields.role_ids, setOwnerFields]
  )

  // Gets all company roles that can hold shares (if multiple, only one can be chosen for a new company owner)
  const shareholderRoleIds = React.useMemo(() => {
    return getCompanyShareholderRoleIds(company, companyRoles)
  }, [companyRoles, company])

  // Gets all company roles that can not hold shares (all of these can be selected for a new company owner)
  const nonShareholderRoleIds = React.useMemo(() => {
    return companyRoles.filter(role => !role.can_hold_shares).map(role => role.id)
  }, [companyRoles])

  // This method returns `ICompanyRole[]` containing maximum one role,
  // that allows shareholding.
  const allRoleIds = React.useMemo(() => {
    return nonShareholderRoleIds.concat(shareholderRoleIds.length ? shareholderRoleIds : [])
  }, [nonShareholderRoleIds, shareholderRoleIds])

  const allRolesSelected = React.useMemo(() => {
    let allSelected = true
    // All selected, if selected ids contain every non-shareholder role id
    nonShareholderRoleIds.forEach(role => {
      if (!ownerFields.role_ids.includes(role)) allSelected = false
    })
    // All selected, if selected ids contain at least one shareholder role id
    if (shareholderRoleIds.length && !shareholderRoleIds.some(roleId => ownerFields.role_ids.includes(roleId)))
      allSelected = false
    return allSelected
  }, [nonShareholderRoleIds, ownerFields.role_ids, shareholderRoleIds])

  const selectAllRoles = React.useCallback(() => {
    setOwnerFields(prevState => ({
      ...prevState,
      role_ids: allRolesSelected ? [] : allRoleIds,
    }))
  }, [allRoleIds, allRolesSelected])

  const clearOwnerState = React.useCallback(() => {
    setOwnerFields(emptyOwnerState)
  }, [setOwnerFields])

  const fetchAndSetCompanyRoles = React.useCallback(async () => {
    assertNonNullable(company, 'cannot fetch company roles because there is no company')

    try {
      const res: ICompanyRole[] = await getCompanyRoles(company.id)
      setCompanyRoles(res)
    } catch (err) {
      console.error(err)
      showApiResponseError(err, 'Failed to fetch company roles')
    }
  }, [setCompanyRoles, company])

  const onOwnerEdit = React.useCallback(
    (userId: number) => {
      const owner: ICompanyOwner | undefined = companyOwners.find(owner => owner.user.id === userId)

      assertNonNullable(owner)
      assertNonNullable(userId)

      setOwnerFields({
        user_id: userId,
        number_of_shares: owner.number_of_shares,
        role_ids: owner.roles.map(role => role.id),
        address: owner.address,
        is_primary_contact: owner.is_primary_contact,
      })
    },
    [companyOwners, setOwnerFields]
  )

  // as the user clicks on edit button,
  // open the edit dialog and set the active edited owner to the state
  const handleEditButtonClick = React.useCallback(
    async (userId: number, userName: string) => {
      const owner: IOwnerSummary = { id: userId, name: userName }
      setActiveOwner(owner)
      setIsEditModeInAddAddressDialog(true)
      await fetchAndSetCompanyRoles()
      onOwnerEdit(userId)
      setIsAddOwnerDialogOpen(true)
    },
    [fetchAndSetCompanyRoles, onOwnerEdit]
  )

  // upon clicking on the 'X' button, set the id of the
  // owner that needs to be deleted
  // and show the dialog asking the user whether he
  // wants to delete it on the company or on the order level
  const handleOwnerDeleteClick = React.useCallback(
    (userId: number) => {
      setOwnerToBeDeleted(userId)
      setShouldShowDeleteConfirmationDialog(true)
    },
    [setOwnerToBeDeleted, setShouldShowDeleteConfirmationDialog]
  )

  const toggleIsNewOwnerDialogOpen = React.useCallback(() => {
    setIsNewOwnerDialogOpen(prevState => !prevState)
  }, [setIsNewOwnerDialogOpen])

  const getRoleById = React.useCallback(
    (roleId: number): ICompanyRole | undefined => companyRoles.find(cr => cr.id === roleId),
    [companyRoles]
  )

  const toggleUpdateLevelForCompanyOwners = React.useCallback(() => {
    setUpdateOwnersOnCompanyLevel(prevState => !prevState)
  }, [setUpdateOwnersOnCompanyLevel])

  const handleModalClose = React.useCallback(() => {
    setIsAddOwnerDialogOpen(false)
    setActiveOwner(undefined)
    setIsEditModeInAddAddressDialog(false)
    if (clearOwnerState) clearOwnerState()
  }, [setIsAddOwnerDialogOpen, setActiveOwner, setIsEditModeInAddAddressDialog, clearOwnerState])

  return {
    ownerFields,
    takenShares,
    setTakenShares,
    setOwnerFields,
    companyOwners,
    setCompanyOwners,
    companyRoles,
    setCompanyRoles,
    fetchAndSetCompanyOwners,
    clearOwnerState,
    toggleRole,
    onOwnerFieldsChange,
    isNewOwnerDialogOpen,
    setIsNewOwnerDialogOpen,
    isAddOwnerDialogOpen,
    setIsAddOwnerDialogOpen,
    activeOwner,
    setActiveOwner,
    isEditModeInAddAddressDialog,
    setIsEditModeInAddAddressDialog,
    handleEditButtonClick,
    handleOwnerDeleteClick,
    toggleIsNewOwnerDialogOpen,
    onOwnerEdit,
    fetchAndSetCompanyRoles,
    isLoading,
    setIsLoading,
    getRoleById,
    shouldUpdateOwnersOnCompanyLevel,
    setUpdateOwnersOnCompanyLevel,
    toggleUpdateLevelForCompanyOwners,
    handleModalClose,
    setOwnerToBeDeleted,
    ownerIdToBeDeleted,
    shouldShowDeleteConfirmationDialog,
    setShouldShowDeleteConfirmationDialog,
    selectAllRoles,
    allRolesSelected,
  }
}

export default useCompanyOwners
