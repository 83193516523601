import React from 'react'
import { useStyles } from './styles'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

interface OfferCardProps {
  borderColor: string
  extraTextColor?: string
  mainTextColor?: string
  extraText?: string
  mainText: string
  img: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  atLeastOneCompanyCompleted?: boolean
  completed_orders?: boolean
}

const OfferCard = ({
  borderColor,
  extraTextColor,
  mainTextColor,
  img,
  extraText,
  mainText,
  onClick,
  atLeastOneCompanyCompleted,
  completed_orders,
}: OfferCardProps) => {
  const [open, setOpen] = React.useState(false)
  const [errorMSg, setErrorMsg] = React.useState('')

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const classes = useStyles()
  const setCardStyles = () => {
    if (!atLeastOneCompanyCompleted && completed_orders) {
      onClick = () => {
        setOpen(true)
        setErrorMsg('Powerup will be available after your company is complete!')
      }
      return 'grayscale(100%)'
    } else return ''
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={errorMSg}
      >
        <div
          style={{ borderColor: borderColor, filter: setCardStyles() }}
          className={classes.offerCardContainer}
          onClick={onClick}
        >
          <img src={img} alt="icon" className="product-img" />
          <div className="inline-text">
            <span style={{ color: extraTextColor }} className="extra-text">
              {extraText}
            </span>
            &nbsp;
            <p style={{ color: mainTextColor }} className="title-text">
              {mainText}
            </p>
          </div>
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default OfferCard
