import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import ModalTitle from '../modalTitle'
import PrimaryButton from '../buttons/primaryButton'
import SecondaryButton from '../buttons/secondaryButton'
import { useConfirmationDialogStyles } from './confirmationDialogStyles'

interface IDeleteConfirmationDialog {
  onClose(): void
  open: boolean
  isLoading?: boolean
  title: string
  firstOptionTitle: string
  secondOptionTitle?: string
  onFirstOptionClick(): void
  onSecondOptionClick?: () => void
}

const DialogWithConfirmButtons: React.FC<IDeleteConfirmationDialog> = ({
  onClose,
  title,
  open,
  isLoading,
  firstOptionTitle,
  secondOptionTitle,
  onFirstOptionClick,
  onSecondOptionClick,
}) => {
  const classes = useConfirmationDialogStyles()

  return (
    <Dialog onBackdropClick={onClose} maxWidth="xl" open={open}>
      <div className={classes.container}>
        <ModalTitle classes={{ title: classes.modalTitle }} title={title} toggleModal={onClose} />
        <div
          style={{ justifyContent: secondOptionTitle ? 'space-around' : 'center' }}
          className={classes.buttonsWrapper}
        >
          {secondOptionTitle && (
            <SecondaryButton disabled={isLoading} classes={{ button: classes.button }} onClick={onSecondOptionClick}>
              {secondOptionTitle}
            </SecondaryButton>
          )}
          <PrimaryButton disabled={isLoading} classes={{ button: classes.button }} onClick={onFirstOptionClick}>
            {firstOptionTitle}
          </PrimaryButton>
        </div>
      </div>
    </Dialog>
  )
}

export default DialogWithConfirmButtons
