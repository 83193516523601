import React from 'react'
import moneyBag from '../../icons/moneyBag.svg'
import bronzeMedal from '../../icons/bronzeMedal.svg'
import silverMedal from '../../icons/silverMedal.svg'
import platinumMedal from '../../icons/platinumMedal.svg'
import goldMedal from '../../icons/goldMedal.svg'
import linkedin from '../../icons/linkedin.svg'
import facebookSquare from '../../icons/facebookSquare.svg'
import twitterSquare from '../../icons/twitterSquare.svg'
import MailIcon from '../../icons/mail-icon.png'
import LinkIcon from '../../icons/link-icon.png'
import { IUser } from 'models/user'

import { Container, TextField, Link, Grid } from '@material-ui/core'
import { useStyles } from './style'
import copyToClipboard from 'utils/copyToClipboard'

interface EarnModallProp {
  user: IUser | null
}

const EarnModal: React.FC<EarnModallProp> = ({ user }: EarnModallProp) => {
  const classes = useStyles()

  return (
    <Container maxWidth="sm">
      <div className={classes.earnContainer} style={{ paddingTop: 0 }}>
        <p className="pop_up_title">How to Earn More!</p>
        <div className="money_bag_container">
          <img src={moneyBag} alt="moneyBag" />
        </div>
        <p className="income_para">Create a long lasting residual income pipeline.</p>
        <p className="plat_form_para">As your platform grows, BusinessRocket grows with you.</p>
        <Grid container spacing={1}>
          <Grid item xs={3} sm={4} />
          <Grid item xs={2} sm={2} className="common_grid_container">
            <div>
              <img src={bronzeMedal} alt="bronzeMedal" className="medal_icon" />
              <p className="metal_category bronze_color">BRONZE</p>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <div>
              <img src={silverMedal} alt="silverMedal" className="medal_icon" />
              <p className="metal_category silver_color">SILVER</p>
            </div>
          </Grid>
          <Grid item xs={3} sm={2} className="common_grid_container">
            <div>
              <img src={goldMedal} alt="goldMedal" className="medal_icon" />
              <p className="metal_category gold_color">GOLD</p>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <div className="platinum_and_para_container">
              <img src={platinumMedal} alt="platinumMedal" className="medal_icon" />
              <p className="metal_category platinum_color">PLATINUM</p>
            </div>
          </Grid>

          <Grid item xs={3} sm={4}>
            <p className="clients_per_month">
              clients <br /> per month
            </p>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <p className="clients_range">0-5</p>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <p className="clients_range">6-10</p>
          </Grid>
          <Grid item xs={3} sm={2} className="common_grid_container">
            <p className="clients_range">11-100</p>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <p className="clients_range">101+</p>
          </Grid>

          <Grid item xs={3} sm={4}>
            <p className="clients_per_month_and_comission">
              your <br />
              commission
            </p>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <p className="comission_percentage">5%</p>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <p className="comission_percentage">10% </p>
          </Grid>
          <Grid item xs={3} sm={2} className="common_grid_container">
            <p className="comission_percentage">15%</p>
          </Grid>
          <Grid item xs={2} sm={2} className="common_grid_container">
            <p className="comission_percentage">20%</p>
          </Grid>
        </Grid>
        <p className="default_link_para">Your Personal Default Link:</p>
        <div className="text_field_and_btn_main_container">
          <div className="para_text_field_container">
            <p className="business_rocket_link">www.BusinessRocket.com/?ref=</p>
            <TextField value={user?.ref_code} variant="outlined" size="small" className="code_text_field" />
            {/* <Link target="_blank" href={`https://www.businessrocket.com/?ref=${user?.ref_code}`}  onClick={() => copyToClipboard(value)}> */}
            <div onClick={() => copyToClipboard(`https://www.businessrocket.com/?ref=${user?.ref_code}`)}>
              <img src={LinkIcon} alt="LinkSquare" />
            </div>
          </div>
          {/* <div className="edit_btn_container">
            <button className="click_edit_customize">Edit</button>
            <p className="edit_text_para">
              Click Edit to <br /> customize
            </p>
          </div> */}
        </div>
        <p className="quick_share_options">Quick Share Options</p>
        <div className="social_media_icons_container">
          <Link
            target="_blank"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&quote=I%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20`}
          >
            <img src={linkedin} alt="linkedin" />
          </Link>
          <Link
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&quote=I%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20`}
          >
            <img src={facebookSquare} alt="facebookSquare" />
          </Link>
          <Link
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=I%27ve+recently+opened+my+business+through+BusinessRocket%2C+they+are+a+one-stop+business+formation+service+company.+Highly+Recommended%21+Fast+Processing%2C+Best+Prices%2C+always+there+to+for+questions+about+your+new+Business+Tell+them+I+referred+you%21+%0A%0Ahttps%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}`}
          >
            <img src={twitterSquare} alt="twitterSquare" />
          </Link>
          <Link
            target="_blank"
            href={`mailto:?body=Hey%2C%20%0A%0AI%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20%0A%0Ahttps%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&subject=BusinessRocket%20Invitation`}
          >
            <img src={MailIcon} alt="MailSquare" />
          </Link>
          {/* <Link target="_blank" href={`https://www.businessrocket.com/?ref=${user?.ref_code}`}>
            <img src={LinkIcon} alt="LinkSquare" />
          </Link> */}
        </div>
      </div>
    </Container>
  )
}

export default EarnModal
