import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'

export const log = (event: Sentry.Event) => Sentry.captureEvent(event)

export const warn = (event: Sentry.Event) => {
  console.warn(event.message)
  log({
    ...event,
    level: Severity.Warning,
  })
}

export const error = (event: Sentry.Event) => {
  console.error(event.message)
  log({
    ...event,
    level: Severity.Error,
  })
}
