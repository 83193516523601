import React from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import { AutoReminderProps } from 'api/autoReminders'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

interface AutoReminderRowProps {
  autoReminder: AutoReminderProps
  onEditClick: () => void
  onDeleteClick: () => void
}

const AutoReminderRow: React.FC<AutoReminderRowProps> = ({ autoReminder, onEditClick, onDeleteClick }) => {
  const handleEditIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onEditClick()
    },
    [onEditClick]
  )

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )
  return (
    <TableRow onClick={onEditClick} style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ minWidth: 180 }}>{autoReminder?.name}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{formatDateTimeToHumanFriendly(autoReminder?.created_at)}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{autoReminder?.total_configs}</TableCell>

      <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleEditIconClick}>
            <EditIcon data-testid="editButton" />
          </IconButton>
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AutoReminderRow
