import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'id', label: 'Company Id', style: {}, sortable: false },
  { id: 'name', label: 'Company Name', style: {}, sortable: true },
  { id: 'company_type', label: 'Company Type', style: {}, sortable: true },
  { id: 'state_of_formation', label: 'State Of Formation', style: {}, sortable: true },
  { id: 'file_date', label: 'File Date', style: {}, sortable: true },
  // { id: 'sales_rep', label: 'Sales Rep.', style: {}, sortable: false },
  { id: 'owner_name', label: 'Ownership', style: {}, sortable: false },
  { id: 'creation_time', label: 'Creation Date', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const CompanyTableHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default CompanyTableHead

// import { StyledTableCell, StyledTableHead } from 'components/common/table'
// import * as React from 'react'

// const CompanyTableHead: React.FC = () => {
//   return (
//     <StyledTableHead>
//       <StyledTableCell style={{ paddingLeft: 5, paddingRight: 5 }}>Company ID</StyledTableCell>
//       <StyledTableCell>Company Name</StyledTableCell>
//       <StyledTableCell>Company Type</StyledTableCell>
//       <StyledTableCell style={{ paddingLeft: 0, paddingRight: 10 }}>State Of Formation</StyledTableCell>
//       <StyledTableCell>File Date</StyledTableCell>
//       <StyledTableCell>Sales Representative</StyledTableCell>
//       <StyledTableCell>Ownership</StyledTableCell>
//       <StyledTableCell></StyledTableCell>
//     </StyledTableHead>
//   )
// }

// export default CompanyTableHead
