import { makeStyles } from '@material-ui/core/styles'
import { fetchTaxOrders } from 'api/taxOrders'
import TaxOrders from 'components/taxOrders'
import * as React from 'react'
import { updatePageVisitsCount } from 'utils/user'

const useStyles = makeStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    margin: '0.5rem 0',
  },
})

interface ITaxOrdersPageProps {}

const TaxOrdersPage: React.FC<ITaxOrdersPageProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <TaxOrders apiCall={fetchTaxOrders} />
      </div>
    </div>
  )
}

export default TaxOrdersPage
