import { Grid } from '@material-ui/core'
import { getKpiDashboardNotifications, getKpiDashboardUsers } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import DetailModal from '../kpiDetail/detailmodal'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'

interface UnsubscribeCardResponse {
  company_count: number
  client_count: number
  prospect_count: number
}
// type UserIdVars = 'new_users_ids' | 'user_logged_ids' | 'user_logged_more_than_once_ids'
const UnsubscribeCard = () => {
  const classes = useStyles()

  const [tabValue, setTabValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [startData, setStartData] = useState('')
  const [filteredData, setFilteredData] = useState<UnsubscribeCardResponse>()
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async payload => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardNotifications(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }

      getKpi(payload)
    }
  }, [currentData, startData])
  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])
  return (
    <>
      <Grid className={classes.commonRow} container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <PrimaryHeading heading="Notifications" text={startData} />
            </Grid>
            <Grid item xs={10}>
              <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="Company Count"
                heading={filteredData?.company_count || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="Client Count"
                heading={filteredData?.client_count || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="Prospect Count"
                heading={filteredData?.prospect_count || 0}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default UnsubscribeCard
