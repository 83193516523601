import { Grid } from '@material-ui/core'
import { getKpiDashboard } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import EntriesCard from './entriesCard'

interface KpiDashboardResponse {
  filtered_customers: number
  filtered_customers_with_usernames: number
  filtered_entities: number
  filtered_open_orders: number
  total_customers: number
  total_customers_with_usernames: number
  total_entities: number
  total_open_orders: number
}

const MainCard = () => {
  const [tabValue, setTabValue] = useState(0)
  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<KpiDashboardResponse>()

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboard(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])

  return (
    <>
      <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <EntriesCard
            title="Total Entities"
            totalValue={filteredData?.total_entities}
            filterValue={filteredData?.filtered_entities}
            date={`${moment(currentData).format('MM/DD/YYYY')} - ${moment(startData).format('MM/DD/YYYY')}`}
            isDecrement
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={3}>
          <EntriesCard
            title="Total Open Orders"
            totalValue={filteredData?.total_open_orders}
            filterValue={filteredData?.filtered_open_orders}
            date={`${moment(currentData).format('MM/DD/YYYY')} - ${moment(startData).format('MM/DD/YYYY')}`}
            isIcrement
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={3}>
          <EntriesCard
            title="Total Customers"
            totalValue={filteredData?.total_customers}
            filterValue={filteredData?.filtered_customers}
            date={`${moment(currentData).format('MM/DD/YYYY')} - ${moment(startData).format('MM/DD/YYYY')}`}
            isDecrement
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={3}>
          <EntriesCard
            title="Total Customers
w/Usernames"
            totalValue={filteredData?.total_customers_with_usernames}
            filterValue={filteredData?.filtered_customers_with_usernames}
            date={`${moment(currentData).format('MM/DD/YYYY')} - ${moment(startData).format('MM/DD/YYYY')}`}
            isIcrement
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default MainCard
