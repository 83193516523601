import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '0 15px 50px 50px',
      flexGrow: 1,

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
      },
      '& .secondary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '24px',
        color: '#202124',
        fontWeight: 600,
        paddingTop: '1.5rem',
        paddingBottom: '1rem',
      },
    },

    table: {
      minWidth: 650,
      background: '#fff',

      '& .MuiTable-root': {
        background: '#fff',
      },

      '& .MuiTableHead-root': {
        background: '#f1f3f4',
        // borderTop: '10px solid #f47950',

        '& .MuiTableCell-root': {
          padding: '0.5rem',
          paddingLeft: '1rem',
        },

        '& .MuiTableCell-head': {
          color: '#22222299',
          fontWeight: 500,
          fontSize: '14px',
        },
      },

      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          padding: '0.5rem',
          paddingLeft: '1rem',
          fontWeight: 500,
          color: '#222222',
        },
      },
      '& .inline-cell': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '38px',
      },
    },

    formControl: {
      minWidth: '190px',
      width: '100%',

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },

      '& .select-filed': {
        background: '#eaecf0',
        padding: '0.2rem 1rem',
        paddingLeft: '0',
        color: '#22222299',
        fontWeight: 500,
        fontFamily: 'Roboto',

        '& .MuiSelect-select': {
          fontSize: '14px',
        },

        '& .MuiInputBase-root': {
          '& ::before': {
            border: 'none !important',
          },

          '& .MuiInputAdornment-root': {
            marginLeft: '-12px',
          },
        },

        '&:hover': {
          border: 'none',
        },
        '&::before': {
          border: 'none',

          '&:hover': {
            border: 'none !important',
          },
        },
      },
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },

    subContainer: {
      marginTop: '2rem',

      '& .outer-sec': {
        background: '#f6f8fb',
        padding: '1rem',
        // textAlign: 'center',

        '& .MuiGrid-item': {
          fontWeight: 500,
          fontSize: '16px',
        },
        '& .border-left': {
          borderRight: '2px solid #dfdada',
          marginRight: '1rem',
        },
      },

      '& .bell-icon': {
        color: '#fc1111',
      },

      '& .heading-divider': {
        background: '#788190',
        marginTop: '1rem',
      },

      '& .search-filed-container': {
        background: '#eaf1fb',
        borderRadius: '32px',
        '& .search-icon': {
          color: '#676A6F',
        },

        '& .MuiInputBase-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '10.5px',
          },
        },
      },
    },

    innerItems: {
      minHeight: '50px',
      width: '100%',
      marginTop: '1.25rem',

      '& .header-controls': {
        display: 'flex',
        justifyContent: 'space-between',

        '& .inProgress-btn': {
          background: '#41c576',
          color: '#fff',
          marginRight: '1rem',
        },
        '& .complete-btn': {
          background: '#307abc',
          color: '#fff',
        },
        '& .company-btn': {
          background: '#ff7171',
          color: '#fff',
          marginLeft: '1rem',
        },
        '& .link-btn': {
          color: '#788190',
          paddingRight: '1rem',
        },
      },

      '& .item-divider': {
        marginTop: '1.25rem',
      },

      '& .text-box-grid': {
        margin: '2rem 0',
        marginTop: '1rem',
        width: '100%',

        '& .text-box': {
          // background: '#f6f8fb',
          background: '#fff',
          minHeight: '140px',
          padding: '1rem',

          '& .address-box': {
            margin: '0',
            padding: '0',

            '& .MuiTypography-root': {
              color: '##979797',
              fontSize: '14px',
              fontFamily: 'Roboto',
            },

            '& .MuiPaper-root': {
              minHeight: 'auto',
              padding: 0,
              margin: 0,
              background: 'transparent',
              boxShadow: 'none',

              '& div': {
                padding: 0,
                margin: 0,
              },
            },
          },

          // '& .bottom-space': {
          //   '&:not(:last-child)': {
          //     marginBottom: '1rem',
          //   },
          // },
        },

        '& .input-box': {
          '& p': {
            margin: 0,
          },
          '& .inputs': {
            display: 'flex',
            alignItems: 'center',
            // background: '#f6f8fb',
            background: '#fff',
            padding: '0.85rem 1rem',
            marginBottom: '0.9rem',

            '& .input-dark-text': {
              color: '#020202',
              paddingRight: '2.5rem',
            },
            '& .input-light-text': {
              color: '#979797',
              overflowX: 'hidden',
            },
          },
        },

        '& .dark-text': {
          color: '#020202',
          paddingBottom: '0.5rem',
          // fontSize: '1rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          // fontSize: '1rem',
          display: 'flex',
          flexWrap: 'wrap',
        },
        '& .light-text': {
          color: '#979797',
        },

        '& .text-area': {
          resize: 'none',
          background: 'inherit',
          border: 'none',
          outline: 'none',
          width: '100%',

          '&::placeholder': {
            color: '#979797',
            fontSize: '2rem,',
          },
        },
        '& .save-btn': {
          '& .MuiButtonBase-root': {
            background: '#788190',
            color: '#fff',
            minWidth: '100px',
            height: 'auto',
          },
          background: '#788190',
          color: '#fff',
          width: '100px',
          height: 'auto',
        },
      },
    },
    dateField: {
      margin: 0,
      // marginTop: 13,
      '& > div': {
        '& > div': {
          height: 'auto',
        },
      },
    },
    dueDatePicker: {
      width: '100%',
      backgroundColor: '#eaf1fb',
      borderRadius: '32px',
      '& .MuiInputBase-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },

    // textAreaContainer: {
    //   position: 'relative',
    // },
    // input: {
    //   backgroundColor: '#f6f6f8',
    //   fontSize: '16px',
    //   display: 'block',
    //   border: '0',
    //   borderRadius: '4px',
    //   outline: 'none',
    //   padding: '8px ',
    //   fontFamily: 'Roboto-Regular',
    //   width: '100%',
    //   height: '113px',
    //   resize: 'none',
    // },
    // label: {
    //   marginBottom: '4px',
    //   fontSize: '12px',
    //   color: '#202124 ',
    // },
    // iconBtn: {
    //   color: '#2c90d2 !important',
    //   fontSize: '14px',
    //   padding: '0',
    //   position: 'absolute',
    //   top: '30px',
    //   right: '5px',
    //   '&:hover': {
    //     backgroundColor: 'transparent !important',
    //   },
    // },

    autocompleteField: {
      padding: 0,
      // backgroundColor: 'gray',
      // backgroudColor: '#eaf1fb',
      '& .MuiInputBase-root': {
        backgroundColor: '#eaf1fb',
        borderRadius: '32px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },

    tableWrapper: {
      display: 'flex',
      overflowX: 'scroll',
    },
  })
)
