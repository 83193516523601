import * as React from 'react'
import { FilterableAPICall, PagedTable, usePagedTable } from '@jetkit/react'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { addBONotes, fetchBONotes, getCurrentNote, IBONotes } from 'api/taxOrders'
import { assetTypeToDocumentHandler, DocumentHandler, UploadRequestClass } from 'models/asset'
import { ComponentToPrint } from 'utils/pdfPrintWrapper'
import { Grid } from '@material-ui/core'
import NotesCard from './notesCard'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { Skeleton } from '@material-ui/lab'
import showSuccessNotification from 'utils/showSuccessNotification'
import TextEditor from 'components/common/TextEditor'

export const AssetsUploadCallBackContext = React.createContext<UploadRequestClass | undefined>(undefined)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.tableBackgroundColor,
      paddingBottom: 300,
      alignItems: 'center',
    },
    notes: {
      padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(88)}`,
      justifyContent: 'center',
      '& .notes-div': {
        marginTop: '2rem',
      },
    },
    questionTextField: {
      margin: '6.5rem 0rem 0 2rem',
      '& .editor': {
        marginTop: '0 !important',
      },
    },
    saveButtonWrapper: {
      marginTop: '1rem',
      width: '10.7rem',
      marginLeft: '2rem',
      // alignSelf: 'center',
      // marginRight: '2rem',
    },
    heading: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 'bold',
      fontFamily: theme.typography.h1.fontFamily,
    },
  })
)

interface IBONotesProps {
  taxOrderId: number
  apiCall?: FilterableAPICall<IBONotes>
}

const BONotes: React.FC<IBONotesProps> = ({ taxOrderId, apiCall = fetchBONotes(taxOrderId) }) => {
  const documentHandler: DocumentHandler = React.useMemo(() => {
    const DocumentHandler = assetTypeToDocumentHandler['questions']
    return new DocumentHandler()
  }, [])
  const classes = useStyles()
  const [loading, setLoading] = React.useState<boolean>()
  const [note, setNotes] = React.useState<any>('')
  const [notesId, setNotesId] = React.useState<any>(0)
  const componentRef = React.createRef<any>()

  // apiCall is not included to these memo dependencies because in such case
  // whole component is rerendered infinitely.
  // Instead of that taxOrderId is added there, because apiCall function, returned by fetchTaxQuestions(taxOrderId: number),
  // will be changed only if taxOrderId changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoApiCall = React.useMemo(() => apiCall(), [taxOrderId])
  const pagedTable = usePagedTable<IBONotes>({
    apiCall: memoApiCall,
  })

  const addNotes = async () => {
    console.log(notesId, 'NOTESIDDDDDDDDDDDDDDDDDDDHEREH')
    setLoading(true)
    try {
      const payload = {
        bo_content: !notesId ? note : '',
        taxorder_id: taxOrderId,
      }
      await addBONotes(payload)
      setLoading(false)
      pagedTable.reloadData()
      setNotes('')
      showSuccessNotification('Note Added')
      setNotesId(null)
    } catch (error) {
      setLoading(false)
      console.log(error, 'ERROR')
    }
  }

  const editNote = async (Inote: IBONotes) => {
    setNotes(Inote.new_content)
  }
  const handleUpdate = e => {
    setNotes(e)
  }

  const page = pagedTable?.renderProps?.page ?? 0
  const pageSize = pagedTable?.renderProps?.pageSize ?? 25
  const getNote = async () => {
    const res = await getCurrentNote(taxOrderId)
    setNotes(res?.bo_content)
  }
  React.useEffect(() => {
    getNote()
  }, [])
  return (
    <AssetsUploadCallBackContext.Provider value={documentHandler.UploadRequest}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={8}>
            <div className={classes.questionTextField}>
              <TextEditor height={'calc(100vh - 400px)'} contentText={note} setContentText={e => handleUpdate(e)} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className={classes.saveButtonWrapper}>
                <ButtonWithLoading disabled={!note?.length} onClick={addNotes} isLoading={loading}>
                  Save
                </ButtonWithLoading>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ margin: '2rem' }}>
              <p className={classes.heading}>Versions:</p>
              <ComponentToPrint ref={componentRef}>
                {pagedTable.isLoading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <PagedTable
                    {...pagedTable.renderProps}
                    renderRow={(notes: IBONotes) => (
                      <NotesCard
                        key={notes?.id}
                        // type={type}
                        asset={notes}
                        onEditClick={() => editNote(notes)}
                        notes={notes}
                      />
                    )}
                  />
                )}
              </ComponentToPrint>
            </div>
          </Grid>
        </Grid>
      </div>
    </AssetsUploadCallBackContext.Provider>
  )
}

export default BONotes
