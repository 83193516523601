import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody, TextField } from '@material-ui/core'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo, useState } from 'react'
import { useStyles } from './styles'
import { skeletons } from 'components/common/table'
import { fetchNotificationLogsById, INotificationLogs, postNotificationType } from 'api/notificationLogs'
import LogsHead from 'components/notificationLogs/table/logsHead'
import LogsRow from 'components/notificationLogs/table/logsRow'
import { Autocomplete } from '@material-ui/lab'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showSuccessNotification from 'utils/showSuccessNotification'

interface ICompanyNotificationLogsProps {
  company_id: number
}
interface FilterType {
  label: string
  value: number
}

const CompanyNotificationLogs = ({ company_id }: ICompanyNotificationLogsProps) => {
  const classes = useStyles()
  const [notiType, setNotiType] = useState<FilterType | null>(null)
  const [isloading, setisloading] = useState(false)

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'send_time' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(() => <LogsHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />, [
    sortSettings,
    handleSortingClick,
  ])

  const memoApiCall = useMemo(() => fetchNotificationLogsById(company_id)({ sort_order: order, sort_by }), [
    company_id,
    order,
    sort_by,
  ])

  const pagedTable = usePagedTable<INotificationLogs>({
    apiCall: memoApiCall,
  })

  const handleNotificationType = async () => {
    setisloading(true)
    const payload = {
      company_id: company_id,
      remarketing_sequence: notiType?.value,
    }
    try {
      await postNotificationType(payload)
      setisloading(false)
      showSuccessNotification('Created Successfully Remarketing Sequence')
    } catch (error) {
      console.log(error)
    }
    setisloading(false)
  }

  return (
    <div className={classes.notificationSection}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className="table-text">Notification Logs</p>
        </div>
        <div className="inline-field-btn">
          <Autocomplete
            id="notiType"
            options={notificationType}
            getOptionLabel={option => option?.label}
            onChange={(event: any, newValue: FilterType | null) => {
              setNotiType(newValue)
            }}
            renderInput={params => <TextField {...params} label="Remarketing Sequence" variant="outlined" />}
          />
          <ButtonWithLoading disabled={!notiType?.value} isLoading={isloading} onClick={handleNotificationType}>
            Initiate
          </ButtonWithLoading>
        </div>
      </div>

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({
              numberOfColumns: 9,
              sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100],
              numberOfRows: 9,
            })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={9}
          emptyRowText="No Notifications Logs"
          {...pagedTable.renderProps}
          renderRow={(logs: INotificationLogs) => <LogsRow logs={logs} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default CompanyNotificationLogs

const notificationType = [
  { value: 1, label: 'Free Bank Offer' },
  { value: 2, label: 'Chase Bank Offer' },
  { value: 3, label: 'Tax Sequence' },
]
