import { markTaskAsDone, markTaskAsNotDone } from 'api/task'
import { ITask } from 'models/task'
import { TasksProgressContext } from 'pages/dashboard/tasksProgress'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import { TasksContext } from 'contexts/tasks'

function useTaskComplete() {
  const { getProgress } = React.useContext(TasksProgressContext)
  const tasksContext = React.useContext(TasksContext)

  const completeTask = React.useCallback(
    async (task: ITask) => {
      if (!task.id) return

      try {
        if (!!task.completed) {
          await markTaskAsNotDone(task.id)
        } else {
          await markTaskAsDone(task.id)
        }
        getProgress()
        tasksContext.reloadTasks()
      } catch (error) {
        showApiResponseError(error, 'Something went wrong')
      }
    },
    [tasksContext, getProgress]
  )

  return { completeTask }
}

export default useTaskComplete
