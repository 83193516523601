import { Dialog } from '@material-ui/core'
import { getCompanyDetails } from 'api/companies'
import { toggleTaskActivation } from 'api/task'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import AddTaskDialog from 'components/orders/addTaskDialog'
import { OrderAttributes } from 'components/tasks/companyOrderConfigDialog/orderAttributes/OrderAttributes'
import { useTasks } from 'components/tasks/companyOrderConfigDialog/orderAttributes/useTasks'
import { IOrderTask } from 'models/task'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { CloseDialogButton } from '../../common/buttons/closeDialogButton'
import { useCompanyOrderCreate } from '../../orders/hooks/useCompanyOrderCreate'
import { useCompanyOrderConfig } from '../../orders/hooks/useCompanyOrderEdit'
import CompanyOrderTaskActivationTable from './companyOrderTaskActivationTable'
import { useStyles } from './styles'
export interface ICreateCompanyOrderDialogProps {
  open: boolean
  /**
   * Used when closing a dialog without mutations as a consequence.
   */
  onClose: () => void
  /**
   * Used when closing the dialog with object mutations as a consequence.
   */
  onSave: () => void
  /**
   * Company that the new order will belong to. If not provided the user should pick a company on his own.
   */
  companyId?: number
  /**
   * When creating an order during the new company creation flow we may wanna alter business logic.
   */
  isCompanyCreation?: boolean

  complianceId?: number

  setIsUpdated?: any
}

/**
 * Dialog window that allows to CREATE a new company order.
 */
const CreateCompanyOrderDialog = ({
  open,
  onClose,
  onSave,
  companyId,
  isCompanyCreation,
  complianceId,
  setIsUpdated,
}: ICreateCompanyOrderDialogProps) => {
  const classes = useStyles()

  const [openAddTaskDialog, setOpenAddTaskDialog] = React.useState(false)
  const { companyOrderDetails, onCompanyOrderFieldChange, setCompanyOrderDetails } = useCompanyOrderConfig({
    useUpdate: false,
  })
  const [tasks, setTasks] = React.useState([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const { handleGettingDefaultTasksForOrderType, handleCloseAddTaskDialog } = useTasks({
    onCompanyOrderFieldChange,
    companyOrderDetails,
    setOpenAddTaskDialog,
  })

  React.useEffect(() => {
    handleGettingDefaultTasksForOrderType()
    // Adding the `handleGettingDefaultTasksForOrderType` dependency below causes infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyOrderDetails.order_type_id])

  const { handleSavingCompanyOrder, onTaskCreated } = useCompanyOrderCreate(
    companyOrderDetails,
    onCompanyOrderFieldChange,
    onSave,
    setCompanyOrderDetails,
    isCompanyCreation,
    complianceId
  )

  const handleGettingInitialCompany = React.useCallback(
    async (companyId?: number) => {
      if (companyId) {
        const company = await getCompanyDetails(companyId)

        if (company) {
          onCompanyOrderFieldChange('company', company)
        }
      }
    },
    [onCompanyOrderFieldChange]
  )

  React.useEffect(() => {
    console.log('det', companyOrderDetails)
    handleGettingInitialCompany(companyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  const newCustomTaskTemplate = React.useMemo(() => {
    // When creating a new task we want to pre-fill it with info about the company it's being created for.
    return { company: companyOrderDetails.company, company_id: companyOrderDetails.company?.id, is_activated: true }
  }, [companyOrderDetails.company])

  const handleSaveOrder = async () => {
    try {
      setLoading(true)
      await handleSavingCompanyOrder(tasks)
      setLoading(false)
      showSuccessNotification('Order Added Successfully')
      if (setIsUpdated) {
        setIsUpdated(true)
      }
    } catch (e) {
      setLoading(false)
      showApiResponseError(e, 'ErrorAddingOrder' + e)
    }
  }
  return (
    <>
      <AddTaskDialog
        companyId={companyOrderDetails?.company?.id}
        open={openAddTaskDialog}
        onClose={handleCloseAddTaskDialog}
        onTaskCreated={onTaskCreated}
        orderId={companyOrderDetails.id}
        task={(newCustomTaskTemplate as unknown) as IOrderTask}
        mode={'noapi'}
      />
      <Dialog classes={{ paper: classes.mainWrapper }} open={open}>
        <div className={classes.dataWrapper}>
          <CloseDialogButton onClose={onClose} className={classes.closeButton} />
          <OrderAttributes
            companyOrderDetails={companyOrderDetails}
            onCompanyOrderFieldChange={onCompanyOrderFieldChange}
            setOpenAddTaskDialog={setOpenAddTaskDialog}
            mode="create"
          />
          <CompanyOrderTaskActivationTable
            tasks={companyOrderDetails.tasks}
            toggleTaskActivation={toggleTaskActivation}
            onCompanyOrderFieldChange={onCompanyOrderFieldChange}
            setOpenAddTaskDialog={setOpenAddTaskDialog}
            isCompanyOrderCreate
            setTasks={setTasks}
            taskIDs={tasks}
          />
        </div>
        <ButtonWithLoading
          kind="BRSecondary"
          wrapperClassName={classes.saveButton}
          onClick={() => handleSaveOrder()}
          isLoading={loading}
        >
          Save
        </ButtonWithLoading>
      </Dialog>
    </>
  )
}

export default CreateCompanyOrderDialog
