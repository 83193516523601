import { Button, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import Logo from 'icons/logo'
import BusinessIcon from '../clientDashboard/img/company-icon.png'
import { getLoginUser } from 'api/users'
import useUser from 'hooks/useUser'
import { COOKIEE_NAMES, USERTYPES } from 'utils/constant'
import useRouter from 'use-react-router'
import showApiResponseError from 'utils/showApiResponseError'
import { useCookies } from 'react-cookie'
import { IS_DEVELOPMENT_MODE } from 'utils/environment'

const AccountLists = ({ clientList }) => {
  const classes = useStyles()
  const { history } = useRouter()
  const { updateUser } = useUser()
  const [cookies, setCookie] = useCookies([COOKIEE_NAMES.device])
  const [isLoading, setisLoading] = React.useState(false)
  // const parentUser = React.useMemo(() => JSON.parse(localStorage.getItem('parent_user') || ''), [])

  const checkingUserType = React.useCallback(
    (type: string) => {
      debugger

      switch (type) {
        case USERTYPES.admin:
          return history.push('/dashboard')
        case USERTYPES.client:
          // start
          if (history?.location?.pathname !== '/') {
            return history.push(history?.location?.pathname)
          } else {
            return history.push({ pathname: '/client-dashboard', state: { isPopup: true } })
          }
        // end
        // return history.push({ pathname: '/client-dashboard', state: { isPopup: true } })
        case USERTYPES.employee:
          return history.push('/dashboard')
        case USERTYPES.salesRep:
          return history.push('/client-dashboard')
        case USERTYPES.accountant:
          return history.push('/dashboard')
        case USERTYPES.salesAgent:
          return history.push('/web-submissions')
        default:
          break
      }
    },
    [history]
  )

  const submitHandler = async (id: string) => {
    if (!id) {
      return
    }
    setisLoading(true)

    const parentUser = JSON.parse(localStorage.getItem('parent_user') || '')
    debugger
    try {
      debugger
      const user: any = await getLoginUser(id, parentUser?.accessToken)

      if (user?.error) {
        showApiResponseError(user?.error, user?.error)
      } else {
        if (IS_DEVELOPMENT_MODE)
          localStorage.setItem(
            'auth-tokens-development',
            JSON.stringify({ accessToken: user.access_token, refreshToken: user.refresh_token })
          )
        else {
          localStorage.setItem(
            'auth-tokens-production',
            JSON.stringify({ accessToken: user.access_token, refreshToken: user.refresh_token })
          )
        }

        updateUser(user.user || null)
        checkingUserType(user?.user?.user_type)

        if (user.user?.device) {
          setCookie(COOKIEE_NAMES.device, user.user.device, { maxAge: 31536000 })
        }
      }
      localStorage.setItem('page_visits', '0')
      localStorage.setItem('user_clicks', '0')
      setisLoading(false)
    } catch (error) {
      showApiResponseError(error, 'Error: Invalid Username or Password')
    }
    setisLoading(false)
  }

  return (
    <div className={classes.container}>
      <Logo />
      <div className={classes.cardSection}>
        <Typography className={classes.title}>Choose a Profile</Typography>
        <div className={classes.formContainer}>
          {clientList?.map((item: any) => (
            <div className={classes.userCard}>
              <div className="icon-section">
                <img src={BusinessIcon} />
                <h3>{item?.name}</h3>
              </div>
              <div className="button-section">
                <Button
                  variant="contained"
                  disabled={isLoading}
                  className="sign-btn"
                  onClick={() => submitHandler(item?.user_id)}
                >
                  {isLoading ? <CircularProgress size={20} /> : 'Sign In'}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AccountLists

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    cardSection: {
      borderRadius: '0.5rem',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      padding: '2rem',
      marginTop: '2rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.1rem',
      fontFamily: 'Roboto, sans-serif',
      textAlign: 'center',
    },

    formContainer: {
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '3rem 0 5rem 0',
    },
    userCard: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '1rem 0',
      borderBottom: '1px solid #e0e0e0',

      '& .icon-section': {
        display: 'flex',
        alignItems: 'center',
        width: '70%',

        '& img': {
          width: '2rem',
          height: '2rem',
        },
        '& h3': {
          marginLeft: '1rem',
          margin: 0,
          fontSize: '1rem',
        },
      },
      '& .button-section': {
        width: '30%',
      },
      '& .sign-btn': {
        borderRadius: '2rem',
        background: '#eff4fe',
        color: '#307abc',
        boxShadow: 'none',
        fontSize: '0.8rem',
        padding: '0.25rem 1rem',
      },
      '& .MuiButton-contained:hover': {
        boxShadow: 'none',
        backgroundColor: '#d5e0f7',
      },
    },

    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      color: '#f47950',
    },
  })
)
