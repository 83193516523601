import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import CopyTextButton from 'components/common/textFields/copyTextButton'
import * as React from 'react'
import copyToClipboard from 'utils/copyToClipboard'

interface IBRTextFieldProps {
  showCopyButton?: boolean
  customCopyButtonStyles?: React.CSSProperties
  customTextToCopy?: string
  dataTestId?: string
}

// Material UI TextField extension, that allows to show 'copy' button as
// input's adornment.
const BRTextField: React.FC<TextFieldProps & IBRTextFieldProps> = props => {
  const {
    showCopyButton,
    customCopyButtonStyles,
    customTextToCopy,
    dataTestId = props.name ? `${props.name}-text-field` : undefined,
    ...textFieldProps
  } = props

  let inputProps = props.InputProps
  const reference = React.useRef<TextFieldProps>()
  const value = props.customTextToCopy || (props.value as string)

  if (showCopyButton && typeof value === 'string' && value.trim() !== '') {
    inputProps = {
      ...inputProps,
      endAdornment: (
        <CopyTextButton onClick={() => copyToClipboard(value)} customStyles={props.customCopyButtonStyles} />
      ),
    }
  }

  return (
    <TextField
      fullWidth
      {...textFieldProps}
      data-testid={dataTestId}
      InputProps={inputProps}
      InputLabelProps={{ shrink: true }}
      inputRef={reference}
    />
  )
}

export default BRTextField
