import useAddressEditErrors, { isAddressErrorKey } from 'components/clients/create/useAddressEditErrors'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import { emptyAddress } from 'components/companies/hooks/addressEditHooks'
import { IAddress } from 'models/address'
import * as React from 'react'
import { warn } from 'utils/logging'
import { ValueOf } from 'utils/objectUtils'
import { addressSchema } from 'validators/address'
import { ValidationError } from 'yup'

interface Props {
  initialAddress: IAddress | null
  onSubmit(address: IAddress): void
  onClose(): void
}

/**
 * this dialog can be used for creating or updating an address
 *
 * it is an adapter for AddAddressDialog with Formik-like interface
 */
export const CreateUpdateAddressDialog: React.FC<Props> = ({ initialAddress, onSubmit, onClose }) => {
  // if object has `id`, this object already exists in the database
  // and we are updating it.
  // otherwise, this is create mode
  const isUpdateMode = !!initialAddress?.id

  const [address, setAddress] = React.useState<IAddress>(initialAddress ?? emptyAddress)

  const addressErrors = useAddressEditErrors()

  const validate = React.useCallback(
    async (address: IAddress) => {
      try {
        addressSchema.validateSync(address, { abortEarly: false })

        return true
      } catch (err) {
        if (err.name !== 'ValidationError') {
          throw err
        }

        const validationError = err as ValidationError

        validationError.inner.forEach(keyError => {
          const key = keyError.path
          if (!key) {
            warn({ message: 'Empty path in ValidationError' })
            return
          }
          if (!isAddressErrorKey(key)) {
            warn({ message: `Unknown error key ${key}` })
            return
          }

          addressErrors.setError(key, true)
        })

        return false
      }
    },
    [addressErrors]
  )

  const handleAddressFieldsChange = React.useCallback(
    (key: keyof IAddress) => (value: ValueOf<IAddress>) => {
      setAddress(prev => ({
        ...prev,
        [key]: value,
      }))
    },
    []
  )

  const handleSubmitClick = React.useCallback(async () => {
    //

    const isValid = await validate(address)
    if (!isValid) {
      // errors are handled inside validation function
      return
    }

    onSubmit(address)
  }, [address, onSubmit, validate])

  return (
    <AddAddressDialog
      open={true}
      isEditing={isUpdateMode}
      addressFields={address}
      handleInputFieldsChange={handleAddressFieldsChange}
      onAddressAdd={handleSubmitClick}
      handleDialogClose={onClose}
      addressErrors={addressErrors}
    />
  )
}
