import {
  Checkbox,
  createStyles,
  FormControl,
  FormHelperText,
  ListItem,
  makeStyles,
  Theme,
  Avatar,
} from '@material-ui/core'
import { createSpecialOfferCategory, updateSpecialOfferCategory } from 'api/specialOffersCategory'

import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'

import SubmitFormButton from 'components/common/buttons/submitFormButton'
import BRTextField from 'components/common/textFields/BRTextField'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedSpecialOffers, ICreatedSpecialOffersCategory, ISpecialOffersCategory } from 'models/user'
import * as React from 'react'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { EditorApiKey } from 'utils/constant'
import { Editor } from '@tinymce/tinymce-react'
import ImageUpload from 'components/common/textFields/ImageUpload'
import { uploadOfferImage } from 'api/specialOffers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    imgDiv: {
      display: 'flex',
      alignItems: 'inherit',
    },
    userAvatar: {
      width: '55px',
      height: '55px',
      marginTop: '3%',
      marginLeft: '3%',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: ISpecialOffersCategory = {
  title: '',
  link_text: '',
  description: '',
  heading: '',
}

export interface IClientDetailsProps {
  editedClient?: ISpecialOffersCategory
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedSpecialOffers) => void
}

const SpecialOffersCategoryDetails: React.FC<IClientDetailsProps> = ({
  editedClient,
  onRequestSuccessful /*, initialClient*/,
}) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<ISpecialOffersCategory>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  const updateClientRequest = async (client: ISpecialOffersCategory) => {
    const editedClient = client
    const resClient = await updateSpecialOfferCategory({
      id: editedClient.id,
      title: editedClient.title || '',
      category_img: editedClient.category_img || '',
      link_text: editedClient.link_text || '',
      description: editedClient.description || '',
      heading: editedClient.heading || '',
      // country_name: editedClient.country_name,
    })
    editedClient.id && (await imgUpload(editedClient.id))
    debugger
    return resClient
  }
  const imgUpload = async (id: number) => {
    const imageInput: any = document.querySelector('#empimg')
    const file = imageInput.files[0]
    if (file !== null && file !== undefined) await uploadOfferImage(id, file, { entity: 'specialoffers_category' })
  }
  const createClientRequest = async (client: ISpecialOffersCategory) => {
    const resClient = await createSpecialOfferCategory({ ...client })
    await imgUpload(resClient.id)

    return resClient
  }

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedSpecialOffersCategory = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const changeClientField = React.useCallback(
    (field: keyof ISpecialOffersCategory, value: ValueOf<ISpecialOffersCategory>) => {
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    try {
      let clientResponse: ICreatedSpecialOffersCategory
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)
      }
      debugger
      const updatedClient = clientResponse //formMode === FormMode.UPDATE ? client : newClient
      setClient(updatedClient)
    } catch (err) {
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }, [formMode, client, clientEditErrors])

  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Title"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.title}
          onChange={event => changeClientField('title', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          label="Heading"
          className={classes.textField}
          type="text"
          name="heading"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.heading}
          onChange={event => changeClientField('heading', event.target.value)}
        />

        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Link Text"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.link_text}
          onChange={event => changeClientField('link_text', event.target.value)}
        />

        <Editor
          apiKey={EditorApiKey}
          init={{
            plugins: [
              'advlist autolink lists link charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            width: '100%',
            height: '20rem',
            valid_children: '+body[style]',
            valid_styles: { '*': '*' },
            init_instance_callback: function(editor) {
              editor.on('click', function(e) {
                if (e.target.nodeName === 'INPUT') {
                  if (e.target.getAttribute('checked')) {
                    e.target.removeAttribute('checked')
                  } else {
                    e.target.setAttribute('checked', 'true')
                  }
                }
              })
            },
          }}
          onEditorChange={e => changeClientField('description', e)}
          initialValue={''}
          value={client.description}
        />
        {client.category_img !== '' ? (
          <div className={classes.imgDiv}>
            <ImageUpload
              // We show copy button in these fields only if we're looking at existing client, not creating a new one.
              showCopyButton={!!editedClient}
              required
              data-testid="client-name"
              error={clientEditErrors.meetinglink}
              ///helperText={clientEditErrors. && 'Please Specify image'}
              label="Feature image"
              style={{
                marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined,
                width: client.category_img !== '' ? '100%' : '100%',
              }}
              className={classes.textField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorHelperText,
                },
              }}
              type="file"
              name="image"
              margin="normal"
              variant="outlined"
              id="empimg"
              // value={client.image}
              // onChange={event => changeClientField('image', event.target.value)}
              // disabled
            />

            {client.category_img !== '' ? (
              //  <img style={{ paddingTop: '3.5%' }} src={client.category_img} height="14%" width="12%" />
              <div style={{ width: '85px' }}>
                <Avatar alt="avatar" src={client.category_img} className={classes.userAvatar} />
              </div>
            ) : null}
          </div>
        ) : (
          <ImageUpload
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            showCopyButton={!!editedClient}
            required
            data-testid="client-name"
            error={clientEditErrors.meetinglink}
            ///helperText={clientEditErrors. && 'Please Specify image'}
            label="Feature image"
            style={{ marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined, width: '100%' }}
            className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="file"
            name="image"
            margin="normal"
            variant="outlined"
            id="empimg"
            // value={client.image}
            // onChange={event => changeClientField('image', event.target.value)}
            // disabled
          />
        )}
        <SubmitFormButton
          onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add Category'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default SpecialOffersCategoryDetails
