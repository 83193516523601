import { Button, Divider, Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import { getProspectById, ProspectData, updateProspectAddressById } from 'api/leadManager'
import Addresses from 'components/common/addresses'
import React, { useState, useEffect } from 'react'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import showErrorNotification from 'utils/showErrorNotification'
import { useStyles } from './styles'
import useRouter from 'hooks/router'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import { IAddress } from 'models/address'
import { emptyAddress } from '../addProspectModal'
import showSuccessNotification from 'utils/showSuccessNotification'

interface InnerTableItemProps {
  inProgressBtn?: boolean
  taskDetails?: ProspectData
}
export interface NotesData {
  id?: number
  note_text?: string
  emp_id?: number
  prospect_id?: number
  created_by?: string
  created_on?: string
}

const InnerTableItem = ({ inProgressBtn, taskDetails }: InnerTableItemProps) => {
  const classes = useStyles()
  const { history } = useRouter()
  const [isLoading, setisLoading] = useState(false)
  const [prospectData, setProspectData] = useState<ProspectData>()

  const getNotes = async (id: number | undefined) => {
    setisLoading(true)
    try {
      if (id) {
        const res = await getProspectById(id)
        setProspectData(res)
        setisLoading(false)
      }
    } catch (error) {
      showErrorNotification(error)
    }
    setisLoading(false)
  }

  useEffect(() => {
    if (taskDetails?.id) {
      getNotes(taskDetails?.id)
    }
  }, [taskDetails?.id])

  const addressEditErrors = useAddressEditErrors()
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [addressesToDelete, setAddressesToDelete] = React.useState<number[]>([])

  const [prospectAddress, setProspectAddress] = React.useState<IAddress[]>([])

  const { country, city, street, postal_code, locality } = addressEditErrors

  useEffect(() => {
    if (prospectData) {
      setProspectAddress(prospectData?.addressData ? [prospectData?.addressData] : [])
    }
  }, [prospectData, prospectData?.addressData])

  const toggleAddAddressDialog = React.useCallback(() => {
    setCurrentEditedAddress(emptyAddress)
    setShowAddAddressDialog(!showAddAddressDialog)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )

  const updateAddressbyId = React.useCallback(
    async (addressesWithUpdatedAddress: any) => {
      const addressId = prospectData?.addressData?.id || 0
      try {
        await updateProspectAddressById(addressId, addressesWithUpdatedAddress[0])
        showSuccessNotification('Address Successfully Update.')
      } catch (error) {
        console.log(error)
      }
    },
    [prospectData?.addressData?.id]
  )

  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    const indexToUpdate = prospectAddress && prospectAddress.findIndex(address => address.id === editedAddressId)

    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(prospectAddress || [])]
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setProspectAddress(addressesWithUpdatedAddress)
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    setCurrentEditedAddress(emptyAddress)
    setShowAddAddressDialog(false)
    updateAddressbyId(addressesWithUpdatedAddress)
  }, [editedAddressId, prospectAddress, currentEditedAddress, addressesToEdit, updateAddressbyId])

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((prospectAddress && prospectAddress.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [prospectAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setProspectAddress(prospectAddress.filter(address => address.id !== id))
      if (id > 0) setAddressesToDelete([...addressesToDelete, id])
    },
    [addressesToDelete, prospectAddress]
  )

  const handleAddAddress = React.useCallback(() => {
    //

    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      const newAddresses: IAddress[] = []

      newAddresses.push(currentEditedAddress)
      setProspectAddress(newAddresses)
      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd])
      toggleAddAddressDialog()
    }
    return true
  }, [addressesToAdd, currentEditedAddress, editedAddressId, toggleAddAddressDialog, updateAddress])

  return (
    <div className={classes.innerItems}>
      <Divider className="item-divider" />
      <Grid container spacing={3} className="text-box-grid">
        <Grid item xs={4}>
          <div className="input-box">
            <div className="inputs">
              <p className="input-dark-text">Phone </p>
              <p className="input-light-text">
                {isLoading ? <Skeleton animation="wave" variant="text" width="200px" /> : prospectData?.phone}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Email </p>
              <p className="input-light-text">
                {isLoading ? <Skeleton animation="wave" variant="text" width="200px" /> : prospectData?.email}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">POC </p>
              <p className="input-light-text">
                {isLoading ? <Skeleton animation="wave" variant="text" width="200px" /> : prospectData?.poc_name}
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="text-box">
            {isLoading ? (
              <Skeleton animation="wave" variant="text" width="325px" height="175px" />
            ) : (
              <Addresses
                label="Prospect Address Info"
                customClass
                customClassName="address-box"
                onlyOneAddress={true}
                addresses={prospectAddress}
                onAddAddressClick={handleAddAddressClick}
                onAddressEditClick={handleAddressEditClick}
                onAddressDeleteClick={handleAddressDeleteClick}
                isDeletable={false}
                isEditable={false}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="text-box">
            {isLoading ? (
              [1, 2, 3, 4, 5, 6, 7].map(key => <Skeleton animation="wave" variant="text" key={key} />)
            ) : prospectData?.notes?.length ? (
              prospectData?.notes?.slice(0, 3)?.map((note: NotesData) => (
                <div key={note?.id}>
                  <p className="light-text">
                    {note?.created_by} {formatDateTimeToHumanFriendly(note?.created_on || '')}
                  </p>
                  <p className="dark-text">{note?.note_text}</p>
                </div>
              ))
            ) : (
              <p className="dark-text">No Notes Found</p>
            )}
          </div>
        </Grid>
        {/* <Grid item xs={3}>
          <Button
            color="primary"
            endIcon={<Edit />}
            onClick={() => history.push(`/prospect/${prospectData?.id}`)}
            disabled={!prospectData?.id}
          >
            Edit
          </Button>
        </Grid> */}
      </Grid>
      <AddAddressDialog
        open={showAddAddressDialog}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressFieldsChange}
        handleDialogClose={toggleAddAddressDialog}
        onAddressAdd={handleAddAddress}
        addressErrors={{
          country,
          city,
          locality,
          street,
          postal_code,
        }}
        // onPrimaryAddressChecked={handlePrimaryAddressChecked}
        isEditing={editedAddressId !== undefined}
        showPrimaryAddressCheckbox
      />
    </div>
  )
}

InnerTableItem.defaultProps = {
  inProgressBtn: false,
}

export default InnerTableItem
