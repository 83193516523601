import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'

import { changeScheduleStatus, ReminderScheduleProps } from 'api/orderItem'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import moment from 'moment'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { REMINDER_SCHEDULES_STATUS } from 'utils/constant'
import PrimaryButton from 'components/common/buttons/primaryButton'

interface ReminderScheduleRowProps {
  reminderItem: ReminderScheduleProps
  changeStatus: (id: number) => void
  loading: boolean
}

const ReminderScheduleRow: React.FC<ReminderScheduleRowProps> = ({ reminderItem, changeStatus, loading }) => {
  return (
    <TableRow data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ minWidth: 160 }}>
        {moment(reminderItem?.send_time).format('DD MMMM YYYY, h:mm a')}
        <br />
        {'(Pacific Standard Time)'}
      </TableCell>
      <TableCell style={{ maxWidth: 120 }}>{reminderItem?.type}</TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <div dangerouslySetInnerHTML={{ __html: reminderItem?.content }} />
      </TableCell>
      <TableCell style={{ maxWidth: 120 }}>{reminderItem?.status}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>
        {reminderItem?.updated_at && reminderItem?.updated_by
          ? `Status Changed to Not Sent By ${reminderItem?.updated_by} on ${moment(reminderItem?.updated_at).format(
              'DD MMMM YYYY'
            )}`
          : ''}
      </TableCell>
      <TableCell style={{ maxWidth: 120 }}>
        {reminderItem.status === REMINDER_SCHEDULES_STATUS.Scheduled && (
          <PrimaryButton style={{ background: '#d9534f' }} onClick={() => changeStatus(reminderItem.id)}>
            Do Not Send
          </PrimaryButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export default ReminderScheduleRow
