import { usePagedTable } from '@jetkit/react'
import { CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { fetchSpecialOfferCategory } from 'api/specialOffersCategory'
import ClientDetails from 'components/specialOffersCategory/create/specialOfferDetails'
import { ISpecialOffersCategory } from 'models/user'
import * as React from 'react'
import useRouter from 'use-react-router'
import showApiResponseError from 'utils/showApiResponseError'
import { updatePageVisitsCount } from 'utils/user'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      ...theme.title,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    notesContainer: {
      width: '35%',
    },
    flex: {
      display: 'flex',
    },
  })
)

const EditSpecialOfferCategory = () => {
  const router = useRouter<{ id: string | undefined }>()
  const [client, setClient] = React.useState<ISpecialOffersCategory>()
  const classes = useStyles()

  const fetchClientAndSetState = React.useCallback(
    async (id: number) => {
      try {
        const client = await fetchSpecialOfferCategory(id)
        setClient(client)
      } catch (error) {
        showApiResponseError(error, 'Could not load client details')
      }
    },
    [setClient]
  )

  React.useEffect(() => {
    // Scroll to top of the page because if we begin editing client
    // in the middle or bottom of clients table, first thing we see is the bottom
    // of the `client/:id` page
    window.scrollTo(0, 0)
    if (!router.match.params.id) {
      router.history.push('/')
      return
    }
    const id: number | undefined = parseInt(router.match.params.id)
    if (isNaN(id)) {
      router.history.push('/')
      return
    }
    fetchClientAndSetState(id)
  }, [fetchClientAndSetState, router.history, router.match.params.id])

  const onRequestSuccessful = React.useCallback(() => router.history.push('/special-offers-category'), [router.history])

  return (
    <div className={classes.flex}>
      <div className={classes.container}>
        {client ? (
          <React.Fragment>
            <div className={classes.titleButtonContainer}>
              <Typography className={classes.title}>Edit Category (ID: {client.id || '- - -'})</Typography>
            </div>
            <ClientDetails editedClient={client} onRequestSuccessful={onRequestSuccessful} />
          </React.Fragment>
        ) : (
          <CircularProgress style={{ alignSelf: 'center' }} />
        )}
      </div>
    </div>
  )
}

export default EditSpecialOfferCategory
