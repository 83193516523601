import { Divider, Grid } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { DeadlineListProps, getUpcommingDeadline } from 'api/complianceCalendar'
import React, { useState, useEffect, useCallback } from 'react'
import showErrorNotification from 'utils/showErrorNotification'
import { useStyles } from './styles'
import Alert from '@material-ui/lab/Alert'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import moment from 'moment'

const ComplianceCalendar = () => {
  const classes = useStyles()

  const [deadlineList, setDeadlineList] = useState<DeadlineListProps[]>([])

  const getUpcomingDeadlines = useCallback(async () => {
    try {
      const deadlines = await getUpcommingDeadline()
      if (deadlines?.length > 0) setDeadlineList(deadlines)
    } catch (e) {
      showErrorNotification(e)
    }
  }, [])

  useEffect(() => {
    getUpcomingDeadlines()
  }, [])

  return (
    <div className={classes.subContainer}>
      <h1 className="primary-heading">Compliance Calendar</h1>
      <Divider className="heading-divider" />
      <p className="secondary-heading">Upcoming Deadlines</p>

      {deadlineList?.length > 0 ? (
        deadlineList.map((deadline, key) => (
          <Grid container spacing={3} key={key}>
            <Grid item xs={1} alignItems="center" justify="center">
              <div className="outer-sec" style={{ padding: '0.75rem 0', textAlign: 'center' }}>
                <NotificationsIcon className="bell-icon" />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Grid container className="outer-sec">
                <Grid item xs={2} className="border-left">
                  {/* {formatDateToHumanFriendly(deadline?.due_date)} */}
                  {moment.utc(deadline?.due_date).format('DD MMMM YYYY')}
                </Grid>
                <Grid item xs={4} className="border-left">
                  Task: {deadline?.task_name?.toLocaleUpperCase()}
                </Grid>
                <Grid item xs={2} className="border-left">
                  Total: {deadline?.total}
                </Grid>
                <Grid item xs={2}>
                  Done: {deadline?.completed_count}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Alert severity="info">No Upcoming Deadlines</Alert>
      )}
    </div>
  )
}

export default ComplianceCalendar
