import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ModalTitle from 'components/common/modalTitle'
import * as React from 'react'
import {
  IBOQues,
  addBOQuestion,
  // downloadBOQuestionURL,
  downloadBOQuestionURL,
  editBOQuestion,
  getRejectedReason,
  reviewQues,
  sendAnswer,
} from 'api/taxOrders'
import { Avatar, Grid, Link, Theme } from '@material-ui/core'
import FeedbackModal from './feedbackModal'
import showSuccessNotification from 'utils/showSuccessNotification'
import showErrorNotification from 'utils/showErrorNotification'
import SelectField from 'components/common/select'
import { fetchSuggestions } from 'api/taxOrders'
import AssetUpload from 'components/common/assetUpload'
import { AssetsUploadCallBackContext } from '..'
import { AssetsUploadCallBackContextAnswer } from '..'
import { UploadFileToS3Args } from 'models/asset'
import { Skeleton } from '@material-ui/lab'
import DownloadButton from 'components/common/assetUpload/downloadButton'
import { GetAppOutlined } from '@material-ui/icons'
import { User_Type_TaxOrder_Ans } from 'utils/constant'

// import AssetUpload from 'components/common/assetUpload'

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingTop: '1.5rem',
      paddingBottom: '1rem',
    },
    questionTextField: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '1.5rem',
    },
    preDefined: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '1.5rem',
    },
    answerTextField: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '0.6rem',
    },
    addButtton: {
      display: 'flex',
      justifyContent: 'end',
    },
    saveButtonWrapper: {
      marginTop: '1rem',
      width: '10.7rem',
      marginLeft: '2rem',
      marginRight: '2rem',
    },
    feedbackBtn: {
      marginTop: '1rem',
      width: '80%',
      // padding: '0 1rem',
    },

    answerQuestionCheckbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
    },
    imageFile: {
      display: 'flex',
      alignItems: 'center',
      width: '75%',
      '& p': {
        paddingLeft: '0.5rem',
        margin: 0,
        fontSize: 12,
      },
    },
    uploadQuestion: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem',
    },
    imageList: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: '0.5rem',
      },
    },
    rounded: {
      color: '#fff',
      backgroundColor: '#ff8b8b',
      fontSize: 14,
    },
    downloadButton: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    downloadButtonLink: {
      textDecoration: 'none',
    },
    attachmentList: {
      padding: '2rem',
      paddingTop: 0,
    },
    modalTitle: {
      '& .MuiTypography-root': {
        marginLeft: '2rem !important',
        textAlign: 'start !important',
      },
    },
    addQuestionButton: {
      color: theme.palette.primary.main,
      margin: '1.5rem 2rem 0 2rem',
      textAlign: 'end',
    },
  }
})

interface IAddQuestionDialog {
  taxOrderId: number
  open: boolean
  setOpen: (open: boolean) => void
  refreshQuestions: () => void
  question?: IBOQues
  setSelectedQues: any
}

// export type QuestionDialogType = 'add' | 'edit'

const QuestionDialog: React.FC<IAddQuestionDialog> = ({
  taxOrderId,
  open,
  setOpen,
  setSelectedQues,
  refreshQuestions,
  question,
}) => {
  const UploadRequest = React.useContext(AssetsUploadCallBackContext)
  const UploadRequestAnswer = React.useContext(AssetsUploadCallBackContextAnswer)
  const classes = useStyles()
  const [model, setModel] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [nestedQuestions, setNestedQuestions] = React.useState<any[]>([])
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [acceptLoader, setAcceptLoader] = React.useState<boolean>(false)
  const [rejectLoader, setRejectLoader] = React.useState<boolean>(false)
  const [questionId, setQuestionId] = React.useState<number | null>()
  const [attachments, setAttachments] = React.useState<any>()
  const [questionAttachments, setQuestionAttachments] = React.useState<any>()
  const [questionLoading, setQuestionLoading] = React.useState<boolean>(false)

  const addQues = async () => {
    setLoading(true)
    try {
      if (question?.id && model.answer) {
        const payload = {
          answer: model.answer,
        }
        await sendAnswer(question?.id, payload)
      } else if (nestedQuestions?.length) {
        const payload = nestedQuestions
        await addBOQuestion(payload)
      } else if (question?.id && !question?.answered.length && !question?.question_id) {
        // await
        const payload = {
          question_text: model?.question,
        }
        await editBOQuestion(Number(question?.id), payload)
      } else {
        const payload = [
          {
            question_id: questionId ?? null,
            question_text: model.question,
            taxorder_id: taxOrderId,
            answered: model?.answer?.length ? model.answer : '',
            parent_question_id: null,
          },
        ]
        await addBOQuestion(payload)
      }
      setQuestionId(null)
      setModel([])
      setLoading(false)
      setNestedQuestions([])
      setOpen(false)
      refreshQuestions()
    } catch (error) {
      setLoading(false)
    }
  }

  const addNestedQues = () => {
    setNestedQuestions([
      ...nestedQuestions,
      {
        question_text: '',
        question_id: null,
        taxorder_id: taxOrderId,
        parent_question_id: question?.id,
        answered: '',
      },
    ])
  }

  const handleNestedQuestionChange = (index: number, value: string) => {
    const updatedNestedQuestions = nestedQuestions.map((nestedQues, i) =>
      i === index ? { ...nestedQues, question_text: value } : nestedQues
    )
    setNestedQuestions(updatedNestedQuestions)
  }
  const handleFeedbackDone = async (feedback: string) => {
    if (feedback.length) {
      setRejectLoader(true)
      try {
        const payload = {
          id: question?.id,
          status: '4',
          feedback: feedback,
        }
        await reviewQues(payload)
        showSuccessNotification('Feedback Successfully Sent')
        setRejectLoader(false)
        setOpen(false)
        refreshQuestions()
      } catch (error) {
        showErrorNotification(error)
        console.log(error)
        setRejectLoader(false)
      }
    }
  }
  const handlereviewQues = async (review: string) => {
    if (review === 'accept') {
      setAcceptLoader(true)
      try {
        const payload = {
          id: question?.id,
          status: '3',
          feedback: 'approved',
        }
        await reviewQues(payload)
        showSuccessNotification('Answer Accepted Successfully')
        setAcceptLoader(false)
        setOpen(false)
        refreshQuestions()
      } catch (error) {
        showErrorNotification(error)
        console.log(error)
        setAcceptLoader(false)
      }
    } else {
      setIsOpen(true)
    }
  }
  const handleClose = () => {
    // setSelectedQues(null)
    setNestedQuestions([])
    setModel([])
    setOpen(false)
    refreshQuestions()
    setAttachments([])
    setQuestionAttachments([])
  }
  const addAttribute = React.useCallback((field: 'question', id: number | null, label: string | null) => {
    if (field === 'question') {
      if (id) {
        // setCompanyId(id)
        // setFeedback(label)
        // model.question=labe
        setQuestionId(id)
        setModel({ ...model, question: label })
        console.log('label is hereeeee')
      }
    }
  }, [])

  const getPredefinedFeedback = async () => {
    try {
      const result = await getRejectedReason()
    } catch (error) {
      console.log(error)
    }
  }

  const [isLoading, setIsLoading] = React.useState(false)

  const getAnswersAttachments = async id => {
    setIsLoading(true)
    try {
      const res: any = await downloadBOQuestionURL(taxOrderId, id, false)
      setAttachments(res)
      setIsLoading(false)
      // console.log(res)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
    // setIsLoading(false)
  }

  const getQuestionsAttachments = async id => {
    setQuestionLoading(true)
    try {
      const res: any = await downloadBOQuestionURL(taxOrderId, id, true)
      setQuestionAttachments(res)
      setQuestionLoading(false)
      // console.log(res)
    } catch (error) {
      setQuestionLoading(false)
      console.log(error)
    }
    // setIsLoading(false)
  }

  const getFileExtension = filename => {
    const extension = filename.split('.').pop()
    return extension
  }

  const attachmentUploadComplete = (args: UploadFileToS3Args) => {
    if (args?.objectId) {
      getAnswersAttachments(args?.objectId)
      showSuccessNotification('File Upload Successfully')
      setAttachments([])
    }
  }

  const attachmentQuestionUploadComplete = (args: UploadFileToS3Args) => {
    if (args?.objectId) {
      getQuestionsAttachments(args?.objectId)
      showSuccessNotification('File Upload Successfully')
      setQuestionAttachments([])
    }
  }

  React.useEffect(() => {
    getPredefinedFeedback()
  }, [])

  React.useEffect(() => {
    if (question?.id && !question?.child_question?.length) {
      getAnswersAttachments(question?.id)
    }
  }, [question])

  React.useEffect(() => {
    if (question?.id) {
      getQuestionsAttachments(question?.id)
    }
  }, [question])
  return (
    <Dialog
      PaperProps={{ className: classes.container }}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
    >
      <div className={classes.modalTitle}>
        <ModalTitle onClose={handleClose} title="Add A Question" />
      </div>
      {!question ? (
        <div className={classes.preDefined}>
          <SelectField
            onOptionSelected={option =>
              addAttribute('question', option ? option.value : null, option ? option.label : null)
            }
            placeholder="Select Question"
            title="question"
            fetchSuggestions={fetchSuggestions}
            field="question"
          />
        </div>
      ) : (
        ''
      )}

      <TextField
        disabled={loading || question?.answered?.length ? true : false || question?.question_id ? true : false}
        // label="Question"
        placeholder="Write Question"
        className={classes.questionTextField}
        type="text"
        name="title"
        variant="outlined"
        // multiline
        rows={3}
        onChange={e => {
          // setQuestionId(null)
          setModel({ ...model, question: e.target.value })
        }}
        value={model.question}
        defaultValue={question && question.question_text}
      />
      <Grid container justify="flex-end">
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                paddingRight: '30px',
                alignItems: 'center',
              }}
            >
              <div>
                {question?.id && question?.status !== '3' && (
                  <AssetUpload
                    type="bo_question"
                    objectId={question?.id || 0}
                    UploadRequest={UploadRequest}
                    setLoading={questionLoading}
                    onUploadComplete={attachmentQuestionUploadComplete}
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.attachmentList}>
        {questionLoading ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          questionAttachments &&
          questionAttachments?.map((item: any, index: number) => (
            <div className={classes.imageList} key={index}>
              <div className={classes.imageFile}>
                <Avatar variant="rounded" className={classes.rounded} src={item?.url}>
                  {/* <PictureAsPdf /> */}
                  {getFileExtension(item?.filename)}
                </Avatar>
                <p>{item?.filename}</p>
              </div>

              <a href={item?.url} className={classes.downloadButtonLink} target="_blank" rel="noopener noreferrer">
                <DownloadButton className={classes.downloadButton}>
                  <GetAppOutlined color="primary" />
                </DownloadButton>
              </a>
            </div>
          ))
        )}
      </div>
      {question && !question?.child_question?.length ? (
        <TextField
          disabled={loading || question?.status === '3'}
          label="Answer"
          className={classes.answerTextField}
          type="text"
          name="title"
          variant="outlined"
          multiline
          rows={3}
          onChange={e => setModel({ ...model, answer: e.target.value })}
          value={model.answer}
          defaultValue={question && question.answered}
        />
      ) : (
        ''
      )}

      {nestedQuestions.map((nestedQues, index) => (
        <TextField
          key={index}
          disabled={loading}
          label={`Nested Question ${index + 1}`}
          className={classes.questionTextField}
          type="text"
          name={`nested-question-${index}`}
          variant="outlined"
          rows={3}
          onChange={e => handleNestedQuestionChange(index, e.target.value)}
          value={nestedQues.question}
        />
      ))}
      {question && question?.answered?.length && question.answered_by ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '35px',
            paddingTop: '5px',
            fontSize: '14px',
          }}
        >
          <span>Answered by : {question ? User_Type_TaxOrder_Ans[question.answered_by] : ''}</span>
        </div>
      ) : (
        ''
      )}

      {/* {question && question?.answered && (
        <Link onClick={addNestedQues} component="button" variant="body2" className={classes.addQuestionButton}>
          + Add Question
        </Link>
      )} */}
      {/* {question && question?.answered && ( */}
      <Grid container justify="flex-end">
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                paddingRight: '30px',
                alignItems: 'center',
              }}
            >
              {/* <div>
                <p style={{ margin: '0' }}>Answer Attachments : </p>
              </div> */}
              <div>
                {question?.id && question?.status !== '3' && !question?.child_question?.length && (
                  <AssetUpload
                    type="bo_answer"
                    objectId={question?.id || 0}
                    UploadRequest={UploadRequestAnswer}
                    setLoading={setIsLoading}
                    onUploadComplete={attachmentUploadComplete}
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* )} */}

      <div className={classes.attachmentList}>
        {isLoading && !question?.child_question?.length ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          attachments &&
          attachments?.map((item: any, index: number) => (
            <div className={classes.imageList} key={index}>
              <div className={classes.imageFile}>
                <Avatar variant="rounded" className={classes.rounded} src={item?.url}>
                  {/* <PictureAsPdf /> */}
                  {getFileExtension(item?.filename)}
                </Avatar>
                <p>{item?.filename}</p>
              </div>

              <a href={item?.url} className={classes.downloadButtonLink} target="_blank" rel="noopener noreferrer">
                <DownloadButton className={classes.downloadButton}>
                  <GetAppOutlined color="primary" />
                </DownloadButton>
              </a>
            </div>
          ))
        )}
      </div>
      {question?.id && question?.status !== '3' && (
        <Link onClick={addNestedQues} component="button" variant="body2" className={classes.addQuestionButton}>
          + Add Question
        </Link>
      )}
      <Grid container>
        <Grid item xs={6}>
          {(question?.status === '1' || question?.status === '2') && question?.answered ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div style={{ marginLeft: '2rem' }} className={classes.feedbackBtn}>
                  <ButtonWithLoading
                    // disabled={!model?.answerer?.length}
                    onClick={() => handlereviewQues('accept')}
                    isLoading={acceptLoader}
                  >
                    Accept
                  </ButtonWithLoading>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.feedbackBtn}>
                  <ButtonWithLoading
                    //  disabled={!model?.answerer?.length}
                    onClick={() => handlereviewQues('reject')}
                    isLoading={rejectLoader}
                  >
                    Reject
                  </ButtonWithLoading>
                </div>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        <Grid item xs={6}>
          <div className={classes.addButtton}>
            <div className={classes.saveButtonWrapper}>
              <ButtonWithLoading
                disabled={
                  !(model?.question?.length ||
                  model?.answer?.length ||
                  nestedQuestions?.length ||
                  question?.status === '3'
                    ? true
                    : false)
                }
                // disabled={question?.status === '3' ? true : false}
                onClick={addQues}
                isLoading={loading}
              >
                {question?.id ? 'Save' : 'Add'}
              </ButtonWithLoading>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <a href={item?.url} className={classes.downloadButtonLink} target="_blank" rel="noopener noreferrer">
        <DownloadButton className={classes.downloadButton}>
          <GetAppOutlined color="primary" />
        </DownloadButton>
      </a> */}
      {/* <AssetUpload
        type="questions"
        // type="abc"
        objectId={0}
        // UploadRequest={UploadRequest}
        // setLoading={true}
        // onUploadComplete={attachmentUploadComplete}
      /> */}

      <FeedbackModal onDone={handleFeedbackDone} open={isOpen} setOpen={() => setIsOpen(false)} />
    </Dialog>
  )
}

export default QuestionDialog
