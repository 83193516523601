import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { INameCheckList, ICreatedNameCheckList } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'

export const createClient = async (client): Promise<ICreatedNameCheckList> => {
  delete client.id // delete the extid, the backend should take care of it

  return (await apiClient.post('namechecklists', snakeCaseKeys(client))).data
}

export const updateClient = async (client: INameCheckList | any): Promise<ICreatedNameCheckList> => {
  return (await apiClient.post(`namechecklists/${client.id}`, snakeCaseKeys(client))).data
}
export const fetchClient = async (id: number): Promise<ICreatedNameCheckList> => {
  return (await apiClient.get(`namechecklists/${id}`)).data
}

export const paginateStates = () => {
  return requestPaginated<ICreatedNameCheckList[]>({ url: '/namechecklists-paginated' })
}
export const deleteById = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`namechecklists/${id}`)
}

export const nameCheckByStateId = async (id: number): Promise<ICreatedNameCheckList> => {
  return (await apiClient.get(`namecheck/${id}`)).data
}
