import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TableActionButton from 'components/common/buttons/tableActionButton'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { ICompany, legalTypesToHumanReadableFormat } from 'models/company'
import * as React from 'react'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import DeleteIcon from 'components/common/icons/delete'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: 40,
      height: 40,
    },
    deleteIcon: {
      color: theme.destructiveColor,
      fontSize: '2rem',
      padding: 1,
    },
  })
)

interface ICompanyTableRowProps {
  company: ICompany
  onDeleteClick: () => void
  onClick: () => void
}

const CompanyTableRow: React.FC<ICompanyTableRowProps> = ({ company, onDeleteClick, onClick }) => {
  const classes = useStyles()

  return (
    <StyledTableRow onClick={onClick}>
      <StyledTableCell>{company.id}</StyledTableCell>
      <StyledTableCell>
        {company.name}{' '}
        {company.parent_company && (
          <>
            <br /> <Chip label={'Sub Company'} size="small" color="secondary" />
          </>
        )}
      </StyledTableCell>
      <StyledTableCell>{legalTypesToHumanReadableFormat[company.legal_type]}</StyledTableCell>
      <StyledTableCell>{company.state_of_formation}</StyledTableCell>
      <StyledTableCell>{company.file_date}</StyledTableCell>
      {/* cell for the sales rep */}
      {/* <StyledTableCell /> */}
      <StyledTableCell>
        {company &&
          company.owner &&
          company.owner?.map((item: any) => {
            return `${item.first_name} ${item.last_name} -
        `
          })}
      </StyledTableCell>
      <StyledTableCell>{company.creation_time ? formatDateToHumanFriendly(company.creation_time) : ''}</StyledTableCell>

      <StyledTableCell>
        <TableActionButton
          className={classes.button}
          onClick={event => {
            event.stopPropagation()
            onDeleteClick()
          }}
        >
          {/* <CloseIcon className={classes.deleteIcon} /> */}
          <DeleteIcon tooltipText="Delete Company" />
        </TableActionButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default CompanyTableRow
