import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { ISalesRep } from 'models/user'

export interface Owners {
  order_id: number
  owner_address: string
  owner_category: string
  owner_city: string
  owner_company_name: string
  owner_email: string
  owner_first_name: string
  owner_id: number
  owner_last_name: string
  owner_middle_name: string
  owner_phone: string
  owner_position: string
  owner_state: string
  owner_type: string
  owner_zip_code: string
  ownership_percentage: string
  roles?: any
}

interface AddonsData {
  addon_description: string
  addon_icon: string
  addon_id: number
  addon_img: string
  addon_name: string
  addon_price: number
  order_type: string
  form?: number
}

interface Address {
  city: string | null
  country: string | null
  line1: string | null
  line2: string | null
  postal_code: string | null
  state: string | null
}

interface Checks {
  address_line1_check: string | null
  address_postal_code_check: string | null
  cvc_check: string
}

interface Networks {
  available: string[]
  preferred: string | null
}

interface ThreeDSecureUsage {
  supported: boolean
}

interface PaymentDetails {
  brand: string
  address: Address
  checks: Checks
  country: string
  display_brand: string
  email: string | null
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  generated_from: any | null // Use a more specific type if known
  last4: string
  name: string
  networks: Networks
  phone: string | null
  three_d_secure_usage: ThreeDSecureUsage
  wallet: any | null // Use a more specific type if known
}

export interface WebSubmissionsData {
  addons: AddonsData[]
  billing_address: string
  billing_name: string
  billing_state: string
  billing_zip_code: string
  cardholder_name: string
  companies_registered_agent: string
  company_account: string
  company_address: string
  company_city: string
  company_creation: string
  company_description: string
  company_name: string
  company_ownership: string
  company_register_state: string
  company_state: string
  company_wesite: string
  company_zip_code: number
  deleted: number
  diff_country_owner: string
  diff_country_owners: string
  discounted_total: number
  ein_registrar: string
  ein_registrar_ssn: string
  email: string
  employees_plan: string
  expedite_process_fee: string
  expedite_processing: string
  file_jointly: number
  first_name: string
  form_version: number
  gclid: string
  fbclid: string
  msclkid: string
  invoice: string
  is_com_conversion: number
  is_net_conversion: number
  last_name: string
  middle_name: string
  order_date: string
  order_discount: number
  order_id: number
  order_status_old: string
  order_step: string
  order_total: string
  order_type: string | number
  other_ein_registrar: string
  own_registered_agent: string
  package: string | number
  package_fee: number
  package_name: string
  pay_clicked: number
  payment_card_number: string
  payment_card_type: string
  payment_deducted_amount: string
  payment_error: string
  payment_id: string
  payment_method: string
  payment_status: string
  phone: string
  post_payment_amount: string
  post_payment_date: string
  post_payment_error: string
  post_payment_id: string
  post_payment_method: string
  post_payment_status: string
  post_step: string
  ref_code: string
  referrer: string
  register_state_fee: number
  register_state_name: string
  registered_agent_address: string
  registered_agent_city: string
  registered_agent_name: string
  registered_agent_phone: string
  registered_agent_state: string
  registered_agent_zipcode: number
  share_assign: string
  share_value: string
  token: string
  user_agent: string
  utm_campaign: string
  utm_content: string
  utm_medium: string
  utm_source: string
  business_activity?: string
  bank_offer?: number
  active_bank_offer?: string
  owners: Owners[]
  web_url?: string
  promocode?: string | number
  tax_upgrade?: boolean
  registered_agent_first_name?: string
  subscription_id?: string
  ipostal_address_provided?: boolean
  ipostal_link?: string
  next_order_step?: number
  post_next_step?: string
  promoDiscount?: number
  sales_rep?: ISalesRep
  brid?: string
  ip_address?: string

  submission_ip?: string
  hotjar_id?: string
  google_analytics_id?: string
  tax_taken_from?: number
  tax_subscription_amount?: number
  tax_price_id?: number
  professional_address?: boolean
  business_rocket_address?: boolean
  address_subscription_amount?: number
  address_taken_from?: number
  resident?: string

  payment_details?: PaymentDetails

  subscriptions?: { name: string; subscription_id: string }[]

  ra_subscription?: string
  tax_subscription?: string
  rocket_compliance_subscription?: string
  business_address_subscription?: string
}

export interface WebSubmissionParams {
  type?: number
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
  ref_code?: string
  active_bank_offer?: string
  bank_offer?: number
  order_step?: number
  start_date?: string
  end_date?: string
}

export interface SubmissionSummaryData {
  offlinePaid_count: number
  paid_count: number
  success_rate: string
  total_count: number
}

export interface ImarkSubmissionAsConversionReq {
  submission_type: submission_types
  gclid: string
}

export type submission_types = 'com' | 'net'

export interface IUpdateResponse {
  success?: 'Updated Successfully'
  error?: 'Error Updating Data'
}
export const fetchWebSubmissions = (
  type?: number,
  query?: string,
  utmMediumValue?: string,
  utmSourceValue?: string,
  utmCampaignValue?: string,
  utmContentValue?: string,
  refCodeValue?: string,
  bankOfferValue?: string,
  offerAcceptValue?: any,
  statusValue?: any,
  orderType?: any,
  paymentType?: any,
  startDateRange?: string,
  endDateRange?: string,
  isGCLID?: boolean,
  formCompletion?: boolean,
  clidsValue?: string,
  formVersionValue?: any,
  stateOfFormation?: any,
  packageId?: any,
  resident?: any
) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'

  return requestPaginated<WebSubmissionsData[]>({
    url: `/web/submissions?type=${type}&query=${query ? encodeURIComponent(query) : ''}&utm_medium=${
      utmMediumValue ? utmMediumValue : ''
    }&utm_source=${utmSourceValue ? utmSourceValue : ''}&utm_campaign=${
      utmCampaignValue ? utmCampaignValue : ''
    }&utm_content=${utmContentValue ? utmContentValue : ''}&ref_code=${
      refCodeValue ? refCodeValue : ''
    }&active_bank_offer=${bankOfferValue ? bankOfferValue : ''}&bank_offer=${
      offerAcceptValue || offerAcceptValue === 0 ? offerAcceptValue : -2
    }&order_step=${statusValue || statusValue === 0 ? statusValue : -1}&start_date=${
      startDateRange ? encodeURIComponent(startDateRange) : ''
    }&end_date=${endDateRange ? encodeURIComponent(endDateRange) : ''}&order_type=${
      orderType || orderType === 0 ? orderType : -1
    }&payment_status=${paymentType || ''}&no_gclid=${encodeURIComponent(
      isGCLID || false
    )}&form_completion=${encodeURIComponent(formCompletion || false)}&clid=${clidsValue}&form_version=${
      formVersionValue ? formVersionValue : 0
    }&state_of_formation=${encodeURIComponent(stateOfFormation || 0)}&package_id=${packageId ||
      0}&resident=${resident || ''}`,
  })
}

export const getWebSubmissionById = async (id: number): Promise<WebSubmissionsData> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'

  const data = (await apiClient.get(`/web/submissions/${id}`)).data
  return data
}

export const deleteWebSubmission = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/websubmission/${id}`)

export const getWebSubmissionFilters = async (startDateRange?: string, endDateRange?: string): Promise<any> => {
  const data = (
    await apiClient.get(
      `/submissions-filters?start_date=${startDateRange ? encodeURIComponent(startDateRange) : ''}&end_date=${
        endDateRange ? encodeURIComponent(endDateRange) : ''
      }&entity_type=orders`
    )
  ).data
  return data
}

export const submissionFiltersSummary = async (
  type?: number,
  utmMediumValue?: string,
  utmSourceValue?: string,
  utmCampaignValue?: string,
  utmContentValue?: string,
  refCodeValue?: string,
  bankOfferValue?: string,
  offerAcceptValue?: any,
  statusValue?: any,
  orderType?: any,
  paymentType?: any,
  startDateRange?: string,
  endDateRange?: string,
  isGCLID?: boolean,
  formCompletion?: boolean,
  clidsValue?: string,
  formVersionValue?: any,
  stateOfFormation?: any,
  packageId?: any,
  resident?: any
): Promise<SubmissionSummaryData> => {
  const data = (
    await apiClient.get(
      `/submissions-filters/summary?type=${type}&utm_medium=${utmMediumValue}&utm_source=${utmSourceValue}&utm_campaign=${utmCampaignValue}&utm_content=${utmContentValue}&ref_code=${refCodeValue}&active_bank_offer=${bankOfferValue}&bank_offer=${
        offerAcceptValue || offerAcceptValue === 0 ? offerAcceptValue : -2
      }&order_step=${statusValue || statusValue === 0 ? statusValue : -1}&start_date=${encodeURIComponent(
        startDateRange || ''
      )}&end_date=${encodeURIComponent(endDateRange || '')}&order_type=${
        orderType || orderType === 0 ? orderType : -1
      }&payment_status=${paymentType || ''}&no_gclid=${encodeURIComponent(
        isGCLID || false
      )}&form_completion=${encodeURIComponent(formCompletion || false)}&clid=${clidsValue}&form_version=${
        formVersionValue ? formVersionValue : 0
      }&state_of_formation=${stateOfFormation || 0}&package_id=${packageId || 0}&resident=${resident || ''}`
    )
  ).data
  return data
}

export const markOfflinePaidByID = async (id: number): Promise<any> => {
  await apiClient.get(`/mark-offline-paid/${id}`)
}

export const fetchNoUTMWebSubmissions = () => {
  return requestPaginated<WebSubmissionsData[]>({
    url: `/web-submissions-no-utms`,
  })
}

export const orderReviewStatusWebSubmission = async (id: number, payload: any): Promise<any> =>
  (await apiClient.post(`orders-review-status/${id}`, payload)).data

export const markOfflineConversion = async (
  data: ImarkSubmissionAsConversionReq,
  id: number
): Promise<IUpdateResponse> => {
  return (await apiClient.post(`/submission-conversion/${id}`, data)).data
}

export const getVisitorLogsByIP = async (ip_address: string): Promise<any> => {
  return (await apiClient.get(`/visitorlog/submission?ip_address=${ip_address}`)).data
}
