import * as React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: 200,
      backgroundColor: theme.button.backgroundColor,
      height: 50,
      textTransform: 'none',
      fontWeight: 'bold',
      color: 'white',
      '&:hover': {
        color: theme.button.hover.color,
        backgroundColor: theme.button.hover.backgroundColor,
      },
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.26)!important',
    },
  })
)

interface IPrimaryButtonProps extends ButtonProps {
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  classes?: Record<string, string>
}

const PrimaryButton: React.FC<IPrimaryButtonProps> = ({ children, className, onClick, style, ...props }) => {
  const classes = useStyles(props)
  return (
    <Button
      {...props}
      style={style}
      variant="contained"
      onClick={onClick}
      classes={{ disabled: classes.disabled }}
      className={`${classes && classes.button} ${className}`}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
