import React, { useState } from 'react'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { createStyles, makeStyles, Theme, MenuItem } from '@material-ui/core'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import BRTextField from 'components/common/textFields/BRTextField'
import { PROMO_CODE_TYPES } from 'utils/constant'
import { addPromoCode } from 'api/promoCode'
import moment from 'moment'
import showSuccessNotification from 'utils/showSuccessNotification'
import showApiResponseError from 'utils/showApiResponseError'
import PromoCodeList from './promoCodeList'

const PromoCodes = () => {
  const classes = useStyles()

  const [promoValue, setPromoValue] = useState({ code: '', expiry: moment(), type: '', value: '', maxUsage: '' })
  const { code, expiry, type, value, maxUsage } = promoValue

  const [addPromoLoader, setAddPromoLoader] = useState(false)
  const [addCodeModal, setAddCodeModal] = useState(false)
  const [addPromoSuccess, setAddPromoSuccess] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setPromoValue(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const addPromoCodeHandler = async () => {
    if (!code || !expiry || !type || !value || !maxUsage) {
      return
    }

    setAddPromoLoader(true)
    const payload = {
      code: code,
      expiry: moment(expiry),
      type: type,
      value: parseInt(value, 10),
      max_usage: parseInt(maxUsage, 10),
    }

    try {
      await addPromoCode(payload)
      showSuccessNotification('Promo Code Successfully Added!')
      setAddPromoLoader(false)
      setAddCodeModal(false)
      setAddPromoSuccess(true)
    } catch (error) {
      showApiResponseError(error, 'Error')
      console.log(error)
    }
    setAddPromoLoader(false)

    setPromoValue({ code: '', expiry: moment(), type: '', value: '', maxUsage: '' })
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.missingItems}>Promo Codes</p>
          <p className={classes.tableTitle}>Promo Codes List</p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading onClick={() => setAddCodeModal(true)}>Add Promo Code</ButtonWithLoading>
        </div>
      </div>
      <ModalWrapper open={addCodeModal} setOpen={setAddCodeModal} heading="Add Promo Code">
        <BRTextField
          value={code}
          name="code"
          label="Code"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
        />
        <BRTextField
          value={expiry}
          name="expiry"
          label="Expiry"
          type="date"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
        />
        <BRTextField
          value={type}
          name="type"
          label="Type"
          select
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
        >
          <MenuItem value={PROMO_CODE_TYPES?.percentage}>Percentage</MenuItem>
          <MenuItem value={PROMO_CODE_TYPES?.fixed}>Fixed</MenuItem>
        </BRTextField>
        <BRTextField
          value={value}
          name="value"
          label="Value"
          type="number"
          variant="outlined"
          focused
          className={classes.textField}
          onChange={handleChange}
        />
        <BRTextField
          value={maxUsage}
          name="maxUsage"
          label="Max Usage"
          type="number"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
        />

        <ButtonWithLoading isLoading={addPromoLoader} onClick={addPromoCodeHandler}>
          Add
        </ButtonWithLoading>
      </ModalWrapper>
      <PromoCodeList addPromoSuccess={addPromoSuccess} />
    </div>
  )
}

export default PromoCodes

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    textField: {
      marginBottom: '1rem',
    },
  })
)
