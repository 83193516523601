import { TextFieldWithDebounce } from '@jetkit/react'
import { Grid, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { nameCheckByStateId } from 'api/namechecklist'
import Addresses from 'components/common/addresses'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import DatePicker from 'components/common/datePicker'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import BRTextField from 'components/common/textFields/BRTextField'
import CopyTextFieldWrapper from 'components/common/textFields/copyTextFieldWrapper'
import EinInput from 'components/common/textFields/einInput'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'

import { IDetailedCompanyResponse } from 'models/company'
import moment from 'moment'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import showErrorNotification from 'utils/showErrorNotification'
import usaStates from 'utils/usaStates'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 430,
      display: 'flex',
      flexDirection: 'column',
    },
    textField: {
      margin: '0.5rem 0',
      width: '100%',
    },
    datePicker: {
      width: '100%',
    },
    einInput: {
      marginBottom: '0.5rem',
      width: '100%',
    },
    checkboxWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    checkbox: {
      background: 'none',
      paddingLeft: 0,
    },
  })
)

interface ICompanyInfoProps {
  companyDetails: IDetailedCompanyResponse
  handleCompanyChanges: <K extends IDetailedCompanyResponse>(field: keyof K, value: K[keyof K]) => void
}

export const CompanyInfo: React.FC<ICompanyInfoProps> = props => {
  const classes = useStyles(props)
  const { companyDetails, handleCompanyChanges } = props

  const [namecheckloading, setnamecheckloading] = React.useState<boolean>(false)

  const checkName = async () => {
    try {
      setnamecheckloading(true)
      const res = await nameCheckByStateId(companyDetails?.state_of_formation_id || 0)
      if (res && res.name_check_url && res.name_check_url.length) {
        window.open(res.name_check_url, '_blank')
        setnamecheckloading(false)
      } else {
        showErrorNotification('No Url provided for this state')
        setnamecheckloading(false)
      }
      //showSuccessNotification('Sent Successfully')
    } catch (e) {
      showApiResponseError(e, 'Error: Something went wrong! ')
      setnamecheckloading(false)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1 className="campany-detail">Company Details</h1>
      </Grid>
      <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
        <CopyTextFieldWrapper style={{ width: '100%' }} valueToCopy={companyDetails.name}>
          <TextFieldWithDebounce
            variant="outlined"
            label="Company Name (Tax Filer)"
            type="text"
            name="Company"
            margin="normal"
            disabled
            className={classes.textField}
            value={companyDetails.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCompanyChanges('name', event.target.value)}
          />
        </CopyTextFieldWrapper>
        <ButtonWithLoading
          style={{ width: '250px', marginLeft: '0.5rem', minHeight: '56px' }}
          // wrapperClassName={classes.nameCheckBtn}
          isLoading={namecheckloading}
          // disabled={disabled}
          onClick={checkName}
        >
          Name Check
        </ButtonWithLoading>
      </Grid>
      <Grid item xs={3}>
        <TextFieldWithDebounce
          variant="outlined"
          label="Company Type"
          type="text"
          name="Company"
          margin="normal"
          disabled
          className={classes.textField}
          value={companyDetails.legal_type}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCompanyChanges('name', event.target.value)}
        />
      </Grid>
      <Grid item xs={9}>
        <BRTextField
          showCopyButton
          value={(companyDetails && companyDetails.business_activity) || ''}
          multiline
          type="text"
          name="Business Activity"
          placeholder="Business Activity"
          label="Business Activity"
          className={classes.textField}
          variant="outlined"
          rows={5}
          disabled
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleCompanyChanges('business_activity', event.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <CopyTextFieldWrapper valueToCopy={companyDetails.state_of_formation}>
          {companyDetails.country_of_formation &&
          ['United States', UNITED_STATES, 'USA'].includes(companyDetails.country_of_formation) ? (
            <SelectAutocomplete
              containerStyles={{ margin: '0.5rem 0', width: '100%' }}
              options={usaStates.map(state => ({
                value: state,
                label: state,
              }))}
              label="State of Formation"
              value={companyDetails.state_of_formation || ''}
              handleChange={value => handleCompanyChanges('state_of_formation', value as string)}
            />
          ) : (
            <TextFieldWithDebounce
              variant="outlined"
              label="State/Province"
              type="text"
              className={classes.textField}
              name="State/Province"
              value={companyDetails.state_of_formation ? companyDetails.state_of_formation : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleCompanyChanges('state_of_formation', event.target.value)
              }
              disabled
            />
          )}
        </CopyTextFieldWrapper>
        <CopyTextFieldWrapper valueToCopy={companyDetails.ein || ''}>
          <EinInput
            value={companyDetails.ein || undefined}
            onChange={(value: string) => {
              handleCompanyChanges('ein', value)
            }}
            label="EIN"
            classes={{ textField: classes.textField, container: classes.einInput }}
            disabled
          />
        </CopyTextFieldWrapper>
      </Grid>
      <Grid item xs={3}>
        <CopyTextFieldWrapper valueToCopy={companyDetails.state_id || ''}>
          <TextFieldWithDebounce
            variant="outlined"
            label="State ID"
            type="text"
            className={classes.textField}
            name="state_id"
            value={companyDetails.state_id ? companyDetails.state_id : ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChanges('state_id', event.target.value)
            }
            disabled
          />
        </CopyTextFieldWrapper>
      </Grid>
      <Grid item xs={3}>
        <CopyTextFieldWrapper valueToCopy={moment(companyDetails.file_date)?.toString()}>
          <DatePicker
            value={companyDetails.file_date ? moment(companyDetails.file_date) : undefined}
            classes={{ root: classes.datePicker }}
            label="File Date"
            style={{ width: '100%' }}
            onDateSelected={option => handleCompanyChanges('file_date', option)}
            disabled
          />
        </CopyTextFieldWrapper>
      </Grid>
      <Grid item xs={3}>
        <CopyTextFieldWrapper valueToCopy={moment(companyDetails.meeting_date)?.toString()}>
          <DatePicker
            value={companyDetails.meeting_date ? moment(companyDetails.meeting_date) : undefined}
            classes={{ root: classes.datePicker }}
            label="Meeting Date"
            style={{ width: '100%' }}
            onDateSelected={option => handleCompanyChanges('meeting_date', option)}
            disabled
          />
        </CopyTextFieldWrapper>
      </Grid>
      <Grid item xs={3}>
        <CopyTextFieldWrapper valueToCopy={companyDetails.tax_structure}>
          <TextField
            variant="outlined"
            disabled
            label="Tax Structure"
            type="text"
            name="tax_structure"
            margin="normal"
            className={classes.textField}
            value={companyDetails.tax_structure}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChanges('tax_structure', event.target.value)
            }
          />
        </CopyTextFieldWrapper>
      </Grid>
      <Grid item xs={12}>
        <h1 className="campany-detail">Business Addresses</h1>
      </Grid>
      <Grid item xs={6}>
        <Addresses
          label="Primary Address"
          onlyOneAddress
          addresses={companyDetails?.primary_address ? [companyDetails?.primary_address] : []}
          isEditable={false}
          isDeletable={false}
          onAddressEditClick={function(id: number): void {
            throw new Error('Function not implemented.')
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Addresses
          label="Mailing Address"
          onlyOneAddress
          addresses={companyDetails?.mailing_address ? [companyDetails?.mailing_address] : []}
          isEditable={false}
          isDeletable={false}
          onAddressEditClick={function(id: number): void {
            throw new Error('Function not implemented.')
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CompanyInfo
