import {
  Checkbox,
  Chip,
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { deleteAddress, editAddress, getstates } from 'api/addresses'
import { checkCompanyTypes, createClient, updateClient } from 'api/stateprocessing'
import { customFetchSuggestions, fetchRegisterAgentSuggestions, fetchSuggestions } from 'api/suggestions'
import CommissionAccordion from 'components/clients/commissions/accordion'
import EinSsnInput from 'components/clients/create/einSsnInput'
import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { validateEmail } from 'utils/validators'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { ISuggestionAttribute } from 'components/interfaces'
import { PrimaryAddress } from 'components/companies/companyCreation/firstStep/PrimaryAddress'
import { useState } from 'react-transition-group/node_modules/@types/react'
import { IInputFieldsErrors } from 'components/companies/create'
import { MailingAddress } from 'components/companies/companyCreation/firstStep/MailingAddress'
import SelectAutocomplete from '../../../components/common/SelectAutocomplete'
import { legalTypesLists } from 'utils/autocompleteFakers'
import showSuccessNotification from 'utils/showSuccessNotification'
import { Autocomplete } from '@material-ui/lab'
import { toTitleCase } from 'utils/toTitleCase'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .select-item': {
        '& .MuiFormControl-root ': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },

    selectItem: {
      margin: '0 !important',
      width: '100%',
    },
    optionLabel: {
      '& .title': {
        margin: '0',
      },
      '& .address': {
        color: '#0000009c',
        fontSize: '12px',
        margin: '0',
        fontStyle: 'italic',
      },
    },
    PaidToGovt: {
      paddingLeft: 0,
      fontSize: 20,
      cursor: 'pointer',
      margin: '6px 0',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: IStates = {
  id: 0,
  state_id: 0,
  company_type: 0,
  state_display_name: '',
  processing_time: '',
  rush_processing_time: '',
  state_fee: '',
  annual_state_fee: '',
  paid_to_state_directly: false,
  estimated_fee: false,
  essential_processing_time: '',
}

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

export interface IClientDetailsProps {
  editedClient?: IStates
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedState) => void
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

interface FilterType {
  label: string
  value: number
}

// Because date returned from backend, is in different format, that existing validator accepts,
// we format it properly first

const StateProcessingDetails: React.FC<IClientDetailsProps> = ({
  editedClient,
  onRequestSuccessful /*, initialClient*/,
}) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  console.log('1' /*initialClient*/)
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<IStates>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [addressesToDelete, setAddressesToDelete] = React.useState<number[]>([])
  const [countriesdata, setcountriesdata] = React.useState<any>([
    {
      id: 1,
      name: 'USA',
    },
    {
      id: 2,
      name: 'Canada',
    },
  ])
  const [states, setStates] = React.useState([])

  const { history } = useRouter()
  const updateClientRequest = async (client: IStates) => {
    let id = 0
    countriesdata.map((item: any) => {
      if (item.name === client.country_name) {
        id = item.id
      }
    })
    client.country_id = id
    const editedClient = client
    const resClient = await updateClient({
      // We pick certain fields from editedClient object, because client,
      // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
      // if we send them to PATCH `user/:id`
      // extid: editedClient.extid,
      id: editedClient.id,
      state_id: editedClient.state_id,
      company_type: editedClient.company_type,
      state_display_name: '',
      state_fee: editedClient.state_fee,
      processing_time: editedClient.processing_time,
      rush_processing_time: editedClient.rush_processing_time,
      annual_state_fee: editedClient.annual_state_fee,
      registered_agent: editedClient.registered_agent?.value,
      paid_to_state_directly: editedClient.paid_to_state_directly,
      estimated_fee: editedClient.estimated_fee,
      essential_processing_time: editedClient.essential_processing_time,
    })
    showSuccessNotification('Updated Successfully')
    return resClient
  }

  const createClientRequest = async (client: IStates) => {
    const resClient = await createClient({ ...client, registered_agent: client.registered_agent?.value })
    return resClient
  }

  const toggleAddAddressDialog = React.useCallback(() => {
    setShowAddAddressDialog(!showAddAddressDialog)
    setCurrentEditedAddress(emptyAddress)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const changeClientField = React.useCallback(
    (field: keyof IStates, value: ValueOf<IStates>) => {
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const validateFields = React.useCallback((): boolean => {
    ////
    const isValid = true
    const tmpHelperTexts = helperTexts
    // if (!validateName(client)) {
    //   clientEditErrors.setError('name', true)
    //   isValid = false
    // }
    // if (!client.email || !validateEmail(client.email)) {
    //   clientEditErrors.setError('email', true)
    //   tmpHelperTexts.email = 'Please Specify Email'
    //   isValid = false
    // }
    // if (client.dob && client.dob !== '' && !validateDob(client)) {
    //   clientEditErrors.setError('dob', true)
    //   isValid = false
    // }
    // if (!validateAddress(client)) {
    //   clientEditErrors.setError('no_primary_address', true)
    //   isValid = false
    // }
    // setHelperTexts(tmpHelperTexts)
    return isValid
  }, [helperTexts, client, clientEditErrors])

  const sendAddressDeletionRequests = React.useCallback(() => {
    addressesToDelete.forEach(async addressId => {
      try {
        deleteAddress(addressId)
      } catch (err) {
        showApiResponseError(err, `Failed to delete address ${addressId}`)
      }
    })
  }, [addressesToDelete])

  const sendAddressUpdateRequests = React.useCallback(() => {
    addressesToEdit.forEach(async address => {
      try {
        const cleanedUpAddress: IAddress = removeNullAndUndefined(address) as IAddress
        await editAddress(cleanedUpAddress)
      } catch (err) {
        showApiResponseError(err, `Failed to update address ${address.id}`)
      }
    })
  }, [addressesToEdit])

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    if (!validateFields()) return false
    try {
      let clientResponse: ICreatedState
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)

        // When creating a new client, the attached addresses are without client ids.
        // This prevents us from sending a request to create a new address and link it with the client.
        const addressesToCreateWithClientIds = addressesToAdd
        addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
        setAddressesToAdd(addressesToCreateWithClientIds)
      }

      if (addressesToDelete.length) {
        sendAddressDeletionRequests()
      }
      if (addressesToEdit.length) {
        sendAddressUpdateRequests()
      }

      const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
      history.push('/state-processing')
      setClient(updatedClient)
      onFormSubmitted()
    } catch (err) {
      const errorMessage: string | undefined = err?.response?.data?.message
      const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
      const tmpHelperTexts = {
        email: '',
        ssn_ein: '',
      }
      if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
        if (errorMessage.includes('email')) {
          clientEditErrors.setError('email', true)
          tmpHelperTexts.email = 'This email is already in use'
        }
        if (errorMessage.includes('ssn_ein')) {
          clientEditErrors.setError('ssn_ein', true)
          tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
        }
        setHelperTexts(tmpHelperTexts)
        return false
      }
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }, [
    validateFields,
    formMode,
    addressesToDelete.length,
    addressesToEdit.length,
    client,
    addressesToAdd,
    sendAddressDeletionRequests,
    sendAddressUpdateRequests,
    clientEditErrors,
  ])

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedState = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  // We cannot really use `useMemo()` here, because it will cause error messages to appear once
  // we open addressEditionDialog. The reason for that is, that once we open it, fields are empty.
  // Empty fields are not valid for a request, but are OK for initial state of dialog or any other moment,
  // while editing these fields. Address errors must be set if necessary only when submit button is clicked,
  // `useCallback()` fits this purpose. Using `useMemo()` causes errors to be set immediately,
  // and to be set always, when a field is empty.

  const classes = useStyles()
  const stateOptions = () => {
    return states?.map((state: any) => ({
      value: state?.id,
      label: state?.state_display_name,
    }))
  }
  React.useEffect(() => {
    const states = async () => {
      setLoading(true)

      const state = await getstates()
      setStates(state)
      setLoading(false)
    }
    states()
  }, [])
  const [companyTypeOptions, setcompanyTypeOptions] = React.useState<any>(legalTypesLists)
  const [loading, setLoading] = React.useState<boolean>(false)

  const [RAList, setRAList] = React.useState<ISuggestionAttribute[]>([])

  React.useEffect(() => {
    const chcktypes = async () => {
      if (client.state_id && !editedClient) {
        setLoading(true)

        const takenTypes = await checkCompanyTypes(client.state_id)
        const companytypes: any = legalTypesLists
        const companyTypeOptionsTemp: any = []
        let takenId = 0
        const removeValFromIndex: number[] = []
        if (takenTypes.length) {
          console.log('typess')

          takenTypes.map(item => {
            companytypes.map((type, index: number) => {
              if (item.company_type === type.value) {
                takenId = item.company_type
                removeValFromIndex.push(index)
                companyTypeOptionsTemp.push(takenId)
              }
            })
          })
          for (let i = removeValFromIndex.length - 1; i >= 0; i--) companytypes.splice(removeValFromIndex[i], 1)
          const uniqueArray = companyTypeOptionsTemp.filter((item, pos) => {
            return companyTypeOptionsTemp.indexOf(item) == pos
          })
          setcompanyTypeOptions(companytypes)
        }
        setLoading(false)
      }
    }
    // chcktypes()
  }, [client.state_id])

  const findSelectedAssigne = async () => {
    const data = await fetchRegisterAgentSuggestions('')
    setRAList(data)
  }
  React.useEffect(() => {
    findSelectedAssigne()
  }, [])

  const splitString = (stringToSplit: string, separator: string) => {
    const arr = stringToSplit.split(separator)
    return { name: arr[0], address: arr[1] }
    // return arrayOfStrings.join(' / ')
  }

  console.log('RAList', RAList)
  React.useEffect(() => {
    debugger
    if (client?.estimated_fee && !client.paid_to_state_directly) changeClientField('paid_to_state_directly', true)
    // if (editedClient?.estimated_fee && !editedClient.paid_to_state_directly)
    // changeClientField('paid_to_state_directly', true)
  }, [client?.estimated_fee, client?.paid_to_state_directly])
  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <div data-testid="state-of-formation" className={classes.selectItem}>
          <FormControl variant="outlined" className={classes.selectItem}>
            <InputLabel>State of Formation</InputLabel>
            <Select
              onChange={e => changeClientField('state_id', e.target.value as number)}
              label="State"
              name="stateOfFormation"
              value={client.state_id}
              fullWidth
              disabled={loading}
            >
              {stateOptions()?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {client.state_id && client.state_id > 0 && !loading ? (
          <>
            <br />

            <div data-testid="company-type" className={classes.selectItem}>
              <FormControl variant="outlined" className={classes.selectItem}>
                <InputLabel>Company Type</InputLabel>
                <Select
                  onChange={e => changeClientField('company_type', e.target.value as number)}
                  label="Company Type"
                  name="companyType"
                  fullWidth
                  value={client.company_type}
                >
                  {companyTypeOptions?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
          </>
        ) : (
          <></>
        )}

        <FormControl variant="outlined" style={{ margin: '0.5rem 0', width: '100%' }}>
          <TextField
            id="processingtime"
            label="Processing Time"
            variant="outlined"
            value={client.processing_time}
            onChange={e => changeClientField('processing_time', e.target.value)}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0', width: '100%' }}>
          <TextField
            id="rushprocessingtime"
            label="Rush Processing Time"
            variant="outlined"
            value={client.rush_processing_time}
            onChange={e => changeClientField('rush_processing_time', e.target.value)}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0', width: '100%' }}>
          <TextField
            id="processingtime"
            label="State Fee"
            variant="outlined"
            value={client.state_fee}
            onChange={e => changeClientField('state_fee', e.target.value)}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0', width: '100%' }}>
          <TextField
            id="annualFee"
            label="Annual Report Fee"
            variant="outlined"
            value={client.annual_state_fee}
            onChange={e => changeClientField('annual_state_fee', e.target.value)}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0', width: '100%' }}>
          {/* <SelectField
            onOptionSelected={option => changeClientField('registered_agent', option && option)}
            title="Register Agent"
            fetchSuggestions={fetchSuggestions}
            field="registered_agent"
            value={client.registered_agent}
          /> */}
          <Autocomplete
            id="registered_agent"
            options={RAList}
            getOptionLabel={option => option.label}
            value={client.registered_agent}
            onChange={(event: any, newValue: ISuggestionAttribute | null) => {
              changeClientField('registered_agent', newValue)
            }}
            renderInput={params => <TextField {...params} label="Register Agent" variant="outlined" />}
            renderOption={option => (
              <div className={classes.optionLabel}>
                <p className="title">{splitString(option?.label, '-').name}</p>
                <p className="address">{splitString(option?.label, '-').address}</p>
              </div>
            )}
          />
        </FormControl>
        <ListItem key={100} className={classes.PaidToGovt}>
          <Checkbox
            color="secondary"
            checked={client.paid_to_state_directly}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            // value={values.is_primary_contact}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => changeClientField('paid_to_state_directly', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Paid to state directly')}</span>
        </ListItem>
        <ListItem key={101} className={classes.PaidToGovt}>
          <Checkbox
            color="secondary"
            checked={client.estimated_fee}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            // value={values.is_primary_contact}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => changeClientField('estimated_fee', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Estimated Fee')}</span>
        </ListItem>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0', width: '100%' }}>
          <TextField
            id="essential_processing_time"
            label="Essential Processing Fee"
            variant="outlined"
            value={client.essential_processing_time}
            onChange={e => changeClientField('essential_processing_time', e.target.value)}
          />
        </FormControl>
        <SubmitFormButton
          //onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default StateProcessingDetails
