import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { DialogContent } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      fontFamily: 'Lato',

      // '& .MuiDialogContent-root': {
      //   minHeight: '500px',
      // },
    },

    stepsText: {
      color: '#307abc',
      fontSize: '12px',
      margin: '0',
      fontFamily: 'Roboto',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
)

export interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles()
  const { children, onClose } = props
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}
interface CustomDialogueProps {
  children: any
  open: boolean
  handleClose: () => void
  activeStep: number
}

const CustomDialogue = ({ children, open, handleClose, activeStep }: CustomDialogueProps) => {
  const classes = useStyles()
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="xs"
        fullWidth
        className={classes.mainContainer}
        disableBackdropClick
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {activeStep > 0 ? 'Add Company' : ''}
          {activeStep > 0 ? <p className={classes.stepsText}>STEP {activeStep} of 3</p> : ''}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  )
}
export default CustomDialogue
