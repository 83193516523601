import TaskRow from 'components/tasks/taskRow'
import { IOrder } from 'models/order'
import { ITask } from 'models/task'
import OrderRow from 'pages/dashboard/orderRow'
import * as React from 'react'

export type IDashboardRow = {
  type: 'task' | 'order'
  task?: ITask
  order?: IOrder
}

interface Props {
  data: IDashboardRow
}

// show a row in the table for either ITask or IOrder
const DashboardRow = ({ data }: Props) => {
  switch (data.type) {
    case 'order':
      return data.order ? <OrderRow order={data.order} key={data.order.id} /> : null
    case 'task':
      return data.task ? <TaskRow task={data.task} key={data.task.id} /> : null
  }
}

export default DashboardRow
