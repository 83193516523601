import { PagedTable, usePagedTable } from '@jetkit/react'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Fab,
  Table,
  TableBody,
} from '@material-ui/core'
import {
  addOrderReminder,
  addOrderRemindersConfig,
  changeOrderReminderStatus,
  getOrderReminderItems,
  getOrderReminders,
  getTemplatesList,
  OrderItemProps,
  OrderReminderProps,
} from 'api/orderItem'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import moment from 'moment'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import showErrorNotification from 'utils/showErrorNotification'
import OrderItemAccordionWrapper from './orderItem/OrderItemAccordionWrapper'
import OrderItemRow from './orderItem/OrderItemRow'
import OrderItemTableHead from './orderItem/OrderItemTableHead'
import { useStyles } from './style'
import useRouter from 'use-react-router'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showSuccessNotification from 'utils/showSuccessNotification'
import ReminderScheduleModal from './orderItem/ReminderScheduleModal'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'
import { MISSING_ITEM_STATUS, REMINDER_ITEM_STATUS } from 'utils/constant'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import OrderReminderRow from './orderItem/OrderItemRow/OrderReminder'
import OrderReminderTableHead from './orderItem/OrderItemTableHead/OrderReminder'
import MissingItemTable from './orderItem/MissingItemTable'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { handleChange } from 'components/taxWidgets/TaxWidgetController/handler'

const AddOrderItem = () => {
  const classes = useStyles()
  const { match } = useRouter()

  const { id }: any = match?.params

  const userDetails = JSON.parse(localStorage.getItem('currentUser') || '')
  const userId = userDetails?.extid

  const [templateList, setTemplateList] = useState<any[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [orderItems, setOrderItems] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isReminderSchedule, setIsReminderSchedule] = useState(false)

  const [isEditModal, setIsEditModal] = useState(false)

  const [selectedReminderItem, setSelectedReminderItem] = useState<OrderReminderProps>()
  const [isMissingItmesModal, setIsMissingItmesModal] = useState(false)

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const [updateOrderItems, setUpdateOrderItems] = useState<any>({})

  const tableOrderReminderHead = useMemo(
    () => <OrderReminderTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  console.log('selectedReminderItem', selectedReminderItem)

  // const memoApiCall = useMemo(() => getOrderReminderItems(id)(), [id])

  const orderReminders = useMemo(() => getOrderReminders(id)(), [id])
  const pagedOrderRemindersTable = usePagedTable<OrderReminderProps>({
    apiCall: orderReminders,
  })

  const orderItemHanlder = () => {
    const orderItem = {
      selectedMissingItem: '',
      missingItemName: '',
      missingItemDescription: '',
      status: MISSING_ITEM_STATUS?.NotReceived,
    }
    const uuid = Math.random()
    setOrderItems({ ...orderItems, [uuid]: orderItem })
  }
  const orderItemsData = Object.entries(orderItems)

  // const updateOrderItemHanlder = () => {
  //   const updateOrderItem = {
  //     selectedMissingItem: '',
  //     missingItemName: '',
  //     missingItemDescription: '',
  //     status: MISSING_ITEM_STATUS?.NotReceived,
  //   }
  //   const uuid = Math.random()
  //   setUpdateOrderItems({ ...updateOrderItems, [uuid]: updateOrderItem })
  // }
  // const updateOrderItemsData = Object.entries(updateOrderItems)

  const handleUpdateOrderItemsData = (id, field, data) => {
    orderItems[id][field] = data
    setOrderItems({ ...orderItems })
  }

  const handleDelete = id => {
    delete orderItems[id]
    setOrderItems({ ...orderItems })
  }

  const getTemplateList = async () => {
    try {
      const data = await getTemplatesList()
      setTemplateList(data)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    getTemplateList()
  }, [])

  const addOrderHandler = useCallback(
    async orderItemID => {
      setIsLoading(true)

      const sendingArray = orderItemsData.map(([key, value]) => {
        const { missingItemName, missingItemDescription, status }: any = value
        return {
          item_name: missingItemName,
          item_description: missingItemDescription,
          status,
          created_at: moment().toString(),
          created_by: userId,
          order_reminder_id: orderItemID,
        }
      })

      try {
        await addOrderRemindersConfig({ data: sendingArray })
        setIsLoading(false)
        showSuccessNotification('Missing Item Successfully Created')
        pagedOrderRemindersTable.reloadData()
        setOrderItems({})
      } catch (error) {
        showErrorNotification(error)
      }
      setIsLoading(false)
    },
    [orderItemsData, pagedOrderRemindersTable, userId]
  )

  const templateHanlder = useCallback(async () => {
    if (!selectedTemplate) {
      return showErrorNotification('Please Select template')
    }

    setIsLoading(true)

    const templateData = {
      reminder_template_id: selectedTemplate,
      order_id: parseInt(id),
    }
    try {
      const res = await addOrderReminder(templateData)
      if (res) {
        addOrderHandler(res?.data?.id)
      }
      setIsLoading(false)
    } catch (error) {
      showErrorNotification(error)
    }
    setIsLoading(false)
  }, [addOrderHandler, id, selectedTemplate])

  const handleSave = useCallback(() => {
    templateHanlder()
  }, [templateHanlder])

  return (
    <div className={classes.root}>
      <div className="inline-field">
        <FormControl variant="outlined" className={classes.template}>
          <InputLabel>Template</InputLabel>
          <Select label="Template" value={selectedTemplate} onChange={(e: any) => setSelectedTemplate(e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {templateList?.map(temp => (
              <MenuItem value={temp?.id}>{temp?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {!orderItemsData?.length ? (
          <Button variant="contained" color="primary" onClick={orderItemHanlder}>
            + Add Item
          </Button>
        ) : (
          ''
        )}
      </div>
      {orderItemsData?.length ? (
        <>
          {orderItemsData?.map(([key, value]) => (
            <OrderItemAccordionWrapper
              keyId={key}
              orderItemData={value}
              handleDelete={handleDelete}
              handleUpdate={handleUpdateOrderItemsData}
            />
          ))}

          {/* <div className={classes.addBtn}>
            <ButtonWithLoading onClick={handleSave} isLoading={isLoading}>
              Save
            </ButtonWithLoading>
          </div> */}
        </>
      ) : (
        ''
      )}

      {orderItemsData?.length ? (
        <div className={classes.addBtn}>
          <Button className="add-button" variant="contained" color="primary" onClick={orderItemHanlder}>
            + Add Item
          </Button>
          <ButtonWithLoading onClick={handleSave} isLoading={isLoading}>
            Save
          </ButtonWithLoading>
        </div>
      ) : (
        ''
      )}

      <div className="inline-field">
        <h1 className="campany-detail">Reminders List</h1>
        <div>
          <Button
            variant="contained"
            color="primary"
            className="reminder-schedule"
            onClick={() => setIsReminderSchedule(true)}
          >
            Reminder Schedules
          </Button>
        </div>
      </div>

      <TableLoadingIndicator isLoading={pagedOrderRemindersTable.isLoading} />
      {pagedOrderRemindersTable.isLoading ? (
        <Table>
          {tableOrderReminderHead}
          <TableBody>{skeletons({ numberOfColumns: 4, sizes: [120, 200, 120, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={4}
          emptyRowText="No Template Items"
          {...pagedOrderRemindersTable.renderProps}
          renderRow={(orderItem: OrderReminderProps) => (
            <OrderReminderRow
              orderItem={orderItem}
              onEditClick={() => {
                setSelectedReminderItem(orderItem)
                setIsMissingItmesModal(true)
              }}
            />
          )}
          header={tableOrderReminderHead}
        />
      )}

      {/* <SearchBar className={classes.searchBar} onChange={e => console.log(e)} placeholder="Search" /> */}

      <ReminderScheduleModal isOpen={isReminderSchedule} close={() => setIsReminderSchedule(false)} />

      {/* <ModalWrapper open={isEditModal} setOpen={setIsEditModal} heading="Update Missing Items">
        {updateOrderItemsData?.map(([key, value]) => (
          <OrderItemAccordionWrapper
            keyId={key}
            orderItemData={value}
            handleDelete={handleDelete}
            handleUpdate={handleUpdateOrderItemsData}
          />
        ))}
      </ModalWrapper> */}

      <ModalWrapper
        maxWidth={'lg'}
        open={isMissingItmesModal}
        setOpen={setIsMissingItmesModal}
        heading="Missing Items List"
      >
        <MissingItemTable orderItem={selectedReminderItem} />
      </ModalWrapper>
    </div>
  )
}

export default AddOrderItem
