import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { Divider, Grid } from '@material-ui/core'
import PowerCard from './powerCard'
import { useParams } from 'react-router'
import {
  ISpecialOffers,
  ISpecialOffersCategory,
  getSpecialofferByID,
  getSpecialoffersByCategoryID,
} from 'api/specialOffers'
import ProTip from '../proTip'
import { Skeleton } from '@material-ui/lab'
import { OfferDialog } from 'pages/clientDashboard/offers'
import { getClientCompanies } from 'api/clientDashboard'

const PowerUp = () => {
  const classes = useStyles()
  const [powerCategories, setPowerCategories] = useState<ISpecialOffers[]>([])
  const { id } = useParams<any>()
  const [isLoading, setIsLoading] = useState(false)

  const [isOffer, setIsOffer] = useState<boolean>(false)
  const [offerDate, setOfferDate] = useState<any>()
  const [companies, setCompanies] = React.useState<any>([])

  const [powerupName, setPowerupName] = useState('')

  const getPowerCategories = async (id: number) => {
    setIsLoading(true)
    try {
      const result = await getSpecialoffersByCategoryID(id)
      setPowerCategories(result)
      setIsLoading(false)
    } catch (error) {
      console.log('error', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getPowerCategories(id)
  }, [id])

  React.useEffect(() => {
    const getCompanyDetails = async () => {
      const data: any = await getClientCompanies()
      setCompanies(data)
    }
    getCompanyDetails()
  }, [])

  React.useEffect(() => {
    const getCompanyDetails = async (id: number) => {
      const data: ISpecialOffersCategory = await getSpecialofferByID(id)
      setPowerupName(data?.title || '')
    }
    getCompanyDetails(id)
  }, [id])

  const offerModalHandler = (data: any) => {
    setIsOffer(true)
    setOfferDate(data)
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1 className="primary-heading">Power Ups - {powerupName}</h1>
          <Divider />
        </Grid>
        {isLoading ? (
          <Grid item xs={12} lg={8} style={{ marginTop: '2rem' }}>
            {[...Array(6)].map((value: undefined, index: number) => (
              <Grid container spacing={3} key={index}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={145} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : powerCategories?.length > 0 ? (
          <Grid item xs={12} lg={8}>
            {powerCategories?.map((item: ISpecialOffers) => (
              <PowerCard
                title={item?.title || ''}
                heading={item?.heading || ''}
                subheading={item?.subheading || ''}
                description={item?.description || ''}
                link={item?.link || ''}
                logo={item?.logo || ''}
                button_text={item?.button_text || ''}
                onClick={() => offerModalHandler(item)}
                terms_text={item?.terms_text || ''}
              />
            ))}
          </Grid>
        ) : (
          <Grid item xs={12} lg={8}>
            No Power Ups Found!
          </Grid>
        )}

        <Grid item xs={12} lg={4}>
          <ProTip />
        </Grid>
      </Grid>

      <OfferDialog
        open={isOffer}
        setOpen={setIsOffer}
        title={offerDate?.title}
        question={offerDate?.company_prompt}
        answers={offerDate?.link}
        link={offerDate?.link}
        btnText={offerDate?.button_text}
        isPopup={offerDate?.ispopup}
        companies={companies}
        offerId={offerDate?.id}
      />
    </div>
  )
}

export default PowerUp
