import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button, Fab, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import SelectField from 'components/common/select'
import { fetchSuggestions } from 'api/suggestions'
import { DeleteForever } from '@material-ui/icons'
import showErrorNotification from 'utils/showErrorNotification'
import { getMissingItemsList } from 'api/orderItem'
import { Editor } from '@tinymce/tinymce-react'
import { EditorApiKey } from 'utils/constant'
import { htmlRegexG } from 'utils/functions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '2rem',
      '& .accordion-container': {},

      '& .filed-header': {
        display: 'flex',
        width: '100%',
      },
    },
    headerBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '1rem',
      marginBottom: '2rem',

      '& .delete-icon': {
        color: '#fff',
        fontSize: '2rem',
      },

      '& .save-btn': {
        color: '#fff',
        padding: '0.5rem 2rem',
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      color: '#76A8D4',
    },
    formControl: {
      marginRight: '1rem',
      minWidth: '250px',
    },
    formControl1: {
      margin: '0',
      minWidth: '250px',
    },
    smsBody: {
      width: '100%',
      marginTop: '1rem',
    },
    emailSubject: {
      width: '100%',
    },
    accordionDetails: {
      display: 'block',
      paddingBottom: '0',

      '& .filed-header': {
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
      },
    },
    accordionSummary: {
      background: '#f6f6f8',
    },
    orderField: {
      width: '100%',
      // marginBottom: '2.4rem',
    },
    missingItemSection: {
      marginTop: '0.5rem', //   display: 'flex',
      //   justifyContent: 'space-between',
    },
  })
)

interface OrderItemAccordionWrapperProps {
  orderItemData: any
  keyId: string
  handleUpdate: any
  handleDelete: any
  isTaxOrder?: boolean
}

const OrderItemAccordionWrapper = ({
  orderItemData,
  keyId,
  handleUpdate,
  handleDelete,
  isTaxOrder,
}: OrderItemAccordionWrapperProps) => {
  const classes = useStyles()
  const [missingItems, setMissingItems] = useState<any[]>([])

  const getMissingList = async () => {
    try {
      const data = await getMissingItemsList(isTaxOrder ? true : false)
      setMissingItems(data)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    getMissingList()
  }, [])

  const selectedMissingItem = missingItems?.find(item => item?.id === orderItemData.selectedMissingItem)

  useEffect(() => {
    if (selectedMissingItem) {
      handleUpdate(keyId, 'missingItemName', selectedMissingItem?.name)
      handleUpdate(keyId, 'missingItemDescription', selectedMissingItem?.description)
    }
  }, [selectedMissingItem])

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded className="accordion-container">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography className={classes.heading}>Missing Item</Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          <Grid container spacing={3} className={classes.missingItemSection}>
            <Grid item xs={6} className="margin-extra">
              <FormControl variant="outlined" className={classes.orderField}>
                <InputLabel>Missing Item</InputLabel>
                <Select
                  label="Missing Item"
                  value={orderItemData.selectedMissingItem}
                  onChange={e => handleUpdate(keyId, 'selectedMissingItem', e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {missingItems?.map(temp => (
                    <MenuItem value={temp?.id}>
                      <b>{temp?.name}</b>&nbsp; - &nbsp;
                      <span>{temp?.description?.replace(htmlRegexG, '')?.slice(0, 100)}</span>
                      {/* <span className="text" dangerouslySetInnerHTML={{ __html: temp?.description || '' }} />*/}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.orderField}>
                <TextField
                  id="missingItem"
                  label="Missing Item Name"
                  variant="outlined"
                  value={orderItemData.missingItemName}
                  onChange={e => handleUpdate(keyId, 'missingItemName', e.target.value)}
                  // helperText={errors?.description ? 'Enter Description' : ''}
                />
                {/* <FormHelperText className={classes.errorMsg}>{errors?.description ? '*Enter Description' : ''}</FormHelperText> */}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.orderField}>
                {/* <TextField
                  id="missingItemDescription"
                  label="Missing Item Descriptions"
                  variant="outlined"
                  rows={6}
                  multiline
                  value={orderItemData.missingItemDescription}
                  onChange={e => handleUpdate(keyId, 'missingItemDescription', e.target.value)}
                  // helperText={errors?.description ? 'Enter Description' : ''}
                /> */}
                <Editor
                  apiKey={EditorApiKey}
                  init={{
                    plugins: [
                      'advlist autolink lists link charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    width: '100%',
                    height: '20rem',
                    valid_children: '+body[style]',
                    valid_styles: { '*': '*' },
                    init_instance_callback: function(editor) {
                      editor.on('click', function(e) {
                        if (e.target.nodeName === 'INPUT') {
                          if (e.target.getAttribute('checked')) {
                            e.target.removeAttribute('checked')
                          } else {
                            e.target.setAttribute('checked', 'true')
                          }
                        }
                      })
                    },
                  }}
                  onEditorChange={e => handleUpdate(keyId, 'missingItemDescription', e)}
                  initialValue={''}
                  value={orderItemData.missingItemDescription}
                />
                {/* <FormHelperText className={classes.errorMsg}>{errors?.description ? '*Enter Description' : ''}</FormHelperText> */}
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <div className={classes.headerBtn}>
          <Fab color="secondary" size="medium">
            <DeleteForever className="delete-icon" onClick={() => handleDelete(keyId)} />
          </Fab>
        </div>
      </Accordion>
    </div>
  )
}

export default OrderItemAccordionWrapper
