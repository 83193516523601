import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TextField,
} from '@material-ui/core'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useStyles } from './styles'
import ModalHeader from 'nav/Commision-Card/modalHeader'
import { useDropzone } from 'react-dropzone'
import showSuccessNotification from 'utils/showSuccessNotification'
import useSortTable from 'hooks/useSortTable'
import {
  fileUploadSignURL,
  getClientMissingItemList,
  getOrderReminderEntities,
  IOrderReminderItems,
  missingItemAnswer,
} from 'api/clientMissingItem'
import { PagedTable, usePagedTable } from '@jetkit/react'
import OrderItemRow from './table/row'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import OrderItemTableHead from './table/head'
import { skeletons } from 'components/common/table'
import showApiResponseError from 'utils/showApiResponseError'
import showErrorNotification from 'utils/showErrorNotification'
import axios from 'axios'
import BackupIcon from '@material-ui/icons/Backup'
import MissingItemCard from './missingItemCard'
import { Skeleton } from '@material-ui/lab'

const ClientMissingItems = () => {
  const classes = useStyles()
  const [companies, setCompanies] = useState<any>([])
  const [taxOrder, setTaxOrders] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  // const [isUpload, setisUpload] = React.useState<boolean>(false)

  // const [itemId, setItemId] = useState<any>()
  // const [isFileResponseUploading, setIsFileResponseUploading] = useState(false)
  // const [answerText, setAnswerText] = useState<string>('')
  // const [fileToUpload, setFileToUpload] = useState<File>()

  // const { sortSettings, handleSortingClick } = useSortTable({ order: 'asc', sort_by: '' })
  // const { order, sort_by } = sortSettings
  // const [loading, setloading] = React.useState<boolean>(false)

  // const memoApiCall = useMemo(() => getClientMissingItemList(1, 4)({ sort_order: order, sort_by }), [order, sort_by])

  // const pagedTable = usePagedTable<IOrderReminderItems>({
  //   apiCall: memoApiCall,
  // })

  // const tableHead = useMemo(
  //   () => <OrderItemTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
  //   [sortSettings, handleSortingClick]
  // )

  // const onDrop = useCallback(
  //   (files: File[]) => {
  //     console.log('files', files)

  //     if (!files.length) {
  //       console.error(`No file provided`)
  //     }

  //     const file = files[0]

  //     console.log('file', file)
  //     console.log('itemIditemIditemIditemIditemIditemIditemIditemIditemIditemId:', itemId)
  //     setFileToUpload(file)
  //     // handleFileUpload(file)
  //     // setAnswerText(answerText)
  //   },
  //   [itemId, answerText]
  // )

  // const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true })

  // console.log('answerTextanswerTextanswerText', answerText)
  // const submitAnswer = useCallback(async () => {
  //   try {
  //     setloading(true)
  //     const response = await missingItemAnswer(itemId, answerText)
  //     console.log(response)
  //     showSuccessNotification('Submitted Successfully')
  //     if (fileToUpload) await handleFileUpload(fileToUpload)
  //     setloading(false)
  //     setisUpload(false)
  //     setFileToUpload(undefined)
  //     setAnswerText('')
  //     pagedTable.reloadData()
  //   } catch (error) {
  //     showErrorNotification('Error submitting Answer')
  //     setloading(false)
  //   }
  // }, [itemId, answerText, fileToUpload])
  // const handleFileUpload = async (file: File) => {
  //   // Handle the uploaded file
  //   console.log('Uploaded file:', file)

  //   console.log('itemIditemIditemIditemId:', itemId)
  //   console.log('answerTextanswerTextanswerText itemId', answerText)
  //   debugger
  //   // if (!answerText?.length) {
  //   //   return
  //   // }

  //   try {
  //     setIsFileResponseUploading(true)

  //     // const response = await missingItemAnswer(itemId, 'answerTextanswerTextanswerText')

  //     // console.log('responseresponseresponse:', response)

  //     const fileUpload = await fileUploadSignURL(itemId, file?.name)

  //     const uploadS3 = await axios({
  //       url: fileUpload.url,
  //       method: 'PUT',
  //       headers: fileUpload?.headers,
  //       data: file,
  //     })
  //     console.log('uploadS3', uploadS3)

  //     if (uploadS3?.status === 200) {
  //       showSuccessNotification('File successfully upload')
  //       setAnswerText('')
  //     }

  //     setisUpload(false)
  //     // const confirmFile = await confirmFileUpload(fileUpload?.docId)
  //     // console.log('confirmFile', confirmFile)
  //   } catch (error) {
  //     showErrorNotification(error ? (error as any)?.message : 'Something went wrong!')
  //     showApiResponseError(error as any, 'Something went wrong!')
  //   }
  //   setIsFileResponseUploading(false)
  // }

  const getOrderReminEntities = async () => {
    setLoading(true)
    try {
      const result = await getOrderReminderEntities()
      setCompanies(result?.companies)
      setTaxOrders(result?.tax_orders)
    } catch (error) {
      console.log(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getOrderReminEntities()
  }, [])

  return (
    <div className={classes.clientCompanies}>
      <h1 className="main-heading">Missing Items</h1>

      <Divider />
      <br />
      <div className="companies-header">
        <HeadingHeader title="Companies" />
      </div>
      <Grid container spacing={2}>
        {loading ? (
          [0, 1, 2].map((elem, index) => {
            return (
              <Grid key={index} item sm={12} md={6} lg={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            )
          })
        ) : companies?.length ? (
          companies?.map((element, index) => {
            return (
              <Grid key={index} item sm={12} md={6} lg={4}>
                <MissingItemCard
                  count={element?.item_count}
                  isCompany={true}
                  id={element?.id}
                  stateName={element?.state_display_name}
                  companyName={element?.name}
                />
              </Grid>
            )
          })
        ) : (
          <Grid item sm={12} md={6} lg={4}>
            <p>No missing items found</p>
          </Grid>
        )}
      </Grid>
      <div className="companies-header">
        <HeadingHeader title="Tax Orders" />
      </div>
      <Grid container spacing={2}>
        {loading ? (
          [0, 1, 2].map((elem, index) => {
            return (
              <Grid key={index} item sm={12} md={6} lg={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            )
          })
        ) : taxOrder?.length ? (
          taxOrder?.map((element, index) => {
            return (
              <Grid item lg={4} md={6} sm={12}>
                <MissingItemCard
                  stateName={
                    element?.company_name?.length
                      ? element?.company_name
                      : element?.client_name?.length
                      ? element?.client_name
                      : ''
                  }
                  count={element?.item_count}
                  isCompany={false}
                  id={element?.id}
                  companyName={
                    `${element?.order_type === 1 ? 'Personal' : element?.order_type === 2 ? 'Business' : ''} ${
                      element?.service_type
                    } ORDER`
                    // element?.order_type === 1
                    //   ? 'Personal'
                    //   : element?.order_type === 2
                    //   ? 'Business'
                    //   : ''
                  }
                />
              </Grid>
            )
          })
        ) : (
          <Grid item sm={12} md={6} lg={4}>
            <p>No missing items found</p>
          </Grid>
        )}
      </Grid>

      {/* <div className="companies-header">
        <HeadingHeader title="Item List" />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableLoadingIndicator isLoading={pagedTable.isLoading} />
          {pagedTable.isLoading ? (
            <Table>
              {tableHead}
              <TableBody>
                {skeletons({ numberOfColumns: 5, sizes: [200, 100, 100, 100, 100], numberOfRows: 10 })}
              </TableBody>
            </Table>
          ) : (
            <PagedTable
              colSpan={8}
              emptyRowText="No Missing Items"
              {...pagedTable.renderProps}
              renderRow={(orderItem: IOrderReminderItems) => (
                <OrderItemRow orderItem={orderItem} setisUpload={setisUpload} setItemId={setItemId} />
              )}
              header={tableHead}
            />
          )}
        </Grid>
      </Grid> */}

      {/* <Dialog
        maxWidth="xs"
        fullWidth
        open={isUpload}
        onClose={() => setisUpload(false)}
        disableBackdropClick={isFileResponseUploading ? true : false}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ marginLeft: '1.5rem' }}>Upload Items</h3>
          <ModalHeader id="" onClose={() => setisUpload(false)} />
        </div>
        <DialogContent>
          <TextField
            multiline
            rows={2}
            label="Answer"
            variant="outlined"
            fullWidth
            focused
            placeholder="Explain what are you uploading."
            name="answerText"
            value={answerText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAnswerText(event.target.value as string)}
          />
          <div style={{ display: 'flex', marginTop: '5%', color: 'gray' }}>
            <span> {fileToUpload?.name}</span>
            <BackupIcon color="primary" />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              aria-label="Upload"
              onClick={open}
              size="large"
              disabled={isFileResponseUploading ? true : false || !answerText?.length ? true : false}
            >
              Upload
            </Button>
          </div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              aria-label="Upload"
              onClick={submitAnswer}
              size="large"
              disabled={loading}
            >
              Submit
            </Button>
            {loading && (
              <div className={classes.rootProgress}>
                <LinearProgress />
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog> */}
    </div>
  )
}

export default ClientMissingItems
