import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { green, blue, red } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '50px 50px 5px 50px',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',

        '&:not(last-child)': {
          marginBottom: '0.5rem',
        },
      },
      '& h1': {
        margin: '0',
      },
      '& .title-text': {
        color: '#020202',
        fontSize: '1.25rem',
        fontFamily: 'Lato',
        fontWeight: 600,
      },
    },
    tableSection: {
      padding: '0 50px',
      // display: 'flex',
    },

    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    formControl: {
      minWidth: '120px',
      // minWidth: '100%',

      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    datePickerFiled: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1.5rem',

      '& span': {
        margin: '0 0.5rem',
        fontWeight: 600,
      },
    },
    stateOfFormationField: {
      // marginLeft: '1.5rem',
    },
    '& .title-text': {
      color: '#020202',
      fontSize: '1.25rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    // SummaryContainer: {
    //   display: 'flex',
    //   margin: '1rem 0',

    // },
    titleText: {
      color: '#020202',
      fontSize: '1.25rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    cardContainer: {},
    cardHeaderInner: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },
    blue: {
      color: '#fff',
      backgroundColor: blue[500],
    },
    red: {
      color: '#fff',
      backgroundColor: red[500],
    },
    searchBar: {
      marginBottom: '1rem',
    },
    cardHeading: {
      margin: '0 20px',
      fontSize: '20px',
      color: '#0000009e',
    },
  })
)
