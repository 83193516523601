import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openScheduleMeetingButton: {
      backgroundColor: '#3c9fdf',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 0,
      position: 'absolute',
      right: 0,
      padding: '0.5rem',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#3c9fdf',
      },
    },
  })
)
interface IOpenScheduleMeetingButtonProps {
  onClick: () => void
}

const OpenScheduleMeetingButton: React.FC<IOpenScheduleMeetingButtonProps> = ({ onClick }) => {
  const classes = useStyles()
  return (
    <IconButton
      disableRipple
      onClick={onClick}
      className={classes.openScheduleMeetingButton}
      data-testid="toggle-notes-button"
    >
      <PeopleAltOutlinedIcon fontSize="default" />
    </IconButton>
  )
}

export default OpenScheduleMeetingButton
