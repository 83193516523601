import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient, ICreatedEmployee, IEmployee } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'
import { USERTYPES, USER_TYPES, USER_TYPES_MAPPING } from 'utils/constant'
//import data1 from '../mock/employeeData'
//import date1  from '../mock/employeeData'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (emp: IEmployee): Promise<ICreatedEmployee> => {
  delete emp.extid // delete the extid, the backend should take care of it

  // apiClient.defaults.baseURL = 'http://192.168.0.216:3000'

  // emp.user_type = emp.employee_type !== 'Accountant' ? '1' : '4'
  emp.user_type = emp.employee_type ? USER_TYPES_MAPPING[emp.employee_type] : USERTYPES?.employee
  emp.password = emp.password === '' || emp.password?.length === 0 ? 'password' : emp.password
  //
  return (await apiClient.post('user', snakeCaseKeys(emp))).data
  //return (await apiClient.get('users')).data
}

export const updateClient = async (emp: any): Promise<ICreatedEmployee> => {
  // emp.employee_type = emp.employee_type !== 'Accountant' ? '1' : '4'
  emp.user_type = emp.employee_type ? USER_TYPES_MAPPING[emp.employee_type] : USERTYPES?.employee
  return (await apiClient.post(`employee/${emp.id}`, snakeCaseKeys(emp))).data
}

//update Active status
export const updateEmployeeStatus = async (id: string, emp: any): Promise<any> => {
  return (await apiClient.post(`employee/status/${id}`, snakeCaseKeys(emp))).data
}
export const fetchClient = async (id: number): Promise<ICreatedEmployee> => {
  const res = (await apiClient.get(`employees/${id}`)).data
  return res
}

export const fetchlinkedwidgets = async (id: any): Promise<ICreatedEmployee> => {
  const res = (await apiClient.get(`employees/${id}/widgets`)).data
  return res
}
export const getwidgets = async (query = ''): Promise<ICreatedClient[]> => {
  return (await apiClient.get(`widgets`, { params: { page: 1, page_size: 100, query, sort_order: 'desc' } })).data
}
// export const fetchClients = async (query = ''): Promise<ICreatedClient[]> =>
//   (await apiClient.get(`user`, { params: { user_type: 'client', query } })).data
//added by ay
export const fetchClients = async (query = '') => {
  //(await apiClient.get(`user`, { params: { user_type: 'client', query } })).data
  //return data1;
}
//export const paginateClients = () => requestPaginated<ICreatedClient[]>({ url: '/user/client' })
//export const paginateClients = () => {return data1}
export const paginateClients = () => {
  return requestPaginated<any>({ url: '/employees' })
  // const data = apiClient.get(`/user/employee/1/12/ `);
  // console.log('dataaaa', data);

  // return data
}

// export const searchClients = async (query = ''): Promise<IClient[]> =>
//   (await apiClient.get(`search/client`, { params: { query } })).data

export const getClientAddresses = async (id: number): Promise<IAddress[]> =>
  (await apiClient.get(`user/${id}/address`)).data

interface IClientAssetParams {
  clientId: number
  documentId: number
}

export const uploadEmpImage = async (empid: number | undefined, file_name: File): Promise<any> => {
  const url = (await apiClient.post(`user/${empid}/upload-asset`, { file_name: file_name.name })).data
  return await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data', //'multipart/form-data',
    },
    body: file_name,
  })
}
export const getEmpImage = async (empid: number | undefined): Promise<any> => {
  const url = (await apiClient.get(`employee/${empid}/image`)).data
  return url
}
export const getClientDocuments = (clientId: number) => requestPaginated<IAsset[]>({ url: `user/${clientId}/asset` })

export const deleteClientDocument = async ({ clientId, documentId }: IClientAssetParams): Promise<void> => {
  await apiClient.delete(`user/${clientId}/asset/${documentId}`)
}

export const downloadClientDocumentURL = ({ clientId, documentId }: IClientAssetParams): string => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  return `${BASE_URL}/user/${clientId}/asset/${documentId}/download?jwt=${token}`
}
