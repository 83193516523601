import { apiClient, IPaginatedRequest, IPaginatedResponse } from '@jetkit/react'
import { deleteClientDocument, downloadClientDocumentURL, getClientDocuments, getClientTaxDocuments } from 'api/clients'
import { deleteCompanyDocument, downloadCompanyDocumentURL, getCompanyDocuments } from 'api/companies'
import { deleteCompanyOrderDocument, downloadCompanyOrderDocumentURL, getCompanyOrderDocuments } from 'api/orders'
import {
  deleteTaxDocument,
  downloadBOQuestionURL,
  downloadQuestionsURL,
  downloadTaxDocumentURL,
  getTaxDocuments,
} from 'api/taxOrders'
import axios, { AxiosRequestConfig } from 'axios'
import { IDocumentUploadProps } from 'components/common/assets/documentUploads'
import { ITaxOrderType } from 'models/taxOrder'
import showErrorNotification from 'utils/showErrorNotification'
import { AnyObjectSchema } from 'yup'

export interface IAsset {
  readonly id: number

  readonly company_id?: number
  readonly client_id?: number
  readonly order_id?: number
  readonly taxorder_id?: number
  readonly question_id?: number

  filename: string
  created_at: string
}

export type AssetType = ITaxOrderType | 'company_order' | 'tax_order' | 'questions' | 'bo_question' | 'bo_answer'

export interface UploadResponse {
  url: string
  headers: object
  docId?: number
}

export abstract class UploadRequest implements UploadFileToS3Args {
  public file: File
  public objectId: number
  public type: AssetType
  public docType?: any
  public notes?: any
  public visibleOnClient?: any
  public docLabel?: string
  public isRADoc?: any
  public tax_order_id?: number
  public isAddressDoc?: boolean
  protected abstract getPresignedUploadRequest(
    objectId: number,
    file: File,
    docType?: any,
    notes?: any,
    visibleOnClient?: any,
    docLabel?: string,
    isRADoc?: any,
    tax_order_id?: number,
    isAddressDoc?: boolean
  ): Promise<UploadResponse>

  public constructor(type: AssetType, objectId: number, file: File, tax_order_id?: number) {
    this.type = type
    this.file = file
    this.objectId = objectId
    this.tax_order_id = tax_order_id
  }

  public async uploadFileToS3({ onProgress }: UploadFileToS3Args) {
    debugger

    const { objectId, file, docType, notes, visibleOnClient, docLabel, isRADoc, tax_order_id, isAddressDoc } = this
    const uploadResponse = await this.getPresignedUploadRequest(
      objectId,
      file,
      docType,
      notes,
      visibleOnClient,
      docLabel,
      isRADoc,
      tax_order_id,
      isAddressDoc
    )
    //
    const options: AxiosRequestConfig = {
      headers: uploadResponse.headers,
      onUploadProgress: onProgress,
    }
    console.log(uploadResponse.docId)
    // return await fetch(uploadResponse.url, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   },
    //   body: file
    // })
    // return axios.put(uploadResponse.url, file, options)

    try {
      const upload = await axios({
        url: uploadResponse.url,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: file,
      })
      return upload
    } catch (error) {
      showErrorNotification('Error Uploading File.')
      // delete file record if upload fails.
      await deleteCompanyDocument({ companyId: 0, documentId: uploadResponse.docId || 0 })
    }
  }
}

export class CompanyAssetUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(
    companyId: number,
    file: File,
    docType: any,
    notes: any,
    visibleOnClient: boolean,
    docLabel: string,
    isRADoc: boolean,
    tax_order_id: number,
    isAddressDoc
  ): Promise<UploadResponse> {
    // console.log('datatypr', docType, notes)
    debugger
    return (
      await apiClient.post(`company/${companyId}/upload-asset`, {
        mime_type: docType,
        file_name: file.name,
        notes: notes,
        visibleOnClient: visibleOnClient,
        doc_label: docLabel,
        registered_agent_document: isRADoc,
        address_document: isAddressDoc,
      })
    ).data
  }
}

export class TaxAssetUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(
    taxId: number,
    file: File,
    docType: any,
    notes: any,
    visibleOnClient: boolean,
    docLabel: string,
    isRADoc: boolean
  ): Promise<UploadResponse> {
    // console.log('datatypr', docType, notes)

    return (
      await apiClient.post(`taxorder/${taxId}/upload-asset`, {
        mime_type: docType,
        file_name: file.name,
        notes: notes,
        visibleOnClient: visibleOnClient,
        doc_label: docLabel,
        registered_agent_document: isRADoc,
      })
    ).data
  }
}
export class BOQuestionUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(
    questionID: number,
    file: File,
    docType: any,
    notes: any,
    visibleOnClient: boolean,
    docLabel: string,
    isRADoc: boolean,
    tax_order_id: number
  ): Promise<UploadResponse> {
    return (
      await apiClient.post(`/bouploads`, {
        taxorder_id: tax_order_id,
        question_id: questionID,
        file_name: file.name,
        is_question: true,
      })
    ).data
  }
}

export class BOAnswerUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(
    questionID: number,
    file: File,
    docType: any,
    notes: any,
    visibleOnClient: boolean,
    docLabel: string,
    isRADoc: boolean,
    tax_order_id: number
  ): Promise<UploadResponse> {
    return (
      await apiClient.post(`/bouploads`, {
        taxorder_id: tax_order_id,
        question_id: questionID,
        file_name: file.name,
        is_question: false,
      })
    ).data
  }
}

export class QuestionsUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(questionId: number, file: File, docType: any): Promise<UploadResponse> {
    console.log('datatype', docType)
    console.log('file.name', file.name)
    debugger

    return (
      await apiClient.post(`question-attachments/${questionId}`, {
        mime_type: docType,
        file_name: file.name,
      })
    ).data
  }
}

export class EmployeeAssetUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(emp_id: number, file: File, docType: any, notes: any): Promise<UploadResponse> {
    return (
      await apiClient.post(`employee/${emp_id}/upload-asset`, {
        mime_type: file.type,
        file_name: file.name,
      })
    ).data
  }
}

export class ClientAssetUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(
    clientId: number,
    file: File,
    docType: any,
    notes: any,
    visibleOnClient?: any,
    docLabel?: string,
    isRADoc?: any,
    tax_order_id?: number,
    isAddressDoc?: boolean
  ): Promise<UploadResponse> {
    return (
      await apiClient.post(`client/${clientId}/upload-asset`, {
        mime_type: docType,
        file_name: file.name,
        notes: notes,
        visibleOnClient,
        docLabel,
        isRADoc,
        tax_order_id,
        isAddressDoc,
      })
    ).data
  }
}

export class CompanyOrderAssetUploadRequest extends UploadRequest {
  async getPresignedUploadRequest(
    orderId: number,
    file: File,
    docType: any,
    notes: any,
    visibleOnClient: boolean,
    docLabel: string,
    isRADoc: boolean,
    tax_order_id?: number,
    isAddressDoc?: boolean
  ): Promise<UploadResponse> {
    return (
      await apiClient.post(`order/${orderId}/upload-asset`, {
        mime_type: docType,
        file_name: file.name,
        notes: notes,
        visibleOnClient: visibleOnClient,
        doc_label: docLabel,
        registered_agent_document: isRADoc,
        address_document: isAddressDoc,
      })
    ).data
  }
}

export interface UploadFileToS3Args extends IDocumentUploadProps {
  file: File
  docType?: any
  notes?: any
  visibleOnClient?: any
  docLabel?: string
  onProgress?: (evt: ProgressEvent) => void
  isRADoc?: any
  tax_order_id?: number
  isAddressDoc?: boolean
}

export type UploadRequestClass = new (
  type: AssetType,
  objectId: number,
  file: File,
  docType?: any,
  notes?: any,
  visibleOnClient?: any,
  docLabel?: string,
  isRADoc?: any,
  tax_order_id?: number,
  isAddressDoc?: boolean
) => UploadRequest

export abstract class DocumentHandler {
  abstract getDocuments(
    objectId: number,
    archive?: boolean,
    taxId?: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>>

  abstract getDocumentDownloadURL(asset: IAsset): Promise<string | undefined>

  abstract deleteDocument(objectId: number, assetId: number): Promise<void>

  abstract UploadRequest: UploadRequestClass
}

export class CompanyDocumentHandler extends DocumentHandler {
  getDocuments(
    objectId: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    return getCompanyDocuments(objectId)
  }

  async getDocumentDownloadURL(asset: IAsset) {
    console.log(asset)

    if (!asset.company_id) return

    return await downloadCompanyDocumentURL({
      companyId: asset.company_id,
      documentId: asset.id,
    })
  }

  async deleteDocument(objectId: number, assetId: number) {
    await deleteCompanyDocument({ companyId: objectId, documentId: assetId })
  }

  UploadRequest = CompanyAssetUploadRequest
}

export class TaxDocumentHandler extends DocumentHandler {
  getDocuments(
    objectId: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    return getTaxDocuments(objectId)
  }

  async getDocumentDownloadURL(asset: IAsset) {
    console.log(asset)

    if (!asset.taxorder_id) return

    return await downloadTaxDocumentURL({
      taxId: asset.taxorder_id,
      documentId: asset.id,
    })
  }

  async deleteDocument(objectId: number, assetId: number) {
    await deleteTaxDocument({ taxId: objectId, documentId: assetId })
  }

  UploadRequest = TaxAssetUploadRequest
}

export class BOQuestionHandler extends DocumentHandler {
  getDocuments(
    objectId: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    return getTaxDocuments(objectId)
  }

  async getDocumentDownloadURL(asset: IAsset) {
    console.log(asset)

    if (!asset.taxorder_id) return

    return await downloadBOQuestionURL(asset.taxorder_id, asset?.id, false)
  }

  async deleteDocument(objectId: number, assetId: number) {
    await deleteTaxDocument({ taxId: objectId, documentId: assetId })
  }

  UploadRequest = BOQuestionUploadRequest
}

export class BOAnswerHandler extends DocumentHandler {
  getDocuments(
    objectId: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    return getTaxDocuments(objectId)
  }

  async getDocumentDownloadURL(asset: IAsset) {
    console.log(asset, 'ANSWERRRRRRRRRRRRRRRRRRRRRRRRRR')

    if (!asset.taxorder_id) return

    return await downloadBOQuestionURL(asset.taxorder_id, asset?.id, false)
  }

  async deleteDocument(objectId: number, assetId: number) {
    await deleteTaxDocument({ taxId: objectId, documentId: assetId })
  }

  UploadRequest = BOAnswerUploadRequest
}
export class QuestionHandler extends DocumentHandler {
  getDocuments(
    objectId: number
  ): (queryParams?: {} | undefined) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    throw new Error('Method not implemented.')
  }
  deleteDocument(objectId: number, assetId: number): Promise<void> {
    throw new Error('Method not implemented.')
  }

  // getDocuments(
  //   objectId: number
  // ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
  //   return getTaxDocuments(objectId)
  // }

  async getDocumentDownloadURL(asset: IAsset) {
    debugger

    console.log(asset)

    if (!asset.question_id) return

    return await downloadQuestionsURL({
      questionId: asset.question_id,
    })
  }

  // async deleteDocument(objectId: number, assetId: number) {
  //   await deleteTaxDocument({ taxId: objectId, documentId: assetId })
  // }

  UploadRequest = QuestionsUploadRequest
}

export class ClientDocumentHandler extends DocumentHandler {
  getDocuments(
    objectId: number,
    archive: boolean,
    taxId: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    // return getClientDocuments(objectId)
    return getClientTaxDocuments(objectId, archive, taxId)
  }

  async getDocumentDownloadURL(asset: IAsset) {
    if (!asset.client_id) return

    return await downloadClientDocumentURL({
      clientId: asset.client_id,
      documentId: asset.id,
    })
  }

  async deleteDocument(objectId: number, assetId: number) {
    await deleteClientDocument({ clientId: objectId, documentId: assetId })
  }

  UploadRequest = ClientAssetUploadRequest
}

export class CompanyOrderDocumentHandler extends DocumentHandler {
  getDocuments(
    objectId: number
  ): (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>> {
    return getCompanyOrderDocuments(objectId)
  }

  async getDocumentDownloadURL(asset: IAsset) {
    // if (!asset.order_id) return

    // return await downloadCompanyOrderDocumentURL({
    //   orderId: asset.order_id,
    //   documentId: asset.id,
    // })
    // console.log(asset);

    if (!asset.company_id) return

    return await downloadCompanyDocumentURL({
      companyId: asset.company_id,
      documentId: asset.id,
    })
  }

  async deleteDocument(objectId: number, assetId: number) {
    await deleteCompanyOrderDocument({ orderId: objectId, documentId: assetId })
  }

  UploadRequest = CompanyOrderAssetUploadRequest
}

export const assetTypeToDocumentHandler: Record<AssetType, typeof ClientDocumentHandler> = {
  business: CompanyDocumentHandler,
  personal: ClientDocumentHandler,
  company_order: CompanyOrderDocumentHandler,
  tax_order: TaxDocumentHandler,
  questions: QuestionHandler,
  bo_question: BOQuestionHandler,
  bo_answer: BOAnswerHandler,
}
