import * as React from 'react'
import { Base } from 'utils/FormGenerator/formTypings'
import fieldTypeToRenderFieldFunctionMapping from 'utils/FormGenerator/mapping'
import { isBaseField, isCustomComponent } from 'utils/FormGenerator/typeAssertionFunction'

export const FormField = <ValuesType,>(field: Base, values: ValuesType) => {
  if (isCustomComponent(field)) {
    return field.render()
  }

  if (!isBaseField(field)) {
    throw new Error('type error')
  }

  const Field = fieldTypeToRenderFieldFunctionMapping[field.type]
  return <Field field={field} value={getValueByComplexKey<ValuesType>(values, field.name)} />
}

/**
 *
 *
 * @param values object with nested fields
 * @param fieldPath string that represents list of keys to value divided by dot
 * @example
 * // returns 5
 * getValueByComplexKey({
 *    company: {
 *       id: 5
 *    }
 * }, "company.id");
 */
function getValueByComplexKey<ValuesType>(values: ValuesType, fieldPath: string) {
  const keyList = fieldPath.split('.')

  let currentValue = values[keyList[0]]

  for (let i = 1; i < keyList.length; i++) {
    const key = keyList[i]
    currentValue = currentValue[key]
  }

  return currentValue
}
