import TasksProgress from 'pages/dashboard/tasksProgress'
import React from 'react'
import { useStyles } from './styles'

interface HeadingProgressProps {
  tableHeading: string
}

const HeadingProgress = ({ tableHeading }: HeadingProgressProps) => {
  const classes = useStyles()
  return (
    <div className={classes.tableHeader}>
      <h1 className="table-heading">{tableHeading}</h1>
      {tableHeading === 'Daily Task' && <TasksProgress className={classes.progressBar} />}
    </div>
  )
}

export default HeadingProgress
