import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import { legalTypesToHumanReadableFormat } from 'models/company'
import moment from 'moment'
import { NotificationType } from 'pages/notifications'
import * as React from 'react'
import { datePickerDateFormat } from 'utils/formatDate'
import { INotification } from '../notificationsDialog'

interface ICompanyNotificationRowProps {
  notificationType: NotificationType
  notification: INotification
  onEditClick: () => void
  onDeleteClick: () => void
}

const NotificationRow: React.FC<ICompanyNotificationRowProps> = ({
  notificationType,
  notification,
  onEditClick,
  onDeleteClick,
}) => {
  // Because we cannot show notification dates for all following years in this table row,
  // we just give user main info about further notifications:
  // if all following years are skipped, we show 'No Further Notifications Scheduled'
  // if on all following years notifications should be fired on company's file date, we show 'File Date'
  // if all other cases we show 'Custom Dates'. User will be able to see all these dates by clicking on
  // notification row or edit icon.
  // const nextDatesCellContent = React.useMemo((): string => {
  //   if (
  //     notification.dates.slice(1).filter(notification => !notification.date).length ===
  //     notification.dates.slice(1).length
  //   ) {
  //     return 'No Further Notifications Scheduled'
  //   }
  //   if (
  //     notification.dates.slice(1).filter(notification => notification.use_file_date).length ===
  //     notification.dates.slice(1).length
  //   ) {
  //     return 'File Date'
  //   }
  //   return 'Custom Dates'
  // }, [notification])

  const handleEditIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onEditClick()
    },
    [onEditClick]
  )

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )

  const companyTypeCheck = (type: number | string) => {
    switch (type) {
      case 1:
        return 'LLC'
      case 2:
        return 'Corporation'
      case 3:
        return 'S Corporation'
      case 4:
        return 'Non Profit'
      case 5:
        return 'DBA'

      default:
        break
    }
  }

  return (
    <TableRow onClick={onEditClick} style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      {/* <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}> */}
      <TableCell style={{ minWidth: 120 }}>{notification?.title}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{notification?.orderTypeName}</TableCell>
      {/* {notificationType === 'company' ? (
        <TableCell style={{ paddingRight: 5 }}>{notification.company && notification.company.name}</TableCell>
      ) : (
        <TableCell>{notification.company_type && legalTypesToHumanReadableFormat[notification.company_type]}</TableCell>
      )} */}
      {/* {notificationType === 'companyType' ? <TableCell>{notification.state_of_formation}</TableCell> : null} */}
      {/* <TableCell>
        {(notification.dates && moment(notification.dates[0].date).format(datePickerDateFormat)) || '- - -'}
      </TableCell> */}
      {/* <TableCell>{nextDatesCellContent}</TableCell> */}
      <TableCell>{companyTypeCheck(notification?.company_type || '')}</TableCell>
      <TableCell>{notification?.stateName}</TableCell>
      <TableCell>{notification?.first_year_schedule}</TableCell>
      <TableCell>{notification?.following_year_file_date}</TableCell>
      <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleEditIconClick}>
            <EditIcon data-testid="editButton" />
          </IconButton>
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default NotificationRow
