/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import OrderCard from './OrderCard'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import OrderCardContent from './OrderCardContent'
import { useParams } from 'react-router'
import {
  getWebSubmissionById,
  markOfflinePaidByID,
  WebSubmissionsData,
  markOfflineConversion,
  submission_types,
  getVisitorLogsByIP,
} from 'api/webSubmission'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { skeletons } from 'components/common/table'
import { stepMappings } from 'utils/stepmappings'
import Alert from '@material-ui/lab/Alert'
import {
  FILE_JOINTLY_TYPES,
  FORMS,
  OrderTypes,
  ORDER_TYPES_ROUTES,
  paymentMethodMappings,
  paymentStatusMappings,
  PAYMENT_STATUS,
  POST_STEPS,
  WEB_ORDER_TYPES,
  RESIDENT_MAPPING,
} from 'utils/constant'
import SubmissionHistory from 'components/leadManagerElements/editProspectById/components/submissionHistory'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { checkNullValue, checkPostStepLabel } from 'utils/functions'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import CopyTextButton from 'components/common/textFields/copyTextButton'
import copyToClipboard from 'utils/copyToClipboard'
import showErrorNotification from 'utils/showErrorNotification'
import LinearProgress from '@material-ui/core/LinearProgress'
import { GetApp, History, LaunchOutlined } from '@material-ui/icons'
import { AlertTitle } from '@material-ui/lab'
import { updatePageVisitsCount } from 'utils/user'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { IVisitorLogs } from 'api/visitorLog'
import { Base64 } from 'js-base64'
import { TextField } from 'formik-material-ui'
import BRTextField from 'components/common/textFields/BRTextField'

interface IWebSubmissionProps {
  submission_id?: number
}

const WebSubmissionDetails: React.FC<IWebSubmissionProps> = ({ submission_id }) => {
  const { id } = useParams<any>()
  const [webSubmissionData, setWebSubmissionData] = useState<WebSubmissionsData>()
  const [isLoading, setIsLoading] = useState(false)
  const [historyOpen, setHistoryOpen] = useState(false)
  const router = useHistory()
  const [visitorLogs, setVisitorLogs] = useState<IVisitorLogs[]>([])

  const [isPaidOfflineLoading, setIsPaidOfflineLoading] = useState(false)
  const [conversionDialogOpen, setConversionDialogOpen] = useState(false)
  const [conversionType, setConversionConversionType] = useState<submission_types>('com')

  const handleConversionDialog = () => {
    // setConversionDialogOpen(true)
  }

  // const handleClose = () => {
  //   setConversionDialogOpen(false);
  // };
  const getWebSubmissionData = async (id: number) => {
    setIsLoading(true)
    try {
      const data = await getWebSubmissionById(id)
      setWebSubmissionData(data)
      setIsLoading(false)
    } catch (error) {
      console.log('error')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (submission_id) {
      getWebSubmissionData(submission_id)
    } else if (id && !submission_id) {
      getWebSubmissionData(id)
    }
  }, [id, submission_id])

  const classes = useStyles()
  // const postPaymentLink = `${webSubmissionData?.web_url}post-payment-${
  //   webSubmissionData?.order_type === 0 ? 'corp' : 'llc'
  // }/step1/?token=${webSubmissionData?.token}`

  const postPaymentLink = React.useMemo(
    () =>
      `${webSubmissionData?.web_url}post-payment-${
        ORDER_TYPES_ROUTES[webSubmissionData?.order_type || '0']
      }/step1/?token=${webSubmissionData?.token}`,
    [webSubmissionData?.order_type, webSubmissionData?.web_url, webSubmissionData?.token]
  )

  const iPostal1Link = React.useMemo(() => `${webSubmissionData?.ipostal_link}/?token=${webSubmissionData?.token}`, [
    webSubmissionData?.ipostal_link,
    webSubmissionData?.token,
  ])

  const hotjarObj = React.useMemo(() => {
    const decoder = Base64.decode(webSubmissionData?.hotjar_id || '')
    if (decoder) {
      return JSON.parse(decoder)
    }
  }, [webSubmissionData?.hotjar_id])

  const googleAnalyticsObj = React.useMemo(() => {
    const id = webSubmissionData?.google_analytics_id?.split('.')
    const sliceId = id?.slice(-2)
    const stringId = sliceId?.toString()?.replace(',', '.')
    return stringId
  }, [webSubmissionData?.google_analytics_id])

  // `${webSubmissionData?.web_url}post-payment-${
  //   ORDER_TYPES_ROUTES[webSubmissionData?.order_type]
  // }/step1/?token=${webSubmissionData?.token}`

  const package_fee = webSubmissionData?.package_fee || 0
  const register_state_fee = webSubmissionData?.register_state_fee || 0
  const addon_fee = webSubmissionData?.addons?.length ? 49 : 0
  const discount_price = webSubmissionData?.discounted_total || 0

  const count = package_fee + register_state_fee + addon_fee

  const bankOfferTaken = action => {
    if (action === 0) {
      return 'No'
    } else if (action === 1) {
      return 'Yes'
    } else return 'TBD'
  }

  const handleMarkAsPaid = async (id: number) => {
    if (!id) return

    setIsPaidOfflineLoading(true)
    try {
      await markOfflinePaidByID(id)
      setIsPaidOfflineLoading(false)
      showSuccessNotification('Successfully paid offline')
      if (submission_id) {
        getWebSubmissionData(submission_id)
      } else if (id && !submission_id) {
        getWebSubmissionData(id)
      }
    } catch (error) {
      showApiResponseError(error, 'Getting error')
    }
    setIsPaidOfflineLoading(false)
  }

  const handleInvoiceLink = (link: string) => {
    window.open(link, '_blank')
  }

  const promoDiscount = package_fee - register_state_fee - addon_fee - discount_price

  const checkingEIN = () => {
    const parseTotal = parseInt(webSubmissionData?.post_payment_amount || '')
    if (parseTotal === 98) {
      return true
    } else if (parseTotal === 49 && !webSubmissionData?.tax_upgrade) {
      return true
    } else {
      return false
    }
  }
  const [isComLoading, setIsComLoading] = useState(false)
  const [isNetLoading, setIsNetLoading] = useState(false)
  const [isConversionLoading, setIsConversionLoading] = useState<boolean>()

  const markSubmissionAsOfflineConversion = async (type: submission_types, gclid: string) => {
    try {
      // if (type === 'com') setIsComLoading(true)
      // else setIsNetLoading(true)
      setIsConversionLoading(true)
      const res = await markOfflineConversion({ submission_type: type, gclid }, webSubmissionData?.order_id || 0)
      res.success
        ? showSuccessNotification('Conversion Marked Successfully')
        : showErrorNotification('Error Updating Submission')
      // setIsComLoading(false)
      // setIsNetLoading(false)
      setIsConversionLoading(false)
    } catch (error) {
      showApiResponseError(error, 'Error Updating Submission')
      setIsComLoading(false)
      setIsNetLoading(false)
    }
  }

  // const totalSum = () => {
  //   let t = 0
  //   webSubmissionData?.addons?.map(item => item?.addon_id !== 28 && (t = t + item?.addon_price))
  //   return t
  // }

  // const postAmount = totalSum()
  const orderTypeConversion = React.useMemo(() => {
    if (webSubmissionData) return ORDER_TYPES_ROUTES[webSubmissionData?.order_type]
    else return ''
  }, [webSubmissionData?.order_type])

  const iPostal1 = React.useMemo(() => webSubmissionData?.addons?.some(item => item?.addon_id === 34), [
    webSubmissionData?.addons,
  ])

  const getLogs = async (ip: string) => {
    try {
      const res = await getVisitorLogsByIP(ip)
      setVisitorLogs(res)
    } catch (error) {
      showApiResponseError(error, 'Error Getting Logs')
    }
  }
  React.useEffect(() => {
    if (webSubmissionData?.submission_ip) {
      getLogs(webSubmissionData?.submission_ip)
    }
  }, [webSubmissionData?.submission_ip])
  console.log('visitorLogs', visitorLogs)

  return (
    <>
      <div className={classes.container}>
        <div className="inline-header">
          <h1>
            Order: #{webSubmissionData?.order_id}
            &nbsp;{' '}
            <span style={{ fontSize: '1.25rem' }}>
              {OrderTypes[webSubmissionData?.order_type !== undefined ? webSubmissionData?.order_type : '']}
            </span>
          </h1>
          {webSubmissionData?.payment_status && (
            <Alert
              severity={
                webSubmissionData?.payment_status === '1' || webSubmissionData?.payment_status === '3'
                  ? 'success'
                  : 'error'
              }
            >
              <b>{paymentStatusMappings[webSubmissionData?.payment_status || '0']} </b>
            </Alert>
          )}
          {webSubmissionData?.sales_rep?.id && (
            <Alert severity="info" style={{ margin: '0 1rem', background: 'rgba(145, 133, 225, 0.2)' }}>
              <b>Wholesale Order</b>
            </Alert>
          )}
        </div>

        <Card className={classes.cardContainer}>
          <div className={classes.cardHeader}>
            <div className={classes.cardHeaderInner}>
              {/* <h2 className={classes.cardHeading}>View Order</h2> */}
              <ButtonWithLoading
                style={{ margin: '0 15px 0 0' }}
                className={classes.viewBtn}
                // onClick={() => markSubmissionAsOfflineConversion('net')}
                isLoading={isNetLoading}
                onClick={() => {
                  setConversionDialogOpen(true)
                  setConversionConversionType('net')
                }}
              >
                .NET Conversion
              </ButtonWithLoading>
              <ButtonWithLoading
                style={{ margin: '0 15px 0 0' }}
                isLoading={isComLoading}
                className={classes.viewBtn}
                // onClick={() => markSubmissionAsOfflineConversion('com')}
                onClick={() => {
                  setConversionDialogOpen(true)
                  setConversionConversionType('com')
                }}
              >
                .COM Conversion
              </ButtonWithLoading>

              {/* {webSubmissionData?.payment_status && (
              <Alert
                severity={
                  webSubmissionData?.payment_status === '1' || webSubmissionData?.payment_status === '3'
                    ? 'success'
                    : 'error'
                }
              >
                <b>{paymentStatusMappings[webSubmissionData?.payment_status || '0']} </b>
              </Alert>
            )} */}
              {webSubmissionData?.order_id && (
                //    <ButtonWithLoading
                //    style={{ marginLeft: '1rem' }}
                //    onClick={() => setHistoryOpen(true)}
                //    kind={'BRSecondary'}
                //  >
                //    Submission History
                //  </ButtonWithLoading>
                <Fab title="Submission History" onClick={() => setHistoryOpen(true)}>
                  <History />
                </Fab>
              )}
              <SubmissionHistory
                open={historyOpen}
                setOpen={setHistoryOpen}
                submission_id={checkNullValue(webSubmissionData?.order_id)}
              />
              {webSubmissionData?.payment_status !== '3' && webSubmissionData?.payment_status !== '1' ? (
                <ButtonWithLoading
                  style={{ marginLeft: '0.5rem' }}
                  isLoading={isPaidOfflineLoading}
                  onClick={() => handleMarkAsPaid(id)}
                >
                  Mark Offline Paid
                </ButtonWithLoading>
              ) : (
                ''
              )}
              <ButtonWithLoading
                style={{ marginLeft: '0.5rem' }}
                // isLoading={isPaidOfflineLoading}
                onClick={() =>
                  router.push({
                    pathname: '/visitor-logs',
                    state: { brid: webSubmissionData?.brid, ip_address: webSubmissionData?.ip_address },
                  })
                }
              >
                View Visitor Logs
              </ButtonWithLoading>
            </div>

            <div className={classes.cardHeaderInner}>
              {/* <Button
              className={classes.paymentBtn}
              onClick={() => {
                window.open(postPaymentLink, '_blank')
              }}
            >
              PostForm Link
            </Button> */}
              {/* <IconButton
              onClick={() => {
                window.open(postPaymentLink, '_blank')
              }}
            >
              <LaunchOutlined />
            </IconButton>
            <CopyTextButton onClick={() => copyToClipboard(postPaymentLink)} /> */}

              <h2 className={classes.cardHeading}>Step: {stepMappings(webSubmissionData?.next_order_step)}</h2>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <OrderCard title="Basic Info">
                    <OrderCardContent
                      title="First Name:"
                      value={checkNullValue(webSubmissionData?.first_name)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="Middle Name:"
                      value={checkNullValue(webSubmissionData?.middle_name)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="Last Name:"
                      value={checkNullValue(webSubmissionData?.last_name)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="Phone:"
                      value={checkNullValue(webSubmissionData?.phone)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="Email:"
                      value={checkNullValue(webSubmissionData?.email)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="Resident:"
                      value={checkNullValue(RESIDENT_MAPPING[webSubmissionData?.resident || ''] || '')}
                      isLoading={isLoading}
                    />
                  </OrderCard>
                </Grid>
                <Grid item xs={12} md={7}>
                  <OrderCard title="Company Info">
                    <OrderCardContent
                      title="Company Name:"
                      value={checkNullValue(webSubmissionData?.company_name)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="State of Formation:"
                      value={checkNullValue(webSubmissionData?.register_state_name)}
                      isLoading={isLoading}
                    />
                    <OrderCardContent
                      title="Company Address:"
                      value={checkNullValue(webSubmissionData?.company_address)}
                      isLoading={isLoading}
                    />

                    <OrderCardContent
                      title="City:"
                      value={checkNullValue(webSubmissionData?.company_city)}
                      isLoading={isLoading}
                    />

                    <OrderCardContent
                      title="State:"
                      value={checkNullValue(webSubmissionData?.company_state)}
                      isLoading={isLoading}
                    />

                    <OrderCardContent
                      title="Zip Code:"
                      value={checkNullValue(webSubmissionData?.company_zip_code)}
                      isLoading={isLoading}
                    />

                    <OrderCardContent
                      title="Business Activity:"
                      value={checkNullValue(webSubmissionData?.company_description)}
                      isLoading={isLoading}
                    />

                    <OrderCardContent title="Order Type:" value={orderTypeConversion} isLoading={isLoading} />
                  </OrderCard>
                </Grid>
                {webSubmissionData?.sales_rep?.id ? (
                  <Grid item xs={12}>
                    <OrderCard title="Sales Rep Info" isSalesRep>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <OrderCardContent
                            title="First Name:"
                            value={checkNullValue(webSubmissionData?.sales_rep?.first_name)}
                            isLoading={isLoading}
                          />
                          <OrderCardContent
                            title="Last Name:"
                            value={checkNullValue(webSubmissionData?.sales_rep?.last_name)}
                            isLoading={isLoading}
                          />
                          <OrderCardContent
                            title="Phone:"
                            value={checkNullValue(webSubmissionData?.sales_rep?.phone_1)}
                            isLoading={isLoading}
                          />
                          <OrderCardContent
                            title="Email:"
                            value={checkNullValue(webSubmissionData?.sales_rep?.email_1)}
                            isLoading={isLoading}
                          />
                          <OrderCardContent
                            title="Company Name:"
                            value={checkNullValue(webSubmissionData?.sales_rep?.company_name)}
                            isLoading={isLoading}
                          />
                        </Grid>
                      </Grid>
                    </OrderCard>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
              <OrderCard title="Company Ownership">
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Owner/ Company Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>% of Ownership</TableCell>
                        <TableCell>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        skeletons({ numberOfColumns: 3, sizes: [200, 120, 120, 120, 120], numberOfRows: 3 })
                      ) : webSubmissionData?.owners?.length ? (
                        webSubmissionData?.owners.map((row, index) => (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell component="th" scope="row">
                              {row?.owner_company_name ||
                                row.owner_first_name + ' ' + row.owner_middle_name + ' ' + row.owner_last_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.owner_address}, {row?.owner_city}, {row?.owner_state} {row?.owner_zip_code}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.owner_phone}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.ownership_percentage}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.roles?.length &&
                                row?.roles.map(item => (
                                  <>
                                    {item.title}
                                    <br />
                                  </>
                                ))}
                              {row?.owner_type}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <p>No Ownership</p>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <OrderCardContent
                  title="No. of Owners:"
                  value={checkNullValue(webSubmissionData?.company_ownership)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="File Jointly:"
                  value={FILE_JOINTLY_TYPES[webSubmissionData?.file_jointly || 0]}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="EIN registrar:"
                  value={checkNullValue(webSubmissionData?.ein_registrar)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="EIN registrar SSN:"
                  value={checkNullValue(webSubmissionData?.ein_registrar_ssn)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Registrar Agent:"
                  value={checkNullValue(webSubmissionData?.registered_agent_name)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Registered Agent Address:"
                  value={`${checkNullValue(webSubmissionData?.registered_agent_address)} ${checkNullValue(
                    webSubmissionData?.registered_agent_city
                  )} ${checkNullValue(webSubmissionData?.registered_agent_state)} ${checkNullValue(
                    webSubmissionData?.registered_agent_zipcode
                  )}`}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Registered Agent Phone:"
                  value={checkNullValue(webSubmissionData?.registered_agent_phone)}
                  isLoading={isLoading}
                />
              </OrderCard>
              <OrderCard title="Company Details">
                <OrderCardContent
                  title="No. of Shares:"
                  value={checkNullValue(webSubmissionData?.share_assign)}
                  isLoading={isLoading}
                />
              </OrderCard>

              {/* <OrderCard title="Payment Details">
              <OrderCardContent
                title="Payment ID:"
                value={checkNullValue(webSubmissionData?.payment_id)}
                isLoading={isLoading}
              />
              <OrderCardContent
                title="Payment Method:"
                value={paymentMethodMappings[checkNullValue(webSubmissionData?.payment_method) || '']}
                isLoading={isLoading}
              />
              <Button className={classes.viewBtn} onClick={() => handleInvoiceLink(webSubmissionData?.invoice || '')}>
                Download Invoice
              </Button>
            </OrderCard> */}
              <OrderCard title="Bank Offer">
                <OrderCardContent
                  title="Bank Offer:"
                  value={bankOfferTaken(webSubmissionData?.bank_offer)}
                  isLoading={isLoading}
                />{' '}
                <OrderCardContent
                  title="Active Bank Offer:"
                  value={checkNullValue(webSubmissionData?.active_bank_offer)}
                  isLoading={isLoading}
                />
              </OrderCard>

              <OrderCard title="Submission">
                <OrderCardContent
                  title="Date:"
                  value={formatDateTimeToHumanFriendly(webSubmissionData?.order_date || '')}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Ref Code:"
                  value={checkNullValue(webSubmissionData?.ref_code)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="UTM Source:"
                  value={checkNullValue(webSubmissionData?.utm_source)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="UTM Medium:"
                  value={checkNullValue(webSubmissionData?.utm_medium)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="UTM Campaign:"
                  value={checkNullValue(webSubmissionData?.utm_campaign)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="UTM Content:"
                  value={checkNullValue(webSubmissionData?.utm_content)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="GCLID:"
                  value={checkNullValue(webSubmissionData?.gclid)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="FBCLID:"
                  value={checkNullValue(webSubmissionData?.fbclid)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="MSCLKID:"
                  value={checkNullValue(webSubmissionData?.msclkid)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Referrer:"
                  value={checkNullValue(webSubmissionData?.referrer)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Old Order Status:"
                  value={checkNullValue(webSubmissionData?.order_status_old)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Token:"
                  value={checkNullValue(webSubmissionData?.token)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Form Version:"
                  value={checkNullValue(webSubmissionData?.form_version)}
                  isLoading={isLoading}
                />
                <OrderCardContent title="BRID:" value={checkNullValue(webSubmissionData?.brid)} isLoading={isLoading} />

                <OrderCardContent
                  title="IP Address:"
                  value={checkNullValue(webSubmissionData?.submission_ip)}
                  isLoading={isLoading}
                />
                <OrderCardContent title="HotJar ID:" value={hotjarObj?.id || ''} isLoading={isLoading} />
                <OrderCardContent title="Google Analytics ID:" value={googleAnalyticsObj || ''} isLoading={isLoading} />
                <OrderCardContent
                  title="User Agent:"
                  value={checkNullValue(webSubmissionData?.user_agent)}
                  isLoading={isLoading}
                />
              </OrderCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <OrderCard
                title={
                  <div className="inline-heading">
                    <span>Order Form</span>
                    <IconButton
                      title="Download Invoice"
                      onClick={() => handleInvoiceLink(webSubmissionData?.invoice || '')}
                    >
                      <GetApp />
                    </IconButton>
                  </div>
                }
              >
                <OrderCardContent
                  title="Package Name:"
                  value={checkNullValue(webSubmissionData?.package_name)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Package Fee:"
                  // value={`$${checkNullValue(webSubmissionData?.package_fee)}`}
                  value={`$${webSubmissionData?.package_fee ? webSubmissionData?.package_fee : 0}`}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="State Fee:"
                  value={`$${checkNullValue(webSubmissionData?.register_state_fee)}`}
                  isLoading={isLoading}
                />

                {/* {(webSubmissionData?.package === 0 || webSubmissionData?.package_name === 'Starter') &&
              webSubmissionData?.addons?.length ? (
                <>
                  <OrderCardContent title="ADDONS" value="" />
                  {webSubmissionData?.addons?.map(
                    item =>
                      item?.addon_id === 28 && (
                        <OrderCardContent title={`${item?.addon_name}:`} value={`$${item?.addon_price}`} />
                      )
                  )}
                </>
              ) : (
                ''
              )} */}
                {webSubmissionData?.addons?.length ? (
                  <>
                    <OrderCardContent title="ADDONS" value="" />
                    {webSubmissionData?.addons?.map(
                      item =>
                        item?.form === FORMS.main_form && (
                          <OrderCardContent
                            title={`${item?.addon_name}:`}
                            value={`$${item?.addon_price}`}
                            isPaid={item?.addon_id === 35 ? webSubmissionData?.rocket_compliance_subscription : ''}
                          />
                        )
                    )}
                  </>
                ) : (
                  ''
                )}

                {webSubmissionData?.tax_taken_from === 1 && webSubmissionData?.tax_price_id ? (
                  <OrderCardContent
                    title="Business Tax Plan Subscription:"
                    value={`${webSubmissionData?.tax_price_id ? '$' + webSubmissionData?.tax_subscription_amount : 0}`}
                    isLoading={isLoading}
                    isPaid={webSubmissionData?.tax_subscription}
                  />
                ) : (
                  ''
                )}

                {webSubmissionData?.professional_address &&
                  webSubmissionData.address_taken_from === FORMS.main_form && (
                    <OrderCardContent
                      title="Professional Address:"
                      value={`$${checkNullValue(webSubmissionData?.address_subscription_amount)}`}
                      isLoading={isLoading}
                      isPaid={webSubmissionData?.business_address_subscription}
                    />
                  )}
                {webSubmissionData?.business_rocket_address &&
                  webSubmissionData.address_taken_from === FORMS.main_form && (
                    <OrderCardContent
                      title="BR California Address:"
                      value={`$${checkNullValue(webSubmissionData?.address_subscription_amount)}`}
                      isLoading={isLoading}
                      isPaid={webSubmissionData?.business_address_subscription}
                    />
                  )}

                {webSubmissionData?.promocode ? (
                  <>
                    <Divider className={classes.totalDivider} />
                    <OrderCardContent
                      title={`Promo Discount: `}
                      value={`$${webSubmissionData.promoDiscount?.toFixed(2)}` || 0}
                      isLoading={isLoading}
                    />
                    <p className={classes.promocodeText}>{checkNullValue(webSubmissionData?.promocode)}</p>
                  </>
                ) : (
                  ''
                )}
                <Divider className={classes.totalDivider} />
                <OrderCardContent
                  title="Total:"
                  value={`${
                    webSubmissionData?.payment_status === '1' || webSubmissionData?.payment_status === '3'
                      ? '$' + (webSubmissionData?.discounted_total || '')
                      : ''
                  }`}
                  isLoading={isLoading}
                />
                <Divider className={classes.totalDivider} />
                <OrderCardContent
                  title="Payment Status:"
                  value={paymentStatusMappings[webSubmissionData?.payment_status || '0']}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Payment Method:"
                  value={paymentMethodMappings[checkNullValue(webSubmissionData?.payment_method) || '']}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Payment ID:"
                  value={checkNullValue(webSubmissionData?.payment_id)}
                  isLoading={isLoading}
                />
                {/* {!webSubmissionData?.payment_id ? ( */}
                <OrderCardContent
                  title="Payment Error:"
                  value={checkNullValue(webSubmissionData?.payment_error)}
                  isLoading={isLoading}
                />
                {/* ) : (
                  ''
                )} */}

                {/* <div className={classes.invoiceBtn}>
               <Button className={classes.viewBtn} onClick={() => handleInvoiceLink(webSubmissionData?.invoice || '')}>
                  Download Invoice
                </Button> 
              </div>*/}
                {/* <OrderCardContent title="Total:" value={`$${count}`} isLoading={isLoading} /> */}
              </OrderCard>

              <OrderCard
                title={
                  <div className="inline-heading">
                    <span>Post Payment Form</span>
                    {webSubmissionData?.payment_status === '1' || webSubmissionData?.payment_status === '3' ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => {
                            window.open(postPaymentLink, '_blank')
                          }}
                          title="Open PostForm New Tab"
                        >
                          <LaunchOutlined />
                        </IconButton>
                        <CopyTextButton onClick={() => copyToClipboard(postPaymentLink)} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                }
                isComplete={
                  // checkPostStepLabel(webSubmissionData?.order_type || '', webSubmissionData?.post_step || '') ===
                  // 'Thank You'
                  webSubmissionData?.post_step === POST_STEPS.thank_you
                }
                isPending={
                  // (checkingEIN() ||
                  //   webSubmissionData?.tax_upgrade ||
                  //   webSubmissionData?.registered_agent_first_name === 'BusinessRocket, Inc' ||
                  //   webSubmissionData?.registered_agent_name === 'BusinessRocket, Inc') &&
                  // !webSubmissionData?.post_payment_id
                  webSubmissionData?.addons.length &&
                  webSubmissionData?.addons?.some(item => item?.form === FORMS.post_form) &&
                  webSubmissionData?.post_step !== POST_STEPS.thank_you
                }
              >
                {/* {webSubmissionData?.addons?.length ? (
                <>
                  <OrderCardContent title="Addons:" value="" />
                  {webSubmissionData?.addons?.map(item => (
                    <OrderCardContent title={`${item?.addon_name}:`} value={`$${item?.addon_price}`} />
                  ))}
                </>
              ) : (
                ''
              )} */}

                {/* {webSubmissionData?.addons?.length && <OrderCardContent title="ADDONS" value="" />}

              {(webSubmissionData?.package === 0 || webSubmissionData?.package_name === 'Starter') &&
              webSubmissionData?.addons?.length ? (
                <>
                  {webSubmissionData?.addons?.map(
                    item =>
                      item?.addon_id === 28 && (
                        <OrderCardContent title={`${item?.addon_name}:`} value={`$${item?.addon_price}`} />
                      )
                  )}
                </>
              ) : (
                ''
              )}
              {webSubmissionData?.addons?.length ? (
                <>
                  {webSubmissionData?.addons?.map(
                    item =>
                      item?.addon_id !== 28 && (
                        <OrderCardContent title={`${item?.addon_name}:`} value={`$${item?.addon_price}`} />
                      )
                  )}
                </>
              ) : (
                ''
              )} */}

                {webSubmissionData?.addons?.length ? (
                  <>
                    <OrderCardContent title="ADDONS" value="" />
                    {webSubmissionData?.addons?.map(item => {
                      if (item?.form === FORMS.post_form) {
                        if (item?.addon_id === 30 && webSubmissionData?.package_name?.toLowerCase() !== 'starter') {
                          return (
                            <OrderCardContent
                              title={`${item?.addon_name}:`}
                              value={`$0`}
                              isPaid={webSubmissionData?.ra_subscription}
                            />
                          )
                        } else {
                          return (
                            <OrderCardContent
                              title={`${item?.addon_name}:`}
                              value={`$${item?.addon_price}`}
                              isPaid={item?.addon_id === 30 ? webSubmissionData?.ra_subscription : ''}
                            />
                          )
                        }
                      }
                    })}
                  </>
                ) : (
                  ''
                )}

                {/* {webSubmissionData?.addons?.length ? (
                <>
                  <OrderCardContent title="Addons:" value="" />
                  {webSubmissionData?.addons?.map(item => (
                    <OrderCardContent title={`${item?.addon_name}:`} value={`$${item?.addon_price}`} />
                  ))}
                </>
              ) : (
                ''
              )} */}

                {webSubmissionData?.tax_taken_from === 2 && webSubmissionData?.tax_price_id ? (
                  <OrderCardContent
                    title="Business Tax Plan Subscription:"
                    value={`${webSubmissionData?.tax_price_id ? '$' + webSubmissionData?.tax_subscription_amount : 0}`}
                    isPaid={webSubmissionData?.tax_subscription}
                    isLoading={isLoading}
                  />
                ) : (
                  ''
                )}
                {webSubmissionData?.professional_address &&
                  webSubmissionData.address_taken_from === FORMS.post_form && (
                    <OrderCardContent
                      title="Professional Address"
                      value={`$${checkNullValue(webSubmissionData?.address_subscription_amount)}`}
                      isLoading={isLoading}
                      isPaid={webSubmissionData?.business_address_subscription}
                    />
                  )}
                {webSubmissionData?.business_rocket_address &&
                  webSubmissionData.address_taken_from === FORMS.post_form && (
                    <OrderCardContent
                      title="BR California Address:"
                      value={`$${checkNullValue(webSubmissionData?.address_subscription_amount)}`}
                      isLoading={isLoading}
                      isPaid={webSubmissionData?.business_address_subscription}
                    />
                  )}
                <Divider className={classes.totalDivider} />

                <OrderCardContent
                  title="Total:"
                  // value={`$${webSubmissionData?.post_payment_amount || '0'}`}
                  value={`${
                    webSubmissionData?.post_payment_status === 'Paid'
                      ? '$' + webSubmissionData?.post_payment_amount
                      : ''
                  }`}
                  // value={postAmount}
                  isLoading={isLoading}
                />

                <Divider className={classes.totalDivider} />

                <OrderCardContent
                  title="Step:"
                  value={checkPostStepLabel(
                    webSubmissionData?.order_type || '',
                    webSubmissionData?.post_next_step || ''
                  )}
                  // webSubmissionData?.order_type === WEB_ORDER_TYPES.llc
                  //   ? postpaymentStepMappings(webSubmissionData?.post_step || '')
                  //   : postpaymentStepMappingsCorp(webSubmissionData?.post_step || '')

                  isLoading={isLoading}
                />

                <OrderCardContent
                  title="Payment Status:"
                  value={paymentStatusMappings[webSubmissionData?.post_payment_status || '0']}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Payment Method:"
                  value={
                    webSubmissionData?.post_payment_method
                      ? paymentMethodMappings[webSubmissionData?.post_payment_method]
                      : ''
                  }
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Payment ID:"
                  value={checkNullValue(webSubmissionData?.post_payment_id)}
                  isLoading={isLoading}
                />
                {/* {webSubmissionData?.subscription_id ? (
                  <OrderCardContent
                    title="Subscription ID:"
                    value={checkNullValue(webSubmissionData?.subscription_id)}
                    isLoading={isLoading}
                  />
                ) : (
                  ''
                )} */}
                {/* {!webSubmissionData?.post_payment_id ? ( */}
                <OrderCardContent
                  title="Payment Error:"
                  value={checkNullValue(webSubmissionData?.post_payment_error)}
                  isLoading={isLoading}
                />
                {/* ) : (
                  ''
                )} */}
              </OrderCard>

              {/* <OrderCard title="IPostal1 Address">
              <div style={{ background: webSubmissionData?.ipostal_address_provided ? 'none' : 'red' }}>
                <OrderCardContent
                  title="IPostal Address Provided:"
                  value={webSubmissionData?.ipostal_address_provided ? 'Provided' : 'Not Provided'}
                  isLoading={isLoading}
                />
              </div>
            </OrderCard> */}

              {iPostal1 ? (
                <div className="ipostal-alert">
                  <Alert severity={webSubmissionData?.ipostal_address_provided ? 'success' : 'error'}>
                    <div className="ipostal-alert-header">
                      <AlertTitle>IPostal1 Address</AlertTitle>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          title="Open IPostal1 Address New Tab"
                          onClick={() => {
                            window.open(iPostal1Link, '_blank')
                          }}
                        >
                          <LaunchOutlined />
                        </IconButton>
                        <CopyTextButton onClick={() => copyToClipboard(iPostal1Link)} />
                      </div>
                    </div>

                    {webSubmissionData?.ipostal_address_provided ? 'Provided' : 'Not Provided'}
                  </Alert>
                </div>
              ) : (
                ''
              )}

              <OrderCard title="Subscription Details">
                {webSubmissionData?.subscriptions?.map(item => (
                  <OrderCardContent title={`${item?.name}:`} value={item?.subscription_id} isLoading={isLoading} />
                ))}
              </OrderCard>

              <OrderCard title="Card Details">
                <OrderCardContent
                  title="Name:"
                  value={checkNullValue(webSubmissionData?.payment_details?.name)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Brand:"
                  value={checkNullValue(webSubmissionData?.payment_details?.display_brand)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Exp:"
                  value={`${checkNullValue(webSubmissionData?.payment_details?.exp_month)}/${checkNullValue(
                    webSubmissionData?.payment_details?.exp_year
                  )}`}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Mode:"
                  value={checkNullValue(webSubmissionData?.payment_details?.funding)}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Last 4 Digit:"
                  value={`XXXX-XXXX-XXXX-${checkNullValue(webSubmissionData?.payment_details?.last4)}`}
                  isLoading={isLoading}
                />
                <OrderCardContent
                  title="Country:"
                  value={checkNullValue(webSubmissionData?.payment_details?.country)}
                  isLoading={isLoading}
                />
              </OrderCard>
            </Grid>
          </Grid>
        </Card>
        <div>
          <TableContainer className={classes.table} component={Paper}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>IP Address</TableCell>
                  <TableCell>BRID</TableCell>
                  <TableCell>Visit Time</TableCell>
                  <TableCell>Ref Code</TableCell>
                  <TableCell>utm_campaign</TableCell>
                  <TableCell>utm_content</TableCell>
                  <TableCell>utm_medium</TableCell>
                  <TableCell>utm_source</TableCell>
                  <TableCell>gclid</TableCell>
                  <TableCell>sscid</TableCell>
                  <TableCell>fbclid</TableCell>
                  <TableCell>msclkid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitorLogs?.map((logs: IVisitorLogs, i: number) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {logs?.ip_address}
                    </TableCell>
                    <TableCell>{logs?.brid}</TableCell>
                    <TableCell>{logs?.visit_time ? formatDateTimeToHumanFriendly(logs?.visit_time) : '--'}</TableCell>
                    <TableCell>{logs?.ref_code}</TableCell>
                    <TableCell>{logs?.utm_campaign}</TableCell>
                    <TableCell>{logs?.utm_content}</TableCell>
                    <TableCell>{logs?.utm_medium}</TableCell>
                    <TableCell>{logs?.utm_source}</TableCell>
                    <TableCell>{logs?.gclid}</TableCell>
                    <TableCell>{logs?.sscid}</TableCell>
                    <TableCell>{logs?.fbclid}</TableCell>
                    <TableCell>{logs?.msclkid}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <ConversionDialog
        open={conversionDialogOpen}
        setOpen={setConversionDialogOpen}
        handleSubmit={markSubmissionAsOfflineConversion}
        conversion_type={conversionType}
        loading={isConversionLoading}
      />
    </>
  )
}

const ConversionDialog: React.FC<{
  open: boolean
  setOpen: any
  handleSubmit: (val: submission_types, gclid: string) => void
  conversion_type: submission_types
  loading?: boolean
}> = ({ open, setOpen, handleSubmit, conversion_type, loading }) => {
  const [gclid, setGclid] = React.useState('')

  const handleClose = () => {
    setOpen(false)
    setGclid('')
  }
  useEffect(() => {
    if (loading === false) {
      handleClose()
    }
  }, [loading])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      {/* <DialogTitle>Subscribe</DialogTitle> */}
      <DialogContent>
        <DialogContentText>Enter GCLID</DialogContentText>
        <BRTextField
          placeholder="gclid"
          type="text"
          variant="outlined"
          value={gclid}
          onChange={(e: any) => setGclid(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <ButtonWithLoading
          disabled={!gclid.length}
          onClick={() => handleSubmit(conversion_type, gclid)}
          color="primary"
          isLoading={loading}
        >
          Submit
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  )
}
const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',

        '&:not(last-child)': {
          marginBottom: '0.5rem',
        },
      },

      '& .inline-header': {
        display: 'flex',
        alignItems: 'center',

        '& h1': {
          marginRight: '2rem',
        },
      },
      '& .ipostal-alert': {
        margin: '1.25rem',

        '& .MuiAlert-message': {
          width: '100%',
          padding: '0',
          paddingBottom: '0.5rem',

          '& .ipostal-alert-header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        },
      },
      '& .inline-heading': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    cardContainer: {
      margin: '0 10px',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 0 ',
      borderBottom: '2px solid #00000012',
    },
    cardHeaderInner: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1.25rem',
    },
    cardHeading: {
      margin: '0 20px',
      fontSize: '20px',
      color: '#0000009e',
    },
    viewBtn: {
      border: '1px solid transparent',
      background: '#0000ffa1',
      color: '#fff',
      margin: '0 15px 0 0',
      padding: '0.25rem 1rem',

      '&:hover': {
        border: '1px solid #0000ffa1',
        color: '#0000ffa1',
        background: '#fff',
      },
    },
    paymentBtn: {
      border: '1px solid transparent',
      background: 'gray',
      color: '#fff',
      padding: '0.25rem 1rem',

      '&:hover': {
        border: '1px solid gray',
        background: '#fff',
        color: 'gray',
      },
    },
    tableContainer: {
      margin: '20px 0 0',
    },
    tableRow: {
      '&:last-child': {
        borderBottom: '0',
      },
    },
    paymentRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #000',
    },

    totalDivider: {
      margin: '0.5rem 0 0.75rem 0',
    },
    promocodeText: {
      fontSize: '0.8rem',
      marginTop: '-0.5rem !important',
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    invoiceBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: '1rem',

      '& .MuiButtonBase-root': {
        margin: '0',
      },
    },
    table: {
      maxHeight: 650,
      height: 650,
      margin: '2rem 0',
    },
  })
)

// {checkingEIN() ||
//           webSubmissionData?.tax_upgrade ||
//           webSubmissionData?.registered_agent_first_name === 'BusinessRocket, Inc' ||
//           webSubmissionData?.registered_agent_name === 'BusinessRocket, Inc' ? (
//             <>
//               <OrderCardContent title="Addons:" value="" />
//               {checkingEIN() ? <OrderCardContent title={`EIN Upgrade:`} value={`$49`} /> : ''}
//               {/* {webSubmissionData?.addons?.length
//                 ? webSubmissionData?.addons?.map(item => (
//                     <OrderCardContent title={`${item?.addon_name}:`} value={`$${item?.addon_price}`} />
//                   ))
//                 : ''} */}
//                 {webSubmissionData?.tax_upgrade ? <OrderCardContent title={`S-Corp:`} value={`$49`} /> : ''}
//                 {webSubmissionData?.registered_agent_first_name === 'BusinessRocket, Inc' ||
//                 webSubmissionData?.registered_agent_name === 'BusinessRocket, Inc' ? (
//                   <OrderCardContent title={`Registered Agent Services:`} value={`$0`} />
//                 ) : (
//                   ''
//                 )}
//               </>
//             ) : (
//               ''
//             )}
export default WebSubmissionDetails
