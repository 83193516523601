import { createStyles, FormControl, InputLabel, makeStyles, Select, Theme, MenuItem } from '@material-ui/core'
import { MeetingLinkProps, getMeetingLinksList } from 'api/meetingLinks'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import React from 'react'

interface ScheduleMeetingModal {
  meetingModal: boolean
  setMeetingModal: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
      marginBottom: '1rem',
    },
    scheduleBtn: {
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
      textDecoration: 'none',
      padding: '1rem',
      borderRadius: '4px',
      display: 'flex',
      width: 'max-content',
    },
  })
)

const ScheduleMeetingModal = ({ meetingModal, setMeetingModal }: ScheduleMeetingModal) => {
  const classes = useStyles()

  const [meetingLinkList, setMeetingLinkList] = React.useState<MeetingLinkProps[]>([])
  const [selectedLink, setSelectedLink] = React.useState('')

  React.useEffect(() => {
    const getMeetingLinkList = async () => {
      try {
        const result: MeetingLinkProps[] = await getMeetingLinksList()
        setMeetingLinkList(result)
      } catch (error) {
        console.log(error)
      }
    }

    getMeetingLinkList()
  }, [])

  return (
    <ModalWrapper open={meetingModal} setOpen={setMeetingModal} heading="Schedule a Meeting" maxWidth="sm">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="select-native">Schedule a Meeting for:</InputLabel>
        <Select
          native
          value={selectedLink}
          onChange={(e: any) => setSelectedLink(e.target.value)}
          label="Schedule a Meeting for:"
          inputProps={{
            name: 'selectedLink',
            id: 'outlined-selectedLink-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {meetingLinkList?.map((meetingLink: MeetingLinkProps) => (
            <option key={meetingLink?.title} value={meetingLink?.link}>
              {meetingLink?.title}
            </option>
          ))}
        </Select>
      </FormControl>
      <a href={selectedLink} target="_blank" rel="noopener noreferrer" className={classes.scheduleBtn}>
        Schedule Meeting
      </a>
    </ModalWrapper>
  )
}

export default ScheduleMeetingModal
