import { AppBar, Button, CircularProgress, IconButton, Tab, Tabs, TextField, Typography } from '@material-ui/core'
import { getCompanyDetails, updateCompany } from 'api/companies'
import DocumentUploads from 'components/common/assets/documentUploads'
import EditIcon from 'components/common/icons/edit'
import CompanyOrders from 'components/companies/companyOrders'
import CompanyTasks from 'components/companies/companyTasks'
import { diff } from 'deep-object-diff'
import { useResource } from 'hooks/useResource'
import { IDetailedCompanyResponse } from 'models/company'
import { useStyles } from 'pages/company/styles'
import TabPanel from 'pages/company/tabPanel'
import { useCompanyDetailsFormFields } from 'pages/company/useFormFields'
import * as React from 'react'
import { useLocation, useParams } from 'react-router'
import {
  companyFieldsToHumanFriendly,
  detailedCompanyResponseToUpdateCompanyParams,
  patchNestedCompanyFields,
} from 'utils/company'
import Form from 'utils/FormGenerator/index'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import Notes from 'components/common/notes/notes'
import { NotesTableContext } from 'pages/orders/orderDetails'
import { fetchNotes } from 'api/note'
import { usePagedTable } from '@jetkit/react'
import { removeNullAndUndefined } from 'utils/objectUtils'
import { INote } from 'models/note'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import { LEGALTYPES } from 'utils/constant'
import CompanyComplianceTask from 'components/companies/companyComplianceTask'
import CompanyLabels from 'components/companies/companyLabels'
import CompanySpecialOffers from 'components/companies/companySpecialOffers'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ChangeLogsDialog from './changeLogsDialog'
import { updatePageVisitsCount } from 'utils/user'
import TaxOrders from 'components/taxOrders'
import { fetchTaxOrders } from 'api/taxOrders'
import { CompanyContext } from './companyContext'
import CompanySubscription from 'components/companies/companySubscription'
import CompanyNotificationLogs from 'pages/CompanyNotificationLogs'
import { nameCheckByStateId } from 'api/namechecklist'
import showApiResponseError from 'utils/showApiResponseError'

// generates props for Tab based on its index
const getTabProps = (tabIndex: number) => {
  return {
    id: `tab-${tabIndex}`,
    'aria-controls': `tab-panel-${tabIndex}`,
  }
}

enum CompanyDetailsTabs {
  COMPANY_DETAILS = 0,
  COMPANY_ORDERS = 1,
  COMPANY_TASKS = 2,
  SUBSCRIPTION = 3,
  COMPANY_COMPLIANCE_TASK = 4,
  TAX_ORDERS = 5,
  NOTIFICATION = 6,
  COMPANY_UPLOADS = 7,
  SPECIAL_OFFERS = 8,
  COMPANY_LABELS = 9,
}

interface ILoadedCompanyDetailsProps {
  company: IDetailedCompanyResponse
  setCompany: React.Dispatch<React.SetStateAction<IDetailedCompanyResponse | undefined>>
}

const LoadedCompanyDetails = ({ company, setCompany }: ILoadedCompanyDetailsProps) => {
  const classes = useStyles()
  const { state } = useLocation<any>()

  const [activeTab, setActiveTab] = React.useState(CompanyDetailsTabs.COMPANY_DETAILS)
  const [companyName, setCompanyName] = React.useState(company.name)
  const [isOpen, setIsOpen] = React.useState(false)
  const [namecheckloading, setnamecheckloading] = React.useState<boolean>(false)

  // React.useEffect(() => {
  //   // setCompanyName(company.name)
  // }, [company])
  React.useEffect(() => {
    if (state) {
      setActiveTab(state['tab'])
    }
  }, [state])
  const [isNameBeingEdited, setIsNameBeingEdited] = React.useState(false)

  const onSubmit = React.useCallback(
    async (values: IDetailedCompanyResponse) => {
      if (!company.id) {
        showErrorNotification('Failed to update company')
        throw new Error('Company Id is missing')
      }

      try {
        //

        // TODO: #359 add generic company interface
        // detailedCompanyResponseToUpdateCompanyParams converts interface to API friendly
        // ( SHOULD BE REMOVED right after we resolve issues with interfaces ).
        // dif function compares init values and formik values ( so we send only updated fields to backend )
        const updatedCompanyFields: any = diff(company, { ...values, name: companyName })

        updatedCompanyFields.directors_meeting_address = values.director_meeting.address
        updatedCompanyFields.shareholders_meeting_address = values.shareholder_meeting.address
        // updatedCompanyFields.client_meeting_date_time = values.director_meeting.date
        // updatedCompanyFields.shareholders_meeting_date_time = values.shareholder_meeting.date
        // updatedCompanyFields.primary_address = values.primary_address
        // updatedCompanyFields.mailing_address = values.mailing_address

        await updateCompany(company.id, detailedCompanyResponseToUpdateCompanyParams(updatedCompanyFields))
        await patchNestedCompanyFields(updatedCompanyFields, company)
        setCompany(values)
        showSuccessNotification('Company successfully updated')
        //
      } catch (error) {
        showErrorNotification('Failed to update company')
      }
    },
    [company, companyName, setCompany]
  )
  const [notesVisible, setNotesVisible] = React.useState<boolean>(true)

  const defaultSearchOptions: NoteEntities = React.useMemo(
    (): NoteEntities => ({
      types: ['company'],
      company_id: company?.id,
    }),
    [company]
  )

  const [searchOptions, setSearchOptions] = React.useState<NoteEntities>(defaultSearchOptions)
  const setSearchQuery = React.useCallback(
    (newQuery: string) => setSearchOptions(prevState => ({ ...prevState, query: newQuery })),
    [setSearchOptions]
  )
  const memoApiCall = React.useMemo(() => fetchNotes(removeNullAndUndefined(searchOptions)), [searchOptions])
  const pagedTable = usePagedTable<INote>({
    apiCall: memoApiCall,
  })

  const onOpenNotesClick = React.useCallback(() => setNotesVisible(true), [])

  const handleChangeTab = React.useCallback((event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setActiveTab(newTabIndex)
  }, [])
  const { fields } = useCompanyDetailsFormFields(company, pagedTable)

  const checkName = async () => {
    try {
      setnamecheckloading(true)
      const res = await nameCheckByStateId(company?.state_of_formation_id || 0)
      // const res = await nameCheckByStateId(orderDetails.company_details?.state_of_formation_id || 0)
      if (res && res.name_check_url && res.name_check_url.length) {
        window.open(res.name_check_url, '_blank')
        setnamecheckloading(false)
      } else {
        showErrorNotification('No Url provided for this state')
        setnamecheckloading(false)
      }
      //showSuccessNotification('Sent Successfully')
    } catch (e) {
      showApiResponseError(e, 'Error: Something went wrong! ')
      setnamecheckloading(false)
    }
  }

  return (
    <div className={classes.mainContainer}>
      {/* <div style={{ display:'flex' }}>
        
        </div> */}

      <ChangeLogsDialog open={isOpen} setOpen={setIsOpen} companyId={company?.id} />
      <div className={classes.companyNameFieldContainer}>
        <div className="inline-field">
          {isNameBeingEdited ? (
            <TextField value={companyName} onChange={event => setCompanyName(event.target.value)} />
          ) : (
            <Typography className={classes.companyNameLabel}>{companyName}</Typography>
          )}
          <IconButton
            className={classes.editButton}
            onClick={() => setIsNameBeingEdited(editMode => !editMode)}
            disableRipple
          >
            <EditIcon width={25} height={25} />
          </IconButton>
        </div>

        <div style={{ display: 'flex' }}>
          <ButtonWithLoading
            style={{ marginRight: '0.5rem' }}
            // wrapperClassName={classes.nameCheckBtn}
            isLoading={namecheckloading}
            onClick={checkName}
            wrapperClassName={classes.logsBtn}
          >
            Name Check
          </ButtonWithLoading>
          <ButtonWithLoading
            color="primary"
            variant="contained"
            wrapperClassName={classes.logsBtn}
            onClick={() => setIsOpen(true)}
          >
            Change Log
          </ButtonWithLoading>
        </div>
      </div>
      <div style={{ width: '100%', flexGrow: 1 }}>
        <AppBar position="static" classes={{ root: classes.appBarContainer }}>
          <Tabs
            value={activeTab}
            // classes={{ flexContainer: classes.tabsContainer, indicator: classes.currentStepIndicator }}
            onChange={handleChangeTab}
            aria-label="company tabs"
            variant="scrollable"
            // scrollButtons="auto"
            scrollButtons="auto"
          >
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Company Details"
              {...getTabProps(CompanyDetailsTabs.COMPANY_DETAILS)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Orders"
              {...getTabProps(CompanyDetailsTabs.COMPANY_ORDERS)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Tasks"
              {...getTabProps(CompanyDetailsTabs.COMPANY_TASKS)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Subscription"
              {...getTabProps(CompanyDetailsTabs.SUBSCRIPTION)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Compliance Task"
              {...getTabProps(CompanyDetailsTabs.COMPANY_COMPLIANCE_TASK)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Tax Orders"
              {...getTabProps(CompanyDetailsTabs.TAX_ORDERS)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Notification"
              {...getTabProps(CompanyDetailsTabs.NOTIFICATION)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Uploads"
              {...getTabProps(CompanyDetailsTabs.COMPANY_UPLOADS)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Special Offers"
              {...getTabProps(CompanyDetailsTabs.SPECIAL_OFFERS)}
            />
            <Tab
              classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }}
              label="Labels"
              {...getTabProps(CompanyDetailsTabs.COMPANY_LABELS)}
            />
            <div className={classes.indicator}></div>
          </Tabs>
        </AppBar>

        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.COMPANY_DETAILS}>
          <Form initialValues={company} fieldsData={fields} submitButtonLabel={'Save Company'} onSubmit={onSubmit} />
          {notesVisible ? (
            <div style={{ width: '35%' }}>
              <NoteEntitiesContext.Provider
                value={{
                  types: ['company'],
                  company: {
                    value: company.id,
                    label: company.name,
                  },
                }}
              >
                <NotesTableContext.Provider
                  value={{
                    pagedTable,
                    searchOptions,
                    setSearchQuery,
                    setSearchOptions,
                  }}
                >
                  <Notes setModalIsOpen={setNotesVisible} />
                </NotesTableContext.Provider>
              </NoteEntitiesContext.Provider>
            </div>
          ) : (
            <div style={{ float: 'right' }}>
              <OpenNotesButton onClick={onOpenNotesClick} />
            </div>
          )}
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.COMPANY_UPLOADS}>
          <div className={classes.documentsContainer}>
            <DocumentUploads type="business" objectId={company.id} />
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.COMPANY_ORDERS}>
          <CompanyOrders companyId={company.id} />
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.COMPANY_TASKS}>
          <div className={classes.documentsContainer}>
            <CompanyTasks company_id={company.id} company={company} />
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.COMPANY_COMPLIANCE_TASK}>
          <div className={classes.documentsContainer}>
            <CompanyComplianceTask company_id={company.id} />
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.COMPANY_LABELS}>
          <div className={classes.documentsContainer}>
            <CompanyLabels company_id={company.id} />
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.SPECIAL_OFFERS}>
          <div className={classes.documentsContainer}>
            <CompanySpecialOffers company_id={company.id} />
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.TAX_ORDERS}>
          <div className={classes.documentsContainer}>
            <CompanyContext.Provider value={{ companyName: companyName, companyId: company.id }}>
              <TaxOrders apiCall={fetchTaxOrders} />
            </CompanyContext.Provider>
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.SUBSCRIPTION}>
          <div className={classes.documentsContainer}>
            <CompanySubscription company_id={company.id} />
          </div>
        </TabPanel>
        <TabPanel activeTabIndex={activeTab} tabIndex={CompanyDetailsTabs.NOTIFICATION}>
          <div className={classes.documentsContainer}>
            <CompanyNotificationLogs company_id={company.id} />
          </div>
        </TabPanel>
      </div>
    </div>
  )
}

const CompanyPage = () => {
  // TODO: #418 If id is undefined, handle it
  const { companyId } = useParams<{ companyId?: string }>()
  const classes = useStyles()

  const company = useResource(getCompanyDetails, companyId)
  // console.log('compantDet',company);

  return company.value ? (
    <LoadedCompanyDetails company={companyFieldsToHumanFriendly(company.value)} setCompany={company.setValue} />
  ) : (
    <div className={classes.loadingWrapper}>
      <CircularProgress size={100} />
    </div>
  )
}

export default CompanyPage
