import { apiClient, getAccessToken } from '@jetkit/react'
import { Doc } from 'models/doc'

export const getDoc = async (id: number): Promise<Doc> => {
  const res = await apiClient.get(`document/${id}`)
  return res.data
}

export const renderDocumentForOrder = async (orderId: number): Promise<Doc> => {
  const res = await apiClient.post(`document/${orderId}/render_order_document`)
  return res.data
}

export const updateDoc = async (docId: number, new_text): Promise<Doc> => {
  const res = await apiClient.patch(`document/${docId}`, { document: { document_text: new_text }, generate_pdf: true })
  return res.data
}

export const getDocumentPDFDownload = (docId: number): string => {
  return new URL(`api/document/${docId}/download?jwt=${getAccessToken()}`, apiClient.defaults.baseURL).toString()
}
