import { DoneAll, Email, Sms } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { NotesData } from '.'
import { markProspectAsReadById } from 'api/leadManager'
import showApiResponseError from 'utils/showApiResponseError'
import CircularProgress from '@material-ui/core/CircularProgress'

interface ChatBoxProps {
  note: NotesData
}

const ChatBox: React.FC<ChatBoxProps> = ({ note }: ChatBoxProps) => {
  const { id, creator, note_text, created_by, read, created_on, sent_notification_id } = note
  const [isMarkReadNoteLoading, setIsMarkReadNoteLoading] = React.useState(false)

  const [isMarkReadIcon, setIsMarkReadIcon] = React.useState(false)
  useEffect(() => {
    if (
      (creator?.includes('Client') && read) ||
      creator?.includes('System') ||
      (sent_notification_id && creator?.includes('Employee'))
    ) {
      setIsMarkReadIcon(true)
    } else {
      setIsMarkReadIcon(false)
    }
  }, [creator, read, sent_notification_id])

  const handleMarkAsRead = async (id: number) => {
    if (!id) return
    if (creator?.includes('Client') && read) return
    if (creator?.includes('System')) return

    setIsMarkReadNoteLoading(true)

    const payload = { read: true }

    try {
      await markProspectAsReadById(id, payload)
      setIsMarkReadNoteLoading(false)
      setIsMarkReadIcon(true)
    } catch (error) {
      showApiResponseError(error, 'Error')
    }
    setIsMarkReadNoteLoading(false)
  }

  return (
    <div style={{ width: '100%' }}>
      {creator?.includes('System') ||
      creator?.includes('Client') ||
      (sent_notification_id && creator?.includes('Employee')) ? (
        <div
          className="chatbox-container"
          style={{
            justifyContent: creator?.includes('Client') ? 'flex-start' : 'flex-end',
          }}
        >
          <div
            className={`${
              creator?.includes('System') || (sent_notification_id && creator?.includes('Employee'))
                ? 'email-chatbox-section'
                : 'chatbox-section'
            }`}
            onClick={() => handleMarkAsRead(id || 0)}
          >
            <p className="title">{creator?.includes('Employee') ? created_by : creator}</p>
            <p className="text" dangerouslySetInnerHTML={{ __html: note_text || '' }} />

            <div className="inline-icon">
              {creator?.includes('System') && note_text?.includes('Email') ? (
                <Email color="primary" fontSize="small" />
              ) : (
                <Sms color="disabled" fontSize="small" />
              )}
              <p>{formatDateTimeToHumanFriendly(created_on || '')}</p>
              <div className="icon-wrapper">
                <DoneAll color={isMarkReadIcon ? 'primary' : 'disabled'} fontSize="small" />
                {isMarkReadNoteLoading && <CircularProgress size={28} className="done-progress" />}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ChatBox
