import LinearProgress from '@material-ui/core/LinearProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 450,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#f6f6f8',
      borderRadius: 4,
      height: 50,
      padding: '0px 25px 0px 25px',
    },
    label: {
      marginRight: 25,
      fontWeight: 500,
      fontSize: 18,
      color: '#020202',
    },
    percentage: {
      marginLeft: 25,
      fontWeight: 500,
      fontSize: 14,
      color: '#020202',
    },
    progressIndicator: {
      flexGrow: 1,
    },
    progressBarColorPrimary: {
      backgroundColor: '#0c2453',
    },
    progressColorPrimary: {
      backgroundColor: '#d8d8d8',
    },
  })
)

export const useProgress = (apiCall: () => Promise<number>): TasksProgressContextProps => {
  const [progress, setProgress] = React.useState<number | undefined>(undefined)
  const getProgress = React.useCallback(async () => {
    const result = await apiCall()
    setProgress(result)
  }, [setProgress, apiCall])

  React.useEffect(() => {
    getProgress()
  }, [getProgress])

  return {
    progress,
    getProgress,
  }
}

export interface TasksProgressContextProps {
  progress?: number
  getProgress: () => void
}

/**
 * TasksProgressContext is a context to store value of tasks progress.
 * Tasks progress is defined as a ratio of number of tasks completed to
 * total number of tasks per given.
 *
 * `progress` equals undefined when the value is not yet fetched from backend.
 * In this case, progress bar is shown in indeterminate variant
 */
export const TasksProgressContext = React.createContext<TasksProgressContextProps>({
  progress: undefined,
  getProgress: () => {
    throw new Error('Internal React error. TasksProgressContext.getProgress() method is not initialized.')
  },
})

interface ITasksProgressProps {
  className?: string
}

function TasksProgress({ className }: ITasksProgressProps) {
  const classes = useStyles()

  const { progress } = React.useContext(TasksProgressContext)

  return (
    <div className={classnames(classes.root, className)}>
      <Typography className={classes.label}>Progress</Typography>
      <LinearProgress
        classes={{ barColorPrimary: classes.progressBarColorPrimary, colorPrimary: classes.progressColorPrimary }}
        className={classes.progressIndicator}
        variant={progress !== undefined && progress >= 0 ? 'determinate' : 'indeterminate'}
        value={progress}
      />
      {progress !== undefined && progress >= 0 ? (
        <Typography className={classes.percentage}>{progress} %</Typography>
      ) : null}
    </div>
  )
}

export default TasksProgress
