import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useRouter from 'hooks/router'
import classnames from 'classnames'
//import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import SendIcon from '@material-ui/icons/Send'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import * as MenuIcons from 'icons/menuIcons'
import {
  AssessmentOutlined,
  AssignmentOutlined,
  FormatAlignLeft,
  LanguageOutlined,
  Notifications,
  PostAdd,
  Schedule,
} from '@material-ui/icons'
import { USERTYPES } from 'utils/constant'
import useUser from 'hooks/useUser'
import { AppContext } from 'App'
import { Badge } from '@material-ui/core'

interface RenderIconProps {
  selected: boolean
}
export interface IMenuSection {
  title: string
  path: string
  renderIcon?: ({ selected }: RenderIconProps) => React.ReactNode
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 11,

      '& .MuiSvgIcon-root': {
        marginRight: 16,

        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
        },
      },
      '& svg': {
        marginRight: 16,

        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
        },
      },
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    selectedSectionContainer: {
      // backgroundImage: 'linear-gradient(to left, #213161, #24489e)',
      background: 'inherit',
    },
    sectionTitle: {
      // marginLeft: 15,
      cursor: 'pointer',
      color: '#788190',
      fontSize: '16px',
      fontWeight: 500,
      width: '100%',
      padding: 0,

      '& .MuiListItem-root': {
        // paddingTop: '0',
        // paddingBottom: '0',
        margin: 0,

        '& i': {
          paddingLeft: 16,
        },
      },
    },
    selectedSectionTitle: {
      color: '#407ace',
      margin: 0,
    },
    ribbon: {
      width: 4,
      height: 46,
    },
    ribbonActive: {
      backgroundColor: '#42aef4',
    },
    nested: {
      paddingLeft: theme.spacing(4),
      marginLeft: 15,
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 500,
      paddingTop: '0.25rem !important',

      '& .MuiListItemIcon-root': {
        minWidth: '36px',
      },
    },
    listItem: {
      marginLeft: '15px',
      fontWeight: 'bold',
    },
    documentCount: {
      marginLeft: '1rem',
    },
  })
)

interface INavMenuProps {
  sections: IMenuSection[]
  defaultSelectedSection?: IMenuSection
  sectionSelected: (section: IMenuSection) => void
  hideSectionTitles?: boolean
}

const NavMenu = ({ sections, sectionSelected, defaultSelectedSection, hideSectionTitles }: INavMenuProps) => {
  const classes = useStyles()
  const router = useRouter()
  const [open, setOpen] = React.useState(false)
  const [select, setselect] = React.useState(false)
  const [isSelected, setisSelected] = React.useState(false)

  const [isNotiOpen, setIsNotiOpen] = React.useState(false)
  const [isAutoReminderOpen, setIsAutoReminderOpen] = React.useState(false)

  const [isTaxOpen, setIsTaxOpen] = React.useState(false)

  const [isLeadManagerOpen, setIsLeadManagerOpen] = React.useState(false)
  const [isWebSubmissionOpen, setIsWebSubmissionOpen] = React.useState(false)

  const userDetails = JSON.parse(localStorage.getItem('currentUser') || '')
  const userType = userDetails?.user_type

  const { user } = useUser()

  if (defaultSelectedSection) {
    sectionSelected(defaultSelectedSection)
  }
  const handleClick = () => {
    setOpen(!open)
  }
  const count = React.useContext(AppContext).unreadDocsCount
  const missingitemscount = React.useContext(AppContext).missingItemsCount

  return (
    <div>
      {sections.map((section: any) => {
        const isSelected = router.location.pathname === section.path
        //  setisSelected(router.location.pathname === section.path)

        return (
          <div key={section.path}>
            {section.title !== 'Predefined Lists' &&
              section.title !== 'State' &&
              section.title !== 'Notifications' &&
              section.title !== 'Auto Reminder' &&
              section.title !== 'Tax Service Orders' &&
              section.title !== 'KPI Dashboard' &&
              section.title !== 'Lead Manager' &&
              section.title !== 'Web Submissions' && (
                <div
                  key={section.path}
                  className={classnames(classes.section, { [classes.selectedSectionContainer]: isSelected })}
                  onClick={() => {
                    sectionSelected(section)
                    setOpen(false)
                    setselect(false)
                  }}
                >
                  {/* <div className={classnames(classes.ribbon, { [classes.ribbonActive]: isSelected })} /> */}
                  <div className={classes.sectionContainer}>
                    {section.renderIcon && section.renderIcon({ selected: isSelected })}
                    {hideSectionTitles && (
                      <Typography
                        className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}
                      >
                        {section.title}
                      </Typography>
                    )}
                    {section.title === 'Company Documents' && (
                      <Badge className={classes.documentCount} badgeContent={count} color="error" />
                    )}
                    {section.title === 'Missing Items' && (
                      <Badge className={classes.documentCount} badgeContent={missingitemscount} color="error" />
                    )}
                  </div>
                </div>
              )}

            {section.title === 'KPI Dashboard' && userType === '9' ? (
              <div
                className={classes.sectionContainer}
                onClick={() => {
                  section.path = '/kpi-dashboard'
                  sectionSelected(section)
                }}
              >
                {section.renderIcon && section.renderIcon({ selected: isSelected })}
                {hideSectionTitles && (
                  <Typography
                    className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}
                  >
                    {section.title}
                  </Typography>
                )}
              </div>
            ) : (
              ''
            )}

            {section.title === 'Predefined Lists' && (
              <div
                className={classnames(classes.section, { [classes.selectedSectionContainer]: select })}
                onClick={() => {
                  //sectionSelected(null)
                  setselect(true)
                  setisSelected(false)
                }}
              >
                {hideSectionTitles && section.title === 'Predefined Lists' && (
                  <List className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: select })}>
                    <ListItem button onClick={handleClick}>
                      <i style={{ marginLeft: '-21px' }}>
                        {section.renderIcon && section.renderIcon({ selected: select })}
                      </i>
                      <ListItemText className={classes.listItem} primary="Predefined Lists" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="State"
                            onClick={() => {
                              section.path = '/states'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="City"
                            onClick={() => {
                              section.path = '/cities'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Document Types"
                            onClick={() => {
                              section.path = '/document-types'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Predefined Tasks"
                            onClick={() => {
                              section.path = '/predefined-tasks'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Order Statuses"
                            onClick={() => {
                              section.path = '/order-status'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Order Types"
                            onClick={() => {
                              section.path = '/order-types'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Widgets"
                            onClick={() => {
                              section.path = '/widgets'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Tax Widgets"
                            onClick={() => {
                              section.path = '/tax-widgets'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="State Processing Configs"
                            onClick={() => {
                              section.path = '/state-processing'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Missing Items"
                            onClick={() => {
                              section.path = '/missing-items'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Industry Types"
                            onClick={() => {
                              section.path = '/industry-type'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>

                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Prospect statuses"
                            onClick={() => {
                              section.path = '/prospect-status'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Promo Codes"
                            onClick={() => {
                              section.path = '/promo-code'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>

                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Labels"
                            onClick={() => {
                              section.path = '/labels'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Power Ups"
                            onClick={() => {
                              section.path = '/special-offers'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText
                            primary="Name CheckLists"
                            onClick={() => {
                              section.path = '/name-checklists'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <PeopleAltOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Meeting Links"
                            onClick={() => {
                              section.path = '/meeting-links'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <PeopleAltOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Subscriptions"
                            onClick={() => {
                              section.path = '/subscription'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <PeopleAltOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Powerup Category"
                            onClick={() => {
                              section.path = '/special-offers-category'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                )}
              </div>
            )}

            {/* //////////////////////////////////////////////////////////////////// */}

            {section.title === 'Notifications' && (
              <div className={classnames(classes.section, { [classes.selectedSectionContainer]: isSelected })}>
                {hideSectionTitles && section.title === 'Notifications' && (
                  <List className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}>
                    <ListItem button onClick={() => setIsNotiOpen(!isNotiOpen)}>
                      <i style={{ marginLeft: '-21px' }}>
                        {section.renderIcon && section.renderIcon({ selected: isSelected })}
                      </i>
                      <ListItemText className={classes.listItem} primary="Notifications" />
                      {isNotiOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isNotiOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <FormatAlignLeft />
                          </ListItemIcon>
                          <ListItemText
                            primary="Templates List"
                            onClick={() => {
                              section.path = '/templates-list'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <FormatAlignLeft />
                          </ListItemIcon>
                          <ListItemText
                            primary="Company Notifications List"
                            onClick={() => {
                              section.path = '/company-notifications'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                )}
              </div>
            )}

            {/* //////////////////////////////////////////////////////////////////// */}

            {section.title === 'Auto Reminder' && (
              <div className={classnames(classes.section, { [classes.selectedSectionContainer]: isSelected })}>
                {hideSectionTitles && section.title === 'Auto Reminder' && (
                  <List className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}>
                    <ListItem button onClick={() => setIsAutoReminderOpen(!isAutoReminderOpen)}>
                      <i style={{ marginLeft: '-21px' }}>
                        {section.renderIcon && section.renderIcon({ selected: isSelected })}
                      </i>
                      <ListItemText className={classes.listItem} primary="Auto Reminder" />
                      {isAutoReminderOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isAutoReminderOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <FormatAlignLeft />
                          </ListItemIcon>
                          <ListItemText
                            primary="Templates List"
                            onClick={() => {
                              section.path = '/reminder-template-list'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <FormatAlignLeft />
                          </ListItemIcon>
                          <ListItemText
                            primary="Add Template"
                            onClick={() => {
                              section.path = '/reminder-template'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                )}
              </div>
            )}

            {
              /////////////////////////////////}
            }
            {section.title === 'Tax Service Orders' && (
              <div
                className={classnames(classes.section, { [classes.selectedSectionContainer]: isSelected })}
                // onClick={() => {
                //   //sectionSelected(null)
                //   setselect(true)
                //   setisSelected(false)
                // }}
              >
                {hideSectionTitles && section.title === 'Tax Service Orders' && (
                  <List className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}>
                    <ListItem button onClick={() => setIsTaxOpen(!isTaxOpen)}>
                      <i style={{ marginLeft: '-21px' }}>
                        {section.renderIcon && section.renderIcon({ selected: isSelected })}
                      </i>
                      <ListItemText
                        // onClick={() => {
                        //   section.path = '/tax-orders'
                        //   sectionSelected(section)
                        // }}
                        className={classes.listItem}
                        primary="Tax Service Orders"
                      />
                      {isTaxOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isTaxOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            {/* <FormatAlignLeft /> */}
                            <MenuIcons.CompanyOrderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Orders"
                            onClick={() => {
                              section.path = '/tax-orders'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <FormatAlignLeft />
                          </ListItemIcon>
                          <ListItemText
                            primary="Invoices"
                            onClick={() => {
                              section.path = '/invoices'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                )}
              </div>
            )}

            {/* //////////////////////////////////////////////////////////////////// */}

            {section.title === 'Lead Manager' && (
              <div className={classnames(classes.section, { [classes.selectedSectionContainer]: isSelected })}>
                {hideSectionTitles && section.title === 'Lead Manager' && (
                  <List className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}>
                    <ListItem button onClick={() => setIsLeadManagerOpen(!isLeadManagerOpen)}>
                      <i style={{ marginLeft: '-21px' }}>
                        {section.renderIcon && section.renderIcon({ selected: isSelected })}
                      </i>
                      <ListItemText className={classes.listItem} primary="Lead Manager" />
                      {isLeadManagerOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isLeadManagerOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <FormatAlignLeft />
                          </ListItemIcon>
                          <ListItemText
                            primary="Prospects"
                            onClick={() => {
                              section.path = '/prospects'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        {user?.user_type !== USERTYPES?.salesAgent ? (
                          <>
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <Schedule />
                              </ListItemIcon>
                              <ListItemText
                                primary="Prospect Schedules"
                                onClick={() => {
                                  section.path = '/prospect-schedules'
                                  sectionSelected(section)
                                }}
                              />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <PostAdd />
                              </ListItemIcon>
                              <ListItemText
                                primary="Add Prospect Schedule"
                                onClick={() => {
                                  section.path = '/prospect-schedule'
                                  sectionSelected(section)
                                }}
                              />
                            </ListItem>
                          </>
                        ) : (
                          ''
                        )}
                      </List>
                    </Collapse>
                  </List>
                )}
              </div>
            )}

            {section.title === 'Web Submissions' && (
              <div className={classnames(classes.section, { [classes.selectedSectionContainer]: isSelected })}>
                {hideSectionTitles && section.title === 'Web Submissions' && (
                  <List className={classnames(classes.sectionTitle, { [classes.selectedSectionTitle]: isSelected })}>
                    <ListItem button onClick={() => setIsWebSubmissionOpen(!isWebSubmissionOpen)}>
                      <i style={{ marginLeft: '-21px' }}>
                        {section.renderIcon && section.renderIcon({ selected: isSelected })}
                      </i>
                      <ListItemText className={classes.listItem} primary="Web Submissions" />
                      {isWebSubmissionOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isWebSubmissionOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <AssignmentOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary="Submissions"
                            onClick={() => {
                              section.path = '/web-submissions'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <AssessmentOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary="Reports"
                            onClick={() => {
                              section.path = '/web-submissions-reports'
                              sectionSelected(section)
                            }}
                          />
                        </ListItem>
                        {user?.user_type !== USERTYPES?.salesAgent ? (
                          <ListItem button className={classes.nested}>
                            <ListItemIcon>
                              <AssignmentOutlined />
                            </ListItemIcon>
                            <ListItemText
                              primary="No UTM Submissions"
                              onClick={() => {
                                section.path = '/web-submissions-no-utm'
                                sectionSelected(section)
                              }}
                            />
                          </ListItem>
                        ) : (
                          ''
                        )}
                      </List>
                    </Collapse>
                  </List>
                )}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default NavMenu
