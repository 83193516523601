import { getDoc, getDocumentPDFDownload, renderDocumentForOrder } from 'api/docs'
import { Doc } from 'models/doc'
import * as React from 'react'

export interface IUseTemplate {
  document_id: number
}
export const useTemplatePDF = ({ document_id }: IUseTemplate) => {
  const [isLoading, setLoading] = React.useState<boolean>(true)
  const [doc, setDoc] = React.useState<Doc | null>(null)
  const [value, setValue] = React.useState<string>('')
  const [pdfLink, setPdfLink] = React.useState<string | null>(null)

  const renderDocumentFromTemplate = React.useCallback(
    async (id: number) => {
      const doc = await renderDocumentForOrder(id)
      setDoc(doc)
      setValue(doc.document_text)
      setLoading(false)
      const link = await getDocumentPDFDownload(doc.id)
      setPdfLink(link)
    },
    [setPdfLink, setLoading, setValue, setDoc]
  )

  const fetchDoc = React.useCallback(
    async (document_id: number) => {
      const doc = await getDoc(document_id)
      setDoc(doc)
      setValue(doc.document_text)
      setLoading(false)
      const link = getDocumentPDFDownload(doc.id)
      setPdfLink(link)
    },
    [setPdfLink, setLoading, setValue, setDoc]
  )

  React.useEffect(() => {
    fetchDoc(document_id)
  }, [document_id, fetchDoc, renderDocumentFromTemplate])

  return {
    value,
    setValue,
    doc,
    pdfLink,
    isLoading,
  }
}
