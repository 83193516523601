import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import * as localForage from 'localforage'
import { applyMiddleware, combineReducers, createStore, Middleware, Reducer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { Persistor, PersistPartial, persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

export const history = createBrowserHistory()

interface IStorePersistor {
  store: IApplicationState
  persistor: Persistor
}

export interface IApplicationState {}

export function storeAndPersistorForRootReducer(rootReducer: Reducer<{ router: RouterState } & PersistPartial>) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    blacklist: ['router'],
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const middlewares: Middleware[] = [thunk, routerMiddleware(history)]
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))
  const store = createStore(persistedReducer, enhancer)
  const persistor = persistStore(store)

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./', () => {
      store.replaceReducer(rootReducer)
    })
  }

  return { store, persistor }
}

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
  })
