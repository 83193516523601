import * as React from 'react'
import { menuIconColor, selectedMenuIconColor } from '../theme'
import {
  BusinessCenter,
  Dashboard,
  ExitToApp,
  FindInPage,
  Folder,
  MonetizationOn,
  Notifications,
  Reorder,
} from '@material-ui/icons'

const iconStyle = (selected: boolean) => ({
  color: selected ? selectedMenuIconColor : menuIconColor,
})

const clientSections = [
  {
    title: 'Dashboard',
    path: '/client-dashboard',
    renderIcon: ({ selected }) => <Dashboard style={{ ...iconStyle(selected) }} />,
  },
  {
    title: 'Referrals',
    path: '/client-referrals',
    renderIcon: ({ selected }) => <MonetizationOn style={iconStyle(selected)} />,
  },
  {
    title: 'Companies',
    path: '/client-companies',
    renderIcon: ({ selected }) => <Folder style={iconStyle(selected)} />,
  },
  {
    // title: 'Correspondence',
    title: 'Company Documents',
    path: '/client-correspondence',
    renderIcon: ({ selected }) => <BusinessCenter style={iconStyle(selected)} />,
  },

  {
    title: 'Notification',
    path: '/client-notifications',
    renderIcon: ({ selected }) => <Notifications style={iconStyle(selected)} />,
  },
  {
    title: 'Missing Items',
    path: '/client-missing-item',
    renderIcon: ({ selected }) => <FindInPage style={iconStyle(selected)} />,
  },
  // {
  //   title: 'Settings',
  //   path: '/client-Settings',
  //   renderIcon: ({ selected }) => <Settings style={iconStyle(selected)} />,
  // },
  {
    title: 'Logout',
    path: '',
    renderIcon: ({ selected }) => <ExitToApp style={iconStyle(selected)} />,
  },
]

export default clientSections
