// import React from 'react'
// import { AddProspectSchedules } from 'components/leadManagerElements/schedules'

// const AddSchedule = () => <AddProspectSchedules />

// export default AddSchedule

import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useParams } from 'react-router'
import { AddProspectSchedules } from 'components/leadManagerElements/schedules'

const AddSchedule = () => {
  const classes = useStyles()
  const { id } = useParams<any>()

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.missingItems}>Prospect Schedules</p>
          <p className={classes.tableTitle}>{id ? 'Update Prospect Schedules' : 'Add Prospect Schedules'}</p>
        </div>
      </div>
      <AddProspectSchedules />
    </div>
  )
}

export default AddSchedule

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
