import * as React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopy'

interface ICopyTextButtonProps {
  onClick?: () => void
  customStyles?: React.CSSProperties
}

const CopyTextButton: React.FC<ICopyTextButtonProps> = ({ onClick, customStyles }) => {
  return (
    <InputAdornment position="end" style={customStyles}>
      <IconButton onClick={onClick} edge="end" size="small" style={{ margin: 0 }}>
        <CopyIcon fontSize="small" style={{ opacity: 0.7 }} />
      </IconButton>
    </InputAdornment>
  )
}

export default CopyTextButton
