import { searchClients } from 'api/clients'
import {
  addTasksToCompanyOrder,
  createCompanyOrder,
  deleteOrder,
  fetchOrderTypeByName,
  findOrders,
  getDefaultTasksForOrderType,
  updateOrder,
} from 'api/orders'
import { COMPANY_CREATION_ORDER_COMMISSION } from 'BRValuesConfig'
import { IInputFieldsErrors } from 'components/companies/create'
import { IAddress } from 'models/address'
import { ICreatedCompany, ILegalType } from 'models/company'
import { IOrder } from 'models/order'
import { IClient } from 'models/user'
import React, { useCallback } from 'react'
import { ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'

export const UNITED_STATES = 'United States of America'
const BUSINESS_FORMATION_ORDER_TYPE_NAME = 'Business Formation'

export interface ICompanyDetailsState {
  companyName: string
  stateOfFormation: string
  shouldCreateFormationOrder: boolean
  newCompany: boolean
  price: number
  commission: number
  address?: IAddress
  mailingAddress?: IAddress
  businessActivity: string
  incorporator: string | null
  companyType?: ILegalType
  country: string
  client?: IClient
  shares?: number
  state_tax_id?: string
  tax_structure?: string
  client_id?: number
}

export const emptyInputFieldsState: ICompanyDetailsState = {
  companyName: '',
  country: UNITED_STATES,
  companyType: undefined,
  shouldCreateFormationOrder: false,
  stateOfFormation: '',
  newCompany: true,
  price: 0,
  commission: 0,
  businessActivity: '',
  incorporator: '',
}

const useCompanyDetails = () => {
  const [companyDetailsInputFields, setCompanyDetailsInputFields] = React.useState<ICompanyDetailsState>(
    emptyInputFieldsState
  )
  const [inputFieldsErrors, setErrors] = React.useState<IInputFieldsErrors>({})

  const [clients, setClients] = React.useState<IClient[]>()
  const [formationOrder, setFormationOrder] = React.useState<IOrder | null>(null)

  const setInputFieldError = (field: string, value: boolean) => {
    setErrors((prevState: IInputFieldsErrors) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onInputFieldsChange = (key: keyof ICompanyDetailsState, value: ValueOf<ICompanyDetailsState>) => {
    debugger
    if (inputFieldsErrors[key] && value) {
      setInputFieldError(key, false)
    }
    if (key === 'country') {
      handleCountryChange(value as string)
    } else if (key === 'price') {
      onInputFieldsChange('commission', value ? ((value as number) * COMPANY_CREATION_ORDER_COMMISSION).toFixed(2) : 0)
    } else if (key === 'address') {
      console.log(value)
      ;(value as IAddress).is_primary = true
    }
    setCompanyDetailsInputFields(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleCountryChange = (country: string) => {
    // reset the state value if we change the country to/from US because we are using
    // free-form for the non-US states and a select for US states
    if (
      (country !== UNITED_STATES && companyDetailsInputFields.country === UNITED_STATES) ||
      (country === UNITED_STATES && companyDetailsInputFields.country !== UNITED_STATES)
    ) {
      onInputFieldsChange('stateOfFormation', '')
    }
  }

  const validateCompanyDetailsFields = () => {
    debugger
    let isValid = true
    // if (!companyDetailsInputFields.client) {
    //   debugger
    //   setInputFieldError('client', true)
    //   isValid = false
    // }
    if (!companyDetailsInputFields.client_id) {
      debugger
      setInputFieldError('client_id', true)
      isValid = false
    }
    if (!companyDetailsInputFields.companyName) {
      debugger
      setInputFieldError('companyName', true)
      isValid = false
    }
    if (!companyDetailsInputFields.country) {
      debugger
      setInputFieldError('country', true)
      isValid = false
    }
    if (!companyDetailsInputFields.companyType) {
      debugger
      setInputFieldError('companyType', true)
      isValid = false
    }
    if (companyDetailsInputFields.country === UNITED_STATES && !companyDetailsInputFields.stateOfFormation) {
      debugger
      setInputFieldError('stateOfFormation', true)
      isValid = false
    }
    if (companyDetailsInputFields.businessActivity === '') {
      debugger
      setInputFieldError('businessActivity', true)
      isValid = false
    }
    if (!companyDetailsInputFields.incorporator) {
      debugger
      setInputFieldError('incorporator', true)
      isValid = false
    }
    if (!companyDetailsInputFields.address) {
      debugger
      setInputFieldError('address', true)
      isValid = false
    }
    return isValid
  }

  const createOrUpdateFormationOrder = React.useCallback(
    async (company: ICreatedCompany, formationOrder?: IOrder | null) => {
      if (!company) {
        showErrorNotification('Could not create formation order. Company ID is missing')
        console.warn('Could not create formation order. Company ID is missing')
        return
      }

      const businessFormationOrder = await fetchOrderTypeByName(BUSINESS_FORMATION_ORDER_TYPE_NAME)
      if (!businessFormationOrder) {
        showErrorNotification('Could not create formation order. Order type not found')
        console.warn('Could not create formation order. Order type not found')
        return
      }

      // If the user wishes to implicitly create a business formation order during the company creation flow, we wanna add defaults tasks for that order.
      const defaultFormationOrderTasks = await getDefaultTasksForOrderType(businessFormationOrder.id)
      try {
        let createdOrder: IOrder | null = null
        if (
          formationOrder &&
          (companyDetailsInputFields.price !== formationOrder.price ||
            companyDetailsInputFields.commission !== formationOrder.commission)
        ) {
          createdOrder = await updateOrder({
            id: formationOrder.id,
            order_type_id: businessFormationOrder.id,
            price: companyDetailsInputFields.price,
            commission: companyDetailsInputFields.commission,
          })
        } else if (!formationOrder) {
          createdOrder = await createCompanyOrder({
            company_id: company.id,
            order_type_id: businessFormationOrder.id,
            price: companyDetailsInputFields.price,
            commission: companyDetailsInputFields.commission,
            //incorporator: companyDetailsInputFields.incorporator,
          })
          try {
            await addTasksToCompanyOrder(createdOrder!.id, defaultFormationOrderTasks)
            showSuccessNotification('Added Successfully')
          } catch (e) {
            showApiResponseError(e, 'Error Saving Data')
          }
        }
        setFormationOrder(createdOrder)
      } catch (err) {
        showApiResponseError(err, 'Could not create formation order')
      }
    },
    [companyDetailsInputFields.commission, companyDetailsInputFields.price, setFormationOrder]
  )

  const deleteFormationOrder = React.useCallback(async () => {
    if (!formationOrder) return
    try {
      await deleteOrder(formationOrder.id)
      setFormationOrder(null)
    } catch (err) {
      showApiResponseError(err, 'Could not delete formation order')
    }
  }, [formationOrder])

  const getFormationOrderAndSetState = async (companyId: number) => {
    // try {
    //   const formationOrders = await findOrders({
    //     orderTypeQuery: BUSINESS_FORMATION_ORDER_TYPE_NAME,
    //     companyId: companyId,
    //   })
    //   if (formationOrders && formationOrders.length) {
    //     setFormationOrder(formationOrders[0])
    //     onInputFieldsChange('shouldCreateFormationOrder', true)
    //     onInputFieldsChange('price', formationOrders[0].price)
    //     if (formationOrders[0].commission) onInputFieldsChange('commission', formationOrders[0].commission)
    //   } else if (formationOrder) {
    //     // reset formation order if we haven't got anything in the response
    //     setFormationOrder(null)
    //     onInputFieldsChange('shouldCreateFormationOrder', false)
    //     onInputFieldsChange('price', 0)
    //     onInputFieldsChange('commission', 0)
    //   }
    // } catch (err) {
    //   showApiResponseError(err, 'Could not fetch formation order')
    // }
    try {
      const formationOrders = await fetchOrderTypeByName('')
      if (formationOrders && formationOrders.length) {
        setFormationOrder(formationOrders[0])
        onInputFieldsChange('shouldCreateFormationOrder', true)
        onInputFieldsChange('price', formationOrders[0].price)
        if (formationOrders[0].commission) onInputFieldsChange('commission', formationOrders[0].commission)
      } else if (formationOrder) {
        // reset formation order if we haven't got anything in the response
        setFormationOrder(null)
        onInputFieldsChange('shouldCreateFormationOrder', false)
        onInputFieldsChange('price', 0)
        onInputFieldsChange('commission', 0)
      }
    } catch (err) {
      showApiResponseError(err, 'Could not fetch formation order')
    }
  }

  const getClients = useCallback(async (query: string) => {
    setClients(undefined)
    const clients = await searchClients(query || '')
    setClients(clients)
  }, [])

  return {
    inputFieldsErrors,
    setErrors,
    companyDetailsInputFields,
    setCompanyDetailsInputFields,
    getClients,
    clients,
    onInputFieldsChange,
    validateCompanyDetailsFields,
    formationOrder,
    setFormationOrder,
    createOrUpdateFormationOrder,
    deleteFormationOrder,
    getFormationOrderAndSetState,
  }
}

export default useCompanyDetails
