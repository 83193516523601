import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '1.3rem',
      },
    },

    proTipCard: {
      background: '#f7faff',
      padding: '1rem 2rem 2rem',
      boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.16)',
      marginTop: '2rem',

      '& img': {
        width: '84px',
        margin: '1.5rem 0 0.9rem 0',
        // objectFit: 'contain',
        height: '84px',
      },

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .title-text': {
        color: '#307abc',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        fontWeight: 600,
      },
      '& .heading-text': {
        fontSize: '1rem',
        color: '#020202',
        fontWeight: 600,
      },
      '& .content-text': {
        padding: '1rem 0',

        '& li': {
          listStyleType: 'auto',
        },
      },
      '& .power-btn': {
        color: '#5b38da',
        fontSize: '1rem',
        padding: 0,
      },

      '& .protip-container': {
        '& .Mui-disabled': {
          color: '#957bf3 !important',
        },
        '& .MuiButtonBase-root': {
          color: '#5b38da',
        },
      },
    },
  })
)
