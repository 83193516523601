import {
  Badge,
  Chip,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core'
import {
  AssignmentInd,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Launch,
  NotificationsActive,
  Person,
  Visibility,
} from '@material-ui/icons'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import * as React from 'react'
import { OrderTypes } from 'utils/constant'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import { postpaymentStepMappings, stepMappings } from 'utils/stepmappings'
import useRouter from 'hooks/router'
import InnerTableItem from './innerTableItem'
import { Link } from 'react-router-dom'

interface IProspectTableRowProps {
  prospect: any
  onDeleteClick: () => void
}

const ProspectTableRow: React.FC<IProspectTableRowProps> = ({ prospect, onDeleteClick }) => {
  const classes = useStyles()
  const { history } = useRouter()
  const [open, setOpen] = React.useState(false)

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>{prospect?.id}</TableCell>
        <TableCell component="th" scope="row">
          {`${prospect?.first_name} ${prospect?.middle_name ? prospect?.middle_name : ''} ${prospect?.last_name}`}
        </TableCell>
        <TableCell>{prospect?.company_name}</TableCell>
        <TableCell>{prospect?.phone}</TableCell>
        <TableCell>{prospect?.email}</TableCell>
        <TableCell>{prospect?.industry_type}</TableCell>
        <TableCell>{prospect?.created_at ? formatDateToHumanFriendly(prospect?.created_at || '') : '-'}</TableCell>
        <TableCell className="icon-cell" sortDirection="desc">
          {formatDateToHumanFriendly(prospect?.last_update)}
        </TableCell>
        <TableCell>{prospect?.status}</TableCell>
        <TableCell width="150px" className="badge-cell">
          <Badge badgeContent={prospect?.count} color="error">
            <NotificationsActive color="action" titleAccess="Unread Count" />
          </Badge>
          <Badge badgeContent={prospect?.emp_sms_count} color="primary">
            <AssignmentInd color="action" titleAccess="Employee Count" />
          </Badge>
          <Badge badgeContent={prospect?.client_count} color="primary">
            <Person color="action" titleAccess="Client Count" />
          </Badge>
        </TableCell>
        <TableCell className="icon-cell">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <IconButton aria-label="expand row" size="small" onClick={() => history.push(`/prospect/${prospect?.id}`)}>
            <Visibility />
          </IconButton>
          <IconButton aria-label="expand row" size="small">
            <Link to={`/prospect/${prospect?.id}`} target="_blank" rel="noreferrer">
              <Launch color="action" fontSize="small" />
            </Link>
          </IconButton>
          <IconButton aria-label="expand row" size="small" onClick={handleDeleteIconClick}>
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <InnerTableItem taskDetails={prospect} />
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default ProspectTableRow

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
      '& .badge-cell': {
        '& > *': {
          margin: theme.spacing(1),
          cursor: 'pointer',
        },
        '& .MuiBadge-root > .MuiBadge-colorPrimary': {
          background: 'gray',
          color: '#fff',
        },
      },
      '& .icon-cell': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& a': {
          height: '20px',
        },
      },
    },
  })
)
