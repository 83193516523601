import * as React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const SkeletonContents = () => {
  const salesRepSkeleton = (
    <Skeleton variant="rect" width={'100%'} height={135} style={{ borderRadius: 4, marginTop: 25 }} />
  )
  const doubleFieldSkeleton = (
    <div style={{ display: 'flex', marginTop: 20 }}>
      <Skeleton variant="rect" width={'100%'} height={56} style={{ borderRadius: 4 }} />
      <Skeleton variant="rect" width={'100%'} height={56} style={{ borderRadius: 4, marginLeft: 15 }} />
    </div>
  )
  const singleFieldSkeleton = (
    <Skeleton variant="rect" width={'100%'} height={56} style={{ borderRadius: 4, marginTop: 15 }} />
  )

  return (
    <React.Fragment>
      <div style={{ display: 'flex', width: '60%', flexDirection: 'column' }}>
        <Skeleton variant="rect" width={250} height={35} style={{ borderRadius: 4 }} />
        <div style={{ display: 'flex', marginTop: 10 }}>
          <Skeleton variant="rect" width={250} height={56} style={{ borderRadius: 4 }} />
          <Skeleton variant="rect" width={230} height={56} style={{ borderRadius: 4, marginLeft: 10 }} />
        </div>
        {salesRepSkeleton}
        {doubleFieldSkeleton}
        {singleFieldSkeleton}
        {doubleFieldSkeleton}
        {doubleFieldSkeleton}
      </div>
    </React.Fragment>
  )
}

export default SkeletonContents
