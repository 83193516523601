import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'
import BRTextField from 'components/common/textFields/BRTextField'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useStyles } from './styles'
import showErrorNotification from 'utils/showErrorNotification'
import ProgressTable from './progressTable'
import {
  deleteProspectById,
  fetchProspectLists,
  getIndustryType,
  getProspecrFilters,
  getProspectLists,
  getProspectStatuses,
  IndustryProspectData,
  ProspectData,
} from 'api/leadManager'
import { Autocomplete } from '@material-ui/lab'
import { Clear, FormatAlignLeft } from '@material-ui/icons'
import { Search } from '@material-ui/icons'
import { OrderDueDatePicker } from 'components/common/companyOrder/orderDueDatePicker'
import { datePickerDateFormat } from 'utils/formatDate'
import ProspectTableHead from './prospectTableHead'
import useSortTable from 'hooks/useSortTable'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { skeletons } from 'components/common/table'
import ProspectTableRow from './prospectTableRow'
import DeleteDialog from 'components/notifications/deleteDialog'
import showApiResponseError from 'utils/showApiResponseError'
import IOSSwitch from 'components/common/iosSwitch'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'

interface ProgressTaskProps {
  isUpdated?: boolean
  setIsUpdated?: any
}

const ProgressTask = ({ isUpdated, setIsUpdated }: ProgressTaskProps) => {
  const classes = useStyles()
  // const [complianceTasks, setComplianceTasks] = useState<ComplianceTasksData[]>([])
  // const [prospectData, setProspectData] = useState<any>()

  // const [isLoadingTasks, setIsLoadingTasks] = useState(false)

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(25)

  const [industryTypesList, setIndustryTypesList] = useState<IndustryProspectData[]>([])
  const [prospectStatusList, setProspectStatusList] = useState<IndustryProspectData[]>([])
  const [companyOrderDueDate, setCompanyOrderDueDate] = useState<Date>()

  const [industryType, setIndustryType] = useState<IndustryProspectData>()
  const [prospectStatus, setProspectStatus] = useState<IndustryProspectData>()

  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [prospectItemToDelete, setProspectItemToDelete] = React.useState<any>()

  const [filtersList, setFiltersList] = React.useState<any>()

  const [refCodeValue, setRefCodeValue] = React.useState<string>('')
  const [utmSourceValue, setUtmSourceValue] = React.useState<string>('')
  const [utmMediumValue, setUtmMediumValue] = React.useState<string>('')
  const [utmCampaignValue, setUtmCampaignValue] = React.useState<string>('')
  const [utmContentValue, setUtmContentValue] = React.useState<string>('')

  const [startDateRange, setStartDateRange] = React.useState<Moment | null>(null)
  const [endDateRange, setEndDateRange] = React.useState<Moment | null>(null)

  const [clidsValue, setClidsValue] = React.useState<string>('')
  const clids = ['NONE', 'GCLID', 'FBCLID', 'MSCLKID']

  const [isGCLID, setIsGCLID] = React.useState(false)

  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'last_update' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(
    () => <ProspectTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(
    () =>
      fetchProspectLists(
        industryType?.id ? industryType?.id : 0,
        prospectStatus?.id ? prospectStatus?.id : 0,
        companyOrderDueDate ? companyOrderDueDate : '',
        query,
        utmMediumValue,
        utmSourceValue,
        utmCampaignValue,
        utmContentValue,
        refCodeValue,
        isGCLID,
        clidsValue
      )({
        start_date: startDateRange ? encodeURIComponent(startDateRange?.format('YYYY-MM-DD 00:00 Z')) : '',
        end_date: endDateRange ? encodeURIComponent(endDateRange?.format('YYYY-MM-DD 23:59 Z')) : '',
        sort_order: order,
        sort_by,
      }),
    [
      industryType?.id,
      prospectStatus?.id,
      companyOrderDueDate,
      query,
      utmMediumValue,
      utmSourceValue,
      utmCampaignValue,
      utmContentValue,
      refCodeValue,
      isGCLID,
      clidsValue,
      startDateRange,
      endDateRange,
      order,
      sort_by,
    ]
  )

  const pagedTable = usePagedTable<ProspectData>({
    apiCall: memoApiCall,
  })

  useEffect(() => {
    if (isUpdated) {
      pagedTable.reloadData()
    }
  }, [isUpdated, pagedTable])

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleDeleteNotificationClick = React.useCallback((prospect: any) => {
    setProspectItemToDelete(prospect)
    setDeleteItemDialogShown(true)
  }, [])
  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
    setIsUpdated(false)
  }, [setIsUpdated])

  const handleDueDateSelected = date => {
    setCompanyOrderDueDate(date)
  }

  const getInductriesList = async () => {
    try {
      const res = await getIndustryType()
      setIndustryTypesList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const getProspectsList = async () => {
    try {
      const res = await getProspectStatuses()
      setProspectStatusList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    getInductriesList()
    getProspectsList()
  }, [])

  React.useEffect(() => {
    const getFilters = async () => {
      try {
        const result = await getProspecrFilters()
        setFiltersList(result)
      } catch (error) {
        showApiResponseError(error, 'Error getting filter data')
      }
    }
    getFilters()
  }, [])

  // const getTasks = useCallback(async () => {
  //   const complianceTaskParams = {
  //     industry_type: industryType?.id,
  //     status: prospectStatus?.id,
  //     query: query,
  //     page: page + 1,
  //     page_size: rowsPerPage,
  //     last_update: companyOrderDueDate,
  //   }
  //   setIsLoadingTasks(true)
  //   try {
  //     const tasks = await getProspectLists(complianceTaskParams)
  //     setProspectData(tasks)
  //     setIsLoadingTasks(false)
  //   } catch (e) {
  //     showErrorNotification(e)
  //     setIsLoadingTasks(false)
  //   }
  // }, [companyOrderDueDate, industryType?.id, page, prospectStatus?.id, query, rowsPerPage])

  // useEffect(() => {
  //   getTasks()
  // }, [getTasks, isUpdated])

  const handleStartDateChange = date => {
    setStartDateRange(date)
  }
  const handleEndDateChange = date => {
    setEndDateRange(date)
  }

  return (
    <div className={classes.subContainer}>
      <div className={classes.datePickerFiled}>
        <FormControl className="formControl">
          <KeyboardDatePicker
            className="select-filed"
            format="MM/DD/yyyy"
            value={startDateRange}
            onChange={handleStartDateChange}
            label="Start Date"
            variant="inline"
            inputVariant="outlined"
            autoOk
          />
        </FormControl>
        <span>to</span>
        <FormControl className="formControl">
          <KeyboardDatePicker
            className="select-filed"
            format="MM/DD/yyyy"
            value={endDateRange}
            onChange={handleEndDateChange}
            label="End Date"
            variant="inline"
            inputVariant="outlined"
            autoOk
          />
        </FormControl>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Autocomplete
            // options={industryTypesList?.map(type => type.name)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={industryTypesList}
            className={classes.autocompleteField}
            freeSolo
            onChange={(_event, value: any) => setIndustryType(value)}
            value={industryType}
            renderInput={params => (
              <TextField
                {...params}
                name="industryType"
                variant="outlined"
                label="Industry Type"
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={prospectStatusList}
            className={classes.autocompleteField}
            freeSolo
            onChange={(_event, value: any) => setProspectStatus(value)}
            value={prospectStatus}
            renderInput={params => (
              <TextField
                {...params}
                name="prospectStatus"
                variant="outlined"
                label="Prospect Status"
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <OrderDueDatePicker
            className={classes.dueDatePicker}
            classes={{ root: classes.dateField }}
            onDueDateSelected={handleDueDateSelected}
            dueDateValue={companyOrderDueDate}
            dateFormat={datePickerDateFormat}
            label="Last Update"
            InputProps={{
              startAdornment: companyOrderDueDate ? (
                <IconButton onClick={() => handleDueDateSelected(null)}>
                  <Clear />
                </IconButton>
              ) : (
                ''
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="refCodeValue"
            options={filtersList?.ref_code ? (filtersList?.ref_code as string[]) : []}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setRefCodeValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="Ref Codes" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="utmSourceValue"
            options={filtersList?.utm_source ? (filtersList?.utm_source as string[]) : []}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setUtmSourceValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="UTM Source" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="utmMediumValue"
            options={filtersList?.utm_medium ? (filtersList?.utm_medium as string[]) : []}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setUtmMediumValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="UTM Medium" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="utmCampaignValue"
            options={filtersList?.utm_campaign ? (filtersList?.utm_campaign as string[]) : []}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setUtmCampaignValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="UTM Campaign" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="utmContentValue"
            options={filtersList?.utm_content ? (filtersList?.utm_content as string[]) : []}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setUtmContentValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="UTM Content" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={9}>
          <BRTextField
            placeholder="Search"
            type="text"
            variant="outlined"
            className="search-filed-container"
            value={query}
            onChange={(e: any) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="clidsValue"
            options={clids as string[]}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setClidsValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="CLIDS" variant="outlined" />}
          />
        </Grid>{' '}
        {/* <Grid item xs={3} style={{ alignSelf: 'center' }}>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={isGCLID}
                onClick={event => {
                  event.stopPropagation()
                }}
                onChange={e => {
                  setIsGCLID(!isGCLID)
                  // toggleStatus(client.id, e.target.checked)
                }}
                name="gclid"
              />
            }
            label=""
          />
          <label>No GCLID</label>
        </Grid> */}
      </Grid>
      <br />
      {/* <ProgressTable
        prospectData={prospectData || null}
        isLoadingTasks={isLoadingTasks}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setIsUpdated={setIsUpdated}
      /> */}

      <br />

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      <div className={classes.tableWrapper}>
        {pagedTable.isLoading ? (
          <Table>
            {tableHead}
            <TableBody>
              {skeletons({
                numberOfColumns: 11,
                sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
                numberOfRows: 10,
              })}
            </TableBody>
          </Table>
        ) : (
          <PagedTable
            colSpan={11}
            emptyRowText="No Prospect List Found"
            {...pagedTable.renderProps}
            renderRow={prospect => (
              <ProspectTableRow prospect={prospect} onDeleteClick={() => handleDeleteNotificationClick(prospect)} />
            )}
            header={tableHead}
          />
        )}
      </div>

      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={prospectItemToDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deleteProspectById}
      />
    </div>
  )
}

export default ProgressTask
