export enum TestID {
  ALL_ROLES_SELECTED_CHECKBOX = 'ALL_ROLES_SELECTED_CHECKBOX',

  // address component
  DELETE_ADDRESS_BUTTON = 'DELETE_ADDRESS_BUTTON',
  EDIT_ADDRESS_BUTTON = 'EDIT_ADDRESS_BUTTON',

  // address dialog
  ADDRESS_LINE = 'ADDRESS_LINE',
  CITY = 'CITY',
  STATE_SELECT = 'STATE_SELECT',
  CITY_SELECT = 'CITY_SELECT',

  STATE = 'STATE',
  ZIP = 'ZIP',

  // order template table page
  ORDER_TEMPLATE_ROW = 'ORDER_TEMPLATE_ROW',
  SEARCH_QUERY = 'SEARCH_QUERY',

  // company order config dialog
  ORDER_TASK_ROW = 'ORDER_TASK_ROW',

  ORDER_TYPE_SELECT = 'ORDER_TYPE_SELECT',
  ASSIGNEE_ASYNC_SELECT_DROPDOWN = 'ASSIGNEE_ASYNC_SELECT_DROPDOWN',

  // addresses components
  ADDRESS_RADIO = 'ADDRESS_RADIO',
  ADDRESS_INFO = 'ADDRESS_INFO',

  // submit form button
  SUBMIT_FORM_BUTTON = 'SUBMIT_FORM_BUTTON',

  // tax order details
  SAVE_TAX_ORDER_BUTTON = 'SAVE_TAX_ORDER_BUTTON',

  // ---------------------------------------------------------------------
  // Autocomplete
  AUTOCOMPLETE_FIELD = 'autocomplete-field',
  AUTOCOMPLETE_OPTION = 'autocomplete-option',
  INCORPORATOR_AUTOCOMPLETE = 'company-incorporator',

  // Company order dialog
  DIALOG_CLOSE_ICON = 'dialog-close-icon',
  // ---------------------------------------------------------------------
  // company creation form

  // step 3
  DIRECTOR_MEETING_ADDRESS_SELECT = 'DIRECTOR_MEETING_ADDRESS_SELECT',

  // ---------------------------------------------------------------------
}
