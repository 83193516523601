import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { ICompany } from 'models/company'
import { IClient } from 'models/user'

export interface INotificationLogs {
  id: number
  sent_to: string
  type: string
  company_id: number
  client_id: number
  order_id: number
  subject: string
  content: string
  send_time: string
  sender: string
  client?: IClient
  company?: ICompany
  notification_type?: string
}

export const fetchNotificationLogs = () => requestPaginated<INotificationLogs[]>({ url: 'sentnotifications' })
export const fetchNotificationLogsById = (companyId: number) =>
  requestPaginated<INotificationLogs[]>({ url: `sentnotifications?company_id=${companyId}` })

export const postNotificationType = async (payload: any): Promise<AxiosResponse<void>> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000'
  return await apiClient.post('/remarketing-sequence-start', payload)
}
