import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import Addresses from 'components/common/addresses'
import AddressesSelect from 'components/common/addresses/addressesSelect'
import DatePicker from 'components/common/datePicker'
import { positiveNumericInput } from 'components/common/functions'
import useStyles from 'components/common/styles/createDialogStyles'
import EinInput from 'components/common/textFields/einInput'
import { CompanyCreationFlowAddressKey, IInputFieldsErrors } from 'components/companies/create'
import { IMeetingReportFields } from 'components/companies/hooks/meetingReport'
import { IAddress } from 'models/address'
import moment from 'moment'
import * as React from 'react'
import { datePickerDateFormat, datePickerDatePlaceholder } from 'utils/formatDate'
import { ValueOf } from 'utils/objectUtils'
import { TestID } from 'utils/testUtils/TestID'

interface IMeetingReport {
  values: IMeetingReportFields
  onInputFieldsChange: (key: keyof IMeetingReportFields, value: ValueOf<IMeetingReportFields>) => void
  onAddAddressClick(field: CompanyCreationFlowAddressKey): void
  companyAddresses: IAddress[]
  onAddressEditClick: (key: CompanyCreationFlowAddressKey) => (id: number) => void
  onAddressDeleteClick: (key: CompanyCreationFlowAddressKey) => (id: number) => void
  errors: IInputFieldsErrors
  selectedCompanyAddress?: IAddress // address that has
  // been selected by the user on the 1st step
}

const MeetingReport: React.FC<IMeetingReport> = ({
  errors,
  values,
  onInputFieldsChange,
  companyAddresses,
  onAddAddressClick,
  onAddressEditClick,
  onAddressDeleteClick,
  selectedCompanyAddress,
}) => {
  const classes = useStyles()
  const [meetingAddressesPrefilled, setPrefilledMeetingAddresses] = React.useState<boolean>(false)

  const prefillMeetingAddresses = React.useCallback(
    (companyAddress: IAddress) => {
      const selectedAddress = companyAddresses.find(
        (address: IAddress) =>
          companyAddress.city === address.city &&
          companyAddress.country === address.country &&
          companyAddress.locality === address.locality &&
          companyAddress.postal_code === address.postal_code &&
          companyAddress.street === address.street
      )
      if (!selectedAddress) return
      onInputFieldsChange('directorsMeetingAddress', selectedAddress)
      onInputFieldsChange('shareholdersMeetingAddress', selectedAddress)
      setPrefilledMeetingAddresses(true)
    },
    [onInputFieldsChange, companyAddresses]
  )

  const shouldPrefillCompanyMeetings = React.useCallback(
    () =>
      !meetingAddressesPrefilled &&
      !values.directorsMeetingId &&
      !values.shareholdersMeetingId &&
      companyAddresses.length,
    [companyAddresses.length, values.directorsMeetingId, values.shareholdersMeetingId, meetingAddressesPrefilled]
  )

  React.useEffect(() => {
    if (selectedCompanyAddress && shouldPrefillCompanyMeetings()) {
      prefillMeetingAddresses(selectedCompanyAddress)
    }
  }, [selectedCompanyAddress, prefillMeetingAddresses, shouldPrefillCompanyMeetings])

  const handleShareholderMeetingAddressChange = React.useCallback(
    (newAddressId: number | null) => {
      const selectedCompanyAddress = companyAddresses.find(address => address.id === newAddressId) ?? null
      onInputFieldsChange('shareholdersMeetingAddress', selectedCompanyAddress)
    },
    [companyAddresses, onInputFieldsChange]
  )

  const handleDirectorsMeetingAddress = React.useCallback(
    (newAddressId: number | null): void => {
      const selectedAddress = companyAddresses.find(address => address.id === newAddressId) ?? null
      onInputFieldsChange('directorsMeetingAddress', selectedAddress)
    },
    [companyAddresses, onInputFieldsChange]
  )
  React.useEffect(() => {
    // if(companyAddresses){
    //   let temp: IAddress = companyAddresses[0]
    //   values.directorsMeetingAddress = companyAddresses[0]
    // }
  }, [])
  return (
    <div className={classes.innerContainer}>
      <div className={classes.formContainer}>
        <DatePicker
          value={values.fileDate}
          style={{ width: '100%' }}
          classes={{ root: classes.fullWidth }}
          label="File Date"
          placeholder={datePickerDatePlaceholder}
          format={datePickerDateFormat}
          onDateSelected={value => onInputFieldsChange('fileDate', moment(value))}
        />
        <DatePicker
          value={values.formationMeetingDate}
          classes={{ root: classes.fullWidth }}
          onDateSelected={value => onInputFieldsChange('formationMeetingDate', moment(value))}
          format={datePickerDateFormat}
          placeholder={datePickerDatePlaceholder}
          style={{ width: '100%' }}
          label="Meeting Date"
        />
        <EinInput
          style={{ width: '100%' }}
          classes={{ container: classes.fullWidthContainer }}
          label="EIN Number"
          error={errors.ein}
          value={values.ein}
          onChange={(value: string) => {
            onInputFieldsChange('ein', value)
          }}
        />
        <TextField
          label="State ID Number"
          variant="outlined"
          value={values.stateId}
          onChange={event => onInputFieldsChange('stateId', event.target.value)}
          style={{ width: '100%', marginTop: '0.6rem' }}
        />
        <Typography className={classes.sectionTitle}>Directors Meeting</Typography>
        <KeyboardDateTimePicker
          value={values.directorsMeetingDate}
          onChange={value => onInputFieldsChange('directorsMeetingDate', value?.format('YYYY-MM-DD HH:mm Z'))}
          style={{ width: '100%' }}
          format="MM/DD hh:mm A"
          label="Meeting Date And Time"
          autoOk
          inputVariant="outlined"
        />
        {!companyAddresses ? (
          <AddressesSelect
            className={classes.textField}
            onAddAddressClick={() => onAddAddressClick('directorsMeetingAddress')}
            addresses={companyAddresses}
            label="Directors Meeting Address"
            dataTestId={TestID.DIRECTOR_MEETING_ADDRESS_SELECT}
            onChange={handleDirectorsMeetingAddress}
          />
        ) : (
          <Addresses
            label="Address Details"
            onlyOneAddress={true}
            addresses={values.directorsMeetingAddress !== undefined ? [values.directorsMeetingAddress] : []} //{companyAddresses}
            onAddressEditClick={() => onAddAddressClick('directorsMeetingAddress')} //{onAddressEditClick('directorsMeetingAddress')}
            onAddAddressClick={() => onAddAddressClick('directorsMeetingAddress')}
            onAddressDeleteClick={onAddressDeleteClick('directorsMeetingAddress')}
          />
        )}
        <Typography className={classes.sectionTitle}>Shareholder Meeting</Typography>
        <KeyboardDateTimePicker
          value={values.shareholdersMeetingDate}
          onChange={value => onInputFieldsChange('shareholdersMeetingDate', value?.format('YYYY-MM-DD HH:mm Z'))}
          style={{ width: '100%' }}
          format="MM/DD hh:mm A"
          label="Meeting Date And Time"
          autoOk
          inputVariant="outlined"
        />
        {/* {!values.shareholdersMeetingAddress ? (
          <AddressesSelect
            className={classes.textField}
            onAddAddressClick={() => onAddAddressClick('shareholdersMeetingAddress')}
            addresses={companyAddresses}
            label="Shareholders Meeting Address"
            onChange={handleShareholderMeetingAddressChange}
          />
        ) : (
          <Addresses
            label="Address Details"
            onlyOneAddress={true}
            addresses={[values.shareholdersMeetingAddress]}
            onAddressEditClick={onAddressEditClick('shareholdersMeetingAddress')}
            onAddressDeleteClick={onAddressDeleteClick('shareholdersMeetingAddress')}
          />
        )} */}
        {/* {!companyAddresses ? (
          <AddressesSelect
            className={classes.textField}
            onAddAddressClick={() => onAddAddressClick('shareholdersMeetingAddress')}
            addresses={companyAddresses}
            label="Shareholders Meeting Address"
            onChange={handleShareholderMeetingAddressChange}
          />
        ) : (
          <Addresses
            label="Address Details"
            onlyOneAddress={true}
            addresses={companyAddresses}
           // onAddressEditClick={onAddressEditClick('shareholdersMeetingAddress')}
           onAddressEditClick={() => onAddAddressClick('shareholdersMeetingAddress')}
            
            onAddressDeleteClick={onAddressDeleteClick('shareholdersMeetingAddress')}
          />
        )} */}
        {!companyAddresses ? (
          <AddressesSelect
            className={classes.textField}
            onAddAddressClick={() => onAddAddressClick('shareholdersMeetingAddress')}
            addresses={companyAddresses}
            label="Shareholders Meeting Address"
            onChange={handleShareholderMeetingAddressChange}
          />
        ) : (
          <Addresses
            label="Address Details"
            onlyOneAddress={true}
            //addresses={companyAddresses}
            addresses={values.shareholdersMeetingAddress !== undefined ? [values.shareholdersMeetingAddress] : []} //{companyAddresses}
            // onAddressEditClick={onAddressEditClick('shareholdersMeetingAddress')}
            onAddAddressClick={() => onAddAddressClick('shareholdersMeetingAddress')}
            onAddressEditClick={() => onAddAddressClick('shareholdersMeetingAddress')} //{() => onAddressEditClick('shareholdersMeetingAddress')}
            onAddressDeleteClick={onAddressDeleteClick('shareholdersMeetingAddress')}
          />
        )}
      </div>
    </div>
  )
}

export default MeetingReport
