import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',

      '& .icon-wrapper': {
        background: '#eaecf0',
        padding: '0.5rem',
        borderRadius: '4px',
        marginRight: '1rem',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

interface ModalWrapperProps {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  children?: React.ReactNode | React.ReactNode[]
  heading: string
  maxWidth?: any
}

const ModalWrapper = ({ open, setOpen, children, heading, maxWidth }: ModalWrapperProps) => {
  const useDiagloadStyles = makeStyles({
    dialogRoot: {
      fontFamily: 'Roboto',

      '& .MuiDialog-paper': {
        // background: '#F4F5F7',
        padding: '1rem 0.5rem 2.5rem',
      },
      '& .icon-wrapper': {
        background: '#eaecf0',
        padding: '0.5rem',
        borderRadius: '4px',
        marginRight: '1rem',
      },
    },
  })

  const handleClose = () => {
    setOpen(false)
  }
  const classes = useDiagloadStyles()

  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : 'sm'}
      fullWidth
      onClose={handleClose}
      open={open}
      className={classes.dialogRoot}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {heading}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
export default ModalWrapper
