import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo } from 'react'
import { useStyles } from './styles'
import { skeletons } from 'components/common/table'
import LogsRow from './table/logsRow'
import LogsHead from './table/logsHead'
import { useLocation } from 'react-router'
import { fetchFailedPayments } from 'api/failedPayment'
import { IFailedPaymentReponse } from '../../api/failedPayment'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

const PaymentFailedLogs = () => {
  const classes = useStyles()

  const { state } = useLocation<any>()

  console.log('121212121 state', state)

  const [query, setQuery] = React.useState('')
  const [status, setStatus] = React.useState<boolean>(false)

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'created_at' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(() => <LogsHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />, [
    sortSettings,
    handleSortingClick,
  ])

  const memoApiCall = useMemo(() => fetchFailedPayments()({ sort_order: order, sort_by, query, read: status }), [
    order,
    query,
    sort_by,
    status,
  ])

  const pagedTable = usePagedTable<IFailedPaymentReponse>({
    apiCall: memoApiCall,
  })

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleStatus = (event: React.MouseEvent<HTMLElement>, newStatus: boolean) => {
    setStatus(newStatus)
  }

  console.log('pagedTable', pagedTable)

  return (
    <div className={classes.notificationSection}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className="notification-text">Logs</p>
          <div className={classes.headerSec}>
            <p className="table-text">Failed Payment Logs</p>

            <ToggleButtonGroup aria-label="payment status" value={status} exclusive onChange={handleStatus}>
              <ToggleButton value={false} aria-label="Failed">
                Failed
              </ToggleButton>
              <ToggleButton value={true} aria-label="Complete">
                Complete
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search....." />

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({
              numberOfColumns: 9,
              sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100],
              numberOfRows: 10,
            })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={9}
          emptyRowText="No Payments Fail Logs"
          {...pagedTable.renderProps}
          renderRow={(logs: IFailedPaymentReponse) => <LogsRow logs={logs} reloadTable={handleSuccessfulRequest} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default PaymentFailedLogs
