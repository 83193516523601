import { Button, Dialog, Divider, Grid, Link, useMediaQuery } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from './style'
import ScheduleCallCard from './scheduleCallCard'
import HeadingHeader from './headingHeader'
import CompanyCard from './companyCard'

import DeadlineTable from './table/deadlineTable'
import HistoryTabs from './tabs'
import { checkMissingItemExist, getClientCompanies, getSpecialOffers } from 'api/clientDashboard'
import { ICompany } from 'models/company'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import CompanyCardSkeleton from './companyCardSkeleton'
import { OfferCard, OfferDialog } from './offers'
import useRouter from 'use-react-router'
import { Alert, AlertTitle, Skeleton, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import useUser from 'hooks/useUser'
import ModalHeader from 'nav/Commision-Card/modalHeader'
import ShareEarnModal from 'nav/Commision-Card/shareEarnModal'
import EarnModal from 'nav/Commision-Card/earnModal'
import PowerUpCard from './client/powerUpCard'
import { ISpecialOffersCategory, getSpecialoffersCategories } from 'api/specialOffers'
import AddCompany from './Order/AddCompany'
import ExistingCompanyProvider from 'providers/existingCompany/existingCompany.provider'
import { ArrowRightAlt } from '@material-ui/icons'
import { useHistory } from 'react-router'
import NewCompanyCard from './newCompanyCard'
import { filterCompanyNamesByStatus } from 'utils/functions'
import CalendlyModal from './CalendlyModal'
import { InlineWidget, PopupWidget } from 'react-calendly'
import { theme } from 'theme'
import { fetchMissingItemsCount } from 'api/clients'

const ClientDashboard = () => {
  const classes = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const [companyDetails, setcompanyDetails] = useState<ICompany[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [offers, setOffers] = useState<any>([])
  const [isOffer, setIsOffer] = useState<boolean>(false)
  const [offerDate, setOfferDate] = useState<any>()

  const [status, setStatus] = React.useState<boolean>(true)
  const [isSchedule, setisSchedule] = React.useState<boolean>(false)

  const [isOpen, setIsOpen] = useState(false)
  const [isEarnOpen, setIsEarnOpen] = useState(false)
  const [specialOfferCategories, setSpecialOfferCategories] = useState<ISpecialOffersCategory[]>([])
  const [reviewCompanies, setReviewCompanies] = useState([])
  const [missingItemExist, setmissingItemExist] = useState(false)
  const [counts, setCounts] = useState<any>()

  const { user } = useUser()

  const { location } = useRouter()
  const router = useHistory()

  React.useEffect(() => {
    const state = location?.state as { companyModal: boolean }
    if (state?.companyModal) {
      setIsOpen(false)
    } else if (location?.state) {
      setIsOpen(true)
    }
  }, [location?.state])

  React.useEffect(() => {
    // updatePageVisitsCount()
  }, [])

  const checkItems = async () => {
    const data = await checkMissingItemExist('open')
    const itemExist = data.items?.length ? true : false
    setmissingItemExist(itemExist)
  }
  React.useEffect(() => {
    checkItems()
  }, [])

  const getCompanyDetails = React.useCallback(async () => {
    setIsLoading(true)

    try {
      const data: any = await getClientCompanies(status)
      if (data.error) setcompanyDetails([])
      else {
        setcompanyDetails(data)
        setReviewCompanies(filterCompanyNamesByStatus(data, 'Under Review'))
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
    setIsLoading(false)
  }, [status])

  const getOffers = async () => {
    try {
      const offers = await getSpecialOffers()
      setOffers(offers)
    } catch (error) {
      console.log('error', error)
    }
  }

  const getCountsOfCompaniesAndTaxOrder = async () => {
    const response = await fetchMissingItemsCount()
    setCounts(response)
  }

  useEffect(() => {
    getCompanyDetails()
  }, [getCompanyDetails, status])

  useEffect(() => {
    getOffers()
  }, [])

  const offerModalHandler = (data: any) => {
    setIsOffer(true)
    setOfferDate(data)
  }

  const handleStatus = (event: React.MouseEvent<HTMLElement>, newStatus: boolean) => {
    setStatus(newStatus)
  }
  const checkIsCompanyCompleted = () => {
    // check if atleast one company is completed //
    const atLeastOneCompanyCompleted = companyDetails.some(company => company.file_date?.length > 0)
    return atLeastOneCompanyCompleted
  }

  useEffect(() => {
    const getSpecialoffersCat = async () => {
      try {
        const result: ISpecialOffersCategory[] = await getSpecialoffersCategories()
        setSpecialOfferCategories(result)
      } catch (error) {
        console.log('error', error)
      }
    }
    getSpecialoffersCat()
  }, [])

  useEffect(() => {
    getCountsOfCompaniesAndTaxOrder()
  }, [])

  return (
    <div className={classes.clientDashboard}>
      {/* <p className="user-company-name">User</p> */}
      <h1 className="main-heading">Dashboard</h1>
      <Divider />
      <br />
      {/* <Divider /> */}
      {/* <br />
      <ScheduleCallCard />
      <br /> */}

      <HeadingHeader title="Upcoming Deadlines" />

      <br />
      <HistoryTabs isDashboard companyDetails={companyDetails} />
      <div className="companies-header">
        <HeadingHeader title="Companies" isLink={false} />
        <div>
          {/* <ExistingCompanyProvider>
            <AddCompany />
          </ExistingCompanyProvider> */}
          <ToggleButtonGroup aria-label="company status" value={status} exclusive onChange={handleStatus}>
            <ToggleButton value={true} aria-label="active">
              Active
            </ToggleButton>
            <ToggleButton value={false} aria-label="Inactive">
              InActive
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      {missingItemExist && counts?.count && (
        <div
          style={{
            background: '#fdecea',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2%',
            borderRadius: '4px',
          }}
        >
          <p
            style={{
              marginLeft: '2%',
              fontSize: '0.875rem',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
              lineHeight: '1.43',
            }}
          >
            <strong>ATTENTION!</strong>
            <br />
            {/* You have open missing items that need your attention. */}
            {/* You have {counts?.count} missing items in {counts?.company_count} companies and {counts?.tax_order_count}{' '}
            taxorder */}
            {` You have ${counts?.count} missing item${counts?.count > 1 ? 's' : ''} in ${
              counts?.company_count
                ? `${counts?.company_count} ${counts?.company_count > 1 ? 'companies' : 'company'}`
                : ''
            }  ${
              counts?.tax_order_count
                ? `${counts?.tax_order_count} ${counts?.tax_order_count > 1 ? 'taxorders' : 'taxorder'}`
                : ''
            }
            `}
            {/* You have {counts?.count} missing items that need your */}
          </p>
          <div style={{ padding: '1%' }}>
            <Button
              color="primary"
              variant="contained"
              className={classes.reviewBtn}
              onClick={() => router.push('client-missing-item')}
            >
              Review
            </Button>
          </div>
        </div>
      )}
      <Grid container spacing={3}>
        {/* {missingItemExist && (
          <Grid item xs={12}>
            <Alert severity="error">
              <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={8}>
                    <AlertTitle>ATTENTION</AlertTitle>
                    <p>You have open missing items that need your attention.</p>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container justify="flex-end">
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.reviewBtn}
                        onClick={() => router.push('client-missing-item')}
                      >
                        Review
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Alert>
          </Grid>
        )} */}

        {reviewCompanies?.length ? (
          <Grid item xs={12}>
            <div className="header">
              Your {reviewCompanies.length === 1 ? 'company' : 'companies'}{' '}
              <span className="companies-name">{reviewCompanies.join(', ') || ''} </span>
              LLC compliance is brewing. Please allow 24-48 hrs for our system to gather information about your company.
            </div>
          </Grid>
        ) : (
          ''
        )}
        {isLoading ? (
          [...Array(6)].map((value: undefined, index: number) => (
            <Grid item xs={12} md={6} xl={4} key={index}>
              <CompanyCardSkeleton />
            </Grid>
          ))
        ) : (
          <>
            {companyDetails?.length > 0 ? (
              companyDetails?.map((company, key) => (
                <Grid item xs={12} md={12} lg={6} xl={4} key={key}>
                  <NewCompanyCard
                    name={company?.name}
                    city={company?.state_of_formation || ''}
                    date={company?.file_date?.length ? formatDateToHumanFriendly(company?.file_date) : 'Pending'}
                    companyId={company?.id}
                    file_date={company?.file_date}
                    profile_score={company?.profile_score}
                    business_active={company?.business_active}
                    business_active_status={company?.business_active_status}
                    expected_ein_date={company?.expected_ein_date}
                    expected_file_date={company?.expected_file_date}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Alert severity="info">No Company Found!</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={6} xl={4}>
              <ExistingCompanyProvider>
                <AddCompany />
              </ExistingCompanyProvider>
            </Grid>
            <Grid item xs={12}>
              <p className="ques">
                If you have any questions regarding our platform or this entity, arrange a meeting with one of our team
                members.{' '}
                <Link className="link-schedule" onClick={() => setisSchedule(true)}>
                  Schedule
                </Link>
              </p>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={matches ? true : false}
        open={isSchedule}
        onClose={() => setisSchedule(false)}
      >
        <ModalHeader id="calendly-modal" onClose={() => setisSchedule(false)} />
        <InlineWidget styles={{ height: '100vh' }} url="https://calendly.com/d/2c3-mdn-v3b" />
      </Dialog>

      <br />
      <div className="power-ups-section">
        <HeadingHeader
          title="Power Ups"
          isLink={true}
          linkText="See All"
          linkTo="/power-up/all"
          // secondaryComponent={
          //   <Button
          //     // variant="outlined"
          //     // endIcon={<ArrowRightAlt />}
          //     color="primary"
          //     onClick={() => router.push('/power-up/all')}
          //   >
          //     See All
          //   </Button>
          // }
        />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-end">
            {specialOfferCategories?.length > 0
              ? specialOfferCategories?.map((offer: ISpecialOffersCategory) => (
                  <Grid item xs={12} sm={6} lg={4} xl={3} key={offer.id}>
                    <PowerUpCard
                      category_img={offer?.category_img}
                      title={offer?.title}
                      link_text={offer?.link_text}
                      heading={offer?.heading}
                      description={offer?.description}
                      id={offer?.id}
                    />
                  </Grid>
                ))
              : [...Array(6)].map((value: undefined, index: number) => (
                  <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                    <Skeleton variant="rect" height={145} />
                  </Grid>
                ))}
            {/* <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Button
                variant="outlined"
                endIcon={<ArrowRightAlt />}
                color="primary"
                onClick={() => router.push('/power-up/all')}
              >
                See All
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container spacing={3}>
        {offers.length
          ? offers?.map((offer, key) => (
              <Grid item xs={6} sm={4} md={2} key={key}>
                <OfferCard
                  img={offer.logo}
                  // mainTextColor={offer.color}
                  borderColor={offer.color}
                  mainText={offer.title}
                  onClick={() => offerModalHandler(offer)}
                  atLeastOneCompanyCompleted={checkIsCompanyCompleted()}
                  completed_orders={offer.completed_orders}
                />
              </Grid>
            ))
          : [...Array(6)].map((value: undefined, index: number) => (
              <Grid item xs={2} key={index}>
                <Skeleton variant="rect" height={145} />
              </Grid>
            ))}
      </Grid>
      <OfferDialog
        open={isOffer}
        setOpen={setIsOffer}
        title={offerDate?.title}
        question={offerDate?.company_prompt}
        answers={offerDate?.link}
        link={offerDate?.link}
        btnText={offerDate?.button_text}
        isPopup={offerDate?.ispopup}
        offerId={offerDate?.id}
        companies={companyDetails}
      /> */}
      {/* Earn and Share Modal */}
      <Dialog maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalHeader id="share-earn-modal" onClose={() => setIsOpen(false)} />
        <ShareEarnModal
          user={user}
          handler={() => {
            setIsEarnOpen(true)
            setIsOpen(false)
          }}
        />
      </Dialog>
      <Dialog maxWidth="sm" open={isEarnOpen} onClose={() => setIsEarnOpen(false)}>
        <ModalHeader
          id="earn-modal"
          onClose={() => setIsEarnOpen(false)}
          onBack={() => {
            setIsEarnOpen(false)
            setIsOpen(true)
          }}
        />
        <EarnModal user={user} />
      </Dialog>
    </div>
  )
}

export default ClientDashboard
