import * as React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 50,
      textTransform: 'none',
      fontWeight: 'bold',
      color: theme.button.backgroundColor,
      '&:hover': {
        color: theme.button.hover.color,
        backgroundColor: theme.button.hover.backgroundColor,
      },
      borderColor: theme.palette.primary.main,
    },
  })
)

interface ISecondaryButtonProps {
  onClick?: () => void
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  classes?: Record<string, string>
  disabled?: boolean
  isLoading?: boolean
}

const SecondaryButton: React.FC<ISecondaryButtonProps> = (props: ISecondaryButtonProps) => {
  const classes = styles(props)
  const { onClick, children, style, disabled, isLoading } = props
  return (
    <Button
      disabled={disabled || isLoading}
      style={style}
      variant="outlined"
      onClick={onClick}
      className={classes.button}
    >
      {children} {isLoading && <CircularProgress size={15} style={{ marginLeft: '1rem' }} />}
    </Button>
  )
}

export default SecondaryButton
