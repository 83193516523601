import React, { useContext } from 'react'
import CommonInput from '../CommonInput'
import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AddressFileds from '../addressFileds'
import { ILegalTypes, legalTypesLists } from 'utils/autocompleteFakers'
import { ExistingCompanyContext } from 'providers/existingCompany/existingCompany.provider'

export interface IStateProps {
  id: number
  state_display_name: string
}

const Step1 = () => {
  const classes = useStyles()
  const { formationStateList, setFormationState, setCompanyType, address, setAddress, name, setName } = useContext(
    ExistingCompanyContext
  )

  return (
    <Grid container spacing={0}>
      <h2 className={classes.subHeading}>Company Information</h2>

      <Autocomplete
        getOptionSelected={(option, value) => option.state_display_name === value.state_display_name}
        getOptionLabel={option => option.state_display_name}
        options={formationStateList}
        id="state-auto-complete"
        autoComplete
        includeInputInList
        placeholder="States"
        className={classes.autoComplete}
        renderInput={params => (
          <TextField
            {...params}
            label="What state is your business registered in? "
            placeholder="State"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        onChange={(_, value) => setFormationState(value as IStateProps)}
      />

      <CommonInput
        label={'What is your existing company name?'}
        palcehodler={'Company Name'}
        name="name"
        value={name}
        onChange={(e: any) => {
          setName(e.target.value)
        }}
      />

      <Autocomplete
        getOptionSelected={(option, value) => option.label === value.label}
        getOptionLabel={option => option.label}
        options={legalTypesLists}
        id="state-auto-complete"
        autoComplete
        includeInputInList
        placeholder="States"
        className={classes.autoComplete}
        renderInput={params => (
          <TextField
            {...params}
            label="What is your company type? "
            placeholder="Company Type"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        onChange={(_, value) => setCompanyType(value as ILegalTypes)}
      />

      <AddressFileds addressObj={address} setAddressObj={setAddress} />
    </Grid>
  )
}

export default Step1

const useStyles = makeStyles(theme =>
  createStyles({
    autoComplete: {
      width: '100%',

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInputBase-root ': {
        fontSize: '0.9rem',
        background: '#f7f8f9',
        padding: '0 0.5rem',
      },
      '& .MuiFormLabel-root': {
        // fontSize: '0.9rem',
        fontFamily: 'Roboto, sans-serif',
        textTransform: 'uppercase',
        color: '#202124',
      },
      '& .MuiInputLabel-shrink': {
        [theme.breakpoints.down('sm')]: {
          width: '400px',
        },
      },
    },

    subHeading: {
      color: '#202124 ',
      fontSize: '1.25rem',
      margin: 0,
    },
  })
)
