import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo } from 'react'
import { useStyles } from './styles'
import { skeletons } from 'components/common/table'
import { fetchNotificationLogs, INotificationLogs } from 'api/notificationLogs'
import LogsHead from 'components/notificationLogs/table/logsHead'
import LogsRow from 'components/notificationLogs/table/logsRow'
import { updatePageVisitsCount } from 'utils/user'

const NotificationLogs = () => {
  const classes = useStyles()

  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'send_time' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(() => <LogsHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />, [
    sortSettings,
    handleSortingClick,
  ])

  const memoApiCall = useMemo(() => fetchNotificationLogs()({ sort_order: order, sort_by, query }), [
    order,
    query,
    sort_by,
  ])

  const pagedTable = usePagedTable<INotificationLogs>({
    apiCall: memoApiCall,
  })

  return (
    <div className={classes.notificationSection}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className="notification-text">Logs</p>
          <p className="table-text">Notification Logs</p>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search by email/phone" />

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({
              numberOfColumns: 9,
              sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100],
              numberOfRows: 9,
            })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={9}
          emptyRowText="No Notifications Logs"
          {...pagedTable.renderProps}
          renderRow={(logs: INotificationLogs) => <LogsRow logs={logs} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default NotificationLogs
