import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { ICreatedSpecialOffersCategory, ISpecialOffersCategory } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'

export const createSpecialOfferCategory = async (category): Promise<ICreatedSpecialOffersCategory> => {
  delete category.id // delete the extid, the backend should take care of it

  return (await apiClient.post('specialofferscategories', snakeCaseKeys(category))).data
}

export const updateSpecialOfferCategory = async (
  category: ISpecialOffersCategory | any
): Promise<ICreatedSpecialOffersCategory> => {
  return (await apiClient.post(`specialofferscategories/${category.id}`, snakeCaseKeys(category))).data
}
export const fetchSpecialOfferCategory = async (id: number): Promise<ICreatedSpecialOffersCategory> => {
  return (await apiClient.get(`specialofferscategories/${id}`)).data
}

export const paginateSpecialOfferCategory = () => {
  return requestPaginated<ICreatedSpecialOffersCategory[]>({ url: '/specialofferscategories-paginated' })
}
export const deleteSpecialOfferCategory = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`specialofferscategories/${id}`)
}
export const fetchAllSpecialOfferCategories = async (): Promise<ISpecialOffersCategory[]> => {
  return (await apiClient.get(`specialofferscategories`)).data
}
