import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'reminderId', label: 'Reminder Id', style: {}, sortable: false },
  { id: 'itemName', label: 'Item Name', style: {}, sortable: false },
  { id: 'createdAt', label: 'Created At', style: {}, sortable: false },
  { id: 'createdBy', label: 'Created By', style: {}, sortable: false },
  { id: 'completedBy', label: 'Completed By', style: {}, sortable: false },
  { id: 'status', label: 'status', style: {}, sortable: false },
  // { id: 'item_response', label: 'Client Response', style: {}, sortable: false },

  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const OrderItemTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default OrderItemTableHead
