import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'id', label: `Priority`, style: {}, sortable: false },
  { id: 'filer_name', label: `Filer's Name`, style: {}, sortable: false },
  { id: 'labels', label: 'Labels', style: {}, sortable: true },

  { id: 'tax_year', label: 'Tax Year', style: {}, sortable: true },
  { id: 'type', label: 'Type', style: {}, sortable: false },
  { id: 'cpa_assigned', label: 'CPA Assigned', style: {}, sortable: false },

  { id: 'stage', label: 'Stage', style: {}, sortable: false },
  { id: 'extension', label: 'Extension', style: {}, sortable: false },
  { id: 'updated', label: 'Last Update', style: {}, sortable: true },
  { id: 'due_date', label: 'Due Date', style: {}, sortable: true },
  { id: '', label: 'Action', style: {}, sortable: false },
]

const AffiliatedTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
      headColumns={headColumns}
    />
  )
}

export default AffiliatedTableHead
