import { usePagedTable, PagedTable } from '@jetkit/react'
import { Button, Table, TableBody } from '@material-ui/core'
import {
  addOrderRemindersConfig,
  changeOrderReminderStatus,
  getOrderReminderItems,
  getTaxReminderItems,
  OrderItemProps,
  OrderReminderProps,
} from 'api/orderItem'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import React, { useCallback, useMemo, useState } from 'react'
import OrderItemRow from '../OrderItemRow'
import useSortTable from 'hooks/useSortTable'
import OrderItemTableHead from '../OrderItemTableHead'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'
import { MISSING_ITEM_STATUS, REMINDER_ITEM_STATUS } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { useStyles } from '../../style'
import moment from 'moment'
import OrderItemAccordionWrapper from '../OrderItemAccordionWrapper'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

interface MissingItemTableProps {
  orderItem: OrderReminderProps | undefined
  isTaxOrder?: boolean
}

const MissingItemTable = ({ orderItem, isTaxOrder }: MissingItemTableProps) => {
  const classes = useStyles()

  const userDetails = JSON.parse(localStorage.getItem('currentUser') || '')
  const userId = userDetails?.extid

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })
  const [selectedRowItem, setSelectedRowItem] = useState<any>({})
  const [statusConfrimModal, setStatusConfrimModal] = useState(false)
  const [statusLoader, setStatusLoader] = useState(false)
  const [notifyClient, setNotifyClient] = useState(false)
  const [remindertoggleValue, setRemindertoggleValue] = useState(REMINDER_ITEM_STATUS?.Active)

  // const [isItemModal, setIsItemModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [orderItems, setOrderItems] = useState<any>({})
  const [acceptReason, setAcceptReason] = useState('')

  const orderItemHanlder = () => {
    const orderItem = {
      selectedMissingItem: '',
      missingItemName: '',
      missingItemDescription: '',
      status: MISSING_ITEM_STATUS?.NotReceived,
    }
    const uuid = Math.random()
    setOrderItems({ ...orderItems, [uuid]: orderItem })
  }
  const orderItemsData = Object.entries(orderItems)

  const tableHead = useMemo(
    () => <OrderItemTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getOrderReminderItems(orderItem ? orderItem?.id : 0, remindertoggleValue)(), [
    orderItem,
    remindertoggleValue,
  ])

  const pagedTable = usePagedTable<OrderItemProps>({
    apiCall: memoApiCall,
  })

  const handleEditNotificationClick = React.useCallback(
    async (orderItem: OrderItemProps) => {
      setStatusLoader(true)
      const id = orderItem?.id
      const status = MISSING_ITEM_STATUS?.Received
      const notify = notifyClient
      try {
        if (id) {
          await changeOrderReminderStatus(id, { status, notify, completion_note: acceptReason })
        }
        setStatusLoader(false)
        setStatusConfrimModal(false)
        showSuccessNotification('Missing Item Status Successfully Changed')
        pagedTable.reloadData()
      } catch (error) {
        showErrorNotification(error)
      }
      setStatusLoader(false)
    },
    [notifyClient, pagedTable, acceptReason]
  )

  const addOrderHandler = useCallback(
    async orderItemID => {
      setIsLoading(true)

      const sendingArray = orderItemsData.map(([key, value]) => {
        const { missingItemName, missingItemDescription, status }: any = value
        return {
          item_name: missingItemName,
          item_description: missingItemDescription,
          status,
          created_at: moment().toString(),
          created_by: userId,
          order_reminder_id: orderItemID,
        }
      })

      try {
        await addOrderRemindersConfig({ data: sendingArray })
        setIsLoading(false)
        showSuccessNotification('Missing Item Successfully Created')
        pagedTable.reloadData()
        setOrderItems({})
      } catch (error) {
        showErrorNotification(error)
      }
      setIsLoading(false)
    },
    [orderItemsData, pagedTable, userId]
  )

  const handleSave = useCallback(() => {
    if (orderItem?.id) addOrderHandler(orderItem?.id)
  }, [addOrderHandler, orderItem?.id])

  const handleUpdateOrderItemsData = (id, field, data) => {
    orderItems[id][field] = data
    setOrderItems({ ...orderItems })
  }

  const handleDelete = id => {
    delete orderItems[id]
    setOrderItems({ ...orderItems })
  }

  return (
    <>
      <div className={classes.toggleSection}>
        <ToggleButtonGroup
          size="small"
          value={remindertoggleValue}
          exclusive
          onChange={(e, val) => setRemindertoggleValue(val)}
        >
          <ToggleButton value={REMINDER_ITEM_STATUS?.Active}>Active</ToggleButton>
          <ToggleButton value={REMINDER_ITEM_STATUS?.Complete}>Complete</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({ numberOfColumns: 7, sizes: [120, 200, 120, 120, 200, 120], numberOfRows: 6 })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Missing Items"
          {...pagedTable.renderProps}
          renderRow={(orderItem: OrderItemProps) => (
            <OrderItemRow
              orderItem={orderItem}
              handleOnAlert={(notifyVal: boolean, reason: string) => {
                setStatusConfrimModal(true)
                setSelectedRowItem(orderItem)
                setNotifyClient(notifyVal)
                setAcceptReason(reason)
              }}
              // setIsItemModal={setIsItemModal}
            />
          )}
          header={tableHead}
        />
      )}

      <DialogWithConfirmButtons
        onClose={() => setStatusConfrimModal(false)}
        open={statusConfrimModal}
        isLoading={statusLoader}
        title={`Are you sure you have received ${selectedRowItem?.item_name} ?`}
        firstOptionTitle="Yes, Received"
        secondOptionTitle="Cancel"
        onFirstOptionClick={() => handleEditNotificationClick(selectedRowItem)}
        onSecondOptionClick={() => setStatusConfrimModal(false)}
      />

      {orderItemsData?.length ? (
        <>
          {orderItemsData?.map(([key, value]) => (
            <OrderItemAccordionWrapper
              keyId={key}
              orderItemData={value}
              handleDelete={handleDelete}
              handleUpdate={handleUpdateOrderItemsData}
              isTaxOrder={isTaxOrder}
            />
          ))}

          {/* <div className={classes.addBtn}>
            <ButtonWithLoading onClick={handleSave} isLoading={isLoading}>
              Save
            </ButtonWithLoading>
          </div> */}
        </>
      ) : (
        ''
      )}
      {!orderItemsData?.length ? (
        <div className={classes.addBtn}>
          <Button className="add-button" variant="contained" color="primary" onClick={orderItemHanlder}>
            + Add Item
          </Button>
        </div>
      ) : (
        ''
      )}

      {orderItemsData?.length ? (
        <div className={classes.addBtn}>
          <Button className="add-button" variant="contained" color="primary" onClick={orderItemHanlder}>
            + Add Item
          </Button>
          <ButtonWithLoading onClick={handleSave} isLoading={isLoading}>
            Save
          </ButtonWithLoading>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default MissingItemTable
