import { OrderTypes, paymentStatusMappings } from 'utils/constant'
import {
  // otherPostPaymentStepMappingsOther,
  postpaymentStepMappings,
  // postpaymentStepMappingsCorp,
} from 'utils/stepmappings'

export const uniqueArrayObj = arr => {
  const newArr = Array.from(new Set(arr?.map(a => a?.value)))?.map(value => {
    return arr?.find(a => a?.value === value)
  })
  return newArr
}

export const checkNullValue = (value: string | number | undefined | null) => {
  if (value) {
    return value
  } else {
    return ''
  }
}

export const getPaymentDropdownVal = () => {
  return Object.entries(paymentStatusMappings).map(([value, label]) => {
    return {
      value,
      label,
    }
  })
}

export const reversedArray = (arr: any[]) => {
  return Array.from(arr).reverse()
}

// export const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g
export const htmlRegexG = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;|&lt;/g

export const checkPostStepLabel = (otherType: number | string, postStep: string) => {
  // if (OrderTypes[otherType] === 'LLC') {
  //   return postpaymentStepMappings(postStep)
  // } else if (OrderTypes[otherType] === 'Corporation') {
  //   return postpaymentStepMappingsCorp(postStep)
  // } else
  // {
  //   return otherPostPaymentStepMappingsOther(postStep)
  // }
  return postpaymentStepMappings(postStep)
}

export const objToArray = (arr: any) => {
  return Object.entries(arr).map(([value, label]) => {
    return {
      value,
      label,
    }
  })
}

export const checkCreditScore = (score: number) => {
  if (score >= 1 && score <= 9) {
    return { rank: 'D', score: score, color1: '#e74d41' }
  } else if (score > 9 && score <= 19) {
    return { rank: 'D+', score: score, color1: '#e74d41' }
  } else if (score > 19 && score <= 29) {
    return { rank: 'C-', score: score, color1: '#e74d41', color2: '#f97359' }
  } else if (score > 29 && score <= 35) {
    return { rank: 'C', score: score, color1: '#e74d41', color2: '#f97359' }
  } else if (score > 35 && score <= 42) {
    return { rank: 'C+', score: score, color1: '#e74d41', color2: '#f97359', color3: '#5bb3d9' }
  } else if (score > 42 && score <= 50) {
    return { rank: 'B-', score: score, color1: '#e74d41', color2: '#f97359', color3: '#5bb3d9' }
  } else if (score > 50 && score <= 64) {
    return { rank: 'B', score: score, color1: '#e74d41', color2: '#f97359', color3: '#5bb3d9', color4: '#2ba5d9' }
  } else if (score > 64 && score <= 74) {
    return { rank: 'B+', score: score, color1: '#e74d41', color2: '#f97359', color3: '#5bb3d9', color4: '#2ba5d9' }
  } else if (score > 74 && score <= 81) {
    return {
      rank: 'A-',
      score: score,
      color1: '#e74d41',
      color2: '#f97359',
      color3: '#5bb3d9',
      color4: '#2ba5d9',
      color5: '#1c71e1',
    }
  } else if (score > 81 && score <= 89) {
    return {
      rank: 'A',
      score: score,
      color1: '#e74d41',
      color2: '#f97359',
      color3: '#5bb3d9',
      color4: '#2ba5d9',
      color5: '#1c71e1',
      color6: '#5b38da',
    }
  } else if (score > 89 && score <= 100) {
    return {
      rank: 'A+',
      score: score,
      color1: '#e74d41',
      color2: '#f97359',
      color3: '#5bb3d9',
      color4: '#2ba5d9',
      color5: '#1c71e1',
      color6: '#5b38da',
    }
  } else {
    return { rank: 'D-', score: score, color1: '#e74d41' }
  }
}

export const filterCompanyNamesByStatus = (companies, business_status) => {
  return companies.filter(company => company?.business_active_status === business_status).map(company => company?.name)
}
