import { CircularProgress, Fab, IconButton, TableCell, TableRow } from '@material-ui/core'
import { Done, GetApp } from '@material-ui/icons'
import { IDocuments } from 'api/ai-assistant'
import DeleteIcon from 'components/common/icons/delete'
import * as React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import clsx from 'clsx'
import { useStyles } from '../styles'

interface RowProps {
  doucments: IDocuments
  onDownloadClick?: () => void
  onDeleteClick?: () => void
  isDocLoading?: boolean
}

const Row: React.FC<RowProps> = ({ doucments, onDownloadClick, onDeleteClick, isDocLoading }) => {
  const classes = useStyles()

  const handleDownloadIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDownloadClick && onDownloadClick()
    },
    [onDownloadClick]
  )

  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick && onDeleteClick()
    },
    [onDeleteClick]
  )

  return (
    <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      {/* <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}> */}
      {/* <TableCell>{doucments?.assistant_id}</TableCell> */}
      <TableCell>{doucments?.id}</TableCell>
      {/* <TableCell>{doucments?.file_id}</TableCell> */}
      <TableCell>{doucments?.file_name}</TableCell>
      <TableCell>{doucments?.uploaded_by}</TableCell>
      <TableCell>{formatDateTimeToHumanFriendly(doucments?.uploaded_on)}</TableCell>
      <TableCell style={{ minWidth: 90 }}>
        <div>
          <IconButton style={{ marginRight: 10 }} onClick={handleDownloadIconClick}>
            <GetApp color="primary" data-testid="editButton" />
          </IconButton>
          {/* <div className={classes.wrapper}>
            <Fab
              aria-label="download"
              color="primary"
              disableRipple
              className={classes.buttonSuccess}
              onClick={handleDownloadIconClick}
            >
              {!isDocLoading ? <Done /> : <GetApp />}
              &nbsp;
            </Fab>
            {isDocLoading ? <CircularProgress size={36} className={classes.fabProgress} /> : ''}
          </div> */}
          <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default Row
