import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useUser from 'hooks/useUser'

export interface IInitialStepProps {
  handleNext?: any
}

const InitialStep = ({ handleNext }: IInitialStepProps) => {
  const classes = useStyles()
  const { user } = useUser()

  return (
    <Grid container spacing={0}>
      <h2 className={classes.subHeading}>Add a Company to Dashboard</h2>
      <p>Add a company to Dashboard</p>

      <Button variant="contained" color="primary" className={classes.primary} fullWidth onClick={handleNext}>
        Add existing Company
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.secondary}
        fullWidth
        onClick={() => window.open(`https://www.businessrocket.com/start/?client_id=${user?.id}`, '_blank')}
      >
        Add NEW Company
      </Button>
    </Grid>
  )
}

export default InitialStep

const useStyles = makeStyles(theme =>
  createStyles({
    autoComplete: {
      width: '100%',

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInputBase-root ': {
        fontSize: '0.9rem',
        background: '#f7f8f9',
        padding: '0 0.5rem',
      },
      '& .MuiFormLabel-root': {
        fontSize: '0.9rem',
        fontFamily: 'Roboto, sans-serif',
        textTransform: 'uppercase',
        color: '#202124',
      },
    },
    subHeading: {
      color: '#202124 ',
      fontSize: '1.25rem',
      margin: 0,
    },
    primary: {
      color: '#fff ',
      marginBottom: '1rem',
      marginTop: '1rem',
      borderRadius: '50px',
      height: '3rem',
      fontWeight: 400,
    },
    secondary: {
      borderRadius: '50px',
      height: '3rem',
      fontWeight: 400,
      marginBottom: '5rem',
    },
  })
)
