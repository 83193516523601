import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { AssetsDeleteCallBackContext } from 'components/common/assets/documentUploads'
import DownloadButton from 'components/common/assetUpload/downloadButton'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'

import { IAsset, AssetType, UploadFileToS3Args } from 'models/asset'
import * as React from 'react'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { Checkbox, ListItem } from '@material-ui/core'
import { toggleAddressDocument, toggleClientDocuments, toggleRADocuments } from 'api/companies'
import UploadEditModal from '../assetUpload/uploadEditModal'
import { GetAppOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      maxWidth: 80,
      flexWrap: 'wrap',
    },
    downloadButton: {
      // marginLeft: 10,
      margin: '0.25rem',
      width: 40,
      height: 40,
    },
    downloadButtonLink: {
      textDecoration: 'none',
    },

    fileName: {
      marginTop: '0.25rem',
      '& p': {
        color: '#0000009c',
        fontSize: '12px',
        margin: '0',
        fontStyle: 'italic',
      },
    },
  })
)

interface IAssetRowProps {
  type: AssetType
  asset: any
  getAssetDownloadURL: (asset: IAsset) => Promise<string | undefined>
  onUploadComplete?: (args: UploadFileToS3Args) => void
  isDownload?: boolean
  handleGetRow?: (asset: any) => void
}

function AssetRow({
  type,
  asset,
  getAssetDownloadURL,
  onUploadComplete,
  isDownload = false,
  handleGetRow,
}: IAssetRowProps) {
  console.log(asset, 'ASSESTSSSSSSS')

  const onDeleteClick = React.useContext(AssetsDeleteCallBackContext)

  const [assetToBeDeleted, setAssetToBeDeleted] = React.useState<number | undefined>()
  const [deletionInProgress, setDeletionInProgress] = React.useState<boolean>(false)
  const [checked, setChecked] = React.useState<boolean>(false)

  const [isRAChecked, setisRAChecked] = React.useState<boolean>(false)
  const [isAddressChecked, setisAddressChecked] = React.useState<boolean>(false)

  const [isEdit, setIsEdit] = React.useState<boolean>(false)

  const [checkedId, setCheckedId] = React.useState<boolean>(false)

  const classes = useStyles()

  const handleDeleteClick = async () => {
    if (!onDeleteClick || !assetToBeDeleted) return
    try {
      setDeletionInProgress(true)
      await onDeleteClick(assetToBeDeleted)
      setDeletionInProgress(false)
      setAssetToBeDeleted(undefined)
      showSuccessNotification(`File ${asset.filename} was deleted`)
    } catch (err) {
      setDeletionInProgress(false)
      showApiResponseError(err, 'Could not delete the file')
    }
  }

  const handleSetAssetToDelete = React.useCallback(() => {
    setAssetToBeDeleted(asset.id)
  }, [asset.id])
  React.useEffect(() => {
    setChecked(asset.is_show_on_client)
    setisRAChecked(asset.registered_agent_document)
    setCheckedId(asset.id)
    setisAddressChecked(asset.address_document)
  }, [])
  const download = async () => {
    // alert('hehe')
    console.log(asset, 'hehe')

    const url = await getAssetDownloadURL(asset)
    if (url) window.open(url, '_blank')
    // return url?.toString();
  }

  const toggleClientDoc = async (id: number, target: any, asset: any) => {
    // value=!asset.is_show_on_client
    asset.is_show_on_client = !asset.is_show_on_client
    target.value = asset.is_show_on_client
    target.checked = asset.is_show_on_client
    setChecked(asset.is_show_on_client)
    try {
      await toggleClientDocuments(asset.id, asset.is_show_on_client)
      showSuccessNotification(`Status Updated Successfully`)
    } catch (e) {
      showApiResponseError(e, 'Error Occured!')
    }
  }

  const toggleRADoc = async (id: number, target: any, asset: any) => {
    asset.registered_agent_document = !asset.registered_agent_document
    target.value = asset.registered_agent_document
    target.checked = asset.registered_agent_document
    setisRAChecked(asset.registered_agent_document)
    try {
      await toggleRADocuments(asset.id, asset.registered_agent_document)
      showSuccessNotification(`Document Status Updated Successfully`)
    } catch (e) {
      showApiResponseError(e, 'Error Occured!')
    }
  }
  const toggleAddressDoc = async (id: number, target: any, asset: any) => {
    asset.address_document = !asset.address_document
    target.value = asset.address_document
    target.checked = asset.address_document
    setisAddressChecked(asset.address_document)
    try {
      await toggleAddressDocument(asset.id, asset.address_document)
      showSuccessNotification(`Document Status Updated Successfully`)
    } catch (e) {
      showApiResponseError(e, 'Error Occured!')
    }
  }
  const getColValue = () => {
    if (type === 'personal') {
      return asset.tax_year
    } else {
      return `${asset.orderName || ''} ${asset.order_id ? '#' + asset.order_id : ''}`
    }
  }

  const getRow = (asset: any) => {
    setCheckedId(!checkedId)
    if (handleGetRow) {
      handleGetRow(asset)
    }
  }
  return (
    <TableRow style={{ height: 56 }}>
      {isDownload ? (
        <TableCell>
          <Checkbox
            color="primary"
            checked={checkedId}
            value={asset.id}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => getRow(asset)}
          />
        </TableCell>
      ) : (
        ''
      )}

      <DeleteConfirmationDialog
        onDelete={() => handleDeleteClick()}
        isDeleting={deletionInProgress}
        open={!!assetToBeDeleted}
        itemName={asset.filename}
        onClose={() => setAssetToBeDeleted(undefined)}
      />
      <UploadEditModal assetData={asset} open={isEdit} setOpen={setIsEdit} onUploadComplete={onUploadComplete} />
      <TableCell>
        {`${asset.doc_type}`}
        <div className={classes.fileName}>
          <p>{`${asset.filename}`}</p>
        </div>
      </TableCell>
      <TableCell>{asset.doc_label}</TableCell>
      <TableCell>{asset.notes}</TableCell>
      <TableCell>{formatDateToHumanFriendly(asset.created_at)}</TableCell>
      <TableCell>{getColValue()}</TableCell>
      {type !== 'personal' && !isDownload && (
        <>
          <TableCell>
            <Checkbox
              color="primary"
              // disabled={type === 'personal'}
              checked={checked}
              value={asset.is_show_on_client}
              style={{ padding: 0, marginRight: 10 }}
              onChange={e => toggleClientDoc(asset.id, e.target, asset)}
            />
          </TableCell>
          <TableCell>
            <Checkbox
              color="primary"
              // disabled={type === 'personal'}
              checked={isRAChecked}
              value={asset.registered_agent_document}
              style={{ padding: 0, marginRight: 10 }}
              onChange={e => toggleRADoc(asset.id, e.target, asset)}
            />
          </TableCell>
          <TableCell>
            <Checkbox
              color="primary"
              // disabled={type === 'personal'}
              checked={isAddressChecked}
              value={asset.address_document}
              style={{ padding: 0, marginRight: 10 }}
              onChange={e => toggleAddressDoc(asset.id, e.target, asset)}
            />
          </TableCell>
        </>
      )}
      <TableCell>{asset.uploaded_by}</TableCell>
      {!isDownload && (
        <TableCell>
          <div className={classes.buttonsContainer}>
            {/* Added an a tag here, as we want to open the downloaded file in a new tab */}
            <a
              //href={download}
              className={classes.downloadButtonLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={download}
            >
              <DownloadButton className={classes.downloadButton}>
                <GetAppOutlined color="primary" />
              </DownloadButton>
            </a>
            <DownloadButton onClick={() => setIsEdit(true)} className={classes.downloadButton}>
              <EditIcon />
            </DownloadButton>
            <DownloadButton onClick={handleSetAssetToDelete} className={classes.downloadButton}>
              <DeleteIcon />
            </DownloadButton>
          </div>
        </TableCell>
      )}
    </TableRow>
  )
}
export default AssetRow
