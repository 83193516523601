import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { paginatedProducts, deleteSubscriptionProduct } from 'api/subscription'

import CreateSubscription from 'components/subscription/create'
import SubscriptionTableHead from 'components/subscription/table/head'
import SubscriptionTableRow from 'components/subscription/table/row'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import useSortTable from 'hooks/useSortTable'
import { IClient } from 'models/user'
import * as React from 'react'
import { theme } from 'theme'
import useRouter from 'use-react-router'
import { updatePageVisitsCount } from 'utils/user'
import { IProductResponse } from 'api/subscription'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.title.fontFamily,
    fontWeight: 600,
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
})

interface ISubscriptionProps extends WithStyles<typeof styles> {}

const Subscription: React.FC<ISubscriptionProps> = ({ classes }) => {
  const [query, setQuery] = React.useState('')
  const [clientToDelete, setClientToDelete] = React.useState<IProductResponse>()
  const [deleteClientDialogShown, setDeleteClientDialogShown] = React.useState(false)
  const router = useRouter()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'creation_time' })

  const tableHead = React.useMemo(
    () => (
      <SubscriptionTableHead
      // handleSortingClick={handleSortingClick}
      />
    ),
    [sortSettings, handleSortingClick]
  )

  const { order, sort_by } = sortSettings

  const apiCall = React.useMemo(() => paginatedProducts()({ sort_order: order, sort_by, query }), [
    order,
    query,
    sort_by,
  ])
  const pagedTable = usePagedTable<IProductResponse>({ apiCall })

  const onEditClick = React.useCallback(
    (product: IProductResponse) => {
      if (!product.id) return
      router.history.push(`/subscription/${product.id}`)
    },
    [router.history]
  )

  const onDeleteClick = React.useCallback(
    (product: IProductResponse) => {
      if (!product.id) return
      setClientToDelete(product)
      setDeleteClientDialogShown(true)
    },
    [setClientToDelete, setDeleteClientDialogShown]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteClientDialogShown}
        close={() => setDeleteClientDialogShown(false)}
        deletionObject={clientToDelete}
        deletionRequest={deleteSubscriptionProduct}
        onRequestSuccessful={onDeleteSuccessful}
      />
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>Subscription</Typography>
        <CreateSubscription fetchProducts={pagedTable.reloadData} />
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar onChange={setQuery} placeholder="Search by name" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            {tableHead}
            <TableBody>{skeletons({ numberOfColumns: 3, numberOfRows: 10 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Data"
            {...pagedTable.renderProps}
            renderRow={(product: IProductResponse) => (
              <SubscriptionTableRow
                product={product}
                onEditClick={() => onEditClick(product)}
                onDeleteClick={() => onDeleteClick(product)}
              ></SubscriptionTableRow>
            )}
            header={tableHead}
            colSpan={6}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(Subscription)
