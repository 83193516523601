import { Button, Dialog, Divider, Grid, Link, useMediaQuery } from '@material-ui/core'
import { Alert, AlertTitle, Skeleton, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { checkMissingItemExist, getClientCompanies, getSpecialOffers } from 'api/clientDashboard'
import { ICompany } from 'models/company'
import CompanyCard from 'pages/clientDashboard/companyCard'
import CompanyCardSkeleton from 'pages/clientDashboard/companyCardSkeleton'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import { OfferCard, OfferDialog } from 'pages/clientDashboard/offers'
import ScheduleCallCard from 'pages/clientDashboard/scheduleCallCard'
import React, { useState, useEffect } from 'react'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import { updatePageVisitsCount } from 'utils/user'
import { useStyles } from './styles'
import { ISpecialOffersCategory, getSpecialoffersCategories } from 'api/specialOffers'
import PowerUpCard from 'pages/clientDashboard/client/powerUpCard'
import AddCompany from 'pages/clientDashboard/Order/AddCompany'
import ExistingCompanyProvider from 'providers/existingCompany/existingCompany.provider'
import { ArrowRightAlt, Error, LocalMall } from '@material-ui/icons'
import Chip from '@material-ui/core/Chip'
import NewCompanyCard from 'pages/clientDashboard/newCompanyCard'
import { useHistory } from 'react-router'
import { filterCompanyNamesByStatus } from 'utils/functions'
import CalendlyModal from 'pages/clientDashboard/CalendlyModal'
import ModalHeader from 'nav/Commision-Card/modalHeader'
import { InlineWidget } from 'react-calendly'
import { theme } from 'theme'
import { MISSING_ITEM_STATUS } from 'utils/constant'

const ClientCompanies = () => {
  const classes = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const [companyDetails, setcompanyDetails] = useState<ICompany[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [offers, setOffers] = useState<any>([])
  const [isOffer, setIsOffer] = useState<boolean>(false)
  const [offerDate, setOfferDate] = useState<any>()
  const [status, setStatus] = React.useState<boolean>(true)
  const [specialOfferCategories, setSpecialOfferCategories] = useState<ISpecialOffersCategory[]>([])
  const [reviewCompanies, setReviewCompanies] = useState([])

  const [isSchedule, setisSchedule] = React.useState<boolean>(false)
  const [missingItemExist, setmissingItemExist] = useState(false)

  const router = useHistory()

  const getCompanyDetails = React.useCallback(async () => {
    setIsLoading(true)
    try {
      const data: any = await getClientCompanies(status)
      if (!data.error) {
        setcompanyDetails(data || [])
        setReviewCompanies(filterCompanyNamesByStatus(data, 'Under Review'))
      } else setcompanyDetails([])

      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
    }
  }, [status])

  const handleStatus = (event: React.MouseEvent<HTMLElement>, newStatus: boolean) => {
    setStatus(newStatus)
  }

  const getOffers = async () => {
    try {
      const offers = await getSpecialOffers()
      setOffers(offers)
    } catch (error) {
      console.log('error', error)
    }
  }

  const offerModalHandler = (data: any) => {
    setIsOffer(true)
    setOfferDate(data)
  }
  const checkIsCompanyCompleted = () => {
    // check if atleast one company is completed //
    const atLeastOneCompanyCompleted = companyDetails.some(company => company.file_date?.length > 0)
    return atLeastOneCompanyCompleted
  }
  useEffect(() => {
    getCompanyDetails()
  }, [getCompanyDetails, status])

  useEffect(() => {
    getOffers()
  }, [])

  useEffect(() => {
    const getSpecialoffersCat = async () => {
      try {
        const result: ISpecialOffersCategory[] = await getSpecialoffersCategories()
        setSpecialOfferCategories(result)
      } catch (error) {
        console.log('error', error)
      }
    }
    getSpecialoffersCat()
  }, [])
  const checkItems = async () => {
    const data = await checkMissingItemExist('open')
    const itemExist = data.length ? true : false
    setmissingItemExist(itemExist)
  }
  React.useEffect(() => {
    checkItems()
  }, [])
  return (
    <div className={classes.clientCompanies}>
      <h1 className="main-heading">Companies</h1>

      <Divider />
      <br />
      <ScheduleCallCard />
      <br />

      <div className="companies-header">
        <HeadingHeader title="Company List" />
        <div>
          {/* <ExistingCompanyProvider>
            <AddCompany />
          </ExistingCompanyProvider> */}

          <ToggleButtonGroup aria-label="company status" value={status} exclusive onChange={handleStatus}>
            <ToggleButton value={true} aria-label="active">
              Active
            </ToggleButton>
            <ToggleButton value={false} aria-label="Inactive">
              InActive
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <Grid container spacing={3}>
        {missingItemExist && (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>ATTENTION</AlertTitle>
              You have open missing items that need your attention{' '}
              <strong style={{ cursor: 'pointer' }} onClick={() => router.push('client-missing-item')}>
                Review
              </strong>
              .
            </Alert>
          </Grid>
        )}

        {reviewCompanies?.length ? (
          <Grid item xs={12}>
            <div className="header">
              Your {reviewCompanies.length === 1 ? 'company' : 'companies'}{' '}
              <span className="companies-name">{reviewCompanies.join(', ') || ''} </span>
              LLC compliance is brewing. Please allow 24-48 hrs for our system to gather information about your company.
            </div>
          </Grid>
        ) : (
          ''
        )}

        {/* {companies.map((company, key) => (
          <Grid item xs={4} key={key}>
            <CompanyCard name={company.name} city={company.city} avatar={company.avatar} date={company.date} />
          </Grid>
        ))} */}

        {isLoading ? (
          [...Array(6)].map((value: undefined, index: number) => (
            <Grid item xs={12} md={6} xl={4} key={index}>
              <CompanyCardSkeleton />
            </Grid>
          ))
        ) : (
          <>
            {companyDetails?.length > 0 ? (
              companyDetails?.map((company, key) => (
                <Grid item xs={12} md={12} lg={6} xl={4} key={key}>
                  <NewCompanyCard
                    name={company?.name}
                    city={company?.state_of_formation || ''}
                    date={company?.file_date?.length ? formatDateToHumanFriendly(company?.file_date) : 'Pending'}
                    companyId={company?.id}
                    file_date={company?.file_date}
                    profile_score={company?.profile_score}
                    business_active={company?.business_active}
                    business_active_status={company?.business_active_status}
                    expected_ein_date={company?.expected_ein_date}
                    expected_file_date={company?.expected_file_date}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Alert severity="info">No Company Found!</Alert>
              </Grid>
            )}
            {/* <Grid item xs={12}>
              <p className="ques">
                If you have any questions regarding our platform or this entity, arrange a meeting with one of our team
                members.{' '}
                <Link className="link-schedule" href={``}>
                  Schedule
                </Link>
              </p>
            </Grid> */}
            <Grid item xs={12} md={6} xl={4}>
              <ExistingCompanyProvider>
                <AddCompany />
              </ExistingCompanyProvider>
            </Grid>
            <Grid item xs={12}>
              <p className="ques">
                If you have any questions regarding our platform or this entity, arrange a meeting with one of our team
                members.{' '}
                <Link className="link-schedule" onClick={() => setisSchedule(true)}>
                  Schedule
                </Link>
              </p>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={matches ? true : false}
        open={isSchedule}
        onClose={() => setisSchedule(false)}
      >
        <ModalHeader id="calendly-modal" onClose={() => setisSchedule(false)} />
        <InlineWidget styles={{ height: '100vh' }} url="https://calendly.com/d/2c3-mdn-v3b" />
      </Dialog>

      {/* <br /> */}
      <div className="power-ups-section">
        <HeadingHeader
          title="Power Ups"
          isLink={false}
          secondaryComponent={
            <Button
              variant="outlined"
              endIcon={<ArrowRightAlt />}
              color="primary"
              onClick={() => router.push('/power-up/all')}
            >
              See All
            </Button>
          }
        />
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-end">
            {specialOfferCategories?.length > 0
              ? specialOfferCategories?.map((offer: ISpecialOffersCategory) => (
                  <Grid item xs={12} sm={6} lg={4} xl={3} key={offer.id}>
                    <PowerUpCard
                      category_img={offer?.category_img}
                      title={offer?.title}
                      link_text={offer?.link_text}
                      heading={offer?.heading}
                      description={offer?.description}
                      id={offer?.id}
                    />
                  </Grid>
                ))
              : [...Array(6)].map((value: undefined, index: number) => (
                  <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                    <Skeleton variant="rect" height={145} />
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid container spacing={3}>
        {offers.length
          ? offers?.map((offer, key) => (
              <Grid item xs={6} md={4} lg={2} key={key}>
                <OfferCard
                  img={offer.logo}
                  // mainTextColor={offer.color}
                  borderColor={offer.color}
                  mainText={offer.title}
                  onClick={() => offerModalHandler(offer)}
                  atLeastOneCompanyCompleted={checkIsCompanyCompleted()}
                  completed_orders={offer.completed_orders}
                />
              </Grid>
            ))
          : [...Array(6)].map((value: undefined, index: number) => (
              <Grid item xs={6} md={4} lg={2} key={index}>
                <Skeleton variant="rect" height={145} />
              </Grid>
            ))}
      </Grid>
      <OfferDialog
        open={isOffer}
        setOpen={setIsOffer}
        title={offerDate?.title}
        question={offerDate?.company_prompt}
        answers={offerDate?.link}
        link={offerDate?.link}
        btnText={offerDate?.button_text}
        isPopup={offerDate?.ispopup}
        companies={companyDetails}
        offerId={offerDate?.id}
      /> */}
    </div>
  )
}

export default ClientCompanies
