// import { getComplianceTasksByCompanyId } from 'api/complianceCalendar'
import React, { useEffect, useState } from 'react'
import ProgressTask from './table/progressTask'
import YearsTable from './yearsTable'

const CompanyComplianceTask = ({ company_id }) => {
  const [isUpdated, setIsUpdated] = useState(false)
  //   console.log('company_id', company_id)

  //   useEffect(() => {
  //     const getTask = async () => {
  //       const payload = {
  //         company_id: company_id,
  //         page: 1,
  //         page_size: 5,
  //       }
  //       const res = await getComplianceTasksByCompanyId(payload)
  //       console.log('data', res)
  //     }

  //     getTask()
  //   }, [company_id])

  return (
    <div>
      <ProgressTask isUpdated={isUpdated} setIsUpdated={setIsUpdated} companyId={company_id} />
      <YearsTable company_id={company_id} />
    </div>
  )
}

export default CompanyComplianceTask
