import { Grid, Box } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { fetchSubscriptionsByCompanyID, ISubscriptionsProps } from 'api/subscription'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import React, { useState } from 'react'
import { STRIPE_SUBSCRIPTION_STATUSES } from 'utils/constant'
import ImportSubscriptionModal from './ImportSubscriptionModal'
import { useStyles } from './styles'
import SubscriptionCard from './subscriptionCard'
import SubscriptionModal from './SubscriptionModal'

interface ICompanySubscriptionProps {
  company_id: number
}

const CompanySubscription = ({ company_id }) => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isImportOpen, setIsImportOpen] = useState(false)

  const [subscriptions, setSubscriptions] = React.useState<ISubscriptionsProps[]>([])

  const [activeSubscriptions, setActiveSubscriptions] = React.useState<ISubscriptionsProps[]>([])
  const [inActiveSubscriptions, setInActiveSubscriptions] = React.useState<ISubscriptionsProps[]>([])
  const [canceledSubscriptions, setcanceledSubscriptions] = React.useState<ISubscriptionsProps[]>([])
  const [unPaidPastDueSubscriptions, setUnPaidPastDueSubscriptions] = React.useState<ISubscriptionsProps[]>([])

  const [subId, setSubId] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  const getSubscriptions = async (id: number) => {
    try {
      setLoading(true)
      const result: ISubscriptionsProps[] = await fetchSubscriptionsByCompanyID(id)

      console.log('resultresultresultresult', result)

      const active = result?.filter(
        item =>
          item?.active &&
          item.status !== STRIPE_SUBSCRIPTION_STATUSES.past_due &&
          item.status !== STRIPE_SUBSCRIPTION_STATUSES.unpaid &&
          (item.status !== STRIPE_SUBSCRIPTION_STATUSES.incomplete ||
            item.status !== STRIPE_SUBSCRIPTION_STATUSES.incomplete_expired)
      )
      setActiveSubscriptions(active)
      const inActive = result?.filter(
        item =>
          !item?.active &&
          item.status !== STRIPE_SUBSCRIPTION_STATUSES.canceled &&
          item.status !== STRIPE_SUBSCRIPTION_STATUSES.past_due &&
          item.status !== STRIPE_SUBSCRIPTION_STATUSES.unpaid &&
          (item.status === STRIPE_SUBSCRIPTION_STATUSES.incomplete ||
            item.status === STRIPE_SUBSCRIPTION_STATUSES.incomplete_expired)
      )
      setInActiveSubscriptions(inActive)
      setSubscriptions(result)
      setcanceledSubscriptions(result?.filter(item => item.status === STRIPE_SUBSCRIPTION_STATUSES.canceled))
      // console.log('fetchSubscriptionsByCompanyID', result)
      setUnPaidPastDueSubscriptions(
        result?.filter(
          item =>
            item.status === STRIPE_SUBSCRIPTION_STATUSES.past_due || item.status === STRIPE_SUBSCRIPTION_STATUSES.unpaid
        )
      )
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }
  React.useEffect(() => {
    if (company_id) {
      getSubscriptions(company_id)
    }
  }, [company_id])

  console.log('subscriptions', subscriptions)

  const handleOpen = (id: number) => {
    setSubId(id)
    setIsOpen(true)
    setIsEdit(true)
  }

  return (
    <div className={classes.companySubscriptionContainer}>
      <div className="inline-item">
        <h1 className="primary-heading">Company Subscription</h1>
        <ButtonWithLoading
          color="primary"
          variant="contained"
          onClick={() => {
            setIsImportOpen(true)
          }}
        >
          Import Subscription
        </ButtonWithLoading>
      </div>

      <ButtonWithLoading
        color="primary"
        variant="contained"
        wrapperClassName={classes.subBtn}
        onClick={() => {
          setIsOpen(true)
          setIsEdit(false)
        }}
      >
        Add Subscription
      </ButtonWithLoading>

      <h4 className="secondary-heading">Active</h4>

      <Grid container spacing={3} className="active-section">
        {loading && (
          <>
            {[1, 2, 3, 4].map(_ => (
              <Skeleton variant="rect" width={300} height={250} style={{ marginLeft: '2rem' }} key={_} />
            ))}
          </>
        )}
        {activeSubscriptions?.map((item: ISubscriptionsProps) => (
          <>
            <Grid item xs={3}>
              <SubscriptionCard
                isActive={item?.active}
                subscriptionName={item?.product?.name}
                subscriptionAmount={item?.price?.amount || 0}
                subscriptionPeriod={item.price.billing_period || ''}
                subStartDate={item?.creation_time}
                renewDate={item?.current_period_end}
                status={item?.status}
                // handleOpen={() => {
                //   setSubId(item?.id)
                //   setIsOpen(true)
                //   setIsEdit(true)
                // }}
                handleOpen={() => handleOpen(item?.id)}
                subscriptionType={item?.subscription_type || ''}
                allocatedAddress={item?.allocated_address || ''}
              />
            </Grid>
          </>
        ))}
        {!loading && !activeSubscriptions.length && <Alert severity="info">No Active Subscriptions</Alert>}
        <Grid item xs={4}>
          {/* <SubscriptionCard
            isActive
            handleOpen={() => {
              setIsOpen(true)
              setIsEdit(true)
            }}
          /> */}
        </Grid>
        <Grid item xs={4}>
          {/* <SubscriptionCard
            isActive
            handleOpen={() => {
              setIsOpen(true)
              setIsEdit(true)
            }}
          /> */}
        </Grid>
      </Grid>
      <h4 className="secondary-heading">Unpaid / Past Due</h4>

      <Grid container spacing={3} className="active-section">
        {loading && (
          <>
            {[1, 2, 3, 4].map(_ => (
              <Skeleton variant="rect" width={300} height={250} style={{ marginLeft: '2rem' }} key={_} />
            ))}
          </>
        )}
        {unPaidPastDueSubscriptions?.map((item: ISubscriptionsProps) => (
          <>
            <Grid item xs={3}>
              <SubscriptionCard
                isActive={item?.active}
                subscriptionName={item?.product?.name}
                subscriptionAmount={item?.price?.amount || 0}
                subscriptionPeriod={item.price.billing_period || ''}
                subStartDate={item?.creation_time}
                renewDate={item?.current_period_end}
                status={item?.status}
                // handleOpen={() => {
                //   setSubId(item?.id)
                //   setIsOpen(true)
                //   setIsEdit(true)
                // }}
                handleOpen={() => handleOpen(item?.id)}
                subscriptionType={item?.subscription_type || ''}
              />
            </Grid>
          </>
        ))}
        {!loading && !unPaidPastDueSubscriptions.length && (
          <Alert severity="info">No Unpaid/Past Due Subscriptions</Alert>
        )}
        <Grid item xs={4}>
          {/* <SubscriptionCard
            isActive
            handleOpen={() => {
              setIsOpen(true)
              setIsEdit(true)
            }}
          /> */}
        </Grid>
        <Grid item xs={4}>
          {/* <SubscriptionCard
            isActive
            handleOpen={() => {
              setIsOpen(true)
              setIsEdit(true)
            }}
          /> */}
        </Grid>
      </Grid>

      <h4 className="secondary-heading">InActive</h4>

      <Grid container spacing={3} className="inactive-section">
        {loading && (
          <>
            {[1, 2, 3, 4].map(_ => (
              <Skeleton variant="rect" width={300} height={250} style={{ marginLeft: '2rem' }} key={_} />
            ))}
          </>
        )}
        {inActiveSubscriptions?.map((item: ISubscriptionsProps) => (
          <>
            <Grid item xs={3}>
              <SubscriptionCard
                isActive={item?.active}
                subscriptionName={item?.product?.name}
                subscriptionAmount={item?.price?.amount || 0}
                subscriptionPeriod={item.price.billing_period || ''}
                subStartDate={item?.creation_time}
                renewDate={item?.current_period_end}
                // handleOpen={() => {
                //   setSubId(item?.id)
                //   setIsOpen(true)
                //   setIsEdit(true)
                // }}
                handleOpen={() => handleOpen(item?.id)}
                status={item?.status}
                subscriptionType={item?.subscription_type || ''}
                allocatedAddress={item?.allocated_address || ''}
              />
            </Grid>
          </>
        ))}
        {!loading && !inActiveSubscriptions.length && <Alert severity="info">No InActive Subscriptions</Alert>}
      </Grid>
      <h4 className="secondary-heading">Canceled</h4>

      <Grid container spacing={3} className="inactive-section">
        {loading && (
          <>
            {[1, 2, 3, 4].map(_ => (
              <Skeleton variant="rect" width={300} height={250} style={{ marginLeft: '2rem' }} key={_} />
            ))}
          </>
        )}
        {canceledSubscriptions?.map((item: ISubscriptionsProps) => (
          <>
            <Grid item xs={3}>
              <SubscriptionCard
                isActive={item?.active}
                subscriptionName={item?.product?.name}
                subscriptionAmount={item?.price?.amount || 0}
                subscriptionPeriod={item.price.billing_period || ''}
                subStartDate={item?.creation_time}
                renewDate={item?.current_period_end}
                // handleOpen={() => {
                //   setSubId(item?.id)
                //   setIsOpen(true)
                //   setIsEdit(true)
                // }}
                // handleOpen={null}
                handleOpen={() => handleOpen(item?.id)}
                status={item?.status}
                subscriptionType={item?.subscription_type || ''}
                allocatedAddress={item?.allocated_address || ''}
              />
            </Grid>
          </>
        ))}
        {!loading && !canceledSubscriptions.length && <Alert severity="info">No Canceled Subscriptions</Alert>}
      </Grid>

      <ModalWrapper maxWidth={'md'} open={isOpen} setOpen={setIsOpen} heading="" onClose={() => setSubId(0)}>
        <SubscriptionModal
          isEdit={isEdit}
          subscription_id={subId}
          setIsOpen={setIsOpen}
          company_id={company_id}
          reloadloadDetails={async () => await getSubscriptions(company_id)}
          setSubId={setSubId}
        />
      </ModalWrapper>

      <ModalWrapper
        maxWidth={'sm'}
        open={isImportOpen}
        setOpen={setIsImportOpen}
        heading={`Import Subscription # ${company_id}`}
      >
        <ImportSubscriptionModal
          company_id={company_id}
          setIsOpen={setIsImportOpen}
          reloadloadDetails={async () => await getSubscriptions(company_id)}
        />
      </ModalWrapper>
    </div>
  )
}

export default CompanySubscription
