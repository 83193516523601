import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { getKpiDashboardLeadManager } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'

const LeadManagerCard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<any>()

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardLeadManager(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])

  return (
    <Grid className={classes.commonRow} container spacing={0}>
      <Grid item xs={2}>
        <PrimaryHeading heading="Lead Manager" text={currentData} />
      </Grid>
      <Grid item xs={10}>
        <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
      </Grid>

      <Grid item xs={2}>
        <SectionDetail
          primaryText="Total leads after notification"
          heading={filteredData?.total_leads_after_notification || 0}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={2}>
        <SectionDetail
          primaryText="Number of E-mail sent"
          heading={filteredData?.total_email_sent || 0}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={2}>
        <SectionDetail
          primaryText="Number of SMS sent"
          heading={filteredData?.total_sms_sent || 0}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={2}>
        <SectionDetail
          primaryText="Average Number of E-mail sent"
          heading={Math.round(filteredData?.avg_email_sent as number) || 0}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={2}>
        <SectionDetail
          primaryText="Average Number of SMS sent"
          heading={Math.round(filteredData?.avg_sms_sent as number) || 0}
          isLoading={isLoading}
        />
      </Grid>

      <Grid xs={6} item>
        <h2 className="offer-heading">Top 10 list of E-mail</h2>
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        ) : (
          filteredData?.top_ten_emails.map((value: any, index: number) => (
            <Grid item xs={12} key={index}>
              #{index + 1} {value?.subject?.substring(0, 85)}
            </Grid>
          ))
        )}
      </Grid>
      <Grid xs={6} item>
        <h2 className="offer-heading">Top 10 list of SMS</h2>
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        ) : (
          filteredData?.top_ten_sms.map((value: any, index: number) => (
            <Grid item xs={12} key={index}>
              #{index + 1} {value?.content?.substring(0, 85)}
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  )
}

export default LeadManagerCard
