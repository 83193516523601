import { emptyOrderDetails, IOrderDetailsResponse } from 'api/orders'
import { createCompanySuggestions, createOrderSuggestions, createOrderTypeSuggestions } from 'api/suggestions'
import { COMPANY_CREATION_ORDER_COMMISSION } from 'BRValuesConfig'
import { SortingType } from 'components/common/table'
import { emptyAddress } from 'components/companies/hooks/addressEditHooks'
import { ISuggestionAttribute } from 'components/interfaces'
import { CompanyOrderContext } from 'components/orders/CompanyOrderContext'
import { IAddress } from 'models/address'
import { ILegalType } from 'models/company'
import { INewOrderState, IOrder, IOrderStatus, orderStatuses } from 'models/order'
import * as React from 'react'
import { ValueType } from 'react-select/src/types'
import { ValueOf } from 'utils/objectUtils'
import showErrorNotification from 'utils/showErrorNotification'
import useRouter from 'hooks/router'

export interface IOrderCompanyDetailsInputFields {
  name: string
  country: string | null
  state_of_formation: string | null
  business_activity: null | string
  file_date: string | null
  address: IAddress
  ein: string | null
  legal_type?: ILegalType
  number_of_shares: null | number
}

export const emptyCompanyDetailsInputFields: IOrderCompanyDetailsInputFields = {
  name: '',
  country: '',
  state_of_formation: '',
  business_activity: '',
  file_date: '',
  address: emptyAddress,
  ein: '',
  legal_type: undefined,
  number_of_shares: 0,
}

export interface ISearchOptions {
  query?: string
  // order_type_ids?: number[]
  order_type_ids?: any

  page_size?: number
  sort_by: string
  sort_order: SortingType
  with_sales_reps: string
  company_id?: number
  order_type_query?: string
  order_statuses?: any //IOrderStatus[]
  open?: boolean
  is_rush_order?: boolean
  client_id?: number
}

interface IUseCompany {
  companyId?: number
  orderSearchOptions?: Partial<ISearchOptions>
  showNoDialogUponCreation?: boolean
  onOrderCreated?: (order: IOrder) => void
}

const getEmptySearchOptionsState = (
  companyId?: number,
  orderSearchOptions?: Partial<ISearchOptions>
): ISearchOptions => {
  const defaultSearchOptions: ISearchOptions = {
    // order_statuses: [orderStatuses.default],
    with_sales_reps: 'all',
    sort_by: 'last_update',
    sort_order: 'desc',
    open: true,
    is_rush_order: false,
    // client_id: null,
  }

  if (companyId) {
    return {
      ...defaultSearchOptions,
      company_id: companyId,
    }
  }

  if (orderSearchOptions?.query) {
    return {
      ...(defaultSearchOptions as ISearchOptions),
      query: orderSearchOptions?.query,
    }
  }

  return defaultSearchOptions as ISearchOptions
}

const useCompanyOrders = ({ companyId, showNoDialogUponCreation, onOrderCreated, orderSearchOptions }: IUseCompany) => {
  const emptyOrderState: INewOrderState = React.useMemo(
    () => ({
      company_id: companyId,
      order_type_id: undefined,
      price: '0',
      commission: '0',
      due_date: undefined,
      required_order_ids: [],
    }),
    [companyId]
  )

  // creating a state hook to trigger table reloading
  const { reloadTable, setReloadTable } = React.useContext(CompanyOrderContext)

  const [searchOptions, setSearchOptions] = React.useState<ISearchOptions>(
    getEmptySearchOptionsState(companyId, orderSearchOptions)
  )
  const [newOrderState, setNewOrderState] = React.useState<INewOrderState>(emptyOrderState)
  const [orderDetails, setOrderDetails] = React.useState<IOrderDetailsResponse>(emptyOrderDetails)
  const router = useRouter()
  const [params, setparams] = React.useState<any>(new URLSearchParams(router.location.search).get('orderTypes'))

  const fetchSuggestions = React.useCallback(
    async (query: string, field: string): Promise<ISuggestionAttribute[]> => {
      switch (field) {
        case 'company_id':
          return createCompanySuggestions(query)
        case 'order_type_id':
          return createOrderTypeSuggestions(query)
        case 'required_order_ids':
          if (!newOrderState.company_id) return []
          else return createOrderSuggestions(query, newOrderState.company_id)
        default:
          showErrorNotification(`Failed to get options`)
          console.error(`Incorrect object type`)
          return []
      }
    },
    [newOrderState.company_id]
  )

  const addForeignKeyAttribute = React.useCallback(
    (
      field: keyof Pick<INewOrderState, 'order_type_id' | 'company_id'>,
      id: ValueOf<Pick<INewOrderState, 'order_type_id' | 'company_id'>>
    ) => {
      // remove required orders if company is changed
      if (field === 'company_id' && id !== newOrderState.company_id)
        setNewOrderState(prevState => ({ ...prevState, required_order_ids: [] }))

      // assign new id or set to undefined if value is invalid
      setNewOrderState(prevState => ({
        ...prevState,
        [field]: id || undefined,
      }))
    },
    [setNewOrderState, newOrderState]
  )

  const handleNumberInputChange = React.useCallback(
    (field: string, value: string) => {
      setNewOrderState(prevState => ({ ...prevState, [field]: value }))
      if (field === 'price') {
        setNewOrderState(prevState => ({
          ...prevState,
          commission: (parseFloat(value) * COMPANY_CREATION_ORDER_COMMISSION).toFixed(2),
        }))
      }
    },
    [setNewOrderState]
  )

  const handleAddFilter = React.useCallback(
    (field: string, select: ValueType<ISuggestionAttribute>) => {
      // params.delete('orderStatuses')
      // console.log(params);
      // params.delete('orderTypes')
      // console.log(params);
      window.history.pushState({}, document.title, '/' + 'company-order')
      switch (field) {
        case 'order_type_ids':
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          })) // collect all ids
          break
        case 'order_statuses':
          // setSearchOptions(prevState => ({
          //   ...prevState,
          //   [field]:
          //     select && Array.isArray(select)
          //       ? select.map((item: ISuggestionAttribute) => item.label as IOrderStatus)
          //       : [],
          // })) // collect strings representing enum values
          setSearchOptions(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          })) // collect all ids
          break

        default:
          showErrorNotification('Wrong filter key')
      }
    },
    [setSearchOptions]
  )

  const handleAddFilters = React.useCallback(
    (fields: string[], selects: ValueType<ISuggestionAttribute>[]) => {
      const state = {}
      fields.forEach((field, index) => {
        const select = selects[index]
        state[field] = select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : []
      })
      setSearchOptions(prevState => ({
        ...prevState,
        ...state,
      }))
    },
    [setSearchOptions]
  )

  const handleAddMultiValue = React.useCallback(
    (field: string, select: ValueType<ISuggestionAttribute>) => {
      switch (field) {
        case 'required_order_ids':
          setNewOrderState(prevState => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          }))
          break
        default:
          showErrorNotification('Wrong key')
      }
    },
    [setNewOrderState]
  )

  const handleSortingClick = React.useCallback(
    (sortBy: string) => {
      if (searchOptions['sort_by'] === sortBy) {
        setSearchOptions(prevState => ({
          ...prevState,
          sort_order: prevState['sort_order'] === 'desc' ? 'asc' : 'desc',
        }))
      } else {
        setSearchOptions(prevState => ({ ...prevState, sort_order: 'desc', sort_by: sortBy }))
      }
    },
    [searchOptions, setSearchOptions]
  )

  const handleSearch = React.useCallback(
    (query: string) => {
      setSearchOptions(prevState => ({ ...prevState, query: encodeURIComponent(query) }))
    },
    [setSearchOptions]
  )

  const handleWithSalesRepsFilter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
      if (value) setSearchOptions(prevState => ({ ...prevState, with_sales_reps: value }))
    },
    [setSearchOptions]
  )

  const handleOpenStatusFilter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
      setSearchOptions(prevState => ({ ...prevState, open: value }))
    },
    [setSearchOptions]
  )
  const handleRushOrderFilter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
      setSearchOptions(prevState => ({ ...prevState, is_rush_order: value }))
    },
    [setSearchOptions]
  )

  return {
    searchOptions,
    setSearchOptions,
    handleAddFilter,
    handleSortingClick,
    handleSearch,
    handleWithSalesRepsFilter,
    handleOpenStatusFilter,
    newOrderState,
    addForeignKeyAttribute,
    fetchSuggestions,
    reloadTable,
    setReloadTable,
    handleNumberInputChange,
    handleAddMultiValue,
    orderDetails,
    setOrderDetails,
    handleAddFilters,
    handleRushOrderFilter,
  }
}

export default useCompanyOrders

// FIXME: #382 many variables from this hook are unused. they can be removed
