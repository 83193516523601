import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  // { id: 'id', label: 'ID', style: {}, sortable: false },
  { id: 'name', label: 'Company Name', style: { width: '10%' }, sortable: false },
  // { id: 'name', label: 'Client Name', style: { width: '10%' }, sortable: false },
  { id: 'type', label: 'Type', style: { width: '5%' }, sortable: true },
  { id: 'notification_type', label: 'Notification Type', style: { width: '10%' }, sortable: true },
  { id: 'subject', label: 'Subject', style: { width: '15%' }, sortable: true },
  { id: 'content', label: 'content', style: { width: '20%' }, sortable: true },
  { id: 'sender', label: 'Sender', style: { width: '10%' }, sortable: true },
  { id: 'sent_to', label: 'Sent To', style: { width: '10%' }, sortable: true },
  { id: 'send_time', label: 'Send Time', style: { width: '10%' }, sortable: true },
]

const LogsHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default LogsHead
