import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import moment from 'moment'
import React, { useCallback, useState, useEffect } from 'react'
import useRouter from 'use-react-router'
import { REMINDER_TYPES } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useStyles } from '../styles'
import AccordionWrapper from './accordionWrapper'
import { useParams } from 'react-router'
import useUser from 'hooks/useUser'
import {
  addPropspectSchedule,
  addPropspectScheduleConfig,
  getPropspectScheduleById,
  IndustryProspectData,
  updatePropspectScheduleConfig,
  getIndustryType,
  getProspectStatuses,
} from 'api/leadManager'
import { Autocomplete, Skeleton } from '@material-ui/lab'

const AddProspectSchedules = () => {
  const classes = useStyles()
  const { history } = useRouter()
  const [templateName, setTemplateName] = useState('')
  const [industryType, setIndustryType] = useState<any>()
  const [pocValue, setPocValue] = useState<any>()

  const [configType, setConfigType] = useState('')
  const [reminders, setReminders] = useState<any>({})

  const [industryTypesList, setIndustryTypesList] = useState<IndustryProspectData[]>([])
  const [prospectStatusList, setProspectStatusList] = useState<IndustryProspectData[]>([])

  const { user } = useUser()
  const [currentUser, setCurrentUser] = useState<number>()

  useEffect(() => {
    setCurrentUser(user?.id)
  }, [user])

  const [updateRemindersData, setUpdateRemindersData] = useState<any>({})

  const [isLoading, setIsLoading] = useState(false)

  const [isEditLoading, setIsEditLoading] = useState(false)

  const { id } = useParams<any>()

  const getReminderData = async (id: number) => {
    setIsEditLoading(true)
    try {
      const data = await getPropspectScheduleById(id)
      setUpdateRemindersData(data)
      setIsEditLoading(false)
    } catch (error) {
      console.log(error)
    }
    setIsEditLoading(false)
  }

  useEffect(() => {
    if (id) {
      getReminderData(id)
    }
  }, [id])

  useEffect(() => {
    if (updateRemindersData) {
      setTemplateName(updateRemindersData?.name)

      setIndustryType(updateRemindersData?.industry_type)
      setPocValue(updateRemindersData?.prospect_status)
    }
  }, [updateRemindersData])

  const userDetails = JSON.parse(localStorage.getItem('currentUser') || '')
  const userId = userDetails?.extid

  useEffect(() => {
    if (id || updateRemindersData) {
      const updatedReminders = updateRemindersData?.configs?.reduce(
        (prev, curr) => {
          console.log('previous', curr?.schedule_time)

          const reminder = {
            senderName: curr?.sender,
            scheduleTime: curr?.schedule_time?.replace(/[^0-9]/g, ''),
            scheduleType: curr?.schedule_time?.match(/(hours|minutes|days)/g)[0].toLowerCase(),
            contentText: curr?.content,
            emailSubject: curr?.subject,
            smsBody: curr?.content,
            type: curr?.type,
          }
          const uuid = Math.random()
          prev[uuid] = reminder
          return prev
        },
        { ...reminders }
      )
      setReminders({ ...updatedReminders })
    }
  }, [id, updateRemindersData])

  const remindersTypeHanlder = (
    type: string,
    senderName = '',
    scheduleTime = '',
    scheduleType = '',
    contentText = '',
    emailSubject = '',
    smsBody = ''
  ) => {
    const reminder = {
      senderName: senderName,
      scheduleTime: scheduleTime,
      scheduleType: scheduleType,
      contentText: contentText,
      emailSubject: emailSubject,
      smsBody: smsBody,
      type: type,
    }
    const uuid = Math.random()
    setReminders({ ...reminders, [uuid]: reminder })
  }

  const remindersData = Object.entries(reminders)

  console.log('remindersData', remindersData)
  console.log('updateRemindersData', updateRemindersData)

  const handleUpdateReminderData = (id, field, data) => {
    reminders[id][field] = data
    setReminders({ ...reminders })
  }

  const handleDelete = id => {
    delete reminders[id]
    setReminders({ ...reminders })
  }

  const smsHandler = type => {
    setConfigType(type)
    remindersTypeHanlder(type)
  }
  const emailHandler = type => {
    setConfigType(type)
    remindersTypeHanlder(type)
  }

  const addRemindersHandler = useCallback(
    async reminderItemID => {
      setIsLoading(true)
      const sendingArray = remindersData.map(([key, value]) => {
        const { scheduleTime, scheduleType, contentText, emailSubject, senderName, smsBody, type }: any = value
        return {
          type: type,
          schedule_time: `${scheduleTime} ${scheduleType}`,
          sender: senderName,
          prospect_template_id: reminderItemID,
          subject: emailSubject,
          content: contentText || smsBody,
        }
      })

      try {
        await addPropspectScheduleConfig({ data: sendingArray })
        showSuccessNotification('Prospect Schedules Successfully Created')
        setIsLoading(false)
      } catch (error) {
        showErrorNotification(error)
      }

      setIsLoading(false)
    },
    [remindersData]
  )

  const templateHanlder = useCallback(async () => {
    setIsLoading(true)
    const templateData = {
      name: templateName,
      industry_type: industryType,
      prospect_status: pocValue,
      created_at: moment().toLocaleString(),
      created_by: userId,
    }
    const updatedArray = remindersData.map(([key, value]) => {
      const { scheduleTime, scheduleType, contentText, emailSubject, senderName, smsBody, type }: any = value
      return {
        type: type,
        schedule_time: `${scheduleTime} ${scheduleType}`,
        sender: senderName,
        subject: emailSubject,
        content: contentText || smsBody,
      }
    })

    const data = {
      name: templateName,
      industry_type: industryType,
      prospect_status: pocValue,
      data: updatedArray,
    }

    try {
      if (id) {
        await updatePropspectScheduleConfig(id, data)
        showSuccessNotification('Prospect Schedules Successfully Updated')
      } else {
        const res = await addPropspectSchedule(templateData)
        if (res) {
          addRemindersHandler(res?.data?.id)
        }
      }
      setIsLoading(false)
      history.push('/prospect-schedules')
    } catch (error) {
      showErrorNotification(error)
    }
    setIsLoading(false)
  }, [addRemindersHandler, history, id, industryType, pocValue, remindersData, templateName, userId])

  const handleSave = useCallback(() => {
    templateHanlder()
  }, [templateHanlder])

  const getInductriesList = async () => {
    try {
      const res = await getIndustryType()
      setIndustryTypesList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const getProspectsList = async () => {
    try {
      const res = await getProspectStatuses()
      setProspectStatusList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    getInductriesList()
    getProspectsList()
  }, [])

  // const getValue = (option: IndustryProspectData[]) => {
  //   if (updateRemindersData?.industry_type) {
  //     const val = option?.find(item => item.id === updateRemindersData?.industry_type)

  //     return {
  //       id: val?.id || 0,
  //       name: val?.name || '',
  //     }
  //   }
  // }

  return (
    <div className={classes.addReminderSec}>
      <div className="header-section">
        {isEditLoading ? (
          <Skeleton variant="text" width={'225px'} height={'78px'} />
        ) : (
          <FormControl variant="outlined" className="text-field">
            <TextField
              id="notificationTitle"
              label="Template Name"
              variant="outlined"
              value={templateName}
              onChange={e => setTemplateName(e.target.value)}
            />
          </FormControl>
        )}
        {isEditLoading ? (
          <Skeleton variant="text" width={'225px'} height={'78px'} />
        ) : (
          <FormControl variant="outlined" className={classes.formControl1}>
            <InputLabel>Industry Type</InputLabel>
            <Select
              value={industryType}
              defaultValue={updateRemindersData?.industry_type}
              onChange={e => setIndustryType(e.target.value)}
              label="Industry Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {industryTypesList?.map(option => (
                <MenuItem value={option?.id}>{option?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* <Autocomplete
          // options={industryTypesList?.map(type => type.name)}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          options={industryTypesList}
          className={classes.autocompleteField}
          defaultValue={industryTypesList?.find(item => item.id === updateRemindersData?.industry_type)}
          value={industryType}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              name="industryType"
              variant="outlined"
              label="Industry Type"
              InputProps={{ ...params.InputProps }}
              // onBlur={event => handleCreatorSelected(event.target.value)}
            />
          )}
          onChange={(_, value) => setIndustryType(value)}
        /> */}
        {/* <Autocomplete
          // options={prospectStatusList.map(type => type.name)}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          options={prospectStatusList}
          className={classes.autocompleteField}
          defaultValue={prospectStatusList?.find(item => item.id === updateRemindersData?.prospect_status)}
          // value={{ id: 3, name: 'Lead Received' }}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              name="prospectStatus"
              variant="outlined"
              label="Prospect Status"
              InputProps={{ ...params.InputProps }}
            />
          )}
          onChange={(_, value) => setPocValue(value)}
        /> */}
        {/* {isEditLoading ? (
          'Loading'
        ) : (
          <Dropdown
            label="Prospect Status"
            options={prospectStatusList}
            placeholder="Prospect Status"
            val={pocValue}
            handleValueChange={e => setPocValue(e.target.value)}
          />
        )} */}
        {isEditLoading ? (
          <Skeleton variant="text" width={'225px'} height={'78px'} />
        ) : (
          <FormControl variant="outlined" className={classes.formControl1}>
            <InputLabel>Prospect Status</InputLabel>
            <Select
              value={pocValue}
              defaultValue={updateRemindersData?.prospect_status}
              onChange={e => setPocValue(e.target.value)}
              label="Prospect Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {prospectStatusList?.map(option => (
                <MenuItem value={option?.id}>{option?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="placeholders">
        Placeholders: &nbsp;{' '}
        <b className="bold-text">
          $FIRST_NAME$, $LAST_NAME$, $COMPANY_NAME$, $MISSING_ITEMS$, $STATE_OF_FORMATION$, $CLIENT_ADDRESS$,
          $FORM_LINK$, $EMP_FIRST_NAME$, $MEETING_LINK$, $INVOICE_PAYMENT_LINK$
        </b>
      </div>
      <div className="btn-sec">
        <Button variant="contained" color="primary" onClick={() => smsHandler(REMINDER_TYPES?.sms)}>
          + SMS
        </Button>
        <Button variant="contained" color="primary" onClick={() => emailHandler(REMINDER_TYPES?.email)}>
          + Email
        </Button>
      </div>

      {remindersData.length ? (
        <>
          {remindersData?.map(([key, value]) => (
            <AccordionWrapper
              keyId={key}
              reminderData={value}
              handleDelete={handleDelete}
              handleUpdate={handleUpdateReminderData}
              currentUser={currentUser || 0}
            />
          ))}

          <div className={classes.headerBtn}>
            <ButtonWithLoading onClick={handleSave} isLoading={isLoading}>
              {id ? 'Update' : 'Save'}
            </ButtonWithLoading>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddProspectSchedules
