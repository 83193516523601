import { ICompany, IDetailedCompanyResponse } from 'models/company'
import { ISalesRep } from 'models/salesRep'
import { IClient, IEmployee } from 'models/user'

// TODO: #377 would be nice to have separate interfaces for business tax order and personal tax order
export interface ITaxOrder {
  readonly id: number
  updated_at: string
  due_date: string
  tax_year: string
  is_filed: boolean
  tax_order_type: ITaxOrderType
  tax_order_stage_id: number
  sales_rep_id?: number
  accountant_id?: number
  accountant?: IClient | IEmployee
  creator_id: number
  company_id?: number
  company?: ICompany
  client?: IClient
  sales_rep?: ISalesRep
  tax_order_stage: ITaxOrderStage
  tax_structure_business?: ITaxStructureBusiness
  tax_structure_personal?: ITaxStructurePersonal
  company_details?: IDetailedCompanyResponse
  client_details?: IClient
  tax_labels?: any
  priority?: number

  senior_cpa?: number
  senior_accountant?: any
  filer?: string
  questionsCount?: number
  creation_time?: string

  tax_associate?: any
  tax_controller?: any
  unpaid_subscription?: boolean
  error?: string
  last_updated?: string
  service_type?: string
  status_processing_date?: string
  checklistanswer_completed?: boolean
  accountantIds?: any
  reminder_sequence?: string | null
}

export interface INewTaxOrder {
  accountant?: IClient | IEmployee
  accountant_user_id?: number
  assigned_user_id?: string
  client?: IClient
  client_id?: number
  company_id?: string
  completed_at?: string
  creation_time?: string
  deleted_at?: string
  due_date?: string
  extension_filed?: string
  file_date?: string
  id?: number
  is_active?: number
  is_deleted?: false
  labels?: string
  order_status?: string
  order_status_description?: string
  order_type?: number
  priority?: number
  tax_labels?: any
  tax_order_stage?: ITaxOrderStage
  tax_order_type?: string
  tax_structure?: string
  tax_year?: string
  senior_cpa?: number
  filer?: string

  tax_associate?: number
  tax_controller?: number
}

export interface INewTaxOrderState {
  tax_year: string
  parent_id?: number
  accountant_id?: number
  tax_order_type: ITaxOrderType
  tax_structure_business: ITaxStructureBusiness
  tax_structure_personal: ITaxStructurePersonal

  senior_cpa?: number
  tax_associate?: number
  tax_controller?: number
  service_type?: ITaxOrderServiceType
}

export interface ITaxOrderStage {
  id: number
  status: string
  title: string
  stage_color?: string
}

export interface ITaxStructureArr {
  value: string
  label: string
}

export type ITaxStructureBusiness = 'c_corp' | 's_corp' | 'smllc' | 'mmllc' | 'partnership' | 'sole' | 'non_profit'

export type ITaxServicesOptions =
  | 'c_corp'
  | 's_corp'
  | 'smllc'
  | 'mmllc'
  | 'partnership'
  | 'sole'
  | 'non_profit'
  | 'single'
  | 'joint'
  | 'married'
  | 'other'

export const TaxStructureBusinessOptions: Record<ITaxStructureBusiness, string> = {
  c_corp: 'C-Corporation',
  s_corp: 'S-Corporation',
  smllc: 'SMLLC (Schedule C)',
  mmllc: 'MMLLC (Partnership)',
  partnership: 'Partnership',
  sole: 'Sole Proprietorship',
  non_profit: 'Non-Profit',
}

export type ITaxStructurePersonal = 'single' | 'joint' | 'married' | 'other'
export const TaxStructurePersonalOptions: Record<ITaxStructurePersonal, string> = {
  single: 'Single',
  joint: 'Joint',
  married: 'Married, Filling Separately',
  other: 'Other',
}

export type ITaxOrderStatus = 'open' | 'completed' | 'cancelled'
export const TaxOrderStatuses = { options: ['open', 'completed', 'cancelled'], default: 'open' as ITaxOrderStatus }

export type ITaxOrderType = 'business' | 'personal'
export const TaxOrderTypeOptions: Record<string, ITaxOrderType> = { Business: 'business', Personal: 'personal' }

export type ITaxOrderServiceType = 'tax' | 'bookkeeping'
export const TaxOrderServiceTypeOptions: Record<string, ITaxOrderServiceType> = {
  Tax: 'tax',
  Bookkeeping: 'bookkeeping',
}

export const TaxServicesOptions: Record<ITaxServicesOptions, string> = {
  c_corp: 'C-Corporation',
  s_corp: 'S-Corporation',
  smllc: 'SMLLC (Schedule C)',
  mmllc: 'MMLLC (Partnership)',
  partnership: 'Partnership',
  sole: 'Sole Proprietorship',
  non_profit: 'Non-Profit',
  single: 'Single',
  joint: 'Joint',
  married: 'Married, Filling Separately',
  other: 'Other',
}

export const TaxServicesArr: ITaxStructureArr[] = [
  { value: 'C-Corporation', label: 'C-Corporation - April 15th / October 15th' },
  { value: 'S-Corporation', label: 'S-Corporation - March 15th / September 15th' },
  { value: 'SMLLC (Schedule C)', label: 'SMLLC - April 15th / October 15th' },
  { value: 'MMLLC (Partnership)', label: 'MMLLC - MMLLC MMLLC MMLLC MMLLC' },
  { value: 'Partnership', label: 'Partnership - March 15th / September 15th' },
  { value: 'Sole Proprietorship', label: 'Sole-Proprietorship - April 15th / October 15th' },
  { value: 'Non-Profit', label: 'Non-Profit - April 15th / October 15th' },
  { value: 'Single', label: 'Single - April 15th / October 15th' },
  { value: 'Married, Filling Separately', label: 'Married, filing Single - April 15th / October 15th' },
  { value: 'Joint', label: 'Married, Filing Jointly - April 15th / October 15th' },
]
