import notify from '@jetkit/react/build/snackbarCustom/notify'
import { customFetchSuggestions } from 'api/suggestions'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import { NoteType } from 'models/note'
import * as React from 'react'
import { removeNullAndUndefined } from 'utils/objectUtils'
import useRouter2 from 'use-react-router'

export type NoteEntities = {
  types?: NoteType[]
  client_id?: number
  company_id?: number
  task_id?: number
  order_id?: number
  tax_order_id?: number
}

export type EntityFilter = Record<NoteType, boolean>

export const defaultEntityFilter = (defaultTypes?: NoteType[]): Record<NoteType, boolean> => ({
  client: !!defaultTypes && defaultTypes.includes('client'),
  order: !!defaultTypes && defaultTypes.includes('order'),
  company: !!defaultTypes && defaultTypes.includes('company'),
  task: !!defaultTypes && defaultTypes.includes('task'),
  general: !!defaultTypes && defaultTypes.includes('general'),
  prospect: !!defaultTypes && defaultTypes.includes('prospect'),
  taxorder: !!defaultTypes && defaultTypes.includes('taxorder'),
  compliance: !!defaultTypes && defaultTypes.includes('compliance'),
})

export interface ISelectedEntities {
  company_id?: number
  order_id?: number
  client_id?: number
  task_id?: number
  tax_order_id?: number
}

interface INoteEntitySelectorProps {
  setNoteEntities: (value: React.SetStateAction<NoteEntities>) => void
  noteEntities: NoteEntities
  strict?: boolean
  onEntitySelected?: (selectedEntities: ISelectedEntities) => void
}
interface IUseEntityFilter {
  defaultTypes?: NoteType[]
}

export const useEntityFilter = ({ defaultTypes }: IUseEntityFilter) => {
  const [entityFilter, setEntityFilter] = React.useState<EntityFilter>(defaultEntityFilter(defaultTypes))

  const handleTypeChange = React.useCallback(
    (type: NoteType) => {
      setEntityFilter({
        ...defaultEntityFilter(),
        [type]: true,
      })
    },
    [setEntityFilter]
  )

  return {
    entityFilter,
    setEntityFilter,
    handleTypeChange,
  }
}

const NoteEntitySelector: React.FC<INoteEntitySelectorProps> = ({
  strict,
  onEntitySelected,
  setNoteEntities,
  noteEntities,
}) => {
  const noteEntitiesContext = React.useContext(NoteEntitiesContext)

  if (!noteEntitiesContext) {
    const msg = 'Error: NoteEntitiesContext is not initialized'
    notify.warning(msg)
    console.error(msg)
    throw new Error(msg)
  }

  const [company, setCompany] = React.useState<ISuggestionAttribute | null>(noteEntitiesContext?.company || null)
  const [client, setClient] = React.useState<ISuggestionAttribute | null>(noteEntitiesContext?.client || null)
  const [order, setOrder] = React.useState<ISuggestionAttribute | null>(noteEntitiesContext?.order || null)
  const [task, setTask] = React.useState<ISuggestionAttribute | null>(noteEntitiesContext?.task || null)
  const [taxorder, setTaxorder] = React.useState<ISuggestionAttribute | null>(noteEntitiesContext?.taxorder || null)
  //
  // const router = useRouter2()

  // React.useEffect(() => {
  //   if (router.match.path.includes('company-order')) {
  //     handleNoteTypeSelect('order')
  //     setOrder({ label: text, value: order?.value || 0 })

  //     router.match.params.id
  //   }
  //   if (router.match.path.includes('company/')) {
  //     handleNoteTypeSelect('company')
  //   }
  // }, [])

  //
  React.useEffect(() => {
    // update noteEntities from noteEntitiesContext, where noteEntities is a top level search options for the whole notes panel.

    let selectedEntities: NoteEntities = {
      company_id: company?.value,
      client_id: client?.value,
      order_id: order?.value,
      task_id: task?.value,
      tax_order_id: taxorder?.value,
    }
    selectedEntities = removeNullAndUndefined(selectedEntities)

    setNoteEntities(prev => ({
      // types are not changed, as this component is only for selecting specific ids
      types: prev.types,
      ...selectedEntities,
    }))

    // to inform parent component that entities were selected.
    // probably could be done by useEffect on parent component.
    if (onEntitySelected) onEntitySelected(selectedEntities)
  }, [task, company, client, order, taxorder, setNoteEntities, onEntitySelected])

  React.useEffect(() => {
    const text = order?.label.replace('- Order Drafted', '') || ''
    setOrder({ label: text, value: order?.value || 0 })
  }, [])

  console.log('order', order)
  console.log('company', company)

  console.log('noteEntities', noteEntities)

  console.log('noteEntitiesContext', noteEntitiesContext)

  return (
    <div>
      {noteEntities?.types?.includes('client') && (
        <SelectField
          key={client?.value}
          value={client}
          onOptionSelected={setClient}
          title="Client"
          fetchSuggestions={customFetchSuggestions()}
          field="client_id"
        />
      )}
      {noteEntities?.types?.includes('company') && (
        <SelectField
          value={company}
          onOptionSelected={setCompany}
          title="Company"
          fetchSuggestions={customFetchSuggestions(
            strict
              ? removeNullAndUndefined({
                  client_id: noteEntities?.client_id,
                })
              : {}
          )}
          field="company_id"
        />
      )}
      {noteEntities?.types?.includes('order') && (
        <SelectField
          value={order}
          onOptionSelected={setOrder}
          title="Order"
          fetchSuggestions={customFetchSuggestions(
            strict
              ? removeNullAndUndefined({
                  company_id: noteEntities?.company_id,
                  client_id: noteEntities?.client_id,
                })
              : {}
          )}
          field="order_id"
        />
      )}
      {noteEntities?.types?.includes('taxorder') && (
        <SelectField
          value={taxorder}
          onOptionSelected={setTaxorder}
          title="Tax Order"
          fetchSuggestions={customFetchSuggestions(
            strict
              ? removeNullAndUndefined({
                  tax_order_id: noteEntities?.tax_order_id,
                })
              : {}
          )}
          field="tax_order_id"
        />
      )}
      {/* {noteEntities?.types?.includes('task') && (
        <SelectField
          value={task}
          onOptionSelected={setTask}
          title="Task"
          fetchSuggestions={customFetchSuggestions({})}
          field="task_id"
        />
      )} */}
    </div>
  )
}

export default NoteEntitySelector
