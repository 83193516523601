import React, { useContext, useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import AddOwner from '../AddOwner'
import { Add } from '@material-ui/icons'
import useUser from 'hooks/useUser'
import { ExistingCompanyContext, initialOwnerObj } from 'providers/existingCompany/existingCompany.provider'

const Step2 = () => {
  const classes = useStyles()
  const { user } = useUser()

  const { address, companyOwners, setCompanyOwners } = useContext(ExistingCompanyContext)

  const [oneOwner, setOneOwner] = useState<boolean | null>(null)

  useEffect(() => {
    const isPrimaryAddress = user?.addresses?.filter(item => item?.is_primary)
    const defaultOwner = {
      id: 0,
      first_name: user?.first_name || '',
      middle_name: user?.middle_name || '',
      last_name: user?.last_name || '',
      phone: user?.phone_number || '',
      email: user?.email || '',
      address: {
        is_primary: isPrimaryAddress ? isPrimaryAddress[0]?.is_primary : '',
        is_mailing: isPrimaryAddress ? isPrimaryAddress[0]?.is_mailing : '',
        zip: isPrimaryAddress ? isPrimaryAddress[0]?.zip : '',
        address_line_1: isPrimaryAddress ? isPrimaryAddress[0]?.street : '',
        city_id: isPrimaryAddress ? isPrimaryAddress[0]?.city_id : '',
      },
      shares: 0,
      existing_client: true,
    }

    if (oneOwner) {
      setCompanyOwners([defaultOwner])
    } else {
      setCompanyOwners(prev => [...prev])
    }
  }, [
    oneOwner,
    setCompanyOwners,
    user?.addresses,
    user?.email,
    user?.first_name,
    user?.last_name,
    user?.middle_name,
    user?.phone_number,
  ])

  const handleAddOwner = () => {
    setCompanyOwners(prev => {
      const newData = [...prev]
      newData.push({
        // id: newData[newData.length - 1].id + 1,
        id: (newData[newData?.length - 1]?.id || 0) + 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        shares: 0,
        address: { is_primary: true, is_mailing: false, zip: '', address_line_1: '', city_id: 0 },
      })
      return [...newData]
    })
  }

  const onDelete = index => {
    const newArray = companyOwners
    newArray.splice(index, 1)
    setCompanyOwners([...newArray])
    console.log('index', index)
  }
  const saveFormData = (object, index) => {
    const newArray = companyOwners
    newArray[index] = object
    setCompanyOwners([...newArray])
  }

  useEffect(() => {
    console.log('companyOwners', companyOwners)
    console.log('address', address)
  }, [companyOwners, address])

  return (
    <div className={classes.mainContainer}>
      <div className={classes.action}>
        <label className={classes.label}>Are you one of the owner of this business?</label>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              variant={oneOwner ? 'contained' : 'outlined'}
              color="primary"
              className={classes.button}
              onClick={() => setOneOwner(true)}
            >
              Yes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={oneOwner === false ? 'contained' : 'outlined'}
              color="primary"
              className={classes.button}
              onClick={() => {
                setOneOwner(false)
                setCompanyOwners([initialOwnerObj])
              }}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </div>

      {oneOwner ? (
        <>
          {companyOwners.map((items, i) => {
            return (
              <div key={i}>
                {i !== 0 && (
                  <Button color="primary" className={classes.deleteBtn} onClick={() => onDelete(i)}>
                    <CloseIcon />
                  </Button>
                )}
                <AddOwner
                  // setAddOwner={setAddOwner}
                  saveFormData={saveFormData}
                  index={i}
                  // setUpdateOwner={setUpdateOwner}
                  // updateOwner={updateOwner}
                  addOwnerNew={companyOwners[i]}
                />
              </div>
            )
          })}
          <div className={classes.addOwnerBtnSec}>
            <Button color="primary" endIcon={<Add />} size="small" variant="outlined" onClick={handleAddOwner}>
              Add Owner
            </Button>
          </div>
        </>
      ) : oneOwner === false ? (
        <>
          {companyOwners.map((items, i) => {
            return (
              <div key={i}>
                {i !== 0 && (
                  <Button color="primary" className={classes.deleteBtn} onClick={() => onDelete(i)}>
                    <CloseIcon />
                  </Button>
                )}
                <AddOwner
                  // setAddOwner={setAddOwner}
                  saveFormData={saveFormData}
                  index={i}
                  // setUpdateOwner={setUpdateOwner}
                  // updateOwner={updateOwner}
                  addOwnerNew={companyOwners[i]}
                />
              </div>
            )
          })}
          <div className={classes.addOwnerBtnSec}>
            <Button color="primary" endIcon={<Add />} size="small" variant="outlined" onClick={handleAddOwner}>
              Add Owner
            </Button>
          </div>
        </>
      ) : (
        ''
      )}

      {/* <CommonInput label="Who owns this business?" palcehodler={'First Name'} />
      <CommonInput palcehodler={'Middle Name'} />
      <CommonInput palcehodler={'Last Name'} />

      <div className="phone-filed">
        <label className={classes.label}>Phone</label>
        <PhoneInput value={phone} onChange={value => setPhone(value)} />
      </div>

      <CommonInput label={'Email'} palcehodler={'Email'} />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CommonInput label={'What is your business address?'} palcehodler={'Address Line'} />
          <CommonInput palcehodler={'City'} />

          <Grid container spacing={0} justify="space-between">
            <Grid item xs={4}>
              <CommonInput palcehodler={'Zip'} />
            </Grid>
            <Grid item xs={7}>
              <CommonInput palcehodler={'State'} />
            </Grid>
          </Grid>
          <CommonInput palcehodler={'Country'} />
        </Grid> 
      </Grid>  */}
      {/* <div className={classes.action}>
        <label className={classes.label}>Are you the sole owner of this business?</label>
        <Button
          variant={soleOwner ? 'outlined' : 'contained'}
          color="primary"
          className={classes.button}
          onClick={handleYes}
        >
          Yes
        </Button>
        <Button
          variant={soleOwner ? 'contained' : 'outlined'}
          color="primary"
          className={classes.button}
          onClick={handleNo}
        >
          No
        </Button>
      </div> */}

      {/* {soleOwner ? (
        <Grid container spacing={0} alignItems="flex-end" justify="space-between">
          <Grid item xs={7}>
            <CommonInput label="Ownership" palcehodler="No. Of Shares" />
          </Grid>
          <Grid item xs={4}>
            <Button color="primary" variant="text" endIcon={<Add />} size="small">
              ADD OWNER
            </Button>
          </Grid>
        </Grid>
      ) : (
        ''
      )} */}

      {/* <>
        {addOwnerNew.map((items, i) => {
          return (
            <div key={i}>
              {i !== 0 && (
                <Button color="primary" className={classes.deleteBtn} onClick={() => onDelete(i)}>
                  <CloseIcon />
                </Button>
              )}
              <AddOwner
                // setAddOwner={setAddOwner}
                saveFormData={saveFormData}
                index={i}
                // setUpdateOwner={setUpdateOwner}
                // updateOwner={updateOwner}
                addOwnerNew={addOwnerNew[i]}
              />
            </div>
          )
        })}
        <Button
          color="primary"
          onClick={e => {
            setAddOwner(prev => {
              const newData = [...prev]
              newData.push({
                id: newData[newData.length - 1].id + 1,
                first_name: '',
                middle_name: '',
                last_name: '',
                email: '',
                phone: '',
                shares: 0,
                address: addressObj,
              })
              return [...newData]
            })
          }}
        >
          Add Owner
        </Button>
      </> */}
    </div>
  )
}

export default Step2

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      width: '100%',
      minHeight: '430px',
      fontFamily: 'Lato',

      '& .phone-filed': {
        '& .react-tel-input': {
          marginTop: '0.25rem !important',
          '& input': {
            height: '2rem !important',
          },
        },
      },
    },
    addOwnerBtnSec: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
    label: {
      textTransform: 'uppercase',
      color: '#202124',
      fontSize: '0.8rem',
      fontFamily: 'Roboto, sans-serif',
      marginBottom: '0.25rem',
    },
    action: {
      margin: '10px 0 0 ',
      '& > .MuiButton-containedPrimary': {
        color: '#fff',
      },
    },
    button: {
      padding: '6.5px 37.5px ',
      margin: '10px 20px 0 0',
      width: '100%',
    },
    deleteBtn: {
      margin: '20px 0 0 0',
      width: '100%',
      justifyContent: 'flex-end',
      padding: '0 ',
    },
  })
)
