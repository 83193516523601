import { makeStyles } from '@material-ui/core/styles'
import { addLabelsForCompany, findLabelList, getCompanyLabels } from 'api/label'
import React, { useCallback, useEffect, useState } from 'react'
import showSuccessNotification from 'utils/showSuccessNotification'
import showApiResponseError from 'utils/showApiResponseError'
import Labels from 'components/labels'
import { LABEL_TYPES } from 'utils/constant'
import { truncate } from 'fs'
// const colourStyles: StylesConfig<ColourOption, true> = {
//     control: (styles) => ({ ...styles, backgroundColor: 'white' }),
//     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//       const color = chroma(data.color);
//       return {
//         ...styles,
//         backgroundColor: isDisabled
//           ? undefined
//           : isSelected
//           ? data.color
//           : isFocused
//           ? color.alpha(0.1).css()
//           : undefined,
//         color: isDisabled
//           ? '#ccc'
//           : isSelected
//           ? chroma.contrast(color, 'white') > 2
//             ? 'white'
//             : 'black'
//           : data.color,
//         cursor: isDisabled ? 'not-allowed' : 'default',

//         ':active': {
//           ...styles[':active'],
//           backgroundColor: !isDisabled
//             ? isSelected
//               ? data.color
//               : color.alpha(0.3).css()
//             : undefined,
//         },
//       };
//     },
//     multiValue: (styles, { data }) => {
//       const color = chroma(data.color);
//       return {
//         ...styles,
//         backgroundColor: color.alpha(0.1).css(),
//       };
//     },
//     multiValueLabel: (styles, { data }) => ({
//       ...styles,
//       color: data.color,
//     }),
//     multiValueRemove: (styles, { data }) => ({
//       ...styles,
//       color: data.color,
//       ':hover': {
//         backgroundColor: data.color,
//         color: 'white',
//       },
//     }),
//   };
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))
const TaxOrderLabels = ({ taxOrderId }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  //   console.log('taxOrderId', taxOrderId)
  const [dataList, setDataList] = useState<any>([])
  const [selectedList, setSelectedList] = useState<any>([])
  const [listLoading, setListLoading] = useState(false)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    getListfunc()
  }, [])

  const getListfunc = async () => {
    try {
      const res = await findLabelList(LABEL_TYPES.taxorder)
      setDataList(res)
    } catch (error) {}
  }
  useEffect(() => {
    const getList = async () => {
      try {
        setListLoading(true)
        const res = await getCompanyLabels(taxOrderId, LABEL_TYPES.taxorder)
        setListLoading(false)

        setSelectedList(res)
      } catch (error) {
        setListLoading(false)
      }
    }
    getList()
  }, [taxOrderId])
  const handleChange = selectArray => {
    setSelectedList(prev => [...prev, selectArray])
    setChanged(true)
  }
  useEffect(() => {
    if (selectedList.length > 0 && dataList.length > 0) {
      const filteredData: any[] = []
      for (let i = 0; i < dataList.length; i++) {
        let count = 0
        for (let j = 0; j < selectedList.length; j++) {
          if (dataList[i].value !== selectedList[j].value) {
            count += 1
          }
        }
        if (count === selectedList.length) {
          filteredData.push(dataList[i])
        }
      }

      setDataList(filteredData)
    }
  }, [selectedList])
  useEffect(() => {
    // const func =async()=>{

    // }
    changed && saveCompanyLabels()
  }, [selectedList])
  const saveCompanyLabels = async () => {
    const ids: number[] = selectedList.map(item => {
      return item.value
    })
    const uniq: number[] = [...new Set(ids)]
    try {
      setLoading(true)
      await addLabelsForCompany({ data: uniq }, taxOrderId, LABEL_TYPES.taxorder)
      setLoading(false)

      showSuccessNotification('Updated SuccessFully')
    } catch (error) {
      showApiResponseError(error, 'Error Updating')
      setLoading(false)
    }
  }
  const onDeleteClick = async val => {
    console.log(val)
    const filteredData = selectedList.filter(object => {
      return object.value !== val
    })
    setSelectedList(filteredData ? filteredData : [])
    setChanged(true)
    await getListfunc()
  }
  return (
    <Labels
      title="Tax Order Labels"
      dataList={dataList}
      selectedList={selectedList}
      handleChange={handleChange}
      loading={loading}
      handleSave={saveCompanyLabels}
      onDeleteClick={onDeleteClick}
      listLoading={listLoading}
    />
  )
}

export default TaxOrderLabels
