import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { positiveNumericInput } from 'components/common/functions'
import BRTextField from 'components/common/textFields/BRTextField'
import * as React from 'react'
import InputMask from 'react-input-mask'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    textField: {
      width: '100%',
      margin: '0.7rem 0',
    },
  })
)

interface IEinInputProps {
  value?: number | string
  onChange(value: string): void
  error?: boolean
  label?: string
  style?: React.CSSProperties
  classes?: Record<string, string>
  disabled?: boolean
  showCopyButton?: boolean
}

const EinInput: React.FC<IEinInputProps> = props => {
  const { value, onChange, error, label = 'EIN', style, disabled, showCopyButton } = props
  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <InputMask
        mask="99-9999999"
        disabled={disabled}
        value={value}
        onChange={event => onChange(positiveNumericInput(event.target.value))}
        className={classes.textField}
      >
        {() => (
          <BRTextField
            showCopyButton={showCopyButton}
            disabled={disabled}
            error={error}
            style={style}
            helperText={error && `Please Specify EIN`}
            label={label}
            className={classes.textField}
            type="text"
            name="EIN"
            variant="outlined"
            margin="normal"
          />
        )}
      </InputMask>
    </div>
  )
}

export default EinInput
