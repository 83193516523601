import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'

import { useHistory } from 'react-router'
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ReminderList from 'components/AutoReminder/ReminderList'

const ReminderTemplateList = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.missingItems}>Auto Reminder</p>
          <p className={classes.tableTitle}>Auto Reminder List</p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => history.push(`/reminder-template`)}>
            Add Auto Reminder
          </ButtonWithLoading>
        </div>
      </div>
      <ReminderList />
    </div>
  )
}

export default ReminderTemplateList

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
