/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Avatar, Grid, Divider, Backdrop, CircularProgress, AppBar, Tabs, Tab, Box } from '@material-ui/core'
import React, { useState, useEffect, useCallback, createContext, useMemo } from 'react'
import { useStyles } from '../style'

import Business from '../img/business.svg'
import Compliance from '../img/compliance.svg'
import License from '../img/license.svg'

import HeadingHeader from '../headingHeader'
import IconCard from '../iconCard'
import HistoryTabs from '../tabs'
import { LocalAtm } from '@material-ui/icons'
import UploadIcon from 'img/upload-icon.png'
// import EditIcon from 'img/edit-icon.png'
import UploadIcons from 'img/b-upload-icon.png'
import CompanyDocumentTable from '../table/companyDocumentTable'
import CompanyOwnerAccordian from '../table/companyOwnerAccordian'
import CompanyInformation from '../table/companyInformation'
import ViewModal from '../viewModal'
import File from 'img/document.png'
import Money from 'img/money-bill.png'
import { useParams } from 'react-router'
import {
  getClientCamplianceTask,
  getClientCompanyById,
  getClientOrder,
  getClientTaxOrder,
  getCompanyOwnersById,
  getSpecialOffers,
  specialOfferCompanies,
  ComplianceTaskParams,
} from 'api/clientDashboard'
import { IDetailedCompanyResponse } from 'models/company'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import { IClientTax, ICompanyOwner, ISpecialOffers } from 'models/user'
import { Skeleton } from '@material-ui/lab'
import useRouter from 'use-react-router'
import { OfferCard } from '../offers'
import { openPopupWidget } from 'react-calendly'
import showErrorNotification from 'utils/showErrorNotification'
import { ComplianceTasks, ComplianceTasksData, PaginationData } from 'api/complianceCalendar'
import CompanyInfo from './companyInfo'
import CompanyProfile from './companyProfile'
import CompanyOwners from './companyOwners'
import LicensesPermitAccordion from './licensesPermitAccordion'
import TaxesAccordion from './taxesAccordion'
import PowerUpCard from './powerUpCard'
import { ISpecialOffersCategory, getSpecialoffersCategories, getCompanySpecialOffers } from 'api/specialOffers'
import ScheduleCallCard from '../scheduleCallCard'
import CreditScoreBar from './creditScoreBar'
import PowerCard from './powerUp/powerCard'
// import { Redirect } from 'react-router-dom';
// <Redirect to='/profile' />

interface ContextData {
  complianceTasks: ComplianceTasksData[]
  paginationDataClient: PaginationData | any
  // complianceTasks: ComplianceTasks | any
  getComplianceTask: (taskPayload: ComplianceTaskParams) => void
  isComplianceLoading: boolean
}

export const ComplianceDataCtx = createContext<ContextData>({
  // complianceTasks: undefined,
  complianceTasks: [],
  paginationDataClient: null,

  getComplianceTask: () => {},
  isComplianceLoading: false,
})

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={6} className="company-box">
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `company-tab-${index}`,
    'aria-controls': `company-tabpanel-${index}`,
  }
}

const Client = () => {
  const classes = useStyles()
  const [isTaxes, setIsTaxes] = useState(false)
  const [isLicenses, setIsLicenses] = useState(false)
  const [companyDetail, setCompanyDetail] = useState<IDetailedCompanyResponse>()
  const [companyOwners, setCompanyOwners] = useState<ICompanyOwner[]>([])
  const [clientTaxs, setClientTaxs] = useState<IClientTax[]>([])
  const [clientLicenses, setClientLicenses] = useState([])
  const [selectedTax, setSelectedTax] = useState<IClientTax>()
  const [selectedOrder, setSelectedOrder] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [specialOfferCategories, setSpecialOfferCategories] = useState<ISpecialOffersCategory[]>([])
  const [specialOffers, setSpecialOffers] = useState<ISpecialOffers[]>([])

  const [complianceTasks, setComplianceTasks] = useState<ComplianceTasksData[]>([])
  // const [complianceTasks, setComplianceTasks] = useState<any>()

  const [paginationDataClient, setPaginationDataClient] = useState<PaginationData>()

  const [isComplianceLoading, setIsComplianceLoading] = useState(false)

  const [isFetchSpecialOffer, setIsFetchSpecialOffer] = useState(false)

  const [offers, setOffers] = useState<any>([])

  const [clientPage, setClientPage] = useState(0)
  const [clientRowsPerPage, setClientRowsPerPage] = useState(5)

  const [tabValue, setTabValue] = React.useState(0)

  const { id } = useParams<{ id: string }>()

  const router = useRouter()
  React.useEffect(() => {
    // updatePageVisitsCount()
  }, [])
  const getCompanyDetail = useCallback(async () => {
    setIsLoading(true)
    try {
      const details = await getClientCompanyById(id)
      setCompanyDetail(details)
      if (details?.error) {
        router.history.push('/something-went-wrong')
      }
    } catch (error) {
      setIsLoading(true)
    }
  }, [id, router.history])

  useEffect(() => {
    getCompanyDetail()
  }, [getCompanyDetail])

  useEffect(() => {
    const getCompanySpecial = async (id: string) => {
      try {
        const result = await getCompanySpecialOffers(id)
        setSpecialOffers(result)
        console.log('companyID:number', result)
      } catch (error) {}
    }
    if (id) {
      getCompanySpecial(id)
    }
  }, [id])

  const getCompanyOwners = async () => {
    setIsLoading(true)
    try {
      const owners = await getCompanyOwnersById(id)
      setCompanyOwners(owners)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
    }
  }

  const getCompanyClientOrder = async () => {
    setIsLoading(true)
    try {
      const orders = await getClientOrder(id)
      setClientLicenses(orders)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
    }
  }

  const getCompanyClientTaxOrder = async () => {
    setIsLoading(true)
    try {
      const taxOrders = await getClientTaxOrder(id)
      setClientTaxs(taxOrders)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
    }
  }

  const getOffers = async () => {
    try {
      const offers = await getSpecialOffers()
      setOffers(offers)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getCompanyOwners()
    getCompanyClientOrder()
    getCompanyClientTaxOrder()
    getOffers()
  }, [])

  const getComplianceTask = useCallback(
    async (taskPayload: ComplianceTaskParams) => {
      debugger
      const { company_id, due_date, order_type_id, status, compliance_type } = taskPayload
      setIsComplianceLoading(true)
      debugger

      if (id || company_id || due_date || order_type_id || status || compliance_type) {
        const payload = {
          company_id: id ? id : company_id,
          status: status === '0' ? status.toString() : '',
          due_date: due_date ? due_date : '',
          compliance_type: compliance_type ? compliance_type : '',
          // order_type_id: order_type_id ? order_type_id : null,
          // status: statusType,
          page: clientPage + 1,
          page_size: clientRowsPerPage,
        }
        try {
          debugger

          const tasks = await getClientCamplianceTask(payload)
          setComplianceTasks(tasks?.data)
          setPaginationDataClient(tasks?.pagination)
          setIsComplianceLoading(false)
        } catch (error) {
          showErrorNotification(error)
          setIsComplianceLoading(false)
        }
      } else return
    },
    [clientPage, clientRowsPerPage, id]
  )
  // useEffect(() => {
  //   getComplianceTask('1')
  // }, [])

  // const value = useMemo(
  //   () => ({
  //     complianceTasks,
  //     getComplianceTask,
  //   }),
  //   [complianceTasks, getComplianceTask]
  // )

  const offerModalHandler = async (data: any) => {
    const offer_id: number = data?.id
    const company_id: string = id
    setIsFetchSpecialOffer(true)
    try {
      const res = await specialOfferCompanies({ company_id, offer_id })
      if (data?.ispopup) {
        openPopupWidget({ url: data?.link })
      } else {
        window.open(data?.link)
      }
      setIsFetchSpecialOffer(false)

      return res
    } catch (error) {
      showErrorNotification(error)
      setIsFetchSpecialOffer(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    const getSpecialoffersCat = async () => {
      try {
        const result: ISpecialOffersCategory[] = await getSpecialoffersCategories()
        setSpecialOfferCategories(result)
      } catch (error) {
        console.log('error', error)
      }
    }
    getSpecialoffersCat()
  }, [])

  return (
    <div className={classes.clientDashboard}>
      <span className="inline-icon-heading">
        {isLoading ? (
          <>
            <Skeleton variant="circle" width={40} height={40} className="avatar" />
            <Skeleton className="main-heading" variant="text" width={250} height={60} />
          </>
        ) : (
          <>
            <Avatar className="avatar">{companyDetail?.name?.charAt(0).toUpperCase()}</Avatar>
            <h1 className="main-heading">{companyDetail?.name?.toUpperCase()}</h1>
          </>
        )}
      </span>
      {/* <Divider /> */}
      {/* <ScheduleCallCard /> */}
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8} xl={9}>
          <div className="upcoming-deadlines">
            <HeadingHeader title="Upcoming Deadlines" />
          </div>
          <ComplianceDataCtx.Provider
            value={{ complianceTasks, getComplianceTask, isComplianceLoading, paginationDataClient }}
          >
            <HistoryTabs
              clientPage={clientPage}
              setClientPage={setClientPage}
              clientRowsPerPage={clientRowsPerPage}
              setClientRowsPerPage={setClientRowsPerPage}
            />
          </ComplianceDataCtx.Provider>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} className={classes.companyProfileCard}>
          <CompanyProfile profileScore={companyDetail?.profile_score || 30} companyName={companyDetail?.name || ''} />
        </Grid>
      </Grid>

      <div className={classes.inlineHeading}>
        <HeadingHeader title="Company Information" />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8} xl={9}>
          <div className={classes.tabRoot}>
            <AppBar position="static" color="default" className="tab-app-container">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="client tabs"
                variant="scrollable"
                // scrollButtons="auto"
                scrollButtons="on"
              >
                <Tab label="Company Information" {...a11yProps(0)} />
                <Tab label="Company Owners" {...a11yProps(1)} />
                <Tab label="Company Documents" {...a11yProps(2)} />
                {/* <Tab label="Licenses &amp; Permits" {...a11yProps(3)} /> */}
                <Tab label="Company Orders" {...a11yProps(3)} />
                <Tab label="Taxes" {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <CompanyInfo
                clientName={`${companyDetail?.client?.first_name || ''} ${companyDetail?.client?.last_name || ''}`}
                company={companyDetail?.name || ''}
                // fileDate={formatDateToHumanFriendly(companyDetail?.file_date || '')}
                fileDate={companyDetail?.file_date || ''}
                ein={companyDetail?.ein || ''}
                stateId={companyDetail?.state_id || ''}
                businessAddress={`${companyDetail?.primary_address?.street || ''}, ${companyDetail?.primary_address
                  ?.city || ''}, ${companyDetail?.primary_address?.locality || ''}, ${companyDetail?.primary_address
                  ?.zip || ''}`}
                formationState={companyDetail?.state_of_formation || ''}
                business_active_status={companyDetail?.business_active_status || ''}
                expected_ein_date={companyDetail?.expected_ein_date || ''}
                expected_file_date={companyDetail?.expected_file_date || ''}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <CompanyOwners ownersList={companyOwners} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <CompanyDocumentTable />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <LicensesPermitAccordion clientLicenses={clientLicenses} />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <TaxesAccordion clientTaxes={clientTaxs} />
            </TabPanel>
          </div>
        </Grid>
        {companyDetail?.experian_business_id ? (
          <Grid item xs={12} lg={4} xl={3}>
            <CreditScoreBar
              creditScore={companyDetail?.experian_credit_score ? companyDetail?.experian_credit_score : 40}
            />
          </Grid>
        ) : (
          ''
        )}
        {/* <Grid item xs={12} lg={4} xl={3} className={classes.companyProfileCard}>
          <CompanyProfile profileScore={companyDetail?.profile_score || 30} companyName={companyDetail?.name || ''} />
        </Grid> */}
      </Grid>
      <div className={classes.handPickedPowersUp}>
        {specialOffers?.length ? (
          <>
            <div className="hands-powerup-header">
              <HeadingHeader title="Hand-Picked Power-Ups" />
            </div>
            <p style={{ marginTop: '-1.5rem' }}>
              Based on your company profile, we recommend the following product for your needs.
            </p>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8} xl={9}>
                <Grid container spacing={3}>
                  {specialOffers?.length > 0
                    ? specialOffers?.map((item: ISpecialOffers) => (
                        <Grid item xs={12} sm={6} lg={6} key={item?.id}>
                          <PowerCard
                            title={item?.title || ''}
                            heading={item?.heading || ''}
                            subheading={item?.subheading || ''}
                            description={item?.description || ''}
                            link={item?.link || ''}
                            logo={item?.logo || ''}
                            button_text={item?.button_text || ''}
                            onClick={() => offerModalHandler(item)}
                            terms_text={item?.terms_text || ''}
                          />
                        </Grid>
                      ))
                    : [...Array(6)].map((value: undefined, index: number) => (
                        <Grid item xs={12} sm={6} lg={3} key={index}>
                          <Skeleton variant="rect" height={145} />
                        </Grid>
                      ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ' '
        )}
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={8} xl={9}>
          <div className={classes.powersUpHeading}>
            <HeadingHeader title="Power-Ups" isLink={true} linkText="See All" linkTo="/power-up/all" />
          </div>
          <Grid container spacing={3}>
            {specialOfferCategories?.length > 0
              ? specialOfferCategories?.map((offer: ISpecialOffersCategory) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={offer.id}>
                    <PowerUpCard
                      category_img={offer?.category_img}
                      title={offer?.title}
                      link_text={offer?.link_text}
                      heading={offer?.heading}
                      description={offer?.description}
                      id={offer?.id}
                    />
                  </Grid>
                ))
              : [...Array(6)].map((value: undefined, index: number) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                    <Skeleton variant="rect" height={145} />
                  </Grid>
                ))}
          </Grid>
        </Grid>
        {/* {companyDetail?.experian_business_id ? (
          <Grid item xs={12} lg={4} xl={3}>
            <CreditScoreBar
              creditScore={companyDetail?.experian_credit_score ? companyDetail?.experian_credit_score : 40}
            />
          </Grid>
        ) : (
          ''
        )} */}
      </Grid>
      {/* <Grid container spacing={3}>
        {offers?.length > 0
          ? offers?.map((offer, key) => (
              <Grid item xs={6} sm={4} md={2} key={key}>
                <OfferCard
                  img={offer.logo}
                  // mainTextColor={offer.color}
                  borderColor={offer.color}
                  mainText={offer.title}
                  onClick={() => offerModalHandler(offer)}
                />
              </Grid>
            ))
          : [...Array(6)].map((value: undefined, index: number) => (
              <Grid item xs={2} key={index}>
                <Skeleton variant="rect" height={145} />
              </Grid>
            ))}
      </Grid> */}

      <Backdrop className={classes.backdrop} open={isFetchSpecialOffer} onClick={() => setIsFetchSpecialOffer(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Client

// <CompanyInformation
// companyName={companyDetail?.name || ''}
// stateIdNum={companyDetail?.state_id || ''}
// busAddress={companyDetail?.primary_address || null}
// mailAddress={companyDetail?.mailing_address || null}
// fileDate={formatDateToHumanFriendly(companyDetail?.file_date || '')}
// einNumber={companyDetail?.ein || ''}
// stateFormation={companyDetail?.state_of_formation || ''}
// busStructure={companyDetail?.business_activity || ''}
// isLoading={isLoading}
// profileScore={companyDetail?.profile_score || 30}
// />

// <div className={classes.inlineHeading}>
// <HeadingHeader title="Company Owners" />
// {/* <span>
//   Edit
//   <img src={EditIcon} alt="icon" />
// </span> */}
// </div>
// <Grid container spacing={3} className="accordian-table">
// <Grid className="accordian-table-text" item xs={3}>
//   Owner
// </Grid>
// <Grid className="accordian-table-text" item xs={3}>
//   Phone
// </Grid>
// <Grid className="accordian-table-text extra-space" item xs={3}>
//   Email
// </Grid>
// <Grid className="accordian-table-text extra-space" item xs={3}>
//   Ownership
// </Grid>
// </Grid>
// {isLoading ? (
// <Skeleton variant="rect" height={64} />
// ) : (
// companyOwners.length &&
// companyOwners?.map(companyOwner => <CompanyOwnerAccordian companyOwner={companyOwner} isLoading={isLoading} />)
// )}

// <br />
// <HeadingHeader title="Company Documents" />

// <Grid container spacing={0} style={{ width: 'calc(100% + 16px)' }} alignItems="center" justify="space-between">
// <Grid item xs={2} className="secondary-section">
//   <p className="secondary-heading">Documents</p>
// </Grid>
// {/* <Grid item xs={2} className={classes.uploadSec}>
//   <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" />
//   <label htmlFor="contained-button-file">
//     <span>Upload New</span> <img src={UploadIcons} alt="icon" />
//   </label>
// </Grid> */}
// </Grid>

// <CompanyDocumentTable />

// <Grid container spacing={0} style={{ width: 'calc(100% + 16px)' }}>
// <Grid item xs={6} sm={4} md={2} className="secondary-section">
//   <p className="secondary-heading">Licenses &amp; Permints</p>
// </Grid>
// </Grid>

// <Grid container spacing={3}>
// {clientLicenses.length &&
//   clientLicenses?.map(
//     (item: any) =>
//       item?.order_type?.name !== 'Business Formation' && (
//         <Grid item xs={6} sm={4} md={2}>
//           <IconCard
//             title={item?.order_type?.name || ''}
//             icon={<img src={Business} alt="icon" className="icon" />}
//             onClick={() => {
//               setIsLicenses(true)
//               setSelectedOrder(item)
//             }}
//           />
//         </Grid>
//       )
//   )}

// {/* <Grid item xs={2}>
//   <IconCard
//     title="Seller Permit"
//     icon={<img src={Compliance} alt="icon" className="icon" />}
//     onClick={() => setIsLicenses(true)}
//   />
// </Grid>
// <Grid item xs={2}>
//   <IconCard
//     title="TCP License"
//     icon={<img src={License} alt="icon" className="icon" />}
//     onClick={() => setIsLicenses(true)}
//   />
// </Grid> */}
// {/* <Grid item xs={2}>
//   <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" />
//   <label htmlFor="contained-button-file">
//     <div className={classes.iconCard}>
//       <img src={UploadIcon} alt="icon" />
//       <span className="upload-text">Upload New</span>
//     </div>
//   </label>
// </Grid> */}
// </Grid>

// <Grid container spacing={0} style={{ width: 'calc(100% + 16px)' }}>
// <Grid item xs={6} sm={4} md={2} className="secondary-section">
//   <p className="secondary-heading">Taxes</p>
// </Grid>
// </Grid>

// <Grid container spacing={3}>
// {clientTaxs.length &&
//   clientTaxs?.map(tax => (
//     <Grid item xs={6} sm={4} md={2} key={tax?.id}>
//       <IconCard
//         title={`Taxes ${tax?.tax_year}`}
//         icon={<img src={Money} alt="icon" className="icon" />}
//         onClick={() => {
//           setIsTaxes(true)
//           setSelectedTax(tax)
//         }}
//       />
//     </Grid>
//   ))}

// {/* <Grid item xs={2}>
//   <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" />
//   <label htmlFor="contained-button-file">
//     <div className={classes.iconCard}>
//       <img src={UploadIcon} alt="icon" />
//       <span className="upload-text">Upload New</span>
//     </div>
//   </label>
// </Grid> */}
// </Grid>

// <ViewModal
// title={selectedOrder?.order_type?.name}
// open={isLicenses}
// setOpen={setIsLicenses}
// orderStatus={selectedOrder?.order_stage?.title}
// // orderCompleteDate={formatDateToHumanFriendly(selectedOrder?.completed_on || '')}
// orderCompleteDate={
//   selectedOrder?.completed_on?.length ? formatDateToHumanFriendly(selectedOrder?.completed_on) : 'Processing'
// }
// orderStartDate={formatDateToHumanFriendly(selectedOrder?.creation_time || '')}
// icon={File}
// />

// <ViewModal
// title={`Taxes ${selectedTax?.tax_year}`}
// open={isTaxes}
// setOpen={setIsTaxes}
// orderStatus={selectedTax?.tax_order_type}
// // orderCompleteDate={formatDateToHumanFriendly(selectedTax?.completed_at || '')}
// orderCompleteDate={
//   selectedTax?.completed_at?.length ? formatDateToHumanFriendly(selectedTax?.completed_at) : 'Processing'
// }
// orderStartDate={formatDateToHumanFriendly(selectedTax?.creation_time || '')}
// icon={Money}
// />
// <Backdrop className={classes.backdrop} open={isFetchSpecialOffer} onClick={() => setIsFetchSpecialOffer(false)}>
// <CircularProgress color="inherit" />
// </Backdrop>
