import { PostStepNewIds, OrderStepNewIds } from './constant'

export const stepMappings = (step: string | number | undefined) => {
  switch (step) {
    case 0:
      return 'State of Formation'
    case OrderStepNewIds.StepBusinessNameAndState:
      return 'Busniness Name & State of Formation'
    case OrderStepNewIds.StepContactInformation:
      return 'Contact Information'
    case OrderStepNewIds.StepBusinessActivity:
      return 'Business Activity'
    case OrderStepNewIds.StepBusinessAddress:
      return 'Business Address'
    case OrderStepNewIds.StepIPostal:
      return 'IPostal'
    // return 'Offer Unlocked'
    case OrderStepNewIds.StepOwnershipCount:
      return 'Owners'
    case OrderStepNewIds.StepPackages:
      return 'Packages'
    case OrderStepNewIds.StepOfferUnlocked:
      // return 'Gold Standard'
      return 'Offer Unlocked'
    case OrderStepNewIds.StepGoldStandard:
      return 'Gold Standard'
    case OrderStepNewIds.StepReviewAndCheckout:
      return 'Review & Checkout'

    case OrderStepNewIds.StepPayment:
      return 'Payment'
    case OrderStepNewIds.StepThankyou:
      return 'Thank You'
    case OrderStepNewIds.StepAgreement:
      return 'Agreement'
    case OrderStepNewIds.StepBankAccount:
      return 'Bank Account'
    case OrderStepNewIds.StepRush:
      return 'Rush Processing'
    case OrderStepNewIds.StepEIN:
      return 'Ein'
    case OrderStepNewIds.StepRocket:
      return 'Rocket Compliance'
    case OrderStepNewIds.StepTaxBandle:
      return 'Business Tax Subscription'
    default:
      return step
  }
}

// export const postpaymentStepMappings = (step: string | number | undefined) => {
//   switch (step) {
//     case PostStepNewIds.StepAddressConfirmation:
//       return 'Address Confirmation'
//     case PostStepNewIds.StepOwnership:
//       return 'Owners'
//     case PostStepNewIds.StepRegisterAgent:
//       return 'Registered Agent'
//     case PostStepNewIds.StepEINUpgrades:
//       return 'Ein Registrar'
//     case PostStepNewIds.StepMailingAddress:
//       return 'Mailing Address'
//     case PostStepNewIds.StepAgreementUpgrade:
//       return 'Agreements Upgrade'
//     case PostStepNewIds.StepRush:
//       return 'Rush Processing'
//     case PostStepNewIds.StepSCorpUpgrade:
//       return 'S-Corp Upgrade'
//     case PostStepNewIds.StepReviewAndCheckout:
//       return 'Checkout'
//     case PostStepNewIds.StepPayment:
//       return 'Payment'
//     case PostStepNewIds.StepThankyou:
//       return 'Thank You'

//     default:
//       return ''
//   }
// }

export const postpaymentStepMappings = (step: string | number | undefined) => {
  switch (step) {
    case PostStepNewIds.StepAddressConfirmation:
      return 'Address Confirmation'
    case PostStepNewIds.StepOwnership:
      return 'Owners'
    case PostStepNewIds.StepRegisterAgent:
      return 'Registered Agent'
    case PostStepNewIds.StepEINUpgrades:
      return 'Ein Registrar'
    case PostStepNewIds.StepMailingAddress:
      return 'Mailing Address'
    case PostStepNewIds.StepAgreementUpgrade:
      return 'Agreements Upgrade'
    case PostStepNewIds.StepRush:
      return 'Rush Processing'
    case PostStepNewIds.StepSCorpUpgrade:
      return 'S-Corp Upgrade'
    case PostStepNewIds.StepShareNumber:
      return 'No. of Shares'
    case PostStepNewIds.StepPositionConfirmation:
      return 'Position Confirmation'
    case PostStepNewIds.StepBank:
      return 'Bank Owners'
    case PostStepNewIds.StepTaxBandle:
      return 'Business Tax Subscription'
    case PostStepNewIds.StepReviewAndCheckout:
      return 'Checkout'
    case PostStepNewIds.StepPayment:
      return 'Payment'
    case PostStepNewIds.StepThankyou:
      return 'Thank You'

    default:
      return ''
  }
}

// export const otherPostPaymentStepMappingsOther = (step: string | number | undefined) => {
//   switch (step) {
//     case PostStepNewIds.StepAddressConfirmation:
//       return 'Address Confirmation'
//     case PostStepNewIds.StepOwnership:
//       return 'Owners'
//     case PostStepNewIds.StepRegisterAgent:
//       return 'Registered Agent'
//     case PostStepNewIds.StepEINUpgrades:
//       return 'Ein Registrar'
//     case PostStepNewIds.StepMailingAddress:
//       return 'Mailing Address'
//     case PostStepNewIds.StepAgreementUpgrade:
//       return 'Agreements Upgrade'
//     case PostStepNewIds.StepRush:
//       return 'Rush Processing'
//     case PostStepNewIds.StepSCorpUpgrade:
//       return 'S-Corp Upgrade'
//     case PostStepNewIds.StepReviewAndCheckout:
//       return 'Checkout'
//     case PostStepNewIds.StepPayment:
//       return 'Payment'
//     case PostStepNewIds.StepThankyou:
//       return 'Thank You'

//     default:
//       return ''
//   }
// }
