import React from 'react'
import { useParams } from 'react-router'
import { useStyles } from './style'
import DetailReport from './detailReport'
import TableReport from './tableReport'
import moment from 'moment'
import { updatePageVisitsCount } from 'utils/user'

const EmployeeReport = () => {
  const classes = useStyles()
  const { id } = useParams<any>()

  const [startData, setStartData] = React.useState(moment())
  const [endData, setEndData] = React.useState(moment().format('YYYY-MM-DD 23:59 Z'))

  return (
    <div className={classes.container}>
      <h1 className="primary-heading">Employee Report # {id}</h1>

      <DetailReport
        employeeId={id}
        startData={startData}
        setStartData={setStartData}
        endData={endData}
        setEndData={setEndData}
      />

      <TableReport employeeId={id} startData={startData} endData={endData} />
    </div>
  )
}

export default EmployeeReport
