import TextField from '@material-ui/core/TextField'
import { useStyles } from '../../../pages/subscription/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ChangeEvent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Container from '@material-ui/core/Container'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Switch } from '@material-ui/core'
import React from 'react'
import { ICreateProductReq, IProductResponse, updateSubscription } from 'api/subscription'
import { createSubscription } from 'api/subscription'
import { FormMode } from 'components/common/forms/FormMode'
import showApiResponseError from 'utils/showApiResponseError'
import { assertNonNullable } from 'utils/asserts'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import showSuccessNotification from 'utils/showSuccessNotification'
import { Editor } from '@tinymce/tinymce-react'
import { EditorApiKey } from 'utils/constant'

interface IPrice {
  amount?: number
  billing_period?: string
  recurring?: boolean
  id?: number
  name?: string
}
export interface ISubscriptionDetailsProps {
  editedProduct?: ICreateProductReq
  initialClient?: any
  onRequestSuccessful: (client: ICreateProductReq) => void
  title?: string
  fromProspect?: boolean
}
const ProductInfo: React.FC<ISubscriptionDetailsProps> = ({
  editedProduct,
  onRequestSuccessful,
  initialClient,
  title,
}) => {
  const classes = useStyles()
  const formMode: FormMode = editedProduct && editedProduct.id ? FormMode.UPDATE : FormMode.CREATE

  const [subscription, setSubscription] = React.useState<ICreateProductReq>(
    editedProduct ? { ...editedProduct } : { name: '', pricing: [], email: '', content: '', meeting_link: '' }
  )

  const [modalArray, setModalArray] = useState<IPrice[]>(
    subscription.pricing?.length ? subscription.pricing : [{ amount: 0, billing_period: 'year', recurring: true }]
  )
  const [name, setName] = useState<string>(subscription.name || '')
  const [email, setEmail] = useState<string>(subscription.email || '')
  const [content, setContent] = useState<string>(subscription.content || '')
  const [link, setLink] = useState<string>(subscription.meeting_link || '')

  const onSubmitClick = React.useCallback(async (): Promise<any> => {
    if (!name || !modalArray.length) return
    let response
    try {
      debugger
      if (formMode === FormMode.UPDATE) {
        if (!subscription.id) return
        response = await updateSubscription(subscription)
        showSuccessNotification('Subscription Updated Successfully')
      } else {
        response = await createSubscription(subscription)
        showSuccessNotification('Subscription Created Successfully')
      }
      if (formMode === FormMode.UPDATE) setSubscription(response)
      else
        setSubscription({
          ...subscription,
          id: response.id,
        })
    } catch (error) {
      showApiResponseError(error, 'Error Creating Subscription')
    }
    return true
  }, [subscription, formMode, name, modalArray])
  React.useEffect(() => {
    setSubscription({
      name: name,
      pricing: modalArray,
      email: email,
      content: content,
      meeting_link: link,
      id: editedProduct ? editedProduct.id : undefined,
    })
  }, [name, modalArray, email, content, link, editedProduct])
  const handleChangeName = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setName(e.target.value as string)
  }

  const handleChangeEmail = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setEmail(e.target.value as string)
  }
  const handleChangeLink = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setLink(e.target.value as string)
  }

  const handleChangePrice = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>, index: number) => {
    const pricingValue = e.target.value as string
    const priceValueIntoNumber = +pricingValue
    if (typeof priceValueIntoNumber === 'number') {
      const tempArr: any = [...modalArray]
      tempArr[index].amount = e.target.value
      setModalArray(tempArr)
    }
  }
  const handleChangePriceName = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>, index: number) => {
    const name = e.target.value as string
    if (typeof name === 'string') {
      const tempArr: any = [...modalArray]
      tempArr[index].name = e.target.value
      setModalArray(tempArr)
    }
  }
  const handleChangeBillingPeriod = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>, index: number) => {
    if (typeof e.target.value === 'string') {
      const tempArr = [...modalArray]
      tempArr[index].billing_period = e.target.value
      setModalArray(tempArr)
    }
  }
  const addPricingBtn = () => {
    const newArr: any[] = [...modalArray]
    newArr.push({ amount: 0.0, billing_period: 'year', recurring: true })
    setModalArray(newArr)
  }
  const handleCheckBox = (index: number) => {
    const tempArr = [...modalArray]
    tempArr[index].recurring = !tempArr[index].recurring
    setModalArray(tempArr)
  }
  const handleDelete = (e: any, index: number) => {
    e.stopPropagation()
    const removeData = [...modalArray]
    removeData.splice(index, 1)
    setModalArray(removeData)
  }
  console.log('modalArray', modalArray)
  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(subscription.id)
    const subscriptionCreated: ICreateProductReq = { ...subscription, id: subscription.id }
    onRequestSuccessful(subscriptionCreated)
  }, [onRequestSuccessful, subscription])

  return (
    <>
      <Container maxWidth="sm">
        <div className={classes.productContainerRoot}>
          <h4>Subscription details</h4>
          <TextField
            variant="outlined"
            size="small"
            value={name}
            className="name_field"
            onChange={handleChangeName}
            placeholder="Name"
          />

          <TextField
            variant="outlined"
            size="small"
            value={email}
            className="name_field"
            onChange={handleChangeEmail}
            placeholder="Email"
            type="email"
          />

          <Editor
            apiKey={EditorApiKey}
            init={{
              plugins: [
                'advlist autolink lists link charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              width: '100%',
              height: '20rem',
              valid_children: '+body[style]',
              valid_styles: { '*': '*' },
              init_instance_callback: function(editor) {
                editor.on('click', function(e) {
                  if (e.target.nodeName === 'INPUT') {
                    if (e.target.getAttribute('checked')) {
                      e.target.removeAttribute('checked')
                    } else {
                      e.target.setAttribute('checked', 'true')
                    }
                  }
                })
              },
            }}
            onEditorChange={e => setContent(e)}
            initialValue={'Content'}
            value={content}
          />
          <br />
          <TextField
            variant="outlined"
            size="small"
            value={link}
            className="name_field"
            onChange={handleChangeLink}
            placeholder="Meeting Link"
          />
          <h3>Tiers</h3>
          {modalArray.map((item: IPrice, i: number) => (
            <Accordion key={i} expanded={modalArray.length > 0 ? true : false}>
              <AccordionSummary
                //expandIcon={<ExpandMoreIcon />}
                className="summary_content"
              >
                {/* <p>Tier {i + 1}</p>
                 */}
                <TextField
                  variant="outlined"
                  size="small"
                  value={item.name}
                  className="name_field"
                  onChange={e => handleChangePriceName(e, i)}
                  placeholder="Tier Name"
                />
                {!item.id && (
                  <DeleteIcon
                    className="delete_btn"
                    onClick={event => handleDelete(event, i)}
                    onFocus={event => event.stopPropagation()}
                  />
                )}
              </AccordionSummary>

              <AccordionDetails className="accordian_main_container">
                <div className="acoordion_details_container">
                  <p>amount</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.amount}
                    placeholder="0.00"
                    type={'number'}
                    onChange={e => handleChangePrice(e, i)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={item.id ? true : false}
                  />
                  {/* <div className="radio_btns_container">
                    <button className={'recurring_btn'}>Recurring</button>
                    <Switch
                      checked={item.recurring}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color="secondary"
                      onChange={() => (!item.id ? handleCheckBox(i) : null)}
                      //   disabled={item.id ? true : false}
                    />
                  </div> */}
                  {item.recurring && (
                    <>
                      <p>Billing period</p>
                      <div className="drop_down_container">
                        <Select
                          value={item.billing_period}
                          onChange={e => handleChangeBillingPeriod(e, i)}
                          variant="outlined"
                          className="select_down"
                          fullWidth
                          placeholder="day"
                          disabled={item.id ? true : false}
                        >
                          <MenuItem value={'day'}>day</MenuItem>
                          <MenuItem value={'week'}>week</MenuItem>
                          <MenuItem value={'month'}>month</MenuItem>
                          <MenuItem value={'year'}>year</MenuItem>
                        </Select>
                      </div>
                    </>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}

          <div className="add_price_btn_container">
            <Button startIcon={<AddIcon />} className="add_price_btn" variant="outlined" onClick={addPricingBtn}>
              {!modalArray.length ? 'Add price' : 'Add another price'}
            </Button>
            {/* {modalArray.length ? (
              <Button className="save_btn" variant="outlined">
                Save
              </Button>
            ) : (
              ''
            )} */}
          </div>
          <div style={{ maxWidth: '9rem', float: 'right' }}>
            <SubmitFormButton
              onFormSubmitted={onFormSubmitted}
              title={editedProduct ? 'Save' : 'Save'}
              onClick={onSubmitClick}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default ProductInfo
