import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import React, { useState } from 'react'
import { IEditableNotificationDate } from '.'
import CustomizableDateRow from './customizableDateRow'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import { daySubstring, monthSubstring, yearSubstring } from 'utils/formatDate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.customPalette.superLightGray,
      width: '28rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    closeButton: {
      alignSelf: 'flex-end',
      padding: 0,
      paddingTop: '0.5rem',
      borderRadius: '0',
    },
    headerSec: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: '1rem',
      letterSpacing: '0.09px',
      color: theme.customPalette.lightGray,
      marginBottom: '0.7rem',
      textTransform: 'uppercase',
    },
    cell: {
      fontSize: '1.125rem',
      letterSpacing: '0.09px',
      paddingBottom: 0,
      paddingTop: 0,
      justifyContent: 'center',
      textAlign: 'center',
    },
    button: {
      width: '13.75rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      alignSelf: 'center',

      '& .MuiButton-contained': {
        background: '#ececee',
        color: '#3c9fdf',
        fontWeight: 500,
        fontSize: '1.125rem',
        boxShadow: 'none',
      },
    },
    customizeButton: {
      color: '#3c9fdf',
      textDecoration: 'underline',
      fontSize: '1rem',
      fontWeight: 600,
      alignSelf: 'flex-end',
      cursor: 'pointer',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      '&:disabled': {
        opacity: 0.5,
      },
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 150,
    },
  })
)

interface ICustomizeDatesDialogProps {
  open: boolean
  close: () => void
  dates?: IEditableNotificationDate[]
  onSaveDates: (dates: IEditableNotificationDate[]) => void
}

const CustomizeDatesDialog: React.FC<ICustomizeDatesDialogProps> = ({ open, close, dates, onSaveDates }) => {
  const [customizedDates, setCustomizedDates] = React.useState<IEditableNotificationDate[]>()
  const classes = useStyles()

  const [newNextDates, setNewNextDates] = React.useState<any>()

  React.useEffect(() => {
    if (open) setCustomizedDates(dates)
  }, [open, dates])

  const handleDateChange = React.useCallback(
    (notificationDate: IEditableNotificationDate) => {
      if (!customizedDates) return
      if (notificationDate?.id) {
        const id = notificationDate?.id
        const index = customizedDates?.findIndex((item: IEditableNotificationDate) => item?.id === id)
        const dates = [...customizedDates]
        dates[index] = notificationDate
        setCustomizedDates(dates)
      }
    },
    [customizedDates]
  )

  const closeSelf = React.useCallback(() => {
    // Timeout is here so when we clear dialog,
    // layout does not jump and fadeout animation is smooth.
    setTimeout(() => setCustomizedDates(undefined), 1000)
    close()
  }, [close, setCustomizedDates])

  const onSaveDatesClick = React.useCallback(() => {
    if (!customizedDates) return
    onSaveDates(customizedDates)
    // closeSelf()
    close()
  }, [customizedDates, onSaveDates, close])

  if (!customizedDates) return null

  const newArray = (newYear: any) => {
    if (customizedDates) {
      const yearIndex = customizedDates.findIndex((item: any) => item.id === newYear.id)
      if (yearIndex >= 0) {
        customizedDates[yearIndex] = newYear
        setNewNextDates(customizedDates)
      } else {
        newYear.id = customizedDates.length + 1
        setCustomizedDates([...customizedDates, newYear])
      }
      setNewNextDates(null)
    }
  }

  const addDate = () => {
    const firstYear = parseInt(yearSubstring(new Date()))
    const monthString = monthSubstring(new Date())
    const dayString = daySubstring(new Date())

    const newDate = {
      id: newNextDates?.id || 0,
      // schedule_date: moment(`${monthString}/${dayString}/${firstYear + 1}`).toDate(),
      schedule_date: moment(`${monthString}/${dayString}/${firstYear}`).toDate(),
      file_date: false,
      skip: false,
    }

    newArray(newDate)
  }

  return (
    <Dialog open={open} onClose={close} PaperProps={{ className: classes.paper }} scroll="paper">
      <IconButton disableRipple onClick={close} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <div className={classes.headerSec}>
        <p className={classes.title}>Customize</p>
        <Button color="primary" onClick={addDate}>
          Add Date
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>YEAR</TableCell>
            <TableCell />
            <TableCell style={{ paddingRight: 0 }}>USE FILE DATE</TableCell>
            <TableCell style={{ textAlign: 'center' }}>SKIP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customizedDates?.map((notificationDate, index) => (
            <CustomizableDateRow
              key={index}
              date={notificationDate}
              handleDateChange={handleDateChange}
              id={notificationDate?.id || index + 1}
            />
          ))}
        </TableBody>
      </Table>
      <ButtonWithLoading kind="BRPrimary" wrapperClassName={classes.button} onClick={onSaveDatesClick}>
        Save Dates
      </ButtonWithLoading>
    </Dialog>
  )
}

export default CustomizeDatesDialog
