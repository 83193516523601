import { useTheme } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { NotificationsActiveOutlined } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { CSSProperties } from '@material-ui/styles'
import ExportIcon from 'icons/export'
import SendPlaneIcon from 'icons/sendPlane'
import moment from 'moment'
import * as React from 'react'
import { useReactToPrint } from 'react-to-print'
import NotifyModal from '../notifyModal'

const useStyles = makeStyles((theme: Theme) => {
  const buttonStyle: CSSProperties = {
    fontWeight: 'bold',
    letterSpacing: '0.07px',
    textDecoration: 'underline',
  }
  return {
    container: {
      display: 'flex',
      marginTop: 20,
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    datePickerWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    questions: {
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: '0.07px',
      color: theme.palette.text.primary,
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    addQuestionButton: {
      ...buttonStyle,
      color: theme.palette.primary.main,
      marginRight: '1rem',
    },
    sendToClientButton: {
      ...buttonStyle,
      color: theme.customPalette.darkerBlue,
      marginRight: '1rem',
    },
    exportToPdfButton: {
      ...buttonStyle,
      marginLeft: '0.5rem',
      color: theme.customPalette.darkGray,
    },
    notifyButton: {
      ...buttonStyle,
      marginRight: '1rem',
      color: theme.customPalette.darkGray,
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '0.25rem',
        fontSize: '1.25rem',
      },
    },
  }
})

interface IToolbarProps {
  pickedYear: string
  onYearChange: (date: MaterialUiPickersDate) => void
  onAddQuestionClicked: () => void
  refComp?: any
}

const Toolbar: React.FC<IToolbarProps> = ({ pickedYear, onYearChange, onAddQuestionClicked, refComp }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [isOpen, setIsOpen] = React.useState(false)

  const handlePrint = useReactToPrint({
    content: () => refComp.current,
    documentTitle: 'Document',
  })

  return (
    <div className={classes.container}>
      <div className={classes.datePickerWrapper}>
        <Typography className={classes.questions}>QUESTIONS</Typography>
        <DatePicker
          views={['year']}
          label="Year"
          value={pickedYear}
          onChange={onYearChange}
          autoOk
          style={{ width: 80, marginLeft: '1.5rem' }}
          inputVariant="outlined"
          okLabel=""
          minDate={moment().add(-10, 'year')}
          maxDate={moment().add(+10, 'year')}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <Link component="button" variant="body2" className={classes.notifyButton} onClick={() => setIsOpen(true)}>
          <NotificationsActiveOutlined /> Notify
        </Link>
        <Link onClick={onAddQuestionClicked} component="button" variant="body2" className={classes.addQuestionButton}>
          + Add Question
        </Link>
        <SendPlaneIcon fillColor={theme.customPalette.primaryDark} height={20} />
        <Link component="button" variant="body2" className={classes.sendToClientButton}>
          Send To Client
        </Link>
        <ExportIcon />
        <Link component="button" variant="body2" className={classes.exportToPdfButton} onClick={handlePrint}>
          Export To PDF
        </Link>
      </div>

      <NotifyModal open={isOpen} setOpen={() => setIsOpen(false)} />
    </div>
  )
}

export default Toolbar
