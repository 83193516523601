import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import { IAddress } from 'models/address'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      margin: '5px 0px',
      justifyContent: 'space-between',
      padding: '0 10px',
      minHeight: '150px',
    },
    addressWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 10px',
      padding: '10px 0',
    },
    addressLine: {
      fontSize: '15px',
    },
    primaryAddressTextWrapper: {
      color: 'white',
      display: 'flex',
      alignItems: 'center',
    },
    actionButtonsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

interface IAddressProps {
  address: IAddress
  onPrimaryAddressChange?: (address: IAddress) => (checked: boolean) => void
  onEditClick(id?: number): void
  onDeleteClick?(id?: number): void
  noPrimaryAddressError?: boolean

  /**
   * if true, shows radio button which is used when selecting address from
   * multiple addresses
   */
  onlyOneAddress?: boolean

  isDeletable?: boolean
  isEditable?: boolean
}

export const Address: React.FC<IAddressProps> = ({
  address,
  onPrimaryAddressChange,
  onEditClick,
  onDeleteClick,
  noPrimaryAddressError,
  onlyOneAddress,
  isDeletable = true,
  isEditable = true,
}) => {
  const classes = useStyles()

  const handleDeleteClick = React.useCallback(() => onDeleteClick?.(address.id), [address.id, onDeleteClick])

  const handleEditClick = React.useCallback(() => onEditClick(address.id), [address.id, onEditClick])

  if (!address) return null

  const primaryColor = address.is_primary && !onlyOneAddress ? 'white' : undefined

  return (
    <Paper
      style={{
        background: address?.is_foreign ? '#92b2ff5e' : 'inherit',
        backgroundImage:
          address.is_primary && !onlyOneAddress ? 'linear-gradient(to left, #213161, #24489e)' : undefined,
        border: noPrimaryAddressError ? '1px solid red' : undefined,
      }}
      className={classes.container}
    >
      <div style={{ display: 'flex' }}>
        {!onlyOneAddress && (
          <Tooltip placement="top" title={address.is_primary ? 'Primary Address' : 'Make Address Primary'}>
            <Radio
              style={{ marginLeft: 5, height: 'min-content', alignSelf: 'center' }}
              disableRipple
              disabled={!onPrimaryAddressChange}
              color="secondary"
              onClick={event => onPrimaryAddressChange && onPrimaryAddressChange(address)(!address.is_primary)}
              checked={address.is_primary}
              data-testid={TestID.ADDRESS_RADIO}
            />
          </Tooltip>
        )}
        <div style={{ color: primaryColor }} className={classes.addressWrapper} data-testid={TestID.ADDRESS_INFO}>
          <Typography className={classes.addressLine}>{address.street}</Typography>
          <Typography className={classes.addressLine}>{address.cityObj?.label || address.city}</Typography>
          <Typography className={classes.addressLine}>
            {address.locality} {address.postal_code}
          </Typography>
          <Typography className={classes.addressLine}>{address.country}</Typography>
        </div>
      </div>
      <div className={classes.primaryAddressTextWrapper}>
        {address.is_primary && !onlyOneAddress && <Typography variant="caption">Primary Address</Typography>}
      </div>

      <div className={classes.actionButtonsWrapper}>
        {isEditable && (
          <div>
            <IconButton onClick={handleEditClick} data-testid={TestID.EDIT_ADDRESS_BUTTON}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        {isDeletable && (
          <div>
            <IconButton onClick={handleDeleteClick} data-testid={TestID.DELETE_ADDRESS_BUTTON}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default Address
