import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { IOrderDetailsResponse } from 'api/orders'
import { IOrderTask } from 'models/task'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'

const useStyles = makeStyles({
  statusCheckbox: {
    '&.Mui-checked': {
      color: '#91de91',
    },
    '& > span': {
      '& > svg': {
        width: 32,
        height: 32,
      },
    },
  },
})

interface ITaskActivationCheckboxProps {
  tasks: IOrderTask[]
  task: IOrderTask
  toggleTaskActivation?: (companyOrderTaskId: number) => Promise<void>
  onCompanyOrderFieldChange?: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => void
  setTasks: any
  taskIDs: any
}

/**
 * We want to be able to show company order tasks based on their activation status.
 * This checkbox component enables toggling that.
 *
 * @param task: Specify task that the checkbox activates/deactivates.
 * @param tasks: Array of tasks from which "task" param originates.
 * @param toggleTaskActivation: Change task's activation state to the opposite.
 * @param onCompanyOrderFieldChange: Update a company order.
 */
const TaskActivationCheckbox = ({
  task,
  tasks,
  toggleTaskActivation,
  onCompanyOrderFieldChange,
  setTasks,
  taskIDs,
}: ITaskActivationCheckboxProps) => {
  const classes = useStyles()
  const [taskids, settaskids] = React.useState<any>([])

  const arr: any = []
  const handleCheckboxChange = React.useCallback(
    async (checked: any) => {
      if (task.id && checked) {
        arr.push(task.id)
        console.log('arr', arr)

        //await toggleTaskActivation(task.id)
        settaskids([...taskids, task.id])

        //setTasks(taskids)
        const updatedTasks = tasks.filter(t => {
          if (t.id === task.id) {
            t.is_activated = !t.is_activated
          }
          return t
        })
        setTasks(updatedTasks)
        console.log('updatedTasks', updatedTasks)

        if (onCompanyOrderFieldChange) onCompanyOrderFieldChange('tasks', updatedTasks)
      } else {
        const updatedTasks = tasks.filter(t => {
          if (t.title === task.title) {
            t.is_activated = !t.is_activated
          }
          return t
        })
        setTasks(updatedTasks)

        if (onCompanyOrderFieldChange) onCompanyOrderFieldChange('tasks', updatedTasks)
      }
    },
    [
      /*onCompanyOrderFieldChange, task.id, task.title, tasks, toggleTaskActivation*/
    ]
  )
  // const handleCheckbox=(checked:any, id : any)=>{
  //   if (checked===true) {
  //     let temp:any=[]
  //     tasks.map((item,index)=>{
  //       console.log('temp', item);
  //     })

  //     //setTasks([...taskIDs, parseInt(id)])
  //     //setTasks([...taskIDs, temp])
  //   }
  // }
  return (
    <Checkbox
      disableRipple={true}
      checked={task.is_activated}
      classes={{ root: classes.statusCheckbox }}
      onChange={handleCheckboxChange /*e => handleCheckbox(e.target.checked, e.target.value)*/}
      value={task.id}
      name="checked"
    />
  )
}

export default TaskActivationCheckbox
