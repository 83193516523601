import {
  createStyles,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  OutlinedInput,
  Theme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import React, { useState, useEffect } from 'react'
// import { Editor } from 'react-draft-wysiwyg'
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from '@tinymce/tinymce-react'
import { TempNotification } from 'components/notifications/notificationsDialog'
import { checkEmailTemplate, createNotificationTemplates, editNotificationTemplates } from 'api/notifications'
import { notify } from '@jetkit/react'
import showApiResponseError from 'utils/showApiResponseError'
import { EditorApiKey } from 'utils/constant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '45rem',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1.5rem',
      marginRight: '1.5rem',

      '& .select-notification-template': {
        '& .MuiFormControl-root ': {
          margin: '0 !important',
          width: '100%',
        },
      },
      '& .text-label': {
        padding: '1rem 0 0.5rem 0',
      },
    },
    closeButton: {
      width: 'min-content',
      alignSelf: 'flex-end',
      marginRight: '1rem',
    },
    title: {
      fontSize: '1.75rem',
      fontWeight: 600,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary,
      marginBottom: '0.4rem',
    },

    textField: {
      margin: '0.5rem 0',
    },

    scheduleButton: {
      width: '13.75rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      alignSelf: 'center',
      background: '#ececee',
      color: '#3c9fdf',

      '& .MuiButton-contained': {
        background: '#ececee',
        color: '#3c9fdf',
        fontWeight: 500,
        fontSize: '1.125rem',
        boxShadow: 'none',
      },
    },
    editor: {
      marginTop: '0.5rem',
    },
    testEmail: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',

      '& .text-template-btn': {
        display: 'flex',
        alignSelf: 'flex-end',
        marginBottom: '0',
        minHeight: '56px',
        alignItems: 'center',
        width: '13.75rem',

        '& .MuiButton-root': {
          background: '#dadaf8',
          color: '#3c9fdf',
          minHeight: '56px',
        },
      },
    },
  })
)

interface AddTempleteDialoggProps {
  isOpen: boolean
  close: () => void
  onRequestSuccessful?: () => void
  editedNotification?: TempNotification
}

const AddTempleteDialog = ({ isOpen, close, onRequestSuccessful, editedNotification }: AddTempleteDialoggProps) => {
  const classes = useStyles()

  const [subjectText, setSubjectText] = useState('')
  const [heading, setHeading] = useState('')
  const [subHeading, setSubHeading] = useState('')
  const [testEmail, setTestEmail] = useState('')

  const [buttonText1, setButtonText1] = useState('')
  const [buttonText2, setButtonText2] = useState('')
  const [buttonText3, setButtonText3] = useState('')
  const [buttonLink1, setButtonLink1] = useState('')
  const [buttonLink2, setButtonLink2] = useState('')
  const [buttonLink3, setButtonLink3] = useState('')
  const [protipHeading, setProtipHeading] = useState('')
  const [protipContent, setProtipContent] = useState('')

  const [contentText, setContentText] = useState('')
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const [waitingForEmailCheckResponse, setWaitingForEmailCheckResponse] = useState(false)

  const buttonTitle = React.useMemo(() => (editedNotification?.id ? 'Update Template' : 'Add Template'), [
    editedNotification,
  ])

  useEffect(() => {
    if (isOpen && editedNotification) {
      setSubjectText(editedNotification?.subject || '')
      setContentText(editedNotification?.content || '')
      setHeading(editedNotification?.heading || '')
      setSubHeading(editedNotification?.sub_heading || '')
      setButtonText1(editedNotification?.button1_text || '')
      setButtonText2(editedNotification?.button2_text || '')
      setButtonText3(editedNotification?.button3_text || '')
      setButtonLink1(editedNotification?.button1_link || '')
      setButtonLink2(editedNotification?.button2_link || '')
      setButtonLink3(editedNotification?.button3_link || '')
      setProtipHeading(editedNotification?.protip_heading || '')
      setProtipContent(editedNotification?.protip_content || '')
    } else return
  }, [editedNotification, isOpen])

  const tryToSendRequest = React.useCallback(async () => {
    if (!subjectText || !contentText) return
    const tempNotificationData: TempNotification = {
      subject: subjectText,
      content: contentText,
      heading: heading,
      sub_heading: subHeading,
      button1_text: buttonText1,
      button2_text: buttonText2,
      button3_text: buttonText3,
      button1_link: buttonLink1,
      button2_link: buttonLink2,
      button3_link: buttonLink3,
      protip_heading: protipHeading,
      protip_content: protipContent,
    }
    setWaitingForResponse(true)
    try {
      if (editedNotification) {
        if (editedNotification?.id) {
          await editNotificationTemplates(editedNotification?.id, tempNotificationData)
        } else return
      } else {
        await createNotificationTemplates(tempNotificationData)
      }
      setWaitingForResponse(false)
      close()
      notify.success('Templete Created!')
      onRequestSuccessful && onRequestSuccessful()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, `Sorry, could not ${error ? 'edit' : 'create'} notification`)
    }
  }, [
    buttonLink1,
    buttonLink2,
    buttonLink3,
    buttonText1,
    buttonText2,
    buttonText3,
    close,
    contentText,
    editedNotification,
    heading,
    onRequestSuccessful,
    subHeading,
    subjectText,
    protipHeading,
    protipContent,
  ])

  const handleScheduleClick = React.useCallback(() => {
    tryToSendRequest()
  }, [tryToSendRequest])

  const tryToCheckEmailTemplate = React.useCallback(async () => {
    if (!subjectText || !contentText || !testEmail) return
    const checkEmailData: TempNotification = {
      subject: subjectText,
      content: contentText,
      email: testEmail,
      heading: heading,
      sub_heading: subHeading,
      button1_text: buttonText1,
      button2_text: buttonText2,
      button3_text: buttonText3,
      button1_link: buttonLink1,
      button2_link: buttonLink2,
      button3_link: buttonLink3,
      protip_heading: protipHeading,
      protip_content: protipContent,
    }
    setWaitingForEmailCheckResponse(true)
    try {
      const res: any = await checkEmailTemplate(checkEmailData)
      setWaitingForEmailCheckResponse(false)
      // close()
      if (res.succuss) notify.success('Check your email for test template.!')
      else if (res.error) notify.error('Error Sending Email')

      onRequestSuccessful && onRequestSuccessful()
    } catch (error) {
      setWaitingForEmailCheckResponse(false)
      showApiResponseError(error, `Sorry, could not send email`)
    }
  }, [
    contentText,
    onRequestSuccessful,
    subjectText,
    testEmail,
    heading,
    subHeading,
    buttonLink1,
    buttonLink2,
    buttonLink3,
    buttonText1,
    buttonText2,
    buttonText3,
    protipHeading,
    protipContent,
  ])

  const handleCheckTemplateClick = React.useCallback(() => {
    tryToCheckEmailTemplate()
  }, [tryToCheckEmailTemplate])

  return (
    <Dialog open={isOpen} fullScreen={false} maxWidth="md">
      <IconButton onClick={close} className={classes.closeButton}>
        <Close fontSize="default" />
      </IconButton>
      <div className={classes.innerContainer} data-testid="notification-dialog-inner-container">
        <div className={classes.title}>{buttonTitle}</div>
        <div data-testid="company-type" className="select-notification-template">
          <Alert severity="info">
            <AlertTitle>Placeholders</AlertTitle>
            $FIRST_NAME$, $LAST_NAME$, $COMPANY_NAME$, $STATE_OF_FORMATION$, $ORDER_TYPE$
          </Alert>
          <FormControl variant="outlined">
            <label className="text-label">Subject</label>
            <OutlinedInput
              id="outlined-adornment-subject"
              aria-describedby="outlined-subject-helper-text"
              inputProps={{
                'aria-label': 'subject',
              }}
              labelWidth={0}
              name="subjectText"
              value={subjectText}
              onChange={e => setSubjectText(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <label className="text-label">Heading</label>
            <OutlinedInput
              id="outlined-adornment-heading"
              aria-describedby="outlined-heading-helper-text"
              inputProps={{
                'aria-label': 'heading',
              }}
              labelWidth={0}
              name="heading"
              value={heading}
              onChange={e => setHeading(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <label className="text-label">Sub Heading</label>
            <OutlinedInput
              id="outlined-adornment-sub-heading"
              aria-describedby="outlined-sub-heading-helper-text"
              inputProps={{
                'aria-label': 'sub-heading',
              }}
              labelWidth={0}
              name="subHeading"
              value={subHeading}
              onChange={e => setSubHeading(e.target.value)}
            />
          </FormControl>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl variant="outlined">
                <label className="text-label">Button 1 Text</label>
                <OutlinedInput
                  id="outlined-adornment-sub-heading"
                  aria-describedby="outlined-sub-heading-helper-text"
                  inputProps={{
                    'aria-label': 'sub-heading',
                  }}
                  labelWidth={0}
                  name="buttonText1"
                  value={buttonText1}
                  onChange={e => setButtonText1(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined">
                <label className="text-label">Button 1 Link</label>
                <OutlinedInput
                  id="outlined-adornment-sub-heading"
                  aria-describedby="outlined-sub-heading-helper-text"
                  inputProps={{
                    'aria-label': 'sub-heading',
                  }}
                  labelWidth={0}
                  name="buttonLink1"
                  value={buttonLink1}
                  onChange={e => setButtonLink1(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined">
                <label className="text-label">Button 2 Text</label>
                <OutlinedInput
                  id="outlined-adornment-sub-heading"
                  aria-describedby="outlined-sub-heading-helper-text"
                  inputProps={{
                    'aria-label': 'sub-heading',
                  }}
                  labelWidth={0}
                  name="buttonText2"
                  value={buttonText2}
                  onChange={e => setButtonText2(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined">
                <label className="text-label">Button 2 Link</label>
                <OutlinedInput
                  id="outlined-adornment-sub-heading"
                  aria-describedby="outlined-sub-heading-helper-text"
                  inputProps={{
                    'aria-label': 'sub-heading',
                  }}
                  labelWidth={0}
                  name="buttonLink2"
                  value={buttonLink2}
                  onChange={e => setButtonLink2(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined">
                <label className="text-label">Button 3 Text</label>
                <OutlinedInput
                  id="outlined-adornment-sub-heading"
                  aria-describedby="outlined-sub-heading-helper-text"
                  inputProps={{
                    'aria-label': 'sub-heading',
                  }}
                  labelWidth={0}
                  name="buttonText3"
                  value={buttonText3}
                  onChange={e => setButtonText3(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined">
                <label className="text-label">Button 3 Link</label>
                <OutlinedInput
                  id="outlined-adornment-sub-heading"
                  aria-describedby="outlined-sub-heading-helper-text"
                  inputProps={{
                    'aria-label': 'sub-heading',
                  }}
                  labelWidth={0}
                  name="buttonLink3"
                  value={buttonLink3}
                  onChange={e => setButtonLink3(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <br />
          </div>
          <label className="text-label">Content</label>
          <div className={classes.editor}>
            <Editor
              apiKey={EditorApiKey}
              init={{
                plugins: [
                  'advlist autolink lists link charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                width: '100%',
                height: '20rem',
                valid_children: '+body[style]',
                valid_styles: { '*': '*' },
                init_instance_callback: function(editor) {
                  editor.on('click', function(e) {
                    if (e.target.nodeName === 'INPUT') {
                      if (e.target.getAttribute('checked')) {
                        e.target.removeAttribute('checked')
                      } else {
                        e.target.setAttribute('checked', 'true')
                      }
                    }
                  })
                },
              }}
              onEditorChange={e => setContentText(e)}
              initialValue={''}
              value={contentText}
            />
          </div>
          <FormControl variant="outlined">
            <label className="text-label">Protip Heading</label>
            <OutlinedInput
              id="outlined-adornment-sub-heading"
              aria-describedby="outlined-sub-heading-helper-text"
              inputProps={{
                'aria-label': 'sub-heading',
              }}
              labelWidth={0}
              name="protip_heading"
              value={protipHeading}
              onChange={e => setProtipHeading(e.target.value)}
            />
          </FormControl>
          <div>
            <br />
          </div>
          <label className="text-label">Protip Content</label>
          <div className={classes.editor}>
            <Editor
              apiKey={EditorApiKey}
              init={{
                plugins: [
                  'advlist autolink lists link charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                width: '100%',
                height: '20rem',
                valid_children: '+body[style]',
                valid_styles: { '*': '*' },
                init_instance_callback: function(editor) {
                  editor.on('click', function(e) {
                    if (e.target.nodeName === 'INPUT') {
                      if (e.target.getAttribute('checked')) {
                        e.target.removeAttribute('checked')
                      } else {
                        e.target.setAttribute('checked', 'true')
                      }
                    }
                  })
                },
              }}
              onEditorChange={e => setProtipContent(e)}
              initialValue={''}
              value={protipContent}
            />
          </div>
          <div className={classes.testEmail}>
            <FormControl variant="outlined">
              <label className="text-label">Test Email</label>
              <OutlinedInput
                id="outlined-adornment-Email"
                aria-describedby="outlined-Email-helper-text"
                inputProps={{
                  'aria-label': 'Email',
                }}
                labelWidth={0}
                type="email"
                name="testEmail"
                value={testEmail}
                onChange={e => setTestEmail(e.target.value)}
              />
            </FormControl>
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="text-template-btn"
              onClick={handleCheckTemplateClick}
              isLoading={waitingForEmailCheckResponse}
              disabled={!subjectText || !contentText || !testEmail}
            >
              Test Template
            </ButtonWithLoading>
          </div>
        </div>

        <ButtonWithLoading
          kind="BRPrimary"
          wrapperClassName={classes.scheduleButton}
          onClick={handleScheduleClick}
          isLoading={waitingForResponse}
        >
          {buttonTitle}
          {/* Add Template */}
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default AddTempleteDialog
