import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core'
import {
  getAllMissingDocumnets,
  getAllMissingUploads,
  IFileDetails,
  OrderItemProps,
  orderItemReject,
} from 'api/orderItem'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { MISSING_ITEM_ALL_STATUS, MISSING_ITEM_STATUS } from 'utils/constant'
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined'
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import { htmlRegexG } from 'utils/functions'
import { Check, Close, FileCopy, GetApp, Info } from '@material-ui/icons'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import DeleteIcon from 'components/common/icons/delete'
import { Skeleton } from '@material-ui/lab'

interface OrderItemRowProps {
  orderItem: OrderItemProps
  handleOnAlert: (notifyVal: boolean, reason: string) => void
  // setIsItemModal: (item: boolean) => void
}

const OrderItemRow: React.FC<OrderItemRowProps> = ({ orderItem, handleOnAlert }) => {
  const [isItemModal, setIsItemModal] = useState(false)

  const [isRejectModal, setIsRejectModal] = useState(false)
  const [isAcceptModal, setIsAcceptModal] = useState(false)

  const [fileDetails, setFileDetails] = useState<IFileDetails[]>([])
  const [fileLink, setFileLink] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [rejectReason, setRejectReason] = useState('')
  const [acceptReason, setAcceptReason] = useState('')

  const handleAlertIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, notify) => {
      event.stopPropagation()
      handleOnAlert(notify, acceptReason)
    },
    [acceptReason, handleOnAlert]
  )

  // const statusCheck = (status: string) => {
  //   switch (status) {
  //     case '0':
  //       return 'Not Received'

  //     case '1':
  //       return 'Received'

  //     default:
  //       break
  //   }
  // }

  console.log('orderItemorderItem', orderItem)

  const getAllDownloads = async (id: number, docId: number) => {
    setIsLoading(true)
    try {
      const file = await getAllMissingUploads(id)
      if (file[0]) {
        setFileDetails(file)
        const link = await getAllMissingDocumnets(id, file[0].id)
        setFileLink(link)

        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }
  const handleReject = async () => {
    try {
      await orderItemReject(orderItem?.id || 0, { reject_reason: rejectReason })
      setIsRejectModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
        <TableCell style={{ minWidth: 120 }}>{orderItem?.id}</TableCell>
        <TableCell style={{ maxWidth: 120 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{orderItem?.item_name}</p>
            <Tooltip
              title={
                <p
                  style={{
                    fontSize: '1rem',
                    padding: '0.5rem',
                    lineHeight: '1.25rem',
                    margin: 0,
                    fontFamily: 'Lato',
                  }}
                >
                  {orderItem?.item_description?.replace(htmlRegexG, '')}
                </p>
              }
              arrow
            >
              <Info style={{ margin: '0.5rem' }} color="primary" />
            </Tooltip>
          </div>
          {/* <i style={{ color: '#0000009c' }}>{orderItem?.item_description?.replace(htmlRegexG, '')?.slice(0, 100)}...</i> */}
        </TableCell>
        <TableCell style={{ minWidth: 60 }}>{formatDateTimeToHumanFriendly(orderItem?.created_at)}</TableCell>
        <TableCell style={{ maxWidth: 120 }}>{orderItem?.created_by}</TableCell>
        <TableCell style={{ maxWidth: 120 }}>{orderItem?.completed_by}</TableCell>
        {/* <TableCell style={{ maxWidth: 120 }}>{statusCheck(orderItem?.status)}</TableCell> */}
        <TableCell style={{ maxWidth: 120 }}>{MISSING_ITEM_ALL_STATUS[orderItem?.status || '']}</TableCell>
        {/* <TableCell style={{ maxWidth: 200 }}>{orderItem.item_response}</TableCell> */}

        <TableCell style={{ minWidth: 60 }}>
          {/* {orderItem?.status !== MISSING_ITEM_STATUS?.Received ? (
            <div> */}
          {/* <ButtonWithLoading onClick={e => handleEditIconClick(e, false)}>Mark as Received</ButtonWithLoading> */}
          {/* <ButtonWithLoading kind="BRSecondary" onClick={e => handleEditIconClick(e, true)}>
              Mark as Received And Notify
            </ButtonWithLoading> */}
          {/* <Tooltip title="Mark as Received, no Notify">
                <IconButton onClick={e => handleAlertIconClick(e, false)}>
                  <DoneAllOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mark as Received and Notify">
                <IconButton onClick={e => handleAlertIconClick(e, true)}>
                  <NotificationsActiveOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            ''
          )} */}
          {orderItem?.status !== MISSING_ITEM_STATUS?.Received && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                getAllDownloads(orderItem?.id || 0, 2)
                setIsItemModal(true)
              }}
            >
              Action
            </Button>
          )}
        </TableCell>
      </TableRow>

      <ModalWrapper maxWidth={'sm'} open={isItemModal} setOpen={setIsItemModal} heading="Item details">
        <TextField label="Name" variant="outlined" fullWidth placeholder="Name" value={orderItem?.item_name} disabled />
        <TextField
          multiline
          label="Details"
          variant="outlined"
          fullWidth
          placeholder="Details"
          value={orderItem?.item_description?.replace(htmlRegexG, '')}
          style={{ margin: '1rem 0' }}
          disabled
        />
        <TextField
          label="Client Response"
          variant="outlined"
          fullWidth
          placeholder="Client Response"
          value={orderItem?.item_response}
          disabled
        />

        <List dense>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FileCopy />
              </Avatar>
            </ListItemAvatar>
            {isLoading ? (
              <Skeleton variant="rect" width={'60%'} />
            ) : (
              <ListItemText primary={fileDetails[0]?.file_name} secondary={fileDetails[0]?.uploaded_by} />
            )}

            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                disabled={fileLink ? false : true}
                onClick={() => window.open(fileLink)}
                aria-label="download"
              >
                <GetApp color="secondary" />
              </IconButton>
              <IconButton
                style={{ margin: '0 0.5rem' }}
                edge="end"
                onClick={() => setIsRejectModal(true)}
                aria-label="Reject"
                // disabled={orderItem?.status === MISSING_ITEM_STATUS?.Received ? true : false}
              >
                <Close color="error" />
              </IconButton>
              {orderItem?.status !== MISSING_ITEM_STATUS?.Received ? (
                <IconButton edge="start" onClick={() => setIsAcceptModal(true)} aria-label="Accept">
                  <Check color="primary" />
                </IconButton>
              ) : (
                ''
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {/* {isAcceptModal ? (
          <Box display={'flex'} justifyContent={'space-between'} mt={'1rem'}>
            <Button variant="outlined" color="secondary" onClick={e => handleAlertIconClick(e, false)}>
              Accept, No Notify
            </Button>
            <Button variant="outlined" color="primary" onClick={e => handleAlertIconClick(e, true)}>
              Accept, and Notify
            </Button>
          </Box>
        ) : (
          ''
        )} */}
      </ModalWrapper>

      {/* <ModalWrapper maxWidth={'sm'} open={isRejectModal} setOpen={setIsRejectModal} heading="Showing item uploads"> */}
      <ModalWrapper maxWidth={'sm'} open={isRejectModal} setOpen={setIsRejectModal} heading="Reject reason">
        <TextField
          multiline
          rows={2}
          label="Reject"
          variant="outlined"
          fullWidth
          focused
          placeholder="Explain Why are you reject."
          name="rejectReason"
          value={rejectReason}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRejectReason(event.target.value as string)}
        />
        <Box display={'flex'} justifyContent={'flex-end'} mt={'1rem'}>
          <Button variant="outlined" color="primary" onClick={() => handleReject()}>
            Save
          </Button>
        </Box>
      </ModalWrapper>
      <ModalWrapper maxWidth={'sm'} open={isAcceptModal} setOpen={setIsAcceptModal} heading="Accept details">
        <TextField
          multiline
          rows={2}
          label="Accept"
          variant="outlined"
          fullWidth
          focused
          placeholder="Explain accept reason."
          name="acceptReason"
          value={acceptReason}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAcceptReason(event.target.value as string)}
        />
        <Box display={'flex'} justifyContent={'space-between'} mt={'1rem'}>
          <Button variant="outlined" color="secondary" onClick={e => handleAlertIconClick(e, false)}>
            Accept, No Notify
          </Button>
          <Button variant="outlined" color="primary" onClick={e => handleAlertIconClick(e, true)}>
            Accept, and Notify
          </Button>
        </Box>
      </ModalWrapper>
    </>
  )
}

export default OrderItemRow
