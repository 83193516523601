import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

export default makeStyles((theme: Theme) => ({
  OrderDetailCardContainer: {
    borderRadius: '6px',
    border: '1px solid',
    padding: '1.5rem',
    backgroundColor: theme.customPalette.paleGreyColor,
    borderColor: '#3c9fdf',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '7rem',
    cursor: 'pointer',
  },
  orderInitiatedText: {
    color: theme.customPalette.primaryFontColor,
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    margin: '0',
    padding: '0',
    fontFamily: 'Helvetica',
    minHeight: '36px',
  },
  orderCount: {
    color: theme.customPalette.lightNavyColor,
    fontSize: '3.4rem',
    // fontFamily: theme.typography.body1.fontFamily,

    fontWeight: 900,
    padding: '0',
    margin: '0',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  orderOldest: {
    color: theme.customPalette.midBlueColor,
    // fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 500,
    fontSize: '0.9rem',
    padding: '0',
    margin: '0',
    fontFamily: 'Roboto',
  },
  orderDateText: {
    color: theme.customPalette.primaryFontColor,
    padding: '0',
    margin: '0',
    fontSize: '0.85rem',
    fontFamily: 'Roboto',
    minHeight: '32px',
  },
  inlineText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  TaxOrderDetailCardContainer: {
    borderRadius: '6px',
    border: '1px solid',
    padding: '1.5rem',
    backgroundColor: theme.customPalette.paleGreyColor,
    borderColor: '#3c9fdf',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '7rem',
  },
  alertOrderCount: {
    // color: theme.customPalette.errorText,
    color: '#F47950',
    fontSize: '3.4rem',
    // fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 900,
    padding: '0',
    margin: '0',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
}))
