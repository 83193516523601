import { notify } from '@jetkit/react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { deleteOrder, getOrderDetails, IOrderDetailsResponse, updateOrder } from 'api/orders'
import { default as classnames, default as classNames } from 'classnames'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import CircleCheckbox from 'components/common/elements/CircleCheckbox'
import CollapseIcon from 'components/common/icons/collapse'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import ExpandIcon from 'components/common/icons/expand'
import { ChangeOrderStatusDialog } from 'components/orders/orderDetails/ChangeOrderStatusDialog'
import TaskRow from 'components/tasks/taskRow'
import { IOrder, IOrderStage } from 'models/order'
import moment from 'moment'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import useRouter from 'use-react-router'
import { getDueDate } from 'utils/formatDate'
import { ValueOf } from 'utils/objectUtils'
import { TasksContext } from 'contexts/tasks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleCell: { padding: 0, borderBottom: 'none' },
    orderTitleCell: {
      fontWeight: 'bold',
      paddingLeft: 12,
    },
    overdueRow: {
      backgroundColor: '#ff000047',
    },
    orderTypeCell: { textTransform: 'capitalize' },
    overdueDueDateCell: { color: 'red' },
    actionButton: { width: 43, height: 43 },
    secondActionButton: { marginLeft: 10, marginRight: 10 },
    orderPriorityCell: {
      textTransform: 'capitalize',
      color: '#020202',
      fontWeight: 'bold',
    },
    lowPriority: {
      color: theme.lowPriority.color,
    },
    mediumPriority: {
      color: theme.mediumPriority.color,
    },
    highPriority: {
      color: theme.highPriority.color,
    },
    iconsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    orderTitleCheckBoxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    orderTitle: {
      display: 'inline-block',
      maxWidth: 170,
      color: '#ff0000',
    },
    hoverBackground: {
      '&:hover': {
        backgroundColor: '#fbffb3',
      },
    },
  })
)

interface IOrderRowProps {
  order: IOrder
}

const OrderRow = ({ order }: IOrderRowProps) => {
  const classes = useStyles()
  const overdue = order.due_date ? moment(order.due_date).isBefore() : false

  const [expanded, setExpanded] = React.useState<boolean>(false)

  const router = useRouter()

  const goToOrderDetailsPage = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      router.history.push(`/company-order/${order.id}`)
    },
    [order.id, router.history]
  )

  const [orderDetails, setOrderDetails] = React.useState<IOrderDetailsResponse>()

  React.useEffect(() => {
    const fetchAndSetOrderDetails = async () => {
      const orderDetails = await getOrderDetails(order.id)
      setOrderDetails(orderDetails)
    }

    fetchAndSetOrderDetails()
  }, [order.id])

  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)

  const handleDeleteClick = React.useCallback(event => {
    event.stopPropagation()
    setShowDeleteDialog(true)
  }, [])

  const { reloadTasks } = React.useContext(TasksContext)

  const handleOrderDelete = React.useCallback(
    async (orderId: number) => {
      try {
        await deleteOrder(orderId)
        setShowDeleteDialog(false)
        reloadTasks()
      } catch (error) {
        notify.error(error)
        console.error(error)
      }
    },
    [reloadTasks]
  )

  const isOrderCompleted = order.status === 'completed'

  const toggleExpansion = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setExpanded(prev => !prev)
  }, [])

  const [showChangeOrderStatusDialog, setShowChangeOrderStatusDialog] = useState(false)

  const toggleShowChangeOrderStatusDialog = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    setShowChangeOrderStatusDialog(prev => !prev)
  }, [])

  const onOrderDetailsFieldsChange = useCallback(
    async (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => {
      await updateOrder({
        id: order.id,
        [key]: value,
      })

      router.history.go(0)
    },
    [order.id, router.history]
  )

  const nextOrderStatus = useMemo(() => {
    const oldId: number | undefined = orderDetails?.order_stage?.id
    const nextOrderStage: IOrderStage | undefined = oldId
      ? orderDetails?.order_stages?.find(x => x.id === oldId + 1)
      : undefined
    const newOrderStatus: string | undefined = nextOrderStage?.title
    return newOrderStatus
  }, [orderDetails])

  return (
    <React.Fragment>
      <DeleteConfirmationDialog
        itemName={
          <React.Fragment>
            {order.order_type.name} <br /> For <br /> {order.company.name}
          </React.Fragment>
        }
        onClose={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        onDelete={() => handleOrderDelete(order.id)}
      />

      {orderDetails?.id && nextOrderStatus && (
        <ChangeOrderStatusDialog
          open={showChangeOrderStatusDialog}
          handleClose={() => setShowChangeOrderStatusDialog(false)}
          defaultAssignee={orderDetails?.assignee || null}
          orderId={orderDetails.id}
          onOrderDetailsFieldsChange={onOrderDetailsFieldsChange}
          newOrderStatus={nextOrderStatus}
        />
      )}

      <TableRow
        className={classNames(overdue ? classes.overdueRow : undefined, classes.hoverBackground)}
        data-testid={`order-${order.id}-row`}
        key={order.id}
        onClick={goToOrderDetailsPage}
      >
        <TableCell className={classes.orderTitleCell}>
          <div className={classes.orderTitleCheckBoxContainer}>
            <CircleCheckbox
              dataTestId={`order-${order.id}-checkbox`}
              onChange={toggleShowChangeOrderStatusDialog}
              checked={isOrderCompleted}
            />
            <span className={classes.orderTitle}>
              {order.order_type.name}
              <br />({order.order_stage.title})
            </span>
          </div>
        </TableCell>

        {/* <TableCell /> */}

        <TableCell>Company Order</TableCell>

        <TableCell>{order.company?.name}</TableCell>

        <TableCell>{orderDetails?.company_details?.client.name}</TableCell>

        <TableCell>{order.due_date ? getDueDate(order.due_date) : ''}</TableCell>

        <TableCell align="right">
          <div className={classes.iconsContainer}>
            <TableActionButton
              onClick={goToOrderDetailsPage}
              className={classes.actionButton}
              data-testid={`edit-order-${order.id}`}
            >
              <EditIcon />
            </TableActionButton>

            <TableActionButton
              onClick={handleDeleteClick}
              className={classnames(classes.actionButton, classes.secondActionButton)}
              data-testid={`remove-order-${order.id}`}
            >
              <DeleteIcon />
            </TableActionButton>

            <TableActionButton
              onClick={toggleExpansion}
              className={classes.actionButton}
              data-testid={`expand-order-${order.id}`}
            >
              {expanded ? <CollapseIcon /> : <ExpandIcon />}
            </TableActionButton>
          </div>
        </TableCell>
      </TableRow>

      {expanded &&
        orderDetails?.tasks.map(value => <TaskRow task={value} key={`${value.id} ${value.details}`} shiftLevel={1} />)}
    </React.Fragment>
  )
}

export default OrderRow
