import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontColors } from 'theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'fit-content',

      borderRadius: '6px',
      backgroundColor: '#ffffff',
    },
    closeIconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: 15,
      marginTop: 15,
    },
    closeIcon: {
      opacity: '0.3',
      color: '#000000',
    },
    contentContainer: {
      height: 230,

      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',

      margin: 30,
    },
    titleLabel: {
      fontFamily: 'Lato',
      fontSize: '24px',
      fontWeight: 'bold',
      letterSpacing: '0.12px',
      color: fontColors.primary,
    },
    newStatusLabel: {
      borderRadius: '4px',
      backgroundColor: '#ffe9b0',
    },
    saveButton: {
      width: 220,
      height: 45,
      margin: '5 auto 0',

      border: 'none',
      borderRadius: 4.3,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
    saveButtonLabel: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.07px',
      color: 'white',
    },
    saveButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    dialogContainer: {
      maxWidth: 'unset',
    },
  })
)
