import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField'
import classNames from 'classnames'
import { ISuggestionAttribute } from 'components/interfaces'
import * as React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { ControlProps } from 'react-select/src/components/Control'
import { MenuProps } from 'react-select/src/components/Menu'
import { ValueType } from 'react-select/src/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.body1.fontFamily,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '0.125rem 0 0.5rem 0',
      flexGrow: 1,
    },
    paper: {
      position: 'absolute',
      zIndex: 2,
      marginTop: '0.25rem',
      borderRadius: '0.25rem',
      left: 0,
      right: 0,
    },
    input: {
      display: 'flex',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    inputRoot: {
      padding: '10px 14px',
      display: 'table',
    },
  })
)

interface IMultiSelectProps {
  addAttribute: (field: string, select: ValueType<ISuggestionAttribute>) => void
  title: string
  fetchSuggestions?: (query: string, field: string) => Promise<ISuggestionAttribute[]> // for async
  field: string
  placeholder?: string
  width?: string
  async: boolean
  options?: ISuggestionAttribute[] // should be provided if async={false}
  defaultValue?: ISuggestionAttribute[]
  styled?: boolean // whether MUI or native 'react-select' styling should be applied
  reloadKey?: number | string // value to trigger component update
  value?: ValueType<ISuggestionAttribute>
  multiValueStyles?: React.CSSProperties
  multiValueRemoveStyles?: React.CSSProperties
  classes?: Record<string, string>
  disabled?: boolean
  rootClassName?: string
}

const MultiSelect: React.FunctionComponent<IMultiSelectProps> = props => {
  const classes = useStyles(props)
  const {
    fetchSuggestions,
    addAttribute,
    field,
    placeholder,
    disabled,
    width,
    title,
    async,
    options,
    defaultValue = [],
    styled,
    reloadKey,
    value,
    multiValueStyles,
    multiValueRemoveStyles,
    rootClassName,
  } = props
  const theme = useTheme()
  const selectStyles = {
    input: (base: React.CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    multiValue: (base: React.CSSProperties) => ({
      ...base,
      ...multiValueStyles,
    }),
    multiValueRemove: (base: React.CSSProperties) => ({
      ...base,
      ...multiValueRemoveStyles,
    }),
  }

  function Menu(props: MenuProps<ISuggestionAttribute>) {
    return (
      <Paper square className={classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    )
  }

  type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & React.HTMLAttributes<HTMLDivElement>

  function inputComponent({ inputRef, ...props }: InputComponentProps) {
    return <div className={classes.inputRoot} ref={inputRef} {...props} />
  }

  function Control(props: ControlProps<ISuggestionAttribute>) {
    return (
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        value=" "
        type="text"
        label={title}
        margin="none"
        variant="outlined"
        InputProps={{
          multiline: true,
          inputComponent,
          style: { padding: '10px 6px' },
          inputProps: {
            className: classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.TextFieldProps}
      />
    )
  }

  const components = !styled ? { Menu } : { Menu, Control } // modified components for react-select

  const promiseOptions = React.useMemo(
    () => (inputValue: string) => {
      // returns a list of suggestions from backend with debounce so user can type several characters
      // it's used only for AsyncSelect component
      // more info: https://react-select.com/async
      return new Promise(resolve => {
        resolve(fetchSuggestions ? fetchSuggestions(inputValue, field) : undefined)
      })
    },
    [fetchSuggestions, field]
  )

  const handleChangeSingle = React.useCallback(
    (select: ValueType<ISuggestionAttribute>) => {
      addAttribute(field, select ? select : [])
    },
    [addAttribute, field]
  )

  return (
    <div className={classNames(classes.root, rootClassName)} style={{ width: width, maxWidth: width }}>
      {async ? (
        <AsyncSelect
          key={reloadKey}
          isClearable
          isMulti
          styles={selectStyles}
          value={value}
          cacheOptions
          defaultOptions
          isDisabled={disabled}
          loadOptions={promiseOptions}
          onChange={handleChangeSingle}
          components={components}
          placeholder={placeholder || `Select...`}
          maxMenuHeight={160}
          defaultValue={defaultValue}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          isClearable
          isMulti
          styles={selectStyles}
          disabled={disabled}
          onChange={handleChangeSingle}
          components={components}
          options={options}
          placeholder={placeholder || `Select...`}
          maxMenuHeight={160}
        />
      )}
    </div>
  )
}

export default MultiSelect
