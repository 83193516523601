import { apiClient } from '@jetkit/react'

export interface SendMessageProps {
  id?: number
  read: boolean
}

export const getClientMessage = async (): Promise<any> => apiClient.get(`/messages`)

export const getProspectMessage = async (): Promise<any> => apiClient.get(`/prospect-messages`)

export const sendMessageStatus = async (id: number, data: SendMessageProps): Promise<any> => {
  return await apiClient.post(`/message-status/${id}`, data)
}
