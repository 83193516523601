import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { ISuggestionAttribute } from 'components/interfaces'
import {
  IEditableNotificationDate,
  INotification,
  INotificationDate,
  TempNotification,
} from 'components/notifications/notificationsDialog'

export interface INotificationTemplate {
  preview_url?: string
  id: number
  name: string
  subject?: string
}

export interface INotificationRequestData {
  company_id?: number
  state_of_formation?: number | string
  company_type?: number | string
  dates?: INotificationDate[]
  email_template_id: number
  order_type?: number | string
  first_year_schedule?: string
  following_year_schedule?: string
  title?: string
  tax_structure?: string
  notify_only: boolean
  first_year_schedule_date?: string
}

export const fetchCompanyNotifications = () => requestPaginated<INotification[]>({ url: 'notification/company_order' })

export const fetchCompanyTypeNotifications = () =>
  requestPaginated<INotification[]>({ url: 'notification/company_type' })

// export const createNotification = async (notificationData: INotificationRequestData): Promise<AxiosResponse<void>> =>
//   await apiClient.post('notification', notificationData)

export const createNotification = async (notificationData: INotificationRequestData): Promise<AxiosResponse<void>> =>
  await apiClient.post('/notification-schedules', notificationData)

export const createCustomDateNotification = async (notificationCustomDate: any): Promise<AxiosResponse<void>> =>
  await apiClient.post('/notification-schedule-years', notificationCustomDate)

export const updateCustomDateNotification = async (
  id: number,
  notificationCustomDate: any
): Promise<AxiosResponse<void>> => {
  return await apiClient.post(`/notification-schedule-years/${id}`, notificationCustomDate)
}

export const getNotificationById = async (id: number): Promise<AxiosResponse<void>> =>
  (await apiClient.get(`notification-schedules/${id}`)).data

// export const editNotification = async (
//   id: number,
//   notificationData: INotificationRequestData
// ): Promise<AxiosResponse<void>> => await apiClient.patch(`notification/${id}`, notificationData)

export const editNotification = async (
  id: number,
  notificationData: INotificationRequestData
): Promise<AxiosResponse<void>> => await apiClient.post(`notification-schedules/${id}`, notificationData)

// export const deleteNotification = async (id: number): Promise<AxiosResponse<void>> =>
//   apiClient.delete(`notification/${id}`)

export const deleteNotification = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/notification-schedules/${id}`)

// export const notificationTemplates = async (): Promise<ISuggestionAttribute[]> =>
//   ((await apiClient.get(`/email-template`)).data as INotificationTemplate[]).map(
//     (template): ISuggestionAttribute => {
//       return {
//         value: template.id,
//         label: template.name,
//       }
//     }
//   )

export const notificationTemplates = async (): Promise<ISuggestionAttribute[]> =>
  ((await apiClient.get(`/email-templates`)).data as INotificationTemplate[]).map(
    (template): ISuggestionAttribute => {
      return {
        value: template?.id,
        label: template?.subject || '',
      }
    }
  )

export const fetchNotificationTemplates = () => requestPaginated<TempNotification[]>({ url: 'email-templates' })

export const createNotificationTemplates = async (
  tempNotificationData: TempNotification
): Promise<AxiosResponse<void>> => await apiClient.post('/email-templates', tempNotificationData)

export const editNotificationTemplates = async (
  id: number,
  tempNotificationData: TempNotification
): Promise<AxiosResponse<void>> => await apiClient.post(`/email-templates/${id}`, tempNotificationData)

export const deleteNotificationTemplates = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/email-templates/${id}`)

export const checkEmailTemplate = async (emailTemplateData: TempNotification): Promise<AxiosResponse<void>> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000'
  return await apiClient.post('/check-email-template', emailTemplateData)
}

// export const getCompanyTypeNotifications = () =>
//   requestPaginated<any>({
//     url: `/notification-schedules`,
//   })

export const getCompanyTypeNotifications = () =>
  requestPaginated<any>({
    url: `/notification-schedules`,
  })

export const getstates = async (): Promise<any> => {
  return (await apiClient.get(`/states`)).data
}

// @get('/notification-schedules?page_size={number}&page={number'}&query={string})
// 192.168.18.27
