import { apiClient } from '@jetkit/react'
import { IOrderType } from 'models/order'
import { IOrderDetailsResponse } from './orders'
import { IDetailedCompanyResponse } from 'models/company'

export interface ComplianceTaskParams {
  company_id?: number | null
  client_id?: number | null
  due_date?: string
  order_type_id?: number | null
  status?: string
  page?: number
  page_size?: number
  show_history?: boolean
  query?: string
  response?: number | null
  unread?: boolean | null
  compliance_type?: string
}

export interface DeadlineListProps {
  due_date: string
  task_name: string
  total: number
  completed_count: number
}

export interface NotesData {
  note_text?: string
  company_id?: number
  compliance_task_id?: number
  created_by?: string
}

export interface ComplianceTasks {
  pagination: PaginationData
  data: ComplianceTasksData[]
}

export interface ComplianceTasksData {
  assigned_to: string
  closed: boolean
  company_id: number
  completed: boolean
  due_date: string
  id: number
  notes: NotesData[]
  details: IOrderDetailsResponse
  order_id: number
  order_type_id: number
  status: string
  title: string
  redirectUrl?: string
  business_active?: boolean
  form_link?: string
  company?: IDetailedCompanyResponse
  last_note?: number
  name?: string
  custom_schedules?: any[]
}

export interface PaginationData {
  first_page: number
  last_page: number
  page: number
  total: number
  total_pages: number
}
export interface NotesData {
  id?: number
  note_text?: string
  company_id?: number
  compliance_task_id?: number
  created_by?: string
  created_at?: string
  order_id?: number
}

export interface StaffData {
  id?: number
  name?: string
}

export interface CompanyStatusData {
  compliance_task_id?: number
  status?: string
}

export interface AssighUserData {
  compliance_task_id?: number
  assigned_to?: number
}

export interface PausedTaskData {
  compliance_task_id?: number
  business_active?: boolean
}

export interface ComplianceSequenceData {
  compliance_task_id?: number
  compliance_template_id?: number
  type?: 'start' | 'stop'
}
export interface ComplianceMuteUnmuteData {
  muted: boolean
  company_id: number | string
  notification_schedule_id: number
}

export const getUpcommingDeadline = async (): Promise<DeadlineListProps[]> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.27:3000'
  return await (await apiClient.get('/compliance-tasks-upcoming-deadlines')).data
}

// `/compliance-tasks?company_id=${company_id}&client_id=${client_id}&due_date=${due_date}&order_type_id=${order_type_id}`
export const getComplianceTasks = async (complianceTaskParams: ComplianceTaskParams): Promise<ComplianceTasks> => {
  const {
    company_id,
    client_id,
    due_date,
    order_type_id,
    status,
    page,
    page_size,
    query,
    response,
    unread,
    compliance_type,
  } = complianceTaskParams
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  return (
    await apiClient.get(`/compliance-tasks`, {
      params: {
        company_id: company_id ? company_id : 0,
        client_id: client_id ? client_id : 0,
        due_date: due_date ? due_date : '',
        order_type_id: order_type_id ? order_type_id : 0,
        status: status ? status : '',
        page: page ? page : 0,
        page_size: page_size ? page_size : 0,
        query: query ? query : '',
        response: response || response === 0 ? response : null,
        unread: unread ? unread : null,
        compliance_type: compliance_type ? compliance_type : '',
      },
    })
  ).data
}

export const getComplianceOrderDetails = async (orderId: number): Promise<IOrderDetailsResponse> => {
  return (await apiClient.get(`order/${orderId}`)).data
}

export const addComplianceNote = async (payload: NotesData): Promise<any> => {
  return (await apiClient.post('/compliance-notes', payload)).data
}

export const getComplianceNoteById = async (complianceId: number): Promise<NotesData[]> => {
  return (await apiClient.get(`/compliance-notes/${complianceId}`)).data
}

export const getStaffName = async (): Promise<StaffData[]> => {
  return (await apiClient.get(`/user/staff?query=`)).data
}

export const complianceCompleteOrder = async (payload: CompanyStatusData): Promise<any> => {
  const { compliance_task_id, status } = payload
  return (await apiClient.post(`/compliance-tasks/${compliance_task_id}`, { status })).data
}

export const complianceCompanyClosed = async (payload: CompanyStatusData): Promise<any> => {
  const { compliance_task_id, status } = payload
  return (await apiClient.post(`/compliance-tasks/${compliance_task_id}`, { status })).data
}

export const complianceInProgressOrder = async (payload: CompanyStatusData): Promise<any> => {
  const { compliance_task_id, status } = payload
  return (await apiClient.post(`/compliance-tasks/${compliance_task_id}`, { status })).data
}

export const complianceAssighUser = async (payload: AssighUserData): Promise<any> => {
  const { compliance_task_id, assigned_to } = payload
  return (await apiClient.post(`/compliance-tasks/${compliance_task_id}`, { assigned_to })).data
}

//////////////////////// get compliance task for company ///////////////////////

export const getComplianceTasksByCompanyId = async (
  complianceTaskParams: ComplianceTaskParams
): Promise<ComplianceTasks> => {
  const { company_id, page, page_size, show_history } = complianceTaskParams
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  return await (
    await apiClient.get(`/compliance-tasks/company/${company_id}`, {
      params: {
        page: page ? page : 0,
        page_size: page_size ? page_size : 0,
        show_history: show_history ? show_history : false,
      },
    })
  ).data
}

export const pausedComplianceTask = async (payload: PausedTaskData): Promise<any> => {
  const { compliance_task_id, business_active } = payload
  return (await apiClient.post(`/toggle-notifications/${compliance_task_id}`, { business_active })).data
}
export const fetchComplianceOrderTypes = async (query = ''): Promise<IOrderType[]> => {
  return (await apiClient.get('/compliance/type', { params: { query } })).data
}

export const getComplianceNotificationTemplates = async (): Promise<any[]> => {
  return await (await apiClient.get('/compliancenotificationtemplates')).data
}
export const complianceNotificationSequence = async (payload: ComplianceSequenceData): Promise<any> => {
  return (await apiClient.post(`/compliance-notification-sequence`, payload)).data
}
export const getcomplianceCurrentSequence = async (complianceId: number): Promise<any[]> => {
  return await (await apiClient.get(`/compliance-current-sequence/${complianceId}`)).data
}
export const getComingYearsComplianceSchedules = async (companyID: number): Promise<any[]> => {
  return await (await apiClient.get(`/company-next-schedules/${companyID}`)).data
}
export const complianceMuteUnmute = async (payload: ComplianceMuteUnmuteData): Promise<any> => {
  return (await apiClient.post(`/mute-unmute-schedule`, payload)).data
}
