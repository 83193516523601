import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientNotifications: {
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2rem',
        fontFamily: theme.typography.h1.fontFamily,
        margin: '0',
        marginBottom: '1.3rem',
      },
      '& .upcoming-deadlines': {
        margin: '1rem 0 2rem 0',
      },
      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },
    },
  })
)
