import { Button, MobileStepper } from '@material-ui/core'
import { useStyles } from './styles'
import { useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { IProTip, getCategoryProTip } from 'api/specialOffers'
import { Skeleton } from '@material-ui/lab'

const ProTip = () => {
  const classes = useStyles()

  const [proTips, setProTips] = useState<IProTip[]>([])
  const { id } = useParams<any>()
  const [isLoading, setIsLoading] = useState(false)

  const getproTips = async (id: number) => {
    setIsLoading(true)
    try {
      const result = await getCategoryProTip(id)
      setProTips(result)
      setIsLoading(false)
    } catch (error) {
      console.log('error', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getproTips(id)
  }, [id])

  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = proTips.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.proTipCard}>
      <p className="title-text">Pro Tips</p>
      {isLoading ? (
        <Skeleton variant="rect" height={145} />
      ) : proTips?.length > 0 ? (
        <>
          <div style={{ textAlign: 'center' }}>
            <img src={proTips[activeStep]?.img_url} />
          </div>

          <h1 className="heading-text">{maxSteps ? proTips[activeStep]?.title : ''}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: `${maxSteps ? proTips[activeStep]?.description : ''}  `,
            }}
            className="content-text"
          />
          <Button variant="text" color="primary" className="power-btn">
            {maxSteps ? proTips[activeStep]?.link_text : ''}
          </Button>

          <MobileStepper
            steps={maxSteps || 0}
            position="static"
            variant="text"
            activeStep={activeStep}
            className="protip-container"
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
              </Button>
            }
          />
        </>
      ) : (
        <p>
          <br />
          No Pro Tips
        </p>
      )}
    </div>
  )
}

export default ProTip
