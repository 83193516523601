import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState, useEffect } from 'react'
import CommonInput from '../CommonInput'
import { IStateProps } from '../Step1'
import { fetchStates } from 'api/states'
import { getcities } from 'api/addresses'
import showErrorNotification from 'utils/showErrorNotification'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export interface IAddressProps {
  address_line_1: string
  zip?: string
  is_primary?: boolean
  is_mailing?: boolean
  city_id: number
}

interface AddressFiledsProps {
  addressObj: IAddressProps
  setAddressObj: any
}

const AddressFileds = ({ addressObj, setAddressObj }: AddressFiledsProps) => {
  const classes = useStyles()

  const [addressStateName, setAddressStateName] = useState<IStateProps>()
  const [stateLists, setStateLists] = useState<IStateProps[]>([])
  const [citiesLists, setCitiesLists] = useState<any[]>([])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddressProps>(key: K) => (value: IAddressProps[K]) => {
      setAddressObj({
        ...addressObj,
        [key]: value,
      })
    },
    [addressObj, setAddressObj]
  )

  const getStateList = async () => {
    try {
      const res = await fetchStates()
      setStateLists(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const getCitiesList = async (stateId: number) => {
    try {
      const res = await getcities(stateId)
      setCitiesLists(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    getStateList()
  }, [])

  useEffect(() => {
    if (addressStateName) {
      getCitiesList(addressStateName?.id)
    }
  }, [addressStateName])

  return (
    <Grid container spacing={0}>
      <div style={{ margin: '8px 0' }}>
        <CommonInput
          label={'What is your business address?'}
          palcehodler={'Address Line'}
          value={addressObj?.address_line_1}
          name="address_line_1"
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
            onAddressFieldsChange('address_line_1')(e.target.value)
          }
        />
      </div>
      <Autocomplete
        getOptionSelected={(option, value) => option.state_display_name === value.state_display_name}
        getOptionLabel={option => option.state_display_name}
        options={stateLists}
        id="state-auto-complete"
        autoComplete
        includeInputInList
        placeholder="States"
        className={classes.autoComplete}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="State"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        onChange={(_, value) => setAddressStateName(value as IStateProps)}
      />
      <Autocomplete
        id="city"
        options={citiesLists.map((city: any) => ({
          value: city.id,
          label: city.city_name,
        }))}
        getOptionLabel={option => option?.label}
        getOptionSelected={(option, value) => option.label === value?.label}
        autoComplete
        includeInputInList
        placeholder="City"
        className={classes.autoComplete}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="City"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        onChange={(_, value) => onAddressFieldsChange('city_id')(value?.value)}
      />
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={6} md={4}>
          <CommonInput
            palcehodler={'Zip'}
            value={addressObj?.zip}
            name="zip"
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
              onAddressFieldsChange('zip')(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6} md={8}>
          <CommonInput palcehodler={'Country'} value="United States of America" name="country" disabled />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddressFileds

const useStyles = makeStyles(theme =>
  createStyles({
    autoComplete: {
      width: '100%',

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInputBase-root ': {
        fontSize: '0.9rem',
        background: '#f7f8f9',
        padding: '0 0.5rem',
      },
      '& .MuiFormLabel-root': {
        fontSize: '0.9rem',
        fontFamily: 'Roboto, sans-serif',
        textTransform: 'uppercase',
        color: '#202124',
      },
    },
  })
)
