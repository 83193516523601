import * as React from 'react'
interface CompanyContextValue {
  companyName?: string
  companyId?: number
}

export const CompanyContext = React.createContext<CompanyContextValue>({
  companyName: '',
  companyId: undefined,
})
