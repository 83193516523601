import { Grid } from '@material-ui/core'
import PrimaryHeading from 'components/kpiDasboardElements/PrimaryHeading'
import SectionDetail from 'components/kpiDasboardElements/SectionDetail'
import React from 'react'
import { useStyles } from '../style'

interface ReportBoxProps {
  heading?: string
  HeadingOne?: string
  HeadingTwo?: string
  HeadingOneValue?: string | number
  HeadingTwoValue?: string | number
  date?: string
  HeadingFour?: string
  HeadingThree?: string
  HeadingThreeValue?: string | number
  HeadingFourValue?: string | number
}

const ReportBox = ({
  heading,
  HeadingOne,
  HeadingTwo,
  date,
  HeadingOneValue,
  HeadingTwoValue,
  HeadingThree,
  HeadingThreeValue,
  HeadingFour,
  HeadingFourValue,
}: ReportBoxProps) => {
  const classes = useStyles()

  return (
    <Grid className={classes.commonRow} container spacing={0}>
      <Grid item xs={12}>
        <PrimaryHeading heading={heading} text={date} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid container xs={6} spacing={0}>
            <Grid item xs={5}>
              <SectionDetail primaryText={HeadingOne} heading={HeadingOneValue} />
            </Grid>
            <Grid item xs={5}>
              <SectionDetail primaryText={HeadingThree} heading={HeadingThreeValue} />
            </Grid>
          </Grid>
          <Grid container xs={6} spacing={0}>
            <Grid item xs={5}>
              <SectionDetail primaryText={HeadingTwo} heading={HeadingTwoValue} />
            </Grid>
            <Grid item xs={5}>
              <SectionDetail primaryText={HeadingFour} heading={HeadingFourValue} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReportBox
