import * as React from 'react'

interface FilledCheckboxProps {
  style?: React.CSSProperties
}

const FilledCheckbox: React.FC<FilledCheckboxProps> = ({ style }) => {
  const customStyle = style || {}
  return (
    <div
      style={{
        width: 40,
        height: 40,
        borderRadius: 4,
        border: 'solid 1px #979797',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        ...customStyle,
      }}
    >
      <div
        style={{
          width: customStyle && customStyle['width'] ? `calc(${customStyle['width']}px - 10px)` : 30,
          height: customStyle && customStyle['height'] ? `calc(${customStyle['height']}px - 10px)` : 30,
          borderRadius: 4,
          backgroundColor: '#3c9fdf',
        }}
      />
    </div>
  )
}

export default FilledCheckbox
