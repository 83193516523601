import React, { useMemo, useState } from 'react'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { Button, Grid, Paper, Table, TableBody, TableContainer, TextField, Theme, makeStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import { CallLogsDetails, ICallWidgets, fetchCallLogs, updateCallStatus } from 'api/callLogs'
import CallLogsHead from './callLogsTable/callLogsHead'
import useSortTable from 'hooks/useSortTable'
import { skeletons } from 'components/common/table'
import CallLogsRow from './callLogsTable/callLogsRow'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { Autocomplete } from '@material-ui/lab'
import showSuccessNotification from 'utils/showSuccessNotification'
import showApiResponseError from 'utils/showApiResponseError'

interface FilterType {
  label: string
  value: number
}
interface ICallLogTable {
  widgetData?: ICallWidgets
}

const CallLogs = ({ widgetData }: ICallLogTable) => {
  const [isComplete, setIsCompleted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const classes = useStyles()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'date_time' })
  const { order, sort_by } = sortSettings

  const memoApiCall = useMemo(
    () => fetchCallLogs(isComplete ? '3' : '', callQueue[widgetData?.call_queue ?? 1])({ sort_order: order, sort_by }),
    [isComplete, order, sort_by, widgetData?.call_queue]
  )

  const pagedTable = usePagedTable<CallLogsDetails>({
    apiCall: memoApiCall,
  })

  const tableHead = useMemo(
    () => <CallLogsHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )
  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const updateStatus = async (callLogs: CallLogsDetails) => {
    setLoading(true)
    const data = {
      status: 'completed',
    }
    try {
      await updateCallStatus(callLogs['id'], data)
      showSuccessNotification('Status Updated Successfully')
      handleSuccessfulRequest()
      setLoading(false)
    } catch (error) {
      showApiResponseError(error, 'error')
      setLoading(false)
    }
  }
  console.log(callQueue[widgetData?.call_queue ?? ''], 'CALLLLLLLLLLLL')
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ textAlign: 'end', marginBottom: '10px' }}>
            <Button color="primary" variant="outlined" onClick={() => setIsCompleted(!isComplete)}>
              {isComplete ? 'Show History' : 'Show Completed'}
            </Button>
          </div>
        </Grid>
      </Grid>
      {/* <p className={classes.missingItems}>Call Logs</p>
      <p className={classes.tableTitle}>Call Logs Queue List</p>
      <div className={classes.filters}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Autocomplete
              id="callQueue"
              options={callQueue}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setCallQueueValue(newValue)
              }}
              renderInput={params => <TextField {...params} label="Call Logs Status" variant="outlined" />}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              id="callStatus"
              options={callQueueStatus}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setStatus(newValue)
              }}
              renderInput={params => <TextField {...params} label="Call Queue Logs Status" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </div> */}
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            {tableHead}
            <TableBody>
              {skeletons({
                numberOfColumns: 9,
                sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100],
                numberOfRows: 6,
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <PagedTable
            colSpan={10}
            emptyRowText="No Details Found"
            {...pagedTable.renderProps}
            renderRow={(callLogs: CallLogsDetails) => (
              <CallLogsRow
                callLogsData={callLogs}
                onCompleteClick={() => updateStatus(callLogs)}
                getAllData={() => handleSuccessfulRequest()}
                loading={loading}
              />
            )}
            header={tableHead}
          />
        </TableContainer>
      )}
    </div>
  )
}
export default CallLogs

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // padding: 50,
      //   display: 'flex',
      //   flexDirection: 'column',
      '& p': {
        margin: '0',
      },
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    filters: {
      margin: '1rem 0',
    },
  })
)

// const callQueue = [
//   { value: 1, label: 'Processing Call Queue' },
//   { value: 2, label: 'Onboarding Call Queue' },
//   { value: 3, label: 'Tax Processing Call Queue' },
// ]
const callQueueStatus = [
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Processing' },
  { value: 3, label: 'Completed' },
]
const callQueue = {
  'Processing Call Queue': 1,
  'Onboarding Call Queue': 2,
  'Tax Processing Call Queue': 3,
}
