import {
  FormControl,
  FormControlLabel,
  makeStyles,
  createStyles,
  Checkbox,
  Tab,
  withStyles,
  Theme,
  Tabs,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useState } from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      display: 'flex',
      alignItems: 'center',
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: '120px',

      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },

    datePickerFiled: {
      display: 'flex',
      alignItems: 'baseline',

      '& span': {
        margin: '0 0.5rem',
        fontWeight: 600,
      },
    },
  })
)

const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    // marginBottom: '1rem',
  },
  indicator: {
    backgroundColor: '#407ace',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      color: '#292929',
      fontFamily: 'Roboto',

      '&:hover': {
        color: '#407ace',
        opacity: 1,
      },
      '&$selected': {
        color: '#407ace',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#407ace',
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

interface StyledTabProps {
  label: string
  value?: number
}

const FilterRow = ({ tabValue, setTabValue, startDate, setStartDate, endDate, setEndDate, isCustom, setIsCustom }) => {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    setIsCustom(false)
  }
  const handleStartDateChange = date => {
    setStartDate(date)
  }
  const handleEndDateChange = date => {
    setEndDate(date)
  }

  return (
    <div className={classes.mainContainer}>
      <AntTabs value={tabValue} onChange={handleChange} aria-label="ant example">
        <AntTab label="Today" value={0} />
        <AntTab label="Last 7 Days" value={7} />
        <AntTab label="Last 30 days" value={30} />
      </AntTabs>
      <FormControl variant="outlined" className={classes.formControl}>
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={isCustom} onChange={(event: any) => setIsCustom(event.target.checked)} />
          }
          label="Custom"
        />
      </FormControl>
      {isCustom ? (
        <div className={classes.datePickerFiled}>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={startDate}
              onChange={handleStartDateChange}
              label="Start Date"
              autoOk
            />
          </FormControl>
          <span>to</span>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              label="End Date"
              autoOk
            />
          </FormControl>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default FilterRow
