import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useConfirmationDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabProgress: {
      color: 'white',
      position: 'absolute',
      top: 133,
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      marginTop: 15,
    },
    button: {
      width: 200,
    },
    itemNameWrapper: {
      borderRadius: 4,
      border: 'solid 1px rgba(151, 151, 151, 0.26)',
      backgroundColor: 'rgba(250, 250, 250, 0.9)',
      display: 'flex',
      alignItems: 'center',
      margin: '15px 0px',
      padding: '0px 10px',
      maxWidth: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: 560,
      alignItems: 'center',
      padding: '20px 30px',
    },
    disabled: {
      backgroundColor: `${theme.customPalette.errorButtonColor}!important`,
    },
    itemName: {
      fontSize: '1.5rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
    },
    modalTitle: {
      marginRight: 25,
    },
  })
)
