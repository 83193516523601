import { apiClient, requestPaginated } from '@jetkit/react'

interface KpiDashboardData {
  start_date: string
  end_date: string
  from_email?: boolean
}

export const getKpiDashboard = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}`
    )
  ).data
}

export const getKpiDashboardUsers = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-users?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}`
    )
  ).data
}

export const getKpiDashboardOrderTraffic = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-order-traffic?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(
        end_date
      )}`
    )
  ).data
}

export const getKpiDashboardSpecialOffer = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date, from_email } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-special-offers?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(
        end_date
      )}&from_email=${from_email}`
    )
  ).data
}

export const getKpiDashboardUserTraffic = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-user-traffic?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(
        end_date
      )}`
    )
  ).data
}

export const getKpiDashboardLeadManager = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-lead-manager?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(
        end_date
      )}`
    )
  ).data
}

export const getKpiDashboardOrders = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-orders?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}`
    )
  ).data
}
export const getKpiDashboardCompliance = async (payload: KpiDashboardData): Promise<any> => {
  const { start_date, end_date } = payload
  return (
    await apiClient.get(
      `/kpi-dashboard-compliance?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}`
    )
  ).data
}
export const getKpiDashboardNotifications = async (payload): Promise<any> => {
  const { start_date, end_date } = payload
  // apiClient.defaults.url=''
  // apiClient.defaults.baseURL = 'http://192.168.0.214:3000'
  return (
    await apiClient.get(
      `/kpi-dashboard-unsubscribed-entity-counts?start_date=${encodeURIComponent(
        start_date
      )}&end_date=${encodeURIComponent(end_date)}`,
      payload
    )
  ).data
}
export const getClientsByUserIds = requestPaginated<any>({
  url: '/clients-userid',
  method: 'get',
})
export const getCompaniesIds = requestPaginated<any>({
  url: '/company-kpi',
  method: 'get',
})
export const getOrdersByIds = requestPaginated<any>({
  url: '/company-orders-kpi',
  method: 'get',
})
// export const getClientsByUserIds = requestPaginated<any>({
//   url: '/clients-userid',
//   method: 'get',
// })
