import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import CountryList from 'country-list'
import { IAddress } from 'models/address'
import * as React from 'react'
import { useEffect } from 'react-transition-group/node_modules/@types/react'
import { assertNonNullable } from 'utils/asserts'
import { ValueOf } from 'utils/objectUtils'
import { TestID } from 'utils/testUtils/TestID'
import usaStates from 'utils/usaStates'
import { IAddressErrors } from '../../clients/create/clientDetails'
import ButtonWithLoading from '../buttons/buttonWithLoadingProgress'
import ModalTitle from '../modalTitle'
import OutlinedSelect from '../outlinedSelect'
import SelectAutocomplete from '../SelectAutocomplete'
import { getstates, getcities } from 'api/addresses'
import { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

/**
 * country names sorted alphabetically,
 * `United States of America` comes first
 */
const countryNames: string[] = [UNITED_STATES].concat(
  CountryList.getData()
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map(country => country.name)
    .filter(country => country !== UNITED_STATES)
)

const countriesData = CountryList.getData()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: 550,
      padding: 15,
    },
    titleButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',
    },
    title: {
      width: '100%',
      fontSize: '2rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 600,
      textAlign: 'center',
    },
    checkBoxWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    inputFieldsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px',
    },
    submitButton: {
      margin: '0 10px',
    },
    modalTitle: {
      padding: 5,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    updateOnCompanyLevelText: {
      fontSize: 20,
      cursor: 'pointer',
      marginTop: 10,
      marginBottom: 5,
    },
  })
)

interface ObjType {
  label: string
  value: number
}

interface IAddAddressDialogProps {
  /**
   * change value on form fields in parent component
   */
  handleInputFieldsChange(key: keyof IAddress): (value: ValueOf<IAddress>) => void

  addressFields: IAddress
  handleDialogClose(): void

  /**
   * is an `onSubmit` method for this dialog. This is a handler
   * for `Add/Update Address` button which closes this dialog.
   */
  onAddressAdd: () => void

  addressErrors: IAddressErrors

  /**
   * is this create or update mode
   */
  isEditing?: boolean

  /**
   * checkbox for toggling address primary or not primary
   */
  showPrimaryAddressCheckbox?: boolean

  /**
   *
   * method to handle click on checkbox that handles toggling primary/not primary address
   */
  onPrimaryAddressChecked?(checked: boolean): void

  /**
   * is dialog visible
   */
  open?: boolean
}

/**
 * address dialog that can be used for creating or editing an existing address
 *
 * look into CreateUpdateAddressDialog. it may fullfil
 * all your needs and has an easier interface
 */
export const AddAddressDialog: React.FC<IAddAddressDialogProps> = ({
  open,
  handleInputFieldsChange,
  addressFields,
  handleDialogClose,
  onAddressAdd,
  onPrimaryAddressChecked,
  addressErrors,
  isEditing,
  showPrimaryAddressCheckbox,
}) => {
  const classes = useStyles()
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const [selectedCity, setSelectedCity] = useState<ObjType>()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(959))

  const onInputFieldsChange = (
    key: keyof IAddress,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    //
    event.persist()
    handleInputFieldsChange(key)(event.target.value)
  }

  const handlePrimaryAddressChecked = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    assertNonNullable(onPrimaryAddressChecked)
    onPrimaryAddressChecked(checked)
  }
  const getCities = async state => {
    let id = 0
    states.map((item: any, index) => {
      if (item.state_display_name === state) {
        id = item.id
      }
    })
    setCities(await getcities(id))
  }
  let stateData: any = []
  React.useEffect(() => {
    const func = async () => {
      stateData = await getstates()
      //console.log(stateData);
      setStates(stateData)
    }
    func()
  }, [])

  React.useEffect(() => {
    if (addressFields?.locality) {
      getCities(addressFields?.locality)
    }
  }, [addressFields?.locality])

  // const handleCityChange = (newValue: ObjType | null) => {
  //   console.log('addressFields selected city', newValue)

  //   // if (newValue) {
  //   //   switch (newValue) {
  //   //     case newValue?.label:
  //   //       handleInputFieldsChange('city')(newValue?.label)
  //   //       break
  //   //     case newValue?.value:
  //   //       handleInputFieldsChange('city_id')(newValue?.value)
  //   //       break

  //   //     default:
  //   //       break
  //   //   }
  //   // }

  //   if (newValue) {
  //     handleInputFieldsChange('city')(newValue?.label)
  //     handleInputFieldsChange('city_id')(newValue?.value)
  //   }
  //   // if (newValue?.value) {
  //   //   handleInputFieldsChange('city_id')(newValue?.value)
  //   // }
  // }

  React.useEffect(() => {
    //
    if (addressFields?.cityObj) {
      //

      setSelectedCity(addressFields?.cityObj)
    } else if (addressFields?.city_id || addressFields?.city) {
      //
      if (addressFields?.city_id) {
        //

        setSelectedCity({
          value: addressFields?.city_id,
          label: addressFields?.city,
        })
      }
      console.log('selectedCity', selectedCity)
    }
    handleInputFieldsChange('city_id')(addressFields?.city_id || selectedCity?.value)
    handleInputFieldsChange('city')(addressFields?.city || selectedCity?.label)

    const newValue = UNITED_STATES
    handleInputFieldsChange('country')(newValue)
  }, [addressFields?.city, addressFields?.cityObj, addressFields?.city_id])

  console.log('addressFields cities', cities)

  console.log('addressFields', addressFields)

  const newCityObj: ObjType = {
    value: addressFields?.city_id || 0,
    label: addressFields?.city || '',
  }

  const handleChange = React.useCallback(
    (_, newValue) => {
      console.log(newValue, 'sdkkldflsdksdlds')
      //
      if (!newValue) {
        //
        handleInputFieldsChange('cityObj')(selectedCity)
      } else {
        //
        handleInputFieldsChange('cityObj')(newValue)
        setSelectedCity(newValue)
      }
      //
    },
    [handleInputFieldsChange, selectedCity]
  )

  const handleStateSelect = React.useCallback(
    value => {
      handleInputFieldsChange('locality')(value)
      getCities(value)

      setSelectedCity({
        value: 0,
        label: '',
      })
    },
    [handleInputFieldsChange]
  )

  const handleChangeCountry = value => {
    if (value) {
      handleInputFieldsChange('country')(value as string)
    }
    const newValue = UNITED_STATES
    handleInputFieldsChange('country')(newValue)
  }

  return (
    <Dialog maxWidth="lg" open={!!open}>
      <div className={classes.container} style={{ width: matches ? 'auto' : '550px' }}>
        <ModalTitle
          classes={{ inlineTitleButtonContainer: classes.modalTitle }}
          onClose={handleDialogClose}
          title={isEditing ? 'Update Address' : 'Add Address'}
        />
        {showPrimaryAddressCheckbox && (
          <div className={classes.checkBoxWrapper}>
            <Checkbox color="primary" checked={addressFields.is_primary} onChange={handlePrimaryAddressChecked} />
            Primary Shipping Address
          </div>
        )}
        <div className={classes.inputFieldsWrapper}>
          <TextField
            label="Address Line"
            type="text"
            error={addressErrors.street}
            name="Address Line"
            margin="normal"
            variant="outlined"
            helperText={addressErrors.street && 'Please Specify Address'}
            value={addressFields.street}
            onChange={event => onInputFieldsChange('street', event)}
            data-testid={TestID.ADDRESS_LINE}
          />
          <br />
          {/* <TextField
            label="City"
            error={addressErrors.city}
            type="text"
            name="City"
            helperText={addressErrors.city && 'Please Specify City'}
            margin="normal"
            variant="outlined"
            value={addressFields.city}
            onChange={event => onInputFieldsChange('city', event)}
            data-testid={TestID.CITY}
          /> */}

          {/* ) : (
            <TextField
              label="City"
              type="text"
              name="City"
              margin="normal"
              variant="outlined"
              value={addressFields.city}
              onChange={event => onInputFieldsChange('city', event)}
              data-testid={TestID.CITY}
            /> */}
          {/* {addressFields.country === UNITED_STATES ? (
            <SelectAutocomplete
              options={states.map((state: any) => ({
                value: state.state_display_name,
                label: state.state_display_name,
              }))}
              label="State"
              value={addressFields.locality}
              // handleChange={(value: string) => {
              // handleInputFieldsChange('locality')(value)
              // getCities(value)
              // }}
              handleChange={handleStateSelect}
              dataTestId={TestID.STATE_SELECT}
            />
          ) : (
            <TextField
              label="State / Province / Region"
              type="text"
              name="State"
              margin="normal"
              variant="outlined"
              value={addressFields.locality}
              onChange={event => onInputFieldsChange('locality', event)}
              data-testid={TestID.STATE}
            />
          )} */}
          <SelectAutocomplete
            options={states.map((state: any) => ({
              value: state.state_display_name,
              label: state.state_display_name,
            }))}
            label="State"
            value={addressFields.locality}
            // handleChange={(value: string) => {
            // handleInputFieldsChange('locality')(value)
            // getCities(value)
            // }}
            handleChange={handleStateSelect}
            dataTestId={TestID.STATE_SELECT}
            error={addressErrors.locality}
            helperText={addressErrors.locality ? 'Please add State Name' : ''}
          />

          <br />
          {/* <SelectAutocomplete
            options={cities.map((city: any) => ({
              value: city.id,
              label: city.city_name,
            }))}
            label="City"
            value={addressFields.city_id}
            handleChange={(value: string) => {
              handleInputFieldsChange('city')(value)
              handleInputFieldsChange('city_id')(value)
            }}
            dataTestId={TestID.CITY_SELECT}
            isDisabled={addressFields.locality ? false : true}
          /> */}

          <Autocomplete
            id="city"
            options={cities.map((city: any) => ({
              value: city.id,
              label: city.city_name,
            }))}
            getOptionLabel={option => option?.label}
            getOptionSelected={(option, value) => option.label === value?.label}
            // value={selectedCity || newCityObj}
            value={selectedCity}
            // onChange={(event: any, newValue: ObjType | null) => {
            //   // handleCityChange(newValue)
            //   //

            //   if (newValue) handleInputFieldsChange('cityObj')(newValue)

            //   // handleInputFieldsChange('city_id')(newValue?.value)
            //   // handleInputFieldsChange('city')(newValue?.label)
            // }}
            onChange={handleChange}
            renderInput={params => (
              <TextField
                {...params}
                label="City"
                variant="outlined"
                error={addressErrors.city}
                helperText={addressErrors.city && 'Please add city'}
              />
            )}
          />

          <TextField
            label="Zip"
            type="text"
            name="Zip"
            error={addressErrors.postal_code}
            margin="normal"
            variant="outlined"
            helperText={addressErrors.postal_code && 'Please specify ZIP'}
            value={addressFields.postal_code}
            onChange={event => onInputFieldsChange('postal_code', event)}
            data-testid={TestID.ZIP}
          />
          {/* <OutlinedSelect
            name="Country"
            error={addressErrors.country}
            errorHelperText="Please Select Country"
            value={addressFields.country || UNITED_STATES}
            options={countryNames}
            onOptionSelect={value => handleInputFieldsChange('country')(value as string)}
            disabled={true}
          /> */}
          <OutlinedSelect
            name="country"
            error={addressErrors.country}
            errorHelperText="Please Select Country"
            value={addressFields.country || 'United States of America'}
            options={countriesData.map(country => country.name)}
            onOptionSelect={handleChangeCountry}
            disabled={true}
          />
        </div>
        <ButtonWithLoading className={classes.submitButton} onClick={onAddressAdd}>
          {isEditing ? 'Update Address' : 'Add Address'}
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default AddAddressDialog
