import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import * as React from 'react'
import useRouter from 'use-react-router'
import TaxWidgetController from 'components/taxWidgets/TaxWidgetController'
import TaxWidgetTableHead from 'components/taxWidgets/table/head'
import TaxWidgetTableRowRow from 'components/taxWidgets/table/row'
import { ITaxWidgets, deleteTaxWidgetById, getTaxWidges } from 'api/taxWidgets'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '2rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 600,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    searchBarContainer: {
      margin: '0.5rem 0',
    },
    tableContainer: {
      margin: '0.5rem 0',
    },
  })
)

const TaxWidgets: React.FC = () => {
  const classes = useStyles()

  const [query, setQuery] = React.useState('')
  const [taxWidgetToDelete, setTaxWidgetToDelete] = React.useState<ITaxWidgets>()
  const [deleteTaxWidgetDialogShown, setDeleteTaxWidgetDialogShown] = React.useState(false)
  const router = useRouter()

  const apiCall = React.useMemo(() => getTaxWidges()({ query }), [query])
  const pagedTable = usePagedTable<any>({ apiCall })

  const onEditClick = React.useCallback(
    (taxWidget: ITaxWidgets) => {
      if (!taxWidget.id) return
      router.history.push(`/tax-widget/${taxWidget.id}`)
    },
    [router.history]
  )

  const onDeleteClick = React.useCallback((taxWidget: ITaxWidgets) => {
    if (!taxWidget.id) return
    setTaxWidgetToDelete(taxWidget)
    setDeleteTaxWidgetDialogShown(true)
  }, [])

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteTaxWidgetDialogShown}
        close={() => setDeleteTaxWidgetDialogShown(false)}
        deletionObject={taxWidgetToDelete}
        deletionRequest={deleteTaxWidgetById}
        onRequestSuccessful={onDeleteSuccessful}
      />
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>Tax Widgets</Typography>
        <TaxWidgetController fetchClients={pagedTable.reloadData} />
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar onChange={setQuery} placeholder="Search by Title" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            <TaxWidgetTableHead />
            <TableBody>{skeletons({ numberOfColumns: 3, numberOfRows: 6 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Tax Widget data"
            {...pagedTable.renderProps}
            renderRow={(taxWidget: ITaxWidgets) => (
              <TaxWidgetTableRowRow
                taxWidget={taxWidget}
                onEditClick={() => onEditClick(taxWidget)}
                onDeleteClick={() => onDeleteClick(taxWidget)}
              ></TaxWidgetTableRowRow>
            )}
            header={<TaxWidgetTableHead />}
            colSpan={6}
          />
        )}
      </div>
    </div>
  )
}

export default TaxWidgets
