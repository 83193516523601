import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  profileDialog: {
    '& .profile-modal': {
      padding: '1.3rem 1.1rem',
      fontFamily: 'Lato',

      '& .avater': {
        width: '55px',
        height: '55px',
        background: '#307abc',
        color: '#fff',
        fontSize: '25px',
        fontFamily: 'Roboto',
        position: 'fixed',
        fontWeight: 600,
      },

      '& .congrats-img': {
        display: 'block',
        margin: '0 auto',
      },
    },
  },
  companyName: {
    margin: '1rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#202124',
    fontFamily: 'Lato',
    textTransform: 'capitalize',
  },
  account: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',

    '& .progress-color': {
      color: '#307abc',
    },
  },
  accountText: {},
  accountPara: {
    textAlign: 'start',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#202124',
    padding: '0 0 4px',
    margin: '0',
  },
  accountParaTwo: {
    textAlign: 'start',
    fontSize: '13px',
    color: '#202124',
    margin: '0',
  },
  employeeHead: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#202124',
    textAlign: 'center',
    margin: '2rem 0 1.25rem',
  },
  buttonSec: {
    display: 'flex',
    // justifyContent: 'space-evenly',
    alignItems: 'center',

    '& .close-btn': {
      color: '#fff',
      background: '#307abc',
      margin: '0 auto',
    },
    '& .confirm-btn': {
      '& button': {
        color: '#fff',
        background: '#307abc',
        height: 'auto',
      },
    },
    '& .decline-btn': {
      '& button': {
        color: '#788190',
        borderColor: '#788190',
        height: 'auto',

        '&:hover': {
          color: '#788190',
          borderColor: '#788190',
          background: '#fff',
        },
      },
    },
  },

  linkText: {
    cursor: 'pointer',
    margin: '0',
    marginTop: '2rem',
    color: ' #788190',
    textDecoration: 'underline',
  },
})
