import { BarChart, Language, ExitToApp, AttachMoney, Description, StarBorder, MoneyOff } from '@material-ui/icons'
import * as MenuIcons from 'icons/menuIcons'
import * as React from 'react'
import { menuIconColor, selectedMenuIconColor } from '../theme'

const iconStyle = (selected: boolean) => ({
  color: selected ? selectedMenuIconColor : menuIconColor,
})

const employeeSection = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    renderIcon: ({ selected }) => (
      <MenuIcons.DashBoardIcon style={{ ...iconStyle(selected), width: 20 }} viewBox="0 0 14 20" />
    ),
  },
  {
    title: 'Web Submissions',
    path: '/web-submissions',
    renderIcon: ({ selected }) => <Language style={iconStyle(selected)} />,
  },
  {
    title: 'Lead Manager',
    path: '/prospects',
    renderIcon: ({ selected }) => <MenuIcons.LeadManagerIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Failed Payments',
    path: '/payment-fail-logs',
    renderIcon: ({ selected }) => <MoneyOff style={iconStyle(selected)} />,
  },
  {
    title: 'Calendar',
    path: '/compliance-calendar',
    renderIcon: ({ selected }) => <MenuIcons.CalendarIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Sales Representative',
    path: '/sales_representative',
    renderIcon: ({ selected }) => <MenuIcons.SalesRepIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Clients',
    path: '/client',
    renderIcon: ({ selected }) => <MenuIcons.ClientIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Companies',
    path: '/companies',
    renderIcon: ({ selected }) => <MenuIcons.CompanyIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Company Orders',
    path: '/company-order',
    renderIcon: ({ selected }) => <MenuIcons.CompanyOrderIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Company Subscription',
    path: '/company-subscription',
    renderIcon: ({ selected }) => <Description style={iconStyle(selected)} />,
  },
  {
    title: 'Tax Service Orders',
    path: '/tax-orders',
    renderIcon: ({ selected }) => <MenuIcons.PotentialOrderIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Processing and Fee',
    path: '/formation-state',
    renderIcon: ({ selected }) => <AttachMoney style={iconStyle(selected)} />,
  },
  {
    title: 'Power Ups',
    path: '/power-ups',
    renderIcon: ({ selected }) => <StarBorder style={iconStyle(selected)} />,
  },
  // {
  //   title: 'Potential Orders',
  //   path: '/potential-orders',
  //   renderIcon: ({ selected }) => <MenuIcons.PotentialOrderIcon style={iconStyle(selected)} />,
  // },
  // {
  //   title: 'Email Configuration',
  //   path: '/email-configuration',
  //   renderIcon: ({ selected }) => <MenuIcons.EmailIcon style={iconStyle(selected)} />,
  // },

  {
    title: 'Logout',
    path: '',
    renderIcon: ({ selected }) => <ExitToApp style={iconStyle(selected)} />,
  },
]

export default employeeSection
