import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientCompanies: {
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2rem',
        fontFamily: theme.typography.h1.fontFamily,
        marginBottom: '1.3rem',
      },
      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },

      '& .companies-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '1rem 0 2rem 0',

        // marginBottom: '1rem',

        '& > div:first-child': {
          marginRight: '1rem',
        },
      },
      '& .ques': {
        fontSize: '0.875rem',
        '& .link-schedule': {
          color: '#5b38da',
          fontWeight: theme.typography.fontWeightBold,
          cursor: 'pointer',
          textDecoration: 'underline',
          fontFamily: theme.typography.fontFamily,
        },
        '& .schedule-call-modal-container': {
          color: '#5b38da',
          fontWeight: theme.typography.fontWeightBold,
          textDecoration: 'underline',
          fontFamily: theme.typography.fontFamily,
          border: 'none',
          background: 'none',
          padding: 0,
          cursor: 'pointer',
        },
        // backgroundColor:"yellow",
      },
      '& .header': {
        background: '#e8f0ff',
        fontSize: '0.875rem',
        padding: '1.2rem 1rem',
        borderRadius: '4px',
        lineHeight: '1.2rem',
        '& .companies-name': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '& .power-ups-section': {
        margin: '5rem 0 2rem 0',
      },
    },
    companyCard: {
      background: '#f7faff',
      fontFamily: 'Lato',
      padding: '1.25rem',

      '& p, h1': {
        margin: '0',
      },

      '& .icon-sec': {
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: 'linear-gradient(to right,  #d4f0fc 0%,#d4f0fc 50%,#5bb3d9 50%,#5bb3d9 100%)',
        width: '52px',
        height: '52px',
      },
      '& .active-sec': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& .MuiChip-root': {
          background: '#04a464',
          color: '#fff',
          padding: '0 0.8rem',
          fontSize: '0.9rem',
        },

        '& .date-text': {
          color: '#292929',
          fontSize: '0.9rem',
          paddingTop: '0.25rem',
        },
      },

      '& .company-text': {
        color: '#020202',
        fontSize: '1.2rem',
        padding: '1rem 0 0.5rem 0',
      },
      '& .state-text': {
        color: '#292929',
        fontSize: '1rem',
      },
      '& .error-icon': {
        display: 'flex',
        alignItems: 'center',
        minHeight: '56px',

        '& p': {
          paddingLeft: '0.5rem',
          color: '#292929',
          fontSize: '0.85rem',
        },
      },
      '& .detail-text': {
        color: '#5b38da',
        fontSize: '1rem',
        fontFamily: 'Lato',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        lineHeight: 0,
        fontWeight: 600,
      },
    },
  })
)
