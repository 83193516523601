import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { capitalizeString } from 'components/common/functions'
import BRTextField from 'components/common/textFields/BRTextField'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      margin: '0.7rem 0',
    },
    textField: {}, // for overriding textfield
    select: {
      backgroundColor: 'white',
      '&.MuiSelect-select:focus': {
        backgroundColor: 'white',
      },
    },
  })
)

interface ICustomMenuItem {
  option: unknown
}
interface IOutlinedSelectProps {
  name: string
  value?: string
  options: string[]
  error?: boolean
  errorHelperText?: string
  onOptionSelect: (value: unknown) => void
  required?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  CustomMenuItem?: React.FunctionComponent<ICustomMenuItem>
  classes?: Record<string, string>
  textFieldStyles?: React.CSSProperties
  showCopyButton?: boolean
  dataTestId?: string
}

const OutlinedSelect: React.FC<IOutlinedSelectProps> = props => {
  const classes = useStyles(props)
  const {
    name,
    value,
    options,
    onOptionSelect,
    error,
    errorHelperText,
    required,
    disabled,
    style,
    textFieldStyles,
    showCopyButton,
    dataTestId = `${name}-outlined-select`,
  } = props
  return (
    <FormControl error={error} style={style} className={classes.formControl} key={value}>
      {/* Using a TextField Select here as while using outlined Select component, the border
      overlaps the  label text*/}
      <BRTextField
        customCopyButtonStyles={{ position: 'absolute', right: '2rem' }}
        showCopyButton={showCopyButton}
        SelectProps={{ classes: { select: classes.select } }}
        select
        variant="outlined"
        label={name}
        disabled={disabled}
        required={required}
        style={textFieldStyles}
        error={error}
        value={value || ''}
        className={classes.textField}
        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => onOptionSelect(event.target.value)}
        dataTestId={dataTestId}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <span data-testid={`${dataTestId}-options`}>{capitalizeString(option || '')}</span>
          </MenuItem>
        ))}
      </BRTextField>
      {error && <FormHelperText>{errorHelperText}</FormHelperText>}
    </FormControl>
  )
}

export default OutlinedSelect
