import React from 'react'
import { InputBase } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface CommonInputProps {
  label?: string
  palcehodler: string
  onChange?: React.ChangeEventHandler
  value?: any
  name?: string
  type?: string
  disabled?: boolean
}
const CommonInput = ({ label, palcehodler, onChange, value, name, type, disabled }: CommonInputProps) => {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <label className={classes.label}>{label}</label>
      <InputBase
        className={classes.textField}
        id="outlined-basic"
        // variant="standard"
        placeholder={palcehodler}
        onChange={onChange}
        name={name}
        value={value}
        type={type ? type : 'text'}
        disabled={disabled}
      />
    </div>
  )
}

export default CommonInput

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      // margin: '0.5rem 0's,
      width: '100%',
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#202124',
      marginBottom: '0.25rem',
    },
    textField: {
      width: '100%',
      background: '#f7f8f9',
      fontSize: '16px',
      borderRadius: '4px',
      borderBottom: '0',
      padding: '0 0.5rem',
      marginTop: '0.25rem',

      '& input': {
        fontSize: '0.9rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&::placeholder': {
          fontSize: '0.9rem',
          fontFamily: 'Roboto, sans-serif',
          color: '#202124',
        },
      },
    },
  })
)
