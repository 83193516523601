import * as React from 'react'
import { menuIconColor, selectedMenuIconColor } from '../theme'
import { AttachMoney, Dashboard, ExitToApp, Language, LocalOffer } from '@material-ui/icons'
import * as MenuIcons from 'icons/menuIcons'

const iconStyle = (selected: boolean) => ({
  color: selected ? selectedMenuIconColor : menuIconColor,
})

const salesAgentSection = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    renderIcon: ({ selected }) => (
      <MenuIcons.DashBoardIcon style={{ ...iconStyle(selected), width: 20 }} viewBox="0 0 14 20" />
    ),
  },
  {
    title: 'Web Submissions',
    path: '/web-submissions',
    renderIcon: ({ selected }) => <Language style={iconStyle(selected)} />,
  },
  {
    title: 'Lead Manager',
    path: '/prospects',
    renderIcon: ({ selected }) => <MenuIcons.LeadManagerIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Processing and Fee',
    path: '/formation-state',
    renderIcon: ({ selected }) => <AttachMoney style={iconStyle(selected)} />,
  },
  {
    title: 'Logout',
    path: '',
    renderIcon: ({ selected }) => <ExitToApp style={iconStyle(selected)} />,
  },
]

export default salesAgentSection
