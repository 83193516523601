import { Box, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useStyles } from '../styles'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import Question from '../question'
import { usePagedTable } from '@jetkit/react'
import { getClientMissingItemList, IOrderReminderItems } from 'api/clientMissingItem'
import useSortTable from 'hooks/useSortTable'
import useRouter from 'use-react-router'
import { Skeleton } from '@material-ui/lab'
// import { useLocation } from 'use-react-router'
// import states from 'pages/states'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}
const ClientMissingItemDetails = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const router: any = useRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const [details, setDetails] = useState<any>()
  const [name, setName] = useState('')
  // const { match }: any = useRouter()
  // const { id, category } = match.params

  // const { sortSettings } = useSortTable({ order: 'asc', sort_by: '' })
  // const { order, sort_by } = sortSettings
  // const memoApiCall = useMemo(
  //   () =>
  //     getClientMissingItemList(
  //       Number(router?.match?.params?.id),
  //       value === 0 ? 'active' : 'completed',
  //       router?.match?.url?.includes('company')
  //         ? 'company'
  //         : router?.match?.url?.includes('taxOrder')
  //         ? 'tax_order'
  //         : 'company'
  //     )({ sort_order: order, sort_by }),
  //   [order, sort_by, value]
  // )

  // const pagedTable = usePagedTable<IOrderReminderItems>({
  //   apiCall: memoApiCall,
  // })

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  const getMissingItemsDetails = async () => {
    setLoading(true)
    try {
      const response = await getClientMissingItemList(
        Number(router?.match?.params?.id),
        value === 0 ? 'active' : 'completed',
        router?.match?.url?.includes('company')
          ? 'company'
          : router?.match?.url?.includes('taxOrder')
          ? 'tax_order'
          : 'company'
      )
      setDetails(response)
      setName(`
        ${
          response?.company_details?.name?.length
            ? response?.company_details?.name
            : response?.tax_order?.order_type === 1
            ? 'Personal'
            : response?.tax_order?.order_type === 2
            ? 'Business'
            : ''
        } ${!response?.company_details?.name?.length ? response?.tax_order?.service_type : ''} ${
        !response?.company_details?.name?.length ? 'Order' : ''
      }
      `)
      // console.log(response,"RESPONSEISHEREEEEEEEEEEEEEEE")
    } catch {
      console.log('error')
    } finally {
      setLoading(false)
    }
  }
  const reloadData = () => {
    getMissingItemsDetails()
  }

  useEffect(() => {
    getMissingItemsDetails()
  }, [value])

  return (
    <Box className={classes.missingItemDetails}>
      <h1 className="main-heading">{name ? name.toUpperCase() : ''}- Missing Items</h1>

      <Divider />
      <br />
      <HeadingHeader title="Item List" />
      <br />
      <TableLoadingIndicator isLoading={false} />
      <br />
      <Grid container>
        <Grid item lg={6} md={12}>
          <Tabs className="tabs" value={value} onChange={handleChange}>
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Submitted" {...a11yProps(1)} />
          </Tabs>
          <div className="tab-pannels">
            <TabPanel value={value} index={0}>
              {loading ? (
                [0, 1, 2].map((elem, index) => {
                  return (
                    <>
                      <Skeleton key={index} animation="wave" variant="text" />
                    </>
                  )
                })
              ) : details?.items?.length ? (
                details?.items?.map((element, index) => {
                  return (
                    <div>
                      <p className="ques-no">
                        <span>{index + 1} </span>of {details?.items?.length}
                      </p>
                      <Question
                        reloadData={reloadData}
                        attachments={element?.uploads}
                        status={element.status}
                        rejectedReason={element?.reject_reason}
                        answer={element?.item_response}
                        id={element?.id}
                        question={element?.item_description}
                      />
                    </div>
                  )
                })
              ) : (
                <p>No Missing Items Found</p>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {loading ? (
                [0, 1, 2].map((elem, index) => {
                  return (
                    <>
                      <Skeleton key={index} animation="wave" variant="text" />
                    </>
                  )
                })
              ) : details?.items?.length ? (
                details?.items?.map((element, index) => {
                  return (
                    <div>
                      <p className="ques-no">
                        <span>{index + 1} </span>of {details?.items?.length}
                      </p>
                      <Question
                        reloadData={reloadData}
                        attachments={element?.uploads}
                        status={element.status}
                        rejectedReason={element?.reject_reason}
                        answer={element?.item_response}
                        id={element?.id}
                        question={element?.item_description}
                      />
                    </div>
                  )
                })
              ) : (
                <p>No Missing Items Found</p>
              )}
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientMissingItemDetails
