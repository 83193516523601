import React, { useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { Edit, Message, NotificationsNoneOutlined } from '@material-ui/icons'
import CommentsComponent from './commentsComponent'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  addComplianceNote,
  complianceAssighUser,
  complianceCompanyClosed,
  complianceCompleteOrder,
  ComplianceTasksData,
  getStaffName,
  NotesData,
  StaffData,
} from 'api/complianceCalendar'
import { formatDateForInputField, formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import { CURRENT_USER_LOCAL_STORAGE_KEY } from 'hooks/useUser'
import showErrorNotification from 'utils/showErrorNotification'
import { COMPLIANCE_TASKS_STATUS } from 'utils/constant'

import useRouter from 'use-react-router'
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',

      '& .icon-wrapper': {
        background: '#eaecf0',
        padding: '0.5rem',
        borderRadius: '4px',
        marginRight: '1rem',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <FolderOpenIcon className="icon-wrapper" />
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

interface ViewMoreDialogProps {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  taskDetails?: ComplianceTasksData
  notes?: NotesData[]
  inProgressBtn?: boolean
  completeOrderHandler(id: any): void
  companyCloseHandler(id: any): void
  inProgressOrderHandler(id: any): void
}

const ViewMoreDialog = ({
  open,
  setOpen,
  taskDetails,
  notes,
  inProgressBtn,
  completeOrderHandler,
  companyCloseHandler,
  inProgressOrderHandler,
}: ViewMoreDialogProps) => {
  const useDiagloadStyles = makeStyles({
    dialogRoot: {
      fontFamily: 'Roboto',
      '& .MuiDialog-paper': {
        background: '#F4F5F7',
        padding: '1rem 0.5rem 2.5rem',

        '& .btn-sec': {
          display: 'flex',
        },
        '& .inProgress-btn': {
          background: '#41c576',
          color: '#fff',
          marginRight: '1rem',
        },
        '& .complete-btn': {
          background: '#307abc',
          color: '#fff',
        },
        '& .company-btn': {
          background: '#ff7171',
          color: '#fff',
          marginLeft: '1rem',
        },
      },
      '& .icon-wrapper': {
        background: '#eaecf0',
        padding: '0.5rem',
        borderRadius: '4px',
        marginRight: '1rem',
      },
      '& .inline-item': {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        marginRight: '1rem',
      },
      '& .inline': {
        display: 'flex',
        alignItems: 'center',

        '& p': {
          margin: 0,
          marginLeft: '0.5rem',
        },
      },

      '& .dark-text': {
        color: '#5f6368',
        fontSize: '14px',
        textTransform: 'capitalize',
        fontWeight: 500,

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // fontSize: '1rem',
        display: 'flex',
        flexWrap: 'wrap',
      },
      '& .light-text': {
        color: '#7b7b7b',
        fontSize: '14px',
        textTransform: 'capitalize',
        fontWeight: 500,
        paddingBottom: '0.5rem',
      },

      '& .company-details': {
        padding: '1rem 0.75rem',
        background: '#eaecf0',
        fontFamily: 'Roboto',
        paddingBottom: '4.5rem',

        '& p': {
          margin: '0',
        },

        '& .bottom-space': {
          marginBottom: '1.25rem',
        },

        '& .detail-btn': {
          background: '#7881909C',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 400,
          padding: '0.25rem 0.5rem',
        },
      },

      '& .description-area': {
        border: 'none',
        margin: '1.5rem 0 2rem 3.5rem',
        padding: '0.5rem',
        width: '85%',
        resize: 'none',
        outline: 'none',
        color: '#000',
        fontWeight: 500,
        fontFamily: 'Roboto',

        '&::placeholder': {
          color: '#000',
          fontWeight: 500,
        },
      },
    },
    formControl: {
      minWidth: 250,
      width: '100%',
      maxWidth: 250,

      '& .select-filed': {
        background: '#eaecf0',
        padding: '0.2rem 1rem',

        '& .MuiInputBase-root': {
          '&:before': {
            border: 'none',
          },

          '& .MuiInputAdornment-root': {
            marginLeft: '-12px',
          },
        },

        '&:hover': {
          border: 'none',
        },
        '&::before': {
          border: 'none',

          '&:hover': {
            border: 'none !important',
          },
        },
      },
    },
  })

  const handleClose = () => {
    setOpen(false)
  }
  const classes = useDiagloadStyles()

  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [noteText, setNoteText] = React.useState('')
  const [addNoteLoading, setAddNoteLoading] = React.useState(false)
  const [staffs, setStaffs] = React.useState<StaffData[]>([])

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const userDetails = JSON.parse(localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY) || '')
  const userId = userDetails?.extid

  const handleAddNote = async () => {
    setAddNoteLoading(true)
    const data = {
      note_text: noteText,
      company_id: taskDetails?.company_id,
      compliance_task_id: taskDetails?.id,
      created_by: userId,
    }
    if (data) {
      try {
        await addComplianceNote(data)
        setNoteText('')
        setAddNoteLoading(false)
      } catch (error) {
        showErrorNotification(error)
        setAddNoteLoading(false)
      }
    }
  }

  React.useEffect(() => {
    if (taskDetails?.due_date) {
      setSelectedDate((taskDetails?.due_date as unknown) as Date)
    }
  }, [])

  const getAllStaffName = async () => {
    try {
      const staff = await getStaffName()
      setStaffs(staff)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  React.useEffect(() => {
    getAllStaffName()
  }, [])

  // const completeOrderHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.completed,
  //   }
  //   try {
  //     const complateOrder = await complianceCompleteOrder(payload)
  //     return complateOrder
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }
  // const companyCloseHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.closed,
  //   }
  //   try {
  //     const companyClosed = await complianceCompanyClosed(payload)
  //     return companyClosed
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }

  // const inProgressOrderHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.inprogress,
  //   }
  //   try {
  //     const companyClosed = await complianceCompanyClosed(payload)
  //     return companyClosed
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }
  const [assigneMember, setAssigneMember] = useState()

  const assighHanlder = async e => {
    const { value } = e.target
    const payload = {
      compliance_task_id: taskDetails?.id,
      assigned_to: value,
    }
    try {
      const res = await complianceAssighUser(payload)
      setAssigneMember(value)
      return res
    } catch (error) {
      showErrorNotification(error)
    }
  }
  const { history } = useRouter()

  return (
    <Dialog maxWidth="md" fullWidth onClose={handleClose} open={open} className={classes.dialogRoot}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {taskDetails?.details?.company_details?.name} - {taskDetails?.details?.company_details?.state_of_formation}
        <br />
        <p style={{ fontSize: '12px', margin: '0' }}>
          {formatDateForInputField(taskDetails?.details?.company_details?.file_date || '')}
        </p>
      </DialogTitle>
      <DialogContent>
        <div className="btn-sec">
          {inProgressBtn ? (
            <Button
              variant="contained"
              color="primary"
              className="inProgress-btn"
              onClick={() => inProgressOrderHandler(taskDetails?.id)}
            >
              In Progress Order
            </Button>
          ) : (
            ''
          )}
          <Button
            variant="contained"
            color="primary"
            className="complete-btn"
            onClick={() => completeOrderHandler(taskDetails?.id)}
          >
            Complete
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="company-btn"
            onClick={() => companyCloseHandler(taskDetails?.id)}
          >
            Cancel
          </Button>
        </div>

        <Grid container style={{ marginTop: '2rem' }}>
          <Grid item xs={9}>
            <div className="inline-item">
              <div className="inline-item">
                <FileCopyOutlinedIcon className="icon-wrapper" />
                <FormControl className={classes.formControl}>
                  <InputLabel className="dark-text">Type</InputLabel>

                  <Select className="select-filed" defaultValue={10} disabled>
                    <MenuItem value={10}>{taskDetails?.title}</MenuItem>
                  </Select>
                  {/* <Select className="select-filed" defaultValue={10}>
                    <MenuItem value={10}>Process Order</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select> */}
                </FormControl>
              </div>
              <div className="inline-item">
                <FileCopyOutlinedIcon className="icon-wrapper" />
                <FormControl className={classes.formControl}>
                  <InputLabel className="dark-text">Order Type</InputLabel>

                  <Select className="select-filed" defaultValue={10} disabled>
                    <MenuItem value={10}>{taskDetails?.details?.order_type?.name}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <br />
            <div className="inline-item">
              <div className="inline-item">
                <NotificationsNoneOutlined className="icon-wrapper" />
                <FormControl className={classes.formControl}>
                  <InputLabel className="dark-text">Due Date</InputLabel>
                  <KeyboardDatePicker
                    className="select-filed"
                    format="MM/DD/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* <Select className="select-filed" defaultValue="Process Order">
                    <MenuItem value={10}>Process Order</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select> */}
                </FormControl>
              </div>
              <div className="inline-item">
                <FileCopyOutlinedIcon className="icon-wrapper" />
                <FormControl className={classes.formControl}>
                  <InputLabel className="dark-text">Assigh</InputLabel>
                  <Select
                    className="select-filed"
                    defaultValue={taskDetails?.assigned_to}
                    value={assigneMember}
                    onChange={assighHanlder}
                  >
                    {/* <MenuItem value={staffs[0]?.id}>{staffs[0]?.name}</MenuItem> */}
                    {staffs?.map(staff => (
                      <MenuItem value={staff?.id}>{staff?.name}</MenuItem>
                    ))}

                    {/* <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>

            <textarea rows={4} placeholder="Description..." className="description-area"></textarea>

            <div className="inline">
              <Message className="icon-wrapper" />
              <p className="dark-text">Activity</p>
            </div>
            <CommentsComponent
              placeholder="Write a comment"
              value={noteText}
              isLoading={addNoteLoading}
              onClick={handleAddNote}
              onChange={e => setNoteText(e.target.value)}
            />
            {notes?.map(note => (
              <CommentsComponent
                infoText={`${note?.created_by} ${formatDateTimeToHumanFriendly(note?.created_at || '')}`}
                value={note?.note_text}
                avatarText={(note?.created_by || '')?.charAt(1)?.toLocaleUpperCase()}
              />
            ))}
          </Grid>
          <Grid item xs={3} style={{ marginTop: '-2rem' }}>
            <div className="inline-item">
              <p className="dark-text">Information</p>
              <Edit style={{ marginBottom: '0.5rem' }} />
            </div>
            <div className="company-details">
              <div className="bottom-space">
                <p className="light-text">Primary Contact Name</p>
                <p className="dark-text">
                  {taskDetails?.details?.company_details?.primary_contact &&
                    taskDetails?.details?.company_details?.primary_contact[0]?.name}{' '}
                  {taskDetails?.details?.company_details?.client?.isdeleted ? (
                    <Typography
                      variant="caption"
                      display="block"
                      color="error"
                      align="right"
                      style={{ paddingLeft: '1rem' }}
                    >
                      (Client Deleted)
                    </Typography>
                  ) : (
                    ''
                  )}
                </p>
              </div>
              <div className="bottom-space">
                <p className="light-text">Phone Number</p>
                <p className="dark-text">
                  {taskDetails?.details?.company_details?.primary_contact &&
                    taskDetails?.details?.company_details?.primary_contact[0]?.phone_1}
                </p>
              </div>
              <div className="bottom-space">
                <p className="light-text">Email:</p>
                <p className="dark-text">
                  {taskDetails?.details?.company_details?.primary_contact &&
                    taskDetails?.details?.company_details?.primary_contact[0]?.email_1}
                </p>
              </div>
              <div className="bottom-space">
                <p className="light-text">Ownership Information</p>
                {taskDetails?.details?.company_details?.company_users?.map(user => (
                  <>
                    <p className="dark-text">
                      {user?.user?.name} <br /> {user?.number_of_shares}% <br />{' '}
                      {user?.roles?.map(role => (
                        <>
                          {role?.title}
                          <br />
                        </>
                      ))}
                    </p>
                    <br />
                  </>
                ))}
                {/* <p className="dark-text">
                  Alex Chernavsky <br /> 40% <br /> President <br /> Secretary
                </p>
                <br />
                <p className="dark-text">
                  Anna Chernavsky
                  <br /> 40% <br />
                  Director
                </p> */}
              </div>
              <div className="bottom-space">
                <p className="light-text">Registered Agent</p>
                <p className="dark-text">
                  {taskDetails?.details?.company_details?.company_users?.map(user =>
                    user?.roles?.map(role => (role?.title === 'Registered Agent' ? user?.user?.name : ''))
                  )}
                </p>
              </div>
              <div className="bottom-space">
                <p className="light-text">Business Address</p>
                <p className="dark-text">
                  {taskDetails?.details?.company_details?.primary_address?.zip}{' '}
                  {taskDetails?.details?.company_details?.primary_address?.street} <br />
                  {taskDetails?.details?.company_details?.primary_address?.locality}{' '}
                  {taskDetails?.details?.company_details?.primary_address?.postal_code}
                </p>
              </div>
              <Button
                variant="contained"
                className="detail-btn"
                onClick={() => history.push(`/company/${taskDetails?.details?.company_details?.id}`)}
              >
                Company Details
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default ViewMoreDialog
