import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as React from 'react'
import { formatDateTimeToHumanFriendly, getDueDateWithYear, getTimeFromNow } from 'utils/formatDate'
import { toTitleCase } from 'utils/toTitleCase'
import { ITaxOrder } from 'models/taxOrder'
import Chip from '@material-ui/core/Chip'
import chroma from 'chroma-js'
import { IconButton } from '@material-ui/core'
import EditIcon from 'components/common/icons/edit'

interface ITaxOrderTableRowProps {
  taxOrder: ITaxOrder
}

const getFilersName = (taxOrder: ITaxOrder) =>
  taxOrder.company
    ? taxOrder.company.name || ''
    : taxOrder.client
    ? (taxOrder.client?.first_name || '') +
      ' ' +
      (taxOrder.client?.middle_name || '') +
      ' ' +
      (taxOrder.client?.last_name || '')
    : ''

const AffiliatedTableRow: React.FC<ITaxOrderTableRowProps> = ({ taxOrder }) => {
  const setColor = (bgColor: string) => {
    if (!bgColor) return ''
    const diff = chroma.contrast(bgColor, 'white')

    if (diff > 2) {
      return 'white'
    } else return 'black'
  }
  return (
    <TableRow hover style={{ cursor: 'pointer' }}>
      <TableCell>{taxOrder.priority}</TableCell>
      <TableCell>{getFilersName(taxOrder)}</TableCell>
      <TableCell>
        {taxOrder?.tax_labels?.map((item, i: number) => (
          <Chip
            key={i}
            size="small"
            label={item.name}
            style={{ backgroundColor: item.color, color: setColor(item.color), marginTop: '2px' }}
          />
        ))}
      </TableCell>
      <TableCell>{taxOrder.tax_year}</TableCell>
      <TableCell>{toTitleCase(taxOrder.tax_order_type)}</TableCell>
      <TableCell>
        {taxOrder.accountant?.first_name} {taxOrder.accountant?.last_name}
      </TableCell>
      <TableCell>{taxOrder.tax_order_stage ? taxOrder.tax_order_stage.title : ''}</TableCell>
      <TableCell>{taxOrder.is_filed ? 'Filed' : 'Not Filed'}</TableCell>
      {/* relative datetime values for due date and last update */}
      <TableCell title={formatDateTimeToHumanFriendly(taxOrder.updated_at)} style={{ whiteSpace: 'nowrap' }}>
        {getTimeFromNow(taxOrder.updated_at)}
      </TableCell>
      <TableCell title={formatDateTimeToHumanFriendly(taxOrder.due_date)} style={{ whiteSpace: 'nowrap' }}>
        {getDueDateWithYear(taxOrder.due_date)}
      </TableCell>
      <TableCell>
        <IconButton style={{ marginRight: 10 }} onClick={() => window.open(`/tax-orders/${taxOrder.id}`, '_blank')}>
          <EditIcon data-testid="editButton" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default AffiliatedTableRow
