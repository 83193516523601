/* eslint-disable @typescript-eslint/no-empty-function */
import { ISuggestionAttribute } from 'components/interfaces'
import { InitialSearchParams, TaxOrderSearchParams } from 'components/taxOrders/search'
import { ITaxOrderType } from 'models/taxOrder'
import * as React from 'react'
import { ValueType } from 'react-select/src/types'

/*
 ** Here define a context to initialize it in the top component (src/components/taxOrders/index.tsx)
 ** and make it available for all children
 */

interface TaxOrderContextValue {
  searchOptions: TaxOrderSearchParams
  handleSortingClick?: (sortBy: string) => void
  handleAddFilter: (field: string, select: ValueType<ISuggestionAttribute>) => void
  handleSearch: (query: string) => void
  handleFiledOnlyFilter: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => void
  handleOrderTypeFilter: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: ITaxOrderType | string) => void
  reloadTable: () => void
  handleTaxOrderStatusOnlyFilter: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => void
}

export const TaxOrderContext = React.createContext<TaxOrderContextValue>({
  searchOptions: InitialSearchParams,
  handleSortingClick: () => {},
  handleAddFilter: () => {},
  handleSearch: () => {},
  handleFiledOnlyFilter: () => {},
  handleOrderTypeFilter: () => {},
  reloadTable: () => {},
  handleTaxOrderStatusOnlyFilter: () => {},
})
