import React, { useMemo } from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Table, Theme, TableBody, TableRow } from '@material-ui/core'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { skeletons } from 'components/common/table'
import { getClientsByUserIds, getOrdersByIds } from 'api/kpiDashboard'
import { removeNullAndUndefined } from 'utils/objectUtils'
import KpiDetailTableHead from './head'
import KpiDetailTableRow from './row'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import useRouter from 'use-react-router'

const DetailsTable = ({
  ids,
  type,
  apiFunction,
  from_email = false,
  offer_id,
  startDate,
  endDate,
  offer_taken_from,
}) => {
  const classes = useStyles()
  const { history } = useRouter()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const tableHead = useMemo(
    () => (
      <KpiDetailTableHead
        type={type}
        sortSettings={sortSettings}
        handleSortingClick={handleSortingClick}
        special_offer={!!offer_id}
      />
    ),
    [sortSettings, handleSortingClick]
  )
  const [searchOptions, setSearchOptions] = React.useState<any>({
    user_id: undefined,
    order_ids: undefined,
    company_ids: undefined,
    special_offer: undefined,
    offer_id: undefined,
    start_date: undefined,
    end_date: undefined,
  })

  React.useEffect(() => {
    switch (type) {
      case KPI_ENTITY_TYPES.client:
        setSearchOptions({ user_id: ids?.toString() || '' })
        break
      case KPI_ENTITY_TYPES.order:
        setSearchOptions({ order_ids: ids?.toString() || '' })
        break
      case KPI_ENTITY_TYPES.company:
        setSearchOptions({
          company_ids: ids?.toString() || '',
          from_email,
          offer_id,
          start_date: encodeURIComponent(startDate),
          end_date: encodeURIComponent(endDate),
          offer_taken_from,
        })
        break
      default:
        break
    }
  }, [type, ids, startDate, endDate])
  const memoApiCall = useMemo(() => apiFunction(removeNullAndUndefined(searchOptions)), [searchOptions, ids])

  const pagedTable = usePagedTable<any>({
    apiCall: memoApiCall,
    queryParams: searchOptions,
  })

  const handleEditClick = React.useCallback(
    (data, type) => {
      switch (type) {
        case KPI_ENTITY_TYPES.client:
          history.push(`/client/${data.id}`)
          break
        case KPI_ENTITY_TYPES.company:
          history.push(`/company/${data.id}`)
          break
        case KPI_ENTITY_TYPES.order:
          history.push(`/company-order/${data.id}`)
          break
        default:
          break
      }
    },
    [type]
  )

  return (
    <div className={classes.container}>
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 3, sizes: [120, 200, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Data"
          {...pagedTable.renderProps}
          renderRow={(data: any) => (
            <KpiDetailTableRow
              rowData={data}
              onEditClick={() => handleEditClick(data, type)}
              type={type}
              special_offer={!!offer_id}
            />
          )}
          emptyRowComponent={
            <TableBody>
              <TableRow> {skeletons({ numberOfColumns: 1, sizes: [200], numberOfRows: 6 })}</TableRow>
            </TableBody>
          }
          header={tableHead}
        />
      )}
    </div>
  )
}

export default DetailsTable

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
