import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationSection: {
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2.5rem',
        fontFamily: theme.typography.h1.fontFamily,
        marginBottom: '1.3rem',
      },
      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      '& p': {
        margin: '0',
      },

      '& .notification-text': {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        letterSpacing: '0.09px',
        fontSize: 18,
        marginBottom: '1rem',
      },
      '& .table-text': {
        fontSize: '2rem',
        fontFamily: 'SFCompactDisplay',
        fontWeight: 600,
      },
    },

    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    headerSec: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)
