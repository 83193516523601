import { searchClients } from 'api/clients'
import { fetchStaff } from 'api/staff'
import CountryList from 'country-list'
import { useResources } from 'hooks/useResources'
import { legalTypesList, IDetailedCompanyResponse } from 'models/company'
import * as React from 'react'
import {
  AutocompleteField,
  DateField,
  DateTimeField,
  IRow,
  NumberField,
  TextField,
  CustomComponent,
  AddressField,
} from 'utils/FormGenerator/formTypings'
import usaStates from 'utils/usaStates'
import CompanyOwners from 'components/companies/companyOwners/companyOwners'
import { getCompanyAddresses, toggleCompanyStatus } from 'api/companies'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import { TaxStructureBusinessOptions } from 'models/taxOrder'
import { EMPLOYEE_ROLES, LEGALTYPES, USERTYPES } from 'utils/constant'
import Switch from '@material-ui/core/Switch'
import { Dialog, DialogContent, DialogTitle, makeStyles, DialogActions, Button } from '@material-ui/core'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { TextField as TextFieldComponent } from '@material-ui/core'
import useUser from 'hooks/useUser'
import TextFieldMui from '@material-ui/core/TextField'
import { IPagedTableHook } from '@jetkit/react'
import { INote } from 'models/note'
import SalesRep from 'components/orders/SalesRep'
import { ISubscriptionsProps, cancelSubscriptionArr } from 'api/subscription'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import showErrorNotification from 'utils/showErrorNotification'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', minWidth: 90, sortable: false },
  {
    field: 'name',
    headerName: 'Subscription Name',
    minWidth: 250,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'Price',
    minWidth: 125,
    sortable: false,
  },
]

interface IUseFields {
  fields: IRow[]
  // pagedTable: IPagedTableHook<INote>
}
export const useStyles = makeStyles({
  toggleInput: {
    fontSize: '23px',
    color: 'black',

    width: '109%',
  },
  salesRep: {
    marginLeft: '6%',
    width: '88%',
  },
})
export const useCompanyDetailsFormFields = (
  company: IDetailedCompanyResponse,
  pagedTable: IPagedTableHook<INote>
): IUseFields => {
  // console.log('companyform', company)
  const { user } = useUser()

  const classes = useStyles()
  // fetch options for autocomplete
  const users = useResources(searchClients)
  const staff = useResources(fetchStaff)
  const addresses = useResources(getCompanyAddresses, company.id)
  const [businessActive, setBusinessActive] = React.useState<boolean>(company.business_active || false)
  const [businessActivity, setBusinessActivity] = React.useState<string | undefined>(undefined)

  const [companySub, setcompanySub] = React.useState(false)
  const [subscriptionDetails, setSubscriptionDetails] = React.useState<ISubscriptionsProps[]>([])

  const [selectedRowIds, setSelectedRowIds] = React.useState([])
  const [newRow, setNewRow] = React.useState<any[]>([])

  const handleSelectionModelChange = selectionModel => {
    setSelectedRowIds(selectionModel)
  }

  React.useEffect(() => {
    if (subscriptionDetails?.length) {
      const row = subscriptionDetails?.map((item: ISubscriptionsProps) => {
        return {
          id: item?.id,
          name: item?.product?.name,
          amount: item?.price?.amount,
        }
      })
      setNewRow(row)
    }
  }, [subscriptionDetails, companySub])

  // example of field with unstable type
  const stateField = React.useMemo(
    () =>
      company.country_of_formation === UNITED_STATES
        ? new AutocompleteField({ label: 'state', name: 'state_of_formation', options: usaStates })
        : new TextField({ label: 'state', name: 'state_of_formation' }),
    [company]
  )
  const taxStructureSelectOptions = React.useMemo(() => Object.values(TaxStructureBusinessOptions), [])
  const taxStructureSelectValue = React.useMemo(
    () =>
      // defining label for chosen option (tax structure) for OutlinedSelect element
      TaxStructureBusinessOptions[company.tax_structure],
    []
  )
  const [lastRowArray, setLastRowArray] = React.useState<any>([
    new AutocompleteField({
      label: 'Tax structure',
      name: 'tax_structure',
      options: taxStructureSelectOptions,
    }),
  ])

  const handleBusinessActiveChange = React.useCallback(async (value: boolean) => {
    setBusinessActive(value)

    try {
      const result = await toggleCompanyStatus(company.id, value)
      if (result?.data?.activeSubscriptions) {
        setSubscriptionDetails(result?.data?.activeSubscriptions)
        setcompanySub(true)
      } else {
        setcompanySub(false)
      }
      showSuccessNotification('Updated Successfully')
      pagedTable.reloadData()
    } catch (error) {
      showApiResponseError(error, 'Error Updating Status')
    }
  }, [])

  React.useEffect(() => {
    // show shares field if company type not llc
    if (company.company_type !== LEGALTYPES.llc && company.company_type !== LEGALTYPES.non_profit) {
      setLastRowArray([...lastRowArray, new NumberField({ label: 'Number of Shares', name: 'number_of_shares' })])
    }
  }, [])
  const einAndStateIdFields = () => {
    // if (user?.employee_role === EMPLOYEE_ROLES.junior) {
    //   return [
    //     // new CustomComponent(() => (
    //     //   <TextFieldMui
    //     //     id="filled-basic"
    //     //     label="Ein"
    //     //     style={{ backgroundColor: 'white' }}
    //     //     variant="filled"
    //     //     value={company.ein}
    //     //   />
    //     // )),
    //     new TextField({
    //       label: 'state id',
    //       name: 'state_id',
    //       disabled: true,
    //       // customComponent: (
    //       //   <>
    //       //     <input disabled></input>
    //       //   </>
    //       // ),
    //     }),
    //   ]
    // } else {
    //Ein is visible to All now 16 dec 2022
    return [new TextField({ label: 'ein', name: 'ein' }), new TextField({ label: 'state id', name: 'state_id' })]
    // }
  }
  const salesRepComponent = () => {
    if (company && company.salesRep?.id) {
      return [
        new CustomComponent(() => (
          <SalesRep salesRep={company && company.salesRep} classes={{ root: classes.salesRep }} />
        )),
      ]
    } else {
      return []
    }
  }

  const handleCancelSub = async () => {
    const payload = {
      subscription_ids: selectedRowIds,
    }
    try {
      await cancelSubscriptionArr(payload)
      showSuccessNotification('Successfully Cancel Subscriptions')
      // const result = await toggleCompanyStatus(company.id, businessActive)
      // if (result?.data?.activeSubscriptions) {
      //   setSubscriptionDetails(result?.data?.activeSubscriptions)
      // }
      setcompanySub(false)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  console.log('selectedRowIds selectedRowIds', selectedRowIds)

  // array fieldsDetails
  const fields = React.useMemo(
    (): IRow[] => [
      {
        fields: salesRepComponent(),
      },
      {
        fields: [
          new AutocompleteField({
            name: 'client',
            label: 'client',
            options: users,
            getOptionLabel: (client: any) => client.name,
          }),
          new AutocompleteField({ label: 'country', name: 'country_of_formation', options: CountryList.getNames() }),
        ],
      },
      {
        fields: [
          stateField,
          new AutocompleteField({ label: 'company type', name: 'legal_type', options: legalTypesList }),
        ],
      },

      {
        fields: [
          new AddressField({
            label: 'Company address',
            name: 'primary_address',
            options: addresses,
            addressType: 'contact',
            entity_id: company.id,
          }),
          new AddressField({
            label: 'Mailing address',
            name: 'mailing_address',
            options: addresses,
            addressType: 'mailing',
            entity_id: company.id,
          }),
        ],
      },
      {
        fields: [
          new TextField({ label: 'business activity', name: 'business_activity', multiline: true, row: 4 }),
          new AutocompleteField({
            label: 'incorporator',
            name: 'incorporator',
            options: staff.map(user => user.name),
            freeSolo: true,
          }),
        ],
      },
      {
        fields: [
          new DateField({ label: 'file date ', name: 'file_date' }),
          new DateField({ label: 'meeting date', name: 'meeting_date' }),
        ],
      },
      {
        fields: einAndStateIdFields(),
      },
      {
        fields: [
          new DateTimeField({ label: 'director meeting date', name: 'director_meeting.date' }),
          new DateTimeField({ label: 'shareholder meeting date', name: 'shareholder_meeting.date' }),
        ],
      },
      {
        fields: [
          new AddressField({
            label: 'director meeting address',
            name: 'director_meeting.address',
            options: addresses,
            // addressType: 'meeting',
            addressType: 'directors_meeting',
            entity_id: company.id,
          }),
          new AddressField({
            label: 'shareholder meeting address',
            name: 'shareholder_meeting.address',
            options: addresses,
            // addressType: 'meeting',
            addressType: 'shareholders_meeting',
            entity_id: company.id,
          }),
        ],
      },
      //
      {
        fields: lastRowArray,
      },

      //

      {
        fields: [
          new CustomComponent(() => (
            <span className={classes.toggleInput}>
              Business Active
              <Switch
                checked={businessActive}
                onChange={e => {
                  handleBusinessActiveChange(e.target.checked)
                }}
                name="checkedA"
                size="medium"
                inputProps={{ 'aria-label': 'business Active' }}
              />
            </span>
          )),
        ],
      },

      {
        fields: [
          new CustomComponent(() =>
            subscriptionDetails?.length ? (
              <Dialog open={companySub} fullWidth maxWidth="sm">
                <DialogTitle>Company Subscription</DialogTitle>
                <DialogContent>
                  <div style={{ height: 300, width: 'auto' }}>
                    <DataGrid
                      rows={newRow}
                      columns={columns}
                      pageSize={5}
                      checkboxSelection={true}
                      disableSelectionOnClick
                      onSelectionModelChange={handleSelectionModelChange}
                      selectionModel={selectedRowIds} // Pass the selectedRowIds to maintain selection
                      disableColumnFilter
                      disableColumnMenu
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ color: '#fff', height: '50px' }}
                    onClick={() => setcompanySub(false)}
                    color="secondary"
                    variant="contained"
                  >
                    Close
                  </Button>
                  <ButtonWithLoading onClick={handleCancelSub}>Cancel Subscription</ButtonWithLoading>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )
          ),
        ],
      },

      {
        fields: [new CustomComponent(() => <CompanyOwners company={company} />)],
      },
    ],
    [
      salesRepComponent,
      users,
      stateField,
      addresses,
      company,
      staff,
      lastRowArray,
      classes.toggleInput,
      businessActive,
      handleBusinessActiveChange,
      subscriptionDetails?.length,
      companySub,
      newRow,
      selectedRowIds,
    ]
  )

  return { fields }
}
