import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'
import purple from '@material-ui/core/colors/purple'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { OpenInNew } from '@material-ui/icons'
import { deleteOrder } from 'api/orders'
import classnames from 'classnames'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import CompanyOrderConfigDialog from 'components/tasks/companyOrderConfigDialog'
import { useHandleDialogAccessibility } from 'hooks/useHandleDialogAccessibility'
import { IOrder } from 'models/order'
import * as React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { COLORS, PAYMENT_STATUS } from 'utils/constant'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly, getDueDate, getTimeFromNow } from 'utils/formatDate'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'

interface IOrderTableRowProps {
  order: IOrder
  searchQuery?: string
  reloadData?: () => void
  onEdit: (orderId: number) => void
  isCompanyCreationFlow?: boolean
  disableActionButtons?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingRight: 8,
    },
    actionsCell: { display: 'table-cell', height: '100%', minWidth: 50 },
    row: {
      cursor: 'pointer',
    },
    rowWithoutSalesRep: {
      // backgroundColor: blue[50],
      '&:hover': {
        backgroundColor: blue[100],
      },
    },
    rowWithSalesRep: {
      backgroundColor: purple[200],
      '&:hover': {
        backgroundColor: purple[200],
      },
    },
    cellNoWrap: {
      whiteSpace: 'nowrap',
    },
    buttonsContainer: {
      display: 'flex',
    },
    actionButton: {
      width: 40,
      height: 40,
      '& span': {
        fontSize: '2rem',
      },
    },
  })
)

const OrderTableRow: React.FC<IOrderTableRowProps> = ({
  order,
  reloadData,
  onEdit,
  isCompanyCreationFlow,
  disableActionButtons,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
  const { isOpen, handleOpen, handleSave, handleClose } = useHandleDialogAccessibility({
    saveSideEffect: () => reloadData && reloadData(),
  })

  const handleOrderDelete = React.useCallback(
    async (orderId: number) => {
      try {
        await deleteOrder(orderId)
        reloadData && reloadData()
        setShowDeleteDialog(false)
        showSuccessNotification('Order deleted')
      } catch (err) {
        showApiResponseError(err, 'Failed to delete order')
      }
    },
    [reloadData, setShowDeleteDialog]
  )

  const handleClick = React.useCallback(
    (orderId: number) => {
      history.push(`/company-order/${orderId}`)
    },
    [history]
  )

  const rowClassNames = classnames(classes.row, order.sales_rep ? classes.rowWithSalesRep : classes.rowWithoutSalesRep)

  const onClick = React.useCallback(() => handleClick(order.id), [handleClick, order.id])
  const setBgColor = () => {
    let color = ''

    if (order.is_rush_order) {
      color = COLORS.rushOrders
    }
    if (order.internal_rush_processing) {
      color = COLORS.internalRushProcessing
    }
    return color
  }
  return (
    /* For company name and sales rep name we check if there is a search query.
     ** If so, we create elements with highlighted parts that match search query.
     ** Otherwise, we use regular strings.
     */
    <React.Fragment>
      <DeleteConfirmationDialog
        itemName={
          <React.Fragment>
            {order.order_type.name} <br /> For <br /> {order.company.name}
          </React.Fragment>
        }
        onClose={() => {
          setShowDeleteDialog(false)
        }}
        open={showDeleteDialog}
        onDelete={() => handleOrderDelete(order.id)}
      />
      <CompanyOrderConfigDialog onClose={handleClose} onSave={handleSave} companyOrderId={order.id} open={isOpen} />
      <TableRow
        style={{ backgroundColor: setBgColor() /*order.is_rush_order ? COLORS.rushOrders : undefined*/ }}
        // onClick={onClick}
        className={rowClassNames}
        data-testid="company-order-row"
      >
        <TableCell className={classes.cell} style={{ textTransform: 'uppercase' }}>
          {order.id}
        </TableCell>
        <TableCell className={classes.cell} style={{ textTransform: 'uppercase' }}>
          {order.order_type.name}
        </TableCell>

        {!isCompanyCreationFlow && (
          <TableCell className={classes.cell}>
            {order.company.name}
            <br />
            <i style={{ color: '#5c5c5c', fontSize: '12px' }}>{order.company.state_of_formation}</i>{' '}
          </TableCell>
        )}
        {/* {!isCompanyCreationFlow && <TableCell>{order.sales_rep ? order.sales_rep.name : ''}</TableCell>} */}
        <TableCell
          className={classes.cell}
          style={{ color: order.payment_status === PAYMENT_STATUS.paid ? 'green' : 'red' }}
        >
          {!!order.price &&
            `$${order.price.toLocaleString('en-US', {
              style: 'decimal',
              useGrouping: true,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}`}
        </TableCell>
        {!isCompanyCreationFlow && (
          <TableCell title={formatDateTimeToHumanFriendly(order?.creation_time || '')} className={classes.cellNoWrap}>
            {/* {getDueDate(order?.creation_time || '')} */}
            {order?.creation_time ? formatDateToHumanFriendly(order?.creation_time || '') : ''}
          </TableCell>
        )}
        <TableCell>
          <div
            style={{
              backgroundColor: order.order_stage?.stage_color,
              borderRadius: 4,
              padding: 8,
              textAlign: 'center',
            }}
          >
            {order.order_stage?.title}
          </div>
        </TableCell>
        {/* relative datetime values for due date and last update */}
        <TableCell title={formatDateTimeToHumanFriendly(order.last_update)} className={classes.cellNoWrap}>
          {getTimeFromNow(order.last_update)}
        </TableCell>
        <TableCell title={formatDateTimeToHumanFriendly(order.due_date)} className={classes.cellNoWrap}>
          {getDueDate(order.due_date)}
        </TableCell>
        {!disableActionButtons && (
          <TableCell className={classes.actionsCell}>
            <div className={classes.buttonsContainer}>
              <TableActionButton className={classes.actionButton} style={{ marginRight: 10 }}>
                <Link to={`/company-order/${order.id}`} target="_blank" rel="noreferrer">
                  <OpenInNew data-testid="newTab" color="primary" />
                </Link>
              </TableActionButton>
              <TableActionButton
                onClick={
                  //!isCompanyCreationFlow?
                  () => onEdit(order.id)
                  // : e => {
                  //     e.stopPropagation()
                  //     handleOpen()
                  //   }
                }
                className={classes.actionButton}
                style={{ marginRight: 10 }}
              >
                <EditIcon data-testid="editButton" />
              </TableActionButton>
              {!isCompanyCreationFlow && (
                <TableActionButton
                  onClick={event => {
                    event.stopPropagation()
                    setShowDeleteDialog(true)
                  }}
                  className={classes.actionButton}
                >
                  <DeleteIcon />
                </TableActionButton>
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  )
}

export default OrderTableRow
