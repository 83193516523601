import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React from 'react'
import Business from '../img/business.svg'
import { formatDateToHumanFriendlyWithComa } from 'utils/formatDate'
import { Alert } from '@material-ui/lab'

const LicensesPermitAccordion = ({ clientLicenses }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  console.log('clientLicenses', clientLicenses)

  return (
    <Grid container spacing={3} className="licenses-permit-accordion-container">
      {clientLicenses?.length > 0 ? (
        clientLicenses?.map((item: any, key: number) => (
          // item?.order_type?.name !== 'Business Formation' &&
          <Grid item xs={12} sm={6} lg={4}>
            <Accordion expanded={expanded === `panel${key + 1}`} onChange={handleChange(`panel${key + 1}`)}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="accordion-summary"
              >
                <img src={Business} alt="icon" className="accordion-icon" />
                <p className="accordion-heading">{item?.order_type?.name}</p>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                {/* <div className="bottom-space">
                <p className="light-text">Document Type</p>
                <p className="dark-text">California Seller&apos;s Permit</p>
              </div> */}
                <div className="bottom-space">
                  <p className="light-text">Status</p>
                  <p className="dark-text"> {item?.order_stage?.title}</p>
                </div>
                <div className="bottom-space">
                  <p className="light-text">Date Complited</p>
                  <p className="dark-text">
                    {item?.completed_on ? formatDateToHumanFriendlyWithComa(item?.completed_on || '') : 'Not Completed'}
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Alert severity="info">No Licenses Found!</Alert>
        </Grid>
      )}
    </Grid>
  )
}

export default LicensesPermitAccordion
