import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

interface CommissionTableHeadProps {
  onAddTransaction: () => void
}

const CommissionTableHead: React.FC<CommissionTableHeadProps> = ({ onAddTransaction }) => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>Reference</StyledTableCell>
        <StyledTableCell>Amount</StyledTableCell>
        <StyledTableCell>Type</StyledTableCell>
        <StyledTableCell>T. Date</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default CommissionTableHead
