import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { EditorApiKey } from 'utils/constant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      marginTop: '2rem',
    },
  })
)

const TextEditor = ({ contentText, setContentText, height = '20rem' }) => {
  const classes = useStyles()

  return (
    <div className={classes.editor}>
      <Editor
        apiKey={EditorApiKey}
        init={{
          plugins: [
            'advlist autolink lists link charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          width: '100%',
          height: height,
          valid_children: '+body[style]',
          valid_styles: { '*': '*' },
          init_instance_callback: function(editor) {
            editor.on('click', function(e) {
              if (e.target.nodeName === 'INPUT') {
                if (e.target.getAttribute('checked')) {
                  e.target.removeAttribute('checked')
                } else {
                  e.target.setAttribute('checked', 'true')
                }
              }
            })
          },
        }}
        onEditorChange={setContentText}
        initialValue={''}
        value={contentText}
      />
    </div>
  )
}

export default TextEditor
