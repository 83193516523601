import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Header from './Header'
import CommonInput from './commonInput'
import NotificationsIcon from '@material-ui/icons/Notifications'
import CreateIcon from '@material-ui/icons/Create'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Tabs,
  Tab,
  Fab,
  Badge,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core'
import { TabPanel, TabContext, TabList } from '@material-ui/lab'
import AddressInput from './addresInput'
import NotificationBox from './NotificationBox'
import Dropdown from './Dropdown'
import AddTask from './AddTask'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import AutoSchedule from './AutoSchedule'
import InputField from 'components/leadManagerElements/inputField'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { Alert, Skeleton } from '@material-ui/lab'
import Addresses from 'components/common/addresses'
import {
  addProspectNote,
  changeProspectStatus,
  getIndustryType,
  getProspectNotesById,
  getProspectReminderSchedules,
  getProspectStatuses,
  getProspectTaskById,
  IndustryProspectData,
  markAllProspectAsReadById,
  OrderReminderSchedulesData,
  pauseNotifications,
  ProspectData,
  sendProspectSms,
  updateProspectAddressById,
  updateProspectById,
} from 'api/leadManager'
import showErrorNotification from 'utils/showErrorNotification'
import moment from 'moment'
import showSuccessNotification from 'utils/showSuccessNotification'
import { ISuggestionAttribute } from 'components/interfaces'
import { fetchAssigneeSuggestions, fetchSuggestions } from 'api/suggestions'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import { IAddress } from 'models/address'
import { emptyAddress } from 'components/leadManagerElements/addProspectModal'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import CreateTaskDialog from '../../../../components/tasks/create/createDialog'
import AddTaskDialog from 'components/orders/addTaskDialog'
import AddProspectTask from 'components/leadManagerElements/addProspectTask'
import { PROSPECT_STATUSES, USERTYPES, USER_TYPES } from 'utils/constant'
import { createClient, fetchClientByRefcode } from 'api/clients'
import CreateClient from '../../../clients/create'
import PhoneInput from 'components/clients/create/phoneInput'
import SubmissionHistory from './submissionHistory'
import {
  Close,
  Create,
  CreateOutlined,
  DoneAll,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Markunread,
  Subject,
} from '@material-ui/icons'
import { useStyles } from './styles'
import useDeleteTask from 'hooks/useDeleteTask'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import { TasksContext } from 'contexts/tasks'
import TaskDetailsElement from 'components/tasks/taskRowDetails/element'
import TableActionButton from 'components/common/buttons/tableActionButton'
import CollapseIcon from 'components/common/icons/collapse'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import ExpandIcon from 'components/common/icons/expand'
import { default as classnames, default as classNames } from 'classnames'
import CircleCheckbox from 'components/common/elements/CircleCheckbox'
import useTaskComplete from 'hooks/useTaskComplete'
import { ITaskDescription, deleteTask, getTaskDescriptions, markTaskAsDone, markTaskAsNotDone } from 'api/task'
import showApiResponseError from 'utils/showApiResponseError'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'
import { IClient, ICreatedClient } from 'models/user'
import { updatePageVisitsCount } from 'utils/user'
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused'
import ModalTitle from 'components/common/modalTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useStyles as useStyles2 } from 'components/common/assetUpload/uploadModalStyle'
import copyToClipboard from 'utils/copyToClipboard'
import ChatBox from './chatBox'
import NoteBox from './noteBox'
import UpcomingNotificationBox from './upcomingNotificationBox'
import useUser from 'hooks/useUser'
import useRouter from 'use-react-router'
import SelectField from 'components/common/select'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '& .icon-cell': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .edit-icon': {
      color: '#3c9fdf',
      cursor: 'pointer',
      borderBottom: '1px solid #3c9fdf',
    },
    '& .description-text': {
      fontWeight: 400,
    },
  },
  tableInnerItem: {
    '& p': {
      fontSize: '14px',
    },
  },
  detailsRow: {
    borderTop: '1px solid #E0E0E0',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionButton: {
    width: 32,
    height: 32,

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  secondActionButton: { marginLeft: 10, marginRight: 10 },
  taskPriorityCell: {
    textTransform: 'capitalize',
    color: '#020202',
    fontWeight: 'bold',
  },
  prevNotes: {
    '& .MuiListSubheader-root': {
      lineHeight: 'initial',
      marginBottom: '1rem',
      color: '#2a2a2a',
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    '& li': {
      padding: 0,
      marginBottom: 0,

      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '0.5rem',
        marginTop: '0.25rem',

        '& svg': {
          fontSize: '1rem',
        },
      },
      '& .MuiListItemText-root': {
        margin: 0,

        '& > p': {
          fontSize: '0.75rem',
        },
      },
    },
    '& .MuiDivider-root': {
      margin: '0.5rem 0',
    },
    // marginBottom: '1rem',
  },
})

function Row(props) {
  const { row, onEditClick, onDeleteClick, onCompleteTaskClick } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const [taskDescription, settaskDescription] = React.useState<ITaskDescription[]>([])

  React.useEffect(() => {
    const getDescription = async (id: number) => {
      try {
        const res: ITaskDescription[] = await getTaskDescriptions(id)
        settaskDescription(res)
        console.log('resresres', res)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (row?.id) {
      getDescription(row?.id)
    }
  }, [row?.id])
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          <CircleCheckbox
            dataTestId={`task-${row?.id}-checkbox`}
            onChange={(e: any) => onCompleteTaskClick(e, row)}
            checked={!!row?.completed}
          />
          {row?.title}
        </TableCell>

        <TableCell align="right">
          <div className={classes.iconsContainer}>
            <TableActionButton
              onClick={(e: any) => onEditClick(e, row)}
              className={classes.actionButton}
              data-testid={`edit-task-${row.id}`}
            >
              <EditIcon />
            </TableActionButton>

            <TableActionButton
              onClick={(e: any) => onDeleteClick(e, row)}
              className={classnames(classes.actionButton, classes.secondActionButton)}
              data-testid={`remove-task-${row.id}`}
            >
              <DeleteIcon />
            </TableActionButton>

            <TableActionButton
              onClick={() => setOpen(!open)}
              className={classes.actionButton}
              data-testid={`expand-task-${row.id}`}
            >
              {open ? <CollapseIcon /> : <ExpandIcon />}
            </TableActionButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="task-details">
              <TableRow className={classes.detailsRow}>
                <TableCell width="200px">
                  {/* <TaskDetailsElement title="Description" text={row?.description} className={classes.tableInnerItem} /> */}
                  {taskDescription?.length ? (
                    <List
                      className={classes.prevNotes}
                      subheader={<ListSubheader component="div">Notes History</ListSubheader>}
                    >
                      {taskDescription?.map((item: ITaskDescription) => (
                        <>
                          <ListItem alignItems="flex-start" key={item?.id}>
                            <ListItemIcon>
                              <Subject />
                            </ListItemIcon>
                            <ListItemText
                              primary={item?.note_text}
                              secondary={
                                <React.Fragment>
                                  {item?.created_by}&nbsp;
                                  {item?.created_at ? formatDateTimeToHumanFriendly(item?.created_at) : ''}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider component="li" />
                        </>
                      ))}
                    </List>
                  ) : (
                    ''
                  )}{' '}
                </TableCell>
                <TableCell width="200px" />
              </TableRow>

              <TableRow>
                <TableCell width="200px">
                  <TaskDetailsElement
                    title="Assigned To"
                    text={row?.assignee?.name}
                    className={classes.tableInnerItem}
                  />
                </TableCell>
                <TableCell width="200px">
                  <TaskDetailsElement
                    title="Due Date"
                    text={row?.due_date && formatDateToHumanFriendly(row?.due_date)}
                    className={classes.tableInnerItem}
                  />
                </TableCell>
                <TableCell width="200px">
                  <TaskDetailsElement title="Assigned By" text={row?.assigned_by} className={classes.tableInnerItem} />
                </TableCell>
              </TableRow>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

interface ProspectListProps {
  prospect: ProspectData | null
  isLoading: boolean
}
export interface NotesData {
  id?: number
  note_text?: string
  emp_id?: number
  prospect_id?: number
  created_by?: string
  created_on?: string
  creator?: string
  read?: boolean
  sent_notification_id?: number
}

const ProspectList = ({ prospect, isLoading }: ProspectListProps) => {
  const classes = useStyles()
  const { user } = useUser()

  const [isAutoSchedules, setIsAutoSchedules] = React.useState(false)
  const [orderReminders, setOrderReminders] = React.useState<OrderReminderSchedulesData[]>([])
  const [orderReminderLoading, setOrderReminderLoading] = React.useState(false)

  const [firstName, setFirstName] = React.useState('')
  const [prospectName, setProspectName] = React.useState('')
  const [middleName, setMiddleName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [submissionId, setSubmissionId] = React.useState('')
  const [invoicePaymentLink, setInvoicePaymentLink] = React.useState('')

  const [noteText, setNoteText] = React.useState('')
  const [notes, setNotes] = React.useState<NotesData[]>([])
  const [isGetNoteLoading, setIsGetNoteLoading] = React.useState(false)
  const [isNoteLoading, setIsNoteLoading] = React.useState(false)

  const [statusValue, setStatusValue] = React.useState<number>(0)
  const [industryValue, setIndustryValue] = React.useState<number | string>(0)
  const [pocValue, setPOCValue] = React.useState<number>(0)

  const [senderOptions, setSenderOptions] = React.useState<ISuggestionAttribute[]>([])
  const [prospectStatusList, setProspectStatusList] = React.useState<IndustryProspectData[]>([])
  const [industryTypesList, setIndustryTypesList] = React.useState<IndustryProspectData[]>([])

  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [prospectAddress, setProspectAddress] = React.useState<IAddress[]>([])

  const [isProspectUpdateLoading, setIsProspectUpdateLoading] = React.useState(false)
  const [isProspectTransferLoading, setIsProspectTransferLoading] = React.useState(false)

  const [isProspectTransfer, setIsProspectTransfer] = React.useState(false)

  const [addTaskModal, setAddTaskModal] = React.useState(false)
  const [historyPopUpOpen, setHistoryPopUpOpen] = React.useState(false)

  const [isMarkAllRead, setIsMarkAllRead] = React.useState(false)
  const [affiliate, setAffiliate] = React.useState<ICreatedClient>({
    email: '',
    name: '',
    id: 0,
    user_type: USERTYPES.client,
  })
  const [smsText, setSmsText] = React.useState('')
  const [smsSendLoading, setsmsSendLoading] = React.useState(false)
  const [pauseLoading, setPauseLoading] = React.useState(false)
  const [pauseDays, setPauseDays] = React.useState(0)

  const [salesRep, setSalesRep] = React.useState<ISuggestionAttribute | null>()
  const [clientObj, setClientObj] = React.useState<ISuggestionAttribute | null>()

  const findSelectedAssigne = async () => {
    const data = await fetchAssigneeSuggestions('')
    setSenderOptions(data)
  }
  React.useEffect(() => {
    findSelectedAssigne()
  }, [])
  const { history } = useRouter()

  const prepareProspectToClient = async () => {
    try {
      // if (prospect?.client_id) {
      //   history.push(`/client/${prospect.client_id}`)
      // }
      setIsProspectTransferLoading(true)
      if (prospect && prospect.ref_code) {
        const aff = await fetchClientByRefcode(prospect?.ref_code)
        setAffiliate(aff)
      }
      setIsProspectTransfer(true)
      setIsProspectTransferLoading(false)
    } catch (error) {
      showApiResponseError(error, error + 'Error Getting Affiliate')
      setIsProspectTransfer(true)
      setIsProspectTransferLoading(false)
    }
  }
  React.useEffect(() => {
    const getNots = async () => {
      setIsGetNoteLoading(true)
      if (prospect?.id) {
        try {
          const res = await getProspectNotesById(prospect?.id)
          setNotes(res)
          setIsGetNoteLoading(false)
        } catch (error) {
          console.log('error', error)
        }
        setIsGetNoteLoading(false)
      }
    }
    getNots()
    // }, [prospect?.id, isNoteLoading])
  }, [prospect?.id])

  React.useEffect(() => {
    if (prospect) {
      setFirstName(prospect?.first_name || '')
      setMiddleName(prospect?.middle_name || '')
      setLastName(prospect?.last_name || '')
      setTitle(prospect?.title || '')
      setPhone(prospect?.phone)
      setEmail(prospect?.email || '')
      setCompanyName(prospect?.company_name || '')
      setSubmissionId(prospect?.submission_id)
      setStatusValue(prospect?.prospect_status || 0)
      setIndustryValue(prospect?.industry_type || 0)
      setPOCValue(prospect?.poc || 0)
      setProspectAddress([prospect?.addressData])
      setProspectName(prospect?.name)
      setInvoicePaymentLink(prospect?.invoice_payment_link || '')
      setSalesRep(prospect?.sales_rep)
      setClientObj(prospect?.affiliate)
    }
  }, [prospect])

  const changeDropDownField = React.useCallback(async (field: keyof IClient, valueAtt: ISuggestionAttribute | null) => {
    if (field && field === 'sales_rep') {
      console.log('valueAttvalueAttvalueAttvalueAtt setSalesRep', valueAtt)
      setSalesRep(valueAtt)
    } else if (field && field === 'affiliate') {
      console.log('valueAttvalueAttvalueAttvalueAtt setClientObj', valueAtt)
      setClientObj(valueAtt)
    }
  }, [])

  const getReminderOrderSchedules = async () => {
    if (!prospect?.id) return
    setOrderReminderLoading(true)
    try {
      const res = await getProspectReminderSchedules(prospect?.id)
      setOrderReminders(res)
      setOrderReminderLoading(false)
    } catch (error) {
      console.log(error)
    }
    setOrderReminderLoading(false)
  }

  React.useEffect(() => {
    getReminderOrderSchedules()
  }, [prospect])

  const handleAddNote = async () => {
    setIsNoteLoading(true)
    const localNotesPayload: NotesData = {
      prospect_id: 0,
      note_text: noteText,
      created_on: moment().toString(),
      read: false,
      creator: 'Employee',
      created_by: 'Admin',
      emp_id: undefined,
      id: 0,
    }
    const payload = {
      prospect_id: prospect?.id,
      note_text: noteText,
      created_on: moment(),
    }
    try {
      await addProspectNote(payload)
      setIsNoteLoading(false)
      // showSuccessNotification('Note Created ..!')
      setNotes([...notes, localNotesPayload])
      setNoteText('')
    } catch (error) {
      showErrorNotification(error)
    }
    setIsNoteLoading(false)
  }

  console.log('local notes', notes)

  //pause noti
  const handlePauseSchedules = async () => {
    if (!prospect) {
      showErrorNotification('No Prospect ID')
      return
    }
    setPauseLoading(true)
    const payload = {
      prospect_id: prospect?.id,
      pauseDays,
    }
    try {
      const res = await pauseNotifications(payload)
      setPauseLoading(false)
      res.success && showSuccessNotification(`Notifications Paused for ${pauseDays} days`)
      setPauseDays(0)
      res.error && showErrorNotification(res.error)
    } catch (error) {
      showErrorNotification(error)
    }
    setPauseLoading(false)
    // setOpen(false)
  }
  //
  const handleSendSms = async () => {
    setsmsSendLoading(true)
    setIsNoteLoading(true)

    const localSMSPayload = {
      prospect_id: 0,
      note_text: smsText,
      created_on: moment().toString(),
      read: true,
      creator: 'Employee',
      created_by: user?.name,
      emp_id: undefined,
      id: 0,
      sent_notification_id: 1,
    }
    const payload = {
      msg_text: smsText,
    }

    try {
      if (prospect) {
        const res = await sendProspectSms(payload, prospect.id)
        // res.success && showSuccessNotification('Sms Sent ..!')
        res.error && showErrorNotification(res.error)
        setNotes([...notes, localSMSPayload])

        setSmsText('')
      }
      setsmsSendLoading(false)
    } catch (error) {
      showErrorNotification(error)
    }
    setsmsSendLoading(false)
    setIsNoteLoading(false)
  }
  const getProspectsList = async () => {
    try {
      const res = await getProspectStatuses()
      setProspectStatusList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }
  const getInductriesList = async () => {
    try {
      const res = await getIndustryType()
      setIndustryTypesList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  React.useEffect(() => {
    getInductriesList()
    getProspectsList()
  }, [])

  const toggleAddAddressDialog = React.useCallback(() => {
    setCurrentEditedAddress(emptyAddress)
    setShowAddAddressDialog(!showAddAddressDialog)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
        city: currentEditedAddress?.cityObj?.label || '',
        city_id: currentEditedAddress?.cityObj?.value || 0,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )

  const updateAddressbyId = React.useCallback(
    async (addressesWithUpdatedAddress: any) => {
      const addressId = prospect?.addressData?.id || 0
      //
      const updatedAddress = addressesWithUpdatedAddress?.map(item => {
        if (item?.cityObj) {
          return {
            ...item,
            city: item?.cityObj?.label || '',
            city_id: item?.cityObj?.value || 0,
          }
        } else {
          return {
            ...item,
          }
        }
      })
      try {
        await updateProspectAddressById(addressId, updatedAddress[0])
        showSuccessNotification('Address Successfully Update.')
      } catch (error) {
        console.log(error)
      }
    },
    [prospect?.addressData?.id]
  )

  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    const indexToUpdate = prospectAddress && prospectAddress.findIndex(address => address.id === editedAddressId)
    //
    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(prospectAddress || [])]
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setProspectAddress(addressesWithUpdatedAddress)
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    setCurrentEditedAddress(emptyAddress)
    setShowAddAddressDialog(false)
    updateAddressbyId(addressesWithUpdatedAddress)
  }, [editedAddressId, prospectAddress, currentEditedAddress, addressesToEdit, updateAddressbyId])

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((prospectAddress && prospectAddress.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [prospectAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setProspectAddress(prospectAddress.filter(address => address.id !== id))
    },
    [prospectAddress]
  )

  const handleAddAddress = React.useCallback(() => {
    //

    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      const newAddresses: IAddress[] = []

      newAddresses.push(currentEditedAddress)
      setProspectAddress(newAddresses)
      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd])
      toggleAddAddressDialog()
    }
    return true
  }, [addressesToAdd, currentEditedAddress, editedAddressId, toggleAddAddressDialog, updateAddress])

  const handleValueChange = React.useCallback(
    async (event: any) => {
      setStatusValue(event.target.value)
      // updateStatus(event.target.value)
      if (prospect?.id) {
        const id = prospect?.id

        try {
          await changeProspectStatus(id, { status: event.target.value })
          showSuccessNotification('Status Update.!')
        } catch (error) {
          console.log(error)
        }
      }
    },
    [prospect?.id]
  )

  const updateProspect = async () => {
    if (prospect?.id) {
      const id = prospect?.id
      if (!prospectAddress[0]?.id) {
      }
      const newProspectAddress = prospectAddress?.map(item => {
        if (!item[0]?.id) {
          return {
            ...item,
            city: item?.cityObj?.label || '',
            city_id: item?.cityObj?.value || 0,
          }
        } else if (item?.cityObj) {
          return {
            ...item,
            city: item?.cityObj?.label || '',
            city_id: item?.cityObj?.value || 0,
          }
        } else {
          return {
            ...item,
          }
        }
      })
      const data = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        company_name: companyName,
        industry_type: industryValue,
        prospect_status: statusValue,
        phone: phone,
        email: email || '',
        poc: pocValue,
        title: title || '',
        submission_id: submissionId || '',
        addressData: newProspectAddress,
        invoice_payment_link: invoicePaymentLink || '',
        client_id: clientObj?.value,
        sales_representative_id: salesRep?.value,
      }
      setIsProspectUpdateLoading(true)
      try {
        await updateProspectById(id, data)
        setIsProspectUpdateLoading(false)
        showSuccessNotification('Prospect Updated.!')
      } catch (error) {
        showErrorNotification(error)
        console.log(error)
      }
      setIsProspectUpdateLoading(false)
    }
  }

  const transferToClient = async () => {
    prospectAddress[0].is_primary = true
    const data = {
      name: firstName.toUpperCase(),
      last_name: lastName.toUpperCase(),
      middle_name: middleName?.toUpperCase(),
      phone_number: phone,
      email: email,
      addresses: prospectAddress,
      user_type: USERTYPES.client,
    }
    setIsProspectTransferLoading(true)
    try {
      await changeProspectStatus(prospect?.id || 0, { status: PROSPECT_STATUSES.completed })

      await createClient(data)
      setIsProspectTransferLoading(false)
      showSuccessNotification('Transfer SuccessFull.!')
    } catch (error) {
      showErrorNotification(error)
      console.log(error)
    }
    setIsProspectTransferLoading(false)
  }

  // const addTaskHanlder = () => {
  //   setAddTaskModal(true)
  // }

  const [taskToEdit, setTaskToEdit] = React.useState<any>()

  const addTaskHanlder = React.useCallback(() => {
    setTaskToEdit(undefined)
    setAddTaskModal(true)
  }, [])
  const onClientCreated = async () => {
    setIsProspectTransfer(false)
    setIsProspectTransferLoading(true)

    try {
      await changeProspectStatus(prospect?.id || 0, { status: PROSPECT_STATUSES.completed })

      setIsProspectTransferLoading(false)
      showSuccessNotification('Transfer SuccessFull.!')
    } catch (error) {
      console.log(error)

      showErrorNotification('Error Updating Status')
      console.log(error)
    }
    setIsProspectTransferLoading(false)
  }

  const [prospectTask, setProspectTask] = React.useState<any>()
  const [isProspectTaskLoading, setIsProspectTaskLoading] = React.useState<boolean>(false)
  const [showEdit, setShowEdit] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  const { showDelete, setShowDelete } = useDeleteTask()
  const { reloadTasks } = React.useContext(TasksContext)

  const [isUpdated, setIsUpdated] = React.useState(false)

  const deleteTaskfucn = React.useCallback(
    async (taskId: number) => {
      setIsUpdated(false)
      if (!taskId) return

      try {
        setIsDeleting(true)
        await deleteTask(taskId)
        showSuccessNotification('Task deleted')
        setShowDelete(false)
        setIsUpdated(true)
      } catch (error) {
        showApiResponseError(error, 'Something went wrong')
      }
      setIsUpdated(false)
    },
    [setShowDelete]
  )

  const onDeleteClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
      event.stopPropagation()

      setTaskToEdit(row)
      console.log('row', row)

      setShowDelete(true)
    },
    [setShowDelete]
  )

  const toggleShowEdit = React.useCallback(() => {
    setShowEdit(prev => !prev)
  }, [])

  const onEditCLick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
      event.stopPropagation()

      const newTask = {
        details: row?.description,
        ...row,
      }

      setTaskToEdit(newTask)
      console.log('row', row)

      toggleShowEdit()
    },
    [toggleShowEdit]
  )

  const onCompleteTaskClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
      event.stopPropagation()

      setIsUpdated(false)

      if (!row?.id) return

      try {
        if (!!row?.completed) {
          await markTaskAsNotDone(row?.id)
          showSuccessNotification('Task Updated')
        } else {
          await markTaskAsDone(row?.id)
          showSuccessNotification('Task Completed')
        }
        setIsUpdated(true)
      } catch (error) {
        showApiResponseError(error, 'Something went wrong')
      }
      setIsUpdated(false)
    },
    []
  )

  React.useEffect(() => {
    const getProspectTask = async () => {
      setIsProspectTaskLoading(true)
      if (prospect?.id) {
        try {
          const res = await getProspectTaskById(prospect?.id)
          setProspectTask(res)
          setIsProspectTaskLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Something went wrong')
        }
        setIsProspectTaskLoading(false)
      }
    }
    getProspectTask()
  }, [prospect?.id, isUpdated])

  const handleMarkAsAllRead = async (id: number) => {
    if (!id) return
    setIsNoteLoading(true)
    const payload = { read: true }

    const newNotes = notes.map(note => {
      return { ...note, read: true }
    })

    try {
      await markAllProspectAsReadById(id, payload)
      showSuccessNotification('Marked all as read')
      setNotes(newNotes)
      setIsMarkAllRead(false)
      setIsNoteLoading(false)
    } catch (error) {
      showApiResponseError(error, 'Error')
    }
    setIsNoteLoading(false)
  }

  const checkingNotes = () => {
    const obj = notes?.find(note => note?.creator?.includes('Client') && !note?.read)
    console.log('obj', obj)

    if (obj?.id) {
      return false
    } else {
      return true
    }
  }
  const checkReadSMS = notes?.filter(note => note.creator === 'Client' && !note.read)

  return (
    <div className={classes.mainContainer}>
      <DialogWithConfirmButtons
        onClose={() => setIsMarkAllRead(false)}
        open={isMarkAllRead}
        title={`Are you sure you want to Mark as all read ?`}
        firstOptionTitle="Yes"
        secondOptionTitle="Cancel"
        onFirstOptionClick={() => handleMarkAsAllRead(prospect?.id || 0)}
        onSecondOptionClick={() => setIsMarkAllRead(false)}
        isLoading={isNoteLoading}
      />

      {isProspectTransfer && (
        <CreateClient
          isPopup={true}
          title={'Transfer to Client'}
          // fetchClients={fetchClients}
          openByDefault={true}
          onClientCreated={onClientCreated}
          onClose={() => setIsProspectTransfer(false)}
          initialClient={prospectAddress}
          editedClient={{
            name: firstName.toUpperCase(),
            middle_name: middleName?.toUpperCase(),
            last_name: lastName.toUpperCase(),
            phone_number: phone,
            email: email,
            addresses: prospectAddress,
            user_type: USERTYPES.client,
            username: '',
            affiliate: { id: affiliate?.id, name: affiliate.name, email: affiliate.email },
          }}
          fromProspect={true}
        />
      )}
      <AddProspectTask
        open={addTaskModal}
        onClose={() => setAddTaskModal(false)}
        // onTaskCreated={onTaskCreated}
        prospectId={prospect?.id || 0}
        prospectName={`${prospect?.first_name} ${prospect?.middle_name ? prospect?.middle_name : ''} ${
          prospect?.last_name
        }`}
        task={taskToEdit}
        mode={taskToEdit ? 'update' : 'create'}
        onFormSubmitted={() => setIsUpdated(!isUpdated)}
      />

      <CreateTaskDialog
        onFormSubmitted={() => setIsUpdated(!isUpdated)}
        taskToEdit={taskToEdit}
        open={showEdit}
        toggleOpen={toggleShowEdit}
      />

      <DeleteConfirmationDialog
        isDeleting={isDeleting}
        itemType="task"
        onDelete={() => taskToEdit?.id && deleteTaskfucn(taskToEdit?.id)}
        open={showDelete}
        itemName={taskToEdit?.title}
        onClose={() => setShowDelete(false)}
      />

      <div className={classes.headingDiv}>
        <Grid container justify="space-between">
          <Grid item xs={9}>
            <h1 className={classes.heading}>Prospect</h1>
          </Grid>
          {prospect?.form_link && (
            <ButtonWithLoading onClick={() => copyToClipboard(prospect.form_link)} kind={'BRPrimary'}>
              Copy Form Link
            </ButtonWithLoading>
          )}
          {prospect?.submission_id && (
            <ButtonWithLoading onClick={() => setHistoryPopUpOpen(true)} kind={'BRSecondary'}>
              Submission History
            </ButtonWithLoading>
          )}
          {/* </Grid> */}
          <SubmissionHistory
            open={historyPopUpOpen}
            setOpen={setHistoryPopUpOpen}
            submission_id={prospect?.submission_id}
          />
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={7}>
          <Header title={'Prospect Information'} />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField label="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField label="Middle Name" value={middleName} onChange={e => setMiddleName(e.target.value)} />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField label="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField label="Title" value={title} onChange={e => setTitle(e.target.value)} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                // <InputField label="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                <PhoneInput value={phone || ''} onChange={val => setPhone(val)} />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
              )}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textBox}>
                {isLoading ? (
                  <Skeleton animation="wave" variant="text" width="100%" height="175px" />
                ) : (
                  <Addresses
                    label="Address"
                    customClass
                    customClassName="address-box"
                    onlyOneAddress={true}
                    onAddAddressClick={handleAddAddressClick}
                    addresses={prospectAddress || []}
                    onAddressEditClick={handleAddressEditClick}
                    onAddressDeleteClick={handleAddressDeleteClick}
                    isDeletable={false}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField label="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <Dropdown
                  label="Industry Type"
                  options={industryTypesList}
                  placeholder="Industry Type"
                  val={industryValue}
                  handleValueChange={e => setIndustryValue(e.target.value)}
                />
              )}
            </Grid>
            {/* <Grid item xs={6}>
              <Dropdown
                label="POC"
                options={senderOptions}
                placeholder="POC"
                val={pocValue}
                handleValueChange={e => setPOCValue(e.target.value)}
              />
            </Grid> */}

            <Grid item xs={6} className={classes.autoField}>
              <SelectField
                // showCopyButton={!!editedClient}
                onOptionSelected={option => changeDropDownField('sales_rep', option ? option : null)}
                title="Sales Representative"
                fetchSuggestions={fetchSuggestions}
                field="sales_rep_id"
                placeholder="Choose Sales Representative"
                value={salesRep}
                variant="standard"
                label="Choose Sales Representative"
              />
            </Grid>
            <Grid item xs={6} className={classes.autoField}>
              <SelectField
                // showCopyButton={!!editedClient}
                onOptionSelected={option => changeDropDownField('affiliate', option ? option : null)}
                title="Affiliate"
                fetchSuggestions={fetchSuggestions}
                field="affiliate_id"
                placeholder="Choose Affiliate"
                value={clientObj}
                variant="standard"
                label="Choose Affiliate"
              />
            </Grid>

            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField
                  label="Submission ID"
                  value={submissionId}
                  onChange={e => setSubmissionId(e.target.value)}
                />
              )}
            </Grid>

            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <InputField
                  label="Invoice Payment Link"
                  value={invoicePaymentLink}
                  onChange={e => setInvoicePaymentLink(e.target.value)}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={3}>
              <ButtonWithLoading
                kind="BRPrimary"
                wrapperClassName="text-template-btn"
                onClick={updateProspect}
                isLoading={isProspectUpdateLoading}
              >
                Update
              </ButtonWithLoading>
            </Grid>
            <Grid item xs={3}>
              <ButtonWithLoading
                kind="BRPrimary"
                wrapperClassName="text-template-btn"
                isLoading={isProspectTransferLoading}
                onClick={() => prepareProspectToClient()}
              >
                Transfer To Client
              </ButtonWithLoading>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
              <ButtonWithLoading kind="BRPrimary" wrapperClassName="text-template-btn" onClick={addTaskHanlder}>
                Add Task
              </ButtonWithLoading>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ margin: '2rem 0' }}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tasks</TableCell>
                    <TableCell width="100px">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isProspectTaskLoading ? (
                    <TableRow className={classes.root}>
                      {[1, 2]?.map(_ => (
                        <TableCell component="th" scope="row">
                          <Skeleton variant="rect" width={'100%'} height={'50px'} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : prospectTask?.length ? (
                    prospectTask?.map(row => (
                      <Row
                        key={row?.id}
                        row={row}
                        onEditClick={onEditCLick}
                        onDeleteClick={onDeleteClick}
                        onCompleteTaskClick={onCompleteTaskClick}
                      />
                    ))
                  ) : (
                    <TableRow className={classes.root}>
                      <TableCell>
                        <Alert severity="info">No Prospect Task Found</Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Header title={'Status'} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <Dropdown
                  label="Status"
                  options={prospectStatusList}
                  placeholder="Select your status"
                  val={statusValue}
                  handleValueChange={handleValueChange}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width={'100%'} height={'50px'} />
              ) : (
                <Dropdown
                  label="ASSIGN EMPLOYEE"
                  options={senderOptions}
                  placeholder="ASSIGN EMPLOYEE"
                  val={pocValue}
                  handleValueChange={e => setPOCValue(e.target.value)}
                />
              )}
            </Grid>
            {/* <Grid item xs={12}>
              <AddTask iconText={'Add Task'} handler={addTaskHanlder} />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tasks</TableCell>
                      <TableCell width="100px">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isProspectTaskLoading ? (
                      <TableRow className={classes.root}>
                        {[1, 2]?.map(_ => (
                          <TableCell component="th" scope="row">
                            <Skeleton variant="rect" width={'100%'} height={'50px'} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ) : prospectTask?.length ? (
                      prospectTask?.map(row => (
                        <Row
                          key={row?.id}
                          row={row}
                          onEditClick={onEditCLick}
                          onDeleteClick={onDeleteClick}
                          onCompleteTaskClick={onCompleteTaskClick}
                        />
                      ))
                    ) : (
                      <TableRow className={classes.root}>
                        <TableCell>
                          <Alert severity="info">No Prospect Task Found</Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> */}
            {/* <Grid item xs={12}>
              <div className="text-box">
                <TextareaAutosize
                  className="text-area"
                  placeholder="Notes/ Descriptions"
                  rowsMax="10"
                  rowsMin="10"
                  value={noteText}
                  name="noteText"
                  onChange={e => setNoteText(e.target.value)}
                />
                <ButtonWithLoading
                  kind="BRPrimary"
                  wrapperClassName="save-btn"
                  onClick={handleAddNote}
                  isLoading={isNoteLoading && !smsSendLoading}
                  disabled={!noteText.length}
                >
                  Add Note
                </ButtonWithLoading>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="text-box">
                <TextareaAutosize
                  className="text-area"
                  placeholder="Type Your message here..."
                  rowsMax="10"
                  rowsMin="10"
                  value={smsText}
                  name="smsText"
                  onChange={e => setSmsText(e.target.value)}
                />
                <ButtonWithLoading
                  kind="BRPrimary"
                  wrapperClassName="save-btn"
                  onClick={handleSendSms}
                  isLoading={smsSendLoading}
                  disabled={!smsText.length}
                >
                  Send
                </ButtonWithLoading>
              </div>
            </Grid> */}

            <Grid item xs={12}>
              <Grid className={classes.gridRow} container spacing={2}>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="Ref Code" value={prospect?.ref_code} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="UTM Source" value={prospect?.utm_source} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="UTM Medium" value={prospect?.utm_medium} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="UTM Campaign" value={prospect?.utm_campaign} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="UTM Content" value={prospect?.utm_content} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="GCLID" value={prospect?.gclid} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="FBCLID" value={prospect?.fbclid} disabled />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton variant="text" width={'100%'} height={'50px'} />
                  ) : (
                    <InputField label="MSCLKID" value={prospect?.msclkid} disabled />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {/* <Header
            title={'Communication'}
            iconText="Upcoming Notifications"
            icon={<NotificationsIcon />}
            handler={() => setIsAutoSchedules(true)}
            secondaryBtnText={'Mark all as read'}
            disabled={checkingNotes()}
            secondaryIcon={<Markunread />}
            secondaryHandler={() => setIsMarkAllRead(true)}
            teritaryBtnText={'Pause Notification'}
            teritaryIcon={<NotificationsPausedIcon />}
            teritaryHandler={() => {
              setOpen(true)
            }}
          /> */}
          <Grid className={classes.gridRow} container spacing={0}>
            <TabContext value={value}>
              <div style={{ width: '100%' }}>
                <TabList onChange={handleChange}>
                  <Tab
                    label={
                      <div className={classes.tabSec}>
                        <p className="noti-text">Communication Record</p>
                        <div className="noti-dots">{checkReadSMS?.length || 0}</div>
                      </div>
                    }
                    value={'1'}
                  />
                  <Tab label="Notes" value={'2'} />
                  <Tab label="Upcoming Notifications" value={'3'} />
                </TabList>
                <TabPanel value={'1'} style={{ width: 'calc(100vw-416px)' }}>
                  <Grid item xs={12}>
                    {isGetNoteLoading ? (
                      [1, 2, 3, 4, 5, 6, 7].map(key => <Skeleton animation="wave" variant="text" key={key} />)
                    ) : notes?.length ? (
                      notes?.map((note: NotesData) => <ChatBox note={note} key={note?.id} />)
                    ) : (
                      <p style={{ margin: '1rem 0' }}>No Message Yet.!</p>
                    )}
                  </Grid>

                  <Grid container spacing={0} style={{ marginTop: '1rem' }}>
                    <Grid item xs={1}>
                      <Fab
                        color="primary"
                        aria-label="Mark all as read"
                        disabled={checkingNotes()}
                        onClick={() => setIsMarkAllRead(true)}
                      >
                        <DoneAll htmlColor="#fff" />
                      </Fab>
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        placeholder="Type your reply here"
                        focused={false}
                        className={classes.msgField}
                        value={smsText}
                        name="smsText"
                        onChange={e => setSmsText(e.target.value)}
                        multiline
                        rowsMax={3}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CreateOutlined fontSize="small" color="disabled" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={handleSendSms}
                                disabled={!smsText.length || smsSendLoading}
                              >
                                {smsSendLoading ? <CircularProgress size={24} /> : 'Send'}
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={'2'}>
                  <Grid item xs={12}>
                    {isGetNoteLoading ? (
                      [1, 2, 3, 4, 5, 6, 7].map(key => <Skeleton animation="wave" variant="text" key={key} />)
                    ) : notes?.length ? (
                      notes?.map((note: NotesData) => <NoteBox note={note} key={note?.id} />)
                    ) : (
                      <p style={{ margin: '1rem 0' }}>No Notes Yet.!</p>
                    )}
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '1rem' }}>
                    <TextField
                      placeholder="Write your note here"
                      focused={false}
                      className={classes.msgField}
                      value={noteText}
                      name="noteText"
                      onChange={e => setNoteText(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CreateOutlined fontSize="small" color="disabled" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              color="primary"
                              variant="contained"
                              size="large"
                              onClick={handleAddNote}
                              disabled={!noteText.length || (isNoteLoading && !smsSendLoading)}
                            >
                              {isNoteLoading && !smsSendLoading ? <CircularProgress size={24} /> : 'Add Note'}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel value={'3'}>
                  {orderReminderLoading
                    ? [1, 2, 3, 4, 5, 6, 7].map(key => <Skeleton animation="wave" variant="text" key={key} />)
                    : orderReminders?.length
                    ? orderReminders?.map(reminder => <UpcomingNotificationBox reminder={reminder} />)
                    : 'No Schedules Yet.!'}

                  <Grid container spacing={3} alignItems="flex-end">
                    <Grid item xs={12}>
                      <Header title={'Pause Notification'} />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        label="Enter No Of Days"
                        value={pauseDays}
                        onChange={(e: any) => setPauseDays(e.target.value)}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ButtonWithLoading
                        wrapperClassName={classes.pauseBtn}
                        onClick={handlePauseSchedules}
                        color="primary"
                        variant="contained"
                        isLoading={pauseLoading}
                      >
                        Pause
                      </ButtonWithLoading>
                    </Grid>
                  </Grid>
                </TabPanel>
              </div>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
      {/* <ModalWrapper open={isAutoSchedules} setOpen={setIsAutoSchedules} heading="Auto Schedules">
        {orderReminderLoading ? (
          <AutoSchedule isLoading={orderReminderLoading} />
        ) : (
          orderReminders?.map(reminder => (
            <AutoSchedule
              date={reminder?.send_time}
              text={reminder?.type}
              scheduleText={reminder?.status}
              subject={''}
              description={reminder?.content}
            />
          ))
        )}
      </ModalWrapper> */}
      <AddAddressDialog
        open={showAddAddressDialog}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressFieldsChange}
        handleDialogClose={toggleAddAddressDialog}
        onAddressAdd={handleAddAddress}
        addressErrors={{
          country: false,
          city: false,
          street: false,
          postal_code: false,
        }}
        isEditing={editedAddressId !== undefined}
      />
      {/* <PauseDialog
        open={open}
        pauseLoading={pauseLoading}
        handlePauseSchedules={handlePauseSchedules}
        setOpen={setOpen}
        pauseDays={pauseDays}
        setPauseDays={setPauseDays}
        // onDueDateSelected={onDueDateSelected}
      /> */}
      {/* <AddTaskDialog
        open={addTaskModal}
        onClose={() => setAddTaskModal(false)}
        // onTaskCreated={onTaskCreated}
        // orderId={orderId}
        // task={taskToEdit}
        // mode={taskToEdit ? 'update' : 'create'}
      /> */}

      {/* <CreateTaskDialog open={addTaskModal} toggleOpen={addTaskHanlder} /> */}
    </div>
  )
}

// const PauseDialog: React.FC<any> = ({ open, setOpen, loading, handlePauseSchedules, pauseDays, setPauseDays }) => {
//   const classes2 = useStyles2()
//   const classes = useStyles()

//   // const [open, setOpen] = React.useState(isOpen)

//   return (
//     <Dialog open={open} className={classes2.dialogContainer} fullWidth={false} maxWidth="sm">
//       <ModalTitle title={''} toggleModal={setOpen} />
//       <DialogContent className={classes.root}>
//         <InputField label="Enter No Of Days" value={pauseDays} onChange={e => setPauseDays(e.target.value)} />
//       </DialogContent>
//       <DialogActions className={classes2.dialogAction}>
//         <ButtonWithLoading
//           onClick={handlePauseSchedules}
//           color="primary"
//           variant="contained"
//           className={classes2.uploadBtn}
//           isLoading={loading}
//         >
//           Pause
//         </ButtonWithLoading>
//       </DialogActions>
//     </Dialog>
//   )
// }

export default ProspectList
