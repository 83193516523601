import { Button, FormControl, OutlinedInput, TextField } from '@material-ui/core'
import { addRemindersConfig, addReminderTemp, getAutoRemindersById, updateRemindersConfig } from 'api/autoReminders'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import moment from 'moment'
import React, { useCallback, useState, useEffect } from 'react'
import useRouter from 'use-react-router'
import { REMINDER_TYPES } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useStyles } from '../styles'
import AccordionWrapper from './accordionWrapper'
import { useParams } from 'react-router'
import useUser from 'hooks/useUser'

const AddReminder = () => {
  const classes = useStyles()
  const { history } = useRouter()
  const [templateName, setTemplateName] = useState('')
  const [configType, setConfigType] = useState('')
  const [reminders, setReminders] = useState<any>({})

  const { user } = useUser()
  const [currentUser, setCurrentUser] = useState<number>()

  useEffect(() => {
    setCurrentUser(user?.id)
  }, [user])

  const [updateRemindersData, setUpdateRemindersData] = useState<any>({})

  const [isLoading, setIsLoading] = useState(false)

  const { id } = useParams<any>()

  const getReminderData = async (id: number) => {
    try {
      const data = await getAutoRemindersById(id)
      setUpdateRemindersData(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) {
      getReminderData(id)
    }
  }, [id])

  useEffect(() => {
    if (updateRemindersData) {
      setTemplateName(updateRemindersData?.name)
    }
  }, [updateRemindersData])

  const userDetails = JSON?.parse(localStorage?.getItem('currentUser') || '')
  const userId = userDetails?.extid

  useEffect(() => {
    if (id || updateRemindersData) {
      const updatedReminders = updateRemindersData?.configs?.reduce(
        (prev, curr) => {
          console.log('previous', curr?.schedule_time)

          const reminder = {
            senderName: curr?.sender,
            scheduleTime: curr?.schedule_time?.replace(/[^0-9]/g, ''),
            scheduleType: curr?.schedule_time?.match(/(hours|minutes|days)/g)[0].toLowerCase(),
            contentText: curr?.content,
            emailTitle: curr?.title,
            emailSubject: curr?.subject,
            smsBody: curr?.content,
            type: curr?.type,
          }
          const uuid = Math.random()
          prev[uuid] = reminder
          return prev
        },
        { ...reminders }
      )
      setReminders({ ...updatedReminders })
    }
  }, [id, updateRemindersData])

  const remindersTypeHanlder = (
    type: string,
    senderName = '',
    scheduleTime = '',
    scheduleType = '',
    contentText = '',
    emailTitle = '',
    emailSubject = '',
    smsBody = ''
  ) => {
    const reminder = {
      senderName: senderName,
      scheduleTime: scheduleTime,
      scheduleType: scheduleType,
      contentText: contentText,
      emailTitle: emailTitle,
      emailSubject: emailSubject,
      smsBody: smsBody,
      type: type,
    }
    const uuid = Math.random()
    setReminders({ ...reminders, [uuid]: reminder })
  }

  const remindersData = Object.entries(reminders)

  console.log('remindersData', remindersData)
  console.log('updateRemindersData', updateRemindersData)

  const handleUpdateReminderData = (id, field, data) => {
    reminders[id][field] = data
    setReminders({ ...reminders })
  }

  const handleDelete = id => {
    delete reminders[id]
    setReminders({ ...reminders })
  }

  const smsHandler = type => {
    setConfigType(type)
    remindersTypeHanlder(type)
  }
  const emailHandler = type => {
    setConfigType(type)
    remindersTypeHanlder(type)
  }

  const addRemindersHandler = useCallback(
    async reminderItemID => {
      setIsLoading(true)
      const sendingArray = remindersData.map(([key, value]) => {
        const {
          scheduleTime,
          scheduleType,
          contentText,
          emailTitle,
          emailSubject,
          senderName,
          smsBody,
          type,
        }: any = value
        return {
          type: type,
          schedule_time: `${scheduleTime} ${scheduleType}`,
          sender: senderName,
          reminder_template_id: reminderItemID,
          title: emailTitle,
          subject: emailSubject,
          content: contentText || smsBody,
        }
      })

      try {
        await addRemindersConfig({ data: sendingArray })
        showSuccessNotification('Auto Reminder Successfully Created')
        setIsLoading(false)
      } catch (error) {
        showErrorNotification(error)
      }

      setIsLoading(false)
    },
    [remindersData]
  )

  const templateHanlder = useCallback(async () => {
    setIsLoading(true)
    const templateData = {
      name: templateName,
      created_at: moment().toLocaleString(),
      created_by: userId,
    }
    const updatedArray = remindersData.map(([key, value]) => {
      const {
        scheduleTime,
        scheduleType,
        contentText,
        emailTitle,
        emailSubject,
        senderName,
        smsBody,
        type,
      }: any = value
      return {
        type: type,
        schedule_time: `${scheduleTime} ${scheduleType}`,
        sender: senderName,
        title: emailTitle,
        subject: emailSubject,
        content: contentText || smsBody,
      }
    })

    const data = {
      name: templateName,
      data: updatedArray,
    }

    try {
      if (id) {
        await updateRemindersConfig(id, data)
        showSuccessNotification('Auto Reminder Successfully Updated')
      } else {
        const res = await addReminderTemp(templateData)
        if (res) {
          addRemindersHandler(res?.data?.id)
        }
      }
      setIsLoading(false)
      history.push('/reminder-template-list')
    } catch (error) {
      showErrorNotification(error)
    }
    setIsLoading(false)
  }, [addRemindersHandler, history, id, remindersData, templateName, userId])

  const handleSave = useCallback(() => {
    templateHanlder()
  }, [templateHanlder])

  return (
    <div className={classes.addReminderSec}>
      <div className="header-section">
        <FormControl variant="outlined" className="text-field">
          <TextField
            id="notificationTitle"
            label="Template Name"
            variant="outlined"
            value={templateName}
            onChange={e => setTemplateName(e.target.value)}
            // helperText={errors?.description ? 'Enter Description' : ''}
          />
          {/* <FormHelperText className={classes.errorMsg}>{errors?.description ? '*Enter Description' : ''}</FormHelperText> */}
        </FormControl>
        <div className="placeholders">
          Placeholders: &nbsp;{' '}
          <b className="bold-text">
            $FIRST_NAME$, $LAST_NAME$, $COMPANY_NAME$, $MISSING_ITEMS$, $MEETING_LINK$, $STATE_OF_FORMATION$,
            $SENDER_FIRST_NAME$, $IPOSTAL1_LINK$, $POST_PAYMENT_LINK$, $ADDRESS$, $MISSING_ITEMS_LINK$
          </b>
        </div>
      </div>

      <div className="btn-sec">
        <Button variant="contained" color="primary" onClick={() => smsHandler(REMINDER_TYPES?.sms)}>
          + SMS
        </Button>
        <Button variant="contained" color="primary" onClick={() => emailHandler(REMINDER_TYPES?.email)}>
          + Email
        </Button>
      </div>

      {remindersData.length ? (
        <>
          {remindersData?.map(([key, value]) => (
            <AccordionWrapper
              keyId={key}
              reminderData={value}
              handleDelete={handleDelete}
              handleUpdate={handleUpdateReminderData}
              currentUser={currentUser || 0}
            />
          ))}

          <div className={classes.headerBtn}>
            <ButtonWithLoading onClick={handleSave} isLoading={isLoading}>
              {id ? 'Update' : 'Save'}
            </ButtonWithLoading>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddReminder
