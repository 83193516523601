import * as React from 'react'
import { emptyFunction } from 'utils/objectUtils'

interface CompanyOrderContextProps {
  reloadTable: boolean
  setReloadTable: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * CompanyOrderContext is a context which helps reload company orders table.
 * To reload orders table, call to `setReloadTable(true)` is made
 */
export const CompanyOrderContext = React.createContext<CompanyOrderContextProps>({
  reloadTable: false,
  setReloadTable: emptyFunction,
})
