import { TextFieldProps } from '@material-ui/core'
import DatePicker from 'components/common/datePicker'
import * as React from 'react'

interface ICompanyOrderDueDateProps {
  classes?: Record<'root', string>
  className?: string
  dueDateValue?: string | moment.Moment | Date
  dateFormat?: string
  onDueDateSelected: (date: string) => void
  InputProps?: TextFieldProps['InputProps']
  label?: string
}

/**
 * Order may have to be completed by a certain date.
 *
 * @param dateFormat: String representing how we want the date to be returned to us. E.g. 'MM/DD/YYYY'
 * @param dueDateValue: Currently selected due date.
 * @param onDueDateSelected: Triggered on selecting a date from the MUI `DatePicker`
 */
export const OrderDueDatePicker = ({
  className,
  classes,
  dueDateValue,
  dateFormat,
  onDueDateSelected,
  InputProps,
  label,
}: ICompanyOrderDueDateProps) => {
  return (
    <DatePicker
      className={className}
      classes={classes}
      onDateSelected={onDueDateSelected}
      label={label ? label : 'Due Date'}
      value={dueDateValue}
      placeholder={label ? label : 'Due Date'}
      format={dateFormat}
      InputProps={InputProps}
    />
  )
}
