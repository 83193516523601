import { CircularProgress } from '@material-ui/core'
import { UserDashboard } from 'components/dashboard'
import useUser from 'hooks/useUser'
import * as React from 'react'

const Dashboard: React.FC = () => {
  const { user } = useUser()

  if (!user) {
    return <CircularProgress />
  }

  if (!user.id) {
    throw new Error('Unknown user')
  }

  return <UserDashboard userId={user.id} />
}

export default Dashboard
