import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { getKpiDashboardCompliance } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import DetailModal from '../kpiDetail/detailmodal'
import OfferDetail from '../OfferDetail'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'

interface IKPIComplianceResponse {
  selected_compliance_option_all_percentage: number
  selected_compliance_option_portal_percentage: number
  selected_compliance_option_email_percentage: number
  no_of_entities_with_email: number
  selected_compliance_renew_option: number
  selected_compliance_alreadyrenewed_option: number
  selected_renew_option_percentage: number
  selected_alreadyrenewed_option_percentage: number
  selected_closed_option_percentage: number
  annual_report_completed: number
  other_compliances: any
  selected_compliance_closed_option: number
  annual_report_purchased?: number
  boi_purchased?: number
  boi_completed?: number
}

const ComplianceKpi = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<IKPIComplianceResponse>()
  const [ids, setIds] = useState<any[]>([])
  const [entityType, setEntityType] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardCompliance(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])
  const handleModalOpen = (type: string, field: keyof IKPIComplianceResponse) => {
    // setEntityType(type)
    // if (filteredData) {
    //   field && setIds(filteredData[`${field}`])
    //   setModalOpen(true)
    // }
  }
  return (
    <>
      <Grid className={classes.commonRow} container spacing={0}>
        <Grid item xs={2}>
          <PrimaryHeading heading="Compliance" text={currentData} />
        </Grid>
        <Grid item xs={10}>
          <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
        </Grid>

        <Grid
          item
          xs={2}

          //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'foreignEinOrderIds')}
        >
          <SectionDetail
            primaryText="Contacted "
            heading={filteredData?.no_of_entities_with_email || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid
          item
          xs={2}

          //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'foreignEinCompletedOrderIds')}
        >
          <SectionDetail
            primaryText="Selected Any Option %"
            heading={filteredData?.selected_compliance_option_all_percentage || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid
          item
          xs={2}

          //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, 'iPostalTasksOrderIds')}
        >
          <SectionDetail
            primaryText="Selected Option from Portal %"
            heading={filteredData?.selected_compliance_option_portal_percentage || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid
          item
          xs={2}

          //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, 'iPostalCompletedOrderIds')}
        >
          <SectionDetail
            primaryText="Selected Option from Email %"
            heading={Math.round(filteredData?.selected_compliance_option_email_percentage as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Selected Renew Option "
            heading={Math.round(filteredData?.selected_compliance_renew_option as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Selected Renew Option %"
            heading={Math.round(filteredData?.selected_renew_option_percentage as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Selected Already Renewed Option "
            heading={Math.round(filteredData?.selected_compliance_alreadyrenewed_option as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Selected Already Renewed Option %"
            heading={Math.round(filteredData?.selected_alreadyrenewed_option_percentage as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Selected Closed Option "
            heading={Math.round(filteredData?.selected_compliance_closed_option as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Selected Closed Option %"
            heading={Math.round(filteredData?.selected_closed_option_percentage as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Annual Report Completed"
            heading={Math.round(filteredData?.annual_report_completed as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Annual Report Purchased"
            heading={Math.round(filteredData?.annual_report_purchased as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="BOI Report Completed"
            heading={Math.round(filteredData?.boi_completed as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="BOI Report Purchased"
            heading={Math.round(filteredData?.boi_purchased as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h2 className="offer-heading">Other Compliances</h2>
          <Grid container spacing={3}>
            {filteredData?.other_compliances?.length ? (
              filteredData.other_compliances?.map((data: any, i: number) => (
                <Grid
                  item
                  xs={6}
                  key={i}
                  //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, data.companies)}
                >
                  <OfferDetail
                    count={data?.count?.toString() || ''}
                    description={data?.title || ''}
                    isLoading={isLoading}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <h3>No Data Found</h3>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* <Grid xs={6} item>
        <h2 className="offer-heading">Top Addons</h2>
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        ) : (
          filteredData?.topAddons &&
          filteredData?.topAddons?.map((value: topAddon, index: number) => (
            <Grid item xs={12} key={index}>
              {value.count} {value?.addon_name || ''}
            </Grid>
          ))
        )}
      </Grid> */}
        {/* <Grid xs={6} item>
        <h2 className="offer-heading">Top Addons</h2>
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        ) : (
          filteredData?.top_ten_sms.map((value: any, index: number) => (
            <Grid item xs={12} key={index}>
              #{index + 1} {value?.content?.substring(0, 85)}
            </Grid>
          ))
        )}
      </Grid> */}
      </Grid>
      {filteredData && <DetailModal open={modalOpen} setOpen={setModalOpen} ids={ids} type={entityType} />}
    </>
  )
}

export default ComplianceKpi
