import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'

import EditIcon from 'components/common/icons/edit'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import * as React from 'react'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IClientTableRowProps {
  status: any
  onEditClick: () => void
  onDeleteClick: () => void
}

const ClientTableRow: React.FC<IClientTableRowProps> = ({ status, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  // const [isModalOpen, setIsModalOpen] = React.useState(false)
  // const [isMoreOptionsOpen, setIsMoreOptionsOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      {/* <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialstates={states} /> */}
      <StyledTableRow onClick={onEditClick} data-testid={`${status.id}`} className={classes.row}>
        <StyledTableCell>{status.name}</StyledTableCell>
        <StyledTableCell>{status.id}</StyledTableCell>
        <StyledTableCell>
          {status.is_editable && (
            <TableActionButton
              className={classes.button}
              onClick={event => {
                event.stopPropagation()
                onDeleteClick()
              }}
              disabled={!status.is_editable}
              style={{ width: '35px', height: '35px' }}
            >
              <DeleteIcon tooltipText="Delete" />
            </TableActionButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default ClientTableRow
