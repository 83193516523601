import { IOrderDetailsResponse } from 'api/orders'
import { createCompanySuggestions, createOrderSuggestions, fetchAssigneeSuggestions } from 'api/suggestions'
import { COMPANY_CREATION_ORDER_COMMISSION } from 'BRValuesConfig'
import { IAutocompleteOption } from 'components/common/forms/GenericAutocomplete'
import { ISuggestionAttribute } from 'components/interfaces'
import { useAssigneeField } from 'components/orders/hooks/useAssigneeField'
import { IDetailedCompanyResponse } from 'models/company'
import { IUser } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import showErrorNotification from 'utils/showErrorNotification'

interface IUseCompanyOrderFormHandlersProps {
  onCompanyOrderFieldChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => Promise<void>
  companyOrderDetails: IOrderDetailsResponse
  setOpenAddTaskDialog: React.Dispatch<React.SetStateAction<boolean>>
  company_commission?: number
}

/**
 * We have a lot of event handling business logic when we edit/create a company order.
 * This hook serves the purpose of isolating that logic from the presentation layer.
 *
 * @param companyOrderDetails
 * State of the company order that we are gonna update/create
 * @param onCompanyOrderFieldChange
 * Callback used whenever we intend to update a field of `companyOrderDetails`
 */
export const useCompanyOrderFormHandlers = ({
  onCompanyOrderFieldChange,
  companyOrderDetails,
  setOpenAddTaskDialog,
  company_commission,
}: IUseCompanyOrderFormHandlersProps) => {
  /**
   * Company orders may have a deadline that they need to be completed by.
   *
   * @param dueDate: date most frequently originating from MUI `<DatePicker/>`
   */
  const handleDueDateSelected = React.useCallback(
    async (dueDate: string) => {
      const newDueDate = new Date(dueDate).toISOString()
      onCompanyOrderFieldChange('due_date', newDueDate)
    },
    [onCompanyOrderFieldChange]
  )

  /**
   * Returns the date in the format that it needs to be supplied to `<DatePicker/>`.
   */
  const companyOrderDueDate = React.useMemo(() => {
    return (companyOrderDetails.due_date && moment(companyOrderDetails.due_date)) || undefined
  }, [companyOrderDetails.due_date])

  /**
   * Company order's execution may be assigned to a user in the system.
   */
  const handleAssigneeSelected = React.useCallback(
    (assignee: IUser | null) => {
      onCompanyOrderFieldChange('assignee', assignee)
      if (assignee) onCompanyOrderFieldChange('assignee_id', assignee.id)
    },
    [onCompanyOrderFieldChange]
  )

  const orderTypeSuggestion: ISuggestionAttribute | null = React.useMemo(
    () =>
      companyOrderDetails.order_type && {
        value: companyOrderDetails.order_type.id,
        label: companyOrderDetails.order_type.name,
      },
    [companyOrderDetails.order_type]
  )

  /**
   * Company orders may have different types, designated by specific needs of a company.  E.g. "Business Formation" ...
   *
   * Whilst updating an order type, we should update both `order_type_id` and `order_type` fields.
   */
  const handleSelectingOrderType = React.useCallback(
    async (option: ISuggestionAttribute | null) => {
      if (option) {
        onCompanyOrderFieldChange('order_type_id', option.value)
        onCompanyOrderFieldChange('order_type', { id: option.value, name: option.label })
      }
    },
    [onCompanyOrderFieldChange]
  )

  /**
   * Company order may belong only to a single company. E.g.
   *
   * We can choose a company when creating a new order, but shouldn't change company for an already existing order.
   */
  const handleSelectingCompany = React.useCallback(
    (option: IAutocompleteOption | null) => {
      onCompanyOrderFieldChange('company_id', option ? option.value : null)

      onCompanyOrderFieldChange('company', { id: option?.value, name: option?.label } as IDetailedCompanyResponse)
    },
    [onCompanyOrderFieldChange]
  )

  /**
   * A company order is executed by real people and a fee may be charged from a client for their work.
   *
   * Every price involves a commission which we should calculate whenever setting it.
   */
  const handleChangingPrice = React.useCallback(
    async event => {
      const price = parseFloat(Number.parseFloat(event.target.value).toFixed(2))

      const commission = (price * (company_commission || COMPANY_CREATION_ORDER_COMMISSION)).toFixed(2) || 0

      await onCompanyOrderFieldChange('commission', commission)
      await onCompanyOrderFieldChange('price', price)
    },
    [onCompanyOrderFieldChange]
  )

  const handleChangingCommission = React.useCallback(
    async event => {
      let commission = parseFloat(event.target.value)
      if (isNaN(commission)) commission = 0
      console.log(commission, 'com')

      await onCompanyOrderFieldChange('commission', commission)
    },
    [onCompanyOrderFieldChange]
  )

  const { assigneeAsSuggestionAttribute, onAssigneeSelected } = useAssigneeField({
    assignee: companyOrderDetails.assignee,
    handleAssigneeSelected: handleAssigneeSelected,
  })

  /**
   * A company order's progress may be dependent on some other already created orders.
   *
   * @param query: we may wanna provide a search string to filter the suggestions by
   */
  const handleFetchingRequiredOrderSuggestions = React.useCallback(
    (query: string, field: string): Promise<ISuggestionAttribute[]> => {
      return createOrderSuggestions(query, companyOrderDetails.company_id)
    },
    [companyOrderDetails.company_id]
  )

  /**
   *  Used when adding new required orders to an already existing order with  `<MultiSelect/>`
   */
  const handleAddMultiValue = React.useCallback(
    (field: string, select: ValueOf<ISuggestionAttribute>) => {
      switch (field) {
        case 'required_order_ids':
          const requiredOrderIds =
            select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : []
          onCompanyOrderFieldChange('required_order_ids', requiredOrderIds)
          break
        default:
          showErrorNotification('Wrong key')
      }
    },
    [onCompanyOrderFieldChange]
  )

  const fetchCompanySuggestions = React.useCallback(async (query: string, field: string): Promise<
    ISuggestionAttribute[]
  > => {
    return createCompanySuggestions(query)
  }, [])

  const handleFetchAssigneeSuggestions = React.useCallback((query, _) => fetchAssigneeSuggestions(query), [])

  const companyOrderCommission = React.useMemo(() => {
    return (companyOrderDetails.price * (company_commission || COMPANY_CREATION_ORDER_COMMISSION)).toFixed(2) || 0
  }, [companyOrderDetails.price])

  return {
    handleFetchingRequiredOrderSuggestions,
    handleFetchAssigneeSuggestions,
    companyOrderDueDate,
    handleAddMultiValue,
    assigneeAsSuggestionAttribute,
    onAssigneeSelected,
    handleChangingPrice,
    handleSelectingOrderType,
    orderTypeSuggestion,
    companyOrderCommission,
    handleDueDateSelected,
    handleSelectingCompany,
    fetchCompanySuggestions,
    handleChangingCommission,
  }
}
