import { getClientAddresses } from 'api/clients'
import { getCompanyAddresses } from 'api/companies'
import { IAddress } from 'models/address'
import * as React from 'react'
import { notEmpty } from 'utils/objectUtils'

interface Props {
  clientId?: number
  companyId?: number
}

export const useAddressOptionsForCompanyOwner = ({ clientId, companyId }: Props) => {
  const [addressOptionsForNewOwner, setAddressOptionsForNewOwner] = React.useState<IAddress[]>([])

  const removeDuplicates = (addresses: IAddress[]): IAddress[] => {
    // addresses are all existing address in database.
    // filtering is added because `id` on IAddress is optional
    const ids: number[] = addresses.map(address => address.id).filter(notEmpty)
    const idsWithoutDuplicates: number[] = Array.from(new Set(ids))

    const addressesWithoutDuplicates: IAddress[] = idsWithoutDuplicates
      .map(id => addresses.find(address => address.id && address.id === id))
      .filter(notEmpty)

    return addressesWithoutDuplicates
  }

  React.useEffect(() => {
    const getAndSetAddressOptionsForNewOwner = async () => {
      const clientAddresses = clientId ? await getClientAddresses(clientId) : []
      const companyAddresses = companyId ? await getCompanyAddresses(companyId) : []

      let result: IAddress[] = [...clientAddresses, ...companyAddresses]
      result = removeDuplicates(result)

      setAddressOptionsForNewOwner(result)
    }

    getAndSetAddressOptionsForNewOwner()
  }, [clientId, companyId])

  return addressOptionsForNewOwner
}
