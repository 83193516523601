import { AddressError } from 'components/clients/create/useAddressEditErrors'
import { IAddress } from 'models/address'
import * as yup from 'yup'

export const addressSchema: yup.SchemaOf<Pick<IAddress, AddressError>> = yup.object({
  city: yup.string().required(),
  country: yup.string().required(),
  locality: yup.string().required(),
  street: yup.string().required(),
  postal_code: yup.string().required(),
})
