/* eslint-disable @typescript-eslint/no-empty-function */
import { IAddressCompany, IClientCompanyOwners } from 'api/clientDashboard'
import { fetchStates } from 'api/states'
import React, { useState, createContext, useEffect } from 'react'
import { ILegalTypes } from 'utils/autocompleteFakers'
import showErrorNotification from 'utils/showErrorNotification'

type ExistingCompanyProviderProps = {
  children: React.ReactChild
}

interface IStateProps {
  id: number
  state_display_name: string
}

export const initialStateObj: IStateProps = {
  id: 0,
  state_display_name: '',
}
export const initialCompanyTypeObj: ILegalTypes = {
  value: 0,
  label: '',
}
export const initialAddressObj: IAddressCompany = {
  is_primary: true,
  is_mailing: false,
  zip: '',
  address_line_1: '',
  city_id: 0,
}

export const initialOwnerObj: IClientCompanyOwners = {
  id: 0,
  first_name: '',
  middle_name: '',
  last_name: '',
  phone: '',
  email: '',
  address: initialAddressObj,
  shares: 0,
}

type ExistingCompanyContext = {
  formationState: IStateProps
  setFormationState: (formationState: IStateProps) => void
  formationStateList: IStateProps[]
  address: IAddressCompany
  setAddress: (address: IAddressCompany) => void
  stateId: string
  setStateId: (stateId: string) => void
  einNumber: string
  setEinNumber: (einNumber: string) => void
  companyType: ILegalTypes
  setCompanyType: (formationState: ILegalTypes) => void
  sharesNumber: string
  setSharesNumber: (sharesNumber: string) => void
  fileDate: string
  setFileDate: (fileDate: string) => void
  name: string
  setName: (name: string) => void
  companyOwners: IClientCompanyOwners[]
  setCompanyOwners: any
}

const options: ExistingCompanyContext = {
  formationState: initialStateObj,
  setFormationState: stateObj => {},
  formationStateList: [initialStateObj],
  address: initialAddressObj,
  setAddress: address => {},
  stateId: '',
  setStateId: id => {},
  einNumber: '',
  setEinNumber: einNumber => {},
  companyType: initialCompanyTypeObj,
  setCompanyType: formationState => {},
  sharesNumber: '',
  setSharesNumber: sharesNumber => {},
  fileDate: '',
  setFileDate: fileDate => {},
  name: '',
  setName: name => {},
  companyOwners: [initialOwnerObj],
  setCompanyOwners: () => {
    return
  },
}

export const ExistingCompanyContext = createContext(options)

const ExistingCompanyProvider: React.FC<ExistingCompanyProviderProps> = ({
  children,
}: ExistingCompanyProviderProps) => {
  const [stateLists, setStateLists] = useState<IStateProps[]>([])
  const [formationState, setFormationState] = useState<IStateProps>(initialStateObj)
  const [companyType, setCompanyType] = useState<ILegalTypes>(initialCompanyTypeObj)
  const [name, setName] = useState('')
  const [einNumber, setEinNumber] = useState('')
  const [stateId, setStateId] = useState('')
  const [sharesNumber, setSharesNumber] = useState('')
  const [fileDate, setFileDate] = useState('')
  const [address, setAddress] = useState<IAddressCompany>(initialAddressObj)
  const [companyOwners, setCompanyOwners] = useState<IClientCompanyOwners[]>([initialOwnerObj])

  const getStateList = async () => {
    try {
      const res = await fetchStates()
      setStateLists(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }
  useEffect(() => {
    getStateList()
  }, [])

  return (
    <ExistingCompanyContext.Provider
      value={{
        formationStateList: stateLists,
        formationState,
        setFormationState,
        companyType,
        setCompanyType,
        name,
        setName,
        einNumber,
        setEinNumber,
        stateId,
        setStateId,
        sharesNumber,
        setSharesNumber,
        fileDate,
        setFileDate,
        address,
        setAddress,
        companyOwners,
        setCompanyOwners,
      }}
    >
      {children}
    </ExistingCompanyContext.Provider>
  )
}

export default ExistingCompanyProvider
