import { TableCell, TableRow } from '@material-ui/core'
import { INotificationLogs } from 'api/notificationLogs'
import * as React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { htmlRegexG } from 'utils/functions'

interface ILogsRowProps {
  logs: INotificationLogs
}

const LogsRow: React.FC<ILogsRowProps> = ({ logs }) => {
  return (
    <TableRow style={{ cursor: 'pointer' }}>
      {/* <TableCell>{logs?.id}</TableCell> */}
      <TableCell>{logs?.company && logs?.company?.name}</TableCell>
      {/* <TableCell>
        {logs?.client &&
          `${logs?.client?.first_name || ''} ${logs?.client?.middle_name || ''} ${logs?.client?.last_name || ''}`}
      </TableCell> */}
      <TableCell>{logs?.type}</TableCell>
      <TableCell>{logs?.notification_type && logs?.notification_type}</TableCell>
      <TableCell style={{ maxWidth: 150, overflowWrap: 'break-word' }}>{logs?.subject}</TableCell>
      <TableCell style={{ maxWidth: 180, overflowWrap: 'break-word' }}>
        {logs?.content.replace(htmlRegexG, '')}
      </TableCell>
      <TableCell>{logs?.sender}</TableCell>
      <TableCell>{logs?.sent_to}</TableCell>
      <TableCell>{formatDateTimeToHumanFriendly(logs?.send_time)}</TableCell>
    </TableRow>
  )
}

export default LogsRow
