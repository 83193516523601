import * as Sentry from '@sentry/react'
import * as React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { RouteComponentProps } from 'react-router-dom'
import useRouter from 'use-react-router'

interface IErrorBoundaryWrapperProps {
  children: React.ReactNode
}

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>name: {error.name}</pre>
      <pre>message: {error.message}</pre>
      <pre>stack: {error.stack}</pre>
      <pre>{JSON.stringify(error, null, 4)}</pre>
      <button onClick={resetErrorBoundary}>Reload the page</button>
    </div>
  )
}

const errorHandler = (error: Error, info: { componentStack: string }) => {
  //log to an error logging client here
  Sentry.captureException(error)
}

function reloadPage(router: RouteComponentProps) {
  router.history.go(0)
}

const ErrorBoundaryWrapper: React.FC<IErrorBoundaryWrapperProps> = ({ children }: IErrorBoundaryWrapperProps) => {
  //https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
  // Automatically catches and sends JavaScript errors from inside a React component tree to Sentry
  const router = useRouter()

  const onReset = () => {
    reloadPage(router)

    // reset the state of your app so the error doesn't happen again
  }

  return (
    <Sentry.ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} onReset={onReset}>
        {children}
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
