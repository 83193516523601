import { FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core'
import BRTextField from 'components/common/textFields/BRTextField'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import React, { useState, useCallback, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './styles'
// import SelectField from './selectField'
import InProgressTable from './inProgressTable'
import {
  complianceCompanyClosed,
  complianceCompleteOrder,
  complianceInProgressOrder,
  ComplianceTasks,
  getComplianceTasks,
} from 'api/complianceCalendar'
import showErrorNotification from 'utils/showErrorNotification'
import CompanyOrderTypeField from 'components/common/companyOrder/orderTypeField'
import { complianceOrderTypeSuggestions, fetchSuggestions } from 'api/suggestions'
import { ISuggestionAttribute } from 'components/interfaces'
import { OrderDueDatePicker } from 'components/common/companyOrder/orderDueDatePicker'
import { datePickerDateFormat, formatDateToHumanFriendly } from 'utils/formatDate'
import SelectField from 'components/common/select'
import { COMPLIANCE_TASKS_STATUS, ComplianceColorResponse, complianceType } from 'utils/constant'
import showSuccessNotification from 'utils/showSuccessNotification'
import { Clear, KeyboardArrowDown } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { objToArray } from 'utils/functions'
import IOSSwitch from 'components/common/iosSwitch'

interface InProgressTaskProps {
  isUpdated?: boolean
  setIsUpdated?: any
}

const InProgressTask = ({ isUpdated, setIsUpdated }: InProgressTaskProps) => {
  const classes = useStyles()
  // const [complianceTasks, setComplianceTasks] = useState<ComplianceTasksData[]>([])
  const [complianceTasks, setComplianceTasks] = useState<ComplianceTasks>()

  const [isLoadingTasks, setIsLoadingTasks] = useState(false)

  const [orderTypeSuggestion, setOrderTypeSuggestion] = useState<ISuggestionAttribute>()
  const [companyOrderDueDate, setCompanyOrderDueDate] = useState<Date>()
  // const [companyName, setCompanyName] = useState<ISuggestionAttribute | null>()
  // const [clientName, setClientName] = useState<ISuggestionAttribute | null>()
  const [companyName, setCompanyName] = useState<number | null>()
  const [clientName, setClientName] = useState<number | null>()
  const [clientFiltr, setClientFiltr] = useState({ label: '', value: 0 })

  const [complianceTypeValue, setComplianceTypeValue] = React.useState<string>('')

  const [clientResponse, setClientResponse] = useState<ISuggestionAttribute>()
  const [isUnRead, seUnRead] = useState(false)

  const [query, setQuery] = useState('')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const getTasks = useCallback(async () => {
    const complianceTaskParams = {
      // company_id: (companyName && companyName?.value) || null,
      // client_id: (clientName && clientName?.value) || null,
      company_id: (companyName && companyName) || null,
      client_id: (clientName && clientName) || null,
      due_date: companyOrderDueDate && formatDateToHumanFriendly(companyOrderDueDate),
      order_type_id: orderTypeSuggestion?.value,
      status: COMPLIANCE_TASKS_STATUS?.inprogress,
      page: page + 1,
      page_size: rowsPerPage,
      query: query,
      response: clientResponse?.value,
      unread: isUnRead,
      compliance_type: complianceTypeValue,
    }
    setIsLoadingTasks(true)
    try {
      const tasks = await getComplianceTasks(complianceTaskParams)
      setComplianceTasks(tasks)
      setIsLoadingTasks(false)
    } catch (e) {
      showErrorNotification(e)
      setIsLoadingTasks(false)
    }
  }, [
    clientName,
    clientResponse?.value,
    companyName,
    companyOrderDueDate,
    complianceTypeValue,
    isUnRead,
    orderTypeSuggestion?.value,
    page,
    query,
    rowsPerPage,
  ])

  useEffect(() => {
    getTasks()
  }, [getTasks, isUpdated])

  const handleSelectingOrderType = data => {
    setOrderTypeSuggestion(data)
    // setCompanyOrderDueDate(undefined)
    // setCompanyName({
    //   value: 0,
    //   label: '',
    // })
    // setClientName({
    //   value: 0,
    //   label: '',
    // })
    // setCompanyName(null)
    // setClientName(null)
  }

  const handleDueDateSelected = date => {
    setCompanyOrderDueDate(date)
    // setCompanyName({
    //   value: 0,
    //   label: '',
    // })
    // setClientName({
    //   value: 0,
    //   label: '',
    // })
    // setCompanyName(0)
    // setClientName(0)
    // setOrderTypeSuggestion({
    //   value: 0,
    //   label: '',
    // })
  }
  // const addAttribute = React.useCallback((field: 'company_id' | 'client_id', obj: ISuggestionAttribute | null) => {
  //   if (field === 'company_id') {
  //     if (id) {
  //       setCompanyName(id)
  //       setClientName({
  //         value: 0,
  //         label: '',
  //       })
  //       setOrderTypeSuggestion({
  //         value: 0,
  //         label: '',
  //       })
  //       setCompanyOrderDueDate(undefined)
  //     }
  //   }
  //   if (field === 'client_id') {
  //     if (id) {
  //       setClientName(id)
  //       setCompanyName({
  //         value: 0,
  //         label: '',
  //       })
  //       setOrderTypeSuggestion({
  //         value: 0,
  //         label: '',
  //       })
  //       setCompanyOrderDueDate(undefined)
  //     }
  //   }
  // }, [])
  const addAttribute = React.useCallback((field: 'company_id' | 'client_id', id: number | null) => {
    if (field === 'company_id') {
      if (id) {
        setCompanyName(id)
        // setClientName(null)
        // setOrderTypeSuggestion({
        //   value: 0,
        //   label: '',
        // })
        // setCompanyOrderDueDate(undefined)
      }
    }
    if (field === 'client_id') {
      if (id) {
        setClientName(id)
        // setCompanyName(null)
        // setOrderTypeSuggestion({
        //   value: 0,
        //   label: '',
        // })
        // setCompanyOrderDueDate(undefined)
      }
    }
  }, [])

  const completeOrderHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.completed,
      }
      try {
        const complateOrder = await complianceCompleteOrder(payload)
        showSuccessNotification('Order Complete Success')
        setIsUpdated(true)
        getTasks()
        return complateOrder
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [getTasks, setIsUpdated]
  )

  const companyCloseHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.closed,
      }
      try {
        const companyClosed = await complianceCompanyClosed(payload)
        showSuccessNotification('Order Closed Success')
        setIsUpdated(true)
        getTasks()
        return companyClosed
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [getTasks, setIsUpdated]
  )
  const inProgressOrderHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.inprogress,
      }
      try {
        const companyClosed = await complianceInProgressOrder(payload)
        showSuccessNotification('Order InProgress Success')
        setIsUpdated(true)
        getTasks()
        return companyClosed
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [getTasks, setIsUpdated]
  )

  const responseColorHandler = (value: number) => {
    switch (value) {
      case 1:
        return ComplianceColorResponse[value]
      case 2:
        return ComplianceColorResponse[value]
      case 3:
        return ComplianceColorResponse[value]

      default:
        return 'gray'
    }
  }

  return (
    <div className={classes.subContainer}>
      <h1 className="secondary-heading">Tasks</h1>

      <HeadingHeader
        title="In Progress Tasks"
        isLink={false}
        // secondaryComponent={
        //   <BRTextField
        //     placeholder="Search"
        //     type="text"
        //     variant="outlined"
        //     style={{ width: '23.4%' }}
        //     className="search-filed-container"
        //     InputProps={{
        //       startAdornment: (
        //         <InputAdornment position="start">
        //           <SearchIcon />
        //         </InputAdornment>
        //       ),
        //     }}
        //     value={query}
        //     onChange={e => setQuery(e.target.value)}
        //   />
        // }
      />

      <Grid container spacing={3} style={{ width: 'calc(100% + 16px)' }}>
        {/* <Grid item xs={3}>
          <SelectField
            onOptionSelected={obj => addAttribute('company_id', obj ? obj : null)}
            title="Company"
            fetchSuggestions={fetchSuggestions}
            field="company_id"
            value={companyName || null}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={obj => addAttribute('client_id', obj ? obj : null)}
            title="Client"
            fetchSuggestions={fetchSuggestions}
            field="client_id"
            value={clientName || null}
          />
        </Grid> */}
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={option => addAttribute('company_id', option ? option.value : null)}
            title="Company"
            fetchSuggestions={fetchSuggestions}
            field="company_id"
            setFieldValue={setCompanyName}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={option => {
              addAttribute('client_id', option ? option.value : null)
            }}
            title="Client"
            fetchSuggestions={fetchSuggestions}
            field="client_id"
            setFieldValue={setClientName}
          />
        </Grid>
        <Grid item xs={3}>
          <CompanyOrderTypeField
            handleSelectingOrderType={handleSelectingOrderType}
            fetchSuggestions={complianceOrderTypeSuggestions}
            orderTypeSuggestion={orderTypeSuggestion || null}
            disabled={false} //{shouldDisableOrderTypeSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <OrderDueDatePicker
            className={classes.dueDatePicker}
            classes={{ root: classes.dateField }}
            onDueDateSelected={handleDueDateSelected}
            dueDateValue={companyOrderDueDate}
            dateFormat={datePickerDateFormat}
            InputProps={{
              startAdornment: companyOrderDueDate ? (
                <IconButton onClick={() => handleDueDateSelected(null)}>
                  <Clear />
                </IconButton>
              ) : (
                ''
              ),
            }}
          />
          {/* <SelectField label="Search by Company Name">
            {companies?.length
              ? companies?.map(item => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))
              : ''}
          </SelectField> */}
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            popupIcon={<KeyboardArrowDown />}
            renderOption={option => (
              <React.Fragment>
                <span className={classes.color} style={{ backgroundColor: responseColorHandler(option?.value) }} />
                <div className={classes.text}>{option.label as string}</div>
              </React.Fragment>
            )}
            options={clientResponses}
            getOptionLabel={(option: any) => option?.label}
            onChange={(event: any, newValue: any) => {
              setClientResponse(newValue)
            }}
            renderInput={params => <TextField {...params} label="Client Response" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="complianceTypeValue"
            options={complianceType as string[]}
            getOptionLabel={option => option}
            onInputChange={(event, newInputValue) => {
              setComplianceTypeValue(newInputValue)
            }}
            renderInput={params => <TextField {...params} label="Compliance Type" variant="outlined" />}
          />
        </Grid>
        <Grid item xs={3} style={{ alignSelf: 'center' }}>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={isUnRead}
                onClick={event => {
                  event.stopPropagation()
                }}
                onChange={e => {
                  seUnRead(!isUnRead)
                }}
                name="isUnRead"
              />
            }
            label=""
          />
          <label>View Unread</label>
        </Grid>
        {/*   <Grid item xs={3}>
          <SelectField menuItems={orders} label="Search by Order Type" />
        </Grid>
        {/* <Grid item xs={3}>
          <SelectField menuItems={listItems} label="Search by Due Date" />
        </Grid> 
        <Grid item xs={3}>
          <SelectField menuItems={clients} label="Search by Client Name" />
        </Grid>*/}
      </Grid>

      <br />

      <InProgressTable
        complianceTasks={complianceTasks || null}
        isLoadingTasks={isLoadingTasks}
        completeOrderHandler={completeOrderHandler}
        companyCloseHandler={companyCloseHandler}
        inProgressOrderHandler={inProgressOrderHandler}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setIsUpdated={setIsUpdated}
      />
    </div>
  )
}

export default InProgressTask

const clientResponses: ISuggestionAttribute[] = [
  { value: 1, label: 'Help Me Renew' },
  { value: 2, label: 'Already Renewed' },
  { value: 3, label: 'Business Closed' },
  { value: 0, label: 'No Response' },
]
