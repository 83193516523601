import {
  IDetailedCompanyResponse,
  IUpdateCompanyParams,
  legalTypesToApiFormat,
  ICompanyMeetingApiResponse,
  IPatchMeeting,
  legalTypesToHumanReadableFormat,
} from 'models/company'
import { updateMeeting } from 'api/meeting'
import { createAddress, editAddress } from 'api/addresses'

export const detailedCompanyResponseToUpdateCompanyParams = (
  updatedCompany: Partial<IDetailedCompanyResponse>
): IUpdateCompanyParams => {
  const companyPatchObject: IUpdateCompanyParams = {
    name: updatedCompany.name,
    country_of_formation: updatedCompany.country_of_formation ?? undefined,
    state_of_formation: updatedCompany.state_of_formation,
    legal_type: updatedCompany.legal_type && legalTypesToApiFormat[updatedCompany.legal_type],
    file_date: updatedCompany.file_date ?? undefined,
    business_activity: updatedCompany.business_activity ?? undefined,
    incorporator: updatedCompany.incorporator ?? undefined,
    meeting_date: updatedCompany.meeting_date,
    ein: updatedCompany.ein ?? undefined,
    state_id: updatedCompany.state_id,
    tax_structure: updatedCompany.tax_structure,
    number_of_shares: updatedCompany.number_of_shares,

    client_meeting_date_time: updatedCompany?.director_meeting ? updatedCompany?.director_meeting.date : undefined,
    shareholders_meeting_date_time: updatedCompany?.shareholder_meeting
      ? updatedCompany?.shareholder_meeting.date
      : undefined,

    // directors_meeting_address: updatedCompany.director_meeting?.address,
    // shareholder_meeting_address: updatedCompany.shareholder_meeting?.address,
    // primary_address: updatedCompany.primary_address,
    // mailing_address: updatedCompany.mailing_address,
  }

  if (updatedCompany.client && updatedCompany.client.id) {
    companyPatchObject.client_id = updatedCompany.client.id
  }

  return companyPatchObject
}

export const patchNestedCompanyFields = async (
  updatedCompany: Partial<IDetailedCompanyResponse>,
  initialCompany: IDetailedCompanyResponse
): Promise<void> => {
  //
  console.log('up', updatedCompany)
  if (updatedCompany.director_meeting) {
    //
    updateMeeting(toMeetingPatchObject(updatedCompany.director_meeting), initialCompany.director_meeting.id)
  }
  if (updatedCompany.shareholder_meeting) {
    //
    updateMeeting(toMeetingPatchObject(updatedCompany.shareholder_meeting), initialCompany.shareholder_meeting.id)
  }
  if (updatedCompany.primary_address === null && initialCompany.primary_address)
    editAddress({
      ...initialCompany.primary_address,
      company_id: undefined,
    })

  if (updatedCompany.primary_address)
    // editAddress({
    //   ...initialCompany?.addresses[0],
    //   ...updatedCompany.primary_address,
    //   company_id: initialCompany.id,
    // })
    createAddress({
      ...initialCompany?.primary_address,
      ...updatedCompany.primary_address,
      company_id: initialCompany.id,
    })

  if (updatedCompany.mailing_address === null && initialCompany.mailing_address)
    editAddress({
      ...initialCompany.mailing_address,
      company_id: undefined,
    })

  if (updatedCompany.mailing_address)
    // editAddress({
    //   ...initialCompany?.addresses[1],
    //   ...updatedCompany.mailing_address,
    //   company_id: initialCompany.id,
    // })
    createAddress({
      ...initialCompany?.mailing_address,
      ...updatedCompany.mailing_address,
      company_id: initialCompany.id,
    })
}

const toMeetingPatchObject = (meeting: Partial<ICompanyMeetingApiResponse>): IPatchMeeting => {
  return {
    date: meeting.date,
    address_id: meeting.address?.id,
  }
}

export const companyFieldsToHumanFriendly = (company: IDetailedCompanyResponse): IDetailedCompanyResponse => {
  return { ...company, legal_type: legalTypesToHumanReadableFormat[company.legal_type] }
}
