import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AddButton from 'components/common/buttons/primaryButton'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import 'react-phone-input-2/lib/style.css'
import { styles } from 'components/common/styles/createDialogStyles'
import ClientDetails from './stateDetails'
import { ICreatedClient, ICreatedState } from 'models/user'
import { IAddress } from 'models/address'

interface ICreateClientState {
  isOpen: boolean
}

interface ICreateClientProps extends WithStyles<typeof styles> {
  fetchClients?: () => void
  openByDefault?: boolean // means that there is no `Add client` button, modal is open by default
  onClientCreated?: (client: ICreatedState) => void // callback to use once user is created
  onClose?: () => void // cb if we closed the client creation dialog
  title?: string // pass the custom title if client creation component is called from another component
  isPopup?: boolean // if `true`, it makes dialog smaller so user can see the background dialog
  initialClient?: IAddress[] | undefined
}

class CreateClient extends React.Component<ICreateClientProps, ICreateClientState> {
  state: ICreateClientState = {
    isOpen: !!this.props.openByDefault,
  }

  switchIsOpen = (isOpen: boolean) => {
    this.setState({ isOpen })
  }

  clearAndClose = () => {
    if (this.props.onClose) this.props.onClose()
    this.switchIsOpen(false)
  }

  render() {
    const { classes, openByDefault, title, isPopup, initialClient } = this.props
    const { isOpen } = this.state
    // console.log('clients address => ', initialClient)

    return (
      <div>
        {!openByDefault && <AddButton onClick={() => this.switchIsOpen(true)}>Add City</AddButton>}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          classes={{
            paper: isPopup ? classes.popupPaper : classes.paper,
            container: classes.container,
          }}
          open={isOpen}
        >
          <div className={classes.titleButtonContainer}>
            <Typography className={classes.title}>{title || 'Add City'}</Typography>
            <IconButton onClick={() => this.clearAndClose()}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
          <ClientDetails
            onRequestSuccessful={client => {
              if (this.props.fetchClients) this.props.fetchClients()
              if (this.props.onClientCreated && client) this.props.onClientCreated(client)
              this.clearAndClose()
            }}
            // initialClient={initialClient}
          />
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(CreateClient)
