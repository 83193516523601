import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { ISalesRep } from 'models/user'
import { IClient, ICreatedClient, ICreatedEmployee, ICreatedSalesRep, IEmployee } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'
//import data1 from '../mock/employeeData'
//import date1  from '../mock/employeeData'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (salesrep: ISalesRep): Promise<ICreatedSalesRep> => {
  delete salesrep.extid // delete the extid, the backend should take care of it
  salesrep.user_type = '3'
  salesrep.email_1 = salesrep.email_1 !== '' ? salesrep.email_1 : 'a'
  return (await apiClient.post('user', snakeCaseKeys(salesrep))).data
}

export const updateClient = async (client: ISalesRep): Promise<ICreatedSalesRep> =>
  (await apiClient.post(`salesrepresentative/${client.id}`, snakeCaseKeys(client))).data

export const fetchSalesRep = async (id: number): Promise<ICreatedSalesRep> => {
  const res = (await apiClient.get(`salesrepresentatives/${id}`)).data
  return res
}
// export const fetchClients = async (query = ''): Promise<ICreatedClient[]> =>
//   (await apiClient.get(`user`, { params: { user_type: 'client', query } })).data
//added by ay
export const fetchClients = async (query = '') => {
  //(await apiClient.get(`user`, { params: { user_type: 'client', query } })).data
  //return data1;
}
//export const paginateClients = () => requestPaginated<ICreatedClient[]>({ url: '/user/client' })
//export const paginateClients = () => {return data1}
export const paginateClients = () => {
  return requestPaginated<ICreatedSalesRep[]>({ url: '/user/sales_rep' })
}

export const searchClients = async (query = ''): Promise<IClient[]> =>
  (await apiClient.get(`search/client`, { params: { query } })).data

export const getClientAddresses = async (id: number): Promise<IAddress[]> =>
  (await apiClient.get(`user/${id}/address`)).data

interface IClientAssetParams {
  clientId: number
  documentId: number
}

export const uploadClientAsset = async (clientId: number, file: File): Promise<UploadRequest> =>
  (await apiClient.post(`user/${clientId}/upload-asset`, { mime_type: file.type, file_name: file.name })).data

export const getClientDocuments = (clientId: number) => requestPaginated<IAsset[]>({ url: `user/${clientId}/asset` })

export const deleteClientDocument = async ({ clientId, documentId }: IClientAssetParams): Promise<void> => {
  await apiClient.delete(`user/${clientId}/asset/${documentId}`)
}

export const downloadClientDocumentURL = ({ clientId, documentId }: IClientAssetParams): string => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  return `${BASE_URL}/user/${clientId}/asset/${documentId}/download?jwt=${token}`
}
