import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { getCompanyTasks } from 'api/companies'
import { deleteTask } from 'api/task'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import AddTaskDialog from './addTaskDialog'
import OrderTaskRow from 'components/orders/OrderTaskRow'
import { ICompanyTask } from 'models/task'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'

const sortTasksByCompletedComparator = (a: ICompanyTask, b: ICompanyTask): 1 | -1 =>
  !!a.completed < !!b.completed ? -1 : 1

interface IOrderTasks {
  classes?: Record<string, string>
  orderId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    header: {
      padding: 5,
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      backgroundColor: '#f6f6f8',
      color: '#020202',
    },
    actionsHeaderCell: {
      paddingRight: 10,
    },
    addTaskColumn: {
      width: 100,
    },
  })
)

const CompanyTasks: React.FC<any> = props => {
  const classes = useStyles(props)

  const { company_id, company } = props

  const [tasks, setTasks] = React.useState<ICompanyTask[]>([])

  const [showAddTaskDialog, setShowAddTaskDialog] = React.useState(false)
  const [taskToEdit, setTaskToEdit] = React.useState<ICompanyTask>()

  const [showDeleteTaskDialog, setShowDelete] = React.useState(false)
  const [taskToDelete, setTaskToDelete] = React.useState<ICompanyTask | null>(null)
  const [taskDeletionInProgress, setIsDeleting] = React.useState(false)

  const getTasksAndSetState = React.useCallback(async () => {
    const tasks: ICompanyTask[] = await getCompanyTasks(company_id)
    setTasks(tasks)
  }, [company_id])

  React.useEffect(() => {
    if (!company_id) return // this here is because we are stupid and pass around some empty Order
    getTasksAndSetState()
  }, [getTasksAndSetState, company_id])

  const onAddTaskClick = React.useCallback(() => {
    setTaskToEdit(undefined)
    setShowAddTaskDialog(true)
  }, [setShowAddTaskDialog])

  const onTaskCreated = React.useCallback(() => {
    getTasksAndSetState()
    setShowAddTaskDialog(false)
  }, [getTasksAndSetState])

  const onDeleteTask = async () => {
    if (!taskToDelete?.id) return

    try {
      setIsDeleting(true)
      await deleteTask(taskToDelete.id)
      showSuccessNotification('Task deleted')
      getTasksAndSetState()
      setShowDelete(false)
    } catch (error) {
      showApiResponseError(error, 'Failed to delete a task')
    }
    setIsDeleting(false)
  }
  React.useEffect(() => {
    //setTaskToEdit([...taskToEdit, {company_id: company_id}])
  }, [])
  const handleEditClick = (task: ICompanyTask) => {
    setTaskToEdit(task)
    setShowAddTaskDialog(true)
  }

  const handleDeleteClick = (task: ICompanyTask) => {
    setTaskToDelete(task)
    setShowDelete(true)
  }

  return (
    <div className={classes.root}>
      <br />
      <br />

      {/* for creating or editing a task */}
      <AddTaskDialog
        open={showAddTaskDialog}
        onClose={() => setShowAddTaskDialog(false)}
        onTaskCreated={onTaskCreated}
        orderId={company_id}
        task={taskToEdit}
        mode={taskToEdit ? 'update' : 'create'}
        company={company}
        companyId={company_id}
      />

      <DeleteConfirmationDialog
        onDelete={onDeleteTask}
        isDeleting={taskDeletionInProgress}
        open={showDeleteTaskDialog}
        itemName={taskToDelete && taskToDelete.title}
        itemType="task"
        onClose={() => setShowDelete(false)}
      />

      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell padding="none" align="center">
              Tasks
            </TableCell>
            <TableCell padding="none" align="center">
              Assignee
            </TableCell>
            <TableCell padding="default" align="center">
              Due
            </TableCell>
            <TableCell padding="none" align="center">
              Priority
            </TableCell>
            <TableCell className={classes.actionsHeaderCell} padding="none" align="right">
              Actions
            </TableCell>
            <TableCell className={classes.addTaskColumn} padding="none" align="center">
              <Link component="button" variant="body2" onClick={onAddTaskClick} data-testid="add-task-button">
                + Add Task
              </Link>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tasks.sort(sortTasksByCompletedComparator).map((task: ICompanyTask) => (
            <OrderTaskRow
              key={task.id}
              task={task}
              setTasks={setTasks}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default CompanyTasks
