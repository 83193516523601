import { Dialog, FormControl, FormHelperText, IconButton, TextField } from '@material-ui/core'
import { createMissingItems, editMissingItems, MissingItemProps } from 'api/missingItems'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../styles'
import CloseIcon from '@material-ui/icons/Close'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showApiResponseError from 'utils/showApiResponseError'
import { notify } from '@jetkit/react'
import { Editor } from '@tinymce/tinymce-react'
import { EditorApiKey } from 'utils/constant'
import CheckboxLabel from 'components/common/checkboxLabel'

interface AddMissingItemDialogProps {
  isOpen: boolean
  close: () => void
  editedMissingItem?: MissingItemProps
  onRequestSuccessful?: () => void
}

const AddMissingItemDialog: React.FC<AddMissingItemDialogProps> = ({
  isOpen,
  close,
  editedMissingItem,
  onRequestSuccessful,
}) => {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [isTaxOrder, setIsTaxOrder] = useState(false)

  const buttonTitle = React.useMemo(() => (editedMissingItem ? 'Save Changes' : 'Add Missing Item'), [
    editedMissingItem,
  ])

  useEffect(() => {
    if (isOpen && editedMissingItem) {
      setName(editedMissingItem?.name || '')
      setDescription(editedMissingItem?.description || '')
      setIsTaxOrder(editedMissingItem?.tax_order || false)
    } else if (!isOpen) {
      setName('')
      setDescription('')
      setIsTaxOrder(false)
      errors.clearAll()
    }
  }, [editedMissingItem, isOpen])

  const setErrorMessages = React.useCallback(() => {
    if (!name) errors.setName(true)
    if (!description) errors.setDescription(true)
  }, [name, description])

  const useMissingItemsErrors = () => {
    const [name, setName] = React.useState(false)
    const [description, setDescription] = React.useState(false)

    const clearAll = React.useCallback(() => {
      setName(false)
      setDescription(false)
    }, [])

    return {
      name,
      setName,
      description,
      setDescription,
      clearAll,
    }
  }
  const errors = useMissingItemsErrors()

  const tryToSendRequest = React.useCallback(async () => {
    if (!name || !description) return
    const missingData: MissingItemProps = {
      name,
      description,
      tax_order: isTaxOrder,
    }
    setWaitingForResponse(true)
    try {
      if (editedMissingItem) {
        if (editedMissingItem?.id) {
          await editMissingItems(editedMissingItem?.id, missingData)
        }
      } else {
        await createMissingItems(missingData)
      }
      setWaitingForResponse(false)
      close()
      setName('')
      setDescription('')
      notify.success('Missing Item Created!')
      onRequestSuccessful && onRequestSuccessful()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, `Sorry, could not ${editedMissingItem ? 'edit' : 'create'} Missing`)
    }
  }, [close, description, editedMissingItem, isTaxOrder, name, onRequestSuccessful])

  const handleAddMissingItem = React.useCallback(() => {
    tryToSendRequest()
    setErrorMessages()
  }, [setErrorMessages, tryToSendRequest])

  console.log('isTaxOrder', isTaxOrder)

  return (
    <Dialog open={isOpen} PaperProps={{ className: classes.paper }}>
      <IconButton onClick={close} className={classes.closeButton}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <div className={classes.innerContainer} data-testid="notification-dialog-inner-container">
        <div className={classes.title}>Add Missing Item</div>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <TextField
            id="notificationTitle"
            label="Name"
            variant="outlined"
            value={name}
            onChange={e => setName(e.target.value)}
            // helperText={errors?.name ? 'Enter Name' : ''}
          />
          <FormHelperText className={classes.errorMsg}>{errors?.name ? '*Enter Name' : ''}</FormHelperText>
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          {/* <TextField
            id="notificationTitle"
            label="Description"
            multiline
            rows={4}
            variant="outlined"
            value={description}
            onChange={e => setDescription(e.target.value)}
            // helperText={errors?.description ? 'Enter Description' : ''}
          /> */}
          <Editor
            apiKey={EditorApiKey}
            init={{
              plugins: [
                'advlist autolink lists link charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              width: '100%',
              height: '20rem',
              valid_children: '+body[style]',
              valid_styles: { '*': '*' },
              init_instance_callback: function(editor) {
                editor.on('click', function(e) {
                  if (e.target.nodeName === 'INPUT') {
                    if (e.target.getAttribute('checked')) {
                      e.target.removeAttribute('checked')
                    } else {
                      e.target.setAttribute('checked', 'true')
                    }
                  }
                })
              },
            }}
            onEditorChange={e => setDescription(e)}
            initialValue={''}
            value={description}
          />
          <FormHelperText className={classes.errorMsg}>
            {errors?.description ? '*Enter Description' : ''}
          </FormHelperText>

          <CheckboxLabel
            label="Tax Order Item"
            checked={isTaxOrder}
            name="isTaxOrder"
            onChange={(event: any) => setIsTaxOrder(event.target.checked)}
          />
        </FormControl>
        <ButtonWithLoading
          kind="BRPrimary"
          wrapperClassName={classes.scheduleButton}
          isLoading={waitingForResponse}
          onClick={handleAddMissingItem}
        >
          {buttonTitle}
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default AddMissingItemDialog
