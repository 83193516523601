import FormLabel from '@material-ui/core/FormLabel'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Flag from '@material-ui/icons/Flag'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { ITaskPriority } from 'api/task'
import * as React from 'react'

const styles = () =>
  createStyles({
    value: {
      textTransform: 'capitalize',
      marginLeft: '0.5rem',
    },
    button: {
      width: 210,
    },
  })

interface ITaskPrioritiesProps extends WithStyles<typeof styles> {
  onPriorityChange: (priority: string) => void
  defaultPriority?: ITaskPriority
}

const TaskPriorities: React.FunctionComponent<ITaskPrioritiesProps> = ({
  defaultPriority,
  classes,
  onPriorityChange,
}) => {
  const handlePriorityChange = (event, newPriority) => {
    if (newPriority) {
      onPriorityChange(newPriority)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0.5rem 0' }}>
      <FormLabel>Priority</FormLabel>
      <ToggleButtonGroup
        style={{ backgroundColor: '#e4e4e8', margin: '0.5rem 0' }}
        onChange={handlePriorityChange}
        exclusive
        value={defaultPriority}
      >
        <ToggleButton value="Daily" className={classes.button}>
          <Flag style={{ color: '#133476' }} />
          <Typography data-testid="priority-low" className={classes.value}>
            Daily
          </Typography>
        </ToggleButton>
        <ToggleButton value="Weekly" className={classes.button}>
          <Flag style={{ color: 'orange' }} />
          <Typography data-testid="priority-medium" className={classes.value}>
            Soft
          </Typography>
        </ToggleButton>
        {/* <ToggleButton value="CallBack" className={classes.button}>
          <Flag style={{ color: 'red' }} />
          <Typography data-testid="priority-high" className={classes.value}>
            Call Backs
          </Typography>
        </ToggleButton> */}
      </ToggleButtonGroup>
    </div>
  )
}

export default withStyles(styles)(TaskPriorities)
