import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { fetchClient } from 'api/clients'
import { createCommission, getCommissionsTypes } from 'api/commission'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import ModalTitle from 'components/common/modalTitle'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { ISuggestionAttribute } from 'components/interfaces'
import { ICommission, NegativeOnlyCommissionTypes } from 'models/commission'
import { IClient } from 'models/user'
import * as React from 'react'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { fromSnakeCaseToTitle } from 'utils/toTitleCase'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transactionDialog: {
      padding: '50px',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
  })
)

interface CommissionDialogProps {
  editedClient?: IClient
  dialogOpened: boolean
  onClose: () => void
  fetchCommissions: () => void
  changeClientField: (field: keyof IClient, value: ValueOf<IClient>) => void
}

const CommissionDialog: React.FC<CommissionDialogProps> = ({
  editedClient,
  dialogOpened,
  onClose,
  fetchCommissions,
  changeClientField,
}) => {
  const [amountFieldValue, onChangeAmountField] = React.useState<string>('')
  const [referenceFieldValue, onChangeReferenceField] = React.useState<string>('')
  const [typeFieldValue, onChangeTypeField] = React.useState<string>('')

  const classes = useStyles()

  const fetchCommissionsTypes = async () => {
    const types = { commission_types: ['credit', 'debit'] } //await getCommissionsTypes()
    const values = types.commission_types.map(e => {
      return {
        value: e,
        label: fromSnakeCaseToTitle(e),
      }
    })
    return values
  }

  const validateCommission = (commission: ICommission) => {
    if (NegativeOnlyCommissionTypes.includes(commission.type) && commission.amount_cents >= 0) {
      return false
    }
    return true
  }

  const validateClient = (editedClient?: IClient) => {
    /*
    the transaction can be created only of the client as an affiliate
    */
    // if (!editedClient?.affiliate_id) {
    //   showErrorNotification("the client doesn't have an affiliate")
    //   return false
    // }
    return true
  }

  const onSubmitFormClick = async () => {
    if (!validateClient(editedClient)) return false

    if (!typeFieldValue?.length || !amountFieldValue) {
      showErrorNotification('Please add amount and type')

      return false
    }
    // parse string with decimals to integer representing cents
    // const amountCents: number = Math.round(parseFloat(amountFieldValue) * 100)
    const commission: ICommission = {
      type: typeFieldValue,
      reference: referenceFieldValue,
      amount_cents: parseFloat(amountFieldValue),
      client_id: editedClient?.id || 0,
      // affiliate_extid: editedClient?.affiliate?.extid || '',
    }
    if (!validateCommission(commission)) {
      showErrorNotification('the amount has to be negative for the types withdrawal and apply credit')
      return false
    }
    try {
      await createCommission(commission)
      const data = await fetchClient(editedClient?.id || 0)
      changeClientField('credit', data.credit)
      await fetchCommissions()
      onChangeTypeField('')
      onChangeAmountField('')
      showSuccessNotification('Added Successfully')
      return true
    } catch (e) {
      showErrorNotification(e)
      return false
    }
  }

  return (
    <Dialog fullWidth classes={{ paper: classes.transactionDialog }} open={dialogOpened}>
      <ModalTitle title="Add Transaction" onClose={onClose} />
      <BRTextField
        onChange={e => onChangeAmountField(e.target.value)}
        showCopyButton={true}
        required
        data-testid="amount"
        error={false}
        helperText=""
        label="Amount"
        className={classes.textField}
        type="number"
        name="amount"
      />
      <SelectField
        onOptionSelected={option => onChangeTypeField(option?.value + '')}
        fetchSuggestions={
          (fetchCommissionsTypes as unknown) as (query: string, field: string) => Promise<ISuggestionAttribute[]>
        }
        field="type"
        title="Type"
      />
      <BRTextField
        onChange={e => onChangeReferenceField(e.target.value)}
        showCopyButton={true}
        required
        data-testid="reference"
        error={false}
        helperText=""
        label="Reference"
        className={classes.textField}
        type="text"
        name="reference"
      />
      <SubmitFormButton onFormSubmitted={onClose} title={'Update Commissions'} onClick={onSubmitFormClick} />
    </Dialog>
  )
}

export default CommissionDialog
