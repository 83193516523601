import React, { useState, useEffect } from 'react'
import { createStyles, Grid, makeStyles, TextareaAutosize } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import showErrorNotification from 'utils/showErrorNotification'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { Theme } from 'tinymce'
import { addCallLogsNote, getCallLogsNoteById } from 'api/callLogs'
import { reversedArray } from 'utils/functions'
interface ICallLogsRowProps {
  callLogsData: any
  getAllData: () => void
}
export interface NotesData {
  call_logs_id?: number
  notes?: string
  created_by?: string
  id?: number
  created_at?: string
}
const InnerTableItem: React.FC<ICallLogsRowProps> = ({ callLogsData, getAllData }) => {
  const classes = useStyles()
  const [noteText, setNoteText] = useState<string>('')
  const [notes, setNotes] = useState<NotesData[]>([])
  const [getNoteLoading, setGetNoteLoading] = useState<boolean>(false)
  const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false)

  const getNotes = async (id: string | undefined) => {
    setGetNoteLoading(true)
    try {
      if (id) {
        const notes = await getCallLogsNoteById(id)
        const notesReversed = reversedArray(notes)
        setNotes(notesReversed)
        setGetNoteLoading(false)
      }
    } catch (error) {
      showErrorNotification(error)
    }
    setGetNoteLoading(false)
  }

  const handleAddNote = async () => {
    setAddNoteLoading(true)
    const data = {
      notes: noteText,
      call_logs_id: callLogsData?.id,
    }
    if (data) {
      try {
        await addCallLogsNote(data)
        setNoteText('')
        getAllData()
        getNotes(callLogsData?.id)
        setAddNoteLoading(false)
      } catch (error) {
        showErrorNotification(error)
      }
    }
  }

  useEffect(() => {
    if (callLogsData?.id) {
      getNotes(callLogsData?.id)
    }
  }, [callLogsData?.id])
  return (
    <div className={classes.innerItems}>
      <Grid container spacing={3} className="text-box-grid">
        <Grid item xs={6}>
          <div className="text-box">
            <TextareaAutosize
              className="text-area"
              placeholder="Notes/ Descriptions"
              rowsMax="10"
              rowsMin="10"
              value={noteText}
              name="noteText"
              disabled={callLogsData?.status === 'completed'}
              onChange={e => setNoteText(e.target.value)}
            />
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="save-btn"
              onClick={handleAddNote}
              isLoading={addNoteLoading}
              disabled={!noteText.length || callLogsData?.status === 'completed'}
            >
              Save
            </ButtonWithLoading>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-box-notes">
            {getNoteLoading
              ? [1, 2, 3, 4, 5, 6].map(key => <Skeleton animation="wave" variant="text" key={key} />)
              : notes?.length
              ? notes?.map(note => (
                  <div className="bottom-space" key={note?.id}>
                    <p className="dark-text">{note?.notes}</p>
                    <p className="dark-text">
                      {note?.created_by} {formatDateTimeToHumanFriendly(note?.created_at || '')}
                    </p>
                  </div>
                ))
              : 'No notes found'}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default InnerTableItem

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerItems: {
      minHeight: '50px',
      width: '100%',
      marginTop: '1.25rem',

      '& .dark-text': {
        color: '#020202',
        paddingBottom: '0.5rem',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        display: 'flex',
        // flexWrap: 'wrap',
      },

      '& .text-box-grid': {
        margin: '2rem 0',
        marginTop: '1rem',
        width: '100%',

        '& .text-box': {
          background: 'white',
          minHeight: '200px',
          padding: '1rem',
          fontWeight: '500',
        },
        '& .text-box-notes': {
          background: 'white',
          height: '200px',
          overflowY: 'auto',
          padding: '1rem',
          fontWeight: '500',
        },

        '& .text-area': {
          resize: 'none',
          background: 'inherit',
          border: 'none',
          outline: 'none',
          width: '100%',

          '&::placeholder': {
            color: '#979797',
            fontSize: '2rem,',
          },
        },
        '& .save-btn': {
          '& .MuiButtonBase-root': {
            background: '#788190',
            color: '#fff',
            minWidth: '100px',
            height: 'auto',
          },
          background: '#788190',
          color: '#fff',
          width: '100px',
          height: 'auto',
        },
      },
    },
  })
)
