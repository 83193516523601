import { ITaxOrder } from 'models/taxOrder'
import { ITaskPriority } from 'api/task'
import { ICompany } from 'models/company'
import { IDocument } from 'models/document'
import { IOrder } from 'models/order'
import { IUser } from 'models/user'
import { ComplianceTasksData } from 'api/complianceCalendar'

export type IDocsStatus = 'open' | 'sent_to_client' | 'signed' | 'sent_to_cpuc'
export type IHumanReadableDocsStatus = 'Open' | 'Sent To Client' | 'Signed By Client' | 'Completed'
export const docsStatusList: IHumanReadableDocsStatus[] = ['Open', 'Sent To Client', 'Signed By Client', 'Completed']

export const docStatusHumanReadableToEnum = (status: IHumanReadableDocsStatus): IDocsStatus => {
  return {
    Open: 'open',
    'Sent To Client': 'sent_to_client',
    'Signed By Client': 'signed',
    Completed: 'sent_to_cpuc',
  }[status] as IDocsStatus
}

export const docStatusEnumToHumanReadable = (status?: IDocsStatus): IHumanReadableDocsStatus | undefined => {
  if (!status) {
    return
  }
  return {
    open: 'Open',
    sent_to_client: 'Sent To Client',
    signed: 'Signed By Client',
    sent_to_cpuc: 'Completed',
  }[status] as IHumanReadableDocsStatus
}

export const colorForDocsStatus = (status: IDocsStatus): string => {
  return {
    open: '#ffffff',
    sent_to_client: '#ffedbd',
    signed: '#bdd5ff',
    sent_to_cpuc: '#bdffbd',
  }[status] as IHumanReadableDocsStatus
}

export type ITaskType = 'company' | 'general' | 'client' | 'order' | 'task' | 'prospect' | 'taxorder' | 'compliance'

export interface ITaskBase {
  readonly id?: number
  title: string
  details?: string // description
  priority: ITaskPriority
  task_type: ITaskType
  due_date?: string
  assigned_to?: number | null // required by the backend for updating the assignee of the task
  client_id?: number | null
  company_id?: number | null
  order_id?: number | null
  description?: string
  prospect_id?: number | null
  prospect_name?: string

  tax_order_id?: number | null
  tax_name?: string
  task_note?: string

  compliance_task_id?: number | null
  compliance_name?: string
}

export interface ITask extends ITaskBase {
  readonly id?: number
  assignee?: IUser | null // assignee details
  creator?: IUser
  completed: string | null //date time
  completed_by?: IUser
  completed_at?: string
  created_at?: string
  assigned_at?: string

  created_by: number | null
  company?: ICompany
  client?: IUser
  order?: IOrder
  assigned_by?: string

  prospect?: any
  prospect_name?: string

  tax?: ITaxOrder
  tax_name?: string

  compliance?: ComplianceTasksData
  compliance_name?: string
  compliance_task_id?: number

  filer?: string

  tax_order_type?: string
  tax_year?: string
}

export interface IOrderTask extends ITask {
  subtasks?: IOrderTask[]
  can_have_docs: boolean
  parent_id: number | null
  doc_status: IDocsStatus
  document?: IDocument
  status: string
  is_activated: boolean
}

export interface ICompanyTask extends ITask {
  subtasks?: IOrderTask[]
  can_have_docs: boolean
  parent_id: number | null
  doc_status: IDocsStatus
  document?: IDocument
  status: string
  is_activated: boolean
}

export interface TaskCompletion {
  id: number
}

export type TaskSelectKeys =
  | 'client_id'
  | 'company_id'
  | 'order_id'
  | 'assigned_to'
  | 'prospect_id'
  | 'tax_order_id'
  | 'compliance_task_id'

export interface ITaskNotify {
  id: number
  title: string
  description: string
  due_date: string
  priority: number
  assigned_to: number
  order_id: number
  company_id: number
  client_id: number
  prospect_id: number
  tax_order_id: number
  compliance_task_id: number

  is_deleted: boolean
  deleted_at: string
  task_type: string
  completed: boolean
  created_by: string
  completed_at: string
  created_at: string
  assigned_at: string
  assigned_by: string
  completed_by: string
  employee_image: string
}
