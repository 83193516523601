import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { default as AddButton } from 'components/common/buttons/primaryButton'
import CreateCompanyOrderDialog from 'components/tasks/companyOrderConfigDialog/createNewOrderDialog'
import { useHandleDialogAccessibility } from 'hooks/useHandleDialogAccessibility'
import { IOrder } from 'models/order'
import * as React from 'react'
import { CompanyOrderContext } from './CompanyOrderContext'
import { useCompanyOrderConfig } from './hooks/useCompanyOrderEdit'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    paper: {
      padding: '30px 0',
      margin: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      width: 500,
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      width: 250,
      marginTop: 20,
    },
    formContainer: {
      width: 430,
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formationOrderPriceWrapper: {
      width: '100%',
      margin: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    moneyTextField: {
      width: '49%',
      height: 50,
      margin: 0,
    },
  })
)

interface ICreateOrderProps {
  companyId?: number
  hideCompanySelect?: boolean
  onOrderCreated?: (order: IOrder) => void
  isCompanyCreation?: boolean
  isFormationOrder?: boolean
  buttonName?: string
  btnClassName?: string
  btnStyle?: React.CSSProperties
  complianceId?: number
  setIsUpdated?: any
}

/**
 * Allows to open a modal and create a new company order.
 *
 * @param companyId: Company that a new order may belong to
 * @param onOrderCreated: When an order is created we may wanna re-render the table containing all orders to display a new one
 * @param isCompanyCreation: When creating an order during the new company creation flow we may wanna alter some business logic
 */
const CreateOrder: React.FC<ICreateOrderProps> = props => {
  const {
    companyId,
    isCompanyCreation,
    isFormationOrder,
    buttonName,
    btnClassName,
    btnStyle,
    complianceId,
    setIsUpdated,
  } = props
  const classes = useStyles(props)
  const { setReloadTable } = React.useContext(CompanyOrderContext)
  const { isOpen, handleOpen, handleSave, handleClose } = useHandleDialogAccessibility({
    saveSideEffect: () => {
      setReloadTable(true)
    },
  })
  const { setCompanyOrderDetails } = useCompanyOrderConfig({
    useUpdate: true,
  })
  // const { setReloadTable } = React.useContext(CompanyOrderContext)
  React.useEffect(() => {
    isFormationOrder && handleOpen()
    console.log('form', isFormationOrder)
  }, [isFormationOrder, handleOpen])
  React.useEffect(() => {
    if (isFormationOrder) {
      const order_type: any = {
        affiliate: null,
        commission: 0,
        company_users: [],
        company_id: 0,
        creator: null,
        future_orders: [],
        documents: [],
        due_date: null,
        id: 0,
        last_update: null,
        order_stage: null,
        order_stage_id: null,
        order_type: { id: 3, name: 'Business Formation' },
        order_type_id: 3,
        assignee_id: null,
        assignee: null,
        payment_status: 'not_paid',
        price: 0,
        required_orders: [],
        sales_rep: null,
        sales_rep_id: null,
        tasks: [],
        order_stages: null,
      }
      setCompanyOrderDetails(order_type)
    }
  }, [])

  return (
    <div className={classes.container}>
      <AddButton className={btnClassName} style={btnStyle} onClick={handleOpen}>
        {buttonName ? buttonName : 'New Order'}
      </AddButton>
      <CreateCompanyOrderDialog
        companyId={companyId}
        open={isOpen}
        isCompanyCreation={isCompanyCreation}
        onClose={handleClose}
        onSave={handleSave}
        complianceId={complianceId}
        setIsUpdated={setIsUpdated}
      />
    </div>
  )
}

export default CreateOrder
