import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { notifyClient } from 'api/orders'
import AssetsTable from 'components/common/assets/assetsTable'
import SearchBar from 'components/common/searchBar'
import { AssetType, assetTypeToDocumentHandler, DocumentHandler, UploadRequestClass } from 'models/asset'
import * as React from 'react'
import ButtonWithLoading from '../buttons/buttonWithLoadingProgress'
import useRouter from 'hooks/router'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'

export type AssetDeleteCallBack = (assetId: number) => Promise<void>
export const AssetsDeleteCallBackContext = React.createContext<AssetDeleteCallBack | undefined>(undefined)

export const AssetsUploadCallBackContext = React.createContext<UploadRequestClass | undefined>(undefined)

export interface IDocumentUploadProps {
  type: AssetType
  objectId: number // id of object of type `type` in a database. e.g. company_id, client_id, order_id
  isUpload?: boolean
  isPdfUploaded?: boolean
  taxId?: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBarContainer: {
      marginBottom: 20,
      marginTop: 0,
    },
    root: {
      width: '100%',
    },
    uploadSec: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      '& .campany-detail': {
        fontSize: '1.6rem',
        fontFamily: theme.typography.h1.fontFamily,
        width: '100%',
      },
    },
    filterWithTitle: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '250px',
    },
    filterTitle: {
      fontSize: 14,
    },
    toggleButtonGroup: {
      height: 38,
      paddingTop: '0.125rem',
    },
    toggleButton: {
      height: '100%',
      width: '50%',
    },
    isFileButtonText: {
      fontSize: 12,
      color: 'grey',
    },
  })
)

const DocumentUploads: React.FC<IDocumentUploadProps> = ({
  type,
  objectId,
  isUpload,
  isPdfUploaded,
  taxId,
}: IDocumentUploadProps) => {
  const classes = useStyles()
  const router = useRouter()

  const [searchQuery, setSearchQuery] = React.useState('')
  const [loading, setloading] = React.useState<boolean>(false)

  const [documentType, setDocumentType] = React.useState(false)

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const documentHandler: DocumentHandler = React.useMemo(() => {
    const DocumentHandler = assetTypeToDocumentHandler[type]
    return new DocumentHandler()
  }, [type])
  const notifyclient = async () => {
    let company_id: number | null = null
    let order_id: number | null = null

    if (router.location.pathname.includes('company-order')) {
      const params: any = router.match.params
      order_id = params?.id ? params?.id : null
    } else if (router.location.pathname.includes('company')) {
      const params: any = router.match.params
      company_id = params?.companyId ? params?.companyId : null
    }
    try {
      setloading(true)
      await notifyClient(order_id, company_id)
      setloading(false)
      showSuccessNotification('Client Notified Successfully')
    } catch (error) {
      showApiResponseError(error, 'Error Sending Email')
      setloading(false)
    }
  }

  return (
    <AssetsUploadCallBackContext.Provider value={documentHandler.UploadRequest}>
      <div className={classes.root}>
        {isUpload ? (
          <>
            <div className={classes.uploadSec}>
              <h1 className="campany-detail">Uploads</h1>

              {type === 'personal' ? (
                <div className={classes.filterWithTitle}>
                  <Typography className={classes.filterTitle}>Document Status:</Typography>
                  <ToggleButtonGroup
                    className={classes.toggleButtonGroup}
                    onChange={(e: any, value: any) => setDocumentType(value)}
                    exclusive
                    value={documentType}
                  >
                    <ToggleButton value={false} className={classes.toggleButton}>
                      <Typography className={classes.isFileButtonText}>Open</Typography>
                    </ToggleButton>
                    <ToggleButton value={true} className={classes.toggleButton}>
                      <Typography className={classes.isFileButtonText}>Archived</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              ) : (
                ''
              )}

              <ButtonWithLoading
                isLoading={loading}
                onClick={notifyclient}
                className="notify-button"
                style={{ width: '12rem', marginLeft: '1rem' }}
              >
                Notify Client
              </ButtonWithLoading>

              <ButtonWithLoading
                isLoading={loading}
                onClick={() => setIsOpen(true)}
                className="notify-button"
                style={{ width: '12rem', marginLeft: '1rem' }}
              >
                Download All
              </ButtonWithLoading>
            </div>
            <div className={classes.searchBarContainer}>
              <SearchBar onChange={setSearchQuery} placeholder="Search by file name" />
            </div>
          </>
        ) : (
          ''
        )}

        <AssetsTable
          isPdfUploaded={isPdfUploaded}
          type={type}
          objectId={objectId}
          filterQuery={searchQuery}
          getDocuments={documentHandler.getDocuments}
          deleteDocument={documentHandler.deleteDocument}
          getAssetDownloadURL={documentHandler.getDocumentDownloadURL}
          isArchive={documentType}
          taxId={taxId}
        />
      </div>
      <ModalWrapper
        // onClose={() => getCallWidgets()}
        maxWidth={'lg'}
        open={isOpen}
        setOpen={() => setIsOpen(false)}
        heading={'Uploads'}
      >
        <AssetsTable
          isDownload={true}
          isPdfUploaded={isPdfUploaded}
          type={type}
          objectId={objectId}
          filterQuery={searchQuery}
          getDocuments={documentHandler.getDocuments}
          deleteDocument={documentHandler.deleteDocument}
          getAssetDownloadURL={documentHandler.getDocumentDownloadURL}
          isArchive={documentType}
          taxId={taxId}
        />
      </ModalWrapper>
    </AssetsUploadCallBackContext.Provider>
  )
}

DocumentUploads.defaultProps = {
  isUpload: true,
  isPdfUploaded: false,
}

export default DocumentUploads
