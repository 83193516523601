import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import React, { useMemo } from 'react'
import { useStyles } from '../style'
import { skeletons } from 'components/common/table'
import TableReportHead from './tableReportHead'
import useSortTable from 'hooks/useSortTable'
import { usePagedTable, PagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import TableReportRow from './tableReportRow'
import { EmployeeReportData, getEmployeeReport } from 'api/employeeReport'
import moment from 'moment'

interface TableReportProps {
  employeeId: number
  startData: any
  endData: any
}

const TableReport = ({ employeeId, startData, endData }: TableReportProps) => {
  const classes = useStyles()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'logged_at' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(
    () => <TableReportHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(
    () =>
      getEmployeeReport(
        employeeId,
        moment(startData)?.format('YYYY-MM-DD 00:00 Z'),
        moment(endData)?.format('YYYY-MM-DD HH:mm Z')
      )({ sort_order: order, sort_by }),
    [employeeId, endData, startData, order, sort_by]
  )

  const pagedTable = usePagedTable<EmployeeReportData>({
    apiCall: memoApiCall,
  })

  return (
    <div className={classes.tableContainer}>
      <p className={classes.tableTitle}>Report</p>
      <br />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({ numberOfColumns: 10, sizes: [120, 20, 20, 120, 200, 120, 12, 12, 12, 12], numberOfRows: 6 })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={10}
          emptyRowText="No Employee Report Found"
          {...pagedTable.renderProps}
          renderRow={(tableReport: EmployeeReportData) => <TableReportRow tableReport={tableReport} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default TableReport
