import { TextField } from '@material-ui/core'
import { positiveNumericInput } from 'components/common/functions'
import * as React from 'react'
import { SetFieldValueContext } from 'utils/FormGenerator'
import { IFormFieldProps } from 'utils/FormGenerator/formTypings'
import { useStyles } from 'utils/FormGenerator/styles'
import { isNumberField } from 'utils/FormGenerator/typeAssertionFunction'
import CopyButton from 'components/common/CopyButton'

const NumberFieldComponent = ({ field, value }: IFormFieldProps<string>) => {
  if (!isNumberField(field)) {
    throw new Error('type error')
  }

  const classes = useStyles()

  const setFieldValue = React.useContext(SetFieldValueContext)

  return (
    <div className={classes.copyButtonContainer}>
      <CopyButton valueToCopy={value} />
      <TextField
        name={field.name}
        label={field.label}
        value={value}
        classes={{ root: classes.fieldRoot }}
        InputLabelProps={{ classes: { root: classes.fieldLabel, shrink: classes.fieldLabelShrink }, shrink: true }}
        onChange={event => setFieldValue(field.name, positiveNumericInput(event.target.value))}
        InputProps={{ classes: { root: classes.textFieldInputRoot, underline: classes.textInputUnderline } }}
      />
    </div>
  )
}

export default NumberFieldComponent
