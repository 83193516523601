import { Checkbox, createStyles, makeStyles, TableCell, TableRow } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import * as React from 'react'
import { datePickerDateFormat, daySubstring, monthSubstring, yearSubstring } from 'utils/formatDate'
import { IEditableNotificationDate } from '.'

const useStyles = makeStyles(() =>
  createStyles({
    cell: {
      fontSize: '1.125rem',
      letterSpacing: '0.09px',
      paddingBottom: 0,
      paddingTop: 0,
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    datePickerButton: {
      padding: 0,
      marginRight: '0.3rem',
      display: 'block',
      marginBottom: 2,
    },
    datePickerCapitalInput: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      flexDirection: 'row-reverse',
    },
    datePickerInput: {
      padding: 0,
      border: '0px solid black',
      marginBottom: '2px',
      opacity: 0.6,
    },
    datePicker: {
      maxWidth: '7.5rem',
      flexDirection: 'row-reverse',
      border: '0px solid black',
      display: 'block',
    },
  })
)

const CustomizableDateRow: React.FC<{
  date: IEditableNotificationDate
  handleDateChange: (date: IEditableNotificationDate) => void
  id: number
}> = ({ date, handleDateChange, id }) => {
  const classes = useStyles()

  const handleUseFillDateChecked = React.useCallback(
    (checked: boolean) => {
      const updatedYear = { ...date }
      updatedYear.file_date = checked
      if (checked) {
        updatedYear.skip = false
      }
      handleDateChange(updatedYear)
    },
    [date, handleDateChange]
  )

  const handleSkipChecked = React.useCallback(
    checked => {
      const updatedYear = { ...date }
      updatedYear.skip = checked
      if (checked) {
        updatedYear.file_date = false
      }
      handleDateChange(updatedYear)
    },
    [date, handleDateChange]
  )

  const handleDateChanged = React.useCallback(
    (newDate: Date) => {
      const updatedYear = { ...date }
      updatedYear.schedule_date = moment(
        `${monthSubstring(newDate)}/${daySubstring(newDate)}/${yearSubstring(newDate)}`
      ).toDate()
      handleDateChange(updatedYear)
    },
    [handleDateChange, date]
  )

  console.log('date', date)

  return (
    <TableRow data-testid={`row-for-year-${yearSubstring(date.schedule_date)}`}>
      {/* <TableCell className={classes.cell}>{date.date.getFullYear()}</TableCell> */}
      <TableCell className={classes.cell}>{id}</TableCell>
      <TableCell className={classes.cell} style={{ paddingLeft: 0, minWidth: 50 }}>
        {!date.skip && !date.file_date && (
          <KeyboardDatePicker
            autoOk
            KeyboardButtonProps={{ size: 'small', className: classes.datePickerButton }}
            className={classes.datePicker}
            format="MMM D"
            value={date.schedule_date}
            // For some reason type `MaterialUiPickersDate` cannot be casted to `Date` type.
            // So before that I cast it to `unknown`, like typescript suggests.
            onChange={date => handleDateChanged((date as unknown) as Date)}
            contentEditable={false}
            disableToolbar
            InputProps={{ disableUnderline: true, className: classes.datePickerCapitalInput }}
            // Next line should be ignored by eslint, because this prop has almost
            // the same name as one above and eslint does not like that.
            // Unfortunately, both of these props are required to style this component properly.
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ disabled: true, className: classes.datePickerInput }}
            inputVariant="filled"
            // minDate={moment(`01/01/${yearSubstring(date?.schedule_date)}`, datePickerDateFormat)}
            // maxDate={moment(`12/31/${yearSubstring(date?.schedule_date)}`, datePickerDateFormat)}
          />
        )}
      </TableCell>
      <TableCell className={classes.cell} style={{ paddingRight: 0 }}>
        <Checkbox
          data-testid={'use-file-date-checkbox'}
          color="default"
          checked={date.file_date}
          onClick={() => handleUseFillDateChecked(!date.file_date)}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <Checkbox
          data-testid={'skip-checkbox'}
          color="default"
          checked={date.skip}
          onClick={() => handleSkipChecked(!date.skip)}
        />
      </TableCell>
    </TableRow>
  )
}

export default CustomizableDateRow
