import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PrimaryButton from 'components/common/buttons/primaryButton'
import SecondaryButton from 'components/common/buttons/secondaryButton'
import * as React from 'react'
import { CompanyContext } from '.'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '1rem',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    controlButton: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    documentTitle: {
      fontSize: 30,
      color: theme.palette.text.primary,
      letterSpacing: 0.14,
    },
    companyName: {
      fontSize: 20,
      color: theme.palette.primary.main,
      letterSpacing: 0.09,
    },
  })
)

interface IDocumentHeaderProps {
  pdfLink: string | null
  onDocSaveClick: () => void
  title: string
}

function DocumentHeader({ onDocSaveClick, pdfLink, title }: IDocumentHeaderProps) {
  const classes = useStyles()
  const company = React.useContext(CompanyContext)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  return (
    <div className={classes.root}>
      {<Typography className={classes.documentTitle}>{title}</Typography>}
      <div className={classes.headerContainer}>
        {company ? (
          <Typography className={classes.companyName}>
            <a
              className={classes.companyName}
              target={'_blank'}
              rel="noopener noreferrer"
              href={`/company/${company.id}`}
            >
              {company.name}
            </a>
          </Typography>
        ) : (
          <div />
        )}
        <div className={classes.buttonsContainer}>
          <SecondaryButton
            isLoading={isSaving}
            style={{ marginRight: '1rem' }}
            onClick={async () => {
              setIsSaving(true)
              await onDocSaveClick()
              setIsSaving(false)
            }}
          >
            Save
          </SecondaryButton>
          {pdfLink && (
            <a href={pdfLink} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }} download>
              <PrimaryButton>Download PDF</PrimaryButton>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default DocumentHeader
