import * as React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: theme.customPalette.errorButtonColor,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.customPalette.errorButtonColor,
        boxShadow: '0 9px 8px -6px rgba(131, 0, 0, 0.5)',
      },
      '&:disabled': {
        backgroundColor: theme.customPalette.errorButtonColor,
        opacity: 0.7,
      },
      borderColor: theme.customPalette.errorButtonColor,
      height: 50,
    },
    disabled: {}, // to be overwritten by the parent component class
  })
)

interface IAddButtonProps {
  onClick?: () => void
  children?: React.ReactNode
  style?: React.CSSProperties
  classes?: Record<string, string>
  disabled?: boolean
}

const AddButton: React.FC<IAddButtonProps> = (props: IAddButtonProps) => {
  const classes = useStyles(props)
  const { onClick, children, style, disabled } = props
  return (
    <Button
      disabled={disabled}
      style={style}
      variant="contained"
      onClick={onClick}
      classes={{ disabled: classes.disabled }}
      className={classes.button}
      {...props}
    >
      {children}
    </Button>
  )
}

export default AddButton
