import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { specialOfferCompanies } from 'api/clientDashboard'
import React, { useState } from 'react'
import CalendlyModal from '../CalendlyModal'
import { useStyles } from './styles'

const OfferDialog = ({ open, setOpen, title, question, answers, btnText, isPopup, link, companies, offerId }) => {
  const classes = useStyles()

  const [selectCompany, setSelectCompany] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const specialOfferHandler = async () => {
    const offer_id: number = offerId
    const company_id: string = selectCompany

    try {
      const res = await specialOfferCompanies({ company_id, offer_id })
      if (res?.redirect_url) {
        window.open(res?.redirect_url, '_blank')
      }
      console.log('resresresresresres', res)

      return res
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.offerModalContainer}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <div className="offer-modal-header">
          <span className="modal-heading-sec">
            <h1 className="modal-heading">{title}</h1>
          </span>
          <Close onClick={handleClose} className="close" />
        </div>
        <DialogContent className="modal-content">
          <Divider />
          <DialogContentText id="alert-dialog-description">
            <div className="bottom-space">
              <p className="modal-dark-text">{question}</p>
            </div>

            <FormControl variant="outlined" className={classes.selectFormControl}>
              <InputLabel>Select your company</InputLabel>
              <Select
                value={selectCompany}
                label="Select your company"
                fullWidth
                // name="selectedDocType"
                onChange={(e: any) => setSelectCompany(e.target.value)}
              >
                {companies?.map(item => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <div className="bottom-space">
              <p className="modal-dark-text">{answers}</p>
            </div> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-footer">
          {isPopup ? (
            <span onClick={specialOfferHandler}>
              <CalendlyModal calendlyUrl={link} btnText={btnText} />
            </span>
          ) : (
            <Button
              variant="contained"
              className="modal-btn"
              onClick={() => {
                specialOfferHandler()
                link && window.open(link)
              }}
            >
              {btnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OfferDialog
