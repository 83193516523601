import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { getCompanyChangeLogs } from 'api/companies'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import useSortTable from 'hooks/useSortTable'
import { ChangeLogs } from 'models/company'
import React from 'react'
import LogsHead from './logsHead'
import LogsRow from './logsRow'

const ChangeLogsDialog = ({ open, setOpen, companyId }) => {
  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'changed_at' })

  const tableHead = React.useMemo(
    () => <LogsHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const { order, sort_by } = sortSettings

  const memoApiCall = React.useMemo(() => getCompanyChangeLogs(companyId)({ sort_order: order, sort_by, query }), [
    companyId,
    order,
    sort_by,
    query,
  ])

  const pagedTable = usePagedTable<ChangeLogs>({
    apiCall: memoApiCall,
  })

  React.useEffect(() => {
    pagedTable.reloadData()
  }, [open, pagedTable])

  return (
    <ModalWrapper open={open} setOpen={setOpen} heading={`Change Logs For #${companyId}`} maxWidth={'xl'}>
      <SearchBar onChange={setQuery} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 5, sizes: [200, 200, 200, 200, 200], numberOfRows: 5 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={5}
          emptyRowText="No Company Logs"
          {...pagedTable.renderProps}
          renderRow={(logs: ChangeLogs) => <LogsRow logs={logs} />}
          header={tableHead}
        />
      )}
    </ModalWrapper>
  )
}

export default ChangeLogsDialog
