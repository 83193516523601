import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const ClientTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>First Name</StyledTableCell>
        <StyledTableCell>Last Name</StyledTableCell>
        <StyledTableCell>Phone</StyledTableCell>
        <StyledTableCell>E-mail</StyledTableCell>
        <StyledTableCell>Type</StyledTableCell>
        <StyledTableCell>Status</StyledTableCell>
        <StyledTableCell>Creation Time</StyledTableCell>

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default ClientTableHead
