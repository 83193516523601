import React from 'react'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import CallMadeIcon from '@material-ui/icons/CallMade'
import Divider from '@material-ui/core/Divider'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundColor: '#f7f8f9',
      padding: '0.75rem 1rem',
      borderRadius: '4px',
      fontFamily: theme.typography.h1.fontFamily,
    },
    contentBox: {
      display: 'flex',
      paddingTop: '1.3rem',
      alignItems: 'center',
    },
    heading: {
      margin: '0',
      fontSize: '26px',
    },
    downArrow: {
      color: '#ff0617',
      marginLeft: '0.5rem',
    },
    upArrow: {
      color: '#0bc763',
      marginLeft: '0.5rem',
    },
    leftBox: {
      color: '#292929',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    rightBox: {
      color: '#788190',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    text: {
      fontSize: '0.85rem',
      fontFamily: theme.typography.body1.fontFamily,
    },
    divider: {
      height: '40px',
      position: 'relative',
      top: '25px',
    },
  })
)

interface EntriesCardProps {
  title?: string
  totalValue?: number
  date?: string
  filterValue?: number
  isIcrement?: boolean
  isDecrement?: boolean
  isLoading?: boolean
}

const EntriesCard = ({
  title,
  totalValue,
  date,
  filterValue,
  isIcrement,
  isDecrement,
  isLoading,
}: EntriesCardProps) => {
  const classes = useStyles()
  return (
    <Grid container spacing={0} className={classes.mainContainer}>
      <Grid item xs={5} className={classes.leftBox}>
        <p className={classes.text}>{title}</p>
        <div className={classes.contentBox}>
          {!isLoading ? <h2 className={classes.heading}>{totalValue}</h2> : <Skeleton variant="rect" width={50} />}

          {isIcrement ? <CallReceivedIcon className={classes.downArrow} /> : <></>}
          {isDecrement ? <CallMadeIcon className={classes.upArrow} /> : <></>}
        </div>
      </Grid>
      <Grid container xs={2} justify="center">
        <Divider orientation="vertical" className={classes.divider} />
      </Grid>
      <Grid item xs={5} className={classes.rightBox}>
        {!isLoading ? <p className={classes.text}>{date}</p> : <Skeleton variant="rect" width={125} height={32} />}
        <div className={classes.contentBox}>
          {!isLoading ? <h2 className={classes.heading}>{filterValue}</h2> : <Skeleton variant="rect" width={50} />}
        </div>
      </Grid>
    </Grid>
  )
}

export default EntriesCard
