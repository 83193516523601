import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import React from 'react'

const CommentsStyles = makeStyles({
  commentContainer: {
    marginTop: '1.5rem',
    fontFamily: 'Roboto',

    '& .info-text': {
      color: '#5f6368',
      fontSize: '12px',
      margin: '0',
      marginLeft: '4rem',
      paddingBottom: '0.5rem',
    },
    '& .comment-section': {
      display: 'flex',
      alignItems: 'center',

      '& .avatar': {
        marginRight: '1rem',
      },
      '& .comment-field': {
        background: '#fff',
        color: '#5f6368',
        padding: '12px',
        width: '84%',
        border: 'none',
        outline: 'none',
        borderRadius: '4px',
      },
    },
    '& .btn-section': {
      display: 'flex',
      justifyContent: 'end',
      marginRight: '1.2rem',
    },
  },
  saveBtn: {
    width: '80px',
    margin: '1rem 0.5rem',

    '& .MuiButtonBase-root': {
      margin: '0',
      padding: '0',
      height: '34px',
    },
  },
})

interface CommentsComponentProps {
  placeholder?: string
  infoText?: string | boolean
  imageSrc?: string
  avatarText?: string
  value?: string
  onChange?: any
  isLoading?: boolean
  onClick?: any
}

const CommentsComponent = ({
  placeholder,
  infoText,
  imageSrc,
  avatarText,
  value,
  onChange,
  onClick,
  isLoading,
}: CommentsComponentProps): React.ReactElement => {
  const classes = CommentsStyles()
  return (
    <div className={classes.commentContainer}>
      {infoText ? <p className="info-text">{infoText}</p> : ''}

      <div className="comment-section">
        <Avatar className="avatar" src={imageSrc}>
          {avatarText}
        </Avatar>
        <input placeholder={placeholder} className="comment-field" value={value} onChange={onChange} />
      </div>
      <div className="btn-section">
        {onChange && (
          <ButtonWithLoading
            kind="BRPrimary"
            wrapperClassName={classes.saveBtn}
            onClick={onClick}
            isLoading={isLoading}
            disabled={!(value || '').length}
          >
            Save
          </ButtonWithLoading>
        )}
      </div>
    </div>
  )
}

export default CommentsComponent
