import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddButton from 'components/common/buttons/primaryButton'
import { ITaskBase } from 'models/task'
import * as React from 'react'
import CreateTaskDialog from './createDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {},
    addButton: {
      textTransform: 'capitalize',
    },
  })
)

export interface ICreateTaskProps {
  onFormSubmitted?: () => void
}
interface IError {
  title: boolean
}
export interface ICreateTaskState {
  open: boolean
  task: ITaskBase
  errors: {}
}

function CreateTask({ onFormSubmitted }: ICreateTaskProps) {
  const classes = useStyles()
  const [open, setOpen] = React.useState<boolean>(false)

  const toggleDrawer = React.useCallback(() => {
    setOpen(prev => !prev)
  }, [setOpen])

  return (
    <div className={classes.mainContainer}>
      <AddButton onClick={toggleDrawer}>Add Task</AddButton>
      <CreateTaskDialog onFormSubmitted={onFormSubmitted} open={open} toggleOpen={toggleDrawer} />
    </div>
  )
}

export default CreateTask
