import React, { useCallback, useContext, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import DeadlineTable from './table/deadlineTable'
import { ComplianceDataCtx } from './client'
import { ComplianceTasks, ComplianceTasksData, PaginationData } from 'api/complianceCalendar'
import { getClientCamplianceTask } from 'api/clientDashboard'
import showErrorNotification from 'utils/showErrorNotification'
import { ISuggestionAttribute } from 'components/interfaces'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import { ICompany } from 'models/company'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: '2rem 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tabRoot: {
    flexGrow: 1,

    '& .tab-header': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: '#020202',
    },

    '& .history-tab': {
      marginRight: '1rem',
      borderBottom: '3px solid #f1f3f4',
      color: '#020202',
      opacity: 1,
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
    },
    // '& .Mui-selected': {
    //   borderBottom: '3px solid #407ace',
    // },
    '& .MuiTabs-indicator': {
      borderBottom: '2px solid #407ace',
      background: '#407ace',
    },
  },
}))

interface HistoryTabsProps {
  isDashboard?: boolean
  companyDetails?: ICompany[] | []
  clientPage?: number
  setClientPage?: any
  clientRowsPerPage?: number
  setClientRowsPerPage?: any
}

export default function HistoryTabs({
  isDashboard,
  companyDetails,
  clientPage,
  setClientPage,
  clientRowsPerPage,
  setClientRowsPerPage,
}: HistoryTabsProps) {
  const ctxValue = useContext(ComplianceDataCtx)

  const { complianceTasks, getComplianceTask, isComplianceLoading, paginationDataClient } = ctxValue

  const classes = useStyles()
  const [value, setValue] = useState(0)

  // const [newDate, setNewDate] = useState<ComplianceTasksData[]>([])

  const [dashboardTasks, setDashboardTasks] = useState<ComplianceTasksData[]>([])
  // const [dashboardTasks, setDashboardTasks] = useState<ComplianceTasks>()

  // const [dashboardTasks, setDashboardTasks] = useState<any>()

  const [paginationData, setPaginationData] = useState<PaginationData>()

  const [isDashboardComplianceLoading, setDashboardIsComplianceLoading] = useState(false)

  const [companyOrderDueDate, setCompanyOrderDueDate] = useState<Date>()
  // const [orderTypeSuggestion, setOrderTypeSuggestion] = useState<ISuggestionAttribute>()
  const [orderTypeSuggestion, setOrderTypeSuggestion] = useState<string>('')

  const [companyName, setCompanyName] = useState<ISuggestionAttribute>()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [isStatusChange, setIsStatusChange] = useState(false)

  useEffect(() => {
    debugger
    const taskPayload = {
      company_id: companyName ? companyName?.toString() : '',
      status: value.toString() || '',
      due_date: companyOrderDueDate ? formatDateToHumanFriendly(companyOrderDueDate) : '',
      // order_type_id: orderTypeSuggestion ? orderTypeSuggestion?.value : 0,
      compliance_type: orderTypeSuggestion ? orderTypeSuggestion : '',
      page: page + 1,
      page_size: rowsPerPage,
    }
    getComplianceTask(taskPayload)
  }, [
    companyOrderDueDate,
    orderTypeSuggestion,
    getComplianceTask,
    value,
    page,
    rowsPerPage,
    isStatusChange,
    companyName,
  ])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const getTasks = useCallback(async () => {
    setDashboardIsComplianceLoading(true)
    debugger

    const payload = {
      status: value.toString() === '0' ? value.toString() : '',
      due_date: companyOrderDueDate ? formatDateToHumanFriendly(companyOrderDueDate) : '',
      company_id: companyName ? companyName?.toString() : '',
      // order_type_id: orderTypeSuggestion ? orderTypeSuggestion?.value : 0,
      compliance_type: orderTypeSuggestion ? orderTypeSuggestion : '',
      page: page + 1,
      page_size: rowsPerPage,
    }
    try {
      const tasks = await getClientCamplianceTask(payload)

      setDashboardTasks(tasks?.data)
      setPaginationData(tasks?.pagination)
      setDashboardIsComplianceLoading(false)
    } catch (error) {
      showErrorNotification(error)
      setDashboardIsComplianceLoading(false)
    }
  }, [companyName, companyOrderDueDate, orderTypeSuggestion, page, rowsPerPage, value])

  useEffect(() => {
    getTasks()
  }, [getTasks, value, isStatusChange])

  return (
    <div className={classes.tabRoot}>
      <AppBar position="static" className="tab-header">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Overview" {...a11yProps(0)} className="history-tab" />
          <Tab label="History" {...a11yProps(1)} className="history-tab" />
          {/* <Tab label="Pending transactions" {...a11yProps(2)} className="history-tab" /> */}
        </Tabs>
      </AppBar>
      {isDashboard ? (
        <>
          <TabPanel value={value} index={0}>
            <DeadlineTable
              complianceTasks={dashboardTasks}
              pagination={paginationData}
              isComplianceLoading={isDashboardComplianceLoading}
              isDashboard={isDashboard}
              companyOrderDueDate={companyOrderDueDate}
              setCompanyOrderDueDate={setCompanyOrderDueDate}
              orderTypeSuggestion={orderTypeSuggestion}
              setOrderTypeSuggestion={setOrderTypeSuggestion}
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyDetails={companyDetails}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setIsStatusChange={setIsStatusChange}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DeadlineTable
              // complianceTasks={dashboardTasks?.filter(task => task?.status !== '0')}
              complianceTasks={dashboardTasks || null}
              pagination={paginationData}
              isComplianceLoading={isDashboardComplianceLoading}
              tabValue={value}
              isDashboard={isDashboard}
              companyOrderDueDate={companyOrderDueDate}
              setCompanyOrderDueDate={setCompanyOrderDueDate}
              orderTypeSuggestion={orderTypeSuggestion}
              setOrderTypeSuggestion={setOrderTypeSuggestion}
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyDetails={companyDetails}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setIsStatusChange={setIsStatusChange}
            />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <DeadlineTable
              complianceTasks={complianceTasks}
              // pagination={paginationData}
              pagination={paginationDataClient}
              isComplianceLoading={isComplianceLoading}
              companyOrderDueDate={companyOrderDueDate}
              setCompanyOrderDueDate={setCompanyOrderDueDate}
              orderTypeSuggestion={orderTypeSuggestion}
              setOrderTypeSuggestion={setOrderTypeSuggestion}
              page={clientPage || 0}
              setPage={setClientPage}
              rowsPerPage={clientRowsPerPage || 5}
              setRowsPerPage={setClientRowsPerPage}
              setIsStatusChange={setIsStatusChange}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DeadlineTable
              // complianceTasks={complianceTasks?.filter(task => task?.status !== '0')}
              // pagination={paginationData}
              pagination={paginationDataClient}
              complianceTasks={complianceTasks}
              isComplianceLoading={isComplianceLoading}
              tabValue={value}
              companyOrderDueDate={companyOrderDueDate}
              setCompanyOrderDueDate={setCompanyOrderDueDate}
              orderTypeSuggestion={orderTypeSuggestion}
              setOrderTypeSuggestion={setOrderTypeSuggestion}
              page={clientPage || 0}
              setPage={setClientPage}
              rowsPerPage={clientRowsPerPage || 5}
              setRowsPerPage={setClientRowsPerPage}
              setIsStatusChange={setIsStatusChange}
            />
          </TabPanel>
        </>
      )}
    </div>
  )
}
