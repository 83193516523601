import React, { useMemo } from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Table, Theme, TableBody } from '@material-ui/core'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { deleteMeetingLinks, getMeetingLinks, MeetingLinkProps } from 'api/meetingLinks'
import { skeletons } from 'components/common/table'
import DeleteDialog from 'components/notifications/deleteDialog'
import MeetingLinkTableHead from 'components/meetingLinks/MeetingLinkTableHead'
import MeetingLinkRow from 'components/meetingLinks/MeetingLinkRow'
import AddMeetingLinkDialog from 'components/meetingLinks/AddMeetingLinkDialog'

const MeetingLinks = () => {
  const classes = useStyles()

  const [meetingLinkDialogShown, setMeetingLinkDialogShown] = React.useState(false)
  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [meetingLinkToDelete, setMeetingLinkToDelete] = React.useState<MeetingLinkProps>()
  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'title' })

  const tableHead = useMemo(
    () => <MeetingLinkTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const { order, sort_by } = sortSettings

  const memoApiCall = useMemo(() => getMeetingLinks()({ sort_order: order, sort_by, query }), [order, sort_by, query])

  const pagedTable = usePagedTable<MeetingLinkProps>({
    apiCall: memoApiCall,
  })

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleDeleteNotificationClick = React.useCallback((meetingLinkItem: MeetingLinkProps) => {
    setMeetingLinkToDelete(meetingLinkItem)
    setDeleteItemDialogShown(true)
  }, [])

  const handleMeetingLinksDialogCloseClick = React.useCallback(() => {
    setMeetingLinkDialogShown(false)
  }, [])

  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.meetingLinks}>Meeting Links</p>
          <p className={classes.tableTitle}>Meeting Links List</p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => setMeetingLinkDialogShown(true)}>
            Add Meeting Links
          </ButtonWithLoading>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 3, sizes: [120, 200, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Meeting Links"
          {...pagedTable.renderProps}
          renderRow={(meetingLinkItem: MeetingLinkProps) => (
            <MeetingLinkRow
              meetingLink={meetingLinkItem}
              onDeleteClick={() => handleDeleteNotificationClick(meetingLinkItem)}
            />
          )}
          header={tableHead}
        />
      )}
      <AddMeetingLinkDialog
        isOpen={meetingLinkDialogShown}
        close={handleMeetingLinksDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
      />
      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={meetingLinkToDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deleteMeetingLinks}
      />
    </div>
  )
}

export default MeetingLinks

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    meetingLinks: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
