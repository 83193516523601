import { Table, TableBody } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { HeadColumn } from 'components/common/table'
import { IOrder } from 'models/order'
import * as React from 'react'
import { emptyFunction } from 'utils/objectUtils'
import OrderTableHead from '../table/head'
import OrderTableRow from '../table/row'

const headColumns: HeadColumn[] = [
  { id: 'id', label: 'Id', style: { width: '5%', maxWidth: '5%' }, sortable: true },
  { id: 'order_type', label: 'Order Type', style: { width: '19%', maxWidth: '19%' }, sortable: false },
  { id: 'price', label: 'Price', style: { width: '19%', maxWidth: '19%' }, sortable: true },
  { id: 'status', label: 'Status', style: { width: '19%', maxWidth: '19%' }, sortable: true },
  { id: 'last_update', label: 'Last Update', style: { width: '19%', maxWidth: '19%' }, sortable: true },
  { id: 'due_date', label: 'Due Date', style: { width: '19%', maxWidth: '19%' }, sortable: true },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)

interface INotPaginatedOrdersTableProps {
  orders: IOrder[]
  disableActionButtons?: boolean
  classes?: Record<string, string>
}

const NonPaginatedOrdersTable: React.FC<INotPaginatedOrdersTableProps> = props => {
  const { orders, disableActionButtons } = props

  const classes = useStyles(props)

  return (
    <React.Fragment>
      <Table className={classes.root}>
        <OrderTableHead
          disableCompanyNameAndSalesRep
          sortBy="Company Name"
          sortOrder="asc"
          handleSortingClick={() => {
            return undefined
          }}
          headColumns={headColumns}
        />
        <TableBody>
          {orders.map(order => (
            <OrderTableRow
              order={order}
              isCompanyCreationFlow
              disableActionButtons={disableActionButtons}
              // TODO: #383 next line never did anything. should look into what it does and fix it if needed
              onEdit={emptyFunction}
              key={order.id}
            />
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default NonPaginatedOrdersTable
