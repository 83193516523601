import Dialog from '@material-ui/core/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { getTaxOrderAccountants } from 'api/taxOrders'
import { default as AddButton, default as PrimaryButton } from 'components/common/buttons/primaryButton'
import ModalTitle from 'components/common/modalTitle'
import OutlinedSelect from 'components/common/outlinedSelect'
import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import useTaxOrders from 'components/taxOrders/hooks'
import {
  ITaxOrderServiceType,
  ITaxOrderType,
  TaxOrderServiceTypeOptions,
  TaxOrderTypeOptions,
  TaxStructureBusinessOptions,
  TaxStructurePersonalOptions,
} from 'models/taxOrder'
import moment from 'moment'
import { CompanyContext } from 'pages/company/companyContext'
import * as React from 'react'
import { toTitleCase } from 'utils/toTitleCase'
import { fetchSuggestions as fetchSuggestionsDefault } from 'api/suggestions'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    paper: {
      padding: '30px 0',
      margin: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      width: 500,
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      width: 250,
      marginTop: 20,
    },
    formContainer: {
      width: 430,
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    typeAndYearContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    selectContainer: {
      width: '100%',
      margin: '0.5rem 0',
    },
  })
)

interface ICreateTaxOrderProps {}

const CreateTaxOrder: React.FC<ICreateTaxOrderProps> = props => {
  const classes = useStyles(props)
  // const [selectedValve, setSelectedValve] = React.useState<ISuggestionAttribute>()

  // hooks
  const {
    isCreateModalOpen,
    newTaxOrderState,
    toggleCreateTaxOrderModal,
    saveTaxOrder,
    addForeignKeyAttribute,
    fetchSuggestions,
    isCreating,
    setNewTaxOrderState,
    fetchBusinessTaxStructure,
    selectedValve,
    setSelectedValve,
  } = useTaxOrders()

  const handleTypeChange = React.useCallback(
    (value: ITaxOrderType) => {
      if (TaxOrderTypeOptions[value] !== newTaxOrderState.tax_order_type)
        setNewTaxOrderState(prevState => ({
          ...prevState,
          tax_order_type: TaxOrderTypeOptions[value],
          parent_id: undefined, // delete existing parent_id on tax order type change
        }))
    },
    [setNewTaxOrderState, newTaxOrderState.tax_order_type]
  )
  const handleServiceTypeChange = React.useCallback(
    (value: ITaxOrderServiceType) => {
      if (TaxOrderServiceTypeOptions[value] !== newTaxOrderState.service_type)
        setNewTaxOrderState(prevState => ({
          ...prevState,
          service_type: TaxOrderServiceTypeOptions[value],
        }))
    },
    [setNewTaxOrderState, newTaxOrderState.service_type]
  )
  const handleTaxStructureChange = React.useCallback(
    (value: string) => {
      let structure: string | undefined = undefined
      if (newTaxOrderState.tax_order_type === 'business') {
        structure = (Object.entries(TaxStructureBusinessOptions).find(item => item[1] === value) || [
          undefined,
          undefined,
        ])[0]
      } else if (newTaxOrderState.tax_order_type === 'personal')
        structure = (Object.entries(TaxStructurePersonalOptions).find(item => item[1] === value) || [
          undefined,
          undefined,
        ])[0]
      if (structure)
        setNewTaxOrderState(prevState => ({
          ...prevState,
          [`tax_structure_${newTaxOrderState.tax_order_type}`]: structure,
        }))
    },
    [newTaxOrderState.tax_order_type, setNewTaxOrderState]
  )

  const handleYearChange = React.useCallback(
    (date: MaterialUiPickersDate) => {
      if (date)
        setNewTaxOrderState(prevState => ({
          ...prevState,
          tax_year: date.year().toString(),
        }))
    },
    [setNewTaxOrderState]
  )

  const taxStructureSelectValue = React.useMemo(
    () =>
      // defining label for chosen option (tax structure) for OutlinedSelect element
      newTaxOrderState.tax_order_type === 'business'
        ? TaxStructureBusinessOptions[newTaxOrderState.tax_structure_business]
        : TaxStructurePersonalOptions[newTaxOrderState.tax_structure_personal],
    [newTaxOrderState.tax_order_type, newTaxOrderState.tax_structure_business, newTaxOrderState.tax_structure_personal]
  )

  const taxStructureSelectOptions = React.useMemo(
    () =>
      Object.values(
        newTaxOrderState.tax_order_type === 'business' ? TaxStructureBusinessOptions : TaxStructurePersonalOptions
      ),
    [newTaxOrderState.tax_order_type]
  )

  React.useEffect(() => {
    // when new company is chosen, tax structure should be defined
    // according to its legal type
    fetchBusinessTaxStructure(newTaxOrderState.parent_id)
  }, [newTaxOrderState.parent_id, fetchBusinessTaxStructure])
  // React.useEffect(() => {
  //   console.log('company_id in tax popup', props.company_id)
  //   if (props.company_id) {
  //     addForeignKeyAttribute('parent_id', props.company_id)
  //   }
  // }, [props.company_id])
  const { companyName, companyId } = React.useContext(CompanyContext)

  // React.useEffect(() => {}, [newTaxOrderState.parent_id])
  const updateCompanyId = React.useCallback(() => {
    if (companyId) {
      addForeignKeyAttribute('parent_id', companyId)
    }
  }, [companyId])

  React.useEffect(() => {
    const getAccountant = async (id, type) => {
      try {
        const result: any = await getTaxOrderAccountants(id, type)
        setSelectedValve({ label: `${result?.first_name || ''} ${result?.last_name || ''}`, value: result?.id })
        addForeignKeyAttribute('accountant_id', result?.id)
      } catch (error) {
        console.log(error)
      }
    }
    if (newTaxOrderState?.parent_id) {
      getAccountant(newTaxOrderState?.parent_id, newTaxOrderState.tax_order_type)
    }
  }, [addForeignKeyAttribute, newTaxOrderState?.parent_id, newTaxOrderState.tax_order_type])

  const onSelectAccountant = option => {
    addForeignKeyAttribute('accountant_id', option ? option.value : null)
    setSelectedValve({ label: option?.label, value: option?.id })
  }

  return (
    <div className={classes.container}>
      <AddButton
        onClick={() => {
          toggleCreateTaxOrderModal(true)
          updateCompanyId()
        }}
      >
        New Tax Order
      </AddButton>
      <Dialog
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        classes={{
          paper: classes.paper,
          container: classes.container,
        }}
        open={isCreateModalOpen}
        onClose={() => toggleCreateTaxOrderModal(false)}
      >
        <ModalTitle title="New Tax Order" toggleModal={toggleCreateTaxOrderModal} />
        <div className={classes.formContainer}>
          <div className={classes.typeAndYearContainer}>
            <OutlinedSelect
              classes={{ formControl: classes.selectContainer }}
              name={'Service Type'}
              value={toTitleCase(newTaxOrderState.service_type as string)}
              options={Object.keys(TaxOrderServiceTypeOptions)}
              onOptionSelect={value => handleServiceTypeChange(value as ITaxOrderServiceType)}
            />
          </div>
          <div className={classes.typeAndYearContainer}>
            <OutlinedSelect
              classes={{ formControl: classes.selectContainer }}
              name={'Tax Order Type'}
              value={toTitleCase(newTaxOrderState.tax_order_type)}
              options={Object.keys(TaxOrderTypeOptions)}
              onOptionSelect={value => handleTypeChange(value as ITaxOrderType)}
              disabled={companyId ? true : false}
            />
            <DatePicker
              views={['year']}
              label="Tax Year"
              value={newTaxOrderState.tax_year}
              onChange={handleYearChange}
              autoOk
              style={{ width: 200, marginLeft: '1rem' }}
              inputVariant="outlined"
              okLabel=""
              minDate={moment().add(-10, 'year')}
              maxDate={moment().add(+10, 'year')}
            />
          </div>
          {newTaxOrderState.tax_order_type === 'business' && (
            <SelectField
              onOptionSelected={option => addForeignKeyAttribute('parent_id', option ? option.value : null)}
              title="Company"
              fetchSuggestions={fetchSuggestions}
              field="parent_id"
              placeholder="Choose Company"
              defaultValue={{ label: companyName || '', value: companyId || 0 }}
            />
          )}
          {newTaxOrderState.tax_order_type === 'personal' && (
            <SelectField
              onOptionSelected={option => addForeignKeyAttribute('parent_id', option ? option.value : null)}
              title="Client"
              fetchSuggestions={fetchSuggestions}
              field="parent_id"
              placeholder="Choose Client"
            />
          )}
          <SelectField
            // onOptionSelected={option => addForeignKeyAttribute('accountant_id', option ? option.value : null)}
            onOptionSelected={option => onSelectAccountant(option)}
            title="Accountant"
            fetchSuggestions={fetchSuggestions}
            field="accountant_id"
            placeholder="Choose Accountant"
            value={selectedValve}
          />

          <SelectField
            onOptionSelected={option => addForeignKeyAttribute('senior_cpa', option ? option.value : null)}
            title="Senior Accountant"
            fetchSuggestions={fetchSuggestions}
            field="senior_cpa"
            placeholder="Choose Senior Accountant"
          />

          <SelectField
            onOptionSelected={option => addForeignKeyAttribute('tax_associate', option ? option.value : null)}
            title="Tax Associate"
            fetchSuggestions={fetchSuggestionsDefault}
            field="assigned_to"
            placeholder="Choose Tax Associate"
          />

          <SelectField
            onOptionSelected={option => addForeignKeyAttribute('tax_controller', option ? option.value : null)}
            title="Tax Controller"
            fetchSuggestions={fetchSuggestionsDefault}
            field="assigned_to"
            placeholder="Choose Tax Controller"
          />
          {/* <OutlinedSelect
            classes={{ formControl: classes.selectContainer }}
            name={'Tax Structure'}
            value={taxStructureSelectValue}
            options={taxStructureSelectOptions}
            onOptionSelect={value => handleTaxStructureChange(value as string)}
          /> */}
          <PrimaryButton
            disabled={isCreating || !newTaxOrderState.parent_id}
            classes={{ button: classes.button }}
            onClick={() => saveTaxOrder()}
          >
            Create Tax Order
          </PrimaryButton>
        </div>
      </Dialog>
    </div>
  )
}

export default CreateTaxOrder
