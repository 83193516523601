import { PagedTable } from '@jetkit/react'
import NoteRow from 'components/common/notes/noteRow'
import { INote } from 'models/note'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import { useContext } from 'react'
import SearchBar from '../searchBar'
import { skeletons } from '../table'
import AddNoteHeader from './addNoteHeader'
import useStyles from './styles'

export interface APINoteType {
  id?: number
  color?: string
  create?: boolean
  search?: boolean
}

interface INotesProps {
  addNoteOpen?: boolean
  tableBodyClassName?: string
  headerAccessoryView?: React.ReactNode
}

function SearchableNotes(props: INotesProps) {
  const classes = useStyles()

  const { pagedTable, setSearchQuery } = useContext(NotesTableContext)

  const handleNoteCreated = React.useCallback(() => {
    pagedTable?.reloadData()
  }, [pagedTable])

  if (!pagedTable) {
    const msg = 'Empty notes list. You have to add the loading context NotesTableContext'
    console.error(msg)
    return null
  }

  return (
    <React.Fragment>
      <AddNoteHeader accessoryView={props.headerAccessoryView} onCreated={handleNoteCreated} />
      <SearchBar className={classes.searchBar} onChange={setSearchQuery} placeholder="Search notes..." />
      {pagedTable.isLoading ? (
        skeletons({ numberOfColumns: 1, sizes: [360], numberOfRows: 6 })
      ) : (
        <PagedTable
          tableClassName={props.tableBodyClassName}
          {...pagedTable.renderProps}
          renderRow={(row_data: INote) => <NoteRow key={row_data.id} note={row_data} />}
        />
      )}
    </React.Fragment>
  )
}

export default SearchableNotes
