import { Grid, InputAdornment, Button } from '@material-ui/core'
import BRTextField from 'components/common/textFields/BRTextField'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import React, { useState, useCallback, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './styles'
// import SelectField from './selectField'
// import InProgressTable from './inProgressTable'
import {
  complianceCompanyClosed,
  complianceCompleteOrder,
  complianceInProgressOrder,
  ComplianceTasks,
  getComplianceTasks,
  getComplianceTasksByCompanyId,
} from 'api/complianceCalendar'
import showErrorNotification from 'utils/showErrorNotification'
import CompanyOrderTypeField from 'components/common/companyOrder/orderTypeField'
import { createOrderTypeSuggestions, fetchSuggestions } from 'api/suggestions'
import { ISuggestionAttribute } from 'components/interfaces'
import { OrderDueDatePicker } from 'components/common/companyOrder/orderDueDatePicker'
import { datePickerDateFormat, formatDateToHumanFriendly } from 'utils/formatDate'
import SelectField from 'components/common/select'
import { COMPLIANCE_TASKS_STATUS } from 'utils/constant'
import showSuccessNotification from 'utils/showSuccessNotification'
import ProgressTable from './progressTable'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'

interface ProgressTaskProps {
  isUpdated?: boolean
  setIsUpdated?: any
  companyId: number
}

const ProgressTask = ({ isUpdated, setIsUpdated, companyId }: ProgressTaskProps) => {
  const classes = useStyles()
  // const [complianceTasks, setComplianceTasks] = useState<ComplianceTasksData[]>([])
  const [complianceTasks, setComplianceTasks] = useState<ComplianceTasks>()

  const [isLoadingTasks, setIsLoadingTasks] = useState(false)
  const [isHistoryLoadingTasks, setIsHistoryLoadingTasks] = useState(false)

  const [historyComplianceTasks, setHistoryComplianceTasks] = useState<ComplianceTasks>()
  const [isHistoryTask, setIsHistoryTask] = useState(false)
  // const [isHistoryModal, setIsHistoryModal] = useState(false)

  // const [orderTypeSuggestion, setOrderTypeSuggestion] = useState<ISuggestionAttribute>()
  // const [companyOrderDueDate, setCompanyOrderDueDate] = useState<Date>()
  // const [companyName, setCompanyName] = useState<ISuggestionAttribute | null>()
  // const [clientName, setClientName] = useState<ISuggestionAttribute | null>()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [historyPage, setHistoryPage] = useState(0)
  const [rowsPerHistoryPage, setRowsPerHistoryPage] = useState(5)

  const getTasks = useCallback(async () => {
    const complianceTaskParams = {
      // company_id: (companyName && companyName?.value) || null,
      // client_id: (clientName && clientName?.value) || null,
      // due_date: companyOrderDueDate && formatDateToHumanFriendly(companyOrderDueDate),
      // order_type_id: orderTypeSuggestion?.value,
      // status: COMPLIANCE_TASKS_STATUS?.inprogress,
      company_id: companyId,
      page: page + 1,
      page_size: rowsPerPage,
    }
    setIsLoadingTasks(true)
    try {
      const tasks = await getComplianceTasksByCompanyId(complianceTaskParams)
      setComplianceTasks(tasks)
      setIsLoadingTasks(false)
    } catch (e) {
      showErrorNotification(e)
      setIsLoadingTasks(false)
    }
  }, [companyId, page, rowsPerPage])

  const getHistoryTasks = useCallback(
    async (historyValue: boolean) => {
      debugger
      const complianceTaskParams = {
        // company_id: (companyName && companyName?.value) || null,
        // client_id: (clientName && clientName?.value) || null,
        // due_date: companyOrderDueDate && formatDateToHumanFriendly(companyOrderDueDate),
        // order_type_id: orderTypeSuggestion?.value,
        // status: COMPLIANCE_TASKS_STATUS?.inprogress,
        company_id: companyId,
        page: historyPage + 1,
        page_size: rowsPerHistoryPage,
        show_history: historyValue,
      }

      setIsHistoryLoadingTasks(true)
      try {
        const tasks = await getComplianceTasksByCompanyId(complianceTaskParams)
        setHistoryComplianceTasks(tasks)
        setIsHistoryLoadingTasks(false)
        debugger
      } catch (e) {
        showErrorNotification(e)
        setIsHistoryLoadingTasks(false)
      }
    },
    [companyId, historyPage, rowsPerHistoryPage]
  )

  useEffect(() => {
    getTasks()
  }, [getTasks, isUpdated])

  useEffect(() => {
    if (isHistoryTask) {
      getHistoryTasks(isHistoryTask)
    }
  }, [getHistoryTasks, historyPage, isHistoryTask, rowsPerHistoryPage])

  // useEffect(() => {
  //   getHistoryTasks()
  // }, [getHistoryTasks])

  // const handleSelectingOrderType = data => {
  //   setOrderTypeSuggestion(data)
  //   setCompanyOrderDueDate(undefined)
  //   setCompanyName({
  //     value: 0,
  //     label: '',
  //   })
  //   setClientName({
  //     value: 0,
  //     label: '',
  //   })
  // }

  // const handleDueDateSelected = date => {
  //   setCompanyOrderDueDate(date)
  //   setCompanyName({
  //     value: 0,
  //     label: '',
  //   })
  //   setClientName({
  //     value: 0,
  //     label: '',
  //   })
  //   setOrderTypeSuggestion({
  //     value: 0,
  //     label: '',
  //   })
  // }
  // const addAttribute = React.useCallback((field: 'company_id' | 'client_id', obj: ISuggestionAttribute | null) => {
  //   if (field === 'company_id') {
  //     if (obj) {
  //       setCompanyName(obj)
  //       setClientName({
  //         value: 0,
  //         label: '',
  //       })
  //       setOrderTypeSuggestion({
  //         value: 0,
  //         label: '',
  //       })
  //       setCompanyOrderDueDate(undefined)
  //     }
  //   }
  //   if (field === 'client_id') {
  //     if (obj) {
  //       setClientName(obj)
  //       setCompanyName({
  //         value: 0,
  //         label: '',
  //       })
  //       setOrderTypeSuggestion({
  //         value: 0,
  //         label: '',
  //       })
  //       setCompanyOrderDueDate(undefined)
  //     }
  //   }
  // }, [])

  const completeOrderHandler = useCallback(
    async id => {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.completed,
      }
      try {
        const complateOrder = await complianceCompleteOrder(payload)
        showSuccessNotification('Order Complete Success')
        setIsUpdated(true)
        getTasks()
        return complateOrder
      } catch (error) {
        showErrorNotification(error)
      }
    },
    [getTasks, setIsUpdated]
  )

  // const companyCloseHandler = useCallback(
  //   async id => {
  //     const payload = {
  //       compliance_task_id: id,
  //       status: COMPLIANCE_TASKS_STATUS?.closed,
  //     }
  //     try {
  //       const companyClosed = await complianceCompanyClosed(payload)
  //       showSuccessNotification('Order Closed Success')
  //       setIsUpdated(true)
  //       getTasks()
  //       return companyClosed
  //     } catch (error) {
  //       showErrorNotification(error)
  //     }
  //   },
  //   [getTasks, setIsUpdated]
  // )
  // const inProgressOrderHandler = useCallback(
  //   async id => {
  //     const payload = {
  //       compliance_task_id: id,
  //       status: COMPLIANCE_TASKS_STATUS?.inprogress,
  //     }
  //     try {
  //       const companyClosed = await complianceInProgressOrder(payload)
  //       showSuccessNotification('Order InProgress Success')
  //       setIsUpdated(true)
  //       getTasks()
  //       return companyClosed
  //     } catch (error) {
  //       showErrorNotification(error)
  //     }
  //   },
  //   [getTasks, setIsUpdated]
  // )

  return (
    <div className={classes.subContainer}>
      <div className={classes.headerSec}>
        <h1 className="secondary-heading">Compliance Tasks</h1>
        <Button
          color="primary"
          onClick={() => {
            setIsHistoryTask(true)
            // getHistoryTasks(true)
          }}
        >
          Show History
        </Button>
      </div>

      {/*   <HeadingHeader
        title="In Progress Tasks"
        isLink={false}
        secondaryComponent={
          <BRTextField
            placeholder="Search"
            type="text"
            variant="outlined"
            style={{ width: '23.4%' }}
            className="search-filed-container"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        }
      />

       <Grid container spacing={3} style={{ width: 'calc(100% + 16px)' }}>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={obj => addAttribute('company_id', obj ? obj : null)}
            title="Company"
            fetchSuggestions={fetchSuggestions}
            field="company_id"
            value={companyName || null}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            onOptionSelected={obj => addAttribute('client_id', obj ? obj : null)}
            title="Client"
            fetchSuggestions={fetchSuggestions}
            field="client_id"
            value={clientName || null}
          />
        </Grid>
        <Grid item xs={3}>
          <CompanyOrderTypeField
            handleSelectingOrderType={handleSelectingOrderType}
            fetchSuggestions={createOrderTypeSuggestions}
            orderTypeSuggestion={orderTypeSuggestion || null}
            disabled={false} //{shouldDisableOrderTypeSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <OrderDueDatePicker
            className={classes.dueDatePicker}
            classes={{ root: classes.dateField }}
            onDueDateSelected={handleDueDateSelected}
            dueDateValue={companyOrderDueDate}
            dateFormat={datePickerDateFormat}
          />
        </Grid>
      </Grid>

      <br /> */}

      <ProgressTable
        complianceTasks={complianceTasks || null}
        isLoadingTasks={isLoadingTasks}
        completeOrderHandler={completeOrderHandler}
        // companyCloseHandler={companyCloseHandler}
        // inProgressOrderHandler={inProgressOrderHandler}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ModalWrapper open={isHistoryTask} setOpen={setIsHistoryTask} heading="History Task" maxWidth="lg">
        <ProgressTable
          complianceTasks={historyComplianceTasks || null}
          isLoadingTasks={isHistoryLoadingTasks}
          // completeOrderHandler={completeOrderHandler}
          // companyCloseHandler={companyCloseHandler}
          // inProgressOrderHandler={inProgressOrderHandler}
          page={historyPage}
          setPage={setHistoryPage}
          rowsPerPage={rowsPerHistoryPage}
          setRowsPerPage={setRowsPerHistoryPage}
        />
      </ModalWrapper>
    </div>
  )
}

export default ProgressTask
