import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  deadlineContainer: {
    '& .import-header': {
      background: '#f7f8f9',
      padding: '0 1rem',
      margin: '0.2rem',

      '& .send-icon': {
        transform: 'rotate(320deg)',
        color: '#788190',
      },

      '& .inline-item': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        minHeight: '36px',
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,

        '& .left-arrow': {
          color: '#979797',
        },
        '& .right-arrow': {
          color: '#59595b',
        },
      },
    },
  },

  table: {
    // minWidth: 1125,

    // backgroundColor: '#f7f8f9',
    // borderTop: '10px solid #3c9fdf',

    '& .table-head': {
      '& .MuiTableCell-root': {
        paddingLeft: '1rem',
        padding: '0.8rem',
      },
      '& .MuiTableCell-head': {
        fontSize: '14px',
        color: '#202124',

        '&:not(:last-child)': {
          borderRight: '1px solid #2929294D',
        },
      },
    },
    '& .MuiTableHead-root': {
      backgroundColor: '#f7faff',
    },
    // MuiTableRow-root MuiTableRow-head

    '& .inline-arrows': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'row',
      minHeight: '36px',

      '& .left-arrow': {
        color: '#979797',
      },
      '& .right-arrow': {
        color: '#59595b',
      },
    },

    '& .table-row': {
      backgroundColor: '#fff',

      '& .row-checkbox': {
        '& .checkbox-outline': {
          color: '#707070',
        },
        '& .checkbox-filled': {
          color: '#0088d5',
        },
      },

      // '&:nth-of-type(even)': {
      //   backgroundColor: '#f7f8f9',
      // },
      '& .MuiTableCell-root': {
        padding: '0.8rem',
        paddingLeft: '1rem',
        // fontWeight: 500,
        color: '#292929',
        fontSize: '14px',
        // border: 'none',
      },
      '& img': {
        cursor: 'pointer',
      },
    },

    '& .inline-btn': {
      // display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'center',

      '& .inline-info-item': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

        '& .info-icon': {
          fontSize: '2rem',
          color: '#307abc',
          transform: 'rotate(180deg)',
          marginRight: '0.5rem',
        },
        '& .action-text': {
          fontFamily: 'Roboto,sans-serif',
          fontSize: '0.8rem',
          color: '#202124',
        },
      },

      [theme.breakpoints.up('lg')]: {
        // justifyContent: 'flex-end',

        '& button': {
          marginRight: '0.25rem',
        },
      },

      '& .search-icon': {
        borderRadius: '100px',
        background: '#307abc',
        padding: '0.35rem',
        color: '#fff',
        cursor: 'pointer',
      },

      '& .file-btn': {
        color: '#fff',
        background: '#307abc',
        fontSize: '14px',
        padding: '0.5rem 1rem',
        height: 'auto',
      },
      '& .remove-btn': {
        color: '#fff',
        background: '#788190a6',
        fontSize: '14px',
        padding: '0.5rem 1rem',
      },
    },
    '& .inline-icon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  tablePagination: {
    backgroundColor: '#f7faff',
  },
  dateContainer: {
    // display: 'flex',
    // flexWrap: 'wrap',
    '& .date-field': {
      // marginLeft: '1rem',
      // marginRight: '1rem',
      width: '100%',
      backgroundColor: '#EAF1FB',
      borderRadius: '32px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiInputBase-formControl ': {
        backgroundColor: '#EAF1FB',
        borderRadius: '32px',
      },
    },
  },
  formControl: {
    width: '190px',

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },

    '& .select-filed': {
      padding: '0.2rem 1rem',
      paddingLeft: '0',
      fontSize: '14px',
      color: '#202124',
      fontWeight: 500,

      '& .MuiInputBase-root': {
        '& ::before': {
          border: 'none !important',
        },

        '& .MuiInputAdornment-root': {
          marginLeft: '-12px',
        },
      },

      '&:hover': {
        border: 'none',
      },
      '&::before': {
        border: 'none',

        '&:hover': {
          border: 'none !important',
        },
      },
    },
  },
  conpanyInformation: {
    '& .input-field': {
      background: '#f7f8f9 !important',
    },
    '& .text-area': {
      background: '#f7f8f9',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '0.5rem',
      minHeight: '90px',
    },
  },
  profileContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2.5rem',
    width: '100%',

    '& .complete-profile-sec': {
      textAlign: 'center',

      '& .complete-profile-text': {
        color: '#202124',
        textTransform: 'uppercase',
        fontWeight: 500,
      },
      '& .complete-btn': {
        background: '#307abc',
        color: '#fff',
      },
    },

    '& .circular-color': {
      color: '#307abc',
    },
    '& .inner-text': {
      color: '#202124',
      fontSize: '20px',
      fontFamily: 'Lato',
      fontWeight: 800,
    },
  },
  wrapper: {
    margin: '0.5rem',
    position: 'relative',

    '& .MuiFab-root': {
      color: '#307abc',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      width: 'auto',
      height: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  buttonSuccess: {
    border: 'none',
    background: 'transparent',
    margin: '0',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  fabProgress: {
    // color: 'green',
    position: 'absolute',
    top: 1,
    left: -6,
    zIndex: 1,
  },
  selectFormControl: {
    // margin: '0.5rem',
    //   minWidth: 120,
    width: '100%',
    '& .select-field': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiInputBase-input ': {
        backgroundColor: '#EAF1FB',
        borderRadius: '32px',
      },
    },
  },
  selectEmpty: {
    marginTop: '1rem',
  },

  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  dateField: {
    margin: 0,
    // marginTop: 13,
    '& > div': {
      '& > div': {
        height: 56,
      },
    },
  },
  dueDatePicker: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiInputBase-formControl ': {
      backgroundColor: '#eaf1fb',
      borderRadius: '32px',
    },
  },

  dialogContainer: {
    textAlign: 'center',
    padding: '20px',

    '& .schedule-btn': {
      background: '#00aba9',
      padding: '0.25rem 1rem',
      color: '#fff',
      borderRadius: '4px',
      fontWeight: 500,
      minHeight: '34px',
    },
    '& .schedule-call-modal-container': {
      background: '#00aba9',
      padding: '0.25rem 1rem',
      color: '#fff',
      borderRadius: '4px',
      fontWeight: 500,
      fontSize: '1rem',
      minHeight: '34px',
      border: 'none',
      cursor: 'pointer',
    },
  },
  mainHeading: {
    color: '#307abc',
    fontSize: '2.25rem',
    margin: 0,
  },
  details: {
    color: '#788190a6',
    fontSize: '1.25rem',
    marginTop: 0,
  },
  details2: {
    color: '#788190a6',
    fontSize: '1rem',
    marginTop: '-1rem',
    paddingBootom: '0.5rem',
  },
  openLink: {
    background: '#00aba9',
    padding: '0.5rem 1.5rem',
    color: '#fff',
    borderRadius: '4px',
    fontWeight: 500,
    minHeight: '34px',
    textDecoration: 'none',
  },

  companyNameField: {
    '& .MuiFormControl-root': {
      margin: '0 !important',
    },
  },
  searchByCompany: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiInputBase-input ': {
      backgroundColor: '#eaf1fb',
      borderRadius: '32px',
    },
  },
  companyOrderTypeField: {
    '& .MuiAutocomplete-root': {
      backgroundColor: '#eaf1fb',
      borderRadius: '32px',
    },
    '& .MuiInputBase-input ': {
      backgroundColor: '#eaf1fb',
      borderRadius: '32px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  btnWrapper: {
    textAlign: 'end',
    margin: '0.8rem 0 0 0',
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
    },
  },
  cancelButton: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
    },
    margin: theme.spacing(1),
    borderRadius: '32px',
    padding: '14px 16px 13px 19px',
    boxShadow: 'none !important',
    backgroundColor: '#F7F8F9',
  },
  removeTaskBtn: {
    fontFamily: 'Roboto,sans-serif',
    fontSize: '0.9rem',
    color: '#292929',

    '& .close-icon': {
      color: '#ff0000',
    },
  },
  reportBtn: {
    fontFamily: 'Roboto,sans-serif',
    fontSize: '0.9rem',
    color: '#fff',
    background: '#307abc',
    borderRadius: '1rem',
    padding: '0.5rem 1rem',
    lineHeight: 'normal',
  },
}))
