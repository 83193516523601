import { usePagedTable } from '@jetkit/react'
import { CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { fetchClient } from 'api/orderstatus'
import { fetchNotes } from 'api/note'
import ClientDetails from 'components/orderstatus/create/orderstatusDetails'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import Notes from 'components/common/notes/notes'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { ISuggestionAttribute } from 'components/interfaces'
import { INote } from 'models/note'
import { IClient, IStates } from 'models/user'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import useRouter from 'use-react-router'
import { removeNullAndUndefined } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { updatePageVisitsCount } from 'utils/user'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      ...theme.title,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    notesContainer: {
      width: '35%',
    },
    flex: {
      display: 'flex',
    },
  })
)

const EditOrderStatus = () => {
  const router = useRouter<{ id: string | undefined }>()
  const [client, setClient] = React.useState<IStates>()
  const classes = useStyles()

  const fetchClientAndSetState = React.useCallback(
    async (id: number) => {
      try {
        const client = await fetchClient(id)
        setClient(client)
      } catch (error) {
        showApiResponseError(error, 'Could not load client details')
      }
    },
    [setClient]
  )

  React.useEffect(() => {
    // Scroll to top of the page because if we begin editing client
    // in the middle or bottom of clients table, first thing we see is the bottom
    // of the `client/:id` page
    window.scrollTo(0, 0)
    if (!router.match.params.id) {
      router.history.push('/')
      return
    }
    const id: number | undefined = parseInt(router.match.params.id)
    if (isNaN(id)) {
      router.history.push('/')
      return
    }
    fetchClientAndSetState(id)
  }, [fetchClientAndSetState, router.history, router.match.params.id])

  const onRequestSuccessful = React.useCallback(() => router.history.push('/client'), [router.history])

  const [notesVisible, setNotesVisible] = React.useState<boolean>(false)

  const defaultSearchOptions: NoteEntities = React.useMemo(
    (): NoteEntities => ({
      types: ['client'],
      client_id: client?.id,
    }),
    [client]
  )

  const [searchOptions, setSearchOptions] = React.useState<NoteEntities>(defaultSearchOptions)

  const setSearchQuery = React.useCallback(
    (newQuery: string) => setSearchOptions(prevState => ({ ...prevState, query: newQuery })),
    [setSearchOptions]
  )

  const memoApiCall = React.useMemo(() => fetchNotes(removeNullAndUndefined(searchOptions)), [searchOptions])

  const pagedTable = usePagedTable<INote>({
    apiCall: memoApiCall,
  })

  const selectedClient: ISuggestionAttribute | undefined = client?.id
    ? {
        value: client.id,
        label: client.state_display_name,
      }
    : undefined

  const onOpenNotesClick = React.useCallback(() => setNotesVisible(true), [])

  return (
    <div className={classes.flex}>
      <div className={classes.container}>
        {client ? (
          <React.Fragment>
            <div className={classes.titleButtonContainer}>
              <Typography className={classes.title}>Edit Order Status (ID: {client.id || '- - -'})</Typography>
            </div>
            <ClientDetails editedClient={client} onRequestSuccessful={onRequestSuccessful} />
          </React.Fragment>
        ) : (
          <CircularProgress style={{ alignSelf: 'center' }} />
        )}
      </div>

      {notesVisible ? (
        <div className={classes.notesContainer}>
          <NoteEntitiesContext.Provider
            value={{
              types: ['client'],
              client: selectedClient,
            }}
          >
            <NotesTableContext.Provider
              value={{
                pagedTable,
                searchOptions,
                setSearchQuery,
                setSearchOptions,
              }}
            >
              <Notes setModalIsOpen={setNotesVisible} />
            </NotesTableContext.Provider>
          </NoteEntitiesContext.Provider>
        </div>
      ) : (
        <div>
          <OpenNotesButton onClick={onOpenNotesClick} />
        </div>
      )}
    </div>
  )
}

export default EditOrderStatus
