import * as React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Fade from '@material-ui/core/Fade'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxAndIconButtonWrapper: {},
    checkBoxRoot: {
      padding: 0,
    },
    arrowDropDownRoot: {
      padding: 0,
    },
    popper: {
      zIndex: 999,
    },
  })
)

interface ICheckboxWithDropdownArrow {
  menuItems: string[]
  handleMenuItemClick(menuItem: string): void
  checked?: boolean
  indeterminate?: boolean
  color?: 'primary' | 'secondary' | 'default'
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
  classes?: Record<string, string>
}

const CheckboxWithDropdownArrow: React.FC<ICheckboxWithDropdownArrow> = props => {
  const classes = useStyles(props)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const { menuItems, handleMenuItemClick, checked, indeterminate, onChange, color } = props
  const [open, setOpen] = React.useState(false)

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onMenuItemClick = React.useCallback(
    (menuItem: string) => {
      handleMenuItemClick(menuItem)
      handleClose()
    },
    [handleClose, handleMenuItemClick]
  )

  return (
    <div>
      <div className={classes.checkboxAndIconButtonWrapper}>
        <Checkbox
          classes={{ root: classes.checkBoxRoot }}
          disableRipple
          disableFocusRipple
          onChange={onChange}
          checked={checked}
          indeterminate={indeterminate}
          disableTouchRipple
          color={color}
        />
        <IconButton
          classes={{ root: classes.arrowDropDownRoot }}
          disableRipple
          onClick={() => setOpen(prevState => !prevState)}
          ref={anchorRef}
        >
          <ArrowDropDown fontSize="small" />
        </IconButton>
      </div>
      <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={5} id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems.map((menuItem: string, index: number) => (
                    <MenuItem key={index} onClick={() => onMenuItemClick(menuItem)}>
                      {menuItem}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default CheckboxWithDropdownArrow
