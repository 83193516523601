import { PagedTable, usePagedTable } from '@jetkit/react'
import { Dialog, IconButton, Table, TableBody } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { changeScheduleStatus, getOrderReminderSchedules, ReminderScheduleProps } from 'api/orderItem'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo, useEffect } from 'react'
import useRouter from 'use-react-router'
import { useStyles } from '../styles'
import ReminderScheduleRow from './ReminderScheduleRow'
import ReminderScheduleTableHead from './ReminderScheduleTableHead'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { REMINDER_SCHEDULES_STATUS } from 'utils/constant'

interface ReminderScheduleModalProps {
  isOpen: boolean
  close: () => void
}

const ReminderScheduleModal = ({ isOpen, close }: ReminderScheduleModalProps) => {
  const classes = useStyles()
  const { match } = useRouter()

  const { id }: any = match?.params

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const tableHead = useMemo(
    () => <ReminderScheduleTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getOrderReminderSchedules(id)(), [id])

  const pagedTable = usePagedTable<ReminderScheduleProps>({
    apiCall: memoApiCall,
  })

  useEffect(() => {
    pagedTable.reloadData()
  }, [isOpen, pagedTable])
  const [loading, setLoading] = React.useState<boolean>(false)
  const changeStatus = async (id: number) => {
    try {
      // setLoading(true)
      await changeScheduleStatus(id, { status: REMINDER_SCHEDULES_STATUS.NotSent })
      setLoading(false)
      showSuccessNotification('Updated Successfully')
      pagedTable.reloadData()
    } catch (error) {
      showApiResponseError(error, 'Error updating Status')
      setLoading(false)
    }
  }
  return (
    <Dialog open={isOpen} PaperProps={{ className: classes.paper }} /*maxWidth="md"*/ fullScreen>
      <div className={classes.headerItem}>
        <div className={classes.title}>Reminder Schedules List</div>
        <IconButton onClick={close} className={classes.closeButton}>
          <Close fontSize="large" />
        </IconButton>
      </div>

      <SearchBar className={classes.searchBar} onChange={e => console.log(e)} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 6, sizes: [120, 120, 200, 120], numberOfRows: 4 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Reminder Items"
          {...pagedTable.renderProps}
          renderRow={(reminderItem: ReminderScheduleProps) => (
            <ReminderScheduleRow loading={loading} changeStatus={changeStatus} reminderItem={reminderItem} />
          )}
          header={tableHead}
        />
      )}
    </Dialog>
  )
}

export default ReminderScheduleModal
