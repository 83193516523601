import { apiClient, requestPaginated } from '@jetkit/react'

export interface IOrderReminderItems {
  id?: number
  item_name?: string
  item_description?: string
  created_at?: string
  created_by?: string
  status?: string
  is_deleted?: boolean
  deleted_at?: string
  order_reminder_id?: number
  completed_by?: string
  item_response?: string
  reject_reason?: string
  rejected_by?: string
  item_response_time?: string
  uploads?: any[]
}

interface OrderReminderEntitiesResponse {
  companies: any[]
  tax_orders: any[]
}

// export const getClientMissingItemList = (companyId: number, status: string, type: any) =>
//   requestPaginated<IOrderReminderItems[]>({
//     url: `client/order-reminder-items?id=${companyId}&status=${status}&type=${type}`,
//   })

export const getClientMissingItemList = async (companyId: number, status: string, type: any): Promise<any> => {
  return (await apiClient.get(`client/order-reminder-items?id=${companyId}&status=${status}&type=${type}`)).data
}

export const fileUploadSignURL = async (itemId: number, fileName: string): Promise<any> => {
  return (await apiClient.post(`client/missing-item/${itemId}/upload-asset`, { file_name: fileName })).data
}

export const missingItemAnswer = async (itemId: number, answerText: string): Promise<any> => {
  return (await apiClient.post(`client/order-reminder-items/response/${itemId}`, { item_response: answerText })).data
}

export const getOrderReminderEntities = async (): Promise<OrderReminderEntitiesResponse> => {
  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
  return (await apiClient.get(`client/order-reminder-entities`)).data
}
