import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { ICreatedSpecialOffers } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'
export interface ISpecialOffersCategory {
  id?: number
  category_img?: string
  title?: string
  link_text?: string
  heading?: string
  description?: string
}

export interface ISpecialOffers {
  id?: number
  logo?: string
  title?: string
  company_prompt?: string
  button_text?: string
  link?: string
  color?: string
  ispopup?: boolean
  completed_orders?: boolean
  heading?: string
  subheading?: string
  description?: string
  category_id?: number
  terms_text?: string
}

export interface IProTip {
  id: number
  title: string
  description: string
  link: string
  link_text: string
  img_url: string
  category_id: number
}
interface SalectList {
  value: number | string
  label: string
  color?: string
}
interface IUploadFeatureImgReq {
  entity: 'specialoffers' | 'specialoffers_category'
}

export const createClient = async (client): Promise<ICreatedSpecialOffers> => {
  delete client.id // delete the extid, the backend should take care of it

  return (await apiClient.post('specialoffers', snakeCaseKeys(client))).data
}

export const updateClient = async (client: ISpecialOffers | any): Promise<ICreatedSpecialOffers> => {
  return (await apiClient.post(`specialoffers/${client.id}`, snakeCaseKeys(client))).data
}
export const fetchClient = async (id: number): Promise<ICreatedSpecialOffers> => {
  return (await apiClient.get(`specialoffers/${id}`)).data
}

export const paginateStates = () => {
  return requestPaginated<ICreatedSpecialOffers[]>({ url: '/specialofferslist' })
}

export const deleteOffer = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`specialoffers/${id}`)
}

export const getSpecialoffersByCategoryID = async (id: number): Promise<ISpecialOffers[]> => {
  return (await apiClient.get(`specialoffers?category_id=${id}`)).data
}

export const getSpecialoffers = async (): Promise<ISpecialOffers[]> => {
  return (await apiClient.get(`specialoffers`)).data
}

export const getSpecialoffersCategories = async (): Promise<ISpecialOffersCategory[]> => {
  return (await apiClient.get(`specialofferscategories`)).data
}

export const getCategoryProTip = async (id: number): Promise<IProTip[]> => {
  return (await apiClient.get(`protips-paginated?page=1&page_size=100&category_id=${id}&query=`)).data
}
export const getSpecialOfferLabels = async (id: number, type: string): Promise<SalectList> => {
  const res = await apiClient.get(`special-offer/labels//${id}`, { params: { type } })
  return res.data
}
export const uploadOfferImage = async (
  id: number | undefined,
  file_name: File,
  req: IUploadFeatureImgReq
): Promise<any> => {
  const url = (await apiClient.post(`/upload-feature-img/${id}`, req)).data
  return await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data', //'multipart/form-data',
    },
    body: file_name,
  })
}

export const getSpecialofferByID = async (id: number): Promise<ISpecialOffersCategory> => {
  return (await apiClient.get(`specialofferscategories/${id}`)).data
}

export const updateSpecialOfferTableRow = async (source: number, destination: number): Promise<void> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.109:3000/'
  return (await apiClient.post(`/specialoffers-sequence`, { source, destination })).data
}

export const getCompanySpecialOffers = async (id: string): Promise<ISpecialOffers[]> => {
  return await (await apiClient.get(`client/company/special-offers/${id}`)).data
}
