import React from 'react'
import { isLoggedIn } from 'axios-jwt'
import { updateUserlog } from 'utils/user'
import { USERTYPES } from 'utils/constant'

const ClickAnywhereWrapper = ({ children }) => {
  const [clicks, setClicks] = React.useState<number>(JSON.parse(localStorage?.getItem('user_clicks') || '0') || 0)
  const [updateLogs, setUpdateLogs] = React.useState(false)

  React.useEffect(() => {
    const clicksJSON = localStorage?.getItem('user_clicks')
    const data: any = clicksJSON && JSON.parse(clicksJSON)
    setClicks(data)
  }, [])

  React.useEffect(() => {
    localStorage.setItem('user_clicks', JSON.stringify(clicks))
  }, [clicks])

  React.useEffect(() => {
    const empLogUpdate = async () => {
      const userDetails = JSON?.parse(localStorage?.getItem('currentUser') || '')
      const userType = userDetails?.user_type
      try {
        if (userType !== USERTYPES.client && userType !== USERTYPES.salesRep) await updateUserlog()
        setUpdateLogs(false)
        setClicks(0)
      } catch (error) {
        setUpdateLogs(false)
      }
    }
    isLoggedIn() && updateLogs && empLogUpdate()
  }, [updateLogs, isLoggedIn])

  const timer = setTimeout(() => {
    setUpdateLogs(true)
  }, 60000)

  React.useEffect(() => {
    return () => clearTimeout(timer)
  }, [timer])

  const updateClicks = () => {
    // debugger
    isLoggedIn() && setClicks(state => state + 1)
  }

  return <div onClick={() => updateClicks()}>{children}</div>
}

export default ClickAnywhereWrapper
