import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'

export interface LabelItemProps {
  id?: number
  name: string
  color: string
  type?: string
}
interface SalectList {
  value: number | string
  label: string
  color?: string
}

export const getCompanyLabels = async (id: number, type: string): Promise<SalectList> => {
  const res = await apiClient.get(`company/labels/${id}`, { params: { type } })
  return res.data
}
export const findLabelList = async (type: string): Promise<any> => {
  const res = await apiClient.get(`companylabelslist`, { params: { type } })
  return res.data
}
export const getLabels = () =>
  requestPaginated<any>({
    url: '/companylabels',
  })

export const getLabelItemById = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.get(`/companylabels/${id}`)

export const deleteLabel = async (id: number): Promise<AxiosResponse<void>> => apiClient.delete(`/companylabels/${id}`)

export const createLabel = async (missingData: LabelItemProps): Promise<AxiosResponse<void>> =>
  await apiClient.post('/companylabels', missingData)

export const editLabel = async (id: number, labelItem: LabelItemProps): Promise<AxiosResponse<void>> =>
  await apiClient.patch(`companylabels/${id}`, labelItem)

export const addLabelsForCompany = async (data: any, id: number, type: string): Promise<AxiosResponse<void>> =>
  await apiClient.post(`/company/labels/${id}`, data, { params: { type } })
