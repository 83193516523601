import Addresses from 'components/common/addresses'
import AddressesSelect from 'components/common/addresses/addressesSelect'
import { IInputFieldsErrors } from 'components/companies/create'
import { IAddress } from 'models/address'
import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: '100%',
      margin: '0.7rem 0 0 0',
    },
  })
)

interface PrimaryAddressProps {
  address?: IAddress
  addressOptions: IAddress[]
  onAddAddressClick: () => void
  onAddressEditClick: (id: number) => void
  onAddressDeleteClick: (id: number) => void
  handleCompanyAddressChange: (newAddressId: number | null) => void
  errors: IInputFieldsErrors
}

/**
 * This component is some part of code extracted from big file.
 * Not to be reused.
 *
 * TODO: #401 make PrimaryAddress component reusable, have better props
 */
export const PrimaryAddress = ({
  address,
  addressOptions,
  onAddAddressClick,
  onAddressEditClick,
  onAddressDeleteClick,
  handleCompanyAddressChange,
  errors,
}: PrimaryAddressProps) => {
  const classes = useStyles()

  /**
   * converting to list because that's what the component expects
   */
  const primaryAddressAsList = address ? [address] : []
  React.useEffect(() => {
    // onAddressEditClick();
  }, [])
  return address ? (
    <Addresses
      label="Company Address"
      onlyOneAddress={true}
      addresses={primaryAddressAsList}
      onAddressEditClick={onAddressEditClick}
      onAddressDeleteClick={onAddressDeleteClick}
    />
  ) : (
    <AddressesSelect
      className={classes.textField}
      dataTestId="company-address-dropdown"
      onAddAddressClick={onAddAddressClick}
      addresses={addressOptions}
      label="Company Address"
      onChange={handleCompanyAddressChange}
      error={errors.address}
      helperText={errors.address && 'Please Select A Primary Address'}
      style={{ marginBottom: errors.address ? '1.7rem' : undefined }}
    />
  )
}
