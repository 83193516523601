import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientCompanies: {
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2rem',
        fontFamily: theme.typography.h1.fontFamily,
        marginBottom: '1.3rem',
      },
      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },

      '& .companies-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '1rem 0 2rem 0',

        // marginBottom: '1rem',

        '& > div:first-child': {
          marginRight: '1rem',
        },
      },
      '& .ques': {
        fontSize: '0.875rem',
        '& .link-schedule': {
          color: '#5b38da',
          fontWeight: theme.typography.fontWeightBold,
          cursor: 'pointer',
          textDecoration: 'underline',
          fontFamily: theme.typography.fontFamily,
        },
        '& .schedule-call-modal-container': {
          color: '#5b38da',
          fontWeight: theme.typography.fontWeightBold,
          textDecoration: 'underline',
          fontFamily: theme.typography.fontFamily,
          border: 'none',
          background: 'none',
          padding: 0,
          cursor: 'pointer',
        },
        // backgroundColor:"yellow",
      },
      '& .header': {
        background: '#e8f0ff',
        fontSize: '0.875rem',
        padding: '1.2rem 1rem',
        borderRadius: '4px',
        lineHeight: '1.2rem',
        '& .companies-name': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '& .power-ups-section': {
        margin: '5rem 0 2rem 0',
      },
    },
    companyCard: {
      background: '#f7faff',
      fontFamily: 'Lato',
      padding: '1.25rem',

      '& p, h1': {
        margin: '0',
      },

      '& .icon-sec': {
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: 'linear-gradient(to right,  #d4f0fc 0%,#d4f0fc 50%,#5bb3d9 50%,#5bb3d9 100%)',
        width: '52px',
        height: '52px',
      },
      '& .active-sec': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& .MuiChip-root': {
          background: '#04a464',
          color: '#fff',
          padding: '0 0.8rem',
          fontSize: '0.9rem',
        },

        '& .date-text': {
          color: '#292929',
          fontSize: '0.9rem',
          paddingTop: '0.25rem',
        },
      },

      '& .company-text': {
        color: '#020202',
        fontSize: '1.2rem',
        padding: '1rem 0 0.5rem 0',
        [theme.breakpoints.down('md')]: {
          fontSize: '0.8rem',
        },
      },
      '& .state-text': {
        color: '#292929',
        fontSize: '1rem',
      },
      '& .error-icon': {
        display: 'flex',
        alignItems: 'center',
        minHeight: '56px',

        '& p': {
          paddingLeft: '0.5rem',
          color: '#292929',
          fontSize: '0.85rem',
        },
      },
      '& .detail-text': {
        color: '#5b38da',
        fontSize: '1rem',
        fontFamily: 'Lato',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        lineHeight: 0,
        fontWeight: 600,
      },
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    missingItemContainer: {
      backgroundColor: '#F7FAFF',
      padding: '1.5rem 1rem',
      borderRadius: '10px',
      '& p,h1': {
        padding: 0,
        margin: 0,
      },
      '& .left-section': {
        display: 'flex',
        // paddingTop: '0.5rem',
        // alignItems: 'center',
        // width: '100%',
        justifyContent: 'space-between',
        '& .company-text': {
          color: '#020202',
          // fontSize: '1.2rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          minHeight: '78px',
          maxWidth: '208px',
          // minWidth: '208px',
          // [theme.breakpoints.down('sm')]: {
          //   minWidth: '208px',
          // },
          // [theme.breakpoints.down('xs')]: {
          //   minWidth: 'auto',
          // },
        },

        // '& .state-text': {
        //   color: '#292929',
        //   fontSize: '1rem',
        //   paddingTop: '0.2rem',
        //   textTransform: 'uppercase',
        // },
        '& .warning-text': {
          color: '#5b38da',
          fontWeight: 700,
          textAlign: 'end',
          // minHeight: '70px',
          // textTransform: 'uppercase',
        },
      },
      '& .right-section': {
        display: 'flex',
        // justifyContent: 'flex-end',
        // flexDirection: 'column',
        // alignItems: 'end',
        justifyContent: 'space-between',
        // width: '100%',
        // '& .warning-text': {
        //   color: '#5b38da',
        //   fontWeight: 700,
        //   textAlign: 'end',
        //   // minHeight: '70px',
        //   // textTransform: 'uppercase',
        // },
        '& .state-text': {
          color: '#292929',
          fontSize: '1rem',
          paddingTop: '0.2rem',
          textTransform: 'uppercase',
        },
      },
      '& img': {
        marginRight: '1rem',
      },
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    reviewBtnDiv: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '15px !important',
        border: '1px solid black !important',
      },
      '& .review-btn': {
        fontFamily: 'Roboto,sans-serif',
        fontSize: '0.9rem',
        color: '#fff',
        background: '#f44336',
        borderRadius: '1rem',
        // padding: '0.3rem 1.8rem',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        boxShadow: 'none',
      },
    },

    missingItemDetails: {
      display: 'flex',
      padding: '50px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },
      '& .MuiDivider-root': {
        background: '#788190',
        height: '2px',
      },
      '& .main-heading': {
        color: theme.customPalette.primaryFontColor,
        fontSize: '2rem',
        fontFamily: theme.typography.h1.fontFamily,
        marginBottom: '1.3rem',
      },
      '& .tabs': {
        borderBottom: '1px solid #e0e0e0',
        '& .MuiTabs-indicator': {
          backgroundColor: '#26316C !important',
        },
        '& span': {
          textTransform: 'capitalize',
          fontWeight: 600,
        },
      },
      '& .ques-no': {
        fontWeight: 700,
        margin: 0,
      },
    },
    questionContainer: {
      '& .question-field': {
        marginBottom: '5px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '15px !important',
          border: '1px solid black !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          color: 'black !important',
        },
      },
      '& .quest': {
        '& :first-child': {
          marginBottom: '0.3rem',
        },
        '& p,h1,,h2,h3,h4,h5,h6': {
          margin: 0,
        },
      },
      '& .error-div': {
        marginBottom: '1.5rem',
        color: 'red',
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'italic',
        '& p': {
          margin: 0,
          marginLeft: '0.5rem',
          fontSize: '0.813rem',
        },
      },
      '& .attachment-div': {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          fontSize: '0.95rem',
        },
      },
    },
    submitbtnDiv: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .MuiButton-containedPrimary:hover': {
        backgroundColor: '#5b38da !important',
        boxShawdow: 'none !important',
      },
      '& .submitbtn': {
        fontFamily: 'Roboto,sans-serif',
        fontSize: '0.82rem',
        color: '#fff',
        borderRadius: '1rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '0.8rem',
        },
        // padding: '0.3rem 1.8rem',
        lineHeight: 'normal',
        backgroundColor: '#5b38da',
        // width: '150px',
        height: '30px',
        boxShadow: 'none',
      },
    },
    downloadButton: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    downloadButtonLink: {
      textDecoration: 'none',
    },
    attachmentList: {
      padding: '2rem 0',
      paddingTop: 0,
    },
    imageList: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: '0.5rem',
      },
    },
    imageFile: {
      display: 'flex',
      alignItems: 'center',
      width: '75%',
      '& p': {
        paddingLeft: '0.5rem',
        margin: 0,
        fontSize: 12,
      },
    },
    rounded: {
      color: '#fff',
      backgroundColor: '#ff8b8b',
      fontSize: 14,
    },
  })
)
