import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import React, { useMemo } from 'react'
import AddLabelDialog from './AddLabelDialog'
import { useStyles } from './style'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import LabelTableHead from './LabelTableHead'
import useSortTable from 'hooks/useSortTable'
import { deleteLabel, getLabels, LabelItemProps } from 'api/label'
import { skeletons } from 'components/common/table'
import DeleteDialog from 'components/notifications/deleteDialog'
import LabelRow from './LabelRow'

const CompanyLabel = () => {
  const classes = useStyles()

  const [query, setQuery] = React.useState('')
  const [labelItemDialogShown, setLabelItemDialogShown] = React.useState(false)
  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [editedLabel, setEditedLabel] = React.useState<LabelItemProps>()
  const [labelDelete, setLabelDelete] = React.useState<LabelItemProps>()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })

  const tableHead = useMemo(
    () => <LabelTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getLabels()({ query }), [query])
  //   const apiCall = React.useMemo(() => paginateClients()({ query }), [query])

  const pagedTable = usePagedTable<LabelItemProps>({
    apiCall: memoApiCall,
  })

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleEditLabelClick = React.useCallback((label: LabelItemProps) => {
    setEditedLabel(label)
    setLabelItemDialogShown(true)
  }, [])

  const handleDeleteLabelClick = React.useCallback((label: LabelItemProps) => {
    setLabelDelete(label)
    setDeleteItemDialogShown(true)
  }, [])

  const handleLabelDialogCloseClick = React.useCallback(() => {
    setLabelItemDialogShown(false)
  }, [])

  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.missingItems}>Labels</p>
          <p className={classes.tableTitle}>Labels List</p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-label" onClick={() => setLabelItemDialogShown(true)}>
            Add Label
          </ButtonWithLoading>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>{skeletons({ numberOfColumns: 3, sizes: [120, 200, 120], numberOfRows: 6 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Label"
          {...pagedTable.renderProps}
          renderRow={(label: LabelItemProps) => (
            <LabelRow
              labelItem={label}
              onEditClick={() => handleEditLabelClick(label)}
              onDeleteClick={() => handleDeleteLabelClick(label)}
            />
          )}
          header={tableHead}
        />
      )}
      <AddLabelDialog
        isOpen={labelItemDialogShown}
        close={handleLabelDialogCloseClick}
        editedLabelItem={editedLabel}
        onRequestSuccessful={handleSuccessfulRequest}
      />
      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={labelDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deleteLabel}
      />
    </div>
  )
}

export default CompanyLabel
