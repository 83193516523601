// to import function from this file into cypress file, we need to import `moment` using `require`
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment')

export const backendDateFormat = 'YYYY-MM-DD'
export const backendDateTimeFormat = 'MM/DD/YYYY hh:mm A'
export const backendDateTimeZonFormat = 'YYYY-MM-DD 00:00 Z'

export const datePickerDatePlaceholder = 'MM/DD/YYYY'
export const datePickerDatePlaceholderMMYYYY = 'MM/DD'

export const datePickerDateFormat = 'MM/DD/YYYY'
export const datePickerDateFormatMMYYYY = 'MM/DD'

export const formatDateForApiPost = (date: Date | string): string => moment(date).format(backendDateFormat)

export const formatDateTimeZoneForApiPost = (date: Date | string): string =>
  moment(date).format(backendDateTimeZonFormat)

export const formatDateToHumanFriendly = (date: Date | string): string => moment(date).format('DD MMMM YYYY')

export const formatDateTimeToHumanFriendly = (date: Date | string): string =>
  moment(date).format('DD MMMM YYYY, h:mm a')

export const formatDateForInputField = (date: string | Date): string => moment(date).format(datePickerDateFormat)

export const getCurrentTime = (format: string): string => moment().format(format)

export const getDueDate = (dueDate: string): string => {
  if (!dueDate) return ''
  if (
    moment()
      .endOf('day')
      .isSame(moment(dueDate).endOf('day'))
  )
    return 'Today'
  else if (
    moment()
      .add(1, 'day')
      .endOf('day')
      .isSame(moment(dueDate).endOf('day'))
  )
    return 'Tomorrow'
  else if (
    moment()
      .add(-1, 'day')
      .endOf('day')
      .isSame(moment(dueDate).endOf('day'))
  )
    return 'Yesterday'
  else if (moment(dueDate).isBetween(moment(), moment().endOf('week'))) return moment(dueDate).format('dddd')
  return moment(dueDate).format('MMMM Do')
}

export const getDueDateWithYear = (dueDate: string): string => {
  if (!dueDate) return ''
  if (moment(dueDate).isSame(new Date(), 'year')) return getDueDate(dueDate)
  else return moment(dueDate).format('MMMM Do, YYYY')
}

export const getTimeFromNow = (dueDate: string): string => {
  if (!dueDate) return ''
  return moment(dueDate).from(moment.now())
}

export const backendDateStringToFrontendFormat = (date: string): string =>
  moment(date, backendDateFormat).format(datePickerDateFormat)

export const dateFromString = (date: string): moment.Moment => moment(date, 'MM/DD/YYYY')

export const monthSubstring = (date: Date): string => moment(date).format('MM')

export const daySubstring = (date: Date): string => moment(date).format('DD')

export const yearSubstring = (date: Date): string => moment(date).format('YYYY')

export const formatDateToHumanFriendlyWithComa = (date: Date | string): string => moment(date).format('MMM DD, YYYY')

export const formatTransactionDate = (transaction_date: string) => {
  /*
  returns a date with format %Y/%m/%d %H:%M
  */
  if (transaction_date === '') return ''
  const date = new Date(transaction_date)
  return (
    date.getFullYear() + '/' + date.getMonth() + '/' + date.getDay() + ' ' + date.getHours() + ':' + date.getMinutes()
  )
}
