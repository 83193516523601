import { FilterableAPICall, handleError, PagedTable, usePagedTable } from '@jetkit/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  addQuestion,
  deleteQuestion,
  editQuestion,
  fetchTaxQuestions,
  IQuestion,
  QuestionStatusText,
} from 'api/taxOrders'
import SearchBar from 'components/common/searchBar'
import QuestionCard from 'components/taxOrders/details/pendingQuestions/questionCard'
import QuestionDialog, { QuestionDialogType } from 'components/taxOrders/details/pendingQuestions/questionDialog'
import Toolbar from 'components/taxOrders/details/pendingQuestions/toolbar'
import { assetTypeToDocumentHandler, DocumentHandler, UploadRequestClass } from 'models/asset'
import * as React from 'react'
import { ComponentToPrint } from 'utils/pdfPrintWrapper'

export const AssetsUploadCallBackContext = React.createContext<UploadRequestClass | undefined>(undefined)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: theme.customPalette.tableBackgroundColor,
      paddingBottom: 300,
    },
    innerContainer: {
      display: 'flex',
      maxWidth: '52rem',
      width: '100%',
      flexDirection: 'column',
    },
    searchBarContainer: {
      marginTop: '2rem',
      display: 'flex',
      width: '100%',
    },
  })
)

interface IPendingQuestionsProps {
  taxOrderId: number
  apiCall?: FilterableAPICall<IQuestion>
}

const PendingQuestions: React.FC<IPendingQuestionsProps> = ({
  taxOrderId,
  apiCall = fetchTaxQuestions(taxOrderId),
}) => {
  const classes = useStyles()
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [questionsYear, setQuestionsYear] = React.useState(new Date().getFullYear().toString())
  const [questionDialogType, setQuestionDialogType] = React.useState<QuestionDialogType>('add')
  const [addQuestionDialogShown, setAddQuestionDialogShown] = React.useState(false)
  const [newQuestionText, setNewQuestionText] = React.useState('')
  const [editedQuestionId, setEditedQuestionId] = React.useState(0)
  const [editedQuestionText, setEditedQuestionText] = React.useState('')
  const [editedAnswerText, setEditedAnswerText] = React.useState('')
  const [waitingForResponse, setWaitingForResponse] = React.useState(false)
  const [unansweredQuestion, setUnansweredQuestion] = React.useState(false)
  const [isQuestionAdd, setIsQuestionAdd] = React.useState(false)
  const [addQuestionId, setAddQuestionId] = React.useState(0)

  const componentRef = React.createRef<any>()

  const handleYearChange = React.useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) setQuestionsYear(date.year().toString())
    },
    [setQuestionsYear]
  )

  // apiCall is not included to these memo dependencies because in such case
  // whole component is rerendered infinitely.
  // Instead of that taxOrderId is added there, because apiCall function, returned by fetchTaxQuestions(taxOrderId: number),
  // will be changed only if taxOrderId changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoApiCall = React.useMemo(() => apiCall({ query: searchKeyword, year: questionsYear }), [
    questionsYear,
    searchKeyword,
    taxOrderId,
  ])
  const pagedTable = usePagedTable<IQuestion>({
    apiCall: memoApiCall,
  })

  const handleOpenDialogClicked = React.useCallback(
    (dialogType: QuestionDialogType) => {
      setQuestionDialogType(dialogType)
      setAddQuestionDialogShown(true)
    },
    [setQuestionDialogType, setAddQuestionDialogShown]
  )

  const handleQuestionDialogClosed = React.useCallback(() => {
    setNewQuestionText('')
    setEditedQuestionText('')
    setEditedAnswerText('')
    setAddQuestionDialogShown(false)
    setIsQuestionAdd(false)
    setEditedQuestionId(0)
    setAddQuestionId(0)
  }, [setNewQuestionText, setAddQuestionDialogShown])

  const getStatusForQuestion = React.useCallback(() => {
    if (editedAnswerText) {
      if (unansweredQuestion) {
        return QuestionStatusText.UNANSWERED
      }
      return QuestionStatusText.ANSWERED
    } else if (waitingForResponse) {
      return QuestionStatusText.SENT_TO_CLIENT
    }
    return QuestionStatusText.PENDING
  }, [editedAnswerText, unansweredQuestion, waitingForResponse])

  const handleSaveClicked = React.useCallback(
    async (dialogType: QuestionDialogType) => {
      setWaitingForResponse(true)
      try {
        if (dialogType === 'add') {
          const res: any = await addQuestion(taxOrderId, newQuestionText)
          setAddQuestionId(res?.id)
          setIsQuestionAdd(true)
        } else {
          await editQuestion(editedQuestionId, editedQuestionText, editedAnswerText, getStatusForQuestion())
          setAddQuestionDialogShown(false)
        }
        setWaitingForResponse(false)
        // setAddQuestionDialogShown(false)
        // setNewQuestionText('')
        setEditedQuestionText('')
        setEditedAnswerText('')
        pagedTable.reloadData()
      } catch (error) {
        alert(error)
        setWaitingForResponse(false)
        handleError(error)
      }
    },
    [
      taxOrderId,
      newQuestionText,
      editedQuestionId,
      editedQuestionText,
      editedAnswerText,
      setWaitingForResponse,
      // setNewQuestionText,
      setEditedQuestionText,
      setEditedAnswerText,
      pagedTable,
      getStatusForQuestion,
    ]
  )

  const handleDeleteQuestionClicked = React.useCallback(
    async (question: IQuestion) => {
      setWaitingForResponse(true)
      try {
        await deleteQuestion(question.id)
        setWaitingForResponse(false)
        pagedTable.reloadData()
      } catch (error) {
        setWaitingForResponse(false)
        handleError(error)
      }
    },
    [pagedTable]
  )

  const handleMarkUnansweredClick = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnansweredQuestion(event.target.checked)
    },
    [setUnansweredQuestion]
  )

  const documentHandler: DocumentHandler = React.useMemo(() => {
    const DocumentHandler = assetTypeToDocumentHandler['questions']
    return new DocumentHandler()
  }, [])

  return (
    <AssetsUploadCallBackContext.Provider value={documentHandler.UploadRequest}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <SearchBar className={classes.searchBarContainer} onChange={searchText => setSearchKeyword(searchText)} />
          <Toolbar
            pickedYear={questionsYear}
            onYearChange={handleYearChange}
            onAddQuestionClicked={() => handleOpenDialogClicked('add')}
            refComp={componentRef}
          />

          <ComponentToPrint ref={componentRef}>
            {pagedTable.isLoading ? (
              <CircularProgress style={{ alignSelf: 'center', marginTop: '1rem' }} />
            ) : (
              <PagedTable
                {...pagedTable.renderProps}
                renderRow={(question: IQuestion) => (
                  <QuestionCard
                    question={question}
                    onEditClick={() => {
                      setEditedQuestionText(question.question_text)
                      setEditedAnswerText(question.answer_text)
                      setEditedQuestionId(question.id)
                      handleOpenDialogClicked('edit')
                    }}
                    onDeleteClick={handleDeleteQuestionClicked}
                  />
                )}
                colSpan={1}
              />
            )}
          </ComponentToPrint>
        </div>
        <QuestionDialog
          dialogType={questionDialogType}
          shown={addQuestionDialogShown}
          onClose={handleQuestionDialogClosed}
          newQuestionText={newQuestionText}
          editedQuestionText={editedQuestionText}
          editedAnswerText={editedAnswerText}
          onNewQuestionTextChange={setNewQuestionText}
          onEditQuestionTextChange={setEditedQuestionText}
          onEditAnswerTextChange={setEditedAnswerText}
          loading={waitingForResponse}
          onSaveClick={handleSaveClicked}
          unansweredQuestion={unansweredQuestion}
          onMarkUnansweredClick={handleMarkUnansweredClick}
          questionId={editedQuestionId}
          addQuestionId={addQuestionId}
          isQuestionAdd={isQuestionAdd}
          setEditedQuestionId={setEditedQuestionId}
          setAddQuestionId={setAddQuestionId}
        />
      </div>
    </AssetsUploadCallBackContext.Provider>
  )
}

export default PendingQuestions
