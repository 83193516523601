import {
  createStyles,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  TableCell,
  Theme,
  Typography,
} from '@material-ui/core'
import { Subject } from '@material-ui/icons'
import { getTaskDescriptions, ITaskDescription, markTaskAsDone, markTaskAsNotDone } from 'api/task'
import classNames from 'classnames'
import CircleCheckbox from 'components/common/elements/CircleCheckbox'
import { ExpandableTableRow } from 'components/common/expandableTableRow'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import OrderSubTask from 'components/orders/OrderSubTask'
import useUser from 'hooks/useUser'
import { IOrderTask, ITask } from 'models/task'
import moment from 'moment'
import * as React from 'react'
import { COLORS } from 'utils/constant'
import { backendDateFormat, formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lowPriority: {
      color: theme.lowPriority.color,
    },
    mediumPriority: {
      color: theme.mediumPriority.color,
    },
    highPriority: {
      color: theme.highPriority.color,
    },
    taskTitleColumn: {
      padding: '15px 0',
    },
    taskTitleCellWrapper: {
      display: 'inline-flex',
    },
    taskInfoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    taskTitle: {
      width: 148,
    },
    actionsCellWrapper: {
      width: 120,
    },
    actionsWrapper: {
      display: 'inline-flex',
    },
    taskDescription: {
      marginLeft: 10,
    },
    bold: {
      fontWeight: 'bold',
    },
    circleCheckbox: {
      pointerEvents: 'all',
    },
    completedBy: {
      marginLeft: 10,
      marginTop: 10,
    },
    prevNotes: {
      '& .MuiListSubheader-root': {
        lineHeight: 'initial',
        marginBottom: '1rem',
        color: '#2a2a2a',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 400,
      },
      '& li': {
        padding: 0,
        marginBottom: 0,

        '& .MuiListItemIcon-root': {
          minWidth: 'auto',
          marginRight: '0.5rem',
          marginTop: '0.25rem',

          '& svg': {
            fontSize: '1rem',
          },
        },
        '& .MuiListItemText-root': {
          margin: 0,

          '& > p': {
            fontSize: '0.75rem',
          },
        },
      },
      '& .MuiDivider-root': {
        margin: '0.5rem 0',
      },
      // marginBottom: '1rem',
    },
  })
)

interface IOrderTaskRowProps {
  task: IOrderTask
  setTasks: React.Dispatch<React.SetStateAction<IOrderTask[]>>
  handleEditClick: (task: IOrderTask) => void
  handleDeleteClick: (task: IOrderTask) => void
  is_rush_order?: boolean
}

const OrderTaskRow = ({ task, setTasks, handleEditClick, handleDeleteClick, is_rush_order }: IOrderTaskRowProps) => {
  const classes = useStyles()

  const hasSubtasks = task.subtasks && !!task.subtasks.length
  const [taskDescription, settaskDescription] = React.useState<ITaskDescription[]>([])

  React.useEffect(() => {
    const getDescription = async (id: number) => {
      try {
        const res: ITaskDescription[] = await getTaskDescriptions(id)
        settaskDescription(res)
        console.log('resresres', res)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (task?.id) {
      getDescription(task?.id)
    }
  }, [task?.id])

  const priorityCellColorClass = React.useMemo(
    () => ({
      [classes.lowPriority]: task.priority === 'low',
      [classes.mediumPriority]: task.priority === 'medium',
      [classes.highPriority]: task.priority === 'high',
    }),
    [task.priority, classes.lowPriority, classes.mediumPriority, classes.highPriority]
  )

  const completedSubtasksCount: number | undefined = React.useMemo(
    () =>
      task.subtasks &&
      task.subtasks.reduce(
        (totalCompleted, subtask) => totalCompleted + Number(subtask.doc_status === 'sent_to_cpuc'),
        0
      ),
    [task.subtasks]
  )

  const handleUploadClick = (subTask: ITask) => {
    console.debug('upload clicked', subTask.id)
  }

  const { user } = useUser()
  React.useEffect(() => {
    // task.completed_at = task.completed?.toString()
  }, [task.completed])
  const completeTask = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!task.id) return

      const done = event.target.checked
      if (done) {
        await markTaskAsDone(task.id)
      } else {
        await markTaskAsNotDone(task.id)
      }

      setTasks(prevTasks => {
        const tasks = prevTasks.map(existingTask => {
          if (existingTask.id !== task.id) {
            return existingTask
          }
          return {
            ...existingTask,
            // mark it as done locally so we don't have to do another api call
            completed: done
              ? formatDateTimeToHumanFriendly(moment().toString()) /*moment().format(backendDateFormat)*/
              : null,
            completed_at: done
              ? formatDateTimeToHumanFriendly(moment().toString()) /*moment().format(backendDateFormat)*/
              : ' ',
            completed_by: (done && user) || undefined,
          }
        })
        return tasks
      })
    },
    [setTasks, task.id, user]
  )

  const taskSummary = React.useMemo(
    () => (
      <React.Fragment>
        <TableCell className={classes.taskTitleColumn}>
          <div className={classes.taskTitleCellWrapper}>
            <CircleCheckbox
              className={classes.circleCheckbox}
              checked={!!task.completed}
              onChange={completeTask}
              disabled={hasSubtasks}
              dataTestId="task-checkbox"
            />

            <div className={classes.taskInfoWrapper}>
              <Typography className={classes.taskTitle}>{task.title}</Typography>

              {task.subtasks && task.subtasks.length > 0 && (
                <Typography className={classes.bold} data-testid="completed-subtasks-text">
                  {`(${completedSubtasksCount} / ${task.subtasks.length}) done`}
                </Typography>
              )}
            </div>
          </div>
        </TableCell>

        <TableCell padding="none" align="center">
          <Typography>{task.assignee ? task.assignee.name : '-'}</Typography>
        </TableCell>
        <TableCell padding="none" align="center">
          {task.due_date ? formatDateToHumanFriendly(task.due_date) : '-'}
        </TableCell>
        <TableCell padding="none" align="center" className={classNames(priorityCellColorClass)}>
          {task.priority}
        </TableCell>
        <TableCell className={classes.actionsCellWrapper} padding="none" align="right">
          <div className={classes.actionsWrapper}>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                handleEditClick(task)
              }}
              data-testid="edit-task-button"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                handleDeleteClick(task)
              }}
              data-testid="delete-task-button"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </TableCell>
      </React.Fragment>
    ),
    [
      classes,
      completeTask,
      completedSubtasksCount,
      handleDeleteClick,
      handleEditClick,
      hasSubtasks,
      priorityCellColorClass,
      task,
    ]
  )

  const taskDetails = React.useMemo(
    () => (
      <TableCell colSpan={6}>
        {task.subtasks && task.subtasks.length ? (
          <OrderSubTask subTasks={task.subtasks} onUploadClick={handleUploadClick} setTasks={setTasks} />
        ) : // <Typography className={classes.taskDescription}>
        //   <b>Description:</b> {task.details}
        // </Typography>
        taskDescription?.length ? (
          <List className={classes.prevNotes} subheader={<ListSubheader component="div">Notes History</ListSubheader>}>
            {taskDescription?.map((item: ITaskDescription) => (
              <>
                <ListItem alignItems="flex-start" key={item?.id}>
                  <ListItemIcon>
                    <Subject />
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.note_text}
                    secondary={
                      <React.Fragment>
                        {item?.created_by}&nbsp;
                        {item?.created_at ? formatDateTimeToHumanFriendly(item?.created_at) : ''}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </>
            ))}
          </List>
        ) : (
          ''
        )}

        <Typography className={classes.completedBy}>
          <b>Created by:</b> {task.created_by || 'unknown'} at{' '}
          {task.created_at !== null ? formatDateTimeToHumanFriendly(task.created_at || '') : 'unknown date'}
        </Typography>
        {task.assigned_to && (
          <Typography className={classes.completedBy}>
            <b>Assigned by:</b> {task.assigned_by || 'unknown'} <b> to</b> {task.assignee ? task.assignee.name : '-'} at{' '}
            {task.assigned_at !== null ? formatDateTimeToHumanFriendly(task.assigned_at || '') : 'unknown date'}
          </Typography>
        )}

        {task.completed && (
          <Typography className={classes.completedBy}>
            <b>Completed by:</b> {(task.completed_by && task.completed_by.name) || 'unknown'} at{' '}
            {task.completed_at ? formatDateTimeToHumanFriendly(task.completed_at || '') : ''}
          </Typography>
        )}
      </TableCell>
    ),
    [
      classes.completedBy,
      classes.prevNotes,
      setTasks,
      task.assigned_at,
      task.assigned_by,
      task.assignee,
      task.completed,
      task.completed_at,
      task.completed_by,
      task.created_at,
      task.created_by,
      task.subtasks,
      taskDescription,
    ]
  )

  return (
    <ExpandableTableRow
      style={{ backgroundColor: is_rush_order ? COLORS.rushOrders : undefined }}
      key={task.id}
      expandComponent={taskDetails}
      dataTestId="order-task-row"
    >
      {taskSummary}
    </ExpandableTableRow>
  )
}

export default OrderTaskRow
