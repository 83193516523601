import { SortingType } from 'components/common/table'
import { ITaxOrderStatus, ITaxOrderType, TaxOrderStatuses } from 'models/taxOrder'

export interface TaxOrderSearchParams {
  tax_order_type: ITaxOrderType | string
  order_statuses: ITaxOrderStatus[]
  is_filed: boolean | string
  sort_by: string
  sort_order: SortingType
  tax_year: string[] | string
  label: number[] | number | string[] | string
  tax_order_status: boolean | string
  accountant_user_id: number[] | number | string[] | string
  company_id?: number
  stage?: number[] | number | string[] | string
  questions?: string
  tax_structure?: string
}

export const InitialSearchParams: TaxOrderSearchParams = {
  tax_order_type: 'all',
  order_statuses: [TaxOrderStatuses.default],
  is_filed: 'all',
  sort_by: 'priority',
  sort_order: 'desc',
  tax_year: [],
  label: [],
  tax_order_status: 'open',
  accountant_user_id: [],
  stage: [],
  tax_structure: '',
}
