import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'

export const paginateStates = () => {
  return requestPaginated<ICreatedState[]>({ url: '/prospectstatuses' })
}

export const deleteType = async (id: number): Promise<any> => {
  return apiClient.delete(`prospectstatuses/${id}`)
}

export const fetchClient = async (id: number): Promise<ICreatedState> => {
  return (await apiClient.get(`prospectstatuses/${id}`)).data
}

export const createClient = async (client: any): Promise<ICreatedState> => {
  delete client.id // delete the extid, the backend should take care of it
  delete client.state_display_name
  delete client.state_name
  return (await apiClient.post('prospectstatuses', snakeCaseKeys(client))).data
}

export const updateClient = async (client: any): Promise<ICreatedState> => {
  delete client.state_display_name
  //delete client.state_name

  return (await apiClient.post(`prospectstatuses/${client.id}`, snakeCaseKeys(client))).data
}
