import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '85px 15px 50px 50px',
      flexGrow: 1,

      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem',
      },

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '1.3rem',
      },
    },

    powerCard: {
      background: '#f7faff',
      padding: '1rem',
      // padding: '1rem 2rem 2rem',
      boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.16)',
      marginTop: '2rem',
      // minHeight: '281px',
      // height:"100%",
      // [theme.breakpoints.up('xs')]: {
      //   padding: '1rem',
      // },
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.5rem',
        justifyContent: 'end',
      },
      '& .clock-img': {
        height: '30px',
        width: '30px',
      },

      '& .powerimg': {
        // width: '100px',
        height: '50px',
        // display: 'flex',
        // justifyContent: 'end',
      },
      '& img': {
        // align: 'end',
        // width: '100%',
        // height: '80px',
        objectFit: 'contain',
        // display: 'block',
        // [theme.breakpoints.down('xs')]: {
        //   marginTop: '0',
        //   width: '100%',
        //   paddingBottom: '0',
        // },
        // [theme.breakpoints.only('lg')]: {
        //   width: '100%',
        // },
      },
      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .title-text': {
        color: '#307abc',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        fontWeight: 600,
        textAlign: 'end',
        paddingRight: '0.5rem',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .heading-text': {
        textTransform: 'uppercase',
        fontSize: '1.25rem',
        color: '#292929',
        fontWeight: 900,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: '32px',
      },
      '& .sub-heading-text': {
        color: '#292929',
        fontSize: '1rem',
        fontWeight: 600,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minHeight: '36px',
      },
      '& .sub-title-text': {
        // paddingTop: '1rem',
        color: '#292929',
        fontSize: '0.9rem',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: '22px',
        maxHeight: '22px',
        padding: '0 0.5rem',
        marginTop: '0.5rem',

        [theme.breakpoints.down('xs')]: {
          padding: '0 0.5rem',
        },
      },
      '& .content-text': {
        overflow: 'hidden',
        minHeight: '100px',
        '& ul': {
          paddingLeft: '1rem',
        },
        '& li': {
          // listStyleImage: `url(./check-solid.svg)`,
          listStyleType: "'✔'",
          paddingInlineStart: '1ch',
          wordWrap: 'break-word',

          '&::marker': {
            color: '#5b38da',
          },
        },
      },
      '& .see-more': {
        width: '100%',
        minHeight: '40px',
        textAlign: 'end',
        fontSize: '12px',
      },
      '& .power-btn': {
        background: '#5b38da',
        color: '#fff',
        borderRadius: '2rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
        padding: '0.75rem 2rem',
        // height: '49px',
        height: '46px',
      },

      '& .left-sec': {
        marginTop: '1rem',
        display: 'flex',
        // justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'flex-end',

        [theme.breakpoints.down('xs')]: {
          marginTop: '0',
          flexDirection: 'column-reverse',
        },
      },
    },
  })
)
