import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CheckCircle from '@material-ui/icons/CheckCircle'
import EmptyCircleCheckbox from 'icons/EmptyCircleCheckbox'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrapper: {
      width: 30,
      height: 28,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

interface ICircleCheckbox extends CheckboxProps {
  checked: boolean
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
  dataTestId?: string
}

export default function CircleCheckbox({ checked, onChange, dataTestId, ...props }: ICircleCheckbox) {
  const classes = useStyles()

  return (
    <Checkbox
      disableRipple
      disableTouchRipple
      disableFocusRipple
      onChange={onChange}
      checked={checked}
      onClick={e => e.stopPropagation()} // to disable any events in parent components
      {...props}
      icon={
        <div className={classes.iconWrapper}>
          <EmptyCircleCheckbox />
        </div>
      }
      data-testid={dataTestId}
      checkedIcon={
        <div className={classes.iconWrapper}>
          <CheckCircle fontSize="large" color="primary" />
        </div>
      }
    />
  )
}
