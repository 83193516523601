import { createStyles, Link, makeStyles, Theme } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { getCommissionsForClient } from 'api/commission'
import CommissionTable from 'components/clients/commissions'
import { ICommission } from 'models/commission'
import { IClient } from 'models/user'
import * as React from 'react'
import showErrorNotification from 'utils/showErrorNotification'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'auto',
    },
    details: {
      height: 'auto',
    },
    link: {
      padding: '0px 0px 0px 150px',
    },
  })
)

interface CommissionAccordionProps {
  editedClient?: IClient
  changeClientField: (field: keyof IClient, value: ValueOf<IClient>) => void
}

const CommissionAccordion: React.FC<CommissionAccordionProps> = ({ editedClient, changeClientField }) => {
  const [commissions, setCommissions] = React.useState<ICommission[]>([])
  const [dialogOpened, setDialogOpened] = React.useState<boolean>(false)

  const toggleDialogOpened = () => {
    setDialogOpened(!dialogOpened)
  }

  const classes = useStyles()

  const fetchCommissions = async () => {
    try {
      const commissions = await getCommissionsForClient(editedClient?.id || 0)
      if (commissions.length > 0) setCommissions(commissions)
    } catch (e) {
      showErrorNotification(e)
    }
  }
  React.useEffect(() => {
    if (editedClient && editedClient.id) {
      fetchCommissions()
    }
  }, [editedClient])

  return (
    <MuiAccordion classes={{ root: classes.accordion }}>
      <MuiAccordionSummary>
        <Typography>Transactions</Typography>
        <Link
          classes={{ root: classes.link }}
          component="button"
          variant="body2"
          onClick={toggleDialogOpened}
          data-testid="add-transaction-button"
        >
          + Add Transaction
        </Link>
        <KeyboardArrowDownIcon />
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={{ root: classes.details }}>
        <CommissionTable
          toggleDialogOpened={toggleDialogOpened}
          dialogOpened={dialogOpened}
          editedClient={editedClient}
          commissions={commissions}
          loading={false}
          fetchCommissions={fetchCommissions}
          changeClientField={changeClientField}
        />
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}

export default CommissionAccordion
