import { Popover } from '@material-ui/core'
import React, { ReactEventHandler, ReactNode } from 'react'

interface MsgPopup {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: ReactEventHandler
  children: ReactNode
}

const MsgPopup = ({ anchorEl, setAnchorEl, children }) => {
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      {children}
    </Popover>
  )
}

export default MsgPopup
