import DatePicker from 'components/common/datePicker'
import moment from 'moment'
import * as React from 'react'
import { SetFieldValueContext } from 'utils/FormGenerator'
import { IFormFieldProps } from 'utils/FormGenerator/formTypings'
import { useStyles } from 'utils/FormGenerator/styles'
import { isDateField } from 'utils/FormGenerator/typeAssertionFunction'

const DateFieldComponent = ({ field, value }: IFormFieldProps<moment.Moment>) => {
  if (!isDateField(field)) {
    throw new Error('type error')
  }

  const setFieldValue = React.useContext(SetFieldValueContext)

  const classes = useStyles()

  return (
    <DatePicker
      name={field.name}
      label={field.label}
      className={classes.fieldRoot}
      containerClassName={classes.datePickerContainer}
      InputProps={{ classes: { root: classes.textFieldInputRoot, underline: classes.textInputUnderline } }}
      InputLabelProps={{ classes: { root: classes.fieldLabel, shrink: classes.fieldLabelShrink }, shrink: true }}
      inputVariant={'standard'}
      onDateSelected={(date: string) => setFieldValue(field.name, date)}
      value={value}
    />
  )
}

export default DateFieldComponent
