import { DateTimePicker } from '@material-ui/pickers'
import * as React from 'react'
import { backendDateTimeFormat } from 'utils/formatDate'
import { SetFieldValueContext } from 'utils/FormGenerator'
import { IFormFieldProps } from 'utils/FormGenerator/formTypings'
import { useStyles } from 'utils/FormGenerator/styles'
import { isDateTimeField } from 'utils/FormGenerator/typeAssertionFunction'
import moment from 'moment'

const DateTimeFieldComponent = ({ field, value }: IFormFieldProps<string>) => {
  if (!isDateTimeField(field)) {
    throw new Error('type error')
  }

  const setFieldValue = React.useContext(SetFieldValueContext)

  const classes = useStyles()
  return (
    <DateTimePicker
      label={field.label}
      className={classes.fieldRoot}
      name={field.name}
      fullWidth
      value={value ? moment(value).format(backendDateTimeFormat) : moment()}
      InputLabelProps={{ classes: { root: classes.fieldLabel, shrink: classes.fieldLabelShrink }, shrink: true }}
      InputProps={{ classes: { root: classes.textFieldInputRoot, underline: classes.textInputUnderline } }}
      onChange={value => setFieldValue(field.name, value?.toISOString())}
      format={backendDateTimeFormat}
      // format="MM/DD/YYYY hh:mm A"
      autoOk
    />
  )
}

export default DateTimeFieldComponent
