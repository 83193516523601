import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import CallMadeIcon from '@material-ui/icons/CallMade'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      color: '#292929',
      marginTop: '10px',
    },
    contentBox: {
      display: 'flex',
      padding: '0.4rem 0 0.2rem 0',
      alignItems: 'center',
    },
    heading: {
      margin: '0',
      marginRight: '0.5rem',
      fontFamily: 'Roboto',
      fontWeight: 600,
    },
    downArrow: {
      color: '#ff0617',
    },
    upArrow: {
      color: '#0bc763',
    },
    text: {
      fontSize: '0.95rem',
      fontFamily: theme.typography.body1.fontFamily,
    },
  })
)

interface SectionDetailProps {
  primaryText?: string
  heading?: number | string
  isIcrement?: boolean
  isDecrement?: boolean
  secondaryText?: number
  isLoading?: boolean
}

const SectionDetail = ({
  primaryText,
  heading,
  isIcrement,
  isDecrement,
  secondaryText,
  isLoading,
}: SectionDetailProps) => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <p className={classes.text}>{primaryText}</p>
      <div className={classes.contentBox}>
        <h2 className={classes.heading}>{!isLoading ? heading : <Skeleton variant="rect" width={50} />}</h2>
        {isDecrement ? <CallReceivedIcon className={classes.downArrow} /> : ''}
        {isIcrement ? <CallMadeIcon className={classes.upArrow} /> : ''}
      </div>
      {secondaryText || secondaryText === 0 ? (
        <p className={classes.text}>{!isLoading ? secondaryText : <Skeleton variant="rect" width={50} />}</p>
      ) : (
        ''
      )}
    </div>
  )
}

export default SectionDetail
