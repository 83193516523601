import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { getKpiDashboardOrders } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import DetailModal from '../kpiDetail/detailmodal'
import OfferDetail from '../OfferDetail'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'

interface IKPIOrdersResponse {
  totalForeignEinTasks: number
  foreignEinOrderIds: number[]
  totalForeignEinTasksCompleted: number
  foreignEinCompletedOrderIds: number[]

  totalIpostalTasks: number
  iPostalTasksOrderIds: number[]
  totaliPostalTasksCompleted: number
  iPostalCompletedOrderIds: number[]
  addonPercentage: number
  topAddons?: TopAddon[]
  averageSalesPrice?: number
  averageSalesWithoutStateFee?: number
  salesRepOrders?: number
  totalOrdersPaid?: number
  grossNetSalesPre?: number
  grossSalesPost?: number
  grossSalesPre?: number
  grossNetSalesPost?: number
}
interface TopAddon {
  count?: number
  addon_id?: number
  addon_name?: string
}

const OrdersCard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<IKPIOrdersResponse>()
  const [ids, setIds] = useState<any[]>([])
  const [entityType, setEntityType] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardOrders(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])
  const handleModalOpen = (type: string, field: keyof IKPIOrdersResponse) => {
    setEntityType(type)
    if (filteredData) {
      //   switch (field) {
      //     case 'new_entities_ids':
      //       setIds(filteredData?.new_entities_ids)
      //       break
      //     case 'new_order_ids':
      //       setIds(filteredData?.new_order_ids)
      //       break
      //     case 'orders_completed_ids':
      //       setIds(filteredData?.orders_completed_ids)
      //       break

      //     default:
      //       break
      //   }
      field && setIds(filteredData[`${field}`])
      setModalOpen(true)
    }
  }
  return (
    <>
      <Grid className={classes.commonRow} container spacing={0}>
        <Grid item xs={2}>
          <PrimaryHeading heading="Orders" text={currentData} />
        </Grid>
        <Grid item xs={10}>
          <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
        </Grid>

        <Grid item xs={1}>
          <SectionDetail
            primaryText="ADDON % "
            heading={Math.round(filteredData?.addonPercentage as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Gross Sales Average (Pre)"
            heading={Math.round(filteredData?.grossSalesPre as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Gross Sales Average (Post)"
            heading={Math.round(filteredData?.grossSalesPost as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Gross Net Average (Pre)"
            heading={Math.round(filteredData?.grossNetSalesPre as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={2}>
          <SectionDetail
            primaryText="Gross Net Average (Post)"
            heading={Math.round(filteredData?.grossNetSalesPost as number) || 0}
            isLoading={isLoading}
          />
        </Grid>

        <Grid item xs={2}>
          <SectionDetail
            primaryText="Total Orders"
            heading={Math.round(filteredData?.totalOrdersPaid as number) || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h2 className="offer-heading">Top Addons</h2>
          <Grid container spacing={3}>
            {filteredData?.topAddons?.length ? (
              filteredData.topAddons?.map((data: TopAddon, i: number) => (
                <Grid
                  item
                  xs={6}
                  key={i}
                  //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, data.companies)}
                >
                  <OfferDetail
                    count={data?.count?.toString() || ''}
                    description={data?.addon_name || ''}
                    isLoading={isLoading}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <h3>No Data Found</h3>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* <Grid xs={6} item>
        <h2 className="offer-heading">Top Addons</h2>
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        ) : (
          filteredData?.topAddons &&
          filteredData?.topAddons?.map((value: TopAddon, index: number) => (
            <Grid item xs={12} key={index}>
              {value.count} {value?.addon_name || ''}
            </Grid>
          ))
        )}
      </Grid> */}
        {/* <Grid xs={6} item>
        <h2 className="offer-heading">Top Addons</h2>
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        ) : (
          filteredData?.top_ten_sms.map((value: any, index: number) => (
            <Grid item xs={12} key={index}>
              #{index + 1} {value?.content?.substring(0, 85)}
            </Grid>
          ))
        )}
      </Grid> */}
      </Grid>
      {filteredData && <DetailModal open={modalOpen} setOpen={setModalOpen} ids={ids} type={entityType} />}
    </>
  )
}

export default OrdersCard
