import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'order_id', label: 'Order Id', style: { width: 70 }, sortable: false },
  { id: 'order_date', label: 'Order Date', style: {}, sortable: true },
  { id: 'last_updated', label: 'Last Updated', style: {}, sortable: true },

  { id: 'order_step', label: 'Order Status', style: {}, sortable: true },
  { id: 'order_type', label: 'Order Type', style: {}, sortable: true },
  { id: 'post_step', label: 'Post Payment', style: {}, sortable: true },
  { id: 'first_name', label: 'Name', style: {}, sortable: true },
  { id: 'company_name', label: 'Company Name', style: {}, sortable: true },
  { id: 'email', label: 'Email', style: {}, sortable: true },
  { id: 'phone', label: 'Phone', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const WebSubmissionHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default WebSubmissionHead
