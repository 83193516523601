import { Chip, createStyles, IconButton, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'
import * as React from 'react'
import { OrderTypes } from 'utils/constant'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import { checkPostStepLabel } from 'utils/functions'
import { postpaymentStepMappings, stepMappings } from 'utils/stepmappings'

interface IWebSubmissionRowProps {
  webSubmissions: any
}

const WebSubmissionRow: React.FC<IWebSubmissionRowProps> = ({ webSubmissions }) => {
  const classes = useStyles()

  return (
    <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      {/* <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}> */}
      <TableCell width={60}>{webSubmissions?.order_id}</TableCell>
      <TableCell width={150}>
        {webSubmissions?.order_date?.length ? formatDateTimeToHumanFriendly(webSubmissions?.order_date) : 'No Date'}
      </TableCell>
      <TableCell width={150}>
        {webSubmissions?.last_updated?.length ? formatDateTimeToHumanFriendly(webSubmissions?.order_date) : ''}
      </TableCell>
      <TableCell width={130}>
        {webSubmissions?.payment_status === '1' ? (
          <div className={classes.status}>
            <Chip size="medium" label="Paid" className="paid-chip" />
            <Chip size="small" label="Clicked" className="clicked-chip" />
          </div>
        ) : webSubmissions?.pay_clicked === 1 ? (
          <div className={classes.status}>
            <p>{stepMappings(webSubmissions?.next_order_step)}</p>
            <Chip size="small" label="Clicked" className="clicked-chip" />
          </div>
        ) : webSubmissions?.payment_status === '3' ? (
          <div className={classes.status}>
            <Chip size="medium" label="Offline Paid" className="paid-chip" />
            <Chip size="small" label="Clicked" className="clicked-chip" />
            <p>{stepMappings(webSubmissions?.next_order_step)}</p>
          </div>
        ) : (
          stepMappings(webSubmissions?.next_order_step)
        )}
      </TableCell>
      <TableCell>{OrderTypes[webSubmissions?.order_type]}</TableCell>
      <TableCell width={130}>
        {' '}
        {checkPostStepLabel(webSubmissions?.order_type, webSubmissions?.post_next_step)}
      </TableCell>
      <TableCell width={150}>{`${webSubmissions?.first_name || ''} ${webSubmissions?.middle_name ||
        ''} ${webSubmissions?.last_name || ''}`}</TableCell>
      <TableCell>{webSubmissions?.company_name}</TableCell>
      <TableCell>{webSubmissions?.email}</TableCell>
      <TableCell>{webSubmissions?.phone}</TableCell>
    </TableRow>
  )
}

export default WebSubmissionRow

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',

      '& .MuiChip-root': {
        color: '#fff',
        margin: '0.1rem 0',
      },

      '& .paid-chip': {
        background: '#28a745',
      },
      '& .clicked-chip': {
        background: '#dc3545',
      },
    },
  })
)
