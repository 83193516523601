import { FilledInputProps, InputProps, OutlinedInputProps } from '@material-ui/core'
import * as React from 'react'
import BRTextField from '../textFields/BRTextField'

interface ICompanyOrderCommissionFieldProps {
  classes: object
  className?: string
  InputProps: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined
  commissionValue: number | string // type doesn't matter here, as it will be saved as a number on the backend eventually
  disabled: boolean
  OnCommissionChange: any
}

/**
 * We wanna be able to calculate commission from order's price.
 *
 * Whenever the price is changed, the value of this field should change automatically. Hence it's disabled.
 *
 * @param commissionValue: Current value of the field.
 * Shouldn't be changed by user input into the field, but rather calculated on price being altered.
 */
export const OrderCommissionField = ({
  className,
  classes,
  InputProps,
  commissionValue,
  disabled,
  OnCommissionChange,
}: ICompanyOrderCommissionFieldProps) => {
  return (
    <BRTextField
      disabled={disabled}
      variant={'outlined'}
      label="Commission"
      classes={classes}
      className={className}
      InputProps={InputProps}
      value={commissionValue}
      onChange={OnCommissionChange}
    />
  )
}
