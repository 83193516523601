import { Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Checkbox, ListItem } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import { createAddress, editAddress } from 'api/addresses'
import ModalTitle from 'components/common/modalTitle'
import useStyles from 'components/common/styles/createDialogStyles'
import { IOwnerSummary } from 'components/companies/companyOwners'
import { AddressSelect, ChangeAction } from 'components/companies/companyOwners/AddressSelect'
import { useAddressOptionsForCompanyOwner } from 'components/companies/companyOwners/hooks/useAddressOptionsForCompanyOwner'
import { RolesSelector } from 'components/companies/companyOwners/RolesSelector'
import { IAddress } from 'models/address'
import { ICompanyRole, ICreatedCompany, IDetailedCompanyResponse } from 'models/company'
import { ICreateUpdateOwner } from 'models/user'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import { SharesField } from './SharesField'
import { toTitleCase } from '@jetkit/react'
//import { useEffect } from 'react-transition-group/node_modules/@types/react'

type HookProps = {
  onOwnerFieldsChange: (key: keyof ICreateUpdateOwner, value: ValueOf<ICreateUpdateOwner>) => void
  clientId?: number
  companyId?: number
}

/**
 * address-related functions for this dialog extracted to a hook
 */
const useAddress = ({ onOwnerFieldsChange, clientId, companyId }: HookProps) => {
  const addressOptionsForCompanyOwner = useAddressOptionsForCompanyOwner({
    clientId,
    companyId,
  })

  const handleAddressSelect = React.useCallback(
    (address: IAddress) => {
      onOwnerFieldsChange('address', address)
    },
    [onOwnerFieldsChange]
  )

  const handleCreateUpdateAddress = React.useCallback(
    (address: IAddress, action: ChangeAction): void => {
      if (action === ChangeAction.update) {
        editAddress({
          ...address,
          client_id: clientId,
        })
      } else {
        createAddress({
          ...address,
          client_id: clientId,
        })
      }

      onOwnerFieldsChange('address', address)
    },
    [onOwnerFieldsChange, clientId]
  )

  return {
    addressOptionsForCompanyOwner,
    handleAddressSelect,
    handleCreateUpdateAddress,
  }
}

interface IAddOwnerDialog {
  isAddOwnerDialogOpen: boolean
  isOnEdit: boolean

  handleModalClose(): void

  /**
   * user id and name are taken from this prop
   */
  activeOwner: IOwnerSummary

  /**
   * values that are edited in this dialog - number_of_shares,
   * role_ids, address
   */
  values: ICreateUpdateOwner

  onOwnerFieldsChange: (key: keyof ICreateUpdateOwner, value: ValueOf<ICreateUpdateOwner>) => void

  company?: ICreatedCompany | IDetailedCompanyResponse

  /**
   * roles from which the company owner can select some for himself.
   */
  roles: ICompanyRole[]

  toggleRole: (roleId: number) => void
  selectAllRoles?: () => void
  allRolesSelected?: boolean

  handleSubmitOwnerClick(): void

  shouldShowUpdateLevelOption?: boolean
  toggleUpdateLevelOption?: (event: React.MouseEvent<HTMLDivElement | MouseEvent>) => void
  updateOnCompanyLevel?: boolean
  is_primary_contact?: boolean
  takenShares?: number
}

/**
 * dialog for creating or updating company owner.
 *
 * dialog has checkboxes for selecting company roles for a new
 * owner and an address select for the owner
 */
const AddOwnerDialog: React.FC<IAddOwnerDialog> = ({
  isAddOwnerDialogOpen,
  isOnEdit,
  activeOwner,
  handleModalClose,
  values,
  onOwnerFieldsChange,
  company,
  roles,
  toggleRole,
  handleSubmitOwnerClick,
  shouldShowUpdateLevelOption,
  toggleUpdateLevelOption,
  updateOnCompanyLevel,
  selectAllRoles,
  allRolesSelected,
  is_primary_contact,
  takenShares = 0,
}) => {
  const classes = useStyles()

  const [sharesError, setSharesError] = React.useState<boolean>(false)
  const [temp, settemp] = React.useState<boolean>(false)

  const handleSharesChange = React.useCallback(
    (numberOfSharesToTake: number) => {
      onOwnerFieldsChange('number_of_shares', numberOfSharesToTake)
    },
    [onOwnerFieldsChange]
  )

  /**
   * if no shareholder role is selected,
   * shares input field is disabled
   */
  const hasZeroShareholderRolesSelected = React.useMemo(() => {
    const selectedRoleIds = values.role_ids
    const selectedShareholderRoles = roles.filter(role => role.can_hold_shares && selectedRoleIds.includes(role.id))
    return selectedShareholderRoles.length === 0
  }, [roles, values.role_ids])

  const onModalClose = () => {
    setSharesError(false)
    handleModalClose()
  }
  const onSubmitOwnerClick = () => {
    setSharesError(false)
    handleSubmitOwnerClick()
  }

  const { addressOptionsForCompanyOwner, handleAddressSelect, handleCreateUpdateAddress } = useAddress({
    clientId: values.user_id,
    companyId: company?.id,
    onOwnerFieldsChange,
  })

  const DialogTitle: React.FC = () => (
    <>
      {isOnEdit ? 'Update Owner: ' : 'Add Owner: '}
      {<Link href={`/client/${activeOwner.id}`}>{activeOwner.name}</Link>}
    </>
  )

  const companySharesLeftCount: number = React.useMemo(() => {
    const companyTotalSharesCount = company?.number_of_shares ?? 0

    if (!companyTotalSharesCount) {
      return 0
    }
    return companyTotalSharesCount - takenShares
  }, [company, takenShares])
  const togglePrimaryContact = () => {
    values.is_primary_contact = !values.is_primary_contact
    settemp(!temp)
  }

  const isDisabledHandler = () => {
    if (values?.is_primary_contact && values?.address) {
      return false
    } else if (!values.role_ids.length) {
      return true
    } else if (sharesError) {
      return true
    }
  }

  return (
    <Dialog
      fullWidth={true}
      classes={{
        root: classes.ownerModalRoot,
        container: classes.ownerModalContainer,
        paper: classes.ownerModalPaper,
      }}
      open={isAddOwnerDialogOpen}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <div className={classes.inlineTitleButtonContainer}>
        <ModalTitle
          customTitleComponent={
            <Typography variant="h1" className={classes.dialogTitle}>
              <DialogTitle />
            </Typography>
          }
          toggleModal={onModalClose}
        />
      </div>
      <div>
        <SharesField
          isDisabled={hasZeroShareholderRolesSelected}
          sharesError={sharesError}
          setSharesError={setSharesError}
          ownerSharesCount={values.number_of_shares}
          companySharesLeftCount={companySharesLeftCount}
          handleSharesChange={handleSharesChange}
          company={company}
          comp_id={company?.id}
        />

        <RolesSelector
          roleOptions={roles}
          selectedRoleIds={values.role_ids}
          areAllRolesSelected={allRolesSelected}
          selectAllRoles={selectAllRoles}
          toggleRole={toggleRole}
          hasZeroShareholderRolesSelected={hasZeroShareholderRolesSelected}
        />

        {values.user_id && (
          <AddressSelect
            initialValue={values.address}
            options={addressOptionsForCompanyOwner}
            onSelect={handleAddressSelect}
            onCreateUpdate={handleCreateUpdateAddress}
          />
        )}
        <br />
        <ListItem
          key={100}
          // disabled={isRoleCheckboxDisabled}
          // data-testid={role.title}
          className={classes.ownerModalListElement}
          // onClick={() => togglePrimaryContact()}
        >
          <Checkbox
            color="primary"
            checked={values.is_primary_contact}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            value={values.is_primary_contact}
            style={{ padding: 0, marginRight: 10 }}
            onChange={() => togglePrimaryContact()}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Primary Contact')}</span>
        </ListItem>
        <div className={classes.updateLevelAndButtonWrapper}>
          {shouldShowUpdateLevelOption && (
            <FormControl className={classes.flex} onClick={toggleUpdateLevelOption}>
              <Checkbox color="primary" checked={updateOnCompanyLevel} style={{ padding: 0, marginRight: 10 }} />
              <Typography className={classes.updateOnCompanyLevelText}>Update Company</Typography>
            </FormControl>
          )}
          <Button
            // disabled={!values.role_ids.length || sharesError || !values?.address || !temp}
            disabled={isDisabledHandler()}
            variant="contained"
            onClick={onSubmitOwnerClick}
            color="primary"
            className={classes.addOwnerButton}
          >
            {isOnEdit ? 'Update Owner' : 'Add Owner'}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default AddOwnerDialog
