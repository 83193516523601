import React, { useState, useEffect } from 'react'
import FilterRow from '../FilterRow'
import moment from 'moment'
import ReportBox from '../reportBox'
import { Grid } from '@material-ui/core'
import { employeeReportSummary, EmployeeReportSummaryData } from 'api/employeeReport'
import showApiResponseError from 'utils/showApiResponseError'
import { formatDateToHumanFriendly } from 'utils/formatDate'

interface DetailReportProps {
  employeeId: number
  startData: any
  setStartData: any
  endData: any
  setEndData: any
}

const DetailReport = ({ employeeId, startData, setStartData, endData, setEndData }: DetailReportProps) => {
  const [tabValue, setTabValue] = useState(0)
  const [isCustom, setIsCustom] = useState(false)

  const [reportSummary, setReportSummary] = useState<EmployeeReportSummaryData>()

  useEffect(() => {
    if (!isCustom) {
      const data = moment()
        .subtract(tabValue, 'd')
        .format('MM/DD/YYYY')
      setStartData(data)
    }
  }, [startData, tabValue])

  React.useEffect(() => {
    if (isCustom) {
      setTabValue(99)
      setStartData(new Date())
    }
  }, [isCustom])

  React.useEffect(() => {
    const employeeSummary = async () => {
      try {
        const result = await employeeReportSummary(
          employeeId,
          moment(startData)?.format('YYYY-MM-DD 00:00 Z'),
          moment(endData)?.format('YYYY-MM-DD HH:mm Z')
        )
        setReportSummary(result)
      } catch (error) {
        showApiResponseError(error, 'Error getting filter data')
      }
    }
    employeeSummary()
  }, [employeeId, endData, startData])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FilterRow
          tabValue={tabValue}
          setTabValue={setTabValue}
          startDate={startData}
          setStartDate={setStartData}
          endDate={endData}
          setEndDate={setEndData}
          isCustom={isCustom}
          setIsCustom={setIsCustom}
        />
      </Grid>
      <Grid item xs={2}>
        <ReportBox
          heading="Activity"
          // HeadingTwo="Total Time Spend"
          HeadingOne="Total Clicks"
          // date={formatDateToHumanFriendly(endData)}
          // HeadingTwoValue={`${reportSummary?.total_time_spent ? reportSummary?.total_time_spent : 0} Hour`}
          HeadingOneValue={reportSummary?.total_clicks ? reportSummary?.total_clicks : 0}
        />
      </Grid>
      <Grid item xs={5}>
        <ReportBox
          heading="Onboarding"
          HeadingOne="Initialized"
          HeadingTwo="Info Required"
          // date={formatDateToHumanFriendly(endData)}
          HeadingOneValue={reportSummary?.order_initiated ? reportSummary?.order_initiated : 0}
          HeadingTwoValue={reportSummary?.order_info_required ? reportSummary?.order_info_required : 0}
          HeadingThree="Company Created"
          HeadingFour="Order Created"
          HeadingThreeValue={reportSummary?.company_created ? reportSummary?.company_created : 0}
          HeadingFourValue={reportSummary?.order_created ? reportSummary?.order_created : 0}
        />
      </Grid>
      <Grid item xs={3}>
        <ReportBox
          heading="Processing"
          HeadingOne="Submitted to Govt"
          HeadingTwo="Revision Request"
          // date={formatDateToHumanFriendly(endData)}
          HeadingOneValue={reportSummary?.order_submitted_to_govt ? reportSummary?.order_submitted_to_govt : 0}
          HeadingTwoValue={reportSummary?.order_revision_request ? reportSummary?.order_revision_request : 0}
        />
      </Grid>
      <Grid item xs={2}>
        <ReportBox
          heading="Completed"
          HeadingOne="Completed Order"
          // date={formatDateToHumanFriendly(endData)}
          HeadingOneValue={reportSummary?.order_completed ? reportSummary?.order_completed : 0}
        />
      </Grid>
    </Grid>
  )
}

export default DetailReport
