import React from 'react'
import useStyles from './style'

type OrderDetailProps = {
  orderStatus: string
  orderCount: number
  orderDate: string
  alertOrderCount?: number
  orderCountHandler?: any
  alertOrderCountHandler?: any
}

const TaxOrderDetailCard: React.FC<OrderDetailProps> = ({
  orderStatus,
  orderCount,
  orderDate,
  alertOrderCount,
  orderCountHandler,
  alertOrderCountHandler,
}: OrderDetailProps) => {
  const classes = useStyles()
  return (
    <div className={classes.TaxOrderDetailCardContainer}>
      <p className={classes.orderInitiatedText}>{orderStatus}</p>
      <div className={classes.inlineText}>
        <h1 className={classes.orderCount} onClick={orderCountHandler}>
          {orderCount}
        </h1>
        <h1 className={classes.alertOrderCount} onClick={alertOrderCountHandler}>
          {alertOrderCount}
        </h1>
      </div>
      <p className={classes.orderOldest}>Oldest Order</p>
      <p className={classes.orderDateText}>{orderDate}</p>
    </div>
  )
}

export default TaxOrderDetailCard
