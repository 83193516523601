import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { ChangeLogs } from 'models/company'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

interface LogsRowProps {
  logs: ChangeLogs
}

const LogsRow: React.FC<LogsRowProps> = ({ logs }) => {
  return (
    <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ maxWidth: 120 }}>{logs?.field_name}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{logs?.old_value}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{logs?.new_value}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{logs?.changed_by}</TableCell>{' '}
      <TableCell style={{ maxWidth: 120 }}>{formatDateTimeToHumanFriendly(logs?.changed_at)}</TableCell>
    </TableRow>
  )
}

export default LogsRow
