import { PagedTable, usePagedTable } from '@jetkit/react'
import { Button, Paper, Table, TableBody, TableContainer } from '@material-ui/core'
import { IDashboradWidget, ITaxWidgetDashboard, archiveTaxCompaines, getTaxTaxWidgeDetail } from 'api/taxWidgets'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import React, { useState } from 'react'
import { CompanyTableRow, TaxOrderTableRow } from './row'
import { CompanyTableHead, TaxOrderTableHead } from './head'
import showApiResponseError from 'utils/showApiResponseError'
import { ITaxOrder } from 'models/taxOrder'
import showSuccessNotification from 'utils/showSuccessNotification'
import { CUSTOM_TAX_WIDGET_TYPES } from 'utils/constant'

interface ITaxOrderTable {
  widgetData: ITaxWidgetDashboard
}

const TaxOrderTable = ({ widgetData }: ITaxOrderTable) => {
  const [isArchive, setIsArchive] = useState(false)

  const memoApiCall = React.useMemo(() => {
    const queryParam: IDashboradWidget = {
      custom_widget: widgetData?.custom_widget,
      custom: widgetData?.custom,
      id: widgetData?.id || 0,
      archived: isArchive,
      twenty_one_day_alert: widgetData?.twenty_one_day_alert ? true : false,
    }
    return getTaxTaxWidgeDetail(queryParam)
  }, [widgetData?.custom_widget, widgetData?.custom, widgetData?.id, widgetData?.twenty_one_day_alert, isArchive])

  const pagedTable = usePagedTable<any>({ apiCall: memoApiCall })

  const handleTaxOrderDelete = React.useCallback(
    async (companyId: number) => {
      const payload = {
        custom_widget: widgetData?.custom_widget,
        company_id: companyId,
      }
      try {
        await archiveTaxCompaines(payload)
        pagedTable?.reloadData()
        showSuccessNotification('Successfully archive!')
      } catch (err) {
        showApiResponseError(err, 'Failed to delete tax order')
      }
    },
    [pagedTable, widgetData?.custom_widget]
  )

  const tableHead = React.useMemo(() => <TaxOrderTableHead />, [])
  const companyTableHead = React.useMemo(() => <CompanyTableHead />, [])

  return (
    <div>
      {widgetData?.custom_widget === CUSTOM_TAX_WIDGET_TYPES?.previous_clients ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
          <Button color="primary" variant="outlined" onClick={() => setIsArchive(!isArchive)}>
            {isArchive ? 'Show Un Archive' : 'Show Archive'}
          </Button>
        </div>
      ) : (
        ''
      )}

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            {widgetData?.type === 'company' ? companyTableHead : tableHead}
            <TableBody>
              {skeletons({
                numberOfColumns: widgetData?.type === 'company' ? 4 : 11,
                sizes:
                  widgetData?.type === 'company'
                    ? [100, 100, 100, 100]
                    : [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
                numberOfRows: 11,
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <PagedTable
            {...pagedTable.renderProps}
            renderRow={(value: ITaxOrder) =>
              widgetData?.type === 'company' ? (
                <CompanyTableRow
                  taxOrder={value}
                  widgetType={widgetData?.custom_widget}
                  handleTaxOrderDelete={() => handleTaxOrderDelete(value?.id || 0)}
                  isArchive={isArchive}
                />
              ) : (
                <TaxOrderTableRow
                  widgetType={widgetData?.custom_widget}
                  taxOrder={value}
                  handleTaxOrderDelete={() => handleTaxOrderDelete(value?.company_id || 0)}
                />
              )
            }
            header={widgetData?.type === 'company' ? companyTableHead : tableHead}
            colSpan={widgetData?.type === 'company' ? 4 : 11}
            emptyRowText={"Couldn't Find Any Tax Orders Matching Your Criteria"}
          />
        </TableContainer>
      )}
    </div>
  )
}

export default TaxOrderTable
