import { Typography } from '@material-ui/core'
import { fetchAffCommission, IOrderDetailsResponse } from 'api/orders'
import { createOrderTypeSuggestions } from 'api/suggestions'
import classNames from 'classnames'
import { CompanyOrderAssigneeField } from 'components/common/companyOrder/assigneeField'
import { OrderCommissionField } from 'components/common/companyOrder/commissionField'
import { CompanySelectField } from 'components/common/companyOrder/companyField'
import { OrderDueDatePicker } from 'components/common/companyOrder/orderDueDatePicker'
import CompanyOrderTypeField from 'components/common/companyOrder/orderTypeField'
import { OrderPriceField } from 'components/common/companyOrder/priceField'
import CompanyOrderRequiredOrdersField from 'components/common/companyOrder/requiredOrdersField'
import OutlinedSelect from 'components/common/outlinedSelect'
import BRTextField from 'components/common/textFields/BRTextField'
import { useCompanyOrderFormHandlers } from 'components/orders/hooks/useCompanyOrderFormHandlers'
import { isBusinessFormationOrder } from 'domain/orders'
import * as React from 'react'
import { datePickerDateFormat } from 'utils/formatDate'
import { ValueOf } from 'utils/objectUtils'
import { useStyles } from '../styles'

export type CompanyOrderConfigDialogOrderAttributesProps = {
  companyOrderDetails: IOrderDetailsResponse
  onCompanyOrderFieldChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => Promise<void>
  setOpenAddTaskDialog: React.Dispatch<React.SetStateAction<boolean>>
  mode: 'create' | 'update'
}

/**
 * Left column for company order dialog
 */
export const OrderAttributes = ({
  companyOrderDetails,
  onCompanyOrderFieldChange,
  setOpenAddTaskDialog,
  mode,
}: CompanyOrderConfigDialogOrderAttributesProps) => {
  const classes = useStyles()

  // when order is being created, we need to have a company select
  const showCompanySelectField = mode === 'create'

  /**
   * If user is going through company creation form and on the 1st step
   * ticks `Add Formation Order` checkbox, then on the 5th step in the
   * dialog for configuring Business Formation order, the order type
   * should not be editable.
   *
   * This has a side effect. If user is creating a new order and mistakenly
   * selects `Business Formation` as order type, he/she will not be able to
   * change it here. But will need to close and open the dialog again. This
   * situation is very rare.
   * Profit from this is we don't introduce prop drilling.
   *
   * TODO: Alternative and better solution is to create a global context around
   * company creation form, but it's decided not to do this now, because company
   * creation form (`src/components/companies/create.tsx`) is currently a very
   * big file and it would make it even bigger. After company creation form
   * is refactored into smaller components, we can change this implementation
   * detail.
   */
  const shouldDisableOrderTypeSelection: boolean = isBusinessFormationOrder(companyOrderDetails)
  const [companyCommission, setCompanyCommission] = React.useState(0)
  const {
    handleFetchingRequiredOrderSuggestions,
    handleFetchAssigneeSuggestions,
    companyOrderDueDate,
    handleAddMultiValue,
    assigneeAsSuggestionAttribute,
    onAssigneeSelected,
    handleChangingPrice,
    handleSelectingOrderType,
    orderTypeSuggestion,
    companyOrderCommission,
    handleDueDateSelected,
    handleSelectingCompany,
    fetchCompanySuggestions,
    handleChangingCommission,
  } = useCompanyOrderFormHandlers({
    onCompanyOrderFieldChange,
    companyOrderDetails,
    setOpenAddTaskDialog,
    company_commission: companyCommission,
  })
  React.useEffect(() => {
    if (companyOrderDetails?.company?.id) {
      handleSelectingCompany({ value: companyOrderDetails?.company?.id, label: companyOrderDetails?.company?.name })
    }
  }, [])
  const getCompanyCommission = async (company_id: number) => {
    const commissionPer = await fetchAffCommission(company_id)
    setCompanyCommission(commissionPer / 100)
    // await handleChangingPrice({ target: { value: companyOrderDetails.price } })
  }
  React.useEffect(() => {
    console.log('companyOrderDetails.company', companyOrderDetails.company)
    if (companyOrderDetails && companyOrderDetails.company) getCompanyCommission(companyOrderDetails.company.id)
  }, [companyOrderDetails.company])
  return (
    <div className={classes.leftSideWrapper}>
      <Typography className={classes.mainTitle}>Add Order</Typography>

      {showCompanySelectField && (
        <CompanySelectField
          fetchCompanySuggestions={fetchCompanySuggestions}
          onCompanySelected={handleSelectingCompany}
          defaultCompanyValue={
            companyOrderDetails.company && {
              value: companyOrderDetails.company.id,
              label: companyOrderDetails.company.name,
            }
          }
        />
      )}

      <CompanyOrderTypeField
        handleSelectingOrderType={handleSelectingOrderType}
        fetchSuggestions={createOrderTypeSuggestions}
        orderTypeSuggestion={orderTypeSuggestion}
        disabled={false} //{shouldDisableOrderTypeSelection}
      />
      <CompanyOrderAssigneeField
        fetchSuggestions={handleFetchAssigneeSuggestions}
        onOptionSelected={onAssigneeSelected}
        assigneeAsSuggestionAttribute={assigneeAsSuggestionAttribute}
      />
      <div className={classes.requiredOrdersWrapper}>
        <CompanyOrderRequiredOrdersField
          addAttribute={handleAddMultiValue}
          fetchSuggestions={handleFetchingRequiredOrderSuggestions}
          reloadKey={companyOrderDetails.company_id}
        />
      </div>
      <div className={classes.priceRowWrapper}>
        <OrderPriceField
          classes={{
            root: classNames(classes.inputRoot, classes.priceInput),
          }}
          InputProps={{ classes: { input: classes.input } }}
          priceValue={companyOrderDetails.price || 0}
          onPriceChange={handleChangingPrice}
        />
        <OrderCommissionField
          classes={{
            root: classNames(classes.inputRoot, classes.commissionInput),
          }}
          InputProps={{ classes: { input: classes.input } }}
          commissionValue={companyOrderDetails.commission}
          disabled={false}
          OnCommissionChange={handleChangingCommission}
        />
      </div>
      <OutlinedSelect
        name="Payment Status"
        options={['Paid', 'Unpaid']}
        value={companyOrderDetails.payment_status}
        onOptionSelect={(value: unknown) => onCompanyOrderFieldChange('payment_status', value as string)}
      />
      <BRTextField
        label="Invoice Number"
        // className={classes.textField}
        //style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
        type="text"
        name="invoice_no"
        margin="normal"
        variant="outlined"
        value={companyOrderDetails.invoice_no}
        onChange={event => onCompanyOrderFieldChange('invoice_no', event.target.value)}
      />
      <OrderDueDatePicker
        className={classes.dueDatePicker}
        classes={{ root: classes.dateField }}
        onDueDateSelected={handleDueDateSelected}
        dueDateValue={companyOrderDueDate}
        dateFormat={datePickerDateFormat}
      />
    </div>
  )
}
