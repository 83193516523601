import { notify } from '@jetkit/react'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { deleteAddress, editAddress } from 'api/addresses'
import {
  addCompanyMeeting,
  createCompany,
  createCompanyAddress,
  createOwner,
  deleteCompany,
  deleteOwner,
  getCompanyMeetings,
  getCompanyPrimaryAddress,
  getCompanyRoles,
  saveAndInitiateOrders,
  updateCompany,
  updateCompanyAndRefreshOrders,
  updateCompanyMeeting,
} from 'api/companies'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import DocumentUploads from 'components/common/assets/documentUploads'
import AlertButton from 'components/common/buttons/AlertButton'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import DialogStepper from 'components/common/dialogStepper'
import useStyles from 'components/common/styles/createDialogStyles'
import CompanyDetails from 'components/companies/companyDetails'
import CompanyOrders from 'components/companies/companyOrders'
import CompanyOwners from 'components/companies/companyOwners'
import useAddressEdit, { emptyAddress } from 'components/companies/hooks/addressEditHooks'
import useCompanyDetails, {
  emptyInputFieldsState,
  ICompanyDetailsState,
} from 'components/companies/hooks/companyDetails'
import useCompanyOwners, { emptyOwnerState } from 'components/companies/hooks/companyOwners'
import useMeetingReport, {
  emptyMeetingReportState,
  IMeetingReportFields,
  MeetingIdKey,
  MeetingTypeToMeetingIdMapping,
} from 'components/companies/hooks/meetingReport'
import MeetingReport from 'components/companies/meetingReport'
import { CompanyAddressType, IAddress } from 'models/address'
import {
  ICompanyMeeting,
  ICompanyRole,
  ICreateCompanyParams,
  ICreatedCompany,
  legalTypesToApiFormat,
  MeetingType,
} from 'models/company'
import { IOrder } from 'models/order'
import { IClient } from 'models/user'
import * as React from 'react'
import useRouter from 'use-react-router'
import { assertNonNullable } from 'utils/asserts'
import { IS_DEVELOPMENT_MODE } from 'utils/environment'
import { backendDateFormat } from 'utils/formatDate'
import { ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'

export type CompanyCreationFlowAddressKey =
  | 'address'
  | 'mailingAddress'
  | 'directorsMeetingAddress'
  | 'shareholdersMeetingAddress'

enum creationSteps {
  COMPANY_DETAILS = 0,
  ADD_OWNERS = 1,
  MEETING_REPORT = 2,
  ORDERS = 3,
  UPLOADS = 4,
}
export interface IInputFieldsErrors {
  [key: string]: boolean
}

interface ICreateCompanyProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  initialClient?: IClient
  clearInitialClientInParent?: () => void
  onCompanyCreated?: () => void
}

const steps = ['Company Details', 'Add Owners', 'Meeting Report', 'Orders', 'Uploads']
const CompanyCreationFlow: React.FC<ICreateCompanyProps> = ({
  initialClient,
  clearInitialClientInParent,
  onCompanyCreated,
  isOpen,
  setIsOpen,
}) => {
  const [activeStep, setActiveStep] = React.useState<creationSteps>(creationSteps.COMPANY_DETAILS)
  const [completedSteps] = React.useState<{
    [index: number]: boolean
  }>({})

  const [loadingSaveCompany, setLoadingSaveCompany] = React.useState<boolean>(false)
  const [loadingPrevStep, setLoadingPrevStep] = React.useState(false)
  const [loadingNextStep, setLoadingNextStep] = React.useState(false)
  const [createdCompany, setCreatedCompany] = React.useState<ICreatedCompany | undefined>(undefined)

  /**
   * address type that is being edited in address dialog
   */
  const [newAddressKey, setNewAddressKey] = React.useState<CompanyCreationFlowAddressKey>()

  const [saveCompanyClicked, setSaveCompanyClicked] = React.useState(false)
  const classes = useStyles()

  const {
    inputFieldsErrors,
    setErrors,
    companyDetailsInputFields,
    setCompanyDetailsInputFields,
    getClients,
    onInputFieldsChange,
    clients,
    validateCompanyDetailsFields,
    formationOrder,
    createOrUpdateFormationOrder,
    deleteFormationOrder,
    getFormationOrderAndSetState,
  } = useCompanyDetails()

  const {
    currentEditedAddress,
    setCurrentEditedAddress,
    isAddAddressDialogOpen,
    setIsAddAddressDialogOpen,
    isEditing,
    setIsEditing,
    validateAddressFields,
    addressFieldsErrors,
  } = useAddressEdit()

  const {
    meetingReportFields,
    handleMeetingReportFieldsChange,
    companyAddresses,
    getCompanyAddressesAndSetState,
    meetingReportErrors,
    validateMeetingReportFields,
    setMeetingReportFields,
    meetingReportAddressFields,
  } = useMeetingReport(createdCompany)

  const {
    ownerFields,
    setOwnerFields,
    companyOwners,
    setCompanyOwners,
    companyRoles,
    setCompanyRoles,
    fetchAndSetCompanyOwners,
    clearOwnerState,
    toggleRole,
    takenShares,
    setTakenShares,
    selectAllRoles,
    allRolesSelected,
    onOwnerFieldsChange,
    onOwnerEdit,
  } = useCompanyOwners({ company: createdCompany })
  const router = useRouter<{ id: string | undefined }>()

  /**
   * Returns id of company that is being edited in the form.
   *
   * As a side effect, in development environment, if there is NO
   * company being edited in the form, this function will return `1`.
   * Such situation means that the user
   * (developer or tester) wants to go to Nth form step without filling
   * in all the data for previous steps.
   * This function gives him a `placeholder` company to work with.
   */
  const getCreatedCompanyId = React.useCallback(() => {
    if (createdCompany) {
      return createdCompany.id
    } else return 0
    if (IS_DEVELOPMENT_MODE) {
      // for testing purposes just use any company
      notify.warning('Using company creation form in testing mode.')
      return 1
    }

    // throw new Error('Error creating a company')
  }, [createdCompany])

  const saveOwner = React.useCallback(async () => {
    if (!createdCompany) return
    setLoadingSaveCompany(true)
    try {
      await createOwner(ownerFields, createdCompany.id)
      fetchAndSetCompanyOwners(createdCompany.id)
      setLoadingSaveCompany(false)
    } catch (err) {
      showApiResponseError(err, 'Could not create an owner')
      setLoadingSaveCompany(false)
    }
  }, [createdCompany, fetchAndSetCompanyOwners, ownerFields])

  const onOwnerDelete = React.useCallback(
    async (userId: number) => {
      if (!createdCompany) return
      await deleteOwner(createdCompany.id, userId)
      fetchAndSetCompanyOwners(createdCompany.id)
    },
    [createdCompany, fetchAndSetCompanyOwners]
  )

  const onAddOwnerButtonClick = React.useCallback(async () => {
    saveOwner()
    clearOwnerState()
  }, [clearOwnerState, saveOwner])

  const fetchCompanyRoles = React.useCallback(async () => {
    if (!createdCompany) return
    try {
      const res: ICompanyRole[] = await getCompanyRoles(createdCompany.id)
      setCompanyRoles(res)
      return res
    } catch (err) {
      showApiResponseError(err, 'Failed to fetch company roles')
    }
  }, [createdCompany, setCompanyRoles])

  React.useEffect(() => {
    // load company owners

    // do nothing, if already loaded
    if (companyOwners) return

    const companyId = getCreatedCompanyId()
    fetchAndSetCompanyOwners(companyId)
  }, [companyOwners, fetchAndSetCompanyOwners, getCreatedCompanyId])

  React.useEffect(() => {
    if (!clients) getClients('')
  }, [clients, getClients])

  React.useEffect(() => {
    if (!companyAddresses) getCompanyAddressesAndSetState()
  }, [companyAddresses, getCompanyAddressesAndSetState])

  const toggleIsAddAddressDialogOpen = React.useCallback(() => {
    setIsAddAddressDialogOpen(!isAddAddressDialogOpen)
  }, [setIsAddAddressDialogOpen, isAddAddressDialogOpen])

  const onAddAddressClick = React.useCallback(
    (key: CompanyAddressType) => {
      setNewAddressKey(key)
      toggleIsAddAddressDialogOpen()
    },
    [toggleIsAddAddressDialogOpen]
  )

  const onAddressInputFieldsChange = (key: keyof IAddress) => (value: ValueOf<IAddress>) => {
    setCurrentEditedAddress(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  // sending a request to update the address
  const updateAddress = async (address: IAddress) => {
    try {
      await editAddress(address)
      getCompanyMeetingsAndSetState()
      return true
    } catch (err) {
      showApiResponseError(err, 'Could not edit the address')
      return false
    }
  }

  const getCompanyMeetingsAndSetState = async () => {
    const companyId = getCreatedCompanyId()
    const companyMeetings = await getCompanyMeetings(companyId)
    const directorsMeeting = companyMeetings.find(meeting => meeting.meeting_type === 'directors')
    const shareHoldersMeeting = companyMeetings.find(meeting => meeting.meeting_type === 'shareholders')
    const formationMeeting = companyMeetings.find(meeting => meeting.meeting_type === 'formation')
    if (directorsMeeting) {
      setMeetingReportFields((prevState: IMeetingReportFields) => ({
        ...prevState,
        directorsMeetingDate: directorsMeeting.date,
        directorsMeetingAddress: directorsMeeting.address,
        directorsMeetingId: directorsMeeting.id,
      }))
    }
    if (shareHoldersMeeting) {
      setMeetingReportFields((prevState: IMeetingReportFields) => ({
        ...prevState,
        shareholdersMeetingDate: shareHoldersMeeting.date,
        shareholdersMeetingAddress: shareHoldersMeeting.address,
        shareholdersMeetingId: shareHoldersMeeting.id,
      }))
    }
    if (formationMeeting) {
      setMeetingReportFields((prevState: IMeetingReportFields) => ({
        ...prevState,
        formationMeetingDate: formationMeeting.date,
        formationMeetingAddress: formationMeeting.address,
        formationMeetingId: formationMeeting.id,
      }))
    }
  }

  const updateAddressFields = async () => {
    assertNonNullable(newAddressKey)

    // updating meeting report address
    if (meetingReportAddressFields.includes(newAddressKey)) {
      await updateAddress(currentEditedAddress)
    } else {
      try {
        await editAddress(currentEditedAddress)
        getCompanyPrimaryAddressAndSave()
      } catch (err) {
        showApiResponseError(err, "Couldn't update the address")
      }
    }
  }

  const updateCompanyContactAddress = async () => {
    assertNonNullable(newAddressKey)

    if (createdCompany) {
      currentEditedAddress.address_type = 'contact'
      currentEditedAddress.is_primary = true
      await createCompanyAddress(createdCompany.id, currentEditedAddress)
      await getCompanyPrimaryAddressAndSave()
    } else {
      // if the company hasn't been yet created, just edit the state
      setCompanyDetailsInputFields((prevState: ICompanyDetailsState) => ({
        ...prevState,
        [newAddressKey]: currentEditedAddress,
      }))
    }
  }

  const addCompanyAddress = async () => {
    assertNonNullable(newAddressKey)

    // if we are not editing - create an address (this should go to a separate function)
    // create address if it's a meeting address
    if (meetingReportAddressFields.includes(newAddressKey)) {
      setMeetingReportFields((prevState: IMeetingReportFields) => ({
        ...prevState,
        [newAddressKey]: currentEditedAddress,
      }))
      if (!createdCompany) return false
      currentEditedAddress.address_type = 'meeting'
      const newAddress = await createCompanyAddress(createdCompany.id, currentEditedAddress)
      // update the existing meeting with the newly created address
      if (newAddressKey === 'shareholdersMeetingAddress') {
        await updateOrCreateCompanyMeeting('shareholders', {
          date: meetingReportFields.shareholdersMeetingDate,
          meeting_type: 'shareholders',
          address_id: newAddress.id,
        })
      } else if (newAddressKey === 'directorsMeetingAddress') {
        await updateOrCreateCompanyMeeting('directors', {
          date: meetingReportFields.directorsMeetingDate,
          meeting_type: 'directors',
          address_id: newAddress.id,
        })
      } // reset the current meeting state from the backend
      await getCompanyMeetingsAndSetState()
    } else {
      // send a request to update the primary address
      updateCompanyContactAddress()
    }
  }

  const updateCompanyAddress = () => {
    assertNonNullable(newAddressKey)

    // update meeting report address
    if (meetingReportAddressFields.includes(newAddressKey)) {
      setMeetingReportFields((prevState: IMeetingReportFields) => ({
        ...prevState,
        [newAddressKey]: currentEditedAddress,
      }))
    }
    // update company details address
    if (createdCompany) {
      updateAddressFields()
    } else {
      setCompanyDetailsInputFields((prevState: ICompanyDetailsState) => ({
        ...prevState,
        [newAddressKey]: currentEditedAddress,
      }))
    }
  }

  /**
   * Handle add address dialog submit
   */
  const handleAddAddress = async () => {
    //
    if (!validateAddressFields()) return false
    if (isEditing) {
      updateCompanyAddress()
    } else {
      addCompanyAddress()
    }

    // reset the edited address type to default
    setNewAddressKey(undefined)

    if (isEditing) {
      setIsEditing(false)
    }
    setCurrentEditedAddress(emptyAddress)
    toggleIsAddAddressDialogOpen()
    return true
  }

  /**
   * Open up an address dialog for editing
   */
  const handleAddressEditClick = React.useCallback(
    (key: CompanyCreationFlowAddressKey) => (id: number) => {
      setIsAddAddressDialogOpen(true)
      setIsEditing(true)
      setNewAddressKey(key)

      // depending on which fields we are editing (companyDetails or meeting report)
      // set the current edited address

      // check if `key` is in `companyDetailsInputFields`
      // if it is, then we edit its address
      if (companyDetailsInputFields[key]) {
        const addressToEdit: IAddress | undefined = companyDetailsInputFields[key]
        assertNonNullable(addressToEdit)
        setCurrentEditedAddress(addressToEdit)
      } else if (meetingReportFields[key]) {
        setCurrentEditedAddress(meetingReportFields[key])
      }
    },
    [companyDetailsInputFields, meetingReportFields, setIsAddAddressDialogOpen, setCurrentEditedAddress, setIsEditing]
  )

  // First we need to make sure that the primary address exists on the company
  // because we may just use a local addresses and then delete the address that we've
  // got from the backend by id
  const sendDeleteAddressRequest = React.useCallback(
    async (id: number) => {
      if (!createdCompany) return
      try {
        await deleteAddress(id)
      } catch (err) {
        showApiResponseError(err, 'Could not delete address')
      }
    },
    [createdCompany]
  )

  const updateOrCreateCompanyMeeting = React.useCallback(
    async (meetingType: MeetingType, params: ICompanyMeeting) => {
      assertNonNullable(createdCompany, 'cannot update or create company meeting because there is no created company')

      const meetingIdKey: MeetingIdKey = MeetingTypeToMeetingIdMapping[meetingType]
      const meetingId: number | undefined = meetingReportFields[meetingIdKey]
      if (meetingId) {
        // if meeting exists - update it
        //await updateCompanyMeeting(meetingId, params)
      } else {
        // otherwise - create a new
        const meeting = await addCompanyMeeting(createdCompany.id, params)

        handleMeetingReportFieldsChange(meetingIdKey, meeting.id)
      }
    },
    [createdCompany, handleMeetingReportFieldsChange, meetingReportFields]
  )

  const handleCompanyPrimaryAddressDeleteClick = React.useCallback(
    (key: string) => async (id: number) => {
      if (createdCompany) {
        const companyPrimaryAddress = await getCompanyPrimaryAddress(createdCompany.id)
        if (!companyPrimaryAddress || !companyPrimaryAddress.id) return
        sendDeleteAddressRequest(companyPrimaryAddress.id)
      }
      setCompanyDetailsInputFields(prevState => ({
        ...prevState,
        [key]: undefined,
      }))
    },
    [createdCompany, setCompanyDetailsInputFields, sendDeleteAddressRequest]
  )

  const handleMeetingAddressDeleteClick = React.useCallback(
    (key: string) => async (id: number) => {
      if (key === 'directorsMeetingAddress' /*&& meetingReportFields.directorsMeetingId*/) {
        // updateOrCreateCompanyMeeting('directors', {
        //   date: meetingReportFields.directorsMeetingDate,
        //   meeting_type: 'directors',
        //   address_id: null,
        // })
      } else if (key === 'shareholdersMeetingAddress' /*&& meetingReportFields.shareholdersMeetingId*/) {
        // updateOrCreateCompanyMeeting('shareholders', {
        //   date: meetingReportFields.directorsMeetingDate,
        //   meeting_type: 'shareholders',
        //   address_id: null,
        // })
      }
      setMeetingReportFields(prevState => ({
        ...prevState,
        [key]: undefined,
      }))
    },
    [
      meetingReportFields.directorsMeetingDate,
      meetingReportFields.directorsMeetingId,
      meetingReportFields.shareholdersMeetingId,
      setMeetingReportFields,
      updateOrCreateCompanyMeeting,
    ]
  )

  const discardCreatedCompany = async () => {
    if (createdCompany && createdCompany.id) {
      try {
        await deleteCompany(createdCompany.id)
        finishCompanyCreation()
        setDeleteOpen(false)
        onCompanyCreated && onCompanyCreated()
      } catch (error) {
        showApiResponseError(error, 'Error')
        finishCompanyCreation()
        setDeleteOpen(false)
        onCompanyCreated && onCompanyCreated()
      }
    } else {
      finishCompanyCreation()
      setDeleteOpen(false)
    }
  }
  const finishCompanyCreation = React.useCallback(() => {
    // We need to clear initial client value in parent component,
    // so newly opened company creation flow dialog will not prefill client field.

    clearInitialClientInParent && clearInitialClientInParent()
    setCreatedCompany(undefined)
    setErrors({})
    setActiveStep(0)
    setCompanyOwners([])
    setOwnerFields(emptyOwnerState)
    setMeetingReportFields(emptyMeetingReportState)
    setCompanyDetailsInputFields(emptyInputFieldsState)
    setTakenShares(0)
    setIsOpen(false)
  }, [
    clearInitialClientInParent,
    setCompanyDetailsInputFields,
    setCompanyOwners,
    setErrors,
    setIsOpen,
    setMeetingReportFields,
    setOwnerFields,
    setTakenShares,
  ])

  const saveMeetingReport = React.useCallback(async () => {
    if (!createdCompany || !validateMeetingReportFields()) return false
    setLoadingSaveCompany(true)
    try {
      const updatedMeetingReportFields = {
        ein: meetingReportFields.ein && meetingReportFields.ein.length ? meetingReportFields.ein : undefined,
        file_date: meetingReportFields.fileDate?.format(backendDateFormat),
        state_id: meetingReportFields.stateId,
        directors_meeting_address: meetingReportFields.directorsMeetingAddress,
        shareholder_meeting_address: meetingReportFields.shareholdersMeetingAddress,
      }
      await updateCompany(createdCompany.id, updatedMeetingReportFields)
      if (meetingReportFields.formationMeetingDate) {
        await updateOrCreateCompanyMeeting('formation', {
          date: meetingReportFields.formationMeetingDate,
          meeting_type: 'formation',
        })
      }
      await updateOrCreateCompanyMeeting('directors', {
        date: meetingReportFields.directorsMeetingDate,
        meeting_type: 'directors',
        address_id: meetingReportFields.directorsMeetingAddress
          ? meetingReportFields.directorsMeetingAddress.id
          : undefined,
      })
      await updateOrCreateCompanyMeeting('shareholders', {
        date: meetingReportFields.shareholdersMeetingDate,
        meeting_type: 'shareholders',
        address_id: meetingReportFields.shareholdersMeetingAddress
          ? meetingReportFields.shareholdersMeetingAddress.id
          : undefined,
      })
      setLoadingSaveCompany(false)
      return true
    } catch (err) {
      showApiResponseError(err, 'Could not create this company' + err)
      setLoadingSaveCompany(false)
    }
    setLoadingSaveCompany(false)
    return false
  }, [
    createdCompany,
    validateMeetingReportFields,
    meetingReportFields.ein,
    meetingReportFields.fileDate,
    meetingReportFields.stateId,
    meetingReportFields.formationMeetingDate,
    meetingReportFields.directorsMeetingDate,
    meetingReportFields.directorsMeetingAddress,
    meetingReportFields.shareholdersMeetingDate,
    meetingReportFields.shareholdersMeetingAddress,
    updateOrCreateCompanyMeeting,
  ])

  const saveCompanyDetails = React.useCallback(
    // TODO: #389 function `saveCompanyDetails` is too long. should be broken down into smaller ones
    // eslint-disable-next-line complexity
    async (finishCompanyCreation = false): Promise<boolean> => {
      debugger

      const incorporator = companyDetailsInputFields.incorporator
      if (
        !validateCompanyDetailsFields() ||
        !companyDetailsInputFields.client_id ||
        // !companyDetailsInputFields.client ||
        !incorporator ||
        !companyDetailsInputFields.companyType
      )
        return false
      try {
        debugger

        let company: ICreatedCompany | undefined
        const data: ICreateCompanyParams = {
          // client_id: companyDetailsInputFields.client,
          // client_id: companyDetailsInputFields.client.id || 1,
          client_id: companyDetailsInputFields.client_id || 1,
          country_of_formation: companyDetailsInputFields.country,
          state_of_formation: companyDetailsInputFields.stateOfFormation,
          name: companyDetailsInputFields.companyName,
          legal_type: legalTypesToApiFormat[companyDetailsInputFields.companyType],
          number_of_shares: companyDetailsInputFields.shares,
          business_activity: companyDetailsInputFields.businessActivity,
          incorporator: incorporator,
          tax_structure: companyDetailsInputFields.tax_structure,
        }
        // if company hasn't been created - create it
        if (!createdCompany && activeStep === creationSteps.COMPANY_DETAILS) {
          company = await createCompany(data)
          debugger

          // set the company addresses after the company was created
          if (company && activeStep === creationSteps.COMPANY_DETAILS && companyDetailsInputFields.address) {
            companyDetailsInputFields.address.address_type = 'contact'
            companyDetailsInputFields.address.is_primary = true
            const createdAddress = await createCompanyAddress(company.id, companyDetailsInputFields.address)
            if (createdAddress && companyDetailsInputFields.shouldCreateFormationOrder) {
              //await createOrUpdateFormationOrder(company)
            }
            // replace the selected address with the created address id for further usage
            onInputFieldsChange('address', createdAddress)
          }
          if (company && activeStep === creationSteps.COMPANY_DETAILS && companyDetailsInputFields.mailingAddress) {
            companyDetailsInputFields.mailingAddress.address_type = 'mailing'
            companyDetailsInputFields.mailingAddress.is_primary = false
            const createdAddress = await createCompanyAddress(company.id, companyDetailsInputFields.mailingAddress)
            // replace the selected address with the created address id for further usage
            onInputFieldsChange('mailingAddress', createdAddress)
          }
          // if the company has already been created - update it
        } else if (createdCompany) {
          company = finishCompanyCreation
            ? await updateCompanyAndRefreshOrders(createdCompany, data)
            : await updateCompany(createdCompany.id, data)
          if (formationOrder && !companyDetailsInputFields.shouldCreateFormationOrder) {
            await deleteFormationOrder()
          } else if (companyDetailsInputFields.shouldCreateFormationOrder) {
            //await createOrUpdateFormationOrder(company, formationOrder)
          }
        }
        if (activeStep === creationSteps.MEETING_REPORT) {
          saveMeetingReport()
        }
        // if (activeStep === creationSteps.ORDERS) {

        //   setCreatedCompany(createdCompany)
        // } else
        setCreatedCompany(company)
        // if (onCompanyCreated) onCompanyCreated()
        if (company?.id && finishCompanyCreation) router.history.push('/company/' + (company?.id || '0'))
        return true
      } catch (err) {
        debugger

        showApiResponseError(err, 'Could not create this company' + err)
      }
      return false
    },
    [
      activeStep,
      companyDetailsInputFields.address,
      companyDetailsInputFields.businessActivity,
      companyDetailsInputFields.client_id,
      companyDetailsInputFields.companyName,
      companyDetailsInputFields.companyType,
      companyDetailsInputFields.country,
      companyDetailsInputFields.incorporator,
      companyDetailsInputFields.mailingAddress,
      companyDetailsInputFields.shares,
      companyDetailsInputFields.shouldCreateFormationOrder,
      companyDetailsInputFields.stateOfFormation,
      createOrUpdateFormationOrder,
      createdCompany,
      deleteFormationOrder,
      formationOrder,
      onCompanyCreated,
      onInputFieldsChange,
      router.history,
      saveMeetingReport,
      validateCompanyDetailsFields,
    ]
  )

  const saveAndQuit = React.useCallback(async () => {
    setLoadingSaveCompany(true)
    const savingSuccess = await saveCompanyDetails(true)
    setLoadingSaveCompany(false)
    if (savingSuccess) {
      showSuccessNotification(`Company ${companyDetailsInputFields.companyName} has been saved`)

      finishCompanyCreation()
    }
    setSaveCompanyClicked(true)
  }, [companyDetailsInputFields.companyName, finishCompanyCreation, saveCompanyDetails])

  React.useEffect(() => {
    if (saveCompanyClicked && formationOrder) {
      router.history.push('/company-order/' + (formationOrder as IOrder).id)
    }
    setSaveCompanyClicked(false)
  }, [formationOrder, saveCompanyClicked, router.history])

  const getCompanyPrimaryAddressAndSave = async () => {
    return
    // assertNonNullable(createdCompany, 'cannot get company primary address because company is not created')
    // const companyPrimaryAddress = await getCompanyPrimaryAddress(createdCompany.id)
    // if (companyPrimaryAddress.id) {
    //   onInputFieldsChange('address', companyPrimaryAddress)
    // }
  }

  const onStepClick = async (direction: 'forward' | 'backward') => {
    let savingSuccess = false
    debugger
    if (direction === 'forward') {
      debugger
      if (activeStep === creationSteps.COMPANY_DETAILS && validateCompanyDetailsFields()) {
        debugger
        setLoadingNextStep(true)
        savingSuccess = await saveCompanyDetails()
        if (savingSuccess) {
          debugger
          // if company was saved on the first step - we can proceed to the second
          setActiveStep(activeStep + 1)
        }
      } else if (activeStep === creationSteps.ADD_OWNERS) {
        debugger
        setLoadingNextStep(true)
        getCompanyAddressesAndSetState()
        getCompanyMeetingsAndSetState()
      }
      // we need extra validation for the first step
      if (activeStep !== creationSteps.COMPANY_DETAILS) {
        debugger
        setActiveStep(activeStep + 1)
      }
    } else if (direction === 'backward') {
      debugger
      setLoadingPrevStep(true)
      if (activeStep === creationSteps.ADD_OWNERS) {
        debugger
        getCompanyPrimaryAddressAndSave()
        getCompanyMeetingsAndSetState()
        if (createdCompany) getFormationOrderAndSetState(createdCompany.id)
      }
      setActiveStep(activeStep - 1)
    }
    if (activeStep === creationSteps.MEETING_REPORT) {
      debugger
      savingSuccess = await saveMeetingReport()
    }
    setLoadingPrevStep(false)
    setLoadingNextStep(false)
  }

  const refreshClients = React.useCallback(() => {
    getClients('')
  }, [getClients])

  /**
   *
   * In case we're saving company at the last step of the company creation flow we wanna have the following `Save and initiate orders` behavior:
   *
   * - Switch the state of the company orders from `Draft` to `Initiated`
   * - Delete all deactivated tasks of company orders
   */
  const handleSavingCompany = React.useCallback(async () => {
    await saveAndQuit()

    if (activeStep + 1 >= steps.length && createdCompany) await saveAndInitiateOrders(createdCompany?.id)
  }, [saveAndQuit, createdCompany, activeStep])
  React.useEffect(() => {
    if (companyAddresses.length) {
      setMeetingReportFields((prevState: IMeetingReportFields) => ({
        ...prevState,
        //directorsMeetingDate: directorsMeeting.date,
        directorsMeetingAddress: companyAddresses[0],
        shareholdersMeetingAddress: companyAddresses[0],
        //directorsMeetingId: directorsMeeting.id,
      }))
    }
  }, [companyAddresses])
  const getCurrentStep = React.useCallback(() => {
    switch (activeStep) {
      case creationSteps.COMPANY_DETAILS:
        return (
          <CompanyDetails
            // If initial client exists, CompanyDetails component will prefill client field before rendering
            initialClient={initialClient}
            errors={inputFieldsErrors}
            values={companyDetailsInputFields}
            onInputFieldsChange={onInputFieldsChange}
            onAddAddressClick={onAddAddressClick}
            onAddressEditClick={handleAddressEditClick}
            onAddressDeleteClick={handleCompanyPrimaryAddressDeleteClick}
            noPrimaryAddressError={false}
            clients={clients}
            refreshClients={refreshClients}
            createdCompany={createdCompany}
          />
        )
      case creationSteps.ADD_OWNERS:
        return (
          <CompanyOwners
            selectAllRoles={selectAllRoles}
            allRolesSelected={allRolesSelected}
            onOwnerFieldsChange={onOwnerFieldsChange}
            fetchCompanyRoles={fetchCompanyRoles}
            company={createdCompany}
            roles={companyRoles}
            values={ownerFields}
            hideEmptyOwnersTable
            toggleRole={toggleRole}
            onAddOwnerButtonClick={onAddOwnerButtonClick}
            companyOwners={companyOwners}
            onOwnerEdit={onOwnerEdit}
            onOwnerDelete={onOwnerDelete}
            clearOwnerState={clearOwnerState}
            isCompanyCreation={true}
            takenShares={takenShares}
            initialClient={initialClient}
          />
        )
      case creationSteps.MEETING_REPORT:
        return (
          <MeetingReport
            errors={meetingReportErrors}
            companyAddresses={companyAddresses}
            values={meetingReportFields}
            onInputFieldsChange={handleMeetingReportFieldsChange}
            onAddressEditClick={handleAddressEditClick}
            onAddressDeleteClick={handleMeetingAddressDeleteClick}
            onAddAddressClick={onAddAddressClick}
            selectedCompanyAddress={companyDetailsInputFields.address}
          />
        )
      case creationSteps.ORDERS:
        const companyId: number = getCreatedCompanyId()
        return (
          <CompanyOrders
            isFormationOrder={companyDetailsInputFields.shouldCreateFormationOrder}
            formationOrderId={formationOrder?.id}
            companyId={companyId}
          />
        )

      case creationSteps.UPLOADS: {
        const companyId: number = getCreatedCompanyId()
        return (
          <div className={classes.documentUploads}>
            <DocumentUploads type="business" objectId={companyId} />
          </div>
        )
      }
    }
  }, [
    activeStep,
    initialClient,
    inputFieldsErrors,
    companyDetailsInputFields,
    onInputFieldsChange,
    onAddAddressClick,
    handleAddressEditClick,
    handleCompanyPrimaryAddressDeleteClick,
    clients,
    refreshClients,
    createdCompany,
    selectAllRoles,
    allRolesSelected,
    onOwnerFieldsChange,
    fetchCompanyRoles,
    companyRoles,
    ownerFields,
    toggleRole,
    onAddOwnerButtonClick,
    companyOwners,
    onOwnerEdit,
    onOwnerDelete,
    clearOwnerState,
    takenShares,
    meetingReportErrors,
    companyAddresses,
    meetingReportFields,
    handleMeetingReportFieldsChange,
    handleMeetingAddressDeleteClick,
    getCreatedCompanyId,
    formationOrder,
    classes.documentUploads,
  ])
  const [delOpen, setDeleteOpen] = React.useState(false)
  return (
    <>
      <AddAddressDialog
        open={isAddAddressDialogOpen}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressInputFieldsChange}
        handleDialogClose={toggleIsAddAddressDialogOpen}
        onAddressAdd={handleAddAddress}
        addressErrors={addressFieldsErrors}
        isEditing={isEditing}
      />
      <DialogWithConfirmButtons
        onClose={() => setDeleteOpen(false)}
        open={delOpen}
        title={`Are you sure you want to discard this company ?`}
        firstOptionTitle="Yes"
        secondOptionTitle="Cancel"
        onFirstOptionClick={() => discardCreatedCompany()}
        onSecondOptionClick={() => setDeleteOpen(false)}
      />
      <Dialog
        fullWidth
        classes={{
          paper: classes.paper,
          container: classes.container,
        }}
        open={isOpen}
      >
        <div className={classes.inlineTitleButtonContainer}>
          <Typography className={classes.title}>Add Company</Typography>
          <IconButton onClick={() => setDeleteOpen(true)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <DialogStepper
          steps={steps}
          activeStep={activeStep}
          handleStep={setActiveStep}
          completedSteps={completedSteps}
        />
        <div className={classes.flexColumn}>
          <div className={classes.createCompanyStepWrapper}>{getCurrentStep()}</div>
          <div className={classes.formContainer}>
            {activeStep + 1 < steps.length && (
              <ButtonWithLoading
                dataTestId="next-step-button"
                disabled={loadingPrevStep || loadingSaveCompany}
                isLoading={loadingNextStep}
                className={classes.fullWidth}
                onClick={() => onStepClick('forward')}
              >
                Next Step
              </ButtonWithLoading>
            )}
            <ButtonWithLoading
              kind="BRSecondary"
              disabled={loadingPrevStep || loadingNextStep}
              isLoading={loadingSaveCompany}
              wrapperClassName={classes.saveCompanyWrapper}
              onClick={handleSavingCompany}
            >
              Save Company
            </ButtonWithLoading>
            {activeStep > 0 && (
              <ButtonWithLoading
                kind="BREmpty"
                disabled={loadingSaveCompany || loadingNextStep}
                isLoading={loadingPrevStep}
                className={classes.marginTop10}
                onClick={() => onStepClick('backward')}
              >
                Previous Step
              </ButtonWithLoading>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default CompanyCreationFlow
