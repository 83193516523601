import { notify } from '@jetkit/react'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import AddNote, { NoteEntitiesContext } from './addNote'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem',
      minWidth: 500,
    },
    title: {
      fontFamily: theme.typography.h1.fontFamily,
      fontSize: 28,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)
export interface APINoteType {
  id?: number
  color?: string
  create?: boolean
  search?: boolean
}

interface IAddNoteDialogProps {
  open?: boolean
  onClose?: () => void
  onCreated?: () => void
}

function AddNoteDialog(props: IAddNoteDialogProps) {
  const { onClose, open, onCreated } = props
  const classes = useStyles()
  const noteEntitiesContext = React.useContext(NoteEntitiesContext)

  if (!noteEntitiesContext) {
    const msg = 'Error: noteEntitiesContext is not initialized'
    notify.warning(msg)
    console.error(msg)
    return null
  }

  return (
    <Dialog onBackdropClick={onClose} maxWidth="xl" open={!!open}>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>Add Note</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <AddNote onCreated={onCreated} />
      </div>
    </Dialog>
  )
}

export default AddNoteDialog
