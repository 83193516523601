import { apiClient } from '@jetkit/react'
import useUser, { CURRENT_USER_LOCAL_STORAGE_KEY } from 'hooks/useUser'
import { ICompany } from 'models/company'
import { IClientTax, ICompanyOwner, ProfileQuestionProps } from 'models/user'
import { USERTYPES, USER_TYPES } from 'utils/constant'
import { ComplianceMuteUnmuteData, ComplianceTasksData } from './complianceCalendar'

export interface ComplianceTaskParams {
  company_id?: string
  status?: string
  due_date?: string
  order_type_id?: number | null
  page: number
  page_size: number
  compliance_type?: string
}
export interface ChangeComplianceStatusParams {
  compliance_task_id?: number
  status?: string
}

export interface IAddressCompany {
  address_line_1: string
  zip?: string
  creation_time?: string
  is_primary?: boolean
  is_mailing?: boolean
  city_id: number
}
export interface IClientCompanyOwners {
  first_name: string
  middle_name: string
  last_name: string
  phone: string
  email: string
  address: IAddressCompany
  primary_contact?: boolean
  shares?: number
  existing_client?: boolean
  id?: number
}
export interface ICreateClientCompany {
  step?: number //1, 2, 3
  state_of_formation?: number
  name?: string
  address?: IAddressCompany
  client_id?: number
  state_id_number?: string
  ein_number?: string
  number_of_shares?: string
  owners?: IClientCompanyOwners[]
  file_date?: string
  id?: number
  company_type?: number
}

export interface IQuizAnswer {
  company_id: string
  question_id: number
  answer_id: number
  answer: boolean
}

interface IAnswerOption {
  id: number
  question_id: number
  next_question_id: number | null
  answer_text: string
}

export interface IAnswerResponse {
  id: number
  question: string
  score: number
  is_deleted: boolean
  deleted_at: null | string
  is_primary: boolean
  sequence: number
  answerOptions: IAnswerOption[]
}

export const getCurrentUser = () => {
  const userJson = localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY)
  const user = userJson && JSON.parse(userJson)
  if (user?.user_type) return USER_TYPES[user.user_type].toLowerCase()
  else return
}
export const getClientCompanies = async (status?: boolean): Promise<ICompany[]> => {
  const res: any = await apiClient.get(
    `${
      status !== undefined ? `/${getCurrentUser()}/company?business_active=${status}` : `/${getCurrentUser()}/company`
    }`
  )
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const getClientCompanyById = async (id: string): Promise<any> => {
  const res: any = await apiClient.get(`/${getCurrentUser()}/company/${id}`)
  if (res.error) {
    return null
  } else {
    return res.data
  }
}

export const getCompanyOwnersById = async (id: string): Promise<ICompanyOwner[]> => {
  //
  const res: any = await apiClient.get(`/${getCurrentUser()}/company/${id}/user`)
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const getClientOrder = async (id: string): Promise<any> => {
  const res: any = await apiClient.get(`/${getCurrentUser()}/company/order/${id}`)
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const getClientTaxOrder = async (id: string): Promise<IClientTax[]> => {
  const res: any = await apiClient.get(`/${getCurrentUser()}/tax-order/${id}`)
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const getClientUploadsDocuments = async (payload): Promise<any> => {
  const { company_id, query, page, page_size } = payload

  const res: any = await apiClient.get(
    `${getCurrentUser()}-company/uploads/${company_id}?page=${page}&page_size=${page_size}&query=${query}`
  )
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const getCorrespondenceDocumentsVault = async (payload): Promise<any> => {
  const { query, page, page_size, uploaded_on, company_id, document_id } = payload

  // const res: any = await apiClient.get(`client-company/uploads?page=1&page_size=25&query=${query}`)
  // if (res.error) {
  //   return []
  // } else {
  //   return res.data
  // }
  return (
    await apiClient.get(`/${getCurrentUser()}-company/uploads`, {
      params: {
        company_id: company_id ? company_id : '',
        document_id: document_id ? document_id : 0,
        uploaded_on: uploaded_on ? uploaded_on : '',
        page: page ? page : 0,
        page_size: page_size ? page_size : 0,
        query: query ? query : '',
      },
    })
  ).data
}

export const getSpecialOffers = async (query = ''): Promise<any> => {
  const res: any = await apiClient.get(`/specialoffers`)
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const getProfileQuestions = async (id: string): Promise<ProfileQuestionProps[]> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.121:3000/'

  const res: any = await apiClient.get(`/get-questions/${id}`)
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const quizsAnswer = async (payload: IQuizAnswer): Promise<IAnswerResponse> => {
  return (await apiClient.post('/company-quiz/answer', payload)).data
}
// export const quizsAnswer = async (payload: any): Promise<any> => {
//   try {
//     return (await apiClient.post(`/company-quiz/answer`, copyObjectAndDeleteKey(snakeCaseKeys(address), 'id'))).data
//   } catch (e) {
//     console.log('error', e)
//     const data: IAddress = address
//     return await data
//   }
// }

// post('/company-quiz/answer')
// body:{company_id, question_id, answer, }
export const getProfileScoreById = async (id: string): Promise<any> => {
  const res: any = await apiClient.get(`/${getCurrentUser()}/companyScore/${id}`)
  if (res.error) {
    return null
  } else {
    return res.data
  }
}

export const specialOfferCompanies = async (payload: any): Promise<any> => {
  return (await apiClient.post('/special-offers-companies', payload)).data
}

export const getClientCamplianceTask = async (params: ComplianceTaskParams): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'
  const { company_id, status, due_date, page, page_size, compliance_type } = params
  return (
    await apiClient.get(`/${getCurrentUser()}/compliance-tasks`, {
      params: { company_id, status, due_date, compliance_type, page, page_size },
    })
  ).data
}

// export const changeClientCamplianceTaskStatus = async (params: ChangeComplianceStatusParams): Promise<any> => {
//   // apiClient.defaults.baseURL = 'http://192.168.0.127:3000/'
//   //
//   const { compliance_task_id, status } = params
//   apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL_NODE
//   return (
//     await apiClient.post('/client/compliance-tasks', {
//       params: { compliance_task_id, status },
//     })
//   ).data
// }

export const changeClientCamplianceTaskStatus = async (payload: ChangeComplianceStatusParams): Promise<any> => {
  const { compliance_task_id, status } = payload
  return (await apiClient.post(`/${getCurrentUser()}/compliance-tasks/${compliance_task_id}`, { status })).data
}

export const addExisitingCompany = async (payload: ICreateClientCompany): Promise<any> => {
  return (await apiClient.post('/client-company', payload)).data
}

export const getNextSchedules = async (id: string): Promise<any> => {
  const res: any = await apiClient.get(`/${getCurrentUser()}/company-next-schedules/${id}`)
  if (res.error) {
    return []
  } else {
    return res.data
  }
}

export const clientComplianceMuteUnmute = async (payload: ComplianceMuteUnmuteData): Promise<any> => {
  return (await apiClient.post(`/${getCurrentUser()}/mute-unmute-schedule`, payload)).data
}
export const checkMissingItemExist = async (status?: string): Promise<any> => {
  const res: any = await apiClient.get(
    `/${getCurrentUser()}/order-reminder-items?page=1&page_size=1&status=${status || ''}`
  )
  if (res.error) {
    return []
  } else {
    return res.data
  }
}
