import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '2rem',
      minWidth: '920px',
    },
    headerItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    closeButton: {
      width: 'min-content',
      alignSelf: 'flex-end',
    },
    title: {
      fontSize: '1.75rem',
      fontWeight: 600,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary,
      marginBottom: '0.4rem',
    },
    searchBar: {
      marginBottom: '1rem',
    },
  })
)
