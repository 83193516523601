import { Dialog } from '@material-ui/core'
import { toggleTaskActivation } from 'api/task'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import AddTaskDialog from 'components/orders/addTaskDialog'
import { OrderAttributes } from 'components/tasks/companyOrderConfigDialog/orderAttributes/OrderAttributes'
import { useTasks } from 'components/tasks/companyOrderConfigDialog/orderAttributes/useTasks'
import { IOrderTask } from 'models/task'
import * as React from 'react'
import { CloseDialogButton } from '../../common/buttons/closeDialogButton'
import { useCompanyOrderConfig } from '../../orders/hooks/useCompanyOrderEdit'
import CompanyOrderTaskActivationTable from './companyOrderTaskActivationTable'
import { useStyles } from './styles'
import { emptyOrderDetails, getOrderDetails, IOrderDetailsResponse, updateOrder } from 'api/orders'

export interface ICompanyOrderConfigDialogProps {
  open: boolean
  /**
   * Order that will be edited in the component
   */
  companyOrderId: number
  onClose: () => void
  onSave: () => void
  isFormationOrder?: boolean
}

/**
 * Dialog window that allows to EDIT a company order.
 * Allows adding new tasks to an order and toggling their activation alongside.
 */
const CompanyOrderConfigDialog = ({
  open,
  companyOrderId,
  onClose,
  onSave,
  isFormationOrder,
}: ICompanyOrderConfigDialogProps) => {
  const classes = useStyles()
  const [openAddTaskDialog, setOpenAddTaskDialog] = React.useState(false)

  const {
    companyOrderDetails,
    onCompanyOrderFieldChange,
    getCompanyOrderDetails,
    setCompanyOrderDetails,
  } = useCompanyOrderConfig({
    useUpdate: true,
  })

  const { onTaskCreated, handleCloseAddTaskDialog } = useTasks({
    onCompanyOrderFieldChange,
    companyOrderDetails,
    setOpenAddTaskDialog,
  })

  React.useEffect(() => {
    // companyOrderDetails store all the info about an order which we wanna edit in this component.
    if (!isFormationOrder) getCompanyOrderDetails(companyOrderId)
  }, [companyOrderId, getCompanyOrderDetails])

  const newCustomTaskTemplate = React.useMemo(() => {
    return {
      company: companyOrderDetails.company,
      company_id: companyOrderDetails?.company?.id,
      is_activated: true,
      order: companyOrderDetails,
      order_id: companyOrderDetails.id,
    }
  }, [companyOrderDetails])
  React.useEffect(() => {
    if (isFormationOrder) {
      const order_type: IOrderDetailsResponse = {
        affiliate: null,
        commission: 0,
        company_users: [],
        company_id: 0,
        creator: null,
        future_orders: [],
        documents: [],
        due_date: null,
        due_date_order_status: null,
        id: 0,
        last_update: null,
        order_stage: null,
        order_stage_id: null,
        order_type: { id: 3, name: 'Business Formation' },
        order_type_id: 3,
        assignee_id: null,
        assignee: null,
        payment_status: 'Unpaid',
        price: 0,
        required_orders: [],
        sales_rep: null,
        sales_rep_id: null,
        tasks: [],
        order_stages: null,
      }
      setCompanyOrderDetails(order_type)
    }
    // companyOrderDetails.order_type_id=3;
    // companyOrderDetails.order_type.name='BUSINESS FORMATION' || null
    // //companyOrderDetails?.order_type?.name
  }, [])

  return (
    <>
      <AddTaskDialog
        open={openAddTaskDialog}
        onClose={handleCloseAddTaskDialog}
        onTaskCreated={onTaskCreated}
        orderId={companyOrderDetails.id}
        task={(newCustomTaskTemplate as unknown) as IOrderTask}
        mode={'create'}
      />
      <Dialog classes={{ paper: classes.mainWrapper }} open={open}>
        <CloseDialogButton onClose={onClose} className={classes.closeButton} />
        <div className={classes.dataWrapper}>
          <OrderAttributes
            companyOrderDetails={companyOrderDetails}
            onCompanyOrderFieldChange={onCompanyOrderFieldChange}
            setOpenAddTaskDialog={setOpenAddTaskDialog}
            mode={isFormationOrder ? 'create' : 'update'}
          />
          <CompanyOrderTaskActivationTable
            tasks={companyOrderDetails.tasks}
            toggleTaskActivation={toggleTaskActivation}
            onCompanyOrderFieldChange={onCompanyOrderFieldChange}
            setOpenAddTaskDialog={setOpenAddTaskDialog}
          />
        </div>
        <ButtonWithLoading kind="BRSecondary" wrapperClassName={classes.saveButton} onClick={onSave}>
          Save
        </ButtonWithLoading>
      </Dialog>
    </>
  )
}

export default CompanyOrderConfigDialog
