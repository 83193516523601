import * as React from 'react'

interface EmptyCheckboxProps {
  style?: React.CSSProperties
}

const EmptyCheckbox: React.FC<EmptyCheckboxProps> = ({ style }) => {
  const customStyle = style || {}
  return (
    <div
      style={{
        width: 40,
        height: 40,
        borderRadius: 4,
        border: 'solid 1px #979797',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        ...customStyle,
      }}
    />
  )
}

export default EmptyCheckbox
