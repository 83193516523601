import React from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/delete'
import { PromoCodesProps } from 'api/promoCode'
import moment from 'moment'

interface PromoCodeRowProps {
  promoCode: PromoCodesProps
  onDeleteClick: () => void
}

const PromoCodeRow: React.FC<PromoCodeRowProps> = ({ promoCode, onDeleteClick }) => {
  const handleDeleteIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onDeleteClick()
    },
    [onDeleteClick]
  )
  return (
    <TableRow data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ minWidth: 180 }}>{promoCode?.code}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{moment(promoCode?.expiry).format('DD-MMMM-YYYY')}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{promoCode?.type}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{promoCode?.value}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{promoCode?.times_used}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{promoCode?.max_usage}</TableCell>

      <TableCell style={{ minWidth: 90 }}>
        <IconButton data-testid="delete-button" onClick={handleDeleteIconClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default PromoCodeRow
