import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Link, Theme } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { ExpandMore, Star, StarBorder } from '@material-ui/icons'
import { BOQuestionStatus, IBOQues, starredQuestion } from 'api/taxOrders'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import * as React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import showSuccessNotification from 'utils/showSuccessNotification'

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      backgroundColor: 'white',
      marginBottom: theme.typography.pxToRem(10),
      padding: `${theme.typography.pxToRem(14)} ${theme.typography.pxToRem(10)}`,
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0px !important',
      },
    },
    checkbox: {
      marginLeft: theme.typography.pxToRem(10),
      marginTop: theme.typography.pxToRem(10),
    },
    statusLabel: {
      fontSize: 12,
      padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(10)}`,
      borderRadius: theme.typography.pxToRem(3),
      width: '92px',
      color: 'white',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    createdBy: {
      fontWeight: 500,
      paddingRight: '1rem',
      color: '#0000009c',
      fontSize: '0.8rem',
    },
    question: {
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: '0.09px',
      color: 'black',
      marginBottom: theme.typography.pxToRem(5),
    },
    description: {
      color: '#0000009c',
      fontSize: theme.typography.pxToRem(13.5),
    },
    icons: {
      display: 'flex',
      justifyContent: 'end',
      '& svg': {
        margin: '0 10px',
        width: 20,
        height: 20,
      },
      cursor: 'pointer',
    },
    childQues: {
      margin: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(0)}`,
      // borderTop:"2px solid black "
    },
    nestedQues: {
      marginBottom: theme.typography.pxToRem(10),
    },
    addQuestionButton: {
      color: theme.palette.primary.main,
      marginRight: '2rem',
      // margin: '1.5rem 2rem 0 2rem',
      textAlign: 'end',
    },
  }
})

const statusToHuman = (status: BOQuestionStatus): string => {
  switch (status) {
    case '1':
      return 'PENDING'
    case '2':
      return 'UNDER REVIEW'
    case '3':
      return 'COMPLETED'
    case '4':
      return 'REJECTED'
    default:
      return 'STATUS UNKNOWN'
  }
}

interface IQuestionCardProps {
  question: IBOQues
  onEditClick: (question: IBOQues) => void
  onDeleteClick: (question: IBOQues) => void
  starredValue: boolean
}

const questionColors = {
  '1': '#90D7A0',
  '2': '#FBA0D9',
  '3': '#A18AFF',
  '4': '#FF8181',
}

const QuestionCard: React.FC<IQuestionCardProps> = ({ question, onEditClick, onDeleteClick, starredValue }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(true)
  const [starred, setStarred] = React.useState(starredValue)

  const handleStarClick = async id => {
    setStarred(!starred)
    console.log(id, !starred, 'IDISHEREEEEE')
    try {
      const payload = {
        is_stared: !starred,
      }
      await starredQuestion(id, payload)
      showSuccessNotification(!starred ? 'Stared Succesfully' : 'Updated Succesfully')
    } catch (error) {
      console.log(error)
    }
  }

  const handleExpandClick = event => {
    event.stopPropagation()
    setExpanded(prev => !prev)
  }
  return (
    <>
      {question && !question?.child_question?.length ? (
        <div className={classes.main}>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              {/* <Checkbox className={`no-print ${classes.checkbox}`} color="primary" /> */}
            </Grid>
            <Grid item xs={11}>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <div
                    className={classes.statusLabel}
                    style={{
                      backgroundColor: `${questionColors[question.status]}`,
                    }}
                  >
                    {statusToHuman(question.status)}
                  </div>
                </Grid>

                <Grid item xs={10}>
                  <div className={classes.icons}>
                    {question?.status !== '3' ? (
                      <div>
                        <Link
                          onClick={() => {
                            onEditClick(question)
                          }}
                          component="button"
                          variant="body2"
                          className={classes.addQuestionButton}
                        >
                          + Add Question
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}

                    <div
                      onClick={() => {
                        onEditClick(question)
                      }}
                    >
                      <EditIcon />
                    </div>
                    <div
                      onClick={() => {
                        onDeleteClick(question)
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleStarClick(question?.id)}>
                      {starred ? <Star style={{ color: 'gold' }} /> : <StarBorder />}
                    </IconButton>
                  </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <Typography className={classes.question}>{question?.question_text}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.description}>{question?.answered}</Typography>
                </Grid>
                <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                  <p className={classes.createdBy}>
                    {question?.employeedetails?.first_name ?? ''} {question?.employeedetails?.last_name ?? ''}
                  </p>
                  <p className={classes.createdBy}>
                    Created at: {formatDateTimeToHumanFriendly(question.schedule_date)}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.nestedQues}>
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMore onClick={handleExpandClick} />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <Grid container alignItems="center">
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  <div
                    className={classes.statusLabel}
                    style={{
                      backgroundColor: `${questionColors[question.status]}`,
                    }}
                  >
                    {statusToHuman(question.status)}
                  </div>
                </Grid>
                {/* <Grid item xs={2}>
                  <p className={classes.createdBy}>
                    {question?.employeedetails?.first_name ?? ''} {question?.employeedetails?.last_name ?? ''}
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <p className={classes.createdBy}>
                    Created at: {formatDateTimeToHumanFriendly(question.schedule_date)}
                  </p>
                </Grid> */}
                <Grid item xs={8}>
                  <div className={classes.icons}>
                    {question?.status !== '3' ? (
                      <div>
                        <Link
                          onClick={() => {
                            onEditClick(question)
                          }}
                          component="button"
                          variant="body2"
                          className={classes.addQuestionButton}
                        >
                          + Add Question
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* <div>
                      <Link
                        onClick={() => {
                          onEditClick(question)
                        }}
                        component="button"
                        variant="body2"
                        className={classes.addQuestionButton}
                      >
                        + Add Question
                      </Link>
                    </div> */}
                    <div
                      onClick={() => {
                        onEditClick(question)
                      }}
                    >
                      <EditIcon />
                    </div>
                    <div
                      onClick={() => {
                        onDeleteClick(question)
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <IconButton onClick={() => handleStarClick(question?.id)}>
                      {starred ? <Star style={{ color: 'gold' }} /> : <StarBorder />}
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={1}>
                  {/* <Checkbox className={`no-print ${classes.checkbox}`} color="primary" /> */}
                </Grid>
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.question}>{question?.question_text}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.description}>{question?.answered}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {question?.child_question &&
                  question?.child_question?.length &&
                  question?.child_question?.map((element: any, index: number) => {
                    return (
                      <Grid container alignItems="center" className={classes.childQues}>
                        <Grid item xs={1}>
                          {/* <Checkbox className={`no-print ${classes.checkbox}`} color="primary" /> */}
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container alignItems="center">
                            <Grid item xs={2}>
                              <div
                                className={classes.statusLabel}
                                style={{
                                  backgroundColor: `${questionColors[element.status]}`,
                                }}
                              >
                                {statusToHuman(element?.status)}
                              </div>
                            </Grid>
                            {/* <Grid item xs={2}>
                              <p className={classes.createdBy}>Created by: Admin</p>
                            </Grid>
                            <Grid item xs={3}>
                              <p className={classes.createdBy}>Created at: 05 May 2024 10.47 am</p>
                            </Grid> */}
                            <Grid item xs={10}>
                              <div className={classes.icons}>
                                <div
                                  onClick={() => {
                                    onEditClick(element)
                                  }}
                                >
                                  <EditIcon />
                                </div>
                                {/* <div
                                  onClick={() => {
                                    onDeleteClick(question)
                                  }}
                                >
                                  <DeleteIcon />
                                </div> */}
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.question}>{element?.question_text}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.description}>{element?.answered}</Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                              <p className={classes.createdBy}>
                                {question?.employeedetails?.first_name ?? ''}{' '}
                                {question?.employeedetails?.last_name ?? ''}
                              </p>
                              <p className={classes.createdBy}>
                                Created at: {formatDateTimeToHumanFriendly(question.schedule_date)}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  )
}

export default QuestionCard
