import { useStyles as useStyles2 } from 'components/common/assetUpload/uploadModalStyle'
import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {
  CircularProgress,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ModalTitle from 'components/common/modalTitle'
import {
  addTasksToCompanyOrder,
  getCompanyOrderDocumentsWithoutPagination,
  getDefaultTasksForOrderType,
  IOrderDetailsResponse,
  sendDocsToClient,
} from 'api/orders'
import showApiResponseError from 'utils/showApiResponseError'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import CompanyOrderTaskActivationTable from 'components/tasks/companyOrderConfigDialog/companyOrderTaskActivationTable'
import { toggleTaskActivation } from 'api/task'
import TaskActivationCheckbox from 'components/tasks/companyOrderConfigDialog/taskActivationCheckbox'
import { IOrderTask } from 'models/task'
import classNames from 'classnames'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem 0',
        // height: '50vh',
      },
    },
    root: {
      // overflow: 'hidden',
      paddingTop: '2rem',
      '& .MuiTextField-root': {
        marginBottom: '1rem',
        width: '100%',
      },
      '& .MuiPaper-root ': {
        // padding: '1rem',
        // width: '93%',
        width: '100%',
      },
      padding: theme.spacing(2),
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'center',
    },

    uploadBtn: {
      color: '#fff',
      width: '200px',
      height: '50px',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
    },
    tableHead: {
      backgroundColor: '#f6f6f8',
    },
    tableHeadCell: {
      padding: '10px 20px',
    },
    tableCell: {
      padding: '5px 20px',
    },
    tableWrapper: {
      position: 'relative',
    },
    tableRow: {
      backgroundColor: '#FFFFFF',
      height: 68,
    },
    addTaskButton: {
      marginLeft: 10,
      marginBottom: 2,
      textDecoration: 'underline',
    },

    tableHeadCellText: {
      fontFamily: 'Helvetica',
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 0.07,
      color: '#020202',
    },
    taskNameText: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.07,
      color: '#2A2A2A',
    },
    statusCheckbox: {
      '&.Mui-checked': {
        color: '#91de91',
      },
      '& > span': {
        '& > svg': {
          width: 32,
          height: 32,
        },
      },
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
)
interface AddOrderTypeTasksProps {
  open: boolean
  setOpen: (val: boolean) => void
  order: IOrderDetailsResponse
  getTasksAndSetState: () => void
}
const AddOrderTypeTasks: React.FC<AddOrderTypeTasksProps> = ({ open, setOpen, order, getTasksAndSetState }) => {
  const classes2 = useStyles()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tasks, setTasks] = React.useState([])
  const [defaultTasks, setDefaultTasks] = React.useState([])
  const [openAddTaskDialog, setOpenAddTaskDialog] = React.useState(false)

  React.useEffect(() => {
    if (open) handleGettingDefaultTasksForOrderType()
  }, [open])

  const handleGettingDefaultTasksForOrderType = React.useCallback(async () => {
    if (order.order_type_id) {
      setLoading(true)
      const defaultTasks: any = await getDefaultTasksForOrderType(order.order_type_id)
      setDefaultTasks(defaultTasks)
      setLoading(false)
    } else {
      setDefaultTasks([])
    }
  }, [order.order_type_id])

  const addTasks = async () => {
    console.log('tasks', tasks)
    try {
      await addTasksToCompanyOrder(order.id, tasks)
      showSuccessNotification('Added Successfully')
      getTasksAndSetState()
      setOpen(false)
      setTasks([])
      // history.push(`/company-order/${createdOrder.id}`)
    } catch (e) {
      showApiResponseError(e, 'Error Saving Data')
    }
  }
  return (
    <Dialog open={open} className={classes2.dialogContainer} fullWidth maxWidth="sm">
      <ModalTitle title={'Add Tasks'} toggleModal={setOpen} />
      <DialogContent className={classes2.root}>
        {loading ? (
          [...Array(20)]?.map((_, index) => <Skeleton key={index} />)
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes2.tableWrapper}>
              <TableHead className={classes2.tableHead}>
                <TableRow>
                  <TableCell className={classes2.tableHeadCell} align="left">
                    Name
                  </TableCell>
                  <TableCell className={classes2.tableHeadCell} align="center">
                    Activity Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {defaultTasks.map((task: IOrderTask, key: number) => (
                  <TableRow key={key} className={classes2.tableRow}>
                    <TableCell className={classNames(classes2.taskNameText, classes2.tableCell)} align="left">
                      {task.title}
                    </TableCell>
                    <TableCell className={classes2.tableCell} align="center">
                      <TaskActivationCheckbox
                        tasks={defaultTasks}
                        task={task}
                        toggleTaskActivation={toggleTaskActivation}
                        setTasks={setTasks}
                        taskIDs={tasks}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions className={classes2.dialogAction}>
        <ButtonWithLoading
          disabled={tasks.length ? false : true}
          // isLoading={loading}
          onClick={addTasks}
        >
          Add Tasks{' '}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  )
}

export default AddOrderTypeTasks
