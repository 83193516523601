import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { createAddress, editAddress } from 'api/addresses'
import { IOrderDetailsResponse, updateOrder } from 'api/orders'
import { getUserAddresses } from 'api/users'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import AddressesSelect from 'components/common/addresses/addressesSelect'
import useAddressEdit from 'components/companies/hooks/addressEditHooks'
import deepcopy from 'deepcopy'
import { CompanyAddressType, IAddress } from 'models/address'
import { IDetailedCompanyResponse } from 'models/company'
import * as React from 'react'
import { notEmpty, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'

const fieldsWidth = '49%'

// Generate a closest available id for locally created `IAddress`
const minimumNegativeID = (addresses: IAddress[]): number => {
  let minimum = -1
  const allIds = addresses.map(address => address.id)
  while (allIds.includes(minimum)) minimum -= 1
  return minimum
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontalWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '12px 0',
      width: '100%',
    },
    addressFieldWrapper: {
      width: fieldsWidth,
    },
    addressField: {
      width: '100%',
    },
  })
)

interface IAddressProps {
  orderDetails: IOrderDetailsResponse
  onCompanyDetailsInputFieldsChange: (
    key: keyof IDetailedCompanyResponse,
    value: ValueOf<IDetailedCompanyResponse>
  ) => void
  isEditable?: boolean
}

/**
 * component shows primary and mailing addresses.
 * if address is selected, it shows the address.
 * if address is not selected, it shows dropdown where user can select address.
 */
const AddressComponent: React.FC<IAddressProps> = ({
  orderDetails,
  onCompanyDetailsInputFieldsChange,
  isEditable = true,
}) => {
  const classes = useStyles()

  const [addresses, setAddresses] = React.useState<IAddress[]>([])

  const [newAdd, setNewAdd] = React.useState<IAddress>()

  const primaryAddress = React.useMemo(() => addresses.find(address => address.is_primary), [addresses])
  const mailingAddress = React.useMemo(() => addresses.find(address => address.is_mailing), [addresses])

  const [editedAddressType, setEditedAddressType] = React.useState<CompanyAddressType>()

  // Whether editting fields in this component is disabled
  const disabled = React.useMemo(() => !isEditable, [isEditable])

  const {
    currentEditedAddress,
    setCurrentEditedAddress,
    isAddAddressDialogOpen,
    isEditing,
    onAddressInputFieldsChange,
    toggleShowAddAddressDialog,
    validateAddressFields,
    addressFieldsErrors,
    setIsAddAddressDialogOpen,
    setIsEditing,
  } = useAddressEdit()

  const getAllCompanyOwnerAddresses = React.useCallback(
    (): IAddress[] => {
      //extract a list of all the addresses from all the company owners, removing the duplicated ones

      const ownerAddresses: IAddress[][] =
        orderDetails.company_details && orderDetails.company_details.company_users
          ? orderDetails.company_details.company_users.map(company_user => company_user.user.addresses).filter(notEmpty)
          : []

      const allCompanyOwnerAddresses = ownerAddresses.length
        ? ownerAddresses.reduce((prev, cur) => prev && cur && prev.concat(cur)).filter(notEmpty)
        : []

      return allCompanyOwnerAddresses
    },
    [
      /*orderDetails.company_details*/
    ]
  )

  // We are fetching client addresses and merging them with company addresses,
  // filtering out duplicates by id
  const fetchAndSetAddresses = React.useCallback(async () => {
    if (
      !orderDetails.company_details ||
      !orderDetails.company_details.client ||
      !orderDetails.company_details.client.id
    )
      return

    const clientAddresses = await getUserAddresses(orderDetails.company_details.client.id)

    const primaryAddress = clientAddresses.find(address => address.is_primary)
    if (primaryAddress) {
      primaryAddress.is_primary = false
    }

    let allAddresses = clientAddresses
    if (orderDetails.company_details.addresses) {
      allAddresses = orderDetails.company_details.addresses.concat(
        clientAddresses.filter(
          ({ id }) =>
            !orderDetails.company_details || !orderDetails.company_details.addresses.find(address => address.id === id)
        )
      )
    }

    const companyOwnerAddresses = getAllCompanyOwnerAddresses()

    allAddresses = allAddresses.concat(
      companyOwnerAddresses.filter(address => !allAddresses.find(x => x.id === address.id))
    )

    setAddresses(allAddresses)
  }, [
    getAllCompanyOwnerAddresses, //, orderDetails.company_details
  ])

  React.useEffect(() => {
    fetchAndSetAddresses()
  }, [fetchAndSetAddresses])

  const onAddAddressClick = (type: CompanyAddressType) => {
    setEditedAddressType(type)
    toggleShowAddAddressDialog()
  }

  React.useEffect(() => {
    const updateAddress = async (addresses: IAddress) => {
      //

      const newAddress: IAddress = {
        ...addresses,
        company_id: orderDetails?.company_id,
        // is_primary: addresses?.is_mailing ? false : true,
        // is_mailing: addresses?.is_primary ? false : true,
      }

      console.log('newAddress', newAddress)

      try {
        //
        await createAddress(newAddress)
        // await editAddress(newAddress)
        showSuccessNotification('Address Updated')
      } catch (error) {
        showApiResponseError(error, 'Error updating Address')
      }
    }

    if (newAdd) {
      updateAddress(newAdd)
    }
  }, [newAdd])

  const onAddressEditClick = React.useCallback(
    (type: CompanyAddressType, id: number) => {
      const addressToEdit = addresses.find(address => address.id === id)
      if (!addressToEdit) return console.warn(`Didn't find an address with id ${id}`)
      setIsAddAddressDialogOpen(true)
      setIsEditing(true)

      setEditedAddressType(type)

      setCurrentEditedAddress(addressToEdit)
    },
    [setIsAddAddressDialogOpen, setCurrentEditedAddress, setIsEditing, addresses]
  )

  const handleAddressDeleteClick = React.useCallback(
    async (key: CompanyAddressType, id: number) => {
      if (!orderDetails.company_details) return
      let newAddresses: IAddress[] = deepcopy(addresses)

      switch (key) {
        case 'address': {
          newAddresses = newAddresses.map(address =>
            address.id === id
              ? {
                  ...address,
                  is_primary: false,
                }
              : address
          )
          break
        }

        case 'mailingAddress': {
          newAddresses = newAddresses.map(address =>
            id === address.id
              ? {
                  ...address,
                  is_mailing: false,
                }
              : address
          )
          break
        }
      }

      onCompanyDetailsInputFieldsChange('addresses', newAddresses)

      // await updateOrder({ id: orderDetails.id, company_details: orderDetails.company_details })
      setAddresses(newAddresses)
    },
    [addresses, onCompanyDetailsInputFieldsChange, orderDetails]
  )

  const onAddressSelect = async (key: CompanyAddressType, addressId: number | null) => {
    const selectedAddress = addresses.find(address => addressId === address.id) ?? null
    if (key === 'address') {
      onCompanyDetailsInputFieldsChange('primary_address', selectedAddress)
    }
    //
    const newAddresses = addresses.map(address =>
      addressId === address.id
        ? {
            ...address,
            // is_mailing: key === 'mailingAddress' || address.is_mailing,
            // is_primary: key === 'address' || address.is_primary,
            is_mailing: key === 'mailingAddress' ? true : false,
            is_primary: key === 'address' ? true : false,
          }
        : address
    )

    const filterAdd = newAddresses?.find(address => addressId === address.id)

    setAddresses(newAddresses)
    setNewAdd(filterAdd)
    onCompanyDetailsInputFieldsChange('addresses', newAddresses)
    // await updateOrder({
    //   id: orderDetails.id,
    //   company_details: orderDetails.company_details,
    // })
  }

  const handleAddressSubmitClick = React.useCallback(async () => {
    //

    if (!validateAddressFields()) return
    let newAddresses: IAddress[] = deepcopy(addresses)
    if (editedAddressType === 'address') {
      currentEditedAddress.is_primary = true
    } else if (editedAddressType === 'mailingAddress') {
      currentEditedAddress.is_mailing = true
    }
    const newAddress: IAddress = { ...currentEditedAddress, company_id: orderDetails?.company_id }

    try {
      if (newAddress?.id) {
        await editAddress(newAddress)
        showSuccessNotification('Address Updated')
      } else {
        await createAddress(newAddress)
        showSuccessNotification('Address Add Successfully')
      }
    } catch (e) {
      showApiResponseError(e, 'Error updating Address')
    }
    // Check if we're handling a newly created address.
    // Addresses are created on frontend without `id` field, so if it's not there, this is the case.
    if (!newAddress.id) {
      // We set a negative `id` to his address, so we know, that this one is created from order details screen.
      // We set a negative number here, so addresses, created on frontend for current order
      // do not conflict with ones, that are added to order from company at the moment of order creation and have normal positive ids.
      newAddress.id = minimumNegativeID(addresses)
      // We automatically make address primary, if before there were no addresses at all.
      if (!addresses.length) newAddress.is_primary = true
      newAddresses.push(newAddress)
    } else {
      // If we're handling the address, that already exists in `addresses` array, meaning we're doing address update, not creation,
      // we just replace the existing one with currently edited one, by comparing their ids.

      newAddresses = addresses.map(address => (address.id === currentEditedAddress.id ? currentEditedAddress : address))
    }

    setAddresses(newAddresses)

    // In the end we apply changes and close address editing dialog.
    onCompanyDetailsInputFieldsChange('addresses', newAddresses)
    toggleShowAddAddressDialog()
  }, [
    validateAddressFields,
    addresses,
    editedAddressType,
    currentEditedAddress,
    onCompanyDetailsInputFieldsChange,
    toggleShowAddAddressDialog,
  ])

  console.log('orderDetails', orderDetails)

  return (
    <React.Fragment>
      <AddAddressDialog
        open={isAddAddressDialogOpen}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressInputFieldsChange}
        handleDialogClose={toggleShowAddAddressDialog}
        onAddressAdd={handleAddressSubmitClick}
        addressErrors={addressFieldsErrors}
        isEditing={isEditing}
      />
      <div className={classes.horizontalWrapper} style={{ alignItems: 'start' }}>
        <div className={classes.addressFieldWrapper}>
          {!primaryAddress ? (
            <AddressesSelect
              className={classes.addressField}
              onAddAddressClick={() => onAddAddressClick('address')}
              addresses={addresses}
              label="Business Address"
              disabled={disabled}
              onChange={newAddressId => onAddressSelect('address', newAddressId)}
              error={true} // user should select a primary address
            />
          ) : (
            <Addresses
              label="Business Address"
              onlyOneAddress={true}
              onAddAddressClick={() => onAddAddressClick('address')}
              addresses={primaryAddress ? [primaryAddress] : []}
              onAddressEditClick={id => onAddressEditClick('address', id)}
              onAddressDeleteClick={id => handleAddressDeleteClick('address', id)}
              isEditable={isEditable}
              isDeletable={isEditable}
            />
          )}
        </div>

        <div className={classes.addressFieldWrapper}>
          {!mailingAddress ? (
            <AddressesSelect
              className={classes.addressField}
              onAddAddressClick={() => onAddAddressClick('mailingAddress')}
              addresses={addresses}
              label="Mailing Address"
              disabled={disabled}
              onChange={newAddressId => onAddressSelect('mailingAddress', newAddressId)}
            />
          ) : (
            <Addresses
              label="Mailing Address"
              onlyOneAddress={true}
              onAddAddressClick={() => onAddAddressClick('mailingAddress')}
              addresses={mailingAddress ? [mailingAddress] : []}
              onAddressEditClick={id => onAddressEditClick('mailingAddress', id)}
              onAddressDeleteClick={id => handleAddressDeleteClick('mailingAddress', id)}
              isEditable={isEditable}
              isDeletable={isEditable}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddressComponent
