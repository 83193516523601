import { PagedTable, usePagedTable } from '@jetkit/react'
import { IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import NotificationDialog, { INotification } from 'components/notifications/notificationsDialog'
import CompanyTypeHead from 'components/notifications/notificationsTable/companyTypeHead'
import NotificationRow from 'components/notifications/notificationsTable/notificationRow'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo } from 'react'
import { useStyles } from '../styles'
import { skeletons } from 'components/common/table'
import { deleteNotification, fetchCompanyTypeNotifications, getCompanyTypeNotifications } from 'api/notifications'
import EditIcon from 'components/common/icons/edit'
import DeleteIcon from 'components/common/icons/delete'
import { NotificationType } from 'pages/notifications'
import DeleteDialog from 'components/notifications/deleteDialog'
import { updatePageVisitsCount } from 'utils/user'

const CompanyList = () => {
  const classes = useStyles()

  const [notificationsType, setNotificationsType] = React.useState<NotificationType>('companyType')
  const [notificationDialogShown, setNotificationDialogShown] = React.useState(false)
  const [deleteDialogShown, setDeleteDialogShown] = React.useState(false)
  const [editedNotification, setEditedNotification] = React.useState<INotification>()
  const [notificationToDelete, setNotificationToDelete] = React.useState<INotification>()
  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_type' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(
    () => <CompanyTypeHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getCompanyTypeNotifications()({ sort_order: order, sort_by, query }), [
    order,
    query,
    sort_by,
  ])

  const pagedTable = usePagedTable<INotification>({
    apiCall: memoApiCall,
  })

  const handleEditNotificationClick = React.useCallback(
    (notification: INotification) => {
      setEditedNotification(notification)
      setNotificationDialogShown(true)
    },
    [setEditedNotification, setNotificationDialogShown]
  )

  const handleDeleteNotificationClick = React.useCallback(
    (notification: INotification) => {
      setNotificationToDelete(notification)
      setDeleteDialogShown(true)
    },
    [setNotificationToDelete, setDeleteDialogShown]
  )

  const handleNotificationDialogCloseClick = React.useCallback(() => {
    setEditedNotification(undefined)
    setNotificationDialogShown(false)
  }, [setEditedNotification, setNotificationDialogShown])

  const handleDeleteNotificationDialogCloseClick = React.useCallback(() => {
    setNotificationToDelete(undefined)
    setDeleteDialogShown(false)
  }, [setNotificationToDelete, setDeleteDialogShown])

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  return (
    <div className={classes.notificationSection}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className="notification-text">notifications</p>
          <p className="table-text">
            {/* Company{notificationsType === 'companyType' && ' Type'} */}
            Company Type Notifications List
          </p>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonWithLoading data-testid="create-notification" onClick={() => setNotificationDialogShown(true)}>
            Schedule Notification
          </ButtonWithLoading>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {notificationsType === 'company'
              ? skeletons({ numberOfColumns: 6, sizes: [120, 100, 120, 90, 120, 100], numberOfRows: 6 })
              : skeletons({ numberOfColumns: 7, sizes: [120, 81, 100, 130, 80, 80, 70], numberOfRows: 6 })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={7}
          emptyRowText="No Notifications"
          {...pagedTable.renderProps}
          renderRow={(notification: INotification) => (
            <NotificationRow
              notification={notification}
              notificationType={notificationsType}
              onEditClick={() => handleEditNotificationClick(notification)}
              onDeleteClick={() => handleDeleteNotificationClick(notification)}
            />
          )}
          header={tableHead}
        />
      )}
      <NotificationDialog
        isOpen={notificationDialogShown}
        close={handleNotificationDialogCloseClick}
        notificationType={notificationsType}
        editedNotification={editedNotification}
        onRequestSuccessful={handleSuccessfulRequest}
      />
      <DeleteDialog
        open={deleteDialogShown}
        deletionObject={notificationToDelete}
        close={handleDeleteNotificationDialogCloseClick}
        onRequestSuccessful={handleSuccessfulRequest}
        deletionRequest={deleteNotification}
      />
    </div>
  )
}

export default CompanyList

const notification = [
  {
    template: {
      name: 'Action Required - Annual Report Filing Deadline',
    },
    company_type: 'S Corporation',
    state_of_formation: 'California',
    order_type: 'nothing',
    dates: 'Jan - Feb',
    next_date: 'Jan - Feb',
    id: 1,
  },
]
