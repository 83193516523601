import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import React, { useState, useEffect, useCallback } from 'react'
import { useStyles } from './style'
// import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import useUser, { CURRENT_USER_LOCAL_STORAGE_KEY } from 'hooks/useUser'
import showSuccessNotification from 'utils/showSuccessNotification'
import showApiResponseError from 'utils/showApiResponseError'
import { createPassword, getUserImage, updateEmpProfile, updateProfile, uploadClientImg } from 'api/users'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Skeleton } from '@material-ui/lab'
import { USERTYPES, USER_TYPES, USER_TYPES_MAPPING } from 'utils/constant'
import { getEmpImage, uploadEmpImage } from 'api/employees'
import Switch from '@material-ui/core/Switch'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const ProfileSettingModal = ({ open, setOpen }) => {
  const classes = useStyles()
  const { user } = useUser()

  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [twoFAToggle, settwoFAToggle] = useState(false)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passError, setpassError] = useState<boolean>(false)
  const [passlengthError, setpasslengthError] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [profileImg, setProfileImg] = useState('')
  const [uplaodImg, setUplaodImg] = useState<any[]>([])

  const [isLoading, setIsLoading] = useState(false)
  const [isImgChange, setIsImgChange] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClose = () => {
    setOpen(false)
  }
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const userDetails = JSON.parse(localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY) || '')

  const id = userDetails?.extid
  const empId = userDetails?.id

  useEffect(() => {
    setPhone(userDetails?.phone_number)
    setEmail(userDetails?.email)
    settwoFAToggle(userDetails.twoFA)
    console.log('userDetails', userDetails)
  }, [userDetails?.email, userDetails?.phone_number, userDetails.twoFA])

  useEffect(() => {
    if (password !== confirmPassword || password.length < 8) {
      setpassError(true)
      if (password.length < 8) {
        setpasslengthError(true)
      } else setpasslengthError(false)
    } else setpassError(false)
  }, [password, confirmPassword, passError])

  const updateProfileChange = async () => {
    try {
      const res = await updateProfile({ email, phone, twoFA_active: twoFAToggle })
      if (res.success) {
        showSuccessNotification(`Profile Update Successfully`)

        const local = JSON.parse(localStorage.getItem('currentUser') || '')
        local.email = email
        local.phone_number = phone
        local.twoFA = twoFAToggle

        localStorage.setItem('currentUser', JSON.stringify(local))

        handleClose()
      } else if (res.error) {
        showApiResponseError(res.error, `Failed to Update Profile.`)
      }
    } catch (error) {
      if (error.statusCode === 404) showApiResponseError(error, `Failed to Update Profile.`)
      else {
        showApiResponseError(error.message, `Couldn't Save, User doesn't Exists.`)
      }
    }
  }

  const updateEmployeeProfileChange = async () => {
    const id = empId
    const employee_type = userDetails?.user_type
    try {
      const res = await updateEmpProfile({ email, phone, id, employee_type, twoFA_active: twoFAToggle })
      if (res) {
        showSuccessNotification(`Profile Update Successfully`)

        const local = JSON.parse(localStorage.getItem('currentUser') || '')
        local.email = email
        local.phone_number = phone
        local.twoFA = twoFAToggle

        localStorage.setItem('currentUser', JSON.stringify(local))

        handleClose()
      } else if (res.error) {
        showApiResponseError(res.error, `Failed to Update Profile.`)
      }
    } catch (error) {
      if (error.statusCode === 404) showApiResponseError(error, `Failed to Update Profile.`)
      else {
        showApiResponseError(error.message, `Couldn't Save, User doesn't Exists.`)
      }
    }
  }

  const submitPass = async () => {
    if (passlengthError || passError) {
      return
    }

    try {
      const res = await createPassword({ id, password, email: 'temp' })

      if (res.success) {
        showSuccessNotification(`Password Created Successfully`)
        handleClose()
      } else if (res.error) {
        showApiResponseError(res.error, `Failed to create password, User doesn't Exists.`)
      }
    } catch (error) {
      if (error.statusCode === 404) showApiResponseError(error, `Failed to create password, User doesn't Exists.`)
      else {
        showApiResponseError(error.message, `Couldn't Save, User doesn't Exists.`)
      }
    }
  }

  const updateChanges = () => {
    if (USERTYPES.employee === userDetails?.user_type) {
      updateEmployeeProfileChange()
    } else if (USERTYPES.client === userDetails?.user_type) {
      updateProfileChange()
    }

    if (!passError) {
      submitPass()
    }
  }

  ////////////////////////////////////////////////////////

  const onImageChange = async (e: any) => {
    setUplaodImg([...e.target.files])
    // handleUpload()
    setIsImgChange(true)
  }

  const getProfileImg = useCallback(async () => {
    setIsLoading(true)
    try {
      const img = await getUserImage()
      setProfileImg(img)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getEmpProfileImg = React.useCallback(async () => {
    setIsLoading(true)
    try {
      const img = await getEmpImage(userDetails?.id)
      setProfileImg(img)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [userDetails?.id])

  useEffect(() => {
    if (USERTYPES.employee === userDetails?.user_type) {
      getEmpProfileImg()
    } else if (USERTYPES.client === userDetails?.user_type) {
      getProfileImg()
    }
  }, [getEmpProfileImg, getProfileImg, userDetails?.user_type])

  const handleUpload = useCallback(async () => {
    setIsLoading(true)
    try {
      const img = await uploadClientImg(uplaodImg[0])
      setProfileImg(img?.url)
      getProfileImg()
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [getProfileImg, uplaodImg])

  const handleEmpUpload = useCallback(async () => {
    const id = empId
    setIsLoading(true)
    try {
      const img = await uploadEmpImage(id, uplaodImg[0])
      setProfileImg(img?.url)
      getEmpProfileImg()
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [empId, getEmpProfileImg, uplaodImg])

  useEffect(() => {
    if (USERTYPES.employee === userDetails?.user_type) {
      if (isImgChange) {
        handleEmpUpload()
      }
    } else if (USERTYPES.client === userDetails?.user_type) {
      if (isImgChange) {
        handleUpload()
      }
    }
  }, [handleEmpUpload, handleUpload, isImgChange, uplaodImg.length, userDetails?.user_type])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.profileSettingModal}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <div className="view-modal-header">
        <span className="modal-heading-sec">
          <h1 className="modal-heading">Settings</h1>
        </span>
        <Close onClick={handleClose} className="close" />
      </div>
      <DialogContent className="modal-content">
        <Divider />
        <div className={classes.mainRoot}>
          <div className={classes.root}>
            <div className={classes.tabRoot}>
              <input
                accept="image/png, image/jpeg"
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={onImageChange}
              />
              <label htmlFor="icon-button-file" className="img-section">
                <IconButton
                  className={classes.profileImage}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  {/* <AddAPhotoIcon className={classes.profileIcon} /> */}
                  {isLoading ? (
                    <Skeleton variant="circle" width={72} height={72} animation="wave" />
                  ) : (
                    <Avatar src={profileImg} className={classes.profileIcon} sizes="" />
                  )}
                </IconButton>
              </label>
              {/* <Avatar src={userDetails?.avatar_url} /> */}

              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab className={classes.tabsLabel} label="Account and Profile" {...a11yProps(0)} />
                {/* <Tab className={classes.tabsLabel} label="General" {...a11yProps(1)} /> */}
                <Tab className={classes.tabsLabel} label="Password" {...a11yProps(2)} />
              </Tabs>
            </div>
            <div className={classes.tabPanel}>
              <TabPanel value={value} index={0}>
                <label className={classes.inputLabel}> Name</label>
                <input
                  className={classes.innerInput}
                  placeholder="Enter Your Name"
                  value={userDetails?.name}
                  // disabled
                  readOnly
                />
                <label className={classes.inputLabel}> Phone</label>
                <PhoneInput
                  containerClass={classes.innerInput}
                  dropdownClass="country-list-dropdown"
                  placeholder="Enter Your Phone"
                  country={'us'}
                  value={phone}
                  onChange={phone => setPhone(phone)}
                />
                <label className={classes.inputLabel}> Email</label>
                <input
                  className={classes.innerInput}
                  placeholder="Enter Your Email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                {/* <label className={classes.inputLabel}> SSN</label>
                <input className={classes.innerInput} placeholder="Enter Your SSN" value={userDetails?.ssn_ein} /> */}
              </TabPanel>
              {/* <TabPanel value={value} index={1}>
                <label className={classes.inputLabel}> General Field</label>
                <input className={classes.innerInput} placeholder="Enter Your General Field" />
              </TabPanel> */}
              <TabPanel value={value} index={1}>
                <label className={classes.inputLabel}> Password</label>
                <Input
                  className={classes.innerInput}
                  placeholder="Enter Your Password Field"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  autoComplete="false"
                  onChange={e => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className={classes.errorText}>
                  {passlengthError || passError ? 'Password not match' : ''}
                </FormHelperText>
                <label className={classes.inputLabel}> Confirm Password</label>
                {/* <input
                  className={classes.innerInput}
                  placeholder="Enter Your Password Field"
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                /> */}
                <Input
                  className={classes.innerInput}
                  placeholder="Enter Your Password Field"
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  autoComplete="false"
                  onChange={e => setConfirmPassword(e.target.value)}
                  error
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className={classes.errorText}>
                  {passlengthError || passError ? 'Password not match' : ''}
                </FormHelperText>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={userDetails?.twoFa}
                      onChange={e => {
                        userDetails.twoFa = e.target.checked
                      }}
                      name="checkedA"
                    />
                  }
                  label="Two Factor Authentication"
                  style={{fontSize:'12px'}}
                /> */}
                {user?.user_type !== USER_TYPES_MAPPING?.Client ? (
                  <>
                    <hr style={{ width: '109%' }} />
                    <span className={classes.toggleInput}>
                      2 Factor Authentication
                      <Switch
                        checked={twoFAToggle}
                        onChange={e => {
                          settwoFAToggle(e.target.checked)
                        }}
                        name="checkedA"
                        size="small"
                        inputProps={{ 'aria-label': 'Two Factor Authentication' }}
                      />
                    </span>
                  </>
                ) : (
                  ''
                )}
              </TabPanel>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="modal-footer">
        <Button variant="contained" color="primary" className="update-btn" onClick={updateChanges}>
          {/* {value === 0 ? 'Update profile info' : 'Update Password'} */}
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProfileSettingModal
