export const removeNullAndUndefined = <T>(obj: T): Partial<T> => {
  return Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {})
}

export type ValueOf<T> = T[keyof T]

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const emptyFunction = () => {
  return undefined
}
