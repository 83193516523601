import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import React, { useMemo } from 'react'
import { useStyles } from './styles'
import { skeletons } from 'components/common/table'
import { IVisitorLogs, fetchVisitorLogs } from 'api/visitorLog'
import LogsRow from './table/logsRow'
import LogsHead from './table/logsHead'
import { useLocation } from 'react-router'

const VisitorLogs = () => {
  const classes = useStyles()

  const { state } = useLocation<any>()

  console.log('121212121 state', state)

  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'visit_time' })
  const { order, sort_by } = sortSettings

  const tableHead = useMemo(() => <LogsHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />, [
    sortSettings,
    handleSortingClick,
  ])

  const memoApiCall = useMemo(
    () => fetchVisitorLogs(state?.brid || '', state?.ip_address || '')({ sort_order: order, sort_by, query }),
    [order, query, sort_by, state?.brid, state?.ip_address]
  )

  const pagedTable = usePagedTable<IVisitorLogs>({
    apiCall: memoApiCall,
  })

  console.log('pagedTable', pagedTable)

  return (
    <div className={classes.notificationSection}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className="notification-text">Logs</p>
          <p className="table-text">Visitor Logs</p>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search by BRID/IP" />

      <TableLoadingIndicator isLoading={pagedTable.isLoading} />

      {pagedTable.isLoading ? (
        <Table>
          {tableHead}
          <TableBody>
            {skeletons({
              numberOfColumns: 12,
              sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
              numberOfRows: 12,
            })}
          </TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={12}
          emptyRowText="No Visitor Logs"
          {...pagedTable.renderProps}
          renderRow={(logs: IVisitorLogs) => <LogsRow logs={logs} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default VisitorLogs
