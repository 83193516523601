import React, { useMemo } from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Table, Theme, TableBody } from '@material-ui/core'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import useSortTable from 'hooks/useSortTable'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { skeletons } from 'components/common/table'
import AffiliatedTableHead from './head'
import AffiliatedTableRow from './row'
import { ITaxOrder } from 'models/taxOrder'
import { getAssociatedTaxOrder } from 'api/taxOrders'

interface AffiliatedTableProp {
  client_id: number
  company_id: number
}

const AffiliatedTable = ({ client_id, company_id }: AffiliatedTableProp) => {
  const classes = useStyles()

  const [query, setQuery] = React.useState('')

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'creation_time' })

  const tableHead = useMemo(
    () => <AffiliatedTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const memoApiCall = useMemo(() => getAssociatedTaxOrder(client_id, company_id)({ query }), [
    client_id,
    company_id,
    query,
  ])

  const pagedTable = usePagedTable<ITaxOrder>({
    apiCall: memoApiCall,
  })

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titlesContainer}>
          <p className={classes.tableTitle}>Affiliated Tax Order List</p>
        </div>
      </div>
      <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {pagedTable.isLoading ? (
        <Table>
          {/* {tableHead} */}
          <TableBody>{skeletons({ numberOfColumns: 11, sizes: [10], numberOfRows: 5 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          colSpan={11}
          emptyRowText="No Affiliated Orders"
          {...pagedTable.renderProps}
          renderRow={(taxOrder: ITaxOrder) => <AffiliatedTableRow taxOrder={taxOrder} />}
          header={tableHead}
        />
      )}
    </div>
  )
}

export default AffiliatedTable

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    affiliatedTableItem: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
)
