import * as React from 'react'
import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'

const headColumns: HeadColumn[] = [
  { id: 'date_time', label: 'Date Time', style: { width: 180 }, sortable: true },
  { id: 'status', label: 'Status', style: {}, sortable: true },
  { id: 'call_status', label: 'Call Status', style: {}, sortable: true },
  { id: 'callee_name', label: 'Callee Name', style: {}, sortable: true },
  { id: 'caller_name', label: 'Caller Name', style: {}, sortable: true },
  { id: 'caller_number', label: 'Caller Number', style: {}, sortable: true },
  { id: 'duration', label: 'Duration', style: {}, sortable: true },
  { id: 'call_queue', label: 'Call Queue', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const CallLogsHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default CallLogsHead
