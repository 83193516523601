import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'order_id', label: 'Order ID', style: {}, sortable: false },
  { id: 'reminderId', label: 'Reminder Id', style: {}, sortable: false },
  { id: 'name', label: 'Template Name', style: {}, sortable: false },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const OrderReminderTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default OrderReminderTableHead
