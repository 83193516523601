import * as React from 'react'

/**
 * Generic hook for fetching array of objects from server
 *
 * // Unknown type doesn't work well with T[] resources type, so using any is the only option
 *
 * @param apiCall
 * @param args
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useResources<ResourceType>(apiCall: (...args: any[]) => Promise<ResourceType[]>, ...args: unknown[]) {
  const [resources, setResources] = React.useState<ResourceType[]>([])

  const loadResources = React.useCallback(async () => {
    const result = await apiCall(...args)
    setResources(result)

    // we need to spread ...args  otherwise it's endless loop because for JS ['1', '2'] !== ['1', '2'] ;(
    //eslint-disable-next-line
  }, [apiCall, ...args])

  React.useEffect(() => {
    loadResources()
  }, [loadResources])

  return resources
}

export { useResources }
