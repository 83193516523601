import {
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { deleteAddress, editAddress, getstates } from 'api/addresses'

import { createClient, updateClient } from 'api/namechecklist'

import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'

import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedNameCheckList, INameCheckList } from 'models/user'
import * as React from 'react'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    selectItem: {
      margin: '0 !important',
      width: '100%',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: INameCheckList = {
  state_id: 0,
  name_check_url: '',
  id: 0,
}

export interface IClientDetailsProps {
  editedClient?: INameCheckList
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedNameCheckList) => void
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

const ClientDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful /*, initialClient*/ }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<INameCheckList>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()

  const { history } = useRouter()
  const updateClientRequest = async (client: INameCheckList) => {
    const editedClient = client
    const resClient = await updateClient({
      id: editedClient.id,
      name_check_url: editedClient.name_check_url || '',
      state_id: editedClient.state_id,
      // country_name: editedClient.country_name,
    })
    return resClient
  }

  const createClientRequest = async (client: INameCheckList) => {
    const resClient = await createClient({ ...client })
    return resClient
  }

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedNameCheckList = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const changeClientField = React.useCallback(
    (field: keyof INameCheckList, value: ValueOf<INameCheckList>) => {
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    try {
      let clientResponse: ICreatedNameCheckList
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)
      }

      const updatedClient = formMode === FormMode.UPDATE ? client : newClient
      setClient(updatedClient)
    } catch (err) {
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }, [formMode, client, clientEditErrors])

  const classes = useStyles()
  const [states, setStates] = React.useState([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const stateOptions = () => {
    return states?.map((state: any) => ({
      value: state?.id,
      label: state?.state_display_name,
    }))
  }
  React.useEffect(() => {
    const states = async () => {
      setLoading(true)

      const state = await getstates()
      setStates(state)
      setLoading(false)
    }
    states()
  }, [])
  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <div data-testid="state-of-formation" className={classes.selectItem}>
          <FormControl variant="outlined" className={classes.selectItem}>
            <InputLabel>State of Formation</InputLabel>
            <Select
              onChange={e => changeClientField('state_id', e.target.value as number)}
              label="State"
              name="stateOfFormation"
              value={client.state_id}
              fullWidth
              disabled={editedClient?.id ? true : false}
            >
              {stateOptions()?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Name Check Url"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.name_check_url}
          onChange={event => changeClientField('name_check_url', event.target.value)}
        />

        <SubmitFormButton
          onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default ClientDetails
