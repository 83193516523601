import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {},
    input: {
      backgroundColor: '#f6f6f8',
      fontSize: '16px',
      fontFamily: 'Roboto',

      '& input': {
        padding: '0.5rem',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    label: {
      marginBottom: '4px',
      fontSize: '12px',
      color: '#202124 ',
    },
  })
)

interface InputFieldProps {
  label: string
  value?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  type?: string
  disabled?: boolean
  autoFocus?: boolean
}
const InputField = ({ label, value, onChange, type, disabled, autoFocus }: InputFieldProps) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.mainContainer}>
        <label className={classes.label}>{label}</label>
        <TextField
          className={classes.input}
          variant="outlined"
          value={value}
          name={value}
          onChange={onChange}
          type={type ? type : 'text'}
          fullWidth
          disabled={disabled}
          autoFocus={autoFocus}
        />
      </div>
    </>
  )
}
export default InputField
