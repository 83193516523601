import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { BusinessCenter, MoreVert } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'

import EditIcon from 'components/common/icons/edit'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import CompanyCreationFlow from 'components/companies/create'
import { ISpecialOffers, ISalesRep, ISpecialOffersCategory } from 'models/user'
import * as React from 'react'
import { formatPhoneNumberToInternational, formatSSN } from 'utils/formatNumbers'
// import MoreOptionsMenu from '../moreOptionsMenu'
import DescriptionIcon from '@material-ui/icons/Description'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IClientTableRowProps {
  offers: ISpecialOffersCategory
  onEditClick: () => void
  onDeleteClick: () => void
}

const ClientTableRow: React.FC<IClientTableRowProps> = ({ offers, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      {/* <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialstates={offers} /> */}
      <StyledTableRow onClick={onEditClick} data-testid={`${offers.id}`} className={classes.row}>
        <StyledTableCell>{offers.id}</StyledTableCell>
        <StyledTableCell>{offers.title}</StyledTableCell>
        <StyledTableCell>{offers.heading}</StyledTableCell>
        <StyledTableCell>{offers.link_text}</StyledTableCell>

        <StyledTableCell>
          <span ref={moreOptionsRef}></span>
          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              onDeleteClick()
            }}
            style={{ width: '35px', height: '35px' }}
          >
            <DeleteIcon tooltipText="Delete" />
          </TableActionButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default ClientTableRow
