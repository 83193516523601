import { getProspectById, ProspectData } from 'api/leadManager'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import showErrorNotification from 'utils/showErrorNotification'
import { useStyles } from '../styles'
import ProspectList from './components'

const EditProspect = () => {
  const classes = useStyles()
  const { id } = useParams<any>()

  const [isLoading, setisLoading] = useState(false)
  const [prospectData, setProspectData] = useState<ProspectData>()

  const getProspect = async (id: number | undefined) => {
    setisLoading(true)
    try {
      if (id) {
        const res = await getProspectById(id)
        setProspectData(res)
        setisLoading(false)
      }
    } catch (error) {
      showErrorNotification(error)
    }
    setisLoading(false)
  }

  useEffect(() => {
    if (id) {
      getProspect(id)
    }
  }, [id])

  return (
    <div className={classes.container}>
      <ProspectList prospect={prospectData || null} isLoading={isLoading} />
    </div>
  )
}

export default EditProspect
