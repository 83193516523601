import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { IQuestion, QuestionStatus } from 'api/taxOrders'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import * as React from 'react'
import { theme } from 'theme'
import { changeNToBrTag } from 'utils/conversations'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: 4,
      padding: 10,
      paddingLeft: 13,
      paddingRight: 25,
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    checkbox: {
      height: 'min-content',
      alignSelf: 'center',
      marginRight: 12,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    statusLabel: {
      fontSize: 12,
      letterSpacing: '0.06px',
      padding: 5,
      paddingLeft: 8,
      paddingRight: 16,
      width: 'fit-content',
    },
    question: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: '0.09px',
      color: 'black',
    },
    answer: {
      fontSize: 18,
      letterSpacing: '0.09px',
      color: theme.palette.text.primary,
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    questionDetails: {
      display: 'flex',
      alignItems: 'center',

      '& .question-wrapper': {
        display: 'flex',
      },

      '& .question-sec': {
        padding: '0 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& p': {
          margin: 0,
        },
      },
    },
  }
})

const statusToHuman = (status: QuestionStatus): string => {
  switch (status) {
    case 'pending':
      return 'PENDING'
    case 'answered':
      return 'ANSWERED'
    case 'unanswered':
      return 'UNANSWERED'
    case 'sent_to_client':
      return 'SENT TO CLIENT'
    default:
      // return 'STATUS UNKNOWN'
      return 'PENDING'
  }
}

interface IQuestionCardProps {
  question: IQuestion
  onEditClick: (question: IQuestion) => void
  onDeleteClick: (question: IQuestion) => void
}

const questionColors = {
  answered: '#e4ffdf',
  unanswered: '#ffdecc',
  pending: 'lightGray',
  sent_to_client: 'lightBlue',
}

const QuestionCard: React.FC<IQuestionCardProps> = ({ question, onEditClick, onDeleteClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex' }}>
        <Checkbox className={`no-print ${classes.checkbox}`} color="primary" />
        <div className={classes.infoContainer}>
          <div
            className={classes.statusLabel}
            style={{ borderRadius: 4, backgroundColor: `${questionColors[question.status]}` }}
          >
            {statusToHuman(question.status)}
          </div>
          <Typography className={classes.question}>{changeNToBrTag(question.question_text)}</Typography>
          <Typography className={classes.answer}>{changeNToBrTag(question.answer_text)}</Typography>
        </div>
      </div>
      <div className={classes.questionDetails}>
        <div className="question-wrapper">
          <div className="question-sec">
            <b>Created by:</b>
            <p>{question?.name || '---'}</p>
          </div>
          <div className="question-sec">
            <b>Created at:</b>
            <p>{question?.question_date ? formatDateTimeToHumanFriendly(question?.question_date) : '---'}</p>
          </div>
        </div>
        <div className={`no-print ${classes.buttonsWrapper}`}>
          <TableActionButton onClick={() => onEditClick(question)} style={{ marginLeft: 10, width: 40, height: 40 }}>
            <EditIcon />
          </TableActionButton>
          <TableActionButton onClick={() => onDeleteClick(question)} style={{ marginLeft: 10, width: 40, height: 40 }}>
            <DeleteIcon />
          </TableActionButton>
        </div>
      </div>
    </div>
  )
}

export default QuestionCard
