export function capitalizeString(word: string): string {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1)
}

export function normalizeText(word: string): string {
  return word.trim().toLowerCase()
}

export function normalizePhone(word: string): string {
  return word.replace(/ |-|\)|\(/g, '')
}

export function positiveNumericInput(word: string): string {
  return word.replace(/\D/g, '')
}

export function escapeRegExp(word: string): string {
  return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function decimalNumberInput(word: string): string {
  return word.replace(/^\d*\.?\d*$/g, '')
}
