import { apiClient, requestPaginated } from '@jetkit/react'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import snakeCaseKeys from 'snakecase-keys'
import { AnyObjectSchema } from 'yup'

export interface ICreateClientResponse {
  id: number
  name: string
  email: string
  ssn_ein: number | null
  dob: string | null
  sales_rep_id: number | null
  phone_number: string | null
  addresses: IAddress[] | null
}

export const createClient = async (client: any): Promise<ICreatedState> => {
  delete client.id // delete the extid, the backend should take care of it
  delete client.state_display_name
  delete client.state_name
  delete client.state_id

  // //   client.user_type = '2'
  //   client.email = client.email ? client.email : ' '
  return (await apiClient.post('order-type', snakeCaseKeys(client))).data
}

export const updateClient = async (client: any): Promise<ICreatedState> => {
  return (await apiClient.post(`order-type/${client.id}`, snakeCaseKeys(client))).data
}
export const fetchClient = async (id: number): Promise<ICreatedState> => {
  return (await apiClient.get(`order-type/${id}`)).data
}
export const orderTypeTask = async (id: number): Promise<any> => {
  return (await apiClient.get(`order-type/${id}/tasks`)).data
}
export const orderTypeStatus = async (id: number): Promise<any> => {
  return (await apiClient.get(`order-type/${id}/status`)).data
}
export const orderTypeStatusConfig = async (id: number): Promise<any> => {
  return (await apiClient.get(`order-type/${id}/status/config`)).data
}
export const updateorderTypeStatus = async (id: number, status_id: number): Promise<any> => {
  return (await apiClient.post(`order-type/${id}/status/${status_id}`, snakeCaseKeys({ order_status: status_id }))).data
}
export const getorderTypeStatus = async (id: number): Promise<any> => {
  return (await apiClient.get(`order/${id}/status`)).data
}
export const fetchClients = async (query = ''): Promise<ICreatedClient[]> => {
  return (await apiClient.get(`user/client`, { params: { user_type: 'client', query, sort_order: 'desc' } })).data
}
export const paginateStatus = () => {
  return requestPaginated<ICreatedState[]>({ url: '/order-type' })
}
// export const searchClients = async (query = ''): Promise<IClient[]> =>
//   (apiClient.defaults.baseURL =
//     process.env.REACT_APP_BASE_URL ||
//     (
//       await apiClient.get(
//         //'http://localhost:3000/'
//         `search/client`,
//         { params: { query } }
//       )
//     ).data)

export const searchClients = async (query = ''): Promise<IClient[]> => {
  // apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
  // return (await apiClient.get(`search/client`, { params: { query, sort_order: 'desc' } })).data
  return []
}
export const getClientAddresses = async (id: number): Promise<IAddress[]> =>
  (await apiClient.get(`user/${id}/address`)).data

interface IClientAssetParams {
  clientId: number
  documentId: number
}

export const uploadClientAsset = async (clientId: number, file: File): Promise<UploadRequest> =>
  (await apiClient.post(`user/${clientId}/upload-asset`, { mime_type: file.type, file_name: file.name })).data

export const getClientDocuments = (clientId: number) => requestPaginated<IAsset[]>({ url: `user/${clientId}/asset` })

export const deleteClientDocument = async ({ clientId, documentId }: IClientAssetParams): Promise<void> => {
  await apiClient.delete(`user/${clientId}/asset/${documentId}`)
}

export const downloadClientDocumentURL = ({ clientId, documentId }: IClientAssetParams): string => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  return `${BASE_URL}/user/${clientId}/asset/${documentId}/download?jwt=${token}`
}
export const deletePredefinedTask = async (id: number): Promise<any> => {
  return apiClient.delete(`order-type/${id}`)
}

export const getDocumentTypes = async (query = ''): Promise<ICreatedClient[]> => {
  return (await apiClient.get(`document-types`, { params: { page: null, page_size: null, query, sort_order: 'desc' } }))
    .data
}
