import SimpleMenu from '../../simpleMenu'
import * as React from 'react'
import { RefObject } from 'react'
import useRouter from 'use-react-router'
import { IClient } from '../../../models/user'

interface IMoreOptionMenuProps {
  isOpen: boolean
  setIsOpen: (boolean) => void
  refElement: RefObject<HTMLElement>
  client: IClient
}

const MoreOptionsMenu: React.FC<IMoreOptionMenuProps> = ({ isOpen, refElement, setIsOpen, client }) => {
  const router = useRouter()
  const { name } = client

  const handleShowAssociateCompanies = React.useCallback(() => {
    // router.history.push(`/companies?query=${encodeURI(name)}`)
    router.history.push(`/companies?client_id=${client.id}&client_name=${encodeURI(name)}`)
  }, [name, router.history])

  const handleShowAssociateOrders = React.useCallback(() => {
    router.history.push(`/company-order?client_id=${client.id}&client_name=${encodeURI(name)}`)
  }, [name, router.history])

  const createClientMenuOptions = () => {
    return [
      {
        name: 'Show Associated Companies',
        onClick: handleShowAssociateCompanies,
      },
      {
        name: 'Show Associated Orders',
        onClick: handleShowAssociateOrders,
      },
    ]
  }

  return (
    <SimpleMenu
      isOpen={isOpen}
      refElement={refElement}
      handleClose={() => {
        setIsOpen(!isOpen)
      }}
      options={createClientMenuOptions()}
    />
  )
}

export default MoreOptionsMenu
