import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'

export interface ICreateProductReq {
  name: string
  pricing: IStripePrice[]
  id?: number
  email: string
  meeting_link: string
  content: string
  product_id?: string
  quickbook_item_id?: string
  active?: boolean
}

export interface ICreateCompanySubscription {
  trial_period?: number
  price_id: number
  company_id: number
  subscription_type?: string
  paid_through_date?: string
}

export interface IUpdateompanySubscription {
  id: number
  active: boolean
  price_id: number
  resumes_at: number | undefined
}

export interface IStripePrice {
  active?: boolean
  amount?: number
  recurring?: boolean
  billing_period?: string
  product_id?: string // stripe product id
  price_id?: string
  name?: string
  id?: number
}
export interface IStripeProduct {
  id: number
  name: string
}

export interface IProductResponse {
  id: number
  name: string
  product_id: string
  active: boolean
}

export interface ISubscriptionsProps {
  id: number
  active: boolean
  current_period_end: number
  current_period_start: number
  price: IStripePrice
  product: IProductResponse
  status: string
  creation_time?: string
  subscription_type?: string
  allocated_address?: string
}

export interface IPostImportSubscription {
  subscription_id: string
  company_id: number
}

export interface ICompanySubscriptionResponse {
  active: boolean
  company_id: number
  company_name: string
  current_period_end: number
  current_period_start: number
  id: number
  subscription_id: string
  price: IStripePrice
  product: ICreateProductReq
  status?: string
  creation_time?: string
  state_of_formation?: string
  paused?: boolean
  collected_revenue?: number
}

export interface INameValue {
  value: number
  name: string
}

export interface ILogsResponse {
  id: number
  subscription_id: number
  log_date_time: string
  title: string
}

export interface IPastInvoiceResponse {
  amount_due: number
  invoice_id: string
  period_end: number
  period_start: number
  status: string
  subscription: string
}
export const paginatedProducts = () => {
  return requestPaginated<IProductResponse[]>({ url: '/subscriptionproducts-paginated' })
}

export const fetchSubscription = async (id: number): Promise<ICreateProductReq> => {
  const data = (await apiClient.get(`subscriptionproducts/${id}`)).data
  return data
}

export const updateSubscription = async (subscription: ICreateProductReq): Promise<void> => {
  const obj: any = subscription

  return (await apiClient.post(`subscription/${subscription.id}`, snakecaseKeys(obj))).data
}

export const createSubscription = async (subscription: ICreateProductReq): Promise<any> => {
  return (await apiClient.post('create-subscription', snakecaseKeys(subscription))).data
}

export const deleteSubscriptionProduct = async (id: number): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`subscripton-product/${id}`)
}

export const fetchSubscriptionsByCompanyID = async (id: number): Promise<ISubscriptionsProps[]> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.161:3000/'

  const data = (await apiClient.get(`company-subscriptions/${id}`)).data
  return data
}

export const fetchSubscriptionsBySubscriptionID = async (id: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.18.161:3000/'

  const data = (await apiClient.get(`company-subscription-detail/${id}`)).data
  return data
}

export const fetchSubscriptionProduct = async (): Promise<IProductResponse[]> => {
  const data = (await apiClient.get(`subscriptionproducts`)).data
  return data
}

export const createCompanySubscription = async (subscription: ICreateCompanySubscription): Promise<any> => {
  return (await apiClient.post('company-subscription', snakecaseKeys(subscription))).data
}
export const cardRequest = async (id: number, payload) => {
  return (await apiClient.post(`/card-request/${id}`, snakecaseKeys(payload))).data
}
export const updateCompanySubscription = async (payload: IUpdateompanySubscription): Promise<any> => {
  return (await apiClient.post(`company-subscription/${payload?.id}`, snakecaseKeys(payload))).data
}

export const postImportSubscription = async (payload: IPostImportSubscription): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'

  return (await apiClient.post('import-subscription', snakecaseKeys(payload))).data
}

export const companySubscriptions = () => {
  return requestPaginated<ICompanySubscriptionResponse[]>({ url: '/company-subscriptions' })
}
// subscriptions without page table
export const companySubscriptionsData = async params => {
  const data = (
    await apiClient.get(`company-subscriptions`, {
      params: params,
    })
  ).data
  return data
}
export const pastDueRequest = async (id: number, payload) => {
  return (await apiClient.post(`/past-due-notice/${id}`, snakecaseKeys(payload))).data
}
export const updateSubscriptionStatus = async (id: number, payload) => {
  return (await apiClient.post(`/company-subscription-status/${id}`, snakecaseKeys(payload))).data
}

export const deleteSubscription = async (id: number): Promise<AxiosResponse<void>> => {
  return (await apiClient.delete(`subscription/${id}`)).data
}

export const cancelSubscriptionArr = async (payload: any) => {
  return (await apiClient.post(`cancel-subscriptions`, snakecaseKeys(payload))).data
}

export const getSubcriptionLogs = async (id: number): Promise<ILogsResponse[]> => {
  const data = (await apiClient.get(`subscription-logs/${id}`)).data
  return data
}

export const getPastDueInvoices = async (id: number): Promise<IPastInvoiceResponse[]> => {
  const data = (await apiClient.get(`past-due-invoices/${id}`)).data
  return data
}

export const payPostDueInvoices = async (payload: any) => {
  return (await apiClient.post(`pay-pastdue-invoices`, payload)).data
}
