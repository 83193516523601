import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'logged_at', label: 'Date', style: {}, sortable: true },
  // { id: 'timeSpend', label: 'Time Spend', style: {}, sortable: false },
  { id: 'user_clicks', label: 'Total Clicks', style: {}, sortable: true },
  { id: 'page_visits', label: 'Link Clicks', style: {}, sortable: true },
  { id: 'clickRatio', label: 'Clicks Ratio', style: {}, sortable: false },

  { id: 'order_status_updated', label: 'Order State Updated', style: {}, sortable: true },
  { id: 'order_updated', label: 'Order Updated', style: {}, sortable: true },
  { id: 'notes_created', label: 'Notes', style: {}, sortable: true },
  { id: 'task_created', label: 'Task Created', style: {}, sortable: true },
  { id: 'task_completed', label: 'Task Completed', style: {}, sortable: true },
  { id: 'task_updated', label: 'Task Updated', style: {}, sortable: true },
]

const TableReportHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default TableReportHead
