import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CheckListQuestion, sendCheckListAnswer } from 'api/taxOrders'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { SendOutlined } from '@material-ui/icons'
import { useParams } from 'react-router'

interface CheckListQuestionProps {
  quesList?: CheckListQuestion[]
  getUpdatedNotes?: any
}
interface ParamsType {
  id?: number
}
const CheckList: React.FC<CheckListQuestionProps> = ({ quesList, getUpdatedNotes }) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  // const [childQues, setChildQues] = useState<any>([])
  const [disabled, setDisabled] = useState(false)
  const [val, setVal] = useState<any>([])
  const [selectedId, setSelectedId] = useState<number>()
  const [selectedAnsId, setAnsId] = useState<number>()
  const params = useParams()

  function changeAnswer(userArray, questionId, newAnswerId, newAnswerText) {
    let found = false

    for (const item of userArray) {
      if (item.id === questionId) {
        found = true
        if (newAnswerId !== undefined && newAnswerId !== null) {
          item.user_answered.answer_id = newAnswerId
        }
        if (newAnswerText !== undefined && newAnswerText !== null) {
          item.user_answered.answer_text = newAnswerText
        }
        item.user_answered.child_ans = null
        break
      }

      if (Array.isArray(item.nested_question)) {
        const nestedQuestion = item.nested_question.find(nestedItem => nestedItem.id === questionId)
        if (nestedQuestion) {
          found = true
          if (newAnswerId !== undefined && newAnswerId !== null) {
            nestedQuestion.user_answered.answer_id = newAnswerId
          }
          //   if (newAnswerText !== undefined && newAnswerText !== null) {
          //     nestedQuestion.user_answered.answer_text = newAnswerText;
          //   }
          break
        }
      }
    }

    if (!found) {
      console.log(`Question ID ${questionId} not found.`)
    }

    return userArray
  }
  const handleAnswerChange = async (id: any, ansId: any) => {
    // console.log(id, ansId, 'ONCHANGEEEEEEEEEEEEEEEEEEEEEEEEE')
    setSelectedId(id)
    setAnsId(ansId)
    const updatedData = changeAnswer(val, id, Number(ansId), null)
    setVal(updatedData)
    setDisabled(true)

    const payload = {
      question_id: id ?? null,
      answer_id: Number(ansId) ?? null,
      taxorder_id: Number((params as ParamsType)?.id) ?? null,
    }
    try {
      await sendCheckListAnswer(payload)

      setDisabled(false)
      getUpdatedNotes()
    } catch (error) {
      setDisabled(false)
    }
  }
  const handleSendData = async (id: any, text: any) => {
    const updatedData = changeAnswer(val, id, null, text)
    setVal(updatedData)
    setDisabled(true)
    const payload = {
      question_id: id ?? null,
      answer_text: text,
      taxorder_id: Number((params as ParamsType)?.id) ?? null,
    }
    try {
      await sendCheckListAnswer(payload)
      getUpdatedNotes()
      setDisabled(false)
      setInputValue('')
    } catch {
      setDisabled(false)
    }
  }

  useEffect(() => {
    setVal(quesList)
    const initialAnswerTexts: { [key: string]: string } = {}
    quesList?.forEach(question => {
      if (question.id && question?.user_answered?.answer_text) {
        initialAnswerTexts[question?.id] = question?.user_answered?.answer_text?.toString()
      }
    })
  }, [quesList])

  return (
    <>
      <div>
        <div className={classes.header}>
          <h1 className={classes.tableTitle}>Checklist</h1>
        </div>
        <div>
          {val &&
            val?.map((element, index) => {
              return (
                <div key={index} style={{ marginBottom: '1rem' }}>
                  {element?.parent_heading ? (
                    <>
                      <p className={classes.parentQues}>
                        {index + 1}. {element?.parent_heading}:
                      </p>
                      {element.nested_question.map((elem: any, ind: number) => {
                        return (
                          <>
                            <FormControl className={classes.form}>
                              <FormLabel className={classes.nestedQuestionText}>
                                {index + 1}.{ind + 1}. {elem?.question_text}
                              </FormLabel>

                              <RadioGroup
                                row
                                aria-labelledby={`question-label-${index}`}
                                name={`question-${index}`}
                                onChange={e => handleAnswerChange(elem?.id, e.target.value)}
                              >
                                {elem?.answers && elem?.answers?.length > 0 ? (
                                  elem.answers.map((answer: any, answerIndex: number) => {
                                    return (
                                      <>
                                        <FormControlLabel
                                          disabled={disabled}
                                          key={answerIndex}
                                          value={answer?.answer?.id?.toString()}
                                          control={<Radio />}
                                          label={answer.answer.answer_text}
                                          style={{ margin: '0 3rem' }}
                                          checked={
                                            elem?.user_answered?.answer_id &&
                                            elem?.user_answered?.answer_id?.toString() ===
                                              answer?.answer?.id?.toString()
                                              ? true
                                              : false
                                          }
                                        />
                                      </>
                                    )
                                  })
                                ) : (
                                  <>
                                    <TextField
                                      disabled={disabled}
                                      type="secondary"
                                      label="Enter your answer"
                                      variant="outlined"
                                      className={classes.textField}
                                      defaultValue={elem?.user_answered?.answer_text?.toString()}
                                      value={
                                        element?.user_answered?.answer_id &&
                                        element?.user_answered?.answer_id?.toString()
                                      }
                                      onChange={e => {
                                        setInputValue(e.target.value)
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            style={{ cursor: 'pointer' }}
                                            position="end"
                                            onClick={() => handleSendData(elem?.id, inputValue)}
                                          >
                                            <SendOutlined />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <FormControl className={classes.form}>
                        <FormLabel className={classes.questionText}>
                          {index + 1}. {element?.question_text}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby={`question-label-${index}`}
                          name={`question-${index}`}
                          onChange={e => handleAnswerChange(element?.id, e.target.value)}
                        >
                          {element.answers && element.answers.length > 0 ? (
                            element.answers.map((answer: any, answerIndex: number) => {
                              return (
                                <>
                                  <div>
                                    <FormControlLabel
                                      disabled={disabled}
                                      key={answerIndex}
                                      value={answer?.answer?.id?.toString()}
                                      control={<Radio />}
                                      label={answer.answer.answer_text}
                                      style={{ margin: '0 1rem' }}
                                      checked={
                                        element?.user_answered?.answer_id &&
                                        element?.user_answered?.answer_id?.toString() === answer?.answer?.id?.toString()
                                          ? true
                                          : false
                                      }
                                    />
                                    {(answer.child_question &&
                                      selectedId === answer.child_question.parent_question_id &&
                                      answer.answer.id.toString() === selectedAnsId) ||
                                    (answer.child_question &&
                                      answer.answer.id === element?.user_answered?.answer_id) ? (
                                      <FormControl className={classes.form}>
                                        <FormLabel
                                          className={classes.questionText}
                                          id="demo-row-radio-buttons-group-label"
                                        >
                                          {index + 1}.1 {answer.child_question?.question_text}
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          aria-labelledby={`question-label-${index}`}
                                          name={`question-${index}`}
                                          onChange={e => handleAnswerChange(answer.child_question.id, e)}
                                        >
                                          {answer.child_question?.answers &&
                                          answer.child_question?.answers.length > 0 ? (
                                            answer.child_question?.answers.map((answer, answerIndex) => (
                                              <FormControlLabel
                                                disabled={disabled}
                                                key={answerIndex}
                                                value={answer.answer.answer_text}
                                                control={<Radio />}
                                                label={answer.answer.answer_text}
                                                className={classes.formLabel}
                                                // checked={
                                                //   element?.user_answered?.answer_id &&
                                                //   element?.user_answered?.answer_id?.toString() === answer.answer.id.toString()
                                                //     ? true
                                                //     : false
                                                // }
                                              />
                                            ))
                                          ) : (
                                            <TextField
                                              disabled={disabled}
                                              type="secondary"
                                              label="Enter your answer"
                                              variant="outlined"
                                              style={{ margin: '0 2rem' }}
                                              // className={classes.textField}
                                              defaultValue={element?.user_answered?.child_ans?.toString()}
                                              // value={
                                              //   element?.user_answered?.child_ans &&
                                              //   element?.user_answered?.child_ans?.toString()
                                              // }
                                              onChange={e => setInputValue(e.target.value)}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment
                                                    style={{ cursor: 'pointer' }}
                                                    position="end"
                                                    onClick={() => {
                                                      if (!inputValue.length) {
                                                        return
                                                      }
                                                      handleSendData(answer.child_question?.id, inputValue)
                                                    }}
                                                  >
                                                    <SendOutlined />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </>
                              )
                            })
                          ) : (
                            <>
                              <Checkbox
                                color="primary"
                                checked={element?.user_answered?.answer_text?.toString() === 'N/A' ? true : false}
                                onChange={e => handleSendData(element?.id, !e.target.checked ? '' : e.target.value)}
                                value="N/A"
                              />
                              <p className={classes.optionText}>Not Available</p>

                              <TextField
                                disabled={
                                  disabled || element?.user_answered?.answer_text?.toString() === 'N/A' ? true : false
                                }
                                type="secondary"
                                label="Enter your answer"
                                variant="outlined"
                                className={classes.textField}
                                defaultValue={element?.user_answered?.answer_text?.toString()}
                                value={
                                  element?.user_answered?.answer_id && element?.user_answered?.answer_id?.toString()
                                }
                                onChange={e => {
                                  setInputValue(e.target.value)
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ cursor: 'pointer' }}
                                      position="end"
                                      onClick={() => {
                                        if (!inputValue.length) {
                                          return
                                        }
                                        handleSendData(element?.id, inputValue)
                                      }}
                                    >
                                      <SendOutlined />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          )}

                          {console.log(element.answers, 'ALLANSWERS')}
                          {/* {element?.answers && element.answer.length ? "":""} */}
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default CheckList

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    width: '100%',
    marginBottom: '2rem',
  },
  tableTitle: {
    color: '#020202',
    fontSize: '2rem',
    fontFamily: 'Lato',
    fontWeight: 600,
    margin: 0,
  },
  questionText: {
    marginBottom: '0.8rem',
    color: 'black',
    fontSize: '18px',
  },
  textField: {
    margin: '0 2rem',
    width: '30%',

    '& .MuiTextField-root': {
      border: 'none !important',
    },
  },
  formLabel: {
    margin: '0 1rem',
  },
  form: {
    display: 'flex',
    margin: '1rem 1rem 0 1rem',
  },
  childQues: {
    margin: '0 0 0 2rem',
  },
  parentQues: {
    marginBottom: '0.5rem',
    color: 'black',
    fontSize: '18px',
    marginLeft: '1rem',
  },
  nestedQuestionText: {
    marginBottom: '0.5rem',
    color: 'black',
    fontSize: '18px',
    marginLeft: '2rem',
  },
  optionText: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
  },
}))
