import { Avatar, IconButton, Slide, Snackbar } from '@material-ui/core'
import { getTaskNotify } from 'api/task'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { Close } from '@material-ui/icons'
import { ITaskNotify } from 'models/task'
import { TransitionProps } from '@material-ui/core/transitions'
import { isLoggedIn } from 'axios-jwt'

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="left" />
}

const NotesNotify = () => {
  const classes = useStyles()
  const [notifyOpen, setNotifyOpen] = React.useState(false)
  const [isTrigger, setIsTrigger] = React.useState(false)

  const [tasks, setTasks] = React.useState<ITaskNotify[]>([])

  React.useEffect(() => {
    const getSingleNoteNotify = async () => {
      try {
        const res = await getTaskNotify()
        setTasks(res)
        if (res?.length) {
          setNotifyOpen(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (isTrigger && isLoggedIn()) {
      getSingleNoteNotify()
      setIsTrigger(false)
    }
  }, [isTrigger, isLoggedIn])

  const timer = setTimeout(() => {
    setIsTrigger(true)
  }, 300000)

  React.useEffect(() => {
    return () => clearTimeout(timer)
  }, [timer])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setNotifyOpen(false)
  }

  return (
    <Snackbar
      open={notifyOpen}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={SlideTransition}
      action={
        <IconButton aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
          <Close />
        </IconButton>
      }
      message={
        <div className={classes.notifyContent}>
          <Avatar alt={tasks[0]?.assigned_by} src={tasks[0]?.employee_image} />
          <p className="content">
            <b>{tasks[0]?.assigned_by}</b> assigned a task to you
          </p>
        </div>
      }
      className={classes.taskNotify}
    />
    // <>
    //   {tasks?.map((task: ITaskNotify) => (
    //     <Snackbar
    //       open={true}
    //       onClose={handleClose}
    //       autoHideDuration={6000}
    //       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //       action={
    //         <IconButton aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
    //           <Close />
    //         </IconButton>
    //       }
    //       message={
    //         <div className={classes.notifyContent}>
    //           <Avatar alt={task?.assigned_by} src={task?.employee_image} />
    //           <p className="content">
    //             <b>{task?.assigned_by}</b> assigned a task to you
    //           </p>
    //         </div>
    //       }
    //       className={classes.taskNotify}
    //     />
    //   ))}
    // </>
  )
}

export default NotesNotify

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    taskNotify: {
      '& .MuiSnackbarContent-root': {
        background: '#f47950',
      },
    },
    notifyContent: {
      display: 'flex',
      alignItems: 'center',

      '& .content': {
        paddingLeft: '1rem',
      },
    },
  })
)
