import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Editor } from '@tinymce/tinymce-react'
import { updateDoc } from 'api/docs'
import { ICompany } from 'models/company'
import * as React from 'react'
import { useParams } from 'react-router'
import { EditorApiKey } from 'utils/constant'
import DocumentHeader from './documentHeader'
import { useTemplatePDF } from './hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      padding: '1rem 1rem 1rem 2rem',
      justifyContent: 'flex-end',
    },
    controlButton: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    progressContainer: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    editorContainer: {
      width: '97%',
      height: '100%',
      padding: '4rem 1rem 1rem 1rem',
    },
  })
)

export const CompanyContext = React.createContext<ICompany | undefined>(undefined)

function DocumentEditor() {
  const classes = useStyles()

  const { id } = useParams<{ id: string }>()

  const document_id: number = parseInt(id)

  const { value, setValue, doc, pdfLink, isLoading } = useTemplatePDF({
    document_id,
  })

  const handleDocSave = React.useCallback(async () => {
    if (doc) {
      await updateDoc(doc.id, value)
    }
  }, [value, doc])

  if (isLoading) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.editorContainer}>
      <DocumentHeader title={doc ? doc.name : ''} pdfLink={pdfLink} onDocSaveClick={handleDocSave} />
      <Editor
        apiKey={EditorApiKey}
        init={{
          plugins: 'table',
          height: '85%',
          width: '100%',
          valid_children: '+body[style]',
          valid_styles: { '*': '*' },
          init_instance_callback: function(editor) {
            editor.on('click', function(e) {
              if (e.target.nodeName === 'INPUT') {
                if (e.target.getAttribute('checked')) {
                  e.target.removeAttribute('checked')
                } else {
                  e.target.setAttribute('checked', 'true')
                }
              }
            })
          },
        }}
        onEditorChange={e => setValue(e)}
        initialValue={''}
        value={value}
      />
    </div>
  )
}

export default DocumentEditor
