/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { notify } from '@jetkit/react'
import { CircularProgress, createStyles, Dialog, IconButton, makeStyles, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { AxiosResponse } from 'axios'
import AlertButton from 'components/common/buttons/AlertButton'
import { ICompany } from 'models/company'
import { ICities, IDocTypes, ISalesRep, IStates } from 'models/user'
import { IClient, IEmployee } from 'models/user'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import { INotification } from '../notificationsDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: '48rem',
      width: '100%',
      paddingBottom: '3.375rem',
    },
    closeButton: {
      width: 'min-content',
      alignSelf: 'flex-end',
      marginRight: '1rem',
    },
    title: {
      fontSize: 28,
      fontWeight: 600,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontFamily: 'SFCompactDisplay',
      marginBottom: '1rem',
    },
    info: {
      paddingLeft: '4rem',
      paddingRight: '4rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      marginLeft: '5.625rem',
      marginRight: '5.625rem',
      display: 'flex',
      backgroundColor: 'rgba(250, 250, 250, 0.9)',
      border: 'solid 1px rgba(151, 151, 151, 0.26)',
      borderRadius: '4px',
      justifyContent: 'center',
    },
    button: {
      width: '13.75rem',
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: '0.09px',
      alignSelf: 'center',
      marginTop: '1.5rem',
    },
  })
)

// all possible types that we can handle
type DeletableObject =
  | IClient
  | ICompany
  | INotification
  | ObjectWithId
  | IEmployee
  | ISalesRep
  | IStates
  | ICities
  | IDocTypes
  | undefined

interface IDeleteDialogProps {
  open: boolean
  close: () => void
  deletionObject?: DeletableObject
  deletionRequest?: (id: number) => Promise<AxiosResponse<void>>
  onRequestSuccessful?: () => void
}

interface ObjectWithId {
  id: number
}

const hasId = (obj: DeletableObject): obj is ObjectWithId => {
  return obj !== undefined && (obj as ObjectWithId).id !== undefined
}
const isClient = (obj: DeletableObject): obj is IClient => {
  return obj !== undefined && (obj as IClient).email !== undefined
}
const isEmployee = (obj: DeletableObject): obj is IEmployee => {
  return obj !== undefined && (obj as IEmployee).email !== undefined
}
const isSalesRep = (obj: DeletableObject): obj is ISalesRep => {
  return obj !== undefined && (obj as ISalesRep).email_1 !== undefined
}
const isCompany = (obj: DeletableObject): obj is ICompany => {
  return obj !== undefined && (obj as ICompany).legal_type !== undefined
}
const isNotification = (obj: DeletableObject): obj is INotification => {
  return obj !== undefined && (obj as INotification).template !== undefined
}
const isState = (obj: DeletableObject): obj is IStates => {
  return obj !== undefined && (obj as IStates).id !== undefined
}
const isCity = (obj: DeletableObject): obj is ICities => {
  return obj !== undefined && (obj as ICities).id !== undefined
}
const isDocType = (obj: DeletableObject): obj is IStates => {
  return obj !== undefined && (obj as IStates).id !== undefined
}
const isTask = (obj: any): obj is any => {
  return obj !== undefined && (obj as any).id !== undefined
}
const deletionObjectDisplayName = (deletionObject: DeletableObject): string | undefined => {
  if (isCompany(deletionObject)) return 'company'
  if (isNotification(deletionObject)) return 'notification'
  if (isEmployee(deletionObject)) return 'employee'
  if (isSalesRep(deletionObject)) return 'sales representative'
  if (isClient(deletionObject)) return 'client'
  if (isState(deletionObject)) return 'state'
  if (isCity(deletionObject)) return 'city'
  if (isDocType(deletionObject)) return 'document type'
  else return 'name'
}

function deletionObjectDescription(deletionObject: DeletableObject): string {
  if (isNotification(deletionObject)) {
    const infoStrings: string[] = []
    // if (deletionObject.company) infoStrings.push(deletionObject?.company.name)
    // if (deletionObject.company_type) infoStrings.push(deletionObject?.company_type)
    infoStrings.push(deletionObject.order_type.name)
    infoStrings.push(deletionObject.template.name)
    return infoStrings.join(' - ')
  }

  if (isSalesRep(deletionObject)) {
    return deletionObject.first_name
  }
  if (isEmployee(deletionObject)) {
    return deletionObject.first_name
  }
  if (isCompany(deletionObject)) {
    return deletionObject.name
  }
  if (isCity(deletionObject)) {
    if (deletionObject?.city_name! !== undefined) return deletionObject?.city_name!
  }
  if (isDocType(deletionObject)) {
    if (deletionObject?.typename! !== undefined) return deletionObject?.typename!
  }
  if (isState(deletionObject)) {
    return deletionObject.state_display_name
  }
  // if (isTask(deletionObject)) {
  //   if (deletionObject?.name! !== undefined) return deletionObject?.name!
  // }

  throw new Error(`unknown deletion object type ${deletionObject}`)
}

const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  open,
  close,
  deletionObject,
  deletionRequest,
  onRequestSuccessful,
}) => {
  const classes = useStyles()
  const [waitingForResponse, setWaitingForResponse] = React.useState(false)

  const info = deletionObject ? deletionObjectDescription(deletionObject) : undefined

  const handleDeleteClick = React.useCallback(async () => {
    // const errorMessage = `Sorry, could not delete ${deletionObjectDisplayName(deletionObject)}`
    const errorMessage = `Sorry, could not delete data`

    let id: number | undefined
    if (hasId(deletionObject)) {
      id = deletionObject.id
    }
    if (!id || !deletionRequest) return notify.warning(errorMessage)
    setWaitingForResponse(true)
    try {
      await deletionRequest(id)
      setWaitingForResponse(false)
      onRequestSuccessful && onRequestSuccessful()
      notify.success(`Successfully deleted ${deletionObjectDisplayName(deletionObject)}`)
      close()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, errorMessage)
    }
  }, [deletionRequest, deletionObject, onRequestSuccessful, close])

  return (
    <Dialog open={open} PaperProps={{ className: classes.paper }}>
      <IconButton onClick={close} className={classes.closeButton}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <div className={classes.title}>
        Are you sure you want to delete this data{/*deletionObjectDisplayName(deletionObject)*/}?
      </div>
      <div className={classes.info}>{info}</div>
      <AlertButton classes={{ button: classes.button }} onClick={handleDeleteClick} disabled={waitingForResponse}>
        {waitingForResponse ? <CircularProgress style={{ color: 'white' }} /> : 'Delete'}
      </AlertButton>
    </Dialog>
  )
}

export default DeleteDialog
