import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const TaxOrderTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>Priority</StyledTableCell>
        <StyledTableCell>Filer&apos;s Name</StyledTableCell>
        <StyledTableCell>Tax Year</StyledTableCell>
        <StyledTableCell>Type</StyledTableCell>
        {/* <StyledTableCell>CPA Assigned</StyledTableCell> */}
        <StyledTableCell>Associate</StyledTableCell>
        <StyledTableCell>Controller</StyledTableCell>
        <StyledTableCell>Stage</StyledTableCell>
        <StyledTableCell>Creation Date</StyledTableCell>
        <StyledTableCell>Last Updated</StyledTableCell>
        <StyledTableCell>Due Date</StyledTableCell>

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

const CompanyTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>Id</StyledTableCell>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>File Date</StyledTableCell>

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export { CompanyTableHead, TaxOrderTableHead }
