import InputAdornment from '@material-ui/core/InputAdornment'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import * as React from 'react'
import { parseNumberFromInput } from 'utils/formatNumbers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyTextField: {
      width: '49%',
      height: 50,
      margin: 0,
    },
  })
)

interface IMoneyInputFieldProps {
  field: string
  value?: string
  onChange?: (field: string, value: string) => void
  adornment?: string // pass a cuurency sign here
  label?: string // properly capitalized
  float?: boolean // whether should or not be allowed floats (default: false)
  classes?: Record<string, string>
  disabled?: boolean
}

const MoneyInputField: React.FC<IMoneyInputFieldProps> = props => {
  const { field, value, onChange, adornment, label, float, disabled } = props
  const classes = useStyles(props)

  const getAdditionalValues = React.useCallback(() => {
    const inputValue = value !== undefined ? { value: value } : {}
    const inputProps = adornment
      ? {
          InputProps: {
            startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
          },
        }
      : {}
    return { ...inputValue, ...inputProps }
  }, [value, adornment])

  return (
    <TextField
      disabled={disabled}
      onFocus={event => event.target.select()}
      className={classes.moneyTextField}
      label={label}
      type="text"
      name={label}
      margin="normal"
      variant="outlined"
      onChange={event =>
        onChange ? onChange(field, parseNumberFromInput(value, event.target.value, float)) : undefined
      }
      {...getAdditionalValues()}
    />
  )
}

export default MoneyInputField
