import { Box, Button, Grid } from '@material-ui/core'
import React from 'react'
import { useStyles } from '../styles'

import BusinessIcon from '../../clientDashboard/img/company-icon.png'
import { useHistory } from 'react-router'
import useRouter from 'use-react-router'

interface MissingItemCardProps {
  companyName: string
  stateName?: string
  id: number
  isCompany: boolean
  count?: number
}

const MissingItemCard = ({ stateName, companyName, id, isCompany, count }: MissingItemCardProps) => {
  const classes = useStyles()
  //   const router = useHistory()

  const { history } = useRouter()
  const handleReview = () => {
    history.push({
      pathname: `/client-missing-item/${id}/${isCompany ? 'company' : 'taxOrder'}`,
    })
  }
  return (
    <Box className={classes.missingItemContainer}>
      <Grid container>
        <Grid item xs={2} sm={1} md={2}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img width={40} src={BusinessIcon} />
          </div>
        </Grid>
        <Grid item xs={10} sm={11} md={10}>
          <div className="left-section">
            <p className="company-text">{companyName ?? ''}</p>
            <p className="warning-text">{count === 0 ? 'Submitted' : `${count} items need attention`}</p>
          </div>
          <div className="right-section">
            <p className="state-text">{stateName ?? ''}</p>

            <div className={classes.reviewBtnDiv}>
              {count !== 0 ? (
                <Button color="primary" variant="contained" className="review-btn" onClick={handleReview}>
                  Review
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </Grid>

        {/* <Grid item xs={7}> */}
        {/* <div className="left-section">
            <div>
              <img width={40} src={BusinessIcon} />
            </div>
            <div>
              <p className="company-text">{companyName ?? ''}</p>
              <p className="state-text">{stateName ?? ''}</p>
            </div>
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={5}> */}
        {/* <div className="right-section">
            <p className="warning-text">8 items need attention</p>
            <div className={classes.reviewBtnDiv}>
              <Button
                color="primary"
                variant="contained"
                className="review-btn"
                onClick={() => router.push(`/client-missing-item/${id}`)}
              >
                Review
              </Button>
            </div>
          </div> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  )
}

export default MissingItemCard
