import PrimaryButton from 'components/common/buttons/primaryButton'
import { useFormik } from 'formik'
import * as React from 'react'
import { FormField } from 'utils/FormGenerator/FormField'
import { Base, IRow } from 'utils/FormGenerator/formTypings'
import { useStyles } from 'utils/FormGenerator/styles'

type SetFieldValueFunctionType = (field: string, value: unknown) => void
interface IFormProps<ValuesType extends object> {
  initialValues: ValuesType
  fieldsData: IRow[]
  submitButtonLabel?: string
  onSubmit: (values: ValuesType) => void
}

// context for formik "onChange" function
export const SetFieldValueContext = React.createContext<SetFieldValueFunctionType>(() => {
  throw new Error('Method not initialized')
})

const Form = <ValuesType extends object>({
  initialValues,
  fieldsData,
  submitButtonLabel = 'Save',
  onSubmit,
}: IFormProps<ValuesType>) => {
  const classes = useStyles()

  const formik = useFormik({
    onSubmit: (values, _formikHelpers) => {
      onSubmit(values)
    },
    initialValues: initialValues,
  })

  /* Renders form rows */
  const renderForm = (values: ValuesType): React.ReactNode => {
    return fieldsData.map((value, key) => renderRow(key, value, values))
  }

  const renderRow = (key: number, formRow: IRow, values: ValuesType): React.ReactNode => (
    <div key={key} className={classes.fieldRowWrapper}>
      {/* Renders row fields */}
      {formRow.fields.map(field => wrapField(field, values))}
    </div>
  )

  const wrapField = (field: Base, values: ValuesType) => (
    // Complex key is used here cause field object does not have unique ID.
    <div key={JSON.stringify(field)} className={classes.fieldWrapper}>
      {FormField(field, values)}
    </div>
  )

  return (
    <form className={classes.container}>
      <SetFieldValueContext.Provider value={formik.setFieldValue}>
        {renderForm(formik.values)}
      </SetFieldValueContext.Provider>
      <PrimaryButton className={classes.saveButton} onClick={formik.submitForm}>
        {submitButtonLabel}
      </PrimaryButton>
    </form>
  )
}

export default Form
