import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import * as React from 'react'

export const secondaryColors = {
  main: '#E57F5A',
  light: '#3c9fdf',
}

export const fontColors = {
  primary: '#020202',
}

export const tableBackgroundColor = '#F6F6F8'

interface CustomPalette {
  // colors from design
  darkerBlue: string
  darkBlue: string
  primaryDark: string
  errorText: string
  errorButtonColor: string
  salesRepBackground: string
  tableBackgroundColor: string
  noteDefaultColor: string
  whiteBackgroundColor: string
  darkGray: string
  lightGray: string
  superLightGray: string
  primaryFontColor: string
  secondaryBackground: string
  paleGreyColor: string
  midBlueColor: string
  lightNavyColor: string
}

export const menuIconColor = '#788190'
export const selectedMenuIconColor = '#407ace'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    customPalette: CustomPalette
    submitButton: {
      backgroundColor: string
      color: string
      hover: {
        backgroundColor: string
        color: string
      }
    }
    login: {
      titleColor: string
    }
    button: {
      backgroundColor: string
      hover: {
        color: string
        backgroundColor: string
      }
    }
    inactiveStepText: React.CSSProperties
    inactiveStep: React.CSSProperties
    destructiveColor: string
    title: React.CSSProperties
    mediumPriority: React.CSSProperties
    highPriority: React.CSSProperties
    lowPriority: React.CSSProperties
  }
  // allow configuration using `createMuiTheme`

  interface ThemeOptions {
    customPalette: CustomPalette
    submitButton: {
      backgroundColor: string
      color: string
      hover: {
        backgroundColor: string
        color: string
      }
    }
    login: {
      titleColor: string
    }
    button: {
      backgroundColor: string
      hover: {
        color: string
        backgroundColor: string
      }
    }
    inactiveStepText: React.CSSProperties
    inactiveStep: React.CSSProperties
    destructiveColor: string
    title: React.CSSProperties
    mediumPriority: React.CSSProperties
    highPriority: React.CSSProperties
    lowPriority: React.CSSProperties
  }
}

const primaryFont = ['Roboto', 'sans-serif'].join(',')
// All the following keys are optional.
// We try our best to provide a great default value.
export const theme = createMuiTheme({
  customPalette: {
    darkerBlue: '#213161',
    darkBlue: '#24489e',
    primaryDark: '#217cb8',
    errorText: '#f44336',
    errorButtonColor: '#df3c3c',
    salesRepBackground: '#9185e1',
    tableBackgroundColor: tableBackgroundColor,
    noteDefaultColor: '#fdffdd',
    whiteBackgroundColor: '#F6F6F8',
    darkGray: '#5b5555',
    lightGray: '#7a7a7a',
    superLightGray: '#fafafa',
    primaryFontColor: fontColors.primary,
    secondaryBackground: '#e4e4e8',
    paleGreyColor: '#f6f6f7',
    midBlueColor: '#206a9a',
    lightNavyColor: '#18577f',
  },
  overrides: {
    MuiTableCell: {
      body: {
        fontSize: 14,
      },
      head: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 300,
        fontStyle: 'normal',
        letterSpacing: '0.1px',
        color: fontColors.primary,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: tableBackgroundColor,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontSize: '1rem',
      },
    },
  },
  palette: {
    primary: {
      main: '#3c9fdf',
    },
    secondary: secondaryColors,
    background: {},
    text: fontColors,
    grey: {
      200: '#ececee',
    },
  },
  typography: {
    fontFamily: primaryFont,
    body1: {
      fontFamily: 'Roboto, sans-serif',
    },
    h1: {
      fontFamily: 'Lato, Georgia, serif',
      fontSize: '1.5rem',
    },
  },
  submitButton: {
    backgroundColor: '#d8d8d8',
    color: '#666666',
    hover: {
      backgroundColor: '#141923',
      color: 'white',
    },
  },
  login: {
    titleColor: '#141923',
  },
  button: {
    backgroundColor: '#3c9fdf',
    hover: {
      color: 'white',
      backgroundColor: '#2479af',
    },
  },
  inactiveStepText: {
    color: '#666666',
  },
  inactiveStep: {
    border: 'solid 1px #a9a9a9',
    borderRadius: '50%',
  },
  destructiveColor: '#ff8b8b',
  title: {
    fontSize: '2rem',
    fontFamily: 'KazimirText',
    fontWeight: 600,
  },
  mediumPriority: {
    color: '#f4aa50',
  },
  highPriority: {
    color: '#ff1c1c',
  },
  lowPriority: {
    color: '#133476',
  },
})

export type Theme = typeof theme

const withTheme = <P extends object>(Component: React.ComponentType<P>) =>
  class WithTheme extends React.Component<P> {
    render() {
      return (
        <MuiThemeProvider theme={theme}>
          <Component {...this.props} />
        </MuiThemeProvider>
      )
    }
  }

export default withTheme
