import { Divider, Grid } from '@material-ui/core'
import React from 'react'
import SingleOwner from './singleOwner'

const CompanyOwners = ({ ownersList }) => {
  return (
    <Grid container className="owners-list-container">
      {ownersList?.map(owner => (
        <>
          <Grid item xs={12} md={6} className="owners-list-section">
            <SingleOwner
              ownerName={`${owner?.user?.first_name} ${owner?.user?.last_name}`}
              ownership={owner?.number_of_shares > 999 ? `${owner?.number_of_shares}` : `${owner?.number_of_shares}%`}
              positions={owner?.roles}
              ownershipText={owner?.number_of_shares > 999 ? 'Shares:' : 'OwnerShip'}
              // address={owner?.address?.address_line_1}
              address={`${owner?.address?.street || ''}, ${owner?.address?.city || ''}, ${owner?.address?.locality ||
                ''}, ${owner?.address?.zip || ''}`}
              phone={owner?.user?.phone_1}
              email={owner?.user?.email_1}
            />
          </Grid>
        </>
      ))}
    </Grid>
  )
}

export default CompanyOwners

// const arr = ['Managing Member', 'Primary Contact']
// const arr2 = ['Managing Member', 'Primary Contact', 'Member', 'Primary Contact', 'Ein Registrar', 'Registered Agent']

// const owners = [
//   {
//     ownerName: 'Alex Chernavsky ',
//     ownership: '50%',
//     positions: arr,
//     address: '121 N PALM DR, UNIT 15, BEVERLY HILS,CA 90210',
//     phone: '310-777-2539',
//     email: 'ALEX@businessrocket.com',
//   },
//   {
//     ownerName: 'Alex Chernavsky ',
//     ownership: '50%',
//     positions: arr2,
//     address: '121 N PALM DR, UNIT 15, BEVERLY HILS,CA 90210',
//     phone: '310-777-2539',
//     email: 'Maria@businessrocket.com',
//   },
// ]
