import { Checkbox, Link, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { getDocumentPDFDownload } from 'api/docs'
import { customFetchSuggestions } from 'api/suggestions'
import {
  markTaskAsDone,
  markTaskAsNotDone,
  markTasksAsDone,
  markTasksAsNotDone,
  updateTask,
  updateTasks,
} from 'api/task'
import classNames from 'classnames'
import OutlinedSelect from 'components/common/outlinedSelect'
import { AsyncSelectField } from 'components/common/select'
import { useStaff } from 'components/companies/hooks/useStaff'
import { ISuggestionAttribute } from 'components/interfaces'
import OrderSubtaskHeader, { SubtaskSelectionMenuItems } from 'components/orders/OrderSubtaskHeader'
import useUser from 'hooks/useUser'
import {
  colorForDocsStatus,
  docsStatusList,
  docStatusEnumToHumanReadable,
  docStatusHumanReadableToEnum,
  IDocsStatus,
  IHumanReadableDocsStatus,
  IOrderTask,
  ITask,
  TaskCompletion,
} from 'models/task'
import { IUser } from 'models/user'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { backendDateFormat, formatDateTimeToHumanFriendly, getCurrentTime } from 'utils/formatDate'
import { notEmpty } from 'utils/objectUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    titleAndButtonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    },
    linksWrapper: {
      display: 'flex',
    },
    subTaskWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    select: {
      width: '20%',
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 10,
    },
    editButton: {
      color: '#3c9fdf',
    },
    downloadButton: {
      color: '#3778a2',
      '&:visited': {
        color: '#3778a2',
      },
    },
    uploadDocsButton: {
      color: '#2eaa69',
    },
    subtaskWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    firstSubtaskRow: {
      border: 'solid 0.5px #979797',
      margin: '0 -25px 16px -25px',
    },
    notFirstSubtaskRow: {
      border: 'solid 0.5px #979797',
      margin: '16px -25px 16px -25px ',
    },
    subtaskDocStatus: {
      width: '30%',
    },
    selectedSubtasksDocStatus: {
      boxShadow: '0 9px 7px -5px rgba(0, 0, 0, 0.5)',
    },
    assigneeField: {
      width: '30%',
      marginRight: 20,
    },
    titleCellWrapper: {
      display: 'block',
      width: '40%',
    },
    titleCheckboxWrapper: {
      display: 'flex',
    },
  })
)

interface IOrderSubTaskProps {
  subTasks?: IOrderTask[]

  onUploadClick(subTask: ITask): void
  setTasks: React.Dispatch<React.SetStateAction<IOrderTask[]>>
}

const orderSubtaskMenuItems = ['All', 'None'].concat(docsStatusList)

const OrderSubTask: React.FC<IOrderSubTaskProps & RouteComponentProps> = props => {
  const classes = useStyles(props)
  const { subTasks, onUploadClick, history, setTasks } = props

  const [selectedSubTasks, setSelectedSubTasks] = React.useState<IOrderTask[]>([])

  const { user } = useUser()

  // recursively go through task and all subtasks and set `newStatus` to task with id=`taskId` if found
  // also, if the all subtasks for the parent task were completed, mark the parent task as completed
  const updateDocStatusIfNeeded = React.useCallback(
    (taskId: number, newStatus: IDocsStatus, task: IOrderTask): IOrderTask => {
      if (task.id === taskId) {
        return {
          ...task,
          doc_status: newStatus,
          completed: newStatus === 'sent_to_cpuc' ? getCurrentTime(backendDateFormat) : null,
        }
      }

      const newSubtasks =
        task.subtasks && task.subtasks.map(subtask => updateDocStatusIfNeeded(taskId, newStatus, subtask))

      let completed = task.completed
      if (!completed && newSubtasks) {
        const allSubtasksDone = newSubtasks && newSubtasks.map(subtask => subtask.completed).every(val => !!val)
        completed = allSubtasksDone ? getCurrentTime(backendDateFormat) : null
      }

      return {
        ...task,
        subtasks: newSubtasks,
        completed,
        completed_by: user || undefined,
      }
    },
    [user]
  )

  const updateTasksStateWithNewStatus = React.useCallback(
    (subtask: IOrderTask, status: IDocsStatus) => {
      setTasks(tasks =>
        tasks.map(t => (subtask.id ? updateDocStatusIfNeeded(subtask.id, status, t) : undefined)).filter(notEmpty)
      )
    },
    [setTasks, updateDocStatusIfNeeded]
  )

  const updateSubtasksStatus = React.useCallback(
    async (subtasks: IOrderTask[], status: IDocsStatus) => {
      const newTasks = subtasks.map(subtask => ({ id: subtask.id, doc_status: status }))
      await updateTasks(newTasks)

      const selectedTaskIds: TaskCompletion[] = subtasks
        .map(subtask => (subtask.id ? { id: subtask.id } : undefined))
        .filter(notEmpty)
      if (status === 'sent_to_cpuc') {
        await markTasksAsDone(selectedTaskIds)
      } else {
        await markTasksAsNotDone(selectedTaskIds)
      }

      subtasks.map(async subtask => {
        updateTasksStateWithNewStatus(subtask, status)
      })
    },
    [updateTasksStateWithNewStatus]
  )

  const updateSubtaskStatus = React.useCallback(
    async (subtask: IOrderTask, status: IDocsStatus) => {
      if (!subtask.id) return

      await updateTask(subtask.id, { doc_status: status })

      if (status === 'sent_to_cpuc') {
        await markTaskAsDone(subtask.id)
      } else {
        await markTaskAsNotDone(subtask.id)
      }

      updateTasksStateWithNewStatus(subtask, status)
    },
    [updateTasksStateWithNewStatus]
  )

  const onSubtaskStatusSelected = React.useCallback(
    async (value: IHumanReadableDocsStatus, subtask: IOrderTask) => {
      const status: IDocsStatus = docStatusHumanReadableToEnum(value)

      if (selectedSubTasks.length > 0) {
        updateSubtasksStatus(selectedSubTasks, status)
      } else {
        updateSubtaskStatus(subtask, status)
      }

      setSelectedSubTasks([])
    },
    [selectedSubTasks, updateSubtasksStatus, updateSubtaskStatus]
  )

  function handleSelectSubtaskClick(event: React.ChangeEvent<HTMLInputElement>, subTask: IOrderTask) {
    const selectedIndex = selectedSubTasks.indexOf(subTask)
    let newSelected: IOrderTask[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSubTasks, subTask)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSubTasks.slice(1))
    } else if (selectedIndex === selectedSubTasks.length - 1) {
      newSelected = newSelected.concat(selectedSubTasks.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSubTasks.slice(0, selectedIndex),
        selectedSubTasks.slice(selectedIndex + 1)
      )
    }

    setSelectedSubTasks(newSelected)
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked && subTasks) {
      setSelectedSubTasks(subTasks)
    } else {
      setSelectedSubTasks([])
    }
  }

  function handleMenuItemClick(menuItem: SubtaskSelectionMenuItems) {
    if (!subTasks) return
    if (menuItem === 'All') {
      setSelectedSubTasks(subTasks)
    } else if (menuItem === 'None') {
      setSelectedSubTasks([])
    } else {
      const selectedSubTasks = subTasks.filter(subTask => docStatusEnumToHumanReadable(subTask.doc_status) === menuItem)
      setSelectedSubTasks(selectedSubTasks)
    }
  }

  const isSubtaskSelected = React.useCallback(
    (subTask: IOrderTask) => {
      return selectedSubTasks.includes(subTask)
    },
    [selectedSubTasks]
  )

  const onEditFormClick = (subTask: IOrderTask) => {
    if (subTask.document) history.push(`doc/${subTask.document.id}`)
    else console.warn('Subtask does not have a document')
  }

  const getDownloadLink = (subTask: IOrderTask): string | undefined => {
    if (subTask.document) {
      return getDocumentPDFDownload(subTask.document.id)
    } else {
      console.warn("Subtask doesn't contain a document")
    }
  }

  const updateAssigneeInSelectedSubtasks = React.useCallback(
    (task: IOrderTask, assignee: IUser | null): IOrderTask => {
      if (!task.subtasks) {
        return task
      }

      const newSubtasks = task.subtasks.map(subtask =>
        isSubtaskSelected(subtask)
          ? {
              ...subtask,
              assigned_to: assignee?.id,
              assignee: assignee,
            }
          : subtask
      )

      const newTask = {
        ...task,
        subtasks: newSubtasks,
      }

      return newTask
    },
    [isSubtaskSelected]
  )

  const updateAssigneeInTasksSubtask = (task: IOrderTask, subtaskId: number, assignee: IUser | null) => {
    if (!task.subtasks) return task

    const newSubtasks = task.subtasks.map(subtask => {
      if (subtask.id !== subtaskId) {
        return subtask
      }

      return {
        ...subtask,
        assigned_to: assignee?.id,
        assignee: assignee,
      }
    })

    return {
      ...task,
      subtasks: newSubtasks,
    }
  }

  const updateSubtaskAssignee = React.useCallback(
    async (subtaskId: number, assignee: IUser | null) => {
      const newSubtask = await updateTask(subtaskId, {
        assigned_to: assignee?.id,
      })

      const parentTaskId = newSubtask.parent_id

      setTasks(tasks =>
        tasks.map(task => (task.id === parentTaskId ? updateAssigneeInTasksSubtask(task, subtaskId, assignee) : task))
      )
    },
    [setTasks]
  )

  const updateSubtasksAssignee = React.useCallback(
    async (subtasks: IOrderTask[], assignee: IUser | null) => {
      const newSubtasks = subtasks.map(subtask => ({
        id: subtask.id,
        assigned_to: assignee?.id,
      }))
      await updateTasks(newSubtasks)

      const parentTaskId = subtasks[0].parent_id
      if (!parentTaskId) return

      setTasks(tasks =>
        tasks.map(task => (task.id === parentTaskId ? updateAssigneeInSelectedSubtasks(task, assignee) : task))
      )
    },
    [setTasks, updateAssigneeInSelectedSubtasks]
  )

  const { staff } = useStaff()

  const handleAssigneeChange = React.useCallback(
    async (subtaskId: number, assigneeId: number | null) => {
      let newAssignee: IUser | null
      if (assigneeId) {
        const found: IUser | undefined = staff && staff.find(user => user.id === assigneeId)
        newAssignee = found || null
        if (!newAssignee || !newAssignee.id) return
      } else {
        newAssignee = null
      }

      if (selectedSubTasks.length === 0) {
        updateSubtaskAssignee(subtaskId, newAssignee)
      } else {
        updateSubtasksAssignee(selectedSubTasks, newAssignee)
      }

      setSelectedSubTasks([])
    },
    [staff, selectedSubTasks, updateSubtaskAssignee, updateSubtasksAssignee]
  )

  const fetchAssigneeSuggestions = async (query: string): Promise<ISuggestionAttribute[]> =>
    await customFetchSuggestions()(query, 'assigned_to')

  return (
    <div className={classes.root}>
      {subTasks && (
        <OrderSubtaskHeader
          onMenuItemClick={handleMenuItemClick}
          onChange={handleSelectAllClick}
          checked={selectedSubTasks.length === subTasks.length}
          menuItems={orderSubtaskMenuItems}
          indeterminate={selectedSubTasks.length > 0 && selectedSubTasks.length !== subTasks.length}
        />
      )}
      {subTasks &&
        subTasks.map((subTask, index) => {
          if (!subTask.id) return null

          const itemSelected = isSubtaskSelected(subTask)

          const assignee: ISuggestionAttribute | undefined =
            subTask.assigned_to && subTask.assignee
              ? {
                  value: subTask.assigned_to,
                  label: subTask.assignee.name,
                }
              : undefined

          return (
            <div key={`${subTask.id} ${subTask.assigned_to}`} className={classes.subtaskWrapper}>
              <div className={index === 0 ? classes.firstSubtaskRow : classes.notFirstSubtaskRow} />
              <div className={classes.subTaskWrapper}>
                <div className={classes.titleCellWrapper}>
                  <div className={classes.titleCheckboxWrapper}>
                    <Checkbox
                      checked={itemSelected}
                      onChange={event => handleSelectSubtaskClick(event, subTask)}
                      color="default"
                    />
                    <div className={classes.titleAndButtonsWrapper}>
                      <Typography>{subTask.title}</Typography>
                      {/* TODO: hidden until document actions are implemented */}
                      <div className={classes.linksWrapper} data-testid="subtask-actions" style={{ display: 'none' }}>
                        {subTask && subTask.doc_status === 'open' && (
                          <Link
                            className={`${classes.editButton} ${classes.link}`}
                            onClick={() => onEditFormClick(subTask)}
                          >
                            Edit
                          </Link>
                        )}
                        {((subTask && !subTask.doc_status) ||
                          subTask.doc_status === 'signed' ||
                          subTask.doc_status === 'sent_to_cpuc') && (
                          <Link
                            target={'_blank'}
                            href={getDownloadLink(subTask)}
                            download={true}
                            className={`${classes.downloadButton} ${classes.link}`}
                          >
                            Download
                          </Link>
                        )}
                        {subTask.doc_status === 'sent_to_client' && (
                          <Link
                            className={`${classes.uploadDocsButton} ${classes.link}`}
                            onClick={() => onUploadClick(subTask)}
                          >
                            Upload Docs
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>

                  {subTask.completed && (
                    <div>
                      <b>Completed by:</b> {(subTask.completed_by && subTask.completed_by.name) || 'unknown'}
                      <br />
                      at {formatDateTimeToHumanFriendly(subTask.completed)}
                    </div>
                  )}
                </div>

                <div className={classes.assigneeField}>
                  <AsyncSelectField
                    title="Assigned To"
                    fetchSuggestions={(query, _) => fetchAssigneeSuggestions(query)}
                    field="assignee_id"
                    onOptionSelected={option => subTask.id && handleAssigneeChange(subTask.id, option?.value || null)}
                    defaultValue={assignee}
                  />
                </div>
                <OutlinedSelect
                  classes={{
                    formControl: classNames(classes.subtaskDocStatus, {
                      [classes.selectedSubtasksDocStatus]: itemSelected,
                    }),
                  }}
                  name={subTask.doc_status ? '' : 'Status'}
                  value={docStatusEnumToHumanReadable(subTask.doc_status)}
                  options={docsStatusList}
                  onOptionSelect={value => onSubtaskStatusSelected(value as IHumanReadableDocsStatus, subTask)}
                  textFieldStyles={{ backgroundColor: subTask.doc_status && colorForDocsStatus(subTask.doc_status) }}
                  dataTestId="status-select"
                />
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default withRouter(OrderSubTask)
