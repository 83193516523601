import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { BusinessCenter, MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { IClient } from 'models/user'
import * as React from 'react'
import { IProductResponse } from 'api/subscription'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },

    primaryAddress: {
      marginTop: '0.25rem',
      '& p': {
        color: '#0000009c',
        fontSize: '12px',
        margin: '0',
        fontStyle: 'italic',
      },
    },
  })
)

interface ISubscriptionTableRowProps {
  product: IProductResponse
  onEditClick: () => void
  onDeleteClick: () => void
}

const SubscriptionTableRow: React.FC<ISubscriptionTableRowProps> = ({ product, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      <StyledTableRow onClick={onEditClick} data-testid={`${product.id}`} className={classes.row}>
        <StyledTableCell>{product.id || '- - -'}</StyledTableCell>
        <StyledTableCell>{product.name || '- - -'}</StyledTableCell>
        <StyledTableCell>
          {' '}
          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              onDeleteClick()
            }}
          >
            <DeleteIcon tooltipText="Delete client" />
          </TableActionButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default SubscriptionTableRow
