import Collapse from '@material-ui/core/Collapse'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import classnames from 'classnames'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { INoteEntitiesContext, NoteEntitiesContext } from 'components/common/notes/addNote'
import { GlobalNotesContext } from 'components/common/notes/globalNotesDrawer'
import SearchableNotes from 'components/common/notes/searchableNotes'
import { IOrderTask, ITask } from 'models/task'
import * as React from 'react'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import { toTitleCase } from 'utils/toTitleCase'
import TaskDetailsElement from './element'
import { List, ListSubheader, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core'
import { Subject } from '@material-ui/icons'
import { ITaskDescription, getTaskDescriptions } from 'api/task'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootCell: {
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 25,
    },
    expandedRoot: {
      paddingTop: 22,
      paddingBottom: 22,
      display: 'flex',
      justifyContent: 'space-between',
    },
    authorAndDateContainer: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },
    col: {
      //width: '50%',
      width: '100%',
    },
    col2: {
      // maxWidth: 590,
      width: '11rem',
    },
    createdInfo: {
      width: '45%',
    },
    link: {
      cursor: 'pointer',
    },
    notesTitle: {
      marginRight: 19,
      fontSize: 14,
    },
    notesTitleWrapper: {
      display: 'flex',
    },
    tableBody: {
      height: 200,
    },
    prevNotes: {
      '& .MuiListSubheader-root': {
        lineHeight: 'initial',
        marginBottom: '1rem',
        color: '#2a2a2a',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 400,
      },
      '& li': {
        padding: 0,
        marginBottom: 0,

        '& .MuiListItemIcon-root': {
          minWidth: 'auto',
          marginRight: '0.5rem',
          marginTop: '0.25rem',

          '& svg': {
            fontSize: '1rem',
          },
        },
        '& .MuiListItemText-root': {
          margin: 0,

          '& > p': {
            fontSize: '0.75rem',
          },
        },
      },
      '& .MuiDivider-root': {
        margin: '0.5rem 0',
      },
      // marginBottom: '1rem',
    },
  })
)

interface ITaskRowProps {
  task: ITask
  expanded: boolean
  goToDetailsPage?: any //(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>)=> void
}

const getShowNotesPrompt = (task: ITask): string => {
  if (task.task_type === 'task') {
    return 'Show All Task Notes'
  } else if (task.task_type === 'company') {
    return 'Show All Company Notes'
  } else if (task.task_type === 'client') {
    return 'Show All Client Notes'
  } else if (task.task_type === 'order') {
    return 'Show All Order Notes'
  }
  return 'Show All Notes'
}

const getNoteEntity = (task: ITask): INoteEntitiesContext => {
  const result: INoteEntitiesContext = {
    types: [task.task_type],
  }

  switch (task.task_type) {
    case 'task': {
      result.task = task.id
        ? {
            value: task.id,
            label: task.title,
          }
        : undefined
      break
    }

    case 'company': {
      result.company = task.company
        ? {
            value: task.company.id,
            label: task.company.name,
          }
        : undefined
      break
    }

    case 'client': {
      result.client = task.client?.id
        ? {
            value: task.client.id,
            label: task.client.name,
          }
        : undefined
      break
    }

    case 'order': {
      result.order = task.order?.id
        ? {
            value: task.order.id,
            label: task.order.order_type.name,
          }
        : undefined
      break
    }
  }

  return result
}

const TaskDetailsRow = ({ task, expanded, goToDetailsPage }: ITaskRowProps) => {
  const classes = useStyles()

  const globalNotesContext = React.useContext(GlobalNotesContext)

  const [taskDescription, settaskDescription] = React.useState<ITaskDescription[]>([])

  React.useEffect(() => {
    const getDescription = async (id: number) => {
      try {
        const res: ITaskDescription[] = await getTaskDescriptions(id)
        settaskDescription(res)
        console.log('resresres', res)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (task?.id) {
      getDescription(task?.id)
    }
  }, [task?.id])

  return (
    <React.Fragment>
      <TableRow data-testid={`task-${task.id}-details-row`}>
        <TableCell className={classnames(classes.rootCell)} colSpan={7}>
          <Collapse in={expanded}>
            <div className={classes.expandedRoot}>
              <div className={classes.col}>
                {taskDescription?.length ? (
                  <List
                    className={classes.prevNotes}
                    subheader={<ListSubheader component="div">Notes History</ListSubheader>}
                  >
                    {taskDescription?.map((item: ITaskDescription) => (
                      <>
                        <ListItem alignItems="flex-start" key={item?.id}>
                          <ListItemIcon>
                            <Subject />
                          </ListItemIcon>
                          <ListItemText
                            primary={item?.note_text}
                            secondary={
                              <React.Fragment>
                                {item?.created_by}&nbsp;
                                {item?.created_at ? formatDateTimeToHumanFriendly(item?.created_at) : ''}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider component="li" />
                      </>
                    ))}
                  </List>
                ) : (
                  ''
                )}

                {/* <TaskDetailsElement title="Description" text={task.description} /> */}
                <div className={classes.authorAndDateContainer}>
                  <TaskDetailsElement
                    className={classes.createdInfo}
                    title="Created By"
                    text={(task.created_by && task.created_by.toString()) || 'unknown'}
                  />

                  <TaskDetailsElement
                    className={classes.createdInfo}
                    title="Creation Date"
                    text={task.created_at && formatDateToHumanFriendly(task.created_at)}
                  />
                  <TaskDetailsElement
                    className={classes.createdInfo}
                    title="Assigned By"
                    text={(task.assigned_by && task.assigned_by) || ''}
                  />
                  <TaskDetailsElement
                    className={classes.createdInfo}
                    title="Assigned to"
                    text={(task.assignee?.name && task.assignee?.name) || ''}
                  />
                  <TaskDetailsElement
                    className={classes.createdInfo}
                    title="Assignment Date"
                    text={task.assigned_at && formatDateToHumanFriendly(task.assigned_at)}
                  />
                </div>
              </div>
              <div className={classnames(classes.col, classes.col2)}>
                {task.task_type !== 'general' && task.task_type !== 'compliance' ? (
                  <ButtonWithLoading onClick={goToDetailsPage}>{toTitleCase(task.task_type)} Details</ButtonWithLoading>
                ) : (
                  ''
                )}

                {expanded && (
                  <NoteEntitiesContext.Provider value={getNoteEntity(task)}>
                    <SearchableNotes
                      headerAccessoryView={
                        <Link
                          className={classes.link}
                          onClick={() => {
                            globalNotesContext.setNoteEntities(getNoteEntity(task))
                          }}
                        >
                          {getShowNotesPrompt(task)}
                        </Link>
                      }
                      tableBodyClassName={classes.tableBody}
                    />
                  </NoteEntitiesContext.Provider>
                )}
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default TaskDetailsRow
