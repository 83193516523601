import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import snakeCaseKeys from 'snakecase-keys'

export interface ITaxWidgets {
  id?: number
  title?: string
  order_type?: string[] | null
  tax_structure?: string[] | null
  tax_year?: number[] | null
  order_stage?: string[] | null
  accountant_user_id?: number[]
  service_type?: string[] | null
  tax_associate?: number[]
  tax_controller?: number[]
  senior_cpa?: number[]
  question_status?: string[] | null
  order_status?: string[] | null
  labels?: number[] | null
}
export interface SalectList {
  value: number | string
  label: string
  color?: string
}
export interface IGetTaxWidget {
  id: number
  title: string
}
export interface ITaxWidgetsReturnType {
  id: number
  title?: string
  order_type: SalectList[]
  tax_structure?: SalectList[]
  tax_year?: SalectList[]
  order_stage?: SalectList[]
  accountant_user_id: SalectList[]
  service_type?: SalectList[]
  tax_associate?: SalectList[]
  tax_controller?: SalectList[]
  senior_cpa?: SalectList[]
  question_status?: SalectList[]
  order_status?: SalectList[]
  labels?: SalectList[]
}

export interface ITaxWidgetDashboard {
  id?: number
  count: number
  oldest_order: string | undefined
  custom: boolean
  custom_widget: number
  title: string | undefined
  type?: 'company' | 'tax'
  twentyOneDayAlertOrders?: number
  twenty_one_day_alert?: boolean
}
export interface IArchiveTaxCompanies {
  custom_widget: number
  company_id: number
}
export interface IDashboradWidget {
  custom_widget: number
  custom: boolean
  id: number
  archived?: boolean
  twenty_one_day_alert?: boolean
}

export interface ICreateTaxWidget {
  custom_widget?: number
  company_id?: number
  note_text?: string
}

export const getTaxWidges = () => {
  // apiClient.defaults.baseURL = 'http://192.168.0.121:3000/'
  return requestPaginated<IGetTaxWidget[]>({ url: '/taxwidgets' })
}
export const getAllTaxWidge = async (query = ''): Promise<IGetTaxWidget[]> => {
  return (await apiClient.get(`taxwidgets`, { params: { page: 1, page_size: 100, query, sort_order: 'desc' } })).data
}
export const createTaxWidget = async (taxWidget: any): Promise<AxiosResponse<void>> => {
  return (await apiClient.post('taxwidgets', snakeCaseKeys(taxWidget))).data
}
export const updateTaxWidget = async (taxWidgetId: number, taxWidget: any): Promise<AxiosResponse<void>> => {
  return (await apiClient.patch(`taxwidgets/${taxWidgetId}`, snakeCaseKeys(taxWidget))).data
}
export const getTaxWidgetById = async (taxWidgetId: number): Promise<ITaxWidgetsReturnType> => {
  return (await apiClient.get(`taxwidgets/${taxWidgetId}`)).data
}
export const deleteTaxWidgetById = async (taxWidgetId: number): Promise<AxiosResponse<void>> => {
  return (await apiClient.delete(`taxwidgets/${taxWidgetId}`)).data
}

export const getTaxWidgetByEmpId = async (empId: number): Promise<ITaxWidgetsReturnType> => {
  return (await apiClient.get(`employees/${empId}/taxwidgets`)).data
}

export const getDashboardTaxWidget = async (): Promise<ITaxWidgetDashboard[]> => {
  return (await apiClient.get(`dashboard/tax-widgets`)).data
}
export const getCustomTaxWidges = (id: number) => {
  return requestPaginated<IGetTaxWidget[]>({ url: `/custom-taxwidget/${id}` })
}
export const archiveTaxCompaines = async (payload: IArchiveTaxCompanies): Promise<AxiosResponse<void>> => {
  return (await apiClient.post('/archive-tax-companies', snakeCaseKeys(payload))).data
}

export const getTaxTaxWidgeDetail = requestPaginated<any[]>({
  url: `/dashboard-taxwidget-detail`,
})
export const getTaxWidgetNotes = async (customerWidget: number, companyId: number): Promise<any[]> => {
  return (await apiClient.get(`/taxwidgets-notes?custom_widget=${customerWidget}&company_id=${companyId}`)).data
}
export const createTaxWigetNote = async (note: ICreateTaxWidget): Promise<AxiosResponse<void>> =>
  (await apiClient.post(`/tax-widget-notes`, note)).data
