import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { CompanyOrderContext } from 'components/orders/CompanyOrderContext'
import CreateOrder from 'components/orders/create'
import OrderTable from 'components/orders/table'
import CompanyOrderConfigDialog from 'components/tasks/companyOrderConfigDialog'
import { useHandleDialogAccessibility } from 'hooks/useHandleDialogAccessibility'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    createOrderButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '10px 0',
    },
  })
)

interface ICompanyOrders {
  /**
   * Company for which we're gonna display the orders.
   */
  companyId: number
  /**
   * If this parameter is supplied, `CompanyOrderConfigDialog` will be opened immediately.
   * Use case: it is supplied if on step 1 of company creation form we've checked `New Company (Add Formation Order)` button for creating `Business Formation Order`, and we require on step 5 `CompanyOrderConfigDialog` to be opened immediately.
   */
  formationOrderId?: number
  isFormationOrder?: boolean
}

/**
 * We wanna be able to get a table representation of company orders.
 *
 * Used on step 5 of company creation, when adding initial orders.
 * Also used on a separate `Company Orders` page dedicated to this component solely.
 */
const CompanyOrders: React.FC<ICompanyOrders> = props => {
  const { companyId, formationOrderId, isFormationOrder } = props
  const classes = useStyles(props)
  const [reloadTable, setReloadTable] = React.useState(false)
  const { isOpen, handleOpen, handleSave, handleClose } = useHandleDialogAccessibility({
    saveSideEffect: () => setReloadTable(true),
  })

  React.useEffect(() => {
    formationOrderId && handleOpen()
    // isFormationOrder && handleOpen()
    // //console.log(isFormationOrder);
    // handleOpen()
  }, [formationOrderId, isFormationOrder, handleOpen])

  return (
    <>
      {formationOrderId && (
        <CompanyOrderConfigDialog
          onClose={handleClose}
          onSave={handleSave}
          companyOrderId={formationOrderId}
          open={isOpen}
          isFormationOrder={isFormationOrder}
        />
      )}
      {/* {isFormationOrder && (
        <CompanyOrderConfigDialog
          onClose={handleClose}
          onSave={handleSave}
          companyOrderId={0}
          open={isOpen}
          isFormationOrder={isFormationOrder}
        />
      )} */}
      <div className={classes.root}>
        <CompanyOrderContext.Provider value={{ reloadTable: reloadTable, setReloadTable: setReloadTable }}>
          <div className={classes.createOrderButtonWrapper}>
            <CreateOrder
              companyId={companyId}
              hideCompanySelect
              isCompanyCreation
              isFormationOrder={isFormationOrder}
            />
          </div>
          <OrderTable isCompanyCreationFlow disableSearch orderSearchOptions={{ company_id: companyId }} />
        </CompanyOrderContext.Provider>
      </div>
    </>
  )
}

export default CompanyOrders
