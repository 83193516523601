import { notify } from '@jetkit/react'
import { AxiosError } from 'axios'

const showApiResponseError = (err: AxiosError, message: string) => {
  if (err.response && err.response.data && err.response.data.message) {
    notify.warning(`${message} ${err.response.data.message}`)
  } else {
    notify.warning(message)
  }
  console.error(err)
}

export default showApiResponseError
