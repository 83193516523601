// eslint-disable-next-line max-lines
import {
  Avatar,
  Card,
  CardContent,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  TableBody,
  TextField,
  Table,
  FormControlLabel,
  TableContainer,
  Paper,
} from '@material-ui/core'
import { Assignment, AttachMoney, ThumbUp } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React from 'react'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { skeletons } from 'components/common/table'
import useSortTable from 'hooks/useSortTable'
import {
  fetchWebSubmissions,
  getWebSubmissionFilters,
  submissionFiltersSummary,
  SubmissionSummaryData,
  WebSubmissionsData,
  deleteWebSubmission,
} from 'api/webSubmission'
import { OrderStepNewIds, RESIDENT_MAPPING, WEB_SUBMISSION_TYPE } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { checkNullValue, getPaymentDropdownVal } from 'utils/functions'
import WebSubmissionHead from '../webSubmissionTable/webSubmissionHead'
import WebSubmissionRow from '../webSubmissionTable/webSubmissionRow'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useStyles } from './style'
import moment, { Moment } from 'moment'
import IOSSwitch from 'components/common/iosSwitch'
import { updatePageVisitsCount } from 'utils/user'
import { getstates } from 'api/addresses'

interface FilterType {
  label: string
  value: number | string
}

const WebSubmissionReport = () => {
  const classes = useStyles()
  const [startDateRange, setStartDateRange] = React.useState<Moment>(moment())
  const [endDateRange, setEndDateRange] = React.useState<Moment>(moment())
  const [query, setQuery] = React.useState('')

  const [filtersList, setFiltersList] = React.useState<any>()

  const [statusValue, setStatusValue] = React.useState<FilterType | null>(null)
  const [refCodeValue, setRefCodeValue] = React.useState<string>('')
  const [utmSourceValue, setUtmSourceValue] = React.useState<string>('')
  const [utmMediumValue, setUtmMediumValue] = React.useState<string>('')
  const [utmCampaignValue, setUtmCampaignValue] = React.useState<string>('')
  const [utmContentValue, setUtmContentValue] = React.useState<string>('')
  const [bankOfferValue, setBankOfferValue] = React.useState<string>('')
  const [offerAcceptValue, setOfferAcceptValue] = React.useState<FilterType | null>(null)
  const [orderTypeValue, setOrderTypeValue] = React.useState<FilterType | null>(null)
  const [stateOfFormation, setStateOfFormation] = React.useState<FilterType | null>(null)
  const [paymentTypeValue, setPaymentTypeValue] = React.useState<FilterType | null>(null)
  const [clidsValue, setClidsValue] = React.useState<string>('')

  const [formVersionValue, setFormVersionValue] = React.useState<FilterType | null>(null)
  const [packageId, setPackageId] = React.useState<FilterType | null>(null)
  const [resident, setResident] = React.useState<FilterType | null>(null)

  const [webSubmissionToDelete, setWebSubmissionToDelete] = React.useState<WebSubmissionsData | undefined>()
  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [webArchiveIsLoading, setWebArchiveIsLoading] = React.useState(false)

  const [isGCLID, setIsGCLID] = React.useState(false)
  const [formCompletion, setFormCompletion] = React.useState(false)

  const [submissionSummary, setSubmissionSummary] = React.useState<SubmissionSummaryData>()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_date' })

  const { order, sort_by } = sortSettings

  const type = WEB_SUBMISSION_TYPE?.NotArchive

  const memoApiCall = React.useMemo(
    () =>
      fetchWebSubmissions(
        type,
        query,
        utmMediumValue,
        utmSourceValue,
        utmCampaignValue,
        utmContentValue,
        refCodeValue,
        bankOfferValue,
        offerAcceptValue?.value,
        statusValue?.value,
        orderTypeValue?.value,
        paymentTypeValue?.value,
        startDateRange?.format('YYYY-MM-DD 00:00 Z'),
        endDateRange?.format('YYYY-MM-DD 23:59 Z'),
        isGCLID,
        formCompletion,
        clidsValue,
        formVersionValue?.value,
        stateOfFormation?.value,
        packageId?.value,
        resident?.value
        // formatDateToHumanFriendly(startDateRange),
        // formatDateToHumanFriendly(endDateRange)
      )({ sort_order: order, sort_by }),
    [
      bankOfferValue,
      endDateRange,
      offerAcceptValue?.value,
      order,
      isGCLID,
      orderTypeValue?.value,
      paymentTypeValue?.value,
      query,
      refCodeValue,
      sort_by,
      startDateRange,
      statusValue?.value,
      type,
      utmCampaignValue,
      utmContentValue,
      utmMediumValue,
      utmSourceValue,
      formCompletion,
      clidsValue,
      formVersionValue?.value,
      stateOfFormation?.value,
      packageId?.value,
      resident?.value,
    ]
  )

  const pagedTable = usePagedTable<WebSubmissionsData>({
    apiCall: memoApiCall,
  })

  const tableHead = React.useMemo(
    () => <WebSubmissionHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleEditWebSubmissionClick = React.useCallback((webSubmission: WebSubmissionsData) => {
    window.open(`/web-submission/${webSubmission?.order_id}`, '_blank')
  }, [])

  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  const handleDeleteWebSubmissionClick = React.useCallback((webSubmission: WebSubmissionsData) => {
    setWebSubmissionToDelete(webSubmission)
    setDeleteItemDialogShown(true)
  }, [])

  const handleArchive = async (id: number) => {
    if (!id) {
      return
    }
    setWebArchiveIsLoading(true)
    try {
      await deleteWebSubmission(id)
      showSuccessNotification('Successfully Archive')
      handleSuccessfulRequest()
      setWebArchiveIsLoading(false)
    } catch (error) {
      showApiResponseError(error, 'error')
    }
    setWebArchiveIsLoading(false)

    setDeleteItemDialogShown(false)
  }

  const handleStartDateChange = date => {
    setStartDateRange(date)
  }
  const handleEndDateChange = date => {
    setEndDateRange(date)
  }

  React.useEffect(() => {
    const getFilters = async () => {
      try {
        const result = await getWebSubmissionFilters(
          startDateRange?.format('YYYY-MM-DD 00:00 Z'),
          endDateRange?.format('YYYY-MM-DD 23:59 Z')
        )
        setFiltersList(result)
      } catch (error) {
        showApiResponseError(error, 'Error getting filter data')
      }
    }
    getFilters()
  }, [startDateRange, endDateRange])

  React.useEffect(() => {
    const submissionSummary = async () => {
      try {
        const result = await submissionFiltersSummary(
          type,
          utmMediumValue,
          utmSourceValue,
          utmCampaignValue,
          utmContentValue,
          refCodeValue,
          bankOfferValue,
          offerAcceptValue?.value,
          statusValue?.value,
          orderTypeValue?.value,
          paymentTypeValue?.value,
          startDateRange?.format('YYYY-MM-DD 00:00 Z'),
          endDateRange?.format('YYYY-MM-DD 23:59 Z'),
          isGCLID,
          formCompletion,
          clidsValue,
          formVersionValue?.value,
          stateOfFormation?.value,
          packageId?.value,
          resident?.value
          // formatDateToHumanFriendly(startDateRange),
          // formatDateToHumanFriendly(endDateRange)
        )
        setSubmissionSummary(result)
      } catch (error) {
        showApiResponseError(error, 'Error getting filter data')
      }
    }
    submissionSummary()
  }, [
    bankOfferValue,
    endDateRange,
    isGCLID,
    offerAcceptValue?.value,
    orderTypeValue?.value,
    paymentTypeValue?.value,
    refCodeValue,
    startDateRange,
    statusValue?.value,
    type,
    utmCampaignValue,
    utmContentValue,
    utmMediumValue,
    utmSourceValue,
    formCompletion,
    clidsValue,
    formVersionValue?.value,
    stateOfFormation,
    packageId?.value,
    resident?.value,
  ])
  const [states, setStates] = React.useState([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const stateOptions = () => {
    return states?.map((state: any) => ({
      value: state?.id,
      label: state?.state_display_name,
    }))
  }
  React.useEffect(() => {
    const states = async () => {
      setLoading(true)

      const state = await getstates()
      setStates(state)
      setLoading(false)
    }
    states()
  }, [])
  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <p className={classes.tableTitle}>Reports</p>
        </div>

        <div className={classes.datePickerFiled}>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={startDateRange}
              onChange={handleStartDateChange}
              label="Start Date"
              variant="inline"
              inputVariant="outlined"
              autoOk
            />
          </FormControl>
          <span>to</span>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              className="select-filed"
              format="MM/DD/yyyy"
              value={endDateRange}
              onChange={handleEndDateChange}
              label="End Date"
              variant="inline"
              inputVariant="outlined"
              autoOk
            />
          </FormControl>
          {/* <FormControl variant="outlined" className={classes.selectItem}>
          <InputLabel>State of Formation</InputLabel>
          <Select
            // onChange={e => changeClientField('state_id', e.target.value as number)}
            label="State"
            name="stateOfFormation"
            value={client.state_id}
            fullWidth
            disabled={editedClient?.id ? true : false}
          >
            {stateOptions()?.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
          {/* <Grid item xs={3}>
            <Autocomplete
              className={classes.stateOfFormationField}
              id="State of Formation"
              options={stateOptions()}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setStateOfFormation(newValue)
              }}
              renderInput={params => <TextField {...params} label="State of Formation" variant="outlined" />}
            />
          </Grid> */}
        </div>
        <Grid container spacing={3}>
          {/* <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              className={classes.stateOfFormationField}
              id="State of Formation"
              options={stateOptions()}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setStateOfFormation(newValue)
              }}
              renderInput={params => <TextField {...params} label="State of Formation" variant="outlined" />}
            />
          </Grid> */}

          {/* <div className={classes.datePickerFiled}>
          <span>to</span> */}

          {/* <FormControl variant="outlined" className={classes.selectItem}>
          <InputLabel>State of Formation</InputLabel>
          <Select
            // onChange={e => changeClientField('state_id', e.target.value as number)}
            label="State"
            name="stateOfFormation"
            value={client.state_id}
            fullWidth
            disabled={editedClient?.id ? true : false}
          >
            {stateOptions()?.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
          {/* <Grid item lg={3} md={4} xs={12}></Grid> */}
          {/* </div> */}
          {/* <Grid container spacing={3}> */}
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              className={classes.stateOfFormationField}
              id="State of Formation"
              options={stateOptions()}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setStateOfFormation(newValue)
              }}
              renderInput={params => <TextField {...params} label="State of Formation" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="orderTypeValue"
              options={orderType}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setOrderTypeValue(newValue)
              }}
              renderInput={params => <TextField {...params} label="Order Type" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="statusValue"
              options={statusList}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setStatusValue(newValue)
              }}
              renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="paymentTypeValue"
              options={getPaymentDropdownVal()}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: any) => {
                setPaymentTypeValue(newValue)
              }}
              renderInput={params => <TextField {...params} label="Payment Status" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="refCodeValue"
              options={filtersList?.ref_code ? (filtersList?.ref_code as string[]) : []}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setRefCodeValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="Ref Codes" variant="outlined" />}
              renderOption={option => <b>{option}</b>}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="utmSourceValue"
              options={filtersList?.utm_source ? (filtersList?.utm_source as string[]) : []}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setUtmSourceValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="UTM Source" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="utmMediumValue"
              options={filtersList?.utm_medium ? (filtersList?.utm_medium as string[]) : []}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setUtmMediumValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="UTM Medium" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="utmCampaignValue"
              options={filtersList?.utm_campaign ? (filtersList?.utm_campaign as string[]) : []}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setUtmCampaignValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="UTM Campaign" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="utmContentValue"
              options={filtersList?.utm_content ? (filtersList?.utm_content as string[]) : []}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setUtmContentValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="UTM Content" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="bankOfferValue"
              options={bankOffer as string[]}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setBankOfferValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="Bank Offer" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="offerAcceptValue"
              options={offerAccept}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setOfferAcceptValue(newValue)
              }}
              renderInput={params => <TextField {...params} label="Offer Accept" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="clidsValue"
              options={clids as string[]}
              getOptionLabel={option => option}
              onInputChange={(event, newInputValue) => {
                setClidsValue(newInputValue)
              }}
              renderInput={params => <TextField {...params} label="CLIDS" variant="outlined" />}
            />
          </Grid>
          {/* <Grid item lg={3} md={4} xs={12} style={{ alignSelf: 'center' }}>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={isGCLID}
                onClick={event => {
                  event.stopPropagation()
                }}
                onChange={e => {
                  setIsGCLID(!isGCLID)
                  // toggleStatus(client.id, e.target.checked)
                }}
                name="gclid"
              />
            }
            label=""
          />
          <label>No GCLID</label>
        </Grid> */}
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="formVersionValue"
              options={formVersion}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setFormVersionValue(newValue)
              }}
              renderInput={params => <TextField {...params} label="Form Version" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="packageId"
              options={packageNumber}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setPackageId(newValue)
              }}
              renderInput={params => <TextField {...params} label="Package Name" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Autocomplete
              id="packageId"
              options={RESIDENT}
              getOptionLabel={option => option?.label}
              onChange={(event: any, newValue: FilterType | null) => {
                setResident(newValue)
              }}
              renderInput={params => <TextField {...params} label="Resident" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12} style={{ alignSelf: 'center' }}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={formCompletion}
                  onClick={event => {
                    event.stopPropagation()
                  }}
                  onChange={e => {
                    setFormCompletion(!formCompletion)
                  }}
                  name="formCompletion"
                />
              }
              label=""
            />
            <label>Selected Form Completion</label>
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
          {/* <Grid container spacing={1} className={classes.SummaryContainer}> */}
          <Grid item xs={12}>
            <p className="title-text">Total Summary</p>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Card className={classes.cardContainer}>
              <CardContent className={classes.cardHeaderInner}>
                <div>
                  <p>TOTAL SUBMISSION</p>
                  <h1>{submissionSummary?.total_count ?? '0'}</h1>
                </div>
                <Avatar className={classes.blue}>
                  <Assignment />
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Card className={classes.cardContainer}>
              <CardContent className={classes.cardHeaderInner}>
                <div>
                  <p>TOTAL PAID</p>
                  <h1>{submissionSummary?.paid_count ?? '0'}</h1>
                </div>
                <Avatar className={classes.green}>
                  <AttachMoney />
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Card className={classes.cardContainer}>
              <CardContent className={classes.cardHeaderInner}>
                <div>
                  <p>TOTAL PAID OFFLINE</p>
                  <h1>{submissionSummary?.offlinePaid_count ?? '0'}</h1>
                </div>
                <Avatar className={classes.green}>
                  <AttachMoney />
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Card className={classes.cardContainer}>
              <CardContent className={classes.cardHeaderInner}>
                <div>
                  <p>SUCCESS RATE</p>
                  <h1>{submissionSummary?.success_rate ? `${submissionSummary?.success_rate}%` : '0%'}</h1>
                </div>
                <Avatar className={classes.red}>
                  <ThumbUp />
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.titleText}>Submission Table</p>
            <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />
          </Grid>
        </Grid>

        <ModalWrapper
          open={deleteItemDialogShown}
          setOpen={handleDeleteItemsDialogCloseClick}
          heading="Are you sure you want to archive?"
        >
          <h3>{`${checkNullValue(webSubmissionToDelete?.first_name)} ${checkNullValue(
            webSubmissionToDelete?.company_name
          )}`}</h3>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonWithLoading
              onClick={() => handleArchive(webSubmissionToDelete?.order_id || 0)}
              isLoading={webArchiveIsLoading}
            >
              Archive WebSubmission
            </ButtonWithLoading>
          </div>
        </ModalWrapper>
      </div>
      <div className={classes.tableSection}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <TableContainer component={Paper}>
            <Table>
              {tableHead}
              <TableBody>
                {skeletons({
                  numberOfColumns: 10,
                  sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
                  numberOfRows: 6,
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <PagedTable
              colSpan={8}
              emptyRowText="No WebSubmissions"
              {...pagedTable.renderProps}
              renderRow={(webSubmission: WebSubmissionsData) => (
                <WebSubmissionRow
                  webSubmissions={webSubmission}
                  onEditClick={() => handleEditWebSubmissionClick(webSubmission)}
                  onDeleteClick={() => handleDeleteWebSubmissionClick(webSubmission)}
                />
              )}
              header={tableHead}
            />
          </TableContainer>
        )}
      </div>
    </>
  )
}

const statusList = [
  { value: 1, label: 'State of Formation' },
  { value: 2, label: 'Contact Information' },
  { value: 3, label: 'Business Activity' },
  { value: 4, label: 'Business Address' },
  { value: 5, label: 'IPostal' },

  { value: 6, label: 'Ownership' },
  { value: 9, label: 'Gold Standard' },
  { value: 8, label: 'Offer Unlocked' },
  { value: 7, label: 'Packages' },
  { value: 10, label: 'Checkout' },
  { value: 11, label: 'Payment' },
  { value: 12, label: 'Thank You' },
  { value: 51, label: 'Agreement' },
  { value: 52, label: 'Bank Account' },
  { value: OrderStepNewIds.StepRush, label: 'Rush Processing' },
  { value: OrderStepNewIds.StepEIN, label: 'Ein' },
  { value: OrderStepNewIds.StepRocket, label: 'Rocket Compliance' },
]

const bankOffer = ['Chase', 'Bluevine']

const clids = ['NONE', 'GCLID', 'FBCLID', 'MSCLKID']

const offerAccept = [
  { value: -1, label: 'TBD' },
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' },
]

const orderType = [
  { value: 0, label: 'Corporation' },
  { value: 1, label: 'LLC' },
  { value: 2, label: 'DBA' },
  { value: 3, label: 'Non Profit' },
  { value: 4, label: 'Sole Proprietorship' },
  { value: 5, label: 'Partnership' },
]

const formVersion = [
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
]

const packageNumber = [
  { value: 1, label: 'Starter' },
  { value: 2, label: 'Essentials' },
  { value: 3, label: 'Supreme' },
]
export const RESIDENT = [
  { value: 'non_us_resident', label: RESIDENT_MAPPING.non_us_resident },
  { value: 'us_resident', label: RESIDENT_MAPPING.us_resident },
]

export default WebSubmissionReport
