import * as React from 'react'
import useCompanyOwners from 'components/companies/hooks/companyOwners'
import { deleteOwner, createOwner, getCompanyRoles } from 'api/companies'
import showApiResponseError from 'utils/showApiResponseError'
import { ICompanyRole, IDetailedCompanyResponse } from 'models/company'
import CompanyOwnersCreation from 'components/companies/companyOwners'

interface ICompanyOwnersProps {
  company: IDetailedCompanyResponse
}

// Wrapper component for CompanyOwnersCreation component with less number of props
const CompanyOwners = ({ company }: ICompanyOwnersProps) => {
  const {
    ownerFields,
    companyOwners,
    companyRoles,
    setCompanyRoles,
    fetchAndSetCompanyOwners,
    clearOwnerState,
    toggleRole,
    takenShares,
    selectAllRoles,
    allRolesSelected,
    onOwnerFieldsChange,
    onOwnerEdit,
  } = useCompanyOwners({ company })

  React.useEffect(() => {
    fetchAndSetCompanyOwners(company.id)
  }, [company, fetchAndSetCompanyOwners])

  const onOwnerDelete = React.useCallback(
    async (userId: number) => {
      await deleteOwner(company.id, userId)
      fetchAndSetCompanyOwners(company.id)
    },
    [company, fetchAndSetCompanyOwners]
  )

  const saveOwner = React.useCallback(async () => {
    try {
      await createOwner(ownerFields, company.id)
      await fetchAndSetCompanyOwners(company.id)
    } catch (err) {
      showApiResponseError(err, 'Could not create an owner')
    }
  }, [company, fetchAndSetCompanyOwners, ownerFields])

  const onAddOwnerButtonClick = React.useCallback(async () => {
    saveOwner()
    clearOwnerState()
  }, [clearOwnerState, saveOwner])

  const fetchCompanyRoles = React.useCallback(async () => {
    try {
      const res: ICompanyRole[] = await getCompanyRoles(company.id)
      setCompanyRoles(res)
      return res
    } catch (err) {
      showApiResponseError(err, 'Failed to fetch company roles')
    }
  }, [company, setCompanyRoles])

  return (
    <CompanyOwnersCreation
      selectAllRoles={selectAllRoles}
      allRolesSelected={allRolesSelected}
      onOwnerFieldsChange={onOwnerFieldsChange}
      fetchCompanyRoles={fetchCompanyRoles}
      company={company}
      roles={companyRoles}
      values={ownerFields}
      hideEmptyOwnersTable
      toggleRole={toggleRole}
      onAddOwnerButtonClick={onAddOwnerButtonClick}
      companyOwners={companyOwners}
      onOwnerEdit={onOwnerEdit}
      onOwnerDelete={onOwnerDelete}
      clearOwnerState={clearOwnerState}
      takenShares={takenShares}
    />
  )
}

export default CompanyOwners
