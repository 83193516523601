import { Divider } from '@material-ui/core'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import React from 'react'
import NotificationTabs from './notificationTabs'
import { useStyles } from './styles'

const ClientCorrespondence = () => {
  const classes = useStyles()
  React.useEffect(() => {
    // updatePageVisitsCount()
  }, [])
  return (
    <div className={classes.clientCorrespondence}>
      <h1 className="main-heading">Company Documents</h1>
      <Divider />
      <div className="document-vault-section">
        <HeadingHeader title="Document Vault" />
      </div>
      <NotificationTabs />
    </div>
  )
}

export default ClientCorrespondence
