/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  Button,
  MenuItem,
  Grid,
  Select,
  FormControl,
  TextField,
  TableFooter,
  TablePagination,
  Dialog,
  TableContainer,
  Paper,
  Chip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlineSharp'

import SelectField from '../selectField'
import {
  CancelOutlined,
  Close,
  CloseOutlined,
  CloseRounded,
  FormatAlignRight,
  InfoOutlined,
  NotificationsOff,
} from '@material-ui/icons'
import { useStyles } from './styles'
import DeleteModal from '../deleteModal'
import { ComplianceTasks, ComplianceTasksData, PaginationData } from 'api/complianceCalendar'
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab'
import { datePickerDateFormat, formatDateToHumanFriendly } from 'utils/formatDate'
import { changeClientCamplianceTaskStatus, clientComplianceMuteUnmute, getNextSchedules } from 'api/clientDashboard'
import {
  CLIENT_STATUS_TYPE,
  CLIENT_STATUS_TYPE_COLORS,
  CLIENT_STATUS_TYPE_TEXT,
  COMPLIANCE_TASKS_STATUS,
  complianceType,
} from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { OrderDueDatePicker } from 'components/common/companyOrder/orderDueDatePicker'
import CompanyOrderTypeField from 'components/common/companyOrder/orderTypeField'
import { uniqueArrayObj } from 'utils/functions'
import { complianceOrderTypeSuggestions } from 'api/suggestions'
import { ISuggestionAttribute } from 'components/interfaces'
import { ICompany } from 'models/company'
import CalendlyModal from '../CalendlyModal'
import moment from 'moment'
import ModalWrapper from 'components/clientLeadManagerElements/modalWrapper'
import { useParams } from 'react-router'
import { IYearsSchedule } from 'components/companies/companyComplianceTask/yearsTable'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'

// import { PopupModal } from "react-calendly";

interface DeadlineTablePrpps {
  complianceTasks: ComplianceTasksData[]
  // complianceTasks: ComplianceTasks
  isComplianceLoading: boolean
  tabValue?: number
  isDashboard?: boolean
  companyOrderDueDate?: Date
  setCompanyOrderDueDate?: any
  orderTypeSuggestion?: string
  setOrderTypeSuggestion?: any
  companyName?: ISuggestionAttribute
  setCompanyName?: any
  companyDetails?: any

  page: number
  setPage: any
  rowsPerPage: number
  setRowsPerPage: any
  pagination?: PaginationData

  setIsStatusChange?: any
}

const DeadlineTable = ({
  complianceTasks,
  isComplianceLoading,
  tabValue,
  isDashboard,
  companyOrderDueDate,
  setCompanyOrderDueDate,
  orderTypeSuggestion,
  setOrderTypeSuggestion,
  companyName,
  setCompanyName,
  companyDetails,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  pagination,
  setIsStatusChange,
}: DeadlineTablePrpps) => {
  const classes = useStyles()

  const { id } = useParams<{ id: string }>()

  const [selectValue, setSelectValue] = useState('')
  const [open, setOpen] = useState(false)

  const [openNotificationModal, setOpenNotificationModal] = useState(false)

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(5)

  const [selectedState, setSelectedState] = useState<ComplianceTasksData>()

  const [nextYearSchedules, setNextYearSchedules] = useState<IYearsSchedule[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedNotifications, setSelectedNotifications] = useState<IYearsSchedule>()
  const [isMute, setIsMute] = React.useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
  const [showDeleteLoading, setShowDeleteLoading] = React.useState<boolean>(false)
  const [isupdate, setIsupdate] = React.useState(false)

  // const [companyOrderDueDate, setCompanyOrderDueDate] = useState<Date>()
  // const [orderTypeSuggestion, setOrderTypeSuggestion] = useState<ISuggestionAttribute>()

  const [isFileReport, setIsFileReport] = useState(false)

  const [isFileNowSuccess, setIsFileNowSuccess] = useState(false)

  const [redirectLink, setRedirectLink] = useState('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectValue(event.target.value as string)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const fileReportHandler = async row => {
    const id = row?.id
    setIsFileReport(true)
    if (id) {
      const payload = {
        compliance_task_id: id,
        status: COMPLIANCE_TASKS_STATUS?.inprogress,
      }
      try {
        const data = await changeClientCamplianceTaskStatus(payload)
        showSuccessNotification('Order In Progress Success')
        setIsFileReport(false)
        setIsStatusChange(true)
        if (row?.form_link) {
          window.open(`${row?.form_link}`, '_self')
        }
        return data
      } catch (error) {
        showErrorNotification(error)
        setIsFileReport(false)
      }
      setIsStatusChange(false)
    } else return
  }

  // const statusTypeCheck = (key: string) => {
  //   switch (key) {
  //     case '0':
  //       return 'Up coming'

  //     case '1':
  //       return 'In progress'

  //     case '2':
  //       return 'Completed'

  //     case '3':
  //       return 'Closed'

  //     case '4':
  //       return 'Removed'

  //     default:
  //       break
  //   }
  // }

  const handleDueDateSelected = date => {
    setCompanyOrderDueDate(date)
    // setOrderTypeSuggestion({
    //   value: 0,
    //   label: '',
    // })
    setOrderTypeSuggestion('')
    if (isDashboard) {
      setCompanyName('')
    }
  }

  const handleSelectingCompany = event => {
    debugger
    setCompanyName(event?.target?.value)
    // setOrderTypeSuggestion({
    // value: 0,
    // label: '',
    // })
    setOrderTypeSuggestion('')
    setCompanyOrderDueDate(undefined)
  }

  const handleSelectingOrderType = data => {
    setOrderTypeSuggestion(data)
    debugger

    // setCompanyOrderDueDate(undefined)
    if (companyName && data) {
      debugger

      setCompanyName('')
    }
  }

  const handleCancelFilter = () => {
    debugger
    if (isDashboard) {
      setCompanyName('')
    }
    // setOrderTypeSuggestion(undefined)
    setOrderTypeSuggestion('')
    setCompanyOrderDueDate(undefined)
  }

  const companiesList = companyDetails?.map((details: ICompany) => {
    return {
      value: details?.id,
      label: details?.name,
    }
  })

  const dataCount = pagination?.total

  console.log('orderTypeSuggestion', orderTypeSuggestion, companyName)

  console.log('selectedNotificationsselectedNotifications', selectedNotifications)
  console.log('isMuteisMuteisMute', isMute)

  useEffect(() => {
    const getNextCompanySchedules = async (id: string) => {
      setIsLoading(true)

      try {
        const res = await getNextSchedules(id)
        setNextYearSchedules(res)
        setIsupdate(false)
        console.log('resssssssssssss', res)
      } catch (error) {
        console.log('error', error)
      }
      setIsLoading(false)
    }
    if (id) {
      getNextCompanySchedules(id)
    }
  }, [id, isupdate])

  // React.useEffect(() => {
  //   if (selectedNotifications?.muted) setIsMute(selectedNotifications?.muted)
  // }, [selectedNotifications?.muted, isupdate])

  const handleMuteUnmuteSchedule = async () => {
    const payload = {
      muted: isMute || false,
      company_id: id,
      notification_schedule_id: selectedNotifications?.notification_schedule_id || 0,
    }
    setShowDeleteLoading(true)

    try {
      await clientComplianceMuteUnmute(payload)
      setIsupdate(true)
    } catch (error) {
      showErrorNotification(error)
    }
    setShowDeleteDialog(false)
    setShowDeleteLoading(false)
    setIsMute(selectedNotifications?.muted || false)
  }

  return (
    <>
      <Grid container alignItems="center" style={{ width: 'calc(100% + 16px)' }}>
        {/* <Grid item>
          <FormatAlignRight />
        </Grid> */}
        <Grid item xs={12} sm={12} lg={9}>
          <Grid container alignItems="center" spacing={3}>
            {/* <Grid item xs={12} sm={isDashboard ? 6 : 12} lg={4}>
              <OrderDueDatePicker
                className={classes.dueDatePicker}
                classes={{ root: classes.dateField }}
                onDueDateSelected={handleDueDateSelected}
                dueDateValue={companyOrderDueDate}
                dateFormat={datePickerDateFormat}
              />
            </Grid> */}
            {isDashboard ? (
              <Grid item xs={12} sm={12} lg={4} xl={4} className={classes.companyNameField}>
                <SelectField
                  className={classes.searchByCompany}
                  menuItems={uniqueArrayObj(companiesList)}
                  placeholder="Search by Company Name"
                  value={companyName || null}
                  handleChange={handleSelectingCompany}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12} sm={12} lg={isDashboard ? 4 : 6} className={classes.companyOrderTypeField}>
              {/* <CompanyOrderTypeField
                //  className={classes.searchByCompany}
                handleSelectingOrderType={handleSelectingOrderType}
                fetchSuggestions={complianceOrderTypeSuggestions}
                orderTypeSuggestion={orderTypeSuggestion || null}
                disabled={false} //{shouldDisableOrderTypeSelection}
              /> */}
              <Autocomplete
                id="complianceTypeValue"
                options={complianceType as string[]}
                getOptionLabel={option => option}
                onInputChange={(event, newInputValue) => {
                  // setOrderTypeSuggestion(newInputValue)
                  handleSelectingOrderType(newInputValue)
                }}
                value={orderTypeSuggestion}
                renderInput={params => <TextField {...params} label="Compliance Type" variant="outlined" />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={3}>
          <SelectField menuItems={uniqueArrayObj(itemsList)} placeholder="Search by Item Type" />
        </Grid> */}
        {isDashboard ? (
          <Grid item xs={12} sm={12} lg={3}>
            {/* <Grid item xs={12} sm={6} lg={3} xl={2}> */}
            <div className={classes.btnWrapper}>
              <Button
                variant="contained"
                className={classes.cancelButton}
                startIcon={<Close />}
                onClick={handleCancelFilter}
              >
                Cancel Filter
              </Button>
            </div>
            {/* </Grid> */}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} lg={3}>
            <div className={classes.btnWrapper}>
              <Button variant="outlined" color="primary" onClick={() => setOpenNotificationModal(true)}>
                Manage Notifications
              </Button>
            </div>
          </Grid>
        )}
      </Grid>

      <br />

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={'15%'}>
                Date
                {/* <FormControl className={classes.formControl}>
                <Select
                  value={selectValue}
                  name="selectValue"
                  onChange={handleChange}
                  displayEmpty
                  className="select-filed"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Date
                  </MenuItem>
                  <MenuItem value={10}>4 Apr 2020</MenuItem>
                  <MenuItem value={20}>8 Jun 2020</MenuItem>
                  <MenuItem value={30}>18 Dec 2020</MenuItem>
                </Select>
              </FormControl> */}
              </TableCell>
              {/* <TableCell>
                Company Name
                 <FormControl className={classes.formControl}>
              
                <Select
                  value={selectValue}
                  name="selectValue"
                  onChange={handleChange}
                  displayEmpty
                  className="select-filed"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Company Name
                  </MenuItem>
                  <MenuItem value={10}>Zeplin Crew</MenuItem>
                  <MenuItem value={20}>Google</MenuItem>
                  <MenuItem value={30}>Snap Inc.</MenuItem>
                </Select>
              </FormControl> 
              </TableCell>*/}
              <TableCell width={'25%'}>Company Name</TableCell>
              <TableCell width={'15%'}>
                Item
                {/* <FormControl className={classes.formControl}>
                <Select
                  value={selectValue}
                  name="selectValue"
                  onChange={handleChange}
                  displayEmpty
                  className="select-filed"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Item
                  </MenuItem>
                  <MenuItem value={10}>TCP License</MenuItem>
                  <MenuItem value={20}>Annual Report</MenuItem>
                  <MenuItem value={30}>Registered Agent Renewal</MenuItem>
                </Select>
              </FormControl> */}
              </TableCell>
              <TableCell width={'15%'}>Status</TableCell>
              {/* <TableCell align="right">View Full List {'>'}</TableCell> */}
              {/* {tabValue !== 1 ? (
                <TableCell align="left" width={'50%'}>
                  Action Required{' '}
                </TableCell>
              ) : (
                ''
              )} */}
              <TableCell align="left" width={'30%'}>
                Action Required
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isComplianceLoading ? (
              <TableRow className={classes.root}>
                {[...Array(4)]?.map(_ => (
                  <TableCell component="th" scope="row">
                    <Skeleton variant="rect" width={'100%'} height={'50px'} />
                  </TableCell>
                ))}
              </TableRow>
            ) : complianceTasks?.length ? (
              complianceTasks?.map((row: ComplianceTasksData) => (
                <TableRow key={row?.id} className="table-row">
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: moment(row?.due_date).isBetween(moment(), moment().add('30', 'days')) ? 'red' : 'inherit',
                    }}
                  >
                    {row?.due_date ? formatDateToHumanFriendly(row?.due_date) : 'No Date'}
                  </TableCell>
                  {/* <TableCell>{row?.details?.company_details?.name}</TableCell> */}
                  <TableCell>{row?.company ? row?.company?.name : '--------'}</TableCell>
                  <TableCell>{row?.title ? row?.title : 'No Title'}</TableCell>
                  <TableCell>
                    {/* {row?.status === '1' ? (
                  <Chip
                    style={{ background: CLIENT_STATUS_TYPE_COLORS[row?.status] }}
                    label={CLIENT_STATUS_TYPE[row?.status]}
                  />
                ) : (
                  CLIENT_STATUS_TYPE[row?.status]
                )} */}
                    {/* <Chip
                      style={{ background: CLIENT_STATUS_TYPE_COLORS[row?.status] }}
                      label={CLIENT_STATUS_TYPE[row?.status]}
                    /> */}
                    <Chip
                      style={{ background: CLIENT_STATUS_TYPE_COLORS[CLIENT_STATUS_TYPE_TEXT[row?.status]] }}
                      label={row?.status}
                    />
                  </TableCell>
                  <TableCell className="inline-btn">
                    {/* <div className="inline-info-item" style={{ marginRight: '5%' }}>
                      <InfoOutlined className="info-icon" />
                      <p className="action-text">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing sed diam nonummy nibh euismod tincidunt
                      </p>
                    </div> */}
                    {/* {CLIENT_STATUS_TYPE_TEXT[row?.status] === '0' ? ( */}
                    {row?.status === 'Needs Attention' ||
                    row?.status === 'Upcoming' ||
                    row?.status === 'Ordered-In Progress' ? (
                      <>
                        {/* <Button
              color="primary"
              variant="contained"
              className="file-btn"
              onClick={() => {
                fileReportHandler(row?.id)
                setRedirectLink(row?.redirectUrl || '')
                setIsFileNowSuccess(true)
              }}
            >
              File Now
            </Button> */}
                        {row?.status !== 'Ordered-In Progress' && (
                          <div className="inline-info-item">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.reportBtn}
                              onClick={() => {
                                fileReportHandler(row)
                                setRedirectLink(row?.redirectUrl || '')
                                // setIsFileNowSuccess(true)
                              }}
                            >
                              {row?.status === 'Needs Attention' ? 'Finish Report' : 'File Report'}
                            </Button>

                            {/* <Button
              variant="contained"
              className="remove-btn"
              onClick={() => {
                setOpen(true)
                setSelectedState(row)
              }}
            >
              Mute Notifications
            </Button> */}
                            {/* <Button
                            className={classes.removeTaskBtn}
                            endIcon={<CancelOutlined className="close-icon" />}
                            onClick={() => {
                              setOpen(true)
                              setSelectedState(row)
                            }}
                          >
                            Remove Task
                          </Button> */}
                            <IconButton
                              onClick={() => {
                                setOpen(true)
                                setSelectedState(row)
                              }}
                              aria-label="delete"
                            >
                              <NotificationsOff color="primary" />
                              {/* <DeleteIcon color="primary" /> */}
                            </IconButton>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.root}>
                <TableCell>
                  <Alert severity="info">No Task Found</Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataCount ? dataCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog maxWidth="sm" fullWidth open={isFileNowSuccess} onClose={() => setIsFileNowSuccess(false)}>
        <div className={classes.dialogContainer}>
          <h1 className={classes.mainHeading}>Thank you.</h1>
          <p className={classes.details}>We have received your order.</p>
          {redirectLink.length ? (
            redirectLink.includes('calendly') ? (
              <>
                <p className={classes.details2}>Kindly, schedule a meeting with our team to proceed further.</p>
                <CalendlyModal calendlyUrl={redirectLink} btnText="Schedule a meeting" />
              </>
            ) : (
              <a className={classes.openLink} target="blank" href={redirectLink}>
                View details
              </a>
            )
          ) : (
            ''
          )}
        </div>
      </Dialog>

      <DeleteModal open={open} setOpen={setOpen} selectedState={selectedState} setIsStatusChange={setIsStatusChange} />
      <ModalWrapper
        open={openNotificationModal}
        maxWidth={'md'}
        setOpen={setOpenNotificationModal}
        heading={'Coming Years Schedule'}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>1st Compliance</TableCell>
                <TableCell>2nd Compliance</TableCell>
                <TableCell>3rd Compliance</TableCell>
                <TableCell>4th Compliance</TableCell>
                <TableCell>5th Compliance</TableCell>
                <TableCell align="left">Mute/Unmute</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow className={classes.root}>
                  {[...Array(7)]?.map(_ => (
                    <TableCell component="th" scope="row">
                      <Skeleton variant="rect" width={'100%'} height={'50px'} />
                    </TableCell>
                  ))}
                </TableRow>
              ) : nextYearSchedules?.length ? (
                nextYearSchedules?.map((row: IYearsSchedule) => (
                  <TableRow key={row?.notification_schedule_id} className="table-row">
                    <TableCell component="th" scope="row">
                      {row.schedule_name || '- - -'}
                    </TableCell>

                    {row?.years_schedule?.map((item: string) => (
                      <TableCell>{item || '--------'}</TableCell>
                    ))}
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row?.muted}
                            onChange={e => {
                              setSelectedNotifications(row)
                              console.log('e.target.checkede.target.checked', e.target.checked)

                              setIsMute(e.target.checked)
                              setShowDeleteDialog(true)
                            }}
                            name="isMute"
                            color="primary"
                          />
                        }
                        label={row?.muted ? 'Muted' : 'Unmuted'}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={classes.root}>
                  <TableCell>
                    <Alert severity="info">No Schedule Found</Alert>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalWrapper>

      <DeleteConfirmationDialog
        itemName={selectedNotifications?.schedule_name || '- - -'}
        onClose={() => {
          setShowDeleteDialog(false)
          setIsMute(selectedNotifications?.muted || false)
        }}
        open={showDeleteDialog}
        actionText={isMute ? 'Mute' : 'Unmute'}
        itemType="Schedule"
        onDelete={handleMuteUnmuteSchedule}
        buttonText="Confirm"
        isDeleting={showDeleteLoading}
      />
    </>
  )
}

export default DeadlineTable
