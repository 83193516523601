import React from 'react'
import { CircularProgress, createStyles, Dialog, IconButton, makeStyles, Theme } from '@material-ui/core'
import { TempNotification } from 'components/notifications/notificationsDialog'
import Close from '@material-ui/icons/Close'
import AlertButton from 'components/common/buttons/AlertButton'
import { deleteNotificationTemplates } from 'api/notifications'
import showApiResponseError from 'utils/showApiResponseError'
import { notify } from '@jetkit/react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: '48rem',
      width: '100%',
      paddingBottom: '3.375rem',
    },
    closeButton: {
      width: 'min-content',
      alignSelf: 'flex-end',
      marginRight: '1rem',
    },
    title: {
      fontSize: 28,
      fontWeight: 600,
      letterSpacing: '0.14px',
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontFamily: 'SFCompactDisplay',
      marginBottom: '1rem',
    },
    info: {
      paddingLeft: '4rem',
      paddingRight: '4rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      marginLeft: '5.625rem',
      marginRight: '5.625rem',
      display: 'flex',
      backgroundColor: 'rgba(250, 250, 250, 0.9)',
      border: 'solid 1px rgba(151, 151, 151, 0.26)',
      borderRadius: '4px',
      justifyContent: 'center',
    },
    button: {
      width: '13.75rem',
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: '0.09px',
      alignSelf: 'center',
      marginTop: '1.5rem',
    },
  })
)

interface DeleteTempleteDialogProps {
  open: boolean
  close: () => void
  deletionObject?: TempNotification
  onRequestSuccessful?: () => void
}

interface ObjectWithId {
  id: number
}

const DeleteTempleteDialog = ({ open, close, deletionObject, onRequestSuccessful }: DeleteTempleteDialogProps) => {
  const classes = useStyles()
  const [waitingForResponse, setWaitingForResponse] = React.useState(false)

  const handleDeleteClick = React.useCallback(async () => {
    // const errorMessage = `Sorry, could not delete ${deletionObjectDisplayName(deletionObject)}`
    const errorMessage = `Sorry, could not delete data`

    // let id: number | undefined
    // if (hasId(deletionObject)) {
    //   id = deletionObject.id
    // }
    setWaitingForResponse(true)
    try {
      if (deletionObject?.id) {
        await deleteNotificationTemplates(deletionObject?.id)
      } else return
      setWaitingForResponse(false)
      onRequestSuccessful && onRequestSuccessful()
      notify.success(`Successfully deleted ${deletionObject?.subject}`)
      close()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, errorMessage)
    }
  }, [deletionObject, onRequestSuccessful, close])

  return (
    <Dialog open={open} PaperProps={{ className: classes.paper }}>
      <IconButton onClick={close} className={classes.closeButton}>
        <Close fontSize="large" />
      </IconButton>
      <div className={classes.title}>Are you sure you want to delete this data {deletionObject?.subject}?</div>
      {/* <div className={classes.info}>{info}</div> */}
      <AlertButton classes={{ button: classes.button }} onClick={handleDeleteClick} disabled={waitingForResponse}>
        {waitingForResponse ? <CircularProgress style={{ color: 'white' }} /> : 'Delete'}
      </AlertButton>
    </Dialog>
  )
}

export default DeleteTempleteDialog
