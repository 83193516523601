import { createStyles, makeStyles, Theme, Chip } from '@material-ui/core'
import { normalizePhone } from 'components/common/functions'
import BRTextField from 'components/common/textFields/BRTextField'
import CopyTextButton from 'components/common/textFields/copyTextButton'
import { ISalesRep } from 'models/user'
import * as React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import copyToClipboard from 'utils/copyToClipboard'
import { ValueOf } from 'utils/objectUtils'

interface ISalesRepPricingProps {
  onChange: (field: keyof ISalesRep, value: ValueOf<ISalesRep>) => void
  showCopyButton?: boolean
  salesRep: ISalesRep
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
  })
)

const SalesRepPricing: React.FC<ISalesRepPricingProps> = ({ onChange, showCopyButton, salesRep }) => {
  const classes = useStyles()

  return (
    <>
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-starter"
        label="Starter Package Price"
        //   style={{ marginBottom: clientEditErrors.starter ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="starter"
        margin="normal"
        variant="outlined"
        value={salesRep.starter}
        onChange={event => onChange('starter', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-essentials"
        label="Essentials Package Price"
        //   style={{ marginBottom: clientEditErrors.essentials ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="essentials"
        margin="normal"
        variant="outlined"
        value={salesRep.essentials}
        onChange={event => onChange('essentials', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-supreme"
        label="Supreme Package Price"
        //   style={{ marginBottom: clientEditErrors.supreme ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="supreme"
        margin="normal"
        variant="outlined"
        value={salesRep.supreme}
        onChange={event => onChange('supreme', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-agreements"
        label="Agreements Price"
        //   style={{ marginBottom: clientEditErrors.agreements ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="agreements"
        margin="normal"
        variant="outlined"
        value={salesRep.agreements}
        onChange={event => onChange('agreements', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-annual_report"
        label="Annual Report Price"
        //   style={{ marginBottom: clientEditErrors.annual_report ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="annual_report"
        margin="normal"
        variant="outlined"
        value={salesRep.annual_report}
        onChange={event => onChange('annual_report', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-ein"
        label="Ein Upgrade Price"
        //   style={{ marginBottom: clientEditErrors.ein ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="ein"
        margin="normal"
        variant="outlined"
        value={salesRep.ein}
        onChange={event => onChange('ein', Number(event.target.value || 0))}
      />

      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-foreign_ein"
        label="Foreign Ein Price"
        //   style={{ marginBottom: clientEditErrors.foreign_ein ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="foreign_ein"
        margin="normal"
        variant="outlined"
        value={salesRep.foreign_ein}
        onChange={event => onChange('foreign_ein', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-ra_services"
        label="Registered Agent Service (First Year)"
        //   style={{ marginBottom: clientEditErrors.ra_services ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="ra_services"
        margin="normal"
        variant="outlined"
        value={salesRep.ra_services}
        onChange={event => onChange('ra_services', Number(event.target.value || 0))}
        // disabled={true}
      />
      {/* <Chip label={'Registered Agent service First Year Free and $149 per year thereafter'} />
      <br /> */}
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-rush_processing"
        label="Rush Processing Price"
        //   style={{ marginBottom: clientEditErrors.rush_processing ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="rush_processing"
        margin="normal"
        variant="outlined"
        value={salesRep.rush_processing}
        onChange={event => onChange('rush_processing', Number(event.target.value || 0))}
      />
      <BRTextField
        showCopyButton={showCopyButton}
        data-testid="client-s_corp"
        label="S-corp Price"
        //   style={{ marginBottom: clientEditErrors.s_corp ? '1.5rem' : undefined }}
        className={classes.textField}
        type="number"
        name="s_corp"
        margin="normal"
        variant="outlined"
        value={salesRep.s_corp}
        onChange={event => onChange('s_corp', Number(event.target.value || 0))}
      />
    </>
  )
}

export default SalesRepPricing
