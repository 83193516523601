import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

interface CheckBoxProps {
  checked?: boolean
  onChange?: any
  name?: string
  label: string
}

const CheckboxLabel: React.FC<CheckBoxProps> = ({ checked, onChange, name, label }: CheckBoxProps) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} name={name} color="primary" />}
      label={label}
    />
  )
}

export default CheckboxLabel
