import { apiClient, requestPaginated } from '@jetkit/react'
import { INewNote, INote } from 'models/note'

export const deleteNote = async (noteId: number): Promise<void> => {
  await apiClient.delete(`/note/${noteId}`)
  return
}

interface INoteUpdateRequest {
  noteId: number
  note_text: string
}
// apiClient.defaults.baseURL = 'https://je55nxw22i.execute-api.us-west-1.amazonaws.com/dev/'
export const fetchNotes = requestPaginated<INote[]>({ url: '/note' })
// export const fetchNotes =(a)=>{
//   apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL //'http://localhost:3000/'
//   return requestPaginated<INote[]>({ url: '/note' })
// }
export const updateNoteText = async ({ noteId, note_text }: INoteUpdateRequest): Promise<INote> => {
  // (await apiClient.patch(`/note/${noteId}`, { note_text })).data
  return (await apiClient.post(`/note/${noteId}`, { note_text })).data
}

export const createNote = async (note: INewNote): Promise<INote> => (await apiClient.post(`/note`, note)).data

export const createNoteForStageChange = async (orderId: number, newStage: string) =>
  await apiClient.post(`/order/${orderId}/log_stage_change`, { stage: newStage })

export const getUnpaidSubscriptions = async (
  company_id?: number,
  order_id?: number,
  tax_order_id?: number
): Promise<string[]> =>
  (
    await apiClient.get(`entity-subscriptions`, {
      // await apiClient.get(`unpaid-subscriptions`, {
      params: {
        company_id: company_id ? company_id : 0,
        order_id: order_id ? order_id : 0,
        tax_order_id: tax_order_id ? tax_order_id : 0,
      },
    })
  ).data
