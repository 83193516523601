import { IPaginatedRequest, IPaginatedResponse, PagedTable, usePagedTable } from '@jetkit/react'
import { createStyles, makeStyles, Table, TableBody, Theme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import AssetRow from 'components/common/assets/assetRow'
import AssetTableHeader from 'components/common/assets/assetTableHeader'
import { AssetsDeleteCallBackContext, IDocumentUploadProps } from 'components/common/assets/documentUploads'
import { IAsset } from 'models/asset'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import { skeletons } from 'components/common/table'
import ButtonWithLoading from '../buttons/buttonWithLoadingProgress'

export type AssetsAPICall = (req: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>>
export type AssetUploadCallBack = () => void

export const AssetsAPICallContext = React.createContext<AssetsAPICall | null>(null)

interface IAssetsTableProps extends IDocumentUploadProps {
  filterQuery?: string
  getDocuments: (
    objectId: number,
    archive?: boolean,
    taxId?: number
  ) => (queryParams?: {}) => (paginatedRequest: IPaginatedRequest) => Promise<IPaginatedResponse<IAsset[]>>
  deleteDocument: (objectId: number, assetId: number) => Promise<void>
  getAssetDownloadURL: (asset: IAsset) => Promise<string | undefined> //| undefined
  isPdfUploaded?: boolean
  isArchive?: boolean
  taxId?: number
  isDownload?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      borderRadius: '5px 5px 0px 0px',
      height: 10,
      backgroundColor: '#f47950',
    },
  })
)

const AssetsTable = ({
  filterQuery,
  objectId,
  type,
  getDocuments,
  deleteDocument,
  getAssetDownloadURL,
  isPdfUploaded,
  isArchive,
  taxId,
  isDownload = false,
}: IAssetsTableProps) => {
  const classes = useStyles()

  const [rows, setRows] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const apiCall = React.useContext<AssetsAPICall | null>(AssetsAPICallContext)

  const memoApiCall = React.useMemo(
    () => (apiCall ? apiCall : getDocuments(objectId, isArchive, taxId)({ query: filterQuery })),
    [apiCall, getDocuments, objectId, isArchive, taxId, filterQuery]
  )

  const pagedTable = usePagedTable<IAsset>({
    apiCall: memoApiCall,
  })

  const onDelete = React.useCallback(
    async (assetId: number) => {
      try {
        await deleteDocument(objectId, assetId)
      } catch (error) {
        showApiResponseError(error, 'Could not delete a document')
      }
      pagedTable.reloadData()
    },
    [pagedTable, objectId, deleteDocument]
  )

  const onUploadComplete = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleGetRow = (asset: any) => {
    if (!rows.includes(asset)) {
      setRows([...rows, asset])
    } else if (rows.includes(asset)) {
      setRows(rows.filter(existingRow => existingRow !== asset))
    }
  }

  const downloadAll = async () => {
    setLoading(true)
    rows.forEach(async element => {
      const url = await getAssetDownloadURL(element)
      if (url) {
        window.open(url, '_blank')
        setLoading(false)
      }
    })
  }

  React.useEffect(() => {
    if (isPdfUploaded) pagedTable.reloadData()
  }, [isPdfUploaded])

  React.useEffect(() => {
    if (pagedTable?.renderProps?.rows?.length) {
      setRows(pagedTable.renderProps.rows)
    }
  }, [pagedTable.renderProps.rows])
  return (
    <AssetsDeleteCallBackContext.Provider value={onDelete}>
      {isDownload ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
          <ButtonWithLoading
            isLoading={loading}
            disabled={!rows.length}
            onClick={downloadAll}
            className="notify-button"
            style={{ width: '12rem', marginLeft: '1rem' }}
          >
            Download All
          </ButtonWithLoading>
        </div>
      ) : (
        ''
      )}

      <Divider className={classes.divider} />
      {pagedTable.isLoading ? (
        <Table>
          {/* <AssetTableHeader type={type} objectId={objectId} onUploadComplete={onUploadComplete} /> */}
          <TableBody>{skeletons({ numberOfColumns: 9, sizes: [100], numberOfRows: 9 })}</TableBody>
        </Table>
      ) : (
        <PagedTable
          emptyRowText="No Files"
          {...pagedTable.renderProps}
          renderRow={(row_data: IAsset) => (
            <AssetRow
              handleGetRow={handleGetRow}
              isDownload={isDownload}
              key={row_data.id}
              type={type}
              asset={row_data}
              getAssetDownloadURL={getAssetDownloadURL}
              onUploadComplete={onUploadComplete}
            />
          )}
          header={
            <AssetTableHeader
              isDownload={isDownload}
              type={type}
              objectId={objectId}
              onUploadComplete={onUploadComplete}
            />
          }
          colSpan={9}
        />
      )}
    </AssetsDeleteCallBackContext.Provider>
  )
}
export default AssetsTable
