import { useStyles } from '../styles'
import React from 'react'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import InputField from 'components/leadManagerElements/inputField'
import { postImportSubscription, IPostImportSubscription } from 'api/subscription'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'

interface ImportSubscriptionModalProps {
  company_id: number
  reloadloadDetails?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ImportSubscriptionModal: React.FC<ImportSubscriptionModalProps> = ({
  company_id,
  reloadloadDetails,
  setIsOpen,
}: ImportSubscriptionModalProps) => {
  const classes = useStyles()

  const [subscriptionId, setSubscriptionId] = React.useState('')

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSaveChange = async () => {
    setIsLoading(true)

    const payload: IPostImportSubscription = {
      subscription_id: subscriptionId,
      company_id: company_id,
    }

    try {
      const res = await postImportSubscription(payload)
      if (res?.error) {
        showErrorNotification(res?.error)
      } else {
        showSuccessNotification(res?.success)
        reloadloadDetails && (await reloadloadDetails())
        setIsOpen(false)
      }
      setIsLoading(false)
    } catch (error) {
      console.log('error', error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <InputField
        autoFocus
        label="Subscription ID"
        value={subscriptionId}
        onChange={e => setSubscriptionId(e.target.value)}
      />
      {/* <br /> */}
      <hr />

      <div className={classes.saveBtn}>
        <ButtonWithLoading isLoading={isLoading} color="primary" variant="contained" onClick={handleSaveChange}>
          Import
        </ButtonWithLoading>
      </div>
    </>
  )
}

export default ImportSubscriptionModal
