import React, { useContext } from 'react'
import CommonInput from '../CommonInput'
import InputMask from 'react-input-mask'
import { positiveNumericInput } from 'components/common/functions'
import { InputBase } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ExistingCompanyContext } from 'providers/existingCompany/existingCompany.provider'

const Step3 = () => {
  const classes = useStyles()

  const {
    stateId,
    setStateId,
    einNumber,
    setEinNumber,
    fileDate,
    setFileDate,
    sharesNumber,
    setSharesNumber,
  } = useContext(ExistingCompanyContext)

  const handleChange = (value: string) => {
    setEinNumber(value)
  }

  return (
    <div>
      <CommonInput
        label={'What is your business State  ID Number?'}
        palcehodler={'State ID'}
        name="stateId"
        value={stateId}
        onChange={(e: any) => setStateId(e.target.value)}
      />
      <InputMask
        mask="99-9999999"
        // disabled={disabled}
        value={einNumber}
        onChange={event => handleChange(positiveNumericInput(event.target.value))}
        // className={classes.textField}
      >
        {() => (
          <div className={classes.mainContainer}>
            <label className={classes.label}>What is your business EIN number?</label>
            <InputBase className={classes.textField} placeholder={'EIN NUMBER'} />
          </div>
        )}
      </InputMask>
      <div style={{ marginBottom: '10px' }}>
        <CommonInput
          label={'What is your business File Date?'}
          palcehodler={'Business File Date'}
          type="date"
          name="fileDate"
          value={fileDate}
          onChange={(e: any) => setFileDate(e.target.value)}
        />
      </div>
      <CommonInput
        label={'How many shares does your corporation have issued? '}
        palcehodler={'Number Of Shares'}
        name="sharesNumber"
        value={sharesNumber}
        onChange={(e: any) => setSharesNumber(e.target.value)}
      />
    </div>
  )
}

export default Step3

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      margin: '0.5rem 0',
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#202124',
      marginBottom: '0.25rem',
    },
    textField: {
      width: '100%',
      background: '#f7f8f9',
      fontSize: '16px',
      borderRadius: '4px',
      borderBottom: '0',
      padding: '0 0.5rem',
      marginTop: '0.25rem',

      '& input': {
        '&::placeholder': {
          fontSize: '0.9rem',
          fontFamily: 'Roboto, sans-serif',
          color: '#202124',
        },
      },
    },
  })
)
