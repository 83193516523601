import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { BaseTextFieldProps } from '@material-ui/core/TextField'
import { SearchField } from 'api/suggestions'
import BRTextField from 'components/common/textFields/BRTextField'
import { ISuggestionAttribute } from 'components/interfaces'
import React, { CSSProperties, HTMLAttributes } from 'react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { ControlProps } from 'react-select/src/components/Control'
import { MenuProps } from 'react-select/src/components/Menu'
import { ValueType } from 'react-select/src/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.body1.fontFamily,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem 0',
      flexGrow: 1,
    },
    title: {
      marginBottom: '0.25rem',
    },
    paper: {
      position: 'absolute',
      zIndex: 2,
      marginTop: '0.25rem',
      borderRadius: '0.25rem',
      left: 0,
      right: 0,
    },
    input: {
      display: 'flex',
    },
    valueContainer: {
      position: 'relative',
      alignSelf: 'center',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
  })
)

interface IReactSelectProps {
  key?: number

  onOptionSelected: (option: ISuggestionAttribute | null) => void

  title: string
  fetchSuggestions: (query: string, field: SearchField) => Promise<ISuggestionAttribute[]>
  field: SearchField
  required?: boolean
  placeholder?: string
  disabled?: boolean
  inputValue?: string
  defaultOptions?: ISuggestionAttribute[]
  defaultValue?: ISuggestionAttribute
  error?: boolean
  errorText?: string
  value?: ISuggestionAttribute | null
  showCopyButton?: boolean
  dataTestId?: string
  label?: string

  setFieldValue?: (val: any) => void

  variant?: string
  className?: string
}

const Option = props => {
  return (
    <div data-testid={`option-${props.data.label}`}>
      <components.Option {...props} />
    </div>
  )
}

/**
 * @deprecated
 * Usage of `GenericAutocomplete` is recommended instead.
 */
export const AsyncSelectField = ({
  key, // value to trigger component rerendering
  fetchSuggestions,
  onOptionSelected,
  title,
  field,
  required,
  placeholder,
  disabled,
  inputValue,
  defaultOptions,
  defaultValue,
  error,
  errorText,
  value,
  showCopyButton,
  dataTestId = `${title}-async-select-field`,
  setFieldValue,
  label,
  variant,
  className,
}: IReactSelectProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    singleValue: (base: CSSProperties) => ({
      ...base,
      color: error ? theme.customPalette.errorText : base.color,
      '& input': {
        font: 'inherit',
      },
    }),
    placeholder: (base: CSSProperties) => ({
      ...base,
      color: error ? theme.customPalette.errorText : base.color,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& input': {
        font: 'inherit',
      },
    }),
  }

  // In many places where this component is used, only `id` of `ISuggestionAttribute`
  // is being passed to parent component without storing it there. In such cases no value
  // is passed here from parent component. Which is why we store selected value here, so it's
  // possible to copy actual string.
  const [innerStoredValue, setInnerStoredValue] = React.useState<ISuggestionAttribute>()

  function Menu(props: MenuProps<ISuggestionAttribute>) {
    return (
      <Paper square className={classes.paper} {...props.innerProps} data-testid={`${title}-select-options`}>
        {props.children}
      </Paper>
    )
  }

  type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>

  function inputComponent({ inputRef, ...props }: InputComponentProps) {
    return <div ref={inputRef} {...props} />
  }

  function ValueContainer(props: ValueContainerProps<ISuggestionAttribute>) {
    return <div className={classes.valueContainer}>{props.children}</div>
  }

  function Control(props: ControlProps<ISuggestionAttribute>) {
    return (
      <BRTextField
        label={label}
        dataTestId={dataTestId}
        showCopyButton={showCopyButton}
        customTextToCopy={innerStoredValue && innerStoredValue.label}
        error={error}
        helperText={error ? errorText : ''}
        fullWidth
        required={!!required}
        value=" "
        type="text"
        margin="none"
        variant={variant ? variant : 'outlined'}
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.TextFieldProps}
      />
    )
  }

  const components = {
    Menu,
    Control,
    ValueContainer,
    Option,
  }

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      resolve(fetchSuggestions(inputValue, field))
    })

  const handleChangeSingle = (select: ValueType<ISuggestionAttribute>) => {
    if (select && Array.isArray(select)) {
      throw new Error('Unexpected type passed to ReactSelect onChange handler')
    }

    const selectedOption: ISuggestionAttribute | null = select ? (select as ISuggestionAttribute) : null

    setInnerStoredValue(selectedOption || undefined)

    onOptionSelected(selectedOption)

    if (setFieldValue && !selectedOption) {
      setFieldValue(null)
    }
  }

  return (
    <div className={`${classes.root} ${className}`} data-testid={`${title}-select`}>
      <AsyncSelect
        key={key}
        isDisabled={!!disabled}
        isClearable
        inputValue={inputValue}
        styles={selectStyles}
        defaultOptions={defaultOptions ? defaultOptions : true}
        loadOptions={promiseOptions}
        onChange={handleChangeSingle}
        components={components}
        placeholder={placeholder || `Type ${title} Name`}
        maxMenuHeight={160}
        cacheOptions={false}
        defaultValue={defaultValue}
        // we are doing that to have 'managed' input field (pass in null)
        value={value}
      />
    </div>
  )
}

export default AsyncSelectField
