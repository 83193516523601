import { apiClient } from '@jetkit/react'
import { IClient, IUser } from 'models/user'

export const searchStaff = async (query: string): Promise<IClient[]> => {
  return (await apiClient.get('user/employees', { params: { query } })).data
  //return (await apiClient.get('search/staff', { params: { query } })).data
}
export const fetchStaff = async (query = ''): Promise<IUser[]> => {
  // apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL //'http://localhost:3000/'
  // return (await apiClient.get('user', { params: { user_type: 'staff', query } })).data
  return (await apiClient.get('user/employees', { params: { user_type: 'staff', query } })).data
}
export const getStaff = async (userId: number): Promise<IUser> => {
  return (await apiClient.get(`user/${userId}`)).data
}
