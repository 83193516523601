import { Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ArrowForward } from '@material-ui/icons'
import { IBONotes } from 'api/taxOrders'

import * as React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      '& p': {
        margin: 0,
      },
      backgroundColor: 'white',
      marginBottom: theme.typography.pxToRem(10),
      padding: `${theme.typography.pxToRem(14)} ${theme.typography.pxToRem(14)}`,
      cursor: 'pointer',
      borderRadius: '8px',
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 1px 2px 0px',
    },

    createdBy: {
      display: 'flex',
      alignItems: 'center',
      '& .created-by': {
        marginLeft: '0.5rem !important',
        fontSize: '1.1rem',
        fontWeight: 600,
        marginBottom: '0.2rem !important',
      },
    },
    icon: {
      '& svg': {
        width: 20,
        height: 20,
      },
      '& p': {
        fontSize: '12px',
        marginRight: '10px',
      },
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    notesContainer: {
      // display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'center',
      // marginRight: '10px',
      marginLeft: '2rem',
      '& .name': {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        marginBottom: '0.5rem',
      },
      '& .current-version': {
        fontStyle: 'italic',
      },
    },
  }
})

interface INotesCardProps {
  notes: IBONotes
  asset?: any
  key?: any
  onEditClick?: () => void
  // isCurrent: number
}

const NotesCard: React.FC<INotesCardProps> = ({ notes, onEditClick, key, asset }) => {
  const classes = useStyles()
  return (
    <div className={classes.main} onClick={onEditClick}>
      <div className={classes.createdBy}>
        <ArrowForward />
        <p className="created-by">{formatDateTimeToHumanFriendly(notes.created_at)}</p>
      </div>
      <div className={classes.notesContainer}>
        {/* <p className="current-version">Current version</p> */}
        {notes?.isCurrent && <p className="current-version">Current version</p>}

        <p className="name">
          {notes?.employee_details?.first_name ?? ''} {notes?.employee_details?.last_name ?? ''}
        </p>
      </div>
    </div>
  )
}

export default NotesCard
