import { apiClient } from '@jetkit/react'
import { IAddress } from 'models/address'
import snakeCaseKeys from 'snakecase-keys'
import copyObjectAndDeleteKey from 'utils/deleteKeyFromObject'

const createAddressObj = (address: IAddress) => {
  // //
  if (address?.cityObj?.value) {
    // //
    return {
      ...address,
      city: address?.cityObj?.label || '',
      city_id: address?.cityObj?.value || 0,
    }
  } else {
    // //
    return address
  }
}

export const createAddress = async (address: IAddress): Promise<IAddress> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'
  // //
  return (
    await apiClient.post(
      `/address`,
      copyObjectAndDeleteKey(
        // snakeCaseKeys({ ...address, city: address?.cityObj?.label, city_id: address?.cityObj?.value }),
        snakeCaseKeys(createAddressObj(address)),

        'id'
      )
    )
  ).data
}

export const getstates = async (): Promise<any> => {
  return (await apiClient.get(`/states`)).data
}
export const getcities = async (state): Promise<any> => {
  return (await apiClient.get(`/city/${state}`)).data
}

const updatedAddress = (address: IAddress) => {
  // //
  if (address?.cityObj?.value) {
    // //
    return {
      ...address,
      city: address?.cityObj?.label || '',
      city_id: address?.cityObj?.value || 0,
    }
  } else {
    // //
    return address
  }
}

export const editAddress = async (address: IAddress): Promise<IAddress> => {
  // //
  try {
    // //
    return (
      await apiClient.post(
        `/address/${address.id}`,
        copyObjectAndDeleteKey(
          // snakeCaseKeys({ ...address, city: address?.cityObj?.label, city_id: address?.cityObj?.value }),
          snakeCaseKeys(updatedAddress(address)),

          'id'
        )
      )
    ).data
  } catch (e) {
    console.log('error', e)
    // //
    const data: IAddress = address
    return await data
  }
}
export const deleteAddress = async (addressId: number) => await apiClient.delete(`/address/${addressId}`)
