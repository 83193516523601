/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  MenuItem,
  Select,
  FormControl,
  TablePagination,
  CircularProgress,
  Fab,
  TableContainer,
  Paper,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import DownloadIcon from 'img/download-icon.png'
import PrintIcon from 'img/print-icon.png'
import { useStyles } from './styles'
import { formatDateToHumanFriendly, formatDateToHumanFriendlyWithComa } from 'utils/formatDate'
import { getClientUploadsDocuments } from 'api/clientDashboard'
import { useParams } from 'react-router'
import { downloadClientCompanyDocumentURL } from 'api/companies'
import clsx from 'clsx'
import { GetApp, Done, Warning } from '@material-ui/icons'
import { Alert, Skeleton } from '@material-ui/lab'
import ModalTitle from 'components/common/modalTitle'

const CompanyDocumentTable = () => {
  const classes = useStyles()

  const { id } = useParams<{ id: string }>()

  const [selectValue, setSelectValue] = useState('')

  const [uploadDocumentsRow, setUploadDocumentsRow] = useState<any>()

  const [isUploadDocuments, setIsUploadDocuments] = useState(false)

  const [docId, setDocId] = useState<string>('')
  const [isDownloadableLink, setIsDownloadableLink] = useState<boolean>(false)
  const [success, setSuccess] = useState(false)

  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [paymentLink, setPaymentLink] = useState<string>('')
  const [currentDoc, setCurrentDoc] = useState<any>()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectValue(event.target.value as string)
  }

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, uploadDocumentsRow?.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getUploadDocments = useCallback(async () => {
    setIsUploadDocuments(true)
    const payload = {
      company_id: id,
      query: '',
      page: page + 1,
      page_size: rowsPerPage,
    }
    try {
      const documents = await getClientUploadsDocuments(payload)
      setUploadDocumentsRow(documents)
      setIsUploadDocuments(false)
    } catch (error) {
      console.log(error)
    }

    setIsUploadDocuments(false)
  }, [id, page, rowsPerPage])

  useEffect(() => {
    getUploadDocments()
  }, [getUploadDocments])

  const getDownloadDocument = async (objectId: number, assetId: number) => {
    try {
      setIsDownloadableLink(true)
      const downloadDoc = await downloadClientCompanyDocumentURL({ companyId: objectId, documentId: assetId })
      setCurrentDoc(uploadDocumentsRow?.data?.find(item => item.id === assetId))
      console.log('downloadDocdownloadDocdownloadDoc', downloadDoc)
      if (downloadDoc?.subscription_inactive) {
        setIsAlertOpen(downloadDoc?.subscription_inactive)
        setPaymentLink(downloadDoc?.payment_link)
      } else {
        setSuccess(true)
        window.location.assign(downloadDoc || '')
      }
    } catch (error) {
      console.log(error)
    }
    setIsDownloadableLink(false)
  }

  const dataCount = uploadDocumentsRow?.pagination?.total

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead className="table-head">
            <TableRow>
              <TableCell>
                UPLOAD DATE
                {/* {/* <FormControl className={classes.formControl}>
                <Select
                  value={selectValue}
                  name="selectValue"
                  onChange={handleChange}
                  displayEmpty
                  className="select-filed"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Uploaded Date
                    </MenuItem>
                    {uploadDocumentsRow?.data?.map((row, i) => (
                      <MenuItem value={formatDateToHumanFriendly(row?.uploaded_on)}>
                      {formatDateToHumanFriendly(row?.uploaded_on)}
                      </MenuItem>
                  ))}
                  </Select>
                  </FormControl> */}
                {/* Document Type */}
              </TableCell>
              <TableCell>
                DOCUMENT TYPE
                {/* {/* <FormControl className={classes.formControl}>
                <Select
                value={selectValue}
                name="selectValue"
                  onChange={handleChange}
                  displayEmpty
                  className="select-filed"
                  inputProps={{ 'aria-label': 'Without label' }}
                  >
                  <MenuItem value="" disabled>
                    File Name
                    </MenuItem>
                  {uploadDocumentsRow?.data?.map((row, i) => (
                    <MenuItem value={row?.filename}>{row?.filename}</MenuItem>
                    ))}
                </Select>
                </FormControl> */}
                {/* Description  */}
              </TableCell>

              <TableCell>DESCRIPTION</TableCell>
              <TableCell>LABEL</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isUploadDocuments ? (
              <TableRow className={classes.root}>
                {[1, 2, 3, 4]?.map(_ => (
                  <TableCell component="th" scope="row">
                    <Skeleton variant="rect" width={'100%'} height={'50px'} />
                  </TableCell>
                ))}
              </TableRow>
            ) : // ) : uploadDocumentsRow?.data?.length ? (
            //   uploadDocumentsRow?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
            uploadDocumentsRow?.data?.length ? (
              uploadDocumentsRow?.data?.map((row, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i} className="table-row">
                  <TableCell component="th" scope="row">
                    {formatDateToHumanFriendlyWithComa(row?.uploaded_on)}
                  </TableCell>
                  <TableCell>{row?.doc_type}</TableCell>
                  <TableCell>{row?.notes}</TableCell>
                  <TableCell>{row?.doc_label}</TableCell>
                  <TableCell>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="download"
                        color="primary"
                        disableRipple
                        className={buttonClassname}
                        onClick={() => {
                          getDownloadDocument(row?.company_id, row?.id)
                          setDocId(row?.id)
                        }}
                      >
                        {docId === row?.id && success ? <Done /> : <GetApp />}
                        &nbsp;
                      </Fab>
                      {docId === row?.id && isDownloadableLink ? (
                        <CircularProgress size={36} className={classes.fabProgress} />
                      ) : (
                        ''
                      )}
                    </div>
                    {/* <img src={DownloadIcon} alt="icon" onClick={() => getDownloadDocument(row?.company_id, row?.id)} /> */}
                    {/* <img src={PrintIcon} alt="icon" /> */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.root}>
                <TableCell>
                  <Alert severity="info">No Documents Found</Alert>
                </TableCell>
              </TableRow>
            )}
            {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
          </TableBody>
        </Table>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataCount ? dataCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog open={isAlertOpen} maxWidth="sm" disableBackdropClick fullWidth={true}>
        <br />
        <ModalTitle
          customTitleComponent={
            <Box display={'flex'} alignItems={'center'} ml={'1rem'}>
              <Warning color="error" style={{ marginRight: '0.5rem' }} />
              <h2 style={{ margin: 0 }}>Attention</h2>
            </Box>
          }
          toggleModal={setIsAlertOpen}
        />
        <DialogContent>
          <DialogContentText style={{ margin: 0 }}>
            {paymentLink
              ? `Your ${
                  currentDoc?.address_document ? 'Address' : 'Registered Agent'
                } Subscription is Unpaid. Kindly pay now
            to access the document.`
              : `Your  ${
                  currentDoc?.address_document ? 'Address' : 'Registered Agent'
                } Subscription doesn't Exists. Kindly Reach out to our Representative for Subscription Activation`}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {paymentLink && (
            <Button variant="contained" color="primary" onClick={() => window.open(`${paymentLink}`, '_blank')}>
              Pay Now
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CompanyDocumentTable
