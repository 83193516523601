import React from 'react'

export const changeNToBrTag = (text: string) => {
  return text?.split('\n').map((line, i) => (
    <span key={i}>
      {line}
      <br />
    </span>
  ))
}
