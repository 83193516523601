import * as React from 'react'
import { menuIconColor, selectedMenuIconColor } from '../theme'
import { IMenuSection } from './menu'
import { Dashboard, ExitToApp, Folder, Group, LocalOffer } from '@material-ui/icons'

const iconStyle = (selected: boolean) => ({
  color: selected ? selectedMenuIconColor : menuIconColor,
})

const accountantSection = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    renderIcon: ({ selected }) => <Dashboard style={{ ...iconStyle(selected), width: 20 }} />,
  },
  // {
  //   title: 'Clients',
  //   path: '/client',
  //   renderIcon: ({ selected }) => <Group style={{ ...iconStyle(selected), width: 20 }} />,
  // },
  // {
  //   title: 'Companies',
  //   path: '/companies',
  //   renderIcon: ({ selected }) => <Folder style={{ ...iconStyle(selected), width: 20 }} />,
  // },
  {
    title: 'Tax Orders',
    path: '/tax-orders',
    renderIcon: ({ selected }) => <LocalOffer style={{ ...iconStyle(selected), width: 20 }} />,
  },
  {
    title: 'Logout',
    path: '',
    renderIcon: ({ selected }) => <ExitToApp style={iconStyle(selected)} />,
  },
]

export default accountantSection
