import MomentUtils from '@date-io/moment'
import { UseSnackbarUI } from '@jetkit/react'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { isLoggedIn } from 'axios-jwt'
import * as React from 'react'
import { Route, RouteComponentProps, Switch, withRouter, Redirect, Router } from 'react-router-dom'
import Notifications from './components/notification'
import { IUser } from './models/user'
import Nav from './nav'
import Login from './pages/login'
import { routes } from './routes'
import withTheme from './theme'
import useRouter from 'use-react-router'
import { USERTYPES } from 'utils/constant'
import NotesNotify from 'components/notesNotify'
import { updatePageVisitsCount, updateUserlog } from 'utils/user'
import ClickAnywhereWrapper from 'components/clickAnywhereWrapper'
import clientSections from 'nav/clientSection'
import salesAgentSection from 'nav/salesAgentSection'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const styles = createStyles({
  bodyContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
})

interface IAppProps extends WithStyles<typeof styles> {}

interface IAppStateProps {
  user?: IUser
}
interface AppContextValue {
  unreadDocsCount: number
  setUnreadDocsCount: (val: number) => void
  missingItemsCount: number
  setMissingItemsCounts: (val: number) => void
}

export const AppContext = React.createContext<AppContextValue>({
  unreadDocsCount: 0,
  setUnreadDocsCount: val => null,
  missingItemsCount: 0,
  setMissingItemsCounts: val => null,
})

const App: React.FC<IAppProps & IAppStateProps & RouteComponentProps> = ({ classes, user }) => {
  const loc = useRouter()
  const { history } = useRouter()

  const theme = useTheme()
  const isCollaps = useMediaQuery(theme.breakpoints.down(959))
  const [unreadDocsCount, setUnreadDocsCount] = React.useState(0)
  const [missingItemsCount, setMissingItemsCounts] = React.useState(0)

  // const [clicks, setClicks] = React.useState<number>(0)
  // const [clicks, setClicks] = React.useState(parseInt(localStorage.getItem('user_clicks') || '0'))

  // React.useEffect(() => {
  //   const clicks = localStorage.getItem('user_clicks')
  //   setClicks(JSON.parse(clicks || ''))
  // }, [])

  // const [updateLogs, setUpdateLogs] = React.useState(false)

  // const updateClicks = () => {
  //   setClicks(state => state + 1)
  // }
  // const ref = useOutsideClick(updateClicks)

  const resetWindowScrollPosition = React.useCallback(() => window.scrollTo(0, 0), [])

  React.useEffect(() => {
    isLoggedIn() && updatePageVisitsCount()
  }, [history.location.pathname])

  const checkingUserType = (userType: string) => {
    switch (userType) {
      case USERTYPES.admin:
        return history.push('/dashboard')
      case USERTYPES.client:
        return history.push('/client-dashboard')
      case USERTYPES.employee:
        return history.push('/dashboard')
      case USERTYPES.salesRep:
        return history.push('/client-dashboard')
      case USERTYPES.accountant:
        // return history.push('/tax-orders')
        return history.push('/dashboard')
      case USERTYPES.salesAgent:
        return history.push('/web-submissions')
      default:
        break
    }
  }
  const getBaseRoute = (path: string) => {
    const basePath = path.split('/')[1]
    return basePath
  }
  React.useEffect(() => {
    resetWindowScrollPosition()
    if (isLoggedIn()) {
      const userDetails = JSON?.parse(localStorage?.getItem('currentUser') || '')
      const userType = userDetails?.user_type

      if (userType === USERTYPES.client || userType === USERTYPES.salesRep) {
        const authorizedRoute = clientSections.some(
          item =>
            getBaseRoute(history?.location?.pathname) === getBaseRoute(item.path) ||
            getBaseRoute(history?.location?.pathname) === 'power-up' ||
            getBaseRoute(history?.location?.pathname) === 'power-up/all' ||
            getBaseRoute(history?.location?.pathname) === 'credit-score'
        )
        if (!authorizedRoute) {
          history.push('/client-dashboard')
        }
      }
      if (userType === USERTYPES.salesAgent) {
        const authorizedRoute = salesAgentSection.some(
          item =>
            getBaseRoute(history?.location?.pathname) === getBaseRoute(item.path) ||
            getBaseRoute(history?.location?.pathname) === 'web-submission' ||
            getBaseRoute(history?.location?.pathname) === 'prospect' ||
            getBaseRoute(history?.location?.pathname) === 'dashboard'
        )
        if (!authorizedRoute) {
          history.push('/web-submissions')
        }
      }
      if (history?.location?.pathname === '/' || history?.location?.pathname === '*') {
        checkingUserType(userType)
      }
    }
  }, [history?.location?.pathname])
  // React.useEffect(() => {
  //   const empLogUpdate = async () => {
  //     const userDetails = JSON?.parse(localStorage?.getItem('currentUser') || '')
  //     const userType = userDetails?.user_type
  //     try {
  //       if (userType !== USERTYPES.client) await updateUserlog()
  //       setUpdateLogs(false)
  //     } catch (error) {
  //       setUpdateLogs(false)
  //     }
  //   }
  //   isLoggedIn() && updateLogs && empLogUpdate()
  // }, [updateLogs, isLoggedIn])

  // const timer = setTimeout(() => {
  //   setUpdateLogs(true)
  // }, 300000)

  // React.useEffect(() => {
  //   return () => clearTimeout(timer)
  // }, [timer])
  //
  if (
    !isLoggedIn() &&
    !loc.location.pathname.includes('/register') &&
    !loc.location.pathname.includes('/create-password') &&
    !loc.location.pathname.includes('/forgot-username') &&
    !loc.location.pathname.includes('/forgot-password')
  ) {
    return (
      <React.Fragment>
        <UseSnackbarUI />
        <Login />
      </React.Fragment>
    )
  }

  // console.log(clicks, 'clicks')

  // const updateClicks = () => {
  //   setClicks(state => state + 1)

  return (
    <AppContext.Provider value={{ unreadDocsCount, setUnreadDocsCount, missingItemsCount, setMissingItemsCounts }}>
      <ClickAnywhereWrapper>
        <div className={classes.mainContainer}>
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
            rel="stylesheet"
          ></link>
          <UseSnackbarUI />
          {process.env.REACT_APP_WEBSOCKETS_ENABLED === '1' && <Notifications />}

          {/* for task notify */}
          <NotesNotify />

          {!loc.location.pathname.includes('/create-password') &&
          !loc.location.pathname.includes('/forgot-username') &&
          !loc.location.pathname.includes('/forgot-password') &&
          !loc.location.pathname.includes('/register') &&
          !loc.location.pathname.includes('/auth') ? (
            <div className={classes.bodyContainer}>
              <Nav user={user} />
              <main style={{ width: isCollaps ? 'calc(100% - 64px)' : 'calc(100% - 296px)' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Switch>
                    {routes.map(({ path, component }) => (
                      <Route key={path} path={path} component={component} exact />
                    ))}
                    {/* <Route path="*">
                  <PageNotFound />
                </Route> */}
                    <Redirect to="/" />
                  </Switch>
                </MuiPickersUtilsProvider>
              </main>
            </div>
          ) : (
            <div className={classes.bodyContainer}>
              <main style={{ width: '100%' }}>
                {/* <main style={{ width: 'calc(100% - 296px)' }}> */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Switch>
                    {routes.map(({ path, component }) => (
                      <Route key={path} path={path} component={component} exact />
                    ))}
                    {/* <Route path="*">
                  <PageNotFound />
                </Route> */}
                    <Redirect to="/" />
                  </Switch>
                </MuiPickersUtilsProvider>
              </main>
            </div>
          )}
        </div>
      </ClickAnywhereWrapper>
    </AppContext.Provider>
  )
}

export default withTheme(withRouter(withStyles(styles)(App)))

/* TODO: #346 should have a dict with (route, component) and here have a routes.map() code. will be useful for testing with routes */
