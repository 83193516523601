import { IAddress, IAddressType } from 'models/address'

export enum FieldType {
  autocomplete = 'autocomplete',
  text = 'text',
  address = 'address',
  date = 'date',
  dateTime = 'date_time',
  number = 'number',
  // Custom type created for adding things unrelated to form ( tables, images, secondary buttons, etc )
  // It could be any component.
  custom = 'custom',
}
/**
 * Base parameters for all components that are considered as form fields
 */
interface IBaseFieldParams {
  name: string
  label: string
  customComponent?: React.ReactNode
  multiline?: boolean
  row?: number
  disabled?: boolean
}

interface IAutocompleteParams extends IBaseFieldParams {
  options: unknown[]
  getOptionLabel?: (value: unknown) => string
  freeSolo?: boolean
}

interface IAddressParams extends IBaseFieldParams {
  options: IAddress[]
  entity_id: number
  addressType?: IAddressType
}

export type SetFieldValueFunctionType = (field: string, value: unknown) => void

export type IFormFieldProps<T> = {
  field: BaseField
  value: T
}

export interface IRow {
  fields: Base[]
}

// Base class for component element
export class Base {
  constructor(public type: FieldType) {}
}

export class CustomComponent extends Base {
  constructor(public render: () => React.ReactNode) {
    super(FieldType.custom)
  }
}

// Base class for form field element
export class BaseField extends Base {
  constructor(
    type: FieldType,
    public label: string,
    public name: string,
    public multiline?: boolean,
    public row?: number,
    public customComponent?: React.ReactNode,
    public disabled?: boolean
  ) {
    super(type)
  }
}

export class TextField extends BaseField {
  constructor(params: IBaseFieldParams) {
    super(FieldType.text, params.label, params.name, params.multiline, params.row, params.customComponent ?? undefined)
  }
}

export class AutocompleteField extends BaseField implements IAutocompleteParams {
  options: unknown[]
  getOptionLabel?: (value: unknown) => string
  freeSolo?: boolean

  constructor(params: IAutocompleteParams) {
    super(
      FieldType.autocomplete,
      params.label,
      params.name,
      params.multiline,
      params.row,
      params.customComponent ?? undefined
    )
    this.options = params.options
    this.getOptionLabel = params.getOptionLabel
    this.freeSolo = params.freeSolo
  }
}

export class DateField extends BaseField {
  constructor(params: IBaseFieldParams) {
    super(FieldType.date, params.label, params.name, params.multiline, params.row, params.customComponent)
  }
}

export class NumberField extends BaseField {
  constructor(params: IBaseFieldParams) {
    super(FieldType.number, params.label, params.name, params.multiline, params.row, params.customComponent)
  }
}

export class DateTimeField extends BaseField {
  constructor(params: IBaseFieldParams) {
    super(FieldType.dateTime, params.label, params.name, params.multiline, params.row, params.customComponent)
  }
}

export class AddressField extends BaseField {
  options: IAddress[]
  addressType?: IAddressType
  entity_id: number

  constructor(params: IAddressParams) {
    super(FieldType.address, params.label, params.name, params.multiline, params.row, params.customComponent)
    this.options = params.options
    this.addressType = params.addressType
    this.entity_id = params.entity_id
  }
}
