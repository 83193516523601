export interface ICommission {
  type: string
  reference: string
  amount_cents: number
  client_extid?: string
  order_id?: number
  affiliate_extid?: string
  transaction_date?: string
  client_id?: number
}

export interface ICommissionTypes {
  commission_types: string[]
}

export const CommissionTypesEnum = {
  withdrawal: 'withdrawal',
  applyCredit: 'apply_credit',
  commissionFromOrder: 'commission_from_order',
  adjustment: 'adjustment',
}

export const NegativeOnlyCommissionTypes = [CommissionTypesEnum.withdrawal, CommissionTypesEnum.applyCredit]
