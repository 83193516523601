import { IUserCredential, authService, isLoggedIn } from '@jetkit/react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import useUser from 'hooks/useUser'
import Logo from 'icons/logo'
import { IUser } from 'models/user'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import showApiResponseError from 'utils/showApiResponseError'
import { COOKIEE_NAMES, FIELDS_MIN_LENGTH } from 'utils/constant'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  CardHeader,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import BRTextField from 'components/common/textFields/BRTextField'
import { checkUsername, registerUser, verifyUserEmail } from 'api/users'
import PhoneInput from 'components/clients/create/phoneInput'
import { RadioButtonUnchecked, CheckCircle, Visibility, VisibilityOff } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import AuthBG from 'img/auth-bg.webp'
import showErrorNotification from 'utils/showErrorNotification'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 'calc(400px - 40px)',
      padding: '1.5rem 2.5rem',

      '& .MuiOutlinedInput-root': {
        borderRadius: '2rem',
        background: '#E8F0FE',
      },

      '& .checkbox-section': {
        maxWidth: '500px',
        paddingBottom: '1rem',

        '& .unCheck-icon': {
          color: '#26316c',
        },
        '& .check-icon': {
          color: '#26316c',
        },
        '& .MuiTypography-body1': {
          fontSize: '0.85rem',
          color: 'gray',
          fontFamily: 'Montserrat, sans-serif',
          marginTop: '0.5rem',
        },
      },
    },
    mainContainer: {
      backgroundImage: `url(${AuthBG})`,
      height: 'calc(100vh - 5rem)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',

      [theme.breakpoints.down('xs')]: {
        height: '100vh',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '5rem 0',

      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },

      '& .MuiPaper-root': {
        boxShadow: '0 3px 6px 0 rgba(120, 129, 144, 0.8)',

        [theme.breakpoints.down('xs')]: {
          boxShadow: 'none',
        },
      },
      '& .MuiButtonBase-root.Mui-disabled': {
        pointerEvents: 'inherit',
      },
    },
    title: {
      color: '#343b4c',
      fontSize: '1.5rem',
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center',
    },
    submitButton: {
      height: 45,
      color: '#fff',
      fontSize: '1rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      backgroundColor: '#5b38d9',
      // backgroundColor: '#26316c',
      justifyContent: 'center',
      borderRadius: '2rem',
      padding: '1rem 0',
      width: '200px',

      '&:hover': {
        backgroundColor: '#2479af',
        color: '#fff',
      },
    },
    disableButton: {
      color: '#fff !important',
      cursor: 'not-allowed !important',
      backgroundColor: '#2479af !important',

      '&:hover': {
        background: '#2479af',
      },
    },
    rootProgress: {
      width: 'calc(400px + 40px)',

      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    fullWidth: {
      width: '100%',

      '& > div': {
        '& input': {
          borderRadius: '2rem!important',
        },
        '& .flag-dropdown': {
          borderRadius: '2rem 0 0 2rem !important',
        },
        '& .selected-flag': {
          borderRadius: '2rem 0 0 2rem !important',
          background: 'transparent !important',
        },
      },
    },

    btnContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      // marginBottom: '2rem',

      '& .MuiButtonBase-root.Mui-disabled': {
        pointerEvents: 'inherit',
      },
      '& .footer-text': {
        color: '#343b4c',
        fontSize: '0.75rem',
        fontFamily: 'Montserrat, sans-serif',
        textAlign: 'center',
      },
    },
    footerLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      '& a': {
        color: '#407ace',
        margin: '1rem',
      },
    },
  })

interface ILoginProps extends WithStyles<typeof styles> {}

interface IBrUserCredential extends IUserCredential {
  user?: IUser
}

const Register: React.FC<ILoginProps & RouteComponentProps> = ({ classes, history }) => {
  const refQueryParams = new URLSearchParams(window.location.search).get('ref')

  console.log('refref', refQueryParams)

  const [loading, setLoading] = React.useState(false)
  const [username, setUsername] = React.useState<string>('')
  const [errorUsername, setErrorusername] = React.useState<boolean>(false)
  const [errorUsernameText, setErrorusernameText] = React.useState<string>('')
  const [usernameFieldDisabled, setUsernameFieldDisabled] = React.useState<boolean>(false)

  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  const [passError, setpassError] = React.useState<boolean>(false)
  const [passlengthError, setpasslengthError] = React.useState<boolean>(false)

  const [isChecked, setIsChecked] = React.useState<boolean>(false)

  const [isDisabled, setIsDisabled] = React.useState<boolean>(false)
  const [isDisabledContinue, setIsDisabledContinue] = React.useState<boolean>(false)
  const [passwordError, setPasswordError] = React.useState('')

  const { updateUser } = useUser()
  const [showtwofa, setShowtfa] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState({})
  const [cookies, setCookie] = useCookies([COOKIEE_NAMES.device])
  const [isContinue, setisContinue] = React.useState<boolean>(false)

  const [inputFields, setInputValues] = React.useState({
    fullName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  })

  React.useEffect(() => {
    if (isLoggedIn()) {
      history.push('/')
    }
  }, [history])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  React.useEffect(() => {
    if (inputFields?.password) {
      setpassError(true)
      if (inputFields?.password.length < 8) {
        setpasslengthError(true)
      } else setpasslengthError(false)
    } else setpassError(false)
  }, [inputFields?.password])

  // const userNameCheck = async (username: string) => {
  //   try {
  //     setLoading(true)
  //     setUsernameFieldDisabled(true)
  //     const res: any = await checkUsername({ username: username })
  //     setLoading(false)
  //     setUsernameFieldDisabled(false)
  //     if (res.error && username) {
  //       setErrorusername(true)
  //       setErrorusernameText('Sorry! username already exists!')
  //     } else {
  //       setErrorusername(false)
  //     }
  //   } catch (e) {
  //     showApiResponseError(e, 'Error Checking username')
  //   }
  // }

  // React.useEffect(() => {
  //   // const userNameCheck = async () => {
  //   //   try {
  //   //     setLoading(true)
  //   //     setUsernameFieldDisabled(true)
  //   //     const res: any = await checkUsername({ username: username })
  //   //     setLoading(false)
  //   //     setUsernameFieldDisabled(false)
  //   //     if (res.error && username) {
  //   //       setErrorusername(true)
  //   //       setErrorusernameText('Sorry! username already exists!')
  //   //     } else {
  //   //       setErrorusername(false)
  //   //     }
  //   //   } catch (e) {
  //   //     showApiResponseError(e, 'Error Checking username')
  //   //   }
  //   // }
  //   const reg = /^[A-Za-z0-9]*$/

  //   // client.username &&
  //   //   client.username?.length >= FIELDS_MIN_LENGTH.username &&
  //   //   // reg.test(client.username) &&
  //   //   usernameChanged &&
  //   //   userNameCheck()
  //   // debugger
  //   if (username) {
  //     if (reg.test(username)) {
  //       if (username && username?.length < FIELDS_MIN_LENGTH.username) {
  //         setErrorusername(true)
  //         setErrorusernameText(`Username cannot be less than ${FIELDS_MIN_LENGTH.username} characters`)
  //         // } else if (username && username?.length >= FIELDS_MIN_LENGTH.username) {
  //         //   setTimeout(() => {
  //         //     userNameCheck()
  //         //   }, 500)
  //       } else {
  //         setErrorusername(false)
  //         setErrorusernameText('')
  //       }
  //     } else {
  //       setErrorusername(true)
  //       setErrorusernameText(`Username should contain Alpha numeric Only`)
  //     }
  //   }
  // }, [username])

  // const onBlurCapture = () => {
  //   if (username && username?.length > FIELDS_MIN_LENGTH.username) {
  //     userNameCheck(username || '')
  //   }
  // }

  React.useEffect(() => {
    if (!inputFields?.fullName?.length || !inputFields?.email?.length || !inputFields?.phone?.length) {
      setIsDisabledContinue(true)
      setIsDisabled(false)
    } else if (!inputFields?.password?.length || !inputFields?.confirmPassword?.length || passwordError || !passError) {
      setIsDisabledContinue(false)
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
      setIsDisabledContinue(false)
    }
  }, [
    inputFields?.confirmPassword?.length,
    inputFields?.email?.length,
    inputFields?.fullName?.length,
    inputFields?.password?.length,
    inputFields?.phone?.length,
    passError,
    passwordError,
  ])

  React.useEffect(() => {
    const isPasswordValid = inputFields.password === inputFields.confirmPassword
    setPasswordError(isPasswordValid ? '' : 'Passwords do not match')
  }, [inputFields.password, inputFields.confirmPassword])

  //
  //
  const [clientsList, setClientsList] = React.useState([])
  const [subClients, setSubClients] = React.useState(false)

  const fullNameSplite = inputFields?.fullName?.split(' ')

  const onSubmitClick = async () => {
    if (isDisabled) {
      return
    }

    try {
      setLoading(true)
      setUsernameFieldDisabled(true)
      // const res: any = await checkUsername({ username: username })
      setLoading(false)
      // setUsernameFieldDisabled(false)
      // if (res.error && username) {
      //   setErrorusername(true)
      //   setErrorusernameText('Sorry! username already exists!')
      // } else {
      //   setErrorusername(false)

      try {
        setLoading(true)

        const payload = {
          first_name: fullNameSplite[0] || '',
          middle_name: fullNameSplite[2] ? fullNameSplite[1] : '',
          last_name: fullNameSplite[2] ? fullNameSplite[2] : fullNameSplite[1] || '',
          date_of_birth: '',
          email: inputFields?.email,
          phone: inputFields?.phone,
          username: '',
          password: inputFields?.password,
          smsConsend: isChecked,
          refcode: refQueryParams ? refQueryParams : '',
        }

        let user: any = await registerUser(payload)
        debugger
        setLoading(false)
        if (user?.error) {
          showApiResponseError(user?.error, user?.error)
        }
        user = await authService.login(inputFields.email, inputFields.password)

        // history.push('/dashboard')
        // setCurrentUser(user)
        if (user?.clientList?.length) {
          // apiClient.defaults.headers = { ...apiClient.defaults.headers, Authorization: `Bearer ${user?.accessToken}` }
          setSubClients(true)
          setClientsList(user?.clientList)
          debugger
          localStorage.setItem('parent_user', JSON.stringify(user))

          setLoading(false)
          if (user?.error) {
            showApiResponseError(user?.error, user?.error)
          }
          if (user.user?.device) {
            setCookie(COOKIEE_NAMES.device, user.user.device, { maxAge: 31536000 })
          }

          setCurrentUser(user)
          localStorage.setItem('page_visits', '0')
          localStorage.setItem('user_clicks', '0')

          if (user.twoFA) {
            // return <TwoFA user />
            setShowtfa(true)
          } else {
            console.log('in else')

            updateUser(user.user || null)

            // history.push({ pathname: '/client-dashboard', state: { isPopup: true } })
            history.push({ pathname: '/client-dashboard' })
          }
        } else {
          // Remove device header After login
          // delete apiClient.defaults.headers.device

          setLoading(false)
          if (user?.error) {
            showApiResponseError(user?.error, user?.error)
          }
          if (user.user?.device) {
            setCookie(COOKIEE_NAMES.device, user.user.device, { maxAge: 31536000 })
          }
          //localStorage.removeItem('auth-tokens-development')
          // history.push('/auth', {user})
          setCurrentUser(user)
          localStorage.setItem('page_visits', '0')
          localStorage.setItem('user_clicks', '0')

          if (user.twoFA) {
            // return <TwoFA user />
            setShowtfa(true)
          } else {
            console.log('in else')

            updateUser(user.user || null)

            // checkingUserType(user?.user?.user_type)
            // history.push({ pathname: '/client-dashboard', state: { isPopup: true } })
            history.push({ pathname: '/client-dashboard' })
          }
        }
        localStorage.setItem('page_visits', '0')
        localStorage.setItem('user_clicks', '0')
      } catch (err) {
        debugger
        showApiResponseError(err, 'Error: Invalid Username or Password')
        setLoading(false)
      }
      // }
    } catch (e) {
      showApiResponseError(e, 'Error Checking username')
    }

    // try {
    //   setLoading(true)

    //   const payload = {
    //     first_name: fullNameSplite[0] || '',
    //     middle_name: fullNameSplite[2] ? fullNameSplite[1] : '',
    //     last_name: fullNameSplite[2] ? fullNameSplite[2] : fullNameSplite[1] || '',
    //     date_of_birth: '',
    //     email: email,
    //     phone: phone,
    //     username: username,
    //     password: password,
    //     smsConsend: isChecked,
    //   }

    //   const user: any = await registerUser(payload)
    //   debugger
    //   setLoading(false)
    //   if (user?.error) {
    //     showApiResponseError(user?.error, user?.error)
    //   }
    //   history.push('/dashboard')
    //   setCurrentUser(user)
    //   localStorage.setItem('page_visits', '0')
    //   localStorage.setItem('user_clicks', '0')
    // } catch (err) {
    //   debugger
    //   showApiResponseError(err, 'Error: Invalid Username or Password')
    //   setLoading(false)
    // }
  }

  const onInputFieldsChange = (key: 'fullName' | 'email' | 'phone' | 'password' | 'confirmPassword', value: string) => {
    setInputValues(prevInputValues => ({
      ...prevInputValues,
      [key]: value,
    }))
  }

  const handleVerifyEmail = async () => {
    setLoading(true)
    try {
      const res = await verifyUserEmail({ email: inputFields?.email })
      if (res.success) {
        setisContinue(true)
      } else {
        showErrorNotification(res.error)
      }
      setLoading(false)

      console.log('resresresresres', res)
    } catch (error) {
      showErrorNotification(error)
    }
    setLoading(false)
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <Card className={classes.root}>
          <Logo />
          <CardHeader title="Register" classes={{ title: classes.title }} />
          <CardContent>
            {!isContinue ? (
              <>
                <BRTextField
                  required
                  data-testid="name"
                  // error={clientEditErrors.password}
                  // helperText={passlengthError && 'Password cannot be less than 8 characters!'}
                  label="Name"
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorHelperText,
                    },
                  }}
                  type="text"
                  name="fullName"
                  margin="normal"
                  variant="outlined"
                  value={inputFields.fullName}
                  // onChange={event => setFullName(event.target.value)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onInputFieldsChange('fullName', event.target.value)
                  }
                />
                <BRTextField
                  required
                  data-testid="client-email"
                  // error={email?.length < 3 ? true : false}
                  // helperText={passlengthError && 'Password cannot be less than 8 characters!'}
                  label="Email"
                  //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
                  //className={classes.textField}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorHelperText,
                    },
                  }}
                  type="text"
                  name="email"
                  margin="normal"
                  variant="outlined"
                  value={inputFields.email}
                  // onChange={event => setEmail(event.target.value)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onInputFieldsChange('email', event.target.value)
                  }
                />
                <FormControl className={classes.fullWidth}>
                  <PhoneInput
                    value={inputFields?.phone || ''}
                    // onChange={value => setPhone(value)}
                    onChange={value => onInputFieldsChange('phone', value)}
                    // error={clientEditErrors['phone_number']}
                  />
                  {/* {clientEditErrors['phone_number'] && (
                <FormHelperText className={classes.errorHelperText}>Please enter phone number</FormHelperText>
              )} */}
                </FormControl>
                <div className="checkbox-section">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUnchecked className="unCheck-icon" />}
                          checkedIcon={<CheckCircle className="check-icon" />}
                          name="isChecked"
                          checked={isChecked}
                          data-cy="isChecked"
                          onChange={event => {
                            setIsChecked(event.target.checked)
                          }}
                        />
                      }
                      label="Send me order updates SMS messages. Opt-out anytime."
                    />
                  </FormGroup>
                </div>
              </>
            ) : (
              <>
                {' '}
                <BRTextField
                  required
                  data-testid="client-password"
                  error={passlengthError}
                  helperText={passlengthError && 'Password cannot be less than 8 characters!'}
                  label="Password"
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorHelperText,
                    },
                  }}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  margin="normal"
                  variant="outlined"
                  value={inputFields.password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onInputFieldsChange('password', event.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <BRTextField
                  required
                  data-testid="client-password"
                  error={passwordError && inputFields?.confirmPassword ? true : false}
                  helperText={passwordError && inputFields?.confirmPassword && passwordError}
                  label="Confirm Password"
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorHelperText,
                    },
                  }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  margin="normal"
                  variant="outlined"
                  value={inputFields?.confirmPassword}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onInputFieldsChange('confirmPassword', event.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}

            {/* <p className={classes.HelperTextUsername}>
              *To Create an Account Kindly choose Username of minimum length {FIELDS_MIN_LENGTH.username}
            </p> */}
            {/* <BRTextField
              disabled={usernameFieldDisabled}
              data-testid="client-username"
              error={errorUsername}
              helperText={errorUsername && errorUsernameText}
              label="User Name"
              style={{ marginBottom: errorUsername ? '2.5rem' : undefined }}
              className={classes.textField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorHelperText,
                },
              }}
              type="text"
              name="name"
              margin="normal"
              variant="outlined"
              value={username}
              onChange={event => setUsername(event.target.value)}
              autoComplete=""
              onBlur={onBlurCapture}
            /> */}
          </CardContent>

          <CardActions classes={{ root: classes.btnContainer }}>
            {!isContinue ? (
              <>
                <Button
                  size="large"
                  fullWidth
                  color="primary"
                  disabled={isDisabledContinue}
                  className={classes.submitButton}
                  // onClick={() => setisContinue(true)}
                  onClick={handleVerifyEmail}
                  classes={{ disabled: classes.disableButton }}
                >
                  Continue
                </Button>
                <p className="footer-text">
                  By providing a telephone number and submitting this form you are consenting to be contacted by SMS
                  text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
                </p>
              </>
            ) : (
              <Button
                size="large"
                fullWidth
                color="primary"
                disabled={isDisabled}
                className={classes.submitButton}
                onClick={onSubmitClick}
                classes={{ disabled: classes.disableButton }}
              >
                Submit
              </Button>
            )}
          </CardActions>

          {/* <Grid container spacing={0} alignContent="center" justify="center" alignItems="center">
          <Grid item xs={5}>
            <Divider />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            OR
          </Grid>
          <Grid item xs={5}>
            <Divider />
          </Grid>
        </Grid> */}

          <div className={classes?.footerLinks}>
            <Link to="/">Login</Link>
          </div>
        </Card>

        {loading && (
          <div className={classes.rootProgress}>
            <LinearProgress />
          </div>
        )}
        {/* <div style={{ margin: '1rem 0' }}>
        Have already an account? &nbsp;
        <Link style={{ margin: '0.5rem' }} to="/">
          Login here.
        </Link>
      </div> */}
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(Register))
