import { createStyles, FormControl, FormHelperText, makeStyles, Theme } from '@material-ui/core'
import { deleteAddress, editAddress } from 'api/addresses'
import { createClient, updateClient } from 'api/cities'
import { fetchSuggestions } from 'api/suggestions'
import CommissionAccordion from 'components/clients/commissions/accordion'
import EinSsnInput from 'components/clients/create/einSsnInput'
import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient, ICreatedState, IStates } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { validateEmail } from 'utils/validators'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { ISuggestionAttribute } from 'components/interfaces'
import { PrimaryAddress } from 'components/companies/companyCreation/firstStep/PrimaryAddress'
import { useState } from 'react-transition-group/node_modules/@types/react'
import { IInputFieldsErrors } from 'components/companies/create'
import { MailingAddress } from 'components/companies/companyCreation/firstStep/MailingAddress'
import SelectAutocomplete from '../../../components/common/SelectAutocomplete'
import { getstates, getcities } from 'api/addresses'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: IStates = {
  id: 0,
  //state_name: '',
  state_display_name: '',
  //country_id: 0,
  //country_name: '',
  //city_name: '',
  state_id: 0,
}

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

export interface IClientDetailsProps {
  editedClient?: IStates
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedState) => void
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

// Because date returned from backend, is in different format, that existing validator accepts,
// we format it properly first
const extractDateFromClient = (client: IClient): string | undefined => {
  if (!client.dob) return undefined
  return backendDateStringToFrontendFormat(client.dob)
}

const validateName = (client: IClient) => {
  return !(!client.name || client.name.trim().length < 3)
}

const validateAddress = (client: IClient): boolean => {
  const { addresses } = client
  if (addresses && addresses.length) {
    if (!addresses.find(address => address.is_primary === true)) return false
  }
  return true
}

const validateDob = (client: IClient) => {
  if (!client.dob) return true
  const selectedDate = dateFromString(client.dob)
  if (!selectedDate.isValid() || selectedDate.isAfter(moment())) {
    return false
  }
  return true
}

// Backend accepts addresses with `id` field and unprocessable entities
const addressesWithoutIds = (client: IClient): IAddress[] | undefined => {
  if (!client.addresses) return undefined
  return client.addresses.map(address => {
    delete address.id
    return address
  })
}

// const updateClientRequest = async (client: IStates) => {
//   const editedClient = client
//   const resClient = await updateClient({
//     // We pick certain fields from editedClient object, because client,
//     // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
//     // if we send them to PATCH `user/:id`
//    // extid: editedClient.extid,
//     id: editedClient.id,
//     state_name: editedClient.state_name,
//     state_display_name: editedClient.state_display_name,
//     country_id: editedClient.country_id,
//     country_name: editedClient.country_name,

//   })
//   return resClient
// }

// const createClientRequest = async (client: IStates) => {
//     countriesdata.
//   const resClient = await createClient({ ...client })
//   return resClient
// }

const addressesWithNewPrimary = (addresses: IAddress[], newPrimaryAddress: IAddress | null): IAddress[] =>
  addresses.map((address: IAddress) => {
    if (newPrimaryAddress && address.id === newPrimaryAddress.id) {
      address.is_primary = true
    }
    if (!newPrimaryAddress || (address.id !== newPrimaryAddress.id && address.is_primary)) {
      address.is_primary = false
    }
    return address
  })

const ClientDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful /*, initialClient*/ }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<IStates>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [addressesToDelete, setAddressesToDelete] = React.useState<number[]>([])
  const [states, setstates] = React.useState<any>([])

  const [countriesdata, setcountriesdata] = React.useState<any>([
    {
      id: 1,
      name: 'USA',
    },
    {
      id: 2,
      name: 'Canada',
    },
  ])
  const { history } = useRouter()
  const updateClientRequest = async (client: IStates) => {
    let id = 0
    states.map((item: any) => {
      if (item.state_display_name === client.state_display_name) {
        id = item.id
      }
    })
    client.state_id = id
    const editedClient = client
    const resClient = await updateClient({
      // We pick certain fields from editedClient object, because client,
      // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
      // if we send them to PATCH `user/:id`
      // extid: editedClient.extid,
      id: editedClient.id,
      city_name: editedClient.city_name,
      state_display_name: editedClient.state_display_name,
      state_id: editedClient.state_id,
      // country_name: editedClient.country_name,
    })
    return resClient
  }

  const createClientRequest = async (client: IStates) => {
    let id = 1
    states.map((item: any) => {
      if (item.state_display_name === client.state_display_name) {
        id = item.id
      }
    })
    client.state_id = id
    const resClient = await createClient({ ...client })
    return resClient
  }
  const addAttribute = React.useCallback(
    <K extends keyof Pick<IClient, 'sales_rep_id' | 'affiliate_id'>>(field: K, id: IClient[K]) => {
      setClient({
        ...client,
        [field]: id,
      })
    },
    [setClient, client]
  )

  const toggleAddAddressDialog = React.useCallback(() => {
    setShowAddAddressDialog(!showAddAddressDialog)
    setCurrentEditedAddress(emptyAddress)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const changeClientField = React.useCallback(
    (field: keyof IStates, value: ValueOf<IStates>) => {
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const validateFields = React.useCallback((): boolean => {
    ////
    const isValid = true
    const tmpHelperTexts = helperTexts
    // if (!validateName(client)) {
    //   clientEditErrors.setError('name', true)
    //   isValid = false
    // }
    // if (!client.email || !validateEmail(client.email)) {
    //   clientEditErrors.setError('email', true)
    //   tmpHelperTexts.email = 'Please Specify Email'
    //   isValid = false
    // }
    // if (client.dob && client.dob !== '' && !validateDob(client)) {
    //   clientEditErrors.setError('dob', true)
    //   isValid = false
    // }
    // if (!validateAddress(client)) {
    //   clientEditErrors.setError('no_primary_address', true)
    //   isValid = false
    // }
    // setHelperTexts(tmpHelperTexts)
    return isValid
  }, [helperTexts, client, clientEditErrors])

  const sendAddressDeletionRequests = React.useCallback(() => {
    addressesToDelete.forEach(async addressId => {
      try {
        deleteAddress(addressId)
      } catch (err) {
        showApiResponseError(err, `Failed to delete address ${addressId}`)
      }
    })
  }, [addressesToDelete])

  const sendAddressUpdateRequests = React.useCallback(() => {
    addressesToEdit.forEach(async address => {
      try {
        const cleanedUpAddress: IAddress = removeNullAndUndefined(address) as IAddress
        await editAddress(cleanedUpAddress)
      } catch (err) {
        showApiResponseError(err, `Failed to update address ${address.id}`)
      }
    })
  }, [addressesToEdit])

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    if (!validateFields()) return false
    try {
      let clientResponse: ICreatedState
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)

        // When creating a new client, the attached addresses are without client ids.
        // This prevents us from sending a request to create a new address and link it with the client.
        const addressesToCreateWithClientIds = addressesToAdd
        addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
        setAddressesToAdd(addressesToCreateWithClientIds)
      }

      if (addressesToDelete.length) {
        sendAddressDeletionRequests()
      }
      if (addressesToEdit.length) {
        sendAddressUpdateRequests()
      }

      const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
      setClient(updatedClient)
    } catch (err) {
      const errorMessage: string | undefined = err?.response?.data?.message
      const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
      const tmpHelperTexts = {
        email: '',
        ssn_ein: '',
      }
      if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
        if (errorMessage.includes('email')) {
          clientEditErrors.setError('email', true)
          tmpHelperTexts.email = 'This email is already in use'
        }
        if (errorMessage.includes('ssn_ein')) {
          clientEditErrors.setError('ssn_ein', true)
          tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
        }
        setHelperTexts(tmpHelperTexts)
        return false
      }
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }, [
    validateFields,
    formMode,
    addressesToDelete.length,
    addressesToEdit.length,
    client,
    addressesToAdd,
    sendAddressDeletionRequests,
    sendAddressUpdateRequests,
    clientEditErrors,
  ])

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedState = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const removeAddressFieldsErrors = React.useCallback((key: AddressError) => addressEditErrors.setError(key, false), [
    addressEditErrors,
  ])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      removeAddressFieldsErrors(key as AddressError)
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
      })
    },
    [removeAddressFieldsErrors, setCurrentEditedAddress, currentEditedAddress]
  )

  // We cannot really use `useMemo()` here, because it will cause error messages to appear once
  // we open addressEditionDialog. The reason for that is, that once we open it, fields are empty.
  // Empty fields are not valid for a request, but are OK for initial state of dialog or any other moment,
  // while editing these fields. Address errors must be set if necessary only when submit button is clicked,
  // `useCallback()` fits this purpose. Using `useMemo()` causes errors to be set immediately,
  // and to be set always, when a field is empty.
  const validateNewAddressFields = React.useCallback(() => {
    const { country, city, street, postal_code } = currentEditedAddress
    const fieldsToValidate = { country, city, street, postal_code }
    addressEditErrors.setError('country', country.length === 0)
    addressEditErrors.setError('city', city.length === 0)
    addressEditErrors.setError('street', street.length === 0)
    addressEditErrors.setError('postal_code', postal_code.length === 0)
    return !Object.values(fieldsToValidate).some(string => string.length === 0)
  }, [currentEditedAddress, addressEditErrors])

  const updateAddress = React.useCallback((): void | undefined => {
    // if (editedAddressId === undefined) return
    // const { addresses } = client
    // const indexToUpdate = addresses && addresses.findIndex(address => address.id === editedAddressId)
    // // checks for zero inequality
    // // checks for zero inequality
    // if (indexToUpdate === undefined) return
    // const addressesWithUpdatedAddress = [...(client.addresses || [])]
    // addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    // setClient({
    //   ...client,
    //   addresses: addressesWithUpdatedAddress,
    // })
    // setEditedAddressId(undefined)
    // setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    // setCurrentEditedAddress(emptyAddress)
  }, [editedAddressId, client, addressesToEdit, setAddressesToEdit, currentEditedAddress])

  const handleAddAddress = React.useCallback(() => {
    // if (!validateNewAddressFields()) return false

    // if (editedAddressId !== undefined) {
    //   updateAddress()
    // } else {
    //   // in case new address is a primary address, reset the current primary address
    //   const newAddresses: IAddress[] = client.addresses ? deepcopy(client.addresses) : []
    //   if (currentEditedAddress.is_primary && client.addresses) {
    //     const primaryAddress = newAddresses.find(address => address.is_primary)
    //     if (primaryAddress) {
    //       primaryAddress.is_primary = false
    //     }
    //     if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
    //   }
    //   currentEditedAddress.id = client.addresses && client.addresses.length ? client.addresses.length * -1 : 0
    //   newAddresses.push(currentEditedAddress)
    //   setClient({ ...client, addresses: newAddresses })

    //   currentEditedAddress.client_id = client.id

    //   setCurrentEditedAddress(emptyAddress)
    //   setAddressesToAdd([...addressesToAdd, currentEditedAddress])
    //   toggleAddAddressDialog()
    // }
    return true
  }, [
    validateNewAddressFields,
    editedAddressId,
    updateAddress,
    client,
    currentEditedAddress,
    addressesToAdd,
    toggleAddAddressDialog,
    clientEditErrors,
  ])

  const handlePrimaryAddressChange = React.useCallback(
    (address: IAddress) => (checked: boolean) => {
      if (checked) {
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
        // const addresses = addressesWithNewPrimary(client.addresses || [], address)
        // setClient({ ...client, addresses: addresses })

        // setClient({ ...client, addresses: [...addresses, ...(initialClient || [])] })
      }
    },
    [clientEditErrors, client]
  )

  const handleCompanyAddressChange = React.useCallback(
    (newAddressId: number | null): void => {
      // const addressArray = initialClient || []
      // const selectedAddress = addressArray.find(address => address.id === newAddressId)
      //if (selectedAddress) setClient({ ...client, addresses: [selectedAddress] })
    },
    [client /*initialClient*/]
  )

  const handlePrimaryAddressChecked = React.useCallback(
    (checked: boolean) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        is_primary: checked,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      //  setClient({ ...client, addresses: client.addresses && client.addresses.filter(address => address.id !== id) })
      if (id > 0) setAddressesToDelete([...addressesToDelete, id])
    },
    [setClient, setAddressesToDelete, addressesToDelete, client]
  )

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      //   setEditedAddressId(id)
      //   setCurrentEditedAddress((client.addresses && client.addresses.find(address => address.id === id)) || emptyAddress)
      //   setShowAddAddressDialog(true)
    },
    [setEditedAddressId, setCurrentEditedAddress, setShowAddAddressDialog]
  )

  // Here we navigate to companies page and pass this client object there.
  // If that component will find this object on `router.history.location.state`,
  // company creation flow dialog will be opened automatically with prefilled client field.
  const createCompany = React.useCallback(() => {
    history.push('/companies', { client })
  }, [client, history])

  //   const navigateToAssociateCompanies = React.useCallback(() => {
  //     history.push(`/companies?query=${encodeURI(client.name)}`)
  //   }, [client.name, history])

  const classes = useStyles()

  const { country, city, street, postal_code } = addressEditErrors

  //   const salesRepresentative: ISuggestionAttribute | undefined = useMemo(
  //     () =>
  //       editedClient?.sales_rep?.id
  //         ? { label: editedClient.sales_rep.name, value: editedClient.sales_rep.id }
  //         : undefined,
  //     [editedClient]
  //   )

  //   const affiliate: ISuggestionAttribute | undefined = useMemo(
  //     () =>
  //       editedClient?.affiliate?.id
  //         ? { label: editedClient.affiliate.name, value: editedClient.affiliate.id }
  //         : undefined,
  //     [editedClient]
  //   )

  // const arrayAddress = initialClient?.map(item => {
  //   return item
  // })

  // const newArray = client.addresses

  //   console.log('initialClient', initialClient)
  //   console.log('client.addresses', client.addresses)
  React.useEffect(() => {
    const func = async () => {
      const stateData = await getstates()
      //console.log(stateData);
      setstates(stateData)
    }
    func()
  }, [])
  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        {/* <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.name}
          helperText={clientEditErrors.name && 'Please Specify Name Of The Client'}
          label="State Name"
          style={{ marginBottom: clientEditErrors.name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.state_display_name}
          onChange={event => changeClientField('state_display_name', event.target.value)}
        /> */}

        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          error={clientEditErrors.email}
          helperText={helperTexts.email}
          label="City Name"
          className={classes.textField}
          style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
          type="text"
          name="email"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.city_name}
          onChange={event => changeClientField('city_name', event.target.value)}
        />
        {/* <SelectField
          showCopyButton={!!editedClient}
          onOptionSelected={option => addAttribute('sales_rep_id', (option && option.value) || undefined)}
          title="Sales Representative"
            fetchSuggestions={fetchSuggestions}
          field="sales_rep_id"
          placeholder="Choose Country"
        //   value={salesRepresentative}
        /> */}
        <br />
        <SelectAutocomplete
          options={states.map((state: any) => ({
            value: state.state_display_name,
            label: state.state_display_name,
          }))}
          label="State/Province"
          value={client.state_display_name}
          // handleChange={(options: any) => {
          //   //handleInputFieldsChange('city')(value)
          //   //addAttribute('sales_rep_id', (options && options.value) || undefined)
          // }}
          handleChange={value => changeClientField('state_display_name', value)}
          // dataTestId={TestID.CITY_SELECT}
          // isDisabled={addressFields.locality ? false : true}
        />
        {/* {editedClient && <CommissionAccordion editedClient={editedClient} />} */}
        <SubmitFormButton
          //onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add City'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default ClientDetails
