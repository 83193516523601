import { AssertionError } from 'assert'
import showErrorNotification from 'utils/showErrorNotification'

/**
 * assert that value is not null or undefined
 */
export function assertNonNullable<T>(value: T, errorMessage?: string): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    const message = errorMessage ?? `Expected 'value' to be defined, but received ${value}`
    showErrorNotification(message)
    throw new AssertionError({ message })
  }
}
