import { Button, Divider, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './styles'
import ProgressTask from './table/progressTask'
import AddProspectModal from './addProspectModal'

const ClientLeadManagerElements = () => {
  const classes = useStyles()
  const [isUpdated, setIsUpdated] = useState(false)
  const [isModal, setIsModal] = useState(false)

  return (
    <div className={classes.container}>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={6}>
          <h1 className="primary-heading">Referral List</h1>
        </Grid>
        <Grid container item xs={6} justify="flex-end">
          <Button color="secondary" variant="contained" className="add-btn" onClick={() => setIsModal(true)}>
            Add Referral
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <AddProspectModal open={isModal} setOpen={setIsModal} setIsUpdated={setIsUpdated} />

      <ProgressTask isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
    </div>
  )
}

export default ClientLeadManagerElements
