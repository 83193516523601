import {
  BarChart,
  ExitToApp,
  Language,
  Toc,
  Phone,
  DescriptionOutlined,
  SpeakerNotes,
  MoneyOff,
} from '@material-ui/icons'
import * as MenuIcons from 'icons/menuIcons'
import * as React from 'react'
import { menuIconColor, selectedMenuIconColor } from '../theme'
import { IMenuSection } from './menu'

const iconStyle = (selected: boolean) => ({
  color: selected ? selectedMenuIconColor : menuIconColor,
})

const sections = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    renderIcon: ({ selected }) => (
      <MenuIcons.DashBoardIcon style={{ ...iconStyle(selected), width: 20 }} viewBox="0 0 14 20" />
    ),
  },
  {
    title: 'Web Submissions',
    path: '/web-submissions',
    renderIcon: ({ selected }) => <Language style={iconStyle(selected)} />,
  },
  // {
  //   title: 'Call Queue Phone Logs',
  //   path: '/call-logs',
  //   renderIcon: ({ selected }) => <Phone style={iconStyle(selected)} />,
  // },
  {
    title: 'Lead Manager',
    path: '/prospects',
    renderIcon: ({ selected }) => <MenuIcons.LeadManagerIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Failed Payments',
    path: '/payment-fail-logs',
    renderIcon: ({ selected }) => <MoneyOff style={iconStyle(selected)} />,
  },
  {
    title: 'Calendar',
    path: '/compliance-calendar',
    renderIcon: ({ selected }) => <MenuIcons.CalendarIcon style={iconStyle(selected)} />,
  },
  {
    title: 'KPI Dashboard',
    path: '/kpi-dashboard',
    renderIcon: ({ selected }) => <BarChart style={iconStyle(selected)} />,
  },
  {
    title: 'Rocky AI',
    path: '/rocky-ai',
    renderIcon: ({ selected }) => <SpeakerNotes style={iconStyle(selected)} />,
  },
  {
    title: 'Sales Representative',
    path: '/sales_representative',
    renderIcon: ({ selected }) => <MenuIcons.SalesRepIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Employee',
    path: '/employee',
    renderIcon: ({ selected }) => <MenuIcons.EmployeeIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Clients',
    path: '/client',
    renderIcon: ({ selected }) => <MenuIcons.ClientIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Companies',
    path: '/companies',
    renderIcon: ({ selected }) => <MenuIcons.CompanyIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Company Orders',
    path: '/company-order',
    renderIcon: ({ selected }) => <MenuIcons.CompanyOrderIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Company Subscription',
    path: '/company-subscription',
    renderIcon: ({ selected }) => <DescriptionOutlined style={iconStyle(selected)} />,
  },
  {
    title: 'Tax Service Orders',
    path: '/tax-orders',
    renderIcon: ({ selected }) => <MenuIcons.PotentialOrderIcon style={iconStyle(selected)} />,
  },
  // {
  //   title: 'Invoices',
  //   path: '/invoices',
  //   renderIcon: ({ selected }) => <MenuIcons.PotentialOrderIcon style={iconStyle(selected)} />,
  // },
  // {
  //   title: 'Potential Orders',
  //   path: '/potential-orders',
  //   renderIcon: ({ selected }) => <MenuIcons.PotentialOrderIcon style={iconStyle(selected)} />,
  // },
  // {
  //   title: 'Notifications',
  //   path: '/notifications',
  //   renderIcon: ({ selected }) => <MenuIcons.NotificationIcon style={iconStyle(selected)} />,
  // },
  // {
  //   title: 'Email Configuration',
  //   path: '/email-configuration',
  //   renderIcon: ({ selected }) => <MenuIcons.EmailIcon style={iconStyle(selected)} />,
  // },
  {
    title: 'Notifications',
    path: 'notifications',
    renderIcon: ({ selected }) => <MenuIcons.NotificationIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Notifications Logs',
    path: '/notifications-logs',
    renderIcon: ({ selected }) => <MenuIcons.NotificationIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Visitor Logs',
    path: '/visitor-logs',
    renderIcon: ({ selected }) => <Toc style={iconStyle(selected)} />,
  },
  {
    title: 'Predefined Lists',
    path: 'predefined-lists',
    renderIcon: ({ selected }) => <MenuIcons.PredefinedListIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Auto Reminder',
    path: 'auto-reminder',
    renderIcon: ({ selected }) => <MenuIcons.NotificationIcon style={iconStyle(selected)} />,
  },
  {
    title: 'State',
    path: '/states',
    renderIcon: ({ selected }) => <MenuIcons.PredefinedListIcon style={iconStyle(selected)} />,
  },
  {
    title: 'Logout',
    path: '',
    renderIcon: ({ selected }) => <ExitToApp style={iconStyle(selected)} />,
  },
]

export default sections
