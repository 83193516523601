import * as React from 'react'
import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/styles'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { ICommission } from 'models/commission'
import { fromSnakeCaseToTitle } from 'utils/toTitleCase'
import { formatDateToHumanFriendly, formatTransactionDate } from 'utils/formatDate'
import useRouter from 'hooks/router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IClientTableRowProps {
  commission: ICommission
}

const CommissionTableRow: React.FC<IClientTableRowProps> = ({ commission }) => {
  const classes = useStyles()

  // const amount_cents: string = (commission.amount_cents / 100).toFixed(2)
  const { history } = useRouter()
  const routetoOrderDetails = orderID => {
    if (isNaN(orderID) || orderID < 1) return
    history.push(`/company-order/${orderID}`)
  }
  const transaction_date = commission?.transaction_date || ''

  return (
    <>
      <StyledTableRow
        onClick={() => routetoOrderDetails(commission?.order_id || 0)}
        data-testid={`${commission.client_extid}`}
        className={classes.row}
      >
        <StyledTableCell>{commission.reference}</StyledTableCell>
        <StyledTableCell>{commission.amount_cents}</StyledTableCell>
        <StyledTableCell>{fromSnakeCaseToTitle(commission.type)}</StyledTableCell>
        <StyledTableCell>{formatDateToHumanFriendly(transaction_date)}</StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default CommissionTableRow
