/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ModalTitle from '../modalTitle'
import { useStyles } from './uploadModalStyle'
import { editClientUploadDocument, editCompanyUploadDocument, editUploadDocument } from 'api/companies'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import ButtonWithLoading from '../buttons/buttonWithLoadingProgress'
import { UploadFileToS3Args } from 'models/asset'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { DatePicker as YearPicker } from '@material-ui/pickers'

interface ModalProps {
  open: boolean
  setOpen: any
  assetData: any
  onUploadComplete?: (args: UploadFileToS3Args) => void
}

const UploadEditModal: React.FC<ModalProps> = ({ open, setOpen, assetData, onUploadComplete }: ModalProps) => {
  console.log('assetDataassetData', assetData)

  const classes = useStyles()

  const [notes, setNotes] = React.useState('')
  const [docLabel, setDocLabel] = React.useState('')
  const [taxYear, setTaxYear] = React.useState('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (assetData) {
      setNotes(assetData?.notes)
      setDocLabel(assetData?.doc_label)
      setTaxYear(assetData?.tax_year)
    }
  }, [assetData])

  const handleUpdate = async () => {
    const payload = {
      notes: notes,
      doc_label: docLabel,
      tax_year: taxYear,
    }
    setIsLoading(true)
    try {
      if (assetData?.company_id) {
        await editCompanyUploadDocument(assetData?.id, payload)
      } else {
        await editClientUploadDocument(assetData?.id, payload)
      }
      // await editUploadDocument(assetData?.id, payload)

      setOpen(false)
      setIsLoading(false)
      showSuccessNotification('update successfully')
      if (onUploadComplete) onUploadComplete(assetData)
    } catch (error) {
      showApiResponseError(error, "Can't update")
    }
    setIsLoading(false)
  }

  const handleYearChange = React.useCallback((date: MaterialUiPickersDate) => {
    if (date) setTaxYear(date.year().toString())
  }, [])

  return (
    <Dialog open={open} onClose={setOpen} className={classes.dialogContainer} maxWidth="xs" fullWidth>
      <ModalTitle title={'Update File'} toggleModal={setOpen} />
      <DialogContent className={classes.root}>
        <TextField
          label="File Label"
          variant="outlined"
          value={docLabel}
          fullWidth
          onChange={e => setDocLabel(e.target.value)}
        />
        <TextField
          label="Notes"
          variant="outlined"
          value={notes}
          fullWidth
          multiline
          onChange={e => setNotes(e.target.value)}
        />
        {assetData?.client_id ? (
          <YearPicker
            views={['year']}
            label="Tax Year"
            value={taxYear || null}
            onChange={handleYearChange}
            inputVariant="outlined"
            minDate={moment().add(-10, 'year')}
            maxDate={moment().add(+10, 'year')}
            style={{ margin: '0.5rem 0', width: '100%' }} // `classes` prop doesn't exist
          />
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <ButtonWithLoading
          isLoading={isLoading}
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          className={classes.uploadBtn}
        >
          Update
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  )
}

export default UploadEditModal
