import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ModalTitle from 'components/common/modalTitle'
import { getCompanyOrderDocumentsWithoutPagination, sendDocsToClient } from 'api/orders'
import showApiResponseError from 'utils/showApiResponseError'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { getSubmissionHistory, ISubmissionHistory } from 'api/leadManager'
import showErrorNotification from 'utils/showErrorNotification'
import { LinearProgress, Typography } from '@material-ui/core'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import moment from 'moment'
import { stepMappings } from 'utils/stepmappings'
import DetailsTable from './table'
import { getClientsByUserIds, getCompaniesIds, getOrdersByIds } from 'api/kpiDashboard'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import { removeNullAndUndefined } from 'utils/objectUtils'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '100%',
      },
      //   width: '25%',
    },
    root: {
      overflow: 'hidden',
      paddingTop: '2rem',
      '& .MuiTextField-root': {
        marginBottom: '1rem',
        width: '100%',
      },
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '93%',
      },
      padding: theme.spacing(2),
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'center',
    },

    uploadBtn: {
      color: '#fff',
      width: '200px',
      height: '50px',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
    },
    durationText: {
      color: 'grey',
      fontSize: '12px',
    },
  })
)
const DetailModal: React.FC<any> = ({
  open,
  setOpen,
  ids,
  type,
  from_email = false,
  offer_id,
  startDate,
  endDate,
  offer_taken_from,
}) => {
  const classes2 = useStyles()
  const [loading, setLoading] = React.useState<boolean>(false)

  const getApiFunctionAccToEntity = () => {
    switch (type) {
      case KPI_ENTITY_TYPES.client:
        return getClientsByUserIds
        break
      case KPI_ENTITY_TYPES.order:
        return getOrdersByIds
        break
      case KPI_ENTITY_TYPES.company:
        {
          // console.log('datesfilter', startDate, endDate)
          // debugger
          return getCompaniesIds
        }
        break
      default:
        break
    }
  }
  return (
    <Dialog open={open} maxWidth="md" className={classes2.dialogContainer} fullWidth={true}>
      <ModalTitle title={'Kpi Details'} toggleModal={setOpen} />
      <DialogContent className={'classes2.root'}>{loading && <LinearProgress></LinearProgress>}</DialogContent>
      <DetailsTable
        ids={ids}
        type={type}
        apiFunction={getApiFunctionAccToEntity()}
        offer_id={offer_id}
        startDate={startDate}
        endDate={endDate}
        from_email={from_email}
        offer_taken_from={offer_taken_from}
      />
      <DialogActions className={classes2.dialogAction}></DialogActions>
    </Dialog>
  )
}

export default DetailModal
