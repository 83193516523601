import {
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../style'
import CloseIcon from '@material-ui/icons/Close'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showApiResponseError from 'utils/showApiResponseError'
import { notify } from '@jetkit/react'
import { createLabel, editLabel, LabelItemProps } from 'api/label'
import { LABEL_TYPES } from 'utils/constant'

interface AddLabelDialogProps {
  isOpen: boolean
  close: () => void
  editedLabelItem?: any
  onRequestSuccessful?: () => void
}

const AddLabelDialog: React.FC<AddLabelDialogProps> = ({ isOpen, close, editedLabelItem, onRequestSuccessful }) => {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [type, setType] = useState('')

  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const buttonTitle = React.useMemo(() => (editedLabelItem ? 'Save Changes' : 'Add Label'), [editedLabelItem])

  useEffect(() => {
    if (isOpen && editedLabelItem) {
      setName(editedLabelItem?.name || '')
      setColor(editedLabelItem?.color || '')
      setType(editedLabelItem?.type || '')
    } else if (!isOpen) {
      setName('')
      setColor('')
      setType('')
      errors.clearAll()
    }
  }, [editedLabelItem, isOpen])

  const setErrorMessages = React.useCallback(() => {
    if (!name) errors.setName(true)
    if (!type) errors.setType(true)
  }, [name, type])

  const useMissingItemsErrors = () => {
    const [name, setName] = React.useState(false)
    const [type, setType] = useState(false)

    const clearAll = React.useCallback(() => {
      setName(false)
      setType(false)
    }, [])

    return {
      name,
      setName,

      type,
      setType,
      clearAll,
    }
  }
  const errors = useMissingItemsErrors()

  const tryToSendRequest = React.useCallback(async () => {
    if (!name || !type) return
    const labelData: LabelItemProps = {
      name,
      color,
      type,
    }
    setWaitingForResponse(true)
    try {
      if (editedLabelItem) {
        if (editedLabelItem?.id) {
          await editLabel(editedLabelItem?.id, labelData)
        }
      } else {
        await createLabel(labelData)
      }
      setWaitingForResponse(false)
      close()
      notify.success('Label Created!')
      onRequestSuccessful && onRequestSuccessful()
    } catch (error) {
      setWaitingForResponse(false)
      showApiResponseError(error, `Sorry, could not ${editedLabelItem ? 'edit' : 'create'} Label`)
    }
    setName('')
    setColor('')
    setType('')
  }, [close, color, editedLabelItem, name, onRequestSuccessful, type])

  const handleAddMissingItem = React.useCallback(() => {
    tryToSendRequest()
    setErrorMessages()
  }, [setErrorMessages, tryToSendRequest])

  return (
    <Dialog open={isOpen} PaperProps={{ className: classes.paper }}>
      <IconButton onClick={close} className={classes.closeButton}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <div className={classes.innerContainer} data-testid="label-dialog-inner-container">
        <div className={classes.title}>Add Label</div>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <TextField
            id="labelTitle"
            label="Name"
            variant="outlined"
            value={name}
            onChange={e => setName(e.target.value)}
            // helperText={errors?.name ? 'Enter Name' : ''}
          />
          <FormHelperText className={classes.errorMsg}>{errors?.name ? '*Enter Name' : ''}</FormHelperText>
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <TextField
            id="labelTitle"
            label="Color"
            type="color"
            variant="outlined"
            value={color || '#000'}
            onChange={e => setColor(e.target.value)}
            // helperText={errors?.color ? 'Enter color' : ''}
          />
          {/* <FormHelperText className={classes.errorMsg}>{errors?.color ? '*Enter color' : ''}</FormHelperText> */}
        </FormControl>
        <FormControl variant="outlined" style={{ margin: '0.5rem 0' }}>
          <InputLabel id="select-helper-label">Select Type</InputLabel>
          <Select
            labelId="select-helper-label"
            id="select-helper"
            label="Select Type"
            value={type}
            onChange={(e: any) => setType(e.target.value)}
          >
            <MenuItem value={LABEL_TYPES?.company}>Company</MenuItem>
            <MenuItem value={LABEL_TYPES?.taxorder}>Tax Order</MenuItem>
          </Select>
          <FormHelperText className={classes.errorMsg}>{errors?.type ? '*Enter type' : ''}</FormHelperText>
        </FormControl>
        <ButtonWithLoading
          kind="BRPrimary"
          wrapperClassName={classes.scheduleButton}
          isLoading={waitingForResponse}
          onClick={handleAddMissingItem}
        >
          {buttonTitle}
        </ButtonWithLoading>
      </div>
    </Dialog>
  )
}

export default AddLabelDialog
