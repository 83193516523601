import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '25%',
      },
      //   width: '25%',
    },
    root: {
      flexWrap: 'wrap',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      // alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
    },
    innerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
      marginTop: '1rem',
    },
    stage: {
      width: '34%',
      justifyContent: 'space-between',
    },
    assigneeFieldContainer: {
      width: '38%',
    },
    dueDateContainer: {
      width: '27%',
    },
    dueDateInput: {
      width: '100%',
    },
    clientContainer: {
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    orderNameContainer: {
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    saveBtnSection: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
      marginBottom: '1.5rem',
    },
    moneyTextField: {
      width: '100% !important',
    },
    formControl: {
      // margin: theme.spacing(1),
      // minWidth: 120,
      width: '100%',
    },
    completeBtn: {
      '& div': {
        '& button': {
          background: '#28a745',
        },
      },
    },
    sendReviewButton: {
      margin: '0 0.5rem',
      //   width: '25%',
      //   marginLeft: '3px',
      //   '& button': {
      //     // fontSize: '12px',
      //   },
    },
    saveButton: {
      width: '25%',
      marginLeft: '3px',
      '& button': {
        // fontSize: '12px',
      },
    },
    resendConfirmationBtn: {
      width: '30%',
      marginLeft: '3px',
      '& button': {
        // fontSize: '12px',
      },
    },
    nameCheckBtn: {
      width: '17%',
      '& button': {
        // fontSize: '12px',
      },
    },
    submissionLink: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '1rem',
      background: '#3c9fdf',
      padding: '6px 16px',
      borderRadius: '0.2rem',
      fontWeight: 600,
      fontFamily: 'Roboto,sans-serif',
      color: '#fff',
      boxShadow:
        ' 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
    rushOrderList: {
      maxWidth: 'fit-content',
    },
    dateFiled: {
      '& > div': {
        margin: 0,
      },
    },
  })
)
