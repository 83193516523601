import { FilterableAPICall, PagedDataContext, usePagedTable } from '@jetkit/react'
import Typography from '@material-ui/core/Typography'
import { deleteTaxOrder, fetchTaxOrders, updateTableRow } from 'api/taxOrders'
import CreateTaxOrder from 'components/taxOrders/create'
import useTaxOrders from 'components/taxOrders/hooks'
import TaxOrderTable from 'components/taxOrders/table'
import { TaxOrderContext } from 'components/taxOrders/taxOrderContext'
import { INewTaxOrder, ITaxOrder } from 'models/taxOrder'
import * as React from 'react'
import ExampleDnd from './exampleDnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Backdrop,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core'
import { formatDateTimeToHumanFriendly, getDueDateWithYear, getTimeFromNow } from 'utils/formatDate'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import TableActionButton from 'components/common/buttons/tableActionButton'
import useRouter from 'use-react-router'
import showApiResponseError from 'utils/showApiResponseError'
import chroma from 'chroma-js'
import { toTitleCase } from 'utils/toTitleCase'
import useSortTable from 'hooks/useSortTable'
import TaxOrderTableHead from './table/head'
import { skeletons } from 'components/common/table'
import { taxOrderStatusColors } from 'utils/constant'
import CopyTextButton from 'components/common/textFields/copyTextButton'
import copyToClipboard from 'utils/copyToClipboard'
import { CompanyContext } from 'pages/company/companyContext'
import { Link } from 'react-router-dom'
import { OpenInNew } from '@material-ui/icons'
import DeleteDialog from 'components/notifications/deleteDialog'
import Badge from '@material-ui/core/Badge'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleButtonContainer: {
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: '2rem',
      fontFamily: 'Times New Roman',
      fontWeight: 600,
    },
    cellSpacing: {
      width: '100px',

      '& button': {
        marginRight: '0.5rem',
      },
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    notFoundText: {
      textAlign: 'center',
      background: '#F6F6F8',
      margin: '0',
      padding: '1rem',
    },
    inlineField: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    actionButton: {
      width: 40,
      height: 40,
      '& span': {
        fontSize: '2rem',
      },
    },
  })
)

interface ITaxOrdersProps {
  apiCall: FilterableAPICall<ITaxOrder>
}

const TaxOrders: React.FC<ITaxOrdersProps> = ({ apiCall = fetchTaxOrders }) => {
  const classes = useStyles()
  const router = useRouter()

  const { reloadData } = React.useContext(PagedDataContext)
  const { companyId } = React.useContext(CompanyContext)

  // get hooks for Tax Orders
  const {
    searchOptions,
    handleSortingClick,
    handleAddFilter,
    handleSearch,
    handleFiledOnlyFilter,
    handleOrderTypeFilter,
    handleTaxOrderStatusOnlyFilter,
  } = useTaxOrders()

  const tableHead = React.useMemo(
    () => (
      <TaxOrderTableHead
        sortBy={searchOptions['sort_by']}
        sortOrder={searchOptions['sort_order']}
        handleSortingClick={handleSortingClick}
      />
    ),
    [handleSortingClick, searchOptions]
  )

  // stuff for paginated table from '@jetkit/react'
  const memoApiCall = React.useMemo(() => {
    searchOptions.tax_year = searchOptions.tax_year.toString()
    searchOptions.label = searchOptions.label?.toString()
    searchOptions.accountant_user_id = searchOptions.accountant_user_id?.toString()
    searchOptions.stage = searchOptions.stage?.toString()
    // debugger
    if (companyId) searchOptions.company_id = companyId
    return apiCall(searchOptions)
  }, [apiCall, searchOptions])
  const pagedTable = usePagedTable<any>({
    apiCall: memoApiCall,
  })

  const tableData = pagedTable?.renderProps?.rows
  const [taxTableData, setTaxTableData] = React.useState<any>(tableData)

  React.useEffect(() => {
    if (tableData) {
      setTaxTableData(tableData)
    }
  }, [tableData])
  React.useEffect(() => {
    searchOptions.company_id = 0
  }, [router.history, searchOptions])

  const handleDragEnd = async (event: any) => {
    if (!event.destination) return

    const tempData = Array.from(taxTableData)
    const temp2Data = Array.from(taxTableData)
    const [source_data]: any = tempData.splice(event.source.index, 1)
    const [destination_data]: any = temp2Data.splice(event?.destination?.index, 1)

    tempData.splice(event.destination.index, 0, source_data)
    setTaxTableData(tempData)

    const source = parseInt(event?.draggableId)
    const destination = destination_data?.priority

    try {
      await updateTableRow(source, destination)
    } catch (error) {
      showApiResponseError(error, 'Failed to update Row')
    }
  }

  const handleEditClick = (taxOrderId: number) => {
    router.history.push(`/tax-orders/${taxOrderId}`)
  }
  const reloadTaxTable = React.useCallback(() => {
    if (companyId) searchOptions.company_id = companyId
    pagedTable.reloadData()
  }, [pagedTable, searchOptions])

  const handleTaxOrderDelete = React.useCallback(
    async (orderId: number) => {
      try {
        await deleteTaxOrder(orderId)
        reloadTaxTable()
      } catch (err) {
        showApiResponseError(err, 'Failed to delete tax order')
      }
    },
    [reloadTaxTable]
  )

  const setColor = (bgColor: string) => {
    if (!bgColor) return ''
    const diff = chroma.contrast(bgColor, 'white')

    if (diff > 2) {
      return 'white'
    } else return 'black'
  }

  const getFilersName = (taxOrder: ITaxOrder) =>
    taxOrder.company
      ? taxOrder.company.name || ''
      : taxOrder.client
      ? (taxOrder.client?.first_name || '') +
        ' ' +
        (taxOrder.client?.middle_name || '') +
        ' ' +
        (taxOrder.client?.last_name || '')
      : ''

  const handleChangePage = (event, newPage) => {
    pagedTable?.renderProps?.handleChangePage(event, newPage)
  }
  const handleChangeRowsPerPage = event => {
    pagedTable?.renderProps?.handleChangeRowsPerPage(event)
  }

  const page = pagedTable?.renderProps?.page
  const pageSize = pagedTable?.renderProps?.pageSize
  const totalRows = pagedTable?.renderProps?.totalRows
  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [taxDelete, settaxDelete] = React.useState<ITaxOrder>()

  const handleDeleteClick = React.useCallback((tax: ITaxOrder) => {
    settaxDelete(tax)
    setDeleteItemDialogShown(true)
  }, [])

  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  return (
    <>
      <DeleteDialog
        open={deleteItemDialogShown}
        deletionObject={taxDelete}
        close={handleDeleteItemsDialogCloseClick}
        onRequestSuccessful={reloadTaxTable}
        deletionRequest={deleteTaxOrder}
      />
      <TaxOrderContext.Provider
        value={{
          searchOptions,
          handleSortingClick,
          handleAddFilter,
          handleSearch,
          handleFiledOnlyFilter,
          handleOrderTypeFilter,
          reloadTable: reloadTaxTable,
          handleTaxOrderStatusOnlyFilter,
        }}
      >
        <div className={classes.titleButtonContainer}>
          <Typography className={classes.title}>Tax Service Orders</Typography>
          <CreateTaxOrder />
        </div>
        <TaxOrderTable pagedTable={pagedTable} />
      </TaxOrderContext.Provider>

      {pagedTable.isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            {tableHead}
            {skeletons({
              numberOfColumns: 12,
              sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
              numberOfRows: 10,
            })}
          </Table>
        </TableContainer>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableContainer component={Paper}>
            <Table>
              {tableHead}

              <Droppable droppableId="table">
                {provider => (
                  <TableBody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                    {taxTableData?.map((item, index) => (
                      <Draggable key={item.priority.toString()} draggableId={item.priority.toString()} index={index}>
                        {provider => (
                          <TableRow
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                            {...provider.dragHandleProps}
                            style={{ background: item?.unpaid_subscription ? '#fdecea' : 'inherit' }}
                          >
                            <TableCell>{item?.priority}</TableCell>
                            <TableCell>
                              {/* <div>{item?.accountant?.first_name}</div> */}
                              <div className={classes.inlineField}>
                                {getFilersName(item)}
                                <CopyTextButton onClick={() => copyToClipboard(getFilersName(item))} />
                              </div>
                              <div>
                                {item?.tax_labels?.map((item, i: number) => (
                                  <Chip
                                    key={i}
                                    size="small"
                                    label={item.name}
                                    style={{
                                      backgroundColor: item.color,
                                      color: setColor(item.color),
                                      marginTop: '2px',
                                    }}
                                  />
                                ))}
                              </div>
                            </TableCell>
                            <TableCell>{item?.tax_year}</TableCell>
                            <TableCell>{toTitleCase(item?.service_type)}</TableCell>
                            {/* <TableCell>{item?.tax_order_type}</TableCell> */}
                            <TableCell>
                              {toTitleCase(item?.tax_order_type)} {toTitleCase(item.service_type)}
                            </TableCell>

                            <TableCell>
                              {item?.accountant?.first_name} {item.accountant?.last_name}
                            </TableCell>
                            <TableCell>
                              <Badge
                                badgeContent={item.questionsCount}
                                color={'primary'}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <div
                                  style={{
                                    background: taxOrderStatusColors[item?.order_status_description],
                                    borderRadius: 4,
                                    padding: 8,
                                    textAlign: 'center',
                                  }}
                                >
                                  {item?.order_status_description}
                                </div>
                              </Badge>
                            </TableCell>
                            {/* <TableCell>Not Filed</TableCell> */}
                            <TableCell>{item?.state_of_formation}</TableCell>
                            <TableCell>{item?.is_filed ? 'Filed' : 'Not Filed'}</TableCell>

                            <TableCell>
                              {/* {formatDateToHumanFriendlyWithComa(item?.creation_time ? item?.creation_time : '')} */}
                              {getTimeFromNow(item?.creation_time)}
                            </TableCell>
                            <TableCell>
                              {item.last_updated ? formatDateTimeToHumanFriendly(item.last_updated) : ''}
                            </TableCell>
                            <TableCell>{getDueDateWithYear(item.due_date)}</TableCell>
                            <TableCell className={classes.cellSpacing}>
                              <TableActionButton
                                onClick={event => {
                                  handleEditClick(item?.id)
                                  event.stopPropagation()
                                }}
                                style={{ width: 40, height: 40 }}
                              >
                                <EditIcon />
                              </TableActionButton>
                              <TableActionButton className={classes.actionButton}>
                                <Link to={`/tax-orders/${item.id}`} target="_blank" rel="noreferrer">
                                  <OpenInNew data-testid="newTab" color="primary" />
                                </Link>
                              </TableActionButton>
                              <TableActionButton
                                onClick={event => {
                                  handleDeleteClick(item)
                                  event.stopPropagation()
                                }}
                                // disabled={true}
                                style={{ width: 40, height: 40 }}
                              >
                                <DeleteIcon />
                              </TableActionButton>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
            {!taxTableData?.length && <p className={classes.notFoundText}>No Tax Orders Found</p>}

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={totalRows}
              rowsPerPage={pageSize}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </DragDropContext>
      )}

      {/* <ExampleDnd /> */}
    </>
  )
}

export default TaxOrders
