import Dialog from '@material-ui/core/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ModalTitle from 'components/common/modalTitle'
import * as React from 'react'
import CompanyOwnersCreation, { ICompanyOwnersCreation } from '.'

interface INewOwnerDialog extends ICompanyOwnersCreation {
  open: boolean
  onClose(): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    paper: {
      padding: '30px 40px',
      margin: 0,
      // maxWidth: '100%',
      // maxHeight: '100%',
      // width: 500,
      minHeight: 250,
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
)

/**
 * dialog with select field to select a new owner
 */
const NewOwnerDialog: React.FC<INewOwnerDialog> = props => {
  const classes = useStyles(props)
  const {
    onClose,
    onOwnerFieldsChange,
    fetchCompanyRoles,
    toggleRole,
    clearOwnerState,
    onAddOwnerButtonClick,
    roles,
    values,
    companyOwners,
    onOwnerEdit,
    open,
    updateOnCompanyLevel,
    toggleUpdateLevelOption,
    shouldShowUpdateLevelOption,
    selectAllRoles,
    allRolesSelected,
    company,
    takenShares = 0,
  } = props

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      // fullScreen
      open={open}
      classes={{
        paper: classes.paper,
        container: classes.container,
      }}
    >
      <ModalTitle onClose={onClose} title="Add New Owner" />
      <CompanyOwnersCreation
        takenShares={takenShares}
        company={company}
        selectAllRoles={selectAllRoles}
        allRolesSelected={allRolesSelected}
        onOwnerFieldsChange={onOwnerFieldsChange}
        fetchCompanyRoles={fetchCompanyRoles}
        toggleRole={toggleRole}
        onAddOwnerButtonClick={onAddOwnerButtonClick}
        clearOwnerState={clearOwnerState}
        values={values}
        roles={roles}
        hideCompanyOwnersTable
        companyOwners={companyOwners}
        onOwnerEdit={onOwnerEdit}
        shouldShowUpdateLevelOption={shouldShowUpdateLevelOption}
        updateOnCompanyLevel={updateOnCompanyLevel}
        toggleUpdateLevelOption={toggleUpdateLevelOption}
      />
    </Dialog>
  )
}

export default NewOwnerDialog
