import * as React from 'react'

export type AddressError = 'country' | 'city' | 'street' | 'postal_code' | 'locality'

const addressErrorList: AddressError[] = ['country', 'city', 'street', 'postal_code', 'locality']

export function isAddressErrorKey(key: string): key is AddressError {
  return (addressErrorList as string[]).includes(key)
}

const useAddressEditErrors = () => {
  const [country, setCountry] = React.useState(false)
  const [city, setCity] = React.useState(false)
  const [street, setStreet] = React.useState(false)
  const [postal_code, setPostalCode] = React.useState(false)
  const [locality, setLocality] = React.useState(false)

  const setError = (error: AddressError, state: boolean) => {
    switch (error) {
      case 'country':
        setCountry(state)
        break
      case 'city':
        setCity(state)
        break
      case 'street':
        setStreet(state)
        break
      case 'postal_code':
        setPostalCode(state)
        break
      case 'locality':
        setLocality(state)
        break
    }
  }

  return {
    country,
    city,
    street,
    postal_code,
    locality,
    setError,
  }
}

export default useAddressEditErrors
