import { addTasksToCompanyOrder, createCompanyOrder, emptyOrderDetails, IOrderDetailsResponse } from 'api/orders'
import { useHandleDialogAccessibility } from 'hooks/useHandleDialogAccessibility'
import { IOrderTask } from 'models/task'
import * as React from 'react'
import { notEmpty, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { CompanyOrderContext } from '../CompanyOrderContext'
// import { useHistory } from 'react-router'

/**
 * Includes business logic specific to new company order creation.
 *
 * @param companyOrderDetails
 * State of the new company order that's being created.
 * @param onCompanyOrderFieldChange
 * Generic handler that should be used whenever we intend to update a field on `companyOrderDetails`.
 * @param isCompanyCreation
 * Normally when creating an order only tasks that were marked as activated will be created.
 * During the new company creation flow we want to also create the deactivated tasks, because in that flow the user can edit the activation status of tasks on the already created orders.
 * Thus, during new company creation we wanna abolish the deactivated tasks only when the creation is over and the user has `Saved company and initiated orders`.
 */
export const useCompanyOrderCreate = (
  companyOrderDetails: IOrderDetailsResponse,
  onCompanyOrderFieldChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => void,
  onSave: () => void,
  setCompanyOrderDetails: React.Dispatch<React.SetStateAction<IOrderDetailsResponse>>,
  isCompanyCreation?: boolean,
  complianceId?: number
) => {
  /**
   * If we're not creating a new company we want to create a company order only with tasks that were marked as activated by the user.
   */
  const { isOpen, handleOpen, handleSave, handleClose } = useHandleDialogAccessibility({
    saveSideEffect: () => setReloadTable(true),
  })
  const { setReloadTable } = React.useContext(CompanyOrderContext)
  // const history = useHistory()

  const handleSavingCompanyOrder = React.useCallback(
    async (tasks: any) => {
      try {
        const createdOrder = await createCompanyOrder({
          due_date: companyOrderDetails.due_date ? new Date(companyOrderDetails.due_date) : undefined,
          price: companyOrderDetails.price,
          commission: companyOrderDetails.commission,
          company_id: companyOrderDetails.company?.id,
          order_type_id: companyOrderDetails.order_type_id ?? undefined,
          required_order_ids: companyOrderDetails.required_order_ids,
          assignee_id: companyOrderDetails.assignee_id,
          tasks: tasks,
          payment_status: companyOrderDetails.payment_status,
          invoice_no: companyOrderDetails.invoice_no,
          compliance_task_id: complianceId,
        })

        let activatedTasks = companyOrderDetails.tasks

        if (!isCompanyCreation) {
          activatedTasks = companyOrderDetails.tasks
            .filter((task: IOrderTask) => task.is_activated && task)
            .filter(notEmpty)
        }
        try {
          await addTasksToCompanyOrder(createdOrder.id, activatedTasks)
          showSuccessNotification('Saved Successfully')
          setReloadTable(true)
          // history.push(`/company-order/${createdOrder.id}`)
        } catch (e) {
          showApiResponseError(e, 'Error Saving Data')
        }
        onSave()

        // We wanna reset the `companyOrderDetails` state after creating an order so that when  a new order we start with fresh details
        if (companyOrderDetails.company) {
          // We want to remember the last company that a user has created an order for.
          setCompanyOrderDetails({
            ...emptyOrderDetails,
            company: companyOrderDetails.company,
            company_id: companyOrderDetails.company?.id,
          })
        } else {
          setCompanyOrderDetails(emptyOrderDetails)
        }
      } catch (e) {
        console.log(e)
      }
    },
    [companyOrderDetails, onSave, setCompanyOrderDetails, isCompanyCreation]
  )

  const onTaskCreated = React.useCallback(
    async (task?: IOrderTask) => {
      if (task) {
        onCompanyOrderFieldChange('tasks', [task, ...companyOrderDetails.tasks])
      }
    },
    [companyOrderDetails.tasks, onCompanyOrderFieldChange]
  )

  return { handleSavingCompanyOrder, onTaskCreated }
}
