import { Button, Divider } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router'
import 'react-circular-progressbar/dist/styles.css'
import { useStyles } from '../style'
// import RadialSeparators from './RadialSeparotors'
import ExperienIcon from '../img/Experian_logo_color.png'
import { CreditProgressMetter } from '../img/creditProgressMetter'
import { checkCreditScore } from 'utils/functions'
import useRouter from 'use-react-router'

interface CompanyInformationProps {
  creditScore: number
}

const CreditScoreBar = ({ creditScore }: CompanyInformationProps) => {
  const classes = useStyles()
  const { history } = useRouter()

  const { id } = useParams<{ id: string }>()

  return (
    <div className={classes.creditScoreCard}>
      <h1 className="profile-heading">Your Business Credit score is being Ignited</h1>
      <Divider className="heading-divider" />

      <p className="profile-title">
        Business Rocket helps business owners establish and build their business credit score based on Experian Data.
      </p>

      <div className={classes.creditProgressContainer}>
        <p className="label" style={{ marginRight: '0.5rem' }}>
          D
        </p>
        <CreditProgressMetter
          part1={checkCreditScore(creditScore)?.color1}
          part2={checkCreditScore(creditScore)?.color2}
          part3={checkCreditScore(creditScore)?.color3}
          part4={checkCreditScore(creditScore)?.color4}
          part5={checkCreditScore(creditScore)?.color5}
          part6={checkCreditScore(creditScore)?.color6}
          svgWidth="100%"
          svgHeight="auto"
          text={checkCreditScore(creditScore)?.rank}
        />
        <p className="label" style={{ marginLeft: '0.5rem' }}>
          A
        </p>

        {/* <p className="label" style={{ marginRight: '-0.5rem' }}>
          D
        </p>
        <CircularProgressbarWithChildren
          value={creditScore}
          text={checkCreditScore(creditScore)?.rank}
          strokeWidth={8}
          circleRatio={0.75}
          styles={buildStyles({
            strokeLinecap: 'butt',
            rotation: 1 / 2 + 1 / 8,
            pathColor: checkCreditScore(creditScore)?.color,
            trailColor: '#d0d5dd',
            textColor: '#343b4c',
          })}
        >
          <RadialSeparators
            count={8}
            style={{
              background: '#f7faff',
              width: '4px',
              // This needs to be equal to props.strokeWidth
              height: `${20}%`,
            }}
          />
        </CircularProgressbarWithChildren>
        <p className="label" style={{ marginLeft: '-0.5rem' }}>
          A
        </p> */}
      </div>

      <p className="profile-subtitle1">Below Average</p>
      <p className="profile-subtitle2">Over Last Week</p>

      <div className="inline-img">
        <img src={ExperienIcon} alt="icon" />
      </div>

      {/* <div className="complete-profile-sec">
        <Button variant="contained" className="crdit-detail-btn" onClick={() => history.push(`/credit-score/${id}`)}>
          SEE CREDIT DETAILS
        </Button>
      </div> */}
    </div>
  )
}

export default CreditScoreBar
