import { Button, Grid, Link, TextField } from '@material-ui/core'
import { DatePicker as YearPicker } from '@material-ui/pickers'
import DatePicker from 'components/common/datePicker'
import { fetchSuggestions as fetchSuggestionsDefault } from 'api/suggestions'
import PrimaryButton from 'components/common/buttons/primaryButton'
import { capitalizeString } from 'components/common/functions'
import OutlinedSelect from 'components/common/outlinedSelect'
import CopyTextFieldWrapper from 'components/common/textFields/copyTextFieldWrapper'
import moment from 'moment'
import SelectField from 'components/common/select'
import React, { useEffect, useState } from 'react'
import { formatDateTimeZoneForApiPost, backendDateFormat } from 'utils/formatDate'
import { TestID } from 'utils/testUtils/TestID'
import { ITaxOrder } from 'models/taxOrder'
import { useStyles } from './style'
import useTaxOrders from '../hooks'
import { TAX_ORDER_STAGES } from 'utils/constant'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import ModalWrapper from 'components/clientLeadManagerElements/modalWrapper'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { getScheulderLink, getTaxQuesResolvedAccountants } from 'api/taxOrders'
import useRouter from 'use-react-router'
import { Skeleton } from '@material-ui/lab'
interface IEditableTaxOrderComponentProps {
  editableTaxOrder: ITaxOrder
  handleTaxOrderChanges: <K extends ITaxOrder>(field: keyof K, value: K[keyof K]) => void
  isSendingRequest: boolean
  handleUpdate: () => Promise<void>
  open: boolean
  setOpen: any
  // accountants: Accountant[]
  // state: any
  // handleChange: any
  setQuesResolAccountants: any
  quesResolAccountants: any
}
type Accountant = {
  id: number
  first_name: string
  last_name: string
  type: string
}
const EditableTaxOrderComponent: React.FC<IEditableTaxOrderComponentProps> = ({
  editableTaxOrder,
  handleTaxOrderChanges,
  isSendingRequest,
  handleUpdate,
  open,
  setOpen,
  // accountants,
  // state,
  // handleChange,
  setQuesResolAccountants,
  quesResolAccountants,
}) => {
  const classes = useStyles()
  const { fetchSuggestions } = useTaxOrders()
  const [accountants, setAccountants] = React.useState<any>([])
  const [state, setState] = React.useState({})
  const router = useRouter<{ id: string | undefined }>()
  const [loading, setLoading] = useState(false)

  const [taxOrderStage, setTaxOrderStage] = useState('')

  // const [state, setState] = React.useState({
  //   gilad: false,
  //   jason: false,
  //   antoine: false,
  // })

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({ ...state, [event.target.name]: event.target.checked })
  // }

  // const { gilad, jason, antoine } = state

  const handleYearChange = React.useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) handleTaxOrderChanges('tax_year', date.year().toString())
    },
    [handleTaxOrderChanges]
  )

  const setOrderStage = async (value: string) => {
    setTaxOrderStage(value)

    const orderStage = {
      id: 1,
      status: '',
      title: value,
      stage_color: '',
    }
    handleTaxOrderChanges('tax_order_stage', orderStage)

    if (value === 'Questions Resolved') {
      getQuesResolAccountants()
    }
  }

  const orderStages = Object.values(TAX_ORDER_STAGES)

  if (!editableTaxOrder?.checklistanswer_completed) {
    // const index = orderStages.indexOf('Processing')
    // if (index !== -1) {
    //   orderStages.splice(index, 1)
    // }
  }
  const handleSave = () => {
    setOpen(false)
    handleUpdate()
    setTaxOrderStage('')
    setAccountants([])
  }
  const getQuesResolAccountants = async () => {
    setOpen(true)
    setLoading(true)
    const result = await getTaxQuesResolvedAccountants(Number(router?.match?.params?.id))
    setAccountants(result?.data)
    const initialState = result?.data.reduce((acc, item) => {
      acc[item.id] = false
      return acc
    }, {})
    setState(initialState)
    setLoading(false)
  }
  // const handleChange = event => {
  //   const { name, checked } = event.target
  //   setState(prevState => ({
  //     ...prevState,
  //     [name]: checked,
  //   }))
  //   handleTaxOrderChanges('accountantIds', )

  //   // if (checked) {
  //   //   const trueIds = Object.keys(state)
  //   //     .filter(id => state[id])
  //   //     .map(id => Number(id))
  //   //   setQuesResolAccountants(trueIds)
  //   //   console.log(trueIds,"ALLTRUESIDSSSSSSSSSSSSSSS")
  //   //   // setQuesResolAccountants([...quesResolAccountants, Number(name)])
  //   //   handleTaxOrderChanges('accountantIds', [...trueIds, checked ? Number(name) : null])
  //   // } else {
  //   //   quesResolAccountants.pop(Number(name))
  //   // }
  // }
  const handleChange = event => {
    const { name, checked } = event.target

    setState(prevState => {
      const newState = {
        ...prevState,
        [name]: checked,
      }

      // Generate the new accountantIds array
      const accountantIds = Object.keys(newState)
        .filter(key => newState[key])
        .map(id => Number(id))

      // Call the handleTaxOrderChanges with the new accountantIds array
      handleTaxOrderChanges('accountantIds', accountantIds)

      return newState
    })
  }

  const gotoDirectedLink = async () => {
    const result = await getScheulderLink(Number(router?.match?.params?.id))
    window.open(result?.link, '_blank')
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <OutlinedSelect
          name="Tax Order Stage"
          // style={{ width: '430px' }}
          classes={{ formControl: classes.textField }}
          value={editableTaxOrder?.tax_order_stage?.title || ''}
          options={orderStages}
          onOptionSelect={value => setOrderStage(value as string)}
        />
      </Grid>
      <Grid item xs={3}>
        <YearPicker
          views={['year']}
          label="Tax Year"
          value={editableTaxOrder.tax_year}
          onChange={handleYearChange}
          autoOk
          inputVariant="outlined"
          okLabel=""
          minDate={moment().add(-10, 'year')}
          maxDate={moment().add(+10, 'year')}
          className={classes.textField}
          // style={{ margin: '0.5rem 0', width: '100%' }} // `classes` prop doesn't exist
        />
        {console.log(state, 'asDJAKSDJas')}
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          value={editableTaxOrder.due_date && moment(editableTaxOrder.due_date)}
          // classes={{ root: classes.datePicker }}
          style={{ width: '100%' }}
          label="Due Date"
          onDateSelected={(value: string) => handleTaxOrderChanges('due_date', formatDateTimeZoneForApiPost(value))}
          format={backendDateFormat}
        />
      </Grid>
      <Grid item xs={3}>
        <CopyTextFieldWrapper valueToCopy={editableTaxOrder.tax_order_type}>
          <TextField
            variant="outlined"
            disabled
            label="Tax Order Type"
            type="text"
            name="tax_order_type"
            margin="normal"
            className={classes.textField}
            value={capitalizeString(editableTaxOrder.tax_order_type)}
          />
        </CopyTextFieldWrapper>
      </Grid>
      <Grid item xs={3}>
        <SelectField
          onOptionSelected={option => handleTaxOrderChanges('tax_controller', (option && option.value) || undefined)}
          title="Tax Controller"
          label="Tax Controller"
          fetchSuggestions={fetchSuggestionsDefault}
          field="assigned_to"
          placeholder="Choose Tax Controller"
          defaultValue={
            editableTaxOrder.tax_controller && editableTaxOrder.tax_controller.id
              ? {
                  value: editableTaxOrder.tax_controller.id,
                  label: `${editableTaxOrder.tax_controller.first_name} ${editableTaxOrder.tax_controller.last_name}`,
                }
              : undefined
          }
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          onOptionSelected={option => handleTaxOrderChanges('tax_associate', (option && option.value) || undefined)}
          title="Tax Associate"
          label="Tax Associate"
          fetchSuggestions={fetchSuggestionsDefault}
          field="assigned_to"
          placeholder="Choose Tax Associate"
          defaultValue={
            editableTaxOrder.tax_associate && editableTaxOrder.tax_associate.id
              ? {
                  value: editableTaxOrder.tax_associate.id,
                  label: `${editableTaxOrder.tax_associate.first_name} ${editableTaxOrder.tax_associate.last_name}`,
                }
              : undefined
          }
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          value={editableTaxOrder.status_processing_date && moment(editableTaxOrder.status_processing_date)}
          // classes={{ root: classes.datePicker }}
          style={{ width: '100%' }}
          label="Last Year Processing Date"
          onDateSelected={(value: string) =>
            handleTaxOrderChanges('status_processing_date', formatDateTimeZoneForApiPost(value))
          }
          format={backendDateFormat}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          onOptionSelected={option => handleTaxOrderChanges('accountant_id', (option && option.value) || undefined)}
          title="Accountant"
          label="Accountant"
          fetchSuggestions={fetchSuggestions}
          field="accountant_id"
          placeholder="Choose Accountant"
          defaultValue={
            editableTaxOrder.accountant && editableTaxOrder.accountant.id
              ? { value: editableTaxOrder.accountant.id, label: editableTaxOrder.accountant.name }
              : undefined
          }
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          onOptionSelected={option => handleTaxOrderChanges('senior_cpa', (option && option.value) || undefined)}
          title="Senior Accountant"
          label="Senior Accountant"
          fetchSuggestions={fetchSuggestions}
          field="senior_cpa"
          placeholder="Choose Senior Accountant"
          defaultValue={
            editableTaxOrder.senior_accountant && editableTaxOrder.senior_accountant.id
              ? {
                  value: editableTaxOrder.senior_accountant.id,
                  label: `${editableTaxOrder.senior_accountant.first_name} ${editableTaxOrder.senior_accountant.last_name}`,
                }
              : undefined
          }
        />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}>
        <Button variant="outlined" color="primary" onClick={() => gotoDirectedLink()} className={classes.schedulerLink}>
          Scheduler Direct Link
        </Button>
      </Grid>

      <Grid item xs={12} justify="flex-end" container>
        <div>
          <PrimaryButton
            style={{ marginTop: 10, marginBottom: 10, width: 350 }}
            onClick={() => {
              if (taxOrderStage === 'Questions Resolved' && accountants?.length && !quesResolAccountants.length) {
                setOpen(true)
              } else if (
                taxOrderStage !== 'Questions Resolved' ||
                (taxOrderStage === 'Questions Resolved' && quesResolAccountants.length)
              ) {
                handleUpdate()
              }
            }}
            disabled={isSendingRequest}
            data-testid={TestID.SAVE_TAX_ORDER_BUTTON}
          >
            Save Tax Order
          </PrimaryButton>
        </div>
      </Grid>
      <ModalWrapper open={open} setOpen={setOpen} heading="Select an Accountant:">
        <Grid container>
          <Grid item xs={12}>
            {loading ? (
              <>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
              </>
            ) : accountants?.length ? (
              <FormControl component="fieldset">
                <FormGroup>
                  {accountants?.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox checked={state[item.id] || false} onChange={handleChange} name={item.id.toString()} />
                      }
                      label={`${item?.first_name} ${item?.last_name} ( ${item?.type} )`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <p>No Accountants Found</p>
            )}
          </Grid>
          <Grid item xs={12} justify="flex-end" container>
            <PrimaryButton style={{ marginTop: 10, width: 150 }} disabled={!accountants?.length} onClick={handleSave}>
              Save
            </PrimaryButton>
          </Grid>
        </Grid>
      </ModalWrapper>
    </Grid>
  )
}

export default EditableTaxOrderComponent
