import React, { useMemo } from 'react'
import { createStyles, makeStyles, Theme, Button, Table, TableBody, TableContainer, Paper } from '@material-ui/core'
import { useHistory } from 'react-router'
import WebSubmissionHead from './webSubmissionTable/webSubmissionHead'
import { deleteWebSubmission, fetchWebSubmissions, WebSubmissionsData } from 'api/webSubmission'
import { PagedTable, usePagedTable } from '@jetkit/react'
import WebSubmissionRow from './webSubmissionTable/webSubmissionRow'
import { skeletons } from 'components/common/table'
import useSortTable from 'hooks/useSortTable'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { WEB_SUBMISSION_TYPE } from 'utils/constant'
import DeleteDialog from 'components/notifications/deleteDialog'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { checkNullValue } from 'utils/functions'
import { updatePageVisitsCount } from 'utils/user'

const WebSubmissions = () => {
  const classes = useStyles()
  const history = useHistory()
  const [query, setQuery] = React.useState('')

  const [webSubmissionToDelete, setWebSubmissionToDelete] = React.useState<WebSubmissionsData | undefined>()
  const [deleteItemDialogShown, setDeleteItemDialogShown] = React.useState(false)
  const [webArchiveIsLoading, setWebArchiveIsLoading] = React.useState(false)

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'order_date' })

  const { order, sort_by } = sortSettings

  // const paramsObj  {}

  // const type: number = WEB_SUBMISSION_TYPE?.NotArchive

  // const memoApiCall = useMemo((type) => fetchWebSubmissions()({ sort_order: order, sort_by, query }), [
  //   order,
  //   query,
  //   sort_by,
  // ])

  const type = WEB_SUBMISSION_TYPE?.NotArchive

  const memoApiCall = useMemo(() => fetchWebSubmissions(type, query)({ sort_order: order, sort_by }), [
    order,
    query,
    sort_by,
    type,
  ])

  const pagedTable = usePagedTable<WebSubmissionsData>({
    apiCall: memoApiCall,
  })

  const tableHead = useMemo(
    () => <WebSubmissionHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleEditWebSubmissionClick = React.useCallback((webSubmission: WebSubmissionsData) => {
    window.open(`/web-submission/${webSubmission?.order_id}`, '_blank')
    // history.push(`/web-submission/${webSubmission?.order_id}`)
  }, [])

  const handleDeleteItemsDialogCloseClick = React.useCallback(() => {
    setDeleteItemDialogShown(false)
  }, [])

  const handleDeleteWebSubmissionClick = React.useCallback((webSubmission: WebSubmissionsData) => {
    setWebSubmissionToDelete(webSubmission)
    setDeleteItemDialogShown(true)
  }, [])

  const handleArchive = async (id: number) => {
    if (!id) {
      return
    }
    setWebArchiveIsLoading(true)
    try {
      await deleteWebSubmission(id)
      showSuccessNotification('Successfully Archive')
      handleSuccessfulRequest()
      setWebArchiveIsLoading(false)
    } catch (error) {
      showApiResponseError(error, 'error')
    }
    setWebArchiveIsLoading(false)

    setDeleteItemDialogShown(false)
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.titlesContainer}>
            <p className={classes.missingItems}>Web Submission</p>
            <p className={classes.tableTitle}>Web Submission List</p>
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="outlined" color="primary" onClick={() => history.push('/archive-web-submissions')}>
              Achieved List
            </Button>
          </div>
        </div>
        <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search" />

        <ModalWrapper
          open={deleteItemDialogShown}
          setOpen={handleDeleteItemsDialogCloseClick}
          heading="Are you sure you want to archive?"
        >
          <h3>{`${checkNullValue(webSubmissionToDelete?.first_name)} ${checkNullValue(
            webSubmissionToDelete?.company_name
          )}`}</h3>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonWithLoading
              onClick={() => handleArchive(webSubmissionToDelete?.order_id || 0)}
              isLoading={webArchiveIsLoading}
            >
              Archive WebSubmission
            </ButtonWithLoading>
          </div>
        </ModalWrapper>
      </div>
      <div className={classes.tableSection}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <TableContainer component={Paper}>
            <Table>
              {tableHead}
              <TableBody>
                {skeletons({
                  numberOfColumns: 10,
                  sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
                  numberOfRows: 6,
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <PagedTable
              colSpan={10}
              emptyRowText="No WebSubmissions"
              {...pagedTable.renderProps}
              renderRow={(webSubmission: WebSubmissionsData) => (
                <WebSubmissionRow
                  webSubmissions={webSubmission}
                  onEditClick={() => handleEditWebSubmissionClick(webSubmission)}
                  onDeleteClick={() => handleDeleteWebSubmissionClick(webSubmission)}
                />
              )}
              header={tableHead}
            />
          </TableContainer>
        )}
      </div>
    </>
  )
}

export default WebSubmissions

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '50px 50px 5px 50px',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      marginBottom: '1rem',
    },
    titlesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    missingItems: {
      color: '#42aef4',
      textTransform: 'uppercase',
      letterSpacing: '0.09px',
      fontSize: 18,
      paddingBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    searchBar: {
      marginBottom: '1rem',
    },

    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    textField: {
      marginBottom: '1rem',
    },
    tableSection: {
      padding: '0 50px',
      // display: 'flex',
    },
  })
)
