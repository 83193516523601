import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const YearsTableHead: React.FC<any> = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>Title</StyledTableCell>
        <StyledTableCell>1st Compliance</StyledTableCell>
        <StyledTableCell>2nd Compliance</StyledTableCell>
        <StyledTableCell>3rd Compliance</StyledTableCell>
        <StyledTableCell>4th Compliance</StyledTableCell>
        <StyledTableCell>5th Compliance</StyledTableCell>
        <StyledTableCell>Mute/Unmute</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default YearsTableHead
