/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react'

interface ICreditProgressMetterSvg {
  part1?: string
  part2?: string
  part3?: string
  part4?: string
  part5?: string
  part6?: string
  svgWidth?: any
  svgHeight?: any
  text?: string
}

export const CreditProgressMetter = ({
  part1,
  part2,
  part3,
  part4,
  part5,
  part6,
  svgWidth,
  svgHeight,
  text,
}: ICreditProgressMetterSvg) => {
  const textRef = useRef<SVGTextElement>(null)

  useEffect(() => {
    if (textRef.current) {
      textRef.current.getBBox()
      const textX = `50%`
      const textY = `50%`

      textRef.current.setAttribute('x', textX)
      textRef.current.setAttribute('y', textY)
    }
  }, [])

  return (
    <svg width={svgWidth} height={svgHeight} viewBox={`0 0 402 400`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M84.585 362.145C41.5498 331.456 12.4681 284.929 3.73752 232.799L43.0491 226.215C50.0336 267.919 73.299 305.141 107.727 329.692L84.585 362.145Z"
        fill={part1 ? part1 : '#E74D41'}
      />
      <path
        d="M1.91998 219.007C-3.15286 166.395 12.8822 113.922 46.4977 73.1325L77.2573 98.482C50.3649 131.114 37.5368 173.092 41.5951 215.182L1.91998 219.007Z"
        fill={part2 ? part2 : '#D0D5DD'}
      />
      <path
        d="M54.5225 63.9795C90.5656 25.3181 140.491 2.5582 193.315 0.706716L194.711 40.5414C152.452 42.0226 112.512 60.2305 83.6772 91.1596L54.5225 63.9795Z"
        fill={part3 ? part3 : '#D0D5DD'}
      />
      <path
        d="M345.069 63.3951C309.026 24.7337 259.101 1.97378 206.277 0.122305L204.88 39.957C247.14 41.4382 287.08 59.6461 315.914 90.5752L345.069 63.3951Z"
        fill={part4 ? part4 : '#D0D5DD'}
      />
      <path
        d="M397.671 218.423C402.744 165.81 386.709 113.338 353.094 72.5481L322.334 97.8976C349.226 130.529 362.054 172.508 357.996 214.597L397.671 218.423Z"
        fill={part5 ? part5 : '#D0D5DD'}
      />
      <path
        d="M315.006 361.56C358.041 330.872 387.123 284.345 395.854 232.215L356.542 225.631C349.558 267.335 326.292 304.557 291.864 329.107L315.006 361.56Z"
        fill={part6 ? part6 : '#D0D5DD'}
      />
      <text
        ref={textRef}
        fill="black"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize={'5rem'}
        fontWeight={700}
        fontFamily="Roboto"
      >
        {text}
      </text>
    </svg>
  )
}
