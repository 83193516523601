import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Edit from '@material-ui/icons/Edit'
import { deleteNote, updateNoteText } from 'api/note'
import classNames from 'classnames'
import { INote } from 'models/note'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import { useContext } from 'react'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import ShowMoreText from 'react-show-more-text'
import { changeNToBrTag } from 'utils/conversations'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog'
import NoteEditButtons from './noteEditButtons'
import useStyles from './styles'

interface INoteProps {
  noteData: any //INote
  color?: string
}

const Note: React.FC<INoteProps> = ({ noteData, color }) => {
  const classes = useStyles()
  const { created_on, note_text, status_changed } = noteData
  const [editing, setEditing] = React.useState<boolean>(false)
  const noteValue = React.useRef('')

  const { pagedTable } = useContext(NotesTableContext)

  const handleEditingClicked = React.useCallback(() => {
    noteValue.current = note_text
    setEditing(true)
  }, [setEditing, note_text])

  const handleNoteSave = React.useCallback(async () => {
    try {
      await updateNoteText({ note_text: noteValue.current, noteId: noteData.id })
      pagedTable?.reloadData()
      setEditing(false)
    } catch (error) {
      showApiResponseError(error, 'Failed to create note')
    }
  }, [setEditing, noteValue, noteData.id, pagedTable])

  const handleCancelEdit = React.useCallback(() => {
    noteValue.current = note_text
    setEditing(false)
  }, [setEditing, note_text])

  const handleNoteChange = (evt: ContentEditableEvent) => {
    noteValue.current = evt.target.value
  }

  const shouldShowEditButtons = !editing // && editable
  if (status_changed) {
    color = '#AFE1AF'
    if (noteData.creator.name === 'System') {
      color = 'rgb(119 153 153 / 39%)'
    }
  }
  const styles = color ? { backgroundColor: color } : {} // if color for this type of note is not provided, use default one from classes

  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)

  const handleNoteDelete = React.useCallback(async () => {
    try {
      await deleteNote(noteData.id)
      setShowDeleteDialog(false)
      pagedTable?.reloadData()
      showSuccessNotification('Note deleted')
    } catch (err) {
      showApiResponseError(err, 'Failed to delete note')
    }
  }, [setShowDeleteDialog, noteData, pagedTable])
  const transformText = (note: string) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = note
    return tmp.textContent || tmp.innerText || ''
  }

  return (
    <div className={classes.root}>
      <DeleteConfirmationDialog
        itemName={
          <React.Fragment>
            Note
            <br />
            <br />
            {note_text}
          </React.Fragment>
        }
        onClose={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        onDelete={handleNoteDelete}
      />
      <div className={classes.container} style={styles} data-testid="note-row">
        <div className={classes.noteContent}>
          <Typography className={classNames(classes.dateTime, classes.noteAuthor)}>
            {formatDateTimeToHumanFriendly(created_on)}, &nbsp;{noteData.creator && noteData.creator.name}
          </Typography>
          <Typography className={classNames(classes.dateTime, classes.noteAuthor)}>{noteData.title}</Typography>
          <Typography className={classes.textContainer}>
            {changeNToBrTag(noteData?.note_text)}
            {/* {noteData?.note_text} */}
            {/* {editing ? (
              <ContentEditable
                html={noteValue.current}
                data-column="item"
                className="content-editable"
                onChange={handleNoteChange}
                onBlur={handleNoteSave}
              />
            ) : (
              // <ShowMoreText anchorClass={classes.showMore}>
              //   {transformText(note_text)}
              //   <br />
              // </ShowMoreText>
              transformText(note_text)
            )} */}
          </Typography>
        </div>
        {/* {shouldShowEditButtons && (
          <div className={classes.buttonsContainer}>
            <IconButton disableRipple className={classes.iconButton} onClick={handleEditingClicked}>
              <Edit className={classes.editIcon} />
            </IconButton>

            <IconButton disableRipple className={classes.iconButton} onClick={() => setShowDeleteDialog(true)}>
              <CloseIcon className={classes.deleteIcon} />
            </IconButton>
          </div>
        )} */}
      </div>
      {editing && (
        <NoteEditButtons className={classes.editButtonsContainer} onSave={handleNoteSave} onCancel={handleCancelEdit} />
      )}
    </div>
  )
}

export default Note
