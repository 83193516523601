import React, { useState, useEffect, useCallback } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  MenuItem,
  Grid,
  Select,
  FormControl,
  TextField,
  Checkbox,
  Badge,
  IconButton,
  TablePagination,
  Fab,
  CircularProgress,
  InputLabel,
  TableContainer,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText,
} from '@material-ui/core'
import {
  CheckBoxOutlineBlank,
  ChevronLeftSharp,
  ChevronRightSharp,
  FormatAlignLeft,
  Send,
  Search,
  CheckBox,
  Done,
  GetApp,
  Close,
  Warning,
} from '@material-ui/icons'
import { useStyles } from 'pages/clientDashboard/table/styles'
import SelectField from 'pages/clientDashboard/selectField'
import ProfileSettingModal from '../profileSettingModal'
import { Theme, withStyles, createStyles } from '@material-ui/core/styles'
import { getClientCompanies, getCorrespondenceDocumentsVault } from 'api/clientDashboard'
import { formatDateToHumanFriendly } from 'utils/formatDate'
import clsx from 'clsx'
import { downloadClientCompanyDocumentURL, downloadCompanyDocumentURL } from 'api/companies'
import { Alert, Skeleton } from '@material-ui/lab'
import { uniqueArrayObj } from 'utils/functions'
import { ISuggestionAttribute } from 'components/interfaces'
import showErrorNotification from 'utils/showErrorNotification'
import { getDocumentTypes } from 'api/documentTypes'
import moment from 'moment'
import { AppContext } from 'App'
import ModalTitle from 'components/common/modalTitle'
import { Link } from 'react-router-dom'

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 6,
      top: 0,
      padding: '0 4px',
      background: '#f47950',
      color: '#fff',
    },
  })
)(Badge)

const DocumentVault = () => {
  const classes = useStyles()

  const [selectValue, setSelectValue] = useState('')

  const [correspondenceDocumentsRow, setCorrespondenceDocumentsRow] = useState<any>()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [docs, setdocs] = React.useState<any>([])
  const [companies, setCompanies] = React.useState<any>([])

  const [selectedCompany, setSelectedCompany] = useState('')

  // const [companyName, setCompanyName] = useState<ISuggestionAttribute>()
  // const [docType, setDocType] = useState<ISuggestionAttribute>()

  const [selectedDocType, setSelectedDocType] = useState('')
  const [selectedDate, setSelectedDate] = useState('')

  const [isCorrespondenceDocuments, setIsCorrespondenceDocuments] = useState(false)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectValue(event.target.value as string)
  }

  const [docId, setDocId] = useState<string>('')
  const [isDownloadableLink, setIsDownloadableLink] = useState<boolean>(false)
  const [success, setSuccess] = useState(false)

  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [paymentLink, setPaymentLink] = useState<string>('')

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  const getDocumentsVault = useCallback(async () => {
    setIsCorrespondenceDocuments(true)
    const payload = {
      query: '',
      company_id: (selectedCompany && selectedCompany) || null,
      document_id: (selectedDocType && selectedDocType) || null,
      uploaded_on: selectedDate && moment(selectedDate).format(),
      page: page + 1,
      page_size: rowsPerPage,
    }
    try {
      const documents = await getCorrespondenceDocumentsVault(payload)
      setCorrespondenceDocumentsRow(documents)
      setIsCorrespondenceDocuments(false)
    } catch (error) {
      showErrorNotification(error)
      console.log(error)
    }
    setIsCorrespondenceDocuments(false)
  }, [page, rowsPerPage, selectedCompany, selectedDate, selectedDocType])

  useEffect(() => {
    getDocumentsVault()
  }, [getDocumentsVault])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // const uniqueDate = correspondenceDocumentsRow?.filter((c, index) => {
  //   console.log('c', c?.uploaded_on)
  //   return correspondenceDocumentsRow?.indexOf(c?.uploaded_on) === index
  // })

  // console.log('uniqueDate', uniqueDate)

  const [companiesNameArr, setCompaniesNameArr] = useState([])
  const [docTypeArr, setDocTypeArr] = useState([])
  const [currentDoc, setCurrentDoc] = useState<any>()

  const companiesNameArrHandler = React.useCallback(() => {
    const arr = correspondenceDocumentsRow?.data?.map(item => {
      return item?.company_name
    })
    setCompaniesNameArr(arr)
  }, [correspondenceDocumentsRow])

  const docTypeArrHandler = React.useCallback(() => {
    const arr = correspondenceDocumentsRow?.data?.map(item => {
      return item?.doc_type
    })
    setDocTypeArr(arr)
  }, [correspondenceDocumentsRow])

  useEffect(() => {
    companiesNameArrHandler()
    docTypeArrHandler()
  }, [companiesNameArrHandler, correspondenceDocumentsRow, docTypeArrHandler])

  React.useEffect(() => {
    const getCompanyDetails = async () => {
      try {
        const data: any = await getClientCompanies()
        setCompanies(data)
      } catch (error) {
        console.error(error)
      }
    }
    getCompanyDetails()
  }, [])

  const companiesList = companies?.length
    ? companies?.map((details: any) => {
        return {
          value: details?.id,
          label: details?.name,
        }
      })
    : []

  console.log('companies12345', companies)

  React.useEffect(() => {
    const getdocs = async () => {
      const data: any = await getDocumentTypes()
      setdocs(data)
    }
    getdocs()
  }, [])

  const docTypeList = docs?.map((details: any) => {
    return {
      value: details?.id,
      label: details?.typename,
    }
  })

  // const handleSelectingCompany = event => {
  //   setCompanyName(event?.target?.value)
  // }
  // const handleSelectingDocType = event => {
  //   setDocType(event?.target?.value)
  // }

  // const companiesName = [...new Set(companiesNameArr)]
  // const docTypesName = [...new Set(docTypeArr)]
  const count = React.useContext(AppContext).unreadDocsCount
  const { setUnreadDocsCount } = React.useContext(AppContext)

  const getDownloadDocument = async (objectId: number, assetId: number, downloaded_by_client: boolean) => {
    try {
      debugger
      setIsDownloadableLink(true)
      const downloadDoc = await downloadClientCompanyDocumentURL({ companyId: objectId, documentId: assetId })

      console.log('downloadDocdownloadDocdownloadDoc', downloadDoc)
      setCurrentDoc(correspondenceDocumentsRow.data.find(item => item.id === assetId))
      if (downloadDoc?.subscription_inactive) {
        setIsAlertOpen(downloadDoc?.subscription_inactive)
        setPaymentLink(downloadDoc?.payment_link)
      } else {
        const tempArr = correspondenceDocumentsRow.data.map(obj => {
          if (obj.id === assetId) {
            return { ...obj, downloaded_by_client: true }
          }
          return obj
        })
        debugger
        setCorrespondenceDocumentsRow({ data: tempArr })
        window.location.assign(downloadDoc || '')
        setSuccess(true)
        !downloaded_by_client && setUnreadDocsCount(count - 1)
      }
    } catch (error) {
      console.log(error)
    }
    setIsDownloadableLink(false)
  }

  const handleCancelFilter = () => {
    setSelectedDate('')
    setSelectedCompany('')
    setSelectedDocType('')
  }

  const dataCount = correspondenceDocumentsRow?.pagination?.total

  return (
    <div className={classes.deadlineContainer}>
      {/* <Grid container spacing={3} alignItems="center" style={{ width: 'calc(100% + -5px)' }}> */}
      <Grid container spacing={3} alignItems="center">
        {/* <Grid item>
          <FormatAlignLeft />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} lg={3}>
          <form className={classes.dateContainer} noValidate>
            <TextField
              id="date"
              label="Uploaded on"
              type="date"
              variant="outlined"
              placeholder="Uploaded on"
              // defaultValue={new Date().toLocaleDateString()}
              className="date-field"
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedDate}
              onChange={(e: any) => setSelectedDate(e.target.value)}
            />
          </form>
        </Grid> */}
        <Grid item xs={12} sm={6} lg={4} xl={4}>
          <FormControl variant="outlined" className={classes.selectFormControl}>
            <InputLabel>Search by Company Name</InputLabel>
            <Select
              className="select-field"
              label="Search by Company Name"
              fullWidth
              value={selectedCompany}
              name="selectedCompany"
              onChange={(e: any) => {
                setSelectedCompany(e.target.value)
                setSelectedDocType('')
              }}
            >
              {uniqueArrayObj(companiesList)?.length ? (
                uniqueArrayObj(companiesList)?.map(item => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No item</MenuItem>
              )}
            </Select>
          </FormControl>
          {/* <SelectField menuItems={correspondenceDocumentsRow} label="Search by Company Name" /> */}
          {/* <SelectField
            menuItems={uniqueArrayObj(companiesList)}
            placeholder="Search by Company Name"
            value={companyName || null}
            handleChange={handleSelectingCompany}
          /> */}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormControl variant="outlined" className={classes.selectFormControl}>
            <InputLabel>Search by Document Type</InputLabel>
            <Select
              className="select-field"
              value={selectedDocType}
              label="Search by Document Type"
              fullWidth
              name="selectedDocType"
              onChange={(e: any) => {
                setSelectedDocType(e.target.value)
                setSelectedCompany('')
              }}
            >
              {/* {docTypesName?.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))} */}
              {uniqueArrayObj(docTypeList)?.length ? (
                uniqueArrayObj(docTypeList)?.map(item => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No item</MenuItem>
              )}
            </Select>
          </FormControl>
          {/* <SelectField menuItems={correspondenceDocumentsRow} label="Search by Document Type" /> */}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <div className={classes.btnWrapper}>
            <Button
              variant="contained"
              className={classes.cancelButton}
              startIcon={<Close />}
              onClick={handleCancelFilter}
            >
              Cancel Filter
            </Button>
          </div>
        </Grid>
      </Grid>

      <br />

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                {/* <FormControl className={classes.formControl}>
                  <Select
                    value={selectValue}
                    name="selectValue"
                    onChange={handleChange}
                    displayEmpty
                    className="select-filed"
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Date
                    </MenuItem>
                    {correspondenceDocumentsRow?.map((row, i) => (
                      <MenuItem value={formatDateToHumanFriendly(row?.uploaded_on)}>
                        {formatDateToHumanFriendly(row?.uploaded_on)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                Date
              </TableCell>
              <TableCell>Company Name | SOF</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* row?.doc_type === selectedDocType ||
              row?.company_name === selectedCompany ||
             (row?.formatDateToHumanFriendly(row?.uploaded_on) === selectedDate && */}
            {isCorrespondenceDocuments ? (
              <TableRow className={classes.root}>
                {[1, 2, 3, 4, 5]?.map(_ => (
                  <TableCell component="th" scope="row">
                    <Skeleton variant="rect" width={'100%'} height={'50px'} />
                  </TableCell>
                ))}
              </TableRow>
            ) : // correspondenceDocumentsRow?.data
            //   ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            //   .map((row, i) =>
            correspondenceDocumentsRow?.data?.length > 0 ? (
              correspondenceDocumentsRow?.data?.map((row, i) => (
                <TableRow key={i} className="table-row">
                  {/* <TableCell component="th" scope="row">
                    <Checkbox
                      className="row-checkbox"
                      icon={<CheckBoxOutlineBlank className="checkbox-outline" />}
                      checkedIcon={<CheckBox className="checkbox-filled" />}
                    />
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    {formatDateToHumanFriendly(row?.uploaded_on)}
                  </TableCell>
                  <TableCell>{row?.company_name}</TableCell>
                  <TableCell>{row?.doc_type}</TableCell>
                  <TableCell>{row?.orderName}</TableCell>
                  <TableCell className="inline-btn">
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="download"
                        color="primary"
                        disableRipple
                        className={buttonClassname}
                        onClick={() => {
                          getDownloadDocument(row?.company_id, row?.id, row?.downloaded_by_client)
                          setDocId(row?.id)
                        }}
                        disabled={docId === row?.id && success}
                      >
                        {docId === row?.id && success ? <Done /> : <GetApp />}
                      </Fab>
                      {docId === row?.id && isDownloadableLink ? (
                        <CircularProgress size={36} className={classes.fabProgress} />
                      ) : (
                        ''
                      )}
                      {!row.downloaded_by_client && docId !== row?.id && (
                        <Chip label={'New'} size="small" style={{ backgroundColor: '#7dd37d' }} />
                      )}
                    </div>

                    {/* <Search className="search-icon" onClick={() => setOpen(true)} /> */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.root}>
                <TableCell>
                  <Alert severity="info">No Document Found</Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Grid
          spacing={1}
          alignItems="center"
          justify="space-between"
          // style={{ width: 'calc(100% + 16px)' }}
          className="import-header"
        >
          <Grid item xs={12} className="inline-item">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataCount ? dataCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </TableContainer>
      {/* <ProfileSettingModal open={open} setOpen={setOpen} /> */}

      <Dialog open={isAlertOpen} maxWidth="sm" disableBackdropClick fullWidth={true}>
        <br />
        <ModalTitle
          customTitleComponent={
            <Box display={'flex'} alignItems={'center'} ml={'1rem'}>
              <Warning color="error" style={{ marginRight: '0.5rem' }} />
              <h2 style={{ margin: 0 }}>Attention</h2>
            </Box>
          }
          toggleModal={setIsAlertOpen}
        />
        <DialogContent>
          <DialogContentText style={{ margin: 0 }}>
            {paymentLink
              ? `Your ${
                  currentDoc?.address_document ? 'Address' : 'Registered Agent'
                } Subscription is Unpaid. Kindly pay now
            to access the document.`
              : `Your  ${
                  currentDoc?.address_document ? 'Address' : 'Registered Agent'
                } Subscription doesn't Exists. Kindly Reach out to our Representative for Subscription Activation`}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {paymentLink && (
            <Button variant="contained" color="primary" onClick={() => window.open(`${paymentLink}`, '_blank')}>
              Pay Now
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DocumentVault

const listItems = [
  {
    value: 'item1',
    label: 'item1',
  },
  {
    value: 'item2',
    label: 'item2',
  },
  {
    value: 'item3',
    label: 'item3',
  },
  {
    value: 'item4',
    label: 'item4',
  },
]

/* <DataGrid
rows={correspondenceDocumentsRow}
columns={columns}
pageSize={5}
rowsPerPageOptions={[10]}
checkboxSelection
/>
const columns: GridColDef[] = [
  { field: 'uploaded_on', headerName: 'DATE', width: 90 },
  {
    field: 'company_name',
    headerName: 'COMPANY NAME | SOF	',
    width: 150,
  },
  {
    field: 'doc_type',
    headerName: 'DOCUMENT TYPE',
    width: 150,
  },
  {
    field: 'orderName',
    headerName: 'ORDER TYPE',
    type: 'number',
    width: 110,
  },
] */
