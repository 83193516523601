// import { Button } from '@material-ui/core'
import React from 'react'
// import CallIcon from '@material-ui/icons/Call'
import { useStyles } from './style'
import BG from 'img/schedule-bg.webp'
import { CloseRounded } from '@material-ui/icons'
import CalendlyModal from './CalendlyModal'

const ScheduleCallCard = () => {
  const classes = useStyles()

  return (
    <div className={classes.scheduleCallCard}>
      <img src={BG} className="schedule-bg" alt="bg" />
      <div className="content-sec">
        <span>
          <h1 className="primary-text">Get help with Taxes!</h1>
          <p className="secondary-text">Meet our Tax Advisors who can help you learn how to spend</p>
          <p className="secondary-text">money correctly and maximize your tax deductions.</p>
        </span>
        {/* <Button className="schedule-btn" startIcon={<CallIcon />}>
          SCHEDULE
        </Button> */}
        <CalendlyModal
          calendlyUrl="https://calendly.com/d/zbt7-gs9h/businessrocket-tax-team-call-back"
          btnText="Learn About Business Taxes"
        />
        {/* <CloseRounded className="close-icon" /> */}
        &emsp;
      </div>
    </div>
  )
}

export default ScheduleCallCard
