import { FilledInputProps, InputProps, OutlinedInputProps } from '@material-ui/core'
import * as React from 'react'
import BRTextField from '../textFields/BRTextField'

interface ICompanyOrderPriceFieldProps {
  classes: object
  className?: string
  InputProps: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined
  priceValue: number
  onPriceChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

/**
 * We wanna know how much to charge for an order. This price ought to be stored as an integer on the backend.
 *
 * @param priceValue: Current value of the field
 * @param onPriceChanged: Triggers on the field being editted
 */
export const OrderPriceField = ({
  className,
  classes,
  InputProps,
  priceValue,
  onPriceChange,
}: ICompanyOrderPriceFieldProps) => {
  return (
    <BRTextField
      variant={'outlined'}
      label="Price"
      classes={classes}
      className={className}
      InputProps={InputProps}
      value={priceValue}
      onChange={onPriceChange}
      type="number"
    />
  )
}
