import React, { useCallback, useState } from 'react'
import { useStyles } from '../styles'
import { Avatar, Box, Button, Grid, TextField } from '@material-ui/core'
import { Error, GetAppOutlined } from '@material-ui/icons'
import showErrorNotification from 'utils/showErrorNotification'
import { fileUploadSignURL, missingItemAnswer } from 'api/clientMissingItem'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import showApiResponseError from 'utils/showApiResponseError'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { MISSING_ITEM_ALL_STATUS } from 'utils/constant'
import DownloadButton from 'components/common/assetUpload/downloadButton'
interface QuestionProps {
  question?: any
  id?: number
  answer?: string
  rejectedReason?: string
  status?: string
  attachments?: any
  reloadData?: any
}

const Question = ({ question, id, answer, rejectedReason, status, attachments, reloadData }: QuestionProps) => {
  const classes = useStyles()
  const [loading, setloading] = React.useState<boolean>(false)
  const [answerText, setAnswerText] = useState<string>('')
  const [fileToUpload, setFileToUpload] = useState<File>()
  const [isUpload, setisUpload] = React.useState<boolean>(false)
  //   const [isFileResponseUploading, setIsFileResponseUploading] = useState(false)
  const [fileName, setFileName] = useState('')
  const submitAnswer = useCallback(async () => {
    try {
      setloading(true)
      const response = await missingItemAnswer(id || 0, answerText)
      console.log(response)
      showSuccessNotification('Submitted Successfully')
      if (fileToUpload) await handleFileUpload(fileToUpload)
      setloading(false)
      setisUpload(false)
      setFileToUpload(undefined)
      setAnswerText('')
      reloadData()
    } catch (error) {
      showErrorNotification('Error submitting Answer')
      setloading(false)
    }
  }, [id, answerText, fileToUpload])
  const onDrop = useCallback(
    (files: File[]) => {
      console.log('files', files)
      setFileName(files[0]?.name)

      if (!files.length) {
        console.error(`No file provided`)
      }

      const file = files[0]
      setFileToUpload(file)
    },
    [id, answerText]
  )
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true })
  const handleFileUpload = async (file: File) => {
    try {
      //   setIsFileResponseUploading(true)

      const fileUpload = await fileUploadSignURL(id || 0, file?.name)

      const uploadS3 = await axios({
        url: fileUpload.url,
        method: 'PUT',
        headers: fileUpload?.headers,
        data: file,
      })
      if (uploadS3?.status === 200) {
        showSuccessNotification('File successfully upload')
        setAnswerText('')
      }

      setisUpload(false)
    } catch (error) {
      showErrorNotification(error ? (error as any)?.message : 'Something went wrong!')
      showApiResponseError(error as any, 'Something went wrong!')
    }
    // setIsFileResponseUploading(false)
  }
  const getFileExtension = filename => {
    const extension = filename?.split('.').pop()
    return extension
  }

  return (
    <Box className={classes.questionContainer}>
      <Grid container>
        <Grid item xs={12}>
          <p className="quest" dangerouslySetInnerHTML={{ __html: question }} />
          <TextField
            onChange={e => setAnswerText(e.target.value)}
            className="question-field"
            fullWidth
            variant="outlined"
            defaultValue={answer}
            disabled={status === '2' || status === '1' ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item md={9} sm={9} xs={7}>
              <div className="attachment-div">
                <input {...getInputProps()} disabled={status === '2' || status === '1' ? true : false} />
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.813rem', cursor: 'pointer' }}>
                  <p style={{ marginRight: '0.5rem', display: 'flex', alignItems: 'center' }} onClick={open}>
                    <AttachmentIcon />
                    <p style={{ margin: 0, marginLeft: '0.5rem' }}>{fileName?.length ? fileName : 'Add File'}</p>
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item md={3} sm={3} xs={5}>
              <div className={classes.submitbtnDiv}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  aria-label="Upload"
                  onClick={submitAnswer}
                  size="large"
                  className="submitbtn"
                  disabled={loading || status === '2' || status === '1' ? true : false}
                >
                  {status === '0' || status === '3' ? 'Submit' : MISSING_ITEM_ALL_STATUS[status || '0']}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="error-div">
                {rejectedReason ? (
                  <>
                    <i>
                      <Error />
                    </i>
                    <p>{rejectedReason}</p>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.attachmentList}>
                {attachments && attachments.length > 0 && (
                  <div className={classes.imageList} key={0}>
                    <div className={classes.imageFile}>
                      <Avatar variant="rounded" className={classes.rounded} src={attachments[0]?.url}>
                        {getFileExtension(attachments[0]?.file_name)}
                      </Avatar>
                      <p>{attachments[0]?.file_name}</p>
                    </div>

                    <a
                      href={attachments[0]?.download_link}
                      className={classes.downloadButtonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadButton className={classes.downloadButton}>
                        <GetAppOutlined color="primary" />
                      </DownloadButton>
                    </a>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Question
