import React from 'react'
import { Badge, IconButton, TableCell, TableRow } from '@material-ui/core'
import EditIcon from 'components/common/icons/edit'
import { OrderReminderProps } from 'api/orderItem'

import { Edit, Visibility } from '@material-ui/icons'

interface OrderReminderRowProps {
  orderItem: OrderReminderProps
  onEditClick: () => void
}

const OrderReminderRow: React.FC<OrderReminderRowProps> = ({ orderItem, onEditClick }) => {
  const handleEditIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onEditClick()
    },
    [onEditClick]
  )

  console.log('orderItemorderItem', orderItem)

  return (
    <TableRow style={{ cursor: 'pointer' }} data-testid={`row-for-notification-with-id-${1}`}>
      <TableCell style={{ minWidth: 120 }}>{orderItem?.tax_order_id}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{orderItem?.reminder_template_id}</TableCell>
      <TableCell style={{ maxWidth: 120 }}>{orderItem?.name}</TableCell>

      <TableCell style={{ minWidth: 60 }}>
        <IconButton onClick={e => handleEditIconClick(e)}>
          {orderItem?.open_item ? (
            <Badge color="secondary" variant="dot">
              <Visibility />
            </Badge>
          ) : (
            <Visibility />
          )}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default OrderReminderRow
