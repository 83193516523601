import { IUser } from 'models/user'
import { useCallback, useEffect, useState } from 'react'

export const CURRENT_USER_LOCAL_STORAGE_KEY = 'currentUser'

const useUser = (): {
  user: IUser | null
  updateUser: (user: IUser | null) => void
} => {
  const [user, setUser] = useState<IUser | null>(null)

  useEffect(() => {
    const userJson = localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY)
    const user: IUser | null = userJson && JSON.parse(userJson)
    setUser(user)
  }, [])

  const updateUser = useCallback((user: IUser | null) => {
    const userJson = JSON.stringify(user)
    localStorage.setItem(CURRENT_USER_LOCAL_STORAGE_KEY, userJson)
  }, [])

  return { user, updateUser }
}

export default useUser
