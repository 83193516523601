import {
  HeadColumn,
  skeletons,
  SortableTableHead,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'invoice_no', label: 'Invoice No.', style: {}, sortable: true },
  { id: 'issue_date', label: 'Issue Date', style: {}, sortable: false },
  { id: 'amount', label: 'Amount', style: {}, sortable: true },
  { id: 'filer_name', label: 'Filer', style: {}, sortable: false },
  { id: 'created_at', label: 'Created At', style: {}, sortable: true },
  // { id: 'sales_rep', label: 'Sales Rep.', style: {}, sortable: false },
  { id: 'created_by', label: 'Created By', style: {}, sortable: false },
  { id: 'paid_at', label: 'Payment Date', style: {}, sortable: false },
  { id: 'paid_by', label: 'Paid By', style: {}, sortable: false },

  { id: 'paid', label: 'Status', style: {}, sortable: true },

  { id: 'actions', label: '', style: { width: '16%' }, sortable: false },
]
const InvoiceTableHead: React.FC<{ sortSettings: ISortSettings; handleSortingClick: (sort_by: string) => void }> = ({
  sortSettings,
  handleSortingClick,
}) => {
  return (
    // <StyledTableHead>
    //   <StyledTableRow>
    //     <StyledTableCell>Invoice No.</StyledTableCell>
    //     <StyledTableCell>Issue Date</StyledTableCell>
    //     <StyledTableCell>Amount</StyledTableCell>
    //     <StyledTableCell>Filer</StyledTableCell>
    //     <StyledTableCell>Created At</StyledTableCell>
    //     <StyledTableCell>Created By</StyledTableCell>
    //     <StyledTableCell>Payment Date</StyledTableCell>
    //     <StyledTableCell>Paid By</StyledTableCell>

    //     <StyledTableCell>Status</StyledTableCell>

    //     {/* <StyledTableCell>Action</StyledTableCell> */}
    //   </StyledTableRow>
    // </StyledTableHead>
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default InvoiceTableHead
