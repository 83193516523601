import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  productContainerRoot: {
    '& .name_field': {
      width: '300px',
      marginBottom: '1rem',

      //   '& .MuiOutlinedInput-root': {
      //     fontSize: '12px',
      //     color: '#000',
      //   },
    },
    '& .accordian_main_container': {
      display: 'initial',
      '& .acoordion_details_container': {
        padding: '0 1rem',
        '& .radio_btns_container': {
          marginTop: '1.5rem',
          '& .recurring_btn': {
            border: 'none',
            background: 'none',
            padding: '0.5rem',
          },
        },
        '& .drop_down_container': {
          maxWidth: '15.813rem',
          '& .select_down': {
            '& .MuiSelect-outlined': {
              padding: '0.6rem 0.875rem',
              maxWidth: '1000px',
            },
          },
        },
      },
    },

    '& .summary_content': {
      '& .MuiAccordionSummary-content': {
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& .delete_btn': {
        color: '#6F6F6F',
      },
    },

    '& .add_price_btn_container': {
      marginTop: '1rem',
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      '& .save_btn': {
        backgroundColor: '#2F93E0',
        color: '#fff',
        fontWeight: 'bold',
      },
    },
  },
})
