import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteModal: {
      justifyContent: 'center',
      textAlign: 'center',
      color: '#000',

      '& .close-icon': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1rem 1rem 0 0',

        '& .close': {
          cursor: 'pointer',
          color: '#707070',
        },
      },

      '& .modal-action': {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        paddingTop: '1rem',
        paddingBottom: '1.5rem',

        '& .remove-btn': {
          '& button': {
            color: '#fff',
            background: '#e90e00',
            fontSize: '14px',
            fontWeight: 400,
            height: 'auto',
          },
        },
        '& .keep-btn': {
          color: '#fff',
          background: '#307abc',
          fontSize: '14px',
          fontWeight: 400,
        },
      },
    },
  })
)
