import { PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { paginateStates, deleteOffer } from 'api/specialOffers'

import CreateClient from 'components/specialOffers/create'
import ClientTableHead from 'components/specialOffers/tablePowerUps/head'
import ClientTableRow from 'components/specialOffers/tablePowerUps/row'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import { ISpecialOffers } from 'models/user'
import * as React from 'react'
import { theme } from 'theme'
import useRouter from 'use-react-router'
import { updatePageVisitsCount } from 'utils/user'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.title.fontFamily,
    fontWeight: 600,
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
})

interface IOfferProps extends WithStyles<typeof styles> {}

const PowerUps: React.FC<IOfferProps> = ({ classes }) => {
  const [query, setQuery] = React.useState('')

  const apiCall = React.useMemo(() => paginateStates()({ query }), [query])
  const pagedTable = usePagedTable<ISpecialOffers>({ apiCall })

  return (
    <div className={classes.container}>
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>Power Ups</Typography>
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar onChange={setQuery} placeholder="Search by title" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            <ClientTableHead />
            <TableBody>{skeletons({ numberOfColumns: 3, numberOfRows: 5 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Data"
            {...pagedTable.renderProps}
            renderRow={(client: ISpecialOffers) => <ClientTableRow offers={client}></ClientTableRow>}
            header={<ClientTableHead />}
            colSpan={6}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(PowerUps)
