import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const ClientTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>Street Address</StyledTableCell>
        <StyledTableCell>City</StyledTableCell>
        <StyledTableCell>State</StyledTableCell>
        <StyledTableCell>Zip</StyledTableCell>
        <StyledTableCell>Ref Code</StyledTableCell>
        <StyledTableCell>Login Username</StyledTableCell>

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default ClientTableHead
