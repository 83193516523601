import { FilterableAPICall, PagedTable, usePagedTable } from '@jetkit/react'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { deleteBOQuestion, fetchBOQuestions, IBOQues } from 'api/taxOrders'
import SearchBar from 'components/common/searchBar'

import { assetTypeToDocumentHandler, DocumentHandler, UploadRequestClass } from 'models/asset'
import * as React from 'react'
import Toolbar from '../pendingQuestions/toolbar'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ComponentToPrint } from 'utils/pdfPrintWrapper'
import { CircularProgress } from '@material-ui/core'
import QuestionCard from './questionCard'
import QuestionDialog from './questionDialog'
import BOToolbar from './toolbar'
import showSuccessNotification from 'utils/showSuccessNotification'

export const AssetsUploadCallBackContext = React.createContext<UploadRequestClass | undefined>(undefined)
export const AssetsUploadCallBackContextAnswer = React.createContext<UploadRequestClass | undefined>(undefined)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: theme.customPalette.tableBackgroundColor,
      paddingBottom: 300,
    },
    innerContainer: {
      display: 'flex',
      maxWidth: '52rem',
      width: '100%',
      flexDirection: 'column',
    },
    searchBarContainer: {
      marginTop: '2rem',
      display: 'flex',
      width: '100%',
    },
  })
)

interface IBOQuestionsProps {
  taxOrderId: number
  reminderSequence: string | null | undefined
  // editableTaxOrder?.reminder_sequence
  apiCall?: FilterableAPICall<IBOQues>
}

const BOQuestions: React.FC<IBOQuestionsProps> = ({
  taxOrderId,
  apiCall = fetchBOQuestions(taxOrderId),
  reminderSequence,
}) => {
  const documentHandler: DocumentHandler = React.useMemo(() => {
    const DocumentHandler = assetTypeToDocumentHandler['bo_question']
    return new DocumentHandler()
  }, [])

  const documentHandlerAnswer: DocumentHandler = React.useMemo(() => {
    const DocumentHandler = assetTypeToDocumentHandler['bo_answer']
    return new DocumentHandler()
  }, [])
  const classes = useStyles()
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [questionsYear, setQuestionsYear] = React.useState(new Date().getFullYear().toString())
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [selectedQues, setSelectedQues] = React.useState<any>()
  const componentRef = React.createRef<any>()

  // apiCall is not included to these memo dependencies because in such case
  // whole component is rerendered infinitely.
  // Instead of that taxOrderId is added there, because apiCall function, returned by fetchTaxQuestions(taxOrderId: number),
  // will be changed only if taxOrderId changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoApiCall = React.useMemo(() => apiCall({ query: searchKeyword, year: questionsYear }), [
    questionsYear,
    searchKeyword,
    taxOrderId,
  ])
  const pagedTable = usePagedTable<IBOQues>({
    apiCall: memoApiCall,
  })
  const handleYearChange = React.useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) setQuestionsYear(date.year().toString())
    },
    [setQuestionsYear]
  )

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }
  const handleRefreshQuestions = () => {
    pagedTable.reloadData()
    setSelectedQues(null)
  }
  const handleEdit = question => {
    setSelectedQues(question)
    setIsDialogOpen(true)
  }
  const handleDelete = async question => {
    try {
      const payload = {
        taxorder_id: taxOrderId,
        id: question.id,
      }
      await deleteBOQuestion(payload)
      showSuccessNotification('Sucessfully updated')
      pagedTable.reloadData()
    } catch (error) {
      console.log(error)
    }
  }

  // const [addQuestionId, setAddQuestionId] = React.useState(0)

  return (
    <AssetsUploadCallBackContext.Provider value={documentHandler.UploadRequest}>
      <AssetsUploadCallBackContextAnswer.Provider value={documentHandlerAnswer.UploadRequest}>
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <SearchBar className={classes.searchBarContainer} onChange={searchText => setSearchKeyword(searchText)} />
            <BOToolbar
              reminderSequence={reminderSequence}
              pickedYear={questionsYear}
              onYearChange={handleYearChange}
              onAddQuestionClicked={() => handleOpenDialog()}
              refComp={componentRef}
            />
            <ComponentToPrint ref={componentRef}>
              {pagedTable.isLoading ? (
                <CircularProgress style={{ alignSelf: 'center', marginTop: '1rem' }} />
              ) : (
                <PagedTable
                  {...pagedTable.renderProps}
                  renderRow={(question: IBOQues) => (
                    <QuestionCard
                      starredValue={question?.is_stared}
                      onDeleteClick={handleDelete}
                      onEditClick={handleEdit}
                      question={question}
                    />
                  )}
                  colSpan={1}
                />
              )}
            </ComponentToPrint>
            <QuestionDialog
              refreshQuestions={handleRefreshQuestions}
              open={isDialogOpen}
              setOpen={setIsDialogOpen}
              taxOrderId={taxOrderId}
              question={selectedQues}
              setSelectedQues={setSelectedQues}
            />
          </div>
        </div>
      </AssetsUploadCallBackContextAnswer.Provider>
    </AssetsUploadCallBackContext.Provider>
  )
}

export default BOQuestions
