import { notify, toTitleCase } from '@jetkit/react'
import {
  Collapse,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Chip,
} from '@material-ui/core'
import { AccountBox } from '@material-ui/icons'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import Address from 'components/common/addresses/address'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import CopyTextFieldWrapper from 'components/common/textFields/copyTextFieldWrapper'
import AdditionalData from 'components/companies/companyOwners/ownerAdditionalData'
import { ICreatedCompany, IDetailedCompanyResponse } from 'models/company'
import { ICompanyOwner } from 'models/user'
import * as React from 'react'
import useRouter from 'use-react-router'
import { assertNonNullable } from 'utils/asserts'
import { COLORS, USERTYPES } from 'utils/constant'
import { formatDateToHumanFriendlyWithComa } from 'utils/formatDate'
import { formatPhoneNumberToInternational, formatSSN } from 'utils/formatNumbers'
import { emptyFunction } from 'utils/objectUtils'
import OwnersForm from './OwnersForm'
import ReactPhoneInput from 'react-phone-input-2'
import useUser from 'hooks/useUser'
import { useLocation } from 'react-router'

const useStyles = makeStyles(() =>
  createStyles({
    sharesBottomLine: {
      fontSize: 12,
      margin: 0,
    },
    actionsColumn: {
      textAlign: 'right',
      minWidth: 100,
    },
    actionsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    dropdownArrow: {
      marginLeft: '0.5rem',
      transition: 'all 0.3s ease-in-out',
    },
    hideableContent: {
      display: 'flex',
      padding: 14,
      paddingRight: 0,
      paddingTop: 0,
    },
    personalInfo: {
      marginLeft: 100,
    },
    addressDetails: {
      marginLeft: 200,
    },
    mainRoot: {
      margin: '20px 0px',
      padding: '0 0 0 14px',
    },
    textRoot: {},
    inputInner: { padding: '0 0 0 0px' },
  })
)

interface IOwnerRowProps {
  companyOwner: ICompanyOwner
  company?: any //ICreatedCompany | IDetailedCompanyResponse

  /**
   * handle edit owner button click
   */
  handleEditButtonClick(userId: number, userName: string): void

  onOwnerDelete?: (userId: number) => void
  isCompanyCreation?: boolean // whether it is company creation form
  isEditable?: boolean
}

const OwnerRow: React.FC<IOwnerRowProps> = ({
  companyOwner,
  company,
  handleEditButtonClick,
  onOwnerDelete,
  isCompanyCreation = false,
  isEditable = true,
}) => {
  const router = useRouter()
  const { user } = useUser()
  const { pathname } = useLocation()

  const classes = useStyles()
  const [detailsShown, setDetailsShown] = React.useState(false)
  const goToClientPage = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      router.history.push(`/client/${companyOwner.user.id}`)
    },
    [companyOwner.user.id, router.history]
  )

  const handleEditOwnerIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation()
      assertNonNullable(isEditable)
      assertNonNullable(companyOwner.user.id)
      handleEditButtonClick(companyOwner.user.id, companyOwner.user.name)
    },
    [isEditable, companyOwner.user, handleEditButtonClick]
  )

  const handleDeleteOwnerIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation()
      if (!companyOwner.user || !companyOwner.user.id || !onOwnerDelete)
        return notify.warning('Sorry, cannot delete this owner')
      return onOwnerDelete(companyOwner.user.id)
    },
    [companyOwner.user, onOwnerDelete]
  )
  const additionalInfo = React.useMemo(() => {
    if (
      !companyOwner.user.phone_number &&
      !companyOwner.user.email &&
      !companyOwner.user.dob &&
      !companyOwner.user.ssn_ein &&
      !companyOwner.user.addresses
    )
      return undefined

    const ssn = companyOwner.user.ssn_ein && formatSSN(companyOwner.user.ssn_ein.toString())
    const dateOfBirth = companyOwner.user.dob && formatDateToHumanFriendlyWithComa(companyOwner.user.dob)
    const phone = companyOwner.user.phone_number && formatPhoneNumberToInternational(companyOwner.user.phone_number)

    return (
      <TableRow>
        <TableCell colSpan={4} style={{ padding: 0 }}>
          <Collapse in={detailsShown}>
            {/* <div className={classes.hideableContent}>
              <div className={classes.personalInfo}>
                {phone && <AdditionalData title="Phone" data={phone} />}
                {companyOwner.user.email && <AdditionalData title="Email" data={companyOwner.user.email} />}
                {ssn && <AdditionalData title="SSN" data={ssn} />}
                {dateOfBirth && <AdditionalData title="Date of Birth" data={dateOfBirth} />}
              </div>
              {companyOwner.address ? (
                <Address
                  address={companyOwner.address}
                  onEditClick={emptyFunction}
                  isEditable={false}
                  isDeletable={false}
                  onlyOneAddress={true}
                />
              ) : null}
            </div> */}
            <div className={classes.mainRoot}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <div className={classes.textRoot}>
                    <div className={classes.inputInner}>
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <CopyTextFieldWrapper valueToCopy={companyOwner && companyOwner.user.name}>
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="Name"
                              defaultValue={companyOwner.user.name}
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                        <Grid item xs={2}>
                          <CopyTextFieldWrapper valueToCopy={companyOwner && companyOwner.number_of_shares?.toString()}>
                            <TextField
                              required
                              id="outlined-required"
                              label="Share"
                              defaultValue={companyOwner.number_of_shares}
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                        <Grid item xs={5}>
                          <CopyTextFieldWrapper valueToCopy={companyOwner && companyOwner.user.ssn_ein?.toString()}>
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="SSN"
                              defaultValue={companyOwner.user.ssn_ein}
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                      </Grid>
                    </div>

                    <div className={classes.inputInner}>
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <CopyTextFieldWrapper valueToCopy={companyOwner && companyOwner.user.phone_1}>
                            <ReactPhoneInput
                              data-testid="phone-input"
                              country="us"
                              inputClass="TextField"
                              inputStyle={{
                                width: '100%',
                                height: 55,
                                backgroundColor: '#fafafb',
                              }}
                              inputProps={{ required: true }}
                              dropdownStyle={{ width: 420, marginTop: 8 }}
                              value={companyOwner.user.phone_1}
                              countryCodeEditable={false}
                              disabled
                            />
                          </CopyTextFieldWrapper>
                          {/* <CopyTextFieldWrapper valueToCopy={companyOwner && companyOwner.user.phone_1}>
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="Phone"
                              defaultValue={companyOwner.user.phone_1}
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper> */}
                        </Grid>
                        {user?.user_type !== USERTYPES.accountant ? (
                          <Grid item xs={7}>
                            <CopyTextFieldWrapper valueToCopy={companyOwner && companyOwner.user.email_1}>
                              <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="Email"
                                defaultValue={companyOwner.user.email_1}
                                variant="outlined"
                                disabled
                              />
                            </CopyTextFieldWrapper>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </div>

                    <div className={classes.inputInner}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <CopyTextFieldWrapper
                            valueToCopy={
                              companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                ? companyOwner?.user?.addresses[0].street
                                : ''
                            }
                          >
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="Address"
                              defaultValue={
                                companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                  ? companyOwner?.user?.addresses[0].street
                                  : null
                              }
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.inputInner}>
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <CopyTextFieldWrapper
                            valueToCopy={
                              companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                ? companyOwner?.user?.addresses[0].locality
                                : ''
                            }
                          >
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="State"
                              defaultValue={
                                companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                  ? companyOwner?.user?.addresses[0].locality
                                  : null
                              }
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                        <Grid item xs={5}>
                          <CopyTextFieldWrapper
                            valueToCopy={
                              companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                ? companyOwner?.user?.addresses[0].city
                                : ''
                            }
                          >
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="City"
                              defaultValue={
                                companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                  ? companyOwner?.user?.addresses[0].city
                                  : null
                              }
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                        <Grid item xs={2}>
                          <CopyTextFieldWrapper
                            valueToCopy={
                              companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                ? companyOwner?.user?.addresses[0].postal_code
                                : ''
                            }
                          >
                            <TextField
                              fullWidth
                              required
                              id="outlined-required"
                              label="Zip"
                              defaultValue={
                                companyOwner.user.addresses?.length !== 0 && companyOwner.user.addresses !== undefined
                                  ? companyOwner?.user?.addresses[0].postal_code
                                  : null
                              }
                              variant="outlined"
                              disabled
                            />
                          </CopyTextFieldWrapper>
                        </Grid>
                      </Grid>
                    </div>

                    <div className={classes.inputInner}>
                      {companyOwner.roles?.length ? (
                        <Grid container spacing={3}>
                          <Grid item xs={12} style={{ paddingBottom: '0', marginBottom: '-0.5rem' }}>
                            <label>Roles</label>
                          </Grid>
                          {companyOwner.roles.map((item, index) => (
                            <Grid key={index} item>
                              <Chip label={item.title} />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    style={{ justifyContent: 'flex-end', marginLeft: '7%' }} //className={classes.actionRoot}
                  >
                    {!pathname.includes('tax-orders') ? (
                      <>
                        {!isCompanyCreation && (
                          <IconButton onClick={goToClientPage}>
                            <AccountBox />
                          </IconButton>
                        )}
                        <IconButton onClick={handleEditOwnerIconClick}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDeleteOwnerIconClick}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      ''
                    )}
                    <ArrowIcon
                      onClick={() => setDetailsShown(prev => !prev)}
                      className={classes.dropdownArrow}
                      style={{ transform: detailsShown ? 'rotate(180deg)' : undefined }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* <StyledTableCell className={classes.actionsColumn}>
              <div className={classes.actionsWrapper} style={{ justifyContent: 'flex-end' }}>
                {!isCompanyCreation && (
                  <IconButton onClick={goToClientPage}>
                    <AccountBox />
                  </IconButton>
                )}
                <IconButton onClick={handleEditOwnerIconClick}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleDeleteOwnerIconClick}>
                  <DeleteIcon />
                </IconButton>
                <ArrowIcon
                  onClick={() => setDetailsShown(prev => !prev)}
                  className={classes.dropdownArrow}
                  style={{ transform: detailsShown ? 'rotate(180deg)' : undefined }}
                />
              </div>
            </StyledTableCell> */}
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }, [
    companyOwner.user.phone_number,
    companyOwner.user.email,
    companyOwner.user.dob,
    companyOwner.user.ssn_ein,
    companyOwner.user.addresses,
    companyOwner.address,
    detailsShown,
    classes.hideableContent,
    classes.personalInfo,
  ])

  const cellStyle = React.useMemo(
    (): React.CSSProperties | undefined => (additionalInfo ? { borderBottom: '0px solid black' } : undefined),
    [additionalInfo]
  )
  const sharesDisplay = company => {
    if (company && company.number_of_shares && companyOwner.number_of_shares && company.legal_type !== 'LLC') {
      return (
        <div>
          <p style={{ margin: 0 }}>{((companyOwner.number_of_shares / company.number_of_shares) * 100).toFixed(2)}%</p>
          <p className={classes.sharesBottomLine}>({companyOwner.number_of_shares} shares)</p>
        </div>
      )
    } else if (company?.legal_type === 'LLC' || company?.company_type === 1) {
      return (
        <div>
          <p style={{ margin: 0 }}>
            {companyOwner.number_of_shares !== null ? companyOwner.number_of_shares.toFixed(2) : 0}%
          </p>
          {/* <p className={classes.sharesBottomLine}>({companyOwner.number_of_shares} shares)</p> */}
        </div>
      )
    } else return <p className={classes.sharesBottomLine}>{companyOwner.number_of_shares}</p>
  }
  return (
    <React.Fragment>
      {!detailsShown && (
        <StyledTableRow
          key={companyOwner.user.id}
          onClick={() => setDetailsShown(prev => !prev)}
          style={{ background: companyOwner.user.is_registered_agent ? COLORS.registeredAgent : undefined }}
        >
          <StyledTableCell style={cellStyle}>
            {companyOwner.user.name} <br />
            <p>
              {companyOwner.user.phone_1}
              <br /> {user?.user_type !== USERTYPES.accountant ? companyOwner.user.email_1 : ''}
            </p>
          </StyledTableCell>
          <StyledTableCell style={cellStyle}>{sharesDisplay(company)}</StyledTableCell>
          <StyledTableCell style={cellStyle}>
            <b>{companyOwner.is_primary_contact ? '(Primary Contact)' : ''}</b>
            {companyOwner.roles.map(role => (
              <p key={role.title} style={{ margin: '4px 0px' }}>
                {toTitleCase(role.title)}
              </p>
            ))}
          </StyledTableCell>
          <StyledTableCell className={classes.actionsColumn} style={cellStyle}>
            {isEditable && (
              <div className={classes.actionsWrapper} style={{ justifyContent: 'flex-end' }}>
                {!pathname.includes('tax-orders') ? (
                  <>
                    {!isCompanyCreation && (
                      <IconButton onClick={goToClientPage}>
                        <AccountBox />
                      </IconButton>
                    )}
                    <IconButton onClick={handleEditOwnerIconClick}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={handleDeleteOwnerIconClick}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : (
                  ''
                )}
                {additionalInfo && (
                  <ArrowIcon
                    className={classes.dropdownArrow}
                    style={{ transform: detailsShown ? 'rotate(180deg)' : undefined }}
                  />
                )}
              </div>
            )}
          </StyledTableCell>
        </StyledTableRow>
      )}
      {additionalInfo}
    </React.Fragment>
  )
}

export default OwnerRow
