import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      '& .text-area': {
        resize: 'none',
        background: 'inherit',
        border: 'none',
        outline: 'none',
        width: '100%',

        '&::placeholder': {
          color: '#979797',
          fontSize: '2rem,',
        },
      },
      '& .text-box': {
        background: '#f6f8fb',
        minHeight: '200px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',

        '& .bottom-space': {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
      },
      '& .save-btn': {
        '& .MuiButtonBase-root': {
          background: '#3c9fdf',
          color: '#fff',
          minWidth: '100px',
          height: 'auto',

          '& .Mui-disabled': {
            background: '#788190',
            color: '#ffffffc7 !important',
          },
        },
        background: '#3c9fdf',
        color: '#fff',
        width: '100px',
        height: 'auto',
        '& .Mui-disabled': {
          background: '#788190',
          color: '#ffffffc7 !important',
        },
      },

      '& .note-chatbox-section': {
        padding: '0.85rem 1.25rem',
        background: '#F0F0F0',
        borderRadius: '10px 0px 10px 10px',
        fontFamily: 'Lato',
        width: 'calc(100% - 2.5rem)',
        margin: '1rem 0',

        '& .note-text': {
          fontSize: '0.9rem',
          fontWeight: 500,
          lineHeight: '1.5rem',
          color: '#252C32',
        },
        '& .inline-text': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',

          '& .title-text': {
            fontSize: '0.9rem',
            fontWeight: 700,
            lineHeight: '1.5rem',
            color: '#000000',
          },
          '& .date-text': {
            margin: '0 0.5rem',
            color: '#818181',
            fontSize: '0.85rem',
          },
        },
      },

      '& .chatbox-container': {
        display: 'flex',
        margin: '1rem 0',

        '& .email-chatbox-section': {
          padding: '0.85rem 1.25rem',
          background: '#EAF4FB',
          borderRadius: '10px 0px 10px 10px',
          fontFamily: 'Lato',
          cursor: 'pointer',
          maxWidth: '60%',
        },

        '& .chatbox-section': {
          padding: '0.85rem 1.25rem',
          background: '#F0F0F0',
          borderRadius: '0px 10px 10px 10px',
          fontFamily: 'Lato',
          cursor: 'pointer',
          maxWidth: '60%',
        },
        '& .title': {
          fontSize: '0.9rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
        },
        '& .text': {
          fontSize: '0.9rem',
          fontWeight: 500,
          lineHeight: '1.5rem',
        },
        '& .inline-icon': {
          display: 'flex',
          alignItems: 'center',
          marginTop: '0.5rem',

          '& p': {
            margin: '0 0.5rem',
            color: '#818181',
            fontSize: '0.85rem',
          },

          '& .icon-wrapper': {
            position: 'relative',

            '& .done-progress': {
              position: 'absolute',
              top: '-4px',
              left: '-4px',
              color: '#0000005c',
            },
          },
        },
      },
    },
    gridRow: {
      marginBottom: '15px',

      '& .MuiTabs-root': {
        borderBottom: '2px solid #80808045',
      },
      '& .MuiTabPanel-root': {
        padding: 0,
      },
    },
    heading: {
      fontSize: '32px',
      fontFamily: 'Lato',
      marginTop: '0px',
    },
    divider: {
      background: '#788190',
      height: '2px',
      marginTop: '1.25rem',
    },
    saveBtn: {
      padding: '11px 85px 10px 86px',
    },
    btnRow: {
      textAlign: 'right',
      marginTop: '30px',
    },
    textBox: {
      background: '#f6f8fb',
      minHeight: '140px',
      padding: '1rem',

      '& .address-box': {
        margin: '0',
        padding: '0',

        '& .MuiTypography-root': {
          color: '##979797',
          fontSize: '14px',
          fontFamily: 'Roboto',
        },

        '& .MuiPaper-root': {
          minHeight: 'auto',
          padding: 0,
          margin: 0,
          background: 'transparent',
          boxShadow: 'none',

          '& div': {
            padding: 0,
            margin: 0,
          },
        },
      },

      '& .bottom-space': {
        '&:not(:last-child)': {
          marginBottom: '1rem',
        },
      },
    },
    headingDiv: { display: 'flex', justifyContent: 'space-between' },
    table: {
      background: '#fff',

      '& .MuiTable-root': {
        background: '#fff',
      },

      '& .MuiTableHead-root': {
        background: '#f1f3f4',
        // borderTop: '10px solid #f47950',

        '& .MuiTableCell-root': {
          padding: '0.5rem',
          paddingLeft: '1rem',
        },

        '& .MuiTableCell-head': {
          color: '#22222299',
          fontWeight: 500,
          fontSize: '14px',
        },
      },

      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          padding: '0.5rem',
          paddingLeft: '1rem',
          fontWeight: 500,
          color: '#222222',
        },
      },
      '& .inline-cell': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '38px',
      },
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    msgField: {
      width: 'calc(100% - 3.8rem)',
      padding: '1rem 2rem',
      background: '#EAEAEA',
      borderRadius: '50px',

      '& .MuiInput-underline': {
        '&:before': {
          borderBottom: '0px !important',
        },
      },
      '& button': {
        color: '#fff',
        borderRadius: '50px',
        width: '108px',
      },
    },
    tabSec: {
      position: 'relative',
      left: '-2px',
      display: 'flex',

      '& .noti-dots': {
        color: '#fff',
        width: '14px',
        height: '14px',
        padding: '0.25rem',
        fontSize: '0.7rem',
        background: '#F76659',
        lineHeight: '14px',
        marginLeft: '8px',
        borderRadius: '100px',
        textAlign: 'center',
      },
    },
    pauseBtn: {
      '& button': {
        height: '34px',
        marginBottom: '2px',
        // marginTop: '5rem',
      },
    },
    autoField: {
      '& .MuiInputBase-root': {
        background: '#F6F6F8',
        padding: '0.25rem',

        '&:before': {
          border: 'none',
        },
      },
    },
  })
)
