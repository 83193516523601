import { handleError } from '@jetkit/react'
import { debounce } from 'lodash'
import * as React from 'react'

interface IUseAPICallbackParams {
  debounceDelay?: number
  onError?: Function
  rethrowError?: boolean
}

/**
 * This is our wrapper for useCallback it will provide common "utilities" used / needed when we call API
 * @param cb - The actual callback that might throw error
 * @param deps - Dependencies for that callback
 * @param options - Custom options (debounce,  onError callback, rethrowing errors etc
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAPICallback<T extends (...args: any[]) => any>(
  cb: T,
  deps: React.DependencyList,
  options?: IUseAPICallbackParams
) {
  const { debounceDelay, onError, rethrowError } = options ?? {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callback = async (...args: any[]) => {
    try {
      return await cb(...args)
    } catch (err) {
      handleError(err)

      onError?.()

      if (rethrowError) {
        throw err
      }
    }
  }

  // eslint-disable-next-line
  return React.useCallback(debounceDelay ? debounce(callback, debounceDelay) : callback, deps)
}
