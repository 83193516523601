import { apiClient, requestPaginated } from '@jetkit/react'
import { IDashboardRow } from 'components/tasks/dashboardRow'
import { ITaskNotify, IOrderTask, ITask, ITaskBase, TaskCompletion } from 'models/task'
import moment from 'moment'

export interface ITaskDescription {
  created_at: string
  created_by: string
  id: number
  note_text: string
  task_id: number
}

export type ITaskPriority = 'low' | 'medium' | 'high'

export const editTask = async (task: ITaskBase): Promise<any> => {
  return (await apiClient.post(`/task/${task.id}`, task)).data
}
export const createTask = async (task: ITaskBase): Promise<ITask> => {
  return (await apiClient.post('/task', task)).data
}

export const fetchTasks = () => {
  return requestPaginated<ITask[]>({ url: '/task' })
}
export const fetchTasksAndOrders = requestPaginated<IDashboardRow[]>({ url: '/dashboard' })

export const getTasksProgress = async (): Promise<number> => {
  return (await apiClient.get(`/task/progress`)).data //.progress
}

export const deleteTask = async (taskId: number): Promise<void> => {
  await apiClient.delete(`/tasks/${taskId}`)
}

export const markTaskAsDone = async (taskId: number): Promise<void> => {
  await apiClient.put(`/task/${taskId}/complete`)
}

export const markTasksAsDone = async (taskIds: TaskCompletion[]): Promise<void> => {
  await apiClient.put(`/task/complete`, taskIds)
}

export const markTaskAsNotDone = async (taskId: number): Promise<void> => {
  await apiClient.put(`/task/${taskId}/not-complete`)
}

export const markTasksAsNotDone = async (taskIds: TaskCompletion[]): Promise<void> => {
  await apiClient.put(`/task/not-complete`, taskIds)
}

export const updateTask = async (taskId: number, orderTask: Partial<IOrderTask>): Promise<any> => {
  return (await apiClient.post(`task/${taskId}`, orderTask)).data
}
export const updateTasks = async (orderTasks: Partial<IOrderTask>[]): Promise<IOrderTask[]> =>
  (await apiClient.patch(`task`, orderTasks)).data

export const toggleTaskActivation = async (companyOrderTaskId: number): Promise<void> => {
  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL //'http://localhost:3000/'
  return
  //return await apiClient.patch(`task/${companyOrderTaskId}/toggle-activation`)
}

export const getTaskNotify = async (): Promise<ITaskNotify[]> => {
  return (await apiClient.get(`/task-notification`)).data
}

export const getTaxOrderTasks = async (tax_order_id: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000'
  return (await apiClient.get(`/taxorder-tasks/${tax_order_id}`)).data //.progress
}

export const getTaskDescriptions = async (taskId: number): Promise<ITaskDescription[]> => {
  return (await apiClient.get(`/task-notes/${taskId}`)).data
}
