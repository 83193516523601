import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/styles'

import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { ISpecialOffers, ISalesRep } from 'models/user'
import * as React from 'react'
// import MoreOptionsMenu from '../moreOptionsMenu'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IClientTableRowProps {
  offers: ISpecialOffers
}

const ClientTableRow: React.FC<IClientTableRowProps> = ({ offers }) => {
  const classes = useStyles()

  return (
    <>
      {/* <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialstates={offers} /> */}
      <StyledTableRow data-testid={`${offers.id}`} className={classes.row}>
        <StyledTableCell>{offers.id}</StyledTableCell>
        <StyledTableCell>{offers.title}</StyledTableCell>
        <StyledTableCell>{offers.link}</StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default ClientTableRow
