import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
    btn: {
      height: '3rem',
      color: 'white',
      width: '8rem',
    },
  })
)

export default function UploadButton({ onChange, text, loading = false, disabled = false }) {
  const classes = useStyles()

  return (
    <div className={''}>
      <input accept="pdf/*" onChange={onChange} className={classes.input} id="uploaded-file" multiple type="file" />
      <label htmlFor="uploaded-file">
        <Button className={classes.btn} variant="contained" color="secondary" component="span" disabled={disabled}>
          {text}
        </Button>
        {loading && <LinearProgress style={{ width: '100%' }} />}
      </label>
    </div>
  )
}
