import { Chip, Grid, Tooltip } from '@material-ui/core'
import { ArrowRightAlt, Error, Info, LocalMall } from '@material-ui/icons'
import React from 'react'
import { useStyles } from './style'
import { useHistory } from 'react-router'
import showErrorNotification from 'utils/showErrorNotification'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import BusinessIcon from './img/company-icon.png'
import { CLIENT_COMPANY_STATUS } from 'utils/constant'
import { formatDateToHumanFriendly } from 'utils/formatDate'

interface NewCompanyCard {
  name: string
  city: string
  date: string
  smallCard?: boolean
  companyId?: number
  file_date: string
  profile_score?: number
  business_active?: boolean
  business_active_status?: string
  expected_ein_date?: string
  expected_file_date?: string
}

const NewCompanyCard = ({
  profile_score,
  name,
  city,
  date,
  smallCard,
  companyId,
  file_date,
  business_active,
  business_active_status,
  expected_ein_date,
  expected_file_date,
}: NewCompanyCard) => {
  const classes = useStyles()
  const history = useHistory()

  const companyDetails = () => {
    history.push(`/client-dashboard/${companyId}`)
    // if (file_date) history.push(`/client-dashboard/${companyId}`)
    // else showErrorNotification('Company can not be accessed in Pending State')
  }
  const checkProfileScore = score => {
    if (score >= 0 && score <= 30) {
      return 'red'
    } else if (score > 30 && score <= 70) {
      return 'orange'
    } else if (score > 70 && score <= 100) {
      return 'green'
    } else {
      return 'red'
    }
  }

  return (
    <div className={classes.newCompanyCard}>
      <div className="newCompanyCard-section">
        <div className="icon-sec">
          <img src={BusinessIcon} />
          <div className="progress-sec">
            <CircularProgressbar
              value={profile_score || 30}
              strokeWidth={6}
              styles={buildStyles({
                strokeLinecap: 'butt',
                trailColor: '#eee',
                pathColor: checkProfileScore(profile_score || 30),
              })}
            />
            {profile_score !== 100 ? (
              <Chip
                style={{ background: checkProfileScore(profile_score || 30), color: '#fff' }}
                label={`${profile_score || 30}%`}
                size="small"
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="active-sec">
          {/* <Chip label={business_active ? 'Active' : 'In Active'} /> */}
          <Chip
            // style={{ backgroundColor: business_active_status === 'Under Review' ? '#888888' : '#04a464' }}
            style={{ backgroundColor: CLIENT_COMPANY_STATUS[business_active_status || ''] }}
            label={business_active_status}
          />

          {/* <p className="date-text">{date !== 'Pending' ? date : ''}</p> */}

          <div style={{ marginTop: '0.5rem', width: '70%' }}>
            {expected_file_date ? (
              <p className="date-text">
                <small>
                  <b>Expected File Date:</b>
                </small>{' '}
                {expected_file_date ? formatDateToHumanFriendly(expected_file_date) : ''}
              </p>
            ) : (
              ''
            )}

            {expected_ein_date ? (
              <p className="date-text">
                <small>
                  <b>Expected EIN Date:</b>
                </small>{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${expected_ein_date}`,
                  }}
                />
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div>
        <h1 className="company-text">{name}</h1>
        <p className="state-text">{city}</p>

        {/* <div className="error-icon">
          {profile_score !== 100 ? (
            <>
              <Error fontSize="large" htmlColor="#ffc400" /> <p>Attention Required</p>
            </>
          ) : (
            ''
          )}
        </div> */}
        <span className="detail-text" onClick={companyDetails}>
          See Company Details <ArrowRightAlt />
        </span>
      </div>
    </div>
  )
}

export default NewCompanyCard
