import * as React from 'react'
import { IFormFieldProps } from 'utils/FormGenerator/formTypings'
import { IAddress } from 'models/address'
import { AddressSelect, ChangeAction } from 'components/companies/companyOwners/AddressSelect'
import { isAddressField } from 'utils/FormGenerator/typeAssertionFunction'
import { SetFieldValueContext } from 'utils/FormGenerator'
import { Typography, Box } from '@material-ui/core'
import { useStyles } from 'utils/FormGenerator/styles'
import { editAddress, createAddress } from 'api/addresses'
import showErrorNotification from 'utils/showErrorNotification'
import { error } from 'utils/logging'

const AddressFieldComponent = ({ field, value }: IFormFieldProps<IAddress>) => {
  if (!isAddressField(field)) {
    throw new Error('type error')
  }

  const setFieldValue = React.useContext(SetFieldValueContext)
  const classes = useStyles()

  const onAddressChanged = React.useCallback(
    (address: IAddress | null) => {
      //
      if (field.name === 'primary_address' && address) {
        const newAddress = { ...address, is_primary: true, address_type: 'contact' }
        setFieldValue(field.name, newAddress)
      } else if (field.name === 'mailing_address' && address) {
        const newAddress = { ...address, is_mailing: true, address_type: 'mailing' }
        setFieldValue(field.name, newAddress)
      } else if (field.name === 'director_meeting.address' && address) {
        const newAddress = { ...address, address_type: 'directors_meeting' }
        setFieldValue(field.name, newAddress)
      } else if (field.name === 'shareholder_meeting.address' && address) {
        const newAddress = { ...address, address_type: 'shareholders_meeting' }
        setFieldValue(field.name, newAddress)
      } else if (!address?.id) {
        //
        setFieldValue(field.name, address)
      }
    },
    [field.name, setFieldValue]
  )

  const onCreateUpdate = React.useCallback(
    async (address: IAddress, action: ChangeAction) => {
      //

      try {
        switch (action) {
          case ChangeAction.update: {
            await editAddress({ ...address, address_type: field.addressType, company_id: field.entity_id })
            break
          }
          case ChangeAction.create: {
            await createAddress({ ...address, address_type: field.addressType, company_id: field.entity_id })
            break
          }
        }
        setFieldValue(field.name, address)
      } catch (err) {
        showErrorNotification('Failed to update address')
        error({ message: `Failed to update address for ${field.name} field`, extra: { error: err } })
      }
    },
    [field.addressType, field.entity_id, field.name, setFieldValue]
  )

  return (
    <Box className={classes.customFieldContainer}>
      <Typography classes={{ root: classes.fieldLabel }}>{field.label}</Typography>
      <AddressSelect
        initialValue={value}
        onCreateUpdate={onCreateUpdate}
        onSelect={onAddressChanged}
        options={field.options}
      />
    </Box>
  )
}

export default AddressFieldComponent
