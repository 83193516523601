import TableBody from '@material-ui/core/TableBody'
import { EmptyTableRow, skeletons, StyledTable } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { IClient } from 'models/user'
import * as React from 'react'
import YearsTableHead from './head'
import YearsTableRow from './row'
import { getComingYearsComplianceSchedules } from 'api/complianceCalendar'
import showErrorNotification from 'utils/showErrorNotification'

export interface IYearsSchedule {
  schedule_name: string
  years_schedule: string[]
  muted?: boolean
  notification_schedule_id?: number
}

const YearsTable: React.FC<{ company_id: number }> = ({ company_id }) => {
  const [yearsData, setYearsData] = React.useState<IYearsSchedule[]>()
  const [loading, setIsLoading] = React.useState(false)

  const [isupdate, setIsupdate] = React.useState(false)

  React.useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true)
        const res: IYearsSchedule[] = await getComingYearsComplianceSchedules(company_id)
        setYearsData(res)
        setIsLoading(false)
      } catch (error) {
        showErrorNotification('Failed to Get Schedules')
        setIsLoading(false)
      }
    }
    getData()
  }, [isupdate])
  const fetchedResult = (data: IYearsSchedule[]) => {
    return data.length ? (
      data.map((item, index) => <YearsTableRow key={index} data={item} setIsupdate={setIsupdate} />)
    ) : (
      <EmptyTableRow columns={7} title="No data" />
    )
  }
  return (
    <React.Fragment>
      <h1>Coming Years Schedule</h1>
      <TableLoadingIndicator isLoading={loading} />
      <StyledTable>
        <YearsTableHead />
        <TableBody>
          {yearsData
            ? fetchedResult(yearsData)
            : skeletons({ numberOfColumns: 7, numberOfRows: 5, sizes: [140, 130, 140, 170, 100, 100, 170] })}
        </TableBody>
      </StyledTable>
    </React.Fragment>
  )
}

export default YearsTable
