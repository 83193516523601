import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { BusinessCenter, MoreVert } from '@material-ui/icons'
import { createStyles, makeStyles, withStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import useRouter from 'use-react-router'

import EditIcon from 'components/common/icons/edit'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import CompanyCreationFlow from 'components/companies/create'
import { IEmployee } from 'models/user'
import * as React from 'react'
import { formatPhoneNumberToInternational, formatSSN } from 'utils/formatNumbers'
import MoreOptionsMenu from '../moreOptionsMenu'
import DescriptionIcon from '@material-ui/icons/Description'
import UserIcon from '@material-ui/icons/Person'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { updateEmployeeStatus } from 'api/employees'
import IOSSwitch from 'components/common/iosSwitch'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import { USERTYPES, USER_TYPES } from 'utils/constant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IClientTableRowProps {
  client: IEmployee
  onEditClick: () => void
  onDeleteClick: () => void
}

const ClientTableRow: React.FC<IClientTableRowProps> = ({ client, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)
  const [active, setActive] = React.useState<boolean>(client?.is_active || false)
  const { history } = useRouter()

  const toggleStatus = React.useCallback(
    async (id, val) => {
      try {
        const res = await updateEmployeeStatus(id, { is_active: val })
        res.success && showSuccessNotification('Updated Successfully')
      } catch (e) {
        showApiResponseError(e, 'Error Updating status')
      }
    },
    [active]
  )
  return (
    <>
      {/* <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialClient={client} /> */}
      <StyledTableRow onClick={onEditClick} data-testid={`${client.id}`} className={classes.row}>
        <StyledTableCell>{client.first_name}</StyledTableCell>
        <StyledTableCell>{client.last_name || '- - -'}</StyledTableCell>
        <StyledTableCell>{formatPhoneNumberToInternational(client.phone ? client.phone : '')}</StyledTableCell>
        <StyledTableCell>{client.email}</StyledTableCell>
        <StyledTableCell>{client.employee_type ? USER_TYPES[client.employee_type] : ''}</StyledTableCell>

        <StyledTableCell>{active ? 'Active' : 'In Active'}</StyledTableCell>
        <StyledTableCell>
          {client?.creation_time ? formatDateTimeToHumanFriendly(client?.creation_time) : ''}
        </StyledTableCell>
        <StyledTableCell>
          <span ref={moreOptionsRef}>
            {/* <TableActionButton
              className={classes.button}
              onClick={event => {
                event.stopPropagation()
                setIsMoreOptionsOpen(!isMoreOptionsOpen)
              }}
            >
              <Tooltip title="Show More" ref={moreOptionsRef}>
                <MoreVert />
              </Tooltip>
              <MoreOptionsMenu
                isOpen={isMoreOptionsOpen}
                refElement={moreOptionsRef}
                setIsOpen={setIsMoreOptionsOpen}
                client={client}
              />
            </TableActionButton> */}
          </span>
          {/* <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              onEditClick()
            }}
            style={{width:'23px', height:'24px'}}
          >
            <EditIcon tooltipText="Edit" />{' '}
          </TableActionButton>
          */}

          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              onDeleteClick()
            }}
            style={{ width: '35px', height: '35px' }}
          >
            <DeleteIcon tooltipText="Delete" />
          </TableActionButton>
          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              //onDeleteClick()
            }}
            style={{ width: '35px', height: '35px' }}
          >
            <DescriptionIcon onClick={() => history.push(`/employee-report/${client.id}`)} />
            {/* <ToggleButton
              value="check"
              selected={true}
              onChange={() => {
                // setSelected(!selected);
              }}
            >
              <UserIcon />
            </ToggleButton> */}
          </TableActionButton>
          {/* <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              //onDeleteClick()
            }}
            style={{ width: '35px', height: '35px' }}
          > */}
          <IOSSwitch
            checked={active}
            onClick={event => {
              event.stopPropagation()
            }}
            onChange={e => {
              setActive(!active)
              toggleStatus(client.id, e.target.checked)
            }}
            name="checkedB"
          />
          {/* </TableActionButton> */}
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default ClientTableRow
