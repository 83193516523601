import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import { fetchSuggestions, getRejectedReason, getTaxAnswerEmail, getTaxQuestionEmail } from 'api/taxOrders'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { USERTYPES } from 'utils/constant'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useParams } from 'react-router'
import SelectField from 'components/common/select'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notifyButton: {
      display: 'flex',
      justifyContent: 'flex-end',

      '& button': {
        minWidth: '140px',
      },
    },
    questionTextField: {
      width: '100%',
      marginBottom: '1rem',
    },
  })
)

const FeedbackModal = ({ open, setOpen, onDone }) => {
  const classes = useStyles()
  const [feedback, setFeedback] = useState<string | null>()
  const { user } = useUser()
  const { id } = useParams<any>()
  const handleDoneClick = () => {
    setFeedback('')
    onDone(feedback)
    setOpen(false)
  }
  // const [companyId, setCompanyId] = React.useState<number>(0)
  const addAttribute = React.useCallback((field: 'feedback_id', id: number | null, label: string | null) => {
    // console.log(field, id, label, 'FILED ISHEREEEEEEEEEEEEEEE')
    if (field === 'feedback_id') {
      if (id) {
        // setCompanyId(id)
        setFeedback(label)
      }
    }
  }, [])
  // const getPredefinedFeedback = async () => {
  //   try {
  //     const result = await getRejectedReason()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => {
  //   getPredefinedFeedback()
  // }, [])
  return (
    <ModalWrapper open={open} setOpen={setOpen} heading={`Feedback`}>
      <SelectField
        onOptionSelected={option =>
          addAttribute('feedback_id', option ? option.value : null, option ? option.label : null)
        }
        placeholder="Select Feedback"
        title="feedback"
        fetchSuggestions={fetchSuggestions}
        field="feedback_id"
        // setFieldValue={setCompanyId}
      />
      <TextField
        // disabled={loading}
        // label="Feedback"
        className={classes.questionTextField}
        type="text"
        name="title"
        variant="outlined"
        multiline
        placeholder="Write Feedback"
        rows={3}
        onChange={e => setFeedback(e.target.value)}
        value={feedback}

        // defaultValue={question && question.question_text}
      />
      <div className={classes.notifyButton}>
        <ButtonWithLoading onClick={handleDoneClick} wrapperClassName="question-btn" kind="BRSecondary">
          Done
        </ButtonWithLoading>
      </div>
    </ModalWrapper>
  )
}

export default FeedbackModal
