import { createStyles, makeStyles, Table, TableBody, Typography } from '@material-ui/core'
import SearchBar from 'components/common/searchBar'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { paginateStates } from 'api/stateprocessing'
import React from 'react'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { IStates } from 'models/user'
import { theme } from 'theme'
import StatesTableHead from './stateHead'
import StateTableRow from './stateRow'
import { skeletons } from 'components/common/table'
import { updatePageVisitsCount } from 'utils/user'

const StateFormation = () => {
  const classes = useStyles()
  const [query, setQuery] = React.useState('')

  const apiCall = React.useMemo(() => paginateStates()({ query }), [query])
  const pagedTable = usePagedTable<IStates>({ apiCall })

  return (
    <div className={classes.container}>
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>State Processing &amp; Fee</Typography>
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar className={classes.searchBar} onChange={setQuery} placeholder="Search by State's Name" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            <StatesTableHead />
            <TableBody>{skeletons({ numberOfColumns: 7, numberOfRows: 10 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Processing fee"
            {...pagedTable.renderProps}
            renderRow={(states: IStates) => <StateTableRow states={states} />}
            header={<StatesTableHead />}
            colSpan={7}
          />
        )}
      </div>
    </div>
  )
}

export default StateFormation

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '2rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 600,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    searchBarContainer: {
      margin: '0.5rem 0',
    },
    tableContainer: {
      margin: '0.5rem 0',
    },
    searchBar: {
      marginBottom: '1rem',
    },
  })
)
