import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import { emptyNote, INewNote, NoteType } from 'models/note'
import * as React from 'react'
export interface APINoteType {
  id?: number
  color?: string
  create?: boolean
  search?: boolean
}

interface IUseNotes {
  defaultSearchOptions?: NoteEntities
  defaultType?: NoteType
}

const emptyNoteWithType = (defaultType?: NoteType): INewNote => ({
  ...emptyNote,
  note_type: defaultType,
})

const useNotes = ({ defaultSearchOptions, defaultType }: IUseNotes) => {
  const [searchOptions, setSearchOptions] = React.useState<NoteEntities>(defaultSearchOptions || {})

  const [newNote, setNewNote] = React.useState<INewNote>(emptyNoteWithType(defaultType))

  const updateNewNote = React.useCallback(
    (field: string, value: string | NoteType | number) => {
      setNewNote(prevState => ({ ...prevState, [field]: value }))
    },
    [setNewNote]
  )

  return {
    searchOptions,
    setSearchOptions,
    newNote,
    setNewNote,
    updateNewNote,
  }
}

export default useNotes
