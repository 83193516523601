import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles } from '@material-ui/styles'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { IStates } from 'models/user'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IStateTableRowProps {
  states: IStates
}

const StateTableRow: React.FC<IStateTableRowProps> = ({ states }) => {
  const classes = useStyles()

  return (
    <>
      <StyledTableRow data-testid={`${states.id}`} className={classes.row}>
        <StyledTableCell>{states.state_name}</StyledTableCell>
        <StyledTableCell>{states.company_type_name}</StyledTableCell>
        <StyledTableCell>{states.processing_time}</StyledTableCell>
        <StyledTableCell>{states.rush_processing_time}</StyledTableCell>
        <StyledTableCell>{states.state_fee}</StyledTableCell>
        <StyledTableCell>{states.annual_state_fee}</StyledTableCell>
        <StyledTableCell>
          {states?.average_processing_time && states?.average_processing_time > 0
            ? `${states.average_processing_time} day(s)`
            : ''}{' '}
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default StateTableRow
