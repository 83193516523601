import AssetUpload from 'components/common/assetUpload'
import CheckBox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import ModalTitle from 'components/common/modalTitle'
import EmptyCheckbox from 'icons/emptyCheckbox'
import FilledCheckbox from 'icons/filledCheckbox'
import * as React from 'react'
import { AssetsUploadCallBackContext } from '..'
import { downloadQuestionsURL } from 'api/taxOrders'
import DownloadButton from 'components/common/assetUpload/downloadButton'
import { GetAppOutlined } from '@material-ui/icons'
import { UploadFileToS3Args } from 'models/asset'
import { Skeleton } from '@material-ui/lab'
import showSuccessNotification from 'utils/showSuccessNotification'

const useStyles = makeStyles(() => {
  return {
    container: {
      paddingTop: '1.5rem',
      paddingBottom: '1rem',
    },
    questionTextField: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '1.5rem',
    },
    answerTextField: {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '0.6rem',
    },
    saveButtonWrapper: {
      marginTop: '1rem',
      width: '13.7rem',
      alignSelf: 'center',
    },
    answerQuestionCheckbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
    },
    imageFile: {
      display: 'flex',
      alignItems: 'center',
      width: '75%',
      '& p': {
        paddingLeft: '0.5rem',
        margin: 0,
        fontSize: 12,
      },
    },
    uploadQuestion: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem',
    },
    imageList: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: '0.5rem',
      },
    },
    rounded: {
      color: '#fff',
      backgroundColor: '#ff8b8b',
      fontSize: 14,
    },
    downloadButton: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    downloadButtonLink: {
      textDecoration: 'none',
    },
    attachmentList: {
      padding: '2rem',
      paddingTop: 0,
    },
  }
})

interface IAddQuestionDialog {
  dialogType: QuestionDialogType
  shown: boolean
  onClose: () => void
  newQuestionText: string
  editedQuestionText: string
  editedAnswerText: string
  onNewQuestionTextChange: (text: string) => void
  onEditQuestionTextChange: (text: string) => void
  onEditAnswerTextChange: (text: string) => void
  loading: boolean
  onSaveClick: (dialogType: QuestionDialogType) => void
  unansweredQuestion: boolean
  onMarkUnansweredClick(event: React.ChangeEvent<HTMLInputElement>): void
  questionId?: number
  addQuestionId?: number
  isQuestionAdd?: boolean
  setEditedQuestionId?: any
  setAddQuestionId?: any
}

export type QuestionDialogType = 'add' | 'edit'

const QuestionDialog: React.FC<IAddQuestionDialog> = ({
  dialogType,
  shown,
  onClose,
  newQuestionText,
  editedQuestionText,
  editedAnswerText,
  onNewQuestionTextChange,
  onEditQuestionTextChange,
  onEditAnswerTextChange,
  loading,
  onSaveClick,
  unansweredQuestion,
  onMarkUnansweredClick,
  questionId,
  isQuestionAdd,
  addQuestionId,
  setEditedQuestionId,
  setAddQuestionId,
}) => {
  const UploadRequest = React.useContext(AssetsUploadCallBackContext)

  console.log('addQuestionId', addQuestionId)
  console.log('questionId', questionId)

  const classes = useStyles()
  const isSaveButtonDisabled = React.useMemo((): boolean => {
    if (dialogType === 'add') return newQuestionText === ''
    return editedQuestionText === ''
  }, [dialogType, newQuestionText, editedQuestionText])

  const [attachments, setAttachments] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const getQuestionAttachment = async id => {
    setIsLoading(true)
    try {
      const res: any = await downloadQuestionsURL(id)
      setAttachments(res)
      setIsLoading(false)
      console.log(res)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (questionId) {
      getQuestionAttachment(questionId)
    }
  }, [questionId, onClose])

  const getFileExtension = filename => {
    const extension = filename.split('.').pop()
    return extension
  }

  const attachmentUploadComplete = (args: UploadFileToS3Args) => {
    if (args?.objectId) {
      getQuestionAttachment(args?.objectId)
      showSuccessNotification('File Upload Successfully')
      setEditedQuestionId(args?.objectId)
      setAddQuestionId(args?.objectId)
      setAttachments([])
    }
  }

  const handleClose = () => {
    setAttachments([])
    setEditedQuestionId(0)
    setAddQuestionId(0)
    onClose()
  }

  return (
    <Dialog
      PaperProps={{ className: classes.container }}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      open={shown}
    >
      <ModalTitle title={dialogType === 'add' ? 'Add A Question' : 'Answer A Question'} onClose={handleClose} />
      <TextField
        disabled={loading}
        label="Ask Your Question Here..."
        className={classes.questionTextField}
        type="text"
        name="title"
        variant="outlined"
        multiline
        rows={dialogType === 'add' ? 3 : undefined}
        value={dialogType === 'add' ? newQuestionText : editedQuestionText}
        onChange={event =>
          dialogType === 'add'
            ? onNewQuestionTextChange(event.target.value)
            : onEditQuestionTextChange(event.target.value)
        }
      />
      {dialogType === 'add' ? null : (
        <TextField
          disabled={loading}
          label="Answer"
          className={classes.answerTextField}
          type="text"
          name="title"
          variant="outlined"
          multiline
          rows={3}
          value={editedAnswerText}
          onChange={event => onEditAnswerTextChange(event.target.value)}
        />
      )}
      <div className={classes.uploadQuestion}>
        {dialogType === 'edit' && (
          <FormControlLabel
            className={classes.answerQuestionCheckbox}
            control={
              <CheckBox
                disableRipple
                onChange={onMarkUnansweredClick}
                checked={unansweredQuestion}
                icon={<EmptyCheckbox />}
                checkedIcon={<FilledCheckbox />}
              />
            }
            label={<Typography>Mark Question Unanswered</Typography>}
          />
        )}
        {addQuestionId || questionId ? (
          <AssetUpload
            type="questions"
            objectId={questionId || addQuestionId || 0}
            UploadRequest={UploadRequest}
            setLoading={setIsLoading}
            onUploadComplete={attachmentUploadComplete}
          />
        ) : (
          ''
        )}
        {/* <AssetUpload
            type="questions"
            objectId={questionId || 0}
            UploadRequest={UploadRequest}
            setLoading={setIsLoading}
            onUploadComplete={attachmentUploadComplete}
          /> */}
      </div>

      <div className={classes.attachmentList}>
        {isLoading ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          attachments?.map((item: any, index: number) => (
            <div className={classes.imageList} key={index}>
              <div className={classes.imageFile}>
                <Avatar variant="rounded" className={classes.rounded} src={item?.url}>
                  {/* <PictureAsPdf /> */}
                  {getFileExtension(item?.filename)}
                </Avatar>
                <p>{item?.filename}</p>
              </div>

              <a href={item?.url} className={classes.downloadButtonLink} target="_blank" rel="noopener noreferrer">
                <DownloadButton className={classes.downloadButton}>
                  <GetAppOutlined color="primary" />
                </DownloadButton>
              </a>
            </div>
          ))
        )}
      </div>

      <div className={classes.saveButtonWrapper}>
        {isQuestionAdd ? (
          <ButtonWithLoading
            // disabled={isSaveButtonDisabled}
            onClick={handleClose}
            isLoading={loading}
          >
            Save
          </ButtonWithLoading>
        ) : (
          <ButtonWithLoading
            disabled={isSaveButtonDisabled}
            onClick={() => {
              onSaveClick(dialogType)
              setAttachments([])
            }}
            isLoading={loading}
          >
            Add
          </ButtonWithLoading>
        )}
      </div>
    </Dialog>
  )
}

export default QuestionDialog
