import { Link } from '@material-ui/core'
import CheckBox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { fetchClients, getClientAddresses } from 'api/clients'
import CreateClient from 'components/clients/create'
import getTextFieldCommonProps from 'components/common/commonProps/TextField'
import shouldShowSharesInput from 'components/common/companies/shouldShowSharesInput'
import IncorporatorField from 'components/common/companyOrder/incorporatorField'
import OutlinedSelect from 'components/common/outlinedSelect'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import useStyles from 'components/common/styles/createDialogStyles'
import { IInputFieldsErrors } from 'components/companies/create'
import { ICompanyDetailsState, UNITED_STATES } from 'components/companies/hooks/companyDetails'
import CountryList from 'country-list'
import EmptyCheckbox from 'icons/emptyCheckbox'
import FilledCheckbox from 'icons/filledCheckbox'
import { CompanyAddressType, IAddress } from 'models/address'
import { ICreatedCompany, legalTypesList } from 'models/company'
import { IClient } from 'models/user'
import * as React from 'react'
import { assertNonNullable } from 'utils/asserts'
import { formatNaturalNumber } from 'utils/formatNumbers'
import { warn } from 'utils/logging'
import { notEmpty, ValueOf } from 'utils/objectUtils'
import usaStates from 'utils/usaStates'
import { MailingAddress } from './companyCreation/firstStep/MailingAddress'
import { PriceCommission } from './companyCreation/firstStep/PriceCommission'
import { PrimaryAddress } from './companyCreation/firstStep/PrimaryAddress'
import { useAPICallback } from 'hooks/useAPICallback'
import {
  ITaxOrderType,
  TaxOrderTypeOptions,
  TaxStructureBusinessOptions,
  TaxStructurePersonalOptions,
} from 'models/taxOrder'
import { fetchSuggestions } from 'api/suggestions'
import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'

interface ICompanyDetails {
  values: ICompanyDetailsState
  onInputFieldsChange: (key: keyof ICompanyDetailsState, value: ValueOf<ICompanyDetailsState>) => void
  onAddAddressClick(field: CompanyAddressType): void
  onAddressEditClick: (key: CompanyAddressType) => (id: number) => void
  onAddressDeleteClick: (key: CompanyAddressType) => (id: number) => void
  noPrimaryAddressError?: boolean
  clients?: IClient[]
  refreshClients: () => void
  errors: IInputFieldsErrors
  initialClient?: IClient
  createdCompany?: ICreatedCompany
}

const countriesData = CountryList.getData()

const CompanyDetails: React.FC<ICompanyDetails> = ({
  errors,
  values,
  onInputFieldsChange,
  onAddAddressClick,
  onAddressEditClick,
  onAddressDeleteClick,
  clients,
  refreshClients,
  initialClient,
  createdCompany,
}) => {
  const classes = useStyles()

  const [clientAddresses, setClientAddresses] = React.useState<IAddress[]>([])
  const [clientData, setclientData] = React.useState<IClient[]>([])
  const [clientinitialadd, setclientinitialadd] = React.useState<any>([])
  const [clientDatatemp, setclientDataTemp] = React.useState<any>([])

  const getClientAddressesAndSave = async (client: IClient) => {
    assertNonNullable(client)
    assertNonNullable(client.id)
    const addresses = await getClientAddresses(client.id)
    setClientAddresses(addresses)
  }

  const showSharesInput = React.useCallback(() => values.companyType && shouldShowSharesInput(values.companyType), [
    values.companyType,
  ])
  const [createClientShown, setCreateClientShown] = React.useState(false)

  const handleIncorporatorAutocompleteChange = React.useCallback(
    (value: string | null) => {
      onInputFieldsChange('incorporator', value)
    },
    [onInputFieldsChange]
  )

  // If initial client is passed here from parent component,
  // we automatically update `client` field in `ICompanyDetailsState`.
  React.useEffect(() => {
    if (initialClient && !values.client) {
      onInputFieldsChange('client', initialClient)
    }
  }, [initialClient, onInputFieldsChange, values.client, clients])

  //
  React.useEffect(() => {
    test()
  }, [])
  const test = useAPICallback(async () => {
    const staff: IClient[] = await fetchClients()
    setclientData(staff)
  }, [])
  //
  React.useEffect(() => {
    setclientDataTemp([...clientDatatemp, clientData])
  }, [clientData])

  React.useEffect(() => {
    debugger
    if (initialClient !== undefined) {
      debugger
      handleClientChange(initialClient?.id || 0)
      if (!initialClient.first_name) {
        debugger
        initialClient.first_name = initialClient.name //+ ' ' + initialClient.middle_name + ' ' + initialClient.last_name
      }
      setclientDataTemp([...clientDatatemp, { id: initialClient.id, name: initialClient.name }])
      debugger
      let i = 0
      for (const address of initialClient?.addresses || []) {
        address.id = i++
      }
      setclientinitialadd(initialClient?.addresses)
    }
  }, [initialClient])

  React.useEffect(() => {
    // console.log('inital add',clientinitialadd);
  }, [clientinitialadd])

  const handleClientChange = React.useCallback(
    (selectedClientId: number) => {
      onInputFieldsChange('client_id', selectedClientId)

      const selectedClient: IClient | undefined = clientData?.find(client => client.id == selectedClientId)
      debugger
      if (!selectedClient) {
        // there is no option to unselect, so if client is not found it's a bug
        debugger
        warn({
          message: `user has selected a non-existing option in select field`,
          extra: {
            location: ['company creation flow', 'first step', 'client select'],
            selectedClientId,
          },
        })
        console.log('erorrr')

        return
      }
      debugger

      getClientAddressesAndSave(selectedClient)
      onInputFieldsChange('client', selectedClient)
    },
    [clientData, onInputFieldsChange]
  )
  React.useEffect(() => {
    debugger
    if (clientData.length > 0) {
      debugger
      if (initialClient !== undefined) {
        debugger
        handleClientChange(initialClient?.id || 0)
      }
    }
  }, [clientData])
  // const handleCompanyAddressChange = React.useCallback(
  //   (newAddressId: number | null): void => {
  //     const addressArray = initialClient?.addresses || []
  //     const selectedAddress = addressArray.find(address => address.id === newAddressId)
  //     onInputFieldsChange('address', selectedAddress)
  //   },
  //   [clientAddresses/*initialClient?.addresses*/, onInputFieldsChange]
  // )

  // const handleMailingAddressChange = React.useCallback(
  //   (newAddressId: number | null) => {
  //     const addressArray = initialClient?.addresses || []
  //     const selectedAddress = addressArray.find(address => address.id === newAddressId)
  //     onInputFieldsChange('mailingAddress', selectedAddress)
  //   },
  //   [initialClient?.addresses, onInputFieldsChange]
  // )
  const handleCompanyAddressChange = React.useCallback(
    (newAddressId: number | null): void => {
      const selectedAddress = clientAddresses.find(address => address.id === newAddressId)
      //
      if (initialClient !== undefined) {
        const selectedAddress2 = clientinitialadd.find(address => address.id === newAddressId)
        if (!selectedAddress2?.city_id) {
          const newAddress = {
            ...selectedAddress2,
            city: selectedAddress2?.cityObj?.label,
            city_id: selectedAddress2?.cityObj?.value,
          }
          onInputFieldsChange('address', newAddress)
        } else {
          onInputFieldsChange('address', selectedAddress2)
        }
      } else onInputFieldsChange('address', selectedAddress)
    },
    [clientAddresses, onInputFieldsChange]
  )

  const handleMailingAddressChange = React.useCallback(
    (newAddressId: number | null) => {
      const selectedAddress = clientAddresses.find(address => address.id === newAddressId)
      //
      if (initialClient !== undefined) {
        const selectedAddress2 = clientinitialadd.find(address => address.id === newAddressId)
        if (!selectedAddress2?.city_id) {
          const newAddress = {
            ...selectedAddress2,
            city: selectedAddress2?.cityObj?.label,
            city_id: selectedAddress2?.cityObj?.value,
          }
          onInputFieldsChange('mailingAddress', newAddress)
        } else {
          onInputFieldsChange('mailingAddress', selectedAddress2)
        }
      } else onInputFieldsChange('mailingAddress', selectedAddress)
    },
    [clientAddresses, onInputFieldsChange]
  )
  const taxStructureSelectOptions = React.useMemo(() => Object.values(TaxStructureBusinessOptions), [])
  const defaultAssignee: ISuggestionAttribute | undefined =
    initialClient && initialClient.name && initialClient.id
      ? {
          label: `${
            initialClient.first_name ? initialClient.first_name : initialClient.name
          } ${initialClient?.middle_name || ''} ${initialClient.last_name}`,
          value: initialClient.id,
        }
      : undefined

  return (
    <div className={classes.innerContainer}>
      {createClientShown && (
        <CreateClient
          isPopup={true}
          fetchClients={fetchClients}
          openByDefault={true}
          onClientCreated={createdClient => refreshClients()}
          onClose={() => setCreateClientShown(false)}
          initialClient={values.address ? [values.address] : undefined}
        />
      )}
      <div className={classes.formContainer}>
        <Link
          component="button"
          variant="body2"
          onClick={() => setCreateClientShown(true)}
          className={classes.addClientButton}
        >
          + Add Client
        </Link>
        <div className={classes.clientSelectFieldContainer}>
          {/* <SelectAutocomplete
            dataTestId="client-select-dropdown"
            options={clientData
              ?.map(client =>
                client.id
                  ? {
                      value: client.id,
                      label: client.name,
                    }
                  : undefined
              )
              .filter(notEmpty)}
            //options={[]}
            value={
              (values.client && values.client.name) ||
              (initialClient && initialClient.name + ' ' + initialClient.middle_name + ' ' + initialClient.last_name)
            }
            label="Client"
            error={errors.client}
            helperText={errors.client ? 'Please Select A Client' : undefined}
            handleChange={handleClientChange}
          /> */}
          <SelectField
            // onOptionSelected={option => handleClientChange(option?.value || 0)}
            onOptionSelected={option => onInputFieldsChange('client_id', option?.value)}
            title="Client"
            fetchSuggestions={fetchSuggestions}
            field="client_id"
            defaultValue={defaultAssignee}
            key={initialClient?.id}
          />
        </div>
        <TextField
          data-testid="company-name-field"
          error={errors.companyName}
          helperText={errors.companyName && 'Please Specify Name For The Company'}
          label="Company Name"
          style={{ marginBottom: errors.companyName ? '1.7rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={values.companyName}
          onChange={event => {
            onInputFieldsChange('companyName', event.target.value)
          }}
        />
        <OutlinedSelect
          disabled={true}
          name="Countries"
          error={errors.country}
          errorHelperText="Please Select Country"
          value={values.country}
          options={countriesData.map(country => country.name)}
          onOptionSelect={value => onInputFieldsChange('country', value as string)}
        />
        {values.country === UNITED_STATES ? (
          <SelectAutocomplete
            dataTestId="state-select-dropdown"
            options={usaStates
              ?.map(state => ({
                value: state,
                label: state,
              }))
              .filter(notEmpty)}
            label="State"
            error={errors.stateOfFormation}
            value={values.stateOfFormation}
            helperText={errors.stateOfFormation ? 'Please Select State' : undefined}
            handleChange={value => onInputFieldsChange('stateOfFormation', value)}
          />
        ) : (
          <TextField
            label="State/Province"
            {...getTextFieldCommonProps({ error: errors.name, errorHelperTextClass: classes.errorHelperText })}
            className={classes.textField}
            type="text"
            name="name"
            value={values.stateOfFormation}
            onChange={event => onInputFieldsChange('stateOfFormation', event.target.value)}
          />
        )}
        <OutlinedSelect
          dataTestId="company-type-dropdown"
          name="Company Type"
          error={errors.companyType}
          errorHelperText="Please Specify Company Type"
          value={values.companyType || ''}
          options={legalTypesList}
          onOptionSelect={value => {
            onInputFieldsChange('companyType', value as string)
          }}
          disabled={createdCompany && !!createdCompany.id}
        />
        {showSharesInput() && (
          <TextField
            label="Number of Company Shares"
            {...getTextFieldCommonProps({ error: errors.shares, errorHelperTextClass: classes.errorHelperText })}
            className={classes.textField}
            name="shares"
            value={values.shares}
            onChange={event => {
              const value = formatNaturalNumber(event.target.value)
              onInputFieldsChange('shares', value)
            }}
          />
        )}
        {/* <div className={classes.checkBoxAndTextWrapper}>
          <CheckBox
            disableRipple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onInputFieldsChange('shouldCreateFormationOrder', event.target.checked)
            }
            checked={values.shouldCreateFormationOrder}
            icon={<EmptyCheckbox />}
            checkedIcon={<FilledCheckbox />}
          />
          <Typography>New Company (Add Formation Order)</Typography>
        </div> */}
        {/* {values.shouldCreateFormationOrder && (
          <PriceCommission errors={errors} values={values} onInputFieldsChange={onInputFieldsChange} />
        )} */}
        <PrimaryAddress
          address={values.address}
          // addressOptions={initialClient?.addresses || []}
          addressOptions={initialClient !== undefined ? clientinitialadd : clientAddresses}
          onAddAddressClick={() => onAddAddressClick('address')}
          onAddressEditClick={onAddressEditClick('address')}
          onAddressDeleteClick={onAddressDeleteClick('address')}
          handleCompanyAddressChange={handleCompanyAddressChange}
          errors={errors}
        />
        <MailingAddress
          mailingAddress={values.mailingAddress}
          // addressOptions={initialClient?.addresses || []}
          addressOptions={initialClient !== undefined ? clientinitialadd : clientAddresses}
          onAddAddressClick={() => onAddAddressClick('mailingAddress')}
          onAddressEditClick={onAddressEditClick('mailingAddress')}
          onAddressDeleteClick={onAddressDeleteClick('mailingAddress')}
          handleMailingAddressChange={handleMailingAddressChange}
        />
        <TextField
          data-testid="company-business-activity"
          label="Business Activity"
          //error={errors.businessActivity}
          helperText={errors.businessActivity && 'Please Specify Business Activity Of The Company'}
          className={classes.textField}
          {...getTextFieldCommonProps({
            error: errors.businessActivity,
            errorHelperTextClass: classes.errorHelperText,
          })}
          type="text"
          name="Business Activity"
          value={values.businessActivity}
          onChange={event => onInputFieldsChange('businessActivity', event.target.value)}
          rows={5}
          multiline
        />
        <OutlinedSelect
          // classes={{ formControl: classes.selectContainer }}
          name={'Tax Structure'}
          value={values.tax_structure}
          options={taxStructureSelectOptions}
          onOptionSelect={value => onInputFieldsChange('tax_structure', value as string)}
        />
        <IncorporatorField
          className={classes.textField}
          error={errors.incorporator}
          handleAutocompleteChange={handleIncorporatorAutocompleteChange}
          defaultIncorporator={values.incorporator}
        />
      </div>
    </div>
  )
}

export default CompanyDetails
