import {
  Avatar,
  Checkbox,
  createStyles,
  FormControl,
  FormHelperText,
  ListItem,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { createClient, getSpecialOfferLabels, updateClient, uploadOfferImage } from 'api/specialOffers'

import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'

import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedSpecialOffers, ISpecialOffers, ISpecialOffersCategory } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { toTitleCase } from '@jetkit/react'
import { EditorApiKey, LABEL_TYPES } from 'utils/constant'
import { Editor } from '@tinymce/tinymce-react'
import { fetchAllSpecialOfferCategories } from 'api/specialOffersCategory'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import ImageUpload from 'components/common/textFields/ImageUpload'
import Labels from 'components/labels'
import { findLabelList, getCompanyLabels } from 'api/label'
import showSuccessNotification from 'utils/showSuccessNotification'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    imgDiv: {
      display: 'flex',
      alignItems: 'inherit',
    },
    userAvatar: {
      width: '55px',
      height: '55px',
      marginTop: '3%',
      marginLeft: '3%',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: ISpecialOffers = {
  title: '',
  link: '',
  logo: '',
  company_prompt: '',
  button_text: '',
  color: '',
  ispopup: false,
  id: 0,
  completed_orders: false,
  description: '',
  heading: '',
  subheading: '',
  terms_text: '',
}

export interface IClientDetailsProps {
  editedClient?: ISpecialOffers
  //   initialClient?: IAddress[] | undefined
  onRequestSuccessful: (client: ICreatedSpecialOffers) => void
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

const ClientDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful /*, initialClient*/ }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE
  const defaultClient = { ...newClient /*, addresses: initialClient*/ }
  const [client, setClient] = React.useState<ISpecialOffers>(
    editedClient
      ? {
          ...editedClient,
          //addresses: initialClient,
          //dob: extractDateFromClient(editedClient),
        }
      : defaultClient
  )
  const [offerCategories, setOfferCategories] = React.useState<ISpecialOffersCategory[]>([])

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })
  const [dataList, setDataList] = React.useState<any>([])
  const [selectedList, setSelectedList] = React.useState<any>([])
  const [listLoading, setListLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  //powerups labels
  React.useEffect(() => {
    getListfunc()
  }, [])

  const getListfunc = async () => {
    try {
      const res = await findLabelList(LABEL_TYPES.company)
      setDataList(res)
    } catch (error) {}
  }
  React.useEffect(() => {
    const getList = async () => {
      try {
        setListLoading(true)
        const res = await getSpecialOfferLabels(editedClient?.id || 0, LABEL_TYPES.company)
        setSelectedList(res)
        setListLoading(false)
      } catch (error) {
        setListLoading(false)
      }
    }
    getList()
  }, [editedClient?.id])
  const handleChange = selectArray => {
    setSelectedList(prev => [...prev, selectArray])
    // setChanged(true)
  }
  React.useEffect(() => {
    // debugger
    if (selectedList.length === 0) {
      changeClientField('labels', [])
    }
    if (selectedList.length > 0 && dataList.length > 0) {
      const filteredData: any[] = []
      for (let i = 0; i < dataList.length; i++) {
        let count = 0
        for (let j = 0; j < selectedList.length; j++) {
          if (dataList[i].value !== selectedList[j].value) {
            count += 1
          }
        }
        if (count === selectedList.length) {
          filteredData.push(dataList[i])
        }
      }
      console.log('filteredData', filteredData)
      setDataList(filteredData)
      const ids: number[] = selectedList.map(item => {
        return item.value
      })
      changeClientField('labels', [...new Set(ids)])
    }
  }, [selectedList])
  const saveCompanyLabels = async () => {
    const ids: number[] = selectedList.map(item => {
      return item.value
    })
    const uniq: number[] = [...new Set(ids)]
    try {
      setLoading(true)
      // await addLabelsForCompany({ data: uniq }, company_id, LABEL_TYPES.company)
      setLoading(false)

      showSuccessNotification('Updated SuccessFully')
    } catch (error) {
      showApiResponseError(error, 'Error Updating')
      setLoading(false)
    }
  }
  const onDeleteClick = async val => {
    // debugger
    console.log(val)
    const filteredData = selectedList.filter(object => {
      return object.value !== val
    })

    setSelectedList(filteredData ? filteredData : [])
    // setChanged(true)
    // await getListfunc()
  }
  ///labels end

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()

  const getOfferCategories = async () => {
    const offers = await fetchAllSpecialOfferCategories()
    setOfferCategories(offers)
  }
  React.useEffect(() => {
    getOfferCategories()
  }, [])
  const updateClientRequest = async (client: ISpecialOffers) => {
    const editedClient = client
    const resClient = await updateClient({
      id: editedClient.id,
      title: editedClient.title,
      link: editedClient.link,
      logo: editedClient.logo,
      company_prompt: editedClient.company_prompt,
      button_text: editedClient.button_text,
      color: editedClient.color,
      ispopup: editedClient.ispopup,
      completed_orders: editedClient.completed_orders,
      description: editedClient.description || '',
      heading: editedClient.heading || '',
      subheading: editedClient.subheading || '',
      // country_name: editedClient.country_name,
      category_id: editedClient.category_id,
      terms_text: editedClient.terms_text || '',
      is_active: editedClient.is_active,
      labels: editedClient.labels,
      // sequence: Number(editedClient.sequence),
    })
    editedClient.id && (await imgUpload(editedClient.id))

    return resClient
  }
  const imgUpload = async (id: number) => {
    const imageInput: any = document.querySelector('#empimg')
    const file = imageInput.files[0]
    if (file !== null && file !== undefined) await uploadOfferImage(id, file, { entity: 'specialoffers' })
  }
  const createClientRequest = async (client: ISpecialOffers) => {
    // const resClient = await createClient({ ...client, sequence: Number(client.sequence) })
    const resClient = await createClient({ ...client })
    await imgUpload(resClient.id)

    return resClient
  }

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedSpecialOffers = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const changeClientField = React.useCallback(
    (field: keyof ISpecialOffers, value: ValueOf<ISpecialOffers>) => {
      debugger
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    try {
      let clientResponse: ICreatedSpecialOffers
      if (formMode === FormMode.UPDATE) {
        clientResponse = await updateClientRequest(client)
      } else {
        clientResponse = await createClientRequest(client)
      }

      const updatedClient = clientResponse //formMode === FormMode.UPDATE ? client : newClient
      setClient(updatedClient)
    } catch (err) {
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }, [formMode, client, clientEditErrors])

  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Title"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.title}
          onChange={event => changeClientField('title', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          label="Heading"
          className={classes.textField}
          type="text"
          name="heading"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.heading}
          onChange={event => changeClientField('heading', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          label="Sub-Heading"
          className={classes.textField}
          type="text"
          name="subheading"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.subheading}
          onChange={event => changeClientField('subheading', event.target.value)}
        />

        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Logo"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.logo}
          onChange={event => changeClientField('logo', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Company Prompt"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.company_prompt}
          onChange={event => changeClientField('company_prompt', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Button Text"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.button_text}
          onChange={event => changeClientField('button_text', event.target.value)}
        />

        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Link"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.link}
          onChange={event => changeClientField('link', event.target.value)}
        />
        <br />
        <div style={{ width: '100%' }}>
          {' '}
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel
              id="labelP"
              margin="dense"
              shrink
              variant="outlined"
              // style={{ marginLeft: '1rem', position: 'absolute', top: '-7px' }}
            >
              Powerup Category
            </InputLabel>
            <Select
              // value={age}
              onChange={(e: any) => changeClientField('category_id', e.target.value)}
              label="Powerup Category"
              name="powerup category"
              value={client.category_id}
              fullWidth
              variant="outlined"
              placeholder="Powerup"
              labelId="labelP"
            >
              {offerCategories?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <br />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Color"
          className={classes.textField}
          type="color"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.color}
          onChange={event => changeClientField('color', event.target.value)}
        />
        <Editor
          apiKey={EditorApiKey}
          init={{
            plugins: [
              'advlist autolink lists link charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            width: '100%',
            height: '20rem',
            valid_children: '+body[style]',
            valid_styles: { '*': '*' },
            init_instance_callback: function(editor) {
              editor.on('click', function(e) {
                if (e.target.nodeName === 'INPUT') {
                  if (e.target.getAttribute('checked')) {
                    e.target.removeAttribute('checked')
                  } else {
                    e.target.setAttribute('checked', 'true')
                  }
                }
              })
            },
          }}
          onEditorChange={e => changeClientField('description', e)}
          initialValue={''}
          value={client.description}
        />
        <ListItem key={100} className={''}>
          <Checkbox
            color="secondary"
            checked={client.completed_orders}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            // value={values.is_primary_contact}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => changeClientField('completed_orders', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Show on Completed Orders')}</span>
        </ListItem>
        <ListItem key={100} className={''}>
          <Checkbox
            color="secondary"
            checked={client.is_active}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            // value={values.is_primary_contact}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => changeClientField('is_active', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Active ')}</span>
          <span>&nbsp;(Show on Client Dashboard)</span>
        </ListItem>
        {client.logo !== '' ? (
          <div className={classes.imgDiv}>
            <ImageUpload
              // We show copy button in these fields only if we're looking at existing client, not creating a new one.
              showCopyButton={!!editedClient}
              required
              data-testid="client-name"
              error={clientEditErrors.meetinglink}
              ///helperText={clientEditErrors. && 'Please Specify image'}
              label="Feature image"
              style={{
                marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined,
                width: client.logo !== '' ? '100%' : '100%',
              }}
              className={classes.textField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorHelperText,
                },
              }}
              type="file"
              name="image"
              margin="normal"
              variant="outlined"
              id="empimg"
              // value={client.image}
              // onChange={event => changeClientField('image', event.target.value)}
              // disabled
            />

            {client.logo !== '' ? (
              //  <img style={{ paddingTop: '3.5%' }} src={client.logo} height="14%" width="12%" />
              <div style={{ width: '85px' }}>
                <Avatar alt="avatar" src={client.logo} className={classes.userAvatar} />
              </div>
            ) : null}
          </div>
        ) : (
          <ImageUpload
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            showCopyButton={!!editedClient}
            required
            data-testid="client-name"
            error={clientEditErrors.meetinglink}
            ///helperText={clientEditErrors. && 'Please Specify image'}
            label="Feature image"
            style={{ marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined, width: '100%' }}
            className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="file"
            name="image"
            margin="normal"
            variant="outlined"
            id="empimg"
            // value={client.image}
            // onChange={event => changeClientField('image', event.target.value)}
            // disabled
          />
        )}
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Terms Text"
          className={classes.textField}
          type="text"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.terms_text}
          onChange={event => changeClientField('terms_text', event.target.value)}
        />
        {/* <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          label="Sequence"
          className={classes.textField}
          type="number"
          name="industrytype"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.sequence}
          onChange={event => changeClientField('sequence', event.target.value)}
        /> */}
        <Labels
          title="Powerup Labels"
          dataList={dataList}
          selectedList={selectedList}
          handleChange={handleChange}
          loading={loading}
          handleSave={null}
          onDeleteClick={onDeleteClick}
          listLoading={listLoading}
        />
        <SubmitFormButton
          onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add Offer'}
          onClick={onSubmitClick}
        />
      </div>
    </React.Fragment>
  )
}

export default ClientDetails
