import { apiClient, requestPaginated } from '@jetkit/react'

export interface EmployeeReportData {
  id: number
  user_id: string
  logged_at: string
  user_type: string
  loggedOut_at: string
  page_visits: number
  user_clicks: number
  order_status_updated: number
  order_updated: number
  task_created: number
  task_updated: number
  order_initiated: number
  order_info_required: number
  order_submitted_government_agency: number
  order_revision_request: number
  time_spent: number
  task_completed: number
  notes_created: number
}

export interface EmployeeReportSummaryData {
  total_time_spent: number
  total_clicks: number
  order_initiated: number
  order_info_required: number
  order_submitted_to_govt: number
  order_revision_request: number
  order_completed: number
  company_created: number
  order_created: number
}

export const getEmployeeReport = (employeeId?: number, startData?: string, endData?: string) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'

  return requestPaginated<EmployeeReportData[]>({
    url: `/employee-report-paginated?employee_id=${employeeId ? employeeId : 0}&start_date=${
      startData ? encodeURIComponent(startData) : ''
    }&end_date=${endData ? encodeURIComponent(endData) : ''}`,
  })
}

export const employeeReportSummary = async (
  employeeId?: number,
  startData?: string,
  endData?: string
): Promise<EmployeeReportSummaryData> => {
  const data = (
    await apiClient.get(
      `/employee-report?employee_id=${employeeId ? employeeId : 0}&start_date=${
        startData ? encodeURIComponent(startData) : ''
      }&end_date=${endData ? encodeURIComponent(endData) : ''}`
    )
  ).data
  return data
}
