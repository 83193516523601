import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {},
    formRoot: {
      width: '100%',
    },
    formSelect: {
      outline: 'none',
      padding: '8px',
      fontSize: '16px',
      borderRadius: '4px',
      fontFamily: 'Roboto',
      backgroundColor: '#f6f6f8',
      '&:before': {
        borderBottom: '0px !important',
      },
      '&:after': {
        borderBottom: '0px ',
      },
      '& :focus': {
        backgroundColor: 'transparent ',
      },
      '& .MuiInputBase-input': {
        padding: '0px',
      },
    },
    label: {
      marginBottom: '4px',
      fontSize: '12px',
      color: '#202124 ',
      textTransform: 'uppercase',
    },
  })
)

interface DropdownProps {
  label: string
  options: any[]
  placeholder: string
  val: number | string
  handleValueChange: any
  disabled?: boolean
}

const Dropdown = ({ label, options, placeholder, val, handleValueChange, disabled }: DropdownProps) => {
  const classes = useStyles()

  console.log('options', options)

  return (
    <div className={classes.mainContainer}>
      <label className={classes.label}>{label}</label>

      <FormControl className={classes.formRoot}>
        <Select
          className={classes.formSelect}
          value={val}
          onChange={handleValueChange}
          label={placeholder}
          disabled={disabled}
        >
          <MenuItem value="" disabled>
            <em>None</em>
          </MenuItem>
          {options?.map((item: any) => (
            <MenuItem value={item?.id || item?.value}>{item?.name || item?.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default Dropdown
