import React, { useState } from 'react'
import { useStyles } from 'components/dashboard/styles'
import { Backdrop, Badge, Button, CircularProgress, Divider } from '@material-ui/core'
import { Check, BusinessCenterOutlined, PersonAddOutlined } from '@material-ui/icons'
import MsgPopup from './msgPopup'
import MsgBox from './msgBox'
import { getClientMessage, getProspectMessage, sendMessageStatus } from 'api/mesasges'
import showErrorNotification from 'utils/showErrorNotification'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import showSuccessNotification from 'utils/showSuccessNotification'
import { Skeleton } from '@material-ui/lab'
import useRouter from 'use-react-router'
import { markProspectAsReadById } from 'api/leadManager'

interface MsgBoxProps {
  type: 'client' | 'prospect'
}
const ClientMsgBox = ({ type }: MsgBoxProps) => {
  const classes = useStyles()
  const router = useRouter()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [clientMessages, setClientMessages] = useState<any[]>([])

  const [isMessageLoading, setIsMessageLoading] = useState(false)

  const [isComplete, setIsComplete] = useState(false)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const getMessages = React.useCallback(async () => {
    setIsMessageLoading(true)
    try {
      const messages = await getClientMessage()
      setClientMessages(messages['data'])
      setIsMessageLoading(false)
    } catch (error) {
      showErrorNotification(error)
    }
    setIsMessageLoading(false)
    setIsComplete(false)
  }, [])

  const getProspectSms = React.useCallback(async () => {
    setIsMessageLoading(true)
    try {
      const messages = await getProspectMessage()
      setClientMessages(messages['data'])
      setIsMessageLoading(false)
    } catch (error) {
      showErrorNotification(error)
    }
    setIsMessageLoading(false)
    setIsComplete(false)
  }, [])
  const handleCompleteMark = async (id: number) => {
    const data = {
      read: true,
    }
    setIsMessageLoading(false)

    if (id) {
      try {
        if (type === 'client') await sendMessageStatus(id, data)
        else await markProspectAsReadById(id, data)
        setIsComplete(true)
        setIsMessageLoading(true)
        showSuccessNotification('Message Read')
      } catch (error) {
        showErrorNotification(error)
      }
      setIsMessageLoading(false)
    }
  }

  const handleAssociateCompany = (id: number, name: string) => {
    if (!id) return
    if (type === 'client') router.history.push(`/companies?client_id=${id}&client_name=${encodeURI(name)}`)
    else router.history.push(`/prospect/${id}`)
  }

  React.useEffect(() => {
    if (type === 'client') getMessages()
    else getProspectSms()
  }, [getMessages, isComplete])

  return (
    <div className={classes.clientMsgBoxSection}>
      <div className="msg-box-header">
        <p className="herder-text">{type.toUpperCase()} MESSAGES</p>
        <span className="inline-text">
          <Badge badgeContent={clientMessages?.length || 0} className="badge-count" onClick={handleClick} />
          <p className="herder-text">All Messages</p>
        </span>
      </div>
      <Divider className="msg-divider" />
      {isMessageLoading ? (
        <Skeleton height={100} animation="wave" variant="text" style={{ margin: '0 1rem' }} />
      ) : clientMessages?.length ? (
        <>
          <div className="msg-box-content">
            <p className="primary-text">
              {clientMessages[0]?.client?.first_name} {clientMessages[0]?.client?.last_name}{' '}
              {formatDateTimeToHumanFriendly(clientMessages[0]?.message_timestamp)}
            </p>
            <p className="secondary-text">{clientMessages[0]?.msg_text}</p>
          </div>

          <div className="msg-box-footer">
            <Button
              className="box-btn"
              startIcon={<Check className="btn-icon" />}
              onClick={() => handleCompleteMark(clientMessages[0]?.id)}
            >
              Complete
            </Button>
            <Button
              className="box-btn"
              startIcon={
                type === 'client' ? (
                  <BusinessCenterOutlined className="btn-icon" />
                ) : (
                  <PersonAddOutlined className="btn-icon" />
                )
              }
              onClick={() =>
                handleAssociateCompany(
                  type === 'client' ? clientMessages[0]?.client_id : clientMessages[0]?.prospect_id,
                  `${clientMessages[0]?.client?.first_name} ${clientMessages[0]?.client?.middle_name} ${clientMessages[0]?.client?.last_name}`
                )
              }
            >
              View {type === 'client' ? 'Companies' : 'Prospect'}
            </Button>
            {/* <Button className="box-btn" startIcon={<PersonAddOutlined className="btn-icon" />}>
              Assign
            </Button> */}
          </div>
        </>
      ) : (
        <div className="msg-box-content">
          <p className="secondary-text">No new message.</p>
        </div>
      )}
      {/* <MsgPopup anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {allMsgs.map((msg, key) => (
          <MsgBox key={key} name={msg.name} msgText={msg.msgText} hour={msg.hour} icon={msg.icon} />
        ))}
      </MsgPopup> */}
      <MsgPopup anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {clientMessages?.map((msg, key) => (
          <MsgBox
            key={msg?.id}
            name={msg?.client?.first_name}
            msgText={msg.msg_text}
            hour={msg.message_timestamp}
            icon={msg?.client?.first_name?.charAt(0)?.toUpperCase()}
            completeHandle={() => handleCompleteMark(msg?.id)}
            associateHandle={() =>
              handleAssociateCompany(
                type === 'client' ? msg?.client_id : msg?.prospect_id,

                // msg?.client_id,
                `${msg?.client?.first_name} ${msg?.client?.middle_name} ${msg?.client?.last_name}`
              )
            }
            type={type}
          />
        ))}
      </MsgPopup>
    </div>
  )
}

export default ClientMsgBox
