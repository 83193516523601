import { createStyles, makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      textAlign: 'center',
      padding: '0 1rem',
      marginBottom: '20px',
    },
    commissionContainer: {
      textAlign: 'center',
      padding: '0 1.5rem',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'row-reverse',

      '& img': {
        // marginRight: '1rem',
        width: '36px',
        height: '36px',
      },

      '& .commission-section': {
        textAlign: 'start',

        '& p': {
          fontFamily: 'Roboto',
          margin: 0,
          fontSize: '0.9rem',
        },
        '& .mobile_bronze_badge_text': {
          fontWeight: 600,
          textTransform: 'uppercase',
        },
        '& .comission_text': {
          paddingBottom: '0.25rem',
        },
        '& span': {
          cursor: 'pointer',
          textDecoration: 'underline',
          '& a': {
            fontSize: '0.9rem',
            color: '#292929',
          },
        },
      },
    },
    mainCardContainer: {
      marginBottom: '20px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    mainContent: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '12px !important',
      alignItems: 'center',
    },
    commissionImg: {
      width: '40px',
      height: '40px',
    },
    rightContent: {
      textAlign: 'center',
    },
    heading: {
      fontSize: '16px !important',
      fontWeight: 'bold',
      paddingBottom: '5px',
      color: '#292929',
      fontFamily: 'Lato',
    },
    price: {
      width: '80px',
      fontSize: '16px !important',
      backgroundColor: ' #5bb3d9 !important',
      color: '#fff !important',
    },
    refer: {
      cursor: 'pointer',
      fontSize: '12px',
      backgroundColor: '#d5d8dd',
      height: 'auto',
      padding: ' 4px 11px',
      '& .icon': {
        fontSize: '17px !important',
      },
    },
    dialogContainer: {
      textAlign: 'center',
      padding: '20px',
    },
    mainHeading: {
      marginBottom: '10px',
    },
    iconBox: {
      marginBottom: '20px',
    },
    fb_icon: {
      color: '#385997',
      fontSize: '50px',
      paddingRight: '10px',
    },
    tw_icon: {
      color: '#00aade',
      fontSize: '50px',
      paddingRight: '10px',
    },
    ml_icon: {
      color: '#00aade',
      fontSize: '50px',
      paddingRight: '10px',
    },
    ch_icon: {
      color: '#18ca38',
      fontSize: '50px',
      paddingRight: '10px',
    },
    content: {
      textAlign: 'left',
    },
    para: {
      paddingTop: '10px',
      fontSize: '12px',
    },

    // new popup styles

    // ================================
    //            1st modal
    // ================================
    earnContainer: {
      // position: "relative",
      padding: '2.25rem 3.375rem 2.25rem 3.375rem',
      [theme.breakpoints.down('xs')]: {
        padding: '2.25rem 0 2.25rem 0',
        paddingTop: 0,
      },
      '& .pop_up_title': {
        textAlign: 'center',
        color: '#8e7657',
        fontFamily: 'Lato',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        lineHeight: '1.2',
        letterSpacing: '0.013rem',
        margin: '1rem 0 0.788rem 0',

        [theme.breakpoints.down('xs')]: {
          fontSize: '2rem',
        },
      },
      '& .money_bag_container': {
        textAlign: 'center',
      },
      '& .income_para': {
        margin: '0 0',
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        lineHeight: '1.5',
        letterSpacing: '0.018rem',
        textAlign: 'center',
        color: '#202020',
      },
      '& .plat_form_para': {
        margin: '0 0 1.594rem 0',
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        lineHeight: '1.5',
        letterSpacing: '0.018rem',
        textAlign: 'center',
        color: '#202020',
      },
      '& .common_grid_container': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .medal_icon': {
        marginBottom: '0.419rem',
      },
      '& .metal_category': {
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        lineHeight: '1.25',
        letterSpacing: '0.0004rem',
        textAlign: 'center',
        margin: '0 0',
      },
      '& .platinum_and_para_container': {
        textAlign: 'center',
      },
      '& .bronze_color': {
        color: '#a08f73',
      },
      '& .silver_color': {
        color: '#b8babe',
      },
      '& .gold_color': {
        color: '#bca886',
      },
      '& .platinum_color': {
        color: '#979797',
      },
      '& .clients_per_month_and_comission': {
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        lineHeight: '1.17',
        letterSpacing: '0.015rem',
        textAlign: 'left',
        color: '#202020',
        paddingLeft: '2rem',
        textTransform: 'capitalize',

        [theme.breakpoints.down('xs')]: {
          padding: '0',
        },
      },
      '& .clients_per_month': {
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        lineHeight: '1.17',
        letterSpacing: '0.015rem',
        textAlign: 'left',
        color: '#202020',
        paddingLeft: '2rem',
        textTransform: 'capitalize',

        [theme.breakpoints.down('xs')]: {
          padding: '0',
        },
      },
      '& .clients_range': {
        fontFamily: 'Lato',
        fontSize: '1rem',
        lineHeight: '1.31',
        letterSpacing: '0.02rem',
        color: '#202020',
      },
      '& .comission_percentage': {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '1.31',
        letterSpacing: '0.02rem',
        textAlign: 'center',
        color: '#202020',
      },
      '& .default_link_para': {
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        lineHeight: '1.43',
        textAlign: 'center',
        color: '#343b4c',
        margin: '1rem 0 0 0',
      },
      '& .business_rocket_link': {
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        lineHeight: '1.43',
        textAlign: 'left',
        color: ' #343b4c',
        whiteSpace: 'pre',

        [theme.breakpoints.down('xs')]: {
          fontSize: '0.75rem',
        },
      },
      '& .para_text_field_container': {
        display: 'flex',
        alignItems: 'center',

        '& div': {
          cursor: 'pointer',
          marginLeft: '0.5rem',

          [theme.breakpoints.down('xs')]: {
            marginLeft: '0.5rem',
          },
        },
      },
      '& .code_text_field': {
        '& .MuiOutlinedInput-root': {
          borderRadius: '2.5rem',
          backgroundColor: '#e8f5fe',
          fontSize: '0.9rem',

          [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
          },

          '& .MuiOutlinedInput-input': {
            fontFamily: 'Lato',
            fontSize: '0.9rem',
            color: '#707070',

            [theme.breakpoints.down('xs')]: {
              fontSize: '0.8rem',
              padding: '0.5rem',
              height: '12px',
            },
          },
        },
        [theme.breakpoints.down('xs')]: {
          width: '5.313rem',
          marginLeft: '0 !important',
        },

        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .click_edit_customize': {
        backgroundColor: ' #788190',
        border: 'none',
        borderRadius: '2.5rem',
        padding: '0.625rem 1rem',
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        lineHeight: '1.21',
        letterSpacing: '0.004rem',
        textAlign: 'left',
        color: ' #fff',
      },
      '& .edit_btn_container': {
        marginLeft: '0.563rem',
      },
      '& .text_field_and_btn_main_container': {
        display: 'flex',
        // justifyContent: 'space-between',
        margin: '0.5rem 0',
        justifyContent: 'center',

        alignItems: 'baseline',
        [theme.breakpoints.down('xs')]: {
          marginBottom: '1rem',
          justifyContent: 'center',
        },
      },
      '& .edit_text_para': {
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        lineHeight: '1.17',
        textAlign: 'left',
        color: '#707070',
        margin: '0.438rem 0 0 0',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      '& .quick_share_options': {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '1.19',
        letterSpacing: '0.005rem',
        textAlign: 'center',
        color: '#202124',
        margin: '0 0 0.938rem 0',
      },
      '& .social_media_icons_container': {
        textAlign: 'center',

        '& a': {
          '&:nth-of-type(even)': {
            margin: '0 0.9rem',
          },
        },
      },

      '& .what_should': {
        color: '#307abc',
        // whiteSpace: 'pre',
        fontSize: '.938rem',
        textAlign: 'center',
        letterSpacing: '0.019rem',
        // lineHeight: '0.083rem',
        fontWeight: '500',
      },
      '& .contact_description': {
        textAlign: 'center',
        color: '#202020',
        fontSize: '0.75rem',
        lineHeight: '1.58',
        letterSpacing: '0.015rem',
        margin: '0',
      },
      '& .discount_earn': {
        textAlign: 'center',
        fontSize: '1.25rem',
        letterSpacing: '0.025rem',
        color: ' #202020',
      },
      '& .earn_life_time': {
        textAlign: 'center',
        fontSize: '1.25rem',
        // lineHeight: '0.091rem',
        letterSpacing: '0.025rem',
        color: ' #202020',
      },
      '& .flag': {
        width: '6.875rem',
        height: '5rem',
        boxSizing: 'content-box',
        paddingTop: '0.938rem',
        position: 'relative',
        background: '#8e7657',
        color: 'white',
        fontSize: '0.688rem',
        letterSpacing: '0.2em',
        textAlign: 'center',
        textTransform: 'uppercase',
        clipPath: 'polygon(100% 0, 100% 100%, 50% 85%, 0 100%, 0 0)',

        // '& ::after': {
        //   content: '""',
        //   position: 'absolute',
        //   left: '0',
        //   bottom: '0',
        //   width: '0',
        //   height: '0',
        //   borderBottom: '0.813rem solid #fff',
        //   borderLeft: '3.438rem solid transparent',
        //   borderRight: '3.438rem solid transparent',
        // },
      },
      '& .bronze_badge_text': {
        fontSize: '0.875rem',
        lineHeight: '1.21',
        fontWeight: 'bold',
        letterSpacing: '0.004rem',
        color: '#fff',
        margin: '0 0 0.281rem',
        textTransform: 'uppercase',
      },
      '& .mobile_bronze_badge_text': {
        margin: 0,
        fontSize: '0.875rem',
        lineHeight: '1.23',
        fontWeight: 'bold',
        letterSpacing: '0.004rem',
        color: '#fff',
        textTransform: 'uppercase',
      },
      '& .divider_container': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .brone_badge_divider': {
        backgroundColor: '#f7f8f9',
        width: '2.375rem',
      },
      '& .bronze_member_text': {
        fontSize: '0.813rem',
        lineHeight: '1.23',
        letterSpacing: '0.004rem',
        color: '#fff',
        margin: '0.156rem 0.125rem 0.188rem ',
      },
      '& .mobile_bronze_member_text': {
        fontSize: '0.875rem',
        lineHeight: '1.23',
        letterSpacing: '0.004rem',
        color: '#fff',
        margin: '0 0.25rem',

        [theme.breakpoints.down('xs')]: {
          fontSize: '0.75rem',
        },
      },
      '& .flag_main_container': {
        position: 'absolute',
        top: '0',
        right: '1rem',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      '& .bronze_check_icon': {
        fontSize: '0.875rem',
      },
      '& .mobile_check_icon': {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        marginRight: '0.5rem',
        color: '#fff',
      },
      '& .how_earn_more_btn': {
        border: 'none',
        background: 'none',
        fontSize: '0.75rem',
        cursor: 'pointer',
      },
      '& .mobile_how_earn_more_btn': {
        border: 'none',
        background: 'none',
        fontSize: '0.75rem',
        color: '#fff',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& .mobile_bronze_container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        padding: '0.5rem 0',

        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
    },
  })
)
