import MobileStepper from '@material-ui/core/MobileStepper'
import { notify } from '@jetkit/react'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import * as React from 'react'
import { IS_DEVELOPMENT_MODE } from 'utils/environment'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    activeStepText: {
      fill: 'white!important',
    },
    inactiveStepText: {
      color: theme.inactiveStepText.color,
    },
    inactiveStep: {
      border: theme.inactiveStep.border,
      borderRadius: theme.inactiveStep.borderRadius,
    },
    progress: {
      width: '100%',
    },
    lineHorizontal: {
      borderColor: 'white',
    },
  })

interface IStepper extends WithStyles<typeof styles> {
  steps: string[]
  activeStep: number
  handleStep(step: number): void
  completedSteps: {
    [index: number]: boolean
  }
}

const DialogStepper: React.FC<IStepper> = ({ classes, steps, activeStep, handleStep, completedSteps }) => {
  const connector = (
    <StepConnector
      classes={{
        lineHorizontal: classes.lineHorizontal,
      }}
    />
  )

  const handleStepLabelClick = React.useCallback(
    (index: number) => {
      if (IS_DEVELOPMENT_MODE) {
        notify.warning('Using company creation form in testing mode.')
        handleStep(index)
      } else {
        // In non-development mode, user cannot go between steps
        // by clicking step labels
      }
    },
    [handleStep]
  )

  return (
    <div className={classes.root}>
      <Stepper style={{ display: 'flex', paddingBottom: 0 }} nonLinear activeStep={activeStep} connector={connector}>
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep}>
            <StepLabel
              StepIconProps={{
                classes: {
                  text: classes.activeStepText,
                },
              }}
              onClick={() => handleStepLabelClick(index)}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <MobileStepper
        classes={{
          progress: classes.progress,
        }}
        variant="progress"
        steps={4}
        style={{ background: 'white' }}
        position="static"
        LinearProgressProps={{
          value: ((activeStep + 1) / steps.length) * 100, // get the percentage out of total
        }}
        activeStep={activeStep + 1}
        nextButton={<div style={{ display: 'none' }} />}
        backButton={<div style={{ display: 'none' }} />}
      />
    </div>
  )
}

export default withStyles(styles)(DialogStepper)
