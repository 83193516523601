import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { fetchOrders } from 'api/orders'
import { CompanyOrderContext } from 'components/orders/CompanyOrderContext'
import CreateOrder from 'components/orders/create'
import OrderTable from 'components/orders/table'
import * as React from 'react'
import { updatePageVisitsCount } from 'utils/user'
import useRouter from '../../hooks/router'

const useStyles = makeStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '2rem',
    fontFamily: 'Times New Roman',
    fontWeight: 600,
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
})

interface IOrdersProps {}

const Orders: React.FC<IOrdersProps> = () => {
  const classes = useStyles()

  const { location } = useRouter()

  const query = new URLSearchParams(location.search).get('query') || undefined

  const [reloadTable, setReloadTable] = React.useState<boolean>(false)

  return (
    <CompanyOrderContext.Provider value={{ reloadTable: reloadTable, setReloadTable: setReloadTable }}>
      <div className={classes.container}>
        <div className={classes.titleButtonContainer}>
          <Typography className={classes.title}>Orders</Typography>
          <CreateOrder />
        </div>
        <div className={classes.tableContainer}>
          <OrderTable withSearchFilters apiCall={fetchOrders} orderSearchOptions={{ query }} />
        </div>
      </div>
    </CompanyOrderContext.Provider>
  )
}

export default Orders
