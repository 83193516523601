import { SortableTableHead, SortableTableSortProps } from 'components/common/table'
import * as React from 'react'

const OrderTableHead: React.FC<SortableTableSortProps> = props => (
  <SortableTableHead
    cellStyle={{
      paddingRight: 8,
      backgroundColor: 'white',
    }}
    {...props}
  />
)

export default OrderTableHead
