import { FilterableAPICall, PagedTable, usePagedTable } from '@jetkit/react'
import { Table, TableBody } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { fetchOrders, fetchOrderStatuses, fetchOrderTypes } from 'api/orders'
import SearchBar from 'components/common/searchBar'
import FilterSelect from 'components/common/select/multiSelect'
import { HeadColumn, skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { ISuggestionAttribute } from 'components/interfaces'
import useRouter from 'hooks/router'
import { IOrder, orderStatuses } from 'models/order'
import * as React from 'react'
import { CompanyOrderContext } from '../CompanyOrderContext'
import useCompanyOrders, { ISearchOptions } from '../hooks'
import OrderTableHead from './head'
import OrderTableRow from './row'

// const headColumns: HeadColumn[] = [
//   { id: 'id', label: 'ID', style: { width: '15%', maxWidth: '15%' }, sortable: false },
//   { id: 'company_name', label: 'Company Name', style: { width: '20%', maxWidth: '20%' }, sortable: false },
//   { id: 'sales_rep_name', label: 'Sales Rep', style: { width: '20%', maxWidth: '20%' }, sortable: false },
//   { id: 'order_type', label: 'Order Type', style: { width: '15%', maxWidth: '15%' }, sortable: false },
//   { id: 'price', label: 'Price', style: { width: '10%', maxWidth: '10%' }, sortable: true },
//   { id: 'status', label: 'Status', style: { width: '10%', maxWidth: '10%' }, sortable: true },
//   { id: 'last_update', label: 'Last Update', style: { width: '10%', maxWidth: '10%' }, sortable: true },
//   { id: 'due_date', label: 'Due Date', style: { width: '10%', maxWidth: '10%' }, sortable: true },
//   { id: 'actions', label: '', style: { width: '5%', maxWidth: '5%' }, sortable: false },
// ]

const headColumns: HeadColumn[] = [
  { id: 'id', label: 'ID', style: { width: '10%' }, sortable: false },
  { id: 'order_type', label: 'Order Type', style: { width: '15%' }, sortable: false },
  { id: 'company_name', label: 'Company Name', style: { width: '20%' }, sortable: false },
  { id: 'price', label: 'Price', style: { width: '10%' }, sortable: true },
  { id: 'creation_time', label: 'Creation Date', style: { width: '10%' }, sortable: true },
  { id: 'order_status', label: 'Status', style: { width: '10%' }, sortable: true },
  { id: 'last_update', label: 'Last Update', style: { width: '10%' }, sortable: true },
  { id: 'due_date', label: 'Due Date', style: { width: '10%' }, sortable: true },
  { id: 'actions', label: '', style: { width: '15%' }, sortable: false },
]

const headColumnsWoCompanyNameAndSalesRep: HeadColumn[] = [
  { id: 'id', label: 'Order ID', style: { width: '15%', maxWidth: '15%' }, sortable: false },
  { id: 'order_type', label: 'Order Type', style: { width: '15%', maxWidth: '15%' }, sortable: false },
  { id: 'price', label: 'Price', style: { width: '10%', maxWidth: '10%' }, sortable: true },
  { id: 'order_status', label: 'Status', style: { width: '10%', maxWidth: '10%' }, sortable: true },
  { id: 'last_update', label: 'Last Update', style: { width: '10%', maxWidth: '10%' }, sortable: true },
  { id: 'due_date', label: 'Due Date', style: { width: '10%', maxWidth: '10%' }, sortable: true },
  { id: 'actions', label: '', style: { width: '10%', maxWidth: '10%' }, sortable: false },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainFiltersContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1rem',
    },
    checkBoxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: 0,
      minWidth: 200,
    },
    filterTitle: {
      fontSize: 14,
    },
    searchBarContainer: {
      margin: '0 0 0.5rem 0',
    },
    checkBoxRoot: {
      padding: 0,
    },
    filterWithTitle: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    toggleButtonGroup: {
      height: 38,
      paddingTop: '0.125rem',
    },
    innerSwitchButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'end',
    },
    toggleButton: {
      height: '100%',
      width: 79,
    },
    toggleButtonText: {
      fontSize: 12,
      color: 'grey',
    },
  })
)

interface IOrderTableProps {
  apiCall?: FilterableAPICall<IOrder>
  withSearchFilters?: boolean
  disableSearch?: boolean
  orderSearchOptions?: Partial<ISearchOptions>
  isCompanyCreationFlow?: boolean
  orderIds?: number[]
  orderStatusChanged?: boolean
}

// make a list of ISuggestionAttribute elements from a list of order statuses
const getOrderStatusSuggestions = (): ISuggestionAttribute[] =>
  orderStatuses.options.map((status, i) => {
    return { value: i, label: status }
  })

const OrderTable: React.FC<IOrderTableProps> = ({
  apiCall = fetchOrders,
  withSearchFilters,
  orderSearchOptions,
  disableSearch,
  isCompanyCreationFlow,
  orderStatusChanged,
}) => {
  const classes = useStyles() // styling
  // get hooks for Orders

  const {
    searchOptions,
    handleAddFilter,
    handleSortingClick,
    handleSearch,
    handleWithSalesRepsFilter,
    handleOpenStatusFilter,
    handleAddFilters,
    handleRushOrderFilter,
  } = useCompanyOrders({
    companyId: orderSearchOptions ? orderSearchOptions.company_id : undefined,
    orderSearchOptions,
  })
  const router = useRouter()
  const [orderTypesFromApi, setorderTypesFromApi] = React.useState<any>([])
  const [orderstatusFromApi, setorderstatusFromApi] = React.useState<any>([])
  const [defaultOrderTypes, setDefaultOrderTypes] = React.useState<any>(
    []
    //getTypes()
  )
  const [defaultOrderStatus, setdefaultOrderStatus] = React.useState<any>(
    [] //getStatus()
  )

  const getOrderTypeSuggestions = async (query: string, field: string): Promise<ISuggestionAttribute[]> => {
    const res = await fetchOrderTypes(query)
    setorderTypesFromApi(res)
    return res.map(orderType => {
      return { value: orderType.id, label: orderType.name }
    })
  }

  const getOrderstatusSuggestions = async (query: string, field: string): Promise<ISuggestionAttribute[]> => {
    const res = await fetchOrderStatuses(query)
    setorderstatusFromApi(res)
    return res.map(status => {
      return { value: status.id, label: status.name }
    })
  }
  // stuff for paginated table from '@jetkit/react'
  const memoApiCall = React.useMemo(
    (orderIds?: number[]) => {
      const orderTypesQueryParams = new URLSearchParams(window.location.search).get('orderTypes')
      const orderStatusQueryParams = new URLSearchParams(window.location.search).get('orderStatuses')
      const client_id = new URLSearchParams(window.location.search).get('client_id')

      // // ;

      if (orderIds) {
        return apiCall({ order_ids: orderIds })
      }
      if (searchOptions.order_type_ids) {
        // setDefaultOrderTypes(searchOptions.order_type_ids)
        searchOptions.order_type_ids = searchOptions?.order_type_ids.toString()
      }
      if (searchOptions.order_statuses) {
        searchOptions.order_statuses = searchOptions?.order_statuses.toString()
      }
      if (orderTypesQueryParams && !searchOptions.order_type_ids) {
        searchOptions.order_type_ids = orderTypesQueryParams
      }
      if (orderStatusQueryParams && !searchOptions.order_statuses) {
        searchOptions.order_statuses = orderStatusQueryParams
      }
      if (client_id) {
        searchOptions.client_id = parseInt(client_id)
      }
      return apiCall(searchOptions)
    },
    [apiCall, searchOptions]
  )

  const pagedTable = usePagedTable<IOrder>({
    apiCall: memoApiCall,
  })
  const { reloadTable, setReloadTable } = React.useContext(CompanyOrderContext)
  React.useEffect(() => {
    // if reload=true, it means that we should reload orders data for paged table
    if (reloadTable) {
      pagedTable.reloadData()
    }
  }, [reloadTable, setReloadTable, pagedTable, orderSearchOptions])
  React.useEffect(() => {
    if (orderStatusChanged) {
      pagedTable.reloadData()
    }
  }, [orderStatusChanged])
  // console.log(router.history.location.state);

  // const getTypes = () => {
  //   const abc = new URLSearchParams(router.location.search).get('orderTypes')
  //   if (abc) {
  //     const tempArray: any = abc?.split(',').map(Number)
  //     const arr2: any = []

  //     // tempArray?.map((item: any) => {
  //     //   item = parseInt(item)
  //     //   arr2.push({ value: item, label: 'Order Type id#' + item })
  //     // })
  //     if (orderTypesFromApi.length) {
  //       for (let i = 0; i < tempArray.length; i++) {
  //         for (let j = 0; j < orderTypesFromApi.length; j++) {
  //           if (tempArray[i] === orderTypesFromApi[j].id) {
  //             arr2.push({ value: tempArray[i], label: orderTypesFromApi[j].name })
  //             break
  //           }
  //         }
  //       }
  //       console.log('matched', arr2)

  //       return arr2
  //     }
  //   } else return []
  // }
  // const getStatus = () => {
  //   const abc = new URLSearchParams(router.location.search).get('orderStatuses')
  //   if (abc) {
  //     const tempArray: any = abc?.split(',').map(Number)
  //     const arr2: any = []

  //     tempArray?.map((item: any) => {
  //       item = parseInt(item)
  //       arr2.push({ value: item, label: 'Order Status id#' + item })
  //     })

  //     return arr2
  //   } else return []
  // }

  //const [fromWidgets, setFromWidgets] = React.useState<any>(false)

  React.useEffect(() => {
    // console.log('def',defaultOrderTypes);
    const orderTypesQueryParams = new URLSearchParams(window.location.search).get('orderTypes')
    const fieldsarray: any = []
    const dataarray: any = []

    if (orderTypesQueryParams) {
      const tempArray: any = orderTypesQueryParams?.split(',').map(Number)
      const arr2: any = []

      // tempArray?.map((item: any) => {
      //   item = parseInt(item)
      //   arr2.push({ value: item, label: 'Order Type id#' + item })
      // })
      if (orderTypesFromApi.length) {
        for (let i = 0; i < tempArray.length; i++) {
          for (let j = 0; j < orderTypesFromApi.length; j++) {
            if (tempArray[i] === orderTypesFromApi[j].id) {
              arr2.push({ value: tempArray[i], label: orderTypesFromApi[j].name })
              break
            }
          }
        }
        // console.log('matched', arr2)

        // return arr2
      }
      setDefaultOrderTypes(arr2)
      //handleAddFilter('order_type_ids', arr2)
      fieldsarray.push('order_type_ids')
      dataarray.push(arr2)
    }
    const status = new URLSearchParams(window.location.search).get('orderStatuses')
    if (status) {
      const tempArray: any = status?.split(',').map(Number)
      const arr2: any = []

      // tempArray?.map((item: any) => {
      //   item = parseInt(item)
      //   arr2.push({ value: item, label: 'Order Status id#' + item })
      // })
      if (orderstatusFromApi.length) {
        for (let i = 0; i < tempArray.length; i++) {
          for (let j = 0; j < orderstatusFromApi.length; j++) {
            if (tempArray[i] === orderstatusFromApi[j].id) {
              arr2.push({ value: tempArray[i], label: orderstatusFromApi[j].name })
              break
            }
          }
        }
        // console.log('matchedstatus', arr2)

        // return arr2
      }
      setdefaultOrderStatus(arr2)
      // handleAddFilter('order_statuses', arr2)
      fieldsarray.push('order_statuses')
      dataarray.push(arr2)
    }
    handleAddFilters(fieldsarray, dataarray)
  }, [orderTypesFromApi, orderstatusFromApi])
  React.useEffect(() => {
    // setDefaultOrderTypes([{ value: 3, label: 'Business Formation' }])
    if (searchOptions.order_type_ids?.length) {
      const arr2: any = []
      const tempArray: any = searchOptions.order_type_ids.split(',').map(Number) //[...searchOptions.order_type_ids]
      // console.log('usds', tempArray)
      // tempArray?.map((item: any) => {
      //   item = parseInt(item)
      //   arr2.push({ value: item, label: 'Order Type id#' + item })
      // })
      if (searchOptions.order_type_ids?.length) {
        for (let i = 0; i < tempArray.length; i++) {
          for (let j = 0; j < orderTypesFromApi.length; j++) {
            if (parseInt(tempArray[i]) === orderTypesFromApi[j].id) {
              arr2.push({ value: parseInt(tempArray[i]), label: orderTypesFromApi[j].name })
              break
            }
          }
        }
      }
      setDefaultOrderTypes(arr2)
    } else {
      setDefaultOrderTypes([])
    }
    if (searchOptions.order_statuses?.length) {
      const arr2: any = []
      const tempArray: any = searchOptions.order_statuses.split(',').map(Number) //[...searchOptions.order_statuses]
      // console.log('usdsstatus', tempArray)
      // tempArray?.map((item: any) => {
      //   item = parseInt(item)
      //   arr2.push({ value: item, label: 'Order Type id#' + item })
      // })
      if (searchOptions.order_statuses?.length) {
        for (let i = 0; i < tempArray.length; i++) {
          for (let j = 0; j < orderstatusFromApi.length; j++) {
            if (parseInt(tempArray[i]) === orderstatusFromApi[j].id) {
              arr2.push({ value: parseInt(tempArray[i]), label: orderstatusFromApi[j].name })
              break
            }
          }
        }
      }
      setdefaultOrderStatus(arr2)
    } else {
      setdefaultOrderStatus([])
    }
  }, [searchOptions])
  const goToOrderDetailsPage = React.useCallback(
    (orderId: number) => {
      router.history.push(`/company-order/${orderId}`)
    },
    [router.history]
  )
  React.useEffect(() => {
    if (isCompanyCreationFlow) {
      pagedTable.reloadData()
    }
  }, [isCompanyCreationFlow])
  const table = () => {
    if (pagedTable.isLoading)
      return (
        <Table>
          <OrderTableHead
            sortBy={searchOptions['sort_by']}
            sortOrder={searchOptions['sort_order']}
            handleSortingClick={handleSortingClick}
            headColumns={headColumns}
          />
          <TableBody>{skeletons({ numberOfColumns: 7, fullWidth: true, numberOfRows: 10 })}</TableBody>
        </Table>
      )
    return (
      <PagedTable
        {...pagedTable.renderProps}
        renderRow={(rowData: IOrder) => (
          <OrderTableRow
            isCompanyCreationFlow={isCompanyCreationFlow}
            onEdit={goToOrderDetailsPage}
            key={rowData.id}
            reloadData={pagedTable.reloadData}
            order={rowData}
            searchQuery={searchOptions['query']}
          />
        )}
        header={
          <OrderTableHead
            disableCompanyNameAndSalesRep={isCompanyCreationFlow}
            sortBy={searchOptions['sort_by']}
            sortOrder={searchOptions['sort_order']}
            handleSortingClick={handleSortingClick}
            headColumns={isCompanyCreationFlow ? headColumnsWoCompanyNameAndSalesRep : headColumns}
          />
        }
        colSpan={8}
        emptyRowText="No Orders"
      />
    )
  }

  return (
    <div>
      {withSearchFilters && (
        <div className={classes.mainFiltersContainer}>
          <div className={classes.filterContainer}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Order Type:</Typography>
              <FilterSelect
                addAttribute={handleAddFilter}
                title="Type"
                fetchSuggestions={getOrderTypeSuggestions}
                field="order_type_ids"
                width="300px"
                async={true}
                placeholder="Select Types"
                defaultValue={defaultOrderTypes}
                value={defaultOrderTypes}
              />
            </div>
            <div className={classes.filterWithTitle} style={{ marginLeft: '1rem' }}>
              <Typography className={classes.filterTitle}>Order Status:</Typography>
              <FilterSelect
                addAttribute={handleAddFilter}
                title="Status"
                field="order_statuses"
                width="300px"
                async={true}
                placeholder="Select Statuses"
                defaultValue={defaultOrderStatus}
                value={defaultOrderStatus}
                fetchSuggestions={getOrderstatusSuggestions}
              />
            </div>
          </div>
          <div className={classes.innerSwitchButtonsContainer}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}></Typography>
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                onChange={handleRushOrderFilter}
                exclusive
                value={searchOptions.is_rush_order}
              >
                <ToggleButton value={true} color="secondary" className={classes.toggleButton}>
                  <Typography className={classes.toggleButtonText}>Rush</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className={classes.innerSwitchButtonsContainer}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}></Typography>
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                onChange={handleOpenStatusFilter}
                exclusive
                value={searchOptions.open}
              >
                <ToggleButton value={true} className={classes.toggleButton}>
                  <Typography className={classes.toggleButtonText}>Open </Typography>
                </ToggleButton>
                <ToggleButton value={false} className={classes.toggleButton}>
                  <Typography className={classes.toggleButtonText}>Archived </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className={classes.innerSwitchButtonsContainer}>
            <div className={classes.filterWithTitle}>
              <Typography className={classes.filterTitle}>Sales Rep Status:</Typography>
              <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                onChange={handleWithSalesRepsFilter}
                exclusive
                value={searchOptions.with_sales_reps}
              >
                <ToggleButton value="all" className={classes.toggleButton}>
                  <Typography className={classes.toggleButtonText}>All</Typography>
                </ToggleButton>
                <ToggleButton value="true" className={classes.toggleButton}>
                  <Typography className={classes.toggleButtonText}>With</Typography>
                </ToggleButton>
                <ToggleButton value="false" className={classes.toggleButton}>
                  <Typography className={classes.toggleButtonText}>Without</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      )}
      {!disableSearch && (
        <div className={classes.searchBarContainer}>
          <SearchBar
            onChange={handleSearch}
            placeholder="Search By Company Name Or Sales Rep Name"
            initialValue={searchOptions.query}
          />
        </div>
      )}
      <TableLoadingIndicator isLoading={pagedTable.isLoading} />
      {table()}
    </div>
  )
}

export default OrderTable
