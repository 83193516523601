import React from 'react'
import { ArrowBack, Close } from '@material-ui/icons'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 0,

      [theme.breakpoints.down('xs')]: {
        // paddingTop: '2rem',
        padding: 0,
      },

      '& .icon-wrapper': {
        background: '#eaecf0',
        padding: '0.5rem',
        borderRadius: '4px',
        marginRight: '1rem',
      },
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  onClose: () => void
  onBack?: () => void
}

const ModalHeader = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, onClose, onBack, ...other } = props
  return (
    <div className={classes.root} {...other} style={{ justifyContent: onBack ? '' : 'flex-end' }}>
      {onBack ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onBack}>
          <ArrowBack />
        </IconButton>
      ) : null}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </div>
  )
})

export default ModalHeader
