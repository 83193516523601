import { IClient } from './user'

export type NoteType = 'order' | 'company' | 'task' | 'client' | 'general' | 'prospect' | 'taxorder' | 'compliance'

export const NoteTypeList: NoteType[] = ['company', 'client', 'order', /*'task',*/ 'general', 'taxorder']

interface INoteBase {
  company_id?: number
  order_id?: number
  task_id?: number
  user_id?: number
  sales_rep_id?: number
  note_text: string
  tax_order_id?: number
}

export interface INote extends INoteBase {
  readonly id: number
  readonly last_update: string
  editable: boolean
  note_type: NoteType
  creator: IClient
}

export interface INewNote extends INoteBase {
  note_type?: NoteType
  company_id?: number
  order_id?: number
  client_id?: number
  tax_order_id?: number
}

export const emptyNote: INewNote = {
  company_id: undefined,
  order_id: undefined,
  task_id: undefined,
  user_id: undefined,
  sales_rep_id: undefined,
  note_text: '',
  note_type: undefined,
  tax_order_id: undefined,
}
