import {
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormControl,
  TextareaAutosize,
  Dialog,
  Button,
} from '@material-ui/core'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { IAddress } from 'models/address'
import React, { useEffect, useState } from 'react'
import InputField from '../inputField'
import ModalWrapper from '../modalWrapper'
import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import { Alert, Autocomplete } from '@material-ui/lab'
import {
  createClientProspect,
  createProspectItem,
  getIndustryType,
  getProspectStatuses,
  IndustryProspectData,
} from 'api/leadManager'
import showErrorNotification from 'utils/showErrorNotification'
import { useStyles } from '../styles'
import { ISuggestionAttribute } from 'components/interfaces'
import { fetchAssigneeSuggestions } from 'api/suggestions'
import showSuccessNotification from 'utils/showSuccessNotification'
import PhoneInput from 'components/clients/create/phoneInput'
import Dropdown from '../editProspectById/components/Dropdown'
import { AddOutlined, NoEncryption } from '@material-ui/icons'

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

interface AddProspectModalProps {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  setIsUpdated: any
}
const initialProspect = {
  firstName: '',
  middleName: '',
  lastName: '',
  title: '',
  phone: '',
  email: '',
  companyName: '',
  POC: '',
  submissionId: '',
  note: '',
}
const AddProspectModal = ({ open, setOpen, setIsUpdated }: AddProspectModalProps) => {
  const classes = useStyles()

  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [prospectAddress, setProspectAddress] = React.useState<IAddress[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const [isError, setIsError] = useState(false)

  const [prospectData, setProspectData] = useState(initialProspect)

  const [industryType, setIndustryType] = useState<any>()
  const [pocValue, setPocValue] = useState<any>()
  const [senderOptions, setSenderOptions] = useState<ISuggestionAttribute[]>([])
  const [prospectStatusList, setProspectStatusList] = React.useState<IndustryProspectData[]>([])
  const [statusValue, setStatusValue] = React.useState<number>(0)

  const [isReferralAdd, setIsReferralAdd] = useState(false)
  const [addPhoneFiled, setAddPhoneFiled] = useState(false)
  const [addEmailFiled, setAddEmailFiled] = useState(false)

  const { firstName, middleName, lastName, title, phone, email, companyName, submissionId, note } = prospectData

  const findSelectedAssigne = async () => {
    const data = await fetchAssigneeSuggestions('')
    // const selected = data?.find(item => item?.value === reminderData?.senderName)
    setSenderOptions(data)
    // setSelectedValve(selected)
  }

  useEffect(() => {
    if (
      firstName ||
      middleName ||
      lastName ||
      title ||
      phone ||
      email ||
      companyName ||
      submissionId ||
      pocValue ||
      industryType ||
      prospectAddress.length
    ) {
      return setIsError(false)
    }
  }, [
    companyName,
    email,
    firstName,
    industryType,
    lastName,
    phone,
    pocValue,
    prospectAddress.length,
    submissionId,
    title,
    middleName,
  ])

  const getProspectsList = async () => {
    try {
      const res = await getProspectStatuses()
      setProspectStatusList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  useEffect(() => {
    findSelectedAssigne()
    getProspectsList()
  }, [])

  const toggleAddAddressDialog = React.useCallback(() => {
    setCurrentEditedAddress(emptyAddress)
    setShowAddAddressDialog(!showAddAddressDialog)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
        city: currentEditedAddress?.cityObj?.label || '',
        city_id: currentEditedAddress?.cityObj?.value || 0,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )
  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    const indexToUpdate = prospectAddress && prospectAddress.findIndex(address => address.id === editedAddressId)
    //
    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(prospectAddress || [])]
    //

    // const newCurrentEditedAddress = {
    //   ...currentEditedAddress,
    //   city: currentEditedAddress?.cityObj?.label,
    //   city_id: currentEditedAddress?.cityObj?.value,
    // }
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setProspectAddress(addressesWithUpdatedAddress)
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    setCurrentEditedAddress(emptyAddress)
  }, [editedAddressId, prospectAddress, currentEditedAddress, addressesToEdit])

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((prospectAddress && prospectAddress.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [prospectAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setProspectAddress(prospectAddress.filter(address => address.id !== id))
    },
    [prospectAddress]
  )

  const handleAddAddress = React.useCallback(() => {
    //

    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      const newAddresses: IAddress[] = []
      // console.log('currentEditedAddress', currentEditedAddress)
      // const newCurrentEditedAddress = {
      //   ...currentEditedAddress,
      //   city: currentEditedAddress?.cityObj?.label,
      //   city_id: currentEditedAddress?.cityObj?.value,
      // }
      //
      newAddresses.push(currentEditedAddress)
      console.log('newAddresses', newAddresses)

      setProspectAddress(newAddresses)
      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd])
      toggleAddAddressDialog()
    }
    return true
  }, [addressesToAdd, currentEditedAddress, editedAddressId, toggleAddAddressDialog, updateAddress])

  const [industryTypesList, setIndustryTypesList] = useState<IndustryProspectData[]>([])

  const getIndustriesList = async () => {
    try {
      const res = await getIndustryType()
      setIndustryTypesList(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }
  useEffect(() => {
    getIndustriesList()
  }, [])

  const handleSave = async () => {
    if (
      !firstName ||
      !middleName ||
      !lastName ||
      !title ||
      !phone ||
      !email ||
      !companyName ||
      !submissionId ||
      !pocValue ||
      !industryType ||
      !prospectAddress.length
    ) {
      // return setIsError(true)
    }

    setIsError(false)

    setIsLoading(true)
    const payload = {
      addressData: prospectAddress,
      industry_type: industryType?.id,
      poc: pocValue,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      company_name: companyName,
      phone: phone,
      email: email,
      title: title,
      submission_id: submissionId,
      prospect_status: statusValue,
      note,
    }
    try {
      await createClientProspect(payload)
      setIsLoading(false)
      setOpen(false)
      showSuccessNotification('Prospect Successfully Created.')

      setIsReferralAdd(true)
      setAddPhoneFiled(false)
      setAddEmailFiled(false)

      setIsUpdated(true)
      setProspectAddress([])
      setIndustryType(null)
      setPocValue(undefined)
      setProspectData(initialProspect)
      setStatusValue(0)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
    setIsUpdated(false)
  }

  return (
    <React.Fragment>
      <ModalWrapper
        open={open}
        // setOpen={() => {
        //   setOpen
        //   setAddPhoneFiled(false)
        //   setAddEmailFiled(false)
        // }}
        setOpen={setOpen}
        heading="Add Referral"
      >
        <Grid container spacing={2}>
          <AddAddressDialog
            open={showAddAddressDialog}
            addressFields={currentEditedAddress}
            handleInputFieldsChange={onAddressFieldsChange}
            handleDialogClose={toggleAddAddressDialog}
            onAddressAdd={handleAddAddress}
            addressErrors={{
              country: false,
              city: false,
              street: false,
              postal_code: false,
            }}
            isEditing={editedAddressId !== undefined}
          />
          <Grid item xs={12} md={6}>
            <InputField
              label="First Name"
              value={firstName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, firstName: val }
                })
              }}
            />
          </Grid>
          {/* <Grid item  xs={12} md={6}>
            <InputField
              label="Middle Name"
              value={middleName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, middleName: val }
                })
              }}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <InputField
              label="Last Name"
              value={lastName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, lastName: val }
                })
              }}
            />
          </Grid>
          {/* <Grid item  xs={12} md={6}>
            <InputField
              label="Title"
              value={title}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, title: val }
                })
              }}
            />
          </Grid> */}

          <Grid item xs={12} md={6}>
            {addPhoneFiled ? (
              <PhoneInput
                value={phone || ''}
                onChange={val => {
                  setProspectData(prevState => {
                    return { ...prevState, phone: val }
                  })
                }}
              />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddOutlined />}
                onClick={() => setAddPhoneFiled(true)}
              >
                Add Phone
              </Button>
            )}

            {/* <InputField
              label="Phone"
              value={phone}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, phone: val }
                })
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            {addEmailFiled ? (
              <InputField
                label="Email"
                type="email"
                value={email}
                onChange={e => {
                  const val = e.target.value
                  setProspectData(prevState => {
                    return { ...prevState, email: val }
                  })
                }}
              />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddOutlined />}
                onClick={() => setAddEmailFiled(true)}
              >
                Add Email
              </Button>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <div className={classes.textBox}>
              <Addresses
                label="Address"
                customClass
                customClassName="address-box"
                onlyOneAddress={true}
                addresses={prospectAddress || []}
                onAddAddressClick={handleAddAddressClick}
                onAddressEditClick={handleAddressEditClick}
                onAddressDeleteClick={handleAddressDeleteClick}
              />
            </div>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <InputField
              label="Company Name (optional)"
              value={companyName}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, companyName: val }
                })
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <InputField label="Industry Type" /> */}
            <Autocomplete
              // options={industryTypesList?.map(type => type.name)}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={option => option.name}
              options={industryTypesList}
              className={classes.autocompleteField}
              freeSolo
              renderInput={params => (
                <TextField
                  {...params}
                  name="industryType"
                  label="Service Needed"
                  InputProps={{ ...params.InputProps }}
                  // onBlur={event => handleCreatorSelected(event.target.value)}
                />
              )}
              onChange={(_, value) => setIndustryType(value)}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>POC</InputLabel>
              <Select value={pocValue} onChange={e => setPocValue(e.target.value)} label="POC">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {senderOptions?.map(option => (
                  <MenuItem value={option?.value}>{option?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Submission ID"
              value={submissionId}
              onChange={e => {
                const val = e.target.value
                setProspectData(prevState => {
                  return { ...prevState, submissionId: val }
                })
              }}
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <Dropdown
              label="Status"
              options={prospectStatusList}
              placeholder="Select your status"
              val={statusValue}
              handleValueChange={(event: any) => setStatusValue(event.target.value)}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <TextField
                // style={{ width: '100%' }}
                // className={classes.textBox}
                multiline
                label="Tell us about your referral, what do they need help with?"
                value={note}
                onChange={e => {
                  const val = e.target.value
                  setProspectData(prevState => {
                    return { ...prevState, note: val }
                  })
                }}
                rows={5}
                variant="outlined"
                // minRows={5}
                className={classes.textArea}
              />{' '}
            </FormControl>
          </Grid>
          <br />
          {isError ? <Alert severity="error">Please fill all fields</Alert> : ''}
          <br />

          <div className={classes.footerBtn}>
            <p className="text">
              BusinessRocket will contact your referral within 1-2 Business days. Operational hours are from 9 AM - 5 PM
              (Pacific Standard Time) Monday through Friday, excluding holidays.
            </p>
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="text-template-btn"
              onClick={handleSave}
              isLoading={isLoading}
            >
              Save
            </ButtonWithLoading>
          </div>
        </Grid>
      </ModalWrapper>
    </React.Fragment>
  )
}

export default AddProspectModal
