import React, { useState } from 'react'
import { useStyles } from './style'
import { Dialog, Typography, CardContent, CardMedia, Card, Chip, Link } from '@material-ui/core'
import useUser from '../../hooks/useUser'
import EarnModal from './earnModal'
import ShareEarnModal from './shareEarnModal'
import { VerticalAlignTop } from '@material-ui/icons'
import ModalHeader from './modalHeader'
import bronzeMedal from '../../icons/bronzeMedal.svg'
import silverMedal from '../../icons/silverMedal.svg'
import goldMedal from '../../icons/goldMedal.svg'
import platinumMedal from '../../icons/platinumMedal.svg'

const CommisionCard = () => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [isEarnOpen, setIsEarnOpen] = useState(false)

  const { user } = useUser()

  const handleCheckCommission = (comissionPercent: number) => {
    switch (comissionPercent >= 0) {
      case comissionPercent >= 0 && comissionPercent <= 5:
        return { title: 'BRONZE', icon: bronzeMedal }
      case comissionPercent >= 6 && comissionPercent <= 10:
        return { title: 'Silver', icon: silverMedal }
      case comissionPercent >= 11 && comissionPercent <= 15:
        return { title: 'Gold', icon: goldMedal }
      case comissionPercent >= 16 && comissionPercent <= 20:
        return { title: 'Platinum', icon: platinumMedal }

      default:
        return { title: 'Platinum', icon: platinumMedal }
    }
  }

  return (
    <>
      <div className={classes.commissionContainer}>
        <img
          src={handleCheckCommission(user?.commission_percentage || 0)?.icon}
          alt="bronzeMedal"
          className="medal_icon"
        />
        <div className="commission-section">
          <p className="mobile_bronze_badge_text">
            {handleCheckCommission(user?.commission_percentage || 0)?.title} MEMBER
          </p>
          <p className="comission_text">${Math.round(user?.commission || 0)} in commissions</p>

          <span onClick={() => setIsOpen(true)}>
            <Link>Refer & Earn</Link>
          </span>
        </div>
      </div>

      {/*   <Dialog maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={classes.dialogContainer}>
          <h1 className={classes.mainHeading}>How to Share</h1>
          <div className={classes.iconBox}>
            <Link
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&quote=I%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20`}
            >
              <FacebookIcon className={classes.fb_icon} />
            </Link>
            <Link
              target="_blank"
              href={`https://twitter.com/intent/tweet?text=I%27ve+recently+opened+my+business+through+BusinessRocket%2C+they+are+a+one-stop+business+formation+service+company.+Highly+Recommended%21+Fast+Processing%2C+Best+Prices%2C+always+there+to+for+questions+about+your+new+Business+Tell+them+I+referred+you%21+%0A%0Ahttps%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}`}
            >
              <TwitterIcon className={classes.tw_icon} />
            </Link>
            <Link
              target="_blank"
              href={`mailto:?body=Hey%2C%20%0A%0AI%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20%0A%0Ahttps%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&subject=BusinessRocket%20Invitation`}
            >
              <MailIcon className={classes.ml_icon} />
            </Link>
            <Link target="_blank" href={`https://www.businessrocket.com/?ref=${user?.ref_code}`}>
              <LinkIcon className={classes.ch_icon} />
            </Link>
          </div>
          <div className={classes.content}>
            <h2>How do I share if I am not on social media or unable to send message or email?</h2>
            <p className={classes.para}>
              We have you covered! Simply direct your friends to BusinessRocket.com or have them call 888-700-8213 and
              make them mention <b>{user?.name}</b> when asked who referred them. We will manually add them to your
              account.
            </p> 
          </div>
        </div>
      </Dialog> */}

      <Dialog maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalHeader id="share-earn-modal" onClose={() => setIsOpen(false)} />
        <ShareEarnModal
          user={user}
          handler={() => {
            setIsEarnOpen(true)
            setIsOpen(false)
          }}
        />
      </Dialog>

      <Dialog maxWidth="sm" open={isEarnOpen} onClose={() => setIsEarnOpen(false)}>
        <ModalHeader
          id="earn-modal"
          onClose={() => setIsEarnOpen(false)}
          onBack={() => {
            setIsEarnOpen(false)
            setIsOpen(true)
          }}
        />
        <EarnModal user={user} />
      </Dialog>
    </>
  )
}

export default CommisionCard

// <Card className={classes.mainCardContainer}>
// <CardContent className={classes.mainContent}>
//   <CardMedia
//     image="https://cdn.zeplin.io/5cd18095e18bd568686a654d/assets/7ca5cc64-58d0-471e-8294-28ac36085fd1.svg"
//     title="Paella dish"
//     className={classes.commissionImg}
//   />
//   <div className={classes.rightContent}>
//     <Typography className={classes.heading}>Commission Earned</Typography>
//     <Chip className={classes.price} /*"price"*/ label={commission !== null ? `$${commission}` : '$0.00'} />
//   </div>
// </CardContent>
// </Card>
// <span onClick={() => setIsOpen(true)}>
// <Chip className={classes.refer} icon={<VerticalAlignTop className="icon" />} label="Refer & Earn" />
// </span>
