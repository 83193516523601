import { Grid } from '@material-ui/core'
import { getKpiDashboardOrderTraffic } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import DetailModal from '../kpiDetail/detailmodal'
import OfferDetail from '../OfferDetail'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'
import showErrorNotification from 'utils/showErrorNotification'

interface TrafficCardResponse {
  new_entities: number
  new_orders: number
  orders_completed: number
  percent_entities_one_plus_orders: number
  recent_percent_entities_one_plus_orders: number
  new_entities_ids: number[]
  new_order_ids: number[]
  orders_completed_ids: number[]
  profile_attempt: number
  profile_completed: number
  avg_profile_completed: number
  labels?: any
  totalForeignEinTasks: number
  foreignEinOrderIds: number[]
  totalForeignEinTasksCompleted: number
  foreignEinCompletedOrderIds: number[]

  totalIpostalTasks: number
  iPostalTasksOrderIds: number[]
  totaliPostalTasksCompleted: number
  iPostalCompletedOrderIds: number[]
  salesRepOrders?: number
  profile_attempt_ids?: number[]
  profile_completed_ids?: number[]
}
type TrafficIdVars = 'new_entities_ids' | 'new_order_ids' | 'orders_completed_ids'

const TrafficCard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<TrafficCardResponse>()
  const [ids, setIds] = useState<any[]>([])
  const [entityType, setEntityType] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardOrderTraffic(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])

  const handleModalOpen = (type: string, field: keyof TrafficCardResponse) => {
    setEntityType(type)
    if (filteredData) {
      // switch (field) {
      //   case 'new_entities_ids':
      //     setIds(filteredData?.new_entities_ids)
      //     break
      //   case 'new_order_ids':
      //     setIds(filteredData?.new_order_ids)
      //     break
      //   case 'orders_completed_ids':
      //     setIds(filteredData?.orders_completed_ids)
      //     break

      //   default:
      //     break
      // }
      const fieldIds = filteredData[`${field}`]
      if (!fieldIds?.length) {
        showErrorNotification('No data.')
        return
      }
      field && setIds(fieldIds)
      setModalOpen(true)
    }
  }
  return (
    <>
      <Grid className={classes.commonRow} container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <PrimaryHeading heading="Traffic" text={startData} />
            </Grid>
            <Grid item xs={10}>
              <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'new_order_ids')}
            >
              <SectionDetail primaryText="New Orders" heading={filteredData?.new_orders || 0} isLoading={isLoading} />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'new_entities_ids')}
            >
              <SectionDetail
                primaryText="New Entities"
                heading={filteredData?.new_entities || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'orders_completed_ids')}
            >
              <SectionDetail
                primaryText="Orders Completed"
                heading={filteredData?.orders_completed || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="% Entities w/ 1+ Order"
                heading={filteredData?.percent_entities_one_plus_orders || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="% Recent Entities w/ 1+ Order"
                heading={filteredData?.recent_percent_entities_one_plus_orders || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, 'profile_attempt_ids')}
            >
              <SectionDetail
                primaryText="Profile Attempt"
                heading={filteredData?.profile_attempt || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, 'profile_completed_ids')}
            >
              <SectionDetail
                primaryText="Profile complete"
                heading={filteredData?.profile_completed || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={1}>
              <SectionDetail
                primaryText="Profile Average"
                heading={filteredData?.avg_profile_completed || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'foreignEinOrderIds')}
            >
              <SectionDetail
                primaryText="Non Resident"
                heading={filteredData?.totalForeignEinTasks || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'foreignEinCompletedOrderIds')}
            >
              <SectionDetail
                primaryText="Completed Non Resident"
                heading={filteredData?.totalForeignEinTasksCompleted || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'iPostalTasksOrderIds')}
            >
              <SectionDetail
                primaryText="iPostal1 Orders"
                heading={filteredData?.totalIpostalTasks || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.order, 'iPostalCompletedOrderIds')}
            >
              <SectionDetail
                primaryText="Completed IPostal Address"
                heading={Math.round(filteredData?.totaliPostalTasksCompleted as number) || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="Wholesale Orders"
                heading={Math.round(filteredData?.salesRepOrders as number) || 0}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h2 className="offer-heading">Top Questions</h2>
          <Grid container spacing={3}>
            {filteredData?.labels?.length ? (
              filteredData.labels?.map((data: any, i: number) => (
                <Grid
                  item
                  xs={6}
                  key={i}
                  //   onClick={() => handleModalOpen(KPI_ENTITY_TYPES.company, data.companies)}
                >
                  <OfferDetail
                    count={data?.count?.toString() || ''}
                    description={data?.question || ''}
                    isLoading={isLoading}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <h3>No Data Found</h3>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {filteredData && <DetailModal open={modalOpen} setOpen={setModalOpen} ids={ids} type={entityType} />}
    </>
  )
}

export default TrafficCard
