import { Grid } from '@material-ui/core'
import { getKpiDashboardUserTraffic } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'

interface UserTrafficeCardResponse {
  average_days_to_login: number
  average_page_visits: number
  average_time_logged: number
}

const UserTrafficeCard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<UserTrafficeCardResponse>()

  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardUserTraffic(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])

  return (
    <Grid className={classes.commonRow} container spacing={0}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <PrimaryHeading heading="Traffic" text={startData} />
          </Grid>
          <Grid item xs={10}>
            <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <SectionDetail
              primaryText="Average Page Visits"
              heading={filteredData?.average_page_visits || 0}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={2}>
            <SectionDetail
              primaryText="Average Time Logged"
              heading={filteredData?.average_time_logged || 0}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={3}>
            <SectionDetail
              primaryText="Av.Days from File Date to Login Date"
              heading={filteredData?.average_days_to_login || 0}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserTrafficeCard
