import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: '#2a2a2a',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    text: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '1.39',
      color: '#2a2a2a',
    },
  })
)

interface ITaskDetailsElement {
  title: string
  text?: string
  className?: string
}

function TaskDetailsElement({ title, text, className }: ITaskDetailsElement) {
  const classes = useStyles()
  return (
    <div className={className}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.text}>{text || <br />}</Typography>
    </div>
  )
}

export default TaskDetailsElement
