import { Grid } from '@material-ui/core'
import { getKpiDashboardUsers } from 'api/kpiDashboard'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { KPI_ENTITY_TYPES } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import FilterDays from '../FilterDays'
import DetailModal from '../kpiDetail/detailmodal'
import PrimaryHeading from '../PrimaryHeading'
import SectionDetail from '../SectionDetail'
import { useStyles } from '../styles'

interface UsersCardResponse {
  new_users: number
  selected_special_offer: number
  user_logged: number
  user_logged_more_than_once: number
  new_users_prev: number
  user_logged_prev: number
  user_logged_more_than_once_prev: number
  selected_special_offer_prev: number
  new_users_ids: string[]
  user_logged_ids: string[]
  user_logged_more_than_once_ids: string[]
}
type UserIdVars = 'new_users_ids' | 'user_logged_ids' | 'user_logged_more_than_once_ids'
const UsersCard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const [startData, setStartData] = useState('')
  const currentData = moment().format('YYYY-MM-DD 23:59 Z')

  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const [filteredData, setFilteredData] = useState<UsersCardResponse>()
  const [ids, setIds] = useState<any[]>([])
  const [entityType, setEntityType] = useState<string>('')
  useEffect(() => {
    const data = moment()
      .subtract(tabValue, 'd')
      .format('YYYY-MM-DD 00:00 Z')
    setStartData(data)
  }, [startData, tabValue])

  useEffect(() => {
    if (startData) {
      const payload = {
        start_date: startData,
        end_date: currentData,
      }
      const getKpi = async () => {
        setIsLoading(true)
        try {
          const res = await getKpiDashboardUsers(payload)
          setFilteredData(res)
          setIsLoading(false)
        } catch (error) {
          showApiResponseError(error, 'Could not fetch data')
        }
        setIsLoading(false)
      }
      getKpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData])

  useEffect(() => {
    console.log('fileredData', filteredData?.user_logged_ids)
    filteredData && setIds(filteredData.user_logged_ids)
  }, [filteredData])
  const handleModalOpen = (type: string, field: UserIdVars) => {
    setEntityType(type)
    if (filteredData) {
      switch (field) {
        case 'new_users_ids':
          setIds(filteredData?.new_users_ids)
          break
        case 'user_logged_ids':
          setIds(filteredData?.user_logged_ids)
          break
        case 'user_logged_more_than_once_ids':
          setIds(filteredData?.user_logged_more_than_once_ids)
          break

        default:
          break
      }
      setModalOpen(true)
    }
  }
  return (
    <>
      <Grid className={classes.commonRow} container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <PrimaryHeading heading="USERS" text={startData} />
            </Grid>
            <Grid item xs={10}>
              <FilterDays tabValue={tabValue} setTabValue={setTabValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={2} onClick={() => handleModalOpen(KPI_ENTITY_TYPES.client, 'new_users_ids')}>
              <SectionDetail
                primaryText="New Users"
                heading={filteredData?.new_users || 0}
                isIcrement={filteredData && filteredData.new_users > filteredData.new_users_prev ? true : false}
                isDecrement={filteredData && filteredData.new_users < filteredData.new_users_prev ? true : false}
                secondaryText={filteredData?.new_users_prev || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2} onClick={() => handleModalOpen(KPI_ENTITY_TYPES.client, 'user_logged_ids')}>
              <SectionDetail
                primaryText="Users logged In"
                heading={filteredData?.user_logged || 0}
                isIcrement={filteredData && filteredData.user_logged > filteredData.user_logged_prev ? true : false}
                isDecrement={filteredData && filteredData.user_logged < filteredData.user_logged_prev ? true : false}
                secondaryText={filteredData?.user_logged_prev || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={2}
              onClick={() => handleModalOpen(KPI_ENTITY_TYPES.client, 'user_logged_more_than_once_ids')}
            >
              <SectionDetail
                primaryText="More Than Once"
                heading={filteredData?.user_logged_more_than_once || 0}
                isIcrement={
                  filteredData && filteredData.user_logged_more_than_once > filteredData.user_logged_more_than_once_prev
                    ? true
                    : false
                }
                isDecrement={
                  filteredData && filteredData.user_logged_more_than_once < filteredData.user_logged_more_than_once_prev
                    ? true
                    : false
                }
                secondaryText={filteredData?.user_logged_more_than_once_prev || 0}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={2}>
              <SectionDetail
                primaryText="Selected a special offer"
                heading={filteredData?.selected_special_offer || 0}
                isIcrement={
                  filteredData && filteredData.selected_special_offer > filteredData.selected_special_offer_prev
                    ? true
                    : false
                }
                isDecrement={
                  filteredData && filteredData.selected_special_offer < filteredData.selected_special_offer_prev
                    ? true
                    : false
                }
                secondaryText={filteredData?.selected_special_offer_prev || 0}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {filteredData && <DetailModal open={modalOpen} setOpen={setModalOpen} ids={ids} type={entityType} />}
    </>
  )
}

export default UsersCard
