// import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
// import * as React from 'react'

// const SubscriptionTableHead: React.FC = () => {
//   return (
//     <StyledTableHead>
//       <StyledTableRow>
//         <StyledTableCell>Company Name</StyledTableCell>
//         <StyledTableCell>Subscription Type</StyledTableCell>
//         <StyledTableCell>State Of Formation</StyledTableCell>
//         <StyledTableCell>Date Started</StyledTableCell>
//         <StyledTableCell>Last Billed</StyledTableCell>
//         <StyledTableCell>Next Bill</StyledTableCell>
//         <StyledTableCell>Amount</StyledTableCell>
//         <StyledTableCell>Status</StyledTableCell>
//         {/* <StyledTableCell>Edit </StyledTableCell> */}
//       </StyledTableRow>
//     </StyledTableHead>
//   )
// }

// export default SubscriptionTableHead

import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'company_name', label: 'Company Name', style: {}, sortable: false },
  { id: 'product_id', label: 'Subscription Type', style: {}, sortable: false },
  { id: 'state_of_formation', label: 'State', style: { maxWidth: '100px' }, sortable: false },
  { id: 'creation_time', label: 'Date Started', style: {}, sortable: true },
  { id: 'current_period_start', label: 'Last Billed', style: {}, sortable: true },
  { id: 'current_period_end', label: 'Next Bill', style: {}, sortable: true },
  { id: 'amount', label: 'Amount', style: {}, sortable: false },

  { id: 'status', label: 'Status', style: {}, sortable: false },
  { id: 'collected_revenue', label: 'Collected Revenue', style: {}, sortable: false },
  { id: 'action', label: 'Action', style: {}, sortable: false },
]

const SubscriptionTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default SubscriptionTableHead
