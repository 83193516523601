// import { Link } from '@material-ui/core'
import { Divider, Grid } from '@material-ui/core'
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography'
import { fetchAssigneeSuggestions } from 'api/suggestions'
import PrimaryButton from 'components/common/buttons/primaryButton'
import CheckboxLabel from 'components/common/checkboxLabel'
import DatePicker from 'components/common/datePicker'
import OutlinedSelect from 'components/common/outlinedSelect'
import { AsyncSelectField } from 'components/common/select'
import MoneyInputField from 'components/common/textFields/moneyInputField'
import { useAssigneeField } from 'components/orders/hooks/useAssigneeField'
import { IOrderPaymentStatus, IOrderStage } from 'models/order'
import { IClient, IUser } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { datePickerDateFormat } from 'utils/formatDate'
import showErrorNotification from 'utils/showErrorNotification'
import {
  fetchAffCommission,
  IOrderDetailsResponse,
  postPaymentReminder,
  reSendDetailsConfirmationEmail,
  sendReviewApi,
} from 'api/orders'
import { ValueOf } from 'utils/objectUtils'
import { orderTypeStatus, updateorderTypeStatus, getorderTypeStatus } from 'api/ordertype'
import useRouter from 'use-react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'
import { ORDERSTATUS, ORDERTYPES } from 'utils/constant'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { MenuItem, TextareaAutosize } from '@material-ui/core'
import ModalTitle from 'components/common/modalTitle'
import { useStyles as useStyles2 } from 'components/common/assetUpload/uploadModalStyle'
import Button from '@material-ui/core/Button'
import { Checkbox, ListItem } from '@material-ui/core'
import showApiResponseError from 'utils/showApiResponseError'
import showSuccessNotification from 'utils/showSuccessNotification'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import { nameCheckByStateId } from 'api/namechecklist'
import DocumentModal from './DocumentSendModal'
import WebSubmissionDetails from 'components/webSubmissions/webSubmissionDetails'
import { checkNullValue } from 'utils/functions'
import { Link } from 'react-router-dom'

export type IHumanReadablePaymentStatus = 'Paid' | 'Not Paid'

export const paymentStatusEnumToHumanReadable = (
  status?: IOrderPaymentStatus
): IHumanReadablePaymentStatus | undefined => {
  if (!status) {
    return
  }
  return {
    paid: 'Paid',
    not_paid: 'Not Paid',
  }[status] as IHumanReadablePaymentStatus
}
interface IOrderPageTitle {
  orderName: string
  orderId: number
  stage?: IOrderStage
  stagesOptions: IOrderStage[]
  handleStageSelected(stage?: IOrderStage): void
  assignee: IUser | null
  handleAssigneeSelected(assignee: IUser | null): void
  dueDate?: Date
  onDueDateSelected(dueDate: string): void
  client: IClient
  orderDetails: IOrderDetailsResponse
  onOrderDetailsFieldsChange: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => void
  isEditable?: boolean
  saveOrder(): void
  orderTypeList: any
  onDueDateOrderStatusSelected(dueDate: string): void
  dueDateOrderStatus?: Date
  reloadNoteTable(): void
  setOrderStatusChanged(state: boolean): void
  is_rush_order?: boolean
  saveOrderLoading?: boolean
  internal_rush_processing?: boolean
  expectedEINDate?: Date
  onExpectedEINDateSelected(expectedEINDate: string): void
}

const OrderPageTitle: React.FC<IOrderPageTitle> = props => {
  const classes = useStyles(props)
  const {
    orderName,
    orderId,
    stage,
    stagesOptions,
    handleStageSelected,
    assignee,
    handleAssigneeSelected,
    dueDate,
    dueDateOrderStatus,
    onDueDateSelected,
    onDueDateOrderStatusSelected,
    orderDetails,
    onOrderDetailsFieldsChange,
    isEditable = true,
    saveOrder,
    orderTypeList,
    reloadNoteTable,
    setOrderStatusChanged,
    is_rush_order,
    saveOrderLoading,
    internal_rush_processing,
    expectedEINDate,
    onExpectedEINDateSelected,
  } = props

  const dueDateValue = dueDate && moment(dueDate)
  const dueDateOrderStatuss = dueDateOrderStatus && moment(dueDateOrderStatus)
  const disabled = React.useMemo(() => !isEditable, [isEditable])
  const [orderType, setorderType] = React.useState(orderName)
  const [orderstatuses, setorderstatuses] = React.useState([])
  const [orderstatusesval, setorderstatusesval] = React.useState('')
  const [orderTypeid, setorderTypeid] = React.useState(0)
  const [orderStatusid, setorderStatusid] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [statusChanged, setstatusChanged] = React.useState(false)
  const [sendReview, setSendReview] = React.useState<boolean>(false)
  const [reviewloading, setReviewLoading] = React.useState<boolean>(false)
  const [detailsLoading, setDetailsLoading] = React.useState<boolean>(false)
  // const [namecheckloading, setnamecheckloading] = React.useState<boolean>(false)
  const [docPopUpOpen, setDocPopUpOpen] = React.useState(false)
  const [postpayementLoading, setPostpayementLoading] = React.useState(false)
  const [webSubmissionPopup, setWebSubmissionPopup] = React.useState(false)

  const router = useRouter<{ id: string }>()
  //console.log(router.match.params.id)
  const id: string = router.match.params.id

  const onStatusSelected = React.useCallback(
    (value: string) => {
      const selectedStage = stagesOptions.find(stage => stage.title === value)
      handleStageSelected(selectedStage)
    },
    [stagesOptions, handleStageSelected]
  )
  const onStatusChange = val => {
    setstatusChanged(true)
    setorderstatusesval(val)
    // orderstatuses.map((item: any, index) => {
    //   if (val === item.name) {
    //     setorderStatusid(item.id)
    //   }
    // })
  }
  React.useEffect(() => {
    orderstatuses.map((item: any, index) => {
      if (orderstatusesval === item.name) {
        setorderStatusid(item.id)
      }
    })
  }, [orderstatusesval, orderstatuses])
  React.useEffect(() => {
    const func = async () => {
      const data = await getorderTypeStatus(parseInt(id))
      setorderstatusesval(data.name)
    }
    if (parseInt(id) > 0) func()
  }, [id])
  React.useEffect(() => {
    //console.log('annnn', orderStatusid);
    const func = async () => {
      //setModal()
      try {
        //const data = await updateorderTypeStatus(parseInt(id), orderStatusid)
        orderDetails.order_status = orderStatusid
        //setorderstatuses(data)
        // showSuccessNotification('Status Changed SuccessFully')
        // reloadNoteTable()
        setOrderStatusChanged(true)
      } catch (e) {
        showApiResponseError(e, 'Error Updating Status')
      }
    }
    if (orderStatusid > 0 && statusChanged) func()
  }, [orderStatusid])
  const stages = stagesOptions.map(stage => stage.title)

  const { assigneeAsSuggestionAttribute, onAssigneeSelected } = useAssigneeField({
    assignee: assignee,
    handleAssigneeSelected: handleAssigneeSelected,
  })

  console.log('assigneeAsSuggestionAttribute', assigneeAsSuggestionAttribute)
  console.log('onAssigneeSelected', onAssigneeSelected)

  if (!props.client.id) {
    showErrorNotification('Error loading client details')
    throw new Error(`Client.id is nullish on order_id=${orderId}`)
  }

  React.useEffect(() => {
    const func = () => {
      orderTypeList.map((item, index) => {
        if (orderType === item.name) {
          setorderTypeid(item.id)
        }
      })
    }
    func()
  }, [orderType])
  React.useEffect(() => {
    const func = async () => {
      setLoading(true)
      const data = await orderTypeStatus(orderTypeid)
      setorderstatuses(data)
      setLoading(false)
    }
    if (orderTypeid > 0) func()
  }, [orderTypeid])
  React.useEffect(() => {
    //console.log(orderType, '-', orderName);
    setorderType(orderName)
  }, [orderName])

  React.useEffect(() => {
    if (
      statusChanged &&
      orderDetails.order_type_id === ORDERTYPES.businessFormation &&
      orderStatusid === ORDERSTATUS.Submitted_to_Secretary_of_State
    ) {
      setOpen(true)
      // setModal(orderStatusid);
    }
  }, [orderStatusid])
  const sendReviewRequest = async () => {
    setReviewLoading(true)
    const primaryContact: any[] = []
    const promiseA = new Promise((resfunc, rejfuc) => {
      if (orderDetails.company_details?.company_users) {
        orderDetails.company_details?.company_users.map(async item => {
          if (item.is_primary_contact) {
            primaryContact.push(item.user)
          }
        })
      }
      if (!primaryContact?.length) {
        primaryContact.push(orderDetails.company_details?.client)
      }
      console.log('primaryContact', primaryContact)
      resfunc(primaryContact)
    })

    promiseA.then(async primaryContactVal => {
      const res = await sendReviewApi(
        {
          data: primaryContactVal,
        },
        orderDetails.id
      )
      setReviewLoading(false)

      res.success
        ? showSuccessNotification('Review Request Sent!')
        : showApiResponseError(res.error, 'Error Sending Request')
      reloadNoteTable()
    })
  }
  const sendConfirmationEmail = async () => {
    try {
      setDetailsLoading(true)
      const res = await reSendDetailsConfirmationEmail(orderDetails)
      res.success && showSuccessNotification('Sent Successfully')
      reloadNoteTable()
      setDetailsLoading(false)
    } catch (e) {
      showApiResponseError(e, 'Error Sending Email')
      setDetailsLoading(false)
    }
  }

  // const checkName = async () => {
  //   try {
  //     setnamecheckloading(true)
  //     const res = await nameCheckByStateId(orderDetails.company_details?.state_of_formation_id || 0)
  //     if (res && res.name_check_url && res.name_check_url.length) {
  //       window.open(res.name_check_url, '_blank')
  //       setnamecheckloading(false)
  //     } else {
  //       showErrorNotification('No Url provided for this state')
  //       setnamecheckloading(false)
  //     }
  //     //showSuccessNotification('Sent Successfully')
  //   } catch (e) {
  //     showApiResponseError(e, 'Error: Something went wrong! ')
  //     setnamecheckloading(false)
  //   }
  // }
  const sendPostPayment = async () => {
    try {
      setPostpayementLoading(true)
      const res = await postPaymentReminder({ order_id: orderDetails.id })
      if (res.success) showSuccessNotification('Sent Successfully')
      setPostpayementLoading(false)
    } catch (error) {
      showErrorNotification('Error Sending Email')
      setPostpayementLoading(false)
    }
  }

  const basePath = window.location.origin

  const handleWebSubmission = (id: number) => {
    if (id) {
      router.history.push(`${basePath}/web-submission/${id}`)
    }
  }
  const [companyCommission, setCompanyCommission] = React.useState(0)
  const getCompanyCommission = async (company_id: number) => {
    const commissionPer = await fetchAffCommission(company_id)
    setCompanyCommission(commissionPer / 100)
    // await handleChangingPrice({ target: { value: companyOrderDetails.price } })
  }
  React.useEffect(() => {
    if (orderDetails && orderDetails.company) getCompanyCommission(orderDetails.company.id)
  }, [orderDetails.company])
  React.useEffect(() => {
    onOrderDetailsFieldsChange('commission', Number((companyCommission * orderDetails.price).toFixed(2)))
  }, [orderDetails.price, companyCommission])
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.orderNameContainer} variant="h1">
          {orderName} #{orderId} {loading && <CircularProgress />}
        </Typography>

        {/* <Typography className={classes.clientContainer} variant="h1">
          <Link href={`/client/${props.client.id}`}>{props.client.name}</Link>
        </Typography> */}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <ButtonWithLoading
            //style={{ width: '22%', fontSize: '12px' }}
            // wrapperClassName={classes.nameCheckBtn}
            isLoading={namecheckloading}
            disabled={disabled}
            onClick={checkName}
          >
            Name Check
          </ButtonWithLoading> */}
          <ButtonWithLoading
            // style={{ width: '30%', marginLeft: '3px', fontSize: '12px' }}
            // wrapperClassName={classes.resendConfirmationBtn}
            isLoading={detailsLoading}
            disabled={disabled}
            onClick={sendConfirmationEmail}
          >
            Resend Confirmation Email
          </ButtonWithLoading>
          <ButtonWithLoading
            //style={{ width: '25%', marginLeft: '3px', fontSize: '12px' }}
            wrapperClassName={classes.sendReviewButton}
            isLoading={reviewloading}
            // disabled={disabled}
            onClick={sendReviewRequest}
          >
            Send Review Request
          </ButtonWithLoading>
          {/* <ButtonWithLoading
          //style={{ width: '25%', marginLeft: '3px', fontSize: '12px' }}
          // wrapperClassName={classes.sendReviewButton}
          // isLoading={reviewloading}
          disabled={disabled}
          // onClick={sendReviewRequest}
        >
          Post Payment Reminder
        </ButtonWithLoading> */}
          <ButtonWithLoading
            //style={{ width: '25%', marginLeft: '3px', fontSize: '12px' }}
            // wrapperClassName={classes.sendReviewButton}
            // isLoading={reviewloading}
            // disabled={disabled}
            onClick={() => setDocPopUpOpen(true)}
          >
            Send Documents to Clients
          </ButtonWithLoading>
        </div>
      </div>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6}>
          {loading ? (
            <Typography variant="h1" component="div">
              <Skeleton height={70} />
            </Typography>
          ) : (
            <OutlinedSelect
              name="Order Status"
              value={orderstatusesval}
              options={orderstatuses.map((type: any) => {
                if (type.is_checked === true) return type.name
              })}
              onOptionSelect={(value: unknown) => onStatusChange(value as string)}
              textFieldStyles={{ backgroundColor: stage && stage.stage_color }}
              disabled={disabled}
            />
          )}
        </Grid>
        {/* {orderDetails.order_type_id === ORDERTYPES.businessFormation &&
          orderStatusid === ORDERSTATUS.Submitted_to_Secretary_of_State && (
            <Grid item xs={3}>
              <DatePicker
                className={classes.dueDateInput}
                onDateSelected={onDueDateOrderStatusSelected}
                label="Expected File Date"
                value={dueDateOrderStatuss}
                placeholder="Expected File Date"
                format={datePickerDateFormat}
              />
            </Grid>
          )} */}
        <Grid item xs={3}>
          <AsyncSelectField
            title="Assignee"
            fetchSuggestions={(query, _) => fetchAssigneeSuggestions(query)}
            field="assignee_id"
            onOptionSelected={onAssigneeSelected}
            value={assigneeAsSuggestionAttribute}
            key={assigneeAsSuggestionAttribute?.value}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            className={classes.dueDateInput}
            onDateSelected={onDueDateSelected}
            label="Due Date"
            value={dueDateValue}
            placeholder="Due Date"
            format={datePickerDateFormat}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={3}>
          <OutlinedSelect
            disabled={true}
            name="Order Type"
            value={orderType ? orderType : ''}
            options={orderTypeList.map((type: any) => {
              return type.name
            })}
            onOptionSelect={(value: unknown) => setorderType(value as string)}
            textFieldStyles={{ backgroundColor: stage && stage.stage_color }}
          />
        </Grid>
        <Grid item xs={3}>
          <OutlinedSelect
            name="Payment Status"
            value={orderDetails.payment_status || ''}
            options={['Paid', 'Unpaid']}
            onOptionSelect={(value: unknown) => onOrderDetailsFieldsChange('payment_status', value as string)}
            disabled={disabled}
            textFieldStyles={{ backgroundColor: stage && stage.stage_color }}
          />
          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-payment_status-native-simple">Payment Status</InputLabel>
            <Select
              native
              value={orderDetails.payment_status || ''}
              onChange={(value: unknown) => onOrderDetailsFieldsChange('payment_status', value)}
              label="Payment Status"
              inputProps={{
                name: 'payment_status',
                id: 'outlined-payment_status-native-simple',
              }}
            >
              <option value={'paid'}>Paid</option>
              <option value={'not_paid'}>Not Paid</option>
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item xs={3}>
          <MoneyInputField
            value={orderDetails.price !== null ? orderDetails.price.toString() : '0'}
            onChange={(key: string, value: string) => onOrderDetailsFieldsChange('price', value)}
            label="Price"
            adornment="$"
            field="price"
            float={true}
            disabled={disabled}
            classes={{ moneyTextField: classes.moneyTextField }}
          />
        </Grid>
        <Grid item xs={3}>
          <MoneyInputField
            value={orderDetails.commission !== null ? orderDetails.commission.toString() : '0'}
            onChange={(key: string, value: string) => onOrderDetailsFieldsChange('commission', value)}
            label="Commission"
            adornment="$"
            field="commission"
            float={true}
            disabled={disabled}
            classes={{ moneyTextField: classes.moneyTextField }}
          />
        </Grid>
        <Grid item xs={3}>
          <MoneyInputField
            value={orderDetails?.invoice_no !== null ? orderDetails?.invoice_no?.toString() : '0'}
            onChange={(key: string, value: string) => onOrderDetailsFieldsChange('invoice_no', value)}
            label="Invoice No"
            adornment="#"
            field="invoice_no"
            float={true}
            disabled={disabled}
            classes={{ moneyTextField: classes.moneyTextField }}
          />
        </Grid>
        <Grid item xs={3}>
          <div className={classes.dateFiled}>
            <DatePicker
              className={classes.dueDateInput}
              onDateSelected={onExpectedEINDateSelected}
              label="Expected EIN Date"
              value={expectedEINDate}
              placeholder="Expected EIN Date"
              format={datePickerDateFormat}
              disabled={disabled}
            />
          </div>
        </Grid>
      </Grid>

      <div className={classes.saveBtnSection}>
        <ListItem className={classes.rushOrderList}>
          <Checkbox
            color="secondary"
            checked={is_rush_order}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            disabled={disabled}
            value={is_rush_order}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => onOrderDetailsFieldsChange('is_rush_order', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>Rush Order</span>
        </ListItem>
        <ListItem>
          <Checkbox
            color="secondary"
            checked={internal_rush_processing}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            disabled={disabled}
            value={internal_rush_processing}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => onOrderDetailsFieldsChange('internal_rush_processing', e.target.checked)}
          />
          <span style={{ fontSize: '19px' }}>Internal Rush Order</span>
        </ListItem>
        {/* <ButtonWithLoading
          //style={{ width: '22%', fontSize: '12px' }}
          wrapperClassName={classes.nameCheckBtn}
          isLoading={namecheckloading}
          disabled={disabled}
          onClick={checkName}
        >
          Name Check
        </ButtonWithLoading>
        <ButtonWithLoading
          // style={{ width: '30%', marginLeft: '3px', fontSize: '12px' }}
          wrapperClassName={classes.resendConfirmationBtn}
          isLoading={detailsLoading}
          disabled={disabled}
          onClick={sendConfirmationEmail}
        >
          Resend Confirmation Email
        </ButtonWithLoading>
        <ButtonWithLoading
          //style={{ width: '25%', marginLeft: '3px', fontSize: '12px' }}
          wrapperClassName={classes.sendReviewButton}
          isLoading={reviewloading}
          disabled={disabled}
          onClick={sendReviewRequest}
        >
          Send Review Request
        </ButtonWithLoading> */}
        <ButtonWithLoading
          isLoading={saveOrderLoading}
          //style={{ width: '17%', marginLeft: '3px', fontSize: '12px' }}
          wrapperClassName={classes.saveButton}
          disabled={disabled}
          onClick={saveOrder}
        >
          Save Order
        </ButtonWithLoading>
        {/* </div> */}
      </div>
      <Divider />
      <br />
      {orderDetails.submission_id && (
        <div style={{ display: 'flex' }}>
          <ButtonWithLoading
            wrapperClassName={orderDetails.post_form_completion ? classes.completeBtn : ''}
            isLoading={postpayementLoading}
            disabled={disabled}
            onClick={sendPostPayment}
          >
            Post Payment Reminder
          </ButtonWithLoading>
          <Link
            to={`/web-submission/${orderDetails?.submission_id}`}
            target="_blank"
            rel="noreferrer"
            className={classes.submissionLink}
          >
            Submission Portal Info
          </Link>
          {/* <ButtonWithLoading
            style={{ marginLeft: '1rem' }}
            // wrapperClassName={classes.sendReviewButton}
            // isLoading={reviewloading}
            disabled={disabled}
            onClick={() => handleWebSubmission(orderDetails?.submission_id || 0)}
            // onClick={() => setWebSubmissionPopup(true)}
          >
            <Link to="/external-link" target="_blank" rel="noreferrer" style={styles.link}>
              Go to KindaCode.com
            </Link>
            Submission Portal Info
          </ButtonWithLoading> */}
        </div>
      )}
      <br />
      <DocumentModal open={docPopUpOpen} setOpen={setDocPopUpOpen} order_id={orderDetails.id} />
      <DocumentModal
        open={docPopUpOpen}
        setOpen={setDocPopUpOpen}
        order_id={orderDetails.id}
        reloadNoteTable={reloadNoteTable}
      />
      <Dialog open={webSubmissionPopup} fullWidth={true} maxWidth="lg" onClose={() => setWebSubmissionPopup(false)}>
        <br />
        <ModalTitle title={'Submission Portal info'} onClose={() => setWebSubmissionPopup(false)} />

        <WebSubmissionDetails submission_id={orderDetails.submission_id} />

        {/* <DialogActions className={classes2.dialogAction}>
        <ButtonWithLoading
          // disabled={selectedDocs.length ? false : true}
          // isLoading={submitLoading}
          // onClick={sendDocs}
        >
          Send Email{' '}
        </ButtonWithLoading>
      </DialogActions> */}
      </Dialog>
      <UploadModal
        open={open}
        saveOrder={saveOrder}
        setOpen={setOpen}
        dueDateValue={dueDateValue}
        onDueDateSelected={onDueDateSelected}
      />
      <Divider />
    </div>
  )
}

const UploadModal: React.FC<any> = ({ open, setOpen, onDueDateSelected, dueDateValue, saveOrder }) => {
  const classes = useStyles()
  const classes2 = useStyles2()
  // const [open, setOpen] = React.useState(isOpen)

  return (
    <Dialog open={open} className={classes2.dialogContainer}>
      <ModalTitle title={'Expected File Date'} toggleModal={setOpen} />
      <DialogContent>
        <DatePicker
          className={classes.dueDateInput}
          onDateSelected={onDueDateSelected}
          label="Due Date"
          value={dueDateValue}
          placeholder="Due Date"
          format={datePickerDateFormat}
        />
      </DialogContent>
      <DialogActions className={classes2.dialogAction}>
        <Button onClick={() => setOpen(false)} color="primary" variant="contained" className={classes2.uploadBtn}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrderPageTitle
