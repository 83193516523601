import {
  createStyles,
  FormControl,
  FormHelperText,
  makeStyles,
  Theme,
  TextField,
  Divider,
  Dialog,
  DialogContent,
} from '@material-ui/core'
import { deleteAddress, editAddress } from 'api/addresses'
//import { createClient, updateClient } from 'api/clients'
import { createClient, updateClient } from 'api/salesRep'
import showSuccessNotification from 'utils/showSuccessNotification'
import { checkUsername, resetPassword } from 'api/users'

import { fetchSuggestions } from 'api/suggestions'
import CommissionAccordion from 'components/clients/commissions/accordion'
import EinSsnInput from 'components/clients/create/einSsnInput'
import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'
import Addresses from 'components/common/addresses'
import AddAddressDialog from 'components/common/addresses/addAddressDialog'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import ImageUpload from 'components/common/textFields/ImageUpload'

import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient, ICreatedEmployee, IEmployee, ISalesRep, ICreatedSalesRep } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { validateEmail } from 'utils/validators'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { ISuggestionAttribute } from 'components/interfaces'
import SalesRepPricing from './salesRepPricing'
import ModalTitle from 'components/common/modalTitle'
import Client from 'pages/client'
import { FIELDS_MIN_LENGTH } from 'utils/constant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: ISalesRep = {
  extid: '',
  first_name: '',
  last_name: '',
  name: '',
  email_1: '',
  email_2: '',
  ref_code: '',
  //ssn_ein: undefined,
  //dob: undefined,
  sales_rep: undefined,
  //sales_rep_id: undefined,
  affiliate_id: undefined,
  affiliate: undefined,
  phone_1: undefined,
  phone_2: undefined,
  email: '',
  company_name: '',
  addresses: [],
  user_type: 'salesRep',
}
export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

export interface IClientDetailsProps {
  editedClient?: ISalesRep
  onRequestSuccessful: (client: ICreatedSalesRep) => void
}

export interface IClientDetailsState {
  client: ISalesRep
  errors: {
    first_name: boolean
    last_name: boolean
    name: boolean
    email: boolean
    phone_number: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

// Because date returned from backend, is in different format, that existing validator accepts,
// we format it properly first
const extractDateFromClient = (client: ISalesRep): string | undefined => {
  if (!client.dob) return undefined
  return backendDateStringToFrontendFormat(client.dob)
}

const validateName = (client: IClient) => {
  return !(!client.name || client.name.trim().length < 3)
}
const validatefName = (client: ISalesRep) => {
  return !(!client.first_name || client.first_name.trim().length < 3)
}
const validateAddress = (client: IClient): boolean => {
  const { addresses } = client
  if (addresses && addresses.length) {
    if (!addresses.find(address => address.is_primary === true)) return false
  }
  return true
}

const validateDob = (client: IClient) => {
  if (!client.dob) return true
  const selectedDate = dateFromString(client.dob)
  if (!selectedDate.isValid() || selectedDate.isAfter(moment())) {
    return false
  }
  return true
}

// Backend accepts addresses with `id` field and unprocessable entities
const addressesWithoutIds = (client: IClient): IAddress[] | undefined => {
  if (!client.addresses) return undefined
  return client.addresses.map(address => {
    delete address.id
    return {
      ...address,
      city: address?.cityObj?.label || '',
      city_id: address?.cityObj?.value || 0,
    }
  })
}

const updateEmployeeRequest = async (client: ISalesRep) => {
  const editedClient = client
  //
  const newAddress: IAddress[] | undefined = editedClient?.addresses?.map((item: IAddress) => {
    //
    if (item?.cityObj?.value) {
      return {
        ...item,
        city: item?.cityObj?.label || '',
        city_id: item?.cityObj?.value || 0,
      }
    } else {
      return item
    }
    // return {
    //   ...item,
    //   city: item?.cityObj?.label || '',
    //   city_id: item?.cityObj?.value || 0,
    // }
  })
  const {
    agreements,
    annual_report,
    ein,
    essentials,
    foreign_ein,
    ra_services,
    rush_processing,
    s_corp,
    starter,
    supreme,
  } = editedClient
  const resClient = await updateClient({
    // We pick certain fields from editedClient object, because client,
    // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
    // if we send them to PATCH `user/:id`
    first_name: editedClient.first_name,
    last_name: editedClient.last_name,

    extid: editedClient.extid,
    id: editedClient.id,
    name: editedClient.name,
    email_1: editedClient.email_1 || '',
    email_2: editedClient.email_2 || '',
    email: editedClient.email || '',
    phone_1: editedClient.phone_1 || '',
    phone_2: editedClient.phone_2 || '',
    // ssn_ein: editedClient.ssn_ein,
    // dob: editedClient.dob,
    // phone_number: editedClient.phone_number || '',
    user_type: editedClient.user_type,
    // addresses: editedClient.addresses,
    addresses: newAddress,

    ref_code: editedClient.ref_code,
    company_name: editedClient.company_name,
    username: editedClient.username,
    password: editedClient.password,
    agreements,
    annual_report,
    ein,
    essentials,
    foreign_ein,
    ra_services,
    rush_processing,
    s_corp,
    starter,
    supreme,
  })
  return resClient
}

const createEmployeeRequest = async (client: ISalesRep) => {
  const resClient = await createClient({ ...client, addresses: addressesWithoutIds(client) })
  return resClient
}

const addressesWithNewPrimary = (addresses: IAddress[], newPrimaryAddress: IAddress | null): IAddress[] =>
  addresses.map((address: IAddress) => {
    if (newPrimaryAddress && address.id === newPrimaryAddress.id) {
      address.is_primary = true
    }
    if (!newPrimaryAddress || (address.id !== newPrimaryAddress.id && address.is_primary)) {
      address.is_primary = false
    }
    return address
  })

const SalesRepDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE

  const [client, setClient] = React.useState<ISalesRep>(
    editedClient
      ? {
          ...editedClient,
          dob: extractDateFromClient(editedClient),
        }
      : newClient
  )

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email_1: '', ssn_ein: '' })

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [addressesToDelete, setAddressesToDelete] = React.useState<number[]>([])

  const { history } = useRouter()

  const addAttribute = React.useCallback(
    <K extends keyof Pick<IClient, 'sales_rep_id' | 'affiliate_id'>>(field: K, id: IClient[K]) => {
      setClient({
        ...client,
        [field]: id,
      })
    },
    [setClient, client]
  )

  const toggleAddAddressDialog = React.useCallback(() => {
    setShowAddAddressDialog(!showAddAddressDialog)
    setCurrentEditedAddress(emptyAddress)
  }, [setShowAddAddressDialog, showAddAddressDialog])

  const [usernameChanged, setUsernameChanged] = React.useState<boolean>(false)

  const changeClientField = React.useCallback(
    (field: keyof ISalesRep, value: ValueOf<ISalesRep>) => {
      field === 'username' ? setUsernameChanged(true) : setUsernameChanged(false)

      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const validateFields = React.useCallback((): boolean => {
    let isValid = true
    const tmpHelperTexts = helperTexts

    // if (!validateName(client)) {
    //   clientEditErrors.setError('name', true)
    //   isValid = false
    // }
    if (!validatefName(client)) {
      clientEditErrors.setError('first_name', true)
      isValid = false
    }
    // if (!client.email_1 || !validateEmail(client.email_1)) {
    //   clientEditErrors.setError('email', true)
    //   tmpHelperTexts.email_1 = 'Please Specify Email'
    //   isValid = false
    // }
    if (client.dob && client.dob !== '' && !validateDob(client)) {
      clientEditErrors.setError('dob', true)
      isValid = false
    }
    if (!validateAddress(client)) {
      clientEditErrors.setError('no_primary_address', true)
      isValid = false
    }
    if (client.password === null || client.password === '' || client.password === undefined) {
      // clientEditErrors.setError('password', true)
      // isValid = false
    }
    if (client.username === null || client.username === '' || client.username === undefined) {
      // clientEditErrors.setError('username', true)
      // isValid = false
    }
    setHelperTexts(tmpHelperTexts)
    return isValid
  }, [helperTexts, client, clientEditErrors])

  const sendAddressDeletionRequests = React.useCallback(() => {
    addressesToDelete.forEach(async addressId => {
      try {
        deleteAddress(addressId)
      } catch (err) {
        showApiResponseError(err, `Failed to delete address ${addressId}`)
      }
    })
  }, [addressesToDelete])

  const sendAddressUpdateRequests = React.useCallback(() => {
    addressesToEdit.forEach(async address => {
      console.log('address', address)

      try {
        const cleanedUpAddress: IAddress = removeNullAndUndefined(address) as IAddress
        await editAddress(cleanedUpAddress)
      } catch (err) {
        showApiResponseError(err, `Failed to update address ${address.id}`)
      }
    })
  }, [addressesToEdit])

  function getBase64Image(img) {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    //ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png')
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
  }

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    //by AY
    // var base64 = getBase64Image(document.getElementById("imageid"));
    // client.image= base64;
    if (client.username && client.username?.length < FIELDS_MIN_LENGTH.username) {
      return false
    }

    console.log('data=>', client)

    try {
      setLoading(true)
      setUsernameFieldDisabled(true)
      const res: any = await checkUsername({ username: client?.username })
      setLoading(false)
      setUsernameFieldDisabled(false)
      if (res.error && existingUsername !== client.username) {
        setErrorusername(true)
        setErrorusernameText('Sorry! username already exists!')

        return false
      } else {
        setErrorusername(false)

        try {
          let clientResponse: ICreatedSalesRep
          if (formMode === FormMode.UPDATE) {
            clientResponse = await updateEmployeeRequest(client)
          } else {
            clientResponse = await createEmployeeRequest(client)

            // When creating a new client, the attached addresses are without client ids.
            // This prevents us from sending a request to create a new address and link it with the client.
            const addressesToCreateWithClientIds = addressesToAdd
            addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
            setAddressesToAdd(addressesToCreateWithClientIds)
          }

          if (addressesToDelete.length) {
            sendAddressDeletionRequests()
          }
          // if (addressesToEdit.length) {
          //   sendAddressUpdateRequests()
          // }

          const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
          setClient(updatedClient)
        } catch (err) {
          const errorMessage: string | undefined = err?.response?.data?.message
          const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
          const tmpHelperTexts = {
            email_1: '',
            ssn_ein: '',
          }
          if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
            if (errorMessage.includes('email')) {
              clientEditErrors.setError('email', true)
              tmpHelperTexts.email_1 = 'This email is already in use'
            }
            if (errorMessage.includes('ssn_ein')) {
              clientEditErrors.setError('ssn_ein', true)
              tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
            }
            setHelperTexts(tmpHelperTexts)
            return false
          }
          showApiResponseError(err, 'Could not save changes')
          return false
        }
      }
    } catch (e) {
      showApiResponseError(e, 'Error Checking username')
    }

    if (!validateFields()) return false
    // try {
    //   let clientResponse: ICreatedSalesRep
    //   if (formMode === FormMode.UPDATE) {
    //     clientResponse = await updateEmployeeRequest(client)
    //   } else {
    //     clientResponse = await createEmployeeRequest(client)

    //     // When creating a new client, the attached addresses are without client ids.
    //     // This prevents us from sending a request to create a new address and link it with the client.
    //     const addressesToCreateWithClientIds = addressesToAdd
    //     addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
    //     setAddressesToAdd(addressesToCreateWithClientIds)
    //   }

    //   if (addressesToDelete.length) {
    //     sendAddressDeletionRequests()
    //   }
    //   // if (addressesToEdit.length) {
    //   //   sendAddressUpdateRequests()
    //   // }

    //   const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
    //   setClient(updatedClient)
    // } catch (err) {
    //   const errorMessage: string | undefined = err?.response?.data?.message
    //   const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
    //   const tmpHelperTexts = {
    //     email_1: '',
    //     ssn_ein: '',
    //   }
    //   if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
    //     if (errorMessage.includes('email')) {
    //       clientEditErrors.setError('email', true)
    //       tmpHelperTexts.email_1 = 'This email is already in use'
    //     }
    //     if (errorMessage.includes('ssn_ein')) {
    //       clientEditErrors.setError('ssn_ein', true)
    //       tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
    //     }
    //     setHelperTexts(tmpHelperTexts)
    //     return false
    //   }
    //   showApiResponseError(err, 'Could not save changes')
    //   return false
    // }
    return true
  }, [
    validateFields,
    formMode,
    addressesToDelete.length,
    addressesToEdit.length,
    client,
    addressesToAdd,
    sendAddressDeletionRequests,
    sendAddressUpdateRequests,
    clientEditErrors,
  ])

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedSalesRep = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const removeAddressFieldsErrors = React.useCallback((key: AddressError) => addressEditErrors.setError(key, false), [
    addressEditErrors,
  ])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      removeAddressFieldsErrors(key as AddressError)
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
        city: currentEditedAddress?.cityObj?.label || '',
        city_id: currentEditedAddress?.cityObj?.value || 0,
      })
    },
    [removeAddressFieldsErrors, setCurrentEditedAddress, currentEditedAddress]
  )

  // We cannot really use `useMemo()` here, because it will cause error messages to appear once
  // we open addressEditionDialog. The reason for that is, that once we open it, fields are empty.
  // Empty fields are not valid for a request, but are OK for initial state of dialog or any other moment,
  // while editing these fields. Address errors must be set if necessary only when submit button is clicked,
  // `useCallback()` fits this purpose. Using `useMemo()` causes errors to be set immediately,
  // and to be set always, when a field is empty.
  const validateNewAddressFields = React.useCallback(() => {
    // const { country, city, street, postal_code } = currentEditedAddress
    console.log('currentEditedAddress sales', currentEditedAddress)

    const { country, street, postal_code, locality, city } = currentEditedAddress

    // const fieldsToValidate = { country, city, street, postal_code }
    const fieldsToValidate = { country, street, postal_code, city, locality }

    addressEditErrors.setError('country', country.length === 0)
    addressEditErrors.setError('city', city.length === 0)
    addressEditErrors.setError('locality', locality.length === 0)
    addressEditErrors.setError('street', street.length === 0)
    addressEditErrors.setError('postal_code', postal_code.length === 0)
    return !Object.values(fieldsToValidate).some(string => string.length === 0)
  }, [currentEditedAddress, addressEditErrors])

  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    //
    const { addresses } = client
    const indexToUpdate = addresses && addresses.findIndex(address => address.id === editedAddressId)
    // checks for zero inequality
    // checks for zero inequality
    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(client.addresses || [])]
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setClient({
      ...client,
      addresses: addressesWithUpdatedAddress,
    })
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    //
    setCurrentEditedAddress(emptyAddress)
    toggleAddAddressDialog()
  }, [editedAddressId, client, currentEditedAddress, addressesToEdit, toggleAddAddressDialog])

  const handleAddAddress = React.useCallback(() => {
    //

    if (!validateNewAddressFields()) return false
    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      // in case new address is a primary address, reset the current primary address
      const newAddresses: IAddress[] = client.addresses ? deepcopy(client.addresses) : []
      if (currentEditedAddress.is_primary && client.addresses) {
        const primaryAddress = newAddresses.find(address => address.is_primary)
        if (primaryAddress) {
          primaryAddress.is_primary = false
        }
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
      }
      currentEditedAddress.id = client.addresses && client.addresses.length ? client.addresses.length * -1 : 0
      newAddresses.push(currentEditedAddress)
      setClient({ ...client, addresses: newAddresses })

      currentEditedAddress.client_id = client.id

      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd, currentEditedAddress])
      toggleAddAddressDialog()
    }
    return true
  }, [
    validateNewAddressFields,
    editedAddressId,
    updateAddress,
    client,
    currentEditedAddress,
    addressesToAdd,
    toggleAddAddressDialog,
    clientEditErrors,
  ])

  const handlePrimaryAddressChange = React.useCallback(
    (address: IAddress) => (checked: boolean) => {
      if (checked) {
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
        const addresses = addressesWithNewPrimary(client.addresses || [], address)
        setClient({ ...client, addresses: addresses })
      }
    },
    [clientEditErrors, client]
  )

  const handlePrimaryAddressChecked = React.useCallback(
    (checked: boolean) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        is_primary: checked,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setClient({ ...client, addresses: client.addresses && client.addresses.filter(address => address.id !== id) })
      if (id > 0) setAddressesToDelete([...addressesToDelete, id])
    },
    [setClient, setAddressesToDelete, addressesToDelete, client]
  )

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((client.addresses && client.addresses.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [setEditedAddressId, setCurrentEditedAddress, setShowAddAddressDialog, client.addresses]
  )

  // Here we navigate to companies page and pass this client object there.
  // If that component will find this object on `router.history.location.state`,
  // company creation flow dialog will be opened automatically with prefilled client field.
  const createCompany = React.useCallback(() => {
    history.push('/companies', { client })
  }, [client, history])

  const navigateToAssociateCompanies = React.useCallback(() => {
    history.push(`/companies?query=${encodeURI(client.name)}`)
  }, [client.name, history])

  const classes = useStyles()

  const { country, city, street, postal_code, locality } = addressEditErrors

  const salesRepresentative: ISuggestionAttribute | undefined = useMemo(
    () =>
      editedClient?.sales_rep?.id
        ? { label: editedClient.sales_rep.name, value: editedClient.sales_rep.id }
        : undefined,
    [editedClient]
  )

  const affiliate: ISuggestionAttribute | undefined = useMemo(
    () =>
      editedClient?.affiliate?.id
        ? { label: editedClient.affiliate.name, value: editedClient.affiliate.id }
        : undefined,
    [editedClient]
  )
  // React.useEffect(() => {
  //   //const tempMail: string = client.email.toString()
  //   if (!editedClient) {
  //     if (client.email_1?.length >= 5) {
  //       changeClientField('username', client.first_name + Math.floor(100000 + Math.random() * 900000))
  //     } else {
  //       changeClientField('username', '')
  //     }
  //   }
  // }, [client.email_1])
  const resetPass = async () => {
    try {
      const result = await resetPassword({ username: client.username })
      if (result.success) showSuccessNotification(`Password reset Email sent at ${client.email_1}`)
      else if (result.error) showApiResponseError(result.error, `Invalid Email, ${client.email_1} Doesn't exists`)
    } catch (err) {
      showApiResponseError(err.message, `Couldn't Send send Email!`)
    }
  }
  const [open, setOpen] = React.useState(false)
  const [errorUsername, setErrorusername] = React.useState<boolean>(false)
  const [errorUsernameText, setErrorusernameText] = React.useState<string>('')
  const [usernameFieldDisabled, setUsernameFieldDisabled] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [existingUsername, setExisingUsername] = React.useState<string>(editedClient?.username || '')

  const userNameCheck = async (username: string) => {
    try {
      setLoading(true)
      setUsernameFieldDisabled(true)
      const res: any = await checkUsername({ username: username })
      setLoading(false)
      setUsernameFieldDisabled(false)
      if (res.error && existingUsername !== client.username) {
        setErrorusername(true)
        setErrorusernameText('Sorry! username already exists!')
      } else {
        setErrorusername(false)
      }
    } catch (e) {
      showApiResponseError(e, 'Error Checking username')
    }
  }

  React.useEffect(() => {
    // const userNameCheck = async () => {
    //   try {
    //     setLoading(true)
    //     setUsernameFieldDisabled(true)
    //     const res: any = await checkUsername({ username: client.username })
    //     setLoading(false)
    //     setUsernameFieldDisabled(false)
    //     if (res.error && existingUsername !== client.username) {
    //       setErrorusername(true)
    //       setErrorusernameText('Sorry! username already exists!')
    //     } else {
    //       setErrorusername(false)
    //     }
    //   } catch (e) {
    //     showApiResponseError(e, 'Error Checking username')
    //   }
    // }
    const reg = /^[A-Za-z0-9]*$/

    // client.username &&
    //   client.username?.length >= FIELDS_MIN_LENGTH.username &&
    //   // reg.test(client.username) &&
    //   usernameChanged &&
    //   userNameCheck()
    // debugger
    if (client.username) {
      if (reg.test(client.username)) {
        if (client.username && client.username?.length < FIELDS_MIN_LENGTH.username) {
          setErrorusername(true)
          setErrorusernameText(`Username cannot be less than ${FIELDS_MIN_LENGTH.username} characters`)
        } else {
          setErrorusername(false)
          setErrorusernameText('')
        }
        // } else if (
        //   client.username &&
        //   client.username?.length >= FIELDS_MIN_LENGTH.username &&
        //   // reg.test(client.username) &&
        //   usernameChanged
        // )
        //   setTimeout(() => {
        //     userNameCheck()
        //   }, 1000)
      } else {
        setErrorusername(true)
        setErrorusernameText(`Username should contain Alpha numeric Only`)
      }
    }
  }, [client.username])

  const onBlurCapture = () => {
    if (client.username && client.username?.length > FIELDS_MIN_LENGTH.username) {
      userNameCheck(client.username || '')
    }
  }

  return (
    <React.Fragment>
      <AddAddressDialog
        open={showAddAddressDialog}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressFieldsChange}
        handleDialogClose={toggleAddAddressDialog}
        onAddressAdd={handleAddAddress}
        addressErrors={{
          country,
          city,
          street,
          postal_code,
          locality,
        }}
        onPrimaryAddressChecked={handlePrimaryAddressChecked}
        isEditing={editedAddressId !== undefined}
        showPrimaryAddressCheckbox
      />
      <div className={classes.formContainer}>
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.first_name}
          helperText={clientEditErrors.first_name && 'Please Specify First Name Of The Employee'}
          label="First Name"
          style={{ marginBottom: clientEditErrors.first_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.first_name}
          onChange={event => changeClientField('first_name', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.last_name}
          helperText={clientEditErrors.last_name && 'Please Specify Last Name Of The Employee'}
          label="Last Name"
          style={{ marginBottom: clientEditErrors.last_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.last_name}
          onChange={event => changeClientField('last_name', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="company-name"
          error={clientEditErrors.last_name}
          //helperText={clientEditErrors.last_name && 'Please Specify Last Name Of The Employee'}
          label="Company Name"
          style={{ marginBottom: clientEditErrors.last_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="company-name"
          margin="normal"
          variant="outlined"
          value={client.company_name}
          onChange={event => changeClientField('company_name', event.target.value)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          error={clientEditErrors.email_1}
          helperText={helperTexts.email_1}
          label="Email1"
          className={classes.textField}
          style={{ marginBottom: clientEditErrors.email_1 ? '1.5rem' : undefined }}
          type="text"
          name="email_1"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.email_1}
          onChange={event => changeClientField('email_1', normalizeText(event.target.value))}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          //error={clientEditErrors.email2}
          //helperText={helperTexts.email2}
          label="Email2"
          className={classes.textField}
          style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
          type="text"
          name="email2"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.email_2}
          onChange={event => changeClientField('email_2', normalizeText(event.target.value))}
        />
        <FormControl className={classes.fullWidth}>
          <PhoneInput
            showCopyButton={!!editedClient}
            value={client.phone_1 || ''}
            onChange={value => changeClientField('phone_1', value)}
            error={clientEditErrors['phone_number']}
          />
          {clientEditErrors['phone_number'] && (
            <FormHelperText className={classes.errorHelperText}>Please enter phone number</FormHelperText>
          )}
        </FormControl>
        <FormControl className={classes.fullWidth}>
          <PhoneInput
            showCopyButton={!!editedClient}
            value={client.phone_2 || ''}
            onChange={value => changeClientField('phone_2', value)}
            error={clientEditErrors['phone_number']}
          />
          {clientEditErrors['phone_number'] && (
            <FormHelperText className={classes.errorHelperText}>Please enter Second phone number</FormHelperText>
          )}
        </FormControl>
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.meetinglink}
          helperText={clientEditErrors.meetinglink && 'Please Specify Ref Code'}
          label="Reference Code"
          style={{ marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.ref_code}
          onChange={event => changeClientField('ref_code', event.target.value)}
        />

        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          disabled={usernameFieldDisabled}
          showCopyButton={!!editedClient}
          required
          data-testid="client-username"
          error={errorUsername}
          helperText={errorUsername && errorUsernameText}
          label="User Name"
          style={{ marginBottom: clientEditErrors.username ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.username}
          onChange={event => changeClientField('username', event.target.value)}
          onBlur={onBlurCapture}
        />

        <br />

        <Addresses
          label="Address Details"
          onPrimaryAddressChange={handlePrimaryAddressChange}
          onAddAddressClick={handleAddAddressClick}
          addresses={client.addresses || []}
          onAddressEditClick={handleAddressEditClick}
          onAddressDeleteClick={handleAddressDeleteClick}
          noPrimaryAddressError={clientEditErrors.no_primary_address}
        />
        <br />
        <Divider style={{ width: '100%' }}></Divider>

        <SalesRepPricing onChange={changeClientField} salesRep={client} showCopyButton={!!editedClient} />
        <SubmitFormButton
          //onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add Sales Representative'}
          onClick={onSubmitClick}
        />
        {editedClient && (
          <>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={resetPass}>
              Reset Password{' '}
            </ButtonWithLoading>
            <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={() => setOpen(true)}>
              Show Affiliate Clients{' '}
            </ButtonWithLoading>
          </>
        )}
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        //className={classes.dialogContainer}
        fullWidth={true}
      >
        <br />
        <ModalTitle title={'Affiliate Clients'} toggleModal={setOpen} />
        <DialogContent
        // className={'classes.root'}
        >
          <Client sales_rep_id={client.id} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default SalesRepDetails
