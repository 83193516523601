import LinearProgress from '@material-ui/core/LinearProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    rootLinearProgress: {
      height: 10,
      backgroundColor: 'rgba(243,120,80,0.5)',
    },
    barColorPrimaryLinearProgress: {
      backgroundColor: '#f47950',
    },
    barDailyColor: {
      backgroundColor: '#f47950',
    },
    barWeekColor: {
      backgroundColor: '#a3e362',
    },
    barCallBackColor: {
      backgroundColor: '#3c9fdf',
    },
  })
)
interface ITableLoadingIndicatorProps {
  isLoading: boolean
  background?: string
}

function TableLoadingIndicator({ isLoading, background }: ITableLoadingIndicatorProps) {
  const classes = useStyles()

  const changeBg = (type?: string) => {
    if (type === 'daily') {
      return classes.barDailyColor
    }
    if (type === 'week') {
      return classes.barWeekColor
    }
    if (type === 'callBack') {
      return classes.barCallBackColor
    } else return classes.barColorPrimaryLinearProgress
  }

  return (
    <LinearProgress
      variant={isLoading ? 'indeterminate' : 'determinate'}
      value={100}
      classes={{
        root: classes.rootLinearProgress,
        barColorPrimary:
          // background === 'daily'
          //   ? classes.barDailyColor
          //   : background === 'week'
          //   ? classes.barWeekColor
          //   : background === 'callBack'
          //   ? classes.barCallBackColor
          //   : classes.barColorPrimaryLinearProgress,
          changeBg(background),
      }}
    />
  )
}

export default TableLoadingIndicator
