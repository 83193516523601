import { apiClient } from '@jetkit/react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { refreshApiErrorMsg } from 'utils/constant'
import showApiResponseError from 'utils/showApiResponseError'
import AppEntryPoint from './AppEntryPoint'
import './index.css'
import * as serviceWorker from './serviceWorker'

if (!process.env.REACT_APP_CI_ON) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  })
}

apiClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    /**
     * We want API errors to be captured with Sentry.
     *
     * We also want to show a notification every time an error happens.
     */

    if (typeof error === 'string' && error?.includes(refreshApiErrorMsg)) {
      window.location.reload()
    } else {
      showApiResponseError(error, 'Error: Something Went Wrong')
      Sentry.captureException(error)
    }

    // console.log('Errrr', Sentry.captureException(error))

    return Promise.reject(error)
  }
)

if (module.hot) {
  module.hot.accept()
}

ReactDOM.render(<AppEntryPoint />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
