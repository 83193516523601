import { Link } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { useStyles } from './style'

interface HeadingHeaderProps {
  title: string
  linkText?: string
  secondaryComponent?: ReactNode
  isLink?: boolean
  linkTo?: string
}

const HeadingHeader = ({ title, linkText, isLink, secondaryComponent, linkTo }: HeadingHeaderProps) => {
  const classes = useStyles()

  return (
    <div className={classes.headingHeader}>
      <h1 className={classes.primaryHeading}>{title}</h1>
      {isLink ? (
        <Link href={linkTo} className={classes.linkText}>
          {linkText}
        </Link>
      ) : (
        secondaryComponent
      )}
    </div>
  )
}

HeadingHeader.defaultProps = {
  isLink: true,
  linkText: '',
}

export default HeadingHeader
