import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ModalTitle from 'components/common/modalTitle'
import { getCompanyOrderDocumentsWithoutPagination, sendDocsToClient } from 'api/orders'
import showApiResponseError from 'utils/showApiResponseError'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { getSubmissionHistory, ISubmissionHistory } from 'api/leadManager'
import showErrorNotification from 'utils/showErrorNotification'
import { LinearProgress, Typography } from '@material-ui/core'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import moment from 'moment'
import { stepMappings } from 'utils/stepmappings'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '100%',
      },
      //   width: '25%',
    },
    root: {
      overflow: 'hidden',
      paddingTop: '2rem',
      '& .MuiTextField-root': {
        marginBottom: '1rem',
        width: '100%',
      },
      '& .MuiPaper-root ': {
        padding: '1rem',
        width: '93%',
      },
      padding: theme.spacing(2),
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'center',
    },

    uploadBtn: {
      color: '#fff',
      width: '200px',
      height: '50px',
      fontWeight: 'bold',
      textTransform: 'none',
      backgroundColor: '#3c9fdf',
    },
    durationText: {
      color: 'grey',
      fontSize: '12px',
    },
  })
)
const SubmissionHistory: React.FC<any> = ({ open, setOpen, submission_id }) => {
  const classes2 = useStyles()
  const [history, setHistory] = React.useState<ISubmissionHistory[]>([])
  const [selectedDocs, setSelectedDocs] = React.useState<number[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const getDocs = async () => {
    if (!submission_id) {
      showErrorNotification('No Submision Id')
      return
    }
    try {
      setLoading(true)
      const res = await getSubmissionHistory(submission_id)
      setHistory(res)
      setLoading(false)
    } catch (error) {
      showApiResponseError(error, 'Error')
      setLoading(false)
    }
  }
  React.useEffect(() => {
    if (open) getDocs()
  }, [open])

  const calculateTimeDiff = (nextid, prevId) => {
    const startObj = history.filter((item, i) => {
      return i === prevId
    })
    const endObj = history.filter((item, i) => {
      return i === nextid
    })
    const startDate: any = startObj[0].dateTime
    const endDate: any = endObj[0].dateTime
    const milliseconds = moment(endDate || '').diff(moment(startDate || '')) //+ ', minutes'
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60
    return `After ${hours} hours, ${minutes} minutes, ${seconds} seconds `
  }

  return (
    <Dialog open={open} maxWidth="md" className={classes2.dialogContainer} fullWidth={true}>
      <ModalTitle title={'Submission History'} toggleModal={setOpen} />
      <DialogContent className={'classes2.root'}>
        {loading && <LinearProgress></LinearProgress>}
        <Timeline align="alternate">
          {history.map((item, i) => (
            <TimelineItem key={i}>
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography color={'primary'}> {stepMappings(item?.title)}</Typography>
                <i className={classes2.durationText}>{i > 0 && calculateTimeDiff(i, i - 1)}</i>
                <Typography color={'textPrimary'} style={{ marginTop: '1%' }}>
                  {formatDateTimeToHumanFriendly(item.dateTime || '')}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
      <DialogActions className={classes2.dialogAction}></DialogActions>
    </Dialog>
  )
}

export default SubmissionHistory
