import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'code', label: 'Code', style: { width: '30%' }, sortable: true },
  { id: 'expiry', label: 'Expiry', style: {}, sortable: true },
  { id: 'type', label: 'Type', style: {}, sortable: true },
  { id: 'value', label: 'value', style: {}, sortable: true },
  { id: 'times_used', label: 'No. of Use', style: {}, sortable: true },
  { id: 'max_usage', label: 'Max Usage', style: {}, sortable: true },
  { id: 'actions', label: 'actions', style: {}, sortable: false },
]

const PromoCodeTableHead: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default PromoCodeTableHead
