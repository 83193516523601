/* eslint-disable complexity */
import { useStyles } from '../styles'
import React, { useEffect, useState } from 'react'
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Chip,
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextareaAutosize,
  TextField,
  ListItem,
} from '@material-ui/core'
import { FiberManualRecord, Telegram, Close, Copyright, FileCopy } from '@material-ui/icons'
import Dropdown from 'components/leadManagerElements/editProspectById/components/Dropdown'
import { KeyboardDatePicker } from '@material-ui/pickers'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import InputField from 'components/leadManagerElements/inputField'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import SubscriptionTransactionTable from '../subscriptionTransactionTable'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

import moment, { Moment } from 'moment'
import {
  cardRequest,
  createCompanySubscription,
  deleteSubscription,
  fetchSubscription,
  fetchSubscriptionProduct,
  fetchSubscriptionsBySubscriptionID,
  getPastDueInvoices,
  getSubcriptionLogs,
  ICreateProductReq,
  ILogsResponse,
  IPastInvoiceResponse,
  IProductResponse,
  IStripePrice,
  pastDueRequest,
  payPostDueInvoices,
  updateCompanySubscription,
  updateSubscriptionStatus,
} from 'api/subscription'
import showSuccessNotification from 'utils/showSuccessNotification'
import showApiResponseError from 'utils/showApiResponseError'
import LinearProgress from '@material-ui/core/LinearProgress'
import { STRIPE_SUBSCRIPTION_STATUSES, SUBSCRIPTION_STATUS_MAPPING, SubscriptionType } from 'utils/constant'
import { stripLeadingSlash } from 'history/PathUtils'
import copyToClipboard from 'utils/copyToClipboard'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import showErrorNotification from 'utils/showErrorNotification'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import { toTitleCase } from '@jetkit/react'

interface SubscriptionModalProps {
  isEdit?: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  company_id: number
  subscription_id: number
  reloadloadDetails?: () => void
  setSubId?: any
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isEdit,
  setIsOpen,
  company_id,
  subscription_id,
  reloadloadDetails,
  setSubId,
}: SubscriptionModalProps) => {
  const classes = useStyles()
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault()

  const [selectedDate, setSelectedDate] = React.useState<any>()
  const [alignment, setAlignment] = React.useState('active')
  const [subType, setSubType] = React.useState('')
  const [subPrice, setSubPrice] = React.useState<number | string>(0)
  const [isTrial, setIsTrial] = React.useState(false)
  const [isOffline, setIsOffline] = React.useState(false)
  const [trialDays, setTrialDays] = React.useState('')
  const [paidDate, setPaidDate] = React.useState<Moment>()

  const [paymentMethod, setPaymentMethod] = React.useState<string>('stripe')

  const [subscriptions, setSubscriptions] = React.useState([])
  const [subscriptionPrices, setSubscriptionPrices] = React.useState([])
  const [subscriptionDetail, setSubscriptionDetail] = React.useState<any>()

  const [isLoading, setIsLoading] = React.useState(false)
  const [active, setActive] = React.useState(true)
  const [sendccLoading, setsendccLoading] = React.useState(false)
  const [sendPastDueLoading, setsendPastDueLoading] = React.useState(false)
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = React.useState(false)
  const [isGettingSubLoading, setIsGettingSubLoading] = React.useState(false)

  const [resumeDate, setResumeDate] = React.useState<Moment>()
  const [isResume, setIsResume] = React.useState(false)

  const [isConfrim, setIsConfrim] = React.useState(false)
  const [btnStatus, setBtnStatus] = React.useState('')

  const [subscriptionLogs, setSubscriptionLogs] = React.useState<ILogsResponse[]>([])

  const [isModalopen, setIsModalOpen] = useState<boolean>(false)
  const [isPaidDateModalopen, setIsPaidDateModalOpen] = useState<boolean>(false)
  const [isCancelModalopen, setIsCancelModalOpen] = useState<boolean>(false)

  const [pastInvDetails, setPastInvDetails] = React.useState<IPastInvoiceResponse[]>([])
  const [invIds, setInvIds] = useState<any>([])
  const [payLoader, setPayLoader] = useState<boolean>(false)
  const [isDueLoading, setIsDueLoading] = useState<boolean>(false)

  const [paidThroughReason, setPaidThroughReason] = React.useState('')
  const [cancelReason, setCancelReason] = React.useState('')

  const [isDefaultPaymentMedthod, setIsDefaultPaymentMedthod] = React.useState(true)

  // const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
  //   setAlignment(newAlignment)
  //   newAlignment === 'active' ? setActive(true) : setActive(false)
  // }

  console.log('subscription_id', subscription_id)

  console.log('resumeDate', resumeDate)

  React.useEffect(() => {
    const getDetailBtSubscriptionID = async (id: number) => {
      setIsGettingSubLoading(true)

      try {
        const result = await fetchSubscriptionsBySubscriptionID(id)
        setSubscriptionDetail(result)
        setTrialDays(result?.trial_period)
        setIsTrial(result?.trial_period && result?.trial_period > 0 ? true : false)
        // setIsOffline(result?.subscription_type === 'offline' ? true : false)
        setPaymentMethod(result?.subscription_type)
        setPaidDate(result?.paid_through_date || moment().add(1, 'days'))

        if (result.active === true) {
          setActive(true)
          setAlignment('active')
        } else {
          setActive(false)
          setAlignment('inActive')
        }
        // setSubType(result?.product?.id)
        await handleSubscriptionsChange({ target: { value: result?.product?.id } })
        setSubPrice(result?.price?.id)
        // setSelectedDate(moment.unix(result?.price?.current_period_end).format('MM/DD/yyyy'))
        setIsGettingSubLoading(false)

        console.log('fetchSubscriptionsBySubscriptionID', result)
      } catch (error) {
        console.log('error', error)
      }

      setIsGettingSubLoading(false)
    }
    if (subscription_id) {
      console.log(subscription_id, ':SUBSCIRPTIONID')
      getDetailBtSubscriptionID(subscription_id)
    }
  }, [subscription_id])

  React.useEffect(() => {
    const getSubscriptionProduct = async () => {
      try {
        const result: IProductResponse[] = await fetchSubscriptionProduct()
        const finalResult: any = result?.map((item: IProductResponse) => {
          return {
            value: item?.id,
            name: item?.name,
          }
        })
        setSubscriptions(finalResult)
      } catch (error) {
        console.log('error', error)
      }
    }
    getSubscriptionProduct()
  }, [])

  const handleSubscriptionsChange = async (e: any) => {
    setSubType(e.target.value)

    if (e.target.value) {
      try {
        const result: ICreateProductReq = await fetchSubscription(e.target.value)
        console.log('result', result)
        const finalResult: any = result?.pricing?.map((item: IStripePrice) => {
          return {
            value: item?.id,
            name: `${item?.name} - $${item?.amount}  ${item.billing_period ? '/' + item?.billing_period : ''}`,
          }
        })
        setSubscriptionPrices(finalResult)
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  console.log('paymentMethodpaymentMethodpaymentMethod', paymentMethod)

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  const handleSaveChange = async () => {
    setIsLoading(true)
    if (!isEdit) {
      debugger

      const payload = {
        trial_period: isTrial ? Number(trialDays) : 0,
        price_id: subPrice as number,
        company_id: company_id,
        // subscription_type: isOffline ? 'offline' : 'online',
        subscription_type: paymentMethod,
        paid_through_date: SubscriptionType?.quickbook === paymentMethod ? paidDate?.format('MM/DD/YYYY') : '',
        existing_card: SubscriptionType?.stripe === paymentMethod ? isDefaultPaymentMedthod : false,
      }
      try {
        await createCompanySubscription(payload)
        setTrialDays('')
        setIsLoading(false)
      } catch (error) {
        console.log('error', error)
      }
      setIsOpen(false)
      setIsLoading(false)
    } else {
      debugger

      try {
        const payload = {
          id: subscriptionDetail.id || 0,
          active: active,
          price_id: subPrice as number,
          resumes_at: paidThroughReason?.length
            ? paidDate
              ? moment(paidDate)?.unix()
              : moment()
                  .add(1, 'days')
                  .unix()
            : undefined,
          // resumes_at: paidDate?.unix() ? paidDate?.unix() : moment(subscriptionDetail?.paid_through_date)?.unix(),
          // resumes_at: resumeDate?.unix() ? resumeDate?.unix() : moment(subscriptionDetail?.resumes_at)?.unix(),
          subscription_type: paymentMethod,
          paid_through_date: paidThroughReason?.length
            ? paymentMethod === SubscriptionType?.quickbook
              ? paidDate?.format('MM/DD/YYYY')
              : moment().format('MM/DD/YYYY')
            : '',
          paid_through_reason: paidThroughReason,
        }
        await updateCompanySubscription(payload)
      } catch (error) {
        debugger
        console.log('error', error)
      }
      debugger

      setIsOpen(false)
      setIsLoading(false)
      debugger
    }
    reloadloadDetails && (await reloadloadDetails())
  }

  const handlePaidThough = async () => {
    setIsLoading(true)
    debugger

    try {
      const payload = {
        id: subscriptionDetail.id || 0,
        active: active,
        price_id: subPrice as number,
        resumes_at: paidDate
          ? moment(paidDate)?.unix()
          : moment()
              .add(1, 'days')
              .unix(),
        // resumes_at: paidDate?.unix() ? paidDate?.unix() : moment(subscriptionDetail?.paid_through_date)?.unix(),
        // resumes_at: resumeDate?.unix() ? resumeDate?.unix() : moment(subscriptionDetail?.resumes_at)?.unix(),
        subscription_type: paymentMethod,
        paid_through_date:
          paymentMethod === SubscriptionType?.quickbook
            ? paidDate?.format('MM/DD/YYYY')
            : moment().format('MM/DD/YYYY'),
        paid_through_reason: paidThroughReason,
      }

      debugger

      await updateCompanySubscription(payload)
    } catch (error) {
      debugger
      console.log('error', error)
    }
    debugger

    setIsPaidDateModalOpen(false)
    setIsOpen(false)
    setIsLoading(false)

    reloadloadDetails && (await reloadloadDetails())
  }

  console.log('paidDatepaidDatepaidDate', paidDate)

  const handleCardRequest = async () => {
    setsendccLoading(true)

    const payload = {}
    try {
      const res = await cardRequest(subscriptionDetail.id, payload)
      if (res.success) {
        showSuccessNotification(res.success)
      } else showApiResponseError(res.error, 'Error Sending Request.')
      setsendccLoading(false)
    } catch (error) {
      console.log('error', error)
      showApiResponseError(error, 'Error Sendding Request.')
    }
    setIsOpen(false)
    setsendccLoading(false)
  }

  const handlePastDueRequest = async () => {
    setsendPastDueLoading(true)

    const payload = {}
    try {
      const res = await pastDueRequest(subscriptionDetail.id, payload)
      if (res.success) {
        showSuccessNotification(res.success)
      } else showApiResponseError(res.error, res.error)
      setsendPastDueLoading(false)
    } catch (error) {
      console.log('error', error)
      showApiResponseError(error, 'Error Sending Request.')
    }
    setIsOpen(false)
    setsendPastDueLoading(false)
  }
  const handleSubscription = async () => {
    setCancelSubscriptionLoading(true)
    const status =
      subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled
        ? STRIPE_SUBSCRIPTION_STATUSES.active
        : STRIPE_SUBSCRIPTION_STATUSES.canceled
    const payload = { status }
    try {
      const res = subscriptionDetail?.subscription_id
        ? await updateSubscriptionStatus(subscriptionDetail.id, payload)
        : await deleteSubscription(subscriptionDetail.id)
      if (res.success) {
        showSuccessNotification(res.success)
        reloadloadDetails && (await reloadloadDetails())
        setSubId('')
      } else showApiResponseError(res.error, res.error)
      setCancelSubscriptionLoading(false)
    } catch (error) {
      console.log('error', error)
      showApiResponseError(error, 'Error Sending Request.')
    }
    setIsOpen(false)
    setCancelSubscriptionLoading(false)
    setIsConfrim(false)
  }

  const handleCancleORDeleteSubscription = async () => {
    setCancelSubscriptionLoading(true)
    const status =
      subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled
        ? STRIPE_SUBSCRIPTION_STATUSES.active
        : STRIPE_SUBSCRIPTION_STATUSES.canceled
    const payload = { status, cancel_reason: cancelReason }
    try {
      const res =
        btnStatus === 'cancle'
          ? await updateSubscriptionStatus(subscriptionDetail.id, payload)
          : await deleteSubscription(subscriptionDetail.id)
      if (res.success) {
        showSuccessNotification(res.success)
        reloadloadDetails && (await reloadloadDetails())
        setSubId('')
      } else showApiResponseError(res.error, res.error)
      setCancelSubscriptionLoading(false)
    } catch (error) {
      console.log('error', error)
      showApiResponseError(error, 'Error Sending Request.')
    }
    setIsOpen(false)
    setCancelSubscriptionLoading(false)
    setIsConfrim(false)
  }

  const getSubscriptionLogsDetail = async (id: number) => {
    try {
      const result = await getSubcriptionLogs(id)
      setSubscriptionLogs(result)
    } catch (error) {
      showErrorNotification(error)
    }
  }
  React.useEffect(() => {
    if (subscriptionDetail?.id) {
      getSubscriptionLogsDetail(subscriptionDetail?.id)
    }
  }, [subscriptionDetail?.id])

  const getPastInvoicesDetails = async (id: number) => {
    setIsDueLoading(true)
    try {
      const result = await getPastDueInvoices(id)
      setPastInvDetails(result)
    } catch (error) {
      showErrorNotification(error)
    }
    setIsDueLoading(false)
  }
  React.useEffect(() => {
    if (subscriptionDetail?.id) {
      getPastInvoicesDetails(subscriptionDetail?.id)
    }
  }, [subscriptionDetail?.id, isModalopen])

  const toggleInvoiceId = (id: string) => {
    if (invIds.includes(id)) {
      const updatedInvIds = invIds.filter(item => item !== id)
      setInvIds(updatedInvIds)
    } else {
      setInvIds([...invIds, id])
    }
  }

  const payPastInvoices = async () => {
    setPayLoader(true)
    const payload = {
      invoice_ids: invIds,
    }
    try {
      const data = await payPostDueInvoices(payload)
      if (data.error) {
        showErrorNotification(data.error)
      }
      setPayLoader(false)
      setIsModalOpen(false)
    } catch (error) {
      showErrorNotification(error)
    }
    setPayLoader(false)
  }

  console.log('subscriptions', subscriptions)
  console.log('subscriptionPrices', subscriptionPrices)

  console.log(invIds, 'invIdsinvIdsinvIdsinvIds')

  console.log('subType', subType)
  console.log('subPrice', subPrice)

  console.log('subscriptionDetail?.subscription_id', subscriptionDetail?.subscription_id)
  console.log('subscriptionLogs', subscriptionLogs)

  return (
    <Grid container spacing={3} className={classes.SubscriptionModalContainer}>
      <Grid item xs={8}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            {isEdit ? (
              <InputField label="Types of Subscriptions" disabled={true} value={subscriptionDetail?.product?.name} />
            ) : (
              <Dropdown
                label="Types of Subscriptions"
                options={subscriptions}
                placeholder="Types of Subscriptions"
                val={subType}
                handleValueChange={handleSubscriptionsChange}
              />
            )}
          </Grid>
          {isEdit && (subscriptionDetail?.subscription_id || SubscriptionType?.quickbook === paymentMethod) ? (
            <Grid item xs={4} className="align-end">
              <Chip
                clickable={false}
                deleteIcon={<FiberManualRecord />}
                onDelete={preventDefault}
                label={
                  // subscriptionDetail.paused
                  //   ? SUBSCRIPTION_STATUS_MAPPING[STRIPE_SUBSCRIPTION_STATUSES.paused]
                  //   : SUBSCRIPTION_STATUS_MAPPING[subscriptionDetail?.status || STRIPE_SUBSCRIPTION_STATUSES.incomplete]
                  SUBSCRIPTION_STATUS_MAPPING[subscriptionDetail?.status || STRIPE_SUBSCRIPTION_STATUSES.incomplete]
                }
                className="sub-chip"
                color="primary"
              />
            </Grid>
          ) : (
            ''
          )}
          {/*  */}
          <Grid item xs={8}>
            {isEdit && !subscriptionDetail?.active ? (
              <InputField
                label="Rate"
                disabled={true}
                value={`$${subscriptionDetail?.price?.amount || ''} / ${subscriptionDetail?.price?.billing_period ||
                  ''}`}
              />
            ) : (
              <Dropdown
                label="Rate"
                options={subscriptionPrices}
                placeholder="Rate"
                val={subPrice}
                handleValueChange={e => setSubPrice(e.target.value)}
                disabled={subscriptionDetail?.allocated_address?.length ? true : false}
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Dropdown
              label="Payment Method"
              options={[
                { name: 'Stripe', value: 'stripe' },
                { name: 'Quick Book', value: 'quickbook' },
              ]}
              placeholder="Payment Method"
              val={paymentMethod}
              handleValueChange={e => setPaymentMethod(e.target.value)}
              disabled={
                subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled
                  ? true
                  : false || isGettingSubLoading
              }
            />
          </Grid>
          <Grid item xs={4} />
          {/* <Grid item xs={6} className="align-end">
            <FormControlLabel
              className="check-box"
              control={
                <Checkbox
                  checked={subscriptionDetail?.subscription_type === 'offline' ? true : isOffline}
                  onChange={() => setIsOffline(!isOffline)}
                  name="trial"
                  color="primary"
                  disabled={isEdit}
                />
              }
              label="Offline Paid"
            />
          </Grid> */}
          {/* {(isOffline || (isEdit && subscriptionDetail?.paid_through_date)) && ( */}
          {/* {(SubscriptionType?.stripe !== paymentMethod || (isEdit && subscriptionDetail?.paid_through_date)) && ( */}
          {SubscriptionType?.stripe !== paymentMethod && (
            <Grid item xs={8} className="align-end">
              <KeyboardDatePicker
                className="date-field"
                fullWidth
                margin="normal"
                label="Paid Through Date"
                format="MM/DD/yyyy"
                // value={paidDate ? paidDate : subscriptionDetail?.paid_through_date}
                value={paidDate}
                onChange={e => setPaidDate(e as Moment)}
                inputVariant="filled"
                autoOk
                disablePast
                // disabled={subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false}
                disabled={subscriptionDetail?.id ? true : false}
              />
            </Grid>
          )}
          {SubscriptionType?.stripe === paymentMethod ? (
            <>
              {!isEdit ? (
                <Grid item xs={2} className="align-end">
                  <FormControlLabel
                    className="check-box"
                    control={
                      <Checkbox
                        checked={isTrial}
                        onChange={() => setIsTrial(!isTrial)}
                        name="trial"
                        color="primary"
                        disabled={isEdit}
                      />
                    }
                    label="Trial"
                  />
                </Grid>
              ) : (
                ''
              )}

              {isTrial && (
                <Grid item xs={3} className="align-end">
                  <InputField
                    label="No. of Days"
                    type="number"
                    disabled={isEdit}
                    value={trialDays}
                    onChange={e => setTrialDays(e.target.value)}
                  />
                </Grid>
              )}

              {/* <Grid item xs={4}>
                  <Dropdown
                    label="Trial Period"
                    options={trialPeriodArr}
                    placeholder="Trial Period"
                    val={7}
                    handleValueChange={preventDefault}
                  />
                </Grid> */}

              {isEdit ? (
                subscriptionDetail?.start_date && (
                  <Grid item xs={6}>
                    <p className={classes.smallText}>Starting time</p>
                    <p className="starting-text">
                      {/* Starting time
                      <br /> */}
                      {moment
                        .unix(subscriptionDetail?.start_date || 0)
                        .format('dddd, MMMM Do YYYY, h:mm:ss a')
                        .toString()}
                    </p>
                  </Grid>
                )
              ) : (
                <Grid item xs={6}>
                  <p className={classes.smallText}>Starting time</p>
                  <p className="starting-text">
                    {/* Starting time
                    <br /> */}
                    {moment()
                      .add('days', Number(trialDays))
                      .format('dddd, MMMM Do YYYY, h:mm:ss a')
                      .toString()}
                  </p>
                </Grid>
              )}

              {/* <Grid item xs={4} className="align-end">
                {isEdit ? (
                  <p className="starting-text">
                    Starting time
                    <br />
                    {subscriptionDetail?.start_date &&
                      moment
                        .unix(subscriptionDetail?.start_date || 0)
                        .format('dddd, MMMM Do YYYY, h:mm:ss a')
                        .toString()}
                  </p>
                ) : (
                  <p className="starting-text">
                    Starting time
                    <br />
                    {moment()
                      .add('days', Number(trialDays))
                      .format('dddd, MMMM Do YYYY, h:mm:ss a')
                      .toString()}
                  </p>
                )}
              </Grid> */}
            </>
          ) : (
            ''
            // <Grid item xs={8} className="align-end">
            //   <KeyboardDatePicker
            //     className="date-field"
            //     fullWidth
            //     margin="normal"
            //     label="Paid Through Date"
            //     format="MM/DD/yyyy"
            //     value={paidDate ? paidDate : subscriptionDetail?.paid_through_date}
            //     onChange={e => setPaidDate(e as Moment)}
            //     inputVariant="filled"
            //     autoOk
            //     disablePast
            //   />
            // </Grid>
          )}
          {/*  */}

          {isEdit &&
          subscriptionDetail?.subscription_type === SubscriptionType?.stripe &&
          subscriptionDetail?.current_period_end ? (
            <>
              <Grid item xs={8}>
                {/* <KeyboardDatePicker
                  className="date-field"
                  fullWidth
                  margin="normal"
                  label="Next Billing Date"
                  format="MM/DD/yyyy"
                  value={selectedDate}
                  onChange={() => console.log('jkdlskjdls')}
                  inputVariant="filled"
                  disabled
                /> */}
                <InputField
                  label="Next Billing Date"
                  disabled={true}
                  value={
                    subscriptionDetail?.current_period_end &&
                    moment.unix(subscriptionDetail?.current_period_end).format('dddd, Do MMM YYYY')
                  }
                />
              </Grid>
              {/* <Grid item xs={8}>
                {subscriptionDetail?.active &&
                  subscriptionDetail?.status !== STRIPE_SUBSCRIPTION_STATUSES.trialing &&
                  (subscriptionDetail?.resumes_at || isResume ? (
                    <KeyboardDatePicker
                      className="date-field"
                      fullWidth
                      margin="normal"
                      label="Resume Date"
                      format="MM/DD/yyyy"
                      value={resumeDate ? resumeDate : subscriptionDetail?.resumes_at}
                      onChange={e => setResumeDate(e as Moment)}
                      inputVariant="filled"
                      autoOk
                    />
                  ) : (
                    <Button variant="outlined" color="primary" onClick={() => setIsResume(true)}>
                      Pause Subscription
                    </Button>
                  ))}
              </Grid> */}
              {/* <Grid item xs={12}> */}
              {/* <Grid item xs={4}>
                  <ToggleButtonGroup size="small" value={alignment} exclusive onChange={handleChange}>
                    <ToggleButton value="active">Active</ToggleButton>
                    <ToggleButton value="inActive">InActive</ToggleButton>
                  </ToggleButtonGroup>
                </Grid> */}
              {/* <Grid item xs={8}>
                  <Button
                    onClick={handleSubscription}
                    className={classes.cancelBtn}
                    color="primary"
                    endIcon={<Close color={isEdit ? 'primary' : 'disabled'} />}
                    size="small"
                    disabled={!subscriptionDetail?.subscription_id}
                  >
                    {`${
                      subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? 'Reactivate' : 'Cancel'
                    } Subscription`}
                  </Button>
                  {cancelSubscriptionLoading && (
                    <div className={classes.rootProgress}>
                      <LinearProgress />
                    </div>
                  )}
                </Grid> */}
              {/* </Grid> */}
              {/* <Grid item xs={12}>
                <Button
                  onClick={() => copyToClipboard(subscriptionDetail.link)}
                  className={classes.cancelBtn}
                  color="primary"
                  // endIcon={<Close color={isEdit ? 'primary' : 'disabled'} />}
                  size="small"
                  disabled={!isEdit}
                >
                  {subscriptionDetail?.subscription_id ? 'Copy Update CC link' : 'Copy Payment Link'}
                </Button>
              </Grid> */}
            </>
          ) : (
            ''
          )}
          {!isEdit && SubscriptionType?.stripe === paymentMethod ? (
            <Grid item xs={12} className="align-end">
              <FormControlLabel
                className="check-box"
                control={
                  <Checkbox
                    checked={isDefaultPaymentMedthod}
                    onChange={() => setIsDefaultPaymentMedthod(!isDefaultPaymentMedthod)}
                    name="isDefaultPaymentMedthod"
                    color="primary"
                  />
                }
                label={toTitleCase('Use existing card details')}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {isEdit && (
          <>
            {subscriptionDetail?.subscription_id ? (
              <InputField
                label="Stripe Subscription ID"
                disabled={isEdit}
                value={subscriptionDetail?.subscription_id}
              />
            ) : (
              ''
            )}

            {/* <InputField label="Address" disabled={isEdit} value={subscriptionDetail?.allocated_address} /> */}

            {subscriptionDetail?.allocated_address ? (
              <div className="payment-header" style={{ marginTop: '0rem' }}>
                <p className="payment-heading">Allocated Address</p>
                <div className="payment-details">
                  <p className="light-text">{subscriptionDetail?.allocated_address}</p>
                </div>
              </div>
            ) : (
              ''
            )}

            {/* paymnet card details */}
            {subscriptionDetail?.subscription_id &&
            subscriptionDetail?.status !== STRIPE_SUBSCRIPTION_STATUSES.canceled ? (
              <>
                {/* <InputField label="Stripe Subscription ID" disabled={isEdit} value={subscriptionDetail?.subscription_id} />
                  {subscriptionDetail?.allocated_address && (
                    // <InputField label="Address" disabled={isEdit} value={subscriptionDetail?.allocated_address} />
                    <div className="payment-header" style={{ marginTop: '0rem' }}>
                      <p className="payment-text">Allocated Address</p>
                      <div className="payment-details">
                        <p className="light-text">{subscriptionDetail?.allocated_address}</p>
                      </div>
                    </div>
                  )} */}

                <div className="payment-header" style={{ marginTop: '0rem' }}>
                  {/* <p className="payment-text">Payment Info</p> */}
                  <p className="payment-heading">Payment Info</p>

                  <div className="payment-details">
                    <div className="inline-item">
                      <p className="blance-text">Balance Due:</p>
                      <p className="amount-text">${subscriptionDetail?.balance || 0}</p>
                    </div>

                    <hr />

                    <p className="light-text">Name on card</p>
                    <p className="dark-text">{subscriptionDetail?.card?.name}</p>
                    {/*  */}
                    <p className="light-text">Payment Method</p>
                    <p className="dark-text">XXXX-XXXX-XXXX-{subscriptionDetail?.card?.last4}</p>
                    {/*  */}
                    <div className="inline-item">
                      <div>
                        <p className="light-text">Expiration date</p>
                        <p className="dark-text">
                          {subscriptionDetail?.card?.exp_month}/{subscriptionDetail?.card?.exp_year}
                        </p>
                      </div>
                      {/*  */}
                      <div>
                        <p className="light-text">CVC</p>
                        <p className="dark-text">XXX</p>
                      </div>
                    </div>
                    {/*  */}
                    {/* <p className="light-text">Billing Address</p>
                        <p className="dark-text">North Hills, CA 91343</p> */}
                  </div>
                </div>
                {/* <Button
                        onClick={() => copyToClipboard(subscriptionDetail.link)}
                        // className={classes.cancelBtn}
                        className={classes.sendBtn}
                        color="primary"
                        // endIcon={<Close color={isEdit ? 'primary' : 'disabled'} />}
                        size="small"
                        disabled={!isEdit}
                      >
                        {subscriptionDetail?.subscription_id ? 'Copy Update CC link' : 'Copy Payment Link'}
                      </Button> */}
                {/* <Button
                        onClick={handleCardRequest}
                        className={classes.sendBtn}
                        color="primary"
                        endIcon={<Telegram color="primary" />}
                        size="small"
                      >
                        Send CC Request
                      </Button>
                      {sendccLoading && (
                        <div className={classes.rootProgress}>
                          <LinearProgress />
                        </div>
                      )} */}
                {/* <Button
                      onClick={handlePastDueRequest}
                      className={classes.sendBtn}
                      color="primary"
                      endIcon={<Telegram color={isEdit ? 'primary' : 'disabled'} />}
                      size="small"
                      disabled={!isEdit}
                    >
                      Send Passed Due Notice
                    </Button> */}
                {sendPastDueLoading && (
                  <div className={classes.rootProgress}>
                    <LinearProgress />
                  </div>
                )}
              </>
            ) : (
              <>
                {/* {subscriptionDetail?.allocated_address && (
                  // <InputField label="Address" disabled={isEdit} value={subscriptionDetail?.allocated_address} />
                  <div className="payment-header" style={{ marginTop: '0rem' }}>
                    <p className="payment-text">Allocated Address</p>
                    <div className="payment-details">
                      <p className="light-text">{subscriptionDetail?.allocated_address}</p>
                    </div>
                  </div>
                )} */}
                {/* {!isOffline ? (
                    <Button
                      onClick={handleCardRequest}
                      className={classes.sendBtn}
                      color="primary"
                      endIcon={<Telegram color={isEdit ? 'primary' : 'disabled'} />}
                      size="small"
                      style={{ marginTop: '20rem' }}
                      disabled={!isEdit}
                    >
                      Send CC Request
                    </Button>
                  ) : (
                    ''
                  )}

                  {sendccLoading && (
                    <div className={classes.rootProgress}>
                      <LinearProgress />
                    </div>
                  )} */}
              </>
            )}

            {/* buttons */}

            {/* {isEdit && !isOffline ? ( */}
            {SubscriptionType?.stripe === paymentMethod &&
            subscriptionDetail?.status !== STRIPE_SUBSCRIPTION_STATUSES.canceled ? (
              <>
                <div className={classes.inlineBtn}>
                  <Button
                    onClick={() => copyToClipboard(subscriptionDetail.link)}
                    // className={classes.cancelBtn}
                    // className={classes.sendBtn}
                    color="primary"
                    // endIcon={<Close color={isEdit ? 'primary' : 'disabled'} />}
                    size="small"
                    // disabled={!isEdit}
                    disabled={
                      (subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false) ||
                      isGettingSubLoading
                    }
                    variant="outlined"
                    endIcon={<FileCopy color="primary" />}
                    style={{ padding: '0.3rem 0.4rem' }}
                  >
                    {subscriptionDetail?.subscription_id ? 'Update CC link' : 'Payment Link'}
                  </Button>

                  <Button
                    onClick={handleCardRequest}
                    // className={classes.sendBtn}
                    color="primary"
                    endIcon={<Telegram color="primary" />}
                    size="small"
                    disabled={
                      (subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false) ||
                      isGettingSubLoading
                    }
                    variant="outlined"
                    style={{ padding: '0.3rem 0.4rem' }}
                  >
                    Send CC Request
                  </Button>
                  {sendccLoading && (
                    <div className={classes.rootProgress}>
                      <LinearProgress />
                    </div>
                  )}
                </div>
                {pastInvDetails?.length ? (
                  <ButtonWithLoading
                    isLoading={isDueLoading}
                    color="primary"
                    variant="contained"
                    // disabled={isDueLoading}
                    disabled={
                      (subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false) ||
                      isDueLoading ||
                      isGettingSubLoading
                    }
                    onClick={() => setIsModalOpen(true)}
                  >
                    Pending Invoices
                  </ButtonWithLoading>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <hr />
      </Grid>

      {/* {isEdit ? (
        <MuiAccordion classes={{ root: classes.accordion }}>
          <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
            <p>Transactions History</p>
          </MuiAccordionSummary>
          <MuiAccordionDetails classes={{ root: classes.details }}>
            <SubscriptionTransactionTable transactions={transactionsArr} loading={false} />
          </MuiAccordionDetails>
        </MuiAccordion>
      ) : (
        ''
      )} */}

      <div className={classes.saveBtn}>
        {/* {isOffline && isEdit ? (
          <div className="cancle-btn">
            {isConfrim ? (
              <>
                <Button variant="outlined" color="primary" onClick={() => setIsConfrim(false)}>
                  Cancel
                </Button>
                <div>
                  <Button variant="outlined" color="secondary" onClick={handleCancleORDeleteSubscription}>
                    Confirm
                  </Button>
                  {cancelSubscriptionLoading && (
                    <div className={classes.rootProgress}>
                      <LinearProgress />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setIsConfrim(true)
                    setBtnStatus('cancle')
                  }}
                  className={classes.delSubBtn}
                  variant="outlined"
                  size="small"
                >
                  {`${
                    subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? 'Reactivate' : 'Cancel'
                  } Subscription`}
                </Button>
                <Button
                  onClick={() => {
                    setIsConfrim(true)
                    setBtnStatus('delete')
                  }}
                  className={classes.cancelSubBtn}
                  variant="outlined"
                  size="small"
                >
                  {`Delete Subscription`}
                </Button>
              </>
            )}
          </div>
        ) : (
          ''
        )} */}

        {isEdit ? (
          <div className="cancle-btn">
            {isConfrim ? (
              <>
                <Button variant="outlined" color="primary" onClick={() => setIsConfrim(false)}>
                  Cancel
                </Button>
                <div>
                  <Button variant="outlined" color="secondary" onClick={handleCancleORDeleteSubscription}>
                    Confirm
                  </Button>
                  {cancelSubscriptionLoading && (
                    <div className={classes.rootProgress}>
                      <LinearProgress />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <Button
                  // onClick={() => {
                  //   setIsConfrim(true)
                  //   setBtnStatus('cancle')
                  // }}
                  onClick={() => {
                    setIsCancelModalOpen(true)
                    setBtnStatus('cancle')
                  }}
                  className={classes.delSubBtn}
                  variant="outlined"
                  size="small"
                  disabled={
                    (subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false) ||
                    isGettingSubLoading
                  }
                >
                  {/* {`${
                    subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? 'Reactivate' : 'Cancel'
                  } Subscription`} */}
                  Cancel Subscription
                </Button>
                {/* <Button
                  onClick={() => {
                    setIsConfrim(true)
                    setBtnStatus('delete')
                  }}
                  className={classes.cancelSubBtn}
                  variant="outlined"
                  size="small"
                >
                  {`Delete Subscription`}
                </Button> */}
                {/* <ButtonWithLoading
                  isLoading={isDueLoading}
                  color="primary"
                  variant="contained"
                  // disabled={isDueLoading}
                  disabled={
                    (subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false) ||
                    isDueLoading ||
                    isGettingSubLoading
                  }
                  onClick={() => setIsModalOpen(true)}
                >
                  Pending Invoices
                </ButtonWithLoading> */}

                {SubscriptionType?.stripe !== paymentMethod && (
                  <ButtonWithLoading
                    color="primary"
                    variant="contained"
                    onClick={() => setIsPaidDateModalOpen(true)}
                    disabled={subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false}
                  >
                    Paid Through Date
                  </ButtonWithLoading>
                )}
              </>
            )}
          </div>
        ) : (
          ''
        )}

        {/* {isOffline ? ( */}
        {/* {SubscriptionType?.quickbook === paymentMethod ? (
          <ButtonWithLoading
            isLoading={isLoading}
            color="primary"
            variant="contained"
            onClick={handleSaveChange}
            disabled={subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false}
          >
            Save
          </ButtonWithLoading>
        ) : (
          <ButtonWithLoading
            isLoading={isLoading}
            color="primary"
            variant="contained"
            disabled={isEdit && !subscriptionDetail?.subscription_id}
            onClick={handleSaveChange}
          >
            Save
          </ButtonWithLoading>
        )} */}

        <ButtonWithLoading
          isLoading={isLoading}
          color="primary"
          variant="contained"
          disabled={
            (subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false) ||
            // (isEdit && paymentMethod === SubscriptionType?.stripe && !subscriptionDetail?.subscription_id) ||
            isGettingSubLoading
          }
          onClick={handleSaveChange}
        >
          Save
        </ButtonWithLoading>
      </div>
      {subscriptionLogs?.length ? (
        <>
          <h2 className="primary-heading">Subscription History</h2>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Log Date/Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionLogs?.map((item: ILogsResponse) => (
                  <TableRow key={item?.subscription_id}>
                    <TableCell component="th" scope="row">
                      {item?.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {formatDateTimeToHumanFriendly(item?.log_date_time ? item?.log_date_time : '')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        ''
      )}

      <ModalWrapper maxWidth={'md'} open={isModalopen} setOpen={setIsModalOpen} heading={`Pending Invoices`}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Subscription ID</TableCell>
                <TableCell>Amount Due</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Period Start </TableCell>
                <TableCell>Period End </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pastInvDetails?.map((item: IPastInvoiceResponse) => (
                <TableRow key={item?.invoice_id}>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      color="primary"
                      // disabled={type === 'personal'}
                      // checked={checked}
                      value={item?.invoice_id}
                      style={{ padding: 0, marginRight: 10 }}
                      onChange={() => toggleInvoiceId(item?.invoice_id.toString())}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.subscription}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.amount_due ? item?.amount_due / 100 : ''}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.status}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment.unix(item?.period_start).format('dddd, Do MMM YYYY')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment.unix(item?.period_end).format('dddd, Do MMM YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <ButtonWithLoading
            style={{ width: '12rem', margin: '1rem 0' }}
            isLoading={payLoader}
            color="primary"
            variant="contained"
            disabled={!invIds.length}
            onClick={payPastInvoices}
          >
            Mark Paid
          </ButtonWithLoading>
        </div>
      </ModalWrapper>

      <ModalWrapper
        maxWidth={'xs'}
        open={isPaidDateModalopen}
        setOpen={setIsPaidDateModalOpen}
        heading={`Paid Through Date`}
      >
        <Grid item xs={12} className="align-end">
          <KeyboardDatePicker
            fullWidth
            margin="normal"
            label="Paid Through Date"
            format="MM/DD/yyyy"
            value={paidDate}
            onChange={e => setPaidDate(e as Moment)}
            inputVariant="outlined"
            autoOk
            disablePast
            disabled={subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? true : false}
          />
        </Grid>
        <Grid item xs={12} className="align-end" style={{ marginTop: '0.25rem' }}>
          <TextField
            label="Paid Through Reason"
            value={paidThroughReason}
            onChange={e => setPaidThroughReason(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <div className={classes.inlineBtn}>
          <Button variant="outlined" color="primary" onClick={() => setIsPaidDateModalOpen(false)}>
            Cancel
          </Button>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              disabled={!paidThroughReason?.length ? true : false}
              onClick={handlePaidThough}
            >
              Confirm
            </Button>
            {isLoading && (
              <div className={classes.rootProgress}>
                <LinearProgress />
              </div>
            )}
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper
        maxWidth={'xs'}
        open={isCancelModalopen}
        setOpen={setIsCancelModalOpen}
        heading={`Cancel Subscription`}
      >
        <Grid item xs={12} className="align-end" style={{ marginTop: '0.25rem' }}>
          <TextField
            label="Reason for Cancellation"
            value={cancelReason}
            onChange={e => setCancelReason(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <div className={classes.inlineBtn}>
          <Button variant="outlined" color="primary" onClick={() => setIsCancelModalOpen(false)}>
            Cancel
          </Button>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              disabled={!cancelReason?.length ? true : false}
              onClick={handleCancleORDeleteSubscription}
            >
              Confirm
            </Button>
            {cancelSubscriptionLoading && (
              <div className={classes.rootProgress}>
                <LinearProgress />
              </div>
            )}
          </div>
        </div>
      </ModalWrapper>
    </Grid>
  )
}

export default SubscriptionModal

const trialPeriodArr = [
  { value: 1, label: 'Day' },
  { value: 7, label: 'Weak' },
  { value: 30, label: 'Month' },
  { value: 12, label: 'Annual' },
]

const transactionsArr = [
  { date: 'string', invoice: 'string', amount: 'string', status: 'string' },
  { date: 'string', invoice: 'string', amount: 'string', status: 'string' },
  { date: 'string', invoice: 'string', amount: 'string', status: 'string' },
  { date: 'string', invoice: 'string', amount: 'string', status: 'string' },
]

// ) : subscriptionDetail?.subscription_id ? (
//   <Button
//     onClick={() => setIsConfrim(true)}
//     className={classes.cancelSubBtn}
//     variant="outlined"
//     // endIcon={<Close color={isEdit ? 'primary' : 'disabled'} />}
//     size="small"
//     disabled={subscriptionDetail?.subscription_id ? false : true}
//   >
//     {`${
//       subscriptionDetail?.status === STRIPE_SUBSCRIPTION_STATUSES.canceled ? 'Reactivate' : 'Cancel'
//     } Subscription`}
//   </Button>
// ) : subscriptionDetail?.id && !subscriptionDetail?.active ? (
//   <Button
//     onClick={() => setIsConfrim(true)}
//     className={classes.cancelSubBtn}
//     variant="outlined"
//     size="small"
//   >
//     {`Delete Subscription`}
//   </Button>
// ) : (
//
