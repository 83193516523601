import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import green from '@material-ui/core/colors/green'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import * as React from 'react'
import { TestID } from 'utils/testUtils/TestID'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      width: '100%',
      position: 'relative',
    },
    button: {
      width: '100%',
      backgroundColor: '#3c9fdf',
      height: 50,
      margin: '0.5rem 0',
      color: 'white',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#2374a8',
      },
    },
    buttonSuccess: {
      width: '100%',
      backgroundColor: green[500],
      height: 50,
      margin: '0.5rem 0',
      color: 'white',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
)

interface ISubmitFormButtonProps {
  title?: string

  /**
   * method called on button click
   */
  onClick: () => Promise<boolean>

  /**
   * method called after submit request is successful
   */
  onFormSubmitted?: () => void
}

const SubmitFormButton: React.FC<ISubmitFormButtonProps> = ({ title, onClick, onFormSubmitted }) => {
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const handleButtonClick = async () => {
    try {
      setLoading(true)
      const response = await onClick()
      if (response) {
        setSuccess(true)
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }

    // if (!loading) {
    //   setLoading(true)
    //   const response = await onClick()
    //   if (response) {
    //     setSuccess(true)
    //   }
    //   setLoading(false)
    // }
  }

  React.useEffect(() => {
    if (success && onFormSubmitted) {
      setSuccess(false)
      onFormSubmitted()
    }
  }, [onFormSubmitted, success])
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {success ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonSuccess}
            disabled={loading}
            onClick={handleButtonClick}
            data-testid={TestID.SUBMIT_FORM_BUTTON}
          >
            Success!
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={loading}
            onClick={handleButtonClick}
            data-testid={TestID.SUBMIT_FORM_BUTTON}
          >
            {title || 'Submit'}
          </Button>
        )}
        {loading && <CircularProgress size={30} className={classes.buttonProgress} />}
      </div>
    </div>
  )
}

export default SubmitFormButton
