import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Skeleton from '@material-ui/lab/Skeleton'
import { CSSProperties } from '@material-ui/styles'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeSortArrow: {
      color: theme.customPalette.darkerBlue,
    },
    inactiveSortArrow: {
      opacity: 0.3,
    },
  })
)

export interface HeadColumn {
  id: string // one of main interface fields
  label: string | React.ReactNode // text to display
  style: React.CSSProperties // style to apply
  sortable: boolean // should sorting label be displayed?
}

export type SortingType = 'desc' | 'asc'

export interface SortableTableSortProps {
  cellStyle?: CSSProperties
  disableCompanyNameAndSalesRep?: boolean
  sortBy: string // it's usually a key from existing interface
  sortOrder: SortingType // asc | desc
  handleSortingClick: (sortBy: string) => void // what happens when user clicks on sorting label (arrow)
  headColumns?: HeadColumn[] // a list of rows with style, labels and unique keys
}

export interface SortableTableHeadProps extends SortableTableSortProps {
  headColumns?: HeadColumn[] // a list of rows with style, labels and unique keys
}

export const SortableTableHead: React.FC<SortableTableHeadProps> = ({
  sortBy,
  sortOrder,
  handleSortingClick,
  headColumns,
  cellStyle,
}) => {
  const classes = useStyles()

  const onSortClick = React.useCallback((column: HeadColumn) => handleSortingClick(column.id), [handleSortingClick])

  if (!headColumns) return null

  return (
    <TableHead>
      <TableRow>
        {headColumns.map(column => (
          <TableCell key={column.id} style={{ ...cellStyle, ...column.style }}>
            {column.sortable === true ? (
              <TableSortLabel
                active={column.id === sortBy}
                direction={column.id === sortBy ? sortOrder : undefined}
                onClick={() => onSortClick(column)}
                classes={{ icon: column.id === sortBy ? classes.activeSortArrow : classes.inactiveSortArrow }}
                data-testid={`${column.label}-table-sort-label`}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

// ALL STYLED ELEMENTS BELOW WILL BE DELETED

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.1px',
      color: theme.palette.text.primary,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  })
)(TableRow)

export const StyledTableHead = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)(TableHead)

export const StyledTable = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F6F6F8',
    },
  })
)(Table)

interface IEmptyTableRowProps {
  title?: string
  columns: number
}

export const EmptyTableRow: React.FC<IEmptyTableRowProps> = ({ title, columns }) => {
  return (
    <StyledTableRow>
      <StyledTableCell style={{ textAlign: 'center', textTransform: 'uppercase' }} colSpan={columns}>
        {title || 'nothing to display...'}
      </StyledTableCell>
    </StyledTableRow>
  )
}

interface SkeletonGerenatorParams {
  numberOfColumns: number
  sizes?: number[]
  numberOfRows?: number
  fullWidth?: boolean
}

// The skeletons(SkeletonGeneratorParams) function returns an array of rows,
// containing cells with skeleton views. The only required parameter is 'numberOfColumns: number'.

// 'sizes?: number[]' is an optional parameter, that is an array of numbers,
// representing width of a skeleton view in each cell. If it's not provided,
// default width of 100px will be used for each skeleton view.

// 'numberOfRows?: number' equals 1 by default

// If 'fullWidth?: boolean' parameter is set to true, pixel sizes will be ignored
// and each skeleton view will fill 100% of cell's width.
export const skeletons = ({
  numberOfColumns,
  sizes = Array(numberOfColumns).fill(100),
  numberOfRows = 1,
  fullWidth = false,
}: SkeletonGerenatorParams) => {
  // If length of provided 'sizes' array is less than numberOfColumns,
  // if will be filled up with values of 100px.
  if (sizes.length < numberOfColumns) {
    let index = sizes.length
    while (index++ < numberOfColumns) {
      sizes.push(100)
    }
  }
  return Array(numberOfRows)
    .fill(numberOfRows)
    .map((row, index) => (
      <TableRow key={index}>
        {sizes.map((width, index) => (
          <TableCell key={index}>
            <Skeleton
              variant="rect"
              // Too low width safety catch ↓↓↓
              width={fullWidth ? '100%' : Math.max(20, width)}
              height={30}
              style={{ borderRadius: 4 }}
            />
          </TableCell>
        ))}
      </TableRow>
    ))
}
