import React from 'react'
import useStyles from './style'

type OrderDetailProps = {
  orderStatus: string
  orderCount: number
  orderDate?: string
  isCustomWidget?: boolean
  isOldestOrder?: boolean
}

const OrderDetailCard: React.FC<OrderDetailProps> = ({
  orderStatus,
  orderCount,
  orderDate,
  isCustomWidget,
  isOldestOrder = true,
}: OrderDetailProps) => {
  const classes = useStyles()
  return (
    <div className={classes.OrderDetailCardContainer} style={{ background: isCustomWidget ? '#26316c' : 'inherit' }}>
      <p className={classes.orderInitiatedText} style={{ color: isCustomWidget ? '#fff' : '#020202' }}>
        {orderStatus}
      </p>
      <h1 className={classes.orderCount} style={{ color: isCustomWidget ? '#fff' : '#18577f' }}>
        {orderCount}
      </h1>
      <p className={classes.orderOldest} style={{ color: isCustomWidget ? '#fff' : '#206a9a' }}>
        {isOldestOrder ? 'Oldest Order' : ''}
      </p>
      <p className={classes.orderDateText} style={{ color: isCustomWidget ? '#fff' : '#020202' }}>
        {orderDate}
      </p>
    </div>
  )
}

export default OrderDetailCard
