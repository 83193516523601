import { IconButton, TableCell, TableRow } from '@material-ui/core'
import { TableRowProps } from '@material-ui/core/TableRow'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useToggle from 'hooks/useToggle'
import React, { ReactNode } from 'react'

interface Props extends TableRowProps {
  expandComponent: ReactNode
  dataTestId?: string
}

export const ExpandableTableRow = ({ children, expandComponent, dataTestId, ...otherProps }: Props) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false)

  return (
    <React.Fragment>
      <TableRow data-testid={dataTestId} {...otherProps}>
        {children}
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={toggleIsExpanded} data-testid="task-expand-button">
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {isExpanded && <TableRow>{expandComponent}</TableRow>}
    </React.Fragment>
  )
}
