import { createStyles, FormControl, FormHelperText, makeStyles, Theme, TextField, FormLabel } from '@material-ui/core'
import { deleteAddress, editAddress } from 'api/addresses'
//import { createClient, updateClient } from 'api/clients'
import { createClient, fetchlinkedwidgets, getEmpImage, getwidgets, updateClient, uploadEmpImage } from 'api/employees'
import { Checkbox, ListItem } from '@material-ui/core'

import PhoneInput from 'components/clients/create/phoneInput'
import useAddressEditErrors, { AddressError } from 'components/clients/create/useAddressEditErrors'
import useClientEditErrors, { ClientError } from 'components/clients/create/useClientEditErrors'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import DatePicker from 'components/common/datePicker'
import { normalizeText, positiveNumericInput } from 'components/common/functions'
import SelectField from 'components/common/select'
import BRTextField from 'components/common/textFields/BRTextField'
import ImageUpload from 'components/common/textFields/ImageUpload'
import Button from '@material-ui/core/Button'

import { UNITED_STATES } from 'components/companies/hooks/companyDetails'
import deepcopy from 'deepcopy'
import useRouter from 'hooks/router'
import { IAddress } from 'models/address'
import { IClient, ICreatedClient, ICreatedEmployee, IEmployee } from 'models/user'
import moment from 'moment'
import * as React from 'react'
import { useMemo } from 'react'
import { checkUsername, resetPassword } from 'api/users'
import showSuccessNotification from 'utils/showSuccessNotification'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import TableBody from '@material-ui/core/TableBody'
import { EmptyTableRow, skeletons, StyledTable } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'

import {
  backendDateStringToFrontendFormat,
  dateFromString,
  datePickerDateFormat,
  datePickerDatePlaceholder,
} from 'utils/formatDate'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import { validateEmail } from 'utils/validators'
import { FormMode } from '../../common/forms/FormMode'
import { assertNonNullable } from 'utils/asserts'
import { ISuggestionAttribute } from 'components/interfaces'
import { routerMiddleware } from 'connected-react-router'
import profile from '../../../img/profile.jpg'
import OutlinedSelect from 'components/common/outlinedSelect'
import grey from '@material-ui/core/colors/grey'
import { paginateStatus } from 'api/widgets'
import LinearProgress from '@material-ui/core/LinearProgress'
import { EMPLOYEE_ROLES, FIELDS_MIN_LENGTH } from 'utils/constant'
import { Avatar } from '@material-ui/core'
import { getAllTaxWidge, getTaxWidgetByEmpId } from 'api/taxWidgets'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: 420,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    title: {
      fontSize: '1.5rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 500,
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
    imgDiv: {
      display: 'flex',
      alignItems: 'inherit',
    },
    userAvatar: {
      width: '55px',
      height: '55px',
      marginTop: '3%',
      marginLeft: '3%',
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    HelperTextUsername: {
      color: '#3c9fdf', //'rgba(0, 0, 0, 0.54)',//theme.customPalette.errorText,
      margin: '8px 14px 0',
      fontSize: '13px',
    },
    inlineCheck: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '1rem 0',
    },
  })
)

export interface IAddressErrors {
  country?: boolean
  city?: boolean
  street?: boolean
  postal_code?: boolean
}

const newClient: IEmployee = {
  extid: '',
  first_name: '',
  last_name: '',
  meeting_link: '',
  name: '',
  //image: null,
  email: '',
  ssn_ein: undefined,
  dob: undefined,
  sales_rep: undefined,
  sales_rep_id: undefined,
  affiliate_id: undefined,
  affiliate: undefined,
  phone: undefined,
  addresses: [],
  user_type: '1',
  username: '',
  employee_type: '',
  phone_office: '',
  employee_role: '',
  allowed_ip: '',
}

export const emptyAddress: IAddress = {
  country: UNITED_STATES,
  city: '',
  street: '',
  postal_code: '',
  is_primary: false,
  is_mailing: false,
  locality: '',
  address_type: 'contact',
}

export interface IClientDetailsProps {
  editedClient?: IEmployee
  onRequestSuccessful: (client: ICreatedEmployee) => void
}

export interface IClientDetailsState {
  client: IClient
  errors: {
    first_name: boolean
    last_name: boolean
    name: boolean
    email: boolean
    phone: boolean
    dob: boolean
    ssn_ein: boolean
    sales_rep_id: boolean
    noPrimaryAddress: boolean
  }
  addressErrors: IAddressErrors
  currentEditedAddress: IAddress
  showAddAddressDialog: boolean
  editedAddressId?: number
  addressesToAdd: IAddress[]
  addressesToEdit: IAddress[]
  addressesToDelete: number[]
}

// Because date returned from backend, is in different format, that existing validator accepts,
// we format it properly first
const extractDateFromClient = (client: IEmployee): string | undefined => {
  if (!client.dob) return undefined
  return backendDateStringToFrontendFormat(client.dob)
}

const validateName = (client: IEmployee) => {
  return !(!client.name || client.name.trim().length < 3)
}
const validatefName = (client: IEmployee) => {
  return !(!client.first_name || client.first_name.trim().length < 3)
}
const validateAddress = (client: IEmployee): boolean => {
  const { addresses } = client
  if (addresses && addresses.length) {
    if (!addresses.find(address => address.is_primary === true)) return false
  }
  return true
}

const validateDob = (client: IEmployee) => {
  if (!client.dob) return true
  const selectedDate = dateFromString(client.dob)
  if (!selectedDate.isValid() || selectedDate.isAfter(moment())) {
    return false
  }
  return true
}

// Backend accepts addresses with `id` field and unprocessable entities
const addressesWithoutIds = (client: IEmployee): IAddress[] | undefined => {
  if (!client.addresses) return undefined
  return client.addresses.map(address => {
    delete address.id
    return address
  })
}

const addressesWithNewPrimary = (addresses: IAddress[], newPrimaryAddress: IAddress | null): IAddress[] =>
  addresses.map((address: IAddress) => {
    if (newPrimaryAddress && address.id === newPrimaryAddress.id) {
      address.is_primary = true
    }
    if (!newPrimaryAddress || (address.id !== newPrimaryAddress.id && address.is_primary)) {
      address.is_primary = false
    }
    return address
  })

const EmployeeDetails: React.FC<IClientDetailsProps> = ({ editedClient, onRequestSuccessful }) => {
  const formMode: FormMode = editedClient ? FormMode.UPDATE : FormMode.CREATE

  const [client, setClient] = React.useState<IEmployee>(
    editedClient
      ? {
          ...editedClient,
          dob: extractDateFromClient(editedClient),
        }
      : newClient
  )
  const [url, seturl] = React.useState('')

  React.useEffect(() => {
    const geturl = async () => {
      if (editedClient) {
        seturl(await getEmpImage(editedClient.id))
      }
    }
    geturl()
    console.log('url', url)
  }, [url])

  const clientEditErrors = useClientEditErrors()
  const [helperTexts, setHelperTexts] = React.useState({ email: '', ssn_ein: '' })

  // FIXME: #379 would be nice to extract all address-related functionality to separate components & hooks
  const addressEditErrors = useAddressEditErrors()
  const [editedAddressId, setEditedAddressId] = React.useState<number>()
  const [currentEditedAddress, setCurrentEditedAddress] = React.useState<IAddress>(emptyAddress)
  const [showAddAddressDialog, setShowAddAddressDialog] = React.useState(false)
  const [addressesToAdd, setAddressesToAdd] = React.useState<IAddress[]>([])
  const [addressesToEdit, setAddressesToEdit] = React.useState<IAddress[]>([])
  const [addressesToDelete, setAddressesToDelete] = React.useState<number[]>([])
  const [widgets, setwidgets] = React.useState<any>([])
  const [statusids, setstatusids] = React.useState<any>([])

  const [taxWidgets, setTaxWidgets] = React.useState<any>([])
  const [taxWidgetIDs, setTaxWidgetIDs] = React.useState<any>([])

  React.useEffect(() => {
    const getTaxWidges = async () => {
      try {
        let data: any = []
        !!editedClient ? (data = await getTaxWidgetByEmpId(editedClient?.id || 0)) : (data = await getAllTaxWidge())
        setTaxWidgets(data)
      } catch (error) {
        showApiResponseError(error, 'Failed to get widgets')
      }
    }

    getTaxWidges()
  }, [])

  //   let data: any = []
  //   !!editedClient ? (data = await getTaxWidgetByEmpId(editedClient?.id || 0)) : (data = await getAllTaxWidge())
  //   setTaxWidgets(data)
  //   // const res = editedClient?.id ? await getTaxWidgetByEmpId(editedClient?.id) : getTaxWidges()
  //   // setTaxWidgets(res)
  // } catch (error) {
  //   showApiResponseError(error, 'Failed to get widgets')
  // }

  console.log('taxWidgetstaxWidgetstaxWidgets', taxWidgets)

  const handleTaxEditStatus = (id: any, checked: any, index: number) => {
    const newArr = [...taxWidgets] // copying the old datas array
    newArr[index].is_checked = !newArr[index].is_checked // replace e.target.value with whatever you want to change it to
    //newArr[index].display_order=
    setTaxWidgets(newArr)
    debugger
    const temparray: any = []
    // checked=!checked;
    temparray.push(id)
    if (checked) setTaxWidgetIDs([...taxWidgetIDs, parseInt(id)])
  }

  console.log('taxWidgetstaxWidgetstaxWidgets 121212', taxWidgetIDs)

  const { history } = useRouter()
  const updateEmployeeRequest = async (client: IEmployee) => {
    const editedClient = client
    const newArr2: any = []
    const newArrTaxWidget: any = []

    widgets.map((item: any, index) => {
      if (item.is_checked === true) {
        newArr2.push(item.id)
      }
    })
    taxWidgets.map((item: any, index) => {
      if (item.is_checked === true) {
        newArrTaxWidget.push(item.id)
      }
    })

    const resClient = await updateClient({
      // We pick certain fields from editedClient object, because client,
      // fetched from GET `/user/:id`, has fields, that are unprocessable by server,
      // if we send them to PATCH `user/:id`
      first_name: editedClient.first_name,
      last_name: editedClient.last_name,
      meeting_link: editedClient.meeting_link,
      image: editedClient.image,
      extid: editedClient.extid,
      id: editedClient.id,
      name: editedClient.name,
      email: editedClient.email,
      ssn_ein: editedClient.ssn_ein,
      dob: editedClient.dob,
      phone: editedClient.phone || '',
      user_type: editedClient.user_type,
      addresses: editedClient.addresses,
      designation: editedClient.designation,
      username: editedClient.username,
      employee_type: editedClient.employee_type,
      dashboard_preferences: newArr2,
      phone_office: editedClient.phone_office,
      employee_role: editedClient.employee_role,
      allowed_ip: editedClient.allowed_ip,
      tax_dashboard_preferences: newArrTaxWidget,
      tax_team: editedClient?.tax_team,
    })
    await imgUpload(editedClient.id)
    return resClient
  }

  const imgUpload = async (id: number | undefined) => {
    const imageInput: any = document.querySelector('#empimg')
    const file = imageInput.files[0]
    if (file !== null && file !== undefined) await uploadEmpImage(id, file)
  }
  const createEmployeeRequest = async (client: any) => {
    const newArr2: any = []
    const newArrTaxWidget: any = []

    widgets.map((item: any, index) => {
      if (item.is_checked === true) {
        newArr2.push(item.id)
      }
    })
    taxWidgets.map((item: any, index) => {
      if (item.is_checked === true) {
        newArrTaxWidget.push(item.id)
      }
    })
    client.dashboard_preferences = newArr2
    client.tax_dashboard_preferences = newArrTaxWidget
    debugger

    const resClient = await createClient({ ...client /* addresses: addressesWithoutIds(client)*/ })
    await imgUpload(resClient.id)
    // imageToBase64(client.image) // Path to the image
    //   .then(
    //       async(response:any) => {
    //           console.log(response); // "cGF0aC90by9maWxlLmpwZw=="
    //           await uploadEmpImage(resClient.id, response);

    //       }
    //   )
    //   .catch(
    //       (error:any) => {
    //           console.log(error); // Logs an error if there was one
    //       }
    //   )
    // console.log("lklk", client.image);

    return resClient
  }
  const addAttribute = React.useCallback(
    <K extends keyof Pick<IEmployee, 'sales_rep_id' | 'affiliate_id'>>(field: K, id: IEmployee[K]) => {
      setClient({
        ...client,
        [field]: id,
      })
    },
    [setClient, client]
  )

  const toggleAddAddressDialog = React.useCallback(() => {
    setShowAddAddressDialog(!showAddAddressDialog)
    setCurrentEditedAddress(emptyAddress)
  }, [setShowAddAddressDialog, showAddAddressDialog])
  const [usernameChanged, setUsernameChanged] = React.useState<boolean>(false)

  const changeClientField = React.useCallback(
    (field: keyof IEmployee, value: ValueOf<IEmployee>) => {
      field === 'username' ? setUsernameChanged(true) : setUsernameChanged(false)
      setClient({
        ...client,
        [field]: value !== '' ? value : null,
      })
      if (clientEditErrors[field]) clientEditErrors.setError(field as ClientError, false)
      if (helperTexts[field]) {
        setHelperTexts({
          ...helperTexts,
          [field]: '',
        })
      }
    },
    [setClient, client, clientEditErrors, helperTexts]
  )

  const validateFields = React.useCallback((): boolean => {
    let isValid = true
    const tmpHelperTexts = helperTexts

    // if (!validateName(client)) {
    //   clientEditErrors.setError('name', true)
    //   isValid = false
    // }
    // if (!validatefName(client)) {
    //   clientEditErrors.setError('first_name', true)
    //   isValid = false
    // }
    // if (!client.email || !validateEmail(client.email)) {
    //   clientEditErrors.setError('email', true)
    //   tmpHelperTexts.email = 'Please Specify Email'
    //   isValid = false
    // }
    // if (client.dob && client.dob !== '' && !validateDob(client)) {
    //   clientEditErrors.setError('dob', true)
    //   isValid = false
    // }
    // if (!validateAddress(client)) {
    //   clientEditErrors.setError('no_primary_address', true)
    //   isValid = false
    // }
    if (errorUsername) {
      isValid = false
    }
    setHelperTexts(tmpHelperTexts)
    return isValid
  }, [helperTexts, client, clientEditErrors])

  const sendAddressDeletionRequests = React.useCallback(() => {
    addressesToDelete.forEach(async addressId => {
      try {
        deleteAddress(addressId)
      } catch (err) {
        showApiResponseError(err, `Failed to delete address ${addressId}`)
      }
    })
  }, [addressesToDelete])

  const sendAddressUpdateRequests = React.useCallback(() => {
    addressesToEdit.forEach(async address => {
      try {
        const cleanedUpAddress: IAddress = removeNullAndUndefined(address) as IAddress
        await editAddress(cleanedUpAddress)
      } catch (err) {
        showApiResponseError(err, `Failed to update address ${address.id}`)
      }
    })
  }, [addressesToEdit])

  function getBase64Image(img) {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    //ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png')
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
  }

  const onSubmitClick = React.useCallback(async (): Promise<boolean> => {
    if (!validateFields()) return false

    if (client.username && client.username?.length < FIELDS_MIN_LENGTH.username) {
      return false
    }

    try {
      setLoading(true)
      setUsernameFieldDisabled(true)
      const res: any = await checkUsername({ username: client.username })
      setLoading(false)
      setUsernameFieldDisabled(false)
      if (res.error && existingUsername !== client.username) {
        setErrorusername(true)
        setErrorusernameText('Sorry! username already exists!')

        return false
      } else {
        setErrorusername(false)

        try {
          let clientResponse: ICreatedEmployee

          if (formMode === FormMode.UPDATE) {
            clientResponse = await updateEmployeeRequest(client)
          } else {
            clientResponse = await createEmployeeRequest(client)
            history.push('/employee')

            // When creating a new client, the attached addresses are without client ids.
            // This prevents us from sending a request to create a new address and link it with the client.
            // const addressesToCreateWithClientIds = addressesToAdd
            // addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
            // setAddressesToAdd(addressesToCreateWithClientIds)
            return true
          }

          // if (addressesToDelete.length) {
          //   sendAddressDeletionRequests()
          // }
          // if (addressesToEdit.length) {
          //   sendAddressUpdateRequests()
          // }

          // const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
          // setClient(updatedClient)
        } catch (err) {
          const errorMessage: string | undefined = err?.response?.data?.message
          const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
          const tmpHelperTexts = {
            email: '',
            ssn_ein: '',
          }
          if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
            if (errorMessage.includes('email')) {
              clientEditErrors.setError('email', true)
              tmpHelperTexts.email = 'This email is already in use'
            }
            if (errorMessage.includes('ssn_ein')) {
              clientEditErrors.setError('ssn_ein', true)
              tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
            }
            setHelperTexts(tmpHelperTexts)
            return false
          }
          showApiResponseError(err, 'Could not save changes')
          return false
        }
      }
    } catch (e) {
      showApiResponseError(e, 'Error Checking username')
    }

    // try {
    //   let clientResponse: ICreatedEmployee
    //   if (formMode === FormMode.UPDATE) {
    //     clientResponse = await updateEmployeeRequest(client)
    //   } else {
    //     clientResponse = await createEmployeeRequest(client)
    //     history.push('/employee')

    //     // When creating a new client, the attached addresses are without client ids.
    //     // This prevents us from sending a request to create a new address and link it with the client.
    //     // const addressesToCreateWithClientIds = addressesToAdd
    //     // addressesToCreateWithClientIds.map((address: IAddress) => (address.client_id = clientResponse.id))
    //     // setAddressesToAdd(addressesToCreateWithClientIds)
    //     return true
    //   }

    //   // if (addressesToDelete.length) {
    //   //   sendAddressDeletionRequests()
    //   // }
    //   // if (addressesToEdit.length) {
    //   //   sendAddressUpdateRequests()
    //   // }

    //   // const updatedClient = formMode === FormMode.UPDATE ? clientResponse : newClient
    //   // setClient(updatedClient)
    // } catch (err) {
    //   const errorMessage: string | undefined = err?.response?.data?.message
    //   const duplicateErrorMessagePrefix = 'The values from these fields already exist in the database'
    //   const tmpHelperTexts = {
    //     email: '',
    //     ssn_ein: '',
    //   }
    //   if (errorMessage && errorMessage.startsWith(duplicateErrorMessagePrefix)) {
    //     if (errorMessage.includes('email')) {
    //       clientEditErrors.setError('email', true)
    //       tmpHelperTexts.email = 'This email is already in use'
    //     }
    //     if (errorMessage.includes('ssn_ein')) {
    //       clientEditErrors.setError('ssn_ein', true)
    //       tmpHelperTexts.ssn_ein = 'This SSN/EIN is already in use'
    //     }
    //     setHelperTexts(tmpHelperTexts)
    //     return false
    //   }
    //   showApiResponseError(err, 'Could not save changes')
    //   return false
    // }
    return true
  }, [
    validateFields,
    formMode,
    addressesToDelete.length,
    addressesToEdit.length,
    client,
    addressesToAdd,
    sendAddressDeletionRequests,
    sendAddressUpdateRequests,
    clientEditErrors,
  ])

  const onFormSubmitted = React.useCallback(() => {
    assertNonNullable(client.id)
    const existingClient: ICreatedEmployee = { ...client, id: client.id }
    onRequestSuccessful(existingClient)
  }, [onRequestSuccessful, client])

  const handleAddAddressClick = React.useCallback(() => {
    setShowAddAddressDialog(true)
  }, [setShowAddAddressDialog])

  const removeAddressFieldsErrors = React.useCallback((key: AddressError) => addressEditErrors.setError(key, false), [
    addressEditErrors,
  ])

  const onAddressFieldsChange = React.useCallback(
    <K extends keyof IAddress>(key: K) => (value: IAddress[K]) => {
      removeAddressFieldsErrors(key as AddressError)
      setCurrentEditedAddress({
        ...currentEditedAddress,
        [key]: value,
      })
    },
    [removeAddressFieldsErrors, setCurrentEditedAddress, currentEditedAddress]
  )

  // We cannot really use `useMemo()` here, because it will cause error messages to appear once
  // we open addressEditionDialog. The reason for that is, that once we open it, fields are empty.
  // Empty fields are not valid for a request, but are OK for initial state of dialog or any other moment,
  // while editing these fields. Address errors must be set if necessary only when submit button is clicked,
  // `useCallback()` fits this purpose. Using `useMemo()` causes errors to be set immediately,
  // and to be set always, when a field is empty.
  const validateNewAddressFields = React.useCallback(() => {
    const { country, city, street, postal_code } = currentEditedAddress
    const fieldsToValidate = { country, city, street, postal_code }
    addressEditErrors.setError('country', country.length === 0)
    addressEditErrors.setError('city', city.length === 0)
    addressEditErrors.setError('street', street.length === 0)
    addressEditErrors.setError('postal_code', postal_code.length === 0)
    return !Object.values(fieldsToValidate).some(string => string.length === 0)
  }, [currentEditedAddress, addressEditErrors])

  const updateAddress = React.useCallback((): void | undefined => {
    if (editedAddressId === undefined) return
    const { addresses } = client
    const indexToUpdate = addresses && addresses.findIndex(address => address.id === editedAddressId)
    // checks for zero inequality
    // checks for zero inequality
    if (indexToUpdate === undefined) return
    const addressesWithUpdatedAddress = [...(client.addresses || [])]
    addressesWithUpdatedAddress[indexToUpdate] = currentEditedAddress
    setClient({
      ...client,
      addresses: addressesWithUpdatedAddress,
    })
    setEditedAddressId(undefined)
    setAddressesToEdit([...addressesToEdit, currentEditedAddress])
    setCurrentEditedAddress(emptyAddress)
  }, [editedAddressId, client, addressesToEdit, setAddressesToEdit, currentEditedAddress])

  const handleAddAddress = React.useCallback(() => {
    if (!validateNewAddressFields()) return false

    if (editedAddressId !== undefined) {
      updateAddress()
    } else {
      // in case new address is a primary address, reset the current primary address
      const newAddresses: IAddress[] = client.addresses ? deepcopy(client.addresses) : []
      if (currentEditedAddress.is_primary && client.addresses) {
        const primaryAddress = newAddresses.find(address => address.is_primary)
        if (primaryAddress) {
          primaryAddress.is_primary = false
        }
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
      }
      currentEditedAddress.id = client.addresses && client.addresses.length ? client.addresses.length * -1 : 0
      newAddresses.push(currentEditedAddress)
      setClient({ ...client, addresses: newAddresses })

      currentEditedAddress.client_id = client.id

      setCurrentEditedAddress(emptyAddress)
      setAddressesToAdd([...addressesToAdd, currentEditedAddress])
      toggleAddAddressDialog()
    }
    return true
  }, [
    validateNewAddressFields,
    editedAddressId,
    updateAddress,
    client,
    currentEditedAddress,
    addressesToAdd,
    toggleAddAddressDialog,
    clientEditErrors,
  ])

  const handlePrimaryAddressChange = React.useCallback(
    (address: IAddress) => (checked: boolean) => {
      if (checked) {
        if (clientEditErrors.no_primary_address) clientEditErrors.setError('no_primary_address', false)
        const addresses = addressesWithNewPrimary(client.addresses || [], address)
        setClient({ ...client, addresses: addresses })
      }
    },
    [clientEditErrors, client]
  )

  const handlePrimaryAddressChecked = React.useCallback(
    (checked: boolean) => {
      setCurrentEditedAddress({
        ...currentEditedAddress,
        is_primary: checked,
      })
    },
    [setCurrentEditedAddress, currentEditedAddress]
  )

  const handleAddressDeleteClick = React.useCallback(
    (id: number) => {
      setClient({ ...client, addresses: client.addresses && client.addresses.filter(address => address.id !== id) })
      if (id > 0) setAddressesToDelete([...addressesToDelete, id])
    },
    [setClient, setAddressesToDelete, addressesToDelete, client]
  )

  const handleAddressEditClick = React.useCallback(
    (id: number) => {
      setEditedAddressId(id)
      setCurrentEditedAddress((client.addresses && client.addresses.find(address => address.id === id)) || emptyAddress)
      setShowAddAddressDialog(true)
    },
    [setEditedAddressId, setCurrentEditedAddress, setShowAddAddressDialog, client.addresses]
  )

  // Here we navigate to companies page and pass this client object there.
  // If that component will find this object on `router.history.location.state`,
  // company creation flow dialog will be opened automatically with prefilled client field.
  const createCompany = React.useCallback(() => {
    history.push('/companies', { client })
  }, [client, history])

  const navigateToAssociateCompanies = React.useCallback(() => {
    history.push(`/companies?query=${encodeURI(client.name)}`)
  }, [client.name, history])

  const classes = useStyles()

  const { country, city, street, postal_code } = addressEditErrors

  // const salesRepresentative: ISuggestionAttribute | undefined = useMemo(
  //   () =>
  //     editedClient?.sales_rep?.id
  //       ? { label: editedClient.sales_rep.name, value: editedClient.sales_rep.id }
  //       : undefined,
  //   [editedClient]
  // )

  // const affiliate: ISuggestionAttribute | undefined = useMemo(
  //   () =>
  //     editedClient?.affiliate?.id
  //       ? { label: editedClient.affiliate.name, value: editedClient.affiliate.id }
  //       : undefined,
  //   [editedClient]
  // )
  React.useEffect(() => {
    //const tempMail: string = client.email.toString()
    if (!editedClient) {
      // if (client.email?.length >= 5) {
      //   changeClientField('username', client.first_name + Math.floor(100000 + Math.random() * 900000))
      // } else {
      //   changeClientField('username', '')
      // }
    }
  }, [client.email])
  const [errorUsername, setErrorusername] = React.useState<boolean>(false)
  const [errorUsernameText, setErrorusernameText] = React.useState<string>('')

  const [loading, setLoading] = React.useState<boolean>(false)
  const [existingUsername, setExisingUsername] = React.useState<string>(editedClient?.username || '')
  const [usernameFieldDisabled, setUsernameFieldDisabled] = React.useState<boolean>(false)

  const userNameCheck = async (username: string) => {
    try {
      setLoading(true)
      setUsernameFieldDisabled(true)
      const res: any = await checkUsername({ username: username })
      setLoading(false)
      setUsernameFieldDisabled(false)
      if (res.error && existingUsername !== client.username) {
        setErrorusername(true)
        setErrorusernameText('Sorry! username already exists!')
      } else {
        setErrorusername(false)
      }
    } catch (e) {
      showApiResponseError(e, 'Error Checking username')
    }
  }

  React.useEffect(() => {
    // const userNameCheck = async () => {
    //   try {
    //     setLoading(true)
    //     setUsernameFieldDisabled(true)
    //     const res: any = await checkUsername({ username: client.username })
    //     setLoading(false)
    //     setUsernameFieldDisabled(false)
    //     if (res.error && existingUsername !== client.username) {
    //       setErrorusername(true)
    //       setErrorusernameText('Sorry! this username is already taken!')
    //     } else {
    //       setErrorusername(false)
    //     }
    //   } catch (e) {
    //     showApiResponseError(e, 'Error Checking username')
    //   }
    // }
    const reg = /^[A-Za-z0-9]*$/
    // client.username && client.username?.length > 7 && usernameChanged && userNameCheck()

    if (client.username) {
      if (reg.test(client.username)) {
        if (client.username && client.username?.length < FIELDS_MIN_LENGTH.username) {
          setErrorusername(true)
          setErrorusernameText(`Username cannot be less than ${FIELDS_MIN_LENGTH.username} characters`)
        } else {
          setErrorusername(false)
          setErrorusernameText('')
        }
        // } else if (
        //   client.username &&
        //   client.username?.length >= FIELDS_MIN_LENGTH.username &&
        //   // reg.test(client.username) &&
        //   usernameChanged
        // )
        //   setTimeout(() => {
        //     userNameCheck()
        //   }, 500)
      } else {
        setErrorusername(true)
        setErrorusernameText(`Username should contain Alpha numeric Only`)
      }
    }
  }, [client.username])

  const onBlurCapture = () => {
    if (client.username && client.username?.length > FIELDS_MIN_LENGTH.username) {
      userNameCheck(client.username || '')
    }
  }

  const resetPass = async () => {
    try {
      const result = await resetPassword({ username: client.username })
      if (result.success) showSuccessNotification(`Password reset Email sent at ${client.email}`)
      else if (result.error) showApiResponseError(result.error, `Invalid Email, ${client.email} Doesn't exists`)
    } catch (err) {
      showApiResponseError(err.message, `Couldn't Send Reset Email!`)
    }
  }
  React.useEffect(() => {
    const getorderstatus = async () => {
      let data: any = []
      !!editedClient ? (data = await fetchlinkedwidgets(editedClient?.id)) : (data = await getwidgets())
      setwidgets(data)
    }
    getorderstatus()
  }, [])
  const handleeditstatuschange = (id: any, checked: any, index: number) => {
    const newArr = [...widgets] // copying the old datas array
    newArr[index].is_checked = !newArr[index].is_checked // replace e.target.value with whatever you want to change it to
    //newArr[index].display_order=
    setwidgets(newArr)

    const temparray: any = []
    // checked=!checked;
    temparray.push(id)
    if (checked) setstatusids([...statusids, parseInt(id)])
  }
  return (
    <React.Fragment>
      {/* <AddAddressDialog
        open={showAddAddressDialog}
        addressFields={currentEditedAddress}
        handleInputFieldsChange={onAddressFieldsChange}
        handleDialogClose={toggleAddAddressDialog}
        onAddressAdd={handleAddAddress}
        addressErrors={{
          country,
          city,
          street,
          postal_code,
        }}
        onPrimaryAddressChecked={handlePrimaryAddressChecked}
        isEditing={editedAddressId !== undefined}
        showPrimaryAddressCheckbox
      /> */}
      <div className={classes.formContainer}>
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.first_name}
          helperText={clientEditErrors.first_name && 'Please Specify First Name Of The Employee'}
          label="First Name"
          style={{ marginBottom: clientEditErrors.first_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.first_name}
          onChange={event => changeClientField('first_name', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.last_name}
          helperText={clientEditErrors.last_name && 'Please Specify Last Name Of The Employee'}
          label="Last Name"
          style={{ marginBottom: clientEditErrors.last_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.last_name}
          onChange={event => changeClientField('last_name', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-designation"
          //error={clientEditErrors.last_name}
          //helperText={clientEditErrors.last_name && 'Please Specify Last Name Of The Employee'}
          label="Position"
          style={{ marginBottom: clientEditErrors.last_name ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="designation"
          margin="normal"
          variant="outlined"
          value={client.designation}
          onChange={event => changeClientField('designation', event.target.value)}
        />
        {/* <InputLabel id="demo-simple-select-label">Employee Type </InputLabel>
        <Select
          labelId="client-employee_type"
          id="client-employee_type"
          value={client?.employee_type || ''}
          label="Employee Type"
          onChange={event => changeClientField('employee_type', event.target.value)}
        >
          <MenuItem value={'1'}>Employee</MenuItem>
          <MenuItem value={'4'}>Accountant</MenuItem>
        </Select> */}
        <OutlinedSelect
          name="Employee Type"
          errorHelperText="Please Employee Type"
          value={client?.employee_type}
          options={['Employee', 'Accountant', 'Sales Agent']}
          onOptionSelect={(value: any) => changeClientField('employee_type', value as string)}
        />
        <OutlinedSelect
          name="Employee Role"
          errorHelperText="Please Select Employee Role"
          value={client?.employee_role}
          options={[EMPLOYEE_ROLES.junior, EMPLOYEE_ROLES.senior]}
          onOptionSelect={(value: any) => changeClientField('employee_role', value as string)}
        />
        <BRTextField
          showCopyButton={!!editedClient}
          required
          data-testid="client-email"
          error={clientEditErrors.email}
          helperText={helperTexts.email}
          label="Email"
          className={classes.textField}
          style={{ marginBottom: clientEditErrors.email ? '1.5rem' : undefined }}
          type="text"
          name="email"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          variant="outlined"
          value={client.email}
          onChange={event => changeClientField('email', normalizeText(event.target.value))}
        />
        <br />
        <FormControl className={classes.fullWidth}>
          <FormLabel style={{ fontSize: '0.85rem' }}>Personal Phone number</FormLabel>
          <PhoneInput
            showCopyButton={!!editedClient}
            value={client.phone || ''}
            onChange={value => changeClientField('phone', value)}
            error={clientEditErrors['phone']}
          />
          {clientEditErrors['phone'] && (
            <FormHelperText className={classes.errorHelperText}>Please enter phone number</FormHelperText>
          )}
        </FormControl>
        <br />
        <FormControl className={classes.fullWidth}>
          <FormLabel style={{ fontSize: '0.85rem' }}>Office Phone number</FormLabel>
          <PhoneInput
            showCopyButton={!!editedClient}
            value={client.phone_office || ''}
            onChange={value => changeClientField('phone_office', value)}
            error={clientEditErrors['phone_office']}
          />
          {clientEditErrors['phone_office'] && (
            <FormHelperText className={classes.errorHelperText}>Please enter phone number</FormHelperText>
          )}
        </FormControl>

        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-name"
          error={clientEditErrors.meetinglink}
          helperText={clientEditErrors.meetinglink && 'Please Specify meeting link'}
          label="Meeting Link"
          style={{ marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.meeting_link}
          onChange={event => changeClientField('meeting_link', event.target.value)}
        />
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          showCopyButton={!!editedClient}
          required
          data-testid="client-ip"
          // error={clientEditErrors.meetinglink}
          // helperText={clientEditErrors.meetinglink && 'Please Specify meeting link'}
          label="Allowed IP"
          style={{ marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="allowed_ip"
          margin="normal"
          variant="outlined"
          value={client.allowed_ip}
          onChange={event => changeClientField('allowed_ip', event.target.value)}
        />
        {url !== '' ? (
          <div className={classes.imgDiv}>
            <ImageUpload
              // We show copy button in these fields only if we're looking at existing client, not creating a new one.
              showCopyButton={!!editedClient}
              required
              data-testid="client-name"
              error={clientEditErrors.meetinglink}
              ///helperText={clientEditErrors. && 'Please Specify image'}
              label="Employee image"
              style={{
                marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined,
                width: url !== '' ? '100%' : '100%',
              }}
              className={classes.textField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorHelperText,
                },
              }}
              type="file"
              name="image"
              margin="normal"
              variant="outlined"
              id="empimg"
              // value={client.image}
              // onChange={event => changeClientField('image', event.target.value)}
              // disabled
            />

            {url !== '' ? (
              //  <img style={{ paddingTop: '3.5%' }} src={url} height="14%" width="12%" />
              <div style={{ width: '85px' }}>
                <Avatar alt="avatar" src={url} className={classes.userAvatar} />
              </div>
            ) : null}
          </div>
        ) : (
          <ImageUpload
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            showCopyButton={!!editedClient}
            required
            data-testid="client-name"
            error={clientEditErrors.meetinglink}
            ///helperText={clientEditErrors. && 'Please Specify image'}
            label="Employee image"
            style={{ marginBottom: clientEditErrors.meetinglink ? '1.5rem' : undefined, width: '100%' }}
            className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="file"
            name="image"
            margin="normal"
            variant="outlined"
            id="empimg"
            // value={client.image}
            // onChange={event => changeClientField('image', event.target.value)}
            // disabled
          />
        )}
        {/* <input id="imageInput" type="file" accept="image/*"/> */}
        {/* <EinSsnInput
          showCopyButton={!!editedClient}
          ssnEinHelperText={helperTexts.ssn_ein}
          ssnEinError={clientEditErrors.ssn_ein}
          value={client.ssn_ein}
          onChange={(value: string) => changeClientField('ssn_ein', positiveNumericInput(value))}
        />
        /> */}
        {/* {editedClient && <CommissionAccordion editedClient={editedClient} />*/}
        <span className={classes.HelperTextUsername}>
          To Create an Account Kindly choose Username of minimum length 8
        </span>
        <BRTextField
          // We show copy button in these fields only if we're looking at existing client, not creating a new one.
          disabled={usernameFieldDisabled}
          showCopyButton={!!editedClient}
          data-testid="client-username"
          error={errorUsername}
          helperText={errorUsername && errorUsernameText}
          label="User Name"
          style={{ marginBottom: errorUsername ? '2.5rem' : undefined }}
          className={classes.textField}
          FormHelperTextProps={{
            classes: {
              root: classes.errorHelperText,
            },
          }}
          type="text"
          name="name"
          margin="normal"
          variant="outlined"
          value={client.username}
          onChange={event => changeClientField('username', event.target.value)}
          onBlur={onBlurCapture}
        />
        {loading && (
          <div className={classes.rootProgress}>
            <LinearProgress />
          </div>
        )}

        <div className={classes.inlineCheck}>
          <span style={{ fontSize: '19px' }}>Show Tax widget</span>
          <Checkbox
            color="primary"
            // checked={internal_rush_processing}
            checked={client.tax_team}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            // disabled={disabled}
            value={client.tax_team}
            style={{ padding: 0, marginRight: 10 }}
            onChange={e => changeClientField('tax_team', e.target.checked)}
          />
        </div>

        <Typography className={classes.title}>DashBoard Preferences</Typography>

        <TableLoadingIndicator isLoading={true} />

        <StyledTable style={{ width: '200%' }}>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell>Name</StyledTableCell>
              {/* <StyledTableCell>Display Order</StyledTableCell> */}

              <StyledTableCell>Action</StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {widgets.map((item: any, index: number) => {
              return (
                <StyledTableRow className={classes.row} key={item.id}>
                  <StyledTableCell>{item.name}</StyledTableCell>
                  <StyledTableCell>
                    {!!editedClient ? (
                      <Checkbox
                        color="primary"
                        checked={item.is_checked}
                        // `value` prop is added here for easier testing.
                        // because MUI only changes svg image when checkbox is clicked
                        value={item.id}
                        style={{ padding: 0, marginRight: 10 }}
                        onChange={e => handleeditstatuschange(e.target.value, e.target.checked, index)}
                      />
                    ) : (
                      <Checkbox
                        color="primary"
                        // `value` prop is added here for easier testing.
                        // because MUI only changes svg image when checkbox is clicked
                        value={item.id}
                        style={{ padding: 0, marginRight: 10 }}
                        onChange={e => handleeditstatuschange(e.target.value, e.target.checked, index)}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </StyledTable>

        {/* tax widget get calls  */}
        <Typography className={classes.title}>Tax Order Preferences</Typography>

        <TableLoadingIndicator isLoading={true} />

        <StyledTable style={{ width: '200%' }}>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {taxWidgets.map((item: any, index: number) => {
              return (
                <StyledTableRow className={classes.row} key={item.id}>
                  <StyledTableCell>{item.title}</StyledTableCell>
                  <StyledTableCell>
                    {!!editedClient ? (
                      <Checkbox
                        color="primary"
                        checked={item.is_checked}
                        value={item.id}
                        style={{ padding: 0, marginRight: 10 }}
                        onChange={e => handleTaxEditStatus(e.target.value, e.target.checked, index)}
                      />
                    ) : (
                      <Checkbox
                        color="primary"
                        value={item.id}
                        style={{ padding: 0, marginRight: 10 }}
                        onChange={e => handleTaxEditStatus(e.target.value, e.target.checked, index)}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </StyledTable>

        <SubmitFormButton
          // onFormSubmitted={onFormSubmitted}
          title={editedClient ? 'Save Changes' : 'Add Employee'}
          onClick={onSubmitClick}
        />
        {editedClient && (
          <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={resetPass}>
            Reset Password{' '}
          </ButtonWithLoading>
        )}
      </div>
    </React.Fragment>
  )
}

export default EmployeeDetails
