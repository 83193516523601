import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    heading: {
      color: '#407ace',
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    text: {
      fontSize: '0.7rem',
      padding: '0.4rem 2rem',
      borderRadius: '12px',
      backgroundColor: '#fff',
      marginLeft: '2rem',
    },
  })
)

interface OfferDetailProps {
  count: string
  description: string
  isLoading?: boolean
}

const OfferDetail = ({ count, description, isLoading }: OfferDetailProps) => {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <p className={classes.heading}>{!isLoading ? count : <Skeleton variant="rect" width={50} />}</p>
      <span className={classes.text}>{!isLoading ? description : <Skeleton variant="rect" width={50} />}</span>
    </div>
  )
}

export default OfferDetail
