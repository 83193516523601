import { TableRow, TableCell } from '@material-ui/core'
import { IClientLogs } from 'api/clients'
import { EmployeeReportData } from 'api/employeeReport'
import React from 'react'
import { USER_TYPES } from 'utils/constant'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

interface LogsTableRowProps {
  tableReport: IClientLogs
}

const LogsTableRow: React.FC<LogsTableRowProps> = ({ tableReport }) => {
  return (
    <TableRow data-testid={`row-for-report-with-id-${tableReport?.user_log?.user_id}`}>
      <TableCell style={{}}>{formatDateTimeToHumanFriendly(tableReport?.user_log?.logged_at || '') || ''}</TableCell>
      <TableCell style={{}}>
        {tableReport?.user_log?.loggedOut_at
          ? formatDateTimeToHumanFriendly(tableReport?.user_log?.loggedOut_at || '')
          : ''}
      </TableCell>
      {/* <TableCell style={{}}>{`${tableReport?.time_spent} Hour`}</TableCell> */}
      {/* <TableCell style={{}}>{tableReport?.user_clicks}</TableCell>
      <TableCell style={{}}>{tableReport?.page_visits}</TableCell>
      <TableCell style={{}}>
        {(tableReport?.user_clicks > 0 ? tableReport?.page_visits / tableReport?.user_clicks : 0).toFixed(2)}
      </TableCell>
      <TableCell style={{}}>{tableReport?.order_status_updated}</TableCell>
      <TableCell style={{}}>{tableReport?.order_updated}</TableCell>
      <TableCell style={{}}>{tableReport?.notes_created}</TableCell>
      <TableCell style={{}}>{tableReport?.task_created}</TableCell>
      <TableCell style={{}}>{tableReport?.task_completed}</TableCell>
      <TableCell style={{}}>{tableReport?.task_updated}</TableCell> */}
    </TableRow>
  )
}

export default LogsTableRow
