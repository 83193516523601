import { HeadColumn, SortableTableHead } from 'components/common/table'
import { ISortSettings } from 'hooks/useSortTable'
import * as React from 'react'

const headColumns: HeadColumn[] = [
  { id: 'id', label: 'ID', style: {}, sortable: true },
  { id: 'file_name', label: 'File Name', style: {}, sortable: false },
  { id: 'uploaded_by', label: 'Uploaded By', style: {}, sortable: true },
  { id: 'uploaded_on', label: 'Uploaded On', style: {}, sortable: false },
  { id: 'actions', label: 'Actions', style: { minWidth: 110 }, sortable: false },
]

const Head: React.FC<{
  sortSettings: ISortSettings
  handleSortingClick: (sort_by: string) => void
}> = ({ sortSettings, handleSortingClick }) => {
  return (
    <SortableTableHead
      headColumns={headColumns}
      sortBy={sortSettings.sort_by}
      sortOrder={sortSettings.order}
      handleSortingClick={handleSortingClick}
    />
  )
}

export default Head
