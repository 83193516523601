import React from 'react'

interface ITasksContext {
  reloadTasks: () => void
}

export const TasksContext = React.createContext<ITasksContext>({
  reloadTasks: () => {
    throw new Error('TasksContext is not initialized ')
  },
})
