import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { getOrderDetails, IOrderDetailsResponse } from 'api/orders'
import { default as classnames, default as classNames } from 'classnames'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'
import CircleCheckbox from 'components/common/elements/CircleCheckbox'
import CollapseIcon from 'components/common/icons/collapse'
import DeleteIcon from 'components/common/icons/delete'
import EditIcon from 'components/common/icons/edit'
import ExpandIcon from 'components/common/icons/expand'
import CreateTaskDialog from 'components/tasks/create/createDialog'
import TaskDetailsRow from 'components/tasks/taskRowDetails'
import useDeleteTask from 'hooks/useDeleteTask'
import useTaskComplete from 'hooks/useTaskComplete'
import { IOrderTask, ITask } from 'models/task'
import moment from 'moment'
import { OrderDetailsContext } from 'pages/orders/orderDetails/OrderDetailsContext'
import * as React from 'react'
import { useCallback } from 'react'
import useRouter from 'use-react-router'
import { getDueDate } from 'utils/formatDate'
import { TasksContext } from 'contexts/tasks'
import { Chip, Typography } from '@material-ui/core'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { COLORS } from 'utils/constant'
import Badge from '@material-ui/core/Badge'
import { toTitleCase } from 'utils/toTitleCase'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleCell: { padding: 0, borderBottom: 'none' },
    taskTitleCell: { fontWeight: 'bold', paddingLeft: 12 },
    overdueRow: {
      backgroundColor: COLORS.overDue,
    },
    taskTypeCell: { textTransform: 'capitalize' },
    overdueDueDateCell: { color: 'red' },
    actionButton: { width: 43, height: 43 },
    secondActionButton: { marginLeft: 10, marginRight: 10 },
    taskPriorityCell: {
      textTransform: 'capitalize',
      color: '#020202',
      fontWeight: 'bold',
    },
    lowPriority: {
      color: theme.lowPriority.color,
    },
    mediumPriority: {
      color: theme.mediumPriority.color,
    },
    highPriority: {
      color: theme.highPriority.color,
    },
    iconsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    taskTitleCheckBoxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    taskTitle: {
      display: 'inline-block',
      // maxWidth: 170,
      color: 'black', //'#6666FF',
    },
    tableRowBackground: {
      '&:hover': {
        backgroundColor: '#fbffb3',
      },
      cursor: 'pointer',
    },
    completedBy: {
      marginLeft: 10,
      marginTop: 10,
    },
    completed: {
      backgroundColor: '#a3e362',
    },
    rushTask: {
      backgroundColor: COLORS.rushOrders,
    },
    badge: {
      width: 'max-content',
      marginLeft: '28px',
    },
  })
)

export const LEVEL_SHIFT_WIDTH = 30

interface SubtaskRowsProps {
  task: ITask
  shiftLevel: number
}

const SubtaskRows: React.FC<SubtaskRowsProps> = ({ task, shiftLevel }) => {
  if (!('subtasks' in task)) return null

  // only the order task can have subtasks
  const orderTask = task as IOrderTask

  return (
    <React.Fragment>
      {orderTask.subtasks?.map(value => (
        <TaskRow task={value} shiftLevel={shiftLevel + 1} />
      ))}
    </React.Fragment>
  )
}

const useOrderDetails = (orderId?: number | null) => {
  const [orderDetails, setOrderDetails] = React.useState<IOrderDetailsResponse>()

  React.useEffect(() => {
    const fetchAndSetOrderDetails = async () => {
      const orderDetails = orderId ? await getOrderDetails(orderId) : undefined
      setOrderDetails(orderDetails)
    }

    fetchAndSetOrderDetails()
  }, [orderId])

  return {
    orderDetails,
  }
}

interface ITaskRowProps {
  task: ITask
  shiftLevel?: number
}

const TaskRow = ({ task, shiftLevel = 0 }: ITaskRowProps) => {
  const classes = useStyles()
  const overdue = task.due_date ? moment(task.due_date).isBefore(moment(), 'day') : false

  const priorityCellColorClass = {
    [classes.lowPriority]: task.priority === 'low',
    [classes.mediumPriority]: task.priority === 'medium',
    [classes.highPriority]: task.priority === 'high',
  }

  const [expanded, setExpanded] = React.useState<boolean>(false)
  const [showEdit, setShowEdit] = React.useState<boolean>(false)

  const { showDelete, setShowDelete, deleteTaskCallback, isDeleting } = useDeleteTask()

  const { completeTask } = useTaskComplete()

  const toggleShowEdit = React.useCallback(() => {
    setShowEdit(prev => !prev)
  }, [])

  const onEditCLick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      toggleShowEdit()
    },
    [toggleShowEdit]
  )

  const { reloadTasks } = React.useContext(TasksContext)

  const toggleExpansion = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setExpanded(prev => !prev)
  }, [])

  const onDeleteClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setShowDelete(true)
    },
    [setShowDelete]
  )

  const { orderDetails } = useOrderDetails(task.task_type === 'order' ? task.order_id : null)

  const companyName = task.company?.name || orderDetails?.company?.name

  const clientName = task.client?.name || orderDetails?.company?.client?.name

  const { history } = useRouter()

  const goToDetailsPage = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      event.stopPropagation()
      if (task?.order_id) history.push(`/company-order/${task?.order_id}`)
      if (task?.prospect_id) history.push(`/prospect/${task?.prospect_id}`)
      if (task?.company_id) history.push(`/company/${task?.company_id}`)
      if (task?.client_id) history.push(`/client/${task?.client_id}`)
      if (task?.tax_order_id) history.push(`/tax-orders/${task?.tax_order_id}`)
    },
    [history, orderDetails, task]
  )
  const settaskpriority = (id: any) => {
    id = parseInt(id)
    if (id === 1) return 'Daily'
    else if (id === 2) return 'Weekly'
    else if (id === 3) return 'Call back'
  }
  return (
    <React.Fragment>
      <DeleteConfirmationDialog
        isDeleting={isDeleting}
        itemType="task"
        onDelete={() => task.id && deleteTaskCallback(task.id)}
        open={showDelete}
        itemName={task.title}
        onClose={() => setShowDelete(false)}
      />

      <OrderDetailsContext.Provider value={orderDetails}>
        <CreateTaskDialog onFormSubmitted={reloadTasks} taskToEdit={task} open={showEdit} toggleOpen={toggleShowEdit} />
      </OrderDetailsContext.Provider>
      {/* {task.map((task:any)=>{

      })} */}
      <TableRow
        className={classNames(
          overdue ? classes.overdueRow : undefined,
          orderDetails?.is_rush_order && !task.completed ? classes.rushTask : undefined,
          task.completed ? classes.completed : classes.tableRowBackground
        )}
        data-testid={`task-${task.id}-row`}
        key={task.id}
        onClick={e => setExpanded(prev => !prev)}
        //style={{ backgroundColor: orderDetails?.is_rush_order ? COLORS.rushOrders : undefined }}
      >
        <TableCell className={classes.taskTitleCell}>
          <div
            className={classes.taskTitleCheckBoxContainer}
            style={{
              marginLeft: shiftLevel * LEVEL_SHIFT_WIDTH,
            }}
          >
            <CircleCheckbox
              dataTestId={`task-${task.id}-checkbox`}
              onChange={() => completeTask(task)}
              checked={!!task.completed}
            />
            <span className={classes.taskTitle}>
              {task.title}
              {shiftLevel === 0 && orderDetails?.order_type?.name && (
                // <span>

                // </span>
                <>
                  <br />
                  <Chip label={orderDetails.order_type.name} size="small" style={{ marginTop: '5px' }} />
                  {/* <Badge
                    badgeContent={orderDetails.order_type.name}
                    color="primary"
                    // style={{ width: 'max-content', marginLeft: '28px' }}
                  >
                    {' '}
                  </Badge> */}
                </>
              )}
              {/* <Typography className={classes.completedBy}>
                <b>Created by:</b> {task.created_by || 'unknown'} at{' '}
                {task.created_at !== null ? formatDateTimeToHumanFriendly(task.created_at || '') : 'unknown date'}
              </Typography>
              <Typography className={classes.completedBy}>
                <b>Assigned by:</b> {task.assigned_by || 'unknown'} <b> to</b>{' '}
                {task.assignee ? task.assignee.name : '-'} at{' '}
                {task.assigned_at !== null ? formatDateTimeToHumanFriendly(task.assigned_at || '') : 'unknown date'}
              </Typography> */}

              {/* {task.completed && (
                <Typography className={classes.completedBy}>
                  <b>Completed by:</b> {(task.completed_by && task.completed_by.name) || 'unknown'} at{' '}
                  {formatDateTimeToHumanFriendly(task.completed_at || '')}
                </Typography>
              )} */}
              {!!task.completed && task.completed_by && (
                <span>
                  <br />
                  <b style={{ color: 'black' }}>Completed By </b>{' '}
                  <b style={{ color: 'black', fontWeight: 'lighter' }}>
                    {task.completed_by?.name} at{' '}
                    {task.completed_at ? formatDateTimeToHumanFriendly(task.completed_at) : ''}
                  </b>
                </span>
              )}
            </span>
          </div>
        </TableCell>

        {/* <TableCell className={classnames(classes.taskPriorityCell, priorityCellColorClass)}>
          {settaskpriority(task.priority)}
        </TableCell> */}

        {/* <TableCell className={classes.taskTypeCell}>{task.task_type} Task</TableCell> */}

        <TableCell>{companyName}</TableCell>

        <TableCell>{clientName}</TableCell>

        <TableCell>{task.due_date ? getDueDate(task.due_date) : ''}</TableCell>
        <TableCell>
          {task.task_type ? toTitleCase(task.task_type) : ''}{' '}
          {task.tax_order_type ? toTitleCase(task.tax_order_type) : ''} {task.tax_year ? task.tax_year : ''}
        </TableCell>

        <TableCell align="right">
          <div className={classes.iconsContainer}>
            <TableActionButton
              onClick={onEditCLick}
              className={classes.actionButton}
              data-testid={`edit-task-${task.id}`}
            >
              <EditIcon />
            </TableActionButton>

            <TableActionButton
              onClick={onDeleteClick}
              className={classnames(classes.actionButton, classes.secondActionButton)}
              data-testid={`remove-task-${task.id}`}
            >
              <DeleteIcon />
            </TableActionButton>

            <TableActionButton
              onClick={toggleExpansion}
              className={classes.actionButton}
              data-testid={`expand-task-${task.id}`}
            >
              {expanded ? <CollapseIcon /> : <ExpandIcon />}
            </TableActionButton>
          </div>
        </TableCell>
      </TableRow>

      {/* <TableRow
        className={classNames(overdue ? classes.overdueRow : undefined, classes.tableRowBackground)}
        data-testid={`task-${task.id}-row`}
        key={task.id}
        onClick={goToOrderDetailsPage}
      >
        <TableCell className={classes.taskTitleCell}>
          <div
            className={classes.taskTitleCheckBoxContainer}
            style={{
              marginLeft: shiftLevel * LEVEL_SHIFT_WIDTH,
            }}
          >
            <CircleCheckbox
              dataTestId={`task-${task.id}-checkbox`}
              onChange={() => completeTask(task)}
              checked={!!task.completed}
            />
            <span className={classes.taskTitle}>
              {task[0].title}
              {shiftLevel === 0 && orderDetails?.order_type?.name && (
                <span>
                  <br />({orderDetails.order_type.name})
                </span>
              )}
            </span>
          </div>
        </TableCell>

        <TableCell className={classnames(classes.taskPriorityCell, priorityCellColorClass)}>{task.priority}</TableCell>

        <TableCell className={classes.taskTypeCell}>{task.task_type} Task</TableCell>

        <TableCell>{companyName}</TableCell>

        <TableCell>{clientName}</TableCell>

        <TableCell>{task.due_date ? getDueDate(task.due_date) : ''}</TableCell>

        <TableCell align="right">
          <div className={classes.iconsContainer}>
            <TableActionButton
              onClick={onEditCLick}
              className={classes.actionButton}
              data-testid={`edit-task-${task.id}`}
            >
              <EditIcon />
            </TableActionButton>

            <TableActionButton
              onClick={onDeleteClick}
              className={classnames(classes.actionButton, classes.secondActionButton)}
              data-testid={`remove-task-${task.id}`}
            >
              <DeleteIcon />
            </TableActionButton>

            <TableActionButton
              onClick={toggleExpansion}
              className={classes.actionButton}
              data-testid={`expand-task-${task.id}`}
            >
              {expanded ? <CollapseIcon /> : <ExpandIcon />}
            </TableActionButton>
          </div>
        </TableCell>
      </TableRow> */}

      <TaskDetailsRow task={task} expanded={expanded} goToDetailsPage={goToDetailsPage} />

      <SubtaskRows task={task} shiftLevel={shiftLevel} />
    </React.Fragment>
  )
}

export default TaskRow
