import { Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import * as React from 'react'
import { emptyFunction } from 'utils/objectUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: 34,
    },
    container: {
      display: 'flex',
      width: 150,
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#ececee',
    },
    title: {
      fontSize: 11,
      marginTop: 1,
      letterSpacing: 0.05,
    },
    checkBoxRoot: {
      padding: 5,
    },
  })
)
interface IToggleDoneTasksContext {
  value: boolean
  onChange: (showDone: boolean) => void
}

/**
 * ToggleShowDoneTasksContext is a context to show whether completed tasks
 * should be shown on tasks table or they should be hidden.
 *
 * @param value - whether to show completed tasks
 * @param onChange - change value of `value`
 */
export const ToggleShowDoneTasksContext = React.createContext<IToggleDoneTasksContext>({
  value: false,
  onChange: emptyFunction,
})

function ToggleShowDoneTasks() {
  const { onChange, value } = React.useContext(ToggleShowDoneTasksContext)
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked)
    },
    [onChange]
  )
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Checkbox classes={{ root: classes.checkBoxRoot }} checked={value} color="primary" onChange={handleChange} />
        <Typography className={classes.title}>Show Done Tasks</Typography>
      </div>
    </div>
  )
}

export default ToggleShowDoneTasks
