import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.customPalette.secondaryBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 100px',
  },
  textFieldInputRoot: {
    padding: '15px 20px 14px 20px',
    minHeight: 50,
    borderRadius: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    '&.MuiInput-root.MuiInputBase-root:hover:before': {
      borderBottom: 'none',
    },
    borderColor: 'white',
  },
  textInputUnderline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
  },
  autocompleteInputRoot: {
    padding: '0 20px 14px 20px',
  },
  fieldLabel: {
    textTransform: 'uppercase',
    opacity: 0.6,
    fontSize: 14,
    letterSpacing: 0.07,
    color: theme.customPalette.primaryFontColor,
    marginBottom: 3,
    '&.Mui-focused': {
      color: theme.customPalette.primaryFontColor,
    },
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  fieldRowWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    paddingTop: 30,
  },
  fieldRoot: {
    margin: 0,
    width: 350,
    alignSelf: 'center',
  },
  saveButton: {
    marginTop: 40,
    width: 250,
  },
  datePickerContainer: {
    margin: '0 !important',
    width: 350,
    alignSelf: 'center',
  },
  fieldLabelShrink: {
    transform: 'translate(0, -4px)',
  },
  customFieldContainer: {
    margin: 0,
    width: 350,
    alignSelf: 'center',
  },
  copyButtonContainer: {
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
  },
}))
