interface ITextFieldStyleProps {
  error?: boolean
  errorHelperTextClass?: string
}

/**
 * apply a common outlined style to all textfields across the app
 * error - boolean to apply the error styles
 * errorHelperTextClass - class to apply the error style to the TextField component
 */
const getTextFieldCommonProps = ({ error, errorHelperTextClass }: ITextFieldStyleProps) => {
  const style = { marginBottom: error ? '1.5rem' : undefined }
  const FormHelperTextProps = {
    classes: {
      root: errorHelperTextClass,
    },
  }

  return {
    style,
    error,
    margin: 'normal' as 'normal',
    variant: 'outlined' as 'outlined',
    FormHelperTextProps,
  }
}

export default getTextFieldCommonProps
