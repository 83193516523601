/* eslint-disable max-lines */
import { Chip, Container, createStyles, Grid, LinearProgress, List, makeStyles, Theme } from '@material-ui/core'
import SubmitFormButton from 'components/common/buttons/submitFormButton'
import BRTextField from 'components/common/textFields/BRTextField'
import Select from 'react-select'
import * as React from 'react'
import showApiResponseError from 'utils/showApiResponseError'
import { FormMode } from '../../common/forms/FormMode'
import grey from '@material-ui/core/colors/grey'
import { CancelOutlined } from '@material-ui/icons'
import { getTaxYearFilterOptions } from 'api/suggestions'
import showErrorNotification from 'utils/showErrorNotification'
import { getAccountants, getTaxLabels } from 'api/taxOrders'
import { getStaffName } from 'api/complianceCalendar'
import { ISuggestionAttribute } from 'components/interfaces'
import { TAX_ORDER_STAGES, TAX_ORDER_STATUS, TAX_ORDER_TYPES } from 'utils/constant'
import { ITaxStructureArr, TaxServicesArr } from 'models/taxOrder'
import { handleChange, handleSeprateKeyValue, onDeleteClick } from './handler'
import { createTaxWidget, ITaxWidgetsReturnType, SalectList, updateTaxWidget } from 'api/taxWidgets'
import chroma from 'chroma-js'
import showSuccessNotification from 'utils/showSuccessNotification'

interface IWidgetDetailsProps {
  editedTaxWidget?: ITaxWidgetsReturnType
  onRequestSuccessful?: () => void
}

const WidgetDetails: React.FC<IWidgetDetailsProps> = ({ editedTaxWidget, onRequestSuccessful }) => {
  const formMode: FormMode = editedTaxWidget ? FormMode.UPDATE : FormMode.CREATE
  const classes = useStyles()

  const [listLoading, setListLoading] = React.useState(false)

  const [labels, setLabels] = React.useState<ISuggestionAttribute[]>([])
  const [accountants, setAccountants] = React.useState<ISuggestionAttribute[]>([])
  const [taxControllers, setTaxControllers] = React.useState<ISuggestionAttribute[]>([])

  const [widgetName, setWidgetName] = React.useState<string>('')
  const [selectedCPA, setSelectedCPA] = React.useState<SalectList[]>([])
  const [selectedYear, setSelectedYear] = React.useState<SalectList[]>([])
  const [selectedLabel, setSelectedLabel] = React.useState<SalectList[]>([])
  const [selectedStage, setSelectedStage] = React.useState<SalectList[]>([])
  const [selectedStatus, setSelectedStatus] = React.useState<SalectList[]>([])
  const [selectedStructure, setSelectedStructure] = React.useState<SalectList[]>([])
  const [selectedAccountant, setSelectedAccountant] = React.useState<SalectList[]>([])
  const [selectedAssociate, setSelectedAssociate] = React.useState<SalectList[]>([])
  const [selectedController, setSelectedController] = React.useState<SalectList[]>([])
  const [selectedType, setSelectedType] = React.useState<SalectList[]>([])
  const [selectedOrder, setSelectedOrder] = React.useState<SalectList[]>([])
  const [selectedService, setSelectedService] = React.useState<SalectList[]>([])

  const yearSuggestions = React.useMemo(() => getTaxYearFilterOptions(), [])

  React.useEffect(() => {
    setListLoading(true)
    if (editedTaxWidget) {
      setWidgetName(editedTaxWidget?.title || '')
      setSelectedCPA(editedTaxWidget?.accountant_user_id)
      setSelectedYear(editedTaxWidget?.tax_year || [])
      setSelectedLabel(editedTaxWidget?.labels || [])
      setSelectedStage(editedTaxWidget?.order_stage || [])
      setSelectedStatus(editedTaxWidget?.question_status || [])
      setSelectedStructure(editedTaxWidget?.tax_structure || [])
      setSelectedAccountant(editedTaxWidget?.senior_cpa || [])
      setSelectedAssociate(editedTaxWidget?.tax_associate || [])
      setSelectedController(editedTaxWidget?.tax_controller || [])
      setSelectedType(editedTaxWidget?.order_type || [])
      setSelectedOrder(editedTaxWidget?.order_status || [])
      setSelectedService(editedTaxWidget?.service_type || [])
    }
    setListLoading(false)
  }, [editedTaxWidget])

  React.useEffect(() => {
    const getTaxLabel = async () => {
      setListLoading(true)
      try {
        const response = await getTaxLabels()
        setLabels(response)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
      setListLoading(false)
    }
    getTaxLabel()
  }, [])

  React.useEffect(() => {
    const getTaxAccountant = async () => {
      setListLoading(true)
      try {
        const response = await getAccountants()
        const result: any[] = response?.map(acc => {
          return { value: acc?.id, label: acc?.name }
        })
        setAccountants(result)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
      setListLoading(false)
    }
    getTaxAccountant()
  }, [])

  React.useEffect(() => {
    const getTaxController = async () => {
      setListLoading(true)
      try {
        const response = await getStaffName()
        const result: any[] = response?.map(acc => {
          return { value: acc?.id, label: acc?.name }
        })
        setTaxControllers(result)
      } catch (error) {
        showErrorNotification('Getting Error')
      }
      setListLoading(false)
    }
    getTaxController()
  }, [])

  const onSubmitClick = async (): Promise<boolean> => {
    if (!widgetName?.length) {
      showErrorNotification('Please enter Tax Widget title')
      return false
    }
    try {
      const payload = {
        title: widgetName,
        order_type: handleSeprateKeyValue(selectedType),
        tax_structure: handleSeprateKeyValue(selectedStructure),
        tax_year: handleSeprateKeyValue(selectedYear),
        order_stage: handleSeprateKeyValue(selectedStage),
        accountant_user_id: handleSeprateKeyValue(selectedCPA),
        service_type: handleSeprateKeyValue(selectedService),
        tax_associate: handleSeprateKeyValue(selectedAssociate),
        tax_controller: handleSeprateKeyValue(selectedController),
        senior_cpa: handleSeprateKeyValue(selectedAccountant),
        question_status: handleSeprateKeyValue(selectedStatus),
        order_status: handleSeprateKeyValue(selectedOrder),
        labels: handleSeprateKeyValue(selectedLabel),
      }
      setListLoading(true)
      if (formMode === FormMode.UPDATE) {
        await updateTaxWidget(editedTaxWidget?.id || 0, payload)
        showSuccessNotification('Successfully update widget!')
      } else {
        await createTaxWidget(payload)
      }
      setListLoading(false)

      onRequestSuccessful && onRequestSuccessful()
    } catch (err) {
      showApiResponseError(err, 'Could not save changes')
      return false
    }
    return true
  }

  const setColor = (bgColor: string) => {
    const Reg_Exp = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i

    if (Reg_Exp.test(bgColor)) {
      const darkContrast = chroma.contrast(`${bgColor}`, '#000')
      const lightContrast = chroma.contrast(`${bgColor}`, '#fff')
      if (darkContrast > lightContrast) {
        return '#000'
      } else {
        return '#fff'
      }
    } else {
      return '#fff'
    }
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.formContainer}>
        <div style={{ width: '500px', marginBottom: '2rem' }}>
          <BRTextField
            showCopyButton={!!editedTaxWidget}
            required
            label="Widget Name"
            className={classes.textField}
            error={widgetName?.length ? false : true}
            helperText={!widgetName?.length ? 'Please enter Tax Widget title' : ''}
            type="text"
            name="widgetName"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            focused
            autoFocus
            variant="outlined"
            value={widgetName}
            onChange={event => setWidgetName(event.target.value)}
          />
        </div>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label>CPA:</label>
            <Select
              closeMenuOnSelect={true}
              options={accountants}
              onChange={(value: ISuggestionAttribute) => handleChange(value, 'CPA', selectedCPA, setSelectedCPA)}
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedCPA?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'CPA', selectedCPA, setSelectedCPA)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Year:</label>
            <Select
              closeMenuOnSelect={true}
              options={yearSuggestions}
              onChange={(value: ISuggestionAttribute) => handleChange(value, 'Year', selectedYear, setSelectedYear)}
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedYear?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Year', selectedYear, setSelectedYear)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Label:</label>
            <Select
              closeMenuOnSelect={true}
              options={labels}
              onChange={(value: ISuggestionAttribute) => handleChange(value, 'Label', selectedLabel, setSelectedLabel)}
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedLabel?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Label', selectedLabel, setSelectedLabel)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Stage:</label>
            <Select
              closeMenuOnSelect={true}
              options={Object.values(TAX_ORDER_STAGES).map((item: any) => ({ label: item, value: item }))}
              onChange={(value: ISuggestionAttribute) => handleChange(value, 'Stage', selectedStage, setSelectedStage)}
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedStage?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Stage', selectedStage, setSelectedStage)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Question Status:</label>
            <Select
              closeMenuOnSelect={true}
              options={['Answered', 'Unanswered'].map(item => ({ label: item, value: item }))}
              onChange={(value: ISuggestionAttribute) =>
                handleChange(value, 'Status', selectedStatus, setSelectedStatus)
              }
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedStatus?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Status', selectedStatus, setSelectedStatus)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Structure:</label>
            <Select
              closeMenuOnSelect={true}
              options={TaxServicesArr.map((val: ITaxStructureArr) => ({
                value: val?.value,
                label: val?.label,
              }))}
              onChange={(value: ISuggestionAttribute) =>
                handleChange(value, 'Structure', selectedStructure, setSelectedStructure)
              }
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedStructure?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Structure', selectedStructure, setSelectedStructure)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Senior Accountant:</label>
            <Select
              closeMenuOnSelect={true}
              options={accountants}
              onChange={(value: ISuggestionAttribute) =>
                handleChange(value, 'Accountant', selectedAccountant, setSelectedAccountant)
              }
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedAccountant?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Accountant', selectedAccountant, setSelectedAccountant)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Associate:</label>
            <Select
              closeMenuOnSelect={true}
              options={taxControllers}
              onChange={(value: ISuggestionAttribute) =>
                handleChange(value, 'Associate', selectedAssociate, setSelectedAssociate)
              }
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedAssociate?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Associate', selectedAssociate, setSelectedAssociate)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Controller:</label>
            <Select
              closeMenuOnSelect={true}
              options={taxControllers}
              onChange={(value: ISuggestionAttribute) =>
                handleChange(value, 'Controller', selectedController, setSelectedController)
              }
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedController?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Controller', selectedController, setSelectedController)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Order Type:</label>
            <Select
              closeMenuOnSelect={true}
              options={Object.entries(TAX_ORDER_TYPES).map((item: any) => ({ label: item[0], value: item[1] }))}
              onChange={(value: ISuggestionAttribute) => handleChange(value, 'Type', selectedType, setSelectedType)}
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedType?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Type', selectedType, setSelectedType)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Order Status:</label>
            <Select
              closeMenuOnSelect={true}
              options={['Open', 'Archived'].map(item => ({ label: item, value: item }))}
              onChange={(value: ISuggestionAttribute) => handleChange(value, 'Order', selectedOrder, setSelectedOrder)}
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedOrder?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Order', selectedOrder, setSelectedOrder)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
          <Grid item xs={4}>
            <label>Tax Service Type:</label>
            <Select
              closeMenuOnSelect={true}
              options={Object.entries(TAX_ORDER_STATUS).map((item: any) => ({ label: item[0], value: item[1] }))}
              onChange={(value: ISuggestionAttribute) =>
                handleChange(value, 'Service', selectedService, setSelectedService)
              }
            />
            {listLoading && <LinearProgress />}
            <List>
              <div className={classes.root}>
                {selectedService?.map((item, index) => (
                  <Chip
                    key={item.value}
                    size="medium"
                    label={item.label}
                    onDelete={() => onDeleteClick(item.value, 'Service', selectedService, setSelectedService)}
                    deleteIcon={
                      <CancelOutlined
                        style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                      />
                    }
                    style={{ backgroundColor: item?.color, color: setColor(item?.color || '#fff') }}
                  />
                ))}
              </div>
            </List>
          </Grid>
        </Grid>

        <div>
          <SubmitFormButton
            //onFormSubmitted={onFormSubmitted}
            title={editedTaxWidget ? 'Save Changes' : 'Add Tax Widget'}
            onClick={onSubmitClick}
          />
        </div>
      </Container>
    </React.Fragment>
  )
}

export default WidgetDetails

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    fullWidth: {
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    title: {
      fontSize: '1.5rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 500,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

// const handleChange = (selectArray: ISuggestionAttribute, key: string) => {
//   switch (key) {
//     case 'CPA':
//       if (!selectedCPA?.some(item => item?.value === selectArray?.value))
//         setSelectedCPA(prev => [...prev, selectArray])
//       break
//     case 'Year':
//       if (!selectedYear?.some(item => item?.value === selectArray?.value))
//         setSelectedYear(prev => [...prev, selectArray])
//       break
//     case 'Label':
//       if (!selectedLabel?.some(item => item?.value === selectArray?.value))
//         setSelectedLabel(prev => [...prev, selectArray])
//       break
//     case 'Stage':
//       if (!selectedStage?.some(item => item?.value === selectArray?.value))
//         setSelectedStage(prev => [...prev, selectArray])
//       break
//     case 'Status':
//       if (!selectedStatus?.some(item => item?.value === selectArray?.value))
//         setSelectedStatus(prev => [...prev, selectArray])
//       break
//     case 'Structure':
//       if (!selectedStructure?.some(item => item?.value === selectArray?.value))
//         setSelectedStructure(prev => [...prev, selectArray])
//       break
//     case 'Accountant':
//       if (!selectedAccountant?.some(item => item?.value === selectArray?.value))
//         setSelectedAccountant(prev => [...prev, selectArray])
//       break
//     case 'Associate':
//       if (!selectedAssociate?.some(item => item?.value === selectArray?.value))
//         setSelectedAssociate(prev => [...prev, selectArray])
//       break
//     case 'Controller':
//       if (!selectedController?.some(item => item?.value === selectArray?.value))
//         setSelectedController(prev => [...prev, selectArray])
//       break
//     default:
//       break
//   }
// }

// const onDeleteClick = (val: number, key: string) => {
//   // const filteredData = selectedList.filter(object => {
//   //   return object.value !== val
//   // })
//   // setSelectedList(filteredData ? filteredData : [])
//   setChanged(true)

//   switch (key) {
//     case 'CPA':
//       const filteredDataCPA = selectedCPA.filter(object => {
//         return object.value !== val
//       })
//       setSelectedCPA(filteredDataCPA ? filteredDataCPA : [])
//       break
//     case 'Year':
//       const filteredDataYear = selectedYear.filter(object => {
//         return object.value !== val
//       })
//       setSelectedYear(filteredDataYear ? filteredDataYear : [])
//       break
//     case 'Label':
//       const filteredDataLabel = selectedLabel.filter(object => {
//         return object.value !== val
//       })
//       setSelectedLabel(filteredDataLabel ? filteredDataLabel : [])
//       break
//     case 'Stage':
//       const filteredDataStage = selectedStage.filter(object => {
//         return object.value !== val
//       })
//       setSelectedStage(filteredDataStage ? filteredDataStage : [])
//       break
//     case 'Status':
//       const filteredDataStatus = selectedStatus.filter(object => {
//         return object.value !== val
//       })
//       setSelectedStatus(filteredDataStatus ? filteredDataStatus : [])
//       break
//     case 'Structure':
//       const filteredDataStructure = selectedStructure.filter(object => {
//         return object.value !== val
//       })
//       setSelectedStructure(filteredDataStructure ? filteredDataStructure : [])
//       break
//     case 'Accountant':
//       const filteredDataAccountant = selectedAccountant.filter(object => {
//         return object.value !== val
//       })
//       setSelectedAccountant(filteredDataAccountant ? filteredDataAccountant : [])
//       break
//     case 'Associate':
//       const filteredDataAssociate = selectedAssociate.filter(object => {
//         return object.value !== val
//       })
//       setSelectedAssociate(filteredDataAssociate ? filteredDataAssociate : [])
//       break
//     case 'Controller':
//       const filteredDataController = selectedController.filter(object => {
//         return object.value !== val
//       })
//       setSelectedController(filteredDataController ? filteredDataController : [])
//       break
//     default:
//       break
//   }
// }
