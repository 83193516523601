import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { BusinessCenter, MoreVert } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'

import EditIcon from 'components/common/icons/edit'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import CompanyCreationFlow from 'components/companies/create'
import { IStates, ISalesRep } from 'models/user'
import * as React from 'react'
import { formatPhoneNumberToInternational, formatSSN } from 'utils/formatNumbers'
// import MoreOptionsMenu from '../moreOptionsMenu'
import DescriptionIcon from '@material-ui/icons/Description'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
)

interface IClientTableRowProps {
  states: IStates
  onEditClick: () => void
  onDeleteClick: () => void
}

const ClientTableRow: React.FC<IClientTableRowProps> = ({ states, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      {/* <CompanyCreationFlow isOpen={isModalOpen} setIsOpen={setIsModalOpen} initialstates={states} /> */}
      <StyledTableRow onClick={onEditClick} data-testid={`${states.id}`} className={classes.row}>
        <StyledTableCell>{states.state_name}</StyledTableCell>
        <StyledTableCell>{states.company_type_name}</StyledTableCell>
        <StyledTableCell>
          {/* {formatPhoneNumberToInternational(client.phone_number1 ? client.phone_number1 : '')} */}
          {states.processing_time}
        </StyledTableCell>
        <StyledTableCell>{states.essential_processing_time}</StyledTableCell>

        <StyledTableCell>{states.rush_processing_time}</StyledTableCell>
        <StyledTableCell>{states.state_fee}</StyledTableCell>
        <StyledTableCell>{states.annual_state_fee}</StyledTableCell>
        <StyledTableCell>{`${states?.registered_agent?.first_name || ''} ${states?.registered_agent?.middle_name ||
          ''} ${states?.registered_agent?.last_name || ''}`}</StyledTableCell>

        <StyledTableCell>
          {states?.average_processing_time && states?.average_processing_time > 0
            ? `${states.average_processing_time} day(s)`
            : ''}{' '}
        </StyledTableCell>

        <StyledTableCell>
          <span ref={moreOptionsRef}>
            {/* <TableActionButton
              className={classes.button}
              onClick={event => {
                event.stopPropagation()
                setIsMoreOptionsOpen(!isMoreOptionsOpen)
              }}
            >
              <Tooltip title="Show More" ref={moreOptionsRef}>
                <MoreVert />
              </Tooltip>
              <MoreOptionsMenu
                isOpen={isMoreOptionsOpen}
                refElement={moreOptionsRef}
                setIsOpen={setIsMoreOptionsOpen}
                client={client}
              />
            </TableActionButton> */}
          </span>
          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              onDeleteClick()
            }}
            style={{ width: '35px', height: '35px' }}
          >
            <DeleteIcon tooltipText="Delete" />
          </TableActionButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default ClientTableRow
