/* eslint-disable jsx-a11y/iframe-has-title */
import { PagedTable, usePagedTable } from '@jetkit/react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Chip,
  makeStyles,
  Theme,
  TablePagination,
} from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { paginateStates, deleteOffer, updateSpecialOfferTableRow } from 'api/specialOffers'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import CreateClient from 'components/specialOffers/create'
import ClientTableHead from 'components/specialOffers/table/head'
import ClientTableRow from 'components/specialOffers/table/row'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import { ISpecialOffers } from 'models/user'
import * as React from 'react'
import { theme } from 'theme'
import useRouter from 'use-react-router'
import { updatePageVisitsCount } from 'utils/user'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import grey from '@material-ui/core/colors/grey'
import { Edit } from '@material-ui/icons'
import EditIcon from 'components/common/icons/edit'
import showApiResponseError from 'utils/showApiResponseError'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.title.fontFamily,
    fontWeight: 600,
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
  notFoundText: {
    textAlign: 'center',
    background: '#F6F6F8',
    margin: '0',
    padding: '1rem',
  },
  button: {
    marginLeft: 10,
    width: 40,
    height: 40,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[300],
    },
  },
})

interface IOfferProps extends WithStyles<typeof styles> {}

const SpecialOffers: React.FC<IOfferProps> = ({ classes }) => {
  const [query, setQuery] = React.useState('')
  const [clientToDelete, setClientToDelete] = React.useState<ISpecialOffers>()
  const [deleteClientDialogShown, setDeleteClientDialogShown] = React.useState(false)
  const router = useRouter()

  const apiCall = React.useMemo(() => paginateStates()({ query }), [query])
  const pagedTable = usePagedTable<ISpecialOffers>({ apiCall })

  const tableData = pagedTable?.renderProps?.rows
  const [specialOffers, setSpecialOffers] = React.useState<ISpecialOffers[]>(tableData)

  React.useEffect(() => {
    if (tableData) {
      setSpecialOffers(tableData)
    }
  }, [tableData])

  const handleDragEnd = async (event: any) => {
    if (!event.destination) return

    const tempData = Array.from(specialOffers)
    const temp2Data = Array.from(specialOffers)
    const [source_data]: ISpecialOffers[] = tempData.splice(event.source.index, 1)
    const [destination_data]: ISpecialOffers[] = temp2Data.splice(event?.destination?.index, 1)

    tempData.splice(event.destination.index, 0, source_data)
    setSpecialOffers(tempData)

    const source = parseInt(event?.draggableId)
    const destination = destination_data?.sequence

    try {
      await updateSpecialOfferTableRow(source, destination || 0)
      pagedTable?.reloadData()
    } catch (error) {
      showApiResponseError(error, 'Failed to update Row')
    }
  }

  const onEditClick = React.useCallback(
    (client: ISpecialOffers) => {
      if (!client.id) return
      router.history.push(`/special-offer/${client.id}`)
    },
    [router.history]
  )

  const onDeleteClick = React.useCallback(
    (client: ISpecialOffers) => {
      if (!client.id) return
      setClientToDelete(client)
      setDeleteClientDialogShown(true)
    },
    [setClientToDelete, setDeleteClientDialogShown]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleChangePage = (event, newPage) => {
    pagedTable?.renderProps?.handleChangePage(event, newPage)
  }
  const handleChangeRowsPerPage = event => {
    pagedTable?.renderProps?.handleChangeRowsPerPage(event)
  }

  const page = pagedTable?.renderProps?.page
  const pageSize = pagedTable?.renderProps?.pageSize
  const totalRows = pagedTable?.renderProps?.totalRows

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteClientDialogShown}
        close={() => setDeleteClientDialogShown(false)}
        deletionObject={clientToDelete}
        deletionRequest={deleteOffer}
        onRequestSuccessful={onDeleteSuccessful}
      />
      <div className={classes.titleButtonContainer}>
        <Typography className={classes.title}>Special Offers</Typography>
        <CreateClient fetchClients={pagedTable.reloadData} />
      </div>
      <div className={classes.searchBarContainer}>
        <SearchBar onChange={setQuery} placeholder="Search by title" />
      </div>
      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {/* {pagedTable.isLoading ? (
          <Table>
            <ClientTableHead />
            <TableBody>{skeletons({ numberOfColumns: 5, numberOfRows: 5 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Data"
            {...pagedTable.renderProps}
            renderRow={(client: ISpecialOffers) => (
              <ClientTableRow
                offers={client}
                onEditClick={() => onEditClick(client)}
                onDeleteClick={() => onDeleteClick(client)}
              ></ClientTableRow>
            )}
            header={<ClientTableHead />}
            colSpan={5}
          />
        )} */}

        {pagedTable.isLoading ? (
          <Table>
            <ClientTableHead />
            <TableBody>{skeletons({ numberOfColumns: 5, numberOfRows: 5 })}</TableBody>
          </Table>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <TableContainer component={Paper}>
              <Table>
                <ClientTableHead />

                <Droppable droppableId="table">
                  {provider => (
                    <TableBody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                      {specialOffers?.map((item, index) => (
                        <Draggable key={item?.sequence} draggableId={item?.sequence?.toString()} index={index}>
                          {provider => (
                            <StyledTableRow
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                              {...provider.dragHandleProps}
                              data-testid={`${item.id}`}
                              className={classes.row}
                            >
                              <StyledTableCell>{item.id}</StyledTableCell>
                              <StyledTableCell>{item.title}</StyledTableCell>
                              <StyledTableCell>{item.link}</StyledTableCell>
                              <StyledTableCell>{item.sequence}</StyledTableCell>

                              <StyledTableCell style={{ display: 'flex' }}>
                                <TableActionButton
                                  className={classes.button}
                                  onClick={event => {
                                    event.stopPropagation()
                                    onEditClick(item)
                                  }}
                                  style={{ width: '35px', height: '35px' }}
                                >
                                  <EditIcon tooltipText="Edit" />
                                </TableActionButton>
                                <TableActionButton
                                  className={classes.button}
                                  onClick={event => {
                                    event.stopPropagation()
                                    onDeleteClick(item)
                                  }}
                                  style={{ width: '35px', height: '35px' }}
                                >
                                  <DeleteIcon tooltipText="Delete" />
                                </TableActionButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
              {!specialOffers?.length && <p className={classes.notFoundText}>No Tax Orders Found</p>}
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalRows}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </DragDropContext>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(SpecialOffers)
function setDeleteClientDialogShown(arg0: boolean) {
  throw new Error('Function not implemented.')
}
