import Link from '@material-ui/core/Link'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { IAddress } from 'models/address'
import * as React from 'react'
import Address from './address'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    title: {
      textTransform: 'uppercase',
      fontWeight: 300,
    },
    titleAndAddButtonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 0',
    },
    errorText: {
      color: 'red',
      margin: '8px 12px 0',
    },
  })
)

export interface IAddressesProps {
  label: string
  addresses: IAddress[]
  mailingAddresses?: IAddress[]
  onAddAddressClick?: () => void
  onPrimaryAddressChange?: (address: IAddress) => (checked: boolean) => void
  onAddressEditClick: (id: number) => void
  onAddressDeleteClick?: (id: number) => void
  noPrimaryAddressError?: boolean
  onlyOneAddress?: boolean
  isDeletable?: boolean
  classes?: Record<string, string>
  isEditable?: boolean
  customClass?: boolean
  customClassName?: string
}

/**
 * component that shows multiple addresses
 */
const Addresses: React.FC<IAddressesProps> = props => {
  const classes = useStyles(props)
  const {
    label,
    addresses,
    onAddAddressClick,
    onPrimaryAddressChange,
    onAddressEditClick,
    onAddressDeleteClick,
    noPrimaryAddressError,
    onlyOneAddress,
    isDeletable = true,
    isEditable = true,
    customClass,
    customClassName,
  } = props

  console.log('addressesaddressesaddressesaddresses', addresses)

  return (
    <div className={!customClass ? `${classes.container}` : `${customClassName}`}>
      <div className={classes.titleAndAddButtonWrapper}>
        <Typography className={classes.title} variant="caption">
          {label}
        </Typography>
        {!onlyOneAddress || (onlyOneAddress && addresses.length === 0) ? (
          <Link component="button" variant="body2" onClick={onAddAddressClick}>
            + Add Address
          </Link>
        ) : null}
      </div>
      {addresses &&
        addresses.map((address, key) => (
          <Address
            isDeletable={isDeletable}
            onEditClick={onAddressEditClick}
            onDeleteClick={onAddressDeleteClick}
            key={key}
            onPrimaryAddressChange={onPrimaryAddressChange}
            address={address}
            noPrimaryAddressError={noPrimaryAddressError}
            onlyOneAddress={onlyOneAddress}
            isEditable={address?.is_foreign ? false : isEditable}
          />
        ))}
      {noPrimaryAddressError && (
        <Typography variant="caption" className={classes.errorText}>
          Please provide primary address
        </Typography>
      )}
    </div>
  )
}

export default Addresses
