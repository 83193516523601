import React from 'react'
import { Container, Divider, TextField, Link } from '@material-ui/core'
import linkedin from '../../icons/linkedin.svg'
import facebookSquare from '../../icons/facebookSquare.svg'
import twitterSquare from '../../icons/twitterSquare.svg'
import moneyBag from '../../icons/moneyBag.svg'
import CheckIcon from '@material-ui/icons/Check'
import MailIcon from '../../icons/mail-icon.png'
import LinkIcon from '../../icons/link-icon.png'
import { useStyles } from './style'
import { IUser } from 'models/user'
import copyToClipboard from 'utils/copyToClipboard'

interface ShareEarnModalProp {
  user: IUser | null
  handler?: any
}

const ShareEarnModal: React.FC<ShareEarnModalProp> = ({ user, handler }: ShareEarnModalProp) => {
  const classes = useStyles()

  const handleCheckCommission = (comissionPercent: number) => {
    switch (comissionPercent >= 0) {
      case comissionPercent >= 0 && comissionPercent <= 5:
        return { title: 'BRONZE', color: '#a08f73' }
      case comissionPercent >= 6 && comissionPercent <= 10:
        return { title: 'Silver', color: '#b8babe' }
      case comissionPercent >= 11 && comissionPercent <= 15:
        return { title: 'Gold', color: '#bca886' }
      case comissionPercent >= 16 && comissionPercent <= 20:
        return { title: 'Platinum', color: '#979797' }

      default:
        return { title: 'Platinum', color: '#979797' }
    }
  }

  return (
    <Container maxWidth="sm">
      <div className={classes.earnContainer}>
        <div
          className="mobile_bronze_container"
          style={{ backgroundColor: handleCheckCommission(user?.commission_percentage || 0)?.color }}
        >
          <CheckIcon className="mobile_check_icon" />

          <p className="mobile_bronze_badge_text">{handleCheckCommission(user?.commission_percentage || 0)?.title} </p>
          <p className="mobile_bronze_member_text"> - MEMBER</p>

          <button className="mobile_how_earn_more_btn" onClick={handler}>
            Learn How to Earn More
          </button>
        </div>

        <div className="flag_main_container">
          <div
            className="flag"
            style={{ backgroundColor: handleCheckCommission(user?.commission_percentage || 0)?.color }}
          >
            <p className="bronze_badge_text">{handleCheckCommission(user?.commission_percentage || 0)?.title}</p>
            <div className="divider_container">
              <Divider className="brone_badge_divider" />
            </div>
            <p className="bronze_member_text">MEMBER</p>
            <CheckIcon className="bronze_check_icon" />
          </div>
          <button className="how_earn_more_btn" onClick={handler}>
            Learn How to <br /> Earn More
          </button>
        </div>

        <p className="pop_up_title">Refer & Earn</p>
        <div className="money_bag_container">
          <img src={moneyBag} alt="moneyBag" />
        </div>
        <p className="earn_life_time">
          Earn <strong>LIFETIME</strong> from your referrals:
        </p>
        <p className="discount_earn">
          Your currently earn <br />
          <strong>{user?.commission_percentage}%</strong> from collected gross receipts
        </p>
        <p className="default_link_para">Your Personal Default Link:</p>
        <div className="text_field_and_btn_main_container">
          <div className="para_text_field_container">
            <p className="business_rocket_link">www.BusinessRocket.com/?ref=</p>
            <TextField value={user?.ref_code} variant="outlined" size="small" className="code_text_field" />
            {/* <Link target="_blank" href={`https://www.businessrocket.com/?ref=${user?.ref_code}`}  onClick={() => copyToClipboard(value)}> */}
            <div onClick={() => copyToClipboard(`https://www.businessrocket.com/?ref=${user?.ref_code}`)}>
              <img src={LinkIcon} alt="LinkSquare" />
            </div>
          </div>
          {/* <div className="edit_btn_container">
            <button className="click_edit_customize">Edit</button>
            <p className="edit_text_para">
              Click Edit to <br /> customize
            </p>
          </div> */}
        </div>
        <p className="quick_share_options">Quick Share Options</p>
        <div className="social_media_icons_container">
          <Link
            target="_blank"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&quote=I%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20`}
          >
            <img src={linkedin} alt="linkedin" />
          </Link>
          <Link
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&quote=I%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20`}
          >
            <img src={facebookSquare} alt="facebookSquare" />
          </Link>
          <Link
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=I%27ve+recently+opened+my+business+through+BusinessRocket%2C+they+are+a+one-stop+business+formation+service+company.+Highly+Recommended%21+Fast+Processing%2C+Best+Prices%2C+always+there+to+for+questions+about+your+new+Business+Tell+them+I+referred+you%21+%0A%0Ahttps%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}`}
          >
            <img src={twitterSquare} alt="twitterSquare" />
          </Link>
          <Link
            target="_blank"
            href={`mailto:?body=Hey%2C%20%0A%0AI%27ve%20recently%20opened%20my%20business%20through%20BusinessRocket%2C%20they%20are%20a%20one-stop%20business%20formation%20service%20company.%20Highly%20Recommended!%20Fast%20Processing%20Times%2C%20Affordable%20Packages%2C%20always%20there%20to%20answer%20any%20questions%20about%20starting%20a%20business.%20Check%20them%20out%20and%20tell%20them%20I%20referred%20you!%20%0A%0Ahttps%3A%2F%2Fwww.businessrocket.com%3Fref%3D${user?.ref_code}&subject=BusinessRocket%20Invitation`}
          >
            <img src={MailIcon} alt="MailSquare" />
          </Link>
          {/* <Link target="_blank" href={`https://www.businessrocket.com/?ref=${user?.ref_code}`}>
            <img src={LinkIcon} alt="LinkSquare" />
          </Link> */}
        </div>
        <p className="what_should">What should I do if I am unable to share my link?</p>
        <p className="contact_description">
          We have you covered! Simply direct your friends to BusinessRocket.com or have them call 888-700-8213 and have
          them mention <b>{user?.name}</b> when asked who referred them. We will manually add them to your account.
        </p>
      </div>
    </Container>
  )
}

export default ShareEarnModal
