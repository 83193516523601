import { Divider } from '@material-ui/core'
import { getClientCompanies } from 'api/clientDashboard'
import { ICompany } from 'models/company'
import HeadingHeader from 'pages/clientDashboard/headingHeader'
import HistoryTabs from 'pages/clientDashboard/tabs'
import React, { useState, useEffect } from 'react'
// import NotificationTabs from './notificationTabs'
import { useStyles } from './styles'

const ClientNotifications = () => {
  const classes = useStyles()
  const [companyDetails, setcompanyDetails] = useState<ICompany[]>([])
  const [isLoading, setIsLoading] = useState(false)

  React.useEffect(() => {
    // updatePageVisitsCount()
  }, [])

  const getCompanyDetails = async () => {
    setIsLoading(true)
    try {
      const data: any = await getClientCompanies()
      if (data.error) setcompanyDetails([])
      else setcompanyDetails(data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
    }
  }

  useEffect(() => {
    getCompanyDetails()
  }, [])

  return (
    <div className={classes.clientNotifications}>
      <h1 className="main-heading">Notifications</h1>
      <Divider />
      <div className="upcoming-deadlines">
        <HeadingHeader title="Upcoming Deadlines" />
      </div>
      {/* <NotificationTabs /> */}
      <HistoryTabs isDashboard companyDetails={companyDetails} />
    </div>
  )
}

export default ClientNotifications
