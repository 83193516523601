import { PagedTable, usePagedTable } from '@jetkit/react'
import { Grid, Table, TableBody } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { paginateClients } from 'api/clients'
//import { deleteUser } from 'api/users'
import { deleteClient } from 'api/users'

import CreateClient from 'components/clients/create'
import ClientTableHead from 'components/clients/table/tableHead'
import ClientTableRow from 'components/clients/table/row'
import SearchBar from 'components/common/searchBar'
import { skeletons } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import DeleteDialog from 'components/notifications/deleteDialog'
import useSortTable from 'hooks/useSortTable'
import { IClient } from 'models/user'
import * as React from 'react'
import { theme } from 'theme'
import useRouter from 'use-react-router'
import { updatePageVisitsCount } from 'utils/user'
import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import { fetchSuggestions } from 'api/suggestions'

const styles = createStyles({
  container: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.title.fontFamily,
    fontWeight: 600,
  },
  titleButtonContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarContainer: {
    margin: '0.5rem 0',
  },
  tableContainer: {
    margin: '0.5rem 0',
  },
})

interface IClientProps extends WithStyles<typeof styles> {
  affiliate?: number
  sales_rep_id?: number
  setOpen?: (val: boolean) => void
}

const Client: React.FC<IClientProps> = ({ classes, affiliate, sales_rep_id, setOpen }) => {
  const [query, setQuery] = React.useState('')
  const [clientToDelete, setClientToDelete] = React.useState<IClient>()
  const [deleteClientDialogShown, setDeleteClientDialogShown] = React.useState(false)
  const router = useRouter()
  const [salesRep, setSalesRep] = React.useState<ISuggestionAttribute | null>()

  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'creation_time' })

  const tableHead = React.useMemo(
    () => <ClientTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )

  const { order, sort_by } = sortSettings

  const apiCall = React.useMemo(
    () =>
      paginateClients()({
        sort_order: order,
        sort_by,
        query,
        affiliate_id: affiliate || 0,
        sales_rep_id: sales_rep_id || salesRep?.value || 0,
      }),
    [affiliate, order, query, salesRep?.value, sales_rep_id, sort_by]
  )
  const pagedTable = usePagedTable<IClient>({ apiCall })

  const onEditClick = React.useCallback(
    (client: IClient) => {
      if (!client.id) return
      router.history.push(`/client/${client.id}`)
      setOpen && setOpen(false)
    },
    [router.history, affiliate]
  )

  const onDeleteClick = React.useCallback(
    (client: IClient) => {
      if (!client.id) return
      setClientToDelete(client)
      setDeleteClientDialogShown(true)
    },
    [setClientToDelete, setDeleteClientDialogShown]
  )

  const onDeleteSuccessful = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  console.log('salesRepresentative salesRep', salesRep)

  const changeDropDownField = React.useCallback(async (field: keyof IClient, valueAtt: ISuggestionAttribute | null) => {
    setSalesRep(valueAtt)
  }, [])

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteClientDialogShown}
        close={() => setDeleteClientDialogShown(false)}
        deletionObject={clientToDelete}
        deletionRequest={deleteClient}
        onRequestSuccessful={onDeleteSuccessful}
      />
      {!affiliate && !sales_rep_id && (
        <>
          <div className={classes.titleButtonContainer}>
            <Typography className={classes.title}>Clients</Typography>
            <CreateClient fetchClients={pagedTable.reloadData} />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <div className={classes.searchBarContainer}>
                <SearchBar onChange={setQuery} placeholder="Search by name, email, phone or SSN/EIN" />
              </div>
            </Grid>
            <Grid item xs={4}>
              <SelectField
                showCopyButton={false}
                onOptionSelected={option => changeDropDownField('sales_rep', option ? option : null)}
                title="Sales Representative"
                fetchSuggestions={fetchSuggestions}
                field="sales_rep_id"
                placeholder="Choose Sales Representative"
                value={salesRep}
              />
            </Grid>
          </Grid>
        </>
      )}

      <div className={classes.tableContainer}>
        <TableLoadingIndicator isLoading={pagedTable.isLoading} />
        {pagedTable.isLoading ? (
          <Table>
            {tableHead}
            <TableBody>{skeletons({ numberOfColumns: 8, numberOfRows: 10 })}</TableBody>
          </Table>
        ) : (
          <PagedTable
            emptyRowText="No Clients"
            {...pagedTable.renderProps}
            renderRow={(client: IClient) => (
              <ClientTableRow
                fromCompany={false}
                client={client}
                onEditClick={() => onEditClick(client)}
                onDeleteClick={() => onDeleteClick(client)}
              ></ClientTableRow>
            )}
            header={tableHead}
            colSpan={6}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(Client)
