import { usePagedTable } from '@jetkit/react'
import { CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { fetchClient } from 'api/documentTypes'
import { fetchNotes } from 'api/note'
import ClientDetails from 'components/documentTypes/create/documentTypesDetails'
import { NoteEntitiesContext } from 'components/common/notes/addNote'
import { NoteEntities } from 'components/common/notes/NoteEntitySelector'
import Notes from 'components/common/notes/notes'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import { ISuggestionAttribute } from 'components/interfaces'
import { INote } from 'models/note'
import { IClient, IStates } from 'models/user'
import { NotesTableContext } from 'pages/orders/orderDetails'
import * as React from 'react'
import useRouter from 'use-react-router'
import { removeNullAndUndefined } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import BRTextField from 'components/common/textFields/BRTextField'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { createPassword } from 'api/users'
import showSuccessNotification from 'utils/showSuccessNotification'
import Logo from 'icons/logo'
import useRouter1 from 'hooks/router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      ...theme.title,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    notesContainer: {
      width: '35%',
    },
    flex: {
      display: 'flex',
    },
    formContainer: {
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
  })
)

const CreatePassword = () => {
  const classes = useStyles()
  const [password, setpassword] = React.useState<string>('')
  const [confpassword, setconfpassword] = React.useState<string>('')
  const [passError, setpassError] = React.useState<boolean>(false)
  const router = useRouter<{ id: string | undefined }>()
  const [passlengthError, setpasslengthError] = React.useState<boolean>(false)
  const { history } = useRouter1()

  console.log(router.match.params.id)
  const id = router.match.params.id
  React.useEffect(() => {
    if (password !== confpassword) {
      setpassError(true)
      if (password.length < 8) {
        setpasslengthError(true)
      } else setpasslengthError(false)
    } else setpassError(false)
  }, [password, confpassword])
  const submitPass = async () => {
    if (passlengthError || passError) {
      return
    }
    try {
      const res = await createPassword({ id: id, password: password, email: 'temp' })
      if (res.success) {
        showSuccessNotification(`Password Created Successfully`)
        history.push('/')
      } else if (res.error) {
        showApiResponseError(res.error, `Failed to create password, User doesn't Exists.`)
      }
    } catch (error) {
      if (error.statusCode === 404) showApiResponseError(error, `Failed to create password, User doesn't Exists.`)
      else {
        showApiResponseError(error.message, `Couldn't Save, User doesn't Exists.`)
      }
    }
  }

  return (
    <div className={classes.flex}>
      <div className={classes.container}>
        <div className={classes.titleButtonContainer}>
          <Logo />

          <Typography className={classes.title}>Create Password</Typography>
        </div>
        <div className={classes.formContainer}>
          <BRTextField
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            //showCopyButton={!!editedClient}
            required
            data-testid="client-password"
            // error={clientEditErrors.password}
            helperText={passlengthError && 'Password cannot be less than 8 characters!'}
            label="Password"
            //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
            //className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="password"
            name="password"
            margin="normal"
            variant="outlined"
            value={password}
            onChange={event => setpassword(event.target.value)}
          />
          <BRTextField
            // We show copy button in these fields only if we're looking at existing client, not creating a new one.
            //showCopyButton={!!editedClient}
            required
            data-testid="client-password"
            error={passError}
            helperText={passError && 'Passwords Do not match'}
            label="Confirm Password"
            //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
            //className={classes.textField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            type="password"
            name="password"
            margin="normal"
            variant="outlined"
            value={confpassword}
            onChange={event => setconfpassword(event.target.value)}
          />
          <ButtonWithLoading style={{ width: '100%', marginBottom: '1rem' }} onClick={submitPass}>
            Create Password
          </ButtonWithLoading>
        </div>
      </div>
    </div>
  )
}

export default CreatePassword
