import { Grid, TextField } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CommonInput from '../CommonInput'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import PhoneInput from 'components/clients/create/phoneInput'
import { IAddressProps } from '../addressFileds'
import { ExistingCompanyContext } from 'providers/existingCompany/existingCompany.provider'
import { Autocomplete } from '@material-ui/lab'
import { IStateProps } from '../Step1'
import { getcities } from 'api/addresses'
import showErrorNotification from 'utils/showErrorNotification'
import { IClientCompanyOwners } from 'api/clientDashboard'

interface AddOwnerProps {
  // setAddOwner: any;
  addOwnerNew: IClientCompanyOwners
  saveFormData: any
  index: number
}
const AddOwner = ({
  // setAddOwner,
  addOwnerNew,
  saveFormData,
  index,
}: AddOwnerProps) => {
  const classes = useStyles()

  const { formationStateList } = useContext(ExistingCompanyContext)
  const [citiesLists, setCitiesLists] = useState<any[]>([])
  const [addressStateName, setAddressStateName] = useState<IStateProps>()

  const getCitiesList = async (stateId: number) => {
    try {
      const res = await getcities(stateId)
      setCitiesLists(res)
    } catch (error) {
      showErrorNotification(error)
    }
  }
  useEffect(() => {
    if (addressStateName) {
      getCitiesList(addressStateName?.id)
    }
  }, [addressStateName])
  // const [addressObj, setAddressObj] = React.useState<IAddressProps>({
  //   is_primary: true,
  //   is_mailing: false,
  //   zip: '',
  //   address_line_1: '',
  //   city_id: 0,
  // })

  // const [firstName, setfirstName] = useState('');
  // const [lastName, setlastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [address, setAddress] = useState('');
  // const [ownership, setOwnership] = useState('');
  // const [phone, setPhone] = useState('');

  const onClickSave = () => {
    // const value = {
    //   id: Math.floor(Math.random() * 1000 + 1),
    //   first_name: firstName,
    //   last_name: lastName,
    //   email: email,
    //   phone: phone,
    //   ownership: ownership,
    //   address: address,
    // };
    // setAddOwner({
    //   ...value,
    // });
  }

  useEffect(() => {
    console.log('addOwnerNew', addOwnerNew)
  }, [addOwnerNew])

  const addressObj = (address: IAddressProps, key: any, value: string) => {
    const updatedAddress = { ...address, [key]: value }
    return updatedAddress
  }

  return (
    <div>
      <CommonInput
        label="Who owns this business?"
        palcehodler={'First Name'}
        value={addOwnerNew?.first_name}
        onChange={(e: any) => saveFormData({ ...addOwnerNew, first_name: e.target.value }, index)}
      />
      <CommonInput
        value={addOwnerNew?.middle_name}
        palcehodler={'Middle Name'}
        onChange={(e: any) => saveFormData({ ...addOwnerNew, middle_name: e.target.value }, index)}
      />
      <CommonInput
        value={addOwnerNew?.last_name}
        palcehodler={'Last Name'}
        onChange={(e: any) => saveFormData({ ...addOwnerNew, last_name: e.target.value }, index)}
      />

      <div className="phone-filed">
        <label className={classes.label}>Phone</label>
        <PhoneInput
          value={addOwnerNew?.phone}
          onChange={(value: any) => saveFormData({ ...addOwnerNew, phone: value }, index)}
        />
      </div>

      <CommonInput
        label={'Email'}
        palcehodler={'Email'}
        value={addOwnerNew?.email}
        onChange={(e: any) => saveFormData({ ...addOwnerNew, email: e.target.value }, index)}
      />

      <CommonInput
        label="Ownership"
        palcehodler="No. Of Shares"
        type="number"
        value={addOwnerNew?.shares}
        onChange={(e: any) => saveFormData({ ...addOwnerNew, shares: e.target.value }, index)}
      />

      <Grid container spacing={0}>
        <div style={{ marginBottom: '10px' }}>
          <CommonInput
            label={'What is your business address?'}
            palcehodler={'Address Line'}
            value={addOwnerNew?.address?.address_line_1}
            name="address_line_1"
            // onChange={(e: any) => saveFormData({ ...addOwnerNew, address: { address_line_1: e.target.value } }, index)}
            onChange={(e: any) =>
              saveFormData(
                { ...addOwnerNew, address: addressObj(addOwnerNew?.address, 'address_line_1', e.target.value) },
                index
              )
            }
          />
        </div>{' '}
        <Autocomplete
          getOptionSelected={(option, value) => option.state_display_name === value.state_display_name}
          getOptionLabel={option => option.state_display_name}
          options={formationStateList}
          id="state-auto-complete"
          autoComplete
          includeInputInList
          placeholder="States"
          className={classes.autoComplete}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="State"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(_, value) => setAddressStateName(value as IStateProps)}
        />
        <Autocomplete
          id="city"
          options={citiesLists.map((city: any) => ({
            value: city.id,
            label: city.city_name,
          }))}
          getOptionLabel={option => option?.label}
          getOptionSelected={(option, value) => option.label === value?.label}
          autoComplete
          includeInputInList
          placeholder="City"
          className={classes.autoComplete}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="City"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          // onChange={(_, value) => saveFormData({ ...addOwnerNew, address: { city_id: value?.value } }, index)}
          onChange={(_, value) =>
            saveFormData({ ...addOwnerNew, address: addressObj(addOwnerNew?.address, 'city_id', value?.value) }, index)
          }
        />
        <Grid container spacing={0} justify="space-between">
          <Grid item xs={4}>
            <CommonInput
              palcehodler={'Zip'}
              value={addOwnerNew?.address?.zip}
              name="zip"
              // onChange={(e: any) => saveFormData({ ...addOwnerNew, address: { zip: e.target.value } }, index)}
              onChange={(e: any) =>
                saveFormData(
                  { ...addOwnerNew, address: addressObj(addOwnerNew?.address, 'zip', e.target.value) },
                  index
                )
              }
            />
          </Grid>
          <Grid item xs={7}>
            <CommonInput
              palcehodler={'Country'}
              value="United States of America"
              name="country"
              disabled
              // onChange={(e: any) => saveFormData({ ...addOwnerNew, address: { country: e.target.value } }, index)}
              onChange={(e: any) =>
                saveFormData(
                  { ...addOwnerNew, address: addressObj(addOwnerNew?.address, 'country', e.target.value) },
                  index
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default AddOwner

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {},
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    action: {
      textAlign: 'right',
      width: '100%',
    },
    label: {
      textTransform: 'uppercase',
      color: '#202124',
      fontSize: '0.8rem',
      fontFamily: 'Roboto, sans-serif',
      marginBottom: '0.25rem',
    },
    autoComplete: {
      width: '100%',
      marginBottom: '5px',

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInputBase-root ': {
        fontSize: '0.9rem',
        background: '#f7f8f9',
        padding: '0 0.5rem',
      },
      '& .MuiFormLabel-root': {
        fontSize: '0.9rem',
        fontFamily: 'Roboto, sans-serif',
        textTransform: 'uppercase',
        color: '#202124',
      },
    },
  })
)
