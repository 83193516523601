import Button, { ButtonProps } from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import * as React from 'react'
import PrimaryButton from './primaryButton'
import SecondaryButton from './secondaryButton'

// originally implemented by Mischa Spiegelmock (@revmischa) for Fingrprint
// converted to TS by Dmitry Spiridonov (@d-spiridonov)

type LoadingButtonKind = 'BRPrimary' | 'BRSecondary' | 'BREmpty'

interface IButtonWithLoading extends ButtonProps {
  kind?: LoadingButtonKind
  isLoading?: boolean
  children?: React.ReactNode
  disabled?: boolean
  classes?: Record<string, string>
  wrapperClassName?: string
  linearProgressVariant?: 'determinate' | 'indeterminate' | 'buffer' | 'query'
  linearProgressValue?: number
  dataTestId?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      height: 50,
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '4px',
    },
    prevStepButton: {
      color: '#2479af',
    },
    linearProgress: {
      position: 'absolute',
      left: '0px',
      right: '0px',
      color: theme.palette.primary.dark,
    },
    innerContainer: {
      position: 'relative',
      width: '100%',
    },
  })
)

const buttonOfKind = (
  kind: LoadingButtonKind,
  props: Record<string, unknown>,
  children: React.ReactNode
): JSX.Element => {
  switch (kind) {
    case 'BRPrimary':
      return <PrimaryButton {...props}>{children}</PrimaryButton>
    case 'BRSecondary':
      return <SecondaryButton {...props}>{children}</SecondaryButton>
    case 'BREmpty':
      return <Button {...props}>{children}</Button>
  }
}

const ButtonWithLoading = ({
  kind = 'BRPrimary',
  isLoading,
  children,
  disabled = false,
  linearProgressVariant,
  linearProgressValue,
  onClick,
  style,
  wrapperClassName,
  dataTestId,
}: IButtonWithLoading) => {
  const classes = useStyles()
  return (
    <div style={{ display: 'flex', ...style }} className={wrapperClassName}>
      <div data-testid={dataTestId} className={classes.innerContainer}>
        {buttonOfKind(
          kind,
          {
            style: {
              width: '100%',
              borderRadius: isLoading ? '4px 4px 0px 0px' : undefined,
            },
            className: kind === 'BREmpty' ? classes.prevStepButton : undefined,
            disabled: isLoading || disabled,
            onClick,
          },
          children
        )}
        {isLoading ? (
          <LinearProgress
            value={linearProgressValue}
            variant={linearProgressVariant}
            style={{ borderRadius: isLoading && kind !== 'BREmpty' ? '0px 0px 4px 4px' : undefined }}
            className={classes.linearProgress}
          />
        ) : null}
      </div>
    </div>
  )
}

export default ButtonWithLoading
