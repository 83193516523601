import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { IOrderDetailsResponse } from 'api/orders'
import classNames from 'classnames'
import { IOrderTask } from 'models/task'
import * as React from 'react'
import { ValueOf } from 'utils/objectUtils'
import TaskActivationCheckbox from './taskActivationCheckbox'
import { TestID } from 'utils/testUtils/TestID'

const useStyles = makeStyles({
  rightSideWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 6px)',
    maxHeight: 385,
  },
  createRightSideWrapper: {
    maxHeight: 457,
  },
  tableHead: {
    backgroundColor: '#f6f6f8',
  },
  tableHeadCell: {
    padding: '10px 20px',
  },
  tableCell: {
    padding: '5px 20px',
  },
  tableWrapper: {
    position: 'relative',
  },
  tableRow: {
    backgroundColor: '#FFFFFF',
    height: 68,
  },
  addTaskButton: {
    marginLeft: 10,
    marginBottom: 2,
    textDecoration: 'underline',
  },
  tableHeadCellText: {
    fontFamily: 'Helvetica',
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0.07,
    color: '#020202',
  },
  taskNameText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.07,
    color: '#2A2A2A',
  },
  statusCheckbox: {
    '&.Mui-checked': {
      color: '#91de91',
    },
    '& > span': {
      '& > svg': {
        width: 32,
        height: 32,
      },
    },
  },
})

interface ICompanyOrderTaskActivationTableProps {
  toggleTaskActivation?: (companyOrderTaskId: number) => Promise<void>
  onCompanyOrderFieldChange?: (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => void
  setOpenAddTaskDialog?: React.Dispatch<React.SetStateAction<boolean>>
  tasks: IOrderTask[]
  isCompanyOrderCreate?: boolean
  setTasks?: any
  taskIDs?: any
}

const CompanyOrderTaskActivationTable = ({
  tasks,
  toggleTaskActivation,
  onCompanyOrderFieldChange,
  setOpenAddTaskDialog,
  isCompanyOrderCreate,
  setTasks,
  taskIDs,
}: ICompanyOrderTaskActivationTableProps) => {
  /**
   * A table with tasks belonging to a specific company order.
   *
   * Allows to mark tasks as activated/deactivated. Deactivated tasks won't be displayed to users.
   *
   * @param toggleTaskActivation: API request used to change task's activation state.
   * If this param is present, then we're dealing with an already existing company order.
   */
  const classes = useStyles()

  const handleOpenAddTaskDialog = React.useCallback(() => {
    setOpenAddTaskDialog && setOpenAddTaskDialog(true)
  }, [setOpenAddTaskDialog])

  return (
    <div className={classNames(classes.rightSideWrapper, { [classes.createRightSideWrapper]: isCompanyOrderCreate })}>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table" className={classes.tableWrapper}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeadCell} align="left">
                TASKS
                <Link component="button" onClick={handleOpenAddTaskDialog} className={classes.addTaskButton}>
                  + Add Task
                </Link>
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Activity Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task: IOrderTask, key: number) => (
              <TableRow key={key} className={classes.tableRow}>
                <TableCell
                  className={classNames(classes.taskNameText, classes.tableCell)}
                  align="left"
                  data-testid={TestID.ORDER_TASK_ROW}
                >
                  {task.title}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <TaskActivationCheckbox
                    tasks={tasks}
                    task={task}
                    toggleTaskActivation={toggleTaskActivation}
                    setTasks={setTasks}
                    taskIDs={taskIDs}
                    onCompanyOrderFieldChange={onCompanyOrderFieldChange}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CompanyOrderTaskActivationTable
