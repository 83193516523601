import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { ISuggestionAttribute } from 'components/interfaces'
import { IAddress } from 'models/address'
import moment, { Moment } from 'moment'

export interface ProspectListProps {
  industry_type?: number | null
  status?: number | null
  query?: string
  page?: number
  page_size?: number
  last_update?: Date | null
}

export interface IndustryProspectData {
  id: number
  name: string
}
export interface PaginationData {
  first_page: number
  last_page: number
  page: number
  total: number
  total_pages: number
}

export interface Notes {
  id?: number
  note_text?: string
  emp_id?: number
  prospect_id?: number
  created_by?: string
  created_on?: string
}

export interface ProspectData {
  address?: any
  addressData: IAddress
  company_name: string
  email: string
  first_name: string
  id: number
  industry_type: string | number
  last_name: string
  last_update: string
  phone: string
  poc: number
  prospect_status?: number
  status: string
  submission_id: string
  title: string
  poc_name: string
  notes?: Notes[]
  name: string
  middle_name?: string
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
  ref_code?: string
  gclid?: string
  fbclid: string
  msclkid: string
  invoice_payment_link?: string
  form_link?: string
  client_id?: number
  sales_rep?: ISuggestionAttribute
  affiliate?: ISuggestionAttribute
}

export interface Prospect {
  pagination: PaginationData
  data: ProspectData[]
}

export const createProspectItem = async (prospectData: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  await apiClient.post('/prospects', prospectData)
}
export const createClientProspect = async (prospectData: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  await apiClient.post('/client/prospects', prospectData)
}
export const createProspectNote = async (data: Notes): Promise<any> => {
  await apiClient.post('/prospect-note', data)
}

export const getProspectLists = async (ProspectListProps: ProspectListProps): Promise<Prospect> => {
  const { industry_type, query, status, page, page_size, last_update } = ProspectListProps
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000'
  return await (
    await apiClient.get(`/prospects`, {
      params: {
        industry_type: industry_type ? industry_type : 0,
        status: status ? status : 0,
        query: query ? query : '',
        page: page ? page : 0,
        page_size: page_size ? page_size : 0,
        last_update: last_update ? last_update : '',
      },
    })
  ).data
}

export const fetchProspectLists = (
  industry_type: number,
  status: number,
  last_update: Date | '',
  query: string,
  utmMediumValue?: string,
  utmSourceValue?: string,
  utmCampaignValue?: string,
  utmContentValue?: string,
  refCodeValue?: string,
  isGCLID?: boolean,
  clidsValue?: string
) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'

  return requestPaginated<ProspectData[]>({
    url: `/prospects-paginated?industry_type=${industry_type ? industry_type : 0}&status=${
      status ? status : 0
    }&last_update=${last_update ? last_update : ''}&query=${encodeURIComponent(query)}&utm_medium=${
      utmMediumValue ? utmMediumValue : ''
    }&utm_source=${utmSourceValue ? utmSourceValue : ''}&utm_campaign=${
      utmCampaignValue ? utmCampaignValue : ''
    }&utm_content=${utmContentValue ? utmContentValue : ''}&ref_code=${
      refCodeValue ? refCodeValue : ''
    }&no_gclid=${encodeURIComponent(isGCLID || false)}&clid=${clidsValue}`,
  })
}

export const getIndustryType = async (): Promise<any> => {
  return (await apiClient.get(`industrytypes`)).data
}

export const getProspectStatuses = async (): Promise<any> => {
  return (await apiClient.get(`prospectstatuses`)).data
}

export const getProspectTaskById = async (id: number): Promise<any> => {
  return (await apiClient.get(`prospect-tasks/${id}`)).data
}

export const getProspectById = async (id: number): Promise<any> => {
  return (await apiClient.get(`prospects/${id}`)).data
}

export const getProspectNotesById = async (id: number): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000'

  return (await apiClient.get(`prospectnotes/${id}`)).data
}

export const updateProspectById = async (id: number, prospectData: any): Promise<any> => {
  return (await apiClient.post(`prospects/${id}`, prospectData)).data
}

export const deleteProspectById = async (id: number): Promise<any> => {
  return (await apiClient.delete(`prospects/${id}`)).data
}

export const updateProspectAddressById = async (id: number, address: any): Promise<any> => {
  return (await apiClient.post(`address/${id}`, address)).data
}

export const changeProspectStatus = async (id: number, data: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  return await apiClient.post(`prospect/status/${id}`, data)
}

export const markProspectAsReadById = async (id: number, data: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000'
  return (await apiClient.post(`prospectnotes/${id}`, data)).data
}

export const markAllProspectAsReadById = async (id: number, data: any): Promise<any> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000'
  return (await apiClient.post(`prospectnotes-mark-read/${id}`, data)).data
}

export const getProspecrFilters = async (startDateRange?: string, endDateRange?: string): Promise<any> => {
  const data = (await apiClient.get(`/submissions-filters?entity_type=prospects`)).data
  return data
}

/////////////////////////// Schedules //////////////////

export interface PropspectScheduleProps {
  id?: number
  name: string
  created_at: string
  total_configs: number
}
export interface AddPropspectScheduleProps {
  id?: number
  name: string
  created_at: string
  created_by: string
}

export interface OrderReminderSchedulesData {
  content: string
  id: number
  prospect_id: number
  prospect_template_config_id: number
  prospect_template_id: number
  send_time: string
  status: string
  type: string
}

export interface NotesData {
  id?: number
  note_text?: string
  prospect_id?: number
  created_by?: string
  created_on?: moment.Moment | string
}
export interface ISubmissionHistory {
  title?: string
  dateTime?: string
}

export const addPropspectSchedule = async (PropspectScheduleData: AddPropspectScheduleProps): Promise<any> =>
  await apiClient.post('/prospect-reminder-templates', PropspectScheduleData)

export const addPropspectScheduleConfig = async (data: any): Promise<AxiosResponse<void>> =>
  await apiClient.post('/prospect-templates-config', data)

export const getPropspectSchedule = () =>
  requestPaginated<any>({
    url: '/prospect-reminder-templates',
  })

export const getPropspectScheduleById = async (id: number): Promise<[]> =>
  (await apiClient.get(`prospect-reminder-templates/${id}`)).data

export const updatePropspectScheduleConfig = async (id: number, data: any): Promise<AxiosResponse<void>> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000/'
  return await apiClient.post(`/prospect-reminder-templates/${id}`, data)
}

export const deletePropspectScheduleItems = async (id: number): Promise<AxiosResponse<void>> =>
  apiClient.delete(`/prospect-reminder-templates/${id}`)

export const checkTestTemplate = async (data: any): Promise<AxiosResponse<void>> => {
  return await apiClient.post('/test-remindertemplates', data)
}

export const getProspectReminderSchedules = async (id?: number): Promise<OrderReminderSchedulesData[]> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  return (await apiClient.get(`prospect-reminder-schedules?prospect_id=${id}`)).data
}

export const addProspectNote = async (payload: NotesData): Promise<any> => {
  return (await apiClient.post('/prospect-note', payload)).data
}

export const getSubmissionHistory = async (id: number): Promise<ISubmissionHistory[]> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  return (await apiClient.get(`web/submissions/history/${id}`)).data
}

export const sendProspectSms = async (payload: { msg_text: string }, id: number): Promise<any> => {
  return (await apiClient.post(`/prospect-sms/${id}`, payload)).data
}
export const pauseNotifications = async (payload: { prospect_id: number; pauseDays: number }): Promise<any> => {
  return (await apiClient.post('/pause-prospect-notifications', payload)).data
}

export const fetchClientProspectLists = (
  industry_type: number,
  status: number,
  last_update: Date | '',
  query: string,
  utmMediumValue?: string,
  utmSourceValue?: string,
  utmCampaignValue?: string,
  utmContentValue?: string,
  refCodeValue?: string,
  isGCLID?: boolean,
  clidsValue?: string
) => {
  // apiClient.defaults.baseURL = 'http://192.168.0.108:3000/'

  return requestPaginated<ProspectData[]>({
    url: `/client/prospects?industry_type=${industry_type ? industry_type : 0}&status=${
      status ? status : 0
    }&last_update=${last_update ? last_update : ''}&query=${encodeURIComponent(query)}&utm_medium=${
      utmMediumValue ? utmMediumValue : ''
    }&utm_source=${utmSourceValue ? utmSourceValue : ''}&utm_campaign=${
      utmCampaignValue ? utmCampaignValue : ''
    }&utm_content=${utmContentValue ? utmContentValue : ''}&ref_code=${
      refCodeValue ? refCodeValue : ''
    }&no_gclid=${encodeURIComponent(isGCLID || false)}&clid=${clidsValue}`,
  })
}
