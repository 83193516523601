import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { customFetchSuggestions, SearchField } from 'api/suggestions'
import DatePicker from 'components/common/datePicker'
import SelectField from 'components/common/select'
import { ISuggestionAttribute } from 'components/interfaces'
import { AddTaskDialogMode } from 'components/orders/addTaskDialog'
import TaskPriorities from 'components/tasks/create/taskPriorities'
import TaskTypes from 'components/tasks/create/taskTypes'
import { ITask, ITaskType, TaskSelectKeys } from 'models/task'
import moment from 'moment'
import { OrderDetailsContext } from 'pages/orders/orderDetails/OrderDetailsContext'
import * as React from 'react'
import { datePickerDatePlaceholder, formatDateTimeToHumanFriendly } from 'utils/formatDate'
import useUser from 'hooks/useUser'
import { getProspectById } from 'api/leadManager'
import { getTaskDescriptions, ITaskDescription } from 'api/task'
import { List, ListItem, ListItemText, ListItemIcon, Divider, ListSubheader } from '@material-ui/core'
import { Subject } from '@material-ui/icons'
import { TAX_ORDER_TYPES } from 'utils/constant'
const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      padding: '1.5rem 3rem',
    },
    formHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: '2rem',
      fontFamily: theme.title.fontFamily,
      fontWeight: 600,
    },
    closeIcon: {
      color: '#999999',
      fontSize: '2rem',
    },
    textField: {
      width: '100%',
      margin: '0.5rem 0',
    },
    form: {
      width: 420,
    },
    button: {
      width: '100%',
      backgroundColor: '#3c9fdf',
      height: '3.5rem',
      margin: '0.5rem 0',
      color: 'white',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#2479af',
        color: 'white',
      },
    },
    prevNotes: {
      '& li': {
        padding: 0,
        marginBottom: 0,

        '& .MuiListItemIcon-root': {
          minWidth: 'auto',
          marginRight: '0.5rem',
          marginTop: '0.25rem',

          '& svg': {
            fontSize: '1rem',
          },
        },
        '& .MuiListItemText-root': {
          margin: 0,

          '& > p': {
            fontSize: '0.75rem',
          },
        },
      },
    },
  })

interface ICreateTaskFormProps extends WithStyles<typeof styles> {
  mode?: AddTaskDialogMode
  task: ITask
  errors: {}
  closeDrawer: () => void
  submitForm: () => void
  changeStringValue: (field: string, value: string) => void
  addAttribute: (field: TaskSelectKeys, id: number | null) => void
  fetchSuggestions: (query: string, field: SearchField) => Promise<ISuggestionAttribute[]>
  disabled: boolean
  handleTaskTypeChange: (taskType: ITaskType) => void
  confButtonTitle?: string | React.ReactNode
  companyId?: number
  prospectName?: string
  taxName?: string
  complianceName?: string
}

const CreateTaskForm: React.FunctionComponent<ICreateTaskFormProps> = ({
  mode,
  classes,
  closeDrawer,
  task,
  errors,
  changeStringValue,
  submitForm,
  addAttribute,
  fetchSuggestions,
  disabled,
  handleTaskTypeChange,
  confButtonTitle = 'Add Task',
  prospectName,
  taxName,
  complianceName,
}) => {
  const orderDetails = React.useContext(OrderDetailsContext)
  const [taskDescription, settaskDescription] = React.useState<ITaskDescription[]>([])

  React.useEffect(() => {
    const getDescription = async (id: number) => {
      try {
        const res: ITaskDescription[] = await getTaskDescriptions(id)
        settaskDescription(res)
        console.log('resresres', res)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (task?.id) {
      getDescription(task?.id)
    }
  }, [task?.id])

  const dateChange = React.useCallback(
    (dueDate: string) => {
      console.log('dateChanged', dueDate)

      changeStringValue('due_date', dueDate)
    },
    [changeStringValue, task]
  )
  const { user } = useUser()

  React.useEffect(() => {
    console.log('before', task.assigned_to)

    if (task.assigned_to === null) {
      console.log('after')

      dateChange('')
    }
  }, [task.assigned_to])
  const assignee: ISuggestionAttribute | undefined = task.assigned_to
    ? {
        value: task.assigned_to,
        label: task.assignee ? task.assignee.name : '',
      }
    : user && user.name && user.id
    ? { value: user.id, label: user.name }
    : undefined
  const client: ISuggestionAttribute | undefined =
    task.client && task.client.id
      ? {
          value: task.client.id,
          label: task.client ? task.client.name : '',
        }
      : undefined

  const [company, setCompany] = React.useState<ISuggestionAttribute | undefined>(
    task.company && task.company_id
      ? {
          value: task.company_id,
          label: task.company ? task.company.name : '',
        }
      : orderDetails &&
          orderDetails.company && {
            value: orderDetails.company.id,
            label: orderDetails.company.name,
          }
  )

  // const [prospect, setProspect] = React.useState<ISuggestionAttribute | undefined>()

  // task.prospect_id
  // ? {
  //     value: task.prospect_id,
  //     label: `#${task.prospect_id} ${task.prospect_name ? task.prospect_name : prospectName}`,
  //   }
  // : orderDetails &&
  //     orderDetails.prospect && {
  //       value: orderDetails.prospect.id,
  //       label: orderDetails.prospect.name,
  //     }
  // const [prospectName, setProspectName] = React.useState('')

  const initialProspect = () => {
    // if (task.prospect)
    //   return {
    //     value: task.prospect.prospect_id,
    //     label: task.prospect ? `#${task.prospect_id} - ${task.prospect_name} - ${task.prospect.prospect_name}` : 'xyz',
    //   }

    if (task.prospect_id)
      return {
        value: task.prospect_id,
        label: `#${task.prospect_id} - ${task?.client?.name || task?.prospect_name || ''}`,
      }

    if (orderDetails)
      return {
        value: orderDetails.id,
        label: `#${orderDetails.id} ${orderDetails.order_type && orderDetails.order_type.name}`,
      }
    return undefined
  }

  const initialTaxOrder = () => {
    if (task.id) {
      return {
        value: task.tax_order_id || 0,
        // label: task.tax ? `#${task.tax_order_id} - ${task.tax_name} - ${task.tax.client_details?.name}` : '',
        label: `#${task.tax_order_id} - ${
          TAX_ORDER_TYPES[task?.tax_order_type || ''] === 2
            ? task?.company?.name
            : task?.client?.name || task?.tax_name || ''
        } ${task.filer || ''}`,
      }
    }

    if (task.tax_order_id) {
      return {
        value: task.tax_order_id,
        label: `#${task.tax_order_id} - ${
          TAX_ORDER_TYPES[task?.tax_order_type || ''] === 2 ? task?.company?.name : task?.client?.name || task?.tax_name
        }`,
      }
    }

    if (orderDetails) {
      return {
        value: orderDetails.id,
        label: `#${orderDetails.id} ${orderDetails.order_type && orderDetails.order_type.name}`,
      }
    }
    return undefined
  }

  const initialCompliance = () => {
    if (task) {
      return {
        value: task?.compliance_task_id || 0,
        label: `${
          task?.compliance?.name
            ? task?.compliance?.name
            : `${task?.compliance_task_id ? `#${task?.compliance_task_id} -` : ''} ${task?.compliance_name || ''}`
        } `,
      }
    }

    if (orderDetails) {
      return {
        value: orderDetails.id,
        label: `#${orderDetails.id} ${orderDetails.order_type && orderDetails.order_type.name}`,
      }
    }
    return undefined
  }

  const [prospect, setProspect] = React.useState<ISuggestionAttribute | undefined>(initialProspect())
  const [taxOrder, setTaxOrder] = React.useState<ISuggestionAttribute | undefined>(initialTaxOrder())
  const [complianceTask, setComplianceTask] = React.useState<ISuggestionAttribute | undefined>(initialCompliance())

  // React.useEffect(() => {
  //   const getProspect = async () => {
  //     try {
  //       const res = await getProspectById(prospect?.value || 0)
  //       setProspectName(`${res?.first_name} ${res?.last_name}`)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   getProspect()
  // }, [prospect?.value])

  // this function should be called when the `company` is changed
  const fetchOrderSuggestions = (query: string, field: SearchField): Promise<ISuggestionAttribute[]> => {
    if (company) {
      //fetch orders from given company
      return customFetchSuggestions({ company_id: company.value })(query, field)
    }

    return fetchSuggestions(query, field)
  }

  const fetchProspectSuggestions = (query: string, field: SearchField): Promise<ISuggestionAttribute[]> => {
    //
    if (prospect) {
      //fetch orders from given company
      return customFetchSuggestions({ prospect_id: prospect.value })(query, field)
    }

    return fetchSuggestions(query, field)
  }

  const fetchTaxOrderSuggestions = (query: string, field: SearchField): Promise<ISuggestionAttribute[]> => {
    //
    if (taxOrder) {
      //fetch orders from given company
      return customFetchSuggestions({ tax_order_id: taxOrder.value })(query, field)
    }

    return fetchSuggestions(query, field)
  }

  const fetchComplianceSuggestions = (query: string, field: SearchField): Promise<ISuggestionAttribute[]> => {
    //
    if (complianceTask) {
      //fetch orders from given company
      return customFetchSuggestions({ compliance_task_id: complianceTask.value })(query, field)
    }

    return fetchSuggestions(query, field)
  }

  const onCompanySelected = async (value: ISuggestionAttribute | null) => {
    if (!value) {
      setCompany(undefined)
      return
    }

    setOrder(undefined)
    setCompany(value)
  }

  const onProspectSelected = async (value: ISuggestionAttribute | null) => {
    //
    if (!value) {
      setProspect(undefined)
      return
    }

    setOrder(undefined)
    setCompany(undefined)
    setProspect(value)
  }

  const initialOrder = () => {
    if (task.order)
      return {
        value: task.order.id,
        label: task.order ? `#${task.order_id} - ${task.order.order_type.name}` : '',
      }

    if (task.order_id)
      return {
        value: task.order_id,
        label: `#${task.order_id} ${orderDetails && orderDetails.order_type && orderDetails.order_type.name}`,
      }

    if (orderDetails)
      return {
        value: orderDetails.id,
        label: `#${orderDetails.id} ${orderDetails.order_type && orderDetails.order_type.name}`,
      }
    return undefined
  }

  const [order, setOrder] = React.useState<ISuggestionAttribute | undefined>(initialOrder())

  React.useEffect(() => {
    if (assignee) addAttribute('assigned_to', assignee.value)
  }, [user])

  console.log('taxName', taxName)
  console.log('taxOrderinner', taxOrder)
  console.log('tasktasktasktasktask', taskDescription)
  console.log('tasktasktasktask', task)
  console.log('tasktasktasktask', task.task_type)
  console.log('tasktasktasktask', initialCompliance())
  console.log('tasktasktasktask', complianceTask)
  console.log('tasktasktasktask', orderDetails)

  return (
    <div tabIndex={0} className={classes.container} data-testid="create-task-form">
      <div className={classes.formHeader}>
        <Typography className={classes.title}>{task?.id ? 'Update Task' : 'Add Task'}</Typography>
        <IconButton onClick={closeDrawer}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.form}>
        <TextField
          required
          error={errors['title']}
          id="title-input"
          label="Title"
          helperText={errors['title'] && 'Please provide title'}
          className={classes.textField}
          type="text"
          name="title"
          margin="normal"
          variant="outlined"
          value={task.title}
          disabled={task?.id && task?.id ? true : false}
          onChange={event => changeStringValue('title', event.target.value)}
          InputProps={{
            inputProps: {
              'data-testid': 'title-input',
            },
          }}
        />

        {/* <TextField
          id="description-input"
          label="Description"
          className={classes.textField}
          type="text"
          name="title"
          margin="normal"
          variant="outlined"
          multiline
          rows="5"
          disabled={task?.id && task?.id ? true : false}
          value={task.details || task.description}
          onChange={event => changeStringValue('details', event.target.value)}
          // value={task.details || task.description}
          // value={task.details}
          // onChange={event => changeStringValue('task_note', event.target.value)}
          InputProps={{
            inputProps: {
              'data-testid': 'description-input',
            },
          }}
        /> */}

        <TextField
          id="notes-input"
          label="Notes"
          className={classes.textField}
          type="text"
          name="title"
          margin="normal"
          variant="outlined"
          multiline
          rows="3"
          onChange={event => changeStringValue('task_note', event.target.value)}
          InputProps={{
            inputProps: {
              'data-testid': 'note-input',
            },
          }}
        />

        {taskDescription?.length ? (
          <List className={classes.prevNotes} subheader={<ListSubheader component="div">Notes History</ListSubheader>}>
            {taskDescription?.map((item: ITaskDescription) => (
              <>
                <ListItem alignItems="flex-start" key={item?.id}>
                  <ListItemIcon>
                    <Subject />
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.note_text}
                    secondary={
                      <React.Fragment>
                        {item?.created_by}&nbsp;
                        {item?.created_at ? formatDateTimeToHumanFriendly(item?.created_at) : ''}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </>
            ))}
          </List>
        ) : (
          ''
        )}

        <TaskPriorities
          defaultPriority={task.priority}
          onPriorityChange={(priority: string) => changeStringValue('priority', priority)}
        />
        <TaskTypes value={task.task_type} handleTaskTypeChange={handleTaskTypeChange} />
        {task.task_type === 'client' && (
          <SelectField
            defaultValue={client}
            onOptionSelected={option => addAttribute('client_id', option ? option.value : null)}
            title="Client"
            fetchSuggestions={fetchSuggestions}
            field="client_id"
          />
        )}
        {task.task_type === 'company' && (
          <SelectField
            defaultValue={company}
            onOptionSelected={option => addAttribute('company_id', option ? option.value : null)}
            // onOptionSelected={onCompanySelected}
            title="Company"
            fetchSuggestions={fetchSuggestions}
            field="company_id"
          />
        )}
        {task.task_type === 'order' && (
          <React.Fragment>
            <SelectField
              title="Company"
              field="company_id"
              fetchSuggestions={fetchSuggestions}
              defaultValue={company}
              onOptionSelected={onCompanySelected}
            />
          </React.Fragment>
        )}
        {task.task_type === 'order' && mode !== 'noapi' && (
          <React.Fragment>
            <SelectField
              defaultValue={order}
              onOptionSelected={option => addAttribute('order_id', option ? option.value : null)}
              title="Order"
              fetchSuggestions={fetchOrderSuggestions}
              field="order_id"
              // key is specified so that `fetchOrderSuggestions` is rerun on every `company` change
              key={company && company.value}
            />
          </React.Fragment>
        )}
        {task.task_type === 'prospect' && (
          // <SelectField
          //   defaultValue={prospect}
          //   onOptionSelected={option => addAttribute('prospect_id', option ? option.value : null)}
          //   title="Prospect"
          //   fetchSuggestions={fetchProspectSuggestions}
          //   field="prospect_id"
          // />

          <SelectField
            title="Prospect"
            field="prospect_id"
            fetchSuggestions={fetchProspectSuggestions}
            defaultValue={prospect}
            // onOptionSelected={onProspectSelected}
            onOptionSelected={option => addAttribute('prospect_id', option ? option.value : null)}
          />
        )}
        {task.task_type === 'taxorder' && (
          <SelectField
            title="Tax Order"
            field="tax_order_id"
            fetchSuggestions={fetchTaxOrderSuggestions}
            defaultValue={taxOrder}
            // onOptionSelected={onProspectSelected}
            onOptionSelected={option => addAttribute('tax_order_id', option ? option.value : null)}
          />
        )}
        {task.task_type === 'compliance' && (
          <SelectField
            title="Compliance Task"
            // field="compliance_id"
            field="compliance_task_id"
            fetchSuggestions={fetchComplianceSuggestions}
            defaultValue={complianceTask}
            // onOptionSelected={onProspectSelected}
            onOptionSelected={option => addAttribute('compliance_task_id', option ? option.value : null)}
          />
        )}
        <SelectField
          defaultValue={assignee}
          onOptionSelected={option => addAttribute('assigned_to', option ? option.value : null)}
          title="Assignee"
          fetchSuggestions={fetchSuggestions}
          field="assigned_to"
          key={user?.id}
        />
        <DatePicker
          label="Due Date"
          style={{ width: '100%' }}
          onDateSelected={dateChange}
          value={task.due_date ? moment(task.due_date) : ''}
          placeholder={datePickerDatePlaceholder}
          dataTestId="due-date"
          disabled={task.assigned_to ? false : true}
        />
        <Button
          data-testid="add-task-conf-button"
          variant="contained"
          className={classes.button}
          disabled={disabled}
          onClick={submitForm}
        >
          {confButtonTitle}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(CreateTaskForm)
