import { apiClient, requestPaginated } from '@jetkit/react'
import { AxiosResponse } from 'axios'

export interface OrderItemProps {
  created_at: string
  created_by: string
  id?: number
  item_description: string
  item_name: string
  order_reminder_id: number
  status: string
  completed_by?: string
  item_response?: string
}

export interface ReminderScheduleProps {
  content: string
  id: number
  order_id: number
  reminder_template_config_id: number
  send_time: string
  status: string
  type: string
  updated_by?: string
  updated_at?: string
}

export interface AddOrderReminderrProps {
  id?: number
  reminder_template_id?: number | string
  order_id?: number
  tax_order_id?: number
  order_reminder_id?: number
}

export interface ChangeOrderReminderStatusProps {
  item_id: number
  status: string
}

export interface OrderReminderProps {
  id: number
  reminder_template_id: number
  order_id?: number
  name: number
  tax_order_id?: number
  open_item?: boolean
}

export interface IFileDetails {
  id: number
  item_id: number
  file_name: string
  uploaded_on: string
  uploaded_by: string
}

export const getTemplatesList = async (): Promise<[]> => (await apiClient.get(`remindertemplateslist`)).data

export const getMissingItemsList = async (isTaxOrder?: boolean): Promise<[]> =>
  (await apiClient.get(`missingitems?tax_order=${isTaxOrder || false}`)).data

// apiClient.defaults.baseURL = 'http://192.168.0.127:3000'

export const getOrderReminders = (orderId: number) => {
  return requestPaginated<any[]>({
    url: `/order-reminders-paginated?orderId=${orderId}`,
  })
}

// export const getOrderReminderItems = (orderId: number) => {
//   return requestPaginated<any[]>({
//     url: `/order-reminder-items?orderId=${orderId}`,
//   })
// }
export const getOrderReminderItems = (reminderId: number, status: string) => {
  return requestPaginated<any[]>({
    url: `/order-reminder-items?reminder_id=${reminderId}&status=${status}`,
  })
}

export const getTaxReminderItems = (taxId: number) => {
  return requestPaginated<any[]>({
    url: `/order-reminder-items?tax_order_id=${taxId}`,
  })
}

export const getTaxOrderReminders = (taxId: number) => {
  return requestPaginated<any[]>({
    url: `/order-reminders-paginated?tax_order_id=${taxId}`,
  })
}

export const getOrderReminderSchedules = (orderId: number) =>
  requestPaginated<any[]>({
    url: `/order-reminder-schedules?orderId=${orderId}`,
  })

export const getTaxReminderSchedules = (taxId: number) =>
  requestPaginated<any[]>({
    url: `/order-reminder-schedules?tax_order_id=${taxId}`,
  })

export const addOrderReminder = async (orderReminderData: AddOrderReminderrProps): Promise<any> => {
  return await apiClient.post('/order-reminders', orderReminderData)
}

export const addOrderRemindersConfig = async (data: any): Promise<AxiosResponse<void>> => {
  // apiClient.defaults.baseURL = 'http://192.168.0.127:3000'
  return await apiClient.post('/order-reminder-items', data)
}

export const changeOrderReminderStatus = async (item_id: number, data: any): Promise<AxiosResponse<void>> => {
  return await apiClient.post(`/order-reminder-items/status/${item_id}`, data)
}

export const changeScheduleStatus = async (item_id: number, data: any): Promise<AxiosResponse<void>> => {
  return await apiClient.post(`/update-reminder-status/${item_id}`, data)
}

export const getAllMissingUploads = async (id: number): Promise<IFileDetails[]> =>
  (await apiClient.get(`missing-item/${id}/assets`)).data

export const getAllMissingDocumnets = async (id: number, docId: number): Promise<string> =>
  (await apiClient.get(`missing-item/${id}/asset/${docId}/download`)).data

export const orderItemReject = async (id: number, data): Promise<AxiosResponse<void>> => {
  return await apiClient.post(`/order-reminder-items/reject/${id}`, data)
}
