import {
  AutocompleteField,
  Base,
  BaseField,
  CustomComponent,
  DateField,
  DateTimeField,
  FieldType,
  NumberField,
  TextField,
  AddressField,
} from 'utils/FormGenerator/formTypings'

export const isDateTimeField = (value: Base): value is DateTimeField => {
  return value.type === FieldType.dateTime
}

export const isNumberField = (value: Base): value is NumberField => {
  return value.type === FieldType.number
}

export const isDateField = (value: Base): value is DateField => {
  return value.type === FieldType.date
}

export const isAutocompleteField = (value: Base): value is AutocompleteField => {
  return value.type === FieldType.autocomplete
}

export const isTextField = (value: Base): value is TextField => {
  return value.type === FieldType.text
}

export const isBaseField = (value: Base): value is BaseField => {
  return value.type !== FieldType.custom
}

export const isCustomComponent = (value: Base): value is CustomComponent => {
  return value.type === FieldType.custom
}

export const isAddressField = (value: Base): value is AddressField => {
  return value.type === FieldType.address
}
