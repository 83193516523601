import { Chip, CircularProgress, IconButton, Link, TableCell, TableRow } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { IFailedPaymentReponse, readFailedPayments } from 'api/failedPayment'
import * as React from 'react'
import { FAILED_PAYMENT_TYPES } from 'utils/constant'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

interface ILogsRowProps {
  logs: IFailedPaymentReponse
  reloadTable?: any
}

const LogsRow: React.FC<ILogsRowProps> = ({ logs, reloadTable }) => {
  const [isReadLoading, setIsReadLoading] = React.useState(false)

  console.log('logslogslogslogs', logs)

  const handleReadPayment = async id => {
    setIsReadLoading(true)
    try {
      const res = await readFailedPayments(id, true)
      if (res?.success) {
        reloadTable()
      }
      setIsReadLoading(false)
    } catch (error) {
      console.log('error', error)
    }
    setIsReadLoading(false)
  }

  return (
    <TableRow>
      <TableCell style={{}}>
        {logs?.order_id ? (
          // <a href={`/web-submission/${logs?.order_id}` || ''} target="self" style={{ cursor: 'pointer' }}>
          //   {logs?.order_id}
          // </a>
          <Link href={`/web-submission/${logs?.order_id}` || ''} target="_blank">
            {logs?.order_id}
          </Link>
        ) : (
          '--'
        )}
      </TableCell>
      <TableCell style={{}}>{logs?.company_subscription_id || '--'}</TableCell>
      <TableCell style={{}}>
        {logs?.company_id ? (
          // <a href={`/company/${logs?.company_id}` || ''} target="self" style={{ cursor: 'pointer' }}>
          //   {logs?.company_id}
          // </a>
          <Link href={`/company/${logs?.company_id}` || ''} target="_blank">
            {logs?.company_id}
          </Link>
        ) : (
          '--'
        )}
      </TableCell>
      <TableCell style={{}}>{logs?.company_name || '--'}</TableCell>
      <TableCell style={{}}>
        <Chip
          label={
            logs?.payment_type === FAILED_PAYMENT_TYPES?.order
              ? 'Web Submissions'
              : logs?.payment_type === FAILED_PAYMENT_TYPES?.subscription
              ? logs?.subscription_name || '--'
              : logs?.payment_type === FAILED_PAYMENT_TYPES?.renewal
              ? logs?.compliance_name || '--'
              : '' || '--'
          }
          clickable={false}
          color="primary"
          style={{ color: '#FFF' }}
        />
      </TableCell>
      <TableCell style={{}}>
        {/* {logs?.decline_code || '--'} */}

        {logs?.decline_code ? (
          // <a href={logs?.decline_code_url || ''} target="self" style={{ cursor: 'pointer' }}>
          //   {logs?.decline_code || '--'}
          // </a>
          <Link href={logs?.decline_code_url || ''} target="_blank">
            {logs?.decline_code}
          </Link>
        ) : (
          '--'
        )}
      </TableCell>
      {/* <TableCell style={{}}>
        {logs?.decline_code_url ? (
          <a href={logs?.decline_code_url || ''} target="self" style={{ cursor: 'pointer' }}>
            {logs?.decline_code_url}
          </a>
        ) : (
          '--'
        )}
      </TableCell> */}
      <TableCell
      // style={{
      //   display: 'table-cell',
      //   whiteSpace: 'nowrap',
      //   overflow: 'hidden',
      //   textOverflow: 'ellipsis',
      // }}
      >
        {logs?.error_text || '--'}
      </TableCell>
      <TableCell style={{}}>{logs?.created_at ? formatDateTimeToHumanFriendly(logs?.created_at) : '--'}</TableCell>

      <TableCell style={{}}>
        {!logs?.read ? (
          <IconButton onClick={() => handleReadPayment(logs?.id)}>
            {isReadLoading ? <CircularProgress size={24} /> : <Done color="primary" />}
          </IconButton>
        ) : (
          ''
        )}
      </TableCell>

      {/* <TableCell>{logs?.other_params}</TableCell> */}
    </TableRow>
  )
}

export default LogsRow
