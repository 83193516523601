import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import {
  addComplianceNote,
  complianceCompanyClosed,
  complianceCompleteOrder,
  complianceNotificationSequence,
  ComplianceSequenceData,
  ComplianceTasksData,
  getcomplianceCurrentSequence,
  getComplianceNoteById,
  getComplianceNotificationTemplates,
  NotesData,
  pausedComplianceTask,
} from 'api/complianceCalendar'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { CURRENT_USER_LOCAL_STORAGE_KEY } from 'hooks/useUser'
import React, { useState, useEffect } from 'react'
import { COMPLIANCE_TASKS_STATUS } from 'utils/constant'
import { formatDateTimeToHumanFriendly, formatDateToHumanFriendly } from 'utils/formatDate'
import showErrorNotification from 'utils/showErrorNotification'
import showSuccessNotification from 'utils/showSuccessNotification'
import { useStyles } from './styles'
import ViewMoreDialog from './viewMoreDialog'
import showApiResponseError from 'utils/showApiResponseError'
import { NavLink } from 'react-router-dom'
import { reversedArray } from 'utils/functions'

interface InnerTableItemProps {
  inProgressBtn?: boolean
  taskDetails?: ComplianceTasksData
  completeOrderHandler(id: any): void
  // companyCloseHandler(id: any): void
  // inProgressOrderHandler(id: any): void
}

const InnerTableItem = ({
  inProgressBtn,
  taskDetails,
  completeOrderHandler,
}: //
// companyCloseHandler,
// inProgressOrderHandler,
InnerTableItemProps) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [noteText, setNoteText] = useState('')
  const [addNoteLoading, setAddNoteLoading] = useState(false)
  const [notes, setNotes] = useState<NotesData[]>([])
  const [notesLoading, setNotesLoading] = useState(false)

  const userDetails = JSON.parse(localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY) || '')
  const userId = userDetails?.extid
  const [scheduleList, setScheduleList] = useState<any[]>([])
  const [scheduleID, setScheduleID] = useState()
  const [isScheduleLoading, setIsScheduleLoading] = useState(false)
  const [runningSequences, setRunningSequences] = useState<any>()

  const getNotes = async (id: number | undefined) => {
    setNotesLoading(true)
    try {
      if (id) {
        const notes = await getComplianceNoteById(id)
        setNotes(notes)
        setNotesLoading(false)
      }
    } catch (error) {
      showErrorNotification(error)
    }
    setNotesLoading(false)
  }

  const handleAddNote = async () => {
    setAddNoteLoading(true)
    const data = {
      note_text: noteText,
      company_id: taskDetails?.company_id,
      compliance_task_id: taskDetails?.id,
      created_by: userId,
    }
    if (data) {
      try {
        await addComplianceNote(data)
        setNoteText('')
        getNotes(taskDetails?.id)
        setAddNoteLoading(false)
      } catch (error) {
        showErrorNotification(error)
        setAddNoteLoading(false)
      }
    }
  }

  useEffect(() => {
    if (taskDetails?.id) {
      getNotes(taskDetails?.id)
    }
  }, [taskDetails?.id])

  // const completeOrderHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.completed,
  //   }
  //   try {
  //     const complateOrder = await complianceCompleteOrder(payload)
  //     return complateOrder
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }
  // const companyCloseHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.closed,
  //   }
  //   try {
  //     const companyClosed = await complianceCompanyClosed(payload)
  //     return companyClosed
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }
  // const inProgressOrderHandler = async () => {
  //   const payload = {
  //     compliance_task_id: taskDetails?.id,
  //     status: COMPLIANCE_TASKS_STATUS?.inprogress,
  //   }
  //   try {
  //     const companyClosed = await complianceInProgressOrder(payload)
  //     return companyClosed
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  // }

  const [paused, setPaused] = useState(taskDetails?.business_active || false)
  const [pausedLoading, setPausedLoading] = useState(false)

  // const handleTaskStatus = async () => {
  //   setPausedLoading(true)
  //   const payload = {
  //     compliance_task_id: taskDetails?.id || 0,
  //     business_active: !paused,
  //   }
  //   try {
  //     await pausedComplianceTask(payload)
  //     showSuccessNotification(`${paused ? 'Notification Paused' : 'Notification Unpaused'}`)
  //     setPausedLoading(false)
  //   } catch (error) {
  //     showErrorNotification(error)
  //   }
  //   setPausedLoading(false)
  // }

  const handleTaskStatusChange = React.useCallback(async (value: boolean) => {
    setPaused(value)

    setPausedLoading(true)
    const payload = {
      compliance_task_id: taskDetails?.id || 0,
      business_active: value,
    }

    try {
      await pausedComplianceTask(payload)
      showSuccessNotification(`${value ? 'Notification Paused' : 'Notification Unpaused'}`)
      setPausedLoading(false)
    } catch (error) {
      showApiResponseError(error, 'Error Updating Status')
    }
    setPausedLoading(false)
  }, [])

  const notesReversed = reversedArray(notes)
  useEffect(() => {
    const getComplianceSchedule = async () => {
      try {
        const respose = await getComplianceNotificationTemplates()
        setScheduleList(respose)
      } catch (error) {
        console.error('error', error)
      }
    }
    getComplianceSchedule()
  }, [])

  const handleSchedule = async (id: any, type: 'start' | 'stop') => {
    if (type === 'start' && !scheduleID) {
      showErrorNotification('Please Select Schedule template')
      return
    }
    const payload: ComplianceSequenceData = {
      compliance_task_id: id,
      // compliance_template_id: scheduleID,
      compliance_template_id: type === 'start' ? scheduleID : runningSequences?.id,
      type: type,
    }
    setIsScheduleLoading(true)
    try {
      await complianceNotificationSequence(payload)
      setIsScheduleLoading(false)
      showSuccessNotification(type === 'start' ? 'Schedule Started' : 'Schedule Stopped')
      // const temp = runningSequences.filter(item => item.id !== scheduleID)
      // setRunningSequences(temp)
    } catch (error) {
      console.error('error', error)
    }
    setIsScheduleLoading(false)
  }

  useEffect(() => {
    const getCurrentSequence = async (id: number) => {
      try {
        const result = await getcomplianceCurrentSequence(id)
        setRunningSequences(result)
      } catch (error) {
        console.error(error)
      }
    }
    if (taskDetails?.id) {
      getCurrentSequence(taskDetails?.id)
    }
  }, [taskDetails?.id])
  const [namecheckloading, setnamecheckloading] = React.useState<boolean>(false)

  return (
    <div className={classes.innerItems}>
      <div className="header-controls">
        <div className="btn-sec">
          {/* {inProgressBtn ? (
            <Button
              variant="contained"
              color="primary"
              className="inProgress-btn"
              onClick={() => inProgressOrderHandler(taskDetails?.id)}
            >
              In Progress Order
            </Button>
          ) : (
            ''
          )}
        
          <Button
            variant="contained"
            color="primary"
            className="company-btn"
            onClick={() => companyCloseHandler(taskDetails?.id)}
          >
            Company Closed
          </Button> */}

          {taskDetails?.status !== '2' ? (
            <Button
              variant="contained"
              color="primary"
              className="complete-btn"
              onClick={() => completeOrderHandler(taskDetails?.id)}
            >
              Complete
            </Button>
          ) : (
            ''
          )}

          <FormControlLabel
            control={
              <Switch
                checked={paused}
                onChange={e => {
                  handleTaskStatusChange(e.target.checked)
                }}
                name="paused"
                color="primary"
              />
            }
            label={paused ? 'Active' : 'Paused'}
          />
        </div>
        <Link component="button" variant="body2" className="link-btn" onClick={() => setOpen(true)}>
          View More
        </Link>
      </div>
      {!inProgressBtn && (
        <Grid container spacing={1} style={{ marginTop: '1rem' }}>
          <Grid item xs={7}>
            <Autocomplete
              id="schedulesValue"
              options={scheduleList}
              getOptionLabel={option => option?.name}
              onChange={(_, newValue: any) => {
                setScheduleID(newValue?.id)
              }}
              renderInput={params => <TextField {...params} label="Schedules" variant="outlined" />}
              className="schedule-filed"
            />
            <p className="schedule-text">
              <strong>Currently Running Schedule: </strong>
              {/* {runningSequences[0]?.name} */}
              {runningSequences?.name}
            </p>
          </Grid>

          <Grid item xs={1}>
            <ButtonWithLoading
              isLoading={isScheduleLoading}
              wrapperClassName="start-btn"
              onClick={() => handleSchedule(taskDetails?.id, 'start')}
            >
              START
            </ButtonWithLoading>
          </Grid>
          <Grid item xs={1}>
            <ButtonWithLoading
              kind="BRSecondary"
              isLoading={isScheduleLoading}
              wrapperClassName="end-btn"
              onClick={() => handleSchedule(taskDetails?.id, 'stop')}
            >
              STOP
            </ButtonWithLoading>
          </Grid>
        </Grid>
      )}

      <Divider className="item-divider" />
      <Grid container spacing={3} className="text-box-grid">
        <Grid item xs={4}>
          <div className="text-box">
            <TextareaAutosize
              className="text-area"
              placeholder="Notes/ Descriptions"
              rowsMax="10"
              rowsMin="10"
              value={noteText}
              name="noteText"
              onChange={e => setNoteText(e.target.value)}
            />
            <ButtonWithLoading
              kind="BRPrimary"
              wrapperClassName="save-btn"
              onClick={handleAddNote}
              isLoading={addNoteLoading}
              disabled={!noteText.length}
            >
              Save
            </ButtonWithLoading>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="text-box">
            {notesLoading ? (
              [1, 2, 3, 4, 5, 6].map(key => <Skeleton animation="wave" variant="text" key={key} />)
            ) : notes.length ? (
              notesReversed.slice(0, 3)?.map(note => (
                <div className="bottom-space" key={note?.id}>
                  <p className="dark-text">
                    {note?.created_by} {formatDateTimeToHumanFriendly(note?.created_at || '')}
                  </p>
                  <p className="dark-text">{note?.note_text}</p>
                  {note?.order_id ? (
                    <NavLink target="_blank" to={`/company-order/${note?.order_id}`}>
                      Order Link
                    </NavLink>
                  ) : (
                    ''
                  )}
                </div>
              ))
            ) : (
              <p>No Notes Found</p>
            )}

            {/* {notes.length
              ? notes.slice(0, 3)?.map(note => (
                  <div className="bottom-space" key={note?.id}>
                    <p className="dark-text">
                      {note?.created_by} {formatDateToHumanFriendly(note?.created_at || '')}
                    </p>
                    <p className="dark-text">{note?.note_text}</p>
                  </div>
                ))
              : [1, 2, 3, 4, 5, 6].map(key => <Skeleton animation="wave" variant="text" key={key} />)} */}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="input-box">
            <div className="inputs">
              <p className="input-dark-text">Primary Contact Name </p>
              {/* <p className="input-light-text">{taskDetails?.details?.company_details?.company_users[0]?.user?.name}</p> */}
              <p className="input-light-text">
                {taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[0]?.name}{' '}
                {taskDetails?.details?.company_details?.client?.isdeleted ? (
                  <Typography
                    variant="caption"
                    display="block"
                    color="error"
                    align="right"
                    style={{ paddingLeft: '1rem' }}
                  >
                    (Client Deleted)
                  </Typography>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Phone </p>
              <p className="input-light-text">
                {(taskDetails &&
                  taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[0]?.phone_1) ||
                  ''}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Email </p>
              <p className="input-light-text">
                {taskDetails &&
                  taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[0]?.email_1}
              </p>
            </div>
            <div className="inputs">
              <p className="input-dark-text">Email </p>
              <p className="input-light-text">
                {taskDetails &&
                  taskDetails?.details?.company_details?.primary_contact &&
                  taskDetails?.details?.company_details?.primary_contact[1]?.email_1}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <ViewMoreDialog
        open={open}
        setOpen={setOpen}
        taskDetails={taskDetails}
        notes={notes}
        inProgressBtn={inProgressBtn}
        // completeOrderHandler={completeOrderHandler}
        // companyCloseHandler={companyCloseHandler}
        // inProgressOrderHandler={inProgressOrderHandler}
      />
    </div>
  )
}

InnerTableItem.defaultProps = {
  inProgressBtn: false,
}

export default InnerTableItem
