import { MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { positiveNumericInput } from 'components/common/functions'
import BRTextField from 'components/common/textFields/BRTextField'
import * as React from 'react'
import InputMask from 'react-input-mask'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: '100%',
      height: 50,
      margin: '0.7rem 0',
    },
    select: {
      width: 90,
      marginRight: 5,
      marginTop: 14,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
  })
)

interface IEinSsnInputProps {
  ssnEinError?: boolean
  ssnEinHelperText?: string
  onChange(value: string): void
  value?: number
  showCopyButton?: boolean
  disabled?: boolean
}

type InputType = 'SSN' | 'EIN'

const getMaskForInput = (input: InputType) => {
  switch (input) {
    case 'SSN':
      return '999-99-9999'
    case 'EIN':
      return '99-9999999'
    default:
      throw new Error('unknown input type')
  }
}

const EinSsnInput: React.FC<IEinSsnInputProps> = ({
  ssnEinError,
  ssnEinHelperText,
  onChange,
  value,
  showCopyButton,
  disabled,
}) => {
  const [input, setInput] = React.useState<InputType>('SSN')

  const classes = styles()

  return (
    <div className={classes.container}>
      <TextField
        select
        margin="normal"
        label="SSN/EIN"
        className={classes.select}
        style={{ marginBottom: ssnEinError ? '1.5rem' : undefined }}
        variant="outlined"
        value={input}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInput(event.target.value as InputType)}
        disabled={disabled}
      >
        <MenuItem value="SSN">SSN</MenuItem>
        <MenuItem value="EIN">EIN</MenuItem>
      </TextField>
      <InputMask
        mask={getMaskForInput(input)}
        value={value || ''}
        onChange={event => onChange(positiveNumericInput(event.target.value))}
        className={classes.textField}
        disabled={disabled}
      >
        {() => (
          <BRTextField
            showCopyButton={showCopyButton}
            error={ssnEinError}
            helperText={ssnEinHelperText}
            label={input}
            style={{ marginBottom: ssnEinError ? '1.5rem' : undefined }}
            className={classes.textField}
            type="text"
            data-testid="ssn-ein"
            name={input}
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.errorHelperText,
              },
            }}
            variant="outlined"
            disabled={disabled}
          />
        )}
      </InputMask>
    </div>
  )
}

export default EinSsnInput
