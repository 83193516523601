import { authService, IUserCredential, LoginScreen, apiClient } from '@jetkit/react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import useUser from 'hooks/useUser'
import Logo from 'icons/logo'
import { IUser } from 'models/user'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import showApiResponseError from 'utils/showApiResponseError'
import { COOKIEE_NAMES, USERTYPES } from 'utils/constant'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
} from '@material-ui/core'
import TwoFA from 'pages/auth/twoFA'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import AccountLists from 'pages/auth/accountLists'
import BRTextField from 'components/common/textFields/BRTextField'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import AuthBG from 'img/auth-bg.webp'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 'calc(400px - 40px)',
      padding: '1.5rem 2.5rem',

      '& .MuiOutlinedInput-root': {
        borderRadius: '2rem',
        background: '#E8F0FE',
      },

      '& .checkbox-section': {
        maxWidth: '500px',
        paddingBottom: '1rem',

        '& .unCheck-icon': {
          color: '#26316c',
        },
        '& .check-icon': {
          color: '#26316c',
        },
        '& .MuiTypography-body1': {
          fontSize: '0.85rem',
          color: 'gray',
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    mainContainer: {
      backgroundImage: `url(${AuthBG})`,
      height: 'calc(100vh - 5rem)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',

      [theme.breakpoints.down('xs')]: {
        height: '100vh',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '5rem 0',

      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },

      '& .MuiPaper-root': {
        boxShadow: '0 3px 6px 0 rgba(120, 129, 144, 0.8)',

        [theme.breakpoints.down('xs')]: {
          boxShadow: 'none',
        },
      },
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '2rem',

      '& .MuiButtonBase-root.Mui-disabled': {
        pointerEvents: 'inherit',
      },
    },
    title: {
      color: '#343b4c',
      fontSize: '1.5rem',
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center',
    },
    submitButton: {
      height: 45,
      color: '#fff',
      fontSize: '1rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      backgroundColor: '#5b38d9',
      // backgroundColor: '#26316c',
      justifyContent: 'center',
      borderRadius: '2rem',
      padding: '1rem 0',
      width: '200px',

      '&:hover': {
        backgroundColor: '#2479af',
        color: '#fff',
      },
    },
    disableButton: {
      color: '#fff !important',
      cursor: 'not-allowed !important',
      backgroundColor: '#2479af !important',

      '&:hover': {
        background: '#2479af',
      },
    },
    rootProgress: {
      width: 'calc(400px + 40px)',

      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },

    footerLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      '& a': {
        color: '#407ace',
        margin: '1rem',
      },
    },
  })

interface ILoginProps extends WithStyles<typeof styles> {}

interface IBrUserCredential extends IUserCredential {
  user?: IUser
}

const Login: React.FC<ILoginProps & RouteComponentProps> = ({ classes, history }) => {
  const refQueryParams = new URLSearchParams(window.location.search).get('ref')
  console.log('refref', refQueryParams)

  const [inputFields, setInputValues] = React.useState({
    email: '',
    password: '',
  })
  const [loading, setLoading] = React.useState(false)
  const { updateUser } = useUser()
  const [showtwofa, setShowtfa] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState({})
  const [cookies, setCookie] = useCookies([COOKIEE_NAMES.device])
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false)
  const [showPassword, setShowPassword] = React.useState(false)

  const [clientsList, setClientsList] = React.useState([])
  const [subClients, setSubClients] = React.useState(false)

  React.useEffect(() => {
    if (!inputFields?.password?.length || !inputFields?.email?.length) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [inputFields?.email?.length, inputFields?.password?.length])

  const checkingUserType = React.useCallback(
    (type: string) => {
      switch (type) {
        case USERTYPES.admin:
          if (history?.location?.pathname !== '/' && !history?.location?.pathname?.includes('client')) {
            return history.push(history?.location?.pathname)
          } else {
            return history.push('/dashboard')
          }
        case USERTYPES.client:
          //start
          if (history?.location?.pathname !== '/') {
            return history.push(history?.location?.pathname)
          } else {
            return history.push({ pathname: '/client-dashboard', state: { isPopup: true } })
          }
        // end
        // return history.push({ pathname: '/client-dashboard', state: { isPopup: true } })
        case USERTYPES.employee:
          return history.push('/dashboard')
        case USERTYPES.salesRep:
          return history.push('/client-dashboard')
        case USERTYPES.accountant:
          return history.push('/dashboard')
        case USERTYPES.salesAgent:
          return history.push('/dashboard')
        default:
          break
      }
    },
    [history]
  )

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  console.log('historyhistoryhistory', history)

  const onSubmitClick = async () => {
    try {
      setLoading(true)

      // const ipdata = (await apiClient.get(`https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json`)).data
      // apiClient.defaults.headers = { ...apiClient.defaults.headers, 'x-forwarded-for': ipdata.ip }
      // //
      // apiClient.defaults.baseURL = 'http://192.168.0.121:3000/'
      debugger
      if (cookies.device) apiClient.defaults.headers = { ...apiClient.defaults.headers, device: cookies.device }
      debugger

      const user: any = await authService.login(inputFields.email, inputFields.password)
      console.log(user, 'loooooooooooooooooooooog')

      debugger

      if (user?.clientList?.length) {
        // apiClient.defaults.headers = { ...apiClient.defaults.headers, Authorization: `Bearer ${user?.accessToken}` }
        setSubClients(true)
        setClientsList(user?.clientList)
        debugger
        localStorage.setItem('parent_user', JSON.stringify(user))

        setLoading(false)
        if (user?.error) {
          showApiResponseError(user?.error, user?.error)
        }
        if (user.user?.device) {
          setCookie(COOKIEE_NAMES.device, user.user.device, { maxAge: 31536000 })
        }

        setCurrentUser(user)
        localStorage.setItem('page_visits', '0')
        localStorage.setItem('user_clicks', '0')

        if (user.twoFA) {
          // return <TwoFA user />
          setShowtfa(true)
        } else {
          console.log('in else')

          updateUser(user.user || null)

          checkingUserType(user?.user?.user_type)
        }
      } else {
        // Remove device header After login
        // delete apiClient.defaults.headers.device

        setLoading(false)
        if (user?.error) {
          showApiResponseError(user?.error, user?.error)
        }
        if (user.user?.device) {
          setCookie(COOKIEE_NAMES.device, user.user.device, { maxAge: 31536000 })
        }
        //localStorage.removeItem('auth-tokens-development')
        // history.push('/auth', {user})
        setCurrentUser(user)
        localStorage.setItem('page_visits', '0')
        localStorage.setItem('user_clicks', '0')

        if (user.twoFA) {
          // return <TwoFA user />
          setShowtfa(true)
        } else {
          console.log('in else')

          updateUser(user.user || null)

          checkingUserType(user?.user?.user_type)
        }
      }

      // history.push('/')
    } catch (err) {
      showApiResponseError(err, 'Error: Invalid Username or Password')
      setLoading(false)
    }
  }

  const onInputFieldsChange = (key: 'email' | 'password', value: string) => {
    setInputValues(prevInputValues => ({
      ...prevInputValues,
      [key]: value,
    }))
  }

  React.useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        onSubmitClick()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [inputFields.email, inputFields.password])

  const renderComponents = () => {
    if (showtwofa) {
      return <TwoFA setCookie={setCookie} user={currentUser} checkingUserType={checkingUserType} />
    } else if (subClients) {
      return <AccountLists clientList={clientsList} />
    } else {
      return (
        <div className={classes.mainContainer}>
          <div className={classes.container}>
            <Card className={classes.root}>
              <Logo />
              <CardHeader title="Log In to continue" classes={{ title: classes.title }} />
              <CardContent>
                <BRTextField
                  required
                  data-testid="client-email"
                  // error={email?.length < 3 ? true : false}
                  // helperText={passlengthError && 'Password cannot be less than 8 characters!'}
                  label="Email"
                  //style={{ marginBottom: clientEditErrors.password ? '1.5rem' : undefined }}
                  //className={classes.textField}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorHelperText,
                    },
                  }}
                  type="text"
                  name="email"
                  margin="normal"
                  variant="outlined"
                  value={inputFields.email}
                  // onChange={event => setEmail(event.target.value)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onInputFieldsChange('email', event.target.value)
                  }
                />
                <BRTextField
                  required
                  data-testid="client-password"
                  label="Password"
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorHelperText,
                    },
                  }}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  margin="normal"
                  variant="outlined"
                  value={inputFields.password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onInputFieldsChange('password', event.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </CardContent>

              <CardActions classes={{ root: classes.btnContainer }}>
                <Button
                  size="large"
                  fullWidth
                  color="primary"
                  className={classes.submitButton}
                  onClick={onSubmitClick}
                  disabled={isDisabled}
                  classes={{ disabled: classes.disableButton }}
                >
                  LOG IN
                </Button>
              </CardActions>

              {/* <Grid container spacing={0} alignContent="center" justify="center" alignItems="center">
              <Grid item xs={5}>
                <Divider />
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                OR
              </Grid>
              <Grid item xs={5}>
                <Divider />
              </Grid>
            </Grid> */}

              <div className={classes?.footerLinks}>
                {/* <Link to="/forgot-username">Forgot Username</Link> */}
                <Link to="/forgot-password">Forgot Password</Link>
                <Link to={refQueryParams ? `/register/?ref=${refQueryParams || ''}` : '/register'}>
                  Create an account
                </Link>
              </div>
            </Card>

            {loading && (
              <div className={classes.rootProgress}>
                <LinearProgress />
              </div>
            )}
          </div>
        </div>
      )
    }
  }
  return renderComponents()

  // const onInputFieldsChange = ({
  //   event,
  //   key,
  // }: {
  //   event: React.ChangeEvent<HTMLInputElement>
  //   key: 'email' | 'password'
  // }) => {
  //   setInputValues(prevState => ({
  //     ...prevState,
  //     [key]: event.target.value,
  //   }))
  // }
  // const styles = (theme: Theme) =>
  //   createStyles({
  //     container: {
  //       display: 'flex',
  //       justifyContent: 'center',
  //       flexDirection: 'column',
  //       alignItems: 'center',

  //       '& .Logo-logoContainer': {
  //         [theme.breakpoints.down('sm')]: {
  //           width: 'auto',
  //         },
  //       },

  //       '& .login-screen-section': {
  //         [theme.breakpoints.down('sm')]: {
  //           '& .MuiPaper-root': {
  //             width: '350px',
  //             '& > div': {
  //               '& > div': {
  //                 padding: '1rem',
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     title: {
  //       color: '#141923',
  //       fontSize: '3.5rem',
  //       fontWeight: 500,
  //       fontFamily: 'SFCompactDisplay',

  //       [theme.breakpoints.down('sm')]: {
  //         fontSize: '3rem',
  //         marginBottom: '-1rem',
  //       },
  //     },
  //     submitButton: {
  //       height: 70,
  //       color: '#fff',
  //       fontSize: '1.5rem',
  //       padding: '0 3.75rem',
  //       textTransform: 'capitalize',
  //       backgroundColor: theme.palette.primary.main,
  //       // backgroundColor: '#26316c',
  //       justifyContent: 'center',
  //       '&:hover': {
  //         backgroundColor: '#2479af',
  //         color: '#fff',
  //       },
  //     },
  //     rootProgress: {
  //       width: '28.15rem',
  //       [theme.breakpoints.down('sm')]: {
  //         width: '350px',
  //       },
  //       '& > * + *': {
  //         marginTop: theme.spacing(2),
  //       },
  //     },
  //   })

  // <>
  //   {!showtwofa ? (
  //     <div className={classes.container}>
  //       <Logo />
  //       <LoginScreen
  //         classes={{ title: classes.title, submitButton: classes.submitButton }}
  //         title="Sign In"
  //         onInputChange={onInputFieldsChange}
  //         onSubmitClick={onSubmitClick}
  //         passwordError={true}
  //         emailLabel="Username"
  //       />
  //       {loading && (
  //         <div className={classes.rootProgress}>
  //           <LinearProgress />
  //         </div>
  //       )}
  //       <Link style={{ marginTop: '1rem' }} to="/forgot-username">
  //         Forgot Username?
  //       </Link>
  //       <Link style={{ margin: '0.25rem 0' }} to="/forgot-password">
  //         Forgot Password?
  //       </Link>
  //       <div style={{ marginBottom: '1rem' }}>
  //         Don&apos;t have an account? &nbsp;
  //         <Link to="/register">Register here.</Link>
  //       </div>

  //       {/* <AccountLists onSubmitClick={onSubmitClick} /> */}
  //     </div>
  //   ) : (
  //     <TwoFA setCookie={setCookie} user={currentUser} checkingUserType={checkingUserType} />
  //   )}
  // </>
  // <>
  //   {!subClients ? (
  //     <div className={classes.container}>
  //       <Logo />
  //       <LoginScreen
  //         classes={{ title: classes.title, submitButton: classes.submitButton }}
  //         title="Sign In"
  //         onInputChange={onInputFieldsChange}
  //         onSubmitClick={onSubmitClick}
  //         passwordError={true}
  //         emailLabel="Username"
  //       />
  //       {loading && (
  //         <div className={classes.rootProgress}>
  //           <LinearProgress />
  //         </div>
  //       )}
  //       <Link style={{ marginTop: '1rem' }} to="/forgot-username">
  //         Forgot Username?
  //       </Link>
  //       <Link style={{ margin: '0.25rem 0' }} to="/forgot-password">
  //         Forgot Password?
  //       </Link>
  //       <div style={{ marginBottom: '1rem' }}>
  //         Don&apos;t have an account? &nbsp;
  //         <Link to="/register">Register here.</Link>
  //       </div>
  //     </div>
  //   ) : (
  //     <AccountLists clientList={clientsList} />
  //   )}
  // </>
}

export default withRouter(withStyles(styles)(Login))
