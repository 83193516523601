import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',

      '& .campany-detail': {
        fontSize: '1.6rem',
        fontFamily: theme.typography.h1.fontFamily,
        width: '100%',
      },

      '& .inline-field': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '2rem',

        '& .reminder-schedule': {
          color: '#fff',
          minWidth: '200px',
          fontSize: '1rem',
          background: '#3c9fdf',
        },

        '& button': {
          color: '#fff',
          background: '#A8BC7B',
          // padding: '1rem 1.5rem',
          fontSize: '1.25rem',
        },
      },
    },
    addBtn: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '2rem 0',

      '& .add-button': {
        color: '#fff',
        background: '#A8BC7B',
        // padding: '1rem 1.5rem',
        fontSize: '1.25rem',
      },

      '& button': {
        background: '#3c9fdf',
        color: '#fff',
        padding: '0.5rem 2.25rem',
        marginRight: '0.75rem',
      },
    },
    template: {
      minWidth: '350px',
    },
    missingItemSection: {
      marginTop: '2rem', //   display: 'flex',
      //   justifyContent: 'space-between',
    },
    orderField: {
      width: '100%',
      marginBottom: '2.4rem',
    },
    searchBar: {
      marginBottom: '1rem',
    },

    headerBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',

      '& .delete-icon': {
        color: '#fff',
        fontSize: '2rem',
      },
    },
    toggleSection: {
      textAlign: 'end',
      marginBottom: '1rem',
    },
  })
)
