import { toTitleCase } from '@jetkit/react'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useState } from 'react'
import Select, { StylesConfig } from 'react-select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import chroma from 'chroma-js'

import Chip from '@material-ui/core/Chip'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { LinearProgress } from '@material-ui/core'
import { Cancel, Alarm } from '@material-ui/icons'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  container: {
    width: 'inherit',
  },
}))
const setColor = (bgColor: string) => {
  const color = chroma(bgColor)
  const diff = chroma.contrast(bgColor, 'white')

  if (diff > 2) {
    return 'white'
  } else return 'black'
  //   return (
  // color
  //   .darken()
  //   //.saturate(7)
  //   .hex()

  //   ) //color.alpha(1).css()
}
const Labels = ({ title, dataList, selectedList, handleChange, loading, handleSave, onDeleteClick, listLoading }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h1>{title}</h1>
      <Select
        closeMenuOnSelect={true}
        //defaultValue={[colourOptions[0], colourOptions[1]]}
        // isMulti
        options={dataList}
        styles={[]}
        onChange={handleChange}
      />
      {listLoading && <LinearProgress />}
      <List>
        <div className={classes.root}>
          {selectedList.map((item, index) => (
            //   <ListItem key={index}>
            //     <ListItemText
            //     //primary={'item.name'}
            //     //secondary={secondary ? 'Secondary text' : null}
            //     >
            <Chip
              key={item.value}
              size="medium"
              label={item.label}
              onDelete={() => onDeleteClick(item.value)}
              style={{ backgroundColor: item.color, color: setColor(item.color) }}
              deleteIcon={<CancelOutlinedIcon style={{ color: setColor(item.color) }} />}
            />

            //     </ListItemText>
            //   </ListItem>
          ))}
        </div>
      </List>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonWithLoading isLoading={loading} onClick={handleSave}>
          Save Labels
        </ButtonWithLoading>
      </div> */}
    </div>
  )
}

export default Labels
