import { TableCell, TableRow } from '@material-ui/core'
import { IVisitorLogs } from 'api/visitorLog'
import * as React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { htmlRegexG } from 'utils/functions'

interface ILogsRowProps {
  logs: IVisitorLogs
}

const LogsRow: React.FC<ILogsRowProps> = ({ logs }) => {
  console.log('logslogslogslogs', logs)

  return (
    <TableRow style={{ cursor: 'pointer' }}>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.ip_address}</TableCell>
      <TableCell style={{ maxWidth: '100px' }}>{logs?.brid}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>
        {logs?.visit_time ? formatDateTimeToHumanFriendly(logs?.visit_time) : '--'}
      </TableCell>
      <TableCell>{logs?.ref_code}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.utm_campaign}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.utm_content}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.utm_medium}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.utm_source}</TableCell>
      <TableCell
        style={{
          maxWidth: '100px',
          display: 'table-cell',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        {logs?.gclid}
      </TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.sscid}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.fbclid}</TableCell>
      <TableCell style={{ maxWidth: '9%' }}>{logs?.msclkid}</TableCell>
      {/* <TableCell>{logs?.other_params}</TableCell> */}
    </TableRow>
  )
}

export default LogsRow
