/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { MenuItem, TextareaAutosize } from '@material-ui/core'
import ModalTitle from '../modalTitle'
import { useStyles } from './uploadModalStyle'
import OutlinedSelect from 'components/common/outlinedSelect'
import { Checkbox, ListItem } from '@material-ui/core'
import useStyles2 from 'components/common/styles/createDialogStyles'
import { toTitleCase } from '@jetkit/react'

interface ModalProps {
  open: boolean
  setOpen: () => void
  uploadHandler: () => any
  docSetter: (val: any) => any
  setnote: (val: any) => any
  notess: any
  selectedType: any
  docs: any
  setvisibleonclient: any
  visibleOnClient: any
  setDocLabel: (val: string) => void
  isRADoc: any
  setIsRADoc: any
  isAddressDoc?: boolean
  setIsAddressDoc: any
}

const UploadModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  uploadHandler,
  docSetter,
  setnote,
  notess,
  selectedType,
  docs,
  setvisibleonclient,
  visibleOnClient,
  setDocLabel,
  isRADoc,
  setIsRADoc,
  isAddressDoc,
  setIsAddressDoc,
}: ModalProps) => {
  const classes = useStyles()
  const classes2 = useStyles2()

  return (
    <Dialog open={open} onClose={setOpen} className={classes.dialogContainer} maxWidth="xs" fullWidth>
      <ModalTitle title={'Upload File'} toggleModal={setOpen} />
      <DialogContent className={classes.root}>
        <OutlinedSelect
          disabled={false}
          name="Document Type"
          value={selectedType}
          options={docs.map((val: any) => val.typename)}
          onOptionSelect={(value: any) => docSetter(value)}
        />
        <TextField label="File Label" variant="outlined" fullWidth onChange={e => setDocLabel(e.target.value)} />
        {/* <TextField
          id="outlined-select-currency"
          select
          label="Doc Type"
          //   value={currency}
          //   onChange={handleChange}
          variant="outlined"
          fullWidth
          onChange={docSetter(null)}
        >
          {docTypes.map((option:any) => (
            <MenuItem key={option.typename} value={option.typename}>
              {option.typename}
            </MenuItem>
          ))}
        </TextField> */}
        <TextField
          label="Notes"
          variant="outlined"
          value={notess}
          fullWidth
          multiline
          onChange={e => setnote(e.target.value)}
        />

        <ListItem
          key={100}
          // disabled={isRoleCheckboxDisabled}
          // data-testid={role.title}
          className={classes2.ownerModalListElement}
          // onClick={() => togglePrimaryContact()}
        >
          <Checkbox
            color="primary"
            checked={visibleOnClient}
            // `value` prop is added here for easier testing.
            // because MUI only changes svg image when checkbox is clicked
            value={visibleOnClient}
            style={{ padding: 0, marginRight: 10 }}
            onChange={() => setvisibleonclient(!visibleOnClient)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Visible To Client')}</span>
        </ListItem>

        <ListItem className={classes2.ownerModalListElement}>
          <Checkbox
            color="primary"
            checked={isRADoc}
            value={isRADoc}
            style={{ padding: 0, marginRight: 10 }}
            onChange={() => setIsRADoc(!isRADoc)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Registered agent document')}</span>
        </ListItem>
        <ListItem className={classes2.ownerModalListElement}>
          <Checkbox
            color="primary"
            checked={isAddressDoc}
            value={isAddressDoc}
            style={{ padding: 0, marginRight: 10 }}
            onChange={() => setIsAddressDoc(!isAddressDoc)}
          />
          <span style={{ fontSize: '19px' }}>{toTitleCase('Address document')}</span>
        </ListItem>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          onClick={() => {
            uploadHandler()
            setOpen()
          }}
          color="primary"
          variant="contained"
          className={classes.uploadBtn}
        >
          Choose File
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploadModal

const docType = [
  {
    value: 'ein',
    label: 'EIN',
  },
  {
    value: 'articleOfAssociation',
    label: 'Artical Of Association',
  },
  {
    value: 'minutesOfMeeting',
    label: 'Minutes Of Meeting',
  },
]
