import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const StatesTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>id</StyledTableCell>
        {/* <StyledTableCell>key</StyledTableCell> */}
        <StyledTableCell>State/ Province</StyledTableCell>
        {/* <StyledTableCell>Zip</StyledTableCell>
        <StyledTableCell>Login Username</StyledTableCell> */}

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default StatesTableHead
