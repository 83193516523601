import React from 'react'

interface InlineItemProps {
  title: string
  content?: string
  chipText?: any
  children?: React.ReactNode
}

export const InlineItem = ({ title, content, chipText, children }: InlineItemProps) => {
  return (
    <div className="inline-items">
      <p className="title">{title}</p>
      {/* {content ? <p className="content">{content}</p> : !chipText?.length ? <p className="content">-</p> : ''} */}

      {children ? (
        <div className="content">{children}</div>
      ) : content ? (
        <p className="content">{content}</p>
      ) : !chipText?.length ? (
        <p className="content">-</p>
      ) : (
        ''
      )}

      {chipText?.length ? (
        <div className="chip-inline">
          {chipText?.map((item, index) => (
            <p className="chip-container" key={index}>
              {item?.title}
            </p>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
