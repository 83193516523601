import { apiClient, requestPaginated } from '@jetkit/react'
import axios, { AxiosResponse } from 'axios'
import { getAccessToken } from 'axios-jwt'
import { IAddress } from 'models/address'
import { IAsset, UploadRequest } from 'models/asset'
import {
  ChangeLogs,
  ICompany,
  ICompanyMeeting,
  ICompanyMeetingApiResponse,
  ICompanyRole,
  ICreateCompanyParams,
  ICreatedCompany,
  IDetailedCompanyResponse,
  IUpdateCompanyParams,
} from 'models/company'
import { ISalesRep } from 'models/salesRep'
import { ICompanyOwner, ICreateUpdateOwner } from 'models/user'
import { backendDateFormat } from 'utils/formatDate'

export const fetchCompanies = async (query = ''): Promise<ICompany[]> => {
  // TODO: Add pagination
  const res = await apiClient.get('/company?page_size=15', { params: { query } })
  return res.data
}

export const deleteCompany = async (id: number): Promise<AxiosResponse<void>> => apiClient.delete(`company/${id}`)

export const paginateCompanies = (client_id: number | null, query: string, labels?: string, activeFilter?: string) => {
  debugger
  let url = `/company?query=${encodeURIComponent(query)}&labels=${labels || ''}`
  if (client_id)
    url = `/company?client_id=${client_id}&query=${encodeURIComponent(query)}&labels=${labels ||
      ''}&active_filter=${activeFilter || ''}`
  return requestPaginated<any>({ url: `${url}` })
}
/**
 * @deprecated dates will be parsed outside API functions
 *
 */
function formatParamsDateForApiRequest({
  obj,
  key,
  format,
}): { obj: Record<string, unknown>; key: string; format: string } {
  if (!obj[key] || !obj[key].format) return obj
  obj[key] = obj[key].format(format)
  return obj
}

export const createCompany = async (params: ICreateCompanyParams): Promise<ICreatedCompany> => {
  const res = await apiClient.post(
    '/company',
    formatParamsDateForApiRequest({ obj: params, key: 'file_date', format: backendDateFormat })
  )
  return res.data
}

export const updateCompany = async (company_id: number, params: any): Promise<ICreatedCompany> => {
  params.shareholders_meeting_address = params.shareholder_meeting_address
  const res = await apiClient.post(`/company/${company_id}`, params)
  return res.data
}

export const updateCompanyAndRefreshOrders = async (
  company: ICreatedCompany,
  params: IUpdateCompanyParams
): Promise<ICreatedCompany> => {
  //const res = await apiClient.patch(`/company/${company.id}/update_company_and_refresh_orders`, params)
  const res = await apiClient.post(`/company/${company.id}`, params)

  return res.data
}

export const updateCompanyById = async (
  companyId: number,
  params: Partial<IDetailedCompanyResponse>
): Promise<IDetailedCompanyResponse> =>
  (
    await apiClient.post(
      `/company/${companyId}`,
      formatParamsDateForApiRequest({ obj: params, key: 'file_date', format: backendDateFormat })
    )
  ).data

export const createOwner = async (params: ICreateUpdateOwner, companyId: number): Promise<ICompanyOwner[]> => {
  const res = await apiClient.post(`/company/${companyId}/user`, params)
  return res.data
}

export const fetchCompanyOwners = async (companyId: number): Promise<ICompanyOwner[]> => {
  //const res = await apiClient.get(`/company/1/user`)
  const res = await apiClient.get(`/company/${companyId}/user`)

  return res.data
}

export const deleteOwner = async (companyId: number, userId: number): Promise<void> => {
  await apiClient.delete(`/company/${companyId}/user/${userId}`)
}

export const getCompanyAddresses = async (companyId: number): Promise<IAddress[]> => {
  //const res = await apiClient.get(`company/1/address/available`)
  const res = await apiClient.get(`company/${companyId}/address/available`)
  return res.data
}

export const addCompanyMeeting = async (
  companyId: number,
  params: ICompanyMeeting
): Promise<ICompanyMeetingApiResponse> => {
  const res = await apiClient.post(
    `company/${companyId}/meeting`,
    formatParamsDateForApiRequest({ obj: params, key: 'date', format: 'YYYY-MM-DD hh:mm' })
  )
  return res.data
}

export const getCompanyMeetings = async (companyId: number): Promise<ICompanyMeetingApiResponse[]> => {
  const res = await apiClient.get(`company/${companyId}/meeting`)
  //const res = await apiClient.get(`company/1/meeting`)
  return res.data
}

export const getCompanyDocuments = (companyId: number) =>
  //requestPaginated<IAsset[]>({ url: `company/${companyId}/asset` })
  requestPaginated<IAsset[]>({ url: `company/${companyId}/asset` })

interface ICompanyAssetParams {
  companyId: number
  documentId: number
}
interface ICompanyUpdateAssetParams {
  documentId: number
}

export const deleteCompanyDocument = async ({ companyId, documentId }: ICompanyAssetParams): Promise<void> => {
  await apiClient.delete(`company/${companyId}/asset/${documentId}`)
}

export const toggleClientDocuments = async (documentId: any, value: any): Promise<any> => {
  return await apiClient.post(`toggleClientDoc/${documentId}`, { value: value })
}
export const toggleCompanyStatus = async (company_id: number, value: boolean): Promise<any> => {
  return await apiClient.post(`business-status/${company_id}`, { business_active: value })
}
export const downloadCompanyDocumentURL = async ({ companyId, documentId }: ICompanyAssetParams): Promise<string> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = getAccessToken()
  //return `${BASE_URL}/company/${companyId}/asset/${documentId}/download?jwt=${token}`
  //const res = await apiClient.get(`company/1/meeting`)
  const res = (await apiClient.get(`${BASE_URL}/company/${companyId}/asset/${documentId}/download?jwt=${token}`)).data
  return res //res.data;
}
export const downloadClientCompanyDocumentURL = async ({
  companyId,
  documentId,
}: ICompanyAssetParams): Promise<any> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  // const token = getAccessToken()
  //return `${BASE_URL}/company/${companyId}/asset/${documentId}/download?jwt=${token}`
  //const res = await apiClient.get(`company/1/meeting`)
  // const res = (await apiClient.get(`${BASE_URL}/client/company/${companyId}/asset/${documentId}/download?jwt=${token}`))
  const res = (await apiClient.get(`${BASE_URL}/client/company/${companyId}/asset/${documentId}/download`)).data
  return res //res.data;
}

export const updateCompanyMeeting = async (meetingId: number, params: ICompanyMeeting): Promise<any> => {
  // const res = await apiClient.patch(
  //   `meeting/${meetingId}`,
  //   formatParamsDateForApiRequest({ obj: params, key: 'date', format: 'YYYY-MM-DD hh:mm' })
  // )
  // return res.data
  return await {}
}

export const getCompanyRoles = async (companyId: number): Promise<ICompanyRole[]> => {
  return (await apiClient.get(`company/${companyId}/role/available`)).data
  //return (await apiClient.get(`company/1/role/available`)).data
}
export const deleteCompanyMeeting = async (meetingId: number): Promise<void> => {
  await apiClient.delete(`meeting/${meetingId}`)
}

export const formatAddressForApiPost = (address: IAddress) => {
  return {
    address_type: address.address_type,
    city_id: address.city_id,
    city: address.city,
    country: address.country,
    is_primary: address.is_primary,
    locality: address.locality,
    postal_code: address.postal_code,
    street: address.street,
  }
}

export const createCompanyAddress = async (companyId: number, address: IAddress): Promise<IAddress> => {
  const res = await apiClient.post(`company/${companyId}/address`, formatAddressForApiPost(address))
  return res.data
}

export const getCompanyDetails = async (companyId: number): Promise<IDetailedCompanyResponse> => {
  const res = await apiClient.get(`company/${companyId}`)
  return res.data
}

export const getCompanyPrimaryAddress = async (companyId: number): Promise<IAddress> => {
  const res = await apiClient.get(`company/${companyId}/address/primary`)
  return res.data
}

export const uploadCompanyAsset = async (companyId: number, file: File): Promise<UploadRequest> => {
  // (await apiClient.post(`company/${companyId}/upload-asset`, { mime_type: file.type, file_name: file.name })).data
  return (await apiClient.post(`company/1/upload-asset`, { mime_type: file.type, file_name: file.name })).data
}
export const getSalesRep = async (id: number): Promise<ISalesRep> => (await apiClient.get(`sales-rep/${id}`)).data

/**
 * At the end of company creation we want to set the status of all company orders to `initiated.
 * Besides we want to delete all the deactivated tasks for company orders.
 */
export const saveAndInitiateOrders = async (companyId: number): Promise<void> => {
  //await apiClient.post(`company/${companyId}/save-and-initiate-orders`)
  return
}
export const getCompany = async (companyId: number): Promise<ICompany> => {
  apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
  return (await apiClient.get(`company/${companyId}`)).data
}
export const getOwnershipTaken = async (companyId: number): Promise<any> => {
  return (await apiClient.get(`companyOwnerShipTaken/${companyId}`)).data
}

export const createcompanyTask = async (company_id: number, companyTask: any): Promise<any> =>
  //(await apiClient.post(`order/${orderId}/task`, orderTask)).data
  (await apiClient.post(`/task`, companyTask)).data

export const updateCompanyTask = async (companyTask: any): Promise<any> =>
  (await apiClient.post(`task/${companyTask.id}`, companyTask)).data

export const getCompanyTasks = async (company_id: number): Promise<any[]> =>
  (await apiClient.get(`company/${company_id}/task`)).data

export const getCompanySpecialOffer = async (company_id: number): Promise<any[]> =>
  (await apiClient.get(`special-offers-companies/${company_id}`)).data

export const editUploadDocument = async (documentId: ICompanyUpdateAssetParams, payload: any): Promise<any> => {
  await (await apiClient.post(`company/update-asset/${documentId}`, payload)).data
}

export const editCompanyUploadDocument = async (documentId: ICompanyUpdateAssetParams, payload: any): Promise<any> => {
  await (await apiClient.post(`company/update-asset/${documentId}`, payload)).data
}
export const editClientUploadDocument = async (documentId: ICompanyUpdateAssetParams, payload: any): Promise<any> => {
  await (await apiClient.post(`client/update-asset/${documentId}`, payload)).data
}

export const getCompanyChangeLogs = (companyId: number) =>
  requestPaginated<ChangeLogs[]>({ url: `company-change-log/${companyId}` })

export const toggleRADocuments = async (documentId: any, value: any): Promise<any> => {
  return await apiClient.post(`toggle-registered-agent-doc/${documentId}`, { value: value })
}
export const toggleAddressDocument = async (documentId: any, value: any): Promise<any> => {
  return await apiClient.post(`toggle-address-doc/${documentId}`, { value: value })
}
