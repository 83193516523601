import { FilterableAPICall, handleError, PagedTable, usePagedTable } from '@jetkit/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  addInvoice,
  addQuestion,
  deleteQuestion,
  editQuestion,
  fetchInvoices,
  IInvoice,
  IQuestion,
  paginateInvoices,
  QuestionStatusText,
  updateInvoice,
  updateInvoiceStatus,
} from 'api/taxOrders'
import SearchBar from 'components/common/searchBar'
import QuestionCard from 'components/taxOrders/details/pendingQuestions/questionCard'
import QuestionDialog, { QuestionDialogType } from 'components/taxOrders/details/pendingQuestions/questionDialog'
import Toolbar from 'components/taxOrders/details/pendingQuestions/toolbar'
import * as React from 'react'
import PrimaryButton from 'components/common/buttons/primaryButton'
import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import { EmptyTableRow, skeletons, StyledTable } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import InvoiceTableRow from './row'
import InvoiceDialog from './addInvoiceDialog'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { INVOICE_PAYMENT_STATUS } from 'utils/constant'
import { Table } from '@material-ui/core'
import InvoiceTableHead from './invoiceTableHead'
import useSortTable from 'hooks/useSortTable'
import moment from 'moment'
import DatePicker from 'components/common/datePicker'
import { datePickerDatePlaceholder } from 'utils/formatDate'
import IconButton from '@material-ui/core/IconButton'
import { Clear } from '@material-ui/icons'

interface InvoiceProps {
  taxOrderId: number
  // apiCall?: FilterableAPICall<IInvoice>
}

const Invoices: React.FC<InvoiceProps> = ({
  taxOrderId,
  // apiCall = paginateInvoices(INVOICE_PAYMENT_STATUS.All, { order: 'desc', sort_by: 'id' }, '', taxOrderId),
}) => {
  const classes = useStyles()
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [searchPaymentStatus, setSearchPaymentStatus] = React.useState(INVOICE_PAYMENT_STATUS.All)

  const [questionsYear, setQuestionsYear] = React.useState(new Date().getFullYear().toString())
  const [questionDialogType, setQuestionDialogType] = React.useState<QuestionDialogType>('add')
  const [addQuestionDialogShown, setAddQuestionDialogShown] = React.useState(false)
  const [newQuestionText, setNewQuestionText] = React.useState('')
  const [editedQuestionId, setEditedQuestionId] = React.useState(0)
  const [editedQuestionText, setEditedQuestionText] = React.useState('')
  const [editedAnswerText, setEditedAnswerText] = React.useState('')
  const [waitingForResponse, setWaitingForResponse] = React.useState(false)
  const [unansweredQuestion, setUnansweredQuestion] = React.useState(false)
  const [invoiceNo, setInvoiceNo] = React.useState<string>('')
  const [issueDate, setIssueDate] = React.useState<string>('')
  const [amount, setAmount] = React.useState<number>(0)
  const [loading, setLoading] = React.useState(false)
  const [paymentLink, setPaymentLink] = React.useState<string>('')
  const [invoiceId, setInvoiceId] = React.useState(0)

  const handleYearChange = React.useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) setQuestionsYear(date.year().toString())
    },
    [setQuestionsYear]
  )
  const { sortSettings, handleSortingClick } = useSortTable({ order: 'desc', sort_by: 'id' })
  const [dateFiltr, setDateFiltr] = React.useState<string | undefined>('')

  const tableHead = React.useMemo(
    () => <InvoiceTableHead sortSettings={sortSettings} handleSortingClick={handleSortingClick} />,
    [sortSettings, handleSortingClick]
  )
  // apiCall is not included to these memo dependencies because in such case
  // whole component is rerendered infinitely.
  // Instead of that taxOrderId is added there, because apiCall function, returned by fetchTaxQuestions(taxOrderId: number),
  // will be changed only if taxOrderId changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const memoApiCall = React.useMemo(
  //   () => apiCall({ query: searchKeyword, dateFiltr, payment_status: searchPaymentStatus }),
  //   [searchKeyword, taxOrderId, searchPaymentStatus, dateFiltr]
  // )
  const apiCall = React.useMemo(
    () => paginateInvoices(searchPaymentStatus, sortSettings, dateFiltr, taxOrderId)({ query: searchKeyword }),
    [searchKeyword, searchPaymentStatus, dateFiltr, sortSettings]
  )
  const pagedTable = usePagedTable<IInvoice>({
    apiCall: apiCall,
  })
  const onDateFilterChange = React.useCallback((date: any | null) => {
    setDateFiltr(moment(date).format('MM/DD/YYYY'))
  }, [])
  const handleOpenDialogClicked = React.useCallback(
    (dialogType: QuestionDialogType) => {
      setQuestionDialogType(dialogType)
      setAddQuestionDialogShown(true)
    },
    [setQuestionDialogType, setAddQuestionDialogShown]
  )

  const handleQuestionDialogClosed = React.useCallback(() => {
    setNewQuestionText('')
    setEditedQuestionText('')
    setEditedAnswerText('')
    setAddQuestionDialogShown(false)
    setQuestionDialogType('add')
    setInvoiceId(0)
  }, [setNewQuestionText, setAddQuestionDialogShown])

  const getStatusForQuestion = React.useCallback(() => {
    if (editedAnswerText) {
      if (unansweredQuestion) {
        return QuestionStatusText.UNANSWERED
      }
      return QuestionStatusText.ANSWERED
    } else if (waitingForResponse) {
      return QuestionStatusText.SENT_TO_CLIENT
    }
    return QuestionStatusText.PENDING
  }, [editedAnswerText, unansweredQuestion, waitingForResponse])

  const handleSaveClicked = React.useCallback(
    async (dialogType: QuestionDialogType) => {
      const data: IInvoice = {
        invoice_no: invoiceNo,
        amount: amount,
        issue_date: issueDate,
        payment_link: paymentLink,
      }
      setWaitingForResponse(true)
      try {
        if (dialogType === 'add') await addInvoice(taxOrderId, data)
        else if (dialogType === 'edit') await updateInvoice(invoiceId, data)
        else await editQuestion(editedQuestionId, editedQuestionText, editedAnswerText, getStatusForQuestion())
        setWaitingForResponse(false)
        setAddQuestionDialogShown(false)
        setNewQuestionText('')
        setPaymentLink('')
        setEditedQuestionText('')
        setEditedAnswerText('')
        setAmount(0)
        setIssueDate('')
        setInvoiceNo('')
        setQuestionDialogType('add')
        setInvoiceId(0)
        pagedTable.reloadData()
      } catch (error) {
        alert(error)
        setWaitingForResponse(false)
        handleError(error)
      }
    },
    [
      amount,
      editedAnswerText,
      editedQuestionId,
      editedQuestionText,
      getStatusForQuestion,
      invoiceId,
      invoiceNo,
      issueDate,
      pagedTable,
      paymentLink,
      taxOrderId,
    ]
  )
  const updateStatus = async (taxid, paid, invoiceId) => {
    setLoading(true)
    await updateInvoiceStatus(taxid, paid, invoiceId)
    setLoading(false)
    pagedTable.reloadData()
  }
  const handleDeleteQuestionClicked = React.useCallback(
    async (question: IQuestion) => {
      setWaitingForResponse(true)
      try {
        await deleteQuestion(question.id)
        setWaitingForResponse(false)
        pagedTable.reloadData()
      } catch (error) {
        setWaitingForResponse(false)
        handleError(error)
      }
    },
    [pagedTable]
  )

  const handleMarkUnansweredClick = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnansweredQuestion(event.target.checked)
    },
    [setUnansweredQuestion]
  )
  const onEditClick = React.useCallback((invoice: IInvoice) => {
    setQuestionDialogType('edit')
    setAddQuestionDialogShown(true)

    setInvoiceId(invoice?.id || 0)

    setInvoiceNo(invoice?.invoice_no)
    setIssueDate(invoice?.issue_date)
    setAmount(invoice?.amount)
    setPaymentLink(invoice?.payment_link || '')

    console.log('invoiceinvoiceinvoiceinvoice', invoice)

    return
  }, [])
  return (
    <>
      <div className={classes.headerContainer}>
        <p className={classes.tableTitle}>Invoices</p>
      </div>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div style={{ height: 50 }}>
            <PrimaryButton
              style={{ marginTop: 10, marginBottom: 10, width: '15%', float: 'right', display: 'flex' }}
              onClick={() => setAddQuestionDialogShown(true)}
              // disabled={isSendingRequest}
              // data-testid={TestID.SAVE_TAX_ORDER_BUTTON}
            >
              Add Invoice
            </PrimaryButton>
          </div>
          <div className={classes.mainFiltersContainer}>
            <div className={classes.innerSwitchButtonsContainer}>
              <div className={classes.filterWithTitle}>
                <Typography className={classes.filterTitle}>Payment Status</Typography>
                <ToggleButtonGroup
                  className={classes.toggleButtonGroup}
                  onChange={(e, val) => setSearchPaymentStatus(val)}
                  exclusive
                  value={searchPaymentStatus}
                >
                  <ToggleButton value={INVOICE_PAYMENT_STATUS.Paid} className={classes.toggleButton}>
                    <Typography className={classes.isFileButtonText}>Paid</Typography>
                  </ToggleButton>
                  <ToggleButton value={INVOICE_PAYMENT_STATUS.Unpaid} className={classes.toggleButton}>
                    <Typography className={classes.isFileButtonText}>Unpaid</Typography>
                  </ToggleButton>
                  <ToggleButton value={INVOICE_PAYMENT_STATUS.All} className={classes.toggleButton}>
                    <Typography className={classes.isFileButtonText}>All</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <DatePicker
              label="Issue Date"
              style={{ marginTop: '1rem' }}
              onDateSelected={onDateFilterChange}
              value={dateFiltr && moment(dateFiltr)}
              placeholder={datePickerDatePlaceholder}
              dataTestId="due-date"
              InputProps={{
                startAdornment: dateFiltr ? (
                  <IconButton onClick={() => setDateFiltr('')}>
                    <Clear />
                  </IconButton>
                ) : (
                  ''
                ),
              }}
              inputVariant={'outlined'}
            />
          </div>
          <SearchBar className={classes.searchBarContainer} onChange={searchText => setSearchKeyword(searchText)} />
          <br />
          <TableLoadingIndicator isLoading={pagedTable.isLoading} />

          {pagedTable.isLoading || loading ? (
            // <CircularProgress style={{ alignSelf: 'center', marginTop: '1rem' }} />
            <Table>
              {tableHead}

              {skeletons({
                numberOfColumns: 10,
                numberOfRows: 10,
                sizes: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
              })}
            </Table>
          ) : (
            <PagedTable
              {...pagedTable.renderProps}
              renderRow={(invoice: IInvoice) => (
                <InvoiceTableRow
                  invoice={invoice}
                  updateStatus={updateStatus}
                  loading={loading}
                  onEditClick={onEditClick}
                />
              )}
              colSpan={10}
              header={tableHead}
            />
          )}
          {/* <TableBody>
      {!pagedTable.isLoading ? (
        fetchedResult(invoices)
      ) : (
        skeletons({ numberOfColumns: 5, numberOfRows: 10, sizes: [140, 130, 140, 160, 100, 60] })
      )}
    </TableBody> */}
        </div>
        <InvoiceDialog
          dialogType={questionDialogType}
          shown={addQuestionDialogShown}
          onClose={handleQuestionDialogClosed}
          loading={waitingForResponse}
          onSaveClick={handleSaveClicked}
          setAmount={setAmount}
          setInvoiceNo={setInvoiceNo}
          setIssueDate={setIssueDate}
          amount={amount}
          invoiceNo={invoiceNo}
          issueDate={issueDate}
          paymentLink={paymentLink}
          setPaymentLink={setPaymentLink}
        />
      </div>
    </>
  )
}

export default Invoices

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: theme.customPalette.tableBackgroundColor,
      // paddingBottom: 300,
    },
    innerContainer: {
      display: 'flex',
      // maxWidth: '79.5rem',
      width: '100%',
      flexDirection: 'column',
    },
    searchBarContainer: {
      //   marginTop: '2rem',
      display: 'flex',
      width: '100%',
    },
    mainFiltersContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1rem',
    },
    filterTitle: {
      fontSize: 14,
    },

    checkBoxRoot: {
      padding: 0,
    },
    toggleButton: {
      height: '100%',
      width: 90,
    },
    isFileButtonText: {
      fontSize: 12,
      color: 'grey',
    },
    innerMultiSelectContainer: {
      display: 'flex',
      width: '100%',
    },
    innerSwitchButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'end',
    },
    filterWithTitle: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    toggleButtonGroup: {
      height: 38,
      paddingTop: '0.125rem',
    },
    headerContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: '1rem',
    },
    tableTitle: {
      color: '#020202',
      fontSize: '2rem',
      fontFamily: 'Lato',
      fontWeight: 600,
      margin: 0,
    },
  })
)
