import { Grid } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { ITaskType } from 'models/task'
import * as React from 'react'

const styles = createStyles({
  root: {
    display: 'flex',
    width: '100%',
    margin: '0.5rem 0',
  },
  group: {
    display: 'flex',
    padding: '0.5rem 0',
    // justifyContent: 'space-between',

    '& .MuiFormControlLabel-root': {
      marginRight: '0',
      marginLeft: '0',
      flexDirection: 'row',
    },
  },
})

interface ITaskTypesProps extends WithStyles<typeof styles> {
  handleTaskTypeChange(priority: string): void
  value: ITaskType
}

const TaskTypes: React.FunctionComponent<ITaskTypesProps> = ({
  value: defaultValue,
  classes,
  handleTaskTypeChange,
}) => {
  function handleChange(event: React.ChangeEvent<unknown>) {
    const taskType = (event.target as HTMLInputElement).value as ITaskType
    handleTaskTypeChange(taskType)
  }

  const taskTypes = [
    {
      label: 'General',
      value: 'general',
    },
    {
      label: 'Client',
      value: 'client',
    },
    {
      label: 'Company',
      value: 'company',
    },
    {
      label: 'Order',
      value: 'order',
    },
    {
      label: 'Prospect',
      value: 'prospect',
    },
    {
      label: 'Tax Order',
      value: 'taxorder',
    },
    {
      label: 'Compliance',
      value: 'compliance',
    },
  ]
  return (
    <FormControl className={classes.root}>
      <FormLabel>Task Type</FormLabel>
      <RadioGroup
        className={classes.group}
        aria-label="Task"
        name="task"
        value={defaultValue}
        onChange={handleChange}
        row
      >
        <Grid container spacing={0}>
          {taskTypes.map(type => (
            <Grid item xs={4} key={type.value}>
              <FormControlLabel
                data-testid={`task-type-${type.value}`}
                label={type.label}
                labelPlacement="bottom"
                value={type.value}
                control={<Radio />}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  )
}

export default withStyles(styles)(TaskTypes)
