import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useStyles } from './styles'
import { Box, Button, CircularProgress, Fab, InputAdornment, Table, TableBody, TextField } from '@material-ui/core'
import { ArrowUpward, CloudUpload, CreateOutlined, Edit, Sms } from '@material-ui/icons'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import {
  IAssistantResponse,
  IDocuments,
  IThread,
  IThreadResponse,
  aiAssistantMessage,
  aiAssistantThreads,
  confirmFileUpload,
  deleteUploadDocument,
  fileUploadSignURL,
  getAssistant,
  getDownloadURL,
  getThread,
  getUploadDocuments,
  updateAssistant,
} from 'api/ai-assistant'
import { useDropzone } from 'react-dropzone'
import showErrorNotification from 'utils/showErrorNotification'
import showApiResponseError from 'utils/showApiResponseError'
import axios from 'axios'
import { useInterval } from 'hooks/useInterval'
import showSuccessNotification from 'utils/showSuccessNotification'
import TypingAnimation from './typingAnimation'
import useUser from 'hooks/useUser'
import ModalWrapper from 'components/leadManagerElements/modalWrapper'
import { PagedTable, usePagedTable } from '@jetkit/react'
import { skeletons } from 'components/common/table'
import useSortTable from 'hooks/useSortTable'
import Head from './docmumentTabel/head'
import Row from './docmumentTabel/row'
import DeleteTempleteDialog from 'components/notifications/Templetes/TempletesDialogs/DeleteTempleteDialog'
import DeleteConfirmationDialog from 'components/common/dialogs/DeleteConfirmationDialog'

interface IResponseObj {
  question: string
  answer: string
}

const RockyAIChat = () => {
  const classes = useStyles()

  const { user } = useUser()
  const myRef = React.useRef<HTMLInputElement>(null)

  const [promptText, setPromptText] = useState('')
  const [responseObj, setResponseObj] = useState<IResponseObj[]>([])
  const [isPromptStart, setIsPromptStart] = useState(false)
  const [threadResponse, setThreadResponse] = useState<IThread>()

  const [isResponseLoading, setIsResponseLoading] = useState(false)
  const [isFileResponseUploading, setIsFileResponseUploading] = useState(false)

  const [deleteDialoge, setDeleteDialoge] = React.useState(false)
  const [deleteDocument, setDeleteDocument] = React.useState<IDocuments>()
  const [isDownloadableLink, setIsDownloadableLink] = useState<boolean>(false)

  const executeScroll = () => {
    console.log('executeScrollexecuteScrollexecuteScroll Scrolling to bottom')
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  const [isDocumentOpen, setIsDocumentOpen] = useState(false)
  const [isPromptOpen, setIsPromptOpen] = useState(false)

  const [isUpdatingAssistantloading, setIsUpdatingAssistantloading] = useState(false)
  const [assistantResponse, setAssistantResponse] = useState<IAssistantResponse>()

  const [instructionsText, setInstructionsText] = useState('')

  const [promptID, setPromptID] = useState<IThreadResponse>()

  const [isPromptDisable, setIsPromptDisable] = useState(true)

  const [query, setQuery] = React.useState('')
  const { sortSettings, handleSortingClick } = useSortTable({ order: 'asc', sort_by: 'subject' })
  const { order, sort_by } = sortSettings

  const memoApiCall = useMemo(() => getUploadDocuments()({ sort_order: order, sort_by, query }), [
    order,
    query,
    sort_by,
  ])

  const pagedTable = usePagedTable<IDocuments>({
    apiCall: memoApiCall,
  })

  const tableHead = useMemo(() => <Head sortSettings={sortSettings} handleSortingClick={handleSortingClick} />, [
    sortSettings,
    handleSortingClick,
  ])

  const handleSuccessfulRequest = React.useCallback(() => {
    pagedTable.reloadData()
  }, [pagedTable])

  const handleDelete = (data: IDocuments) => {
    setDeleteDocument(data)
    setDeleteDialoge(true)
  }

  const handleOpenDocumentModal = () => {
    handleSuccessfulRequest()
    setIsDocumentOpen(true)
  }

  console.log('deleteDocument', deleteDocument)

  const handleDeleteDocumentClick = async () => {
    if (!deleteDocument?.id) return

    try {
      await deleteUploadDocument(deleteDocument?.id)
      showSuccessNotification('Documents deleted')
      setDeleteDialoge(false)
    } catch (error) {
      showApiResponseError(error, 'Failed to delete a Documents')
    }
    setDeleteDialoge(false)

    handleSuccessfulRequest()
  }

  const handleDownloadURL = async (data: IDocuments) => {
    console.log('datadatadatadata', data)
    if (!data?.id) return
    console.log('datadatadatadata', data)

    try {
      setIsDownloadableLink(true)
      const downloadDoc = await getDownloadURL(data?.id)

      console.log('downloadDocdownloadDoc', downloadDoc)

      window.location.assign(downloadDoc || '')
    } catch (error) {
      console.log(error)
    }
    setIsDownloadableLink(false)
  }

  const fetchThreadResponse = async () => {
    setIsResponseLoading(true)

    try {
      const data = await getThread()

      setResponseObj(prevList => [...prevList, { question: '', answer: data?.result }])
      setThreadResponse(data)
      if (data?.completed) {
        executeScroll()
        setIsPromptStart(false)
        setIsResponseLoading(false)
      }

      console.log('threadResponsethreadResponse', data)
    } catch (error) {
      console.log('error', error)
    }
  }

  useInterval(
    () => {
      fetchThreadResponse()
    },
    // !isPromptStart || threadResponse?.completed ? null : 5000,
    5000,
    isPromptStart
  )

  const fetchAssistantResponse = async () => {
    try {
      const data = await getAssistant()
      setAssistantResponse(data)
      setInstructionsText(data?.instructions)

      console.log('getAssistantgetAssistant', data)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchAssistantResponse()
  }, [])

  const fetchAssistantID = async () => {
    try {
      const data = await aiAssistantThreads()
      setPromptID(data)

      console.log('fetchAssistantIDfetchAssistantID', data)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchAssistantID()
  }, [])

  const handleUpdateAssistant = async () => {
    if (!instructionsText?.length) {
      return
    }

    try {
      setIsUpdatingAssistantloading(true)
      await updateAssistant(assistantResponse?.id || 0, instructionsText)
      setInstructionsText('')
      setIsPromptOpen(false)
      setIsPromptDisable(true)
      showSuccessNotification('Successfully Updated.!')
      fetchAssistantResponse()
    } catch (error) {
      showErrorNotification(error ? (error as any)?.message : 'Something went wrong!')
      showApiResponseError(error as any, 'Something went wrong!')
    }
    setIsUpdatingAssistantloading(false)
  }

  const handleFileUpload = async (file: File) => {
    // Handle the uploaded file
    console.log('Uploaded file:', file)

    try {
      setIsFileResponseUploading(true)
      const fileUpload = await fileUploadSignURL(file?.name)

      const uploadS3 = await axios({
        url: fileUpload.url,
        method: 'PUT',
        headers: fileUpload?.headers,
        data: file,
      })
      console.log('uploadS3', uploadS3)

      const confirmFile = await confirmFileUpload(fileUpload?.docId)
      console.log('confirmFile', confirmFile)
      showSuccessNotification(confirmFile?.success)
    } catch (error) {
      showErrorNotification(error ? (error as any)?.message : 'Something went wrong!')
      showApiResponseError(error as any, 'Something went wrong!')
    }
    setIsFileResponseUploading(false)
  }

  const onDrop = useCallback((files: File[]) => {
    console.log('files', files)

    if (!files.length) throw new Error(`No file provided`)

    const file = files[0]
    handleFileUpload(file)
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true })

  const handleAddPrompt = (text: string) => {
    setPromptText(text)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      const enteredValue = (event.target as HTMLInputElement).value
      setPromptText(enteredValue)
      console.log('Entered value:', enteredValue)
      handleSentPrompt()
      // Do something with the entered value
    }
  }

  const handleSentPrompt = async () => {
    if (!promptText) return
    setResponseObj(prevList => [...prevList, { question: promptText, answer: '' }])
    setPromptText('')
    setIsResponseLoading(true)

    const payload = {
      prompt: promptText,
      thread_id: promptID?.thread_id,
    }

    try {
      const result: string = await aiAssistantMessage(payload)
      setIsPromptStart(true)
      // setResponseObj(prevList => [...prevList, { question: '', answer: result }])
      console.log('promptTextpromptTextpromptText', result)
    } catch (error) {
      showErrorNotification(error ? (error as any)?.message : 'Something went wrong!')
      showApiResponseError(error as any, 'Something went wrong!')
      console.log('error', error)
      // setIsResponseLoading(false)
    }
  }

  console.log('responseObjresponseObj', responseObj)
  console.log('isResponseLoadingisResponseLoading', isResponseLoading)

  function handleOrderDelete(id: any): void {
    throw new Error('Function not implemented.')
  }

  useEffect(() => {
    executeScroll()
    console.log('executeScrollexecuteScrollexecuteScroll')
  }, [responseObj])

  return (
    <div className={classes.rockyAIChatContainer}>
      <div className="inline-items">
        <h1 className="primary-heading">Rocky AI</h1>
        <div className="btn-group">
          <Button variant="outlined" color="primary" onClick={handleOpenDocumentModal}>
            View Submitted Documents
          </Button>
          <Button variant="contained" color="primary" onClick={() => setIsPromptOpen(true)}>
            Update Prompt
          </Button>
        </div>
      </div>

      <div className={classes.chatContainer}>
        {responseObj?.map(item => (
          <React.Fragment>
            {item?.question?.length ? (
              <div
                className="chatbox-container"
                style={{
                  justifyContent: 'flex-start',
                }}
              >
                <div className="chatbox-section">
                  <p className="title">{user?.name}:</p>
                  <p className="text">{item?.question}</p>

                  <div className="inline-icon">
                    <Sms color="disabled" fontSize="small" />

                    <p>{formatDateTimeToHumanFriendly(Date())}</p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {item?.answer?.length ? (
              <div
                className="chatbox-container"
                style={{
                  justifyContent: 'flex-start',
                }}
              >
                <div className="email-chatbox-section">
                  <p className="title">Rocky AI:</p>
                  <p className="text">{item?.answer}</p>
                  {/* <TypingAnimation text={item?.answer} typingSpeed={25} /> */}
                  {/* {typeText(<p className="text"></p>, item?.answer)} */}
                  {/* <p className="text" dangerouslySetInnerHTML={{ __html: item?.answer }} /> */}
                  {/* <div className="typing-container">
                    <span className="text typing-text">{item?.answer}</span>
                    <span className="cursor">|</span>
                  </div> */}
                  {/* <div className={classes.typingContainer}>
                    <span className={classes.typingText}>{item?.answer}</span>
                    <span className={classes.cursor}>|</span>
                  </div> */}
                  <div className="inline-icon">
                    <Sms color="primary" fontSize="small" />

                    <p>{formatDateTimeToHumanFriendly(Date())}</p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        ))}

        {isResponseLoading || isFileResponseUploading ? (
          <div className={classes.loader}>
            <div className={classes.loaderElement}></div>
          </div>
        ) : (
          ''
        )}

        <div ref={myRef} />
      </div>

      <TextField
        placeholder="Message RockyAI"
        className={classes.promptField}
        value={promptText}
        name="promptText"
        onChange={e => handleAddPrompt(e.target.value)}
        onKeyDown={handleKeyPress}
        disabled={isResponseLoading || isFileResponseUploading}
        // focused
        autoFocus
        multiline
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CreateOutlined fontSize="small" color="disabled" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {/* <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Fab color="secondary" aria-label="Upload" onClick={open} disabled={isFileResponseUploading}>
                  {isFileResponseUploading ? <CircularProgress size={24} /> : <CloudUpload htmlColor="#fff" />}
                </Fab>
              </div> */}
              <Fab
                color="primary"
                aria-label="Send"
                onClick={handleSentPrompt}
                disabled={isResponseLoading || isFileResponseUploading}
              >
                {isResponseLoading ? <CircularProgress size={24} /> : <ArrowUpward htmlColor="#fff" />}
              </Fab>
            </InputAdornment>
          ),
        }}
      />

      <ModalWrapper open={isDocumentOpen} setOpen={setIsDocumentOpen} maxWidth="lg" heading="Submitted Document List">
        <div {...getRootProps()} style={{ textAlign: 'end' }}>
          <input {...getInputProps()} />
          <Fab color="secondary" aria-label="Upload" onClick={open} disabled={isFileResponseUploading}>
            {isFileResponseUploading ? <CircularProgress size={24} /> : <CloudUpload htmlColor="#fff" />}
          </Fab>
        </div>
        {pagedTable.isLoading ? (
          <Table>
            {tableHead}
            <TableBody>
              {skeletons({ numberOfColumns: 2, sizes: [100, 100, 100, 100, 100], numberOfRows: 6 })}
            </TableBody>
          </Table>
        ) : (
          <PagedTable
            colSpan={7}
            emptyRowText="No Notifications"
            {...pagedTable.renderProps}
            renderRow={(list: IDocuments) => (
              <Row
                doucments={list}
                onDownloadClick={() => handleDownloadURL(list)}
                onDeleteClick={() => handleDelete(list)}
                isDocLoading={isDownloadableLink}
              />
            )}
            header={tableHead}
          />
        )}
      </ModalWrapper>

      <ModalWrapper
        open={isPromptOpen}
        setOpen={setIsPromptOpen}
        onClose={() => setIsPromptDisable(true)}
        maxWidth="sm"
        heading="Updated Prompt"
      >
        {assistantResponse?.instructions?.length ? (
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CreateOutlined color="primary" style={{ cursor: 'pointer' }} onClick={() => setIsPromptDisable(false)} />
          </span>
        ) : (
          ''
        )}

        <Box mb={2} mt={1}>
          <TextField
            label="Prompt"
            value={instructionsText}
            disabled={isPromptDisable}
            multiline
            variant="outlined"
            fullWidth
            placeholder="Prompt"
            onChange={e => setInstructionsText(e.target.value)}
            // InputProps={{
            //   endAdornment: (

            //   ),
            // }}
          />
        </Box>
        {/* <Box mb={2}>
          <TextField
            label="Update Prompt"
            variant="outlined"
            fullWidth
            focused
            rows={4}
            multiline
            placeholder="Update Prompt"
            value={instructionsText}
            onChange={e => setInstructionsText(e.target.value)}
          />
        </Box> */}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            disabled={!instructionsText?.length ? true : false}
            color="primary"
            variant="contained"
            onClick={handleUpdateAssistant}
            style={{ color: '#fff', width: '10rem' }}
          >
            {isUpdatingAssistantloading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : 'Save'}
          </Button>
        </Box>
      </ModalWrapper>

      <DeleteConfirmationDialog
        itemName={<React.Fragment>{deleteDocument?.file_name}</React.Fragment>}
        onClose={() => {
          setDeleteDialoge(false)
        }}
        open={deleteDialoge}
        onDelete={handleDeleteDocumentClick}
      />

      {/* window.location.assign(downloadDoc || '') */}
    </div>
  )
}

export default RockyAIChat
