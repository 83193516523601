import { apiClient, requestPaginated } from '@jetkit/react'

export interface IThread {
  result: string
  completed: boolean
  error?: string
}

export interface IDocuments {
  assistant_id: string
  file_id: string
  file_name: string
  id: number
  uploaded_by: string
  uploaded_on: string
}

export interface IAssistantResponse {
  id: number
  assistant_id: string
  instructions: string
  created_at: string
  active: boolean
}

export interface IThreadResponse {
  success?: string
  thread_id: string
}

const apiBaseURL = '/ai-assistant'

export const aiAssistantThreads = async (): Promise<IThreadResponse> => {
  return (await apiClient.post(`${apiBaseURL}/thread`, {})).data
}

export const fileUploadSignURL = async (fileName: string): Promise<any> => {
  return (await apiClient.post(`${apiBaseURL}/file-upload-signed-url`, { file_name: fileName })).data
}

export const confirmFileUpload = async (fileID: number): Promise<any> => {
  return (await apiClient.post(`${apiBaseURL}/file-upload`, { file_id: fileID })).data
}

export const getThread = async (): Promise<IThread> => (await apiClient.get(`${apiBaseURL}/thread`)).data

export const getUploadDocuments = () =>
  requestPaginated<IDocuments[]>({
    url: `${apiBaseURL}/docs`,
  })

export const deleteUploadDocument = async (id: number): Promise<void> => {
  await apiClient.delete(`/ai-document/${id}`)
}

export const getDownloadURL = async (docid: number): Promise<any> =>
  (await apiClient.get(`/ai-document/${docid}/download`)).data

export const getAssistant = async (): Promise<IAssistantResponse> => (await apiClient.get(`/ai-assistant`)).data

export const updateAssistant = async (id: number, instructions: string): Promise<any> => {
  return (await apiClient.post(`/ai-assistant/${id}`, { instructions: instructions })).data
}

export const aiAssistantMessage = async (payload): Promise<any> => {
  return (await apiClient.post(`${apiBaseURL}/message`, payload)).data
}
