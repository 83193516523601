import { CircularProgress, createStyles, FormControl, Link, makeStyles, Theme, Typography } from '@material-ui/core'

import * as React from 'react'
import useRouter from 'use-react-router'
import { removeNullAndUndefined } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import BRTextField from 'components/common/textFields/BRTextField'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { createPassword, resetPassword, sendVerificationCode, submitVerificationCode } from 'api/users'
import showSuccessNotification from 'utils/showSuccessNotification'
import Logo from 'icons/logo'
import useRouter1 from 'hooks/router'
import { LinearProgress } from '@material-ui/core'
import PhoneInput from 'components/clients/create/phoneInput'
import OutlinedSelect from 'components/common/outlinedSelect'
import { COOKIEE_NAMES, VERIFICATION_METHODS } from 'utils/constant'
import useUser from 'hooks/useUser'
import { IS_DEVELOPMENT_MODE } from 'utils/environment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      ...theme.title,
    },
    titleButtonContainer: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    notesContainer: {
      width: '35%',
    },
    flex: {
      display: 'flex',
    },
    formContainer: {
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorHelperText: {
      color: theme.customPalette.errorText,
      margin: '8px 14px 0',
    },
    rootProgress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      color: '#f47950',
    },
  })
)
interface ITwoFAprops {
  user?: any
  checkingUserType?: (type: string) => void
  setCookie?: (name: string, val: string, options?: any) => void
}
const TwoFA: React.FC<ITwoFAprops> = ({ user, checkingUserType, setCookie }) => {
  const classes = useStyles()
  const router = useRouter<{ id: string | undefined }>()
  const { history } = useRouter1()
  const [loading, setLoading] = React.useState(false)
  const [verificationType, setVerificationType] = React.useState('')
  const [currentUser, setCurrentUser] = React.useState(user)
  const [showVerification, setShowVerification] = React.useState(false)
  const [verificationCode, setVerificationCode] = React.useState<number | null>(null)
  const [errorVerificationCode, seterrorVerificationCode] = React.useState(false)

  const { updateUser } = useUser()
  console.log(router.match.params.id)
  const id = router.match.params.id
  const submit = async () => {
    try {
      setLoading(true)
      const req = {
        type: verificationType,
        user_type: user.user_type,
        user_id: user.user_id,
      }
      const res = await sendVerificationCode(req)
      setLoading(false)

      if (res.success) {
        showSuccessNotification(`Verification Code Sent Successfully`)
        setShowVerification(true)
        //history.push('/create-password/' + res.id)
      } else if (res.error) {
        showApiResponseError(res.error, `Error , Something went wrong`)
      }
    } catch (error) {
      setLoading(false)

      if (error.statusCode === 404) showApiResponseError(error, `Failed to send, User doesn't Exists.`)
      else {
        showApiResponseError(error.message, `Couldn't get, User doesn't Exists.`)
      }
    }
  }
  const submitCode = async () => {
    try {
      setLoading(true)
      const req = {
        user_id: user.user_id,
        verificationCode: verificationCode,
      }
      const res = await submitVerificationCode(req)
      setLoading(false)

      if (res.error) {
        showApiResponseError(res.error, res.error)
      } else {
        updateUser(res.user || null)
        if (IS_DEVELOPMENT_MODE)
          localStorage.setItem(
            'auth-tokens-development',
            JSON.stringify({ accessToken: res.access_token, refreshToken: res.refresh_token })
          )
        else {
          localStorage.setItem(
            'auth-tokens-production',
            JSON.stringify({ accessToken: res.access_token, refreshToken: res.refresh_token })
          )
        }
        if (res.user?.device && setCookie) {
          setCookie(COOKIEE_NAMES.device, res.user.device, { maxAge: 31536000 })
        }
        checkingUserType && checkingUserType(res?.user?.user_type)
      }
    } catch (error) {
      setLoading(false)

      if (error.statusCode === 404) showApiResponseError(error, `Something Went Wrong`)
      else {
        showApiResponseError(error.message, `Something Went Wrong.`)
      }
    }
  }
  React.useEffect(() => {
    if (!verificationCode || verificationCode < 100000) {
      seterrorVerificationCode(true)
    } else {
      seterrorVerificationCode(false)
    }
  }, [verificationCode])
  return (
    <div className={classes.flex}>
      <div className={classes.container}>
        <div className={classes.titleButtonContainer}>
          <Logo />

          <Typography className={classes.title}>Verification</Typography>
        </div>
        <div className={classes.formContainer}>
          <OutlinedSelect
            name="Select Verification Method"
            value={verificationType}
            options={[VERIFICATION_METHODS.SMS, VERIFICATION_METHODS.EMAIL]}
            onOptionSelect={value => setVerificationType(value as string)}
          />
          {verificationType === VERIFICATION_METHODS.SMS && (
            // <FormControl
            //   style={{ width: '100%' }} //className={classes.fullWidth}
            // >
            //   <PhoneInput
            //     //showCopyButton={!!editedClient}
            //     value={currentUser.phone}
            //     disabled={true}
            //     onChange={() => {
            //       console.log('')
            //     }}
            //   />
            // </FormControl>
            <>
              <BRTextField
                disabled
                data-testid="client-email"
                label="Phone"
                type="text"
                name="email"
                margin="normal"
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorHelperText,
                  },
                }}
                variant="outlined"
                value={currentUser.phone}
              />
            </>
          )}
          {verificationType === VERIFICATION_METHODS.EMAIL && (
            <>
              <BRTextField
                disabled
                data-testid="client-email"
                label="Email"
                type="text"
                name="email"
                margin="normal"
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorHelperText,
                  },
                }}
                variant="outlined"
                value={currentUser.email}
              />
            </>
          )}
          {showVerification && (
            <>
              <BRTextField
                label="Verification Code"
                type="number"
                name="code"
                error={errorVerificationCode}
                // helperText={errorUsername && errorUsernameText}

                margin="normal"
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorHelperText,
                  },
                }}
                variant="outlined"
                placeholder="Enter 6 Digit Code"
                inputProps={{ maxLength: 6 }}
                value={verificationCode}
                onChange={e =>
                  setVerificationCode(
                    parseFloat(
                      Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6)
                    )
                  )
                }
              />
            </>
          )}
          {(verificationType === VERIFICATION_METHODS.EMAIL || verificationType === VERIFICATION_METHODS.SMS) &&
            !showVerification && (
              <ButtonWithLoading isLoading={loading} style={{ width: '100%' }} onClick={submit}>
                {!showVerification ? 'Send Verification Code' : 'Resend Code'}
              </ButtonWithLoading>
            )}
          {showVerification && (
            <>
              {!errorVerificationCode && (
                <ButtonWithLoading
                  isLoading={loading}
                  style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
                  onClick={submitCode}
                >
                  {'Submit'}
                </ButtonWithLoading>
              )}
              <Link underline="always" onClick={submit}>
                Resend verification Code
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TwoFA
