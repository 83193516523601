import { StyledTableCell, StyledTableHead, StyledTableRow } from 'components/common/table'
import * as React from 'react'

const StatesTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableCell>State</StyledTableCell>
        <StyledTableCell>Company Type</StyledTableCell>
        <StyledTableCell>Processing Time</StyledTableCell>
        <StyledTableCell>Essential Processing Time</StyledTableCell>
        <StyledTableCell>Rush Processing Time</StyledTableCell>
        <StyledTableCell>State Fees</StyledTableCell>
        <StyledTableCell>Annual Report Fee</StyledTableCell>
        <StyledTableCell>Register Agent</StyledTableCell>

        <StyledTableCell>Estimated Processing Time</StyledTableCell>

        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  )
}

export default StatesTableHead
