import React, { useEffect, useState } from 'react'
import { useStyles } from './style'
import { Skeleton } from '@material-ui/lab'
import { Avatar, Button, Grid } from '@material-ui/core'
import { IDetailedCompanyResponse } from 'models/company'
import { useParams } from 'react-router'
import { getClientCompanyById } from 'api/clientDashboard'
import useRouter from 'use-react-router'
import useUser from 'hooks/useUser'
import CreditScoreMeter from './creditScoreMeter'

const CreditScoreDetails: React.FC = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const router = useRouter()
  const { user } = useUser()

  const [companyDetail, setCompanyDetail] = useState<IDetailedCompanyResponse>()
  const [isLoading, setIsLoading] = useState(false)

  const getCompanyDetail = async id => {
    debugger
    setIsLoading(true)
    try {
      const details = await getClientCompanyById(id)
      setCompanyDetail(details)
      if (details?.error) {
        router.history.push('/something-went-wrong')
      }
    } catch (error) {
      setIsLoading(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (id) {
      getCompanyDetail(id)
    }
  }, [id])

  return (
    <div className={classes.creditClientDashboard}>
      <span className="inline-icon-heading">
        {isLoading ? (
          <>
            <Skeleton variant="circle" width={40} height={40} className="avatar" />
            <Skeleton variant="text" width={250} height={60} />
          </>
        ) : (
          <>
            <Avatar className="avatar">{companyDetail?.name?.charAt(0).toUpperCase()}</Avatar>
            <h1 className="main-heading">{companyDetail?.name?.toUpperCase()}</h1>
          </>
        )}
      </span>
      <div className="user-back-card">
        <p className="title">Welcome back, {user?.name}</p>
        <h3 className="description">
          Business Rocket helps business owners establish and build their business credit score based on Experian Data.
        </h3>
      </div>
      <div className="credit-score-card">
        <p className="title">Credit Scores</p>
        <h3 className="description">Your business credit score is ready!</h3>
        <p className="sub-title">
          Based on this score, lenders may associate the business with moderate risk in paying its financial obligations
          on time.
        </p>
      </div>

      <div className="score-meter-card">
        <CreditScoreMeter
          title={'Credit Data provided by Experian®'}
          creditScore={companyDetail?.experian_credit_score ? companyDetail?.experian_credit_score : 35}
        />
        <CreditScoreMeter
          title={'Business Rocket'}
          creditScore={companyDetail?.experian_credit_score ? companyDetail?.experian_credit_score : 65}
        />
      </div>

      <p className="highlight-text">Credit Data provided by Experian®</p>

      <div className="container">
        <div className={true ? 'box-overlay' : 'df-n'}>
          <div className="moniter-card">
            <p className="title">
              Activate full report &<br />
              monitoring
            </p>
            <Button variant="contained" className="subscribe-btn">
              SUBSCRIBE
            </Button>
          </div>
        </div>
        <Grid container spacing={3}>
          {[...Array(8)]?.map((_, key: number) => (
            <Grid item xs={12} md={4} key={key}>
              <div className="credit-data-card">
                <h2 className="credit-heading">Payment Status</h2>
                <h3 className="credit-title">{key + 1}0%</h3>
                <p className="credit-description">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default CreditScoreDetails
