import React from 'react'
import { useStyles } from 'components/dashboard/styles'
import { Avatar, IconButton } from '@material-ui/core'
import { Check, BusinessCenterOutlined, PersonAddOutlined } from '@material-ui/icons'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'

interface MsgBox {
  name: string
  // hour: number
  hour: string
  icon: string
  msgText: string
  completeHandle?: () => void
  associateHandle?: () => void
  type: 'client' | 'prospect'
}

const MsgBox = ({ name, hour, icon, msgText, completeHandle, associateHandle, type }: MsgBox) => {
  const classes = useStyles()

  return (
    <div className={classes.msgBox}>
      <div className="msg-box-header">
        <span className="inline-text">
          {/* <Avatar alt="icon" src={icon} /> &nbsp; */}
          <Avatar alt="icon">{icon}</Avatar> &nbsp;
          <p className="name-text">{name}</p> &nbsp;
          {/* <p className="hour-text">{hour}h</p> */}
          <p className="hour-text">{formatDateTimeToHumanFriendly(hour || '')}</p>
        </span>
        <span>
          <IconButton onClick={completeHandle}>
            <Check className="btn-icon" />
          </IconButton>

          <IconButton onClick={associateHandle}>
            {type === 'client' ? (
              <BusinessCenterOutlined className="btn-icon" />
            ) : (
              <PersonAddOutlined className="btn-icon" />
            )}
          </IconButton>

          {/* <IconButton>
            <PersonAddOutlined className="btn-icon" />
          </IconButton> */}
        </span>
      </div>
      <p className="msg-text">{msgText}</p>
    </div>
  )
}

export default MsgBox
