import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    rockyAIChatContainer: {
      padding: '85px 15px 50px 50px',
      // flexGrow: 1,

      '& h1, p': {
        margin: 0,
        padding: 0,
      },

      '& .inline-items': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      '& .primary-heading': {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: '32px',
        color: '#202124',
        paddingBottom: '2rem',
      },

      '& .btn-group': {
        '& .MuiButton-containedPrimary': {
          marginLeft: '2rem',
          color: '#fff',
        },
      },
    },

    chatContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 14rem)',
      justifyContent: 'flex-start',
      overflow: 'auto',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 14rem)',
      // background: '#F6F6F8',

      '& .chatbox-container': {
        display: 'flex',
        margin: '1rem 0',

        '& .email-chatbox-section': {
          padding: '0.85rem 1.25rem',
          background: '#EAF4FB',
          borderRadius: '10px 0px 10px 10px',
          fontFamily: 'Lato',
          width: '100%',
        },

        '& .chatbox-section': {
          padding: '0.85rem 1.25rem',
          background: '#F0F0F0',
          borderRadius: '0px 10px 10px 10px',
          fontFamily: 'Lato',
          width: '100%',
        },

        '& .title': {
          fontSize: '0.9rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
        },
        '& .text': {
          fontSize: '0.9rem',
          fontWeight: 500,
          lineHeight: '1.5rem',
          whiteSpace: 'pre-wrap',
        },
        '& .inline-icon': {
          display: 'flex',
          alignItems: 'center',
          marginTop: '0.5rem',

          '& p': {
            margin: '0 0.5rem',
            color: '#818181',
            fontSize: '0.85rem',
          },

          '& .icon-wrapper': {
            position: 'relative',

            '& .done-progress': {
              position: 'absolute',
              top: '-4px',
              left: '-4px',
              color: '#0000005c',
            },
          },
        },
      },
    },

    typingContainer: {
      overflow: 'hidden',
    },
    typingText: {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      animation: '$typing 3s steps(40, end) forwards',
    },
    cursor: {
      display: 'inline-block',
      opacity: 1,
      animation: '$blink 0.3s infinite alternate',
    },
    '@keyframes typing': {
      from: {
        width: 0,
      },
      to: {
        width: '100%',
      },
    },
    '@keyframes blink': {
      to: {
        opacity: 0,
      },
    },

    promptField: {
      width: 'calc(100% - 3.8rem)',
      padding: '1rem 2rem',
      background: '#EAEAEA',
      borderRadius: '50px',

      '& .MuiInput-underline': {
        '&:before': {
          borderBottom: '0px !important',
        },
        '&:after': {
          borderBottom: '0px !important',
        },
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
      '& button': {
        color: '#fff',
        // borderRadius: '50px',
        width: '48px',
        height: '48px',
        margin: '0 0.25rem',
      },
    },

    commonRow: {
      margin: '1.35rem 0',
      padding: '1rem 1.25rem',
      display: 'flex',
      backgroundColor: '#f7f8f9',

      '& .offer-heading': {
        fontSize: '0.95rem',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 'normal',
        paddingBottom: '0.5rem',
      },
    },
    loader: {
      margin: '1rem',
      width: '88px',
      height: '12px',
      '--_g': 'no-repeat radial-gradient(farthest-side, #407ace 94%, #0000)',
      background: 'var(--_g) 25% 0, var(--_g) 75% 0',
      backgroundSize: '12px 12px',
      position: 'relative',
      animation: '$loaderAnimation 1s linear infinite',
    },
    loaderElement: {
      content: '""',
      position: 'absolute',
      height: '12px',
      aspectRatio: '1',
      borderRadius: '50%',
      background: '#407ace',
      inset: 0,
      margin: 'auto',
      animation: '$loaderAnimation2 1s cubic-bezier(0.5, 300, 0.5, -300) infinite',
    },
    '@keyframes loaderAnimation': {
      '0%, 24%': { backgroundPosition: '25% 0, 75% 0' },
      '40%': { backgroundPosition: '25% 0, 85% 0' },
      '50%, 72%': { backgroundPosition: '25% 0, 75% 0' },
      '90%': { backgroundPosition: '15% 0, 75% 0' },
      '100%': { backgroundPosition: '25% 0, 75% 0' },
    },
    '@keyframes loaderAnimation2': {
      '100%': { transform: 'translate(0.1px)' },
    },

    loading: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      display: 'block',
      margin: '15px auto',
      position: 'relative',
      color: '#407ace',
      left: '-100px',
      boxSizing: 'border-box',
      animation: '$shadowRolling 2s linear infinite',
    },
    '@keyframes shadowRolling': {
      '0%': {
        boxShadow:
          '0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0)',
      },
      '12%': {
        boxShadow:
          '100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0)',
      },
      '25%': {
        boxShadow: '110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0)',
      },
      '36%': {
        boxShadow: '120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0)',
      },
      '50%': {
        boxShadow: '130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white',
      },
      '62%': {
        boxShadow: '200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white',
      },
      '75%': {
        boxShadow: '200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white',
      },
      '87%': {
        boxShadow:
          '200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white',
      },
      '100%': {
        boxShadow:
          '200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0)',
      },
    },

    buttonSuccess: {
      border: 'none',
      background: 'transparent',
      margin: '0',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    fabProgress: {
      // color: 'green',
      position: 'absolute',
      top: 1,
      left: -6,
      zIndex: 1,
    },

    wrapper: {
      margin: '0.5rem',
      position: 'relative',

      '& .MuiFab-root': {
        color: '#307abc',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        width: 'auto',
        height: 'auto',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  })
)
