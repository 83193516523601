import { Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { BusinessCenter, Edit, MoreVert, OpenInNew } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { createStyles, makeStyles } from '@material-ui/styles'
import TableActionButton from 'components/common/buttons/tableActionButton'
import DeleteIcon from 'components/common/icons/delete'
import { StyledTableCell, StyledTableRow } from 'components/common/table'
import { IClient } from 'models/user'
import * as React from 'react'
import { ICompanySubscriptionResponse } from 'api/subscription'
import moment from 'moment'
import { STRIPE_SUBSCRIPTION_STATUSES, SUBSCRIPTION_STATUS_MAPPING } from 'utils/constant'
import { Link } from 'react-router-dom'
import EditIcon from 'components/common/icons/edit'
import { useHistory } from 'react-router'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 10,
      width: 40,
      height: 40,
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },

    primaryAddress: {
      marginTop: '0.25rem',
      '& p': {
        color: '#0000009c',
        fontSize: '12px',
        margin: '0',
        fontStyle: 'italic',
      },
    },
    actionButton: {
      width: 40,
      height: 40,
      '& span': {
        fontSize: '2rem',
      },
    },
  })
)

interface ISubscriptionTableRowProps {
  subscription: ICompanySubscriptionResponse
  onEditClick: () => void
  onDeleteClick: () => void
}

const SubscriptionTableRow: React.FC<ISubscriptionTableRowProps> = ({ subscription, onEditClick, onDeleteClick }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const moreOptionsRef = React.useRef<HTMLButtonElement>(null)
  const history = useHistory()
  return (
    <>
      {/* <StyledTableRow onClick={onEditClick} data-testid={`${subscription.id}`} className={classes.row}> */}
      <StyledTableRow data-testid={`${subscription.id}`} className={classes.row}>
        <StyledTableCell>{subscription.company_name || '- - -'}</StyledTableCell>
        <StyledTableCell>{subscription.product.name || '- - -'}</StyledTableCell>
        <StyledTableCell>{subscription.state_of_formation || '- - -'}</StyledTableCell>
        <StyledTableCell>
          {subscription.creation_time ? moment(subscription.creation_time).format('dddd, Do MMM YYYY') : '- - -'}
        </StyledTableCell>
        <StyledTableCell>
          {subscription.current_period_start
            ? moment(subscription.current_period_start).format('dddd, Do MMM YYYY')
            : '- - -'}
        </StyledTableCell>
        <StyledTableCell>
          {subscription.current_period_end
            ? moment(subscription.current_period_end).format('dddd, Do MMM YYYY')
            : '- - -'}
        </StyledTableCell>
        <StyledTableCell>${subscription.price.amount || '- - -'}</StyledTableCell>
        <StyledTableCell>
          {subscription.paused
            ? SUBSCRIPTION_STATUS_MAPPING[STRIPE_SUBSCRIPTION_STATUSES.paused]
            : SUBSCRIPTION_STATUS_MAPPING[subscription?.status || STRIPE_SUBSCRIPTION_STATUSES.incomplete]}
        </StyledTableCell>
        <StyledTableCell>{subscription.collected_revenue}</StyledTableCell>
        <StyledTableCell>
          <TableActionButton className={classes.actionButton} style={{ marginRight: 10 }}>
            <Link to={`/company/${subscription.company_id}`} target="_blank" rel="noreferrer">
              <OpenInNew data-testid="newTab" color="primary" />
            </Link>
          </TableActionButton>
          <TableActionButton
            onClick={() => history.push(`/company/${subscription.company_id}`)}
            className={classes.actionButton}
            style={{ marginRight: 10 }}
          >
            <EditIcon data-testid="editButton" />
          </TableActionButton>
        </StyledTableCell>
        {/* 
        <StyledTableCell>
          <TableActionButton
            className={classes.button}
            onClick={event => {
              event.stopPropagation()
              // onDeleteClick()
            }}
          >
            <Edit />
          </TableActionButton>
        </StyledTableCell> */}
      </StyledTableRow>
    </>
  )
}

export default SubscriptionTableRow
