import TableBody from '@material-ui/core/TableBody'
import { EmptyTableRow, skeletons, StyledTable } from 'components/common/table'
import TableLoadingIndicator from 'components/common/tableLoadingIndicator'
import { ICommission } from 'models/commission'
import * as React from 'react'
import CommissionTableHead from './head'
import CommissionTableRow from './row'
import CommissionDialog from 'components/clients/commissions/dialog'
import { IClient } from 'models/user'
import { removeNullAndUndefined, ValueOf } from 'utils/objectUtils'

interface ICommissionTableProps {
  editedClient?: IClient
  commissions?: ICommission[]
  loading: boolean
  dialogOpened: boolean
  toggleDialogOpened: () => void
  fetchCommissions: () => void
  changeClientField: (field: keyof IClient, value: ValueOf<IClient>) => void
}

const CommissionTable: React.FC<ICommissionTableProps> = ({
  editedClient,
  commissions,
  loading,
  dialogOpened,
  toggleDialogOpened,
  fetchCommissions,
  changeClientField,
}) => {
  const fetchedResult = (commissions: ICommission[]) => {
    return commissions.length ? (
      commissions.map(commission => <CommissionTableRow key={commission.transaction_date} commission={commission} />)
    ) : (
      <EmptyTableRow columns={6} title="no transactions" />
    )
  }

  return (
    <React.Fragment>
      <TableLoadingIndicator isLoading={loading} />
      <StyledTable>
        <CommissionTableHead onAddTransaction={toggleDialogOpened} />
        <TableBody>
          {commissions
            ? fetchedResult(commissions)
            : skeletons({ numberOfColumns: 5, numberOfRows: 10, sizes: [300, 300, 300, 300, 300] })}
        </TableBody>
      </StyledTable>
      <CommissionDialog
        fetchCommissions={fetchCommissions}
        changeClientField={changeClientField}
        dialogOpened={dialogOpened}
        onClose={toggleDialogOpened}
        editedClient={editedClient}
      />
    </React.Fragment>
  )
}

export default CommissionTable
