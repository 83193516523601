import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  CircularProgress,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core'
import SelectField from 'components/common/select'
import { fetchSuggestions, fetchAssigneeSuggestions } from 'api/suggestions'
import TextEditor from 'components/common/TextEditor'
import { DeleteForever } from '@material-ui/icons'
import { ISuggestionAttribute } from 'components/interfaces'
import { Autocomplete } from '@material-ui/lab'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showApiResponseError from 'utils/showApiResponseError'
import { notify } from '@jetkit/react'
import { checkTestTemplate } from 'api/autoReminders'
import showSuccessNotification from 'utils/showSuccessNotification'
import ReactPhoneInput from 'react-phone-input-2'
import useUser from 'hooks/useUser'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '2rem',
      '& .accordion-container': {},

      '& .filed-header': {
        display: 'flex',
        width: '100%',
      },
    },
    fotterBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '1rem',
      marginRight: '1rem',

      '& .delete-icon': {
        color: '#fff',
        fontSize: '2rem',
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      color: '#76A8D4',
    },
    formControl: {
      marginRight: '1rem',
      minWidth: '250px',
    },
    formControl1: {
      margin: '0',
      minWidth: '250px',
    },
    smsBody: {
      width: '100%',
      marginTop: '1rem',
    },
    emailSubject: {
      width: '100%',
    },
    accordionDetails: {
      display: 'block',

      '& .filed-header': {
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
      },
    },
    accordionSummary: {
      background: '#f6f6f8',
    },
    testEmail: {
      display: 'flex',
      // width: '50%',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      // flexDirection: 'column',

      '& .text-label': {
        padding: '1rem 0 0.5rem 0',
      },

      '& .form-wrapper': {
        width: '100%',

        '& .test-field': {
          width: '50%',
        },
        '& .TextField': {
          width: '50% !important',
          minHeight: '56px',
        },
      },

      '& .text-template-btn': {
        marginBottom: '0',
        minHeight: '56px',
        alignItems: 'center',
        width: '13.75rem',
        marginTop: '1rem',

        '& .MuiButton-root': {
          // background: '#dadaf8',
          // color: '#3c9fdf',
          minHeight: '56px',
        },
      },
    },
  })
)

interface AccordionWrapperProps {
  reminderData: any
  keyId: string
  handleUpdate: any
  handleDelete: any
  currentUser: number
}

const AccordionWrapper = ({ reminderData, keyId, handleUpdate, handleDelete, currentUser }: AccordionWrapperProps) => {
  const classes = useStyles()
  const { user } = useUser()
  const [selectedValve, setSelectedValve] = useState<ISuggestionAttribute>()
  const [senderOptions, setSenderOptions] = useState<ISuggestionAttribute[]>([])

  const [testEmail, setTestEmail] = useState('')
  const [testPhone, setTestPhone] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // const onAssigneeChange = (newAssigneeId: number | null) => {
  //   handleUpdate(keyId, 'senderName', newAssigneeId)
  // }

  const findSelectedAssigne = async () => {
    const data = await fetchAssigneeSuggestions('')
    const selected = data?.find(item => item?.value === reminderData?.senderName)
    setSenderOptions(data)
    setSelectedValve(selected)
  }

  useEffect(() => {
    findSelectedAssigne()
  }, [])

  const tryToCheckEmailTemplate = React.useCallback(async () => {
    const { emailSubject, contentText, type } = reminderData
    if (!emailSubject || !contentText || !type || !testEmail) return

    const checkEmailData = {
      type: type,
      content: contentText,
      subject: emailSubject,
      email: testEmail,
      phone_number: '',
    }
    setIsLoading(true)
    try {
      await checkTestTemplate(checkEmailData)
      setIsLoading(false)
      showSuccessNotification('Test Email sent!')
    } catch (error) {
      setIsLoading(false)
      showApiResponseError(error, `Sorry, Error sending email`)
    }
  }, [reminderData, testEmail])

  const tryToCheckPhoneTemplate = React.useCallback(async () => {
    const { smsBody, type } = reminderData
    if (!smsBody || !type || !testPhone) return

    const checkPhoneData = {
      type: type,
      content: smsBody,
      subject: '',
      email: '',
      phone_number: testPhone,
    }
    setIsLoading(true)
    try {
      await checkTestTemplate(checkPhoneData)
      setIsLoading(false)
      showSuccessNotification('Test SMS sent!')
    } catch (error) {
      setIsLoading(false)
      showApiResponseError(error, `Sorry, Error sending SMS`)
    }
  }, [reminderData, testPhone])

  // const userDetails = JSON.parse(localStorage.getItem('currentUser') || '')
  // const userId = userDetails?.extid

  console.log('reminderDatareminderData', reminderData, currentUser, selectedValve)

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded className="accordion-container">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography className={classes.heading}>{reminderData?.type.toUpperCase()} Config</Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          <div className="filed-header">
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="scheduleTime"
                label="Schedule Time"
                variant="outlined"
                value={reminderData.scheduleTime}
                onChange={e => handleUpdate(keyId, 'scheduleTime', e.target.value)}
                // helperText={errors?.description ? 'Enter Description' : ''}
              />
              {/* <FormHelperText className={classes.errorMsg}>{errors?.description ? '*Enter Description' : ''}</FormHelperText> */}
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Schedule Type</InputLabel>
              <Select
                value={reminderData.scheduleType}
                onChange={e => handleUpdate(keyId, 'scheduleType', e.target.value)}
                label="Schedule Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="minutes">Minutes</MenuItem>
                <MenuItem value="hours">Hours</MenuItem>
                <MenuItem value="days">Days</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl1}>
              {/* <SelectField
                title="Sender"
                field="assigned_to"
                onOptionSelected={option => onAssigneeChange(option?.value || reminderData?.sender)}
                fetchSuggestions={fetchSuggestions}
                placeholder="Sender"
                key={reminderData?.senderName}
                value={selectedValve}
              /> */}

              <InputLabel>Sender Name</InputLabel>
              <Select
                value={reminderData.senderName || selectedValve}
                onChange={e => handleUpdate(keyId, 'senderName', e.target.value)}
                label="Sender Name"
                // defaultValue={currentUser}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {senderOptions?.map(option => (
                  <MenuItem value={option?.value}>{option?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {reminderData?.type === 'email' ? (
            <>
              <FormControl variant="outlined" className={classes.emailSubject}>
                <TextField
                  id="scheduleTime"
                  label="Email Subject"
                  variant="outlined"
                  value={reminderData.emailSubject}
                  onChange={e => handleUpdate(keyId, 'emailSubject', e.target.value)}

                  // helperText={errors?.description ? 'Enter Description' : ''}
                />
                {/* <FormHelperText className={classes.errorMsg}>{errors?.description ? '*Enter Description' : ''}</FormHelperText> */}
              </FormControl>
              <TextEditor
                contentText={reminderData.contentText}
                setContentText={e => handleUpdate(keyId, 'contentText', e)}
              />

              <div className={classes.testEmail}>
                <FormControl variant="outlined" className="form-wrapper">
                  <label className="text-label">Test Email</label>
                  <OutlinedInput
                    id="outlined-adornment-Email"
                    aria-describedby="outlined-Email-helper-text"
                    inputProps={{
                      'aria-label': 'Email',
                    }}
                    labelWidth={0}
                    type="email"
                    name="testEmail"
                    className="test-field"
                    value={testEmail}
                    onChange={e => setTestEmail(e.target.value)}
                  />
                </FormControl>
                <ButtonWithLoading
                  kind="BRPrimary"
                  wrapperClassName="text-template-btn"
                  isLoading={isLoading}
                  onClick={tryToCheckEmailTemplate}
                >
                  Test Email Template
                </ButtonWithLoading>
              </div>
            </>
          ) : (
            ''
          )}

          {reminderData?.type === 'sms' ? (
            <>
              <FormControl variant="outlined" className={classes.smsBody}>
                <TextField
                  id="scheduleTime"
                  label="Sms Body"
                  variant="outlined"
                  rows={6}
                  multiline
                  value={reminderData.smsBody}
                  onChange={e => handleUpdate(keyId, 'smsBody', e.target.value)}
                  // helperText={errors?.description ? 'Enter Description' : ''}
                />
                {/* <FormHelperText className={classes.errorMsg}>{errors?.description ? '*Enter Description' : ''}</FormHelperText> */}
              </FormControl>
              <div className={classes.testEmail}>
                <FormControl variant="outlined" className="form-wrapper">
                  <label className="text-label">Test SMS</label>
                  {/* <OutlinedInput
                    id="outlined-adornment-SMS"
                    aria-describedby="outlined-SMS-helper-text"
                    inputProps={{
                      'aria-label': 'SMS',
                    }}
                    labelWidth={0}
                    name="testSMS"
                    className="test-field"
                    value={testPhone}
                    onChange={e => setTestPhone(e.target.value)}
                  /> */}
                  <ReactPhoneInput
                    data-testid="phone-input"
                    country="us"
                    inputClass="TextField"
                    inputStyle={{
                      width: '100%',
                      height: 50,
                      backgroundColor: '#fafafb',
                    }}
                    inputProps={{ required: true }}
                    containerStyle={{ margin: '8px 0 2px' }}
                    dropdownStyle={{ width: 420, marginTop: 8 }}
                    value={testPhone}
                    onChange={value => setTestPhone(value)}
                    countryCodeEditable={false}
                  />
                </FormControl>
                <ButtonWithLoading
                  kind="BRPrimary"
                  wrapperClassName="text-template-btn"
                  isLoading={isLoading}
                  onClick={tryToCheckPhoneTemplate}
                >
                  Test SMS Template
                </ButtonWithLoading>
              </div>
            </>
          ) : (
            ''
          )}
        </AccordionDetails>
        <div className={classes.fotterBtn}>
          <Fab color="secondary" size="medium">
            <DeleteForever className="delete-icon" onClick={() => handleDelete(keyId)} />
          </Fab>
        </div>
      </Accordion>
    </div>
  )
}

export default AccordionWrapper
