import { IPagedTableHook, usePagedTable } from '@jetkit/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { fetchNotes } from 'api/note'
import { emptyOrderDetails, getOrderDetails, IOrderDetailsResponse } from 'api/orders'
import classnames from 'classnames'
import { INoteEntitiesContext, NoteEntitiesContext } from 'components/common/notes/addNote'
import useNotes from 'components/common/notes/hooks'
import { NoteEntities, useEntityFilter } from 'components/common/notes/NoteEntitySelector'
import Notes from 'components/common/notes/notes'
import OpenNotesButton from 'components/common/notes/openNotesButton'
import OrderEdit from 'components/orders/orderDetails'
import useRouter from 'hooks/router'
import { INote } from 'models/note'
import { OrderDetailsContext } from 'pages/orders/orderDetails/OrderDetailsContext'
import * as React from 'react'
import { useCallback } from 'react'
import { emptyFunction, removeNullAndUndefined } from 'utils/objectUtils'
import { NoteType } from 'models/note'
import NoteTypeCheckBox from 'components/common/notes/noteTypeCheckbox'
import { updatePageVisitsCount } from 'utils/user'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '40px',
  },
  notesContainer: {
    minWidth: 470,
    position: 'sticky',
    height: '100vh',
    top: 0,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-5px 3px 4px 0 rgba(0, 0, 0, 0.07)',
    marginLeft: '1rem',
    // this zIndex should be higher than zIndex of `stickyFooter` in components/orders/orderDetails/index.tsx
    // so that the current panel is over the footer of OrderEdit component
    zIndex: 1000,
  },
  progressContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orderRoot: {
    marginLeft: '1rem',
    paddingTop: '2rem',
    flexGrow: 1,
  },
  orderRootMain: {
    paddingRight: '2rem',
  },
  notesTable: {
    flexGrow: 1,
  },
})

interface IIDParam {
  id: string | undefined
}

interface INotesTableContextProps {
  pagedTable: IPagedTableHook<INote> | null
  setSearchQuery: (value: string) => void
  searchOptions: NoteEntities
  setSearchOptions: React.Dispatch<React.SetStateAction<NoteEntities>>
}

export const NotesTableContext = React.createContext<INotesTableContextProps>({
  pagedTable: null,
  searchOptions: {},
  setSearchQuery: emptyFunction,
  setSearchOptions: emptyFunction,
})
NotesTableContext.displayName = 'NotesTableContext'
//

//
// this hook is only for usage on company order details page.
const useNotesGlobal = (
  orderId: number
  // company_id?: number
): {
  setSearchQuery: (newQuery: string) => void
  notesPagedTable: IPagedTableHook<INote>
  reloadNoteTable: () => void
  searchOptions: NoteEntities
  setSearchOptions: React.Dispatch<React.SetStateAction<NoteEntities>>
} => {
  const [searchOptions, setSearchOptions] = React.useState<NoteEntities>({
    types: ['order'],
    order_id: orderId,
    // company_id: company_id,
  })

  const setSearchQuery = React.useCallback(
    (newQuery: string) => {
      setSearchOptions(prevState => ({
        ...prevState,
        query: newQuery,
      }))
    },
    [setSearchOptions]
  )
  const data: NoteType = 'order'
  const { entityFilter, setEntityFilter } = useEntityFilter({ defaultTypes: ['order'] })

  React.useEffect(() => {
    if (orderId) {
      notesPagedTable.reloadData()
    }
    // if you pass pagedTable it will be called in infinite loop
    // eslint-disable-next-line
  }, [searchOptions])

  const memoApiCall = React.useMemo(() => fetchNotes(removeNullAndUndefined(searchOptions)), [searchOptions])

  const notesPagedTable = usePagedTable<INote>({
    apiCall: memoApiCall,
    autoLoad: true,
    defaultPageSize: 25,
  })

  const reloadNoteTable = useCallback(() => {
    notesPagedTable.reloadData()
  }, [notesPagedTable])

  return {
    notesPagedTable,
    reloadNoteTable,
    setSearchQuery,
    searchOptions,
    setSearchOptions,
  }
}

const OrderDetails = () => {
  const classes = useStyles()
  const [orderDetails, setOrderDetails] = React.useState<IOrderDetailsResponse>(emptyOrderDetails)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { match, history } = useRouter<IIDParam>()

  const [isUpdate, setIsUpdate] = React.useState(false)

  const getOrderDetailsAndSetState = React.useCallback(
    async (orderId: number) => {
      setIsLoading(true)
      const orderDetails = await getOrderDetails(orderId)
      setIsLoading(false)
      setOrderDetails(orderDetails)
    },
    [setIsLoading, setOrderDetails]
  )

  React.useEffect(() => {
    if (!match.params.id) {
      history.push('/')
      return
    }

    const id: number | undefined = parseInt(match.params.id)
    if (isNaN(id)) {
      history.push('/')
      return
    }

    getOrderDetailsAndSetState(id)

    if (isUpdate) {
      getOrderDetailsAndSetState(id)
    }
    // setNotesVisible(false)
    // // console.log('det1',orderDetails);

    // setNotesVisible(true)
  }, [getOrderDetailsAndSetState, history, match.params.id, isUpdate])

  const { notesPagedTable, setSearchQuery, reloadNoteTable, searchOptions, setSearchOptions } = useNotesGlobal(
    orderDetails.id
    // orderDetails.company_id
  )

  const [notesVisible, setNotesVisible] = React.useState<boolean>(false)
  const [showtemp, setshowtemp] = React.useState<boolean>(false)
  const [notesloading, setnotesloading] = React.useState<boolean>(false)
  const [statusChanged, setStatusChanged] = React.useState<boolean>(false)

  React.useEffect(() => {
    console.log('idd', match.params.id, orderDetails)

    if (orderDetails.id === parseInt(match.params.id || '')) {
      setshowtemp(true)
    } else {
      setshowtemp(false)
    }
  }, [orderDetails, match.params.id])

  React.useEffect(() => {
    if (showtemp) {
      setnotesloading(true)
      const timerid = setTimeout(() => {
        console.log('hi :D ')
        setnotesloading(true)
        setNotesVisible(true)
      }, 1000)
    } else {
    }
  }, [showtemp])

  const defaultNoteEntities: INoteEntitiesContext | undefined = React.useMemo(() => {
    if (!orderDetails.order_type || !orderDetails.order_stage) {
      return undefined
    }

    const orderTitle = `#${orderDetails.id} ${orderDetails.order_type.name} ` //- ${orderDetails.order_stage.title}`
    const companyTitle = `${orderDetails?.company_details?.name} `
    // const clientTitle = `${orderDetails?.company_details?.client?.name} `

    return {
      noteType: 'order',
      types: ['order'],
      order: {
        value: orderDetails.id,
        label: orderTitle,
      },
      company: { value: orderDetails?.company_details?.id || 0, label: orderDetails.company_details?.name || '' },
      // company: {
      //   value: orderDetails?.company_id,
      //   label: companyTitle,
      // },
      // client: {
      //   value: 130,
      //   label: clientTitle,
      // },
    }
  }, [orderDetails])

  const defaultCompanyNoteEntities: INoteEntitiesContext | undefined = React.useMemo(() => {
    if (!orderDetails.order_type || !orderDetails.order_stage) {
      return undefined
    }

    // const orderTitle = `#${orderDetails.id} ${orderDetails.order_type.name} ` //- ${orderDetails.order_stage.title}`
    const companyTitle = `${orderDetails?.company_details?.name} `

    return {
      noteType: 'company',
      types: ['company'],

      company: {
        value: orderDetails?.company_id,
        label: companyTitle,
      },
    }
  }, [orderDetails])
  React.useEffect(() => {
    console.log('defnote', defaultNoteEntities)
    console.log('defnoteComapny', defaultCompanyNoteEntities)
  }, [orderDetails, defaultNoteEntities, defaultCompanyNoteEntities])

  if (isLoading) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress />
      </div>
    )
  }

  if (!orderDetails.id) {
    return null
  }

  // useEntityFilter({defaultTypes: data})

  console.log('orderDetails', orderDetails)
  console.log('notesPagedTable', notesPagedTable)

  return (
    <OrderDetailsContext.Provider value={orderDetails}>
      <div className={classes.root}>
        <OrderEdit
          setOrderDetails={setOrderDetails}
          classes={{ root: classnames(classes.orderRoot, { [classes.orderRootMain]: !notesVisible }) }}
          orderDetails={orderDetails}
          orderStages={orderDetails.order_stages || []}
          reloadNoteTable={reloadNoteTable}
          setIsUpdate={setIsUpdate}
        />
        {notesVisible && showtemp ? (
          <NoteEntitiesContext.Provider value={defaultNoteEntities}>
            <NotesTableContext.Provider
              value={{
                pagedTable: notesPagedTable,
                setSearchQuery,
                searchOptions,
                setSearchOptions,
              }}
            >
              <Notes
                setModalIsOpen={setNotesVisible}
                classes={{ mainContainer: classes.notesContainer }}
                tableBodyClassName={classes.notesTable}
              />
            </NotesTableContext.Provider>
          </NoteEntitiesContext.Provider>
        ) : (
          <div>
            <OpenNotesButton onClick={() => setNotesVisible(true)} />
            {notesloading && <CircularProgress />}
          </div>
        )}
      </div>
    </OrderDetailsContext.Provider>
  )
}

export default OrderDetails
