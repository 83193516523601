import { apiClient } from '@jetkit/react'
import { ICommission, ICommissionTypes } from 'models/commission'

export const getCommissionsForAffiliate = async (id: string): Promise<ICommission[]> =>
  (await apiClient.get(`commission/affiliate/${id}`)).data

export const getCommissionsForClient = async (id: number): Promise<ICommission[]> => {
  return (await apiClient.get(`commission/client/${id}`)).data
}
export const getCommissionsTypes = async (): Promise<ICommissionTypes> => (await apiClient.get(`commission/type`)).data

export const createCommission = async (commission: ICommission): Promise<ICommission> =>
  (await apiClient.post(`commission`, commission)).data
