import React, { useContext, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import CustomDialogue from '../CustomModal/CustomModal'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Step1 from '../Step1'
import Step2 from '../Step2'
import Step3 from '../Step3'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import { AddCircle, ArrowBack } from '@material-ui/icons'
import { ExistingCompanyContext } from 'providers/existingCompany/existingCompany.provider'
import { ICreateClientCompany, addExisitingCompany } from 'api/clientDashboard'
import showErrorNotification from 'utils/showErrorNotification'
import useUser from 'hooks/useUser'
import InitialStep from '../initialStep'
import useRouter from 'use-react-router'

const getSteps = () => {
  debugger

  return [<InitialStep key={1} />, <Step1 key={2} />, <Step2 key={3} />, <Step3 key={4} />]
}
const getStepContent = (stepIndex: number, handleNext: any) => {
  debugger

  switch (stepIndex) {
    case 0:
      return <InitialStep handleNext={handleNext} />
    case 1:
      return <Step1 />
    case 2:
      return <Step2 />
    case 3:
      return <Step3 />
    // case 1:
    //   return <InitialStep />
    // case 2:
    //   return <Step1 />
    // case 3:
    //   return <Step2 />
    // case 4:
    //   return <Step3 />
    default:
      return 'Unknown stepIndex'
  }
}
const AddCompany = () => {
  const { user } = useUser()
  const { location } = useRouter()

  const classes = useStyles()
  const {
    formationState,
    companyType,
    address,
    name,
    stateId,
    einNumber,
    fileDate,
    sharesNumber,
    companyOwners,
  } = useContext(ExistingCompanyContext)

  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [companyDetails, setCompanyDetails] = useState<ICreateClientCompany>()
  const [isLoading, setIsLoading] = useState(false)

  const steps = getSteps()

  useEffect(() => {
    const state = location?.state as { companyModal: boolean }
    if (state?.companyModal) {
      setOpen(true)
    }
  }, [location?.state])

  const handleAddCompany = async (payload: ICreateClientCompany) => {
    setIsLoading(true)
    try {
      const res = await addExisitingCompany(payload)
      if (res?.error) {
        showErrorNotification(res?.error)
        setOpen(false)
        setActiveStep(0)
      }
      debugger
      setCompanyDetails(res)
      setIsLoading(false)
      if (activeStep === 3) {
        window.location.reload()
      }
    } catch (error) {
      showErrorNotification(error)
    }
    setIsLoading(false)
  }

  const handleNext = () => {
    debugger

    if (activeStep !== 0 && (companyType?.value === 0 || formationState?.id === 0 || !address?.city_id)) {
      return
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    debugger

    switch (activeStep) {
      case 1:
        debugger
        const payload1 = {
          step: activeStep,
          state_of_formation: formationState?.id,
          name: name,
          address: address,
          company_type: companyType?.value,
          client_id: user?.id,
        }
        handleAddCompany(payload1)
        break

      case 2:
        debugger
        const payload2 = {
          step: activeStep,
          owners: companyOwners,
          id: companyDetails?.id,
        }
        handleAddCompany(payload2)
        break

      default:
        break
    }
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const addCompany = () => {
    const payload3 = {
      step: activeStep,
      file_date: fileDate,
      state_id_number: stateId,
      ein_number: einNumber,
      number_of_shares: sharesNumber,
      id: companyDetails?.id,
    }
    handleAddCompany(payload3)

    setOpen(false)
    setActiveStep(0)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {/* <ButtonWithLoading onClick={handleClickOpen}>Add existing Company</ButtonWithLoading> */}
      <div className={classes.addCompanyBox} onClick={handleClickOpen}>
        <AddCircle />
        Add Company
      </div>

      <CustomDialogue open={open} handleClose={handleClose} activeStep={activeStep}>
        <div>{activeStep !== steps.length ? <div>{getStepContent(activeStep, handleNext)}</div> : <></>}</div>
        <div className={classes.stepsBtn} style={{ flexDirection: activeStep > 0 ? 'inherit' : 'row-reverse' }}>
          {activeStep > 0 && (
            <Button color="primary" onClick={handleBack} startIcon={<ArrowBack />}>
              Back
            </Button>
          )}

          {activeStep === steps.length - 1 ? (
            <ButtonWithLoading onClick={addCompany} isLoading={isLoading}>
              Add Company
            </ButtonWithLoading>
          ) : activeStep !== 0 ? (
            <Button color="primary" onClick={handleNext} endIcon={<ArrowForwardIcon />} disabled={isLoading}>
              Next
            </Button>
          ) : (
            ''
          )}
        </div>
      </CustomDialogue>
    </>
  )
}

export default AddCompany

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {},
    stepsBtn: {
      textAlign: 'right',
      margin: '40px 0 0 0',
      display: 'flex',
      justifyContent: 'space-between',
      // position: 'absolute',
      // bottom: 0,
      // width: 'calc(100% - 3rem)',
      // background: '#fff',

      '& > .MuiButton-containedPrimary': {
        color: '#fff',
      },
    },
    ArrownIcon: {
      fontSize: '14px',
      marginLeft: '8px',
    },
    addCompanyBox: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      background: '#f6f6f7',
      color: '#5b38da',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '215px',
      fontSize: '1.25rem',
      fontWeight: 'bold',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },

      '& .MuiSvgIcon-root': {
        fontSize: '4rem',
        marginBottom: '1rem',

        [theme.breakpoints.down('sm')]: {
          fontSize: '3rem',
        },
      },
    },
  })
)
