import { useStyles } from '../styles'
import React from 'react'
import { Link } from '@material-ui/core'
import { CalendarTodayOutlined, LocalAtm, MonetizationOnOutlined, Payment } from '@material-ui/icons'
import moment from 'moment'
import { STRIPE_SUBSCRIPTION_STATUSES, SUBSCRIPTION_STATUS_MAPPING, SubscriptionType } from 'utils/constant'

interface SubscriptionCardProps {
  handleOpen: any
  isActive?: boolean
  subscriptionName: string
  subscriptionAmount: number
  subscriptionPeriod: string
  subStartDate?: string
  renewDate: number
  status?: string
  subscriptionType?: string
  allocatedAddress?: string
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  handleOpen,
  isActive,
  subscriptionName,
  subscriptionAmount,
  subscriptionPeriod,
  subStartDate,
  renewDate,
  status,
  subscriptionType,
  allocatedAddress,
}: SubscriptionCardProps) => {
  const classes = useStyles()

  return (
    <div
      className={classes.subscriptionCardContainer}
      style={{ borderColor: isActive ? '#407ace' : '#979797', background: '#f6f8fb' }}
    >
      <div className="inline-icon">
        <h4 className="title" style={{ color: isActive ? '#2c3b9b' : '#888888' }}>
          {subscriptionName}
        </h4>
        {/* {subscriptionType === 'offline' ? (
          <LocalAtm htmlColor={isActive ? '#2c3b9b' : '#888888'} titleAccess="Offline" />
        ) : (
          ''
        )} */}
      </div>

      <div className="inline">
        <CalendarTodayOutlined style={{ color: isActive ? '#5b38d9' : '#788190' }} />
        <p style={{ color: isActive ? '#292929' : '#888888' }}>
          Subscription Start: {subStartDate && moment(subStartDate).format('dddd, Do MMM YYYY')}
        </p>
      </div>

      <div className="inline">
        <CalendarTodayOutlined style={{ color: isActive ? '#5b38d9' : '#788190' }} />
        <p style={{ color: isActive ? '#292929' : '#888888' }}>
          Renews At: {renewDate && moment.unix(renewDate).format('dddd, Do MMM YYYY')}
        </p>
      </div>

      <div className="inline" style={{ color: isActive ? '#307abc' : '#888888' }}>
        <MonetizationOnOutlined style={{ color: isActive ? '#5b38d9' : '#788190' }} />
        <p style={{ color: isActive ? '#292929' : '#888888' }}>
          Subscription Amount: ${subscriptionAmount}/{subscriptionPeriod}
        </p>
      </div>

      <div className="inline">
        <CalendarTodayOutlined style={{ color: isActive ? '#5b38d9' : '#788190' }} />
        <p style={{ color: isActive ? '#292929' : '#888888' }}>Status : {SUBSCRIPTION_STATUS_MAPPING[status || '']}</p>
      </div>

      <div className="inline">
        <Payment style={{ color: isActive ? '#5b38d9' : '#788190' }} />
        <p style={{ color: isActive ? '#292929' : '#888888', textTransform: 'capitalize' }}>
          Payment Method: {SubscriptionType[subscriptionType || '']}
        </p>
      </div>

      {allocatedAddress && (
        <div className="inline">
          <CalendarTodayOutlined style={{ color: isActive ? '#5b38d9' : '#788190' }} />
          <p style={{ color: isActive ? '#292929' : '#888888' }}>Address : {allocatedAddress}</p>
        </div>
      )}
      {/* {status !== STRIPE_SUBSCRIPTION_STATUSES.canceled && ( */}
      <Link
        // href="#"
        underline="always"
        className="edit-btn"
        onClick={handleOpen}
        style={{ color: isActive ? '#307abc' : '#888888' }}
      >
        Edit Subscription
      </Link>
      {/* )} */}
    </div>
  )
}

export default SubscriptionCard
