import { notify } from '@jetkit/react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import { fetchClient } from 'api/clients'
import { createOwner, deleteOwner, updateCompany } from 'api/companies'
import { createNoteForStageChange } from 'api/note'
import {
  emptyOrderDetails,
  fetchDocsForPdf,
  findOrdersByIds,
  IOrderDetailsResponse,
  IUpdateOrder,
  updateOrder,
  generateAndUploadPdf,
  uploadPdf,
  externalDocsToS3,
} from 'api/orders'
import { createOrderSuggestions, makeOrderSuggestion } from 'api/suggestions'
import DocumentUploads from 'components/common/assets/documentUploads'
import DialogWithConfirmButtons from 'components/common/dialogs/DialogWithConfirmButtons'
import MultiSelect from 'components/common/select/multiSelect'
import { IOwnerSummary } from 'components/companies/companyOwners'
import AddOwnerDialog from 'components/companies/companyOwners/AddOwnerDialog'
import CompanyOwnersTable from 'components/companies/companyOwners/CompanyOwnersTable'
import NewOwnerDialog from 'components/companies/companyOwners/newOwnerDialog'
import useCompanyOwners from 'components/companies/hooks/companyOwners'
import { ISuggestionAttribute } from 'components/interfaces'
import CompanyDetails, { BUSINESS_FORMATION } from 'components/orders/CompanyDetails'
import NonPaginatedOrdersTable from 'components/orders/nonPaginatedOrdersTable'
import { ChangeOrderStatusDialog } from 'components/orders/orderDetails/ChangeOrderStatusDialog'
import OrderPageTitle from 'components/orders/orderPageTitle'
import OrderTasks from 'components/orders/OrderTasks'
import SalesRep from 'components/orders/SalesRep'

import OrderTable from 'components/orders/table'

import SkeletonContents from 'components/orders/skeletonContents'
import deepcopy from 'deepcopy'
import { useAPICallback } from 'hooks/useAPICallback'
import { IDetailedCompanyResponse, legalTypesToApiFormat } from 'models/company'
import { IOrder as BaseOrder, IOrderStage, ISubTaskStatus } from 'models/order'
import { IClient, ICompanyOwner, IUser } from 'models/user'
import * as React from 'react'
import { ValueType } from 'react-select/src/types'
import { assertNonNullable } from 'utils/asserts'
import { notEmpty, ValueOf } from 'utils/objectUtils'
import showApiResponseError from 'utils/showApiResponseError'
import showErrorNotification from 'utils/showErrorNotification'
import SelectAutocomplete from 'components/common/SelectAutocomplete'
import ButtonWithLoading from 'components/common/buttons/buttonWithLoadingProgress'
import showSuccessNotification from 'utils/showSuccessNotification'
import { ORDERSTATUS } from 'utils/constant'
import AddOrderItem from '../addOrderItem/index'
import UploadButton from 'components/common/buttons/uploadButton'
import { Divider, Link } from '@material-ui/core'
import AddOrderTypeTasks from '../addOrderTypeTasks'
import CreateOrder from '../create'

const multiValueStyles = {
  height: 50,
  fontSize: '1.2rem',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
}
const multiValueRemoveStyles = {
  height: 50,
}

export type GetOrderStagesAPICall = (id: number) => Promise<IOrderStage[]>
export type GetSubtaskStatusesAPICall = (id: number) => Promise<ISubTaskStatus[]>

export const OrderStagesAPICallContext = React.createContext<GetOrderStagesAPICall | null>(null)
export const OrderSubtaskStatusesAPICallContext = React.createContext<GetSubtaskStatusesAPICall | null>(null)

interface IOrderProps {
  orderDetails: IOrderDetailsResponse
  setOrderDetails: React.Dispatch<React.SetStateAction<IOrderDetailsResponse>>
  orderStages: IOrderStage[]
  classes?: Record<string, string>
  reloadNoteTable(): void
  setIsUpdate?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',

      '& .campany-detail': {
        fontSize: '1.6rem',
        fontFamily: theme.typography.h1.fontFamily,
        width: '100%',
      },

      '& .sub-order-section': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    container: {
      height: '100%',
      width: '100%',
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    paper: {
      padding: 10,
      margin: 0,
      height: 'calc(100% - 20px)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'calc(100% - 350px)',
      backgroundColor: '#fafafb',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    salesRep: {
      marginBottom: 20,
    },
    companyDetails: {
      marginBottom: 40,
    },
    orderTasks: {},
    button: {
      width: '100%',
    },
    multiSelect: {
      marginTop: 40,
      marginBottom: 20,
      width: '100%',
    },
    dependentOrders: {
      marginBottom: 20,
    },
    title: {
      fontSize: '2rem',
      marginBottom: 15,
    },
    ownersTable: {
      marginBottom: 40,
    },
    futureOrders: {
      marginBottom: 20,
      width: '100%',
      margin: '5px 0',
    },
    captionText: {
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    saveButton: {
      marginTop: 5,
      marginBottom: 5,
      width: 300,
    },
    sectionTitle: {
      textTransform: 'uppercase',
      fontWeight: 300,
      marginLeft: 5,
      marginBottom: 10,
      width: '100%',
    },
    stickyFooter: {
      height: '60px',
      bottom: '0px',
      width: '100%',
      position: 'fixed',
      textAlign: 'center',
      zIndex: 1,
      backgroundColor: 'white',
    },
    documentUploads: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
    },
    uploadSec: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    pdfSec: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  })
)

const Order: React.FC<IOrderProps> = props => {
  const classes = useStyles(props)
  const { orderDetails, orderStages, setOrderDetails, reloadNoteTable, setIsUpdate } = props
  const [futureOrders, setFutureOrders] = React.useState<BaseOrder[] | undefined>()

  const noPrimaryAddressError = React.useMemo(() => {
    return !orderDetails.company_details?.addresses?.some(address => address.is_primary) || false
  }, [orderDetails.company_details])

  const {
    ownerFields,
    companyOwners,
    companyRoles,
    fetchAndSetCompanyOwners,
    clearOwnerState,
    toggleRole,
    selectAllRoles,
    allRolesSelected,
    onOwnerFieldsChange,
    fetchAndSetCompanyRoles,
    getRoleById,
    shouldUpdateOwnersOnCompanyLevel,
    setUpdateOwnersOnCompanyLevel,
    toggleUpdateLevelForCompanyOwners,

    isAddOwnerDialogOpen,
    setIsAddOwnerDialogOpen,

    isEditModeInAddAddressDialog,
    setIsEditModeInAddAddressDialog,

    isNewOwnerDialogOpen,
    setIsNewOwnerDialogOpen,

    activeOwner,
    handleOwnerDeleteClick,
    toggleIsNewOwnerDialogOpen,
    isLoading,
    setIsLoading,
    handleModalClose,
    setOwnerToBeDeleted,
    ownerIdToBeDeleted,
    shouldShowDeleteConfirmationDialog,
    setShouldShowDeleteConfirmationDialog,
    setOwnerFields,
    setActiveOwner,
  } = useCompanyOwners({ company: orderDetails.company })

  const validateOrderDetails = React.useCallback((): boolean => {
    if (noPrimaryAddressError) {
      notify.warning('Please provide a primary address')
      return false
    }

    // if (orderDetails.company_details && !orderDetails.assignee_id) {
    //   notify.error('Please fill in incorporator field')
    //   return false
    // }

    return true
  }, [noPrimaryAddressError])
  const [pdfDocs, setPdfDocs] = React.useState([])
  const [pdfDocId, setPdfDocId] = React.useState('')
  const [pdfDocName, setPdfDocName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [isPdfUploaded, setIsPdfUploaded] = React.useState(false)
  const [orderStatusChanged, setOrderStatusChanged] = React.useState(false)
  const [inputFile, setInputFile] = React.useState<any>(null)
  const [uploadLoading, setUploadLoading] = React.useState(false)

  React.useEffect(() => {
    console.log('inputFile', inputFile)
    if (!inputFile) return
    const filename = inputFile.name?.split('.')[0]
    // filename = filename.replace(/[^A-Za-z ]+/g, '')
    console.log('filename', filename)
    setUploadLoading(true)
    const promise = new Promise((resolve, reject) => {
      const id: any = pdfDocId
      // pdfDocs.map((item: any) => {
      //   if (item.document_name === filename) {
      //     id = item.id
      //   }
      // })
      if (!id) {
        reject('No id found!')
      }
      resolve(id)
    })
    promise
      .then(async id => {
        const resultUrlData = await uploadPdf(inputFile, id as string)
        console.log(resultUrlData)
        const uploads = resultUrlData.map(async item => {
          const uploadedRes = await externalDocsToS3({
            company_id: orderDetails.company_id,
            file_name: item.url.replace(/^.*[\\\/]/, ''),
            url: item.url,
            mime_type: item.type,
            order_id: orderDetails.id,
          })
          console.log('uploadedRes', uploadedRes)
        })
        await Promise.all(uploads)
        setUploadLoading(false)

        showSuccessNotification('Uploaded Successfully')
        setIsPdfUploaded(true)
        setInputFile(null)
        // document.getElementById('uploaded-file')?.value(null)
      })
      .catch(err => {
        setUploadLoading(false)

        console.log(err)
      })
  }, [inputFile])
  const onPdfUpload = () => {
    const input = document.getElementById('uploaded-file') as HTMLInputElement | null
    console.log(input)

    const file = input?.files?.length ? input?.files[0] : null
    setInputFile(file)
  }

  const generatePdf = async () => {
    if (pdfDocId === '' && pdfDocName === '') {
      return
    }
    setIsPdfUploaded(false)
    setLoading(true)
    let data: any = {}
    data = orderDetails
    data.order_id = data.id
    data.document_name = pdfDocName
    data.document_id = pdfDocId

    try {
      const res = await generateAndUploadPdf(data)
      setLoading(false)
      //let blob =new Blob([res]) //instanceof Blob //
      // const url = window.URL.createObjectURL(new Blob([res], {type:"application/pdf"}))
      // const link = document.createElement('a')
      // link.href = url
      // link.setAttribute('download', 'file.pdf') //or any other extension
      // document.body.appendChild(link)
      // link.click()
      if (res) {
        const link = document.createElement('a')
        link.href = res
        link.target = '_blank'

        link.download = 'file.pdf'
        link.dispatchEvent(new MouseEvent('click'))
      }
      showSuccessNotification('Pdf Generated Successfully')
      setIsPdfUploaded(true)
    } catch (e) {
      setLoading(false)
      showApiResponseError(e, 'Error Generating Pdf' + e)
    }
  }
  React.useEffect(() => {
    // console.log("orderDetails",orderDetails);
    const getdocs = async () => {
      const data = await fetchDocsForPdf(orderDetails.company_details?.id || 0)
      setPdfDocs(data)
      console.log(data[0])
    }
    getdocs()
  }, [])
  React.useEffect(() => {
    console.log('pdfDocId', pdfDocId)
  }, [pdfDocId])
  // This method calculates amount of shares taken by existing clients
  const takenShares = React.useMemo(() => {
    const owners = orderDetails.company_details && orderDetails.company_details.company_users
    if (!owners || !owners.length) return 0
    else if (orderDetails.company_details && orderDetails.company_details.company_users.length < 2)
      return owners[0].number_of_shares
    const ret = owners.map(owner => owner.number_of_shares).reduce((prev, current) => prev + current)
    return ret
  }, [orderDetails.company_details])

  // const handleSavingOrder = React.useCallback(
  //   async (orderDetails: IUpdateOrder) => {
  //     if (!validateOrderDetails()) return

  //     try {
  //       await updateOrder(orderDetails)
  //     } catch (err) {
  //       showApiResponseError(err, 'Could not save order')
  //     }
  //   },
  //   [validateOrderDetails]
  // )
  const handleDocSelect = (id: string) => {
    setPdfDocId(id)
    pdfDocs.map((item: any) => {
      if (item.id === id) {
        setPdfDocName(item.document_name)
      }
    })
  }
  //single func
  const [companyfieldsChanged, setCompanyFieldsChanged] = React.useState<boolean>(false)

  const saveCompanyDetails = React.useCallback(async () => {
    if (companyfieldsChanged) {
      const data: any = {
        name: orderDetails.company_details?.name,
        legal_type: legalTypesToApiFormat[orderDetails.company_details?.legal_type || 1],
        business_activity: orderDetails.company_details?.business_activity,
        state_of_formation_id: orderDetails.company_details?.state_of_formation_id,
        ein: orderDetails.company_details?.ein,
        incorporator: orderDetails.company_details?.incorporator,
        state_id: orderDetails.company_details?.state_id,
        file_date: orderDetails.company_details?.file_date,
        meeting_date: orderDetails.company_details?.meeting_date,
        number_of_shares: orderDetails.company_details?.number_of_shares,
        tax_structure: orderDetails.company_details?.tax_structure,
      }
      try {
        setLoading(true)
        await updateCompany(orderDetails.company_details?.id || 0, data)
        showSuccessNotification('Updated Successfully!')
        setLoading(false)
      } catch (error) {
        showApiResponseError(error, 'Error updating Details')
        setLoading(false)
      }
      console.log('orderDetails.company_details', orderDetails.company_details)
    }
  }, [orderDetails, companyfieldsChanged])

  const handleSavingOrder = React.useCallback(async () => {
    if (!validateOrderDetails()) return

    try {
      setLoading(true)
      await updateOrder(orderDetails)
      setLoading(false)

      showSuccessNotification('Order Updated SuccessFully')
      reloadNoteTable()
      setOrderStatusChanged(true)
      companyfieldsChanged && (await saveCompanyDetails())
    } catch (err) {
      setLoading(false)

      showApiResponseError(err, 'Could not save order')
    }
  }, [validateOrderDetails, orderDetails, companyfieldsChanged])
  const onOrderDetailsFieldsChange = useAPICallback(
    async (key: keyof IOrderDetailsResponse, value: ValueOf<IOrderDetailsResponse>) => {
      console.log('paymentstattusssssssssss', key, value)

      setOrderDetails((prevState: IOrderDetailsResponse) => ({
        ...prevState,
        [key]: value,
      }))

      // await handleSavingOrder({
      //   ...orderDetails,
      //   [key]: value,
      // })
    },
    [setOrderDetails, handleSavingOrder]
  )

  const isEditable: boolean = React.useMemo(
    () =>
      // (orderDetails.order_type &&
      //   orderDetails.order_type.name === BUSINESS_FORMATION &&
      //   orderDetails.order_stage &&
      //   orderDetails.order_stage.status === 'open') ??false
      orderDetails.order_status === ORDERSTATUS.completed || orderDetails.order_status === ORDERSTATUS.cancelled
        ? false
        : true,
    [orderDetails.order_stage, orderDetails.order_type]
  )

  const handleAddMultiValue = React.useCallback(
    (field: string, select: ValueType<ISuggestionAttribute>) => {
      switch (field) {
        case 'required_order_ids':
          setOrderDetails((prevState: IOrderDetailsResponse) => ({
            ...prevState,
            [field]: select && Array.isArray(select) ? select.map((item: ISuggestionAttribute) => item.value) : [],
          }))
          break
        default:
          throw new Error('Wrong key for `IOrderDetailsResponse`')
      }
    },
    [setOrderDetails]
  )

  const onOwnerEdit = React.useCallback(
    (userId: number) => {
      // get the owner to be edited
      const owner = orderDetails.company_details?.company_users.find(owner => owner.user.id === userId)

      assertNonNullable(owner)
      assertNonNullable(userId)

      setOwnerFields({
        user_id: userId,
        number_of_shares: owner.number_of_shares,
        role_ids: owner.roles.map(role => role.id),
        address: owner.address,
        is_primary_contact: owner.is_primary_contact,
      })
    },
    [setOwnerFields, orderDetails]
  )

  // as the user clicks on edit button,
  // open the edit dialog and set the active edited owner to the state
  const handleEditOwnerButtonClick = React.useCallback(
    async (userId: number, userName: string) => {
      //object for editing in a dialog
      const owner: IOwnerSummary = {
        id: userId,
        name: userName,
      }
      setActiveOwner(owner)

      setIsEditModeInAddAddressDialog(true)
      await fetchAndSetCompanyRoles()
      onOwnerEdit(userId)
      setIsAddOwnerDialogOpen(true)
    },
    [fetchAndSetCompanyRoles, onOwnerEdit, setActiveOwner, setIsAddOwnerDialogOpen, setIsEditModeInAddAddressDialog]
  )

  const defaultRequiredOrders = React.useMemo(
    () => (orderDetails.required_orders || []).map((order: BaseOrder) => makeOrderSuggestion(order)),
    [orderDetails.required_orders]
  )

  const saveEditedOwner = React.useCallback(async () => {
    if (!orderDetails || !orderDetails.company) return

    setIsLoading(true)
    try {
      await createOwner(ownerFields, orderDetails.company.id)
      fetchAndSetCompanyOwners(orderDetails.company.id)
      setIsLoading(false)
    } catch (err) {
      showApiResponseError(err, 'Could not create an owner')
      setIsLoading(false)
    }
    // setUpdateOwnersOnCompanyLevel(false)
  }, [fetchAndSetCompanyOwners, orderDetails, ownerFields, setIsLoading, setUpdateOwnersOnCompanyLevel])

  const updateOwnersOnOrderLevel = React.useCallback(async () => {
    if (!orderDetails.company_details) return
    const companyDetails: IDetailedCompanyResponse = deepcopy(orderDetails.company_details)

    // find the user to update by id and update its No of shares and roles
    const userToUpdate = companyDetails.company_users.find(user => user.user.id === ownerFields.user_id)
    if (!userToUpdate) return
    userToUpdate.number_of_shares = ownerFields.number_of_shares
    userToUpdate.roles = ownerFields.role_ids.map(getRoleById).filter(notEmpty)
    userToUpdate.address = ownerFields.address
    onOrderDetailsFieldsChange('company_details', companyDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onOrderDetailsFieldsChange,
    orderDetails.company_details,
    ownerFields.number_of_shares,
    ownerFields.role_ids,
    ownerFields.user_id,
  ])

  /**
   *  save the chosen (created owner) or update the existing one (if it already
   *  exists among the company owners list
   */
  const createOrUpdateOwner = React.useCallback(
    async (newOwner?: IOwnerSummary) => {
      if (!newOwner || !orderDetails || !orderDetails.company || !orderDetails.company_details) return
      setIsLoading(true)

      const companyDetails: IDetailedCompanyResponse = deepcopy(orderDetails.company_details)

      const isUserOwnerOfTheCompany = !!companyDetails.company_users.find(user => user.user.id === newOwner.id) ?? false

      if (isUserOwnerOfTheCompany) {
        updateOwnersOnOrderLevel()

        if (shouldUpdateOwnersOnCompanyLevel) {
          saveEditedOwner()
        }
      } else {
        const owner: IClient = await fetchClient(newOwner.id)
        owner.name = `${owner.name} ${owner.middle_name} ${owner.last_name}`
        const newUser: ICompanyOwner = {
          user: owner,
          number_of_shares: ownerFields.number_of_shares,
          roles: ownerFields.role_ids.map(getRoleById).filter(notEmpty),
          address: ownerFields.address,
        }
        companyDetails.company_users.push(newUser)

        onOrderDetailsFieldsChange('company_details', companyDetails)

        if (shouldUpdateOwnersOnCompanyLevel) {
          saveEditedOwner()
        }
      }

      setIsNewOwnerDialogOpen(false)
      setIsLoading(false)
    },
    [
      getRoleById,
      onOrderDetailsFieldsChange,
      orderDetails,
      ownerFields.address,
      ownerFields.number_of_shares,
      ownerFields.role_ids,
      saveEditedOwner,
      setIsNewOwnerDialogOpen,
      setIsLoading,
      shouldUpdateOwnersOnCompanyLevel,
      updateOwnersOnOrderLevel,
    ]
  )

  const onEditOwnerButtonClick = React.useCallback(async () => {
    updateOwnersOnOrderLevel()
    if (shouldUpdateOwnersOnCompanyLevel) {
      saveEditedOwner()
    }
    clearOwnerState()
  }, [clearOwnerState, saveEditedOwner, shouldUpdateOwnersOnCompanyLevel, updateOwnersOnOrderLevel])

  const handleSubmitOwnerButtonClick = async () => {
    await onEditOwnerButtonClick()
    handleModalClose()
  }

  // do a request to the API endpoint to delete the owner
  const deleteOwnerOnCompanyLevel = React.useCallback(async () => {
    if (!orderDetails || !orderDetails.company || !ownerIdToBeDeleted) return
    setIsLoading(true)
    await deleteOwner(orderDetails.company.id, ownerIdToBeDeleted)
    setIsLoading(false)
    fetchAndSetCompanyOwners(orderDetails.company.id)
  }, [fetchAndSetCompanyOwners, orderDetails, ownerIdToBeDeleted, setIsLoading])

  // handle owner deletion either on the company or on the order level
  // if it's company level - we are doing an additional API call right away
  // if only order level we are going to update the backend upon saving the
  // order
  const handleOwnerDeleteConfirmationClick = React.useCallback(
    (companyLevel?: boolean): void => {
      if (!orderDetails.company_details) return
      const companyDetails: IDetailedCompanyResponse = deepcopy(orderDetails.company_details)
      companyDetails.company_users = companyDetails.company_users.filter(user => user.user.id !== ownerIdToBeDeleted)
      if (companyLevel) {
        deleteOwnerOnCompanyLevel()
      }
      onOrderDetailsFieldsChange('company_details', companyDetails)
      setOwnerToBeDeleted(undefined)
      setShouldShowDeleteConfirmationDialog(false)
    },
    [
      deleteOwnerOnCompanyLevel,
      ownerIdToBeDeleted,
      orderDetails.company_details,
      onOrderDetailsFieldsChange,
      setOwnerToBeDeleted,
      setShouldShowDeleteConfirmationDialog,
    ]
  )

  // if we close the Delete Confirmation Dialog,
  // reset the owner that needs to be deleted
  // and close the optionsSelectDialog
  const handleOwnerDeleteConfirmationDialogClose = React.useCallback(() => {
    setShouldShowDeleteConfirmationDialog(false)
    setOwnerToBeDeleted(undefined)
  }, [setOwnerToBeDeleted, setShouldShowDeleteConfirmationDialog])

  const getFutureOrders = React.useCallback(async () => {
    const futureOrderIds = orderDetails.future_orders.map(order => order.id)
    if (!futureOrderIds || !futureOrderIds.length) return
    const futureOrders = await findOrdersByIds(futureOrderIds)
    setFutureOrders(futureOrders)
  }, [orderDetails.future_orders, setFutureOrders])

  React.useEffect(() => {
    getFutureOrders() // getFuture orders and display them in the future orders table (if there are any)
  }, [getFutureOrders])

  const onDueDateSelected = React.useCallback(
    (dueDate: string) => {
      const newDueDate = new Date(dueDate).toISOString()
      onOrderDetailsFieldsChange('due_date', newDueDate)
    },
    [onOrderDetailsFieldsChange]
  )

  const onExpectedEINDateSelected = React.useCallback(
    (expectedEINDate: string) => {
      const newExpectedEINDate = new Date(expectedEINDate).toISOString()
      onOrderDetailsFieldsChange('expected_ein_date', newExpectedEINDate)
    },
    [onOrderDetailsFieldsChange]
  )

  const onDueDateOrderStatusSelected = React.useCallback(
    (dueDate: string) => {
      const newDueDate = new Date(dueDate).toISOString()
      onOrderDetailsFieldsChange('due_date_order_status', newDueDate)
    },
    [onOrderDetailsFieldsChange]
  )

  const dueDate = orderDetails.due_date ? new Date(orderDetails.due_date) : undefined

  const expectedEINDate = orderDetails.expected_ein_date ? new Date(orderDetails.expected_ein_date) : undefined

  const dueDateOrderStatus = orderDetails.due_date_order_status
    ? new Date(orderDetails.due_date_order_status)
    : undefined

  const [showChangeOrderStatusDialog, setShowChangeOrderStatusDialog] = React.useState(false)

  const handleChangeOrderStatusDialogClose = React.useCallback(() => setShowChangeOrderStatusDialog(false), [])

  const handleStageSelected = React.useCallback(
    async (stage?: IOrderStage) => {
      if (!stage) return

      onOrderDetailsFieldsChange('order_stage_id', stage.id)
      onOrderDetailsFieldsChange('order_stage', stage)

      await createNoteForStageChange(orderDetails.id, stage.title)

      if (stage.title === 'Complete') {
        // If the order's marked as Complete we don't wanna prompt for a due
        // date and assignee.
        // The idea is to set dueDate and assignee to NULL, so that it's
        // ambiguous and can be filled out later in case further follow up
        // is needed
        await updateOrder({
          ...orderDetails,
          order_stage: stage,
          order_stage_id: stage.id,
          due_date: null,
          assignee_id: null,
        })
        onOrderDetailsFieldsChange('assignee', null)
        onOrderDetailsFieldsChange('assignee_id', null)
        onOrderDetailsFieldsChange('due_date', null)
      } else {
        setShowChangeOrderStatusDialog(true)
        await updateOrder({
          ...orderDetails,
          order_stage: stage,
          order_stage_id: stage.id,
        })
      }

      reloadNoteTable()
    },
    [onOrderDetailsFieldsChange, orderDetails, reloadNoteTable]
  )

  const handleAssigneeSelected = (assignee: IUser | null) => {
    onOrderDetailsFieldsChange('assignee_id', assignee?.id)
    onOrderDetailsFieldsChange('assignee', assignee)
    // //
    // if (assignee?.id) {
    //   //
    //   updateOrder({
    //     id: orderDetails.id,
    //     assignee_id: assignee.id,
    //   })
    // }
  }

  // If order details are not fetched yet or they are
  // we return skeleton variant of the dialog
  if (orderDetails && orderDetails === emptyOrderDetails) {
    return (
      <div className={classes.root}>
        <SkeletonContents />
      </div>
    )
  }

  if (!orderDetails.company) {
    showErrorNotification('Error loading company details')
    throw new Error(`company is nullish on order_id=${orderDetails.id}`)
  }
  return (
    <div className={classes.root}>
      <DialogWithConfirmButtons
        onClose={handleOwnerDeleteConfirmationDialogClose}
        open={shouldShowDeleteConfirmationDialog}
        isLoading={isLoading}
        title="Do you want to delete the owner from this order or from this company?"
        firstOptionTitle="Company"
        secondOptionTitle="Order"
        onFirstOptionClick={() => handleOwnerDeleteConfirmationClick(true)}
        onSecondOptionClick={() => handleOwnerDeleteConfirmationClick()}
      />

      {orderDetails.order_stage && (
        <ChangeOrderStatusDialog
          open={showChangeOrderStatusDialog}
          handleClose={handleChangeOrderStatusDialogClose}
          newOrderStatus={orderDetails.order_stage.title}
          defaultAssignee={orderDetails.assignee}
          orderId={orderDetails.id}
          onOrderDetailsFieldsChange={onOrderDetailsFieldsChange}
        />
      )}

      <OrderPageTitle
        orderName={orderDetails?.order_type?.name ? orderDetails.order_type.name : ''}
        orderId={orderDetails.id}
        stage={orderDetails?.order_stage || undefined}
        stagesOptions={orderStages}
        dueDate={dueDate}
        dueDateOrderStatus={dueDateOrderStatus}
        onDueDateOrderStatusSelected={onDueDateOrderStatusSelected}
        handleStageSelected={handleStageSelected}
        assignee={orderDetails.assignee}
        handleAssigneeSelected={handleAssigneeSelected}
        onDueDateSelected={onDueDateSelected}
        client={orderDetails.company.client}
        orderDetails={orderDetails}
        onOrderDetailsFieldsChange={onOrderDetailsFieldsChange}
        isEditable={isEditable}
        saveOrder={handleSavingOrder}
        orderTypeList={orderDetails?.orderTypeList}
        reloadNoteTable={reloadNoteTable}
        setOrderStatusChanged={setOrderStatusChanged}
        is_rush_order={orderDetails.is_rush_order}
        saveOrderLoading={loading}
        internal_rush_processing={orderDetails.internal_rush_processing}
        expectedEINDate={expectedEINDate}
        onExpectedEINDateSelected={onExpectedEINDateSelected}
      />
      <NewOwnerDialog
        takenShares={takenShares}
        company={orderDetails.company || undefined}
        selectAllRoles={selectAllRoles}
        allRolesSelected={allRolesSelected}
        open={isNewOwnerDialogOpen}
        onClose={toggleIsNewOwnerDialogOpen}
        companyOwners={companyOwners}
        onOwnerFieldsChange={onOwnerFieldsChange}
        onOwnerEdit={onOwnerEdit}
        fetchCompanyRoles={fetchAndSetCompanyRoles}
        toggleRole={toggleRole}
        onAddOwnerButtonClick={createOrUpdateOwner}
        clearOwnerState={clearOwnerState}
        values={ownerFields}
        roles={companyRoles}
        updateOnCompanyLevel={shouldUpdateOwnersOnCompanyLevel}
        shouldShowUpdateLevelOption
        toggleUpdateLevelOption={toggleUpdateLevelForCompanyOwners}
      />
      <DialogWithConfirmButtons
        onClose={handleOwnerDeleteConfirmationDialogClose}
        open={shouldShowDeleteConfirmationDialog}
        isLoading={isLoading}
        title="Do you want to delete the owner from this order or from this company?"
        firstOptionTitle="Company"
        secondOptionTitle="Order"
        onFirstOptionClick={() => handleOwnerDeleteConfirmationClick(true)}
        onSecondOptionClick={() => handleOwnerDeleteConfirmationClick()}
      />
      {isAddOwnerDialogOpen && activeOwner && (
        <AddOwnerDialog
          takenShares={takenShares}
          selectAllRoles={selectAllRoles}
          allRolesSelected={allRolesSelected}
          shouldShowUpdateLevelOption
          isAddOwnerDialogOpen={isAddOwnerDialogOpen}
          isOnEdit={isEditModeInAddAddressDialog}
          activeOwner={activeOwner}
          handleModalClose={handleModalClose}
          values={ownerFields}
          company={orderDetails.company ?? undefined}
          roles={companyRoles}
          toggleRole={toggleRole}
          handleSubmitOwnerClick={handleSubmitOwnerButtonClick}
          onOwnerFieldsChange={onOwnerFieldsChange}
          toggleUpdateLevelOption={toggleUpdateLevelForCompanyOwners}
          updateOnCompanyLevel={shouldUpdateOwnersOnCompanyLevel}
        />
      )}
      {orderDetails.sales_rep ? (
        <SalesRep salesRep={orderDetails.sales_rep} classes={{ root: classes.salesRep }} />
      ) : null}

      <CompanyDetails
        classes={{ root: classes.companyDetails }}
        orderDetails={orderDetails}
        errors={{ noPrimaryAddressError }}
        onOrderDetailsFieldsChange={onOrderDetailsFieldsChange}
        isEditable={isEditable}
        setIsUpdate={setIsUpdate}
        saveOrder={handleSavingOrder}
        setCompanyFieldsChanged={setCompanyFieldsChanged}
      />

      <>
        <h1 className="campany-detail">Company Owners</h1>
        {/* <Typography className={classes.sectionTitle} variant="caption">
        Company Owners
      </Typography> */}
        <CompanyOwnersTable
          classes={{ ownersTable: classes.ownersTable }}
          companyOwners={orderDetails.company_details ? orderDetails.company_details.company_users : []}
          company={orderDetails.company || undefined}
          handleEditButtonClick={handleEditOwnerButtonClick}
          onOwnerDelete={handleOwnerDeleteClick}
          onAddOwnerClick={toggleIsNewOwnerDialogOpen}
          isEditable={isEditable}
        />
      </>

      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <h1 className="">Tasks</h1>
          {/* <Link
            component="button"
            variant="body2"
            onClick={() => setOrderTypeTasksOpen(true)}
            data-testid="add-task-button"
          >
            + Add OrderType Task
          </Link> */}
        </div>

        <OrderTasks
          orderDetails={orderDetails}
          orderId={orderDetails.id}
          is_rush_order={orderDetails.is_rush_order}
          classes={{ root: classes.orderTasks }}
        />
      </>

      <br />
      <>
        <h1 className="campany-detail">Add Missing Items</h1>
        <AddOrderItem />
      </>

      <div className={classes.multiSelect}>
        <h1 className="campany-detail">PDF Generator</h1>
      </div>
      <div className={classes.pdfSec}>
        <div style={{ width: '100%', display: 'flex' }}>
          <SelectAutocomplete
            options={pdfDocs.map((pdf: any) => ({
              value: pdf.id,
              label: pdf.document_name,
            }))}
            containerStyles={{ width: '69%' }}
            // label="Docs"
            // value={addressFields.city}
            handleChange={(value: string) => {
              //handleInputFieldsChange('city')(value)
              handleDocSelect(value)
            }}
            label="Select Documents"
          />
          <ButtonWithLoading
            onClick={generatePdf}
            isLoading={loading}
            disabled={pdfDocId !== '' ? false : true}
            style={{ width: 'auto', marginLeft: '1rem' }}
          >
            Generate PDF
          </ButtonWithLoading>
        </div>

        <UploadButton
          onChange={onPdfUpload}
          text={'Upload PDF'}
          loading={uploadLoading}
          disabled={pdfDocId !== '' ? false : true}
        />
        <Divider style={{ borderRadius: '5px 5px 0px 0px', height: 10, backgroundColor: '#f47950' }} />
      </div>
      <br />
      <br />
      <div className={classes.documentUploads}>
        <DocumentUploads type="company_order" objectId={orderDetails.id} isPdfUploaded={isPdfUploaded} />
      </div>

      <div className={classes.multiSelect}>
        <div className="sub-order-section">
          <h1 className="campany-detail">Orders</h1>
          <CreateOrder
            btnClassName="create-btn"
            buttonName="Add Sub Order"
            companyId={orderDetails.company_id}
            setIsUpdated={setOrderStatusChanged}
          />
        </div>

        <OrderTable
          orderStatusChanged={orderStatusChanged}
          isCompanyCreationFlow
          disableSearch
          orderSearchOptions={{ company_id: orderDetails.company_id }}
        />

        {futureOrders?.length && (
          <div className={classes.futureOrders}>
            <Typography className={classes.captionText} variant="caption">
              Future Orders
            </Typography>
            <NonPaginatedOrdersTable orders={futureOrders} disableActionButtons />
          </div>
        )}
      </div>
    </div>
  )
}

export default Order

// TODO: #373 add error boundaries around big components
