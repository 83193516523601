import { Block, Cancel, Email, Schedule, Send, Sms } from '@material-ui/icons'
import React from 'react'
import { formatDateTimeToHumanFriendly } from 'utils/formatDate'
import { OrderReminderSchedulesData } from 'api/leadManager'

interface UpcomingNotificationBoxProps {
  reminder: OrderReminderSchedulesData
}
const UpcomingNotificationBox: React.FC<UpcomingNotificationBoxProps> = ({
  reminder,
}: UpcomingNotificationBoxProps) => {
  const { send_time, type, status, content } = reminder

  return (
    <div className="chatbox-container">
      <div className={`${'chatbox-section'}`}>
        <p className="text" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="inline-icon">
          {type?.includes('email') ? (
            <Email color="disabled" fontSize="small" />
          ) : (
            <Sms color="disabled" fontSize="small" />
          )}
          <p>{formatDateTimeToHumanFriendly(send_time || '')}</p>
          <div className="icon-wrapper">
            {(status?.includes('Sent') && <Send color={'primary'} fontSize="small" />) ||
              (status?.includes('Scheduled') && <Schedule color={'disabled'} fontSize="small" />) ||
              (status?.includes('cancelled') && <Cancel color={'error'} fontSize="small" />) ||
              (status?.includes('Not Send') && <Block color={'disabled'} fontSize="small" />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpcomingNotificationBox
